// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { filteredMenuItems } from '../../../lib/menuUtil';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../lib/treeUtils';
import { usePlotNodes } from '../../../recoil/plotNodes';
import { useProjectContext } from '../../context/ProjectContext';
import { useCopyXYPlot } from '../../hooks/nodeDuplication/useCopyXYPlot';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

export const PlotTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { projectId } = useProjectContext();

  // == Recoil
  const [plotState] = usePlotNodes(projectId);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deletePlotNode, postDeleteNodeIds } = useNodeDeletion();
  const duplicateRow = useCopyXYPlot();

  const plot = plotState.plots.find((item) => (item.id === node.id));

  const primaryIcon = useMemo(() => {
    switch (plot?.plot.case) {
      case 'monitorPlot':
        return { name: 'chartArrowRight' } as IconSpec;
      case 'xyPlot':
        return { name: 'chart' } as IconSpec;
      default:
        return undefined;
    }
  }, [plot]);

  const deleteRow = useCallback(() => {
    if (deletePlotNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deletePlotNode, node.id, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => {
    const disabled = !canDelete(node.type, node.id);
    return filteredMenuItems([
      {
        itemConfig: duplicateTreeNodeMenuItem(() => duplicateRow(node.id), disabled),
        shouldShow: plot?.plot.case === 'xyPlot',
      },
      {
        itemConfig: deleteTreeNodeMenuItem(deleteRow, disabled),
        shouldShow: true,
      },
    ]);
  }, [canDelete, deleteRow, node.id, node.type, duplicateRow, plot]);

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={primaryIcon}
      renaming={renaming}
    />
  );
};
