// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, useRecoilState } from 'recoil';

export enum ColorByEnum {
  NONE = 'none',
  TAGS = 'tags',
  SURFACE = 'surface',
  VOLUME = 'volume',
  SURFACE_TAGS = 'surfaceTags',
  VOLUME_TAGS = 'volumeTags',
}

export type ColorByType = 'none' | 'tags' | 'surface' | 'volume' | 'surfaceTags' | 'volumeTags';

export const colorByState = atomFamily<ColorByType, string>({
  key: 'colorByState',
  default: ColorByEnum.NONE,
});

export const useColorBy = (projectId: string) => useRecoilState(colorByState(projectId));
