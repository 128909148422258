// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import {
  Edge,
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import cx from 'classnames';
import { createPortal, flushSync } from 'react-dom';

import { CommonMenuItem, ContextMenuProps } from '../../lib/componentTypes/menu';
import { colors } from '../../lib/designSystem';
import { getRelativeEventCoordinates } from '../../lib/event';
import { CommonMenu } from '../Menu/CommonMenu';
import { createStyles, makeStyles } from '../Theme';
import { DotsTwoColumns } from '../svg/TwoColumnDots';

import { TooltippedText } from './TooltippedText';

const useStyles = makeStyles(
  () => createStyles({
    list: {
      overflowY: 'auto',
      paddingRight: '8px',
    },
    item: {
      position: 'relative',
      transition: 'background-color 250ms',

      '&:hover': {
        backgroundColor: colors.neutral300,
      },
    },
    itemContent: {
      display: 'flex',
      height: '28px',
      padding: '4px',
      alignItems: 'center',
      gap: '6px',
      cursor: 'grab',

      '&.dragging': {
        color: colors.neutral650,
      },
    },
    dragHandle: {
      display: 'inline-flex',
    },
    dropIndicator: {
      position: 'absolute',
      width: '100%',
      height: '2px',
      backgroundColor: colors.primaryCta,
      marginLeft: '5px',

      '&.top': {
        top: '-1px',
      },

      '&.bottom': {
        bottom: '-1px',
      },

      '&:before': {
        content: '""',
        position: 'absolute',
        width: '8px',
        height: '8px',
        border: `2px solid ${colors.primaryCta}`,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        top: '-3px',
        left: '-7px',
      },
    },
    dragPreview: {
      backgroundColor: colors.surfaceDark3,
      opacity: 0.99,
      borderRadius: '4px',
      height: '28px',
      lineHeight: '28px',
      fontSize: '13px',
      padding: '0 16px',
    },
  }),
  { name: 'ReorderableList' },
);

export type ReorderableItem = {
  id: string;
  label: string;
  menuItems?: CommonMenuItem[];
}

const itemDataKey = Symbol('draggable-item');

type DraggableItemData = { [itemDataKey]: true; itemId: ReorderableItem['id'] };

function getDraggableItemData(id: string): DraggableItemData {
  return { [itemDataKey]: true, itemId: id };
}

function isDraggableItemData(data: Record<string | symbol, unknown>) {
  return data[itemDataKey] === true;
}

type DraggingState =
  | { type: 'idle' }
  | { type: 'preview', container: HTMLElement }
  | { type: 'is-dragging' }
  | { type: 'is-dragging-over', closestEdge: Edge | null };

const IDLE_STATE: DraggingState = { type: 'idle' };

const Item = (props: ReorderableItem) => {
  // == Props
  const { label, id, menuItems } = props;

  // == Hooks
  const classes = useStyles();

  // == Internal State
  const ref = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<DraggingState>(IDLE_STATE);
  const [contextMenuProps, setContextMenuProps] = useState<ContextMenuProps>({ open: false });

  const getMenuTransform = useCallback((event: MouseEvent) => {
    if (ref.current) {
      const coords = getRelativeEventCoordinates(event, ref.current, { fromRight: true });
      return { left: coords.x + 5, top: coords.y + 5 };
    }
    return undefined;
  }, []);

  const handleContextMenu = (event: React.MouseEvent) => {
    if (menuItems?.length && !contextMenuProps.open) {
      event.preventDefault();

      setContextMenuProps({
        open: true,
        transform: getMenuTransform(event.nativeEvent),
      });
    }
  };

  // == Effects
  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }
    return combine(
      draggable({
        element,
        getInitialData() {
          return getDraggableItemData(id);
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: '16px',
              y: '8px',
            }),
            render({ container }) {
              setState({ type: 'preview', container });
            },
          });
        },
        onDragStart() {
          setState({ type: 'is-dragging' });
        },
        onDrop() {
          setState(IDLE_STATE);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          // not allowing dropping on yourself
          if (source.element === element) {
            return false;
          }
          // only allowing tasks to be dropped on me
          return isDraggableItemData(source.data);
        },
        getData({ input }) {
          const data = getDraggableItemData(id);
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ['top', 'bottom'],
          });
        },
        getIsSticky() {
          return true;
        },
        onDragEnter({ self }) {
          const closestEdge = extractClosestEdge(self.data);
          setState({ type: 'is-dragging-over', closestEdge });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          // Only need to update react state if nothing has changed.
          // Prevents re-rendering.
          setState((current) => {
            if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
              return current;
            }
            return { type: 'is-dragging-over', closestEdge };
          });
        },
        onDragLeave() {
          setState(IDLE_STATE);
        },
        onDrop() {
          setState(IDLE_STATE);
        },
      }),
    );
  }, [id, label]);

  return (
    <>
      <div
        className={classes.item}
        onContextMenu={handleContextMenu}
        ref={ref}>
        <div className={cx(classes.itemContent, { dragging: state.type === 'is-dragging' })}>
          <div className={classes.dragHandle}><DotsTwoColumns /></div>
          <TooltippedText text={label} />
        </div>
        {state.type === 'is-dragging-over' && state.closestEdge ? (
          <div className={cx(classes.dropIndicator, {
            top: state.closestEdge === 'top',
            bottom: state.closestEdge === 'bottom',
          })}
          />
        ) : null}
      </div>
      {state.type === 'preview' ? (
        createPortal(<div className={classes.dragPreview}>{label}</div>, state.container)
      ) : null}
      {menuItems?.length ? (
        <CommonMenu
          anchorEl={ref.current}
          closeOnSelect
          menuItems={menuItems}
          onClose={() => setContextMenuProps({ open: false })}
          open={contextMenuProps.open}
          position="right-down"
          positionTransform={contextMenuProps.transform}
        />
      ) : null}
    </>
  );
};

interface ReorderableListProps {
  items: ReorderableItem[];
  setItems: (items: ReorderableItem[]) => void;
}

export const ReorderableList = (props: ReorderableListProps) => {
  // == Props
  const { items, setItems } = props;

  // == Hooks
  const classes = useStyles();

  // == Internal state
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => monitorForElements({
    canMonitor({ source }) {
      return isDraggableItemData(source.data);
    },
    onDrop({ location, source }) {
      const target = location.current.dropTargets[0];
      if (!target) {
        return;
      }

      const sourceData = source.data;
      const targetData = target.data;

      if (!isDraggableItemData(sourceData) || !isDraggableItemData(targetData)) {
        return;
      }

      const indexOfSource = items.findIndex((item) => item.id === sourceData.itemId);
      const indexOfTarget = items.findIndex((item) => item.id === targetData.itemId);

      if (indexOfTarget < 0 || indexOfSource < 0) {
        return;
      }

      const closestEdgeOfTarget = extractClosestEdge(targetData);

      // Using `flushSync` so we can query the DOM straight after this line
      flushSync(() => {
        setItems(
          reorderWithEdge({
            list: items,
            startIndex: indexOfSource,
            indexOfTarget,
            closestEdgeOfTarget,
            axis: 'vertical',
          }),
        );
      });
    },
  }), [items, setItems]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    return autoScrollForElements({
      element: containerRef.current,
    });
  }, []);

  return (
    <div className={classes.list} ref={containerRef}>
      {items.map((item) => (
        <Item id={item.id} key={item.id} label={item.label} menuItems={item.menuItems} />
      ))}
    </div>
  );
};
