// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { advancedAnalysisLink, isInExploration, jobLink, meshLink, outputsLink, physicsLink, solverLink, workflowLink } from '../../../lib/navigation';
import { AdvancedAnalysisParams } from '../../../lib/routeParamTypes';
import { isSensitivityAnalysis } from '../../../lib/workflowUtils';
import { useWorkflowState } from '../../../recoil/workflowState';
import { ActionButton } from '../../Button/ActionButton';
import Tooltip from '../../Tooltip';
import { useProjectContext } from '../../context/ProjectContext';
import { useConfigWarnings } from '../../hooks/useRunSimulation';
import { LeveledMessageList } from '../../notification/LeveledMessageList';

export const AdvancedAnalysisButton = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // == State
  const workflow = useWorkflowState(projectId, workflowId);
  const isExploration = isInExploration(location.pathname);
  // Do not include workflowId/jobId because we want the setup errors, not the simulation errors.
  const warnings = useConfigWarnings(projectId, '', '');
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const setupDoeTooltip = warnings.length ?
    <LeveledMessageList maxToShow={10} messages={warnings} /> : '';

  const referrerType = useMemo(() => {
    if (isSensitivity) {
      return 'sensitivity';
    }
    if (isInExploration(location.pathname)) {
      return 'doe';
    }
    return 'simulation';
  }, [isSensitivity, location.pathname]);

  // This is coming with Adjoint - LC-22470
  // const menuItems: CommonMenuItem[] = [];
  // menuItems.push({
  //   label: 'Setup Adjoint',
  //   disabled: true,
  //   disabledReason: 'Coming soon',
  //   onClick: () => {},
  // });
  // menuItems.push({
  //   label: 'Setup Design of Experiment',
  //   onClick: () => {
  //     navigate(
  //       advancedAnalysisLink(
  //         projectId,
  //         'doe',
  //         workflowId,
  //         isInExploration(location.pathname) ? 'doe' : 'simulation',
  //       ),
  //     );
  //   },
  // });

  // return (
  //   <SplitButton
  //     asBlock
  //     dataLocator="advancedAnalysisButton"
  //     kind="primary"
  //     menuItems={menuItems}
  //     splitIcon={{ name: 'gear', maxHeight: 12 }}>
  //     Advanced Analysis
  //   </SplitButton>
  // );

  return (
    <Tooltip title={setupDoeTooltip}>
      <span>
        <ActionButton
          asBlock
          dataLocator="setupDoeButton"
          disabled={warnings.length > 0}
          kind="primary"
          onClick={() => {
            // Go to the advanced analysis page. It will set the controlPanelMode to 'exploration'
            // when it detects the `advanced` path and the `doe` in the pathname.
            navigate(
              advancedAnalysisLink(
                projectId,
                workflowId,
                jobId,
                'doe',
                referrerType,
              ),
            );
          }}>
          Setup Design of Experiments
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export const CloseAdvancedAnalysisButton = () => {
  // == Contexts
  const { projectId } = useProjectContext();

  // == Hooks
  const navigate = useNavigate();
  const params = useParams<AdvancedAnalysisParams>();

  // == State
  const referrerWorkflowId = params.referrerWorkflowId ?? '';
  const referrerJobId = params.referrerJobId ?? '';
  const referrerType = params.referrerType ?? 'solver';

  const link = useMemo(() => {
    switch (referrerType) {
      case 'physics':
        return physicsLink(projectId);
      case 'mesh':
        return meshLink(projectId);
      case 'outputs':
        return outputsLink(projectId);
      case 'solver':
        return solverLink(projectId);
      case 'doe':
        return jobLink(projectId, referrerWorkflowId, referrerJobId);
      case 'simulation':
        return workflowLink(projectId, referrerWorkflowId, false);
      case 'sensitivity':
        return workflowLink(projectId, referrerWorkflowId, true);
      default:
        throw Error('Unknown referrer type');
    }
  }, [projectId, referrerWorkflowId, referrerJobId, referrerType]);

  return (
    <ActionButton
      asBlock
      dataLocator="closeAdvancedAnalysis"
      kind="cancel"
      onClick={() => navigate(link)}>
      Close
    </ActionButton>
  );
};
