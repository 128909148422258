// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { CommonMenuListItem } from '../../lib/componentTypes/menu';
import { getFilterNodeIcon } from '../../lib/visUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';

import { useHandlePvFilterClick } from './useHandlePvFilterClick';
import { useIsFilterDisabled } from './useIsFilterDisabled';

// This hooks returns a function which generates an item for a dropdown filters menu.
export const useGetFilterMenuItem = ({ parentNodeId }: { parentNodeId?: string; } = {}) => {
  // Hooks
  const handlePvFilterClick = useHandlePvFilterClick({ parentNodeId });
  const isFilterDisabled = useIsFilterDisabled();

  return (label: string, type: ParaviewRpc.TreeNodeType, earlyAccess?: boolean) => ({
    disabled: isFilterDisabled(type),
    earlyAccess,
    label,
    startIcon: { name: getFilterNodeIcon(type) },
    onClick: () => handlePvFilterClick(type),
  }) as CommonMenuListItem;
};
