// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import * as rpc from '../../lib/rpc';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';

type RecoilKey = {
  geometryId: string,
}

// List of geometries in a given project.
export const geometryDetailsState = selectorFamily<
  geometryservicepb.GetGeometryResponse | null,
  RecoilKey
>({
  key: 'geometryDetails',
  get: (key: RecoilKey) => async () => {
    const { geometryId } = key;
    if (!geometryId) {
      return null;
    }
    try {
      const response = await rpc.callRetryWithClient(
        rpc.clientGeometry,
        'getGeometry',
        rpc.clientGeometry.getGeometry,
        new geometryservicepb.GetGeometryRequest({ geometryId }),
      );
      return response;
    } catch (err) {
      return null;
    }
  },
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

export function useGeometryDetails(geometryId: string) {
  return useRecoilValue(geometryDetailsState({ geometryId }));
}
