// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import * as frontendpb from '../proto/frontend/frontend_pb';

export type nullableHealthReply = frontendpb.CheckGeometryReply_Done | null;

const geometryHealthKey = 'geometryHealth';

function serialize(val: nullableHealthReply): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): nullableHealthReply {
  return (val.length ?
    frontendpb.CheckGeometryReply_Done.fromBinary(val) :
    null);
}

// This represents the state of the geometry health card. All the information for the card is
// contained in a reply received from CheckGeometry.
export const geometryHealthState = atomFamily<nullableHealthReply, string>({
  key: geometryHealthKey,
  default: (projectId: string) => (
    persist.getProjectState(projectId, [geometryHealthKey], deserialize)
  ),
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, geometryHealthKey, deserialize, serialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const useGeometryHealth = (projectId: string) => (
  useRecoilState(geometryHealthState(projectId))
);

export const useSetGeometryHealth = (projectId: string) => (
  useSetRecoilState(geometryHealthState(projectId))
);

export const useGeometryHealthValue = (projectId: string) => (
  useRecoilValue(geometryHealthState(projectId))
);
