// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { MultipleChoiceParam, ParamType, getFullDescriptor } from '../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../SimulationParamDescriptor';
import { Subcode } from '../proto/base/base_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import { GetWorkflowReply_Job } from '../proto/frontend/frontend_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { ParamScope } from './ParamScope';
import { projectHasMovingFrames } from './motionDataUtils';
import { findFluidPhysicsMaterial, getPhysicsInitialization, isPhysicsFluid } from './physicsUtils';
import { isSimulationTransient } from './simulationUtils';
import { Parser } from './status';
import { ParamWithFallback, weakParamCompare } from './weakParamCompare';

const { EXISTING_SOLUTION } = simulationpb.InitializationType;

// List of constraints
export function meshConstraints(
  allowDifferentMeshes: boolean,
) {
  return allowDifferentMeshes ? [] : [
    // Solution files must share the same input mesh file.
    {
      desc: getFullDescriptor(
        paramGroupDesc[ParamGroupName.Input],
        paramDesc[ParamName.Url],
      ),
      warningMessage: 'Incompatible mesh.',
    }];
}

export const fluidConstraints: ParamWithFallback[] = [
  // Turbulence model must be the same, i.e. no fallback option.
  {
    desc: getFullDescriptor(
      paramGroupDesc[ParamGroupName.Turbulence],
      paramDesc[ParamName.TurbulenceModel],
    ),
    warningMessage: 'Incompatible turbulence model.',
  },
  // For viscous model its a bit more involved because we allow solutions with different viscous
  // models as initialization. The compatible models depend on what is currently selected.
  {
    desc: getFullDescriptor(
      paramGroupDesc[ParamGroupName.BasicFluid],
      paramDesc[ParamName.ViscousModel],
    ),
    fallbackParamDesc: getFullDescriptor(
      paramGroupDesc[ParamGroupName.BasicFluid],
      paramDesc[ParamName.ViscousModel],
    ),
    fallBackParamValues: new Map([
      // If its a laminar, inviscid or LES case the other solution can have any viscous model.
      [simulationpb.ViscousModel.LAMINAR, [...Object.values(simulationpb.ViscousModel)]],
      [simulationpb.ViscousModel.INVISCID, [...Object.values(simulationpb.ViscousModel)]],
      [simulationpb.ViscousModel.LES, [...Object.values(simulationpb.ViscousModel)]],
      // RANS, DES can be initialized with any solution that has turbulence involved.
      [simulationpb.ViscousModel.RANS, [simulationpb.ViscousModel.DES]],
      [simulationpb.ViscousModel.DES, [simulationpb.ViscousModel.RANS]],
    ]),
    warningMessage: 'Incompatible viscous model.',
  },
  {
    desc: getFullDescriptor(
      paramGroupDesc[ParamGroupName.MaterialFluid],
      paramDesc[ParamName.DensityRelationship],
    ),
    fallbackParamDesc: getFullDescriptor(
      paramGroupDesc[ParamGroupName.MaterialFluid],
      paramDesc[ParamName.DensityRelationship],
    ),
    fallBackParamValues: new Map([
      // It is ok to initialize constant density from solutions with temperature,
      // but not the other way around.
      [
        simulationpb.DensityRelationship.CONSTANT_DENSITY,
        [
          simulationpb.DensityRelationship.IDEAL_GAS,
          simulationpb.DensityRelationship.CONSTANT_DENSITY_ENERGY,
        ],
      ],
      // Both ideal gas and constant density with energy have temperature.
      [
        simulationpb.DensityRelationship.IDEAL_GAS,
        [simulationpb.DensityRelationship.CONSTANT_DENSITY_ENERGY],
      ],
      [
        simulationpb.DensityRelationship.CONSTANT_DENSITY_ENERGY,
        [simulationpb.DensityRelationship.IDEAL_GAS],
      ],
    ]),
    warningMessage: 'Incompatible material definition.',
  },
];

export const getViolatedParamConstraints = (
  paramScopeA: ParamScope,
  paramScopeB: ParamScope,
  constraints: ParamWithFallback[],
) => (
  constraints.filter((constraint) => !weakParamCompare(constraint, paramScopeA, paramScopeB))
);

export function isPhysicsCompatible(
  paramA: simulationpb.SimulationParam,
  paramB: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  if (paramA.physics.length !== paramB.physics.length) {
    return false;
  }
  // Currently nothing can be initialized from adjoint results.
  if (paramB.general?.floatType === simulationpb.FloatType.ADA1D) {
    return false;
  }
  return !paramA.physics.some((physics, index) => {
    // Check if physics have the same type.
    if (physics.params.case !== paramB.physics[index].params.case) {
      return true;
    }
    // Check if both physics have an associated material.
    if (isPhysicsFluid(physics)) {
      const materialA = findFluidPhysicsMaterial(paramA, physics, geometryTags, staticVolumes);
      const materialB =
        findFluidPhysicsMaterial(paramB, paramB.physics[index], geometryTags, staticVolumes);
      return !materialA || !materialB;
    }
    return false;
  });
}

export function existingSolutionChoiceInParam(simParam: simulationpb.SimulationParam) {
  return simParam.physics.some(
    (physics) => getPhysicsInitialization(physics)?.initializationType === EXISTING_SOLUTION,
  );
}

export function existingSolutionUrlInParam(simParam: simulationpb.SimulationParam) {
  const initPhysics = simParam.physics.find(
    (physics) => getPhysicsInitialization(physics)?.initializationType === EXISTING_SOLUTION,
  );
  return getPhysicsInitialization(initPhysics)?.existingSolutionUrl || '';
}

export function getWarning(
  constraint: ParamWithFallback,
  scope: ParamScope,
  otherScope: ParamScope,
): string {
  const desc = constraint.desc.param;
  const value = scope.value(desc) as number;
  const selectedValue = otherScope.value(desc) as number;
  const paramText = desc.text;
  if (desc.type === ParamType.MULTIPLE_CHOICE) {
    const choices = (desc as MultipleChoiceParam).choices;
    const choiceText = choices.find((choice) => choice.enumNumber === value)?.text;
    // If the parameter is not enabled in the selected simulation we present it as 'None' choice
    // to the user.
    const selectedChoiceText = otherScope.isEnabled(desc.cond) ?
      choices.find((choice) => choice.enumNumber === selectedValue)?.text : 'None';
    return `This simulation has ${paramText} -> ${choiceText} while the selected simulation has
    ${paramText} -> ${selectedChoiceText}`;
  } if (desc.name === paramDesc[ParamName.Url].name) {
    return 'This simulation has a different mesh than the selected simulation.';
  }
  return '';
}

// Checks whether a solution at a particular iteration for a job has diverged. If that is the case
// returns a warning string. Otherwise, if the solution is valid, it returns an empty string.
export function getSolutionDivergedWarning(job: GetWorkflowReply_Job, iteration: bigint): string {
  const status = job.status?.failedReason.case === 'status' ? job.status.failedReason.value : null;
  const parser = status && new Parser(status);
  if (
    iteration === job?.latestIter &&
    parser?.subcode() === Subcode.JOB_CANCELED_DIVERGENCE
  ) {
    return 'The selected solution has diverged and cannot be used for initialization.';
  }
  return '';
}

/**
 * Check if the existing solution param is compatible with the initial base param. If they are not
 * compatible, an error message is returned.
 *
 * @param baseParam the base sim param
 * @param existingSolutionParam the existing solution param
 * @returns an error message if the params are incompatible, otherwise undefined
 */
export function checkSolutionConstraints(
  baseParam: simulationpb.SimulationParam,
  existingSolutionParam: simulationpb.SimulationParam,
) {
  const sameMesh = baseParam.input?.url === existingSolutionParam.input?.url;
  // cannot initialize from a solution with different mesh and moving grid
  if (
    !sameMesh &&
    isSimulationTransient(existingSolutionParam) &&
    projectHasMovingFrames(existingSolutionParam)
  ) {
    return 'Cannot initialize from a solution on a different mesh from transient simulations ' +
      'with moving frames.';
  }
  return undefined;
}
