// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useState } from 'react';

import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
  waitForAll,
} from 'recoil';

import { GroupLeafMap } from '../lib/GroupMap';
import { boundRegex, removeBoundsPrefix } from '../lib/boundaryConditionUtils';
import { EntityGroupMap } from '../lib/entityGroupMap';
import { convertFromProto, convertToProto, makeNumDescendantsMap, replaceStrings, rollupGroups } from '../lib/entityGroupUtils';
import { entityGroupFixture } from '../lib/fixtures';
import { prefixNameGen, uniqueSequenceName } from '../lib/name';
import {
  findParticleGroupById,
  getParticleGroupsForSimulationTree,
  getProbePoints,
} from '../lib/particleGroupUtils';
import { RecoilProjectKey, getProjectState, getProjectStateKey, setStateNow } from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { getSimulationParam } from '../lib/simulationParamUtils';
import { isTestingEnv } from '../lib/testing/utils';
import * as simulationpb from '../proto/client/simulation_pb';
import * as entitygrouppb from '../proto/entitygroup/entitygroup_pb';
import { OutputNode } from '../proto/frontend/output/output_pb';
import * as geometrypb from '../proto/geometry/geometry_pb';
import { MeshFileMetadata } from '../proto/lcn/lcmesh_pb';

import { getGeoState, onGeometryTabSelector } from './geometry/geometryState';
import { geometryTagsState } from './geometry/geometryTagsState';
import { geometryUsesTagsSelector } from './geometry/geometryUsesTags';
import { meshMetadataSelector, meshUrlState } from './meshState';
import { outputNodesState } from './outputNodes';
import { cadMetadataState } from './useCadMetadata';
import { StaticVolume, defaultVolumeState, staticVolumesState } from './volumes';
import { currentConfigSelector } from './workflowConfig';

interface EntitySources {
  meshMetadata?: MeshFileMetadata;
  param: simulationpb.SimulationParam;
  staticVolumes: StaticVolume[];
  tags?: geometrypb.Tags;
}

interface EntityContext extends EntitySources {
  groupMap: EntityGroupMap;
}

export interface EntityGroupData {
  groupMap: EntityGroupMap;
  leafMap: GroupLeafMap;
  idsOrderedByLevel: string[];
}

export const entityGroupPrefix = 'entityGroups';
const legacyEntityGroupPrefix = 'surfaceGroups';

const deserialize = (key: RecoilProjectKey) => async (val: Uint8Array, index: number) => {
  const deserialized = val.length ? entitygrouppb.EntityGroups.fromBinary(val) : null;
  // If a state key other than the first one works (i.e. the state keys with empty workflowId and
  // jobId, see getter in entityGroupsSelector below) it means we don't have a per-job state for
  // entity groups stored in kv store yet. In this case we set the correct state here.
  if (index > 0 && deserialized != null && key.workflowId && key.jobId) {
    await setStateNow(key.projectId, getProjectStateKey(entityGroupPrefix, key), new Date(), val);
  }
  return deserialized;
};
export const serialize = (val: entitygrouppb.EntityGroups) => val.toBinary();

export function getIdsOrderedByLevel(entityGroupMap: EntityGroupMap) {
  const groups = entityGroupMap.getGroups();
  groups.sort((a, b) => a.level - b.level);
  return groups.map(({ id }) => id);
}

export function createEntityGroupData(entityGroupMap: EntityGroupMap): EntityGroupData {
  return {
    groupMap: entityGroupMap,
    leafMap: entityGroupMap.createLeafMap(),
    idsOrderedByLevel: getIdsOrderedByLevel(entityGroupMap),
  };
}

const entityGroupsSelectorRpc = selectorFamily<
  entitygrouppb.EntityGroups | null,
  RecoilProjectKey
>({
  key: 'entityGroupsSelector/rpc',
  get: (key: RecoilProjectKey) => async () => (
    getProjectState(
      key.projectId,
      [
        getProjectStateKey(entityGroupPrefix, key),
        getProjectStateKey(
          entityGroupPrefix,
          { projectId: key.projectId, workflowId: '', jobId: '' },
        ),
        legacyEntityGroupPrefix,
      ],
      deserialize(key),
    )
  ),
  dangerouslyAllowMutability: true,
});

// Selector used for testing
const entityGroupsSelectorTesting = selectorFamily<
  entitygrouppb.EntityGroups | null,
  RecoilProjectKey
>({
  key: 'entityGroupsSelector/testing',
  get: () => entityGroupFixture,
  dangerouslyAllowMutability: true,
});

const entityGroupsSelector = isTestingEnv() ? entityGroupsSelectorTesting : entityGroupsSelectorRpc;

// Converts particles into a more general list of entity groups..
const convertParticles = (entityContext: EntityContext) => {
  const { param, groupMap } = entityContext;

  if (param.particleGroup) {
    const particleGroups = getParticleGroupsForSimulationTree(param);
    particleGroups.forEach((group) => {
      const { particleGroupId: id, particleGroupName } = group;
      if (!groupMap.has(id)) {
        groupMap.add({
          parentId: EntityGroupMap.rootId,
          name: particleGroupName,
          entityType: entitygrouppb.EntityType.PARTICLE_GROUP,
          id,
        });
      } else {
        groupMap.get(id).name = particleGroupName;
      }
    });
  }
};

// Converts monitor planes into a more general list of entity groups.
const convertMonitorPlanes = (entityContext: EntityContext) => {
  const { param, groupMap } = entityContext;

  if (param.monitorPlane) {
    param.monitorPlane.forEach((plane) => {
      const { monitorPlaneId: id, monitorPlaneName } = plane;
      if (!groupMap.has(id)) {
        groupMap.add({
          parentId: EntityGroupMap.rootId,
          name: monitorPlaneName,
          entityType: entitygrouppb.EntityType.MONITOR_PLANE,
          id,
        });
      } else {
        groupMap.get(id).name = monitorPlaneName;
      }
    });
  }
};

// Converts point probes into a more general list of entity groups.
const convertProbePoints = (entityContext: EntityContext) => {
  const { param, groupMap } = entityContext;

  const pointProbes = getProbePoints(param);
  pointProbes.forEach((point) => {
    const { id, name } = point;
    if (!groupMap.has(id)) {
      groupMap.add({
        parentId: EntityGroupMap.rootId,
        name,
        entityType: entitygrouppb.EntityType.PROBE_POINTS,
        id,
      });
    } else {
      groupMap.get(id).name = name;
    }
  });
};

const convertSurfaces = (entityContext: EntityContext) => {
  const { param, meshMetadata, groupMap } = entityContext;

  meshMetadata?.zone.forEach((zone) => {
    zone.bound.forEach((bound) => {
      const name = bound.name;
      if (!name) {
        throw Error(`empty bound name in ${JSON.stringify(meshMetadata)}`);
      }
      if (!groupMap.has(name)) {
        groupMap.add({
          name: param.surfaceName[name]?.surfaceName ?? removeBoundsPrefix(name),
          parentId: EntityGroupMap.rootId,
          entityType: entitygrouppb.EntityType.SURFACE,
          id: name,
        });
      }
    });
  });
};

const convertVolumes = (entityContext: EntityContext) => {
  const { groupMap, staticVolumes } = entityContext;

  staticVolumes.forEach(({ defaultName, id }) => {
    if (!groupMap.has(id)) {
      groupMap.add({
        id,
        name: defaultName,
        parentId: EntityGroupMap.rootId,
        entityType: entitygrouppb.EntityType.VOLUME,
      });
    }
  });
};

// Remove any entity that does not have an associated item in the params anymore
export const pruneGroups = (
  entityGroupMap: EntityGroupMap,
  param: simulationpb.SimulationParam,
) => {
  const newEntityGroup = new EntityGroupMap(entityGroupMap);
  const markedForDeletion: string[] = [];
  entityGroupMap.getGroups().forEach((group) => {
    if (!group.children.size) {
      if (group.entityType === entitygrouppb.EntityType.PARTICLE_GROUP ||
        group.entityType === entitygrouppb.EntityType.PROBE_POINTS) {
        // Delete the entity if a corresponding item in the params cannot be found.
        if (!findParticleGroupById(param, group.id)) {
          markedForDeletion.push(group.id);
        }
      } else if (group.entityType === entitygrouppb.EntityType.MONITOR_PLANE) {
        const plane = param.monitorPlane.find((item) => item.monitorPlaneId === group.id);
        if (!plane) {
          markedForDeletion.push(group.id);
        }
      }
    }
  });
  markedForDeletion.forEach((id) => {
    newEntityGroup.delete(id);
  });
  return newEntityGroup;
};

// Adds entities to the group map if they are missing and updates names
export const updateGroups = (entityContext: EntityContext) => {
  convertSurfaces(entityContext);
  convertVolumes(entityContext);
  convertParticles(entityContext);
  convertProbePoints(entityContext);
  convertMonitorPlanes(entityContext);
};

// Updates groups with the names defined in the surfaceNameMap. If no entry can be found and the
// group name is still empty we remove the group. We also remove groups that are empty. Both could
// happen because of a bug (that is fixed now). For new projects (since release-70) group names are
// stored directly in the group structure in the kv store and there shouldn't be any empty groups.
const validateGroups = (entityContext: EntityContext) => {
  const { param, groupMap } = entityContext;

  const newEntityGroup = new EntityGroupMap(groupMap);
  groupMap.getGroups().forEach((group) => {
    let name = param.surfaceName[group.id]?.surfaceName ?? group.name;
    // If the name is still empty we use the boundary id as a name (i.e. we are using
    // the initial name that comes from the mesh)
    if (!name.length && boundRegex.test(group.id)) {
      name = removeBoundsPrefix(group.id);
    }
    // Only add the group if it has a name
    if (name.length) {
      newEntityGroup.add(
        { parentId: group.parentId ?? 'root', ...group, name },
      );
    }
  });
  return groupMap;
};

// Create a default group map that contains the surfaces defined in the mesh.
export const createDefaultGroupMap = (
  entitySources: EntitySources,
  disableUpdateCallback: boolean,
) => {
  const groupMap = new EntityGroupMap(undefined, disableUpdateCallback);
  updateGroups({ ...entitySources, groupMap });
  return groupMap;
};

export const entityGroupState = atomFamily<EntityGroupMap, RecoilProjectKey>({
  key: entityGroupPrefix,
  default: selectorFamily<EntityGroupMap, RecoilProjectKey>({
    key: `${entityGroupPrefix}/default`,
    get: (key: RecoilProjectKey) => ({ get }) => {
      if (get(onGeometryTabSelector)) {
        const geoState = getGeoState(get, key.projectId);
        if (!geoState) {
          return new EntityGroupMap(undefined, true);
        }
        const metadata = geoState.metadata;
        const volumeState = defaultVolumeState(metadata, geoState.cadMetadata);
        if (geoState.cadMetadata.entityGroups) {
          // Disable the updateCallback for this entity group since we know that we don't need it
          // while in the geometry tab.
          const entityGroupMap = convertFromProto(geoState.cadMetadata?.entityGroups, true);
          const entityContext: EntityContext = {
            meshMetadata: metadata,
            param: new simulationpb.SimulationParam(),
            staticVolumes: volumeState,
            groupMap: entityGroupMap,
          };
          updateGroups(entityContext);
          const geometryTags = get(geometryTagsState({ projectId: key.projectId }));
          geometryTags.addToEntityGroup(entityContext.groupMap);
          // Avoid validating the groups, it's expensive and it should not be needed for igeo.
          return entityContext.groupMap;
        }
        // No entity group is mixed in the geometry tab, so we can disable the updateCallback of
        // the entity group.
        const disableUpdateCallback = true;
        return createDefaultGroupMap({
          meshMetadata: metadata,
          param: new simulationpb.SimulationParam(),
          staticVolumes: volumeState,
        }, disableUpdateCallback);
      }

      // Fetch all pieces of dependent state in parallel.
      const [
        entityGroupsProto,
        currentConfig,
        staticVolumes,
        tags,
        meshUrlGet,
        cadMetadata,
        geoUsesTags,
      ] =
        get(waitForAll([
          entityGroupsSelector(key),
          currentConfigSelector(key),
          staticVolumesState(key.projectId),
          geometryTagsState({ projectId: key.projectId }),
          meshUrlState(key.projectId),
          cadMetadataState(key.projectId),
          geometryUsesTagsSelector(key.projectId),
        ]));
      const meshUrl = meshUrlGet.geometry;

      const metadata = get(meshMetadataSelector({ projectId: key.projectId, meshUrl }));
      const entitySources = {
        meshMetadata: metadata?.meshMetadata,
        param: getSimulationParam(currentConfig),
        staticVolumes,
      };

      const disableUpdateCallback = geoUsesTags;
      // The mesh metadata may contain an entityGroup proto message but the kvstore may also already
      // have an entityGroup proto stored. We need to prioritize the entityGroup proto coming from
      // the kvstore since it is the most recent one and may contain modifications from the user.
      // However, if it's not present we will try to grab the entityGroup proto from the mesh
      // metadata. If we cannot find a valid entityGrou proto from the kvstore or mesh metadata, we
      // will then create a default entity group.
      const sourceProto = entityGroupsProto || metadata?.meshMetadata.entityGroups ||
        cadMetadata.entityGroups;
      if (sourceProto) {
        const entityGroupMap = convertFromProto(sourceProto, disableUpdateCallback);
        const entityContext = {
          ...entitySources,
          groupMap: entityGroupMap,
        };
        updateGroups(entityContext);
        tags.addToEntityGroup(entityContext.groupMap);
        if (geoUsesTags) {
          // No need to validate the groups in this case, we don't have to update the surface names.
          return entityContext.groupMap;
        }
        return validateGroups(entityContext);
      }
      const defGroup = createDefaultGroupMap(entitySources, disableUpdateCallback);
      tags.addToEntityGroup(defGroup);
      return defGroup;
    },
  }),
  effects: (key: RecoilProjectKey) => [
    syncProjectStateEffect(
      key.projectId,
      getProjectStateKey(entityGroupPrefix, key),
      (val: Uint8Array) => (convertFromProto(entitygrouppb.EntityGroups.fromBinary(val))),
      (newVal: EntityGroupMap) => serialize(convertToProto(newVal)),
    ),
  ],
});

/**
 * Map of { groupId: number of descendants } for each groupId in the entity group map.
 */
const numDescendantsMapSelector = selectorFamily<Map<string, number>, RecoilProjectKey>({
  key: 'numDescendantsMapSelector',
  get: (key: RecoilProjectKey) => async ({ get }) => {
    const entityGroupMap = get(entityGroupState(key));
    return makeNumDescendantsMap(entityGroupMap);
  },
});

export function useNumDescendantsMap(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(numDescendantsMapSelector({ projectId, workflowId, jobId }));
}

/**
 * Returns the number of descendants for a given group in the entity group map.
 * @param projectId
 * @param workflowId
 * @param jobId
 * @param groupId the id of the group to get the number of descendants for
 * @returns the number of descendants for the given group.
 */
export const useNumDescendants = (
  projectId: string,
  workflowId: string,
  jobId: string,
  groupId: string,
) => useRecoilValue(numDescendantsMapSelector({ projectId, workflowId, jobId })).get(groupId);

export const entityGroupDataSelector = selectorFamily<EntityGroupData, RecoilProjectKey>({
  key: 'entityGroupDataState',
  get: (key: RecoilProjectKey) => ({ get }) => {
    const groupMap = get(entityGroupState(key));
    return createEntityGroupData(groupMap);
  },
});

export const useEntityGroupMap = (projectId: string, workflowId: string, jobId: string) => (
  useRecoilValue(entityGroupState({ projectId, workflowId, jobId }))
);

export const useSetEntityGroupMap = (projectId: string, workflowId: string, jobId: string) => (
  useSetRecoilState(entityGroupState({ projectId, workflowId, jobId }))
);

export const useResetEntityGroupMap = (projectId: string, workflowId: string, jobId: string) => (
  useResetRecoilState(entityGroupState({ projectId, workflowId, jobId }))
);

export function useEntityGroupData(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(entityGroupDataSelector({ projectId, workflowId, jobId }));
}

// Returns a callback to group entities. onNewGroup is called when the new group is created. By
// default the new group gets a random id assigned. This can be overriden with the newGroupId
// (mainly for testing).
export const useGroupEntities = (
  projectId: string,
  workflowId: string,
  jobId: string,
  onNewGroup?: (newGroupId: string) => void,
) => {
  const [groupId, setGroupId] = useState<string | null>(null);

  useEffect(() => {
    if (groupId) {
      // Don't call onNewGroup until entityGroupState's new value has been set, which
      // we know to be true once setGroupId was called. Otherwise onNewGroup could
      // be called with stale values if the recoil set() functions haven't completed.
      onNewGroup?.(groupId);
      setGroupId(null);
    }
  }, [groupId, onNewGroup]);

  return useRecoilCallback(({ set }) => (selectedGroupIds: string[], newGroupId?: string) => {
    let rollupGroupsCallback: (entities: string[]) => string[];
    set(entityGroupState({ projectId, workflowId, jobId }), ((oldMap) => {
      const newMap = new EntityGroupMap(oldMap);
      const currentNames = oldMap.getGroups().map((group) => group.name);
      const newGroup = newMap.group(
        uniqueSequenceName(
          currentNames,
          prefixNameGen('Group'),
          { recycleNumbers: true },
        ),
        selectedGroupIds,
        newGroupId,
      );
      rollupGroupsCallback = rollupGroups(createEntityGroupData(newMap));
      setGroupId(newGroup.id);
      return newMap;
    }));
    set(outputNodesState({ projectId, workflowId, jobId }), (oldOutputs) => {
      const newOutputs = oldOutputs.clone();
      newOutputs.nodes.forEach((outputNode: OutputNode) => {
        outputNode.inSurfaces = rollupGroupsCallback(outputNode.inSurfaces);
        outputNode.outSurfaces = rollupGroupsCallback(outputNode.outSurfaces);
      });
      return newOutputs;
    });
  });
};

// Returns a callback to ungroup entities. onUngroup is called when the new group is created.
export const useUngroupEntities = (
  projectId: string,
  workflowId: string,
  jobId: string,
  onUngroup?: (ungroupdIds: string[]) => void,
) => (
  useRecoilCallback(({ set }) => (selectedNode: string) => {
    let ungroupedIds: string[] = [];
    set(entityGroupState({ projectId, workflowId, jobId }), (oldMap) => {
      const newMap = new EntityGroupMap(oldMap);
      ungroupedIds = newMap.ungroup(selectedNode);
      onUngroup?.(ungroupedIds);
      return newMap;
    });
    set(outputNodesState({ projectId, workflowId, jobId }), (oldOutputs) => {
      const newOutputs = oldOutputs.clone();
      newOutputs.nodes.forEach((outputNode: OutputNode) => {
        outputNode.inSurfaces = replaceStrings(
          outputNode.inSurfaces,
          [selectedNode],
          ungroupedIds,
        );
        outputNode.outSurfaces = replaceStrings(
          outputNode.outSurfaces,
          [selectedNode],
          ungroupedIds,
        );
      });
      return newOutputs;
    });
  })
);
