// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { CommonMenuItem } from '../../../../../lib/componentTypes/menu';
import { colors } from '../../../../../lib/designSystem';
import { geometryIdLink } from '../../../../../lib/navigation';
import * as rpc from '../../../../../lib/rpc';
import * as geometryservicepb from '../../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import { useGeometryList } from '../../../../../recoil/geometry/geometryListState';
import { ActionButton } from '../../../../Button/ActionButton';
import { CardButton } from '../../../../Button/CardButton';
import { useCommonCardPickerStyles } from '../../../../Theme/commonStyles';
import { useProjectContext } from '../../../../context/ProjectContext';
import MeshImportDialogCommon from '../../../../project/MeshImportDialogCommon';

interface GeometrySelectCardProps {
  geometry?: geometryservicepb.Geometry;
}

function makeSubtitle(faces: number, volumes: number) {
  return `${volumes} volumes, ${faces} faces`;
}

const GeometrySelectCard = (props: GeometrySelectCardProps) => {
  // == Props
  const { geometry } = props;

  // == Contexts
  const { projectId, geometryId } = useProjectContext();

  const selected = geometry?.id === geometryId;

  const [subtitle, setSubtitle] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const req = new geometryservicepb.ListGeometryEntitiesRequest({
      parentResourceId: {
        case: 'geometryId',
        value: geometryId,
      },
    });
    rpc.callRetryWithClient(
      rpc.clientGeometry!,
      'listGeometryEntities',
      rpc.clientGeometry!.listGeometryEntities,
      req,
      true,
      false,
    ).then((res) => {
      setSubtitle(makeSubtitle(res.faces.length, res.bodies.length));
    }).catch((err) => {
      console.error('Error getting geometry entities', err);
    });
  }, [geometryId]);

  const menuItems: CommonMenuItem[] = [];

  return (
    <CardButton
      compact
      disabled={selected}
      disabledReason="Geometry is already selected"
      editableDisabled
      icon={{ maxHeight: 12, name: 'cubeSolid', color: colors.highEmphasisText }}
      key={geometryId}
      menuItems={menuItems}
      onClick={() => {
        if (geometry) {
          navigate(geometryIdLink(projectId, geometry?.id));
        }
      }}
      selected={selected}
      subtitle={`${subtitle}`}
      title={`${geometry?.name}`}
    />
  );
};

const GeometrySelectContainer = () => {
  const classes = useCommonCardPickerStyles();

  const { projectId } = useProjectContext();

  const geometryList = useGeometryList(projectId);
  if (!geometryList?.geometries.length) {
    return null;
  }

  return (
    <div className={classes.cardProps}>
      {geometryList.geometries.map((geometry) => (
        <GeometrySelectCard
          geometry={geometry}
          key={geometry.id}
        />
      ))}
    </div>
  );
};

export const GeometrySelectPanel = () => {
  const classes = useCommonCardPickerStyles();

  const headerText = 'Select a Geometry to Modify';
  const [showUpload, setShowUpload] = useState(false);

  return (
    <div className={classes.propPanelContainer}>
      <div className={classes.headerContainer}>
        <p className={classes.sectionHeader}>{headerText}</p>
      </div>

      <div className={classes.buttonContainer}>
        <ActionButton
          asBlock
          kind="secondary"
          name="newGeometryButton"
          onClick={() => setShowUpload(true)}
          size="small"
          startIcon={{ name: 'plus', maxWidth: 12 }}>
          New Geometry
        </ActionButton>
      </div>
      {showUpload && (
        <MeshImportDialogCommon
          forceUploadGeometry
          onClose={() => setShowUpload(false)}
          open={showUpload}
          type="CAD"
        />
      )}
      <GeometrySelectContainer />
    </div>
  );
};
