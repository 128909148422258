import { useEffect, useRef } from 'react';

import * as flags from '../../flags';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import { useIsLMA } from '../../recoil/useIsLMA';
import { useProjectContext } from '../context/ProjectContext';

type AvailableValue = (
  simulationpb.SpatialDiscretizationHeat |
  simulationpb.SpatialDiscretizationFluid
);

export const useEnforceNodalGradient = <T extends AvailableValue>({
  currentValue,
  setValue,
  enabled,
}: {
  currentValue?: T;
  setValue: (value: T) => Promise<void>;
  enabled: boolean
}) => {
  const { projectId, workflowId, jobId } = useProjectContext();

  const isLMA = useIsLMA({ projectId, workflowId, jobId });
  const isAllTetEnabled = useIsEnabled(flags.allTet);
  const enforceNodalGradient = isLMA && isAllTetEnabled && enabled;
  const enforceCompleted = useRef(false);

  useEffect(() => {
    if (!currentValue || enforceCompleted.current || !enforceNodalGradient) {
      return;
    }

    if (currentValue.gradientMethod !== simulationpb.GradientMethod.NODAL_GRADIENT) {
      enforceCompleted.current = true;

      const newProto = currentValue.clone() as T;
      newProto.gradientMethod = simulationpb.GradientMethod.NODAL_GRADIENT;
      setValue(newProto).catch(() => {});
    }
  }, [currentValue, setValue, enforceNodalGradient]);
};
