// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect, useMemo } from 'react';

import { SetterOrUpdater, atomFamily, useRecoilState } from 'recoil';

import objectId from './objectId';
import { debounce } from './utils';

export interface Size {
  height: number;
  width: number;
}

export interface ResizeObserverOptionalProps {
  name?: string,
  refreshPeriod?: number,
}

const sizeState = atomFamily<Size, string>({
  key: 'sizeState',
  default: { height: 0, width: 0 },
});

/**
   useResizeObserver wraps ResizeObserver so that it automatically follows
   the component lifecycle. "name" must be a unique identifier for the
   element. If name is left empty, the objectId of the ref will be used.

   Example: (props: {}) => {
     const ref = useRef();
     const size = useResizeObserver(ref, { name: example/div });
     // (size.width,size.height) is set to the size of the below div element.
     return (<div ref={ref}> ... </div>);
  }
  useResizeObserverState contains both a size and a way to set the size.
  useResizeObserver contains just the size.
*/
export const useResizeObserverState = (
  containerRef: React.RefObject<HTMLElement>,
  options: ResizeObserverOptionalProps,
): [Size, SetterOrUpdater<Size>] => {
  const componentName = options.name || `${objectId(containerRef, false)}`;
  const [size, setSize] = useRecoilState(sizeState(componentName));
  const onResize = () => {
    const containerElem = containerRef.current;
    if (containerElem) {
      setSize((oldValue) => {
        const height = containerElem.offsetHeight;
        const width = containerElem.offsetWidth;

        if (height !== oldValue.height || width !== oldValue.width) {
          return { height, width };
        }
        return oldValue;
      });
    }
  };
  // Debounce the onResize function. Instead of responding to every tiny size
  // change, wait a short interval, only resize if the function has not been
  // called again in that time.
  const debouncedOnResize = useMemo(
    () => debounce(onResize, options.refreshPeriod),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const containerElem = containerRef.current;
    if (!containerElem) {
      return () => null;
    }

    const observer = new ResizeObserver(debouncedOnResize);
    observer.observe(containerElem);
    return () => observer.unobserve(containerElem);
  }, [containerRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return [size, setSize];
};

const useResizeObserver = (
  containerRef: React.RefObject<HTMLElement>,
  options?: ResizeObserverOptionalProps,
) => useResizeObserverState(containerRef, options || {})[0];

export default useResizeObserver;
