// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { GroupedVirtuoso } from 'react-virtuoso';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import { isUnmodifiedEscapeKey } from '../../lib/event';
import { parseVolumeTree } from '../../lib/geometryUtils';
import { MOTION_FRAME_VOLUME_SUBSELECT_ID } from '../../lib/motionDataUtils';
import { clamp } from '../../lib/number';
import { GEOMETRY_TREE_DATA_LOCATOR, NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { VIEWER_PADDING } from '../../lib/visUtils';
import { usePanel } from '../../recoil/expandedPanels';
import { useGeoShowSurfaces } from '../../recoil/geometry/geoShowSurfaces';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useSetEntitySelection } from '../../recoil/selectionOptions';
import { useShowRowChildrenCountState } from '../../recoil/simulationTree/showRowChildrenCount';
import { useSimulationTreeSubselect } from '../../recoil/simulationTreeSubselect';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useAutoSelectSurfaces } from '../../state/internal/tree/autoSelectSurfaces';
import { useGeometryTree } from '../../state/internal/tree/section/geometry';
import { useVisHeightValue } from '../../state/internal/vis/visHeight';
import { IconButton } from '../Button/IconButton';
import Dropdown from '../Dropdown';
import { CollapsiblePanel } from '../Panel/CollapsiblePanel';
import { ROW_OUTER_HEIGHT } from '../Theme/commonStyles';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { LuminaryToggleSwitch } from '../controls/LuminaryToggleSwitch';
import { useGeometryMesh } from '../hooks/useGeometryMesh';
import { useNodeSorting } from '../hooks/useNodeSorting';
import { useTree } from '../hooks/useTree';
import { HorizontalCirclesTripleIcon } from '../svg/HorizontalCirclesTripleIcon';
import { SearchIcon } from '../svg/SearchIcon';
import { XIcon } from '../svg/XIcon';
import { AddGeometryButton } from '../treePanel/SimulationNodeAddButtons';
import { SimulationRowContainer } from '../treePanel/SimulationRowContainer';
import { useArrowKeyNav } from '../treePanel/useArrowKeyNav';

import { MIN_HEIGHT, TREE_VERTICAL_PADDING, useTreePanelStyles } from './treePanelShared';

import { SimulationRowProps } from '@/lib/componentTypes/simulationTree';
import { useDragSourceNode } from '@/recoil/lcvis/tagsDragAndDrop';

/**
 * Prefix used to denote special commands in the search functionality.
 * All commands must begin with this prefix to be recognized as such.
 */
const SEARCH_COMMAND_PREFIX = '@';

/**
 * A keyword used in the search filter to show only items that don't belong to any tags.
 * When this keyword is typed in the search filter, it filters the results to display
 * only untagged items.
 */
const UNTAGGED_ONLY_KEYWORD = `${SEARCH_COMMAND_PREFIX}untagged`;

/**
 * A list of keywords that trigger the rendering of a pill in the input field.
 * When a user types any of these keywords, a pill element is dynamically displayed
 * at the position where the keyword appears in the input.
 */
const HIGHLIGHTED_KEYWORDS = [UNTAGGED_ONLY_KEYWORD];

interface GeometryTreePanelProps {
  // The vertical space that's already taken by other cards in the LeftOverlayCards vertical column
  nonTreeCardsHeight: number;
}

/** Node types that stay pinned at the top when their children are shown. */
const PARENT_NODE_TYPES = [
  NodeType.GEOMETRY,
  NodeType.SURFACE_GROUP,
  NodeType.SURFACE_CONTAINER,
  NodeType.VOLUME_CONTAINER,
];

/** Node types that can be grouped under the parent nodes */
const GROUPABLE_NODE_TYPES = [
  NodeType.SURFACE,
  NodeType.TAGS_FACE,
  NodeType.VOLUME,
  NodeType.TAGS_BODY,
];

/** Grouped nodes can't have zero-size elements, so this fakes it by rendering a blank 1px space. */
const EmptyNode = () => <div style={{ height: '1px' }} />;

/**
 * We use `GroupedVirtuoso` to keep the parent element sticky.
 * According to the documentation, stickiness works for only one element at a time.
 * We use it in a way that ensures the parent of the topmost items in the list
 * is always displayed at the beginning.
 * This allows us to see where a surface or volume belongs (e.g., a group or tag).
 */
const List = GroupedVirtuoso;

/**
 * The GeometryTreePanel component is a card window that appears in the 3D viewer and includes the
 * the Geometry tree with all the tags and surfaces and volumes.
 * @returns CollapsiblePanel with the Geometry tree
 */
export const GeometryTreePanel = (props: GeometryTreePanelProps) => {
  // == Props
  const { nonTreeCardsHeight } = props;

  // == Context
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { isTreeModal, setSelection } = useSelectionContext();

  // == Hooks
  const classes = useTreePanelStyles();
  const { deleteGeometryNode } = useGeometryMesh();
  const dragSourceNode = useDragSourceNode();

  // == Recoil
  const simulationTreeSubselect = useSimulationTreeSubselect();
  const visHeight = useVisHeightValue();
  const geometryTree = useGeometryTree(projectId, workflowId, jobId);
  const [expanded, setExpanded] = usePanel({
    nodeId: `geometry-tree-${projectId}`,
    panelName: 'geometry-tree',
    defaultExpanded: true,
  });
  const [showRowChildren, setShowRowChildren] = useShowRowChildrenCountState();
  const getSortingItems = useNodeSorting();
  const isGeoPage = useIsGeometryView();
  const [showSurfaces, setShowSurfaces] = useGeoShowSurfaces(projectId);
  const setEntitySelection = useSetEntitySelection(projectId);
  const [selectedGeometry] = useSelectedGeometry(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();

  // == Data
  const [autoSelectSurfaces, setAutoSelectSurfaces] = useAutoSelectSurfaces(projectId);
  const [listContainerHeight, setListContainerHeight] = useState(MIN_HEIGHT);
  const [filter, setFilter] = useState<null | string>(null);
  const filterActive = filter !== null;
  const filterFilled = filterActive && filter !== '';
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const sortingItems = useMemo(() => getSortingItems(EntityGroupMap.rootId), [getSortingItems]);
  const isIgeoProject = !!selectedGeometry.geometryId;
  // we only show the delete geometry option if the project wasn't created with iGeo
  const showDeleteGeometry = !isIgeoProject && !isGeometryView;
  const motionFrameSelectionActive =
    simulationTreeSubselect.id === MOTION_FRAME_VOLUME_SUBSELECT_ID;
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  const volumeTree = useMemo(() => {
    const firstChildren = geometryTree.children.find(
      (node) => node.type !== NodeType.TAGS_CONTAINER,
    );

    if (!firstChildren) {
      return geometryTree;
    }
    return parseVolumeTree(geometryTree);
  }, [geometryTree]);

  const {
    listRef,
    rowProps,
  } = useTree(showSurfaces ? geometryTree : volumeTree, filterFilled);

  const assignedNodeIds = useMemo(() => (
    geometryTags.getAssignedNodeIds(geometryTags.tagIds(), false)
  ), [geometryTags]);

  // If the filter is non-empty, we'll keep only the nodes which name matches the filter and the
  // nodes that contains a children with a name that matches it (even if the parent is collapsed).
  const filteredRowProps = useMemo(() => {
    if (filter === null || filter === '') {
      return rowProps;
    }
    const filterString = filter.toLowerCase();

    const filterItem = (node: SimulationTreeNode): boolean => {
      if ([
        // We don't need the sub containers
        NodeType.SURFACE_CONTAINER,
        NodeType.VOLUME_CONTAINER,
        NodeType.TAGS_CONTAINER,
        NodeType.SURFACE_GROUP,

        // And repeated volumes/surfaces as tag children
        NodeType.TAGS_FACE,
        NodeType.TAGS_BODY,
      ].includes(node.type)) {
        return false;
      }

      if (filterString === UNTAGGED_ONLY_KEYWORD) {
        return (
          !assignedNodeIds.has(geometryTags.getCoreNodeIdentifier(node.id))
        );
      }

      const isInName = node.name.toLowerCase().includes(filterString);

      if (isInName) {
        return true;
      }

      // Keep the root containers for now (Geometry, Points, Contacts, etc)
      if (node.parent?.type === NodeType.ROOT_FLOATING_GEOMETRY) {
        return true;
      }
      return false;
    };

    return rowProps
      // Do the actual filter per name
      .filter((row) => filterItem(row.node))
      // Filter out the empty root containers
      .filter((row, idx, list) => {
        if (row.node.parent?.type === NodeType.ROOT_FLOATING_GEOMETRY) {
          // A root container row has depth of 0. If there is no row after it or if the row after it
          // has the same depth (0), that means the original container is empty.
          const next = list[idx + 1];
          if (!next || (next && row.depth === next.depth)) {
            return false;
          }
        }
        return true;
      });
  }, [assignedNodeIds, filter, geometryTags, rowProps]);

  const headingLabel = useMemo(() => {
    if (
      simulationTreeSubselect.visibleTreeNodeTypes.includes(NodeType.VOLUME_CONTAINER) &&
      simulationTreeSubselect.visibleTreeNodeTypes.includes(NodeType.SURFACE)
    ) {
      return 'Select Surfaces or Volumes';
    }

    if (simulationTreeSubselect.visibleTreeNodeTypes.includes(NodeType.SURFACE)) {
      return 'Select Surfaces';
    }
    if (simulationTreeSubselect.visibleTreeNodeTypes.includes(NodeType.VOLUME)) {
      return 'Select Volumes';
    }
    if (simulationTreeSubselect.visibleTreeNodeTypes.includes(NodeType.PARTICLE_GROUP)) {
      return 'Select Disks';
    }
    if (simulationTreeSubselect.visibleTreeNodeTypes.includes(NodeType.PROBE_POINT)) {
      return 'Select Points';
    }

    return 'Geometry';
  }, [simulationTreeSubselect.visibleTreeNodeTypes]);

  const handleKeyPress = useCallback((event) => {
    if (filterActive && isUnmodifiedEscapeKey(event)) {
      setFilter(null);
    }
  }, [filterActive, setFilter]);

  const handleShowSurfacesToggle = () => {
    if (!showSurfaces) {
      setShowSurfaces(true);
    } else {
      setShowSurfaces(false);
      setEntitySelection('volume');
    }
    setSelection([]);
  };

  // We are using the regular addEventListener because useEventListener doesn't work properly here
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // Listen to arrow keys for navigating in the geometry tree with keyboard shortcuts
  useArrowKeyNav(geometryTree, filteredRowProps, listRef);

  useEffect(() => {
    if (!showSurfaces) {
      setEntitySelection('volume');
    }
  }, [setEntitySelection, showSurfaces]);

  // Make sure the List's parent container has some reasonable height depending on the content
  useLayoutEffect(() => {
    if (!visHeight) {
      return;
    }

    // Calculate the available height
    let maxHeight = (
      visHeight - (
        // Remove the paddings around the edges of the 3D viewer
        2 * VIEWER_PADDING
      ) - (
        // Remove the collapsible header for the Geometry panel + internal padding around the list
        36 + 2 * TREE_VERTICAL_PADDING
      ) - (
        // Remove the height that's taken by other cards (RunStatus, Geometry Health, etc.)
        nonTreeCardsHeight
      ) - (
        // Account some space for the 3D axis in the bottom left
        250
      )
    );
    // We should put a hardcap of 70% from the 3D viewer's height
    maxHeight = Math.min(visHeight * 0.7, maxHeight);

    // Set the height depending on the amount of rows, but no more than the calculated limit
    setListContainerHeight(
      clamp(filteredRowProps.length * ROW_OUTER_HEIGHT, [MIN_HEIGHT, maxHeight]),
    );
  }, [filteredRowProps, nonTreeCardsHeight, visHeight, listContainerHeight]);

  const HeaderRight = () => {
    const menuItems: CommonMenuItem[] = [
      { title: 'SORT' },
      ...sortingItems,
      { separator: true },
      {
        label: showRowChildren ? 'Hide Group Count' : 'Show Group Count',
        onClick: () => setShowRowChildren((prev) => !prev),
      },
      { separator: true },
      {
        label: 'Show Untagged Only',
        onClick: () => {
          setFilter(UNTAGGED_ONLY_KEYWORD);
        },
      },
    ];

    if (showDeleteGeometry) {
      menuItems.push(
        { separator: true },
        {
          label: 'Delete Geometry',
          destructive: true,
          onClick: deleteGeometryNode,
          disabled: readOnly,
        },
      );
    }

    if (filterActive) {
      return (
        <IconButton onClick={() => setFilter(null)}>
          <XIcon maxHeight={8} />
        </IconButton>
      );
    }

    if (isTreeModal) {
      return <></>;
    }

    return (
      <>
        {!isGeometryView && <AddGeometryButton />}
        <Dropdown
          menuItems={menuItems}
          position="below-right"
          toggle={(
            <IconButton className={classes.iconButton}>
              <HorizontalCirclesTripleIcon maxWidth={12} />
            </IconButton>
          )}
        />
      </>
    );
  };

  /**
    * The map includes a list of parent elements (tree elements that need to be sticky).
    * An element is sticky if it contains children with types included in `GROUPABLE_NODE_TYPES`.
    */
  const rowsByParentId = useMemo(
    () => filteredRowProps.reduce((result, item) => {
      const parentId = item.node.parent?.id;

      if (
        parentId &&
        item.node.children.length === 0 &&
        GROUPABLE_NODE_TYPES.includes(item.node.type)
      ) {
        const existingItems = result.get(parentId) || [];
        result.set(parentId, [...existingItems, item]);
      }

      return result;
    }, new Map<string, SimulationRowProps[]>()),
    [filteredRowProps],
  );

  /**
   * The `groups` variable contains a list of nodes that should be sticky.
   * The trick with `flatMap` allows us to return only sticky nodes here
   * However, there are two caveats:
   * - an element that shouldn't belong to a group needs to be nested within a group
   * with an empty parent
   * - the parent node cannot be null-ish or a zero-sized element, so we render
   * the `EmptyNode` component instead.
   */
  const groups = useMemo(() => filteredRowProps.flatMap((item): {
    children: SimulationRowProps[];
    parent: SimulationRowProps | null
  }[] => {
    if (PARENT_NODE_TYPES.includes(item.node.type)) {
      const children = rowsByParentId.get(item.node.id) || [];

      if (children.length === 0) {
        return [{ children: [item], parent: null }];
      }

      return [{ children, parent: item }];
    }

    if (GROUPABLE_NODE_TYPES.includes(item.node.type)) {
      return [];
    }

    return [{ children: [item], parent: null }];
  }), [filteredRowProps, rowsByParentId]);

  /**
   * `GroupedVirtuoso` expects children to be provided as an indexed array,
   * containing children from all groups and accessed by index
   */
  const groupChildren = useMemo(() => groups.flatMap(({ children }) => children), [groups]);

  if (!geometryTree) {
    return (
      <></>
    );
  }

  // Render
  return (
    <div
      className={cx(classes.root, { inSelectionMode: isTreeModal })}
      data-locator="geometryTreePanel">
      <CollapsiblePanel
        allowHeaderOverflow={filterActive}
        collapsed={!expanded}
        disabled={filterActive}
        displayChevron={!dragSourceNode}
        expandWhenDisabled
        headerRight={dragSourceNode ? null : (
          <div className={classes.headerRight}>
            <HeaderRight />
          </div>
        )}
        heading={dragSourceNode ? 'Drag to Assign Tag' : (
          <div className={classes.heading}>
            <button
              className={classes.searchButton}
              onClick={(event) => {
                if (filterActive) {
                  setFilter(null);
                } else {
                  setFilter('');
                  requestAnimationFrame(() => {
                    searchInputRef.current?.focus();
                  });
                }
                // Clicking the icon should not trigger the parent CollapsiblePanel
                event.stopPropagation();
              }}
              type="button">
              <SearchIcon maxWidth={12} />
            </button>
            {filterActive ? (
              <div className={classes.searchInputWrapper}>
                {HIGHLIGHTED_KEYWORDS.includes(filter) && (
                  <div className={classes.searchPill}>{filter}</div>
                )}
                <input
                  className={classes.searchInput}
                  onChange={(event) => setFilter(event.target.value)}
                  // Clicking over the input should not trigger the parent CollapsiblePanel
                  onClick={(event) => event.stopPropagation()}
                  placeholder="Find..."
                  ref={searchInputRef}
                  type="text"
                  value={filter}
                />
              </div>
            ) : headingLabel}
          </div>
        )}
        onToggle={() => setExpanded(!expanded)}
        primaryHeading>
        <>
          <div className={classes.content}>
            {!filteredRowProps.length ? (
              <div className={classes.noResults}>No results</div>
            ) : (
              <div
                className={classes.list}
                data-locator={GEOMETRY_TREE_DATA_LOCATOR}
                style={{ height: listContainerHeight }}>
                <List
                  defaultItemHeight={ROW_OUTER_HEIGHT} // not necessary, but helps performance
                  groupContent={
                    (index) => {
                      const groupItem = groups.at(index);

                      if (!groupItem || groupItem.parent === null) {
                        return <EmptyNode key={index} />;
                      }

                      return (
                        <SimulationRowContainer
                          {...groupItem.parent}
                          disableToggle={filterFilled}
                          key={groupItem.parent.node.id}
                        />
                      );
                    }
                  }
                  groupCounts={groups.map((group) => group.children.length)}
                  itemContent={(index) => {
                    const item = groupChildren.at(index);

                    if (!item) {
                      return <EmptyNode key={index} />;
                    }

                    return (
                      <SimulationRowContainer
                        {...item}
                        disableToggle={filterFilled}
                        key={item.node.id}
                      />
                    );
                  }}
                  ref={listRef}
                />
              </div>
            )}
          </div>
          {motionFrameSelectionActive && (
            <div
              className={classes.treeFooterWithToggle}
              data-locator="autoSelectBoundingSurface">
              Auto-select bounding surfaces
              <LuminaryToggleSwitch
                onChange={(setAutoSelectSurfaces)}
                small
                value={autoSelectSurfaces}
              />
            </div>
          )}
          {isGeoPage && (
            <div
              className={classes.treeFooterWithToggle}
              data-locator="showSurfaces">
              Show surfaces
              <LuminaryToggleSwitch
                onChange={handleShowSurfacesToggle}
                small
                value={showSurfaces}
              />
            </div>
          )}

        </>
      </CollapsiblePanel>
    </div>
  );
};
