// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useProjectContext } from '../../components/context/ProjectContext';
import { applyTransparency, setTransparentOpacity } from '../../lib/lcvis/api';
import { useMeshUrlState } from '../meshState';
import { useActiveVisUrlValue } from '../vis/activeVisUrl';

export const GHOST_MODE_OPACITY = 0.2; // The opacity of transparent surfaces in Ghost mode
export const DEFAULT_OPACITY = 0.5; // The default opacity of transparent surfaces in LCVis

export type TransparencySettings = {
  active: boolean;
  surfaces: Set<string>;
  invert: boolean;
}

export const defaultTransparencySettings = (): TransparencySettings => ({
  active: false,
  surfaces: new Set<string>(),
  invert: false,
});

export const TRANSPARENCY_MODE_SUBTITLE = `
  While in Transparency Mode, the specified surfaces are see-through and can't be interacted with.
`;

export const transparencySettingsState = atom<TransparencySettings>({
  key: 'transparencySettings',
  default: defaultTransparencySettings(),
  effects: [({ onSet, setSelf }) => {
    onSet((newVal, oldVal) => {
      const toMakeTransparent = newVal.surfaces;
      applyTransparency(toMakeTransparent, newVal.invert);

      if (!newVal.surfaces.size || !newVal.active) {
        setTransparentOpacity(DEFAULT_OPACITY);
      } else {
        setTransparentOpacity(GHOST_MODE_OPACITY);
      }

      if (!newVal.active) {
        setSelf(defaultTransparencySettings());
      }
    });
  }],
});

export const useTransparencySettings = () => useRecoilState(transparencySettingsState);
export const useSetTransparencySettings = () => useSetRecoilState(transparencySettingsState);
export const useTransparencySettingsValue = () => useRecoilValue(transparencySettingsState);

/**
 * Handles resetting the transparency settings whenever the user navigates away from the current
 * LCVis view.
 */
export const useResetTransparency = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const setTransparencySettings = useSetTransparencySettings();
  const activeVisUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const [meshUrlState] = useMeshUrlState(projectId);

  useEffect(() => () => {
    setTransparencySettings(defaultTransparencySettings());
  }, [setTransparencySettings, activeVisUrl, meshUrlState.activeType]);
};
