// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo } from 'react';

import cx from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { CurrentView } from '../../lib/componentTypes/context';
import { projectLink } from '../../lib/navigation';
import { useEditStateValue } from '../../recoil/paraviewState';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useControlPanelModeValue } from '../../recoil/useProjectPage';
import { TreeViewType, useTreeViewType } from '../../recoil/useTreeViewState';
import { useCurrentView, useIsExplorationSetup } from '../../state/internal/global/currentView';
import { IconButton } from '../Button/IconButton';
import { DataSelect } from '../Form/DataSelect';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { useProjectContext } from '../context/ProjectContext';
import { useCancelEdit } from '../hooks/useCancelEdit';
import { DuplicateIcon } from '../svg/DuplicateIcon';
import SimulationTreeDeleteButton from '../treePanel/SimulationTreeDeleteButton';
import SimulationTreeMoreMenu from '../treePanel/SimulationTreeMoreMenu';

import { isAdjointSimulation } from '@/lib/simulationParamUtils';
import { useSimulationParam } from '@/state/external/project/simulation/param';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 0,
      // If shrinking is not prevented, the container will change in height when in the simulation
      // tab due to the rightPane's fullWidth
      flexShrink: 0,
    },
    leftControls: {
      paddingLeft: '8px',

      // In the Setup page, element must grow so that the Tabs' bottom border contain the full width
      '&.fullWidth': {
        paddingLeft: 0,
        flex: 1,
        zIndex: 1,
      },
    },
    rightControls: {
      zIndex: 2,
      display: 'flex',
      gap: '4px',
      position: 'absolute',
      right: '8px',
    },
  }),
  { name: 'RightPaneTopControls' },
);

interface RightPaneTopControlsProps {
  height: number;
}

const RightPaneTopControls = (props: RightPaneTopControlsProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const controlPanelMode = useControlPanelModeValue();
  const { projectId, workflowId, jobId, copyToSetup } = useProjectContext();
  const currentView = useCurrentView();
  const [treeViewType, setTreeViewType] = useTreeViewType();
  const editState = useEditStateValue();
  const cancelEdit = useCancelEdit();
  const isExplorationSetup = useIsExplorationSetup();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const [selectedGeometrySetupPage] = useSelectedGeometry(projectId, '', '');
  const [selectedGeometry] = useSelectedGeometry(projectId, workflowId, jobId);
  const differentGeometryVersion = selectedGeometrySetupPage.geometryVersionId !==
    selectedGeometry.geometryVersionId;

  const setupView = (currentView === CurrentView.SETUP);
  const analysisView = (currentView === CurrentView.ANALYSIS);

  const showCopyToSetupButton = (
    analysisView &&
    treeViewType === TreeViewType.SETUP &&
    controlPanelMode !== 'exploration' && !differentGeometryVersion
  );
  const copyToSetupDisabledReason = useMemo(() => {
    if (isAdjointSimulation(simParam)) {
      return `Copy to Setup is not possible from an existing Adjoint result.`;
    }
    if (differentGeometryVersion) {
      return `Copy to Setup is not possible when the topology in the setup tab is different.`;
    }
    return undefined;
  }, [differentGeometryVersion, simParam]);

  useEffect(() => {
    setTreeViewType(TreeViewType.POST_PROCESSING);
  }, [location, setTreeViewType]);

  return (
    <div className={classes.root} style={{ height: props.height }}>
      <div className={cx(classes.leftControls, { fullWidth: setupView })}>
        {analysisView && !isExplorationSetup && (
          <DataSelect
            asBlock
            kind="minimal"
            locator="treeViewTypeDropdown"
            onChange={(mode) => {
              // Make sure we don't have an active edit state when switching to the Setup view
              // or the app will break because the Setup tree doesn't show the Visualization nodes
              if (mode === TreeViewType.SETUP && editState) {
                cancelEdit();
              }
              setTreeViewType(mode);
            }}
            options={[
              {
                value: TreeViewType.POST_PROCESSING,
                name: 'Post-processing',
                selected: treeViewType === TreeViewType.POST_PROCESSING,
              },
              {
                value: TreeViewType.SETUP,
                name: 'Setup Details',
                selected: treeViewType === TreeViewType.SETUP,
              },
            ]}
            size="small"
          />
        )}
      </div>
      <div className={classes.rightControls}>

        <SimulationTreeDeleteButton />
        {showCopyToSetupButton && (
          <Tooltip title={copyToSetupDisabledReason ?? 'Copy to Setup'}>
            <span>
              <IconButton
                disabled={!!copyToSetupDisabledReason}
                name="copyToSetup"
                onClick={async (event) => {
                  event.stopPropagation();
                  await copyToSetup();
                  navigate(projectLink(projectId));
                }}>
                <DuplicateIcon maxHeight={12} />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <SimulationTreeMoreMenu />
      </div>
    </div>
  );
};

export default RightPaneTopControls;
