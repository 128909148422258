// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { assignDomainsToMaterial } from '../../lib/entityRelationships';
import { applyFluidMaterialPreset } from '../../lib/fluidMaterialPresets';
import { getMaterialId } from '../../lib/materialUtils';
import { findPhysicsByDomain } from '../../lib/physicsUtils';
import { applySolidMaterialPreset } from '../../lib/solidMaterialPresets';
import { mapDomainsToIds, mapIdsToDomains } from '../../lib/volumeUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useSimulationMaterialsMap } from '../../state/external/project/simulation/param/materials';

import { useMaterials } from './useMaterials';
import { useWorkflowConfig } from './useWorkflowConfig';

/**
 * Model hook for managing an individual material entity (identified by ID)
 */
export const useMaterialEntity = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string,
) => {
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const materialDataMap = useSimulationMaterialsMap(projectId, workflowId, jobId);

  const { isFixedPreset } = useMaterials(projectId, workflowId, jobId, readOnly);
  const { saveParam, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  const materialEntity = materialDataMap[id]?.model;

  const fluid = useMemo(() => {
    if (materialEntity?.material.case === 'materialFluid') {
      return materialEntity.material.value;
    }
    return null;
  }, [materialEntity]);

  const solid = useMemo(() => {
    if (materialEntity?.material.case === 'materialSolid') {
      return materialEntity.material.value;
    }
    return null;
  }, [materialEntity]);

  // Respond to changes in the material properties. For example, if "Standard Air" is selected, fill
  // in the properties with the standard values for air.
  const updateFluidPreset = useCallback((value: number) => {
    saveParam((newParam) => {
      const newMaterialEntity = newParam.materialEntity.find((item) => getMaterialId(item) === id);
      if (newMaterialEntity?.material.case === 'materialFluid') {
        newMaterialEntity.material.value.materialFluidPreset = value;
        applyFluidMaterialPreset(newMaterialEntity.material.value);
      }
    });
  }, [id, saveParam]);

  // Save a new fluid material configuration for the given ID
  const saveFluidMaterial = useCallback((newFluid: simulationpb.MaterialFluid) => {
    saveParam((newParam) => {
      newParam.materialEntity = newParam.materialEntity.map((entity) => {
        const entityId = getMaterialId(entity);
        if (entity.material.case === 'materialFluid' && entityId === id) {
          entity.material.value = newFluid;
        }
        return entity;
      });
    });
  }, [id, saveParam]);

  // Save a new solid material configuration for the given ID
  const saveSolidMaterial = useCallback((newSolid: simulationpb.MaterialSolid) => {
    saveParam((newParam) => {
      newParam.materialEntity = newParam.materialEntity.map((entity) => {
        const entityId = getMaterialId(entity);
        if (entity.material.case === 'materialSolid' && entityId === id) {
          entity.material.value = newSolid;
        }
        return entity;
      });
    });
  }, [id, saveParam]);

  // Return true if the material's preset is one of the fixed presets that prescribe material
  // field values (and which should only be used once per project).
  const hasFixedPreset = useMemo(() => {
    const presetChoice = fluid?.materialFluidPreset || solid?.materialSolidPreset;
    return !!presetChoice && isFixedPreset(presetChoice);
  }, [fluid, isFixedPreset, solid]);

  // Respond to changes in the material properties. For example, if "Aluminum" is selected, fill
  // in the properties with the standard values for aluminum.
  const updateSolidPreset = useCallback((value: number) => {
    saveParam((newParam) => {
      const newMaterialEntity = newParam.materialEntity.find((item) => getMaterialId(item) === id);
      if (newMaterialEntity?.material.case === 'materialSolid') {
        newMaterialEntity.material.value.materialSolidPreset = value;
        applySolidMaterialPreset(newMaterialEntity.material.value);
      }
    });
  }, [id, saveParam]);

  // A Set of domains assigned to the material
  const assignedDomains = useMemo(() => {
    const rels = simParam.entityRelationships?.volumeMaterialRelationship || [];
    const domains = rels.reduce((result, rel) => {
      const materialId = rel.materialIdentifier?.id;
      const domain = rel.volumeIdentifier?.id;
      if (domain && materialId === id) {
        result.add(domain);
      }
      return result;
    }, new Set<string>());

    return [...domains];
  }, [id, simParam]);

  // A list of volume node IDs that map to the assignedDomains Set
  const assignedVolumeNodeIds = useMemo(
    () => {
      const regularDomains = new Set(mapDomainsToIds(staticVolumes, assignedDomains));
      assignedDomains.forEach((domain) => {
        const tag = geometryTags.domainsFromTag(domain);
        if (tag.length) {
          regularDomains.add(domain);
        }
      });
      return Array.from(regularDomains);
    },
    [assignedDomains, staticVolumes, geometryTags],
  );

  // Update the volume IDs assigned to the material and save the updated param
  const setAssignedVolumeNodeIds = useCallback((volumeIds: string[]) => {
    saveParam((newParam) => {
      const domains = volumeIds.map((volumeId) => {
        const tag = geometryTags.domainsFromTag(volumeId);
        if (tag.length) {
          return volumeId;
        }
        return mapIdsToDomains(staticVolumes, [volumeId])[0];
      });
      assignDomainsToMaterial(newParam, new Set(domains.filter((domain) => !!domain)), id);
    });
  }, [id, staticVolumes, saveParam, geometryTags]);

  const relatedPhysics = useMemo(() => {
    const result: simulationpb.Physics[] = [];

    assignedDomains.forEach((domain) => {
      const physics = findPhysicsByDomain(simParam, domain, geometryTags);
      if (physics) {
        result.push(physics);
      }
    });

    return result;
  }, [geometryTags, assignedDomains, simParam]);

  const deletionDisabledReason = useMemo(() => {
    if (!readOnly && assignedDomains.length && relatedPhysics.length) {
      return 'Material is assigned to one or more volumes that are assigned to physics';
    }

    return '';
  }, [assignedDomains, readOnly, relatedPhysics]);

  return {
    materialEntity,
    fluid,
    solid,

    updateFluidPreset,
    saveFluidMaterial,
    updateSolidPreset,
    saveSolidMaterial,
    hasFixedPreset,

    assignedVolumeNodeIds,
    setAssignedVolumeNodeIds,

    deletionDisabledReason,
  };
};
