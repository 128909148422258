// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';

import { ColumnId, ColumnLabel, ColumnState } from '../../../lib/componentTypes/table';
import { colors } from '../../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../../lib/event';
import { matchSearchTerms, parseSearchString } from '../../../lib/searchText';
import { camelCaseToWords, toCamelCase } from '../../../lib/text';
import { ColumnOpsRecord, useSetDataTable } from '../../../state/internal/component/dataTable';
import { IconButton } from '../../Button/IconButton';
import Dropdown from '../../Dropdown';
import Form from '../../Form';
import { createStyles, makeStyles } from '../../Theme';
import Tooltip from '../../Tooltip';
import { SearchBox } from '../../controls/SearchBox';
import { Dialog } from '../../dialog/Base';
import { ChevronDownIcon } from '../../svg/ChevronDownIcon';
import { GearIcon } from '../../svg/GearIcon';
import { HorizontalCirclesTripleIcon } from '../../svg/HorizontalCirclesTripleIcon';
import { ReorderableItem, ReorderableList } from '../../visual/ReorderableList';
import { TooltippedText } from '../../visual/TooltippedText';

import { TableContext } from './context';
import { labelToLines } from './util';

const LIST_HEIGHT = 400;

export const useStyles = makeStyles(
  () => createStyles({
    dialogContent: {
      display: 'flex',
      gap: '16px',
      fontSize: '13px',
      height: `${LIST_HEIGHT}px`,

      // left/right columns
      '& > div': {
        display: 'flex',
        minWidth: '280px',
        maxWidth: '280px',
        flexDirection: 'column',
      },
    },

    // left column content
    allColumnsSection: {
      padding: '8px 16px',
      minHeight: `${LIST_HEIGHT}px`,
      gap: '8px',
      borderRadius: '4px',
      background: colors.neutral100,
      boxShadow: `
        0px -1px 0px 0px ${colors.neutral300} inset,
        0px 1px 4px 0px rgba(24, 25, 30, 0.10) inset`
      ,
    },
    allColumnsList: {
      overflowY: 'auto',
    },
    customizeRow: {
      display: 'flex',
      gap: '4px',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    groupFilter: {
      display: 'flex',
      gap: '6px',
      alignItems: 'center',
      cursor: 'pointer',
    },
    groupLabel: {
      color: colors.neutral750,
      fontWeight: 'bold',
      height: '28px',
      lineHeight: '28px',

      '&:not(:first-child)': {
        marginTop: '4px',
      },
    },
    allColumnsItem: {
      display: 'flex',
      height: '28px',
      padding: '4px',
      alignItems: 'center',
      gap: '8px',

      '&.nested': {
        marginLeft: '20px',
      },
    },

    // right column content
    selectedColumnsSection: {
      gap: '8px',
      padding: '8px 0 8px 16px',
    },
    empty: {
      padding: '16px 8px',
      background: colors.neutral250,
      textAlign: 'center',
      color: colors.lowEmphasisText,
    },
  }),
  { name: 'TableSettings' },
);

function getColumnLabelLines(id: ColumnId, label?: ColumnLabel): string[] {
  if (label) {
    if (Array.isArray(label)) {
      if (label.some((item) => item.trim())) {
        return labelToLines(label);
      }
    } else if (label.trim()) {
      return labelToLines(label);
    }
  }
  return labelToLines(camelCaseToWords(toCamelCase(id)));
}

function getColumnDisabledReason(column: ColumnState) {
  if (column.config.disableVisibility) {
    return 'Column may not be hidden';
  }

  return '';
}

interface TableSettingsContentProps {
  disableColumnSettings: boolean | undefined;
  showControlColumn: boolean | undefined;
  allColumns: ColumnState[];
  resetColumnVisibility?: () => void;
  name: string;
  rawColumnIds: string[];
}

const TableSettingsContent = (props: TableSettingsContentProps) => {
  // == Props
  const {
    disableColumnSettings,
    showControlColumn,
    allColumns: savedColumns,
    resetColumnVisibility,
    name,
    rawColumnIds,
  } = props;

  // == Hooks
  const classes = useStyles();

  // == State
  const setTableState = useSetDataTable(name);

  // == Local State
  const controlRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(new Set<string>());
  const [groupFilter, setGroupFilter] = useState('');
  const [searchText, setSearchText] = useState('');

  // == Memoized State
  const toggleItem = useCallback((id: string) => {
    setSelected((oldValue) => {
      const newSelected = new Set(oldValue);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  }, []);

  const disabledContinueReason = useMemo(() => {
    if (!selected.size) {
      return 'At least one column must be selected';
    }
    return '';
  }, [selected.size]);

  // We can't directly use the savedColumns (props.allColumns) because it comes in a
  // sorted order where the visible columns are shown first and the hidden - last.
  // And since we are building a custom selection panel, we want the section with the allColumns
  // to be fixed so that the order of the columns there don't change depending on the visibility -
  // we only want the order of the "selected" section to change. That's why we use the rawColumnIds
  // to figure out the original order for displaying the all columns section.
  const allColumns = useMemo(
    () => rawColumnIds.reduce((acc, columnId) => {
      const column = savedColumns.find((col) => col.config.id === columnId);
      if (column) {
        acc.push(column);
      }
      return acc;
    }, [] as ColumnState[]),
    [rawColumnIds, savedColumns],
  );

  // Keep unique groups and superlabels (if there are any) so we can group the items in the list
  const groups = useMemo(
    () => [...allColumns.reduce((acc, item) => {
      if (item.config.group) {
        acc.add(item.config.group);
      }
      return acc;
    }, new Set<string>())],
    [allColumns],
  );

  const superLabels = useMemo(
    () => [...allColumns.reduce((acc, item) => {
      if (item.config.superLabel) {
        acc.add(item.config.superLabel);
      }
      return acc;
    }, new Set<string>())],
    [allColumns],
  );

  const columsPerGroupFilter = useMemo(
    () => (groupFilter ? allColumns.filter((col) => col.config.group === groupFilter) : allColumns),
    [allColumns, groupFilter],
  );

  const filteredColumns = useMemo(
    () => {
      const searchTerms = parseSearchString(searchText);
      if (searchTerms.length) {
        return columsPerGroupFilter.filter((column) => {
          const cfg = column.config;
          const keywords = [cfg.id];
          if (cfg.label) {
            keywords.push(Array.isArray(cfg.label) ? cfg.label.join(' ') : cfg.label);
          }
          if (cfg.superLabel) {
            keywords.push(cfg.superLabel);
          }

          return (matchSearchTerms(searchTerms, keywords));
        });
      }

      return columsPerGroupFilter;
    },
    [columsPerGroupFilter, searchText],
  );

  // This will be passed for the component that can reorder the items
  const reorderableItems = useMemo(() => [...selected].reduce((acc, columnId) => {
    const column = savedColumns.find((col) => col.config.id === columnId);
    if (column) {
      const { config: { id, label, superLabel } } = column;

      const labelContent = getColumnLabelLines(id, label).join(' ');
      const fullLabel = `${labelContent}${superLabel ? ` (${superLabel})` : ''}`;
      acc.push({
        id: columnId,
        label: fullLabel,
        menuItems: [{
          label: 'Deselect',
          onClick: () => toggleItem(id),
        }],
      });
    }
    return acc;
  }, [] as ReorderableItem[]), [selected, savedColumns, toggleItem]);

  const onReorderItems = useCallback((newList) => {
    setSelected(new Set(newList.map((item: ReorderableItem) => item.id)));
  }, []);

  // == Handlers
  const handleContinue = () => {
    setTableState((oldValue) => {
      const selectedArray = [...selected];
      let unselectedOrderIndex = selected.size;

      return {
        ...oldValue,
        columns: allColumns.reduce((result, column, idx) => {
          const columnId = column.config.id;

          if (!selected.has(columnId)) {
            unselectedOrderIndex += 1;
          }

          result[columnId] = {
            hidden: !selected.has(columnId),
            // If the column is in the selected set, use its index from the selected array.
            // Otherwise use the incremented sort variable for the unselected columns.
            order: selected.has(columnId) ? selectedArray.indexOf(columnId) : unselectedOrderIndex,
            width: oldValue.columns[columnId]?.width ?? column.width,
          };

          return result;
        }, {} as ColumnOpsRecord),
      };
    });
    setOpen(false);
  };

  // == Effects
  // Opening the dialog should reset the search/group filters
  useEffect(() => {
    if (open) {
      setGroupFilter('');
      setSearchText('');
    }
  }, [open]);

  // Sometimes not all columns are loaded on page load (e.g. the Outputs in the Results page load
  // slower) so we have to make sure the `selected` state is updated if the colums config changes.
  useEffect(() => {
    if (open) {
      setSelected(new Set(savedColumns.filter((col) => !col.hidden).map((col) => col.config.id)));
    }
  }, [open, savedColumns]);

  const renderColumn = (column: ColumnState) => {
    const { config: { id, label, superLabel } } = column;

    const labelContent = getColumnLabelLines(id, label).join(' ');
    const disabledReason = getColumnDisabledReason(column);

    const handleClick = (
      event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
    ) => {
      event.stopPropagation();
      if (!disabledReason) {
        toggleItem(id);
      }
    };

    return (
      <Tooltip
        key={id}
        title={disabledReason}>
        <div
          className={cx(classes.allColumnsItem, { disabled: !!disabledReason, nested: superLabel })}
          onClick={(event) => {
            handleClick(event);
          }}
          onKeyUp={(event) => {
            if (isUnmodifiedSpaceKey(event)) {
              handleClick(event);
            }
          }}
          role="button"
          tabIndex={0}>
          <div>
            <Form.CheckBox
              checked={selected.has(id)}
              disabled={!!disabledReason}
              onChange={() => { }}
            />
          </div>
          <TooltippedText text={labelContent} />
        </div>
      </Tooltip>
    );
  };

  const renderSuperLabel = (superLabel: string) => {
    const superLabelCols = filteredColumns.filter((col) => superLabel === col.config.superLabel);

    const every = superLabelCols.every((col) => selected.has(col.config.id));
    const some = !every && superLabelCols.some((col) => selected.has(col.config.id));

    const handleClick = (
      event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
    ) => {
      event.stopPropagation();
      setSelected((oldValue) => {
        const newSelected = new Set(oldValue);
        if (every) {
          superLabelCols.forEach((col) => {
            if (!col.config.disableVisibility) {
              newSelected.delete(col.config.id);
            }
          });
        } else {
          superLabelCols.forEach((col) => newSelected.add(col.config.id));
        }
        return newSelected;
      });
    };

    return (
      <div
        className={cx(classes.allColumnsItem)}
        onClick={(event) => {
          handleClick(event);
        }}
        onKeyUp={(event) => {
          if (isUnmodifiedSpaceKey(event)) {
            handleClick(event);
          }
        }}
        role="button"
        tabIndex={0}>
        <div>
          <Form.CheckBox
            checked={every}
            indeterminate={some}
            onChange={() => { }}
          />
        </div>
        <TooltippedText text={superLabel} />
      </div>
    );
  };

  const renderGroup = (columnsPerGroup: ColumnState[]) => {
    const columnsWithoutSuperLabel = columnsPerGroup.filter(
      (column) => !column.config.superLabel,
    ).map(renderColumn);

    const columnsWithSuperLabel = superLabels.map((superLabel) => {
      const columnsPerSuperLabel = columnsPerGroup.filter(
        (col) => col.config.superLabel === superLabel,
      );

      if (columnsPerSuperLabel.length) {
        return (
          <React.Fragment key={superLabel}>
            {renderSuperLabel(superLabel)}
            {columnsPerSuperLabel.map(renderColumn)}
          </React.Fragment>
        );
      }

      return null;
    });

    return [
      ...columnsWithoutSuperLabel,
      ...columnsWithSuperLabel,
    ];
  };

  if (disableColumnSettings || !showControlColumn) {
    return null;
  }

  return (
    <div>
      <Tooltip title={open ? '' : 'Table settings'}>
        <IconButton
          onClick={(event: React.MouseEvent) => {
            setOpen((oldValue) => !oldValue);
          }}
          ref={controlRef}>
          <GearIcon maxHeight={16} maxWidth={16} />
        </IconButton>
      </Tooltip>
      <Dialog
        cancelButton={{ label: 'Cancel' }}
        continueButton={{
          label: 'Apply',
          disabled: !!disabledContinueReason,
          help: disabledContinueReason,
        }}
        modal
        onClose={() => setOpen(false)}
        onContinue={handleContinue}
        open={open}
        subtitle="Select fields to display as columns"
        tertiaryButtons={[
          {
            key: 'reset',
            label: 'Restore Defaults',
            onClick: () => {
              resetColumnVisibility?.();
              setOpen(false);
            },
          },
        ]}
        title="Customize Table"
        width="640">
        <div className={classes.dialogContent}>
          <div className={classes.allColumnsSection}>
            <div className={classes.customizeRow}>
              <div>
                {groups.length ? (
                  <Dropdown
                    menuItems={[
                      {
                        label: 'All Fields',
                        onClick: () => setGroupFilter(''),
                        selected: groupFilter === '',
                      },
                      ...groups.map((columnGroup) => ({
                        label: columnGroup,
                        selected: groupFilter === columnGroup,
                        onClick: () => setGroupFilter(columnGroup),
                      })),
                    ]}
                    position="below-right"
                    toggle={(
                      <div className={classes.groupFilter}>
                        <div>{groupFilter || 'All Fields'}</div>
                        <ChevronDownIcon maxWidth={8} />
                      </div>
                  )}
                  />
                ) : 'All Fields'}
              </div>
              <div>
                <Dropdown
                  menuItems={[
                    {
                      label: 'Select All',
                      disabled: selected.size === columsPerGroupFilter.length,
                      onClick: () => {
                        setSelected((prevSelected) => new Set([
                          ...prevSelected,
                          ...columsPerGroupFilter.map((col) => col.config.id),
                        ]));
                      },
                    },
                    {
                      label: 'Deselect All',
                      disabled: selected.size === 0,
                      onClick: () => {
                        setSelected((prevSelected) => {
                          const newValue = new Set([...prevSelected]);
                          columsPerGroupFilter.forEach((column) => {
                            if (!column?.config.disableVisibility) {
                              newValue.delete(column.config.id);
                            }
                          });
                          return newValue;
                        });
                      },
                    },
                  ]}
                  position="below-right"
                  toggle={(
                    <IconButton>
                      <HorizontalCirclesTripleIcon maxWidth={12} />
                    </IconButton>
                  )}
                />
              </div>
            </div>
            <div>
              <SearchBox
                decorated
                onChange={setSearchText}
                onClear={() => {}}
                onEscape={() => {}}
                value={searchText}
              />
            </div>
            <div className={classes.allColumnsList}>
              {groups.length || groupFilter ? (
                groups.map((group) => {
                  const columnsPerGroup = filteredColumns.filter(
                    (col) => col.config.group === group,
                  );

                  if (columnsPerGroup.length) {
                    return (
                      <React.Fragment key={group}>
                        {!groupFilter && <div className={classes.groupLabel}>{group}</div>}
                        {renderGroup(columnsPerGroup)}
                      </React.Fragment>
                    );
                  }

                  return null;
                })
              ) : renderGroup(filteredColumns)}
            </div>
          </div>
          <div className={classes.selectedColumnsSection}>
            <b>Selected Fields</b>
            <ReorderableList
              items={reorderableItems}
              setItems={onReorderItems}
            />
            {!selected.size && (
            <div className={classes.empty}>
              No selected items
            </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

interface TableSettingsProps {
  name: string;
}

export const TableSettings = (props: TableSettingsProps) => (
  <TableContext.Consumer>
    {(context) => (
      <TableSettingsContent
        allColumns={context.allColumns ?? []}
        disableColumnSettings={context.disableColumnSettings}
        name={props.name}
        rawColumnIds={context.rawColumnIds ?? []}
        resetColumnVisibility={context.resetColumnVisibility}
        showControlColumn={context.showControlColumn}
      />
    )}
  </TableContext.Consumer>
);
