// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as flags from '../../../flags';
import { colors } from '../../../lib/designSystem';
import { defaultBoundingBox, meshAggregateStats } from '../../../lib/mesh';
import { useGeometryDetails } from '../../../recoil/geometry/geometryDetailsState';
import { useGeometryList } from '../../../recoil/geometry/geometryListState';
import { useMeshMetadata } from '../../../recoil/meshState';
import { useSelectedGeometry } from '../../../recoil/selectedGeometry';
import { useCadMetadata } from '../../../recoil/useCadMetadata';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import { useInputFilename } from '../../../recoil/useInputFilename';
import { useActiveVisUrlValue } from '../../../recoil/vis/activeVisUrl';
import { CardButton } from '../../Button/CardButton';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import { useProjectContext } from '../../context/ProjectContext';
import PropertiesSection from '../PropertiesSection';

import { MeshOrSurfacePanel } from './shared/MeshOrSurfacePanel';

function makeSubtitle(faces: number, volumes: number) {
  return `${volumes} volumes, ${faces} faces`;
}

// A panel for displaying info about the geometry.
export const GeometryPropPanel = () => {
  const { projectId, workflowId, jobId } = useProjectContext();

  const [cadMetadata] = useCadMetadata(projectId);
  const inputFileName = useInputFilename(projectId);
  const paraviewActiveUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const meshMetadata = useMeshMetadata(projectId, paraviewActiveUrl);
  const geoModEnabled = useIsEnabled(flags.geoModifications);
  const geometryList = useGeometryList(projectId);
  const geoDetails = useGeometryDetails(geometryList?.geometries[0]?.id || '');
  const [selectedGeometry] = useSelectedGeometry(projectId);

  const isInteractiveGeometryFile = !!selectedGeometry?.geometryId;

  let boundingBox = defaultBoundingBox();
  const cadMin = cadMetadata.boundingBox?.min;
  const cadMax = cadMetadata.boundingBox?.max;
  if (cadMin && cadMax) {
    boundingBox.min.x = cadMin.x;
    boundingBox.min.y = cadMin.y;
    boundingBox.min.z = cadMin.z;
    boundingBox.max.x = cadMax.x;
    boundingBox.max.y = cadMax.y;
    boundingBox.max.z = cadMax.z;
  } else {
    boundingBox = meshAggregateStats(meshMetadata?.meshMetadata).boundingBoxes.mesh || boundingBox;
  }
  const fileInfo = (
    <div>
      {(geoModEnabled && isInteractiveGeometryFile) && (
        <PropertiesSection>
          <CardButton
            compact
            editableDisabled
            flat
            icon={{ maxHeight: 12, name: 'cubeSolid', color: colors.highEmphasisText }}
            subtitle={makeSubtitle(cadMetadata.nFaces, cadMetadata.nBodies)}
            title={geoDetails?.geometry?.name || 'Imported Geometry'}
          />
        </PropertiesSection>
      )}
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Geometry Info"
          nodeId="parent-geometry"
          panelName="geometry-info"
          panelOptions={{ defaultExpanded: !isInteractiveGeometryFile }}>
          <MeshOrSurfacePanel
            fileName={inputFileName.name}
            maxBoundingBox={boundingBox.max}
            minBoundingBox={boundingBox.min}
            numBodies={cadMetadata.nBodies}
            numEdges={cadMetadata.nEdges}
            numFaces={cadMetadata.nFaces}
            numPoints={cadMetadata.nVertices}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );

  return fileInfo;
};
