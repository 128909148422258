// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { unstable_batchedUpdates } from 'react-dom';
import { CallbackInterface, useRecoilCallback } from 'recoil';

import { asyncWrapper } from '../../lib/contextUtils';
import { expandGroups } from '../../lib/entityGroupUtils';
import { geomHealthNodeIdtoIndex } from '../../lib/geometryHealthUtils';
import { areArraysEqual } from '../../lib/lang';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { NodeTableIdentifier, NodeTableType } from '../../lib/nodeTableUtil';
import { isNumericInt } from '../../lib/number';
import { Logger } from '../../lib/observability/logs';
import { OutputGraphId } from '../../lib/outputUtils';
import {
  Contexts,
  NodeContextType,
  SelectionAction,
  applyAction,
  applyNewSelection,
  applySubselections,
  getCurrentSelection,
  getSelectionContext,
  highlightedEntities,
  maybeUngroupSurfaces,
} from '../../lib/selectionUtils';
import { NodeType } from '../../lib/simulationTree/node';
import { isSubselectActive } from '../../lib/subselectUtils';
import { findAncestorIds } from '../../lib/treeUtils';
import { getVolumeIdsFromSurfaces } from '../../lib/volumeUtils';
import { useProjectOperations } from '../../model/hooks/useProject';
import { lcVisEnabledSelector } from '../../recoil/lcvis/lcvisEnabledState';
import { lcVisReadyState } from '../../recoil/lcvis/lcvisReadyState';
import { defaultTransparencySettings, useSetTransparencySettings } from '../../recoil/lcvis/transparencySettings';
import { usePlotNodes, useSetCurrentlySelectedPlot } from '../../recoil/plotNodes';
import { entitySelectionState, quantitySelectionState } from '../../recoil/selectionOptions';
import { simulationTreeSubselectState, useSimulationTreeSubselect } from '../../recoil/simulationTreeSubselect';
import { useActiveNodeTable } from '../../recoil/useActiveNodeTable';
import { useNodeLinkState } from '../../recoil/useNodeLink';
import { useControlPanelMode } from '../../recoil/useProjectPage';
import { useSetRowsOpened } from '../../recoil/useSimulationTreeState';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { geoModificationsTreeSelector } from '../../state/internal/tree/geometryModifications';
import { geometryTreeSelector, useGeometryTree } from '../../state/internal/tree/section/geometry';
import { simulationTreeSelector, useModificationsTree, useSimulationTree } from '../../state/internal/tree/simulation';

import { useProjectContext } from './ProjectContext';

/** The place in the app where the selection action originated. */
export type SelectionSource = 'vis' | 'tree';
export interface SelectionOptions {
  // Action to perform
  action: SelectionAction;
  // Ids to modify the selection
  modificationIds?: string[];
  // Overrides the default node table (NodeTableType.NONE) the action is performed with
  nodeTableOverride?: NodeTableIdentifier;
  // Update the currently active node table. If nodeTableOverride is not defined the active node
  // table will be set to NodeTableType.NONE if this option is true.
  updateActiveNodeTable?: boolean;
  // Update the current highlighting based on the new selection. Default is true.
  updateHighlighting?: boolean;
  // The place in the app where the selection action originated.
  selectionSource?: SelectionSource;
  cancelSubselect?: boolean;
}

export interface ScrollToAction {
  node: string;
  // Fast means the scroll action happens immediately rather than smoothly animating.
  fast?: boolean;
}

// The SelectionContext provided by SelectionManager using useSelectionContext().
// The type Contexts in selectionUtils.ts uses this type, minus modifySelection.
export type ProvidedSelectionContext = NodeContextType & {
  modifySelection: (options: SelectionOptions) => void,
  asyncModifySelection: (options: SelectionOptions) => Promise<void>,
  // Set a new selection. This will also set activeNodeTable to NodeTableType.NONE.
  setSelection: (ids: string[]) => void,
  deselectNodeIds: (ids: string[]) => void;
  scrollToAction: ScrollToAction;
  setScrollTo: (action: ScrollToAction) => void;
  setRevealNodes: (nodeIds: string[]) => void;
}

const SelectionContext = createContext<ProvidedSelectionContext | null>(null);

const logger = new Logger('SelectionManager');

export function useSelectionContext(): ProvidedSelectionContext {
  return useContext(SelectionContext)!;
}

interface SelectionManagerProps {
  children: ReactNode;
}

const SelectionManager = (props: SelectionManagerProps) => {
  const { projectId, workflowId, jobId, readOnly, geometryId } = useProjectContext();
  const simulationTree = useSimulationTree(projectId, workflowId, jobId);
  const geometryTree = useGeometryTree(projectId, workflowId, jobId);
  const modificationsTree = useModificationsTree(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const setThisPlotNode = useSetCurrentlySelectedPlot();
  const [plotState] = usePlotNodes(projectId);
  const [controlPanelMode] = useControlPanelMode();
  const setNodesOpened = useSetRowsOpened(projectId, controlPanelMode);
  const treeSubselect = useSimulationTreeSubselect();
  const setTransparencySettings = useSetTransparencySettings();
  const activatedGhostMode = useRef(false);

  // Which surface table is currently being edited. Set to NONE if no table is
  // being edit. When a table is active, clicking on a surface in the Paraview
  // window or the simulation tree will toggle the surface in or out of the
  // active table.
  const [activeNodeTable, setActiveNodeTable] = useActiveNodeTable();

  // The ids of the currently highlighted entities in the visualizer.
  const [highlightedInVisualizer, setHighlightedInVisualizer] = useState<string[]>([]);

  // The ids of the currently highlighted entities in the simulation tree. Note that
  // this list might be different from selectedNodeIds if a node table is active.
  // In this case the highlighted entities are based on the entities in this table
  // rather than the currently selected node(s).
  const [highlightedInSimTree, setHighlightedInSimTree] = useState(new Set<string>());

  // A warning message that appears in a NodeTable.
  const [nodeTableWarning, setNodeTableWarning] = useState<string>('');

  // The IDs of the selected nodes. We save the ID instead of the node because
  // the nodes are regenerated when simParam changes.
  const [selectedNodeIds, setSelectedNodeIdsBase] = useState<string[]>([]);

  const selectedNode = useMemo(() => {
    if (geometryTree || simulationTree || modificationsTree) {
      // In subselect mode, reference node(s) aren't actually selected but we want them to be the
      // `selectedNode` so that the correct prop panel is displayed.
      const nodeIds = treeSubselect.active ? treeSubselect.referenceNodeIds : selectedNodeIds;
      if (nodeIds.length === 1) {
        // Search through all existing trees in order to find the selected node
        const nodeInGeometryTree = geometryTree?.getDescendant(nodeIds[0]);
        if (nodeInGeometryTree) {
          return nodeInGeometryTree;
        }
        const nodeInSimulationTree = simulationTree?.getDescendant(nodeIds[0]);
        if (nodeInSimulationTree) {
          return nodeInSimulationTree;
        }
        const nodeInModificationsList = modificationsTree?.getDescendant(nodeIds[0]);
        if (nodeInModificationsList) {
          return nodeInModificationsList;
        }
      }
    }

    return null;
  }, [selectedNodeIds, simulationTree, geometryTree, treeSubselect, modificationsTree]);

  // Tracks the ID of a node that needs to be scrolled into view.
  const [scrollToAction, setScrollTo] = useState<ScrollToAction>({ node: '' });

  // Track the IDs of nodes that should be fully expanded.
  const [revealNodes, setRevealNodes] = useState<string[]>([]);

  const [nodeLinkIds, setNodeLinkIds] = useNodeLinkState();

  // The ID of the output node displayed in the output chart.
  const [outputGraphId, setOutputGraphId] = useState<OutputGraphId>({ node: '', graphIndex: 0 });

  // Returns true if the IDs are for one output node.
  const isOutputNode = useCallback((ids: string[]) => {
    if (ids.length === 1 && simulationTree) {
      const treeNode = simulationTree.getDescendant(ids[0]);
      return treeNode && treeNode.type === NodeType.OUTPUT;
    }
    return false;
  }, [simulationTree]);

  // When setting the selection, set the output chart to the same ID if we are
  // selecting one output node.
  const setSelectedNodeIds = useCallback((ids: string[]) => {
    setSelectedNodeIdsBase(ids);
    if (isOutputNode(ids)) {
      setOutputGraphId({ node: ids[0], graphIndex: 0 });
      setThisPlotNode(plotState.plots[0]);
    }
  }, [setSelectedNodeIdsBase, setOutputGraphId, isOutputNode, plotState, setThisPlotNode]);

  // This is a bit of a hack to guarantee a smooth transition out of subselect mode.  We don't want
  // to expose `setSelectedNodeIds` more generally, so this wrapper function is a little more
  // guarded so that it can only be used when subselect is active.
  const setFinishingSubselectNodeIds = useCallback((ids: string[]) => {
    if (treeSubselect.active) {
      setSelectedNodeIds([...ids]);
    }
  }, [setSelectedNodeIds, treeSubselect]);

  const { onParamUpdate } = useProjectOperations(projectId, workflowId, jobId, readOnly);

  const nodeContext: NodeContextType = {
    selectedNode,
    selectedNodeIds,
    outputGraphId,
    setOutputGraphId,
    activeNodeTable,
    isTreeModal: (activeNodeTable.type !== NodeTableType.NONE) || treeSubselect.active,
    setActiveNodeTable,
    nodeTableWarning,
    setNodeTableWarning,
    highlightedInVisualizer,
    setHighlightedInVisualizer,
    highlightedInSimTree,
    treeSubselect,
    setFinishingSubselectNodeIds,
  };

  // Modify the current selection. Fetches all recoil states that are required to determine the new
  // selection. Also determines the currently highlighted nodes in the sim tree and in the
  // visualizer (by setting local states as a side-effects). The new selection is either applied
  // to the selected node ids or to the currently active node table.
  const asyncModifySelection = useRecoilCallback((callbackInterface: CallbackInterface) => async (
    options: SelectionOptions,
  ) => {
    const { getPromise } = callbackInterface.snapshot;
    const { set } = callbackInterface;
    const selectionContext = await getSelectionContext(
      { projectId, workflowId, jobId },
      callbackInterface,
      geometryId,
    );
    const lcvisReady = await getPromise(lcVisReadyState);
    const lcvisEnabled = await getPromise(lcVisEnabledSelector(projectId));
    const currentSimTree = await getPromise(
      isGeometryView ?
        geoModificationsTreeSelector({ projectId, workflowId, jobId }) :
        simulationTreeSelector({ projectId, workflowId, jobId }),
    );
    const geometrySimTree = await getPromise(
      geometryTreeSelector({ projectId, workflowId, jobId }),
    );
    const entitySelectionType = await getPromise(entitySelectionState(projectId));
    // Get a fresh copy of subselect, because the treeSubselect variable at the top of the component
    // could be stale just after the subselect is cancelled.
    const subselect = await getPromise(simulationTreeSubselectState);

    // React does not batch state updates together in async routines by default.
    // The routine below can be used to work around that.
    // Its totally safe to use it:
    // https://github.com/facebook/react/issues/16387#issuecomment-521623662)
    unstable_batchedUpdates(() => {
      const allContexts: Contexts = {
        selection: selectionContext,
        providedSelection: {
          ...nodeContext,
          activeNodeTable: options.nodeTableOverride || { type: NodeTableType.NONE },
        },
      };

      const { isTreeModal } = nodeContext;

      const modificationIds = options.modificationIds ?? [];
      const currentSelection = getCurrentSelection(allContexts);
      let newSelection = currentSelection;

      if (options.action !== SelectionAction.HIGHLIGHT_CURRENT) {
        let finalModificationIds = maybeUngroupSurfaces(modificationIds, allContexts);

        // if we are selecting from vis, we need to convert all the selections to represent the
        // current entitySelectionType before applying the selection action.
        if (options.selectionSource === 'vis' && !isTreeModal) {
          if (entitySelectionType === 'volume') {
            finalModificationIds = expandGroups(
              selectionContext.entityGroupData.leafMap,
            )(finalModificationIds);
            finalModificationIds = getVolumeIdsFromSurfaces(
              finalModificationIds,
              selectionContext.staticVolumes,
            );
          }
        }

        if (subselect.active && !options.cancelSubselect) {
          newSelection = applySubselections(
            subselect,
            currentSelection,
            finalModificationIds,
            options.action,
            selectionContext.entityGroupData,
          );
        } else {
          newSelection = applyAction(currentSelection, finalModificationIds, options.action);
        }

        // if the user makes a selection any way other than by clicking in the visualizer,
        // modify entitySelectionState to match the currently selected entity type.
        if (!isTreeModal && options.selectionSource !== 'vis') {
          const hasVolume = newSelection.some((id) => id.startsWith('volume'));
          if (hasVolume && entitySelectionType !== 'volume') {
            set(entitySelectionState(projectId), 'volume');
          } else if (!hasVolume && entitySelectionType !== 'surface') {
            const hasSurfaceOrGroup = newSelection.some(
              (id) => selectionContext.entityGroupData.groupMap.has(id),
            );
            if (hasSurfaceOrGroup) {
              set(entitySelectionState(projectId), 'surface');
            }
          }
        }

        const isExclusive = options.action === SelectionAction.OVERWRITE_EXCLUDE;
        if (!areArraysEqual(currentSelection, newSelection) || isExclusive) {
          const warnings = applyNewSelection(
            newSelection,
            allContexts,
            isExclusive,
            setSelectedNodeIds,
            onParamUpdate,
          );
          setNodeTableWarning(warnings.length ? warnings[0] : '');
          if (warnings.length) {
            return;
          }
        }
      }

      if (options.updateHighlighting ?? true) {
        // Filter out the geometry health nodes. They are not highlighted in the visualizer.
        // They interfere with displaying volumes. We assume that volumes are only selected
        // individually.
        const filteredSelection = newSelection.filter((entity) => (
          geomHealthNodeIdtoIndex(entity) === -1
        ));
        setHighlightedInSimTree(new Set(filteredSelection));
        const newSelectedNode = (
          filteredSelection.length === 1 && currentSimTree ?
            (
              currentSimTree.getDescendant(filteredSelection[0]) ??
              geometrySimTree.getDescendant(filteredSelection[0])
            ) : null
        );
        // If we only have one node selected we try to find the entities that should be highlighted
        // in the visualizer based on the type of the node.
        const highlightedInVis = highlightedEntities(
          newSelectedNode,
          filteredSelection,
          allContexts,
        );
        if (lcvisEnabled && lcvisReady) {
          lcvHandler.queueDisplayFunction('set selection', (display) => {
            const { workspace, simAnnotationHandler } = display;
            // all the surface ids are of the form ${volumeId}/${otherStuff} where the
            // surface bounds some volume with id volumeId. So when we want to highlight a volume,
            // we select the volume id (e.g. 0), and in the visualizer we highlight all the
            // surfaces which start with that volumeId.
            // We can tell an id is a volume id if it's a completely numeric string.
            const highlightedVolumes = highlightedInVis.filter(
              (id) => !Number.isNaN(parseInt(id, 10)) && isNumericInt(id),
            );
            if (
              highlightedInVis.length &&
              highlightedVolumes.length
            ) {
              workspace?.selectVolumes(new Set(highlightedVolumes)).catch(
                (error) => logger.warn(error),
              );
              // if we are highlighting volumes, deselect all simulation annotations.
              simAnnotationHandler?.selectAnnotations(new Set());
            } else {
              const toSelect = new Set(highlightedInVis);
              workspace?.selectSurfaces(toSelect).catch((error) => logger.warn(error));
              simAnnotationHandler?.selectAnnotations(toSelect);
            }
          });
        }
        setHighlightedInVisualizer(highlightedInVis);
        if (highlightedInVis.length) {
          // reset from box select mode
          set(quantitySelectionState(projectId), 'singleSelect');
        }
      }
      if (options.updateActiveNodeTable) {
        setActiveNodeTable(allContexts.providedSelection.activeNodeTable);
      }

      const isOnlyOneTagSelected = (
        modificationIds.length === 1 &&
        selectionContext.geometryTags.isTagId(modificationIds[0])
      );

      if (isOnlyOneTagSelected) {
        const [selectedId] = modificationIds;

        const tagSurfaces = (
          selectionContext.geometryTags.surfacesFromTagEntityGroupId(selectedId) || []
        );
        const tagDomains = (
          selectionContext.geometryTags.domainsFromTagEntityGroupId(selectedId) || []
        );

        const surfaceIds = tagDomains.reduce((result, tagDomain) => {
          const volume = selectionContext.staticVolumes.find(({ domain }) => domain === tagDomain)!;

          return [...result, ...volume.bounds];
        }, tagSurfaces);

        setTransparencySettings({ active: true, surfaces: new Set(surfaceIds), invert: true });
        activatedGhostMode.current = true;
      } else if (activatedGhostMode.current) {
        setTransparencySettings(defaultTransparencySettings);
        activatedGhostMode.current = false;
      }
    });
  }, [
    selectedNode,
    selectedNodeIds,
    outputGraphId,
    setOutputGraphId,
    setActiveNodeTable,
    nodeTableWarning,
    setNodeTableWarning,
    isSubselectActive,
    activeNodeTable,
    isGeometryView,
    geometryId,
  ]);

  const setSelection = useCallback((ids: string[], selectionSource?: SelectionSource) => (
    asyncWrapper(asyncModifySelection)(
      {
        action: SelectionAction.OVERWRITE,
        modificationIds: ids,
        updateActiveNodeTable: true,
        selectionSource,
      },
    )
  ), [asyncModifySelection]);

  // Remove given node IDs from current selection
  const deselectNodeIds = useCallback((ids: string[]) => {
    const newNodeIds = selectedNodeIds.filter((nodeId) => !ids.includes(nodeId));
    if (newNodeIds.length !== selectedNodeIds.length) {
      // Only call setSelection if the new node ID list differs from selectedNodeIds
      setSelection(newNodeIds);
    }
  }, [selectedNodeIds, setSelection]);

  useEffect(() => {
    if (nodeLinkIds.length) {
      setSelection(nodeLinkIds);
      setScrollTo({ node: nodeLinkIds[0] });
      setNodeLinkIds([]);
    }
  }, [nodeLinkIds, setNodeLinkIds, setSelection]);

  const expandRowAncestors = useCallback((nodeIds: string[]) => {
    setNodesOpened((oldValue) => {
      const newValue = { ...oldValue };
      findAncestorIds(nodeIds, simulationTree).forEach((ancestorId) => {
        newValue[ancestorId] = true;
      });
      return newValue;
    });
  }, [setNodesOpened, simulationTree]);

  useEffect(() => {
    if (revealNodes.length) {
      expandRowAncestors(revealNodes);
      setRevealNodes([]);
    }
  }, [expandRowAncestors, revealNodes]);

  const context = {
    ...nodeContext,
    modifySelection: asyncWrapper(asyncModifySelection),
    asyncModifySelection,
    setSelection,
    scrollToAction,
    setScrollTo,
    deselectNodeIds,
    setRevealNodes,
  };
  return (
    <SelectionContext.Provider value={context}>
      {props.children}
    </SelectionContext.Provider>
  );
};

export default SelectionManager;
