// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { advancedSolverLink, isInExploration, jobLink, workflowLink } from '../../../lib/navigation';
import { AdvancedAnalysisParams } from '../../../lib/routeParamTypes';
import { isSensitivityAnalysis } from '../../../lib/workflowUtils';
import { useWorkflowState } from '../../../recoil/workflowState';
import { ActionButton } from '../../Button/ActionButton';
import { useProjectContext } from '../../context/ProjectContext';

export const AdvancedAnalysisButton = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // == State
  const workflow = useWorkflowState(projectId, workflowId);
  const isExploration = isInExploration(location.pathname);

  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  // Add type when LC-22632 is done
  const referrerType = useMemo(() => {
    if (isSensitivity) {
      return 'sensitivity';
    }
    if (isInExploration(location.pathname)) {
      return 'doe';
    }
    return 'simulation';
  }, [isSensitivity, location.pathname]);

  // This is coming with Adjoint - LC-22470
  // const menuItems: CommonMenuItem[] = [];
  // menuItems.push({
  //   label: 'Setup Adjoint',
  //   disabled: true,
  //   disabledReason: 'Coming soon',
  //   onClick: () => {},
  // });
  // menuItems.push({
  //   label: 'Setup Design of Experiment',
  //   onClick: () => {
  //     navigate(
  //       advancedSolverLink(
  //         projectId,
  //         'doe',
  //         workflowId,
  //         isInExploration(location.pathname) ? 'doe' : 'simulation',
  //       ),
  //     );
  //   },
  // });

  // return (
  //   <SplitButton
  //     asBlock
  //     dataLocator="advancedAnalysisButton"
  //     kind="primary"
  //     menuItems={menuItems}
  //     splitIcon={{ name: 'gear', maxHeight: 12 }}>
  //     Advanced Analysis
  //   </SplitButton>
  // );

  return (
    <ActionButton
      asBlock
      dataLocator="setupDoeButton"
      kind="primary"
      onClick={() => {
        // Go to the advanced analysis page. It will set the controlPanelMode to 'exploration'
        // when it detects the `advanced` path and the `doe` in the pathname.
        navigate(
          advancedSolverLink(
            projectId,
            workflowId,
            jobId,
            'doe',
            referrerType,
          ),
        );
      }}>
      Setup Design of Experiments
    </ActionButton>
  );
};

export const CloseAdvancedAnalysisButton = () => {
  // == Contexts
  const { projectId } = useProjectContext();

  // == Hooks
  const navigate = useNavigate();
  const params = useParams<AdvancedAnalysisParams>();

  // == State
  const referrerWorkflowId = params.referrerWorkflowId ?? '';
  const referrerJobId = params.referrerJobId ?? '';
  const referrerType = params.referrerType ?? 'simulation';

  const link = useMemo(() => {
    if (referrerType === 'doe') {
      return jobLink(projectId, referrerWorkflowId, referrerJobId);
    }
    return workflowLink(projectId, referrerWorkflowId, referrerType === 'sensitivity');
  }, [projectId, referrerWorkflowId, referrerJobId, referrerType]);

  return (
    <ActionButton
      asBlock
      dataLocator="closeAdvancedAnalysis"
      kind="cancel"
      onClick={() => navigate(link)}>
      Close
    </ActionButton>
  );
};
