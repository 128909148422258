import { RECOMMENDED_ITERATIONS_PER_MESH, calculateRecommendedIterations } from './amrUtils';
import { STOPPING_CONDITIONS_NODE_ID } from './simulationTree/node';
import { SimulationValidator } from './simulationValidation';

interface LMAData {
  maxIterations: number;
  initialCv: number;
  finalCv: number;
  cvSchedule: number[]
}

export const validateLMASettings = (data: LMAData, validator: SimulationValidator) => {
  const totalRemeshes = data.cvSchedule.length;

  // Calculate recommended iterations.
  const recommendedIterations = calculateRecommendedIterations(totalRemeshes);

  // Add warnings/info based on max iterations
  if (data.maxIterations < recommendedIterations * 0.5) {
    validator.addWarning(
      STOPPING_CONDITIONS_NODE_ID,
      `Current setting of ${data.maxIterations} iterations may be insufficient for LMA. ` +
      `Recommended value is ${recommendedIterations} iterations ` +
      `(${RECOMMENDED_ITERATIONS_PER_MESH} per mesh).`,
    );
  } else if (data.maxIterations < recommendedIterations) {
    validator.addInfo(
      STOPPING_CONDITIONS_NODE_ID,
      `Current setting of ${data.maxIterations} iterations is below the recommended ` +
      `${recommendedIterations} for LMA (${RECOMMENDED_ITERATIONS_PER_MESH} ` +
      `per mesh). The number of solver iterations has minimal impact on simulation run time.`,
    );
  }
};
