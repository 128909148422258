// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import cx from 'classnames';

import {
  FaultType,
  FormControlSize,
  SelectOption,
  SelectOptionGroup,
} from '../../../lib/componentTypes/form';
import { CommonMenuItem, CommonMenuListItem, CommonMenuPosition } from '../../../lib/componentTypes/menu';
import { isUnmodifiedArrowDownKey, isUnmodifiedSpaceKey } from '../../../lib/event';
import { parseString } from '../../../lib/html';
import { Logger } from '../../../lib/observability/logs';
import { CommonMenu } from '../../Menu/CommonMenu';
import Tooltip, { TooltipProps } from '../../Tooltip';
import { ChevronDownIcon } from '../../svg/ChevronDownIcon';

import './DataSelect.scss';
import useResizeObserver from '@/lib/useResizeObserver';

const logger = new Logger('DataSelect');

/**
 * The DataSelect component implements a select dropdown (comparable to the
 * HTML <select> element using the data-driven CommonMenu to present choices
 * to the user.  It is intentionally data-driven and doesn't rely on child
 * elements for correct rendering; however, its props mirror the native
 * <option> and <optgroup> HTML elements */

// Minimum width of menu
const MIN_MENU_WIDTH = 100;
// Additional amount (in px) to add to calculated sizing width
const SIZING_PADDING = 4;

// Return a single CSS class to apply to the root element, based on a
// combination of state values
function getStateClass(disabled: boolean, readOnly: boolean, open: boolean) {
  if (disabled) {
    return 'disabled';
  }
  if (readOnly) {
    return 'readOnly';
  }
  if (open) {
    return 'open';
  }
  return 'enabled';
}

export type DataSelectKind = 'minimal';

export interface DataSelectProps<T> {
  // A list of options or option groups
  options: (SelectOption<T> | SelectOptionGroup<T>)[];
  // A readOnly input cannot be changed, but its value can be selected (e.g. for copying).
  readOnly?: boolean;
  // Choose a size
  size?: FormControlSize;
  // A disabled input cannot be changed, and its value cannot be selected.  Its
  // presentation is also noticeably dimmer.
  disabled?: boolean;
  // When 'multiple' is false and a value is selected from the menu, onChange is called.
  onChange?: (value: T) => void;
  // Called when `multiple` is true and selections change
  onChangeMultiple?: (value: T[]) => void;
  // Optional ReactNode to show in the input when no option has been selected
  placeholderText?: ReactNode;
  // Where to position the dataselect items relative to the trigger anchor
  position?: CommonMenuPosition;
  // Optional indicator of an error or warning state, affecting the input's presentation
  faultType?: FaultType;
  // When true, layout as a block level element; otherwise, layout inline.
  asBlock?: boolean;
  // Configure a custom data-locator attribute
  locator?: string;
  // Allow selection of multiple options
  multiple?: boolean;
  // Option to show a different style than the default one
  kind?: DataSelectKind;
  // An optional tooltip for the main input
  tooltip?: string;
  // Optional placement for the tooltip
  tooltipPlacement?: TooltipProps['placement'];
}

// The DataSelect component emulates an HTML <select> list but with a couple of
// important differences.  First, it is data-driven and doesn't recognize or
// support child components.  Second, it uses CommonMenu to present choices,
// which has the benefit of allowing rich menu options (i.e. name &
// description).
export function DataSelect<T>(props: DataSelectProps<T>) {
  const {
    locator,
    options,
    onChange,
    onChangeMultiple,
    placeholderText = 'Select\u2026',
    position,
    readOnly = false,
    disabled = false,
    size = 'medium',
    faultType,
    asBlock,
    multiple,
    kind,
    tooltip: mainTooltip = '',
    tooltipPlacement = 'top',
  } = props;

  if (multiple && !onChangeMultiple) {
    logger.warn('onChangeMultiple is missing from multiple-enabled DataSelect');
  }
  if (!multiple && !onChange) {
    logger.warn('onChange is missing from DataSelect');
  }
  if (
    options.some(
      (option) => {
        const opt = option as SelectOption<T>;
        if (opt.toggleNotSelect !== undefined && !opt.onClick) {
          return true;
        }
        return false;
      },
    )) {
    logger.warn('onClick handler missing from toggleable item in DataSelect');
  }

  // Tracks whether the menu is open or not
  const [menuOpen, setMenuOpen] = useState(false);
  // Track the element's overall width, so that we can set the menu to a similar
  // width.
  const [maxWidth, setMaxWidth] = useState(0);
  // This component places a list of all options in a hidden div (the
  // options-sizing div) to expose a maximum width for the .field > .label
  // element, which is tracked here.
  const sizingRef = useRef<HTMLDivElement>(null);
  const sizing = useResizeObserver(sizingRef);

  const rootRef = useRef<HTMLDivElement>(null);

  // Since options may be of type Option or OptionGroup, we need a flattened
  // list of options to generate the hidden option-sizing div
  const flatOptions = useMemo(
    () => options.reduce((result, option) => {
      if ('options' in option) {
        result.push(...option.options);
      } else {
        result.push(option);
      }
      return result;
    }, [] as SelectOption<T>[]),
    [options],
  );

  const selectedOptions = useMemo(
    () => flatOptions.filter((option) => option.selected),
    [flatOptions],
  );

  const selectedOption = useMemo(
    () => (selectedOptions.length ? selectedOptions[0] : undefined),
    [selectedOptions],
  );

  const handleClickItem = useCallback((opt: SelectOption<T>) => {
    if (opt.toggleNotSelect) {
      // if an option is a 'toggle' not a 'select' option, then skip the onChange handlers since the
      // selection isn't technically changing.
      opt.onClick?.();
      return;
    }
    if (multiple) {
      const values = new Set(selectedOptions.map((option) => option.value));
      if (values.has(opt.value)) {
        values.delete(opt.value);
      } else {
        values.add(opt.value);
      }
      onChangeMultiple?.([...values]);
    } else {
      setMenuOpen(false);
      if (opt.onClick) {
        opt.onClick();
      } else {
        onChange?.(opt.value);
      }
    }
  }, [selectedOptions, multiple, onChange, onChangeMultiple]);

  // Map options (including Option or OptionGroup types) to menu items data for
  // the CommonMenu component
  const menuItems = useMemo(
    () => options.reduce((result, option, i) => {
      const optionsToAppend: SelectOption<T>[] = [];

      const addSeparator = () => result.push({ separator: true });
      if ('options' in option) {
        // This is an OptionGroup member.  Prepend a separator (if it's not the first member), and
        // prepend a title.
        if (i) {
          addSeparator();
        }
        if (option.label) {
          result.push({ title: option.label });
        }
        optionsToAppend.push(...option.options);
      } else {
        // This is a plain Option member.  Prepend a separator iff the previous member is an
        // OptionGroup.
        if (i && ('options' in options[i - 1])) {
          addSeparator();
        }
        optionsToAppend.push(option);
      }

      result.push(...optionsToAppend.map((opt) => {
        const {
          auxIcon,
          name,
          description,
          disabled: optDisabled,
          disabledReason,
          selected,
          tooltip,
          icon,
          keyboardShortcut,
        } = opt;

        const endIcon = auxIcon ? { ...auxIcon } : undefined;
        if (endIcon && optDisabled) {
          endIcon.color = 'currentColor';
        }

        return {
          label: name,
          description,
          help: tooltip,
          onClick: () => handleClickItem(opt),
          disabled: optDisabled,
          disabledReason,
          selected,
          startIcon: icon,
          endIcon,
          toggleable: opt.toggleNotSelect,
          engaged: opt.toggleNotSelect ? opt.toggledTrue : undefined,
          keyboardShortcut,
        } as CommonMenuListItem;
      }));

      return result;
    }, [] as CommonMenuItem[]),
    [handleClickItem, options],
  );

  const handleToggle = () => {
    if (disabled || readOnly) {
      return;
    }
    setMaxWidth(Math.ceil(Math.max(MIN_MENU_WIDTH, (rootRef.current?.offsetWidth ?? 0))));
    setMenuOpen(!menuOpen);
  };

  const stateClass = getStateClass(disabled, readOnly, menuOpen);

  // Manage these accessibility props as an object to mollify eslint
  const a11yProps = {
    ...!disabled && {
      onClick: handleToggle,
      onKeyUp: (event: React.KeyboardEvent) => {
        if (isUnmodifiedArrowDownKey(event)) {
          setMenuOpen(true);
        } else if (isUnmodifiedSpaceKey(event)) {
          handleToggle();
        }
      },
      role: 'button',
      tabIndex: 0,
    },
    ...locator && {
      'data-locator': locator,
    },
  };

  const labelContent = () => {
    const fieldLabelStyle = {
      width: (!sizing.width || asBlock) ? 'auto' : `${sizing.width + SIZING_PADDING}px`,
    };

    let content: ReactNode = null;
    if (multiple) {
      if (selectedOptions.length > 1) {
        content = `${selectedOptions.length} selected`;
      } else if (selectedOptions.length === 1) {
        content = selectedOptions[0].avatar || parseString(selectedOptions[0].name);
      }
    } else if (selectedOption) {
      content = selectedOption.avatar || parseString(selectedOption.name);
    } else if (typeof placeholderText !== 'string') {
      content = placeholderText;
    }

    if (content) {
      return (
        <div className="label" style={fieldLabelStyle}>{content}</div>
      );
    }

    return (
      <div className="label" style={fieldLabelStyle}>
        {placeholderText || <>&nbsp;</>}
      </div>
    );
  };

  return (
    <div
      className={cx(
        'dataSelect', // locator for the selenium tests
        'dataSelectUI', // class for styles
        stateClass,
        size,
        { filled: !!selectedOption, 'block-flow': asBlock },
        faultType ? `fault-${faultType}` : '',
        kind || '',
      )}
      ref={rootRef}>
      <div aria-hidden className="sizing">
        <div className="sizingContainer" ref={sizingRef}>
          {flatOptions.map((option) => (
            <div className="sizingRow" key={option.name}>
              {option.icon && (
                <div className="icon" />
              )}
              <div className="label">{option.name}</div>
              {option.auxIcon && (
                <div className="icon" />
              )}
              {option.toggleNotSelect && (
                <div className="icon" />
              )}
              {option.keyboardShortcut && (
                option.keyboardShortcut
              )}
            </div>
          ))}
        </div>
      </div>
      <Tooltip placement={tooltipPlacement} title={mainTooltip}>
        <div
          {...a11yProps}
          className="field">
          {labelContent()}
          <div className="icon" data-locator="dataSelectDropDownIconContainer">
            <ChevronDownIcon maxWidth={8} />
          </div>
        </div>
      </Tooltip>
      <CommonMenu
        anchorEl={rootRef.current}
        maxWidth={Math.max(maxWidth, (sizing.width ?? 0))}
        menuItems={menuItems}
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
        position={position}
        showCheckBoxes={multiple}
      />
    </div>
  );
}
