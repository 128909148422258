import React from 'react';

import { IconName } from '../../lib/componentTypes/svgIcon';
import { expandGroups } from '../../lib/entityGroupUtils';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeoClipValue, useToggleGeometryClip } from '../../recoil/geometry/geometryClipState';
import { useLcvisExplodeFactorState } from '../../recoil/lcvis/explodeFactor';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcvisMeasureValue } from '../../recoil/lcvis/lcvisMeasureState';
import { useLcvisProbeValue } from '../../recoil/lcvis/lcvisProbeState';
import { TRANSPARENCY_MODE_SUBTITLE, useTransparencyDialog } from '../../recoil/lcvis/transparencySettings';
import { useEntitySelectionValue } from '../../recoil/selectionOptions';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useHandleLcVisFilterClick } from '../visFilter/useHandleLcVisFilterClick';
import { useHandleProbeClick } from '../visFilter/useHandlePvFilterClick';

import environmentState from '@/state/environment';

type VisToolItem = {
    icon: IconName;
    label: string;
    onClick: (ev: React.MouseEvent | React.KeyboardEvent) => void;
    key: string;
    engaged: boolean;
    disabled: boolean;
    tooltip: string;
}

export const useVisToolsItems = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { viewState } = useParaviewContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcVisReady = environmentState.use.lcvisReady;
  const lcvisProbe = useLcvisProbeValue();
  const lcvisMeasure = useLcvisMeasureValue();
  const handleLcvButtonClick = useHandleLcVisFilterClick();
  const [transparencyDialogSettings, setTransparencyDialogSettings] = useTransparencyDialog();
  const entitySelectionState = useEntitySelectionValue(projectId);
  const { selectedNodeIds } = useSelectionContext();
  const { groupMap: entityGroupMap, leafMap } = useEntityGroupData(projectId, workflowId, jobId);
  const [explodeFactor, setExplodeFactor] = useLcvisExplodeFactorState(projectId);
  const isGeometryView = useIsGeometryView();
  const geoClipState = useGeoClipValue(projectId);
  const startGeoClip = useToggleGeometryClip(projectId);
  const handleParaviewProbe = useHandleProbeClick();

  const canMakeSelectionTransparent = (
    entitySelectionState === 'surface' &&
    selectedNodeIds.length &&
    entityGroupMap.has(selectedNodeIds[0])
  );

  const menuItems: VisToolItem[] = [];

  if (lcvisEnabled && isGeometryView) {
    menuItems.push({
      icon: 'scissors',
      label: 'Clip Tool',
      onClick: startGeoClip,
      key: 'clipBtn',
      engaged: geoClipState.active,
      disabled: !lcVisReady,
      tooltip: 'Use a clip plane to view a section of the geometry.',
    });
  }

  if (lcvisEnabled) {
    menuItems.push({
      icon: 'transparency',
      label: 'Transparency Mode',
      onClick: () => setTransparencyDialogSettings((prev) => ({
        ...prev,
        active: !prev.active,
        surfaces: (
          (prev.active || !canMakeSelectionTransparent) ?
            new Set() :
            new Set(expandGroups(leafMap)(selectedNodeIds))
        ),
      })),
      key: 'transparencyBtn',
      engaged: transparencyDialogSettings.active,
      disabled: !lcVisReady,
      tooltip: TRANSPARENCY_MODE_SUBTITLE,
    });

    menuItems.push({
      icon: 'cubeFacesOutline',
      label: 'Exploded View',
      onClick: () => {
        setExplodeFactor((prev) => {
          if (prev === null) {
            return 0;
          }
          return null;
        });
      },
      key: 'explodeBtn',
      engaged: explodeFactor !== null,
      disabled: !lcVisReady,
      tooltip: 'Expand objects out from the center of the scene\'s visible bounds.',
    });
  }

  // If LCVis is not enabled, only show the probe button
  menuItems.push({
    icon: 'probe',
    label: 'Probe',
    onClick: async (ev) => {
      if (lcvisEnabled) {
        await handleLcvButtonClick(ev, 'probe');
      } else {
        handleParaviewProbe();
      }
    },
    key: 'probeBtn',
    engaged: lcvisProbe.active,
    disabled: lcvisEnabled ? !lcVisReady : !viewState,
    tooltip: 'Probe',
  });

  if (lcvisEnabled) {
    menuItems.push({
      icon: 'ruler',
      label: 'Measure',
      onClick: (ev) => handleLcvButtonClick(ev, 'measure'),
      key: 'measureBtn',
      engaged: lcvisMeasure.active,
      disabled: !lcVisReady,
      tooltip: 'Measure',
    });
  }

  return menuItems;
};
