// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { findFluidBoundaryCondition, findHeatBoundaryCondition } from '../../lib/boundaryConditionUtils';
import { findContactById } from '../../lib/contactsUtils';
import { findHeatSourceById } from '../../lib/heatSourceUtils';
import { subtractSet } from '../../lib/lang';
import { findFrameById, getAllAttachedDomains, getAllAttachedSurfaceIds } from '../../lib/motionDataUtils';
import { findMultiphysicsInterfaceById } from '../../lib/multiphysicsInterfaceUtils';
import { findOutputNodeById } from '../../lib/outputNodeUtils';
import { getParticleGroupMapByPhysicalBehavior } from '../../lib/physicalBehaviorUtils';
import { getAssignedVolumes, parsePhysicsIdFromSubId } from '../../lib/physicsUtils';
import { findPorousModelById } from '../../lib/porousModelUtils';
import { NodeType } from '../../lib/simulationTree/node';
import { findSlidingInterfaceById } from '../../lib/slidingInterfaceUtils';
import { mapDomainsToIds, mapIndicestoIds, volumeNodeId } from '../../lib/volumeUtils';
import { useMaterialEntity } from '../../model/hooks/useMaterialEntity';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useGeometryContacts } from '../../recoil/geometryContactsState';
import { useOutputNodes } from '../../recoil/outputNodes';
import useMeshMultiPart from '../../recoil/useMeshingMultiPart';
import { useStaticVolumes } from '../../recoil/volumes';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useFilterNode } from '../visFilter/useFilterNode';

import { useSimulationConfig } from './useSimulationConfig';
import { useVolumeNode } from './useVolumeNode';

/**
 * Returns a function which checks if a particular node is attached to the currently selected node.
 * This is useful for when we open/select some node that has a selection table (for volumes,
 * surfaces, points, disks...) and we want to highlight all of these attached items in the tree.
 */
export const useIsAttachedToSelectedNode = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode } = useSelectionContext();

  const { simParam } = useSimulationConfig();

  // == Recoil
  const contacts = useGeometryContacts(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const { surfaceOrGroupIds } = useVolumeNode(selectedNode?.id ?? '');
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  const {
    assignedVolumeNodeIds,
  } = useMaterialEntity(projectId, workflowId, jobId, readOnly, selectedNode?.id || '');
  const { geometryIds } = useFilterNode(selectedNode);

  const [outputNodes] = useOutputNodes(projectId, '', '');

  return useCallback((nodeId: string) => {
    switch (selectedNode?.type) {
      case NodeType.FILTER: {
        return geometryIds.has(nodeId);
      }
      case NodeType.GEOMETRY_CONTACT: {
        const contact = findContactById(contacts, selectedNode?.id);
        return (
          contact?.sideA.includes(nodeId) ||
          contact?.sideB.includes(nodeId)
        );
      }
      case NodeType.MATERIAL_FLUID:
      case NodeType.MATERIAL_SOLID: {
        return assignedVolumeNodeIds.includes(nodeId);
      }
      case NodeType.OUTPUT: {
        const outputNode = findOutputNodeById(outputNodes, selectedNode?.id);
        return (
          outputNode?.inSurfaces.includes(nodeId) ||
          outputNode?.outSurfaces.includes(nodeId)
        );
      }
      case NodeType.PHYSICAL_BEHAVIOR: {
        const particleGroupMap = getParticleGroupMapByPhysicalBehavior(simParam, selectedNode?.id);
        const particleGroupIds = Object.keys(particleGroupMap);
        return particleGroupIds.includes(nodeId);
      }
      case NodeType.PHYSICS_HEAT_HEAT_SOURCE: {
        const heatSource = findHeatSourceById(simParam, selectedNode?.id);
        const heatSourceDomains = heatSource?.heatSourceZoneIds ?? [];
        const attachedVolumes = mapDomainsToIds(staticVolumes, heatSourceDomains);
        return attachedVolumes.includes(nodeId);
      }
      case NodeType.PHYSICS_VOLUME_SELECTION: {
        const selectedPhysicsId = parsePhysicsIdFromSubId(selectedNode?.id);
        const physicsVolumes =
          getAssignedVolumes(simParam, selectedPhysicsId, geometryTags, staticVolumes);
        const physicsVolumesIds = physicsVolumes.map((volume) => volume.id);
        return physicsVolumesIds.includes(nodeId);
      }
      case NodeType.PHYSICS_MULTI_INTERFACE: {
        const multiphysicsInterface = findMultiphysicsInterfaceById(simParam, selectedNode?.id);
        return (
          multiphysicsInterface?.slidingA.includes(nodeId) ||
          multiphysicsInterface?.slidingB.includes(nodeId)
        );
      }
      case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION: {
        const boundaryCondition = findHeatBoundaryCondition(simParam, selectedNode?.id);
        return boundaryCondition?.surfaces.includes(nodeId);
      }
      case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION: {
        const boundaryCondition = findFluidBoundaryCondition(simParam, selectedNode?.id);
        return boundaryCondition?.surfaces.includes(nodeId);
      }
      case NodeType.PHYSICS_SLIDING_INTERFACE: {
        const slidingInterface = findSlidingInterfaceById(simParam, selectedNode?.id);
        return (
          slidingInterface?.slidingA.includes(nodeId) ||
          slidingInterface?.slidingB.includes(nodeId)
        );
      }
      case NodeType.MESH_SIZE: {
        const volumeIds = meshMultiPart?.volumeParams.reduce((acc, volumeParam) => {
          if (volumeParam) {
            acc.push(...mapIndicestoIds(staticVolumes, volumeParam.volumes));
          }
          return acc;
        }, [] as string[]);
        return volumeIds?.includes(nodeId);
      }
      case NodeType.MESH_MODEL: {
        const surfacesIds = meshMultiPart?.modelParams.reduce((acc, params) => {
          acc.push(...params.surfaces);
          return acc;
        }, [] as string[]);
        return surfacesIds?.includes(nodeId);
      }
      case NodeType.MESH_BOUNDARY: {
        const surfacesIds = meshMultiPart?.blParams.reduce((acc, params) => {
          acc.push(...params.surfaces);
          return acc;
        }, [] as string[]);
        return surfacesIds?.includes(nodeId);
      }
      case NodeType.MESH_ADAPTATION_BOUNDARY: {
        const blpList = simParam.adaptiveMeshRefinement?.boundaryLayerProfile;
        const surfacesIds = blpList?.reduce((acc, bl) => {
          acc.push(...bl.surfaces);
          return acc;
        }, [] as string[]);
        return surfacesIds?.includes(nodeId);
      }
      case NodeType.MOTION_FRAME: {
        const frame = findFrameById(simParam, selectedNode.id);
        const surfaceIds = frame?.attachedBoundaries || [];
        const volumesIds = frame?.attachedDomains.map(
          (volumeNode) => volumeNodeId(parseInt(volumeNode, 10)),
        ) || [];
        return surfaceIds.includes(nodeId) || volumesIds.includes(nodeId);
      }
      case NodeType.MOTION_GLOBAL_FRAME: {
        const allGeometry = staticVolumes.reduce((result, staticVolume) => {
          result.volumes.push(staticVolume.id);
          result.surfaces.push(...staticVolume.bounds);
          return result;
        }, { volumes: [] as string[], surfaces: [] as string[] });
        const attachedSurfaceIds = getAllAttachedSurfaceIds(
          simParam,
          undefined,
          geometryTags,
          entityGroupData,
        );
        const attachedVolumes = mapDomainsToIds(
          staticVolumes,
          getAllAttachedDomains(simParam, undefined, geometryTags),
        );

        const explicitVolumes = [...subtractSet(allGeometry.volumes, attachedVolumes)];
        const explicitSurfaces = [...subtractSet(allGeometry.surfaces, attachedSurfaceIds)];
        return (explicitVolumes.includes(nodeId) || explicitSurfaces.includes(nodeId));
      }
      case NodeType.POROUS_MODEL: {
        const porousModel = findPorousModelById(simParam, selectedNode?.id);
        const zoneIds = porousModel?.zoneIds || [];
        const volumeIds = staticVolumes.reduce((result, staticVolume) => {
          if (zoneIds.includes(staticVolume.domain)) {
            result.push(staticVolume.id);
          }
          return result;
        }, [] as string[]);
        return volumeIds?.includes(nodeId);
      }
      case NodeType.VOLUME: {
        return surfaceOrGroupIds.includes(nodeId);
      }
      default:
        break;
    }
    return false;
  }, [
    contacts,
    assignedVolumeNodeIds,
    geometryIds,
    geometryTags,
    meshMultiPart?.blParams,
    meshMultiPart?.volumeParams,
    meshMultiPart?.modelParams,
    outputNodes,
    selectedNode?.id,
    selectedNode?.type,
    simParam,
    staticVolumes,
    surfaceOrGroupIds,
    entityGroupData,
  ]);
};
