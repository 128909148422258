// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useCopyOutput } from '../../hooks/nodeDuplication/useCopyOutput';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'target' };

export const OutputTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteOutputNode, postDeleteNodeIds } = useNodeDeletion();

  const deleteRow = useCallback(() => {
    if (deleteOutputNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteOutputNode, node.id, postDeleteNodeIds]);

  const duplicateRow = useCopyOutput();

  const getContextMenuItems = useCallback(() => {
    const disabled = !canDelete(node.type, node.id);
    return [
      duplicateTreeNodeMenuItem(() => duplicateRow(node.id), disabled),
      deleteTreeNodeMenuItem(deleteRow, disabled),
    ];
  }, [canDelete, deleteRow, duplicateRow, node.id, node.type]);

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      renaming={renaming}
    />
  );
};
