// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

export type ChatMessage = {
  id: string;
  text: string;
  timestamp: Date;
  isUser: boolean;
}

/**
 * ChatThread encapsulates the current chat state that is going on with the assistant.
 */
export class ChatThread {
  sessionId: string;
  messages: Map<string, ChatMessage>;

  // skipEffect can be explicitly set to skip effects once.
  // This is useful when seeding the chat messages window with
  // chat history obtained from backend.
  skipEffect: boolean;

  constructor() {
    // sessionId is lazily and dynamically obtained just before first
    // contact with the assistant.
    this.sessionId = '';
    this.messages = new Map();
    // by default never skip effects after an update
    this.skipEffect = false;
    const welcomeMsg: ChatMessage = {
      id: 'assistant-welcome',
      text: 'Welcome! I am your personal assistant.',
      isUser: false,
      timestamp: new Date(),
    };
    this.messages.set(welcomeMsg.id, welcomeMsg);
  }

  /**
   * Returns a clone of this ChatThread with the sessionId replaced
   * @param sessionId The new (proper) sessionId obtained from backend
   */
  assignSessionId(sessionId: string): ChatThread {
    // Convert Map to array, sort by timestamp, and convert back to Map
    const result = new ChatThread();
    result.sessionId = sessionId;
    result.messages = new Map(
      Array
        .from(this.messages.entries())
        .sort((a, b) => a[1].timestamp.getTime() - b[1].timestamp.getTime()),
    );
    return result;
  }

  /**
   * Returns a clone of this ChatThread with the given assistant response
   * appended at the end of the list of conversation.
   * @param id
   * @param text assistant response
   */
  appendAssistantResponse(id: string, text: string): ChatThread {
    const existingMessage = this.messages.get(id);
    const msg: ChatMessage = {
      id,
      text: existingMessage ? existingMessage.text + text : text,
      isUser: false,
      timestamp: existingMessage ? existingMessage.timestamp : new Date(),
    };
    return this.setChatMessage(msg);
  }

  /**
   * Returns a clone of this ChatThread with the given user text
   * appended at the end of the list of conversation.
   * @param text text entered by the user
   * @param id optional id. Defaults to `Date.now()`
   */
  addUserMessage(text: string, id?: string): ChatThread {
    const msg: ChatMessage = {
      id: id ?? Date.now().toString(),
      text,
      isUser: true,
      timestamp: new Date(),
    };
    return this.setChatMessage(msg);
  }

  /**
   * Clones this chat thread, and sets the `skipEffect` property to true,
   * indicating that effects should not be run.
   */
  withSkipEffect(): ChatThread {
    const result = new ChatThread();
    result.skipEffect = true;
    result.sessionId = this.sessionId;
    result.messages = new Map(this.messages);
    return result;
  }

  /**
   * Convenience method that returns the clone of this ChatThread
   * with the given message appended at the end. Ensures that the
   * conversation is sorted based on the timestamp of the message
   * @param msg the chat message (user or assistant)
   * @private
   */
  private setChatMessage(msg: ChatMessage): ChatThread {
    const updatedMessages = new Map(this.messages);
    updatedMessages.set(msg.id, msg);

    // Convert Map to array, sort by timestamp, and convert back to Map
    const result = new ChatThread();
    result.sessionId = this.sessionId;
    result.messages = new Map(
      Array
        .from(updatedMessages.entries())
        .sort((a, b) => a[1].timestamp.getTime() - b[1].timestamp.getTime()),
    );
    return result;
  }

  /**
   * Convenience method that returns the last message entered by the user
   */
  getLastUserMessage(): ChatMessage | null {
    // Convert entries to an array and reverse it
    const reversedEntries = Array.from(this.messages.values()).reverse();
    for (let i = 0; i < reversedEntries.length; i += 1) {
      if (reversedEntries[i].isUser) {
        return reversedEntries[i];
      }
    }
    return null;
  }
}
