// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { ParamName, paramDesc } from '../../../../SimulationParamDescriptor';
import { ParamScope } from '../../../../lib/ParamScope';
import { SelectOption } from '../../../../lib/componentTypes/form';
import {
  checkSolutionConstraints,
  getSolutionDivergedWarning,
  getViolatedParamConstraints,
  getWarning,
  isPhysicsCompatible,
} from '../../../../lib/initializationUtils';
import { getJobType } from '../../../../lib/jobNameMap';
import { meshAggregateStats } from '../../../../lib/mesh';
import { Level } from '../../../../lib/notificationUtils';
import { formatNumberCondensed, fromBigInt } from '../../../../lib/number';
import { createPhysicsScope, findPhysicsById, getPhysicsInitialization, updatePhysicsInitialization } from '../../../../lib/physicsUtils';
import { getSimulationParam } from '../../../../lib/simulationParamUtils';
import { ParamWithFallback } from '../../../../lib/weakParamCompare';
import { getPhysicsWithIndex, useExistingSolution, useFixExistingSolution } from '../../../../model/hooks/useExistingSolution';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import * as frontendpb from '../../../../proto/frontend/frontend_pb';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { useJobNameMap } from '../../../../recoil/jobNameMap';
import { useMeshMetadata, useMeshUrlState } from '../../../../recoil/meshState';
import { useEnabledExperiments } from '../../../../recoil/useExperimentConfig';
import useProjectMetadata from '../../../../recoil/useProjectMetadata';
import { useStaticVolumes } from '../../../../recoil/volumes';
import { useWorkflowMap } from '../../../../recoil/workflowState';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { SectionMessage } from '../../../notification/SectionMessage';

export interface ExistingSolutionProps {
  paramScope: ParamScope;
  physicsId: string;
  constraints: ParamWithFallback[],
}

// Information about an individual job.
interface JobInfo {
  workflowId: string;
  jobId: string;
  name: string;
  param: simulationpb.SimulationParam;
  violatedConstraints: ParamWithFallback[];
  physicsCompatible: boolean;
  disableWarning?: string;
  job: frontendpb.GetWorkflowReply_Job;
}

const typeParam = paramDesc[ParamName.InitializationType];

// Create the options for simulation/job select menu
function createJobSelect(infos: JobInfo[], selectedJobId: string): SelectOption<string>[] {
  return infos.map((info) => ({
    name: info.name,
    value: info.jobId,
    selected: selectedJobId === info.jobId,
    disabled: !!info.violatedConstraints.length || !info.physicsCompatible || !!info.disableWarning,
    disabledReason: info.disableWarning ?? 'This simulation has incompatible settings.',
  }));
}

// LMA meshes are not in the same relative place to the underlying CAD as meshes generated elsewise
// Thus the relative paths end up different and direct comparison will imply identical CAD urls
// are different. So instead, we compare the "sha256" section of the path, which is generated by
// *all* LC operators, so all the lccadUrls have them. This will likely need to change when we have
// a CAD service.
function extractSha256Hash(path: string): string | null {
  const match = path.match(/sha256:[a-f0-9]+/);
  return match ? match[0] : null;
}

// Create the options for iterationselect menu. The 'job' argument is used to determine whether the
// solution at an iteration is in a diverged state (i.e. the solver was canceled because it detected
// divergence).
function createIterationSelect(
  solutions: frontendpb.Solution[],
  selected: bigint,
  forAdjoint: boolean,
  job?: frontendpb.GetWorkflowReply_Job,
): SelectOption<bigint>[] {
  const lastIter = solutions.at(solutions.length - 1)?.iter;
  return solutions.map((solution) => {
    const iter = solution.iter;
    const diverged = job && !!getSolutionDivergedWarning(job, iter);
    const notLast = forAdjoint && iter !== lastIter;
    return {
      name: iter === undefined ? 'None' : `${iter}`,
      value: iter ?? 0,
      selected: iter === selected,
      disabled: diverged || notLast,
      disabledReason: diverged ?
        'The solution has diverged and cannot be used for initialization.' :
        'The adjoint solver uses the last solution of the primal simulation.',
    };
  });
}

export const ExistingSolution = (props: ExistingSolutionProps) => {
  // == Props
  const { constraints, paramScope, physicsId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const {
    availableSolutions,
    initState,
    jobState,
    selectedWorkflowId,
    selectedJobId,
    solUrl,
    updateInitialization,
  } = useExistingSolution(projectId, workflowId, jobId);
  const fixExistingSolution = useFixExistingSolution(projectId, workflowId, jobId, readOnly);
  // initialization is a state representing the state of the workflow, job, and solution menus. The
  // param only contains the GCS url for a solution.  Without this initialization state, we would
  // need to do an exhaustive search of all workflows, jobs and solutions to set the menus to the
  // proper state representing the selected solution.
  const projectMetadata = useProjectMetadata(projectId);
  const workflowIds = useMemo(() => (
    projectMetadata?.workflow.map((workflow) => workflow.workflowId) || []
  ), [projectMetadata]);
  const workflowMap = useWorkflowMap(projectId, workflowIds);
  const jobNameMap = useJobNameMap(projectId);
  const [meshUrl] = useMeshUrlState(projectId);
  const meshMetadata = useMeshMetadata(projectId, meshUrl.mesh)?.meshMetadata;
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  // == Hooks
  const { simParam, saveParam } = useSimulationConfig();
  const propClasses = useCommonTreePropsStyles();

  const physicsData = getPhysicsWithIndex(simParam, physicsId);

  // This can return one of two different types, InitializationFluid or InitializationHeat, but in
  // this component we only care about the initialization_type, which is common to both.  We can,
  // therefore, treat them isomorphically,
  const initialization = useMemo(
    () => getPhysicsInitialization(physicsData.physics),
    [physicsData.physics],
  );

  // Find all jobs from all workflows that could be used in the initialization.
  const jobInfos = useMemo(() => {
    const result: JobInfo[] = [];
    workflowIds.forEach((otherWorkflowId) => {
      // Get the workflow metadata for each workflow.
      const reply = workflowMap[otherWorkflowId];
      if (reply?.job) {
        Object.values(reply.job).forEach((job) => {
          const otherJobId = job.jobId;
          const name = jobNameMap.getDefault({
            workflowId: otherWorkflowId,
            jobId: otherJobId,
            type: getJobType(reply),
          });
          const otherParam = getSimulationParam(reply.config!);
          const physicsCompatible =
            isPhysicsCompatible(simParam, otherParam, geometryTags, staticVolumes);
          const violatedConstraints: ParamWithFallback[] = [];
          let disableWarning: string | undefined;
          if (physicsCompatible) {
            const otherPhysics = otherParam.physics[physicsData.index];
            const scope = createPhysicsScope(
              otherParam,
              otherPhysics,
              experimentConfig,
              geometryTags,
              staticVolumes,
            );
            if (scope) {
              violatedConstraints.push(
                ...getViolatedParamConstraints(paramScope, scope, constraints),
              );
            }
            disableWarning = checkSolutionConstraints(simParam, otherParam);
          }
          result.push({
            workflowId: otherWorkflowId,
            jobId: otherJobId,
            name,
            param: otherParam,
            violatedConstraints,
            physicsCompatible,
            disableWarning,
            job,
          });
        });
      }
    });
    result.sort((a: JobInfo, b: JobInfo) => (a.name > b.name ? 1 : -1));
    return result;
  }, [
    constraints,
    experimentConfig,
    jobNameMap,
    paramScope,
    physicsData,
    simParam,
    workflowIds,
    workflowMap,
    geometryTags,
    staticVolumes,
  ]);

  const selectedSolutionSeq = BigInt(initState ? initState.iter : -1);
  const selectedJobState = jobInfos.find((info) => info.jobId === selectedJobId);
  // Determine if the selected iteration is in a diverged state (i.e. it is the last iteration in a
  // job that has been canceled because of divergence). Note that even if a solution in that state
  // cannot be explicitly selected we can still get into that state by using CopyToSolution while a
  // diverged solution is selected.
  const selectedIterationWarning = (
    selectedJobState?.job && getSolutionDivergedWarning(selectedJobState.job, selectedSolutionSeq)
  );

  // Disable menus as appropriate while fetching info from backend.
  const disableSimulationMenu = readOnly || !jobInfos.length;
  const disableSolutionMenu = disableSimulationMenu || !availableSolutions.length;

  fixExistingSolution(physicsId);

  const selectedSimWarning = useMemo(() => {
    if (selectedJobState) {
      const selectedParam = selectedJobState.param;
      const selectedPhysics = selectedParam.physics[physicsData.index];
      if (!selectedJobState.physicsCompatible || !selectedPhysics) {
        return ['Incompatible physics.'];
      }
      const selectedParamScope = createPhysicsScope(
        selectedParam,
        selectedPhysics,
        experimentConfig,
        geometryTags,
        staticVolumes,
      );
      // Show warnings if the currently selected solution is incompatible (i.e. a constraint is
      // violated)
      return selectedJobState.violatedConstraints?.map(
        (constraint) => getWarning(constraint, paramScope, selectedParamScope),
      );
    }
    return [];
  }, [selectedJobState, experimentConfig, paramScope, physicsData, geometryTags, staticVolumes]);

  const solMetadata = useMeshMetadata(projectId, solUrl);
  const meshStats = useMemo(() => meshAggregateStats(meshMetadata), [meshMetadata]);
  const meshMessage = useMemo(() => {
    // Should choose the larger of the mesh cv count and the LMA target cv count (if LMA enabled)
    // Same logic as gpupreference.go::nCVInSimulation
    const currentMeshCvCount = meshStats.counters.controlVolume;
    const meshMethod = simParam?.adaptiveMeshRefinement?.meshingMethod;
    const isLMA = (meshMethod === simulationpb.MeshingMethod.MESH_METHOD_AUTO);
    const nCvLMATarget = 1_000_000 * fromBigInt(
      simParam.adaptiveMeshRefinement?.targetCvMillions?.value ?? 0,
    );
    const targetCvCount = isLMA ? Math.max(nCvLMATarget, currentMeshCvCount) : currentMeshCvCount;

    const sourceMeshMetadata = solMetadata?.meshMetadata;
    const sourceCvCount = meshAggregateStats(sourceMeshMetadata).counters.controlVolume;

    const isInterpolation = solMetadata && (sourceCvCount !== currentMeshCvCount);
    // Directly check if the solution mesh is a lcmeshbundle (and thus is from a transient moving
    // grid problem)
    const isMovingGrid = solMetadata?.solnMetadata?.meshUrl?.endsWith('.lcmeshbundle');

    let isSameGeo = true;
    if (sourceMeshMetadata?.lccadUrl && meshMetadata?.lccadUrl) {
      const sourceSha = extractSha256Hash(sourceMeshMetadata?.lccadUrl);
      const meshSha = extractSha256Hash(meshMetadata?.lccadUrl);
      isSameGeo = sourceSha === meshSha;
    }

    // Must be kept in sync with FVMJobSizeForGPUType in gpupreference.go
    // Last changed on 2024-05-17
    const mayAllocateLargerNode = (sourceCvCount / 1.1) > targetCvCount;

    const sourceCvString = `~${formatNumberCondensed(sourceCvCount)} CVs`;
    const currentCvString = `~${formatNumberCondensed(currentMeshCvCount)} CVs`;
    const lmaCvString = `~${formatNumberCondensed(nCvLMATarget)} CVs`;

    if (!isInterpolation) {
      return {
        message: '',
        messageType: 'info',
        messageTitle: '',
      };
    }

    if (isMovingGrid) {
      return {
        message: 'Cannot initialize from a solution on a different mesh ' +
          'for transient simulations with moving frames.',
        messageType: 'error',
        messageTitle: 'Invalid selection.',
      };
    }

    const messagePrefix = `You're about to interpolate from a ${sourceCvString} mesh onto` +
      ` a ${currentCvString} mesh`;
    if (!isSameGeo) {
      const message = `${messagePrefix}, generated from a different geometry. This may ` +
        'adversely effect interpolation quality and credit use.';
      return {
        message,
        messageType: 'warning',
        messageTitle: 'Initializing from a mesh generated for a different geometry.',
      };
    }

    // LMA requires more information to be displayed
    const lmaSuffix = isLMA ? `, with an LMA target mesh size of ${lmaCvString}.` : '.';
    const infoMessage = `${messagePrefix}${lmaSuffix}`;
    if (mayAllocateLargerNode) {
      const message = `${infoMessage} To prevent memory issues, resources will be allocated ` +
        `based on the larger mesh size, potentially increasing credit consumption.`;
      return {
        message,
        messageType: 'warning',
        messageTitle: 'Initializing from substantially larger mesh.',
      };
    } // No warning necessary
    return {
      message: infoMessage,
      messageType: 'info',
      messageTitle: '',
    };
  }, [meshStats, meshMetadata, solMetadata, simParam]);

  if (!initialization) {
    return null;
  }

  const typeOptions = paramScope.enabledChoices(typeParam).map(
    (choice) => ({
      name: choice.text,
      value: choice.enumNumber,
      selected: initialization.initializationType === choice.enumNumber,
      tooltip: choice.help,
    }),
  );

  // Make sure that the initialization_type param is enabled.
  if (!typeOptions.length) {
    throw Error('Expected enabled initialization_type');
  }

  return (
    <>
      <LabeledInput
        help={typeParam.help}
        label={typeParam.text}>
        <DataSelect
          asBlock
          disabled={readOnly}
          onChange={(newValue: simulationpb.InitializationType) => saveParam((newParam) => {
            const newPhysics = findPhysicsById(newParam, physicsId);
            updatePhysicsInitialization(
              newPhysics,
              { type: newValue },
            );
          })}
          options={typeOptions}
          size="small"
        />
      </LabeledInput>
      {simParam.physics.length > 1 && (
        <div className={propClasses.sectionMessageContainer}>
          <div className={propClasses.sectionMessages}>
            <SectionMessage level="info" message="The settings below are applied to all physics." />
          </div>
        </div>
      )}
      <LabeledInput
        help="Simulation to be used as initialization."
        label="Simulation">
        <DataSelect
          asBlock
          disabled={disableSimulationMenu}
          faultType={selectedSimWarning?.length ? 'warning' : undefined}
          onChange={(newJob: string) => {
            const info = jobInfos.find((jobInfo) => jobInfo.jobId === newJob);
            updateInitialization(
              info!.workflowId,
              info!.jobId,
              -1,
            );
          }}
          options={createJobSelect(jobInfos, selectedJobId)}
          size="small"
        />
      </LabeledInput>
      <LabeledInput
        help="Iteration of the simulation to be used as initialization."
        label="Iteration">
        <DataSelect
          asBlock
          disabled={disableSolutionMenu}
          faultType={selectedIterationWarning ? 'warning' : undefined}
          onChange={(newIteration: bigint) => {
            updateInitialization(selectedWorkflowId, selectedJobId, fromBigInt(newIteration));
            const initSolution = availableSolutions.find(
              (solution) => solution.iter === (newIteration),
            );
            saveParam((newParam) => {
              const newPhysics = findPhysicsById(newParam, physicsId);
              updatePhysicsInitialization(
                newPhysics,
                { url: initSolution!.url, type: simulationpb.InitializationType.EXISTING_SOLUTION },
              );
            });
          }}
          options={createIterationSelect(
            availableSolutions,
            selectedSolutionSeq,
            simParam.general?.floatType === simulationpb.FloatType.ADA1D,
            selectedJobState?.job,
          )}
          placeholderText={!jobState && selectedJobId ? 'Loading...' : 'None'}
          size="small"
        />
      </LabeledInput>
      <div className={propClasses.sectionMessageContainer}>
        {selectedSimWarning?.map((warning) => (
          <div className={propClasses.sectionMessages} key={warning}>
            <SectionMessage
              level="warning"
              message={warning}
              title="Incompatible simulation selected."
            />
          </div>
        ))}
        {/* Show a section warning if the selected solution is in a diverged state */}
        {!!selectedIterationWarning && (
          <div className={propClasses.sectionMessages}>
            <SectionMessage
              level="warning"
              message={selectedIterationWarning}
              title="Invalid iteration selected."
            />
          </div>
        )}
        {!!meshMessage.message && (
          <div className={propClasses.sectionMessages}>
            <SectionMessage
              level={meshMessage.messageType as Level}
              message={meshMessage.message}
              title={meshMessage.messageTitle}
            />
          </div>
        )}
      </div>
    </>
  );
};
