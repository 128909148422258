import { atom, useRecoilState } from 'recoil';

import { getLocalStorageData } from '@/lib/browserStorage';
import { localStorageEffect } from '@/lib/persist';

export const TRIAD_POSITION_KEY = 'triad-position';

const DEFAULT_TRIAD_POSITION = [0, 25] satisfies [number, number];

export const getDefaultTriadPosition = () => (
  getLocalStorageData(TRIAD_POSITION_KEY, DEFAULT_TRIAD_POSITION)
);

export const lcvisTriadPosition = atom<[number, number]>({
  key: 'lcvisTriadPosition',
  default: getDefaultTriadPosition(),
  effects: [
    localStorageEffect(TRIAD_POSITION_KEY),
  ],
});

export const useLcvisTriadPosition = () => useRecoilState(lcvisTriadPosition);
