// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { canGroupNodes, canUngroupNode } from '../../lib/groupingUtils';
import { NodeGroupData } from '../../lib/nodeGroupMap';
import { RecoilProjectKey } from '../../lib/persist';
import { SimulationTreeNode } from '../../lib/simulationTree/node';
import {
  CameraGroupMapAccessType,
  useCameraGroupData,
  useGroupCameras,
  useUngroupCameras,
} from '../../recoil/cameraState';
import {
  EntityGroupData,
  useEntityGroupData,
  useGroupEntities,
  useUngroupEntities,
} from '../../recoil/entityGroupState';
import { useGeometryUsesTags } from '../../recoil/geometry/geometryUsesTags';
import { useCurrentView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

type GroupMapDataType = EntityGroupData | NodeGroupData;

export const useNodeGrouping = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode, selectedNodeIds, setScrollTo, setSelection } = useSelectionContext();

  // == Grouping callbacks
  const onUngroup = (groupIds: string[]) => {
    setSelection(groupIds);
    setScrollTo({ node: groupIds[0], fast: true });
  };

  const onNewGroup = (groupId: string) => {
    setSelection([groupId]);
    setScrollTo({ node: groupId, fast: true });
  };

  // == Recoil
  const cameraKey: RecoilProjectKey = { projectId, workflowId, jobId };
  const cameraGroupData = useCameraGroupData(cameraKey, CameraGroupMapAccessType.ALL);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const groupCameras = useGroupCameras(cameraKey, onNewGroup);
  const groupEntities = useGroupEntities(projectId, workflowId, jobId, onNewGroup);
  const ungroupCameras = useUngroupCameras(cameraKey, onUngroup);
  const ungroupEntities = useUngroupEntities(projectId, workflowId, jobId, onUngroup);
  const currentView = useCurrentView();
  const geoUsesTags = useGeometryUsesTags(projectId, workflowId, jobId);

  const areSelectedNodesCameras = useMemo(
    () => selectedNodeIds.every((id) => cameraGroupData.groupMap.has(id)),
    [cameraGroupData, selectedNodeIds],
  );

  const canUngroup = useCallback(
    (node: SimulationTreeNode, groupData: GroupMapDataType) => !geoUsesTags && canUngroupNode(
      node,
      groupData,
      readOnly,
      currentView,
    ),
    [currentView, readOnly, geoUsesTags],
  );

  const canGroup = useCallback(
    (nodeIds: string[], groupData: GroupMapDataType) => !geoUsesTags && canGroupNodes(
      nodeIds,
      groupData,
      readOnly,
      currentView,
    ),
    [currentView, readOnly, geoUsesTags],
  );

  const canGroupSelectedNodes = useMemo(() => {
    const groupMap = selectedNodeIds.every((id) => cameraGroupData.groupMap.has(id)) ?
      cameraGroupData :
      entityGroupData;
    return canGroup(selectedNodeIds, groupMap);
  }, [cameraGroupData, canGroup, entityGroupData, selectedNodeIds]);

  const canUngroupSelectedNode = useMemo(() => {
    if (selectedNode) {
      const groupMap = cameraGroupData.groupMap.has(selectedNode.id) ?
        cameraGroupData :
        entityGroupData;
      return canUngroup(selectedNode, groupMap);
    }
    return false;
  }, [cameraGroupData, canUngroup, entityGroupData, selectedNode]);

  const selectedNodeIdsSet = useMemo(() => new Set(selectedNodeIds), [selectedNodeIds]);

  // If the user has right-clicked the row with ID `nodeId`, and the row is also selected, then
  // apply grouping to all selected nodes.  Otherwise, if the user has right-clicked the row
  // `nodeId` and it's not selected, then apply grouping just to that row.
  const groupableNodes = useCallback(
    (nodeId: string) => (selectedNodeIdsSet.has(nodeId) ? selectedNodeIds : [nodeId]),
    [selectedNodeIds, selectedNodeIdsSet],
  );

  return {
    canGroup,
    canGroupSelectedNodes,
    canUngroup,
    canUngroupSelectedNode,
    groupCameras,
    groupEntities,
    ungroupCameras,
    ungroupEntities,
    areSelectedNodesCameras,
    groupableNodes,
  };
};
