import React, { useCallback, useEffect, useState } from 'react';

import { DefaultValue, atomFamily, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { Assist, isAssistantResponding } from '../../../lib/assistantCall';
import { Logger } from '../../../lib/observability/logs';
import { useProjectContext } from '../../context/ProjectContext';

import { Chat, ChatMessage } from './Chat';

const logger = new Logger('AssistantChat');

/**
 * The state for the AI assistant chat messages. This state is keyed on the project id.
*/
async function sendToAssistant(
  projectId: string,
  userMessage: string,
  setMessages: (callback: (currMessages: Map<string, ChatMessage> | DefaultValue)
    => Map<string, ChatMessage>
  ) => void,
) {
  const id = `${Date.now()}`;
  const finalId = `${id}-response`;

  const updateMessages = (response: string) => {
    setMessages((currMessages) => {
      if (currMessages instanceof DefaultValue) {
        return new Map<string, ChatMessage>();
      }

      const existingMessage = currMessages.get(finalId);
      const newMessage: ChatMessage = {
        id: finalId,
        text: existingMessage ? existingMessage.text + response : response,
        isUser: false,
        timestamp: existingMessage ? existingMessage.timestamp : new Date(),
      };

      const updatedMessages = new Map(currMessages);
      updatedMessages.set(finalId, newMessage);

      // Convert Map to array, sort by timestamp, and convert back to Map
      const sortedMessages = Array.from(updatedMessages.entries())
        .sort((a, b) => a[1].timestamp.getTime() - b[1].timestamp.getTime());

      return new Map(sortedMessages);
    });
  };

  try {
    await Assist(id, userMessage, updateMessages);
  } catch (error) {
    logger.error('Error communicating with assistant:', error);
    updateMessages("Sorry, I couldn't process your request. Please try again.");
  }
}

const assistantMessageState = atomFamily<Map<string, ChatMessage>, string>({
  key: 'assistantMessageState',
  default: new Map<string, ChatMessage>(),
  effects: (projectId: string) => [
    ({ setSelf }) => {
      setSelf((prevMessages) => {
        const currentMessages = prevMessages instanceof DefaultValue ? new Map() : prevMessages;
        currentMessages.set('assistant-welcome', {
          id: 'assistant-welcome',
          text: 'What can I help you with today?',
          isUser: false,
          timestamp: new Date(),
        });
        return new Map(currentMessages);
      });
    },
    ({ setSelf, onSet }) => {
      onSet(async (newMessages, oldMessages) => {
        const userMessages = Array.from(newMessages.values()).filter((msg) => msg.isUser);
        const lastMessage = userMessages[userMessages.length - 1];
        if (lastMessage) {
          await sendToAssistant(projectId, lastMessage.text, setSelf);
        }
      });
    },
  ],
});

export const useResetAssistantChat = () => {
  const { projectId } = useProjectContext();
  const resetMessages = useResetRecoilState(assistantMessageState(projectId));

  return useCallback(async () => {
    try {
      await Assist(projectId, 'reset', (val: string) => {
        console.debug(`Assistant reset response: ${val}`);
      });
      resetMessages();
    } catch (error) {
      logger.error('Error resetting assistant:', error);
    }
  }, [projectId, resetMessages]);
};

export const useAssistantMessages = (
  projectId: string,
) => useRecoilState(assistantMessageState(projectId));

export const useSetAssistantMessages = (
  projectId: string,
) => useSetRecoilState(assistantMessageState(projectId));

export const useAssistantMessagesValue = (
  projectId: string,
) => useRecoilValue(assistantMessageState(projectId));

export const useAddNewAssistantMessage = (projectId: string) => {
  const setMessages = useSetAssistantMessages(projectId);

  return useCallback((text: string) => {
    const newMessage: ChatMessage = {
      id: `${Date.now()}`,
      text,
      isUser: true,
      timestamp: new Date(),
    };

    setMessages((currMessages) => {
      const updatedMessages = new Map(currMessages);
      updatedMessages.set(newMessage.id, newMessage);
      return updatedMessages;
    });
  }, [setMessages]);
};

/**
 * Chat component for the AI assistant.
 * It displays messages from the assistant and allows the user to send messages,
 * which are then processed by the assistant.
 * It uses the Chat component internally and manages the messages state.
 */
export const AssistantChat = () => {
  const { projectId } = useProjectContext();
  const [messages] = useAssistantMessages(projectId);
  const [isResponding, setIsResponding] = useState(false);
  const resetChat = useResetAssistantChat();
  const addNewAssistantMessage = useAddNewAssistantMessage(projectId);

  useEffect(() => {
    const subscription = isAssistantResponding.subscribe((value) => {
      setIsResponding(value);
    });
    // TODO: Remove this when we persist chat history
    resetChat().catch(() => { });
    return () => subscription.unsubscribe();
  }, [resetChat]);

  return (
    <Chat
      disabled={isResponding}
      messages={Array.from(messages.values())}
      onSendMessage={addNewAssistantMessage}
    />
  );
};
