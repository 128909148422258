// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// TODO(LC-6160): Remove the dependencies on components/

export enum NodeType {
  ADJOINT_SETTINGS = 'ADJOINT_SETTINGS',
  CAMERA = 'CAMERA',
  CAMERA_CONTAINER = 'CAMERA_CONTAINER',
  CAMERA_GLOBAL_CONTAINER = 'CAMERA_GLOBAL_CONTAINER',
  CAMERA_GROUP = 'CAMERA_GROUP',
  EQUATIONS = 'EQUATIONS',
  EXPLORATION_POLICY = 'EXPLORATION_POLICY',
  EXPLORATION_VARIABLE = 'EXPLORATION_VARIABLE',
  EXPLORATION_VARIABLE_CONTAINER = 'EXPLORATION_VARIABLE_CONTAINER',
  FAR_FIELD = 'FAR_FIELD',
  FILTER = 'FILTER',
  GENERAL_SETTINGS = 'GENERAL_SETTINGS',
  GEOMETRY = 'GEOMETRY',
  GEOMETRY_CONTACT = 'GEOMETRY_CONTACT',
  GEOMETRY_CONTACT_CONTAINER = 'GEOMETRY_CONTACT_CONTAINER',
  GEOMETRY_MODIFICATION = 'GEOMETRY_MODIFICATION',
  GEOMETRY_VERSIONS_LOADED_CONTAINER = 'GEOMETRY_VERSIONS_LOADED_CONTAINER',
  GEOMETRY_VERSIONS_LOADED = 'GEOMETRY_VERSIONS_LOADED',
  MATERIAL_CONTAINER = 'MATERIAL_CONTAINER',
  MATERIAL_FLUID = 'MATERIAL_FLUID',
  MATERIAL_SOLID = 'MATERIAL_SOLID',
  MESH = 'MESH',
  MESH_BOUNDARY = 'MESH_BOUNDARY',
  MESH_ADAPTATION_BOUNDARY = 'MESH_ADAPTATION_BOUNDARY',
  MESH_MODEL = 'MESH_MODEL',
  MESH_SIZE = 'MESH_SIZE',
  MONITOR_PLANE = 'MONITOR_PLANE',
  MOTION_FRAME = 'MOTION_FRAME',
  MOTION_GLOBAL_FRAME = 'MOTION_GLOBAL_FRAME',
  OUTPUT = 'OUTPUT',
  OUTPUT_CONTAINER = 'OUTPUT_CONTAINER',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  CUSTOM_FIELD_CONTAINER = 'CUSTOM_FIELD_CONTAINER',
  PARTICLE_GROUP = 'PARTICLE_GROUP',
  PHYSICAL_BEHAVIOR = 'PHYSICAL_BEHAVIOR',
  PHYSICS_CONTAINER = 'PHYSICS_CONTAINER',
  PHYSICS_FLUID = 'PHYSICS_FLUID',
  PHYSICS_FLUID_BOUNDARY_CONDITION = 'PHYSICS_FLUID_BOUNDARY_CONDITION',
  PHYSICS_FLUID_BOUNDARY_CONDITION_CONTAINER = 'PHYSICS_FLUID_BOUNDARY_CONDITION_CONTAINER',
  PHYSICS_FLUID_INITIALIZATION = 'PHYSICS_FLUID_INITIALIZATION',
  PHYSICS_FLUID_PHYSICAL_MODEL_CONTAINER = 'PHYSICS_FLUID_PHYSICAL_MODEL_CONTAINER',
  PHYSICS_HEAT = 'PHYSICS_HEAT',
  PHYSICS_HEAT_BOUNDARY_CONDITION = 'PHYSICS_HEAT_BOUNDARY_CONDITION',
  PHYSICS_HEAT_BOUNDARY_CONDITION_CONTAINER = 'PHYSICS_HEAT_BOUNDARY_CONDITION_CONTAINER',
  PHYSICS_HEAT_HEAT_SOURCE = 'PHYSICS_HEAT_HEAT_SOURCE',
  PHYSICS_HEAT_HEAT_SOURCE_CONTAINER = 'PHYSICS_HEAT_HEAT_SOURCE_CONTAINER',
  PHYSICS_HEAT_INITIALIZATION = 'PHYSICS_HEAT_INITIALIZATION',
  PHYSICS_MULTI = 'PHYSICS_MULTI',
  PHYSICS_MULTI_INTERFACE = 'PHYSICS_MULTI_INTERFACE',
  PHYSICS_PERIODIC_PAIR = 'PHYSICS_PERIODIC_PAIR',
  PHYSICS_SLIDING_INTERFACE = 'PHYSICS_SLIDING_INTERFACE',
  PHYSICS_VOLUME_SELECTION = 'PHYSICS_VOLUME_SELECTION',
  PLOT = 'PLOT',
  PLOT_CONTAINER = 'PLOT_CONTAINER',
  POINT_CONTAINER = 'POINT_CONTAINER',
  POROUS_MODEL = 'POROUS_MODEL',
  PROBE_POINT = 'PROBE_POINT',
  POST_PROCESSING_EXTRACT = 'POST_PROCESSING_EXTRACT',
  POST_PROCESSING_EXTRACT_CONTAINER = 'POST_PROCESSING_EXTRACT_CONTAINER',
  REFERENCE_VALUE = 'REFERENCE_VALUE',
  REFINEMENT_REGION = 'REFINEMENT_REGION',
  REFINEMENT_REGION_CONTAINER = 'REFINEMENT_REGION_CONTAINER',
  ROOT = 'ROOT',
  ROOT_FLOATING_GEOMETRY = 'ROOT_FLOATING_GEOMETRY',
  ROOT_EXPLORATION = 'ROOT_EXPLORATION',
  ROOT_GEOMETRY = 'ROOT_GEOMETRY',
  ROOT_SIMULATION = 'ROOT_SIMULATION',
  SOLVER_SETTINGS = 'SOLVER_SETTINGS',
  STOPPING_CONDITIONS = 'STOPPING_CONDITIONS',
  SURFACE = 'SURFACE',
  SURFACE_CONTAINER = 'SURFACE_CONTAINER',
  SURFACE_GROUP = 'SURFACE_GROUP',
  TAGS_FACE = 'TAGS_FACE',
  TAGS_BODY = 'TAGS_BODY',
  TAGS_CONTAINER = 'TAGS_CONTAINER',
  TRANSIENT_SETTINGS = 'TRANSIENT_SETTINGS',
  VOLUME = 'VOLUME',
  VOLUME_CONTAINER = 'VOLUME_CONTAINER',
  UPLOAD_CAD_OR_MESH = 'UPLOAD_CAD_OR_MESH',
  CAD_SOLID_OR_FLUID = 'CAD_SOLID_OR_FLUID',
  GEOMETRY_CHECK = 'GEOMETRY_CHECK',
  SHRINK_WRAP = 'SHRINK_WRAP',
  CREATE_FARFIELD = 'CREATE_FARFIELD',
}

/**
 * When we activate the NodeSubselect for Surfaces, we can pass this array of different Surfaces
 * to the NodeSubselect to filter the Geometry tree and only show the Surfaces entities.
 */
export const SURFACE_NODE_TYPES = [
  NodeType.SURFACE,
  NodeType.PARTICLE_GROUP,
  NodeType.MONITOR_PLANE,
  NodeType.SURFACE_GROUP,
  NodeType.FAR_FIELD,
];

export const TAGS_NODE_TYPES = [
  NodeType.TAGS_BODY,
  NodeType.TAGS_FACE,
  NodeType.TAGS_CONTAINER,
];

export const TRANSPARENCY_NODE_TYPES = [
  NodeType.TAGS_BODY,
  NodeType.TAGS_FACE,
  NodeType.SURFACE,
  NodeType.PARTICLE_GROUP,
  NodeType.MONITOR_PLANE,
  NodeType.SURFACE_GROUP,
  NodeType.FAR_FIELD,
];

export const CREATE_TAG_NODE_TYPES = [
  NodeType.SURFACE,
  NodeType.PARTICLE_GROUP,
  NodeType.MONITOR_PLANE,
  NodeType.SURFACE_GROUP,
  NodeType.FAR_FIELD,
  NodeType.GEOMETRY,
  NodeType.VOLUME,
];

export const GEOMETRY_TREE_NODE_TYPES = new Set([
  NodeType.GEOMETRY_CONTACT,
  NodeType.GEOMETRY_CONTACT_CONTAINER,
  NodeType.FAR_FIELD,
  NodeType.GEOMETRY,
  NodeType.SURFACE,
  NodeType.SURFACE_CONTAINER,
  NodeType.SURFACE_GROUP,
  NodeType.TAGS_FACE,
  NodeType.TAGS_BODY,
  NodeType.PARTICLE_GROUP,
  NodeType.MONITOR_PLANE,
  NodeType.POINT_CONTAINER,
  NodeType.PROBE_POINT,
  NodeType.VOLUME,
  NodeType.VOLUME_CONTAINER,
]);

export const NODES_WITHOUT_CHECKMARK = [
  NodeType.CAMERA_CONTAINER,
  NodeType.FILTER,
  NodeType.MOTION_GLOBAL_FRAME,
  NodeType.OUTPUT_CONTAINER,
  NodeType.CUSTOM_FIELD_CONTAINER,
  NodeType.SOLVER_SETTINGS,
  NodeType.PLOT_CONTAINER,
  NodeType.POST_PROCESSING_EXTRACT_CONTAINER,
  NodeType.GEOMETRY_VERSIONS_LOADED_CONTAINER,
];

export const GEOMETRY_TREE_DATA_LOCATOR = 'geometryTreePanelList';
export const SIMULATION_TREE_DATA_LOCATOR = 'simulationTreePanelRoot';
export const MODIFICATIONS_TREE_DATA_LOCATOR = 'modificationTreePanelList';

export const ROOT_FLOATING_GEOMETRY_CONTAINER_ID = '__containerRootFloatingGeometry';
export const ROOT_CONTAINER_ID = '__containerRoot';
export const ROOT_SIMULATION_CONTAINER_ID = '__containerRootSimulation';
export const ROOT_GEOMETRY_CONTAINER_ID = '__containerRootGeometry';

export const GEOMETRY_NODE_ID = '__containerGeometry';
export const POINT_CONTAINER_ID = '__containerPoint';
export const SURFACE_CONTAINER_ID = '__containerSurface';
export const VOLUME_CONTAINER_ID = '__containerVolume';
export const TAGS_CONTAINER_ID = '__containerTags';

export const GENERAL_SETTINGS_NODE_ID = '__generalSettingsContacts';
export const GEOMETRY_CONTACTS_CONTAINER_ID = '__geometryContainerContacts';

export const ADJOINT_SETTINGS_NODE_ID = '__adjointSettings';

export const SOLVER_SETTINGS = '__solverSettings';

export const MESH_NODE_ID = '__containerMesh';
export const REFINEMENT_REGION_CONTAINER_ID = '__containerRefinementRegion';

export const MATERIAL_CONTAINER_NODE_ID = '__containerMaterial';
export const PHYSICS_CONTAINER_NODE_ID = '__containerPhysics';

export const PLOT_CONTAINER_ID = '__containerPlot';
export const GLOBAL_FRAME_NODE_ID = '__globalFrame';

export const STOPPING_CONDITIONS_NODE_ID = '__stoppingCondition';

export const OUTPUT_CONTAINER_ID = '__containerOutputs';
export const OUTPUT_REFERENCE_VALUE_NODE_ID = '__outputReferenceValue';

export const CUSTOM_FIELD_CONTAINER_ID = '__containerCustomField';

export const ROOT_EXPLORATION_CONTAINER_ID = '__containerRootExploration';
export const VARIABLE_CONTAINER_ID = '__containerExplorationVariable';
export const POLICY_CONTAINER_ID = '__containerExplorationPolicy';

export const CAMERA_GLOBAL_CONTAINER_ID = '__containerCameraGlobal';
export const CAMERA_CONTAINER_ID = '__containerCamera';

export const POST_PROCESSING_EXTRACT_CONTAINER_ID = '__containerPostProcessingExtract';

// New node IDs for geometry tab
export const UPLOAD_CAD_OR_MESH_NODE_ID = '__uploadCadOrMesh';
export const CAD_SOLID_OR_FLUID_NODE_ID = '__cadSolidOrFluid';
export const GEOMETRY_CHECK_NODE_ID = '__geometryCheck';
export const SHRINK_WRAP_NODE_ID = '__shrinkWrap';
export const CREATE_FARFIELD_NODE_ID = '__createFarfield';

/**
 * Data class for a node of any tree-like visual interface, e.g. GeometryTreePanel,
 * ModificationTreePanel, and FloatingSimTree (which renders many different things including the
 * different "checklists" and Results). Each node can be displayed either as a row of the simulation
 * tree panel or in more detail in the properties panel. For each node type, we may have a
 * type-specific row component and/or a type-specific properties panel component.
 */
export class SimulationTreeNode {
  public parent: SimulationTreeNode | null = null;
  private descendantsMap: Map<string, SimulationTreeNode> = new Map();

  constructor(
    public readonly type: NodeType,
    // A unique ID for the node.
    public id: string,
    // The name that is displayed describing the node.
    public name: string,
    // The children are all the nodes contained inside of this one. They are
    // only displayed when this node is open.
    public children: SimulationTreeNode[] = [],
  ) {
    this.children.forEach((child: SimulationTreeNode) => {
      child.parent = this;
    });
    this.buildDescendantsMap();
  }

  private buildDescendantsMap(): void {
    this.descendantsMap.clear();
    this.descendantsMap.set(this.id, this);
    this.children.forEach((child) => {
      child.traverse((node) => {
        this.descendantsMap.set(node.id, node);
      });
    });
  }

  // Gets the descendant with the given ID.
  public getDescendant(id: string): SimulationTreeNode | null {
    return this.descendantsMap.get(id) || null;
  }

  // Returns true iff this node is a descendant of one of the nodes in the provided list of IDs.  If
  // typesWhitelist is specified, only checks parents whose type is in the whitelist.
  public isDescendant(ids: Set<string>, typesWhitelist: Set<NodeType>): boolean {
    for (let cur = this.parent; cur != null; cur = cur.parent) {
      if (typesWhitelist.size && !typesWhitelist.has(cur.type)) {
        continue;
      }
      if (ids.has(cur.id)) {
        return true;
      }
    }
    return false;
  }

  public traverse(callback: (node: SimulationTreeNode) => void): void {
    callback(this);
    this.children.forEach((child) => child.traverse(callback));
  }
}
