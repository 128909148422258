// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as ProtoDescriptor from '../../../../../ProtoDescriptor';
import {
  ParamGroupName,
  ParamName,
  paramDesc,
  paramGroupDesc,
} from '../../../../../SimulationParamDescriptor';
import { ParamScope } from '../../../../../lib/ParamScope';
import { getAdValue } from '../../../../../lib/adUtils';
import assert from '../../../../../lib/assert';
import {
  isFarfieldBoundaryCondition,
  isInletBoundaryCondition,
} from '../../../../../lib/boundaryConditionUtils';
import { assignDefaultBodyFrame, createFrame, findBodyFrame } from '../../../../../lib/motionDataUtils';
import { getMultiphysicsInterfaceName } from '../../../../../lib/multiphysicsInterfaceUtils';
import { SURFACE_NODE_TYPES, TAGS_NODE_TYPES } from '../../../../../lib/simulationTree/node';
import { useFluidBoundaryCondition } from '../../../../../model/hooks/useFluidBoundaryCondition';
import * as simulationpb from '../../../../../proto/client/simulation_pb';
import { initializeNewNode, useSetNewNodes } from '../../../../../recoil/nodeSession';
import { useSimulationParamScope } from '../../../../../state/external/project/simulation/paramScope';
import Form from '../../../../Form';
import { InputDescription } from '../../../../Form/InputDescription';
import LabeledInput from '../../../../Form/LabeledInput';
import { NumberInput } from '../../../../Form/NumberInput';
import { CollapsibleNodePanel } from '../../../../Panel/CollapsibleNodePanel';
import { InsertElement, ParamForm, paramsToShow } from '../../../../ParamForm';
import QuantityAdornment from '../../../../QuantityAdornment';
import Divider from '../../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../../Theme/commonStyles';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useSelectionContext } from '../../../../context/SelectionManager';
import { LuminaryToggleSwitch } from '../../../../controls/LuminaryToggleSwitch';
import { useGetFanCurve } from '../../../../hooks/boundaryConditions/useFanCurve';
import { useCommonBoundaryCondition } from '../../../../hooks/subselect/useCommonBoundaryCondition';
import { useSimulationConfig } from '../../../../hooks/useSimulationConfig';
import { FluidBCRemoveParams, useFluidBCTabularData } from '../../../../hooks/useTabularData';
import { Link } from '../../../../notification/PanelLinks';
import { SectionMessage } from '../../../../notification/SectionMessage';
import Collapsible from '../../../../transition/Collapsible';
import { AttributesDisplay } from '../../../AttributesDisplay';
import { LabeledSection } from '../../../LabeledSection';
import NodeLink from '../../../NodeLink';
import { NodeSubselect } from '../../../NodeSubselect';
import PropertiesSection from '../../../PropertiesSection';
import { FrameSurfacePanel } from '../../shared/FrameSurfacePanel';

export const removeParams = [
  'PhysicalBoundary',
  'HeatPhysicalBoundary',
  'RoughnessControl',
  'EquivalentSandGrainRoughness',
  // profile bc params that have specific inputs not covered by paramform
  ...FluidBCRemoveParams,
  // fan curve params
  'FanCurveTableData',
];

// Maintain a set of param descriptions that correspond to labels we don't want to display
// We compare against this set in ParamForm.tsx to decide whether to display the label or not
const skipLabelParams = [
  'FarfieldFlowDirection',
  'FarfieldMachNumber',
  'FarfieldVelocityMagnitude',
  'FlowDirection',
  'InletVelocityMagnitude',
  'InletVelocityComponents',
  'MassFlowRate',
  'TotalTemperature',
  'TotalPressure',
  'BcUniformNuTilde',
  'FixedHeatFlux',
  'FixedTemperature',
];

const farfieldAngleBetaDesc = paramDesc[ParamName.FarfieldAngleBeta];
const farieldFlowDirDesc = paramDesc[ParamName.FarfieldFlowDirection];
const physicalBoundDesc = paramDesc[ParamName.PhysicalBoundary];
const eqSandGrainRoughDesc = paramDesc[ParamName.EquivalentSandGrainRoughness];
const bcParamGroup = paramGroupDesc[ParamGroupName.BoundaryConditionsFluid];

const { FARFIELD_ANGLES } = simulationpb.FarFieldFlowDirectionSpecification;

interface BoundaryConditionDefinitionProps {
  nodeId: string;
  paramScope: ParamScope;
}

const BoundaryConditionDefinition = (props: BoundaryConditionDefinitionProps) => {
  // == Props
  const { nodeId, paramScope } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection, setScrollTo } = useSelectionContext();
  assert(!!node, 'No selected fluid boundary condition row (definition panel)');

  // == Recoil
  const setNewNodes = useSetNewNodes();

  // == Custom hooks
  const {
    boundaryCondition,
    replaceBoundaryCondition,
    isDependent,

    hasRoughnessInputs,
    roughnessWarning,
    saveRoughnessControl,
    saveRoughnessValue,
  } = useFluidBoundaryCondition(projectId, workflowId, jobId, readOnly, nodeId);
  assert(!!boundaryCondition, 'No selected fluid boundary conditions (definition panel)');

  const { simParam, saveParamAsync } = useSimulationConfig();
  const { isFanCurve, isInletFanCurve, fanCurveUploadElement } = useGetFanCurve()(node.id);
  const { insertTabularElements } = useFluidBCTabularData(node.id, paramScope, isInletFanCurve);

  // == Data
  const isFarfieldBc = isFarfieldBoundaryCondition(boundaryCondition);
  const bodyFrame = findBodyFrame(simParam);
  const angleDirection = (boundaryCondition.farFieldFlowDirectionSpecification === FARFIELD_ANGLES);
  const profileBcOn = boundaryCondition.profileBc;
  // Only Momentum enabled in ParamForm for dependent WALL bc
  const enableParams = isDependent ? [ParamName.WallMomentum] : undefined;

  const warning = angleDirection ?
    'Far field flow direction from Flow Angles is computed using the Body Frame. ' +
    'Please add a Body Frame to input flow direction angles.' :
    'A Body Frame is needed for computing Lift, Sideforce, ' +
    'Downforce, Roll, Pitch, and Yaw output quantities.';

  const addBodyFrame = async () => {
    const newFrameId = await saveParamAsync((newParam) => {
      const frame = createFrame(newParam, simulationpb.MotionType.NO_MOTION, 'Body Frame');
      assignDefaultBodyFrame(newParam, frame);
      return frame.frameId;
    });

    setNewNodes((oldValue) => [...oldValue, initializeNewNode(newFrameId)]);
    setSelection([newFrameId]);
    setScrollTo({ node: newFrameId });
  };

  const bodyFrameLink: Link = ({
    label: 'Add Body Frame',
    onClick: () => addBodyFrame(),
  });

  const flowDirInfoInsert = angleDirection ? farfieldAngleBetaDesc : farieldFlowDirDesc;
  const flowDirInfoText = angleDirection ?
    'Flow Angles defined in Body Frame' : 'Vector defined in global coordinates';

  const flowDirInfo = (
    <Form.LabeledInput label="">
      <div>{flowDirInfoText}</div>
    </Form.LabeledInput>
  );

  const insertElements: InsertElement[] = [
    { element: flowDirInfo, insert: flowDirInfoInsert, replace: false },
  ];

  const reorderElements: string[] = [];

  if (isFanCurve && fanCurveUploadElement) {
    insertElements.push(
      fanCurveUploadElement,
    );
  }

  // elements for profile BCs are inserted in the order they should appear
  insertElements.push(...insertTabularElements);

  reorderElements.push('profileBc', 'direction_specification', 'flow_direction');
  if (profileBcOn) {
    // type is only shown if profile bc is enabled, must insert to array or else the order of
    // inputs would be incorrect
    reorderElements.splice(1, 0, 'profile_type');
  }

  return (
    <>
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Definition"
          nodeId={nodeId}
          panelName="definition">
          <div>
            <ParamForm<simulationpb.BoundaryConditionsFluid>
              enableParams={enableParams}
              group={bcParamGroup}
              ignoreNestLevel
              insertElement={insertElements}
              onUpdate={replaceBoundaryCondition}
              paramScope={paramScope}
              proto={boundaryCondition}
              readOnly={readOnly}
              removeParams={[
                ...removeParams,
                ...(isInletFanCurve ? [paramDesc[ParamName.ProfileBc].pascalCaseName] : []),
              ]}
              reorderElements={reorderElements}
              skipLabelParams={isInletFanCurve ?
                // when a fan curve is used, the total pressure is not dependent on a multiple
                // choice param so its label must be displayed
                skipLabelParams.filter((label) => label !== 'TotalPressure') : skipLabelParams}
            />
          </div>
          <Collapsible collapsed={!hasRoughnessInputs}>
            <LabeledInput label="Wall Roughness" lead>
              <LuminaryToggleSwitch
                disabled={readOnly}
                onChange={saveRoughnessControl}
                small
                value={boundaryCondition.roughnessControl}
              />
            </LabeledInput>
            <Collapsible collapsed={!boundaryCondition.roughnessControl}>
              <LabeledInput
                help={eqSandGrainRoughDesc.help}
                label="Roughness Height"
                lead>
                <NumberInput
                  disabled={!boundaryCondition.roughnessControl}
                  endAdornment={(
                    <QuantityAdornment
                      quantity={eqSandGrainRoughDesc.quantityType}
                    />
                  )}
                  faultType={roughnessWarning ? 'warning' : undefined}
                  onCommit={saveRoughnessValue}
                  size="small"
                  value={getAdValue(boundaryCondition.equivalentSandGrainRoughness)}
                />
                <Collapsible collapsed={!roughnessWarning}>
                  <InputDescription
                    faultType="warning"
                    value={roughnessWarning}
                  />
                </Collapsible>
              </LabeledInput>
            </Collapsible>
          </Collapsible>
        </CollapsibleNodePanel>
        {isFarfieldBc && bodyFrame && (
          <Form.LabeledInput label="">
            <NodeLink
              asBlock
              nodeIds={bodyFrame ? [bodyFrame.frameId] : []}
              text="Update Body Frame"
            />
          </Form.LabeledInput>
        )}
      </PropertiesSection>
      {isFarfieldBc && !bodyFrame && (
        <PropertiesSection>
          <SectionMessage
            level={angleDirection ? 'warning' : 'info'}
            links={[bodyFrameLink]}
            message={warning}
          />
        </PropertiesSection>
      )}
      <Divider />
    </>
  );
};

/**
 * A panel displaying the properties of a fluid boundary condition
 * @param props
 * @returns
 */
export const PhysicsFluidBoundaryConditionPropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected fluid boundary condition row');

  // == Recoil
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // == Custom hooks
  const commonClasses = useCommonTreePropsStyles();
  const {
    boundaryCondition,
    getParamScope,
    isDependent,
    couplingInterface,
  } = useFluidBoundaryCondition(projectId, workflowId, jobId, readOnly, node.id);
  assert(!!boundaryCondition, 'No selected fluid boundary condition');
  const {
    nodeIds,
    nodeFilter,
    setSurfacesIds,
  } = useCommonBoundaryCondition(projectId, workflowId, jobId, readOnly, node.id, isDependent);

  // == Data
  const physicalBoundary = boundaryCondition.physicalBoundary;
  const isInletBc = isInletBoundaryCondition(boundaryCondition);
  const profileBcOn = boundaryCondition.profileBc;
  const boundaryLabel = (physicalBoundDesc as ProtoDescriptor.MultipleChoiceParam).choices.find(
    (choice) => choice.enumNumber === physicalBoundary,
  )?.text;
  const bcParamScope = getParamScope(paramScope);
  // Get the list of params that ParamForm would show.  If it's empty, don't show the collapsible
  // panel
  const formParams = paramsToShow(bcParamScope, bcParamGroup, removeParams);

  return (
    // the root needs to have relative positioning in order for the profile BC column
    // selector menu to have the correct absolute positioning
    <div className={commonClasses.properties} style={{ position: 'relative' }}>
      {couplingInterface ? (
        <LabeledSection label="Multiphysics Interface">
          <NodeLink
            nodeIds={[couplingInterface.slidingInterfaceId]}
            text={getMultiphysicsInterfaceName(couplingInterface)}
          />
        </LabeledSection>
      ) : (
        <AttributesDisplay attributes={[{ label: 'Type', value: boundaryLabel }]} />
      )}
      <Divider />
      {!!formParams.length && (
        //  Only show the definition section if it contains options
        <BoundaryConditionDefinition nodeId={node.id} paramScope={bcParamScope} />
      )}
      <PropertiesSection>
        <NodeSubselect
          id={node.id}
          independentSelection
          labels={['surfaces']}
          nodeFilter={nodeFilter}
          nodeIds={nodeIds}
          onChange={setSurfacesIds}
          readOnly={readOnly || isDependent}
          referenceNodeIds={[node.id]}
          title="Surfaces"
          visibleTreeNodeTypes={[...SURFACE_NODE_TYPES, ...TAGS_NODE_TYPES]}
        />
      </PropertiesSection>
      <Divider />
      {profileBcOn && isInletBc && (
        <PropertiesSection>
          <FrameSurfacePanel
            node={node}
            surfaces={boundaryCondition.surfaces}
          />
        </PropertiesSection>
      )}
    </div>
  );
};
