// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { noWait, selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView } from '../../lib/componentTypes/context';
import { RecoilProjectKey } from '../../lib/persist';
import { currentViewState } from '../../state/internal/global/currentView';
import { selectedGeometryState } from '../selectedGeometry';
import { routeParamsState } from '../useRouteParams';

import { geometryListState } from './geometryListState';

/**
 * Whether to the geometry being processesed uses tags.
 */
export const geometryUsesTagsSelector = selectorFamily<boolean, RecoilProjectKey>({
  key: 'geometryUsesTagSelector',
  get: (key: RecoilProjectKey) => ({ get }) => {
    if (!key.projectId) {
      return false;
    }
    let geometryId = '';
    const [currentView, routeParams, geometryList] = get(waitForAll([
      currentViewState,
      routeParamsState,
      geometryListState(key.projectId),
    ]));
    if (currentView === CurrentView.GEOMETRY) {
      geometryId = routeParams.geometryId || '';
    } else {
      const selectedGeometry = get(noWait(selectedGeometryState(key)));
      geometryId = selectedGeometry.valueMaybe()?.geometryId ?? '';
    }
    if (!geometryId) {
      return false;
    }
    const geometry = geometryList?.geometries.find((geo) => geo.id === geometryId);
    return geometry?.usesTags ?? false;
  },
});

export const useGeometryUsesTags = (
  projectId: string,
  workflowId: string,
  jobId: string,
) => useRecoilValue(geometryUsesTagsSelector({ projectId, workflowId, jobId }));
