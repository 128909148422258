// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback } from 'react';

import { CameraGroupMapAccessType, useCameraGroupMap } from '../../recoil/cameraState';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

export const useHandleManualCameraViewChange = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds, setSelection } = useSelectionContext();

  const cameraGroupMap = useCameraGroupMap(
    { projectId, workflowId, jobId },
    CameraGroupMapAccessType.ALL,
  );

  return useCallback(() => {
    // When we change the camera view in any way (zoom, rotate, open predefined camera, etc.),
    // we should check if there's a selected saved camera and clear the selection if there is.
    if (selectedNodeIds.some((nodeId) => cameraGroupMap.has(nodeId))) {
      setSelection([]);
    }
  }, [selectedNodeIds, setSelection, cameraGroupMap]);
};
