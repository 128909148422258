// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/workflow/workflow.proto (package luminary.proto.workflow, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, proto3 } from "@bufbuild/protobuf";
import { Checkpoint as Checkpoint$1, Exploration } from "../exploration/exploration_pb.js";
import { GPUType } from "../clusterconfig/clusterconfig_pb.js";
import { RuntimeConstraints } from "../job/job_pb.js";
import { Param } from "../fvm/param_pb.js";
import { SimulationParam } from "../client/simulation_pb.js";
import { ImageRendererConfig } from "../imagerenderer/imagerenderer_pb.js";
import { MeshType } from "../upload/upload_pb.js";
import { Vector3 } from "../base/base_pb.js";

/**
 * Config defines information needed to run jobs in the workflow.
 *
 * Note: kube-container-related information, e.g., docker image+tag, is defined
 * in podconfig.PodConfig. workflowpb.Config is provided by the user, and
 * podconfig.PodConfig is provided by the jobmaster itself.  So we need pair
 * (workflow.Config, podconfig.PobConfig) to fully specify the jobs
 *
 * @generated from message luminary.proto.workflow.Config
 */
export const Config = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.Config",
  () => [
    { no: 1, name: "exploration", kind: "message", T: Exploration },
    { no: 3, name: "job_config_template", kind: "message", T: JobConfig },
    { no: 4, name: "no_geometry", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.workflow.Priority
 */
export const Priority = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.Priority",
  () => [
    { no: 1, name: "batch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "priority_tier", kind: "enum", T: proto3.getEnumType(Priority_PriorityTier) },
  ],
);

/**
 * Allows us to specify a priority tier for the job. This is used for job
 * queueing and quota management in the scheduler.
 *
 * @generated from enum luminary.proto.workflow.Priority.PriorityTier
 */
export const Priority_PriorityTier = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.workflow.Priority.PriorityTier",
  [
    {no: 0, name: "PRIORITY_TIER_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PRIORITY_TIER_TRIAL_ACCOUNT_USER", localName: "TRIAL_ACCOUNT_USER"},
  ],
);

/**
 * @generated from message luminary.proto.workflow.GPUPreference
 */
export const GPUPreference = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.GPUPreference",
  () => [
    { no: 1, name: "gpu_type", kind: "enum", T: proto3.getEnumType(GPUType) },
    { no: 2, name: "n_pu", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "n_max_nodes", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.workflow.SchedulingConfig
 */
export const SchedulingConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.SchedulingConfig",
  () => [
    { no: 1, name: "scheduler_type", kind: "enum", T: proto3.getEnumType(SchedulingConfig_SchedulerType) },
    { no: 2, name: "retry_oom_first_try", kind: "message", T: SchedulingConfig_RetryOomFirstTry, oneof: "retry_config" },
  ],
);

/**
 * @generated from enum luminary.proto.workflow.SchedulingConfig.SchedulerType
 */
export const SchedulingConfig_SchedulerType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.workflow.SchedulingConfig.SchedulerType",
  [
    {no: 0, name: "DEFAULT_SCHEDULER"},
    {no: 1, name: "PREFER_K8S_SCHEDULER"},
  ],
);

/**
 * Allows retrying on the first job incarnation if the job fails due to OOM.
 *
 * @generated from message luminary.proto.workflow.SchedulingConfig.RetryOomFirstTry
 */
export const SchedulingConfig_RetryOomFirstTry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.SchedulingConfig.RetryOomFirstTry",
  () => [
    { no: 1, name: "first_try_gpu_pref", kind: "message", T: GPUPreference },
  ],
  {localName: "SchedulingConfig_RetryOomFirstTry"},
);

/**
 * TODO(cstlee): LC-10233 `JobConfig` logically belongs in `job.proto`; we
 * should move it there at some point.
 *
 * @generated from message luminary.proto.workflow.JobConfig
 */
export const JobConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.JobConfig",
  () => [
    { no: 1, name: "deprecated_gpu_type", kind: "enum", T: proto3.getEnumType(GPUType) },
    { no: 2, name: "deprecated_n_pu", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "deprecated_memory", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "priority", kind: "message", T: Priority },
    { no: 8, name: "gpu_pref", kind: "message", T: GPUPreference, repeated: true },
    { no: 11, name: "constraints", kind: "message", T: RuntimeConstraints, opt: true },
    { no: 14, name: "scheduling_config", kind: "message", T: SchedulingConfig, opt: true },
    { no: 3, name: "testjob", kind: "message", T: TestjobParam, oneof: "typ" },
    { no: 4, name: "fvm", kind: "message", T: Param, oneof: "typ" },
    { no: 5, name: "mesh_converter", kind: "message", T: MeshConverterParam, oneof: "typ" },
    { no: 7, name: "paraview", kind: "message", T: Empty, oneof: "typ" },
    { no: 10, name: "lcc", kind: "message", T: LCCParam, oneof: "typ" },
    { no: 13, name: "simulation_param", kind: "message", T: SimulationParam, oneof: "typ" },
    { no: 15, name: "image_renderer", kind: "message", T: ImageRendererConfig, oneof: "typ" },
    { no: 16, name: "mesh_adapter", kind: "message", T: Empty, oneof: "typ" },
  ],
);

/**
 * Information about one job incarnation. This struct is stored in DB
 * job_id(job_incarnation_stat).
 *
 * @generated from message luminary.proto.workflow.JobIncarnationStat
 */
export const JobIncarnationStat = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.JobIncarnationStat",
  () => [
    { no: 1, name: "gpu_pref", kind: "message", T: GPUPreference },
    { no: 2, name: "start_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "end_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Input args given the //cc/cmd/testjob.
 *
 * @generated from message luminary.proto.workflow.TestjobParam
 */
export const TestjobParam = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.TestjobParam",
  () => [
    { no: 1, name: "test_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "test_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "test_crash", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "test_hang_seconds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "input_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "write_checkpoint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "read_checkpoint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Input args for mesh format converter using meshconverter
 *
 * @generated from message luminary.proto.workflow.MeshConverterParam
 */
export const MeshConverterParam = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.MeshConverterParam",
  () => [
    { no: 1, name: "from_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "to_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "disconnect", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "mesh_type", kind: "enum", T: proto3.getEnumType(MeshType) },
    { no: 6, name: "read_zones_openfoam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Parameters to specify running an lcc operator
 *
 * @generated from message luminary.proto.workflow.LCCParam
 */
export const LCCParam = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.LCCParam",
  () => [
    { no: 1, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "command", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "command_args", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "operator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Checkpoint is stored in workflow(checkpoint) column
 *
 * @generated from message luminary.proto.workflow.Checkpoint
 */
export const Checkpoint = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.Checkpoint",
  () => [
    { no: 2, name: "fvm", kind: "message", T: Checkpoint$1, oneof: "typ" },
  ],
);

/**
 * @generated from message luminary.proto.workflow.BoundSolution
 */
export const BoundSolution = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.BoundSolution",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "pressure_force", kind: "message", T: Vector3 },
    { no: 3, name: "friction_force", kind: "message", T: Vector3 },
  ],
);

/**
 * @generated from message luminary.proto.workflow.Solution
 */
export const Solution = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.workflow.Solution",
  () => [
    { no: 1, name: "global_res", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
    { no: 2, name: "bound", kind: "message", T: BoundSolution, repeated: true },
  ],
);

