import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export type TagSelectionSettings = {
  active: boolean,
  surfaces: Set<string>;
};

export const defaultTagSelectionSettings = (): TagSelectionSettings => ({
  active: false,
  surfaces: new Set<string>(),
});

export const tagSelectionSettingsState = atom<TagSelectionSettings>({
  key: 'tagSelectionSettings',
  default: defaultTagSelectionSettings(),
});

export const useTagSelectionSettings = () => useRecoilState(tagSelectionSettingsState);
export const useSetTagSelectionSettings = () => useSetRecoilState(tagSelectionSettingsState);
export const useTagSelectionSettingsValue = () => useRecoilValue(tagSelectionSettingsState);
