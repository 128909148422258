// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { CommonMenuItem } from '../../../../../lib/componentTypes/menu';
import { MAX_ADAPT_FINAL_CELLS_IN_MILLIONS } from '../../../../../lib/constants';
import { colors } from '../../../../../lib/designSystem';
import { createMeshSubtitleFromStats, getMeshMetadata, meshAggregateStats } from '../../../../../lib/mesh';
import { getOrCreateAdaptiveMeshRefinement } from '../../../../../lib/simulationParamUtils';
import * as frontendpb from '../../../../../proto/frontend/frontend_pb';
import { MeshPanelType, useSetMeshPanelState } from '../../../../../recoil/useMeshPanelState';
import { projectActiveMeshSelector, useHasActiveMesh } from '../../../../../recoil/useProjectActiveMesh';
import { useProjectMeshList } from '../../../../../recoil/useProjectMeshList';
import { useSimulationParam } from '../../../../../state/external/project/simulation/param';
import { ActionButton } from '../../../../Button/ActionButton';
import { CardButton } from '../../../../Button/CardButton';
import { IconButton } from '../../../../Button/IconButton';
import { createStyles, makeStyles } from '../../../../Theme';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useHandleMeshSelect } from '../../../../hooks/useHandleMeshSelect';
import { useIsLMAActive, useRenameMesh, useSetupNewMeshWorkflow } from '../../../../hooks/useMesh';
import { ArrowLeftIcon } from '../../../../svg/ArrowLeftIcon';
import { LoadingEllipsis } from '../../../../visual/LoadingEllipsis';

const useStyles = makeStyles(
  () => createStyles({
    propPanelContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    meshingProps: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '0 12px',
      overflow: 'auto',
      height: 'min-content',
      paddingBottom: '12px',
    },
    buttonContainer: {
      display: 'flex',
      padding: '8px 12px 12px',
      position: 'sticky',
    },
    headerContainer: {
      display: 'flex',
      gap: '4px',
      padding: '6px 12px',
    },
    sectionHeader: {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 600,
      margin: '8px 0',
    },
    sectionSubheader: {
      fontWeight: 400,
      margin: 0,
    },
    meshingContainer: {
      flex: '0 0 auto',
      borderTop: '1px solid black',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    link: {
      flex: '1 0 60%',
      color: colors.lowEmphasisText,
      fontSize: '13px',
      fontWeight: 600,
      textDecoration: 'underline',
      cursor: 'pointer',
      outline: 'none',
    },
    emptyFallback: {
      padding: '0 10px 10px',
      color: colors.lowEmphasisText,
      textAlign: 'center',
      textWrap: 'balance',
    },
  }),
  { name: 'MeshPropPanel' },
);

interface MeshSelectCardProps {
  mesh: frontendpb.Mesh,
}

const MeshSelectCard = (props: MeshSelectCardProps) => {
  // == Props
  const { mesh } = props;

  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const activeMesh = useRecoilValue(projectActiveMeshSelector({ projectId }));
  const isLMA = useIsLMAActive();
  const setMeshPanelState = useSetMeshPanelState(projectId);

  // == Hooks
  const { handleMeshSelect } = useHandleMeshSelect();
  const { renameMesh } = useRenameMesh();

  // == State
  const [editNameMode, setEditNameMode] = useState(false);
  const [meshSubtitle, setMeshSubtitle] = useState<string | ReactNode>(<LoadingEllipsis />);
  const [meshDisabled, setMeshDisabled] = useState(isLMA);
  const [disabledReason, setDisabledReason] = useState('');

  // == Data
  const amrTargetCount = getOrCreateAdaptiveMeshRefinement(simParam).targetCvMillions;
  const meshId = mesh.id;
  const selected = activeMesh?.id === meshId;

  useEffect(() => {
    async function getSubtitle() {
      const meta = await getMeshMetadata(meshId);
      const stats = meshAggregateStats(meta);

      const subtitle = createMeshSubtitleFromStats(stats);
      setMeshSubtitle(subtitle);

      // If LMA, check that the mesh is not larger than the max LMA size
      if (isLMA) {
        const meshCVsMillions = stats.counters.controlVolume / 1_000_000;
        const amrCVMax = (amrTargetCount?.value ?? MAX_ADAPT_FINAL_CELLS_IN_MILLIONS);
        const isMeshIncompatible = meshCVsMillions > amrCVMax;
        setDisabledReason(isMeshIncompatible ?
          `Mesh size (CVs) is greater than max adaptation mesh size.` :
          '');
        setMeshDisabled(isMeshIncompatible);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getSubtitle();
  }, [meshId, isLMA, amrTargetCount]);

  const renameItem = {
    label: 'Rename',
    onClick: () => {
      setEditNameMode(true);
    },
  };
  // TODO(pedro): Not including "delete" here because we are not checking if the mesh is used by a
  // simulation.
  const menuItems: CommonMenuItem[] = [renameItem];

  return (
    <CardButton
      compact
      disabled={meshDisabled}
      disabledReason={disabledReason}
      editableDisabled={!editNameMode}
      handleEditTitle={async (val) => {
        if (val !== mesh.name) {
          await renameMesh(meshId, val);
        }
        setEditNameMode(false);
      }}
      icon={{ maxHeight: 12, name: 'cubeOutline', color: colors.neutral650 }}
      key={meshId}
      menuItems={menuItems}
      onClick={async () => {
        // If mesh is already active, just go to details panel
        if (selected) {
          setMeshPanelState(MeshPanelType.DETAILS);
          return;
        }
        await handleMeshSelect(meshId);
      }}
      selected={selected}
      subtitle={meshSubtitle}
      title={mesh.name}
    />
  );
};

const MeshSelectContainer = ({ meshes }: { meshes: frontendpb.Mesh[] }) => {
  const classes = useStyles();

  if (!meshes.length) {
    return null;
  }

  return (
    <div className={classes.meshingProps}>
      {!!meshes.length && meshes.map((mesh: frontendpb.Mesh) => (
        <MeshSelectCard
          key={mesh.id}
          mesh={mesh}
        />
      ))}
    </div>
  );
};

// A panel for allowing user to generate their first mesh
export const MeshSelectPanel = () => {
  const { projectId } = useProjectContext();
  const { setupNewMeshWorkflow } = useSetupNewMeshWorkflow(projectId);

  const isLMA = useIsLMAActive();
  const hasActiveMesh = useHasActiveMesh(projectId);
  const setMeshPanelState = useSetMeshPanelState(projectId);
  const [meshListIn] = useProjectMeshList(projectId);
  // Cannot start LMA from final LMA meshes due to missing links to the meshb files and mesh
  // generation parameters.
  const meshList = meshListIn.filter(
    (mesh) => !isLMA || mesh.meshOrigin !== frontendpb.Mesh_MeshOrigin.ADAPTED_MESH,
  );
  const classes = useStyles();

  const headerText = isLMA ? 'Select Base Mesh' : 'Select a Simulation Mesh';

  const handleBackButtonClick = () => {
    if (hasActiveMesh) {
      setMeshPanelState(MeshPanelType.DETAILS);
    }
    setMeshPanelState(MeshPanelType.EDIT);
  };

  return (
    <div className={classes.propPanelContainer}>
      <div className={classes.headerContainer}>
        {(isLMA || hasActiveMesh) && (
          <IconButton onClick={handleBackButtonClick} size="small">
            <ArrowLeftIcon maxHeight={14} maxWidth={14} />
          </IconButton>
        )}
        <p className={classes.sectionHeader}>{headerText}</p>
      </div>

      {meshList.length === 0 && (
        <div className={classes.emptyFallback}>
          There are no previously generated meshes for this project.
        </div>
      )}

      <div className={classes.buttonContainer}>
        <ActionButton
          asBlock
          kind="secondary"
          name="newMeshButton"
          onClick={setupNewMeshWorkflow}
          size="small"
          startIcon={{ name: 'plus', maxWidth: 12 }}>
          New Mesh
        </ActionButton>
      </div>

      <MeshSelectContainer meshes={meshList} />
    </div>
  );
};
