// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { initialRemoveResult } from '../../lib/baseUtils';
import { UNSUPPORTED_HEAT_LMA, UNSUPPORTED_MULTIPHYS_LMA } from '../../lib/constants';
import {
  findMaterialIdByDomain,
  findPhysicsIdByDomain,
  getPhysicsMaterialIds,
  unassignPhysicsSetFromDomains,
} from '../../lib/entityRelationships';
import { boldEscaped } from '../../lib/html';
import {
  MaterialDatum,
  bucketMaterialsByType,
  findMaterialEntityById,
  getMaterialsByIdFromMap,
  isMaterialFluid,
  isMaterialSolid,
} from '../../lib/materialUtils';
import { removeMultiphysicsInterfacesByPhysicsSet } from '../../lib/multiphysicsInterfaceUtils';
import {
  ConfigurablePhysicsType,
  appendFluidPhysics,
  appendHeatPhysics,
  findPhysicsById,
  getPhysicsId,
  isChtSimulation,
  isPhysicsFluid,
  isPhysicsHeat,
  maxPhysicsCountsMessage,
  physicsReferencesVolumes,
  physicsReferencesVolumesSurfaces,
  renamePhysics as rename,
  wrapMutatePhysicsList,
} from '../../lib/physicsUtils';
import { updateMeshingFromBc } from '../../lib/simulationUtils';
import { findStaticVolumeByDomain } from '../../lib/volumeUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { OutputNode_Type } from '../../proto/frontend/output/output_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useSetOutputNodes } from '../../recoil/outputNodes';
import { useSetMeshMultiPart } from '../../recoil/useMeshingMultiPart';
import { useStaticVolumes } from '../../recoil/volumes';
import { useSimulationMaterialsMap } from '../../state/external/project/simulation/param/materials';

import { useWorkflowConfig } from './useWorkflowConfig';

type PhysicsParamsCase = simulationpb.Physics['params']['case'];

const { MESH_METHOD_AUTO } = simulationpb.MeshingMethod;

export interface MaterialCompatibilty {
  allowAnyFluid: boolean;
  allowAnySolid: boolean;
  allowOnlyFluid: MaterialDatum | null;
  allowNone: boolean;
}

// Expose the physics types that can be added
export const typesToAdd: ConfigurablePhysicsType[] = ['fluid', 'heat'];

/**
 * Model hook for managing a set of physics
 */
export const usePhysicsSet = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  // == Recoil
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const materialDataMap = useSimulationMaterialsMap(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const setOutputNodes = useSetOutputNodes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  const {
    saveParam,
    saveParamAsync,
    simParam,
  } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const allPhysics = simParam.physics;

  // Append a physics to the simulation param
  const appendPhysics = useCallback(
    (param: simulationpb.SimulationParam, type: PhysicsParamsCase) => {
      switch (type) {
        case 'fluid': {
          return appendFluidPhysics(param);
        }
        case 'heat': {
          return appendHeatPhysics(param);
        }
        default: {
          throw Error('Cannot append unknown physics type');
        }
      }
    },
    [],
  );

  // A wrapper for any operation that, when completed, results in changes to boundary conditions
  // which may require updates to meshing parameters.  The `mutation` operation argument should
  // return a truth-y value to signal that boundary conditions were mutated.
  const saveBoundaryConditionAsync = useCallback(
    <T extends any>(mutation: (param: simulationpb.SimulationParam) => T) => (
      saveParamAsync(
        (newParam) => {
          const mutated = mutation(newParam);
          if (mutated) {
            updateMeshingFromBc(simParam, newParam, setMeshMultiPart, geometryTags);
          }
          return mutated;
        },
      )),
    [saveParamAsync, setMeshMultiPart, simParam, geometryTags],
  );

  // Add a physics to the simulation param and save the updated param
  const addPhysics = useCallback(async (type: PhysicsParamsCase) => saveBoundaryConditionAsync(
    (newParam) => appendPhysics(newParam, type),
  ), [appendPhysics, saveBoundaryConditionAsync]);

  const disabledAddReason = useCallback((type: PhysicsParamsCase) => {
    const isLMA = simParam.adaptiveMeshRefinement?.meshingMethod === MESH_METHOD_AUTO;
    const physics = simParam.physics;
    // If LMA and there are no physics, disable heat
    if (isLMA && !physics.length && type === 'heat') {
      return UNSUPPORTED_HEAT_LMA;
    }
    // If LMA and there is already a physics, disable both heat and fluid
    if (isLMA && physics.length) {
      return UNSUPPORTED_MULTIPHYS_LMA;
    }
    return maxPhysicsCountsMessage(simParam, type);
  }, [simParam]);

  // Remove global residual output nodes assigned to the deleted physics
  const removeRelatedResidualOutputs = useCallback((physicsIds: Set<string>) => {
    setOutputNodes((outputNodes) => {
      outputNodes.nodes = outputNodes.nodes.filter((outputNode) => (
        outputNode.type === OutputNode_Type.GLOBAL_OUTPUT_TYPE &&
        !(
          outputNode.nodeProps.case === 'residual' &&
          physicsIds.has(outputNode.nodeProps.value.physicsId)
        )
      ));
      return outputNodes;
    });
  }, [setOutputNodes]);

  // Remove a physics (by ID) from a simulation param and update residual outputs
  const removePhysics = useCallback(
    (param: simulationpb.SimulationParam, id: string): boolean => wrapMutatePhysicsList(
      param,
      () => {
        const { itemToDelete, newItems } = param.physics.reduce(
          (result, physics) => {
            if (getPhysicsId(physics) === id) {
              result.itemToDelete = physics;
            } else {
              result.newItems.push(physics);
            }
            return result;
          },
          initialRemoveResult<simulationpb.Physics>(),
        );

        param.physics = newItems;

        if (itemToDelete) {
          const idSet = new Set([id]);
          unassignPhysicsSetFromDomains(param, idSet);
          removeMultiphysicsInterfacesByPhysicsSet(param, idSet);
          removeRelatedResidualOutputs(idSet);
          return true;
        }
        return false;
      },
    ),
    [removeRelatedResidualOutputs],
  );

  // Delete a physics (by ID) from a simulation param and save the updated param
  const deletePhysics = useCallback(async (id: string): Promise<boolean> => saveParamAsync(
    (newParam) => removePhysics(newParam, id),
  ), [removePhysics, saveParamAsync]);

  // Rename a physics (by ID) and save the update param
  const renamePhysics = useCallback((id: string, name: string) => {
    saveParam((newParam) => rename(newParam, id, name));
  }, [saveParam]);

  /**
   * Given a physics and an existing set of assigned volumes, we can determined the materials
   * associated with the physics via the volume assignments, which allows us to constrain additional
   * volume selections.
   *
   * This memoized state is a data structure describing what additional *materials*, if any, may be
   * associated with each physics, based on the materials already associated with them.
   *
   * Four mutually exclusive states are encapsulated.  If the physics is Fluid and is not yet
   * attached to any volumes, then any volume associated with fluid materials is eligible and
   * `allowAnyFluid` is true.  But if the physics fluid is already attached to one or more volumes,
   * then they must be associated with the same fluid material, and allowOnlyFluid is set.  If the
   * physics is heat, then any volume associated with solid materials is eligible and
   * `allowAnySolid` is true.  If none of the above three states is truth-y, then the fourth state,
   * `allowNone`, is set to true.  It indicates that the volumes already assigned to this physics
   * are incompatible (they're attached to multiple fluid materials or to a mix of solids and
   * fluids), so no additional assignments should be allowed.
   */
  const materialCompatibilityStates = useMemo(() => {
    const states: Record<string, MaterialCompatibilty> = {};
    allPhysics.forEach((physics) => {
      const physicsId = getPhysicsId(physics);

      // From the entity relationships, find the material IDs associated with this physics.
      const materialIds = getPhysicsMaterialIds(simParam, physicsId, geometryTags, staticVolumes);
      // Get material objects identified in materialIds
      const materials = getMaterialsByIdFromMap(materialDataMap, materialIds);
      const buckets = bucketMaterialsByType(materials);

      // Initialize mutually exclusive states
      let allowAnyFluid = false;
      let allowAnySolid = false;
      let allowOnlyFluid: MaterialDatum | null = null;

      if (isPhysicsFluid(physics)) {
        if (buckets.fluids.length === 1) {
          // If exactly one fluid is associated with this physics, then only allow the same material
          // when evaluating other volumes' eligibility.
          allowOnlyFluid = buckets.fluids[0];
        } else if (!buckets.fluids.length) {
          // If no fluids are associated with this physics, then allow volumes assigned to any fluid
          // material.
          allowAnyFluid = true;
        }
      } else if (isPhysicsHeat(physics)) {
        // For heat physics, volumes' materials may be any combination of solid materials.
        allowAnySolid = true;
      }

      states[physicsId] = {
        allowAnyFluid,
        allowAnySolid,
        allowOnlyFluid,
        allowNone: !(allowAnyFluid || allowAnySolid || allowOnlyFluid),
      };
    });
    return states;
  }, [allPhysics, materialDataMap, simParam, geometryTags, staticVolumes]);

  // Return some text describing why a domain (volume) cannot be assigned to a physics.  If an empty
  // string is returned, then the assignment is allowed.
  const getVolumePhysicsAssignmentDisabledReason = useCallback(
    (physicsId: string, domain: string) => {
      const {
        allowAnyFluid,
        allowAnySolid,
        allowNone,
        allowOnlyFluid,
      } = materialCompatibilityStates[physicsId];
      if (allowNone) {
        return 'Existing volume selections for this physics are incompatible.  Please fix before ' +
          'assigning more volumes.';
      }

      const materialId = findMaterialIdByDomain(simParam, domain, geometryTags);
      const material = materialId ? findMaterialEntityById(simParam, materialId) : null;
      if (!material) {
        return 'Assign volume to a material before assigning it to a physics.';
      }

      const existingPhysicsId = findPhysicsIdByDomain(simParam, domain, geometryTags);
      if (existingPhysicsId && existingPhysicsId !== physicsId) {
        return 'Volume is already assigned to another physics';
      }

      if (allowAnySolid && !isMaterialSolid(material)) {
        return 'Only volumes assigned to solid materials may be assigned to this physics.';
      }
      if (allowAnyFluid && !isMaterialFluid(material)) {
        return 'Only volumes assigned to fluid materials may be assigned to this physics.';
      }
      if (allowOnlyFluid && materialId !== allowOnlyFluid.id) {
        const name = allowOnlyFluid.name;
        return `Only volumes assigned to ${boldEscaped(name)} may be assigned to this physics`;
      }

      if (existingPhysicsId === physicsId) {
        const physics = findPhysicsById(simParam, physicsId);
        const volume = findStaticVolumeByDomain(domain, staticVolumes);

        if (volume && physics) {
          // For a physics's current volume selections, disallow unselecting if any of the volume's
          // surfaces have been used in boundary conditions, periodic pairs, or sliding interfaces
          // -OR- if the volume has been used in a heat source
          if (physicsReferencesVolumes(physics, [volume])) {
            return `Volume cannot be removed because it's referenced by a heat source.`;
          }
          if (physicsReferencesVolumesSurfaces(physics, [volume])) {
            return `Volume cannot be removed because its surfaces are referenced by boundary
              conditions, periodic pairs, or sliding interfaces.`;
          }
        }
      }
      return '';
    },
    [geometryTags, materialCompatibilityStates, simParam, staticVolumes],
  );

  const isCht = useMemo(() => isChtSimulation(simParam), [simParam]);

  return {
    isCht,
    allPhysics,
    addPhysics,
    appendPhysics,
    disabledAddReason,
    removePhysics,
    deletePhysics,
    renamePhysics,
    getVolumePhysicsAssignmentDisabledReason,
    saveBoundaryConditionAsync,
  };
};
