import { LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';
import { LcvObjectList } from '../base/LcvObjectList';

import { hexToRgbList } from '@/lib/designSystem';

export class LcvHoveredPlotPoints extends LcvObjectList {
  constructor(lcv: LcvModule, sessionHandle: number, size: number) {
    super(
      lcv,
      lcv.newAnnotation(sessionHandle, 'monitor_points', 0).annotation,
      sessionHandle,
      size,
    );

    this.setParam('enable_depth_testing', LCVType.kLCVDataTypeUint, 0);
    this.setParam('shade_flat', LCVType.kLCVDataTypeUint, 1);
    this.setParam('radius', LCVType.kLCVDataTypeFloat, 0.004);
  }

  setPoints(points: { x: number; y: number; z: number; color: string }[]) {
    this.setSize(points.length);

    for (let index = 0; index < points.length; index += 1) {
      const point = points[index];

      this.setParamAtIndex(
        index,
        'points',
        LCVType.kLCVDataTypeFloat3,
        [point.x, point.y, point.z],
      );

      this.setParamAtIndex(
        index,
        'colors',
        LCVType.kLCVDataTypeFloat3,
        hexToRgbList(point.color),
      );
    }
  }
}
