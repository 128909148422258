// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import assert from '../../lib/assert';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as cadmetadatapb from '../../proto/cadmetadata/cadmetadata_pb';
import * as entitygrouppb from '../../proto/entitygroup/entitygroup_pb';

type GeometryTag = {
  id: string,
  name: string,
  bodyIds: number[],
  faceIds: number[],
  bodiesOfFaces: number[],
}

type ServerReply = geometryservicepb.GetTagsResponse['tags']

const SUFFIX_BODY_TAG = 'body-id-tag-';
const SUFFIX_FACE_TAG = 'face-tag-face-id-';

/**
 * GeometryTags is a class that provides several helper functions to map from entityGroupMap IDs to
 * geometry entity IDs. It is in charge of inserting such tags within the entityGroupMap and it is
 * the owner of the ID mapping.
*/
export class GeometryTags {
  tags: GeometryTag[];
  cadMetadata: cadmetadatapb.CadMetadata;
  // Maps a given face ID to the body ID it belongs to.
  faceIdToBodyId: Map<number, number>;

  // Set of all tag container IDs as they are inserted in the entityGroupMap.
  tagContainerIds: Set<string>;

  // Maps a face entity group ID to the face ID it represents.
  faceEntityGroupMapIdToFaceId = new Map<string, number>();

  tagIdToName: Map<string, string>;
  constructor(serverReply: ServerReply | undefined, cadMetadata: cadmetadatapb.CadMetadata) {
    this.cadMetadata = cadMetadata;
    this.tags = serverReply?.tags.map((tag) => ({
      id: tag.id,
      name: tag.name,
      bodyIds: tag.bodies,
      faceIds: tag.faces,
      bodiesOfFaces: tag.bodiesOfFaces,
    })) || [];
    this.faceIdToBodyId = new Map();
    this.tagContainerIds = new Set();
    this.tagIdToName = new Map();
    this.updateFaceEntityGroupMapIdToFaceId();
    this.tags.forEach((tag) => {
      tag.faceIds.forEach((faceId, index) => {
        this.faceIdToBodyId.set(faceId, tag.bodiesOfFaces[index]);
      });
      this.tagContainerIds.add(tag.id);
      this.tagIdToName.set(tag.id, tag.name);
    });
  }

  private entityGroupIdForFaceIdOfTag(tagId: string, faceId: number) {
    return `${tagId}${SUFFIX_FACE_TAG}${faceId}`;
  }

  // Maps an entity group ID entry for a face to its correspondent face ID in the CAD.
  private updateFaceEntityGroupMapIdToFaceId() {
    this.faceEntityGroupMapIdToFaceId = new Map();
    this.tags.forEach((tag) => {
      tag.faceIds.forEach((faceId) => {
        const faceEntityGroupId = this.entityGroupIdForFaceIdOfTag(tag.id, faceId);
        this.faceEntityGroupMapIdToFaceId.set(faceEntityGroupId, faceId);
      });
    });
  }

  tagIds() {
    return this.tagContainerIds;
  }

  tagNameFromId(id: string) {
    return this.tagIdToName.get(id);
  }

  // Needed to convert from CAD ids into LCN ids (i.e. id vs ids[index]).
  private bodyIdToBodyIndex(bodyId: number) {
    if (this.cadMetadata.volumeIds.length) {
      return this.cadMetadata.volumeIds.indexOf(bodyId);
    }
    return bodyId;
  }

  // Provided an ID of a BODY_TAG it will return the domain ID.
  domainFromTagEntityGroupId(tagEntityGroupId: string) {
    if (!tagEntityGroupId.includes(SUFFIX_BODY_TAG)) {
      return undefined;
    }
    const vals = tagEntityGroupId.split('-');
    const bodyId = parseInt(vals[vals.length - 1], 10);
    if (this.cadMetadata.volumeIds.length) {
      const index = this.cadMetadata.volumeIds?.indexOf(bodyId);
      return index.toString();
    }
    return bodyId.toString();
  }

  // Provided an ID of a geometry tag (TAG_CONTAINER) it will return the domain IDs associated with
  // it.
  domainsFromTag(tagId: string): Array<string> {
    const tag = this.tags.find((tagFind) => tagFind.id === tagId);
    if (!tag) {
      return [];
    }
    if (this.cadMetadata.volumeIds.length) {
      return tag.bodyIds.map((bodyId) => {
        const index = this.cadMetadata.volumeIds?.indexOf(bodyId);
        return index.toString();
      });
    }
    return tag.bodyIds.map((bodyId) => bodyId.toString());
  }

  // Provided an ID of a geometry tag it will return the domain IDs associated with it.
  domainsFromTagEntityGroupId(tagEntityGroupId: string) {
    if (!this.tagContainerIds.has(tagEntityGroupId)) {
      return undefined;
    }
    return this.domainsFromTag(tagEntityGroupId);
  }

  // Given a face entity group ID child of a tag ID, it returns the correspondent face ID.
  surfaceFromTagEntityGroupId(tagEntityGroupId: string) {
    const faceId = this.faceEntityGroupMapIdToFaceId.get(tagEntityGroupId);
    assert(faceId !== undefined, 'Invalid geometry metadata');
    const bodyId = this.faceIdToBodyId.get(faceId);
    assert(bodyId !== undefined, 'Invalid geometry metadata');
    return `${this.bodyIdToBodyIndex(bodyId)}/bound/BC_${faceId}`;
  }

  surfacesFromTagEntityGroupId(tagEntityGroupId: string) {
    if (!this.tagContainerIds.has(tagEntityGroupId)) {
      return undefined;
    }
    const tagName = this.tagIdToName.get(tagEntityGroupId);
    return tagName ? this.surfacesFromTag(tagName) : [];
  }

  private surfacesFromTag(tagName: string): Array<string> {
    const tag = this.tags.find((tagFind) => tagFind.name === tagName);
    if (!tag) {
      return [];
    }
    return tag.faceIds.map((faceId) => {
      const bodyId = this.faceIdToBodyId.get(faceId);
      assert(bodyId !== undefined, 'Invalid geometry metadata');
      return `${this.bodyIdToBodyIndex(bodyId)}/bound/BC_${faceId}`;
    });
  }

  // Adds all the geometry tags to the entity group map.
  addToEntityGroup(groupMap: EntityGroupMap) {
    this.tags.forEach((tag) => {
      groupMap.delete(tag.id);
    });
    this.tags.forEach((tag) => {
      const tagName = tag.name;
      const rootTagId = tag.id;
      const container = groupMap.add({
        name: tagName,
        parentId: EntityGroupMap.rootId,
        entityType: entitygrouppb.EntityType.TAG_CONTAINER,
        id: rootTagId,
      });
      tag.faceIds.forEach((face) => {
        const faceId = this.entityGroupIdForFaceIdOfTag(tag.id, face);
        container.add({
          name: `Surface ${face}`,
          parentId: rootTagId,
          entityType: entitygrouppb.EntityType.FACE_TAG,
          id: faceId,
        });
      });
      tag.bodyIds.forEach((body) => {
        const bodyIdx = this.bodyIdToBodyIndex(body);
        const bodyName = this.cadMetadata.volumeNames[bodyIdx] || `Volume ${bodyIdx + 1}`;
        container.add({
          name: bodyName,
          parentId: rootTagId,
          entityType: entitygrouppb.EntityType.BODY_TAG,
          id: `${rootTagId}${SUFFIX_BODY_TAG}${body}`,
        });
      });
    });
  }

  // Unrolls all the tags in the entities array by replacing the tag with the actual surfaces of the
  // tag. If the tag does not have surfaces, it will be erased.
  unrollFaceTags(entities: string[]) {
    const set = entities.reduce((acc, entity) => {
      if (this.isTagId(entity)) {
        this.surfacesFromTagEntityGroupId(entity)?.forEach((surface) => acc.add(surface));
      } else {
        acc.add(entity);
      }
      return acc;
    }, new Set<string>());
    return Array.from(set);
  }

  // Unrolls all the tags in the entities array by replacing the tag with the actual volumes of the
  // tag. If the tag does not have surfaces, it will be erased.
  unrollBodyTags(entities: string[]) {
    const set = entities.reduce((acc, entity) => {
      if (this.isTagId(entity)) {
        this.domainsFromTagEntityGroupId(entity)?.forEach((vol) => acc.add(vol));
      } else {
        acc.add(entity);
      }
      return acc;
    }, new Set<string>());
    return Array.from(set);
  }

  isTagId(id: string) {
    return this.tagContainerIds.has(id);
  }
}

export const EMPTY_GEOMETRY_TAGS = new GeometryTags(undefined, new cadmetadatapb.CadMetadata());
