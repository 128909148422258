// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { iconForFeature } from '../../../lib/geometryUtils';
import { deleteTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useGeometryState } from '../../../recoil/geometry/geometryState';
import { useProjectContext } from '../../context/ProjectContext';
import { useDeleteGeometryModification } from '../../hooks/useInteractiveGeometry';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

export const GeometryModification = (props: SimulationRowProps) => {
  const { node } = props;
  const { projectId, geometryId } = useProjectContext();
  const geoState = useGeometryState(projectId, geometryId);
  const { deleteRow, disabledDeleteReason } = useDeleteGeometryModification(props.node.id);
  const renaming = useNodeRenaming(node);
  const featureIndex = useMemo(() => (
    geoState?.geometryFeatures.findIndex((item) => item.id === props.node.id)
  ), [geoState?.geometryFeatures, props.node.id]);
  // featureIndex is only undefined if geoState is undefined.
  const feature = geoState?.geometryFeatures[featureIndex!];

  const getContextMenuItems = () => [
    deleteTreeNodeMenuItem(deleteRow, !!disabledDeleteReason, disabledDeleteReason),
  ];

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={iconForFeature(feature)}
      renaming={renaming}
    />
  );
};
