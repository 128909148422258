// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from 'react';

import * as flags from '../../flags';
import { colors } from '../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../lib/event';
import { useUserCanEdit } from '../../lib/projectRoles';
import useResizeObserver from '../../lib/useResizeObserver';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useProjectWithResetGeometry } from '../../recoil/project';
import { useSetSimulationPropertiesPanelDocked } from '../../recoil/propertiesPanel';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import useExplorationSet from '../../recoil/useExplorationSet';
import { useMeshReadyState } from '../../recoil/useMeshReadyState';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useIsBaselineMode } from '../../recoil/useProjectPage';
import { useCurrentConfig } from '../../recoil/workflowConfig';
import { useIsGeometryView, useIsSetupOrIntermediaryView } from '../../state/internal/global/currentView';
import { IconButton } from '../Button/IconButton';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { PropertiesPanelPosition, usePropertiesPanelPosition, useShowPropertyPanelOnNewNode } from '../hooks/usePropertiesPanel';
import { usePanelRoot } from '../hooks/useRunSimulation';
import { Resizable } from '../layout/Resizable';
import { SectionMessage } from '../notification/SectionMessage';
import { ChevronLeftIcon } from '../svg/ChevronLeftIcon';
import { RectanglePoppedOutIcon } from '../svg/RectanglePoppedOut';
import { PropertiesPanel } from '../treePanel/PropertiesPanel';
import SimulationTreePanel from '../treePanel/SimulationTreePanel';

import RunSimulationButton from './RunSimulationButton';

const useStyles = makeStyles(
  () => createStyles({
    topInfoMessage: {
      padding: '8px',
    },
    expControl: {
      color: colors.highEmphasisText,
      fontSize: '14px',
      padding: '13px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '8px',
      overflow: 'hidden',
    },
    expIcon: {
      flex: '0 0 auto',
    },
    expName: {
      flex: '1 1 auto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    runControl: {
      flex: '0 0 auto',
      display: 'flex',
      padding: '8px',
    },
    runButton: {
      flex: '1 1 auto',
    },
    propertiesHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 600,
      padding: '10px 12px',
      width: '100%',
      zIndex: 1,
    },
    splitter: {
      borderTop: `4px solid ${colors.neutral50}`,
      borderBottom: `1px solid ${colors.neutral200}`,
    },
  }),
  { name: 'SimulationSettings' },
);

/**
  * This returns a possible info SectionMessage at the top of the SimulationSettings.
  * If there is such a message, the run simulation button will be hidden.
  */
export function useGetTopInfoMessage() {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const geometryPending = useIsGeometryPending(projectId);
  const [projectWithResetGeometry] = useProjectWithResetGeometry(projectId);

  // If we don't have a mesh/geometry and we are in new project
  if (!meshReadyState && !projectWithResetGeometry) {
    return 'Please upload geometry. You can modify simulation settings once geometry ' +
      'preparation is complete.';
  }
  // If we don't have a mesh/geometry, but we are opening an old project (with removed geometry)
  if (!meshReadyState && projectWithResetGeometry) {
    return 'Existing properties settings will apply to the model when it has been uploaded.';
  }
  // If we have a geometry but it is being prepared atm
  if (geometryPending) {
    return 'You can modify simulation settings once geometry preparation is complete.';
  }

  return '';
}

/**
 * Shows a simulation tree panel which organizes the simulation settings.
 * Selecting a node in that panel will bring up more details in the properties panel.
 */
const SimulationSettings = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const [explorationSet, setExplorationSet] = useExplorationSet(projectId);
  const isBaselineMode = useIsBaselineMode();
  const isGeometryView = useIsGeometryView();
  const isSetupOrIntermediaryView = useIsSetupOrIntermediaryView();
  const projectMetadata = useProjectMetadata(projectId);
  const sensitivityEnabled = useIsEnabled(flags.adjointSA);

  // == Models
  const setSimulationPropertiesPanelDocked = useSetSimulationPropertiesPanelDocked();

  // == Hooks
  const classes = useStyles();
  const topInfoMessage = useGetTopInfoMessage();
  const propertiesPanelPosition = usePropertiesPanelPosition();
  const panelRoot = usePanelRoot();
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);

  // == Derived data
  const exploration = config.exploration;
  const experimentName = isBaselineMode ? '' : exploration?.name || '';

  // == State
  const rootNode = useRef<HTMLDivElement>(null);
  const containerNode = useRef<HTMLDivElement>(null);
  const [propsInitSize, setPropsInitSize] = useState('');

  const rootSize = useResizeObserver(rootNode, { name: 'simSettingsPropsPanel' });

  // == Effects
  useShowPropertyPanelOnNewNode();

  useEffect(() => {
    if (rootSize.height && !propsInitSize) {
      // Set initial size on Props Panel Resizable, based on the height of this component's root
      // (minus approximately half the splitter height), but only set it once
      const size = 0.45 * rootSize.height - 50;
      setPropsInitSize(`${Math.max(0, Math.round(size))}px`);
    }
  }, [rootSize, propsInitSize]);

  if (!panelRoot) {
    return <></>;
  }

  // Show the button only for users that are owners of the projects and only in the setup/geometry
  const showButton = (
    userCanEdit &&
    !topInfoMessage &&
    (isGeometryView || isSetupOrIntermediaryView)
  );

  const unselectExploration = () => {
    const oldList = explorationSet.exploration;
    const i = explorationSet.index;
    setExplorationSet(
      new projectstatepb.ExplorationSet({
        exploration: oldList.map((old, j) => ((i === j) ? exploration! : old)),
        index: -1,
      }),
    );
  };

  return (
    <div className="flexColumnLayout fullHeight" ref={rootNode}>
      {sensitivityEnabled && experimentName && (
        <div className="flexItem">
          <div
            className={classes.expControl}
            onClick={unselectExploration}
            onKeyUp={(event) => {
              if (isUnmodifiedSpaceKey(event)) {
                unselectExploration();
              }
            }}
            role="button"
            tabIndex={0}>
            <div className={classes.expIcon}>
              <ChevronLeftIcon color={colors.neutral650} maxHeight={10} maxWidth={10} />
            </div>
            <div className={classes.expName}>{experimentName}</div>
          </div>
        </div>
      )}
      {showButton && <RunSimulationButton padding />}
      {topInfoMessage && (
        <div className={classes.topInfoMessage}>
          <SectionMessage
            level="info"
            message={topInfoMessage}
          />
        </div>
      )}
      <div className="flexItem elastic">
        <div className="flexColumnLayout" ref={containerNode} style={{ height: '100%' }}>
          <div
            className="flexItem elastic"
            data-locator="simulationTreePanelContainer"
            style={{ flexBasis: '1px', overflow: 'hidden' }}>
            <SimulationTreePanel panelRoot={panelRoot} />
          </div>
          {propertiesPanelPosition === PropertiesPanelPosition.POPPED_IN && (
            <Resizable
              getDragInputs={() => ({ boundingNode: containerNode.current ?? undefined })}
              initialSize={propsInitSize}
              minSize={0}
              splitterContent={(
                <div style={{ width: '100%' }}>
                  <div className={classes.splitter} />
                  <div className={classes.propertiesHeader}>
                    Properties
                    <IconButton onClick={() => setSimulationPropertiesPanelDocked(false)}>
                      <RectanglePoppedOutIcon maxHeight={13} />
                    </IconButton>
                  </div>
                </div>
              )}
              splitterPlacement="top">
              <PropertiesPanel />
            </Resizable>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimulationSettings;
