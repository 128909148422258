// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Buffer } from 'buffer';

import * as zip from '@zip.js/zip.js';
import * as hashwasm from 'hash-wasm';

import * as basepb from '../../proto/base/base_pb';
import * as filepb from '../../proto/file/file_pb';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as uploadpb from '../../proto/upload/upload_pb';
import { UploadProgress } from '../UploadProgress';
import { formatNumber } from '../number';
import { Logger } from '../observability/logs';
import * as rpc from '../rpc';
import { fetchWithRetry } from '../utils';

import { CAD_FILE_EXTENSIONS, DISCRETE_GEOMETRY_FILE_EXTENSIONS, MESH_FILE_EXTENSIONS, NUMERIC_CAD_FILE_EXTENSIONS } from './fileTypes';

// Constants

// Chunk size for hashing the file.
export const HASH_CHUNK_SIZE = 8 * 1024 * 1024; // 8MiB

// Chunk size used for gcs resumable upload.
export const GCS_CHUNK_SIZE = 16 * 1024 * 1024; // 16MiB

// Chunk size used for simple upload
export const SIMPLE_CHUNK_SIZE = 8 * 1024 * 1024; // 8MiB

// GCS XML API retriable status codes
// https://cloud.google.com/storage/docs/xml-api/reference-status
export const GCS_RETRY_CODES = [500, 503, 504];

// Number of retries. Used in fetchWithRetry func.
export const NUM_RETRIES = 8;
// Interfaces

export interface uploaderOpts {
  uploadId: string;
  file: File;
  onProgress: (progress: UploadProgress) => void;
  isUploading: () => boolean;
  logger: Logger;
}

export interface resumableUploaderStreamOpts {
  uploadId: string;
  files: File[];
  paths: string[];
  zipFileSize: number;
  onProgress: (progress: UploadProgress) => void,
  isUploading: () => boolean;
  logger: Logger;
}

export interface ChecksumResultStream {
  sha256Digest: basepb.Checksum;
  crc32cDigest: string;
  fileSize: number;
}

export interface UploadResult {
  url: string;
  conversion: frontendpb.MeshConversionStatus;
}

export interface ChecksumResult {
  sha256Digest: basepb.Checksum;
  crc32cDigest: string;
}

// Functions

export function isGeometryFile(fileName: string) {
  const query = fileName.toLowerCase();
  return CAD_FILE_EXTENSIONS.some((extension) => query.endsWith(extension)) ||
    NUMERIC_CAD_FILE_EXTENSIONS.some(
      (extension) => (new RegExp(`.*.${extension}.[0-9]+$`).test(query)),
    ) ||
    (DISCRETE_GEOMETRY_FILE_EXTENSIONS.some((extension) => query.endsWith(extension)));
}

// TODO(LC-17595): remove this function
export function isDiscreteGeometryFile(fileName: string) {
  const query = fileName.toLowerCase();
  return DISCRETE_GEOMETRY_FILE_EXTENSIONS.some((extension) => query.endsWith(extension));
}

export function isMeshFile(fileName: string) {
  return MESH_FILE_EXTENSIONS.some((extension) => fileName.toLowerCase().endsWith(extension));
}

// A heuristic to check if the set of files are for openfoam mesh.
// https://cfd.direct/openfoam/user-guide/v6-basic-file-format/
const openFOAMRequiredFilenames = ['boundary', 'faces', 'neighbour', 'owner', 'points'];

export function isOpenFOAM(paths: string[]): boolean {
  const required = new Set<string>(openFOAMRequiredFilenames);
  paths.forEach((path: string) => {
    // webkitRelativePath uses forward slash.
    const parts = path.split('/');
    const fileName = parts[parts.length - 1];
    if (required.has(fileName)) {
      required.delete(fileName);
    }
  });
  return required.size === 0;
}

// Helper function that converts uploadpb.MeshConversion to frontend.MeshConversion.
export function convertMeshConversion(
  conversion: uploadpb.MeshConversionStatus,
): frontendpb.MeshConversionStatus {
  const conversionMap = {
    [uploadpb.MeshConversionStatus.NOT_REQUIRED]: frontendpb.MeshConversionStatus.NOT_REQUIRED,
    [uploadpb.MeshConversionStatus.IN_PROGRESS]: frontendpb.MeshConversionStatus.IN_PROGRESS,
    [uploadpb.MeshConversionStatus.COMPLETE]: frontendpb.MeshConversionStatus.COMPLETE,
    [uploadpb.MeshConversionStatus.FAILED]: frontendpb.MeshConversionStatus.FAILED,
  };
  return conversionMap[conversion];
}

/**

  Read the region [start, limit) of the given file.
  Args start and limit are file offsets in bytes.
  Wraps the FileReader.readAsArrayBuffer around a Promise.

  @example

  async function Foo(file: File) {
    let data: ArrayBuffer = await readFileAsync(file, 0, file.size);
  }
*/
export function readFileAsync(file: File, start: number, limit: number): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file.slice(start, limit));
  });
}

// Computes sha256 of data. Returns checksum in bytes.
// Use sha256File to get checksum of entire file.
export async function sha256Sum(data: string | Uint8Array): Promise<basepb.Checksum> {
  const hasher = await hashwasm.createSHA256();
  hasher.update(data);
  const proto = new basepb.Checksum({
    algorithm: {
      case: 'sha256',
      value: hasher.digest('binary'),
    },
  });
  return proto;
}

// fileToFileMeta creates a FileMetadata object given a File and it's sha256 checksum.
export function fileToFileMeta(
  file: File,
  sha256Digest: Uint8Array,
  crc32cDigest: string,
): filepb.FileMetadata {
  // File has valid mesh/cad extension.
  const fileParts = file.name.split('.');
  const fileMeta = new filepb.FileMetadata({
    ext: fileParts.pop() as string,
    name: fileParts.join('.'),
    size: BigInt(file.size),
    sha256Checksum: sha256Digest,
    crc32cChecksum: crc32cDigest,
  });
  return fileMeta;
}

// Compute the crc32c checksum of a file.
// Returns checksum as a base64 string.
export async function crc32cFile(file: File): Promise<string> {
  const crc32c = await hashwasm.createCRC32C();
  const fileSize = file.size;
  for (let off = 0; off < fileSize; off += HASH_CHUNK_SIZE) {
    const endOff = Math.min(off + HASH_CHUNK_SIZE, fileSize);
    const data = await readFileAsync(file, off, endOff);
    crc32c.update(new Uint8Array(data));
  }
  return Buffer.from(crc32c.digest('hex'), 'hex').toString('base64');
}

interface ParsedPath {
  dirName: string;
  relPath: string;
}

export function splitPath(path: string): ParsedPath {
  // webkitRelativePath is always of form filename/relative/.../path, so it must
  // contain at least one '/'.
  const match = /^(.+?)\/(.*)$/.exec(path);
  if (match?.[1] && match[2]) {
    return {
      dirName: match[1],
      relPath: match[2],
    };
  }

  throw Error(`invalid rel path ${path}`);
}

/**
 * simpleUploader uses the UploadData RPC to upload a file through jobmaster.
 * Includes the initial StartUpload RPC to jobmaster.
 */
export async function simpleUploader(opts: uploaderOpts) {
  const { uploadId, file, logger, isUploading, onProgress } = opts;

  const method = uploadpb.Method.SIMPLE;
  // Start upload using METHOD_SIMPLE.
  const startUploadReq = new uploadpb.StartUploadRequest({
    uploadId,
    method,
  });
  await rpc.callRetry('StartUpload', rpc.client.startUpload, startUploadReq);
  // Upload the file chunks.
  logger.info(
    `Uploading data for file: ${file.name}, fileSize: ${file.size}, method: ${method}`,
  );
  let off = 0;
  for (; ;) {
    const endOff = Math.min(
      off + SIMPLE_CHUNK_SIZE,
      file.size,
    );
    const data = await readFileAsync(file, off, endOff);
    const uploadDataReq = new uploadpb.UploadDataRequest({
      uploadId,
      offset: BigInt(off),
      data: new Uint8Array(data),
    });
    const reply = await rpc.callRetry('UploadData', rpc.client.uploadData, uploadDataReq);
    if (!isUploading()) {
      throw Error('canceled');
    }
    if (reply.complete) {
      // Upload is complete
      break;
    }
    off = Number(reply.limitOffset);
    const progress = off / file.size;
    const progressStr = formatNumber(progress * 100, { numDecimals: 0 });
    onProgress({
      done: reply.complete,
      progress,
      message: `Uploading (${progressStr}%)`,
    });
  }
  logger.info(`Done uploading data for file: ${file.name}, fileSize: ${file.size},
          method: ${method}`);
}

/**
 * resumableUploader uses a signed URL and headers to create
 * the initial resumable upload POST request,
 * with subsequent PUT requests to persist the data.
 * Includes the initial StartUpload RPC to jobmaster.
 * GCS docs: https://cloud.google.com/storage/docs/performing-resumable-uploads
*/
export async function resumableUploader(opts: uploaderOpts) {
  // Start upload using METHOD_GCS_RESUMABLE.
  const startUploadReq = new uploadpb.StartUploadRequest({
    uploadId: opts.uploadId,
    method: uploadpb.Method.GCS_RESUMABLE,
  });
  const startUploadReply = await rpc.callRetry(
    'StartUpload',
    rpc.client.startUpload,
    startUploadReq,
  );
  const gcsResumableInfo = startUploadReply.upload?.method.value as uploadpb.GCSResumableMethod;

  // Initial resumable upload POST request
  const headers: Record<string, string> = {};
  Object.entries(gcsResumableInfo.httpHeaders).forEach(([header, value]) => {
    headers[header] = value;
  });
  const postResp = await fetchWithRetry(
    gcsResumableInfo.signedUrl,
    {
      method: 'POST',
      headers,
    },
    NUM_RETRIES,
    GCS_RETRY_CODES,
  );
  if (!postResp.ok) {
    throw Error(`Initial upload req failed: ${postResp.statusText}`);
  }
  // Extract sessionURI for subsequent PUT requests.
  const sessionURI = postResp.headers.get('location');
  if (!sessionURI) {
    throw Error('Expected session URI but got none!');
  }

  // Begin uploading file in chunks.
  let off = 0;
  for (; ;) {
    if (!opts.isUploading()) {
      throw Error('canceled');
    }
    const fileSize = opts.file.size;
    const endOff = Math.min(
      off + GCS_CHUNK_SIZE,
      fileSize,
    );
    opts.logger.info(`Uploading chunk ${off}-${endOff - 1}/${fileSize}`);
    const data = await readFileAsync(opts.file, off, endOff);
    const putResp = await fetchWithRetry(
      sessionURI,
      {
        method: 'PUT',
        headers: {
          'content-length': `${endOff - off}`,
          'content-range': `bytes ${off}-${endOff - 1}/${fileSize}`,
        },
        body: data,
      },
      NUM_RETRIES,
      GCS_RETRY_CODES,
    );
    if (putResp.ok) {
      if (endOff < fileSize - 1) {
        throw Error(`Unexpected OK response endOff=${endOff}/${fileSize - 1}`);
      }
      // Upload is completed.
      break;
    }
    // Upload should be incomplete.
    if (putResp.status !== 308) {
      throw Error(`Unexpected http status ${putResp.status}`);
    }
    // Extract range header to determine where to start next chunk
    const range = putResp.headers.get('range');
    if (!range) {
      throw Error(`Unexpected range header ${range}`);
    }
    opts.logger.info(`Cumulative uploaded bytes: ${range}`);
    const persistedOff = parseInt(range.split('-')[1], 10);
    off = persistedOff + 1;
    const progress = off / fileSize;
    const progressStr = formatNumber(progress * 100, { numDecimals: 0 });
    opts.onProgress({
      done: false,
      progress,
      message: `Uploading (${progressStr}%)`,
    });
  }
}

// ZipChunks converts a list of files into a zip file.
// The zipped chunks are written to transformStream.writable
// and must be read from transformStream.readable.
// Note: for large files DO NOT await this function, instead
// read from the readable stream until the stream ends.
export async function zipChunks(
  files: File[],
  paths: string[],
  transformStream: TransformStream<ArrayBufferView, ArrayBufferView>,
): Promise<void> {
  // zip64 to support large files.
  // dates have to be constant for deterministic zip file generation.
  const writer = new zip.ZipWriter(
    transformStream.writable,
    {
      zip64: true,
      level: 0,
      creationDate: new Date(0),
      lastModDate: new Date(0),
      lastAccessDate: new Date(0),
    },
  );

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const path = paths[i];

    // Path is only correct when uploading directories.
    await writer.add(path || file.name, file.stream());
  }

  await writer.close();
}

// Creates a zip file from a list of files and returns a Blob.
export async function createZipFileBlob(files: File[], paths: string[]): Promise<Blob> {
  const writer = new zip.ZipWriter(new zip.BlobWriter(), {
    zip64: true,
    level: 0,
    creationDate: new Date(0),
    lastModDate: new Date(0),
    lastAccessDate: new Date(0),
  });

  for (let i = 0; i < files.length; i += 1) {
    // Path is only correct when uploading directories.
    await writer.add(paths[i] || files[i].name, files[i].stream());
  }

  const zipBlob = await writer.close();
  return zipBlob;
}

/**
  resumableUploaderStream uploads a list of files as a zip file.
  Uses a signed URL and headers to create the initial
  resumable upload POST request,
  with subsequent PUT requests to persist the data.
  GCS docs: https://cloud.google.com/storage/docs/performing-resumable-uploads
*/
export async function resumableUploaderStream(opts: resumableUploaderStreamOpts) {
  // Start upload using METHOD_GCS_RESUMABLE.
  const {
    uploadId,
    files,
    paths,
    zipFileSize,
    isUploading,
    onProgress,
    logger,
  } = opts;
  const startUploadReq = new uploadpb.StartUploadRequest({
    uploadId,
    method: uploadpb.Method.GCS_RESUMABLE,
  });
  const startUploadReply = await rpc.callRetry(
    'StartUpload',
    rpc.client.startUpload,
    startUploadReq,
  );
  const gcsResumableInfo = startUploadReply.upload?.method.value as uploadpb.GCSResumableMethod;

  // Initialize resumable upload and upload file chunks
  // Initial resumable upload POST request
  const headers: Record<string, string> = {};
  Object.entries(gcsResumableInfo.httpHeaders).forEach(([header, value]) => {
    headers[header] = value;
  });
  const postResp = await fetchWithRetry(
    gcsResumableInfo!.signedUrl,
    {
      method: 'POST',
      headers,
    },
    NUM_RETRIES,
    GCS_RETRY_CODES,
  );
  if (!postResp.ok) {
    throw Error(`Initial upload req failed: ${postResp.statusText}`);
  }
  // Extract sessionURI for subsequent PUT requests.
  const sessionURI = postResp.headers.get('location');
  if (!sessionURI) {
    throw Error('Expected session URI but got none!');
  }

  // Begin uploading files as zip chunks.

  const transformStream = new TransformStream<Uint8Array, Uint8Array>(
    undefined, // identity function
    new ByteLengthQueuingStrategy({ highWaterMark: GCS_CHUNK_SIZE * 2 }),
    new ByteLengthQueuingStrategy({ highWaterMark: GCS_CHUNK_SIZE * 2 }),
  );

  // Write the zip chunks to transformStream.writable

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  zipChunks(files, paths, transformStream);

  // Read from transformStream.readable as chunks are available

  // Bytes sucessfully processed from file
  let bytesProcessed = 0;
  const reader = transformStream.readable.getReader();
  // Buffer to store chunks from reader
  let chunkBuf: Uint8Array[] = [];
  // Size of all chunks in buffer in bytes
  let chunkBufSize = 0;

  // Num combined chunks read from reader.
  let i = 0;

  for (; ;) {
    if (!isUploading()) {
      await reader.cancel();
      throw Error('canceled');
    }

    // read a chunk of arbitrary size from reader.
    const { done, value } = await reader.read();

    // Store chunk in chunkBuf
    if (!done) {
      chunkBufSize += value.byteLength;
      chunkBuf.push(value);
    }

    // Process chunks if chunkBufSize is large enough,
    // or reading is done and buffer is not empty.
    if (done || chunkBufSize >= GCS_CHUNK_SIZE) {
      // Combine buffer into a single chunk
      const combinedChunk = new Uint8Array(chunkBufSize);
      let size = 0;
      chunkBuf.forEach((arr) => {
        combinedChunk.set(arr, size);
        size += arr.length;
      });

      i += 1;
      logger.info(`read chunk #${i}`);

      // Total bytes processed until now
      const curBytesProcessed = bytesProcessed;
      // Total bytes to be processed after using buffer.
      let endBytesProcessed = bytesProcessed + chunkBufSize;

      let chunk = combinedChunk;
      // Process only GCS_CHUNK_SIZE at a time.
      if (chunkBufSize > GCS_CHUNK_SIZE) {
        // Total bytes to be processed
        endBytesProcessed = bytesProcessed + GCS_CHUNK_SIZE;
        // Use only upload.GCS_CHUNK_SIZE bytes
        chunk = combinedChunk.slice(0, GCS_CHUNK_SIZE);

        // reset buffer
        chunkBuf = [];
        chunkBufSize = 0;

        // Store remaining bytes in new buffer.
        chunkBuf.push(combinedChunk.slice(GCS_CHUNK_SIZE));
        chunkBufSize += combinedChunk.byteLength - GCS_CHUNK_SIZE;
      }

      // Upload until entire chunk is uploaded to GCS.
      // GCS only allows multiples of 256KiB (except last chunk) so the
      // same request is sent until all the bytes are uploaded.
      // GCS ignores the bytes that have already been uploaded.
      while (bytesProcessed < endBytesProcessed) {
        logger.info(
          `Uploading chunk ${curBytesProcessed}-${endBytesProcessed - 1}/${zipFileSize}`,
        );

        const putResp = await fetchWithRetry(
          sessionURI,
          {
            method: 'PUT',
            headers: {
              'content-length':
                `${endBytesProcessed - curBytesProcessed}`,
              'content-range':
                `bytes ${curBytesProcessed}-${endBytesProcessed - 1}/${zipFileSize}`,
            },
            body: chunk,
          },
          NUM_RETRIES,
          GCS_RETRY_CODES,
        );

        if (putResp.ok) {
          if (endBytesProcessed < zipFileSize - 1) {
            throw Error(`Unexpected OK response
                    endBytesProcessed=${endBytesProcessed}/${zipFileSize - 1}`);
          }
          // Upload complete
          logger.info('Dir upload complete');
          return;
        }
        // Upload should be incomplete.
        if (putResp.status !== 308) {
          throw Error(`Unexpected http status ${putResp.status}`);
        }
        // Extract range header to determine where to start next chunk
        const range = putResp.headers.get('range');
        if (!range) {
          throw Error(`Unexpected range header ${range}`);
        }
        logger.info(`Cumulative uploaded bytes: ${range}`);
        const persistedOff = parseInt(range.split('-')[1], 10);

        bytesProcessed = persistedOff + 1;

        const progress = bytesProcessed / zipFileSize;
        const progressStr = formatNumber(progress * 100, { numDecimals: 0 });
        onProgress({
          done: false,
          progress,
          message: `Importing (${progressStr}%)`,
        });
      }

      if (done) {
        logger.info('Finished reading zip chunks');
        throw Error(`Unexpected finish reading before upload completion.
            BytesProcessed: ${bytesProcessed}, totalSize: ${zipFileSize}`);
      }
    }
  }
}
