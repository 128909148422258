import { useEffect, useMemo } from 'react';

import assert from '../../lib/assert';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { unpackProto } from '../../lib/protoUtils';
import * as codespb from '../../proto/lcstatus/codes_pb';
import * as geometrypb from '../../proto/lcstatus/details/geometry/geometry_pb';
import { LCStatus } from '../../proto/lcstatus/lcstatus_pb';
import { Level } from '../../proto/lcstatus/levels_pb';
import { useGeometryHealthValue } from '../../recoil/geometryHealth';
import { useLcVisReadyValue } from '../../recoil/lcvis/lcvisReadyState';
import { useProjectContext } from '../context/ProjectContext';

import { useFarfieldTransparency } from './useFarfieldTransparency';

/** Errors with precisely defined edges. */
const ERROR_CODES_WITH_EDGES = [
  codespb.Code.GEO_VOLUME_OPEN,
  codespb.Code.GEO_VOLUME_NON_MANIFOLD,
  codespb.Code.GEO_EDGE_UNMESHABLE,
  codespb.Code.GEO_EDGE_NOT_SMOOTH,
  codespb.Code.GEO_EDGE_LARGE_TOLERANCE,
  codespb.Code.GEO_FACE_EDGE_CROSS,
];

/** Errors with surfaceId(s) that need to take neighboring edges of the surfaces. */
const ERROR_CODES_WITH_SURFACES = [
  codespb.Code.GEO_FACE_EDGES_TOO_CLOSE,
  codespb.Code.GEO_FACE_EDGE_TOO_SMALL,
  codespb.Code.GEO_FACE_FACE_INTERSECTION,
  codespb.Code.GEO_FACE_LARGE_TOLERANCE,
  codespb.Code.GEO_FACE_NEEDS_IMPRINT,
  codespb.Code.GEO_FACE_NOT_SMOOTH,
  codespb.Code.GEO_FACE_SELF_INTERSECTION,
  codespb.Code.GEO_FACE_UNMESHABLE,
];

const ERROR_CODES_TO_HIGHLIGHT = [
  ...ERROR_CODES_WITH_EDGES,
  ...ERROR_CODES_WITH_SURFACES,

  // TODO: support highlighting vertices (codespb.Code.GEO_VERTEX_DUPLICATE) once they're
  // supported & displayed in the lcvis
  // see: https://luminarycloud.atlassian.net/browse/LC-22153?focusedCommentId=66663
] as const;

type SupportedErrorCode = typeof ERROR_CODES_TO_HIGHLIGHT[keyof typeof ERROR_CODES_TO_HIGHLIGHT];
type ErrorResolver = (issue: LCStatus, context: { farfieldSurfaces: Set<string> }) => Promise<{
  problematicEdgeIds: Set<string>;
  adjacentSurfaceIds: Set<string>;
}>;

const extractEdgeProblems = async (edge?: geometrypb.Edge | Array<geometrypb.Edge>) => {
  const emptyResult = {
    problematicEdgeIds: new Set<string>(),
    adjacentSurfaceIds: new Set<string>(),
  };

  if (!edge) {
    return emptyResult;
  }

  if (Array.isArray(edge)) {
    const result = emptyResult;

    await Promise.all(edge.map(async (item) => {
      const { problematicEdgeIds, adjacentSurfaceIds } = await extractEdgeProblems(item);

      problematicEdgeIds.forEach((edgeId) => result.problematicEdgeIds.add(edgeId));
      adjacentSurfaceIds.forEach((surfaceId) => result.adjacentSurfaceIds.add(surfaceId));
    }));

    return result;
  }

  return {
    problematicEdgeIds: new Set([edge.id]),
    adjacentSurfaceIds: new Set(edge.adjacentSurfaces),
  };
};

const extractSurfaceProblems = async (
  surfaceId: string | Set<string>,
  farfieldSurfaces: Set<string>,
) => {
  let problematicEdgeIds = new Set<string>();

  if (surfaceId && lcvHandler.display?.workspace) {
    const surfaces = typeof surfaceId === 'string' ? new Set([surfaceId]) : surfaceId;

    await lcvHandler.display.workspace.workspaceQueue.enqueue(async () => {
      problematicEdgeIds = lcvHandler.display?.workspace?.getSelectedNeighborIds(
        surfaces,
        farfieldSurfaces,
      ) ?? new Set<string>();
    }).catch(() => {});
  }

  return {
    adjacentSurfaceIds: new Set<string>(),
    problematicEdgeIds,
  };
};

const ERROR_RESOLVERS = new Map<SupportedErrorCode, ErrorResolver>([
  [
    codespb.Code.GEO_VOLUME_OPEN,
    (issue) => {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeOpenDetails);

      return extractEdgeProblems(details?.openEdges || []);
    },
  ],
  [
    codespb.Code.GEO_VOLUME_NON_MANIFOLD,
    (issue) => {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeNonManifoldDetails);

      return extractEdgeProblems(details?.nonManifoldEdges || []);
    },
  ],
  [
    codespb.Code.GEO_EDGE_UNMESHABLE,
    (issue) => {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeUnmeshableDetails);

      return extractEdgeProblems(details?.edge);
    },
  ],
  [
    codespb.Code.GEO_EDGE_NOT_SMOOTH,
    (issue) => {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeNotSmoothDetails);

      return extractEdgeProblems(details?.edge);
    },
  ],
  [
    codespb.Code.GEO_EDGE_LARGE_TOLERANCE,
    (issue) => {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeLargeToleranceDetails);

      return extractEdgeProblems(details?.edge);
    },
  ],
  [
    codespb.Code.GEO_FACE_EDGE_CROSS,
    (issue) => {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeCrossDetails);
      const edges = [details?.edge1, details?.edge2].filter(Boolean) as geometrypb.Edge[];

      return extractEdgeProblems(edges);
    },
  ],
  [
    codespb.Code.GEO_FACE_EDGES_TOO_CLOSE,
    (issue, context) => {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgesTooCloseDetails);
      const problematicSurfaceId = details?.surfaceId ?? '';

      return extractSurfaceProblems(problematicSurfaceId, context.farfieldSurfaces);
    },
  ],
  [codespb.Code.GEO_FACE_EDGE_TOO_SMALL, (issue, context) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeTooSmallDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return extractSurfaceProblems(problematicSurfaceId, context.farfieldSurfaces);
  }],
  [codespb.Code.GEO_FACE_FACE_INTERSECTION, (issue, context) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceFaceIntersectionDetails);
    const problematicSurfaceId = new Set(
      [details?.surface1Id, details?.surface2Id].filter(Boolean) as string[],
    );

    return extractSurfaceProblems(problematicSurfaceId, context.farfieldSurfaces);
  }],
  [codespb.Code.GEO_FACE_LARGE_TOLERANCE, (issue, context) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceLargeToleranceDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return extractSurfaceProblems(problematicSurfaceId, context.farfieldSurfaces);
  }],
  [codespb.Code.GEO_FACE_NEEDS_IMPRINT, (issue, context) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceNeedsImprintDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return extractSurfaceProblems(problematicSurfaceId, context.farfieldSurfaces);
  }],
  [codespb.Code.GEO_FACE_NOT_SMOOTH, (issue, context) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceNotSmoothDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return extractSurfaceProblems(problematicSurfaceId, context.farfieldSurfaces);
  }],
  [codespb.Code.GEO_FACE_SELF_INTERSECTION, (issue, context) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceSelfIntersectionDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return extractSurfaceProblems(problematicSurfaceId, context.farfieldSurfaces);
  }],
  [codespb.Code.GEO_FACE_UNMESHABLE, (issue, context) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceUnmeshableDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return extractSurfaceProblems(problematicSurfaceId, context.farfieldSurfaces);
  }],
]);

assert(
  ERROR_RESOLVERS.size === ERROR_CODES_TO_HIGHLIGHT.length,
  'Some error types do not have corresponding resolvers',
);

export const useHighlightProblematicEdges = () => {
  // == Contexts
  const { projectId } = useProjectContext();

  // == Recoil
  const geometryHealth = useGeometryHealthValue(projectId);
  const { farfieldSurfaces } = useFarfieldTransparency();
  const lcvisReady = useLcVisReadyValue();

  const supportedIssues = useMemo(() => (geometryHealth?.issues || []).filter(
    ({ code }) => (ERROR_CODES_TO_HIGHLIGHT as readonly codespb.Code[]).includes(code),
  ), [geometryHealth?.issues]);

  useEffect(() => {
    if (!lcvisReady) {
      return;
    }

    lcvHandler.queueDisplayFunction('setProblematicEdges', async (display) => {
      // collect problematic entries from every issue
      const problematicEdges = new Map<string, LCStatus['level']>();
      const adjacentSurfaces = new Set<string>();

      await Promise.all(supportedIssues.map(async (issue) => {
        // we want red color for problematic surfaces' neighbors, even if it's only a warning
        const currentIssueLevel = (
          ERROR_CODES_WITH_SURFACES.includes(issue.code) ? Level.ERROR : issue.level
        );
        const errorResolver = ERROR_RESOLVERS.get(issue.code as SupportedErrorCode);

        if (errorResolver) {
          const {
            problematicEdgeIds,
            adjacentSurfaceIds,
          } = await errorResolver(issue, { farfieldSurfaces });

          problematicEdgeIds.forEach((edge) => {
            const alreadyExistingLevel = problematicEdges.get(edge);
            const hasHigherLevel = !alreadyExistingLevel || currentIssueLevel === Level.ERROR;

            if (hasHigherLevel) {
              problematicEdges.set(edge, currentIssueLevel);
            }
          });
          adjacentSurfaceIds.forEach((edge) => adjacentSurfaces.add(edge));
        }
      }));

      display?.workspace?.setProblematicEdges(problematicEdges);
    });
  }, [farfieldSurfaces, supportedIssues, lcvisReady]);
};
