// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/imagerenderer/imagerenderer.proto (package luminary.proto.imagerenderer, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { PostProcessingExtractSpec } from "../visualizationservice/visualizationservice_pb.js";

/**
 * @generated from message luminary.proto.imagerenderer.OutputInfo
 */
export const OutputInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.imagerenderer.OutputInfo",
  () => [
    { no: 1, name: "base_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "prefix_kv_pairs", kind: "message", T: OutputInfo_KeyValuePair, repeated: true },
  ],
);

/**
 * Provides a file::ParsedBase schema that should be used by the worker to
 * write the checkpoints files deemed necessary. The worker needs to add these
 * kv prefix pairs to the base_path. The worker is allowed to add suffixes and
 * prefixes to this base path but should not modify the base path itself or
 * the order of kv pairs.
 *
 * @generated from message luminary.proto.imagerenderer.OutputInfo.KeyValuePair
 */
export const OutputInfo_KeyValuePair = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.imagerenderer.OutputInfo.KeyValuePair",
  () => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "OutputInfo_KeyValuePair"},
);

/**
 * @generated from message luminary.proto.imagerenderer.ImageRendererConfig
 */
export const ImageRendererConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.imagerenderer.ImageRendererConfig",
  () => [
    { no: 1, name: "extract", kind: "message", T: PostProcessingExtractSpec },
    { no: 2, name: "source_filter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filters_to_url", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 4, name: "output_info", kind: "message", T: OutputInfo },
  ],
);

