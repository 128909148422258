// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { AdFloatType } from '../proto/base/base_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import { OutputNodes } from '../proto/frontend/output/output_pb';
import { ReferenceValueType, ReferenceValues } from '../proto/output/reference_values_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { getAdValue, newAdFloat } from './adUtils';
import { findFarfield, findParentPhysicsByBoundaryConditionId } from './boundaryConditionUtils';
import { getMaterialFluid } from './materialUtils';
import { idealGasSoundSpeed } from './outputNodeUtils';
import { findFluidPhysicsMaterial } from './physicsUtils';

const {
  REFERENCE_PRESCRIBE_VALUES,
  REFERENCE_FARFIELD_VALUES,
} = ReferenceValueType;

export function validReferenceValueSelection(
  outputNodes: OutputNodes,
  simParam: simulationpb.SimulationParam,
): boolean {
  return (
    outputNodes.referenceValues?.referenceValueType !== REFERENCE_FARFIELD_VALUES ||
    !!findFarfield(simParam)
  );
}

export function getReferencePressure(
  outputNodes: OutputNodes,
  simParam: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): { value: AdFloatType, readOnly: boolean } {
  switch (outputNodes.referenceValues!.referenceValueType) {
    case REFERENCE_PRESCRIBE_VALUES:
      return { value: outputNodes.referenceValues?.pRef!, readOnly: false };
    case REFERENCE_FARFIELD_VALUES: {
      const farField = findFarfield(simParam);
      if (!farField) {
        // Set to 0 if the farfield is deleted after selecting Far Field Values.
        return { value: newAdFloat(0), readOnly: true };
      }
      const fluidMaterial = getMaterialAtFarfield(simParam, farField, geometryTags, staticVolumes);
      const pRef =
          getAdValue(fluidMaterial?.referencePressure) + getAdValue(farField.farfieldPressure);
      return { value: newAdFloat(pRef), readOnly: true };
    }
    default:
      throw Error('Unrecognized reference values selection case.');
  }
}

export function getReferenceTemperature(
  outputNodes: OutputNodes,
  simParam: simulationpb.SimulationParam,
): { value: AdFloatType; readOnly: boolean; } {
  switch (outputNodes.referenceValues!.referenceValueType) {
    case REFERENCE_PRESCRIBE_VALUES:
      return { value: outputNodes.referenceValues?.tRef!, readOnly: false };
    case REFERENCE_FARFIELD_VALUES: {
      const farField = findFarfield(simParam);
      if (farField) {
        return { value: farField.farfieldTemperature!, readOnly: true };
      }
      // Set to 0 if the farfield is deleted after selecting Far Field Values.
      return { value: newAdFloat(0), readOnly: true };
    }
    default:
      throw Error('Unrecognized reference values selection case.');
  }
}

function getMaterialAtFarfield(
  simParam: simulationpb.SimulationParam,
  farfieldBoundary: simulationpb.BoundaryConditionsFluid | undefined,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
) {
  if (!farfieldBoundary) {
    return null;
  }
  const physics = findParentPhysicsByBoundaryConditionId(
    simParam,
    farfieldBoundary.boundaryConditionName,
  );
  if (!physics) {
    return null;
  }
  const materialEntity = findFluidPhysicsMaterial(simParam, physics, geometryTags, staticVolumes);
  if (!materialEntity) {
    return null;
  }
  return getMaterialFluid(materialEntity);
}

export function getReferenceVelocity(
  outputNodes: OutputNodes,
  simParam: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
): { value: AdFloatType; readOnly: boolean; } {
  switch (outputNodes.referenceValues!.referenceValueType) {
    case REFERENCE_PRESCRIBE_VALUES:
      return { value: outputNodes.referenceValues?.vRef!, readOnly: false };
    case REFERENCE_FARFIELD_VALUES: {
      const farField = findFarfield(simParam);
      if (!farField) {
        return { value: newAdFloat(0), readOnly: true };
      }
      const fluidMaterial = getMaterialAtFarfield(simParam, farField, geometryTags, staticVolumes);
      if (fluidMaterial?.densityRelationship !== simulationpb.DensityRelationship.IDEAL_GAS ||
        farField.farfieldMomentum === simulationpb.FarfieldMomentum.FARFIELD_VELOCITY_MAGNITUDE
      ) {
        return { value: farField.farfieldVelocityMagnitude!, readOnly: true };
      }
      const cp = getAdValue(fluidMaterial.specificHeatCp);
      const molecularWeight = getAdValue(fluidMaterial.molecularWeight);
      const farfieldTemp = getAdValue(farField.farfieldTemperature);
      const farfieldMach = getAdValue(farField.farfieldMachNumber);
      const sonicSpeed = idealGasSoundSpeed(cp, molecularWeight, farfieldTemp);
      return { value: newAdFloat(sonicSpeed * farfieldMach), readOnly: true };
    }
    default:
      throw Error('Unrecognized reference values selection case.');
  }
}

export function getReferenceGeometricQuantity(
  outputNodes: OutputNodes,
  quantity: string,
): { value: AdFloatType; readOnly: boolean; } {
  const refValues = outputNodes.referenceValues;
  let refVal: AdFloatType;
  switch (quantity) {
    case 'AreaRef': refVal = refValues?.areaRef!; break;
    case 'LengthRef': refVal = refValues?.lengthRef!; break;
    case 'LengthRefPitch': refVal = refValues?.lengthRefPitch!; break;
    case 'LengthRefRoll': refVal = refValues?.lengthRefRoll!; break;
    case 'LengthRefYaw': refVal = refValues?.lengthRefYaw!; break;
    default: throw Error('Unrecognized geometric reference value param description');
  }
  return { value: refVal, readOnly: false };
}

/**
 * Return the current reference values
 * @param outputNodes
 * @param param
 * @param enabledExperiments
 * @returns reference values
 */
export function getReferenceValues(
  outputNodes: OutputNodes,
  param: simulationpb.SimulationParam,
  enabledExperiments: string[],
  geometryTags: GeometryTags,
  staticVolumes: StaticVolume[],
):
  ReferenceValues {
  if (outputNodes.useRefValues) {
    return new ReferenceValues({
      referenceValueType: outputNodes.referenceValues?.referenceValueType!,
      areaRef: outputNodes.referenceValues?.areaRef!,
      lengthRef: outputNodes.referenceValues?.lengthRef!,
      lengthRefYaw: outputNodes.referenceValues?.lengthRefYaw!,
      lengthRefPitch: outputNodes.referenceValues?.lengthRefPitch!,
      lengthRefRoll: outputNodes.referenceValues?.lengthRefRoll!,
      useAeroMomentRefLengths: outputNodes.referenceValues?.useAeroMomentRefLengths!,
      pRef: getReferencePressure(outputNodes, param, geometryTags, staticVolumes).value,
      tRef: getReferenceTemperature(outputNodes, param).value,
      vRef: getReferenceVelocity(outputNodes, param, geometryTags, staticVolumes).value,
    });
  }
  const { areaRef, lengthRef, pRef, tRef, vRef } = param.referenceValues || {};

  return new ReferenceValues({
    referenceValueType: ReferenceValueType.REFERENCE_PRESCRIBE_VALUES,
    areaRef,
    lengthRef,
    pRef,
    tRef,
    vRef,
  });
}
