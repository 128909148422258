// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import FormControl from '@mui/material/FormControl';

import { useRenderVariableSelection } from '../../model/hooks/useRenderVariableSelection';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { useMeshUrlState } from '../../recoil/meshState';
import { DataSelect } from '../Form/DataSelect';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';

import GeomRepresentationMenu from './GeomRepresentationMenu';
import ResultsVariableSelect from './ResultsVariableSelect';

const useStyles = makeStyles(
  () => createStyles({
    container: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'ParaviewControlBar' },
);

export interface ParaviewControlBarProps {
  // True if we include the toggle between mesh and geometry.
  meshGeomToggle: boolean;
}

// Draw dropdown menus that selects "Surface"/"Wireframe"/etc. and the camera
// position.
const ParaviewControlBar = (props: ParaviewControlBarProps) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Hooks
  const classes = useStyles();
  const renderVariableSelection = useRenderVariableSelection({ projectId, workflowId, jobId });

  // == Recoil
  const [meshUrlState, setMeshUrlState] = useMeshUrlState(projectId);

  const activeType = meshUrlState.activeType;
  const urlStateOptions = [
    {
      name: 'Geometry',
      value: projectstatepb.UrlType.GEOMETRY,
      selected: activeType === projectstatepb.UrlType.GEOMETRY,
    },
  ];

  // Only add the mesh option if we have a mesh url.
  if (meshUrlState.mesh) {
    urlStateOptions.push({
      name: 'Mesh',
      value: projectstatepb.UrlType.MESH,
      selected: activeType === projectstatepb.UrlType.MESH,
    });
  }

  return (
    <div data-locator="paraview-control-bar">
      <FormControl>
        <div className={classes.container}>
          {props.meshGeomToggle && (
            <FormControl>
              <DataSelect
                asBlock
                kind="minimal"
                onChange={(value) => {
                  if (meshUrlState.activeType !== value) {
                    const newMeshUrlState = meshUrlState.clone();
                    newMeshUrlState.activeType = value;
                    setMeshUrlState(newMeshUrlState);
                  }
                }}
                options={urlStateOptions}
                size="medium"
              />
            </FormControl>
          )}
          {renderVariableSelection && (
            <ResultsVariableSelect />
          )}
          <GeomRepresentationMenu />
        </div>
      </FormControl>
    </div>
  );
};

export default ParaviewControlBar;
