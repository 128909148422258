// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView, INTERMEDIATE_VIEWS } from '../../../lib/componentTypes/context';
import { LeveledMessage, levelToRank } from '../../../lib/notificationUtils';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { SimulationValidator, validateSimulation } from '../../../lib/simulationValidation';
import { onGeometryTabSelector } from '../../../recoil/geometry/geometryState';
import { geometryTagsState } from '../../../recoil/geometry/geometryTagsState';
import { geometryPendingState } from '../../../recoil/pendingWorkOrders';
import { initDataSelector } from '../../../recoil/useInitData';
import { meshReadyStateAtom } from '../../../recoil/useMeshReadyState';
import { currentViewAtom_DEPRECATED } from '../../internal/global/currentView';
import { intermediateTreeSelector } from '../../internal/tree/setup';

import { simulationBoundaryNamesState } from './simulation/param/boundaryNames';
import { projectValidationDataState } from './validationData';

export const projectValidatorState = selectorFamily<
  SimulationValidator,
  SectionRecoilKey
>({
  key: 'projectValidatorState',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    if (get(onGeometryTabSelector)) {
      return new SimulationValidator();
    }
    const [
      boundaryConditionNames,
      geometryPending,
      initData,
      meshReady,
      simData,
      geometryTags,
    ] = get(waitForAll([
      simulationBoundaryNamesState(key),
      geometryPendingState(key.projectId),
      initDataSelector(key),
      meshReadyStateAtom(key),
      projectValidationDataState(key),
      geometryTagsState({ projectId: key.projectId }),
    ]));
    return validateSimulation(
      { ...simData, boundaryConditionNames, geometryPending, initData, meshReady, geometryTags },
    );
  },
  dangerouslyAllowMutability: true,
});

export function useProjectValidator(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(projectValidatorState({ projectId, workflowId, jobId }));
}

const errorRank = levelToRank('error');

type LevelMessageMap = Map<string, LeveledMessage[]>;
type LevelMessageViewMap = Map<CurrentView, LevelMessageMap>;

const allWarningsState = selectorFamily<LevelMessageViewMap, SectionRecoilKey>({
  key: 'allWarningsState',
  get: (key: SectionRecoilKey) => ({ get }) => {
    const { projectId, workflowId, jobId } = key;
    const [
      validator,
    ] = get(waitForAll([
      projectValidatorState({ projectId, workflowId, jobId }),
    ]));
    const views = [...INTERMEDIATE_VIEWS, CurrentView.ADVANCED_ANALYSIS];
    const intermediateTrees = get(waitForAll(
      views.map((view) => intermediateTreeSelector({ ...key, currentView: view })),
    ));

    const warnings: LevelMessageViewMap = new Map();
    const setupWarnings: LevelMessageMap = new Map();
    views.forEach((view, index) => {
      const messages = validator.messageMap;
      const viewWarnings: LevelMessageMap = new Map();
      messages.forEach((collector, id) => {
        if (intermediateTrees[index].nodeIds.has(id) && collector.messages.length > 0) {
          const filteredMessages = collector.messages
            .filter((message) => levelToRank(message.level) >= errorRank);
          if (filteredMessages.length > 0) {
            viewWarnings.set(
              id,
              filteredMessages,
            );
            // The setup tab is a special case where we want to show all warnings
            setupWarnings.set(id, filteredMessages);
          }
        }
      });
      warnings.set(view, viewWarnings);
    });
    warnings.set(CurrentView.SETUP, setupWarnings);
    return warnings;
  },
});

export function useAllWarnings(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(allWarningsState({ projectId, workflowId, jobId }));
}

const perTabWarnings = selectorFamily<LevelMessageMap, SectionRecoilKey>({
  key: 'perTabWarnings',
  get: (key: SectionRecoilKey) => ({ get }) => {
    const currenView = get(currentViewAtom_DEPRECATED);
    const warnings = get(allWarningsState(key));
    return warnings.get(currenView) ?? new Map();
  },
});

export const usePerTabWarnings = (
  projectId: string,
  workflowId: string,
  jobId: string,
) => useRecoilValue(perTabWarnings({ projectId, workflowId, jobId }));

export const useAllTabWarnings = (
  projectId: string,
  workflowId: string,
  jobId: string,
) => useRecoilValue(
  allWarningsState({ projectId, workflowId, jobId }),
);
