// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useState } from 'react';

import * as flags from '../../flags';
import { boldEscaped } from '../../lib/html';
import { getMaterialName } from '../../lib/materialUtils';
import { getPhysicsName } from '../../lib/physicsUtils';
import { getMaterialAssignmentTooltip, getPhysicsAssignmentTooltip } from '../../lib/volumeUtils';
import { usePhysicsSet } from '../../model/hooks/usePhysicsSet';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';
import { ContextMenuSection } from '../treePanel/TreeRow';

import { useTagsInteractiveGeometry } from './useInteractiveGeometry';
import { useVolumeNode } from './useVolumeNode';

export const useVolumeNodeRowMenu = (id: string) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  // == Hooks
  const {
    staticVolume,

    assignedMaterialId,
    assignedMaterial,
    materialsMenuItems,
    unassignMaterial,

    assignedPhysicsId,
    assignedPhysics,
    physicsMenuItems,
    unassignPhysics,
  } = useVolumeNode(id);
  const {
    getVolumePhysicsAssignmentDisabledReason,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);
  const { isCreateTagDisabled } = useTagsInteractiveGeometry();
  const isGeometryView = useIsGeometryView();
  const geoTagsEnabled = useIsEnabled(flags.geoTags);

  // With tag creation, we request the users to provide a name for the tag via a dialog.
  const [isCreateTagDialogOpen, setIsCreateTagDialogOpen] = useState(false);
  const [isCreateTagFacesDialogOpen, setIsCreateTagFacesDialogOpen] = useState(false);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];
    if (isGeometryView) {
      if (!isCreateTagDisabled && geoTagsEnabled) {
        sections.push({
          section: 'grouping',
          menuItems: [
            {
              label: 'Create Tag',
              onClick: () => {
                setIsCreateTagDialogOpen(true);
              },
            },
            {
              label: 'Create Tag on Faces',
              onClick: () => {
                setIsCreateTagFacesDialogOpen(true);
              },
            },
          ],
        });
      }
      return sections;
    }
    const materialLocked = !!(assignedPhysicsId && assignedMaterialId);
    const materialTooltip = getMaterialAssignmentTooltip(
      readOnly,
      !!assignedPhysicsId,
      !!assignedMaterialId,
    );
    const physicsTooltip = getPhysicsAssignmentTooltip(
      readOnly,
      !!assignedPhysics,
      !!assignedMaterial,
    );

    if (assignedMaterial) {
      sections.push({
        section: 'relations',
        menuItems: [
          {
            label: `Unassign ${boldEscaped(getMaterialName(assignedMaterial, simParam))}`,
            help: materialLocked ? '' : materialTooltip,
            onClick: unassignMaterial,
            disabled: materialLocked,
            disabledReason: materialLocked ? materialTooltip : '',
          },
        ],
      });
      if (assignedPhysics && assignedPhysicsId && staticVolume) {
        const disabledReason = getVolumePhysicsAssignmentDisabledReason(
          assignedPhysicsId,
          staticVolume.domain,
        );
        sections.push({
          section: 'relations',
          menuItems: [
            {
              label: `Unassign ${boldEscaped(getPhysicsName(assignedPhysics, simParam))}`,
              help: physicsTooltip,
              onClick: unassignPhysics,
              disabled: !!disabledReason,
              disabledReason: disabledReason || physicsTooltip,
            },
          ],
        });
      } else if (physicsMenuItems.length) {
        sections.push({
          section: 'relations',
          menuItems: [
            {
              label: 'Assign Physics',
              onClick: () => { },
              items: physicsMenuItems,
            },
          ],
        });
      }
    } else if (materialsMenuItems.length) {
      sections.push({
        section: 'relations',
        menuItems: [
          {
            label: 'Assign Material',
            onClick: () => { },
            items: materialsMenuItems,
          },
        ],
      });
    }

    return sections;
  }, [
    assignedMaterial,
    assignedMaterialId,
    assignedPhysics,
    assignedPhysicsId,
    getVolumePhysicsAssignmentDisabledReason,
    materialsMenuItems,
    physicsMenuItems,
    readOnly,
    simParam,
    staticVolume,
    unassignMaterial,
    unassignPhysics,
    isGeometryView,
    isCreateTagDisabled,
    geoTagsEnabled,
  ]);

  return {
    getExtraContextMenuItems,
    isCreateTagDialogOpen,
    isCreateTagFacesDialogOpen,
    setIsCreateTagDialogOpen,
    setIsCreateTagFacesDialogOpen,
  };
};
