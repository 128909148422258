// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as shapepb from '../proto/cad/shape_pb';
import * as cadmetadatapb from '../proto/cadmetadata/cadmetadata_pb';
import * as geometrypb from '../proto/geometry/geometry_pb';
import { DEFAULT_SELECTED_FEATURE, DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, SelectedFeature } from '../recoil/geometry/geometryState';
import { StaticVolume } from '../recoil/volumes';

import { magnitude } from './Vector';
import { getAdValue } from './adUtils';
import { SvgIconSpec } from './componentTypes/svgIcon';
import { DEFAULT_FARFIELD_SCALE, boxCenter, defaultCube, defaultCylinder, diagonalLength } from './farfieldUtils';
import { fromBigInt } from './number';
import { NodeType, SimulationTreeNode } from './simulationTree/node';
import { mapIndicestoIds, mapVolumeIdsToIndices } from './volumeUtils';

/** Given a geometry feature, this function returns its associated icon within the feature list. */
export function iconForFeature(feature: geometrypb.Feature | undefined): SvgIconSpec {
  if (!feature) {
    return { name: 'hash' };
  }
  const getName = () => {
    switch (feature.operation?.case) {
      case 'boolean':
        switch (feature.operation.value.op.case) {
          case 'regUnion':
            return 'geometryUnion';
          case 'regIntersection':
            return 'geometryIntersect';
          case 'regChop':
            return 'geometryXor';
          case 'regSubtraction':
            return 'geometrySubtract';
          default:
            return 'hash';
        }
      case 'delete':
        return 'cubeDash';
      case 'create':
        switch (feature.operation.value.shape.case) {
          case 'box':
            return 'cubeSolid';
          case 'sphere':
            return 'sphere';
          case 'halfSphere':
            return 'sphere';
          case 'cone':
            return 'cone';
          case 'cylinder':
            return 'cylinder';
          case 'torus':
            return 'hash';
          default:
            return 'hash';
        }
      case 'transform':
        switch (feature.operation.value.t.case) {
          case 'rotation':
            return 'rotate';
          case 'scaling':
            return 'geometryScale';
          case 'translation':
            return 'arrowUpDownLeftRight';
          case 'reflection':
            return 'mirroredTriangles';
          default:
            return 'hash';
        }
      case 'import':
        return 'diskArrowUp';
      case 'imprint':
        return 'doubleUniqueFrames';
      case 'shrinkwrap':
        return 'shrinkwrap';
      case 'farfield':
        return 'farfield';
      case 'pattern':
        switch (feature.operation.value.direction!.type.case) {
          case 'linearSpacing':
            return 'patternLinear';
          case 'circularDistribution':
            return 'patternCircular';
          default:
            return 'hash';
        }
      default:
        return 'hash';
    }
  };
  return { name: getName() };
}

export function getTitleForFeature(feature: geometrypb.Feature | undefined): string {
  if (!feature) {
    return '';
  }

  switch (feature.operation?.case) {
    case 'boolean':
      switch (feature.operation.value.op.case) {
        case 'regUnion':
          return 'Unite';
        case 'regIntersection':
          return 'Intersect';
        case 'regChop':
          return 'Chop';
        case 'regSubtraction':
          return 'Subtract';
        default:
          return 'Boolean';
      }
    case 'delete':
      return 'Delete';
    case 'create':
      switch (feature.operation.value.shape.case) {
        case 'box':
          return 'Box';
        case 'sphere':
          return 'Sphere';
        case 'halfSphere':
          return 'Half Sphere';
        case 'cylinder':
          return 'Cylinder';
        default:
          return 'Shape';
      }
    case 'transform':
      switch (feature.operation.value.t.case) {
        case 'rotation':
          return 'Rotate';
        case 'scaling':
          return 'Scale';
        case 'translation':
          return 'Translate';
        case 'reflection':
          return 'Mirror';
        default:
          return 'Transform';
      }
    case 'imprint':
      return 'Imprint';
    case 'import':
      return 'Import';
    case 'shrinkwrap':
      return 'Shrinkwrap';
    case 'farfield':
      return 'Farfield';
    case 'pattern':
      switch (feature.operation.value.direction!.type.case) {
        case 'linearSpacing':
          return 'Linear Pattern';
        case 'circularDistribution':
          return 'Circular Pattern';
        default:
          return 'Pattern';
      }
    default:
      return 'Modification';
  }
}

/**
 * Given a geometry feature, this function returns a user-friendly feature name to be used when
 * the feature is created. If the feature already has a name, it will return that name.
 */
export function getFeatureInitialName(
  feature: geometrypb.Feature,
  currentFeatures: geometrypb.Feature[],
): string {
  if (feature.featureName) {
    return feature.featureName;
  }
  let nBox = 1;
  let nCone = 1;
  let nCylinder = 1;
  let nSphere = 1;
  let nHalfSphere = 1;
  let nBooleanUnion = 1;
  let nBooleanSub = 1;
  let nBooleanIntersect = 1;
  let nBooleanChop = 1;
  let nImport = 1;
  let nImprint = 1;
  let nDelete = 1;
  let nScale = 1;
  let nTranslation = 1;
  let nRotation = 1;
  let nReflection = 1;
  let nShrinkwrap = 1;
  let nFarfield = 1;
  let nLinearPattern = 1;
  let nCircularPattern = 1;
  currentFeatures.forEach((curr) => {
    switch (curr.operation.case) {
      case 'create': {
        const shape = curr.operation.value as geometrypb.Create;
        switch (shape.shape.case) {
          case 'box':
            nBox += 1;
            break;
          case 'cone':
            nCone += 1;
            break;
          case 'cylinder':
            nCylinder += 1;
            break;
          case 'sphere':
            nSphere += 1;
            break;
          case 'halfSphere':
            nHalfSphere += 1;
            break;
          default:
            throw Error('Unknown shape create operation');
        }
        break;
      }
      case 'imprint':
        nImprint += 1;
        break;
      case 'import':
        nImport += 1;
        break;
      case 'boolean': {
        const boolean = curr.operation.value as geometrypb.Boolean;
        switch (boolean.op.case) {
          case 'regUnion':
            nBooleanUnion += 1;
            break;
          case 'regSubtraction':
            nBooleanSub += 1;
            break;
          case 'regIntersection':
            nBooleanIntersect += 1;
            break;
          case 'regChop':
            nBooleanChop += 1;
            break;
          default:
            throw Error('Unknown boolean operation');
        }
        break;
      }
      case 'delete':
        nDelete += 1;
        break;
      case 'transform': {
        const boolean = curr.operation.value as geometrypb.Transform;
        switch (boolean.t.case) {
          case 'scaling':
            nScale += 1;
            break;
          case 'translation':
            nTranslation += 1;
            break;
          case 'rotation':
            nRotation += 1;
            break;
          case 'reflection':
            nReflection += 1;
            break;
          default:
            throw Error('Unknown transform operation');
        }
        break;
      }
      case 'shrinkwrap':
        nShrinkwrap += 1;
        break;
      case 'farfield':
        nFarfield += 1;
        break;
      case 'pattern': {
        const pattern = curr.operation.value as geometrypb.Pattern;
        switch (pattern.direction!.type.case) {
          case 'linearSpacing':
            nLinearPattern += 1;
            break;
          case 'circularDistribution':
            nCircularPattern += 1;
            break;
          default:
            throw Error('Unknown pattern operation');
        }
        break;
      }
      default:
        throw Error('Unknown operation');
    }
  });

  switch (feature.operation.case) {
    case 'create': {
      const shape = feature.operation.value as geometrypb.Create;
      switch (shape.shape.case) {
        case 'box':
          return `Box ${nBox}`;
        case 'cone':
          return `Cone ${nCone}`;
        case 'cylinder':
          return `Cylinder ${nCylinder}`;
        case 'sphere':
          return `Sphere ${nSphere}`;
        case 'halfSphere':
          return `Half Sphere ${nHalfSphere}`;
        default:
          throw Error('Unknown shape create operation');
      }
    }
    case 'imprint':
      return `Imprint ${nImprint}`;
    case 'import':
      return `Import ${nImport}`;
    case 'boolean': {
      const boolean = feature.operation.value as geometrypb.Boolean;
      switch (boolean.op.case) {
        case 'regUnion':
          return `Union ${nBooleanUnion}`;
        case 'regSubtraction':
          return `Subtraction ${nBooleanSub}`;
        case 'regIntersection':
          return `Intersection ${nBooleanIntersect}`;
        case 'regChop':
          return `Chop ${nBooleanChop}`;
        default:
          throw Error('Unknown boolean operation');
      }
    }
    case 'delete': {
      return `Delete ${nDelete}`;
    }
    case 'transform': {
      const boolean = feature.operation.value as geometrypb.Transform;
      switch (boolean.t.case) {
        case 'scaling':
          return `Scale ${nScale}`;
        case 'translation':
          return `Translation ${nTranslation}`;
        case 'rotation':
          return `Rotation ${nRotation}`;
        case 'reflection':
          return `Mirror ${nReflection}`;
        default:
          throw Error('Unknown transform operation');
      }
    }
    case 'shrinkwrap':
      return `Shrinkwrap ${nShrinkwrap}`;
    case 'farfield':
      return `Farfield ${nFarfield}`;
    case 'pattern': {
      const pattern = feature.operation.value as geometrypb.Pattern;
      switch (pattern.direction!.type.case) {
        case 'linearSpacing':
          return `Linear Pattern ${nLinearPattern}`;
        case 'circularDistribution':
          return `Circular Pattern ${nCircularPattern}`;
        default:
          throw Error('Unknown pattern operation');
      }
    }
    default:
      throw Error('Unknown operation');
  }
}

/**
 * Returns a new geometry tree with only volume nodes. Used for the geometry tree panel on the Geo
 * page. If the tree node is not populated with children for some reason, do not attempt to parse
 * it, just return the whole geometry tree.
 *
 * @param tree The entire geometry tree
 * @returns The geometry tree with only the volume nodes
 */
export function parseVolumeTree(tree: SimulationTreeNode): SimulationTreeNode {
  const geometryContainerNode = tree.children.find((item) => item.type !== NodeType.TAGS_CONTAINER);

  if (!geometryContainerNode?.children.length) {
    return tree;
  }

  const volumeContainerNode = geometryContainerNode.children.find(
    (node) => node.type === NodeType.VOLUME_CONTAINER,
  );

  if (!volumeContainerNode) {
    return tree;
  }

  const tagsContainerNode = tree.children.find(
    (node) => node.type === NodeType.TAGS_CONTAINER,
  );

  const newVolumeContainerNode = new SimulationTreeNode(
    geometryContainerNode.type,
    geometryContainerNode.id,
    geometryContainerNode.name,
    [volumeContainerNode],
  );

  const newTree = new SimulationTreeNode(
    tree.type,
    tree.id,
    tree.name,
    [tagsContainerNode, newVolumeContainerNode].filter(Boolean) as SimulationTreeNode[],
  );

  return newTree;
}

// Transforms a list of volume IDs into the body id in the CAD metadata.
export function volumeNodeIdsToCadIds(
  volumeIds: string[],
  staticVolumes: StaticVolume[],
  cadMetadata: cadmetadatapb.CadMetadata,
) {
  const indices = mapVolumeIdsToIndices(volumeIds, staticVolumes);
  return indices.reduce((result, idx) => {
    const cadVolId = cadMetadata.volumeIds[fromBigInt(idx)];
    if (!Number.isNaN(Number(cadVolId))) {
      result.push(BigInt(cadVolId));
    }
    return result;
  }, [] as bigint[]);
}

// Inverse of volumeNodeIdsToCadIds.
export function cadIdsToVolumeNodeIds(
  cadIds: bigint[],
  staticVolumes: StaticVolume[],
  cadMetadata: cadmetadatapb.CadMetadata,
) {
  const bigIndices = cadIds.map((cadId) => cadMetadata.volumeIds.indexOf(fromBigInt(cadId)));
  return mapIndicestoIds(staticVolumes, bigIndices);
}

/**
 * Returns whether a feature is modified by comparing it to the server features.
 * @param feature the client feature.
 * @param serverFeatures the server features.
 * @returns true if the feature is not found with serverFeatures or if the proto comparison between
 * the feature and the server feature returns false.
 */
export function isFeatureModified(
  feature?: geometrypb.Feature,
  serverFeatures?: geometrypb.Feature[],
) {
  if (!feature || !serverFeatures) {
    return false;
  }
  const serverFeature = serverFeatures.find((sFeature) => sFeature.id === feature.id);
  if (serverFeature === undefined) {
    return true;
  }
  return !serverFeature.equals(feature);
}

/**
 * Various warnings that can be displayed to prevent the user from applying a feature.
 */
export const FEATURE_WARNINGS = {
  undef: 'Feature or operation is undefined.',
  import: {
    scaling: 'Scaling factor must be > 0.',
  },
  create: {
    shapeUndef: 'Shape value is undefined.',
    boxLengths: 'Box must have nonzero lengths.',
    radius: 'Radius must be > 0.',
    coneApexBase: 'Apex and base must not be equal.',
    cylinderStartEnd: 'Start and end must not be equal.',
    torusRadii: 'Major and minor radii must be > 0.',
    halfSphereNormal: 'Normal must have nonzero magnitude.',
    unknownShape: 'Unknown shape.',
  },
  boolean: {
    undef: 'Boolean operation is undefined.',
    noBodies: 'No volumes assigned.',
    no2Bodies: 'At least 2 volumes required.',
    noTools: 'No tools assigned.',
    unknownOp: 'Unknown boolean operation.',
  },
  delete: {
    noBodies: 'No volumes assigned.',
  },
  transform: {
    undef: 'Transform operation is undefined.',
    noBodies: 'No volumes assigned.',
    scalingFactor: 'Scaling factor must be > 0.',
    scalingOrigin: 'Scaling origin is undefined.',
    reflectionPlane: 'Reflection plane is undefined.',
    reflectionNormal: 'Reflection plane normal must have nonzero magnitude.',
    rotationAngle: 'Rotation angle is 0.',
    rotationAxis: 'Rotation axis is undefined.',
    rotationAxisMag: 'Rotation axis must have nonzero magnitude.',
    translationVector: 'Translation vector is undefined.',
    translationVectorMag: 'Translation vector must have nonzero magnitude.',
    unknownOp: 'Unknown transform operation.',
  },
  pattern: {
    undef: 'Pattern operation is undefined.',
    noBodies: 'No volumes assigned.',
    lowQuantity: 'Quantity must be >= 2.',
    translationVector: 'Direction vector is undefined.',
    translationVectorMag: 'Direction vector must have nonzero magnitude.',
    spacingMag: 'Spacing is 0.',
    rotationAngle: 'Rotation angle is 0.',
    rotationAxis: 'Rotation axis is undefined.',
    rotationAxisMag: 'Rotation axis must have nonzero magnitude.',
  },
  shrinkwrap: {
    noBodies: 'No volumes assigned.',
    negativeResolution: 'Resolution must be > 0.',
    minMaxResolution: 'Min. resolution must be <= max. resolution.',
  },
  fileUndef: 'File is undefined.',
  unknownOp: 'Unknown operation.',
} as const;

/**
 * Returns whether a feature can be applied by checking that all its fields are defined and that
 * there are no errors.
 * @returns '' if the feature can be applied, otherwise the error message.
 */
export function applyFeatureWarning(
  feature?: geometrypb.Feature,
): string {
  if (!feature?.operation || !feature.operation.value) {
    return FEATURE_WARNINGS.undef;
  }
  const operation = feature.operation;
  switch (operation.case) {
    case 'create': {
      if (!operation.value.shape.value) {
        return FEATURE_WARNINGS.create.shapeUndef;
      }
      switch (operation.value.shape.case) {
        case 'box': {
          const box = operation.value.shape.value;
          if (box.max?.x === box.min?.x || box.max?.y === box.min?.y || box.max?.z === box.min?.z) {
            return FEATURE_WARNINGS.create.boxLengths;
          }
          break;
        }
        case 'sphere': {
          const sphere = operation.value.shape.value;
          if (sphere.radius <= 0) {
            return FEATURE_WARNINGS.create.radius;
          }
          break;
        }
        case 'halfSphere': {
          const halfSphere = operation.value.shape.value;
          if (halfSphere.radius <= 0) {
            return FEATURE_WARNINGS.create.radius;
          }
          if (!halfSphere.normal || !magnitude(halfSphere.normal)) {
            return FEATURE_WARNINGS.create.halfSphereNormal;
          }
          break;
        }
        case 'cone': {
          const cone = operation.value.shape.value;
          if (cone.apex?.equals(cone.baseCenter)) {
            return FEATURE_WARNINGS.create.coneApexBase;
          }
          if (cone.baseRadius <= 0) {
            return FEATURE_WARNINGS.create.radius;
          }
          break;
        }
        case 'cylinder': {
          const cylinder = operation.value.shape.value;
          if (cylinder.start?.equals(cylinder.end)) {
            return FEATURE_WARNINGS.create.cylinderStartEnd;
          }
          if (cylinder.radius <= 0) {
            return FEATURE_WARNINGS.create.radius;
          }
          break;
        }
        case 'torus': {
          const torus = operation.value.shape.value;
          if (torus.majorRadius === 0 || torus.minorRadius === 0) {
            return FEATURE_WARNINGS.create.torusRadii;
          }
          break;
        }
        default:
          return FEATURE_WARNINGS.create.unknownShape;
      }
      break;
    }
    case 'boolean': {
      // If the feature requires volumes, check that the volumes are defined.
      const boolean = operation.value;
      if (!boolean.op) {
        return FEATURE_WARNINGS.boolean.undef;
      }
      switch (boolean.op.case) {
        case 'regUnion':
        case 'regIntersection': {
          const regUnion = boolean.op.value;
          if (regUnion.bodies.length < 2) {
            return FEATURE_WARNINGS.boolean.no2Bodies;
          }
          break;
        }
        case 'regChop':
        case 'regSubtraction': {
          const regSub = boolean.op.value;
          if (!regSub.bodies.length) {
            return FEATURE_WARNINGS.boolean.noBodies;
          }
          if (!regSub.tools.length) {
            return FEATURE_WARNINGS.boolean.noTools;
          }
          break;
        }
        default:
          return FEATURE_WARNINGS.boolean.unknownOp;
      }
      break;
    }
    case 'delete': {
      const del = operation.value;
      if (!del.ids.length) {
        return FEATURE_WARNINGS.delete.noBodies;
      }
      break;
    }
    case 'transform': {
      const transform = operation.value;
      if (!transform.t) {
        return FEATURE_WARNINGS.transform.undef;
      }
      if (!transform.body.length) {
        return FEATURE_WARNINGS.transform.noBodies;
      }
      switch (transform.t.case) {
        case 'scaling': {
          const scaling = transform.t.value;
          if (scaling.factor.case === 'isotropic' && scaling.factor.value <= 0) {
            return FEATURE_WARNINGS.transform.scalingFactor;
          }
          if (scaling.origin.case === 'arbitrary' && !scaling.origin.value) {
            return 'Scaling origin is undefined.';
          }
          break;
        }
        case 'reflection': {
          const reflection = transform.t.value;
          if (reflection.plane.case === 'arbitrary') {
            if (!reflection.plane.value.direction) {
              return FEATURE_WARNINGS.transform.reflectionPlane;
            }
            if (!magnitude(reflection.plane.value.direction)) {
              return FEATURE_WARNINGS.transform.reflectionNormal;
            }
          }
          break;
        }
        case 'rotation': {
          const rotation = transform.t.value;
          if (rotation.angle === 0) {
            return FEATURE_WARNINGS.transform.rotationAngle;
          }
          if (rotation.axis.case === 'arbitrary') {
            if (!rotation.axis.value.direction) {
              return FEATURE_WARNINGS.transform.rotationAxis;
            }
            if (!magnitude(rotation.axis.value.direction)) {
              return FEATURE_WARNINGS.transform.rotationAxisMag;
            }
          }
          break;
        }
        case 'translation': {
          const translation = transform.t.value;
          switch (translation.displacement.case) {
            case 'vector':
              if (!translation.displacement.value) {
                return FEATURE_WARNINGS.transform.translationVector;
              }
              if (!magnitude(translation.displacement.value)) {
                return FEATURE_WARNINGS.transform.translationVectorMag;
              }
              break;
            case 'magnitudeVector':
              if (!translation.displacement.value.direction) {
                return FEATURE_WARNINGS.transform.translationVector;
              }
              if (!magnitude(translation.displacement.value.direction)) {
                return FEATURE_WARNINGS.transform.translationVectorMag;
              }
              if (!translation.displacement.value.magnitude) {
                return FEATURE_WARNINGS.transform.translationVectorMag;
              }
              break;
            default:
              break;
          }
          break;
        }
        default:
          return FEATURE_WARNINGS.transform.unknownOp;
      }
      break;
    }
    case 'import': {
      const imp = operation.value;
      if (!imp.geometryUrl) {
        return FEATURE_WARNINGS.fileUndef;
      }
      if (imp.scaling <= 0) {
        return FEATURE_WARNINGS.import.scaling;
      }
      if (imp.parameters) {
        const error = imp.parameters.map((param) => {
          if (param.type.case !== 'float') {
            console.error(`${param.type.case} not yet implemented`);
            return;
          }
          const value = getAdValue(param.type.value.adType);
          const min = param.type.value.min;
          const max = param.type.value.max;
          let name = param.name;
          if (param.owner !== undefined) {
            name = `${param.owner}: ${name}`;
          }
          if (min !== undefined && value < min) {
            return `Parameter "${name}" value (${value}) is below minimum (${min})`;
          }
          if (max !== undefined && value > max) {
            return `Parameter "${name}" value (${value}) is above maximum (${max})`;
          }
          return undefined;
        }).find((er) => er);
        if (error) {
          return error;
        }
      }
      break;
    }
    case 'imprint': {
      return '';
    }
    case 'shrinkwrap': {
      const wrap = operation.value;
      if (!wrap.body.length) {
        return FEATURE_WARNINGS.shrinkwrap.noBodies;
      }
      if (wrap.mode === geometrypb.ShrinkwrapMode.MINMAX) {
        if (wrap.resolutionMax <= 0 || wrap.resolutionMin <= 0) {
          return FEATURE_WARNINGS.shrinkwrap.negativeResolution;
        }
        if (wrap.resolutionMin > wrap.resolutionMax) {
          return FEATURE_WARNINGS.shrinkwrap.minMaxResolution;
        }
      }
      if (wrap.mode === geometrypb.ShrinkwrapMode.UNIFORM) {
        if (wrap.resolutionUniform <= 0) {
          return FEATURE_WARNINGS.shrinkwrap.negativeResolution;
        }
      }
      return '';
    }
    case 'farfield':
      return '';
    case 'pattern': {
      const pattern = operation.value;
      if (!pattern.body.length) {
        return FEATURE_WARNINGS.pattern.noBodies;
      }
      if (!pattern.direction) {
        return FEATURE_WARNINGS.pattern.undef;
      }
      if (pattern.direction.quantity < 2) {
        return FEATURE_WARNINGS.pattern.lowQuantity;
      }
      switch (pattern.direction.type.case) {
        case ('linearSpacing'): {
          const translation = pattern.direction.type.value;
          switch (translation.displacement.case) {
            case 'vector':
              if (!translation.displacement.value) {
                return FEATURE_WARNINGS.pattern.translationVector;
              }
              if (!magnitude(translation.displacement.value)) {
                return FEATURE_WARNINGS.pattern.translationVectorMag;
              }
              break;
            case 'magnitudeVector':
              if (!translation.displacement.value.direction) {
                return FEATURE_WARNINGS.pattern.translationVector;
              }
              if (!magnitude(translation.displacement.value.direction)) {
                return FEATURE_WARNINGS.pattern.translationVectorMag;
              }
              if (!translation.displacement.value.magnitude) {
                return FEATURE_WARNINGS.pattern.spacingMag;
              }
              break;
            default:
              return FEATURE_WARNINGS.pattern.undef;
          }
          break;
        }
        case 'circularDistribution': {
          const circular = pattern.direction.type.value;
          const rotation = circular.rotation;
          if (!circular.full && !rotation!.angle) {
            return FEATURE_WARNINGS.pattern.rotationAngle;
          }
          if (rotation!.axis.case === 'arbitrary') {
            if (!rotation!.axis.value.direction) {
              return FEATURE_WARNINGS.pattern.rotationAxis;
            }
            if (!magnitude(rotation!.axis.value.direction)) {
              return FEATURE_WARNINGS.pattern.rotationAxisMag;
            }
          }
          break;
        }
        default:
          return FEATURE_WARNINGS.pattern.undef;
      }
      return '';
    }
    default:
      return FEATURE_WARNINGS.unknownOp;
  }
  return '';
}

export function generateDefaultShape(
  cadMetadata: cadmetadatapb.CadMetadata,
  shape: geometrypb.Create,
  isFarfield: boolean,
) {
  const DEFAULT_PRIMITIVE_SCALE = 0.5 * (isFarfield ? DEFAULT_FARFIELD_SCALE : 1);
  const bBox = cadMetadata.boundingBox;
  const center = boxCenter(bBox);
  const radius = DEFAULT_PRIMITIVE_SCALE * diagonalLength(bBox);
  const newShape = shape.shape;
  switch (newShape.case) {
    case 'sphere':
      newShape.value = new shapepb.Sphere({ center, radius });
      break;
    case 'halfSphere':
      newShape.value = new shapepb.HalfSphere({ center, radius, normal: { y: 1 } });
      break;
    case 'box': {
      newShape.value = defaultCube(center, 2 * radius);
      break;
    }
    case 'cylinder':
      newShape.value = defaultCylinder(center, radius);
      break;
    case 'cone':
      newShape.value = new shapepb.Cone({
        baseCenter: center,
        baseRadius: radius,
        apex: {
          x: center.x,
          y: center.y,
          z: center.z + radius,
        },
      });
      break;
    case 'torus':
      newShape.value = new shapepb.Torus({
        center,
        normal: {
          x: 0,
          y: 0,
          z: 1,
        },
        majorRadius: radius,
        minorRadius: radius / 2,
      });
      break;
    default:
      throw Error('Shape not recognized');
  }
}

export function globalDisabledReason(
  selectedFeature: SelectedFeature,
  readOnly: boolean,
  isGeoServerActive: boolean,
) {
  if (readOnly) {
    return 'Cannot edit geometries in shared projects';
  }
  if (!isGeoServerActive) {
    return 'Cannot edit geometry while geometry server is busy.';
  }
  if (selectedFeature !== DEFAULT_SELECTED_FEATURE &&
    selectedFeature !== DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE) {
    return 'Already editing a feature';
  }
  return undefined;
}
