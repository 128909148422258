// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as simulationpb from '../proto/client/simulation_pb';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import { getAdValue, newScalarAdVector } from './adUtils';
import { getWallSurfacesWithTags } from './boundaryConditionUtils';
import { prefixNameGen, uniqueSequenceName } from './name';
import { findPhysicsById, getFluid } from './physicsUtils';
import { newNodeId } from './projectDataUtils';
import { surfacesFromVolumes } from './volumeUtils';

export function getAllPorousModels(param: simulationpb.SimulationParam) {
  return param.physics.reduce((result, physics) => {
    result.push(
      ...getFluid(physics)?.porousBehavior || [],
    );
    return result;
  }, [] as simulationpb.PorousBehavior[]);
}

// Return the porous model model with the given ID
export function findPorousModelById(param: simulationpb.SimulationParam, id: string) {
  return getAllPorousModels(param).find((item) => item.porousBehaviorId === id);
}

export function findParentPhysics(param: simulationpb.SimulationParam, id: string) {
  return param.physics.find((physics) => getFluid(physics)?.porousBehavior.some(
    (item) => item.porousBehaviorId === id,
  ));
}

// Get all porous model names already used by a fluid physics
function getAllNames(fluid: simulationpb.Fluid): string[] {
  return fluid.porousBehavior.map((item) => item.porousBehaviorName);
}

// Create and return a new porous model
function createPorousModel(fluid: simulationpb.Fluid): simulationpb.PorousBehavior {
  const newName = uniqueSequenceName(getAllNames(fluid), prefixNameGen('Porous Model'));
  return new simulationpb.PorousBehavior({
    porousBehaviorId: newNodeId(),
    porousBehaviorName: newName,
    // Coefficients are initially undefined, so set them explicitly to zeros
    darcyCoeff: newScalarAdVector(),
    forchheimerCoeff: newScalarAdVector(),
  });
}

// Create a new porous model and append it to a fluid
export function appendPorousModel(
  param: simulationpb.SimulationParam,
  fluidPhysicsId: string,
) {
  const physics = findPhysicsById(param, fluidPhysicsId);
  const fluid = physics ? getFluid(physics) : null;

  if (!physics || !fluid) {
    throw Error('Invalid fluid physics ID');
  }

  const model = createPorousModel(fluid);
  fluid.porousBehavior.push(model);
  return model;
}

// Rename the identified porous model, returning true if successful
export function renamePorousModel(
  param: simulationpb.SimulationParam,
  id: string,
  name: string,
): boolean {
  return param.physics.some((physics) => getFluid(physics)?.porousBehavior.some(
    (item) => {
      if (item.porousBehaviorId === id) {
        item.porousBehaviorName = name;
        return true;
      }
      return false;
    },
  ));
}

// Remove the identified porous model, returning true if successful
export function removePorousModel(param: simulationpb.SimulationParam, id: string): boolean {
  return param.physics.some((physics) => {
    const fluid = getFluid(physics);
    if (fluid) {
      const models = fluid.porousBehavior;
      const newModels = models.filter((item) => item.porousBehaviorId !== id);

      if (newModels.length < models.length) {
        fluid.porousBehavior = newModels;
        return true;
      }
    }
    return false;
  });
}

// Return IDs for all frames referenced by a porous model
export function assignedFrameIds(param: simulationpb.SimulationParam): Set<string> {
  const frames = new Set<string>();

  param.physics.forEach((physics) => {
    getFluid(physics)?.porousBehavior.forEach((model) => {
      const frameId = model.refFrameId;
      if (frameId) {
        frames.add(frameId);
      }
    });
  });
  return frames;
}

// Return all volumes referenced by a porous model
export function assignedVolumes(
  param: simulationpb.SimulationParam,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
): StaticVolume[] {
  const domains = new Set<string>();

  param.physics.forEach((physics) => {
    getFluid(physics)?.porousBehavior.forEach(({ zoneIds }) => {
      const volumeIds = zoneIds.flatMap((zoneId) => {
        const tagDomains = geometryTags.domainsFromTag(zoneId);

        return tagDomains.length > 0 ? tagDomains.map((domain) => `volume-${domain}`) : [zoneId];
      });

      volumeIds.forEach((id) => domains.add(id));
    });
  });

  return staticVolumes.filter((volume) => domains.has(volume.id));
}

// Return true if the Darcy and Forchheimer coefficients are valid
export function validatePorousModelCoefficients(model: simulationpb.PorousBehavior): boolean {
  const darcy = model.darcyCoeff;
  const forchheimer = model.forchheimerCoeff;
  const coefficients = [
    getAdValue(darcy?.x),
    getAdValue(darcy?.y),
    getAdValue(darcy?.z),
    getAdValue(forchheimer?.x),
    getAdValue(forchheimer?.y),
    getAdValue(forchheimer?.z),
  ];

  return coefficients.every((coefficient) => (
    (coefficient !== undefined) &&
    (coefficient !== null) &&
    !Number.isNaN(coefficient) &&
    (Number(coefficient) >= 0)
  ));
}

// Remove the referenced volume from any porous model, returning true if successful
export function detachVolume(
  param: simulationpb.SimulationParam,
  staticVolume: StaticVolume,
): boolean {
  return param.physics.some((physics) => getFluid(physics)?.porousBehavior.some(
    (model) => {
      const domains = model.zoneIds;
      if (domains.includes(staticVolume.domain)) {
        model.zoneIds = domains.filter((domain) => domain !== staticVolume.domain);
        return true;
      }
      return false;
    },
  ));
}

// Attach a volume to a porous model, returning true if successful
export function attachVolume(
  param: simulationpb.SimulationParam,
  staticVolume: StaticVolume,
  modelId: string,
) {
  // Since a volume may be associated with, at most, one porous model, detach it from any other
  // porous models first.
  detachVolume(param, staticVolume);

  const model = findPorousModelById(param, modelId);

  if (model) {
    if (!model.zoneIds.includes(staticVolume.domain)) {
      model.zoneIds.push(staticVolume.domain);
      return true;
    }
  }

  return false;
}

/**
 * @returns all surfaces that are assigned to a porous volume
 */
export function getPorousSurfaces(
  param: simulationpb.SimulationParam,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
) {
  const porousVolumes = assignedVolumes(param, staticVolumes, geometryTags);
  return surfacesFromVolumes(porousVolumes);
}

/**
 * @returns porous surfaces that are not wall surfaces
 */
export function getPorousInterfaces(
  param: simulationpb.SimulationParam,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
) {
  const wallSurfaces = getWallSurfacesWithTags(param, geometryTags);
  const porousSurfaces = getPorousSurfaces(param, staticVolumes, geometryTags);
  return porousSurfaces.filter((surface) => !wallSurfaces.includes(surface));
}
