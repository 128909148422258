import React, { useEffect, useRef } from 'react';

import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useSelectionContext } from '../context/SelectionManager';

type UseDeleteNodeWithKeyboardProps = {
  onDelete: () => Promise<void>;
  nodeId: string;
  enabled?: boolean;
  nodeName?: string;
}

/** When these elements are focused, we don't want the Backspace key to interfere with
  * their default behavior.
  */
const EDITABLE_ELEMENTS = ['INPUT', 'TEXTAREA'];

/**
 * Hook to handle node deletion via the "Delete" key.
 *
 * Listens for the "Delete" key press, shows a confirmation dialog for the specified node,
 * and calls the `onDelete` function if the action is confirmed.
 */
export const useDeleteNodeWithKeyboard = (
  { onDelete, nodeId, nodeName, enabled = true }: UseDeleteNodeWithKeyboardProps,
) => {
  const setConfirmStack = useSetConfirmations();
  // there's no need to use useState here – a ref is sufficient to prevent stacking confirmations
  const isDeleting = useRef(false);

  const { selectedNodeIds } = useSelectionContext();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const deleteSelectedNode = (event: KeyboardEvent) => {
      const isBackspace = event.key === 'Backspace';
      const isInEditableElement = EDITABLE_ELEMENTS.includes((event.target as HTMLElement).tagName);
      const isNodeTheOnlySelection = selectedNodeIds.length === 1 && selectedNodeIds[0] === nodeId;
      const isAlreadyDeleting = isDeleting.current;

      if (!isBackspace || isInEditableElement || !isNodeTheOnlySelection || isAlreadyDeleting) {
        return;
      }

      pushConfirmation(setConfirmStack, {
        continueLabel: 'Delete',
        destructive: true,
        onContinue: async () => {
          isDeleting.current = true;
          await onDelete();
          isDeleting.current = false;
        },
        title: 'Delete Confirmation',
        children: (
          <>Are you sure you want to delete {nodeName ? <strong>{nodeName}</strong> : 'it'}?</>
        ),
      });
    };

    window.addEventListener('keydown', deleteSelectedNode);

    return () => {
      window.removeEventListener('keydown', deleteSelectedNode);
    };
  }, [enabled, nodeId, nodeName, onDelete, selectedNodeIds, setConfirmStack]);
};
