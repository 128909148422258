// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { ClassNameMap, StyleRules, createStyles as createStylesMUI, makeStyles as makeStylesMUI, withStyles as withStylesMUI } from '@mui/styles';

import { colors } from '../../lib/designSystem';

export const createStyles = <T extends StyleRules<object, string>>(styles: T) => (
  createStylesMUI(styles) as unknown as ClassNameMap<Extract<keyof T, string>>
);

/**
 * Custom classname generator to omit the incremented id.
 *
 * https://github.com/mui-org/material-ui/issues/9492
 */
const generateClassName = (rule: any, stylesheet: any) => (
  `${stylesheet.options.classNamePrefix}-${rule.key}`
);

type Styles = { [key: string]: any }
type Options = { [key: string]: any }
/**
 * Wrap `makeStyles` and `withStyles` with our own `generateClassName` function.
 */
export const makeStyles = <T extends object>(
  styles: (theme: Styles) => T,
  options: Options,
) => makeStylesMUI(styles, {
  ...options,
  generateClassName,
} as any) as (...args: any) => ClassNameMap<Extract<keyof T, string>>;

export const withStyles = (styles: Styles, options: Options) => withStylesMUI(styles, {
  ...options,
  generateClassName,
} as any);

export const SCROLLBAR_WIDTH = 14;

/**
 * Override MUI's default theme with our own styles.
 *
 * https://material-ui.com/customization/default-theme/
 */
export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar-corner': {
          background: 'rgba(0,0,0,0)',
        },
        '*::-webkit-scrollbar-button': {
          height: '0px',
        },
        '*::-webkit-scrollbar-thumb': {
          background: colors.neutral500,
          borderRadius: '7px',
          border: `4px solid ${colors.transparent}`,
          backgroundClip: 'padding-box',
        },
        '*::-webkit-scrollbar-track': {
          background: colors.surfaceBackground,
          border: `3px solid ${colors.transparent}`,
          borderRadius: '7px',
          backgroundClip: 'padding-box',
        },
        '*::-webkit-scrollbar': {
          width: `${SCROLLBAR_WIDTH}px`,
        },
      },
    },
    // We need this if we don't want MUI to auto-change background opacity
    // based on elevation (which is the default behavior)
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    // TODO(bamo): palette.action and palette.background do not appear to be documented in
    // MUI v5 docs.  Is this really the right way to do this?
    action: {
      selected: 'rgba(0, 0, 0, 0.4)',
      hover: 'rgba(0, 0, 0, 0.2)',
    },
    background: {
      default: colors.surfaceMedium2,
      paper: colors.surfaceMedium2,
    },
    primary: {
      // If the "dark" and/or "light" keys are omitted, their value(s) will be
      // calculated from "main", according to the "tonalOffset" value.
      // See: https://material-ui.com/customization/palette/#providing-the-colors-directly
      main: colors.primaryCta,
    },
    secondary: {
      main: '#84a7b9',
    },
  },
  typography: {
    // Applying these styles in the theme ensures all button variants are
    // consistent (eg: Button, FAB, etc.)
    button: {
      textTransform: 'none',
    },
    fontFamily: [
      'Inter',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

/**
 * Portable theme provider for the app. Can be used in other environments, like
 * Storybook, to ensure component styling is consistent.
 */
const Theme = (props: { children: ReactNode }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  </StyledEngineProvider>
);

export default Theme;
