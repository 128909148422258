// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { assembleMenuSections } from '../../../lib/menuUtil';
import { nullableMeshing } from '../../../lib/mesh';
import { getShapeName } from '../../../lib/refinementRegionUtils';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem, visibilityToggleTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import useMeshMultiPart from '../../../recoil/useMeshingMultiPart';
import { useHasActiveMesh } from '../../../recoil/useProjectActiveMesh';
import { useRefinementRegionVisibility } from '../../../recoil/useRefinementRegions';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useCopyRefinementRegion } from '../../hooks/nodeDuplication/useCopyRefinementRegion';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'cubeOutline' };

// For a very small number of broken projects, there can exist refinement regions with no name.
// In this case, we need to give them a default name so that the name field renders and can be
// edited by the user. See: https://luminarycloud.atlassian.net/browse/LC-19696
function createDefaultName(meshMultiPart: nullableMeshing, id: string) {
  const rr = meshMultiPart?.refinementParams.find((rrParam) => rrParam.id === id);
  if (rr && !rr.name) {
    return `[${getShapeName(rr)}]`;
  }

  return rr?.name;
}

export const RefinementRegionTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;
  const { id } = node;

  // == Contexts
  const { viewState } = useParaviewContext();
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const hasActiveMesh = useHasActiveMesh(projectId);
  const disabled = readOnly || hasActiveMesh;

  // == Recoil
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const [refinementRegionVis, setRefinementRegionVis] = useRefinementRegionVisibility(projectId);
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteRefinementRegionNode, postDeleteNodeIds } = useNodeDeletion();
  const duplicateRow = useCopyRefinementRegion();

  const isVisible = refinementRegionVis[id] !== false;
  const visControlsDisabled = lcvisEnabled ? !lcvisReady : !viewState;
  const toggleVis = useCallback(() => {
    setRefinementRegionVis((oldVis) => {
      const newVis = { ...oldVis };
      newVis[id] = !newVis[id];
      return newVis;
    });
  }, [id, setRefinementRegionVis]);

  const deleteRow = useCallback(() => {
    if (deleteRefinementRegionNode(id)) {
      postDeleteNodeIds([id]);
    }
  }, [deleteRefinementRegionNode, id, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => {
    const visItems = [visibilityToggleTreeNodeMenuItem(isVisible, toggleVis, visControlsDisabled)];

    const crudDisabled = !canDelete(node.type, id) || disabled;
    const crudItems = [
      duplicateTreeNodeMenuItem(() => duplicateRow(id), crudDisabled),
      deleteTreeNodeMenuItem(deleteRow, crudDisabled),
    ];

    return assembleMenuSections(visItems, crudItems);
  }, [
    isVisible,
    toggleVis,
    visControlsDisabled,
    canDelete,
    deleteRow,
    id,
    node.type,
    duplicateRow,
    disabled,
  ]);

  const visButton = (
    <VisibilityButton disabled={visControlsDisabled} isVisible={isVisible} onClick={toggleVis} />
  );

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      label={node.name || createDefaultName(meshMultiPart, id)}
      primaryIcon={PRIMARY_ICON}
      renaming={renaming}
      visibilityButton={visButton}
    />
  );
};
