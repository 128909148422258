// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as basepb from '../proto/base/base_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import { EntityType } from '../proto/entitygroup/entitygroup_pb';
import * as quantitypb from '../proto/quantity/quantity_pb';
import { EntityGroupData } from '../recoil/entityGroupState';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { StaticVolume } from '../recoil/volumes';

import GroupMap, { Groupable } from './GroupMap';
import { add, mult, newOriginProto, newProto, rotate } from './Vector';
import { adVec3ToVec3, equalsZero, newScalarAdVector, setAdVec3Quantity, vec3ToAdVec3 } from './adUtils';
import { isViscousWallBc } from './boundaryConditionUtils';
import { SvgIconSpec } from './componentTypes/svgIcon';
import { EntityGroupMap } from './entityGroupMap';
import { getSurfaceGroupSurfaces, unwrapSurfaceIds } from './entityGroupUtils';
import { boldEscaped } from './html';
import { intersectSet, intersects, isSuperset, subtractSet, unionSet } from './lang';
import { formatVector, formattedVelocity } from './motionDataFormat';
import { uniqueSequenceName } from './name';
import { Logger } from './observability/logs';
import { getFluid } from './physicsUtils';
import { newNodeId } from './projectDataUtils';
import { isSimulationTransient } from './simulationUtils';
import { mapDomainsToIds, mapIdsToDomains, surfacesFromVolumes } from './volumeUtils';

const logger = new Logger('motionDataUtils');

const { ROTATIONAL_TRANSFORM, TRANSLATIONAL_TRANSFORM } = simulationpb.TransformType;

// These must be kept in sync with go/core/protoutil/fvmparam.go
export const GLOBAL_FRAME_PARENT_ID = '';
export const GLOBAL_FRAME_NAME = 'Global';
export const DEFAULT_GLOBAL_FRAME_ID = 'global_frame_id';

export type FrameLookup = Record<string, simulationpb.MotionData>;

export interface FrameGroup extends Groupable {
  frame: simulationpb.MotionData;
}

export interface RemoveFrameOptions {
  // When true, assign the child frames to the removed frame's parent
  hoist?: boolean;
}

export interface AllAttachedGeometryOptions {
  // When set to 'defined', consider only frames that have rotation or translation when iterating
  // over frames.  When set to 'moving', consider only frames that have rotation or translation and
  // whose motion vector has magnitude !== 0.
  motion?: 'defined' | 'moving';
  // Optionally set a list of frame IDs to include when iterating over frames
  includeFrameIds?: string[];
  // Optionally set a list of frame IDs to ignore when iterating over frames (ignored when
  // includeFrameIds is defined)
  excludeFrameIds?: string[];
}

export const BODY_FRAME_DESCRIPTION = 'The Body Frame is a reference frame that defines body ' +
  'orientation. It is commonly used in aircraft and automotive aerodynamics simulations.';

// Return a simple ID->frame lookup object
export function buildFrameLookup(param: simulationpb.SimulationParam) {
  return param.motionData.reduce((result, frame) => {
    result[frame.frameId] = frame;
    return result;
  }, {} as FrameLookup);
}

// Order the list of frames so that parent frames always precede their children; this is necessary
// for generating a GroupMap without error.
export function orderedFrames(param: simulationpb.SimulationParam) {
  const frameLookup = buildFrameLookup(param);
  const unorderedFrames = param.motionData;

  const frames: simulationpb.MotionData[] = [];
  const frameIds = new Set<string>();

  // There's no guarantee of referential integrity in the motion data structure, so we assume that
  // this process may yield a subset of the motion data frames.  This function's return value will
  // include a list of any orphaned frames.

  let addedCount = 1;
  while (addedCount) {
    addedCount = unorderedFrames.reduce((result, frame) => {
      const frameId = frame.frameId;

      if (!frameIds.has(frameId)) {
        // This frame hasn't been added to the ordered list yet, so let's proceed.
        const parentId = frame.frameParent;

        if (!frameLookup[parentId] || frameIds.has(parentId)) {
          // Parent is either the ROOT or it's already in the ordered list, so its safe to add
          // this frame now.
          frames.push(frame);
          frameIds.add(frameId);
          return result + 1;
        }
      }
      return result;
    }, 0);
  }

  const orphaned = frames.filter((frame) => !frameIds.has(frame.frameId));

  return {
    frames,
    orphaned,
  };
}

// Return a GroupMap representing the frames
export function getFrameGroupMap(param: simulationpb.SimulationParam) {
  const frameLookup = buildFrameLookup(param);
  const frameGroups = new GroupMap<FrameGroup>(() => { });

  const { frames } = orderedFrames(param);
  frames.forEach((frame) => {
    const parentId = frame.frameParent;
    const parent = frameLookup[parentId];

    frameGroups.add({
      id: frame.frameId,
      name: frame.frameName,
      parentId: parent?.frameId || GroupMap.rootId,
      children: new Set([]),
      frame,
    });
  });

  return frameGroups;
}

// Find a frame by ID
export function findFrameById(param: simulationpb.SimulationParam, id: string) {
  return param.motionData.find((frame) => frame.frameId === id);
}

// Find and return a global frame only if it exists
export function findGlobalFrame(
  param: simulationpb.SimulationParam,
): simulationpb.MotionData | undefined {
  const frameGroupMap = getFrameGroupMap(param);

  const rootChildren = frameGroupMap.root().children;
  if (rootChildren.size === 1) {
    return frameGroupMap.get([...rootChildren.keys()][0]).frame;
  }

  return undefined;
}

// Return true if the frame ID belongs to the global frame
export function isFrameGlobal(param: simulationpb.SimulationParam, frameId: string) {
  const globalFrame = findGlobalFrame(param);

  return globalFrame?.frameId === frameId;
}

// Find and return the body frame only if it exists
export function findBodyFrame(
  param: simulationpb.SimulationParam,
): simulationpb.MotionData | undefined {
  if (param.bodyFrame?.bodyFrameId) {
    const bodyFrame = findFrameById(param, param.bodyFrame?.bodyFrameId!);
    if (bodyFrame?.frameParent && !isFrameGlobal(param, bodyFrame.frameParent)) {
      throw Error('Body frame parent must be global frame.');
    }
    return bodyFrame;
  }
  return undefined;
}

// Given a frame's transform list, which can be arbitrary, return an origin vector and an
// orientation vector
export function extractCoordinatesFromTransforms(transforms: simulationpb.FrameTransforms[]) {
  // Coordinates are persisted as a list of transforms, but for MVP, we only support a list of two,
  // where the first entry is a translation and the second is a rotation.
  let origin: basepb.Vector3 = newOriginProto();
  let orientation: basepb.Vector3 = newOriginProto();
  const warnings: string[] = [];

  if (transforms.length > 2) {
    warnings.push(`Unexpected transforms list length: ${transforms.length} > 2`);
  }

  const transIdx = transforms.findIndex(
    (transform) => transform.transformType === TRANSLATIONAL_TRANSFORM,
  );

  const rotIdx = transforms.findIndex(
    (transform) => transform.transformType === ROTATIONAL_TRANSFORM,
  );

  if (transIdx && rotIdx && (transIdx > rotIdx)) {
    warnings
      .push('Unexpected ordering of transformations (translation must come before rotation).');
  }

  if (transIdx > -1) {
    const translationVector = transforms[transIdx]!.transformTranslation;
    if (translationVector) {
      origin = adVec3ToVec3(translationVector);
    } else {
      warnings.push('Translation transform is missing a translation vector');
    }
  }

  if (rotIdx > -1) {
    const rotationVector = transforms[rotIdx]!.transformRotationAngles;
    if (rotationVector) {
      orientation = adVec3ToVec3(rotationVector);
    } else {
      warnings.push('Rotation transform is missing a rotation vector');
    }
  }

  warnings.forEach((message) => logger.error(message));

  return { origin, orientation };
}

export function summarizeCoordinates(
  parentName: string,
  origin: basepb.Vector3,
  orientation: basepb.Vector3,
) {
  const tformParts = [];
  tformParts.push(formatVector(origin, quantitypb.QuantityType.LENGTH));
  tformParts.push(formatVector(orientation, quantitypb.QuantityType.DEGREE));

  return [parentName, tformParts.join(', ')].join(' ');
}
// Return a human-readable string representing a frame's coordinate system
export function summarizeFrameCoordinateSystem(
  frame: simulationpb.MotionData | undefined,
  parentFrame?: simulationpb.MotionData | null,
) {
  const parentName = parentFrame ? parentFrame.frameName : 'Global';

  if (frame) {
    const transforms = frame.frameTransforms || [];

    const { origin, orientation } = extractCoordinatesFromTransforms(transforms);
    return summarizeCoordinates(parentName, origin, orientation);
  }

  return summarizeCoordinates(parentName, newOriginProto(), newOriginProto());
}

// Return a human-readable string representing a frame's motion
export function summarizeMotion(frame: simulationpb.MotionData) {
  const parts: string[] = [];

  switch (frame.motionType) {
    case simulationpb.MotionType.CONSTANT_ANGULAR_MOTION: {
      parts.push(
        'Rotation',
        formattedVelocity(
          frame.motionAngularVelocity,
          quantitypb.QuantityType.ANGULAR_VELOCITY,
        ),
        formattedVelocity(
          frame.motionRotationAngles,
          quantitypb.QuantityType.DEGREE,
        ),
      );
      break;
    }
    case simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION: {
      parts.push(
        'Translation',
        formattedVelocity(frame.motionTranslationVelocity, quantitypb.QuantityType.VELOCITY),
        formattedVelocity(frame.motionTranslation, quantitypb.QuantityType.LENGTH),
      );
      break;
    }
    default: {
      parts.push('No motion');
      // no default
    }
  }

  return parts.join(' ').trim();
}

// Update a frame's transform list with discrete origin and orientation vectors
export function assignFrameCoordinates(
  frame: simulationpb.MotionData,
  origin: basepb.Vector3,
  orientation: basepb.Vector3,
): void {
  const frameName = frame.frameName;
  const translation = vec3ToAdVec3(origin);
  const rotation = vec3ToAdVec3(orientation);
  setAdVec3Quantity(translation, quantitypb.QuantityType.LENGTH);
  setAdVec3Quantity(rotation, quantitypb.QuantityType.DEGREE);

  const translationTransform = new simulationpb.FrameTransforms({
    transformType: TRANSLATIONAL_TRANSFORM,
    transformTranslation: translation,
    transformName: `${frameName}-origin`,
  });

  const rotationTransform = new simulationpb.FrameTransforms({
    transformType: ROTATIONAL_TRANSFORM,
    transformRotationAngles: rotation,
    transformName: `${frameName}-orientation`,
  });

  frame.frameTransforms = [translationTransform, rotationTransform];
}

// Assign an orientation vector to a frame
export function assignFrameOrientation(
  param: simulationpb.SimulationParam,
  frameId: string,
  value: basepb.Vector3,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    const { origin } = extractCoordinatesFromTransforms(frame.frameTransforms);
    assignFrameCoordinates(frame, origin, value);
  }
}

// Assign an origin vector to a frame
export function assignFrameOrigin(
  param: simulationpb.SimulationParam,
  frameId: string,
  value: basepb.Vector3,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    const { orientation } = extractCoordinatesFromTransforms(frame.frameTransforms);
    assignFrameCoordinates(frame, value, orientation);
  }
}

// Assign default body frame orientation to frame and designate it as the Body Frame in sim settings
export function assignDefaultBodyFrame(
  param: simulationpb.SimulationParam,
  frame: simulationpb.MotionData,
) {
  const bodyFrameId = frame.frameId;
  param.bodyFrame = new simulationpb.BodyFrame({ bodyFrameId });
  assignFrameOrientation(param, bodyFrameId, newProto(0, 180, 0));
}

// Create a new frame (MotionData) object and append it to the list in params
function addFrame(
  param: simulationpb.SimulationParam,
  name: string,
  parentId: string,
  id?: string,
) {
  const frame = new simulationpb.MotionData({
    frameId: id || newNodeId(),
    frameName: name,
    frameParent: parentId,
  });

  param.motionData.push(frame);

  return frame;
}

// If a global frame is found, return it.  Otherwise, create one and return it.
export function getOrCreateGlobalFrame(param: simulationpb.SimulationParam) {
  const globalFrame = findGlobalFrame(param);

  if (globalFrame) {
    return globalFrame;
  }

  const frame = addFrame(param, GLOBAL_FRAME_NAME, GLOBAL_FRAME_PARENT_ID, DEFAULT_GLOBAL_FRAME_ID);
  frame.motionType = simulationpb.MotionType.NO_MOTION;

  return frame;
}

// Generate a list of existing frame names, excluding the global frame
function getAllNames(param: simulationpb.SimulationParam) {
  return param.motionData.reduce((result, frame) => {
    if (frame.frameParent !== GLOBAL_FRAME_PARENT_ID) {
      result.push(frame.frameName);
    }
    return result;
  }, [] as string[]);
}

function getFrameNameSuffix(type?: simulationpb.MotionType) {
  switch (type) {
    case simulationpb.MotionType.CONSTANT_ANGULAR_MOTION: {
      return ' - Rotation';
    }
    case simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION: {
      return ' - Translation';
    }
    default: {
      return '';
    }
  }
}

// Initialize coordinates for a frame
export function initializeFrameCoordinates(frame: simulationpb.MotionData) {
  assignFrameCoordinates(frame, newOriginProto(), newOriginProto());
}

// Initialize motion settings for a frame
export function initializeFrameMotion(
  frame: simulationpb.MotionData,
  type: simulationpb.MotionType = simulationpb.MotionType.NO_MOTION,
) {
  frame.motionType = type;
  // Default the motion formulation to be consistent with how angular and translation velocities
  // are reset to 0 when the motion type is changed.
  frame.motionFormulation = simulationpb.MotionFormulation.AUTOMATIC_MOTION_FORMULATION;

  switch (type) {
    case simulationpb.MotionType.CONSTANT_ANGULAR_MOTION: {
      const velocity = newScalarAdVector();
      const initialAngles = newScalarAdVector();
      setAdVec3Quantity(velocity, quantitypb.QuantityType.ANGULAR_VELOCITY);
      setAdVec3Quantity(initialAngles, quantitypb.QuantityType.DEGREE);
      frame.motionAngularVelocity = velocity;
      frame.motionRotationAngles = initialAngles;
      break;
    }
    case simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION: {
      const velocity = newScalarAdVector();
      const initialTranslation = newScalarAdVector();
      setAdVec3Quantity(velocity, quantitypb.QuantityType.VELOCITY);
      setAdVec3Quantity(initialTranslation, quantitypb.QuantityType.LENGTH);
      frame.motionTranslationVelocity = velocity;
      frame.motionTranslation = initialTranslation;
      break;
    }
    default: {
      // no default
    }
  }
}

// Create a new frame with a unique, generated name
export function createFrame(
  param: simulationpb.SimulationParam,
  type: simulationpb.MotionType,
  name?: string,
) {
  const globalFrame = getOrCreateGlobalFrame(param);

  const suffix = getFrameNameSuffix(type);
  const newName = name ||
    uniqueSequenceName(getAllNames(param), (count) => `Frame ${count}${suffix}`);

  const frame = addFrame(param, newName, globalFrame.frameId);
  initializeFrameCoordinates(frame);
  initializeFrameMotion(frame, type);

  return frame;
}

// Return true if frame has child frames
export function frameHasChildren(
  param: simulationpb.SimulationParam,
  frame: simulationpb.MotionData,
) {
  const frameGroupMap = getFrameGroupMap(param);
  const frameId = frame.frameId;

  if (frameGroupMap.has(frameId)) {
    const group = frameGroupMap.get(frameId);
    return group.children.size > 0;
  }

  return false;
}

// Return a frame's direct children
export function findFrameChildren(param: simulationpb.SimulationParam, frameId: string) {
  const childIds: string[] = [];

  const frameGroupMap = getFrameGroupMap(param);
  if (frameGroupMap.has(frameId)) {
    childIds.push(...frameGroupMap.get(frameId).children);
  }

  return param.motionData.filter((frame) => childIds.includes(frame.frameId));
}

// Return a frame's parent
export function findFrameParentById(param: simulationpb.SimulationParam, frameId: string) {
  const frameGroupMap = getFrameGroupMap(param);

  const parentGroup = frameGroupMap.findAncestor(frameId, () => true);
  return parentGroup?.frame;
}

// For a given (source) frame ID, return a list of frames that may be assigned as the source
// frame's new parent.  This excludes the source frame itself and any frames that are children of
// the source frame.
export function getFrameParentCandidates(
  param: simulationpb.SimulationParam,
  sourceId: string,
): simulationpb.MotionData[] {
  const frameGroupMap = getFrameGroupMap(param);
  const leafMap = frameGroupMap.createLeafMap();
  const sourceDescendants = leafMap.get(sourceId) ?? new Set<string>();

  return param.motionData.filter((frame) => {
    if (frame.frameId === sourceId) {
      return false;
    }

    return !sourceDescendants.has(frame.frameId);
  });
}

// Change a frame's parent
export function reassignParentFrame(
  param: simulationpb.SimulationParam,
  frameId: string,
  parentId: string,
) {
  const frameLookup = buildFrameLookup(param);
  const sourceFrame = frameLookup[frameId];
  const parentFrame = frameLookup[parentId];
  if (sourceFrame && parentFrame) {
    sourceFrame.frameParent = parentId;
  }
}

// Change a frame's motion type
export function assignFrameMotionType(
  param: simulationpb.SimulationParam,
  frameId: string,
  motionType: simulationpb.MotionType,
) {
  const frame = findFrameById(param, frameId);
  frame && initializeFrameMotion(frame, motionType);
}

// Change a frame's motion formulation
export function assignFrameMotionFormulation(
  param: simulationpb.SimulationParam,
  frameId: string,
  motionFormulation: simulationpb.MotionFormulation,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    frame.motionFormulation = motionFormulation;
  }
}

// Change a frame's motion specification
export function assignFrameMotionSpecification(
  param: simulationpb.SimulationParam,
  frameId: string,
  motionSpecification: simulationpb.MotionSpecification,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    frame.motionSpecification = motionSpecification;
  }
}

// Return a frame's default rotational velocity vector
export function getFrameDefaultRotationalVelocity(frame: simulationpb.MotionData): basepb.Vector3 {
  if (frame.motionType === simulationpb.MotionType.CONSTANT_ANGULAR_MOTION) {
    const motion = frame.motionAngularVelocity;

    if (motion) {
      return adVec3ToVec3(motion);
    }
  }

  return newOriginProto();
}

// Return a frame's default initial rotation angles
export function getFrameDefaultRotationalAngles(frame: simulationpb.MotionData): basepb.Vector3 {
  if (frame.motionType === simulationpb.MotionType.CONSTANT_ANGULAR_MOTION) {
    const motion = frame.motionRotationAngles;

    if (motion) {
      return adVec3ToVec3(motion);
    }
  }

  return newOriginProto();
}

// Return a frame's default translational velocity vector
export function getFrameDefaultTranslationVelocity(frame: simulationpb.MotionData): basepb.Vector3 {
  if (frame.motionType === simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION) {
    const motion = frame.motionTranslationVelocity;

    if (motion) {
      return adVec3ToVec3(motion);
    }
  }

  return newOriginProto();
}

// Return a frame's default translation vector
export function getFrameDefaultTranslation(frame: simulationpb.MotionData): basepb.Vector3 {
  if (frame.motionType === simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION) {
    const motion = frame.motionTranslation;

    if (motion) {
      return adVec3ToVec3(motion);
    }
  }

  return newOriginProto();
}

// Assign a rotational velocity vector to a frame
export function assignFrameRotationalVelocity(
  param: simulationpb.SimulationParam,
  frameId: string,
  value: basepb.Vector3,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    const velocity = vec3ToAdVec3(value);
    setAdVec3Quantity(velocity, quantitypb.QuantityType.ANGULAR_VELOCITY);

    frame.motionType = simulationpb.MotionType.CONSTANT_ANGULAR_MOTION;
    frame.motionAngularVelocity = velocity;
  }
}

// Assign a zero rotational velocity to a frame. This is used for repositioning.
export function assignFrameZeroRotationalVelocity(
  param: simulationpb.SimulationParam,
  frameId: string,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    const velocity = vec3ToAdVec3(newOriginProto());
    setAdVec3Quantity(velocity, quantitypb.QuantityType.ANGULAR_VELOCITY);
    frame.motionSpecification = simulationpb.MotionSpecification.MOTION_SPECIFICATION_REPOSITION;
    frame.motionAngularVelocity = velocity;
  }
}

// Assign rotational angles vector to a frame
export function assignFrameRotationAngles(
  param: simulationpb.SimulationParam,
  frameId: string,
  value: basepb.Vector3,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    const rotationAngles = vec3ToAdVec3(value);
    setAdVec3Quantity(rotationAngles, quantitypb.QuantityType.DEGREE);

    frame.motionType = simulationpb.MotionType.CONSTANT_ANGULAR_MOTION;
    frame.motionRotationAngles = rotationAngles;
  }
}

// Assign a translational velocity vector to a frame
export function assignFrameTranslationalVelocity(
  param: simulationpb.SimulationParam,
  frameId: string,
  value: basepb.Vector3,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    const velocity = vec3ToAdVec3(value);
    setAdVec3Quantity(velocity, quantitypb.QuantityType.VELOCITY);

    frame.motionType = simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION;
    frame.motionTranslationVelocity = velocity;
  }
}

// Assign a zero translation velocity to a frame. This is used for repositioning.
export function assignFrameZeroTranslationalVelocity(
  param: simulationpb.SimulationParam,
  frameId: string,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    const velocity = vec3ToAdVec3(newOriginProto());
    setAdVec3Quantity(velocity, quantitypb.QuantityType.VELOCITY);
    frame.motionSpecification = simulationpb.MotionSpecification.MOTION_SPECIFICATION_REPOSITION;
    frame.motionTranslationVelocity = velocity;
  }
}

// Assign a translational vector to a frame
export function assignFrameTranslation(
  param: simulationpb.SimulationParam,
  frameId: string,
  value: basepb.Vector3,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    const translation = vec3ToAdVec3(value);
    setAdVec3Quantity(translation, quantitypb.QuantityType.LENGTH);

    frame.motionType = simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION;
    frame.motionTranslation = translation;
  }
}

// Return true if frame has motion
export function frameHasMotion(frame: simulationpb.MotionData) {
  switch (frame.motionType) {
    case simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION:
    case simulationpb.MotionType.CONSTANT_ANGULAR_MOTION: {
      return true;
    }
    default: {
      return false;
    }
  }
}

// Return a velocity vector appropriate to the frame's motion type
export function getFrameVelocity(frame: simulationpb.MotionData) {
  switch (frame.motionType) {
    case simulationpb.MotionType.CONSTANT_ANGULAR_MOTION:
      return frame.motionAngularVelocity;
    case simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION:
      return frame.motionTranslationVelocity;
    default:
      return null;
  }
}

// Return a displacement vector appropriate to the frame's motion type
export function getFrameDisplacement(frame: simulationpb.MotionData) {
  switch (frame.motionType) {
    case simulationpb.MotionType.CONSTANT_ANGULAR_MOTION:
      return frame.motionRotationAngles;
    case simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION:
      return frame.motionTranslation;
    default:
      return null;
  }
}

// Return true if frame has non-zero motion
export function isFrameMoving(frame: simulationpb.MotionData) {
  let motionVector = null;

  switch (frame.motionType) {
    case simulationpb.MotionType.CONSTANT_ANGULAR_MOTION: {
      motionVector = frame.motionAngularVelocity;
      break;
    }
    case simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION: {
      motionVector = frame.motionTranslationVelocity;
      break;
    }
    default: {
      return false;
    }
  }

  return motionVector ? !equalsZero(motionVector) : false;
}

// Return true if frame has repositioning
export function isFrameReposition(frame: simulationpb.MotionData) {
  let motionVector = null;

  switch (frame.motionType) {
    case simulationpb.MotionType.CONSTANT_ANGULAR_MOTION: {
      motionVector = frame.motionRotationAngles;
      break;
    }
    case simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION: {
      motionVector = frame.motionTranslation;
      break;
    }
    default: {
      return false;
    }
  }

  return motionVector ? !equalsZero(motionVector) : false;
}

// Return a list of a frame's ancestors
export function getFrameAncestors(
  param: simulationpb.SimulationParam,
  id: string,
): simulationpb.MotionData[] {
  const frameGroupMap = getFrameGroupMap(param);
  const frameGroupables = frameGroupMap.filterAncestors(id, (entry) => !!entry.frame);

  return frameGroupables.map((groupable) => groupable.frame);
}

// Return true if any of a frame's ancestors has motion
export function frameInheritsMotion(
  param: simulationpb.SimulationParam,
  frame: simulationpb.MotionData,
): boolean {
  const ancestorFrames = getFrameAncestors(param, frame.frameId);
  return ancestorFrames.some(frameHasMotion);
}

// Return true if any of a frame's ancestors is moving.  Where `frameInheritsMotion` will return
// true if any frame in the tree has a configured motion type of rotation or transformation,
// `frameInheritsMovement` also requires that the frame's velocity (rotational or translational) is
// non-zero.
export function frameInheritsMovement(
  param: simulationpb.SimulationParam,
  frame: simulationpb.MotionData,
): boolean {
  const ancestorFrames = getFrameAncestors(param, frame.frameId);
  return ancestorFrames.some(isFrameMoving);
}

// Return a list of a frame's descendants' IDs
export function getFrameDescendantIds(param: simulationpb.SimulationParam, id: string): string[] {
  return getFrameGroupMap(param).findDescendants(id);
}

export function projectHasMovingFrames(param: simulationpb.SimulationParam) {
  return param.motionData.some(isFrameMoving);
}

// Returns true if any frame has non-zero motion while one its ancestors also has non-zero motion
export function projectHasCompositeMotion(param: simulationpb.SimulationParam) {
  return param.motionData.some((frame) => {
    if (isFrameMoving(frame)) {
      const ancestors = getFrameAncestors(param, frame.frameId);
      return ancestors.some((ancestor) => isFrameMoving(ancestor));
    }
    return false;
  });
}

// Return true if frame motion is rotational and non-zero
export function isFrameRotating(frame: simulationpb.MotionData) {
  return (frame.motionType === simulationpb.MotionType.CONSTANT_ANGULAR_MOTION) &&
    isFrameMoving(frame);
}

// Return true if frame motion is translational and non-zero
export function isFrameTranslating(frame: simulationpb.MotionData) {
  return (frame.motionType === simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION) &&
    isFrameMoving(frame);
}

// Return true if any frame has motion
export function projectHasMotion(param: simulationpb.SimulationParam) {
  return param.motionData.some((frame) => frameHasMotion(frame));
}

// Return true if any frame has translation
export function projectHasTranslation(param: simulationpb.SimulationParam) {
  return param.motionData.some((frame) => isFrameTranslating(frame));
}

// Return true if any frame has rotation
export function projectHasRotation(param: simulationpb.SimulationParam) {
  return param.motionData.some((frame) => isFrameRotating(frame));
}

// A helper function for getAllAttachedSurfaceIds and getAllAttachedDomains
export function shouldIterateOnFrame(
  param: simulationpb.SimulationParam,
  frame: simulationpb.MotionData,
  options: AllAttachedGeometryOptions,
) {
  const { excludeFrameIds, includeFrameIds, motion } = options;
  if (motion === 'defined' && !frameHasMotion(frame)) {
    return false;
  }
  if (motion === 'moving' && !isFrameMoving(frame) && !frameInheritsMovement(param, frame)) {
    return false;
  }
  if (includeFrameIds) {
    return includeFrameIds.includes(frame.frameId);
  }
  if (excludeFrameIds) {
    return !excludeFrameIds.includes(frame.frameId);
  }
  return true;
}

// Get list of all surface IDs attached to motion frames (with optional constraints), always
// excluding the global frame
export function getAllAttachedSurfaceIds(
  param: simulationpb.SimulationParam,
  options: AllAttachedGeometryOptions = {},
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  const attachedSurfaces = param.motionData.reduce((result, frame) => {
    if (!isFrameGlobal(param, frame.frameId) && shouldIterateOnFrame(param, frame, options)) {
      return new Set([
        ...result,
        ...unwrapSurfaceIds(frame.attachedBoundaries, geometryTags, entityGroupData),
      ]);
    }

    return result;
  }, new Set<string>());

  return attachedSurfaces;
}

export function getAllAttachedDomainsBySource(
  param: simulationpb.SimulationParam,
  options: AllAttachedGeometryOptions = {},
  geometryTags: GeometryTags,
) {
  return param.motionData.reduce((result, frame) => {
    if (!isFrameGlobal(param, frame.frameId) && shouldIterateOnFrame(param, frame, options)) {
      const frameDomains = frame.attachedDomains.reduce((localResult, domain) => {
        if (geometryTags.isTagId(domain)) {
          return { ...localResult, [domain]: geometryTags.domainsFromTag(domain) };
        }

        return { ...localResult, [domain]: [domain] };
      }, {} as Record<string, string[]>);

      return { ...result, ...frameDomains };
    }

    return result;
  }, {} as Record<string, string[]>);
}

// Get list of all domains attached to motion frames (with optional constraints), always excluding
// the global frame
export function getAllAttachedDomains(
  param: simulationpb.SimulationParam,
  options: AllAttachedGeometryOptions = {},
  geometryTags: GeometryTags,
) {
  return new Set(
    Object.values(
      getAllAttachedDomainsBySource(param, options, geometryTags),
    ).reduce((result, item) => [...result, ...item], []),
  );
}

// Find and return the frame assigned to the given domain (volume)
export function getFrameAttachedToDomain(param: simulationpb.SimulationParam, domain: string) {
  return param.motionData.find((frame) => frame.attachedDomains.includes(domain));
}

// Find and return the frame assigned to the given surface
export function getFrameAttachedToSurface(
  param: simulationpb.SimulationParam,
  surface: string,
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  return param.motionData.find(
    (frame) => unwrapSurfaceIds(
      frame.attachedBoundaries,
      geometryTags,
      entityGroupData,
    ).includes(surface),
  );
}

// Detach a set of surface IDs from all frames.
// It takes care about groups/tags and ensures they don't have any collisions.
// NOTE: This function is explicitly not exported--external code should use removeFrameGeometry()
// below.
function detachFrameSurfaces(
  param: simulationpb.SimulationParam,
  surfaceIds: Set<string>,
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  const unwrappedSurfacesToSelect = unwrapSurfaceIds(
    [...surfaceIds],
    geometryTags,
    entityGroupData,
  );

  param.motionData.forEach((frame) => {
    frame.attachedBoundaries = frame.attachedBoundaries.reduce<string[]>((result, itemId) => {
      const currentItemSurfaces = unwrapSurfaceIds([itemId], geometryTags, entityGroupData);

      // check if unwrapped surfaces have collisions
      // if so – unroll gropus/tags and detach colliding ones
      const surfacesIntersect = intersects(currentItemSurfaces, unwrappedSurfacesToSelect);

      if (!surfacesIntersect) {
        return [...result, itemId];
      }

      return [
        ...result,
        ...currentItemSurfaces.filter((id) => !unwrappedSurfacesToSelect.includes(id)),
      ];
    }, []);
  });

  param.motionData.forEach((frame) => {
    const newSurfaceIds = frame.attachedBoundaries.filter((bound) => !surfaceIds.has(bound));
    frame.attachedBoundaries = newSurfaceIds;
  });
}

// Detach a set of domains from all frames
// NOTE: This function is explicitly not exported--external code should use removeFrameGeometry()
// below.
function detachFrameDomains(param: simulationpb.SimulationParam, domains: Set<string>) {
  param.motionData.forEach((frame) => {
    const newDomains = frame.attachedDomains.filter((domain) => !domains.has(domain));
    frame.attachedDomains = newDomains;
  });
}

// Detach a set of volume IDs from all frames
// NOTE: This function is explicitly not exported--external code should use removeFrameGeometry()
// below.
function detachFrameVolumes(
  param: simulationpb.SimulationParam,
  volumeIds: Set<string>,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  // For each volume, we evaluate whether we also want to detach the volume's surfaces.
  // If the volume and all its surfaces are attached to the same frame, then detach both the volume
  // and its surfaces from that frame.  But if any surface is unattached or attached to another
  // frame, leave all surface assignments alone.
  const surfacesToDetach = new Set<string>();

  const staticVolumeById = Object.fromEntries(staticVolumes.map((volume) => [volume.id, volume]));

  volumeIds.forEach((id) => {
    const isTag = geometryTags.isTagId(id);

    // Find the frame to which this volume is attached (or tag containing this volume)
    const frame = param.motionData.find(
      (frameItem) => {
        if (isTag) {
          // if it's a tag: just check if it's contained in the attachedDomains
          return frameItem.attachedDomains.includes(id);
        }

        return frameItem.attachedDomains.some((domain) => {
          if (geometryTags.isTagId(domain)) {
            // unroll tag and check if the volume domain is within it
            const tagVolumeDomains = geometryTags.domainsFromTagEntityGroupId(domain) || [];
            const tagVolumeIds = mapDomainsToIds(staticVolumes, tagVolumeDomains);

            return tagVolumeIds.includes(id);
          }

          return domain === staticVolumeById[id]?.domain;
        });
      },
    );

    const unrolledVolumeIds = isTag ?
      mapDomainsToIds(staticVolumes, geometryTags.domainsFromTagEntityGroupId(id) || []) :
      [id];

    const bounds = new Set(
      unrolledVolumeIds.flatMap((volumeId) => {
        const volume = staticVolumeById[volumeId];

        return [...volume?.bounds || []];
      }),
    );

    if (frame) {
      frame.attachedDomains = [...frame.attachedDomains.reduce((result, domain) => {
        const isDomainTag = geometryTags.isTagId(domain);

        if (isTag && isDomainTag) {
          if (domain !== id) {
            result.add(domain);
          }

          return result;
        }

        const unrolledDomains = geometryTags.isTagId(domain) ?
          geometryTags.domainsFromTagEntityGroupId(domain) || [] :
          [domain];

        const unrolledIds = mapDomainsToIds(staticVolumes, unrolledDomains);

        if (intersects(new Set(unrolledIds), new Set([id]))) {
          unrolledDomains.forEach((unrolledDomain) => {
            if (unrolledDomain !== staticVolumeById[id].domain) {
              result.add(unrolledDomain);
            }
          });

          return result;
        }

        result.add(domain);
        return result;
      }, new Set<string>())];

      // If the volume's bounds are *all* attached to the volume's frame, then mark them for
      // detachment too.
      if (isSuperset(frame.attachedBoundaries, bounds)) {
        bounds.forEach(surfacesToDetach.add, surfacesToDetach);
      }
    }
  });

  detachFrameSurfaces(param, new Set(surfacesToDetach), geometryTags, entityGroupData);
}

// Attach a set of surface IDs to a frame and remove those surface IDs from all other frames
// NOTE: This function is explicitly not exported--external code should use appendFrameGeometry()
// or replaceFrameGeometry() below.
function attachFrameSurfaces(
  param: simulationpb.SimulationParam,
  frameId: string,
  surfaceIds: Set<string>,
  staticVolumes: StaticVolume[],
  replace = false,
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    // Make sure referenced surfaces are no longer attached to any frames
    detachFrameSurfaces(param, surfaceIds, geometryTags, entityGroupData);

    // take surfaces ids
    const validSurfaceIds = intersectSet(surfacesFromVolumes(staticVolumes), surfaceIds);

    // if selection contains groups/tags, attach them there as well
    surfaceIds.forEach((item) => {
      const isTag = geometryTags.isTagId(item);

      if (isTag) {
        validSurfaceIds.add(item);
      } else {
        const isGroup = entityGroupData.groupMap.has(item) &&
          entityGroupData.groupMap.get(item).children.size > 0;

        // unwrap surfaces for groups
        if (isGroup) {
          unwrapSurfaceIds([item], geometryTags, entityGroupData).forEach((identifier) => {
            validSurfaceIds.add(identifier);
          });
        }
      }
    });

    const newSurfaceIds = replace ?
      validSurfaceIds :
      unionSet(frame.attachedBoundaries, validSurfaceIds);

    frame.attachedBoundaries = [...newSurfaceIds];
  }
}

// Attach a set of domains to a frame and remove those domains from all other frames
// NOTE: This function is explicitly not exported--external code should use appendFrameGeometry()
// or replaceFrameGeometry() below.
function attachFrameVolumes(
  param: simulationpb.SimulationParam,
  frameId: string,
  volumeIds: Set<string>,
  staticVolumes: StaticVolume[],
  replace = false,
  geometryTags: GeometryTags,
) {
  const frame = findFrameById(param, frameId);
  if (frame) {
    const volumeDomains = new Set(mapIdsToDomains(staticVolumes, volumeIds));
    const tagDomains = [...volumeIds].filter((id) => geometryTags.isTagId(id));

    // for the unassignment purposes we need to include volume comains as well as tag ones
    const allDomains = new Set([
      ...volumeDomains,
      ...tagDomains.flatMap((tag) => [tag, ...geometryTags.domainsFromTag(tag)]),
    ]);

    // Make sure referenced volumes are no longer attached to any frames
    detachFrameDomains(param, allDomains);
    const volumesToAssign = [...volumeDomains, ...tagDomains];

    const newDomains = replace ?
      volumesToAssign :
      unionSet(frame.attachedDomains, volumesToAssign);

    frame.attachedDomains = [...newDomains];
  }
}

// Ensures that the global frame is assigned all geometry that's not assigned to other frames
// NOTE: This function is explicitly not exported.  It should be considered private, and external
// code should use one of the more public methods (appendFrameGeometry, replaceFrameGeometry, or
// removeFrameGeometry) to update frame attachments.
function updateGlobalFrameAssignments(
  param: simulationpb.SimulationParam,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  const globalFrame = findGlobalFrame(param);
  if (!globalFrame) {
    return;
  }

  const allDomains = new Set<string>();
  const allSurfaces = new Set<string>();

  staticVolumes.forEach((staticVolume) => {
    allDomains.add(staticVolume.domain);
    staticVolume.bounds.forEach((bound) => allSurfaces.add(bound));
  });

  const globalDomains = subtractSet(
    allDomains,
    getAllAttachedDomains(param, undefined, geometryTags),
  );
  const globalSurfaces = subtractSet(
    allSurfaces,
    getAllAttachedSurfaceIds(param, undefined, geometryTags, entityGroupData),
  );

  globalFrame.attachedBoundaries = [...globalSurfaces];
  globalFrame.attachedDomains = [...globalDomains];
}

// Appends volumes and surfaces to a frame's attached geometry
export function appendFrameGeometry(
  param: simulationpb.SimulationParam,
  frameId: string,
  volumeIds: Set<string>,
  surfaceIds: Set<string>,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  attachFrameSurfaces(
    param,
    frameId,
    surfaceIds,
    staticVolumes,
    false,
    geometryTags,
    entityGroupData,
  );
  attachFrameVolumes(param, frameId, volumeIds, staticVolumes, false, geometryTags);
  updateGlobalFrameAssignments(param, staticVolumes, geometryTags, entityGroupData);
}

// Replaces a frame's attached geometry with a new set of volumes and surfaces
export function replaceFrameGeometry(
  param: simulationpb.SimulationParam,
  frameId: string,
  volumeIds: Set<string>,
  surfaceIds: Set<string>,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  attachFrameSurfaces(
    param,
    frameId,
    surfaceIds,
    staticVolumes,
    true,
    geometryTags,
    entityGroupData,
  );
  attachFrameVolumes(param, frameId, volumeIds, staticVolumes, true, geometryTags);
  updateGlobalFrameAssignments(param, staticVolumes, geometryTags, entityGroupData);
}

/** Replaces a frame's attached volumes with a new set of volumes */
export function replaceFrameVolumes(
  param: simulationpb.SimulationParam,
  frameId: string,
  volumeIds: Set<string>,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  attachFrameVolumes(param, frameId, volumeIds, staticVolumes, true, geometryTags);
  updateGlobalFrameAssignments(param, staticVolumes, geometryTags, entityGroupData);
}

/** Replaces a frame's attached surfaces with a new set of surfaces */
export function replaceFrameSurfaces(
  param: simulationpb.SimulationParam,
  frameId: string,
  surfaceIds: Set<string>,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  attachFrameSurfaces(
    param,
    frameId,
    surfaceIds,
    staticVolumes,
    true,
    geometryTags,
    entityGroupData,
  );
  updateGlobalFrameAssignments(param, staticVolumes, geometryTags, entityGroupData);
}

// Removes a set of volumes and surfaces from a frame's attached geometry
export function removeFrameGeometry(
  param: simulationpb.SimulationParam,
  volumeIds: Set<string>,
  surfaceIds: Set<string>,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) {
  detachFrameVolumes(param, volumeIds, staticVolumes, geometryTags, entityGroupData);
  detachFrameSurfaces(param, surfaceIds, geometryTags, entityGroupData);
  updateGlobalFrameAssignments(param, staticVolumes, geometryTags, entityGroupData);
}

// Given a set of geometry nodes (volumes, surfaces, and/or surface groups) used to initialize a
// new motion frame, return the geometry that should actually be attached to the new frame.
// Rules:
//   - 1. If a volume is selected, include that volume and all of its bounding surfaces.
//   - 2. Include any selected surfaces.
//   - 3. If the selection includes all bounding surfaces for a volume, include the volume.
//   - 4. If a surface group is selected, apply rules 2-3 to each constituent surface.
//   - 5. If a geometry is tag - unroll volumes and surfaces and add to the result.
export function getDefaultAttachableGeometry(
  geometryIds: Set<string>,
  entityGroupData: EntityGroupData,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
) {
  // First, extract a list of volumes and surfaces from `geometryNodes`
  const baseVolumes: string[] = [];
  const baseSurfaces: string[] = [];
  const tagIds: string[] = [];

  geometryIds.forEach((id) => {
    if (geometryTags.isTagId(id)) {
      tagIds.push(id);
    } else if (entityGroupData.groupMap.has(id)) {
      const { entityType } = entityGroupData.groupMap.get(id);
      if (entityType === EntityType.VOLUME) {
        baseVolumes.push(id);
      }
      if ((entityType === EntityType.SURFACE) || (entityType === EntityType.MIXED)) {
        // A surface or surface group
        baseSurfaces.push(...getSurfaceGroupSurfaces(id, entityGroupData));
      }
    }
  });

  const volumes = new Set(baseVolumes);
  const surfaces = new Set(baseSurfaces);

  staticVolumes.forEach((staticVolume) => {
    const { id, bounds } = staticVolume;

    // If a volume is selected, include the volume ID and all of its surfaces.
    if (baseVolumes.includes(id)) {
      bounds.forEach((surface) => surfaces.add(surface));
    }

    // If volume's surfaces are all selected, include the volume ID.
    if (isSuperset(baseSurfaces, bounds)) {
      volumes.add(id);
    }
  });

  tagIds.forEach((tagId) => {
    const tagVolumeDomains = geometryTags.domainsFromTagEntityGroupId(tagId) || [];
    const tagSurfaces = geometryTags.surfacesFromTagEntityGroupId(tagId) || [];

    if (tagVolumeDomains.length > 0) {
      volumes.add(tagId);
    }

    if (tagSurfaces.length > 0) {
      surfaces.add(tagId);
    }
  });

  return { volumes, surfaces };
}

// Remove a frame by ID
export function removeFrame(
  param: simulationpb.SimulationParam,
  id: string,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
  options?: RemoveFrameOptions,
): boolean {
  const { hoist = false } = options || {};

  const frame = findFrameById(param, id);
  if (frame) {
    const frameGroupMap = getFrameGroupMap(param);
    const descendantIds = frameGroupMap.findDescendants(id);
    const idsToRemove = [id];

    if (hoist) {
      const parent = findFrameParentById(param, id);
      if (!parent) {
        throw Error(`No parent round for frame ${frame.frameName}`);
      }
      const parentId = parent.frameId;
      const childFrames = findFrameChildren(param, id);
      childFrames.forEach((childFrame) => {
        childFrame.frameParent = parentId;
      });
    } else {
      idsToRemove.push(...descendantIds);
    }

    const newFrames = param.motionData.filter(
      (item) => !idsToRemove.includes(item.frameId),
    );
    param.motionData = newFrames;
    if (param.bodyFrame?.bodyFrameId === id) {
      param.bodyFrame = undefined;
    }

    // Now that the frame has been removed, any surfaces or volumes that were assigned to it should
    // be re-assigned to the global frame.
    updateGlobalFrameAssignments(param, staticVolumes, geometryTags, entityGroupData);
  }

  // If frame no longer exists, return true to indicate it has been removed
  return !findFrameById(param, id);
}

// Return true if the motion strategy for the project is Moving Reference Frame (MRF)
export function isMovingReferenceFrameProject(param: simulationpb.SimulationParam) {
  return projectHasMotion(param) &&
    (param.general?.flowBehavior === simulationpb.FlowBehavior.STEADY);
}

// Checks to see if a surface and its matching volume are members of the same frame
export function frameHasMatchingSurfaceAndVolume(
  frame: simulationpb.MotionData,
  surfaceId: string,
  staticVolumes: StaticVolume[],
): boolean {
  const domains = frame.attachedDomains;
  return staticVolumes.some((volume) => {
    if (volume.bounds.has(surfaceId)) {
      return domains.includes(volume.domain);
    }
    return false;
  });
}

// A set of common sanity checks for all frames (including global)
export function commonFrameWarnings(
  frame: simulationpb.MotionData,
  param: simulationpb.SimulationParam,
  entityGroupData: EntityGroupData,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
): string[] {
  const warnings: string[] = [];

  const name = frame.frameName;
  const formattedLabel = name ? `Frame ${boldEscaped(name)}` : 'Frame';

  // Check for a valid parent frame
  if (!isFrameGlobal(param, frame.frameId)) {
    if (!findFrameParentById(param, frame.frameId)) {
      warnings.push(`${formattedLabel}: parent frame not found.`);
    }
  }

  // Cache the viscous wall surfaces to accelerate the searches below.
  const viscousWallSurfaces = new Set();
  param.physics.forEach((physics) => {
    getFluid(physics)?.boundaryConditionsFluid.forEach((bc) => {
      if (isViscousWallBc(bc)) {
        unwrapSurfaceIds(bc.surfaces, geometryTags, entityGroupData).forEach((surfaceId) => (
          viscousWallSurfaces.add(surfaceId)
        ));
      }
    });
  });

  // Surface checks
  unwrapSurfaceIds(frame.attachedBoundaries, geometryTags, entityGroupData).forEach((surfaceId) => {
    if (entityGroupData.groupMap.has(surfaceId)) {
      // Non-wall surfaces must move with their corresponding volumes
      if (!frameHasMatchingSurfaceAndVolume(frame, surfaceId, staticVolumes) &&
        frameHasMotion(frame) && !viscousWallSurfaces.has(surfaceId)
      ) {
        const surfaceName = entityGroupData.groupMap.get(surfaceId).name;
        warnings.push(
          `${formattedLabel}: surface ${boldEscaped(surfaceName)} must be a viscous wall to move ` +
          'independently of its volume.',
        );
      }
    } else {
      warnings.push(`${formattedLabel}: attached surface ${boldEscaped(surfaceId)} not found.`);
    }
  });

  // Volume checks
  const allDomains = new Set(staticVolumes.map(({ domain }) => domain));
  frame.attachedDomains.forEach((domain) => {
    if (!allDomains.has(domain) && !geometryTags.isTagId(domain)) {
      warnings.push(`${formattedLabel}: attached volume ${boldEscaped(domain)} not found.`);
    }
  });

  return warnings;
}

// Check for steady problems with both rotating and translating volumes
export function mixedMotionConflict(param: simulationpb.SimulationParam) {
  let hasTranslation = false;
  let hasRotation = false;

  if (isMovingReferenceFrameProject(param)) {
    param.motionData.forEach((frame) => {
      if (frame.attachedDomains.length) {
        if (isFrameRotating(frame)) {
          hasRotation = true;
        }
        if (isFrameTranslating(frame)) {
          hasTranslation = true;
        }
      }
    });
  }

  return hasTranslation && hasRotation;
}

const compositeMotionConflict = (param: simulationpb.SimulationParam) => (
  isMovingReferenceFrameProject(param) && projectHasCompositeMotion(param)
);

// A set of collective sanity checks for motion
export function generalMotionWarnings(param: simulationpb.SimulationParam) {
  const warnings: string[] = [];

  if (mixedMotionConflict(param)) {
    // MRF isn't valid for problems with mixed translating/rotating domains
    warnings.push('Cannot mix translational and rotational motion for steady problems.');
  }

  if (compositeMotionConflict(param)) {
    // Composite motion (a frame and one its ancestors both have motion) isn't allowed either,
    // because it very likely breaks the MRF assumption, and we don't currently have a method for
    // checking if composite motions cancel each other.  For now, disallow it.
    warnings.push('Composite motion not allowed for steady problems.');
  }

  return warnings;
}

export function frameHasAttachedGeometry(frame: simulationpb.MotionData): boolean {
  return (frame.attachedDomains.length + frame.attachedBoundaries.length) > 0;
}

export function frameHasGeometryInBranch(
  frame: simulationpb.MotionData,
  param: simulationpb.SimulationParam,
): boolean {
  // Function for determining if the user should be warned of an "empty" motion tree, i.e. one in
  // which no node has geometry assigned

  // If the node itself has no geometry, check descendants and ancestors for geometry
  if (!frameHasAttachedGeometry(frame)) {
    const groupMap = getFrameGroupMap(param);

    // Check ancestors for a single node with geometry
    const ancestorFrameGroup = groupMap.findAncestor(frame.frameId, (ancestor) => {
      const { frame: ancestorFrame } = ancestor;
      if (ancestorFrame && !isFrameGlobal(param, ancestorFrame?.frameId)) {
        return frameHasAttachedGeometry(ancestorFrame);
      }
      return false;
    });
    if (ancestorFrameGroup) {
      return true;
    }

    // Check descendants for geometry
    const descendantIds = groupMap.findDescendants(frame.frameId);
    return descendantIds.some((descendantId: string) => {
      const descendantFrame = findFrameById(param, descendantId);
      return descendantFrame && frameHasAttachedGeometry(descendantFrame);
    });
  }

  return true;
}

// Return true iff no warnings are generated from the motion configuration
export function isMotionValid(
  param: simulationpb.SimulationParam,
  entityGroupData: EntityGroupData,
  staticVolumes: StaticVolume[],
  geometryTags: GeometryTags,
) {
  const { frames } = orderedFrames(param);

  if (generalMotionWarnings(param).length) {
    return false;
  }

  return frames.every(
    (frame) => !commonFrameWarnings(
      frame,
      param,
      entityGroupData,
      staticVolumes,
      geometryTags,
    ).length,
  );
}

// Checks for the existence of a frame given a frameId. The Global Frame is added
// to sim settings and analyzer requests if the frames list is empty so it will always exist
export function frameExists(param: simulationpb.SimulationParam, frameId: string) {
  const frames = orderedFrames(param).frames;
  return frames.some((frame) => frame.frameId === frameId);
}

// Get list of frames, starting with global and descending through the tree until the given
// `frameId` is reached.  If `inclusive` is true, the frame identified by `frameId` will be
// included; otherwise, the list will terminate with the parent.
export function getFramePathFromGlobal(
  param: simulationpb.SimulationParam,
  frameId: string,
  inclusive = true,
) {
  const map = getFrameGroupMap(param);
  const result: simulationpb.MotionData[] = [];

  let id = frameId;
  while (map.has(id)) {
    if (GroupMap.isRoot(id)) {
      break;
    }

    const group = map.get(id);
    if (inclusive || id !== frameId) {
      result.push(group.frame);
    }

    if (group.parentId) {
      id = group.parentId;
    } else {
      break;
    }
  }

  result.reverse();

  return result;
}

// Apply a set of simulationpb.FrameTransforms objects to a Vector3 object and return the result.
// If `inverse` is true, the translations and rotations will be reversed.
export function applyTransforms(
  posn: basepb.Vector3,
  transforms: simulationpb.FrameTransforms[],
  inverse = false,
): basepb.Vector3 {
  const sign = inverse ? -1 : 1;

  return transforms.reduce((result, tform) => {
    switch (tform.transformType) {
      case TRANSLATIONAL_TRANSFORM: {
        const translation = tform.transformTranslation;
        if (translation) {
          return add(mult(adVec3ToVec3(translation), sign), result);
        }
        return result;
      }
      case ROTATIONAL_TRANSFORM: {
        const rotation = tform.transformRotationAngles;
        if (rotation) {
          return rotate(mult(adVec3ToVec3(rotation), sign), result);
        }
        return result;
      }
      default: {
        return result;
      }
    }
  }, posn);
}

export function frameAuxiliaryIcons(
  param: simulationpb.SimulationParam,
  frame: simulationpb.MotionData,
): SvgIconSpec | undefined {
  if (frameHasMotion(frame)) {
    return { name: 'rotatingDots', color: 'var(--color-citron-green-600' };
  }

  if (param.bodyFrame?.bodyFrameId === frame.frameId) {
    return { name: 'airplane', color: 'var(--color-citron-green-600' };
  }

  return undefined;
}

// Find all frames that meet ALL of the following requirement:
//   1. The frame's motion type is set to NO_MOTION
//   2. Each of the frame's ancestors have motion type set to NO_MOTION
export function getStationaryFrames(
  param: simulationpb.SimulationParam,
): simulationpb.MotionData[] {
  const result: simulationpb.MotionData[] = [];

  const frameGroupMap = getFrameGroupMap(param);

  const processChildren = (childIds: string[]) => {
    childIds.forEach((childId) => {
      const frameGroupable = frameGroupMap.get(childId);
      const frame = frameGroupable.frame;
      if (!frameHasMotion(frame)) {
        result.push(frame);
        processChildren([...frameGroupable.children]);
      }
    });
  };

  processChildren([...frameGroupMap.root().children]);

  return result;
}

// Return true when volumes have motion in a transient simulation, in which case certain user-added
// geometry elements (disks, points, planes) are no supported.
export function unsteadyMotionConflict(
  param: simulationpb.SimulationParam,
  geometryTags: GeometryTags,
) {
  if (isSimulationTransient(param)) {
    return !!getAllAttachedDomains(param, { motion: 'moving' }, geometryTags).size;
  }
  return false;
}

// If a volume (domain) is attached to frame, check whether that frame or any of its ancestors has
// motion.
export function domainHasMotion(param: simulationpb.SimulationParam, domain: string) {
  const frame = getFrameAttachedToDomain(param, domain);
  if (frame) {
    if (frameHasMotion(frame)) {
      return true;
    }

    return getFrameAncestors(param, frame.frameId).some(frameHasMotion);
  }
  return false;
}

/**
 * Generates an error for volume or surface group that contains restricted surfaces
 * @param attachedSurfaces the restricted surface ids that are already attached to other frames
 * @param parentName the parent that contains restricted surfaces (either a volume or a group)
 * @param entityGroupMap the entityGroupMap that contains all entities
 */
function attachedSurfacesError(
  attachedSurfaces: Set<string>,
  parentName: string,
  entityGroupMap: EntityGroupMap,
) {
  const names = [...attachedSurfaces].map(
    (id) => (entityGroupMap.has(id) ? entityGroupMap.get(id).name : id),
  );
  return `Some surfaces in ${parentName} are already attached to other frames: ${names.join(', ')}`;
}

/**
 * Find if there's an error that prevents us to add a volume to a Frame.
 * If we hover over the volumes rows in the Geometry tree, this error will appear as a tooltip for
 * the disabled row.
 * If we click on the volume node in the 3D viewer, the error will appear as a section message
 * under the selection table in the prop panel.
 *
 * @param nodeId the id of the node that is being hovered in the tree or selected in the 3D viewer
 * @param selectedNodeId  the id of the node for which we want to attach volumes
 * @param param SimulationParam
 * @param entityGroupData the entityGroupData
 * @param staticVolumes all the volumes
 * @param autoSelectSurfaces a flag indicating whether the "Auto-select surfaces" toggle is on
 */
export function attachVolumeToFrameError(
  nodeId: string,
  selectedNodeId: string | undefined,
  param: simulationpb.SimulationParam,
  entityGroupData: EntityGroupData,
  staticVolumes: StaticVolume[],
  autoSelectSurfaces: boolean,
  geometryTags: GeometryTags,
) {
  if (!selectedNodeId) {
    return '';
  }

  // The volume row/node we may try to attach to the frame.
  const volume = staticVolumes.find((vol) => vol.id === nodeId);
  if (!volume) {
    return 'Invalid volume selected';
  }

  // The frame we want to attach volumes to
  const frameId = findFrameById(param, selectedNodeId)?.frameId;
  if (!frameId) {
    return 'Cannot find the frame';
  }

  // Find volumes that are already attached to other frames
  const restrictedDomainsBySource = getAllAttachedDomainsBySource(
    param,
    { excludeFrameIds: [frameId] },
    geometryTags,
  );
  const attachmentTargetDomain = Object.entries(restrictedDomainsBySource)
    .find(([, volumes]) => volumes.includes(volume.id))?.[0];

  // Return an error if the current volume is already attached to another frame
  if (attachmentTargetDomain) {
    const otherFrame = getFrameAttachedToDomain(param, attachmentTargetDomain);

    return `${volume.defaultName} is attached to ${otherFrame?.frameName}`;
  }

  // If the "auto-select surfaces" option is on, we should also return an error if any of its
  // child surfaces is attached to other frames
  if (autoSelectSurfaces) {
    // If the volume is already attached to the current frame, we should always allow unselection
    if (getFrameAttachedToDomain(param, volume.domain)?.frameId === frameId) {
      return '';
    }

    // Find all surfaces that are attached to other frames
    const restrictedSurfaces = getAllAttachedSurfaceIds(
      param,
      { excludeFrameIds: [frameId] },
      geometryTags,
      entityGroupData,
    );
    // Find the child surfaces that are attached to other frames
    const restrictedChildSurfaces = intersectSet(restrictedSurfaces, volume.bounds);

    if (restrictedChildSurfaces.size) {
      return attachedSurfacesError(
        restrictedChildSurfaces,
        volume.defaultName,
        entityGroupData.groupMap,
      );
    }
  }
  return '';
}

/**
 * Find if there's an error that prevents us to add a surface to a Frame.
 * If we hover over the surfaces rows in the Geometry tree, this error will appear as a tooltip for
 * the disabled row.
 * If we click on the surface node in the 3D viewer, the error will appear as a section message
 * under the selection table in the prop panel.
 *
 * @param nodeId the id of the node that is being hovered in the tree or selected in the 3D viewer
 * @param selectedNodeId  the id of the node for which we want to attach surfaces
 * @param param SimulationParam
 * @param entityGroupData the entityGroupData
 */
export function attachSurfaceToFrameError(
  nodeId: string,
  selectedNodeId: string | undefined,
  param: simulationpb.SimulationParam,
  entityGroupData: EntityGroupData,
  geometryTags: GeometryTags,
) {
  if (!selectedNodeId) {
    return '';
  }

  // The surface row/node we may try to attach to the frame.
  const surface = entityGroupData.groupMap.has(nodeId) && entityGroupData.groupMap.get(nodeId);
  if (!surface) {
    return 'Invalid surface selected';
  }

  // The frame we want to attach volumes to
  const frameId = findFrameById(param, selectedNodeId)?.frameId;
  if (!frameId) {
    return 'Cannot find the frame';
  }

  // Find surfaces that are already attached to other frames
  const restrictedSurfaces = getAllAttachedSurfaceIds(
    param,
    { excludeFrameIds: [frameId] },
    geometryTags,
    entityGroupData,
  );

  // Return an error if the current surface is already attached to another frame
  if (restrictedSurfaces.has(surface.id)) {
    const otherFrame = getFrameAttachedToSurface(param, surface.id, geometryTags, entityGroupData);
    return `${surface.name} is attached to ${otherFrame?.frameName}`;
  }

  // If we have a surface group and not a regular surface, return an error if any of the child
  // surfaces is already attached to another frame
  if (restrictedSurfaces.size && surface.children.size) {
    const restrictedChildSurfaces = intersectSet(
      restrictedSurfaces,
      entityGroupData.groupMap.findDescendants(surface.id),
    );

    if (restrictedChildSurfaces.size) {
      return attachedSurfacesError(restrictedChildSurfaces, surface.name, entityGroupData.groupMap);
    }
  }
  return '';
}

export const MOTION_FRAME_VOLUME_SUBSELECT_ID = 'frame-volumes';
