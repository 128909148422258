// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 14;
const baseHeight = 16;
export const CubeOutlineStarIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="cubeOutlineStarIcon"
      fill="none"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <g style={{ stroke: getColor(0) }}>
        <path
          d="M7.00103 1.33331L12.7754 4.66665V11.3333L7.00103 14.6666L1.22667 11.3333V8.68374"
          fill="none"
          strokeMiterlimit="10"
        />
        <path
          d="M7.00099 14.5333V8.07178"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.00099 8.07177L12.601 4.83075"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g style={{ stroke: getColor(0) }}>
        <path
          d="M3.4 3.31668L1 2.49668"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M3.4 3.31671L1.92 5.46671"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M3.39999 3.31668L5.79999 2.49668"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M3.39999 3.31671L4.87999 5.46671"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M3.39999 3.31669V0.666687"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
});
