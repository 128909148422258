// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView } from '../../../lib/componentTypes/context';
import { NodeType, ROOT_GEOMETRY_CONTAINER_ID, SimulationTreeNode } from '../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { isTestingEnv } from '../../../lib/testing/utils';
import * as geometrypb from '../../../proto/geometry/geometry_pb';
import { geometryState } from '../../../recoil/geometry/geometryState';
import { routeParamsState } from '../../../recoil/useRouteParams';
import { currentViewState } from '../global/currentView';

const CHILD_FEATURE_CASES: Set<geometrypb.Feature['operation']['case']> = new Set([
  'boolean', 'create', 'delete', 'import', 'imprint', 'transform', 'shrinkwrap', 'farfield',
  'pattern',
]);

// Returns a SimulationTreeNode for the items on the geometry page.
export const geoModificationsTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'geoModificationsTreeSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const [
      currentView,
      { geometryId },
    ] = get(waitForAll([currentViewState, routeParamsState]));

    const children: SimulationTreeNode[] = [];

    if (!isTestingEnv() && geometryId && currentView === CurrentView.GEOMETRY) {
      const geoState = get(geometryState({ projectId: key.projectId, geometryId }));
      geoState?.geometryFeatures.forEach((feature) => {
        // We only support showing certain operation types.
        if (CHILD_FEATURE_CASES.has(feature.operation.case)) {
          children.push(new SimulationTreeNode(
            NodeType.GEOMETRY_MODIFICATION,
            feature.id,
            feature.featureName,
          ));
        }
      });
    }

    return new SimulationTreeNode(
      NodeType.ROOT_GEOMETRY,
      ROOT_GEOMETRY_CONTAINER_ID,
      'Modifications',
      children,
    );
  },
  dangerouslyAllowMutability: true,
});

export function useModificationsTree(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(geoModificationsTreeSelector({ projectId, workflowId, jobId }));
}
