// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect } from 'react';

import * as flags from '../../flags';
import { CommonMenuItem, HelpfulIconSpec } from '../../lib/componentTypes/menu';
import { IconName } from '../../lib/componentTypes/svgIcon';
import { extname, isLcSolnExtension } from '../../lib/path';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcvisSettingsState } from '../../recoil/lcvis/lcvisSettings';
import { useMeshUrlState } from '../../recoil/meshState';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import Dropdown from '../Dropdown';
import { ToolbarButton } from '../Toolbar/ToolbarButton';
import { useProjectContext } from '../context/ProjectContext';

import { useParaviewContext } from './ParaviewManager';

import { ColorByEnum, useColorBy } from '@/recoil/geometry/geometryColorByState';
import environmentState from '@/state/environment';

interface RepresentationItem {
  name?: string,
  type: ParaviewRpc.RepresentationType,
  iconName: IconName,
}

const allRepresentations: RepresentationItem[] = [
  { name: 'Shaded', type: 'Surface', iconName: 'circle' },
  { name: 'Shaded With Edges', type: 'Surface With Edges', iconName: 'wireframeInverted' },
  { type: 'Wireframe', iconName: 'wireframe' },
  { type: 'Points', iconName: 'ringOfCircles' },
];

const geomRepresentations: RepresentationItem[] = [
  { name: 'Shaded', type: 'Surface', iconName: 'circle' },
];

const geomRepresentationsWithEdges: RepresentationItem[] = [
  { name: 'Shaded', type: 'Surface', iconName: 'circle' },
  {
    name: 'Shaded With Edges (Staff)',
    type: 'Surface With Edges',
    iconName: 'wireframeInverted',
  },
];

// Draw dropdown menu that selects "Surface"/"Wireframe"/etc.
const GeomRepresentationMenu = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { setViewAttrs, viewState } = useParaviewContext();
  const [meshUrlState] = useMeshUrlState(projectId);
  const surfaceEdgesEnabled = useIsEnabled(flags.geomSurfaceWithEdges);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const [colorBy, setColorBy] = useColorBy(projectId);

  const [lcvisSettings, setLcvisSettings] = useLcvisSettingsState({ projectId, workflowId, jobId });

  const hasViewAttrs = !!viewState?.attrs;

  // The currently selected representation type.
  const currentType = (
    lcvisEnabled ?
      lcvisSettings.displayProps.reprType :
      hasViewAttrs && viewState!.attrs!.reprType
  ) || 'Surface';

  const menuIcon = (
    allRepresentations.find((item) => item.type === currentType)?.iconName || 'circle'
  );

  const onRepresentationChanged = useCallback((reprType: ParaviewRpc.RepresentationType) => {
    if (lcvisEnabled) {
      setLcvisSettings((prev) => ({
        ...prev,
        displayProps: { ...prev.displayProps, reprType },
      }));
    } else {
      setViewAttrs({ reprType });
    }
  }, [setViewAttrs, lcvisEnabled, setLcvisSettings]);
  const disabled = !hasViewAttrs && !(lcvisEnabled && lcvisReady);

  // If we are postprocessing a solution file, then all representation types are
  // available. Else, the available representations are determined with the
  // meshUrlState.
  const isSolution = viewState &&
    isLcSolnExtension(extname((viewState.root.param as ParaviewRpc.ReaderParam).url));

  let representations = allRepresentations;

  if (!isSolution && meshUrlState.activeType === projectstatepb.UrlType.GEOMETRY) {
    representations = surfaceEdgesEnabled ? geomRepresentationsWithEdges : geomRepresentations;
  }

  if (lcvisEnabled) {
    // LCVis doesn't support a 'Points' representation because it is essentially unused by
    // customers in Paraview. See stats on LC-19974
    representations = allRepresentations.filter((item) => item.type !== 'Points');
  }

  useEffect(() => {
    if (!representations.some((item) => item.type === currentType)) {
      onRepresentationChanged(representations[0].type);
    }
  }, [representations, currentType, onRepresentationChanged]);

  const menuItems: CommonMenuItem[] = [
    ...representations.map((repr: RepresentationItem) => ({
      disabled,
      startIcon: { name: repr.iconName },
      label: repr.name || repr.type,
      onClick: () => onRepresentationChanged(repr.type),
      engaged: repr.type === currentType,
    })),
  ];

  if (lcvisEnabled) {
    const { reprType } = lcvisSettings.displayProps;
    const reprTypeOption = representations.find((item) => item.type === reprType);

    const disabledWhileWireframe = {
      disabled: reprType === 'Wireframe',
      disabledReason: 'Colors are not available for Wireframe representation',
    };

    const hideColors = () => setLcvisSettings((prev) => ({
      ...prev,
      displayProps: {
        ...prev.displayProps,
        showColors: false,
      },
    }));

    const showColors = () => setLcvisSettings((prev) => ({
      ...prev,
      displayProps: {
        ...prev.displayProps,
        showColors: true,
      },
    }));

    const hiddenEdgesDisabled = {
      disabled: reprType !== 'Surface With Edges',
      disabledReason: `Edges are hidden when ${reprTypeOption?.name || reprType} is selected`,
    };

    const startIconColorBy = (key: string): HelpfulIconSpec => ({
      name: 'checkmark',
      color: colorBy === key ? '#9F8EFB' : 'transparent',
    });

    const startIconHighlightEdges = (shouldHighlight?: boolean): HelpfulIconSpec => ({
      name: 'checkmark',
      color: lcvisSettings.displayProps.highlightNeighborEdges === shouldHighlight ?
        '#9F8EFB' : 'transparent',
    });

    menuItems.push({ separator: true });
    menuItems.push({
      label: 'Color by',
      items: [
        {
          label: 'None',
          onClick: () => {
            setColorBy(ColorByEnum.NONE);
            hideColors();
          },
          engaged: colorBy === ColorByEnum.NONE,
          startIcon: startIconColorBy(ColorByEnum.NONE),
          ...disabledWhileWireframe,
        },
        {
          label: 'Surface',
          onClick: () => {
            setColorBy(ColorByEnum.SURFACE);
            showColors();
          },
          engaged: colorBy === ColorByEnum.SURFACE,
          startIcon: startIconColorBy(ColorByEnum.SURFACE),
          ...disabledWhileWireframe,
        },
        {
          label: 'Volume',
          onClick: () => {
            setColorBy(ColorByEnum.VOLUME);
            showColors();
          },
          engaged: colorBy === ColorByEnum.VOLUME,
          startIcon: startIconColorBy(ColorByEnum.VOLUME),
          ...disabledWhileWireframe,
        },
        {
          label: 'Surface Tag',
          onClick: () => {
            setColorBy(ColorByEnum.SURFACE_TAGS);
            showColors();
          },
          engaged: colorBy === ColorByEnum.SURFACE_TAGS,
          startIcon: startIconColorBy(ColorByEnum.SURFACE_TAGS),
          ...disabledWhileWireframe,
        },
        {
          label: 'Volume Tag',
          onClick: () => {
            setColorBy(ColorByEnum.VOLUME_TAGS);
            showColors();
          },
          engaged: colorBy === ColorByEnum.VOLUME_TAGS,
          startIcon: startIconColorBy(ColorByEnum.VOLUME_TAGS),
          ...disabledWhileWireframe,
        },
      ],
    });
    menuItems.push({
      label: 'Highlight Neighbor Edges',
      items: [
        {
          label: 'On',
          onClick: () => {
            setLcvisSettings((prev) => ({
              ...prev,
              displayProps: {
                ...prev.displayProps,
                highlightNeighborEdges: true,
              },
            }));
          },
          engaged: lcvisSettings.displayProps.highlightNeighborEdges === true,
          startIcon: startIconHighlightEdges(true),
          ...hiddenEdgesDisabled,
        },
        {
          label: 'Off',
          onClick: () => {
            setLcvisSettings((prev) => ({
              ...prev,
              displayProps: {
                ...prev.displayProps,
                highlightNeighborEdges: false,
              },
            }));
          },
          engaged: lcvisSettings.displayProps.highlightNeighborEdges === false,
          startIcon: startIconHighlightEdges(false),
          ...hiddenEdgesDisabled,
        },
      ],
    });
  }

  return (
    <Dropdown
      disableCloseOnClick
      flushAlign
      key="geomRepresentationDropdown"
      menuItems={menuItems}
      position="below-right"
      toggle={(
        <ToolbarButton
          disabled={disabled}
          dropdown
          icon={{ name: menuIcon, maxHeight: 14 }}
          key="geomRepresentationButton"
          locator="toolbar-geom-representation"
          onClick={() => { }}
          title="Geometry Representation"
        />
      )}
    />
  );
};

export default GeomRepresentationMenu;
