// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { ReactNode, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { colors } from '../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../lib/event';
import { routes } from '../../lib/navigation';
import { ActionButton } from '../Button/ActionButton';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { SectionMessage } from '../notification/SectionMessage';
import { EyeOffIcon } from '../svg/EyeOffIcon';
import { EyeOnIcon } from '../svg/EyeOnIcon';
import { AutoCollapsingMessage } from '../visual/AutoCollapsingMessage';
import { Flex } from '../visual/Flex';
import { PasswordStrength } from '../visual/PasswordStrength';

import CheckBox from './CheckBox';
import { TextInput } from './TextInput';

import Form from '.';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    },

    inputBlock: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',

      '& input': {
        // chrome is showing the input with 14px but safari is using 11px. This unifies it.
        fontSize: '14px',
      },
    },

    checkboxRow: {
      display: 'flex',
      alignItems: 'baseline',
      gap: '8px',
      cursor: 'pointer',
    },

    // The TextInput has a right padding so when we are adding the endAdornment for the password
    // toggle, we should move it closed to the right edge.
    togglePasswordButton: {
      position: 'relative',
      right: '-5px',
      top: '1px',
    },

    fieldsAndSubmit: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',

      '&.contained': {
        padding: '16px',
        backgroundColor: colors.surfaceMedium2,
        border: `1px solid ${colors.neutral350}`,
        borderRadius: '8px',
      },
    },

    multiColumnRow: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '8px',
    },
  }),
  { name: 'AuthForm' },
);

interface AuthFormField<T extends string> {
  asBlock?: boolean;
  autofocus?: boolean;
  label?: ReactNode;
  dataLocator?: string;
  disabled?: boolean;
  // Show a tooltip with a reason why the field is disabled.
  disabledReason?: string;
  name: T;
  placeholder?: string;
  type?: 'text' | 'password' | 'checkbox';
  validate?: (value: any) => boolean;
  // Setting this to true will show a password strength widget for the first password field.
  // If there are more fields with this flag, the rest (except the first) will be ignored.
  strength?: boolean;
  // Optional default value that will be shown when the form is initially loaded
  value?: string;
  // Text fields can have a helper node, shown above the input and on the right side of the label
  helper?: ReactNode;
  // If true, submit will be disabled if field is empty/unchecked
  required?: boolean;
  // Optional text to be shown at the beginning of the input
  startAdornment?: string;
}

export type DataValues = Record<string, string>;
type MultiFieldRow<T extends string> = AuthFormField<T> | [AuthFormField<T>, AuthFormField<T>];

interface AuthFormProps<T extends string> {
  // This makes the form appear in a bordered section with a different background
  contained?: boolean;
  // All the fields
  fields: AuthFormField<T>[] | MultiFieldRow<T>[];
  // If the fieldsError has a key that matches any of the "name" props for some field, that will
  // trigger an error state for that particular field
  fieldsError?: Partial<Record<T, string>>;
  // If set to true, when the fieldsError contains errors after form submit, all form fields will
  // be set to they default value.
  // If it is an array, only the fields' with names that are defined in the array will be reset.
  // Also if this prop is defined, the submit will be enabled even with fieldsError
  resetFieldsOnError?: true | string[];
  // Global error shown above the form in a SectionMessage
  globalError?: string;
  onChange?: (data: Record<T, string>) => void;
  onSubmit: (data: Record<T, string>) => void;
  submit: {
    disabled?: boolean;
    showSpinner?: boolean;
    label: string;
  }
}

// This component renders a <form> element that calls onSubmit property and uses preventDefault.
// It renders fields, according to the fields prop and keeps a state with the field values which
// is later send with the onSubmit call.
// In a future commit this will be expanded to also render the error message.

// This will be only used for the new login flow pages, but if we want, we can expand it for
// more pages.
const AuthForm = <T extends string>(props: AuthFormProps<T>) => {
  // Props
  const {
    contained,
    fields,
    fieldsError,
    globalError,
    onChange,
    onSubmit,
    submit,
    resetFieldsOnError,
  } = props;

  const flattenFields = useMemo(() => fields.flat(), [fields]);
  const defaultValues = useMemo(() => flattenFields.reduce((acc, field) => {
    acc[field.name] = field.value || '';
    return acc;
  }, {} as Record<string, string>), [flattenFields]);

  // Hooks
  const classes = useStyles();
  const navigate = useNavigate();

  // State
  // This is used to determine whether the user has started typing and whether we should show
  // any potential errors or validations.
  const [active, setActive] = useState(false);
  const [fieldsAreAutofilled, setFieldsAreAutofilled] = useState(false);
  // If we have password fields, we can toggle their eye to show/hide the password as text
  const [showPasswords, setShowPasswords] = useState<string[]>([]);

  // Keep an object state with all fields and their values. Default value field.value or ''.
  const [valuesMap, setValuesMap] = useState(defaultValues);

  // If we have a password field that we need to show the PasswordStrength widget for, we'd need
  // a state to keep track of the strength and whether the password has met the requirements.
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);

  // Derived state
  const hasEmptyField =
    flattenFields.some((field) => field.required && valuesMap[field.name] === '');
  const passwordField = flattenFields.find((field) => field.type === 'password');
  const hasInvalidFields = flattenFields.some(
    (field) => field.validate && !field.validate(valuesMap[field.name]),
  );

  const updateValue = (name: string, value: string) => {
    setActive(true);
    setValuesMap((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const handlePasswordToggle = (field: AuthFormField<T>) => {
    if (showPasswords.includes(field.name)) {
      setShowPasswords(showPasswords.filter((name) => !field.name));
    } else {
      setShowPasswords([...showPasswords, field.name]);
    }
  };

  // Sometimes Axios returns a cryptic "Network Error" without any details. It looks like this
  // mostly happens when the mfaToken has expired so I think it's fine to redirect to the login.
  useEffect(() => {
    if (globalError === 'Network Error') {
      navigate(routes.login, {
        state: {
          error: 'Network error occured or your session expired. Please try again.',
        },
      });
    }
  }, [globalError, navigate]);

  useEffect(() => {
    if (active) {
      onChange?.(valuesMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, valuesMap]);

  useLayoutEffect(() => {
    // The fields can be auto prefilled by the browser but due to security concerns, that prefill
    // does not trigger the usual onChange event. In our case that would make that the component
    // think the fields are empty and the submit button would stay disabled. The following is a
    // workaround that lets us to enable the submit if such autofill has happened.
    const timeout = setTimeout(() => {
      setFieldsAreAutofilled(Array.from(document.querySelectorAll('#auth-form input')).every(
        (el) => el.matches('*:-webkit-autofill'),
      ));
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (active && (Object.keys(fieldsError || {}).length || globalError)) {
      // Reset all fields if the resetFieldsOnError is `true`
      if (resetFieldsOnError === true) {
        setValuesMap(defaultValues);
      }

      // Reset only the fields that are explicitly defined in `resetFieldsOnError`
      if (Array.isArray(resetFieldsOnError)) {
        setValuesMap((oldValues) => {
          resetFieldsOnError.forEach((name) => {
            if (Object.prototype.hasOwnProperty.call(defaultValues, name)) {
              oldValues[name] = defaultValues[name];
            }
          });
          return oldValues;
        });
      }

      const autofocusField = flattenFields.find((field) => field.autofocus);
      if (autofocusField) {
        const el: HTMLInputElement | null = document.querySelector(`[name=${autofocusField.name}]`);
        el?.focus();
      }
    }
  }, [active, defaultValues, flattenFields, fieldsError, globalError, resetFieldsOnError]);

  return (
    <form
      className={classes.root}
      id="auth-form"
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(valuesMap);
      }}>

      {globalError && <SectionMessage level="error" title={globalError} />}

      <div className={cx(classes.fieldsAndSubmit, { contained })}>
        {fields.map((singleOrMultiField, idx) => {
          const renderSingleField = (field: AuthFormField<T>) => (
            <>
              <div className={classes.inputBlock}>
                {field.type === 'checkbox' ? (
                  <div
                    className={classes.checkboxRow}
                    onClick={() => updateValue(field.name, valuesMap[field.name] ? '' : 'true')}
                    onKeyUp={(event) => {
                      if (isUnmodifiedSpaceKey(event)) {
                        updateValue(field.name, valuesMap[field.name] ? '' : 'true');
                      }
                    }}
                    role="button"
                    tabIndex={0}>
                    {/* The extra div is necessary because w/o it, the checkbox gets squezeed */}
                    <div>
                      <CheckBox
                        checked={valuesMap[field.name] === 'true'}
                        dataLocator={field.dataLocator}
                        disabled={field.disabled}
                        onChange={(checked) => updateValue(field.name, checked ? 'true' : '')}
                      />
                    </div>
                    {field.label}
                  </div>
                ) : (
                  <>
                    {(field.label || field.helper) && (
                      <Flex justifyContent="space-between">
                        <Form.Label>{field.label}</Form.Label>
                        {field.helper}
                      </Flex>
                    )}
                    <Tooltip
                      arrow={false}
                      placement="top-end"
                      title={field.disabled && field.disabledReason}>
                      <span>
                        <TextInput
                          asBlock={field.asBlock}
                          autoFocus={field.autofocus}
                          dataLocator={field.dataLocator}
                          dataPrivate={field.type === 'password'}
                          disabled={field.disabled}
                          endAdornment={field.type === 'password' && (
                            <div className={classes.togglePasswordButton}>
                              <ActionButton
                                compact
                                isoPadding
                                kind="minimal"
                                onClick={() => handlePasswordToggle(field)}>
                                {showPasswords.includes(field.name) ? (
                                  <EyeOffIcon maxWidth={16} />
                                ) : (
                                  <EyeOnIcon maxWidth={16} />
                                )}
                              </ActionButton>
                            </div>
                          )}
                          faultType={fieldsError?.[field.name] ? 'error' : undefined}
                          name={field.name}
                          onChange={(value) => updateValue(field.name, value)}
                          placeholder={field.placeholder}
                          startAdornment={field.startAdornment}
                          type={
                            field.type && !showPasswords.includes(field.name) ? field.type : 'text'
                          }
                          value={valuesMap[field.name]}
                        />
                      </span>
                    </Tooltip>
                  </>
                )}
                <AutoCollapsingMessage level="error" message={fieldsError?.[field.name] || ''} />
              </div>
              {passwordField?.name === field.name && passwordField.strength && active && (
                <PasswordStrength
                  onValidation={(isValid) => {
                    setIsPasswordStrong(isValid);
                  }}
                  password={valuesMap[passwordField.name]}
                />
              )}
            </>
          );

          const key = Array.isArray(singleOrMultiField) ?
            singleOrMultiField.map((item) => item.name).join(',') : singleOrMultiField.name;

          return (
            <React.Fragment key={key}>
              {Array.isArray(singleOrMultiField) ? (
                <div className={classes.multiColumnRow}>
                  {singleOrMultiField.map((field) => (
                    <React.Fragment key={field.name}>{renderSingleField(field)}</React.Fragment>))}
                </div>
              ) :
                renderSingleField(singleOrMultiField)}
            </React.Fragment>
          );
        })}

        <ActionButton
          disabled={
            submit.disabled ||
            (fieldsError && Object.keys(fieldsError).length > 0 && !resetFieldsOnError) ||
            (hasEmptyField && !fieldsAreAutofilled) ||
            (hasEmptyField && fieldsAreAutofilled && active) ||
            (hasInvalidFields) ||
            (passwordField?.strength && !isPasswordStrong)
          }
          kind="primary"
          showSpinner={submit.showSpinner}
          type="submit">
          {submit.label}
        </ActionButton>
      </div>
    </form>
  );
};

export default AuthForm;
