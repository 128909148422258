// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../../../SimulationParamDescriptor';
import { FaultType, SelectOption } from '../../../lib/componentTypes/form';
import { colors } from '../../../lib/designSystem';
import { GENERAL_SETTINGS_NODE_ID } from '../../../lib/simulationTree/node';
import { useNodePanel } from '../../../lib/useNodePanel';
import { useGeneral } from '../../../model/hooks/useGeneral';
import { useSolutionOutput } from '../../../model/hooks/useSolutionOutput';
import { useTime } from '../../../model/hooks/useTime';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { useIsStarterPlan } from '../../../recoil/useAccountInfo';
import { useSimulationParamScope } from '../../../state/external/project/simulation/paramScope';
import { useIsStaff } from '../../../state/external/user/frontendRole';
import { DataSelect } from '../../Form/DataSelect';
import { TextInput } from '../../Form/TextInput';
import { AutoCollapsiblePanel } from '../../Panel/AutoCollapsiblePanel';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import { CollapsiblePanel } from '../../Panel/CollapsiblePanel';
import { ParamForm } from '../../ParamForm';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { LuminaryToggleSwitch } from '../../controls/LuminaryToggleSwitch';
import PropertiesSection from '../PropertiesSection';

import { CloudSettings } from './shared/CloudSettings';

export const REMOVE_GRAVITY_FORM_PARAMS = ['FlowBehavior', 'Gravity', 'FloatType'];
export const REMOVE_FLOAT_TYPE_FORM_PARAMS = ['FlowBehavior', 'Gravity', 'Acceleration'];

const solverParamsDesc = paramDesc[ParamName.SolverParams];

export const GeneralSettingsPropPanel = () => {
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // Recoil
  const isStaff = useIsStaff();
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // State
  const [solverParamsFaultType, setSolverParamsFaultType] = useState<FaultType | undefined>();

  // Hooks
  const {
    flowBehavior,
    setFlowBehavior,
    flowBehaviorTypes,
    gravityOn,
    setGravityOn,
    general,
    setGeneral,
    solverParams,
    setSolverParams,
  } = useGeneral(projectId, workflowId, jobId, readOnly);
  const {
    solutionOutput,
    setSolutionOutput,
  } = useSolutionOutput(projectId, workflowId, jobId, readOnly);
  const { time, setTime } = useTime(projectId, workflowId, jobId, readOnly);
  const propClasses = useCommonTreePropsStyles();
  const isStarterPlan = useIsStarterPlan();

  const gravityPanel = useNodePanel(
    GENERAL_SETTINGS_NODE_ID,
    'gravity',
    { defaultExpanded: gravityOn },
  );

  const flowBehaviorOptions: SelectOption<simulationpb.FlowBehavior>[] = flowBehaviorTypes.map(
    (choice) => {
      const isTransientOptionDisabled = (
        isStarterPlan &&
        choice.enumNumber === simulationpb.FlowBehavior.TRANSIENT
      );

      return ({
        value: choice.enumNumber,
        name: choice.text,
        tooltip: choice.help,
        selected: choice.enumNumber === flowBehavior,
        disabledReason: isTransientOptionDisabled ?
          'Cannot run Transient simulations on the Starter Plan' :
          '',
        disabled: isTransientOptionDisabled,
      });
    },
  );

  const handleSolverParams = async (value: string) => {
    setSolverParamsFaultType(undefined);
    if (value) {
      try {
        JSON.parse(value);
        await setSolverParams(value);
      } catch (error) {
        setSolverParamsFaultType('error');
      }
    } else {
      await setSolverParams('');
    }
  };

  const isTransientSimulation = flowBehavior === simulationpb.FlowBehavior.TRANSIENT;

  return (
    <>
      <div className={propClasses.properties}>
        <PropertiesSection>
          <CollapsibleNodePanel
            collapsible={isTransientSimulation}
            displayChevron={isTransientSimulation}
            headerAsPanelRow
            headerRight={(
              <DataSelect
                asBlock
                disabled={readOnly}
                onChange={setFlowBehavior}
                options={flowBehaviorOptions}
                size="small"
              />
            )}
            heading="Time"
            nodeId={GENERAL_SETTINGS_NODE_ID}
            panelName="Time"
            panelOptions={{ forceExpanded: isTransientSimulation }}>
            <ParamForm<simulationpb.Time>
              group={paramGroupDesc[ParamGroupName.Time]}
              onUpdate={setTime}
              paramScope={paramScope}
              proto={time}
              readOnly={readOnly}
            />
          </CollapsibleNodePanel>
        </PropertiesSection>
        <Divider />
        <PropertiesSection>
          <CollapsiblePanel
            collapsed={gravityPanel.collapsed || !gravityOn}
            disabled={!gravityOn}
            headerAsPanelRow
            headerRight={(
              <LuminaryToggleSwitch
                disabled={readOnly}
                onChange={async (on: boolean) => {
                  await setGravityOn(on);
                  gravityPanel.setExpanded(on);
                }}
                small
                value={gravityOn}
              />
            )}
            heading="Gravity"
            onToggle={gravityPanel.toggle}>
            <ParamForm<simulationpb.General>
              group={paramGroupDesc[ParamGroupName.General]}
              onUpdate={setGeneral}
              paramScope={paramScope}
              proto={general}
              readOnly={readOnly}
              removeParams={REMOVE_GRAVITY_FORM_PARAMS}
            />
          </CollapsiblePanel>
        </PropertiesSection>
        <Divider />
        <PropertiesSection>
          <ParamForm<simulationpb.Output>
            group={paramGroupDesc[ParamGroupName.Output]}
            onUpdate={setSolutionOutput}
            paramScope={paramScope}
            proto={solutionOutput}
            readOnly={readOnly}
          />
        </PropertiesSection>
        {isStaff && (
          <>
            <Divider />
            <PropertiesSection>
              <CloudSettings />
            </PropertiesSection>
          </>
        )}
      </div>
      {isStaff && (
        <PropertiesSection>
          <AutoCollapsiblePanel
            heading="Advanced Solver Parameters"
            headingIcon={{
              name: 'warning',
              color: colors.yellow500,
              tooltip: solverParamsDesc.help,
            }}
            initialCollapsed>
            <TextInput
              asBlock
              dataPrivate
              disabled={readOnly}
              faultType={solverParamsFaultType}
              multiline
              name="advanced solver params"
              onCommit={handleSolverParams}
              placeholder="Solver parameters JSON (only visible to staff)"
              resize="vertical"
              rows={20}
              value={solverParams}
            />
          </AutoCollapsiblePanel>
        </PropertiesSection>

      )}
    </>
  );
};
