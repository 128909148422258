// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { RectClipPath } from '@visx/clip-path';
import { curveLinear } from '@visx/curve';
import { localPoint } from '@visx/event';
import { scaleLinear, scaleLog } from '@visx/scale';
import { Circle, LinePath } from '@visx/shape';
import { defaultStyles, useTooltip } from '@visx/tooltip';
import cx from 'classnames';
import * as d3 from 'd3';
import { NumberValue, ScaleLinear, ScaleLogarithmic } from 'd3-scale';

import { AxisLabels } from '../../lib/componentTypes/plot';
import { colors } from '../../lib/designSystem';
import { formatNumber } from '../../lib/number';
import { sortPositions } from '../../lib/plot';
import * as plotpb from '../../proto/plots/plots_pb';
import { ActionButton } from '../Button/ActionButton';
import { createStyles, makeStyles } from '../Theme';
import TimeBar, { TimeBarSettings, pickNearestStep, pickNearestStep2D } from '../TimeBar';

import { ChartAxes } from './ChartAxes';
import { TooltipWithBounds } from './TooltipWithBounds';
import { ViewSize, ZoomControl, ZoomState, newZoomedXLinearScale } from './ZoomControl';
import { BOTTOM_MARGIN, LEFT_MARGIN, RIGHT_MARGIN, SNAP_DISTANCE, Scale, TOP_MARGIN } from './constants';

// Interface holding the name and color of a data line.
export interface DataConfig {
  name: string, // human-readable name.
  color: string, // the line-segment color.
  key?: string, // a unique key.
}

// The payload of the <Tooltip> element.
interface TooltipData {
  // The current position, time step, or iteration.
  position: number,
  // A label describing the current position, time step, or iteration.
  positionLabel: string,
  // A list of the y-values for a particular x-value.
  values: number[], // the data values.
  // The maximum y-value.
  maxValue: number,
  // optional data config that cane be used in tooltip formatting
  dataConfigPerItem?: DataConfig[],
  hoveredIndexes: { datasetIndex: number; xPosition: number; color: string; }[],
}

const useTooltipStyles = makeStyles(
  () => createStyles({
    tooltipRoot: {
      display: 'grid',
      gridTemplateColumns: 'max-content auto max-content',
      justifyItems: 'start',
      alignItems: 'start',
      gap: '8px',
      padding: '8px 0',
      marginTop: '4px',
    },
    tooltipHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      justifyContent: 'space-between',
    },
    tooltipLabel: {
      fontWeight: 600,
    },
    tooltipButton: {
      visibility: 'hidden',

      '&.visible': {
        visibility: 'visible',
      },
    },
  }),
  { name: 'OutputChartTooltip' },
);

interface LoadDatasetButtonOptions {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

// Create the elements to display in the tooltip. For each line in the graph,
// display the name of the quantity, the color of the line, and the value at
// the tooltip location.
// If an onClick handler is provided, then an additional "Load Dataset" button
// is displayed on the tooltip that calls the provided callback function on click
function formatTooltip(
  tooltipData: TooltipData,
  dataToPlot: DataConfig[],
  classes: any,
  loadDatasetButton?: LoadDatasetButtonOptions,
) {
  const dataSource = tooltipData.dataConfigPerItem || dataToPlot;

  const listedValues: ReactElement[] = [];
  dataSource.forEach((config: DataConfig, index: number) => {
    const { key, name, color } = config;
    const value = tooltipData.values[index];
    if (value === undefined || Number.isNaN(value)) {
      return;
    }
    const valueStr = formatNumber(value);
    const tooltipValue = (
      <React.Fragment key={key}>
        <svg
          height={16}
          width={12}>
          <line
            stroke={color}
            strokeWidth="2px"
            x1={0}
            x2={12}
            y1={7}
            y2={7}
          />
        </svg>
        <span>
          {name}
        </span>
        <span className="tabularNumber">
          {valueStr}
        </span>
      </React.Fragment>
    );
    listedValues.push(tooltipValue);
  });

  return (
    <div>
      <div className={classes.tooltipHeader}>
        <div className={classes.tooltipLabel}>{tooltipData.positionLabel}</div>
        <div className={cx(classes.tooltipButton, loadDatasetButton && 'visible')}>
          <ActionButton
            disabled={loadDatasetButton?.disabled}
            kind="secondary"
            onClick={loadDatasetButton?.onClick}
            size="small">Load Dataset
          </ActionButton>
        </div>
      </div>
      <div className={classes.tooltipRoot}>{listedValues}</div>
    </div>
  );
}

// Handles a mouse move event.
// Picks the data point closest to the mouse pointer and calls showTooltip.
const handleMouseMove = (
  ev: React.MouseEvent<HTMLDivElement>,
  data: number[][],
  positions: number[][],
  posScale: ScaleLinear<number, number>,
  showDelayedTooltip: (data: {
    tooltipLeft: number,
    tooltipData: TooltipData,
  }) => void,
  hideTooltip: () => void,
  snapPositions?: number[],
  getPositionLabel?: (position: number) => string,
  dataOnY?: boolean,
  type: 'scatter' | 'line' = 'line',
  originalDataConfig: DataConfig[] = [],
) => {
  // Snap to the nearest full solution if we are within this distance of it in
  // pixels.
  const posScreen = dataOnY ? (localPoint(ev)?.x || 0) : (localPoint(ev)?.y || 0);

  const pos = posScale.invert(posScreen);
  let posSnapped = Math.round(pos);

  if (type === 'scatter') {
    // a scatter chart is not continuous
    // we need to find the closest point and display a tooltip for it

    const closestValuesPerDataset = positions.flatMap((dataset, datasetIndex) => {
      const closestPoint = pickNearestStep(dataset, pos);
      const closestPointValue = closestPoint.value;

      if (closestPointValue === undefined) {
        return [];
      }

      const distance = Math.abs(pos - closestPointValue);
      const leftPosition = posScale(closestPointValue);

      return [{
        value: data[datasetIndex][closestPoint.index],
        closestPoint,
        distance,
        datasetIndex,
        leftPosition,
      }];
    });

    if (closestValuesPerDataset.length === 0) {
      hideTooltip();
      return;
    }

    const closestValue = closestValuesPerDataset.reduce(
      (result, item) => (item.distance < result.distance ? item : result),
    );

    const leftPosition = closestValue.closestPoint.value!;
    const scaledLeftPosition = posScale(leftPosition);

    const dataConfigPerItem: DataConfig[] = [];
    const hoveredIndexes: TooltipData['hoveredIndexes'] = [];

    const closestPositions = positions.flatMap((dataset, datasetIndex) => {
      // we don't need to repeat the same values in here
      const includedValues = new Set<number>();

      const availableItems = dataset.flatMap((xPosition, index) => {
        const MAX_SNAP_DISTANCE = 0.0001;
        const distance = Math.abs(xPosition - leftPosition);

        if (distance > MAX_SNAP_DISTANCE) {
          return [];
        }

        const itemValue = data[datasetIndex][index];

        if (includedValues.has(itemValue)) {
          return [];
        }

        hoveredIndexes.push({
          datasetIndex,
          xPosition,
          color: originalDataConfig[datasetIndex].color,
        });
        includedValues.add(itemValue);

        return itemValue;
      });

      dataConfigPerItem.push(...availableItems.map((value) => ({
        ...originalDataConfig[datasetIndex],
        key: `${originalDataConfig[datasetIndex].key}${value}`,
      })));

      return availableItems;
    });

    showDelayedTooltip({
      tooltipLeft: scaledLeftPosition,
      tooltipData: {
        position: leftPosition,
        positionLabel: getPositionLabel?.(leftPosition) || '',
        maxValue: closestValue.value,
        values: closestPositions,
        dataConfigPerItem,
        hoveredIndexes,
      },
    });

    return;
  }

  // We may have a set of snap positions. Snap to the snap position if we are within the snap
  // distance. Otherwise, snap to the normal positions.
  const snapNearest = snapPositions ? pickNearestStep2D([snapPositions], pos) : { index: -1 };

  if (snapNearest.value && Math.abs(posScale(snapNearest.value) - posScreen) < SNAP_DISTANCE) {
    posSnapped = snapNearest.value;
  } else {
    const normalNearest = pickNearestStep2D(positions, pos);
    if (normalNearest.value !== undefined) {
      posSnapped = normalNearest.value;
    }
  }

  const valuesAtPos = positions.map((posArray, lineIndex) => {
    const dataArray = data[lineIndex];
    // Find the index of the position immediately above posSnapped.
    const index = d3.bisectLeft(posArray, posSnapped);
    if (index === 0 && posArray[0] === posSnapped) {
      return dataArray[0];
    }
    if (index <= 0 || index > posArray.length) {
      return Number.NaN;
    }
    // Linearly interpolate between the previous and the next value.
    const parameter = (
      posSnapped - posArray[index - 1]) / (posArray[index] - posArray[index - 1]
    );
    return parameter * dataArray[index] + (1 - parameter) * dataArray[index - 1];
  });

  const maxValue = Math.max(...valuesAtPos.filter((value) => !Number.isNaN(value)));

  showDelayedTooltip({
    tooltipLeft: posScale(posSnapped),
    tooltipData: {
      position: posSnapped,
      positionLabel: getPositionLabel?.(posSnapped) || '',
      values: valuesAtPos,
      maxValue,
      // lines are displayed only for PV, skipping computing indexes here
      hoveredIndexes: [],
    },
  });
};

interface OutputChartProps {
  // If true, the data is shown on the y-axis, the positions on x-axis.
  // If false, the data is shown on the x-axis, the positions on y-axis.
  dataOnY: boolean;
  // data array containing the y-values (second index) for the lines to plot (first index)
  data: number[][];
  // array containing the positions. (x-values, if dataOnY true). This could represent iterations,
  // time, or spatial positions.
  positions: number[][];
  // Function takes in a position and returns a string describing it.
  getPositionLabel?: (x: number) => string;
  // The chart height in pixels.
  height: number;
  // Scale of the y axis (linear or logarithmic).
  yScale: Scale;
  // config for the individual lines to plot.
  dataConfig: DataConfig[];
  // The settings for the time bar or null for no time bar.
  timeBarSettings: TimeBarSettings | null;
  // True if the plot includes inner loop residuals. Typically makes a "sawtooth" pattern
  sawtooth: boolean;
  // Whether this chart is an XY chart or not
  xyChart: boolean;
  // The labels for the X and Y axis
  axisLabels?: AxisLabels;
  // The plot settings contains ranges for the X and Y axes.
  xyPlot?: plotpb.PlotSettings_XYPlot;
  type?: 'line' | 'scatter';
  // callback executed when the user hovers over the XY plot with LCVis enabled
  onScatterHover?: (hoveredIndexes: TooltipData['hoveredIndexes'])=> void;
}

const OutputChart = (props: OutputChartProps) => {
  const {
    getPositionLabel,
    height,
    timeBarSettings,
    sawtooth,
    xyChart,
    axisLabels,
    dataOnY,
    xyPlot,
    type = 'line',
    onScatterHover = () => {},
  } = props;

  const bottomMargin = xyChart ? 40 : BOTTOM_MARGIN;
  const topMargin = xyChart ? 0 : TOP_MARGIN;
  const snapPositions = (!xyChart && !sawtooth) ? timeBarSettings?.iters : undefined;

  const {
    hideTooltip,
    showTooltip,
    tooltipData,
  } = useTooltip<TooltipData>();
  // true if the user has clicked on the chart and the tooltip is pinned to where the user clicked
  const [tooltipPinned, setTooltipPinned] = useState<boolean>(false);
  // the index of a viewable iteration step of the pinned tooltip
  // the pinned index is -1 when the click position is too far away from a viewable iteration step
  const [tooltipPinnedIndex, setTooltipPinnedIndex] = useState<number>(-1);
  // update the corresponding states to represent no tooltip being pinned
  const unpinTooltip = useCallback(() => {
    setTooltipPinned(false);
    setTooltipPinnedIndex(-1);
  }, [setTooltipPinned, setTooltipPinnedIndex]);
  // true when the tooltip is pinned and the user is hovering over the tooltip
  const [hoverPinned, setHoverPinned] = useState(false);

  // Hide the tooltip when the data changes (since data in the tooltip is only updated on mouse
  // movement it can happen that the tooltip is out of sync).
  useEffect(() => {
    hideTooltip();
    unpinTooltip();
  }, [hideTooltip, unpinTooltip, props.data]);

  const updateHoveredIndexes = useCallback((indexes: TooltipData['hoveredIndexes']) => {
    if (type === 'scatter') {
      onScatterHover(indexes);
    }
  }, [onScatterHover, type]);

  const tooltipClasses = useTooltipStyles();
  const graphElement = React.useRef<SVGSVGElement>(null);

  // Any positions are allowed in props.position. The positions on the curve could go backwards.
  // Split the single curve into curve segments where the positions are sorted and going forward.
  const dataConfig: DataConfig[] = [];
  const positions: number[][] = [];
  const data: number[][] = [];

  // It is possible for the data and positions arrays to be very briefly out of sync
  // when a new output is selected but the analyzer reply has not yet been received.
  // We check the length of the arrays here to avoid indexing errors in that case.
  if (props.data.length === props.positions.length) {
    if (type === 'line') {
      props.positions.forEach((posArray, lineIndex) => {
        const sorted = sortPositions(posArray, props.data[lineIndex]);
        data.push(...sorted.data);
        positions.push(...sorted.positions);
        for (let i = 0; i < sorted.positions.length; i += 1) {
          const newConfig = { ...props.dataConfig[lineIndex] };
          newConfig.key = `${newConfig.name}-${i}`;
          dataConfig.push(newConfig);
        }
      });
    } else if (type === 'scatter') {
      dataConfig.push(...props.dataConfig);
      props.positions.forEach((posArray, lineIndex) => {
        const combinedData = posArray.map((position, positionIndex) => ({
          position,
          data: props.data[lineIndex][positionIndex],
        })).sort((a, b) => a.position - b.position);

        data.push(combinedData.map((item) => item.data));
        positions.push(combinedData.map((item) => item.position));
      });
    }
  }

  // Find the minimum and maximum position for all the curves.
  const minPosArray = positions.reduce((prevMin, posArray) => (
    Math.min(prevMin, ...posArray)
  ), Infinity);
  const maxPosArray = positions.reduce((prevMax, posArray) => (
    Math.max(prevMax, ...posArray)
  ), -Infinity);
  const minPos = Math.max(minPosArray, xyPlot?.xAxisRange?.rangeStart ?? -Infinity);
  const maxPos = Math.min(maxPosArray, xyPlot?.xAxisRange?.rangeEnd ?? Infinity);

  // The iteration that is currently being hovered over.
  const [hoverIter, setHoverIter] = useState<number>(-1);
  const [hoveredValues, setHoveredValues] = useState<number[]>([]);

  // The information for a timeout that will show the tooltip. Saves the
  // timeout ID and the tooltip position.
  const tooltipTimeout = useRef<{ timeout: number, position: number }>(
    { timeout: -1, position: -1 },
  );

  // Show the tooltip after a brief delay. Cancel the tooltip, if the user
  // moves to a different iteration during this delay.
  const showDelayedTooltip = (delayedData: {
    tooltipLeft: number,
    tooltipData: TooltipData,
  }) => {
    const prevIter = tooltipTimeout.current.position;
    const newIter = delayedData.tooltipData.position;
    // Set the hovered information immediately if the tooltip is not pinned
    if (!tooltipPinned) {
      setHoverIter(newIter);
      setHoveredValues(delayedData.tooltipData.values);

      updateHoveredIndexes(delayedData.tooltipData.hoveredIndexes ?? []);
    }
    // Hide tooltip when we are out of bounds
    if (newIter < minPos || newIter > maxPos) {
      hideTooltip();
      unpinTooltip();
      return;
    }
    if (!tooltipPinned) {
      // If a tooltip is not pinned and already shown, set it to a new value.
      // Otherwise, show it after a delay.
      if (tooltipData) {
        showTooltip(delayedData);
      } else if (newIter !== prevIter) {
        if (prevIter !== -1) {
          hideTooltip();
          clearTimeout(tooltipTimeout.current.timeout);
        }
        const timeout = setTimeout(() => {
          showTooltip(delayedData);
        }, 300);
        tooltipTimeout.current = { timeout: (timeout as any), position: newIter };
      }
    }
  };

  const yMin = topMargin;
  const yMax = Math.max(height - bottomMargin, topMargin);

  // disable zoom when the user is hovering over the pinned tooltip to prevent the chart
  // from moving as the user is selecting text in the tooltip
  return (
    <ZoomControl disabled={sawtooth} disabledDrag={hoverPinned} height={height}>
      {(size: ViewSize, zoom: ZoomState) => {
        const xMin = LEFT_MARGIN;
        const xMax = Math.max(LEFT_MARGIN, size.width);
        const xRange = [xMin, xMax - RIGHT_MARGIN];
        const yRange = [yMax, yMin];
        const positionRange = dataOnY ? xRange : yRange;
        const dataScreen = dataOnY ? yRange : xRange;
        const positionScale = newZoomedXLinearScale(
          zoom,
          minPos,
          maxPos,
          positionRange,
          false,
        );
        // Compute the visible Y range of the lines to plot for the given X axis limits.
        // The latter limits can change due to the zoom functionality and when new iteration
        // data is available.
        let maxValue = Number.NEGATIVE_INFINITY;
        let minValue = Number.POSITIVE_INFINITY;
        data?.forEach((dataArray: number[], lineIndex: number) => {
          const posArray = positions[lineIndex];
          dataArray?.forEach((value: number, arrayIndex: number) => {
            const position = posArray[arrayIndex];
            if (position < positionScale.domain()[0] || position > positionScale.domain()[1]) {
              return;
            }
            if (value <= 0.0 && (props.yScale === Scale.LOG)) {
              return;
            }
            if (value > maxValue) {
              maxValue = value;
            }
            if (value < minValue) {
              minValue = value;
            }
          });
        });

        // Build the yScale.
        let dataScale: ScaleLogarithmic<number, number> | ScaleLinear<number, number>;
        // TODO(saito) Wrap yScale to handle zero Y value more gracefully.
        switch (props.yScale) {
          case Scale.LOG:
            dataScale = scaleLog<number>({
              domain: [minValue / 1.2, maxValue * 1.2],
              range: dataScreen,
              nice: true,
            });
            break;
          case Scale.LINEAR:
          default:
            dataScale = scaleLinear<number>({
              domain: [minValue, maxValue],
              range: dataScreen,
              nice: true,
            });
            break;
        }
        const xScale = dataOnY ? positionScale : dataScale;
        const yScale = dataOnY ? dataScale : positionScale;

        // Only show the hover line when we are hovered over a full solution.
        // And don't show on sawtooth plot.
        const showHoverLine = (
          !sawtooth && props.timeBarSettings?.iters.includes(hoverIter)
        );

        const hoverCircles: ReactElement[] = [];
        let posHover = 0;
        if (hoverIter >= 0 || xyChart) {
          posHover = dataOnY ? xScale(hoverIter) : yScale(hoverIter);
        }
        if (hoverIter >= 0 || xyChart) {
          const adjustedConfig = tooltipData?.dataConfigPerItem || dataConfig;

          adjustedConfig?.forEach((config: DataConfig, index: number) => {
            if (Number.isFinite(hoveredValues[index])) {
              hoverCircles.push(
                <circle
                  cx={dataOnY ? (posHover) : (xScale(hoveredValues[index]))}
                  cy={dataOnY ? (yScale(hoveredValues[index]) + 1) : posHover}
                  fill={config.color}
                  fillOpacity={1}
                  key={config.key}
                  r={4}
                  stroke="white"
                  strokeWidth={1}
                />,
              );
            }
          });
        }

        let xLine = 0;
        let timeBar: ReactElement | null = null;
        // Don't show marker on sawtooth plot.
        let markerOpacity = sawtooth ? 0 : 1;
        if (timeBarSettings && !sawtooth && !xyChart) {
          xLine = xScale(timeBarSettings.iters[timeBarSettings.currentIndex]);
          const currentIter = timeBarSettings.iters[timeBarSettings.currentIndex];
          markerOpacity = hoverIter < 0 || hoverIter === currentIter ? 1 : 0.5;
          timeBar = (
            <div
              id="TimeBar"
              style={{ position: 'absolute', width: '100%', zIndex: 3 }}>
              <TimeBar
                height={topMargin}
                markerOpacity={markerOpacity}
                settings={timeBarSettings}
                xScale={xScale}
              />
            </div>
          );
        }

        const getPinnableTooltipIndex = (xScreen: number) => {
          if (!timeBarSettings) {
            return -1;
          }

          const step = xScale.invert(xScreen);
          const nearest = pickNearestStep(timeBarSettings.iters, step);

          if (nearest.value !== undefined) {
            if (Math.abs(xScale(nearest.value) - xScreen) < SNAP_DISTANCE) {
              setTooltipPinnedIndex(nearest.index);
              return nearest.index;
            }
          }

          return -1;
        };

        const onClick = (ev: React.MouseEvent<HTMLDivElement>) => {
          if (tooltipPinned) {
            unpinTooltip();
            return;
          }
          if (timeBarSettings && !sawtooth) {
            setTooltipPinned(true);
            const xScreen = localPoint(ev)?.x || 0;
            const pinnableIndex = getPinnableTooltipIndex(xScreen);

            setTooltipPinnedIndex(pinnableIndex);
          }
        };
        // Functions for formatting the ticks labels.
        const formatPosition = (i: NumberValue) => {
          if (xyChart) {
            return `${formatNumber(Number(i), { maxLength: 5 })}`;
          }
          if (sawtooth) {
            return `${Math.floor(Number(i))}`;
          }
          return `${i}`;
        };
        const formatData = (numValue: NumberValue) => (
          formatNumber(numValue as number, { maxLength: 4 })
        );
        const tickFormatX = dataOnY ? formatPosition : formatData;
        const tickFormatY = dataOnY ? formatData : formatPosition;

        return (
          <div
            onClick={onClick}
            onKeyUp={() => { }}
            onMouseLeave={() => {
              if (!tooltipPinned) {
                hideTooltip();
                setHoverIter(-1);
                updateHoveredIndexes([]);
                if (tooltipTimeout.current.position !== -1) {
                  clearTimeout(tooltipTimeout.current.timeout);
                }
                tooltipTimeout.current = { position: -1, timeout: -1 };
                unpinTooltip();
              }
            }}
            onMouseMove={(ev) => {
              timeBarSettings &&
                handleMouseMove(
                  ev,
                  data,
                  positions,
                  positionScale,
                  showDelayedTooltip,
                  hideTooltip,
                  snapPositions,
                  getPositionLabel,
                  dataOnY,
                  type,
                  dataConfig,
                );
            }}
            role="button"
            tabIndex={-1}>
            {timeBar}
            <svg
              height={height}
              ref={graphElement}
              style={{ outline: 'none' }}
              tabIndex={-1}
              width={size.width}>
              <RectClipPath
                height={yMax - yMin}
                id="chart-area"
                width={xMax - xMin}
                x={xMin}
                y={yMin}
              />

              <rect
                fill={colors.surfaceDark2}
                height={yMax - yMin + 50}
                width={xMax - xMin + 100}
                x={xyChart ? 0 : xMin}
                y={xyChart ? 0 : yMin}
              />
              <line
                stroke={colors.surfaceBackground}
                strokeWidth="1px"
                x1={xMin}
                x2={xMin}
                y1={yMin}
                y2={0}
              />
              <g clipPath="url(#chart-area)">
                {
                  data.map((res: number[], lineIndex: number) => {
                    const posArray = positions.at(lineIndex);
                    const config = dataConfig.at(lineIndex);

                    const getData = (numValue: number) => dataScale(numValue);

                    const getPosition = (_numValue: number, arrayIndex: number) => (
                      positionScale(posArray?.at(arrayIndex) ?? 0)
                    );

                    const getX = dataOnY ? getPosition : getData;
                    const getY = dataOnY ? getData : getPosition;

                    if (type === 'scatter') {
                      return res.map((circle, index) => {
                        const x = getX(circle, index);
                        const y = getY(circle, index);

                        return (
                          <Circle
                            cx={x}
                            cy={y}
                            fill={config?.color}
                            key={`${(config?.key ?? '') + index}`}
                            r={2}
                          />
                        );
                      });
                    }

                    return (
                      <LinePath<number>
                        curve={curveLinear}
                        data={res}
                        defined={(value) => !Number.isNaN(value)}
                        key={config?.key}
                        stroke={config?.color}
                        strokeOpacity={1}
                        strokeWidth={3}
                        x={getX}
                        y={getY}
                      />
                    );
                  })
                }
              </g>
              {!xyChart && (
                <line
                  stroke={colors.surfaceBackground}
                  strokeWidth="1px"
                  x1={xMin}
                  x2={xMax}
                  y1={23}
                  y2={23}
                />
              )}
              {!xyChart && timeBar && !sawtooth && (
                <line
                  key="current-line"
                  opacity={markerOpacity}
                  stroke={colors.citronGreen600}
                  x1={xLine}
                  x2={xLine}
                  y1={yMin}
                  y2={yMax}
                />
              )}
              {!xyChart && showHoverLine && (
                <line
                  key="hover-line"
                  stroke={colors.citronGreen600}
                  x1={posHover}
                  x2={posHover}
                  y1={yMin}
                  y2={yMax}
                />
              )}
              <ChartAxes
                axisLabels={axisLabels}
                bottomMargin={bottomMargin}
                height={height}
                tickFormatX={tickFormatX}
                tickFormatY={tickFormatY}
                width={size.width}
                xScale={xScale}
                xyChart={xyChart}
                yScale={yScale}
              />
              {hoverCircles}
            </svg>
            {
              tooltipData && (xyChart || tooltipData.position > 0) && (
                <TooltipWithBounds
                  left={dataOnY ? xScale(tooltipData.position) : xScale(tooltipData.maxValue)}
                  onClick={tooltipPinned ? (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  } : undefined}
                  onMouseEnter={() => setHoverPinned(tooltipPinned)}
                  onMouseLeave={() => setHoverPinned(false)}
                  style={{
                    ...defaultStyles,
                    background: 'rgba(54, 55, 55, 0.85)',
                    color: '#fff',
                    padding: '10px 15px',
                    // visx's default for pointerEvents is none, must override to click on button
                    pointerEvents: tooltipPinned ? 'auto' : 'none',
                  }}
                  top={dataOnY ? yScale(tooltipData.maxValue) : yScale(tooltipData.position)}>
                  {formatTooltip(
                    tooltipData,
                    dataConfig,
                    tooltipClasses,
                    !xyChart && getPinnableTooltipIndex(xScale(tooltipData.position)) !== -1 ? {
                      onClick: () => {
                        timeBarSettings?.setIndex(tooltipPinnedIndex);
                      },
                      disabled: tooltipPinnedIndex === timeBarSettings?.currentIndex,
                    } : undefined,
                  )}

                </TooltipWithBounds>
              )
            }
          </div>
        );
      }}
    </ZoomControl>
  );
};

export default OutputChart;
