// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getViewFromPath } from '../../../lib/componentTypes/context';
import { geometryIdLink, geometryLink, isInProjectResult, meshLink, outputsLink, physicsLink, projectLink, resultsLink, solverLink } from '../../../lib/navigation';
import { ProjectParams } from '../../../lib/routeParamTypes';
import { TabInfo } from '../../../proto/projectstate/projectstate_pb';
import { useProjectMetadataValue } from '../../../recoil/useProjectMetadata';
import { useTabsState } from '../../../recoil/useTabsState';
import { useLastOpenedResultsTabValue } from '../../../state/external/project/lastOpenedResultsTab';
import { useAllTabWarnings } from '../../../state/external/project/validator';
import { useWorkflowFlagValue } from '../../../workflowFlag';
import PaneSwitcher from '../../Pane/PaneSwitcher';
import { useIsExplorationSetup } from '../../hooks/exploration/useCreateExploration';

const isSelected = (
  tab: TabInfo,
  pathname: string,
  projectId: string,
  geometryId: string,
  isExplorationSetup: boolean,
) => {
  switch (tab.text) {
    case 'Geometry': {
      return pathname === geometryLink(projectId) ||
        pathname === geometryIdLink(projectId, geometryId);
    }
    case 'Setup':
    case 'Simulation': {
      return pathname === projectLink(projectId);
    }
    case 'Results': {
      return pathname === resultsLink(projectId) || isInProjectResult(pathname, projectId);
    }
    case 'Physics':
      return pathname === physicsLink(projectId);
    case 'Mesh':
      return pathname === meshLink(projectId);
    case 'Outputs':
      return pathname === outputsLink(projectId);
    case 'Solver':
      return pathname === solverLink(projectId);
    case 'Advanced Analysis':
      return isExplorationSetup;
    default: {
      return false;
    }
  }
};

export interface ProjectTopLevelLinksSectionProps {
  projectId: string;
}

export const ProjectTopLevelLinksSection = (props: ProjectTopLevelLinksSectionProps) => {
  // Props
  const { projectId } = props;
  const { workflowId = '', jobId = '', geometryId = '' } = useParams<ProjectParams>();

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  // Recoil
  const [tabsState] = useTabsState(projectId);
  const lastOpenedResultsTab = useLastOpenedResultsTabValue(projectId);
  const allWarnings = useAllTabWarnings(projectId, workflowId, jobId);
  const workflowFlag = useWorkflowFlagValue();
  const projectMetadata = useProjectMetadataValue(projectId);
  const isExplorationSetup = useIsExplorationSetup();

  const hasSimulations = (projectMetadata?.workflow.length ?? 0) > 0;

  const isViewComplete = useCallback((link: string, text: string) => {
    if (!workflowFlag) {
      return undefined;
    }
    if (text === 'Results') {
      return hasSimulations;
    }
    const view = getViewFromPath(link);
    const warnings = allWarnings.get(view);
    return !warnings || warnings.size === 0;
  }, [allWarnings, hasSimulations, workflowFlag]);

  // Memoized state
  const nonClosableTabs = useMemo(() => {
    const permanentTabs = tabsState.tabs.filter((tab) => !tab.closable);

    return permanentTabs.map((tab) => ({
      onClick: async () => {
        // If we are opening the Results page for a project, we should go back to the last results
        // tab we were in for that browser session. Otherwise, just fallback to the Results table.
        if (tab.link === resultsLink(projectId) && lastOpenedResultsTab) {
          navigate(lastOpenedResultsTab);
        } else if (tab.link === geometryLink(projectId)) {
          // There have been several bugs related to the entityGroupState not being refreshed when
          // going to the geometry tab. Flush everything by reloading.
          window.location.href = tab.link;
        } else {
          navigate(tab.link);
        }
      },
      selected: isSelected(tab, location.pathname, projectId, geometryId, isExplorationSetup),
      text: tab.text,
      completedPhase: isViewComplete(tab.link, tab.text),
    }));
  }, [
    tabsState.tabs, location.pathname, projectId, geometryId, lastOpenedResultsTab, navigate,
    isViewComplete, isExplorationSetup,
  ]);

  return (
    <div data-locator="project-top-tabs">
      <PaneSwitcher buttons={nonClosableTabs} />
    </div>
  );
};
