// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/*
 Displays the list of projects for a user. This is usually a landing page.
*/
import React, { useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { colors } from '../../lib/designSystem';
import { projectLink } from '../../lib/navigation';
import * as rpc from '../../lib/rpc';
import { SampleProjectCategory, getProjectCategoryLabel } from '../../lib/sampleProjects';
import useFormValidation from '../../lib/useFormValidation';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { analytics } from '../../services/analytics';
import { useSampleProjectsValue } from '../../state/external/project/sampleProjects';
import { ActionLink } from '../Button/ActionLink';
import Form from '../Form';
import { TextInput } from '../Form/TextInput';
import { SampleProjectsMenu } from '../Menu/SampleProjectsMenu';
import PaneSwitcher from '../Pane/PaneSwitcher';
import { createStyles, makeStyles } from '../Theme';
import { AutoCollapsingMessage } from '../visual/AutoCollapsingMessage';

import { Dialog } from './Base';

const useStyles = makeStyles(
  () => createStyles({
    instructions: {
      color: colors.lowEmphasisText,
    },
    sectionTitle: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: '600',
    },
    bodySamples: {
      display: 'flex',
      gap: '48px',
    },
    listContainer: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      minWidth: '292px',
      width: '292px',
    },
    extraPadding: {
      padding: '0 16px',
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
  }),
  { name: 'ProjectDialog' },
);

const BLANK_PROJECT_ID = '__blank_project_id__';
const BLANK_PROJECT_DATA = {
  projectId: BLANK_PROJECT_ID,
  projectDescription: 'Start from a blank template and build the simulation from the ground up.',
  projectName: 'Blank Project',
  iconName: 'paper',
  documentationUrl: '',
  category: SampleProjectCategory.TEMPLATE,
  teaser: 'Upload your own geometry',
  position: 0,
};

interface ProjectDialogProps {
  // Whether the dialog is used to add or edit
  isEdit: boolean,
  // Whether the dialog is open.
  isOpen: boolean,
  // Current project description. Defined iff isEdit===true.
  description?: string
  // Current project name. Defined iff isEdit===true.
  name?: string
  // Cancels the dialog modifications.
  onCancel: () => void,
  // Callback used when the user hits on Apply (for editing) or Create.
  onSubmit: (
    projectId: string,
    name: string,
    description: string,
    isEdit: boolean,
  ) => Promise<void>,
  // Current project id. Defined if isEdit===true.
  projectId?: string,
  // We can pass a sample id if we want to preselect a particular sample from the samples state
  preselectedSampleId?: string,
}

const DEFAULT_CATEGORY = SampleProjectCategory.TEMPLATE;

// Dialog which allows us to set or edit the name and description of a given
// project.
const ProjectDialog = (props: ProjectDialogProps) => {
  // == Props
  const { isOpen, isEdit, preselectedSampleId = '' } = props;

  // == Hooks
  const classes = useStyles();
  const navigate = useNavigate();

  // == State
  const sampleProjects = useSampleProjectsValue();

  const [isComponentMounted, setComponentMounted] = useState(true);

  // == Data
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  // Used to avoid calling multiple onContinue in quick succession.
  const [submitting, setSubmitting] = useState(false);
  const [selectedId, setSelectedId] = useState(preselectedSampleId || BLANK_PROJECT_ID);

  // Display the list of sample projects.
  const sampleProjectsToRender = useMemo(() => [
    BLANK_PROJECT_DATA,
    ...sampleProjects?.sampleProjects.map((sampleProject) => ({
      projectId: sampleProject.projectId,
      projectDescription: sampleProject.projectDescription,
      projectName: sampleProject.projectName,
      ...(
        'iconName' in sampleProject ?
          { iconName: sampleProject.iconName } :
          { imageUrl: sampleProject.imageUrl }
      ),
      documentationUrl: sampleProject.documentationUrl,
      category: sampleProject.category,
      teaser: sampleProject.teaser,
      position: sampleProject.position,
      comingSoon: sampleProject.comingSoon,
    })) || [],
  ], [sampleProjects?.sampleProjects]);

  const projectsById = useMemo(() => Object.fromEntries(
    sampleProjectsToRender.map((item) => [item.projectId, item] as const),
  ), [sampleProjectsToRender]);

  const [selectedCategory, setSelectedCategory] = useState(
    projectsById[selectedId]?.category || DEFAULT_CATEGORY,
  );

  const availableCategories = [
    ...new Set([
      DEFAULT_CATEGORY,
      ...sampleProjectsToRender.map((item) => item.category),
    ]),
  ];

  const { validate, errors, clearErrors } = useFormValidation([{
    key: 'projectName',
    value: name,
    required: true,
  }]);

  const nameChangeTimeout = useRef<NodeJS.Timeout | null>(null);
  // Debounce the name change to avoid sending too many events to MixPanel
  const handleNameChange = (value: string) => {
    setName(value);

    if (nameChangeTimeout.current) {
      clearTimeout(nameChangeTimeout.current);
    }

    nameChangeTimeout.current = setTimeout(() => {
      analytics.track('Project Name Changed', { projectName: value });
    }, 500);
  };

  // Update the selected cell and the name in the dialog.
  const updateSelection = (id: string) => {
    const cell = projectsById[id];
    const isBlankProject = id === BLANK_PROJECT_ID;
    setSelectedId(id);

    if (isBlankProject) {
      setName(props.name ?? '');
      setDescription(props.description ?? '');
      // If we are not editing and just opening a blank project, that will set the name to '',
      // which will trigger the validation and show an error for the name field. We don't want that
      // error for initial openings of the dialog so must clear it after all field updates are done.
      setTimeout(() => clearErrors(), 0);
    } else {
      setName(`${cell.projectName} Sample`);
      setDescription(cell.projectDescription);
    }

    // Track sample selection
    analytics.track('Project Sample Selected', { sampleName: cell?.projectName });
  };

  useEffect(() => {
    if (isOpen) {
      const selectedItemId = preselectedSampleId || BLANK_PROJECT_ID;
      const category = projectsById[selectedItemId]?.category || 'Templates';

      updateSelection(selectedItemId);
      setSelectedCategory(category);

      // Track dialog open
      analytics.track('Project Dialog Opened', { isEdit });
    }
    // We must not include the `updateSelection` in the dependency array because we'll enter in a
    // loop between this useEffect and the `updateSelection`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, preselectedSampleId, clearErrors, isEdit]);

  useEffect(() => {
    setComponentMounted(true);

    // Return cleanup function to handle unmounting
    return () => {
      setComponentMounted(false);
    };
  }, []);

  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{
        label: isEdit ? 'Apply' : 'Create',
        name: 'save-project',
        disabled: !name,
      }}
      controlState={submitting ? 'working' : 'normal'}
      hasCloseButton={false}
      modal
      onClose={() => {
        props.onCancel();
        analytics.track('Project Dialog Cancelled', { isEdit });
      }}
      onContinue={async () => {
        if (validate() && !submitting) {
          setSubmitting(true);

          try {
            if (!isEdit && selectedId !== BLANK_PROJECT_ID && sampleProjects) {
              // A sample is selected. Copy the sample.
              const sampleId = selectedId;

              const req = new frontendpb.CopyProjectRequest({
                params: {
                  sourceId: sampleId,
                },
              });
              const reply = await rpc.callRetry('CopyProject', rpc.client.copyProject, req);

              const newProjectId = reply.createdProject?.id || '';
              // Update the copied project with the name/description the user has provided
              await props.onSubmit(
                newProjectId,
                name.trim(),
                description.trim(),
                true, // isEdit
              );

              // Only navigate if the component is still mounted
              if (isComponentMounted) {
                navigate(projectLink(newProjectId));
              }

              analytics.track('Project Created', {
                projectId: newProjectId,
                projectName: name.trim(),
                sampleUsed: projectsById[sampleId]?.projectName || '',
              });
            } else {
              // Edit or create new blank project
              await props.onSubmit(props.projectId || '', name.trim(), description.trim(), isEdit);

              if (isEdit) {
                analytics.track('Project Edited', {
                  projectId: props.projectId,
                  projectName: name.trim(),
                });
              } else {
                analytics.track('Blank Project Created', {
                  projectName: name.trim(),
                });
              }
            }
          } catch (error: any) {
            if (isComponentMounted) {
              setSubmitting(false);
            }
            analytics.track('Project Creation Error', { error: error.message });
            throw error;
          }

          if (isComponentMounted) {
            setSubmitting(false);
          }
        }
      }}
      open={isOpen}
      width={isEdit ? '600px' : '1100px'}>
      <div className={classes.bodySamples}>
        {!isEdit && (
          <div className={classes.listContainer}>
            <PaneSwitcher
              buttons={availableCategories.map((category) => ({
                text: getProjectCategoryLabel(category),
                selected: category === selectedCategory,
                onClick: () => {
                  setSelectedCategory(category);
                },
              }))}
              withBottomBorder
            />

            <SampleProjectsMenu
              disabled={submitting}
              onSelectedIdChange={updateSelection}
              sampleProjects={sampleProjectsToRender.filter(
                ({ category }) => category === selectedCategory,
              ).sort((a, b) => a.position - b.position)}
              selectedId={selectedId}
            />
          </div>
        )}
        <div className={classes.leftSide}>
          <div
            aria-label={isEdit ? 'Edit Project' : 'New Project'}
            className={cx(classes.sectionTitle, !isEdit && classes.extraPadding)}>
            {isEdit ? 'Edit Project' : 'New Project'}
          </div>
          <div className={cx(classes.inputs, { [classes.extraPadding]: !isEdit })}>
            {!isEdit && (
              <div className={classes.instructions}>
                Start from a template or sample project.
              </div>
            )}
            <div className={classes.input}>
              <Form.Label>Name</Form.Label>
              <TextInput
                autoFocus
                dataPrivate
                disabled={submitting}
                faultType={errors.projectName ? 'error' : undefined}
                name="name"
                onChange={handleNameChange}
                placeholder="Add a project name..."
                value={name}
              />
              <AutoCollapsingMessage level="error" message={errors.projectName} />
            </div>
            <div className={classes.input}>
              <Form.Label>Description</Form.Label>
              <TextInput
                dataPrivate
                disabled={submitting}
                multiline
                name="description"
                onChange={(value) => {
                  setDescription(value);
                  analytics.track('Project Description Changed', {
                    descriptionLength: value.length,
                  });
                }}
                placeholder="Add a project description..."
                rows={4}
                size="small"
                value={description}
              />
            </div>
            {projectsById[selectedId]?.documentationUrl && (
              <div>
                <ActionLink externalIcon href={projectsById[selectedId]?.documentationUrl}>
                  View Tutorial
                </ActionLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ProjectDialog;
