// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import { addClosableTab, replaceTabText, tabLink } from '../../lib/TabManager';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { CellDisplay, ColumnConfig, RowConfig } from '../../lib/componentTypes/table';
import { EMPTY_VALUE } from '../../lib/constants';
import {
  createExplorationVarData,
  createRowData,
  createWorkflowData,
  exportJobTableCallback,
} from '../../lib/jobTableUtils';
import { getJobCredits, getJobRuntime, getJobStatus } from '../../lib/jobUtils';
import { Logger } from '../../lib/observability/logs';
import { useUserCanEdit } from '../../lib/projectRoles';
import { isSimulationTransient } from '../../lib/simulationUtils';
import { addError } from '../../lib/transientNotification';
import { useDeleteWorkflow } from '../../lib/useDeleteWorkflow';
import * as basepb from '../../proto/base/base_pb';
import { JobType } from '../../proto/notification/notification_pb';
import { useGeometryTagsAllWorkflows } from '../../recoil/geometry/geometryTagsState';
import { useJobNameMap } from '../../recoil/jobNameMap';
import { useOutputNodesValue } from '../../recoil/outputNodes';
import { useIsStarterPlan } from '../../recoil/useAccountInfo';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useProgressiveOutputResults } from '../../recoil/useOutputResults';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useTabsState } from '../../recoil/useTabsState';
import { useStaticVolumesAllWorkflows } from '../../recoil/volumes';
import { useWorkflowMap } from '../../recoil/workflowState';
import { useSetLastOpenedResultsTab } from '../../state/external/project/lastOpenedResultsTab';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { useProjectContext } from '../context/ProjectContext';
import { Table } from '../data/Table';
import { generateColumnState, getFormattedValue, getNormalizedValue, getTransformedValue } from '../data/Table/util';

import { suspendWorkflow } from './PauseResumeToggle';

import { useFavoriteEntities } from '@/recoil/useFavoriteEntities';

const logger = new Logger('JobTable');

const OUTPUT_NODE_RESULT_SEPARATOR = '⋮⋮⋮';

// JobTable summarizes jobs in a workflow.  We display them in a table
// currently, but we should switch to a more compact form, e.g., parallel
// coordinate display. This is similar to JobPanel, but JobPanel only displays
// one job.

interface OnEditNameArgs {
  name: string;
  type: JobType;
  workflowId: string;
  jobId: string | undefined;
}

const getFavoriteSimulationKey = (workflowId: string, jobId: string) => `${workflowId}/${jobId}`;

const JobTable = () => {
  const { projectId } = useProjectContext();

  // True if the selected row is currently being renamed.
  const [renaming, setRenaming] = useState<{ jobId?: string; workflowId?: string }>({});

  // The ids of deleted workflows. We need that in order to filter out deleted workflows
  // while the backend delete request is still in progress.
  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  // A local state that temporarily keeps the renamed workflows for DoEs (not for individual jobs).
  // That's needed because there a noticable delay between the rename and the moemnt the
  // useWorkflowMap returns the updated data. And when we rename a DoE name, we want that name
  // to be immediately updated for all other jobs for that DoE.
  const [renamedWorkflows, setRenamedWorkflows] = useState<Record<string, string>>({});

  // Same as above but for canceling workflows
  const [canceledWorkflows, setCanceledWorkflows] = useState<Record<string, boolean>>({});

  const navigate = useNavigate();
  const outputNodes = useOutputNodesValue(projectId, '', '');

  const projectMetadata = useProjectMetadata(projectId);
  const geometryTagsAllWfs = useGeometryTagsAllWorkflows(projectId);
  const staticVolumesAllWfs = useStaticVolumesAllWorkflows(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  const [workflows, workflowIds] = useMemo(() => {
    const workflowList = projectMetadata?.workflow.filter(
      (metadata) => !deletedIds.includes(metadata.workflowId),
    ) || [];
    return [workflowList, workflowList.map((workflow) => workflow.workflowId)];
  }, [projectMetadata, deletedIds]);
  const workflowMap = useWorkflowMap(projectId, workflowIds);
  const jobNameMap = useJobNameMap(projectId);
  const experimentConfig = useEnabledExperiments();
  const [tabsState, setTabsState] = useTabsState(projectId);
  const deleteWorkflow = useDeleteWorkflow(projectId);
  const setLastOpenedResultsTab = useSetLastOpenedResultsTab(projectId);
  const isStaff = useIsStaff();
  const exportExploration = useRecoilCallback(exportJobTableCallback);
  const isStarterPlan = useIsStarterPlan();
  const exportDisabledReason = isStarterPlan ? 'Cannot Download Results on Starter Plan' : '';
  const [favoriteEntities, setFavoriteEntities] = useFavoriteEntities();

  // This will be populated when the data in the table changes and will be used for the Export
  const processedTableRows = useRef<RowConfig[]>([]);

  const workflowData = useMemo(
    () => createWorkflowData(workflowMap, workflows, experimentConfig),
    [workflowMap, workflows, experimentConfig],
  );

  const rowData = useMemo(
    () => createRowData(workflowData, jobNameMap),
    [workflowData, jobNameMap],
  );

  // When the name is changed, the job map is updated with the new name.
  // Also, the tabs state is updated to reflect the new simulation name assigned to the tab.
  const onEditName = useCallback((args: OnEditNameArgs) => {
    const { name, type, workflowId, jobId } = args;
    setRenaming({});
    // If we are renaming a single job name, we should update the tabs state as well
    const link = tabLink(type, projectId, workflowId, jobId);
    setTabsState(replaceTabText(link, name, tabsState));
    return jobNameMap.set({ workflowId, jobId }, name);
  }, [projectId, jobNameMap, setRenaming, tabsState, setTabsState]);

  const onEditExperiment = useCallback((args: OnEditNameArgs) => {
    const { name, workflowId } = args;
    setRenaming({});
    setRenamedWorkflows((old) => ({
      ...old,
      [workflowId]: name,
    }));
    return jobNameMap.set({ workflowId }, name);
  }, [jobNameMap, setRenaming]);

  const openJob = useCallback((rowId: string | null) => {
    const row = rowData.find((eachRow) => eachRow.job.jobId === rowId);
    if (!row) {
      throw Error('No row selected to open.');
    }
    const workflowId = row.workflow.id;
    const jobId = row.job.jobId;

    const link = tabLink(row.type, projectId, workflowId, jobId);
    const text = jobNameMap.getDefault({ workflowId, jobId, type: row.type });
    setTabsState(addClosableTab(text, link, tabsState));
    setLastOpenedResultsTab(link);
    navigate(link);
  }, [jobNameMap, navigate, projectId, rowData, setLastOpenedResultsTab, setTabsState, tabsState]);

  const columnConfigs: ColumnConfig[] = [
    {
      id: 'favorites',
      label: '',
      type: 'boolean',
      displayOptions: { justify: 'end', minimalWidth: true, fullHeight: true },
      group: 'Run Details',
    },
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      group: 'Run Details',
    },
  ];

  const explorationVarData = useMemo(
    () => createExplorationVarData(rowData, geometryTagsAllWfs, staticVolumesAllWfs),
    [rowData, geometryTagsAllWfs, staticVolumesAllWfs],
  );
  // If there's at least 1 DoE in the list, add a new column with the DoE's name
  if (workflowData.some((workflow) => !workflow.singleJob)) {
    columnConfigs.push({
      id: 'experiment',
      label: 'Experiment',
      type: 'string',
      group: 'Run Details',
    });

    if (explorationVarData.length) {
      // Find the columns describing the exploration input variables.
      explorationVarData.forEach((varData) => {
        const labelRows = varData.labelRows;
        const singleLabel = varData.labelRows.length === 1;
        columnConfigs.push({
          id: `${labelRows.toString()}`,
          superLabel: singleLabel ? undefined : labelRows.slice(0, -1).join(' - '),
          label: singleLabel ? labelRows[0] : labelRows.at(-1),
          displayOptions: { justify: 'end' },
          group: 'Inputs',
        });
      });
    }
  }

  const allTransient = rowData.every(
    (data) => data.workflow.simParam && isSimulationTransient(data.workflow.simParam),
  );

  columnConfigs.push(
    {
      id: 'date',
      label: 'Date',
      type: 'number',
      format: 'datetime',
      group: 'Run Details',
    },
    {
      id: 'runTime',
      label: 'Run Time (s)',
      type: 'string',
      displayOptions: { justify: 'end' },
      group: 'Run Details',
    },
    {
      id: 'latestIter',
      label: allTransient ? 'Timesteps' : 'Iter',
      type: 'string',
      displayOptions: { justify: 'end' },
      group: 'Run Details',
    },
  );

  if (allTransient) {
    columnConfigs.push({
      id: 'latestTime',
      label: 'Simulation Time (s)',
      type: 'string',
      displayOptions: { justify: 'end' },
      group: 'Run Details',
    });
  }

  columnConfigs.push({
    id: 'credits',
    label: 'Credits',
    type: 'string',
    displayOptions: { justify: 'end' },
    group: 'Run Details',
  });

  columnConfigs.push({
    id: 'status',
    label: 'Status',
    type: 'string',
    disableSorting: true,
    group: 'Run Details',
  });

  const anyJobRestarted = rowData.some((row) => row.job && row.job.jobIncarnation > 0);
  if (isStaff && anyJobRestarted) {
    columnConfigs.push({
      id: 'restarted',
      label: 'Restarted',
      type: 'string',
      displayOptions: { justify: 'end' },
      group: 'Run Details',
    });
  }

  // Get progressively loaded results and loading state
  const [outputResults] = useProgressiveOutputResults(projectId, outputNodes, rowData);

  // The outputs are more complicated because each output node (as shown in the tree) can result in
  // multiple output results.
  const outputColumns = outputNodes.nodes.reduce((acc, output) => {
    const result = outputResults[output.id];
    if (result?.length) {
      result.forEach((outputResult, idx) => {
        acc.push({
          // We can't use the output.id only, because it will be the same for all the results
          // for that output node. That's why we combine both the id of the node and the idx of
          // the result.
          id: `${output.id}${OUTPUT_NODE_RESULT_SEPARATOR}${idx}`,
          label: outputResult[0].name,
          type: 'number',
          format: 'scientific',
          superLabel: output.name,
          customWidth: 100,
          group: 'Outputs',
        });
      });
    }

    return acc;
  }, [] as ColumnConfig[]);
  columnConfigs.push(...outputColumns);

  const rowConfigs: RowConfig[] = rowData.map((row, rowIdx) => {
    const workflowId = row.workflow.id;
    const jobId = row.job.jobId;

    // Prepare the exploration input values
    const inputsTableData = explorationVarData.reduce<{
      values: Record<string, number | string>;
    }>((acc, varData) => {
      acc.values[varData.labelRows.toString()] = varData.data[rowIdx];
      return acc;
    }, { values: {} });

    const jobStatus = getJobStatus(row.job);

    // Prepare the outputs values and their cellDisplay modifiers (to include tooltips when needed)
    const outputsTableData = outputColumns.reduce<{
      values: Record<string, number | string>;
      cellDisplays: Record<string, CellDisplay>;
    }>((acc, { id }) => {
      const outputParts = id.split(OUTPUT_NODE_RESULT_SEPARATOR);
      const outputId = outputParts[0];
      const outputIdx = Number(outputParts[1]);
      const result = outputResults?.[outputId]?.[outputIdx]?.[rowIdx];

      acc.values[id] = result?.baseValue ?? EMPTY_VALUE;

      if (result?.status) {
        acc.cellDisplays[id] = { type: 'tooltip', content: result.status };
      }

      return acc;
    }, { values: {}, cellDisplays: {} });

    const values: Record<string, any> = {
      workflowId,
      name: row.name,
      favorites: favoriteEntities.simulations.includes(
        getFavoriteSimulationKey(row.workflow.id, row.job.jobId),
      ),

      experiment: row.workflow.singleJob ?
        EMPTY_VALUE :
        renamedWorkflows[workflowId] ?? row.workflow.reply?.name ?? EMPTY_VALUE,
      date: Math.round(row.job.creationTime * 1000),
      runTime: getJobRuntime(row.job),
      latestIter: row.job.latestIter ? `${row.job.latestIter}` : EMPTY_VALUE,
      latestTime: row.job.latestTime.toPrecision(4) ?? '',
      ...outputsTableData.values,
      ...inputsTableData.values,
      credits: getJobCredits(row.job),
      // this is not used for the rendering because the rendering is handled by the cellDisplay,
      // but it is still useful for searching
      status: `${jobStatus.state} ${jobStatus.message}`,
      restarted: row.job.jobIncarnation > 0 ? 'Yes' : 'No',
    };

    const menuItems: CommonMenuItem[] = [];

    if (userCanEdit) {
      const rowIsDoeJob = row.type === JobType.EXPLORATION_JOB;
      menuItems.push(
        {
          label: 'Open',
          onClick: () => openJob(jobId),
        },
        {
          startIcon: { name: 'cloudDownload' },
          label: 'Download',
          disabled: !!exportDisabledReason,
          disabledReason: exportDisabledReason,
          onClick: () => {
            exportExploration(projectId, [workflowId]).then(() => { }).catch(
              (error) => {
                addError('An internal error occured while downloading data. ' +
                  'Try again later or contact support.');
                logger.error(error);
              },
            );
          },
        },
        {
          startIcon: { name: 'cloudDownload' },
          label: 'Download All',
          disabled: !!exportDisabledReason,
          disabledReason: exportDisabledReason,
          onClick: () => {
            // We should `Download All` only the workflows that are filtered
            const filteredWorkflowsIds = [...processedTableRows.current.reduce(
              (acc, tableRow) => acc.add(String(tableRow.values.workflowId)),
              new Set<string>(),
            )];
            exportExploration(projectId, filteredWorkflowsIds).then(() => { }).catch(
              (error) => {
                addError('An internal error occured while downloading data. ' +
                  'Try again later or contact support.');
                logger.error(error);
              },
            );
          },
        },
        {
          startIcon: { name: 'copy' },
          label: 'Copy Row Text',
          onClick: async () => {
            // We want to copy the values as they appear in the table so we must use the table's
            // utility functions to format the values the same way as it does it
            const allColumns = generateColumnState(columnConfigs, {});
            const valuesToCopy = allColumns.map((column) => {
              const value = values[column.config.id];
              const normalValue = getNormalizedValue(value, column);
              const intermediateValue = getTransformedValue(normalValue, column);
              return getFormattedValue(intermediateValue, column, { useGrouping: false });
            });
            // Separating the values with \t allows them to be pasted into multiple cells in
            // Google Spreadsheets
            await navigator.clipboard.writeText(valuesToCopy.join(`\t`));
          },
        },
        { separator: true },
      );
      if (workflowMap[workflowId]?.status?.typ === basepb.JobStatusType.Active) {
        menuItems.push({
          disabled: canceledWorkflows[workflowId],
          disabledReason: 'Canceling is in progress',
          label: rowIsDoeJob ? 'Cancel All in Experiment' : 'Cancel',
          onClick: () => {
            setCanceledWorkflows((old) => ({
              ...old,
              [workflowId]: true,
            }));
            suspendWorkflow(projectId, workflowId);
          },
          help: rowIsDoeJob ?
            `Cancel all simulations in <b>${workflowMap[workflowId]?.name}</b>` :
            'Cancel simulation run',
        });
      }
      menuItems.push({
        label: 'Rename',
        onClick: () => {
          // Setting only a job will start a rename for the job
          setRenaming({ jobId });
        },
      });
      if (!row.workflow.singleJob) {
        menuItems.push({
          label: 'Rename Experiment',
          onClick: () => {
            // Setting both a job and workflow id will start a rename for the experiment
            setRenaming({ jobId, workflowId });
          },
        });
      }
      menuItems.push({ separator: true });
      menuItems.push({
        label: rowIsDoeJob ? 'Delete All in Experiment' : 'Delete',
        help: rowIsDoeJob ?
          `Permanently delete all results in <b>${workflowMap[workflowId]?.name}</b>` :
          'Permanently delete results',
        startIcon: { name: 'trash' },
        destructive: true,
        onClick: () => {
          setDeletedIds([workflowId, ...deletedIds]);
          deleteWorkflow(workflowId).catch(() => { });
        },
      });
    }

    return {
      id: row.job.jobId,
      onDoubleClick: () => openJob(jobId),
      values,
      cellDisplay: {
        name: userCanEdit ? [
          {
            type: 'editable',
            active: renaming.jobId === jobId && !renaming.workflowId,
            onStart: (event: React.MouseEvent) => {
              event.stopPropagation();
              setRenaming({ jobId });
            },
            onEdit: (newName: string) => {
              onEditName({ name: newName, type: row.type, workflowId, jobId });
            },
          },
        ] : [],
        favorites: {
          type: 'favorite',
          active: favoriteEntities.simulations.includes(
            getFavoriteSimulationKey(row.workflow.id, row.job.jobId),
          ),
          onChange: (value) => {
            const key = getFavoriteSimulationKey(row.workflow.id, row.job.jobId);

            setFavoriteEntities((previousValue) => {
              const result = previousValue.clone();

              const favSimulations = new Set(result.simulations);

              if (value) {
                favSimulations.add(key);
              } else {
                favSimulations.delete(key);
              }

              result.simulations = [...favSimulations];
              return result;
            });
          },
        },

        experiment: userCanEdit && !row.workflow.singleJob ? [
          {
            type: 'editable',
            active: renaming.jobId === jobId && renaming.workflowId === workflowId,
            onStart: (event: React.MouseEvent) => {
              event.stopPropagation();
              setRenaming({ jobId, workflowId });
            },
            onEdit: (newName: string) => {
              onEditExperiment({ name: newName, type: row.type, workflowId, jobId });
            },
          },
        ] : [],
        status: {
          type: 'jobStatus',
          // It would be best if the <JobStatus> is simplified so we can just pass a job.status.typ
          // or something to it and it is just a dummy display component without complex logic.
          // Then we wouldn't need to pass any of these to the Table component but that's a task
          // for another time.
          projectId,
          workflowId,
          job: row.job,
        },
        ...outputsTableData.cellDisplays,
      },
      menuItems,
    };
  });

  useEffect(() => {
    setRenamedWorkflows({});
    setCanceledWorkflows({});
  }, [workflowMap]);

  return (
    <Table
      asBlock
      columnConfigs={columnConfigs}
      controls={{
        search: true,
        style: { padding: '0 16px' },
      }}
      defaultSort={{ columnId: 'date' }}
      name={`project-results:${projectId}`}
      onChangeRows={(data) => {
        processedTableRows.current = data;
      }}
      rowConfigs={rowConfigs}
      scrollable
    />
  );
};

export default JobTable;
