// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import * as flags from '../../flags';
import { geometryLink } from '../../lib/navigation';
import { RESIDUAL_ENUM_NUMBER } from '../../lib/outputNodeUtils';
import { defaultConfig } from '../../lib/paramDefaults/workflowConfig';
import {
  DELETE_MESHENTRY_WITHSIM_WARNING,
  GEOMETRY_CADENTRY_HASMESH_HASSIM_WARNING,
  GEOMETRY_CADENTRY_HASMESH_WARNING,
  GEOMETRY_MESHENTRY_WARNING,
  MESH_MESHENTRY_WARNING,
  nodeDeletionConfirmation,
} from '../../lib/simulationTree/deletionWarningUtils';
import { isMeshFile } from '../../lib/upload/uploadUtils';
import { useDeleteWorkflow } from '../../lib/useDeleteWorkflow';
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { checkedUrlsState } from '../../recoil/checkedGeometryUrls';
import { geometryHealthState } from '../../recoil/geometryHealth';
import { lcvisCameraSelector } from '../../recoil/lcvis/lcvisCameraState';
import { lcVisReadyState } from '../../recoil/lcvis/lcvisReadyState';
import { lcvisVisibilityMapSelector } from '../../recoil/lcvis/lcvisVisibilityMap';
import { meshUrlState as meshUrlRecoilState, useMeshUrlState } from '../../recoil/meshState';
import { outputNodesState } from '../../recoil/outputNodes';
import { RecoilKey as CameraRecoilKey, cameraPositionState, newMeshKeys } from '../../recoil/paraviewState';
import { projectWithResetGeometryState } from '../../recoil/project';
import { cadModifierState } from '../../recoil/useCadModifier';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import { inputFilenameState } from '../../recoil/useInputFilename';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { filterStateSelector } from '../../recoil/vis/filterState';
import { projectConfigState } from '../../recoil/workflowConfig';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';

import { useHandleMeshSelect } from './useHandleMeshSelect';

/** Special handling for geometry (CAD) and mesh operations */
export const useGeometryMesh = () => {
  const { paraviewActiveUrl, paraviewMeshMetadata } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  const deleteWorkflow = useDeleteWorkflow(projectId);
  const projectMetadata = useProjectMetadata(projectId);
  const [meshUrlState] = useMeshUrlState(projectId);
  const { handleMeshSelect } = useHandleMeshSelect();
  const setConfirmStack = useSetConfirmations();
  const geoModEnabled = useIsEnabled(flags.geoModifications);
  const navigate = useNavigate();

  // Remove all workflows associated with this project
  const deleteAllWorkflows = async () => {
    const deleteReqs = projectMetadata?.workflow.map(
      (workflow) => deleteWorkflow(workflow.workflowId),
    );
    await Promise.all(deleteReqs ?? []);
  };

  // HandleMeshSelect with no arg removes the selected mesh
  const resetMesh = () => handleMeshSelect();

  // Resets the geometry and the mesh. The user must upload a new file or URL.
  const resetGeometry = useRecoilCallback(
    ({ set, reset }) => async () => {
      // remove far fields and other additional geometry
      set(cadModifierState(projectId), null);
      // remove reference to old geometry / mesh
      const emptyMeshState = new projectstatepb.MeshUrl();
      set(meshUrlRecoilState(projectId), emptyMeshState);
      set(inputFilenameState(projectId), (new projectstatepb.InputFilename()));
      // create an empty config
      const resetConfig = defaultConfig(
        emptyMeshState.mesh,
        null,
        emptyMeshState.meshId,
      );
      // this new empty config has no geometry
      // necessary for future geometry to be properly applied
      resetConfig.noGeometry = true;
      set(projectConfigState(projectId), resetConfig);

      // camera position must be null so backend resets the camera positon for future geometry
      const cameraKey: CameraRecoilKey = {
        projectId,
        meshKeys: newMeshKeys('cameraPosition', paraviewActiveUrl, paraviewMeshMetadata),
      };
      set(cameraPositionState(cameraKey), null);
      set(projectWithResetGeometryState(projectId), true);

      // reset LCVis recoil states
      reset(lcvisCameraSelector({ projectId, workflowId, jobId }));
      reset(lcvisVisibilityMapSelector({ projectId, workflowId, jobId }));
      // reset the filter state
      reset(filterStateSelector({ projectId, workflowId, jobId }));
      reset(lcVisReadyState);
      set(checkedUrlsState(projectId), (oldCheckedUrls) => (
        new projectstatepb.CheckedUrls({
          ...oldCheckedUrls,
          status: projectstatepb.CheckGeometryStatus.UNCHECKED,
        })));
      set(geometryHealthState(projectId), null);

      // Reset output nodes
      set(outputNodesState({ projectId, workflowId, jobId }), (oldOutputNodes) => {
        const newOutputNodes = oldOutputNodes.clone();
        newOutputNodes.nodes = newOutputNodes.nodes.filter((node) => (
          // If the node is not global, it is not associated with a physics node
          node.type !== feoutputpb.OutputNode_Type.GLOBAL_OUTPUT_TYPE ||
          // If it is global, make sure it is not a residual node
          node.choice !== RESIDUAL_ENUM_NUMBER
        ));
        return newOutputNodes;
      });

      // With igeo, we only allow uploads from the geometry page. Navigate the user to it.
      if (geoModEnabled) {
        navigate(geometryLink(projectId));
      }
    },
  );

  const deleteMeshNode = (): boolean => {
    // If the mesh is a generated mesh, reset selected mesh state
    if (!isMeshFile(meshUrlState.url)) {
      resetMesh().catch(console.error);
      return true;
    }

    // Else the project's entry point is an uploaded mesh and we also need to delete the geometry
    const hasSimulations = (projectMetadata?.workflow.length ?? 0) > 0;
    if (hasSimulations) {
      pushConfirmation(
        setConfirmStack,
        nodeDeletionConfirmation(DELETE_MESHENTRY_WITHSIM_WARNING, async () => {
          await deleteAllWorkflows();
          await resetGeometry();
        }),
      );
    } else {
      pushConfirmation(
        setConfirmStack,
        nodeDeletionConfirmation(MESH_MESHENTRY_WARNING, resetGeometry),
      );
    }
    return false;
  };

  // When a geometry or mesh is deleted, the warning message and action are dependent on the entry
  // point of the project as well as the existence of meshes and simulations as seen in the summary
  // doc of LC-12404.
  const deleteGeometryNode = async (): Promise<boolean> => {
    const hasMesh = meshUrlState.mesh;
    const hasSimulations = (projectMetadata?.workflow.length ?? 0) > 0;
    if (isMeshFile(meshUrlState.url)) {
      // The project's entry point was a mesh file
      if (hasMesh) {
        if (hasSimulations) {
          pushConfirmation(
            setConfirmStack,
            nodeDeletionConfirmation(DELETE_MESHENTRY_WITHSIM_WARNING, async () => {
              await deleteAllWorkflows();
              await resetGeometry();
            }),
          );
        } else {
          pushConfirmation(
            setConfirmStack,
            nodeDeletionConfirmation(GEOMETRY_MESHENTRY_WARNING, resetGeometry),
          );
        }
      }
    } else {
      // Assume if the project is not a mesh file, then it has to be a CAD file
      if (hasMesh) { // eslint-disable-line no-lonely-if
        if (hasSimulations) {
          pushConfirmation(
            setConfirmStack,
            nodeDeletionConfirmation(GEOMETRY_CADENTRY_HASMESH_HASSIM_WARNING, async () => {
              await deleteAllWorkflows();
              await resetGeometry();
            }),
          );
        } else {
          pushConfirmation(
            setConfirmStack,
            nodeDeletionConfirmation(GEOMETRY_CADENTRY_HASMESH_WARNING, resetGeometry),
          );
        }
      } else {
        // project has no mesh; simply delete the geometry with no warning
        await resetGeometry();
        return true;
      }
    }
    return false;
  };

  return {
    deleteAllWorkflows,
    deleteGeometryNode,
    deleteMeshNode,
    resetGeometry,
  };
};
