// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const DotsTwoColumns = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="dots-two-columns"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
        fill: getColor(0),
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M4.25 5.25
           C3.8375 5.25 3.5 5.5875 3.5 6
           C3.5 6.4125 3.8375 6.75 4.25 6.75
           C4.6625 6.75 5 6.4125 5 6
           C5 5.5875 4.6625 5.25 4.25 5.25Z"
      />
      <path
        d="M4.25 9.5
           C3.8375 9.5 3.5 9.8375 3.5 10.25
           C3.5 10.6625 3.8375 11 4.25 11
           C4.6625 11 5 10.6625 5 10.25
           C5 9.8375 4.6625 9.5 4.25 9.5Z"
      />
      <path
        d="M4.25 1
           C3.8375 1 3.5 1.3375 3.5 1.75
           C3.5 2.1625 3.8375 2.5 4.25 2.5
           C4.6625 2.5 5 2.1625 5 1.75
           C5 1.3375 4.6625 1 4.25 1Z"
      />
      <path
        d="M7.75 5.25
           C7.3375 5.25 7 5.5875 7 6
           C7 6.4125 7.3375 6.75 7.75 6.75
           C8.1625 6.75 8.5 6.4125 8.5 6
           C8.5 5.5875 8.1625 5.25 7.75 5.25Z"
      />
      <path
        d="M7.75 1
           C7.3375 1 7 1.3375 7 1.75
           C7 2.1625 7.3375 2.5 7.75 2.5
           C8.1625 2.5 8.5 2.1625 8.5 1.75
           C8.5 1.3375 8.1625 1 7.75 1Z"
      />
      <path
        d="M7.75 9.5
           C7.3375 9.5 7 9.8375 7 10.25
           C7 10.6625 7.3375 11 7.75 11
           C8.1625 11 8.5 10.6625 8.5 10.25
           C8.5 9.8375 8.1625 9.5 7.75 9.5Z"
      />
    </svg>
  );
});
