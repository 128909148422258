// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import assert from 'assert';

import { LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';

import { LcvObject } from './LcvObject';

/**
 * Some kinds of objects, e.g. monitor points and disks, are treated as a single ObjectList
 * rather than having a single LcvObject per item. This facilitates batch rendering these
 * objects and reduces memory requirements.
 */
export abstract class LcvObjectList extends LcvObject {
  size: number = 0;

  constructor(lcv: LcvModule, handle: number, sessionHandle: number, size: number) {
    super(lcv, handle, sessionHandle);
    this.setSize(size);
  }

  /** Check that the index is not out of bounds. Throw an error if it is. */
  private checkIndex(index: number, methodName: string) {
    if (index >= this.size || index < 0) {
      throw Error(
        `LcvObjectList has size ${this.size}. Tried to call ${methodName} at index ${index}.`,
      );
    }
  }

  /** Set the param with propertyName on the object at the given index in the list. */
  setParamAtIndex(index: number, propertyName: string, type: LCVType, value: any) {
    this.checkIndex(index, 'setParamAtIndex');
    this.lcv.setParameterIndex(this.sessionHandle, this.handle, propertyName, index, type, value);
  }

  /** Return the property with propertyName on the object at the given index in the list. */
  getPropertyAtIndex(index: number, propertyName: string, type: LCVType) {
    this.checkIndex(index, 'getPropertyAtIndex');
    return this.lcv.getPropertyIndex(
      this.sessionHandle,
      this.handle,
      propertyName,
      index,
      type,
      0,
    ).property;
  }

  /** Delete the item at the given index from the list. This shrinks the list. */
  deleteItemAtIndex(index: number) {
    this.checkIndex(index, 'deleteItemAtIndex');
    this.lcv.objectListErase(this.sessionHandle, this.handle, index);
    this.size -= 1;
  }

  /** Get the object list size. */
  getSize(): number {
    const internalSize = this.lcv.objectListGetSize(
      this.sessionHandle,
      this.handle,
      0,
    ).size;
    assert(this.size === internalSize);
    return internalSize;
  }

  /**
   * To add an object to the LcvObjectList:
   * 1. increase the size of the list by calling increaseSizeByOne.
   * 2. call setParamAtIndex using the new highest index in the list to set the
   *    newly added object's params.
   */
  increaseSizeByOne() {
    this.lcv.objectListAppend(this.sessionHandle, this.handle);
    this.size += 1;
  }

  /**
   * Set the size of the LcvObjectList to a new size, if size == this.size,
   * no operation is performed
   */
  setSize(newSize: number) {
    if (this.size !== newSize) {
      this.lcv.objectListSetSize(this.sessionHandle, this.handle, newSize);
      this.size = newSize;
    }
  }
}
