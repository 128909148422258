// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo, useRef } from 'react';

import { AnyKeyboardEvent } from '../../../../lib/event';
import { isEventTrigger } from '../../../../lib/keyBindings';
import { lcvHandler } from '../../../../lib/lcvis/handler/LcvHandler';
import { ListenerEvent, useEventListener } from '../../../../lib/useEventListener';
import { useLcvisMeasureState } from '../../../../recoil/lcvis/lcvisMeasureState';
import { OverlayValue } from '../util';

import MeasureCard from './MeasureCard';
import { MeasureLabel } from './MeasureLabel';

import '../Overlay.scss';
import { RIGHT_OVERLAY_CARDS_WIDTH } from '@/lib/constants';

const MeasureOverlay = () => {
  const [lcvisMeasureState, setLcvisMeasureState] = useLcvisMeasureState();
  const removeMeasure = useCallback(() => {
    setLcvisMeasureState((oldMeasure) => (
      { ...oldMeasure, active: false, pointToUdpdate: 0, length: undefined }
    ));
  }, [setLcvisMeasureState]);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const measureData: Map<string, OverlayValue> = useMemo(() => {
    const { length } = lcvisMeasureState;

    const map = new Map<string, OverlayValue>();
    if (!length) {
      return map;
    }
    map.set('Length', length);
    map.set('X Offset', Math.abs(lcvisMeasureState.endPoint[0] - lcvisMeasureState.startPoint[0]));
    map.set('Y Offset', Math.abs(lcvisMeasureState.endPoint[1] - lcvisMeasureState.startPoint[1]));
    map.set('Z Offset', Math.abs(lcvisMeasureState.endPoint[2] - lcvisMeasureState.startPoint[2]));
    return map;
  }, [lcvisMeasureState]);

  // Pressing escape will exit the overlay.
  const handleExitOverlay = useCallback((event: AnyKeyboardEvent) => {
    if (isEventTrigger('exitOverlay', event)) {
      event.preventDefault();
      removeMeasure();
    }
  }, [removeMeasure]);
  useEventListener(
    'keydown',
    (event: ListenerEvent) => handleExitOverlay(event as AnyKeyboardEvent),
  );

  return (
    <div
      className="overlayContainer"
      ref={overlayRef}
      style={{ width: `calc(100% - ${RIGHT_OVERLAY_CARDS_WIDTH}px` }}>
      {/* an empty div to occupy the 1st column in the grid */}
      <div />
      <MeasureLabel onExit={removeMeasure} />
      {measureData.size ? (
        <MeasureCard
          data={measureData}
          disableReset={false}
          onReset={() => {
            const { display } = lcvHandler;
            display?.widgets.measureWidget?.reset();
          }}
          title="Measured Values"
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default MeasureOverlay;
