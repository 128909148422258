// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ForwardedRef, forwardRef, useEffect, useRef } from 'react';

import assert from '../../lib/assert';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { VIEWER_PADDING } from '../../lib/visUtils';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useLcvisExplodeFactorValue } from '../../recoil/lcvis/explodeFactor';
import { useLcvisCameraValue } from '../../recoil/lcvis/lcvisCameraState';
import { useLcvisMeasureValue } from '../../recoil/lcvis/lcvisMeasureState';
import { useLcvisMenuSettingsValue } from '../../recoil/lcvis/lcvisMenuSettings';
import { useLcvisProbeValue } from '../../recoil/lcvis/lcvisProbeState';
import { useLcVisReadyValue } from '../../recoil/lcvis/lcvisReadyState';
import { useShowTipsOverlay } from '../../recoil/lcvis/showTipsOverlay';
import { useTagSelectionSettingsValue } from '../../recoil/lcvis/tagsSelection';
import { useTransparencySettingsValue } from '../../recoil/lcvis/transparencySettings';
import { useIsCreateTagModalOpen, useSetCreateTagModalOpen } from '../../recoil/useCreateTagModal';
import { useStaticVolumes } from '../../recoil/volumes';
import { useShowLCVisSettingsDialog } from '../../state/external/lcvis/showLCVisSettingsDialog';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { useIsAnalysisView, useIsGeometryView } from '../../state/internal/global/currentView';
import {
  useMotionAnimationParamState,
  useSetMotionAnimationPlaying,
  useShowMotionAnimationSettings,
} from '../../state/internal/vis/motionAnimation';
import { GeometryFeatureSelectionManager } from '../GeometryFeatureSelectionManager';
import FloatingToolbar from '../Pane/FloatingToolbar';
import CameraControlPanel from '../Paraview/CameraControlPanel';
import MotionAnimationSettings from '../Paraview/MotionAnimationSettings';
import TipsOverlay from '../Paraview/TipsOverlay';
import { createStyles, makeStyles } from '../Theme';
import { BUTTON_SIZE } from '../Toolbar/ToolbarButton';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import CreateTagDialog from '../dialog/CreateTag';
import LCVisSettingsDialog from '../dialog/LCVisSettingsDialog';
import { useHandleManualCameraViewChange } from '../hooks/useHandleManualCameraViewChange';
import { useHighlightProblematicEdges } from '../hooks/useHighlightProblematicEdges';
import { useTagsInteractiveGeometry } from '../hooks/useInteractiveGeometry';
import GeometryModificationDraggablePanel from '../project/GeometryModificationDraggablePanel';
import { LeftOverlayCards } from '../project/LeftOverlayCards';
import { RightOverlayCards } from '../project/RightOverlayCards';
import { FloatingPropertiesPanel } from '../treePanel/FloatingPropertiesPanel';
import { PropertiesPanel } from '../treePanel/PropertiesPanel';

import ContextMenu from './overlays/ContextMenu';
import ExplodeModeLabel from './overlays/ExplodeModeLabel';
import { GeometryModificationClipPanel } from './overlays/GeometryModificationClipPanel';
import { ActiveOverlay } from './overlays/LcVisActiveOverlay';
import { LcVisColorPanel } from './overlays/LcVisColorPanel';
import LoadingOverlay from './overlays/LoadingOverlay';
import MeasureOverlay from './overlays/measure/MeasureOverlay';
import ProbeOverlay from './overlays/probe/ProbeOverlay';
import TagSelectionDialog from './panels/TagSelectionDialog';
import TransparencyDialog from './panels/TransparencyDialog';

const useStyles = makeStyles(
  () => createStyles({
    motionDialog: {
      position: 'absolute',
      right: `${VIEWER_PADDING}px`,
      top: `${BUTTON_SIZE + 2 * VIEWER_PADDING}px`,
      // Should be higher than FloatingToolbar's zIndex
      zIndex: 4,
    },
    geometryBusy: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 40,
    },
  }),
  { name: 'LcVisOverlay' },
);

interface CenteredOverlayProps {
  hasProbeValue: boolean;
  hasMeasureValue: boolean;
  hasExplodeFactor: boolean;
}

const CenteredOverlay = (props: CenteredOverlayProps) => {
  const { hasProbeValue, hasMeasureValue, hasExplodeFactor } = props;

  if (hasProbeValue) {
    return <ProbeOverlay />;
  }
  if (hasExplodeFactor) {
    return <ExplodeModeLabel />;
  }
  if (hasMeasureValue) {
    return <MeasureOverlay />;
  }
  return <FloatingToolbar />;
};

export interface LcVisOverlayHandlerProps {
  className: string,
}

/** Handles components we overlay on top of lcvis, e.g. colormaps, status cards, job panels */
export const LcVisOverlayHandler = forwardRef(
  (
    props: LcVisOverlayHandlerProps,
    ref: ForwardedRef<HTMLCanvasElement | null>,
  ) => {
    // == Props
    const { className } = props;

    // == Contexts
    const { projectId, workflowId, jobId, geometryId } = useProjectContext();
    const { selectedNode, selectedNodeIds, setSelection } = useSelectionContext();

    // == Recoil
    const isGeometryView = useIsGeometryView();
    const isAnalysisView = useIsAnalysisView();
    const lcvisReady = useLcVisReadyValue();
    const [
      showMotionAnimationSettings,
      setShowMotionAnimationSettings,
    ] = useShowMotionAnimationSettings();
    const setMotionAnimationPlaying = useSetMotionAnimationPlaying();
    const [gridMotionParam] = useMotionAnimationParamState(projectId);
    const simParam = useSimulationParam(projectId, workflowId, jobId);
    const staticVolumes = useStaticVolumes(projectId);
    const lcvisCameraValue = useLcvisCameraValue({ projectId, workflowId, jobId });
    const lcvisProbeValue = useLcvisProbeValue();
    const lcvisMeasureValue = useLcvisMeasureValue();
    const explodeFactor = useLcvisExplodeFactorValue(projectId);
    const isStaff = useIsStaff();
    const [showSettingsDialog] = useShowLCVisSettingsDialog();
    const showTipsOverlay = useShowTipsOverlay({ projectId, workflowId, jobId, geometryId });
    // We want to prevent rendering the context menu when it isn't open, to avoid running
    // expensive hooks.
    const showContextMenu = useLcvisMenuSettingsValue().menuOpen;
    const transparencySettings = useTransparencySettingsValue();
    const tagSelectionSettings = useTagSelectionSettingsValue();
    const geometryTags = useGeometryTags(projectId);
    useHighlightProblematicEdges();

    const [isCreateTagDialogOpen] = useIsCreateTagModalOpen();
    const setCreateTagDialogOpen = useSetCreateTagModalOpen();

    // == Hooks
    const classes = useStyles();
    const handleManualCameraViewChange = useHandleManualCameraViewChange();
    const { createTag } = useTagsInteractiveGeometry();

    // State
    const overlayHandlerRef = useRef<HTMLDivElement>(null);

    // Function called when motion is triggered.
    const gridMotionAnimation = async () => {
      // Hide the motion animation settings panel.
      setShowMotionAnimationSettings(false);

      // Translate UI motion state to param state. Note that we need the current param specification
      // since the translation depends on the boundary conditions specification and the flow
      // behavior.
      assert(simParam !== null, 'Simulation param unexpectedly empty');

      // If no motion, or motion warnings exist, the translator returns early.  Attempting a motion
      // animation with an empty motion data list fails and can corrupt state.  Checks upstream
      // should prevent this code path, but we add it here as a fail safe
      if (!simParam.motionData.length) {
        return;
      }

      const display = await lcvHandler.getDisplay();
      if (display?.getWorkspace()) {
        const stepSize = (gridMotionParam.endTime - gridMotionParam.startTime) /
          gridMotionParam.numberOfSteps;
        setMotionAnimationPlaying(true);
        display.getWorkspace()?.setMotionData(
          simParam,
          staticVolumes,
          geometryTags,
        );
        display.getWorkspace()?.animateMotion(
          gridMotionParam.numberOfSteps,
          stepSize,
          gridMotionParam.drawAxes,
          () => setMotionAnimationPlaying(false),
        );
      }
    };

    // We need to deselected a saved camera when the user interacts with the
    // camera.
    useEffect(() => {
      if (lcvisCameraValue.editSource === 'LCVis') {
        handleManualCameraViewChange();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lcvisCameraValue]);

    const renderMotionPanel = !!(showMotionAnimationSettings && simParam);

    return (
      <div
        className={classes.rendererRoot}
        ref={overlayHandlerRef}
        // This div defines the draggable area (for the draggable dialogs). Height must be
        // explicitly set to give the draggable panels the ability to move around the entire canvas.
        style={{ height: '100%' }}>
        <CenteredOverlay
          hasExplodeFactor={explodeFactor !== null}
          hasMeasureValue={lcvisMeasureValue.active}
          hasProbeValue={lcvisProbeValue.active}
        />
        <LeftOverlayCards />
        <FloatingPropertiesPanel hasMotionPanel={renderMotionPanel} />
        {isGeometryView && <GeometryFeatureSelectionManager />}
        <RightOverlayCards />
        <>
          {isCreateTagDialogOpen && (
            <CreateTagDialog
              isOpen={isCreateTagDialogOpen}
              onCancel={() => {
                setCreateTagDialogOpen(false);
                setSelection([]);
              }}
              onSubmit={async (name) => {
                await createTag(name, selectedNodeIds);
                setCreateTagDialogOpen(false);
                setSelection([]);
              }}
            />
          )}
        </>
        {isGeometryView && (
          <>
            <GeometryModificationDraggablePanel node={selectedNode} overlayRef={overlayHandlerRef}>
              <PropertiesPanel />
            </GeometryModificationDraggablePanel>
            <GeometryModificationClipPanel overlayRef={overlayHandlerRef} />
          </>
        )}
        <CameraControlPanel
          disabled={!lcvisReady}
        />
        {!lcvisReady && !showTipsOverlay && !isGeometryView && <LoadingOverlay />}
        {showTipsOverlay && <TipsOverlay />}
        {tagSelectionSettings.active && <TagSelectionDialog ref={overlayHandlerRef} />}
        {transparencySettings.active && <TransparencyDialog ref={overlayHandlerRef} />}
        <ActiveOverlay className={className} />
        {showContextMenu && <ContextMenu ref={ref} />}
        {renderMotionPanel && (
          <div
            className={classes.motionDialog}
            data-locator="motionAnimationSettings">
            <MotionAnimationSettings
              cancelEdit={() => setShowMotionAnimationSettings(false)}
              onClose={gridMotionAnimation}
            />
          </div>
        )}
        {isAnalysisView && <LcVisColorPanel ref={ref} />}
        {isStaff && showSettingsDialog && <LCVisSettingsDialog ref={ref} />}
      </div>
    );
  },
);
