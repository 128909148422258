// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import * as flags from '../../../flags';
import { newProto } from '../../../lib/Vector';
import { RadioButtonOption } from '../../../lib/componentTypes/form';
import { toggleVisibility } from '../../../lib/entityGroupUtils';
import {
  DEFAULT_FARFIELD_SCALE,
  FARFIELD_CHANGE_BASE_DIALOG,
  FARFIELD_ID,
  FARFIELD_NODE_ID,
  boxCenter,
  defaultCube,
  defaultCylinder,
  diagonalLength,
  getRadius,
  isInitialized,
  scaleModifier,
  validateFarfield,
  willChangeBeDestructive,
} from '../../../lib/farfieldUtils';
import { deleteFarFieldImposter, updateFarFieldImposter } from '../../../lib/imposterFilteringUtils';
import { FarFieldOptions, FarFieldType } from '../../../lib/lcvis/classes/filters/LcvImportDatasetFilter';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import * as rpc from '../../../lib/rpc';
import * as shapepb from '../../../proto/cad/shape_pb';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import { UserGeometryMod } from '../../../proto/meshgeneration/meshgeneration_pb';
import * as meshgenerationpb from '../../../proto/meshgeneration/meshgeneration_pb';
import * as projectstatepb from '../../../proto/projectstate/projectstate_pb';
import { useSetCheckedUrls } from '../../../recoil/checkedGeometryUrls';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useFrontendMenuState } from '../../../recoil/frontendMenuState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcvisVisibilityMap } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useMeshUrlState } from '../../../recoil/meshState';
import { useSetPendingWorkOrders } from '../../../recoil/pendingWorkOrders';
import { useCadMetadata } from '../../../recoil/useCadMetadata';
import { CadModifier, useCadModifier } from '../../../recoil/useCadModifier';
import { useEnabledExperiments } from '../../../recoil/useExperimentConfig';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { pushConfirmation, useSetConfirmations } from '../../../state/internal/dialog/confirmations';
import Form from '../../Form';
import { BoxInput } from '../../Form/CompositeInputs/BoxInput';
import { CylinderInput } from '../../Form/CompositeInputs/CylinderInput';
import { HalfSphereInput } from '../../Form/CompositeInputs/HalfSphereInput';
import { SphereInput } from '../../Form/CompositeInputs/SphereInput';
import { RadioButtonGroup } from '../../Form/RadioButtonGroup';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { EditButtons } from '../../controls/EditButtons';
import { ScaleButtons } from '../../controls/ScaleButtons';
import { SectionMessage } from '../../notification/SectionMessage';
import PropertiesSection from '../PropertiesSection';

const { GET_GEOMETRY, CHECK_GEOMETRY } = frontendpb.WorkOrderType;

// Panel for displaying and editing cube parameters.
interface CubePanelProps {
  cube: shapepb.Cube;
  setCube: (cube: shapepb.Cube) => void;
  readOnly: boolean;
}

export const CubePanel = (props: CubePanelProps) => {
  const { cube, setCube, readOnly } = props;
  return (
    <BoxInput
      disabled={readOnly}
      onCommit={(box) => {
        const { center: origin, size } = box;
        const min = newProto(origin.x - size.x / 2, origin.y - size.y / 2, origin.z - size.z / 2);
        const max = newProto(origin.x + size.x / 2, origin.y + size.y / 2, origin.z + size.z / 2);
        setCube(new shapepb.Cube({ min, max }));
      }}
      value={(() => {
        const min = cube.min || newProto(0, 0, 0);
        const max = cube.max || newProto(1, 1, 1);
        const origin = {
          x: (min.x + max.x) / 2,
          y: (min.y + max.y) / 2,
          z: (min.z + max.z) / 2,
        };
        const size = {
          x: max.x - min.x,
          y: max.y - min.y,
          z: max.z - min.z,
        };
        return { center: origin, size };
      })()}
    />
  );
};

// Panel for displaying and editing the sphere parameters.
interface SpherePanelProps {
  sphere: shapepb.Sphere;
  setSphere: (sphere: shapepb.Sphere) => void;
  readOnly: boolean;
}

export const SpherePanel = (props: SpherePanelProps) => {
  const { sphere, setSphere, readOnly } = props;
  return (
    <SphereInput
      disabled={readOnly}
      onCommit={(newSphere) => {
        const { center, radius } = newSphere;
        setSphere(new shapepb.Sphere({ center: newProto(center.x, center.y, center.z), radius }));
      }}
      value={{
        center: {
          x: sphere.center?.x ?? 0,
          y: sphere.center?.y ?? 0,
          z: sphere.center?.z ?? 0,
        },
        radius: sphere.radius ?? 1.00,
      }}
    />
  );
};

// Panel for displaying and editing the half-sphere parameters.
interface HalfSpherePanelProps {
  halfSphere: shapepb.HalfSphere;
  setHalfSphere: (halfSphere: shapepb.HalfSphere) => void;
  readOnly: boolean;
}

export const HalfSpherePanel = (props: HalfSpherePanelProps) => {
  const { halfSphere, setHalfSphere, readOnly } = props;
  return (
    <HalfSphereInput
      disabled={readOnly}
      onCommit={(newHalfSphere) => {
        const { center, radius, normal } = newHalfSphere;
        setHalfSphere(
          new shapepb.HalfSphere({
            center: newProto(center.x, center.y, center.z),
            radius,
            normal: newProto(normal.x, normal.y, normal.z),
          }),
        );
      }}
      value={{
        center: {
          x: halfSphere.center?.x ?? 0,
          y: halfSphere.center?.y ?? 0,
          z: halfSphere.center?.z ?? 0,
        },
        radius: halfSphere.radius ?? 1.00,
        normal: {
          x: halfSphere.normal?.x ?? 0,
          y: halfSphere.normal?.y ?? 0,
          z: halfSphere.normal?.z ?? 1,
        },
      }}
    />
  );
};

// Panel for displaying and editing the cylinder parameters.
interface CylinderPanelProps {
  cylinder: shapepb.Cylinder;
  setCylinder: (cylinder: shapepb.Cylinder) => void;
  readOnly: boolean;
}

export const CylinderPanel = (props: CylinderPanelProps) => {
  const { cylinder, setCylinder, readOnly } = props;
  return (
    <>
      <CylinderInput
        disabled={readOnly}
        onCommit={(cyl) => {
          const { start, end, radius } = cyl;
          setCylinder(
            new shapepb.Cylinder({
              start: newProto(start.x, start.y, start.z),
              end: newProto(end.x, end.y, end.z),
              radius,

            }),
          );
        }}
        value={{
          start: {
            x: cylinder.start?.x ?? 0,
            y: cylinder.start?.y ?? 0,
            z: cylinder.start?.z ?? 0,
          },
          end: {
            x: cylinder.end?.x ?? 1.0,
            y: cylinder.end?.y ?? 0,
            z: cylinder.end?.z ?? 0,
          },
          radius: cylinder.radius ?? 1.00,
        }}
      />
    </>
  );
};

export enum ShapeType {
  SPHERE = 'sphere',
  HALF_SPHERE = 'half-sphere',
  CUBE = 'cube',
  CYLINDER = 'cylinder',
}

export const getFarFieldOptions = (
  newOp: UserGeometryMod,
  transparent: boolean,
): FarFieldOptions => {
  const options = new FarFieldOptions();
  options.transparent = transparent;
  if (newOp.farField.case === 'sphere') {
    options.type = FarFieldType.SPHERE;
    options.radius = newOp.farField.value.radius;
    options.center = newOp.farField.value.center;
  } else if (newOp.farField.case === 'halfSphere') {
    options.type = FarFieldType.HALF_SPHERE;
    options.radius = newOp.farField.value.radius;
    options.center = newOp.farField.value.center;
    options.normal = newOp.farField.value.normal;
  } else if (newOp.farField.case === 'cube') {
    options.type = FarFieldType.BOX;
    options.start = newOp.farField.value.min;
    options.end = newOp.farField.value.max;
  } else if (newOp.farField.case === 'cylinder') {
    options.type = FarFieldType.CYLINDER;
    options.radius = newOp.farField.value.radius;
    options.start = newOp.farField.value.start;
    options.end = newOp.farField.value.end;
  }
  return options;
};

// A panel displaying all the settings for the generated far field.
export const FarFieldPropPanel = () => {
  const {
    paraviewClientState,
    viewState,
    setViewAttrs,
    paraviewRenderer,
    visibilityMap,
    setVisibility,
  } = useParaviewContext();
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  // Current modifier is the CAD modifier that is current in the settings. Edit
  // modifier is the one we are editing. It will go into the settings if save
  // is clicked. It is discarded if cancel is clicked.
  const [currModifier, setCurrModifier] = useCadModifier(projectId);
  const [editModifier, setEditModifier] = useState<CadModifier>(null);
  const [cadMetadata] = useCadMetadata(projectId, workflowId, jobId);
  const setConfirmStack = useSetConfirmations();
  const [lcvisVisibility, setLcvisVisibility] = useLcvisVisibilityMap(
    { projectId, workflowId, jobId },
  );

  const [meshUrlState] = useMeshUrlState(projectId);
  const [frontendMenuState] = useFrontendMenuState(projectId, '', '');
  const setPendingWorkOrders = useSetPendingWorkOrders(projectId);
  const setCheckedUrls = useSetCheckedUrls(projectId);

  const modifier = editModifier || currModifier;
  const inEditMode = !!editModifier;
  const commonClasses = useCommonTreePropsStyles();

  // State to store a backup of the current farfield visibility
  const farfieldVisBackup = useRef<Map<string, boolean>>(new Map());
  const entityGroupData = useEntityGroupData(projectId, '', '');
  const visibility = viewState?.attrs.blockVisibility;

  const onStartEdit = (currCadModifier: CadModifier) => {
    currCadModifier && setEditModifier(currCadModifier);
    if (currCadModifier) {
      if (lcvisEnabled) {
        const farFieldOptions = getFarFieldOptions(currCadModifier, true);
        lcvHandler.queueDisplayFunction('add far field', (display) => {
          display.workspace?.addFarField(farFieldOptions).catch((error) => console.warn(error));
        });
      } else {
        updateFarFieldImposter(paraviewRenderer, currCadModifier, true, paraviewClientState);
      }
      if (visibility && entityGroupData.leafMap.has(FARFIELD_ID)) {
        // Back up the current visibility. We need it to restore the original visibility
        // when the user cancels the edit.
        const leafIds = entityGroupData.leafMap.get(FARFIELD_ID)!;
        farfieldVisBackup.current = new Map<string, boolean>();
        leafIds.forEach((id) => {
          farfieldVisBackup.current.set(id, visibility[id]);
        });
        setVisibility(
          toggleVisibility(visibilityMap, entityGroupData.groupMap, leafIds, false),
        );
      }
      if (lcvisEnabled && entityGroupData.leafMap.has(FARFIELD_ID)) {
        const leafIds = entityGroupData.leafMap.get(FARFIELD_ID)!;
        farfieldVisBackup.current = new Map<string, boolean>();
        leafIds.forEach((id) => {
          farfieldVisBackup.current.set(id, lcvisVisibility.get(id) ?? false);
        });
        setLcvisVisibility(
          (prev) => toggleVisibility(prev, entityGroupData.groupMap, leafIds, false),
        );
      }
    }
  };

  const initialized = isInitialized(currModifier);

  // If the current cad modifies is not initialized, i.e. it does not have
  // any farfield shape set (which is the case after it has been created)
  // we set some initial settings for the edit state (so that editing mode is enabled)
  if (!initialized && !editModifier) {
    const bBox = cadMetadata.boundingBox;
    const center = boxCenter(bBox);
    const radius = DEFAULT_FARFIELD_SCALE * diagonalLength(bBox);
    const newModifier = currModifier ?
      currModifier!.clone() : new meshgenerationpb.UserGeometryMod();
    newModifier.farField = {
      case: 'sphere',
      value: new shapepb.Sphere({ center, radius }),
    };
    onStartEdit(newModifier);
  }

  // Apply a changeMod function to the edit modifier. This sets the shape.
  const setShape = (changeMod: (oldMod: meshgenerationpb.UserGeometryMod) => void) => {
    const newModifier = editModifier!.clone();
    changeMod(newModifier);
    setEditModifier(newModifier);
    const transparent = true;
    if (lcvisEnabled) {
      const farFieldOptions = getFarFieldOptions(newModifier, true);
      lcvHandler.queueDisplayFunction('add far field', (display) => {
        display.workspace?.addFarField(farFieldOptions).catch((error) => console.warn(error));
      });
    } else {
      updateFarFieldImposter(paraviewRenderer, newModifier, transparent, paraviewClientState);
    }
  };

  // Determine which parameter panel to display.
  let shapeValue = '';
  let parameterPanel: ReactNode;
  if (modifier?.farField.case === 'sphere') {
    shapeValue = ShapeType.SPHERE;
    parameterPanel = (
      <SpherePanel
        readOnly={!inEditMode}
        setSphere={(sphere) => setShape((newOp) => {
          newOp.farField = { case: 'sphere', value: sphere };
        })}
        sphere={modifier.farField.value}
      />
    );
  } else if (modifier?.farField.case === 'halfSphere') {
    shapeValue = ShapeType.HALF_SPHERE;
    parameterPanel = (
      <HalfSpherePanel
        halfSphere={modifier.farField.value}
        readOnly={!inEditMode}
        setHalfSphere={(halfSphere) => setShape((newOp) => {
          newOp.farField = { case: 'halfSphere', value: halfSphere };
        })}
      />
    );
  } else if (modifier?.farField.case === 'cube') {
    shapeValue = ShapeType.CUBE;
    parameterPanel = (
      <CubePanel
        cube={modifier.farField.value}
        readOnly={!inEditMode}
        setCube={(cube) => setShape((newOp) => {
          newOp.farField = { case: 'cube', value: cube };
        })}
      />
    );
  } else if (modifier?.farField.case === 'cylinder') {
    shapeValue = ShapeType.CYLINDER;
    parameterPanel = (
      <CylinderPanel
        cylinder={modifier.farField.value}
        readOnly={!inEditMode}
        setCylinder={(cylinder) => setShape((newOp) => {
          newOp.farField = { case: 'cylinder', value: cylinder };
        })}
      />
    );
  }

  // Create a new shape when the shape changes.
  const onShapeChange = (newShapeValue: string) => {
    const center = boxCenter(cadMetadata.boundingBox);
    setShape((oldMod: meshgenerationpb.UserGeometryMod) => {
      const radius = getRadius(oldMod);
      switch (newShapeValue) {
        case ShapeType.SPHERE: {
          oldMod.farField = {
            case: 'sphere',
            value: new shapepb.Sphere({ center, radius }),
          };
          return oldMod;
        }
        case ShapeType.HALF_SPHERE: {
          oldMod.farField = {
            case: 'halfSphere',
            value: new shapepb.HalfSphere({ center, radius, normal: newProto(0, 0, 1) }),
          };
          return oldMod;
        }
        case ShapeType.CUBE: {
          oldMod.farField = {
            case: 'cube',
            value: defaultCube(center, 2 * radius),
          };
          return oldMod;
        }
        case ShapeType.CYLINDER: {
          oldMod.farField = {
            case: 'cylinder',
            value: defaultCylinder(center, radius),
          };
          return oldMod;
        }
        default:
          return oldMod;
      }
    });
  };

  // Define the radio buttons.
  const shapeOptions: RadioButtonOption<string>[] = [
    { label: 'Sphere', value: ShapeType.SPHERE },
    { label: 'Half-Sphere', value: ShapeType.HALF_SPHERE },
    { label: 'Box', value: ShapeType.CUBE },
    { label: 'Cylinder', value: ShapeType.CYLINDER },
  ];

  const applyScale = (scale: number) => {
    setShape((mod) => scaleModifier(scale, mod));
  };

  const experimentConfig = useEnabledExperiments();
  // TODO(LC-6917): remove when Parasolid meshing is enabled for all
  const allowParasolid = experimentConfig.includes(flags.parasolidMeshing);
  const lcsurfaceTessellation = experimentConfig.includes(flags.lcsurfaceTessellation);

  // Saves the edit modifier as the current modifier and create a request to update the geometry.
  const onSave = async () => {
    if (!editModifier) {
      return;
    }

    const inputUrl = meshUrlState.url;
    const getGeometryReq = new frontendpb.GetGeometryRequest({
      projectId,
      userGeo: new meshgenerationpb.UserGeometry({
        url: inputUrl,
        scaling: frontendMenuState.meshScaling,
        allowParasolid,
        lcsurfaceTessellation,
      }),
      userGeoMod: editModifier,
    });
    const getGeomOrder = new frontendpb.PendingWorkOrder({
      typ: {
        case: 'getGeometry',
        value: getGeometryReq,
      },
    });

    const req = new frontendpb.CheckGetGeometryDependenciesRequest({ request: getGeometryReq });
    const reply = await rpc.callRetry(
      'CheckGetGeometryContactsDependencies',
      rpc.client.checkGetGeometryDependencies,
      req,
    );

    const execute = () => {
      // Imposter updates are triggered by setting the state inside ParaviewManager.
      setCurrModifier(editModifier);
      setEditModifier(null);

      // The current URL must be checked. Remove it from checked URLs.
      setCheckedUrls((checkedUrls) => {
        const newUrlList = checkedUrls.urls.filter((url) => url !== meshUrlState.url);
        const newStatus = checkedUrls.status === projectstatepb.CheckGeometryStatus.SUCCESSFUL ?
          projectstatepb.CheckGeometryStatus.RECHECKING :
          projectstatepb.CheckGeometryStatus.UNCHECKED;
        return new projectstatepb.CheckedUrls({
          urls: newUrlList,
          status: newStatus,
        });
      });
      setPendingWorkOrders((workOrders: frontendpb.PendingWorkOrders) => {
        const newWorkOrders = workOrders.clone();
        const workOrdersMap = newWorkOrders.workOrders;
        // Delete any existing check geometry requests. They are checking old geometry.
        if (workOrdersMap[CHECK_GEOMETRY]) {
          delete workOrdersMap[CHECK_GEOMETRY];
        }
        if (!workOrdersMap[GET_GEOMETRY]) {
          workOrdersMap[GET_GEOMETRY] = getGeomOrder;
        }
        return newWorkOrders;
      });
    };

    if (!reply.allFound) {
      pushConfirmation(setConfirmStack, {
        continueLabel: 'Generate Far-Field',
        destructive: true,
        onContinue: () => {
          execute();
        },
        subtitle: `Generating a new Far-Field could potentially result in a corrupt
        project state. Please refer to the Release Notes dated May 16, 2024 for more
        details.

        As a workaround, you can create a new project (do not copy an existing
        project), upload the CAD file, and generate the new Far-Field.

        Do you want to continue?`,
        title: 'Warning',
      });
    } else {
      execute();
    }
  };

  const errors = validateFarfield(editModifier);

  // Remove the far field whenever the component unmounts
  useEffect(
    () => () => {
      if (lcvisEnabled) {
        lcvHandler.queueDisplayFunction('remove far field', (display) => {
          display.workspace?.removeFarField().catch((error) => console.warn(error));
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Delete the imposter when component unmounts, i.e. when the user clicks away.
  useEffect(() => () => {
    deleteFarFieldImposter(paraviewClientState);
  }, [paraviewClientState]);

  return (
    <div className={commonClasses.properties}>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          headerRight={(
            <EditButtons
              disableEdit={readOnly}
              disableSave={!!errors.length}
              editMode={inEditMode}
              onCancel={() => {
                setEditModifier(null);
                // If no options have been changed, i.e. the current cad modifier state is still
                // not intialized, we delete the node when leaving editing mode
                if (!initialized) {
                  setCurrModifier(null);
                }
                if (currModifier) {
                  // Delete the farfield imposter and reset the camera state so that the
                  // original model fits on the screen.
                  if (lcvisEnabled) {
                    lcvHandler.queueDisplayFunction('remove far field', (display) => {
                      display.workspace?.removeFarField().catch((error) => console.warn(error));
                    });
                  } else {
                    deleteFarFieldImposter(paraviewClientState);
                    paraviewRenderer.resetCamera();
                  }
                  if (visibility) {
                    // Restore the stored visibility of the farfield
                    const newVisibility = { ...visibility };
                    farfieldVisBackup.current.forEach((val, key) => {
                      newVisibility[key] = val;
                    });
                    setViewAttrs(
                      { blockVisibility: newVisibility },
                    );
                  }
                  if (lcvisEnabled) {
                    setLcvisVisibility((prev) => {
                      const newVisibility = new Map(prev);
                      farfieldVisBackup.current.forEach((val, key) => {
                        newVisibility.set(key, val);
                      });
                      return newVisibility;
                    });
                  }
                }
              }}
              onSave={async () => {
                if (willChangeBeDestructive(simParam, entityGroupData.groupMap)) {
                  // only display destructive warning when there are things that will ge destroyed
                  pushConfirmation(setConfirmStack, {
                    ...FARFIELD_CHANGE_BASE_DIALOG,
                    onContinue: onSave,
                  });
                } else {
                  await onSave();
                }
              }}
              onStartEdit={() => onStartEdit(currModifier!.clone())}
            />
          )}
          heading="Far-Field Definition"
          nodeId={FARFIELD_NODE_ID}
          panelName="definition">
          <Form.LabeledInput
            help="The shape of the far-field"
            label="Shape">
            <RadioButtonGroup
              disabled={!inEditMode}
              kind="secondary"
              name="farFieldShapes"
              onChange={onShapeChange}
              options={shapeOptions}
              value={shapeValue}
            />
          </Form.LabeledInput>
          {parameterPanel}
          {[ShapeType.CUBE, ShapeType.CYLINDER].includes(shapeValue as ShapeType) && (
            <Form.LabeledInput
              help="Scale the Parameters Up or Down"
              label="Scale">
              <ScaleButtons
                disabled={!inEditMode}
                onClick={applyScale}
                scaleValues={[0.5, 2]}
              />
            </Form.LabeledInput>
          )}
        </CollapsibleNodePanel>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            marginTop: '10px',
          }}>
          {errors.map((error) => (
            <SectionMessage
              key={error}
              level="error"
              message={error}
              title="Error in Far-Field settings"
            />
          ))}
        </div>
      </PropertiesSection>
    </div>
  );
};
