// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/assistant/assistant.proto (package luminary.proto.assistant, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { SimulationParam } from "../client/simulation_pb.js";

/**
 * a request for getting sessions associated with the current project
 *
 * @generated from message luminary.proto.assistant.GetChatSessionsRequest
 */
export const GetChatSessionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.GetChatSessionsRequest",
  () => [
    { no: 2, name: "scope", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * response from GetChatSessions
 *
 * @generated from message luminary.proto.assistant.GetChatSessionsResponse
 */
export const GetChatSessionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.GetChatSessionsResponse",
  () => [
    { no: 1, name: "session_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * request to NewChatSession
 *
 * @generated from message luminary.proto.assistant.NewChatSessionRequest
 */
export const NewChatSessionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.NewChatSessionRequest",
  () => [
    { no: 2, name: "scope", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * response from NewChatSession
 *
 * @generated from message luminary.proto.assistant.NewChatSessionResponse
 */
export const NewChatSessionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.NewChatSessionResponse",
  () => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * a request from user to assist.
 *
 * @generated from message luminary.proto.assistant.Request
 */
export const Request = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.Request",
  () => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cmd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "access", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "scope", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * responses from assistant to request.
 *
 * @generated from message luminary.proto.assistant.Reply
 */
export const Reply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.Reply",
  () => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "action", kind: "message", T: Action, oneof: "step" },
    { no: 5, name: "response", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "step" },
  ],
);

/**
 * a request by assistant to a user visible action
 *
 * @generated from message luminary.proto.assistant.Action
 */
export const Action = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.Action",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "params", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * a response to a action request
 *
 * @generated from message luminary.proto.assistant.Outcome
 */
export const Outcome = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.Outcome",
  () => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "val", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "result" },
    { no: 4, name: "err", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "result" },
  ],
);

/**
 * @generated from message luminary.proto.assistant.GetChatHistoryRequest
 */
export const GetChatHistoryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.GetChatHistoryRequest",
  () => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "scope", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.assistant.ChatHistoryEntry
 */
export const ChatHistoryEntry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.ChatHistoryEntry",
  () => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.assistant.GetChatHistoryResponse
 */
export const GetChatHistoryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.GetChatHistoryResponse",
  () => [
    { no: 1, name: "entries", kind: "message", T: ChatHistoryEntry, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.assistant.SimulationParamToSdkCodeRequest
 */
export const SimulationParamToSdkCodeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.SimulationParamToSdkCodeRequest",
  () => [
    { no: 1, name: "simulation_param", kind: "message", T: SimulationParam },
  ],
);

/**
 * @generated from message luminary.proto.assistant.SimulationParamToSdkCodeResponse
 */
export const SimulationParamToSdkCodeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.assistant.SimulationParamToSdkCodeResponse",
  () => [
    { no: 1, name: "sdk_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

