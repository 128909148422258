// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactChild, ReactElement, ReactNode, useCallback, useMemo } from 'react';

import cx from 'classnames';

import { IconName } from '../../lib/componentTypes/svgIcon';
import { colors } from '../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../lib/event';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import { CONTROL_HEIGHT, useCommonPropPanelRowStyles } from '../Theme/commonStyles';
import Tooltip from '../Tooltip';
import { CaretDownIcon } from '../svg/CaretDownIcon';
import Collapsible from '../transition/Collapsible';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      '--toggle-rotation': '0deg',
      '--cursor': 'pointer',
      '--header-text-color': colors.inputPlaceholderText,
      '--header-caret-color': colors.inputPlaceholderText,

      '&:not($disabled) $header:hover': {
        '--header-caret-color': 'white',
      },
    },
    collapsed: {
      '--toggle-rotation': '-90deg',
    },
    disabled: {
      '--cursor': 'auto',
      '--header-text-color': colors.disabledType,
      '--header-caret-color': colors.disabledType,
    },
    headerBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      cursor: 'var(--cursor)',
      outline: '1px solid transparent',
      outlineOffset: '2px',
      transition: 'background-color 250ms, outline 250ms',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      '$collapsed &': {
        borderRadius: '4px',
      },
      '&:focus-visible': {
        outlineColor: colors.purple200,
      },
    },
    // Contains the heading text and the toggle.
    header: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      overflow: 'hidden',
      minHeight: `${CONTROL_HEIGHT}px`,
    },
    primaryHeader: {
      padding: '10px',
    },
    heading: {
      flex: '1 1 auto',
      color: 'var(--header-text-color)',
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 600,
      display: 'flex',
      overflow: 'hidden',

      '&.allowOverflow': {
        overflow: 'visible',
      },
      '&.white': {
        fontWeight: 'inherit',
        color: 'inherit',
      },
    },
    headingText: {
      flex: '1 1 auto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '&.allowOverflow': {
        overflow: 'visible',
      },
    },
    primaryHeading: {
      color: colors.highEmphasisText,
      fontWeight: 700,
    },
    toggleSwitch: {
      flex: '0 0 auto',
      display: 'flex',
    },
    toggle: {
      color: 'var(--header-caret-color)',
      flex: '0 0 auto',
      display: 'inline-flex',
      transform: 'rotate(var(--toggle-rotation))',
      transition: 'transform 250ms, color 500ms',
    },
    headingIcon: {
      flex: '0 0 auto',
      display: 'flex',
    },
    content: {
      paddingTop: '8px',
      margin: 0,
      border: 0,
    },
  }),
  { name: 'CollapsiblePanel' },
);

export interface PanelEnablement {
  enabled: boolean;
  onToggle: (enabled: boolean) => void;
  readOnly?: boolean;
}

interface HeadingIcon {
  name: IconName;
  color?: string;
  tooltip?: string;
}

export interface BaseProps {
  // Heading to display in the header bar
  heading: string | ReactChild;
  // Optional icon to display after heading text
  headingIcon?: HeadingIcon;
  // If this is the primary heading for a floating card instead of one part of a panel. This gives
  // greater weight to the heading.
  primaryHeading?: boolean;
  // Tooltip for heading text
  help?: string;
  // Disables interactivity of the header bar; content cannot be collapsed or
  // expanded
  disabled?: boolean;
  // Set the animation transition duration.
  transitionPeriod?: number;
  // An optional element that appears on the right side of the header bar.
  headerRight?: ReactNode;
  // Optionally force expand when disabled is true
  expandWhenDisabled?: boolean;
  // Optional element that is displayed only when the panel is collapsed
  collapsedContent?: ReactElement;
  // Optionally impose a prop panel row layout (a 60/40 split) between the main header and the
  // headerRight content
  headerAsPanelRow?: boolean;
  displayChevron?: boolean;
  allowHeaderOverflow?: boolean;
  headerClass?: string;
  collapsible?: boolean;
  whiteHeader?: boolean;
}

export interface CollapsiblePanelProps extends BaseProps {
  // Specifies the collapsed state (default: false)
  collapsed: boolean;
  // Listener for collapsed state change
  onToggle?: (collapsed: boolean) => void;
  children: ReactNode;
}

/** The CollapsiblePanel component presents content with an interactive header
 *  bar that can be clicked to collapse and expand the content
 */
export const CollapsiblePanel = (props: CollapsiblePanelProps) => {
  const {
    collapsed,
    disabled,
    expandWhenDisabled = false,
    heading,
    headingIcon,
    help,
    primaryHeading = false,
    onToggle,
    transitionPeriod = 250,
    collapsedContent,
    headerAsPanelRow = false,
    displayChevron = true,
    allowHeaderOverflow = false,
    headerClass,
    collapsible = true,
  } = props;

  const classes = useStyles();
  const commonRowClasses = useCommonPropPanelRowStyles();

  const toggle = useCallback(() => {
    if (disabled || !collapsible) {
      return;
    }
    if (onToggle) {
      onToggle(!collapsed);
    }
  }, [collapsed, disabled, onToggle, collapsible]);

  const isCollapsed = useMemo(() => {
    if (expandWhenDisabled && disabled) {
      return false;
    }
    return collapsed;
  }, [disabled, expandWhenDisabled, collapsed]);

  // Jumping through hoops to satisfy jsx-a11y/no-static-element-interactions,
  // which is useful but not smart enough to understand when a control is
  // disabled
  const enabledHeaderAttrs = {
    onClick: toggle,
    onKeyUp: (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (isUnmodifiedSpaceKey(event)) {
        toggle();
      }
    },
    role: 'tab',
    tabIndex: 0,
  };

  return (
    <div className={cx(
      classes.root,
      { [classes.collapsed]: isCollapsed, [classes.disabled]: disabled },
    )}>
      <div
        aria-disabled={disabled}
        className={cx(
          classes.headerBar,
          headerClass,
          { [commonRowClasses.root]: headerAsPanelRow },
        )}
        {...!disabled && enabledHeaderAttrs}>
        <div className={cx(classes.header, { [classes.primaryHeader]: primaryHeading })}>
          {displayChevron && (
            <div className={classes.toggle}>
              <CaretDownIcon maxWidth={6} />
            </div>
          )}
          <div
            className={cx(
              classes.heading,
              props.whiteHeader && 'white',
              allowHeaderOverflow && 'allowOverflow',
              { [classes.primaryHeading]: primaryHeading },
            )}>
            <Tooltip title={help || ''}>
              <div
                className={cx(classes.headingText, allowHeaderOverflow && 'allowOverflow')}>
                {heading}
              </div>
            </Tooltip>
          </div>
          {headingIcon && (
            <Tooltip title={headingIcon.tooltip || ''}>
              <div className={classes.headingIcon}>
                <SvgIcon
                  color={headingIcon.color}
                  maxHeight={12}
                  maxWidth={12}
                  name={headingIcon.name}
                />
              </div>
            </Tooltip>
          )}
        </div>
        {props.headerRight && (
          <div
            onClick={(event) => event.stopPropagation()}
            onKeyUp={(event) => event.stopPropagation()}
            role="presentation">
            {props.headerRight}
          </div>
        )}
      </div>
      {collapsible && (
        <Collapsible allowOverflow collapsed={isCollapsed} transitionPeriod={transitionPeriod}>
          <div className={cx({ [classes.content]: !primaryHeading })}>
            {props.children}
          </div>
        </Collapsible>
      )}
      {collapsedContent && (
        <Collapsible
          allowOverflow
          collapsed={!isCollapsed}
          transitionPeriod={transitionPeriod}>
          {collapsedContent}
        </Collapsible>
      )}
    </div>
  );
};
