export const LCVIS_MIN_CONTRAST_VALUE = 0.2;
export const LCVIS_MAX_CONTRAST_VALUE = 3;

export const lcvisToParaviewContrast = (value: number) => Math.round((
  (value - LCVIS_MIN_CONTRAST_VALUE) / (LCVIS_MAX_CONTRAST_VALUE - LCVIS_MIN_CONTRAST_VALUE)
) * 100);

export const paraviewToLcvisContrast = (value: number) => (
  (Math.round(value) / 100) * (LCVIS_MAX_CONTRAST_VALUE - LCVIS_MIN_CONTRAST_VALUE) +
  LCVIS_MIN_CONTRAST_VALUE
);
