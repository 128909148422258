import BaseError from '@/errors/BaseError';
import { ERRORS } from '@/lib/errors';

/**
 * PreparedError indicates an error that can be presented directly to a customer.
 */
export default class PreparedError extends BaseError {
  public name = 'PreparedError';

  constructor(message?: string, source?: Error) {
    super(message || ERRORS.Default, source);
  }
}
