// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import useButtonMenu from '../../lib/useButtonMenu';
import { IconButton } from '../Button/IconButton';
import { SvgIcon } from '../Icon/SvgIcon';
import { CommonMenu } from '../Menu/CommonMenu';
import { createStyles, makeStyles } from '../Theme';
import Tooltip, { TooltipProps } from '../Tooltip';
import { useSelectionContext } from '../context/SelectionManager';
import { PlusIcon } from '../svg/PlusIcon';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconButton: {
      color: 'currentColor',
      padding: 0,
      backgroundColor: 'transparent',
      transition: 'background-color 250ms',
      '&:hover': {
        backgroundColor: 'rgb(255, 255, 255, 0.15)',
      },
    },
  }),
  { name: 'AddButton' },
);

interface AddNodeButtonProps {
  // Function for adding a node.
  addNode: () => void;
  disabled?: boolean;
  tooltip?: string;
}

// A button for adding a particular node.
export const AddNodeButton = (props: AddNodeButtonProps) => {
  const { addNode, tooltip, disabled } = props;

  const { isTreeModal } = useSelectionContext();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip title={tooltip || ''}>
        <div className={classes.buttonWrapper}>
          <IconButton
            className={classes.iconButton}
            data-locator="add-node-button"
            disabled={isTreeModal || disabled}
            onClick={(event) => {
              event.stopPropagation();
              addNode();
            }}
            title="add-node">
            <PlusIcon maxHeight={12} maxWidth={12} />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

interface AddNodeMenuButtonProps {
  disabled?: boolean,
  // A set of menu options to present to the user when the button is clicked
  menuItems: CommonMenuItem[];
  maxWidth?: number;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
}

// A button that exposes a menu for adding to a tree node.
export const AddNodeMenuButton = (props: AddNodeMenuButtonProps) => {
  const { disabled = false, menuItems, tooltip, tooltipPlacement } = props;

  const { anchorEl, anchorRef, onClickButton, onCloseMenu } = useButtonMenu();
  const { isTreeModal } = useSelectionContext();

  const classes = useStyles();
  // Disabling the icon prevents the menu from being shown under the tool tip that tells the user
  // that the interaction isn't supported till some condition is met.
  const iconDisabled = disabled || isTreeModal;

  return (
    <div className={classes.root} ref={anchorRef}>
      <Tooltip title={tooltip || ''}>
        <div className={classes.buttonWrapper}>
          <IconButton
            className={classes.iconButton}
            data-locator="add-node-menu-button"
            disabled={iconDisabled}
            onClick={onClickButton}>
            <SvgIcon maxHeight={12} maxWidth={12} name="plus" />
          </IconButton>
          {menuItems && (
            <CommonMenu
              anchorEl={anchorEl}
              closeOnSelect
              maxWidth={props.maxWidth}
              menuItems={menuItems}
              onClose={onCloseMenu}
              open={!!anchorEl}
              position="below-left"
              tooltipPlacement={tooltipPlacement}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
};
