// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { MultipleChoiceParam } from '../../../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../../../SimulationParamDescriptor';
import assert from '../../../../lib/assert';
import { SelectOption } from '../../../../lib/componentTypes/form';
import { unwrapSurfaceIds } from '../../../../lib/entityGroupUtils';
import { NodeType, TAGS_NODE_TYPES } from '../../../../lib/simulationTree/node';
import { findSlidingInterfaceById } from '../../../../lib/slidingInterfaceUtils';
import { wordsToList } from '../../../../lib/text';
import { getVolumeIdsFromSurfaces, volumeIdsToNames } from '../../../../lib/volumeUtils';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { useEntityGroupData, useEntityGroupMap } from '../../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { useSimulationTreeSubselect } from '../../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../../recoil/volumes';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useSlidingInterfaceSelection } from '../../../hooks/subselect/useSlidingInterfaceSelection';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { SectionMessage } from '../../../notification/SectionMessage';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';

function getInterfaceMethodOptions(
  selectedValue?: simulationpb.InterfaceType,
): SelectOption<simulationpb.InterfaceType>[] {
  return (paramDesc[ParamName.InterfaceType] as MultipleChoiceParam).choices.map((choice) => ({
    name: choice.text,
    tooltip: choice.help,
    value: choice.enumNumber,
    selected: choice.enumNumber === selectedValue,
  }));
}

interface InterfaceSideSurfacesProps {
  sideA?: boolean;
  readOnly?: boolean;
}

const getInterfaceSubselectId = (sideA: boolean) => `motion-interface-side-${sideA ? 'A' : 'B'}`;

const InterfaceSideSurfaces = (props: InterfaceSideSurfacesProps) => {
  const { readOnly, sideA = false } = props;
  const { selectedNode: node } = useSelectionContext();

  const { setSurfaces, nodeFilter, nodesIdsRollUp } = useSlidingInterfaceSelection({ sideA });

  return (
    <NodeSubselect
      id={getInterfaceSubselectId(sideA)}
      independentSelection
      labels={['surfaces']}
      nodeFilter={nodeFilter}
      nodeIds={nodesIdsRollUp}
      onChange={setSurfaces}
      readOnly={readOnly}
      referenceNodeIds={[node?.id || '']}
      title="Surfaces"
      visibleTreeNodeTypes={[NodeType.SURFACE, NodeType.SURFACE_GROUP, ...TAGS_NODE_TYPES]}
    />
  );
};

export const PhysicsSlidingInterfacePropPanel = () => {
  // Context
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected sliding interface row');
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // Recoil state
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // Custom hooks
  const treeSubselect = useSimulationTreeSubselect();

  // Other variables
  const propsClasses = useCommonTreePropsStyles();
  const { simParam, saveParam } = useSimulationConfig();
  const slidingInterface = useMemo(
    () => findSlidingInterfaceById(simParam, node.id),
    [node.id, simParam],
  );
  assert(!!slidingInterface, 'No selected sliding interface');

  const interfaceId = slidingInterface.slidingInterfaceId;
  const sideA = slidingInterface.slidingA;
  const sideB = slidingInterface.slidingB;

  // Form data
  const methodOptions = getInterfaceMethodOptions(slidingInterface.interfaceType);
  const volumesA = getVolumeIdsFromSurfaces(
    unwrapSurfaceIds(sideA || [], geometryTags, entityGroupData),
    staticVolumes,
  );
  const volumesB = getVolumeIdsFromSurfaces(
    unwrapSurfaceIds(sideB || [], geometryTags, entityGroupData),
    staticVolumes,
  );

  // Handlers
  const handleChangeMethod = (value: simulationpb.InterfaceType) => {
    saveParam((newParam) => {
      const newInterface = findSlidingInterfaceById(newParam, interfaceId);
      if (newInterface) {
        newInterface.interfaceType = value;
      }
    });
  };

  return (
    <div>
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Interface"
          nodeId={node.id}
          panelName="interface">
          <LabeledInput
            label="Method">
            <DataSelect
              asBlock
              disabled={readOnly}
              onChange={handleChangeMethod}
              options={methodOptions}
              size="small"
            />
          </LabeledInput>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        {(volumesA.length > 1 || volumesB.length > 1) && (
          <div className={propsClasses.sectionMessages}>
            <SectionMessage
              level="warning"
              message="Each interface side can only hold surfaces that belong to the same volume."
            />
          </div>
        )}
        <CollapsibleNodePanel
          disabled={treeSubselect.id === getInterfaceSubselectId(true)}
          heading="Side A"
          nodeId={node.id}
          panelName="sideA">
          <div className={propsClasses.sectionDescription}>
            Select all surfaces that represent one side of the interface
          </div>
          <InterfaceSideSurfaces
            readOnly={readOnly}
            sideA
          />
          {!!volumesA.length && (
            <div className={propsClasses.nodeTableFooter}>
              Connected Volumes: {wordsToList(volumeIdsToNames(volumesA, entityGroupMap))}
            </div>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={treeSubselect.id === getInterfaceSubselectId(false)}
          heading="Side B"
          nodeId={node.id}
          panelName="sideB">
          <div className={propsClasses.sectionDescription}>
            Select all surfaces that represent the other side of the interface
          </div>
          <InterfaceSideSurfaces
            readOnly={readOnly}
          />
          {!!volumesB.length && (
            <div className={propsClasses.nodeTableFooter}>
              Connected Volumes: {wordsToList(volumeIdsToNames(volumesB, entityGroupMap))}
            </div>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
