// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import cx from 'classnames';

import { addRpcError } from '../../lib/transientNotification';
import useFormValidation from '../../lib/useFormValidation';
import Form from '../Form';
import { TextInput } from '../Form/TextInput';
import { createStyles, makeStyles } from '../Theme';
import { AutoCollapsingMessage } from '../visual/AutoCollapsingMessage';

import { Dialog } from './Base';

const useStyles = makeStyles(
  () => createStyles({
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
  }),
  { name: 'CreatePostProcessingExtractDialog' },
);

interface CreatePostProcessingExtractMenuProps {
  // Whether the dialog is open.
  isOpen: boolean,
  // Cancels the dialog modifications.
  onCancel: () => void,
  // Callback used when the user hits on Apply .
  onSubmit: (name: string) => Promise<void>,
  title?: string;
}

const CreatePostProcessingExtractDialog = (props: CreatePostProcessingExtractMenuProps) => {
  // == Props
  const { isOpen, title = 'Create a post-processing extract' } = props;

  // == Hooks
  const classes = useStyles();

  // == Data
  const [name, setName] = useState('');
  // Used to avoid calling multiple onContinue in quick succession.
  const [submitting, setSubmitting] = useState(false);

  const { validate, errors } = useFormValidation([{
    key: 'name',
    value: name,
    required: true,
  }]);

  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{
        label: 'Create',
        name: 'create-post-processing-extract',
        disabled: !name,
      }}
      controlState={submitting ? 'working' : 'normal'}
      modal
      onClose={props.onCancel}
      onContinue={async () => {
        if (validate() && !submitting) {
          setSubmitting(true);

          try {
            await props.onSubmit(name);
          } catch (error) {
            addRpcError('', error);
          } finally {
            setSubmitting(false);
          }
        }
      }}
      open={isOpen}
      subtitle="Set the new post-processing extract name"
      title={title}
      width="600px">
      <div>
        <div className={classes.leftSide}>
          <div className={cx(classes.inputs)}>
            <div className={classes.input}>
              <Form.Label>Name</Form.Label>
              <TextInput
                autoFocus
                dataPrivate
                disabled={submitting}
                faultType={errors.name ? 'error' : undefined}
                name="name"
                onChange={setName}
                placeholder="Name"
                value={name}
              />
              <AutoCollapsingMessage level="error" message={errors.name} />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CreatePostProcessingExtractDialog;
