// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { getTypeLabel } from '../../lib/materialUtils';
import { typesToAdd } from '../../model/hooks/useMaterials';
import { useGeometryStatus } from '../hooks/useGeometryStatus';
import { useMaterialAdd } from '../hooks/useMaterialAdd';
import { useNonEditableReason } from '../hooks/useNonEditableReason';

import { AddNodeMenuButton } from './AddNodeButton';

export const AddMaterialButton = () => {
  // == Hooks
  const { addMaterialNode } = useMaterialAdd();
  const { working } = useGeometryStatus();
  const nonEditableReason = useNonEditableReason('add Materials');

  const menuItems: CommonMenuItem[] = typesToAdd.map((type) => ({
    label: getTypeLabel(type),
    onClick: () => addMaterialNode(type),
  }));

  return (
    <AddNodeMenuButton
      disabled={working || !!nonEditableReason}
      menuItems={menuItems}
      tooltip={nonEditableReason}
    />
  );
};
