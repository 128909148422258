// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 51;
const baseHeight = 51;

export const PaperIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      fill="none"
      height={finalHeight}
      ref={ref}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M43.8281 5.57812H16.7344C15.4163 5.57812 14.3438 6.65072 14.3438 7.96875V36.6562
        H4.78125V39.8438C4.78125 42.9189 7.28344 45.4219 10.3594 45.4219H40.6406H40.643C42.1021
        45.4219 43.4743 44.8665 44.5246 43.8472C45.6171 42.7874 46.2188 41.3658 46.2188 39.8438
        V13.5469V11.1562V7.96875C46.2188 6.65072 45.1462 5.57812 43.8281 5.57812ZM16.7344 7.17188
        H43.8281C44.2672 7.17188 44.625 7.52887 44.625 7.96875V8.91225C44.3748 8.823 44.1086 8.76562
        43.8281 8.76562H16.7344C16.4539 8.76562 16.1877 8.823 15.9375 8.91225V7.96875C15.9375
        7.52887 16.2953 7.17188 16.7344 7.17188ZM10.3594 43.8281C8.16239 43.8281 6.375 42.0407 6.375
        39.8438 V38.25H35.0625V39.6429C35.0625 41.2765 35.7382 42.7691 36.8132 43.8281H10.3594Z
        M44.625 39.8438C44.625 40.9307 44.1947 41.9467 43.4145 42.7037C42.6344 43.46 41.6104 43.8592
        40.5171 43.8257C38.3887 43.762 36.6562 41.8861 36.6562 39.6429V36.6562H15.9375V11.1562
        C15.9375 10.7164 16.2953 10.3594 16.7344 10.3594H43.8281C44.2672 10.3594 44.625 10.7164
        44.625 11.1562V39.8438Z"
        fill={getColor(0)}
      />
      <path
        d="M17.5312 18.3281V12.75C17.5312 12.3101 17.8883 11.9531 18.3281 11.9531H23.9062L17.5312
        18.3281Z"
        fill={getColor(0)}
      />
    </svg>
  );
});
