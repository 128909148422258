import { useCallback } from 'react';

import { useProjectContext } from '@/components/context/ProjectContext';
import { useSetPanel } from '@/recoil/expandedPanels';

/**
 * Hook that hides the geometry tree, modification tree, and simulation tree.
 * Returns a function that restores the previous visibility state.
 */
export const usePanelsHider = () => {
  const { projectId } = useProjectContext();

  const setGeometryTreePanelVisible = useSetPanel({
    nodeId: `geometry-tree-${projectId}`,
    panelName: 'geometry-tree',
    defaultExpanded: true,
  });

  const setSimTreePanelVisible = useSetPanel({
    nodeId: `floating-sim-tree-${projectId}`,
    panelName: 'floating-sim-tree',
    defaultExpanded: true,
  });

  const setModificationTreePanelVisible = useSetPanel({
    nodeId: `modification-tree-${projectId}`,
    panelName: 'modification-tree',
    defaultExpanded: true,
  });

  return useCallback(() => {
    const panelSetters = [
      setGeometryTreePanelVisible,
      setSimTreePanelVisible,
      setModificationTreePanelVisible,
    ];
    const currentValues: boolean[] = [];

    panelSetters.forEach((setPanelExpanded, index) => setPanelExpanded((currentValue) => {
      currentValues[index] = currentValue;

      return false;
    }));

    return () => {
      panelSetters.forEach((setPanelExpanded, index) => {
        const previousValue = currentValues[index] ?? true;

        setPanelExpanded(previousValue);
      });
    };
  }, [setGeometryTreePanelVisible, setModificationTreePanelVisible, setSimTreePanelVisible]);
};
