// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Choice } from '../../../../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../../../../SimulationParamDescriptor';
import assert from '../../../../lib/assert';
import { SelectOption } from '../../../../lib/componentTypes/form';
import { protoChoicesToSelectOptions } from '../../../../lib/form';
import { parsePhysicsIdFromSubId } from '../../../../lib/physicsUtils';
import { useFluidPhysics } from '../../../../model/hooks/useFluidPhysics';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { useSimulationParamScope } from '../../../../state/external/project/simulation/paramScope';
import { DataSelect } from '../../../Form/DataSelect';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { ParamForm } from '../../../ParamForm';
import ParamRow from '../../../ParamRow';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import PropertiesSection from '../../PropertiesSection';

const paramViscousModel = paramDesc[ParamName.ViscousModel];
const paramTurbulence = paramGroupDesc[ParamGroupName.Turbulence];
const paramTurbulenceModel = paramDesc[ParamName.TurbulenceModel];
const paramSubGridScaleModel = paramDesc[ParamName.SubGridScaleModel];

export const PhysicsFluidPropPanel = () => {
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected fluid physics row');

  // Recoil
  const physicsId = parsePhysicsIdFromSubId(node.id);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // Hooks
  const propClasses = useCommonTreePropsStyles();
  const {
    physics,
    getParamScope,
    adjointSolutionControls,
    viscousModel,
    setViscousModel,

    turbulence,
    setTurbulence,
    turbulenceModel,
    setTurbulenceModel,
    subGridScaleModel,
    setSubGridScaleModel,
  } = useFluidPhysics(projectId, workflowId, jobId, readOnly, physicsId);

  if (!physics || !turbulence || !adjointSolutionControls) {
    return null;
  }

  const physicsScope = getParamScope(paramScope);

  const turbulenceModelChoices = physicsScope.enabledChoices(paramTurbulenceModel);
  const turbulenceModelOptions: SelectOption<Choice['enumNumber']>[] = protoChoicesToSelectOptions(
    turbulenceModelChoices,
    turbulenceModel,
  );

  const subGridScaleModelChoices = physicsScope.enabledChoices(paramSubGridScaleModel);
  const subGridScaleModelOptions: SelectOption<Choice['enumNumber']>[] =
      protoChoicesToSelectOptions(
        subGridScaleModelChoices,
        subGridScaleModel,
      );

  const showTurbulence = !!viscousModel && [
    simulationpb.ViscousModel.DES,
    simulationpb.ViscousModel.RANS,
    simulationpb.ViscousModel.LES,
  ].includes(viscousModel);

  return (
    <div className={propClasses.properties}>
      <PropertiesSection>
        <ParamRow
          enabledChoices={physicsScope.enabledChoices(paramViscousModel)}
          nestLevel={0}
          param={paramViscousModel}
          readOnly={readOnly}
          setValue={setViscousModel}
          value={viscousModel}
        />
      </PropertiesSection>
      {showTurbulence && (
      <>
        <Divider />
        <PropertiesSection>
          <CollapsibleNodePanel
            headerAsPanelRow
            headerRight={viscousModel === simulationpb.ViscousModel.LES ?
              (
                <DataSelect
                  asBlock
                  disabled={readOnly}
                  onChange={setSubGridScaleModel}
                  options={subGridScaleModelOptions}
                  size="small"
                />
              ) :
              (
                <DataSelect
                  asBlock
                  disabled={readOnly}
                  onChange={setTurbulenceModel}
                  options={turbulenceModelOptions}
                  size="small"
                />
              )}
            heading="Turbulence"
            nodeId={physicsId}
            panelName="turbulence"
            panelOptions={{ defaultExpanded: false }}>
            <ParamForm<simulationpb.Turbulence>
              group={paramTurbulence}
              onUpdate={setTurbulence}
              paramScope={physicsScope}
              proto={turbulence}
              readOnly={readOnly}
              removeParams={['TurbulenceModel', 'SubGridScaleModel']}
            />
          </CollapsibleNodePanel>
        </PropertiesSection>
      </>
      )}
    </div>
  );
};
