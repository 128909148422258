import React from 'react';

import cx from 'classnames';

import { SvgIcon } from '@/components/Icon/SvgIcon';
import { createStyles, makeStyles } from '@/components/Theme';
import Tag from '@/components/treePanel/Tag';
import { SvgIconSpec } from '@/lib/componentTypes/svgIcon';
import { colors } from '@/lib/designSystem';

const useSuggestedListStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'inline-flex',
      flexDirection: 'row',
      gap: '8px',

      background: colors.neutral250,
      color: colors.highEmphasisText,
      border: `1px solid ${colors.neutral300}`,
      fontSize: '13px',
      padding: '16px',
      borderRadius: '4px',
      cursor: 'pointer',
      alignItems: 'flex-start',
      textAlign: 'left',

      '&:hover': {
        background: colors.neutral300,
        border: `1px solid ${colors.neutral350}`,
      },

      '&.disabled': {
        background: colors.neutral300,
        color: colors.neutral700,
        cursor: 'auto',
      },
    },
  }),
  { name: 'SuggestedCard' },
);

type SuggestedCardProps = {
  text: string;
  icon?: SvgIconSpec;
  onClick?: () => void;
  disabled?: boolean;
  tag?: string;
}

export const SuggestedCard = (props: SuggestedCardProps) => {
  // == Props
  const { text, icon, onClick, disabled, tag } = props;

  // == Hooks
  const classes = useSuggestedListStyles();

  return (
    <button
      className={cx(classes.root, { disabled })}
      onClick={() => {
        if (!disabled) {
          onClick?.();
        }
      }}
      type="button">
      {icon && (
        <span>
          <SvgIcon
            maxHeight={icon.maxHeight ?? 14}
            maxWidth={icon.maxWidth ?? 14}
            name={icon.name}
          />
        </span>
      )}
      {text}
      {tag && (
        <Tag kind="primary" size="small" text={tag} />
      )}
    </button>
  );
};
