import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import * as flags from '../flags';

import NotFoundPage from './NotFoundPage';

import { ActionButton } from '@/components/Button/ActionButton';
import suspenseWidget from '@/components/SuspenseWidget';
import { createStyles, makeStyles } from '@/components/Theme';
import { useFrodoAssistantEmitter } from '@/components/hooks/assistant/useFrodoAssistantEmitter';
import { getLcUserIdFromToken } from '@/lib/jwt';
import { routes } from '@/lib/navigation';
import { projectListState } from '@/recoil/state';
import { useIsEnabled } from '@/recoil/useExperimentConfig';
import { useAuthInfoV2Value } from '@/state/external/auth/authInfo';

const useStyles = makeStyles(
  () => createStyles({
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },

  }),
  { name: 'LibraryPlaceholder' },
);

const FrodoPageContent = ({ projectId, userId }: { projectId: string; userId: string }) => {
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);
  const [loadState, setLoadState] = useState<'loading' | 'error' | 'success'>('loading');
  const classes = useStyles();
  const eventEmitter = useFrodoAssistantEmitter(projectId);

  useEffect(() => {
    let unmount = () => {};

    // eslint-disable-next-line import/no-unresolved
    import('@luminarycloudinternal/frodo').then(({ renderFrodoApp }) => {
      setLoadState('success');

      if (containerRef.current) {
        unmount = renderFrodoApp(containerRef.current, { userId, eventEmitter });
      }
    }).catch((err) => {
      setLoadState('error');
    });

    return () => {
      unmount();
    };
  }, [userId, navigate, eventEmitter, projectId]);

  return (
    <>
      {loadState === 'loading' && suspenseWidget}
      {loadState === 'error' && (
      <div className={classes.contentWrapper}>
        <div>Could not load Frodo app</div>
        <ActionButton
          onClick={() => {
            navigate(routes.projectList);
          }}>
          Back
        </ActionButton>
      </div>
      )}

      <div ref={containerRef} />
    </>
  );
};

export const FrodoPage = () => {
  const isFrodoEnabled = useIsEnabled(flags.frodo);
  const authInfoValue = useAuthInfoV2Value();
  const userId = useMemo(() => getLcUserIdFromToken(authInfoValue.jwt), [authInfoValue.jwt]);
  const [projectList] = useRecoilState(projectListState);

  if (!projectList) {
    return suspenseWidget;
  }

  // TODO(bart): this is pretty stupid: the AI assistant demands a project,
  // so I just grab the first one I can find; if there’s no project, Frodo just refuses to load.
  //
  // we should probably figure out a better way to pass reactive data to Frodo,
  // but for experiments, this dumb hack just works
  const firstProjectId = projectList?.project.at(0)?.projectId;

  const shouldShowFrodo = isFrodoEnabled && !!userId && firstProjectId;

  if (!shouldShowFrodo) {
    return <NotFoundPage />;
  }

  return <FrodoPageContent projectId={firstProjectId} userId={userId} />;
};
