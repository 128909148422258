// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useMemo } from 'react';

import { checkBounds } from '../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../SimulationParamDescriptor';
import { ParamScope, chainParamScopes } from '../../lib/ParamScope';
import { getAdValue, newAdFloat } from '../../lib/adUtils';
import {
  changeRoughnessControl,
  changeRoughnessValue,
  findFluidBoundaryCondition,
  findParentPhysicsByBoundaryConditionId,
  isBoundaryConditionCompatibleWithRoughness,
  isDependentBoundaryCondition,
} from '../../lib/boundaryConditionUtils';
import { getPhysicsMaterialIds } from '../../lib/entityRelationships';
import { getMaterialsByIdFromMap } from '../../lib/materialUtils';
import { findMultiphysicsInterfaceById } from '../../lib/multiphysicsInterfaceUtils';
import { getPhysicsId } from '../../lib/physicsUtils';
import { lowerFirst } from '../../lib/text';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useStaticVolumes } from '../../recoil/volumes';
import { useSimulationMaterialsMap } from '../../state/external/project/simulation/param/materials';

import { usePhysicsSet } from './usePhysicsSet';
import { useWorkflowConfig } from './useWorkflowConfig';

const roughnessDesc = paramDesc[ParamName.EquivalentSandGrainRoughness];

/**
 * A model hook for managing an individual fluid boundary condition
 * @param projectId
 * @param workflowId
 * @param jobId
 * @param readOnly
 * @param id
 * @returns
 */
export const useFluidBoundaryCondition = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string, // Boundary condition ID
) => {
  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const materialDataMap = useSimulationMaterialsMap(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);

  // == Model hooks
  const { saveBoundaryConditionAsync } = usePhysicsSet(projectId, workflowId, jobId, readOnly);

  // == Custom hooks
  const { simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  // Memoize the boundary condition object itself
  const boundaryCondition = useMemo(() => findFluidBoundaryCondition(simParam, id), [id, simParam]);

  // If the boundary condition is compatible, then return true to expose roughness form inputs.
  const hasRoughnessInputs = useMemo(
    () => !!boundaryCondition && isBoundaryConditionCompatibleWithRoughness(boundaryCondition),
    [boundaryCondition],
  );

  // Validate the roughness value
  const roughnessWarning = useMemo(() => {
    const roughness = getAdValue(boundaryCondition?.equivalentSandGrainRoughness);
    const warning = checkBounds(roughnessDesc, roughness);
    if (warning) {
      return `Roughness ${lowerFirst(warning)}`;
    }
    return '';
  }, [boundaryCondition]);

  // When saving any boundary condition that's *not* compatible with roughness, reset the roughness
  // properties (roughness_control: off, roughness_value: 0).
  const evaluateRoughness = useCallback((param: simulationpb.SimulationParam) => {
    const newBoundaryCondition = findFluidBoundaryCondition(param, id);
    if (newBoundaryCondition && !isBoundaryConditionCompatibleWithRoughness(newBoundaryCondition)) {
      newBoundaryCondition.roughnessControl = false;
      newBoundaryCondition.equivalentSandGrainRoughness = newAdFloat(0);
    }
  }, [id]);

  // Replaces the boundary condition object with a new one, preserving order and saving the updated
  // param
  const replaceBoundaryCondition = useCallback(
    async (newBc: simulationpb.BoundaryConditionsFluid) => saveBoundaryConditionAsync(
      (newParam) => {
        const physics = findParentPhysicsByBoundaryConditionId(newParam, id);
        if (physics?.params.case === 'fluid') {
          const fluid = physics.params.value;
          fluid.boundaryConditionsFluid = fluid.boundaryConditionsFluid.map((oldBc) => {
            if (oldBc.boundaryConditionName === id) {
              return newBc;
            }
            return oldBc;
          });
          evaluateRoughness(newParam);
          return true;
        }
        return false;
      },
    ),
    [evaluateRoughness, id, saveBoundaryConditionAsync],
  );

  // Apply an update function on the boundary condition and save the updated param
  const saveBoundaryCondition = useCallback(
    async (update: (newBc: simulationpb.BoundaryConditionsFluid) => void) => (
      saveBoundaryConditionAsync(
        (newParam) => {
          const newBoundaryCondition = findFluidBoundaryCondition(newParam, id);
          if (newBoundaryCondition) {
            update(newBoundaryCondition);
            evaluateRoughness(newParam);
            return true;
          }
          return false;
        },
      )
    ),
    [evaluateRoughness, id, saveBoundaryConditionAsync],
  );

  // Update the boundary condition's roughness control and save the updated param
  const saveRoughnessControl = async (on: boolean) => saveBoundaryCondition(
    (newBoundaryCondition) => changeRoughnessControl(newBoundaryCondition, on),
  );

  // Update the boundary condition's roughness value and save the updated param
  const saveRoughnessValue = async (value: number) => saveBoundaryCondition(
    (newBoundaryCondition) => changeRoughnessValue(newBoundaryCondition, value),
  );

  // Dependent boundary conditions are created as side effects of multiphysics coupling interfaces,
  // and the user's ability to modify them is limited.
  const isDependent = useMemo(
    () => (!!boundaryCondition && isDependentBoundaryCondition(boundaryCondition)),
    [boundaryCondition],
  );

  const couplingInterface = useMemo(() => {
    const interfaceId = boundaryCondition?.boundaryConditionInterfaceId;
    if (interfaceId) {
      return findMultiphysicsInterfaceById(simParam, interfaceId);
    }
    return undefined;
  }, [boundaryCondition, simParam]);

  const physics = useMemo(
    () => findParentPhysicsByBoundaryConditionId(simParam, id),
    [id, simParam],
  );

  // Find the singular fluid material associated with this BC's parent physics
  const material = useMemo(() => {
    if (physics) {
      const physicsId = getPhysicsId(physics);
      const materialIds = getPhysicsMaterialIds(simParam, physicsId, geometryTags, staticVolumes);
      const materials = getMaterialsByIdFromMap(materialDataMap, materialIds);
      if (materials.length === 1) {
        return materials[0];
      }
    }
    return undefined;
  }, [materialDataMap, physics, simParam, geometryTags, staticVolumes]);

  const getParamScope = useCallback((paramScope: ParamScope) => chainParamScopes(
    [material?.model, physics, boundaryCondition],
    experimentConfig,
    paramScope,
  ), [boundaryCondition, experimentConfig, material, physics]);

  return {
    boundaryCondition,
    getParamScope,
    isDependent,
    replaceBoundaryCondition,
    saveBoundaryCondition,
    couplingInterface,

    hasRoughnessInputs,
    roughnessWarning,
    saveRoughnessControl,
    saveRoughnessValue,

    physics,
    material,
  };
};
