// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.

import { JobConfig } from '@/proto/workflow/workflow_pb';

export const getMeshInfoFromJobConfig = (jobConfig: JobConfig | undefined) => {
  const simParam = jobConfig?.typ.case === 'simulationParam' ? jobConfig.typ.value : null;
  const simulationInput = simParam?.input;

  return {
    meshUrl: simulationInput?.url,
    meshId: simulationInput?.meshIdentifier?.id ?? '',
  };
};
