// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import assert from '../../../lib/assert';
import { findContactById } from '../../../lib/contactsUtils';
import { wordsToList } from '../../../lib/text';
import { getVolumeIdsFromSurfaces, volumeIdsToNames } from '../../../lib/volumeUtils';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useGeometryContacts } from '../../../recoil/geometryContactsState';
import { useStaticVolumes } from '../../../recoil/volumes';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useVolumeNodes } from '../../hooks/useVolumeNodes';
import { SectionMessage } from '../../notification/SectionMessage';
import { Flex } from '../../visual/Flex';
import { NodeSubselect } from '../NodeSubselect';
import PropertiesSection from '../PropertiesSection';

interface ContactSideSurfacesProps {
  contact: frontendpb.GetGeometryContactsReply_GeoContact;
  sideA?: boolean;
  nodeIdToScope: (surfaceId: string) => string;
}

const ContactSideSurfaces = (props: ContactSideSurfacesProps) => {
  const { contact, nodeIdToScope, sideA = false } = props;

  const surfaces = sideA ? contact.sideA : contact.sideB;
  const sideLabel = sideA ? 'A' : 'B';

  // these are just placeholders required by NodeSubselect
  const nodeFilter = useCallback(() => ({ related: false, disable: true }), []);
  const onChange = useCallback(() => { }, []);

  return (
    <NodeSubselect
      disableClearAll
      id={`contact-side-${sideLabel}`}
      labels={[]}
      nodeFilter={nodeFilter}
      nodeIds={surfaces}
      nodeIdToScope={nodeIdToScope}
      onChange={onChange}
      readOnly
      referenceNodeIds={[]}
      showNotFoundNodes
      title={
          sideA ?
            'Select all surfaces that represent one side of the interface' :
            'Select all surfaces that represent the other side of the interface'
        }
    />
  );
};

export const ContactPropPanel = () => {
  // == Contexts
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected contact row');
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const contacts = useGeometryContacts(projectId, workflowId, jobId);

  // Other variables
  const propsClasses = useCommonTreePropsStyles();
  const { surfaceIdToVolumeScope } = useVolumeNodes();
  const contact = useMemo(
    () => findContactById(contacts, node.id),
    [node.id, contacts],
  );
  assert(!!contact, 'No selected contact');

  const { sideA, sideB } = contact;

  // Form data
  const volumesA = getVolumeIdsFromSurfaces(sideA || [], staticVolumes);
  const volumesB = getVolumeIdsFromSurfaces(sideB || [], staticVolumes);

  return (
    <div>
      <Divider />
      <PropertiesSection>
        {(volumesA.length > 1 || volumesB.length > 1) && (
          <div className={propsClasses.sectionMessages}>
            <SectionMessage
              level="warning"
              message="Each interface side can only hold surfaces that belong to the same volume."
            />
          </div>
        )}
        <CollapsibleNodePanel
          heading="Side A"
          nodeId={node.id}
          panelName="sideA">
          <Flex flexDirection="column" gap={8}>
            <ContactSideSurfaces
              contact={contact}
              nodeIdToScope={surfaceIdToVolumeScope}
              sideA
            />
            {!!volumesA.length && (
            <div className={propsClasses.nodeTableFooter}>
              Connected Volumes: {wordsToList(volumeIdsToNames(volumesA, entityGroupMap))}
            </div>
            )}
          </Flex>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Side B"
          nodeId={node.id}
          panelName="sideB">
          <Flex flexDirection="column" gap={8}>
            <ContactSideSurfaces
              contact={contact}
              nodeIdToScope={surfaceIdToVolumeScope}
            />
            {!!volumesB.length && (
            <div className={propsClasses.nodeTableFooter}>
              Connected Volumes: {wordsToList(volumeIdsToNames(volumesB, entityGroupMap))}
            </div>
            )}
          </Flex>
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
