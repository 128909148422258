// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useRef } from 'react';

import { VirtuosoHandle } from 'react-virtuoso';

import { AnyKeyboardEvent, isUnmodifiedArrowDownKey, isUnmodifiedArrowLeftKey, isUnmodifiedArrowRightKey, isUnmodifiedArrowUpKey } from '../../lib/event';
import { ROOT_SIMULATION_CONTAINER_ID, SimulationTreeNode } from '../../lib/simulationTree/node';
import { ListenerEvent, useEventListener } from '../../lib/useEventListener';
import { useControlPanelModeValue } from '../../recoil/useProjectPage';
import { useRowsOpened } from '../../recoil/useSimulationTreeState';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { TreeRowProps } from './TreeRow';

/**
 * A variable to track the most recent time that some TreeRow was rendered while highlighted.
 * We use this to determine whether or not we should consume an arrow key event, since we want to
 * be sure that the previous keydown was processed completely before allowing the next one through.
 *
 * This is not a react state variable, since it shouldn't be involved in any rendering operations.
 *  */
let lastUpdatedHighlight = 0;

export const updateLastHighlightedValue = () => {
  lastUpdatedHighlight = performance.now();
};

export const useArrowKeyNav = (
  tree: SimulationTreeNode, // can be the simulation tree, the geometry tree or any other tree
  rowProps: TreeRowProps[],
  listRef: React.RefObject<VirtuosoHandle>,
) => {
  // == Contexts
  const { projectId } = useProjectContext();
  const { isTreeModal, selectedNode, setSelection, setScrollTo } = useSelectionContext();

  // == Recoil
  const controlPanelMode = useControlPanelModeValue();
  const [nodesOpened, setNodesOpened] = useRowsOpened(projectId, controlPanelMode);

  // use a ref to track the last time handleUpDownArrows was called.
  const lastCalled = useRef(0);

  /** On right arrow press, if the selected row is a container, open it. Otherwise, do nothing. */
  const handleRightArrow = useCallback(() => {
    if (!selectedNode) {
      return;
    }
    if (tree.getDescendant(selectedNode.id)?.children.length) {
      setNodesOpened((prevNodesOpened) => {
        const newVal = { ...prevNodesOpened };
        newVal[selectedNode.id] = true;
        return newVal;
      });
    }
  }, [tree, setNodesOpened, selectedNode]);

  /**
   * On left arrow press, if the selected row is an open container, close it. Otherwise, if the
   * selected row has a parent, select its parent.
   */
  const handleLeftArrow = useCallback(() => {
    if (!selectedNode) {
      return;
    }
    // if the node is a container and it's open, close it.
    if (
      tree.getDescendant(selectedNode.id)?.children.length &&
      nodesOpened[selectedNode.id]
    ) {
      setNodesOpened((prevNodesOpened) => {
        const newVal = { ...prevNodesOpened };
        newVal[selectedNode.id] = false;
        return newVal;
      });
    } else {
      // Otherwise, select the node's parent, if it exists.
      const parent = tree.getDescendant(selectedNode.id)?.parent;
      if (parent && parent.id !== ROOT_SIMULATION_CONTAINER_ID) {
        setSelection([parent.id]);
        setScrollTo({ node: parent.id, fast: true });
      }
    }
  }, [tree, setNodesOpened, selectedNode, nodesOpened, setScrollTo, setSelection]);

  /** The up down arrows cycle through the simtree */
  const handleUpDownArrows = useCallback((
    /** If forward is true, we move down the list. If forward is false, we move up it. */
    forward = true,
  ) => {
    // if the highlighting from the previous change hasn't yet been applied, do nothing.
    if (lastUpdatedHighlight < lastCalled.current || !selectedNode) {
      return;
    }
    lastCalled.current = performance.now();
    const indexOfSelectedNode = rowProps.findIndex((prop) => prop.node === selectedNode);
    if (indexOfSelectedNode === -1) {
      return;
    }
    // The index of the next node to select.
    let indexOfNextNode = 0;
    if (forward) {
      // The next node is at the next index, or if we're at the last index, we wrap back to 0.
      indexOfNextNode = (
        indexOfSelectedNode === rowProps.length - 1 ?
          0 :
          indexOfSelectedNode + 1
      );
    } else {
      // The next node is one below the current one, or if we're at 0, wrap to the end.
      indexOfNextNode = (
        indexOfSelectedNode === 0 ?
          rowProps.length - 1 :
          indexOfSelectedNode - 1
      );
    }
    const nextNodeId = rowProps[indexOfNextNode].node.id;
    setSelection([nextNodeId]);
    // if the newly selected node isn't in view, scroll to it.
    listRef.current?.scrollIntoView({
      behavior: 'auto',
      index: indexOfNextNode,
    });
  }, [rowProps, selectedNode, setSelection, listRef]);

  const handleArrowKeys = (event: AnyKeyboardEvent) => {
    if (isTreeModal) {
      return;
    }

    if (document.activeElement?.getAttribute('data-locator') === 'simulationTreeRow') {
      // if some other TreeRow has focus, blur it so we don't get one outlined row and one
      // highlighted/truly selected row.
      (document.activeElement as HTMLDivElement).blur();
    }

    if (isUnmodifiedArrowDownKey(event)) {
      event.preventDefault();
      handleUpDownArrows(true);
    } else if (isUnmodifiedArrowUpKey(event)) {
      event.preventDefault();
      handleUpDownArrows(false);
    } else if (isUnmodifiedArrowRightKey(event)) {
      event.preventDefault();
      handleRightArrow();
    } else if (isUnmodifiedArrowLeftKey(event)) {
      event.preventDefault();
      handleLeftArrow();
    }
  };
  useEventListener('keydown', (event: ListenerEvent) => handleArrowKeys(event as AnyKeyboardEvent));
};
