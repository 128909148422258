import React, { useEffect, useMemo } from 'react';

import { isAssistantResponding } from '../../../lib/assistant/assistantCall';
import { useAssistantMessagesValue, useResetAssistantChat } from '../../../state/external/assistant/assistantMessages';
import { useSetAssistantResponding } from '../../../state/external/assistant/assistantResponding';
import { useProjectContext } from '../../context/ProjectContext';
import { useAddNewAssistantMessage } from '../../hooks/assistant/useAddNewAssistantMessage';

import { Chat } from './Chat';

import { useAssistantThinking } from '@/state/external/assistant/assistantThinking';

/**
 * Chat component for the AI assistant.
 * It displays messages from the assistant and allows the user to send messages,
 * which are then processed by the assistant.
 * It uses the Chat component internally and manages the messages state.
 */
export const AssistantChat = () => {
  // == Context
  const { projectId } = useProjectContext();

  // == State
  const rawMessages = useAssistantMessagesValue(projectId);
  const resetChat = useResetAssistantChat();
  const addNewAssistantMessage = useAddNewAssistantMessage(projectId);
  const setIsResponding = useSetAssistantResponding();
  const [assistantThinking, setAssistantThinking] = useAssistantThinking();

  const messages = useMemo(() => Array.from(rawMessages.messages.values()), [rawMessages]);

  useEffect(() => {
    // Reset isPreparing if a new non-user message has appeared
    if (assistantThinking && messages.length > 0 && !messages[messages.length - 1].isUser) {
      setAssistantThinking(false);
    }
  }, [messages, assistantThinking, setAssistantThinking]);

  useEffect(() => {
    const subscription = isAssistantResponding.subscribe((value) => {
      setIsResponding(value);
    });
    return () => subscription.unsubscribe();
  }, [resetChat, setIsResponding]);

  return (
    <Chat
      messages={messages}
      onSendMessage={addNewAssistantMessage}
    />
  );
};
