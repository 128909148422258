// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useMemo } from 'react';

import assert from '../../../lib/assert';
import { getTypeLabel as getMaterialTypeLabel } from '../../../lib/materialUtils';
import { NodeTableType } from '../../../lib/nodeTableUtil';
import { getTypeLabel as getPhysicsTypeLabel } from '../../../lib/physicsUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import { getMaterialAssignmentTooltip, getPhysicsAssignmentTooltip, mapDomainsToIds } from '../../../lib/volumeUtils';
import { typesToAdd } from '../../../model/hooks/useMaterials';
import { usePhysicsSet } from '../../../model/hooks/usePhysicsSet';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useSimulationTreeSubselect } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { ModelSelector } from '../../controls/ModelSelector';
import { useVolumeNode } from '../../hooks/useVolumeNode';
import { AttributesDisplay } from '../AttributesDisplay';
import { LabeledSection } from '../LabeledSection';
import { NodeIdsSelectButton } from '../NodeIdsSelectButton';
import NodeTable from '../NodeTable';
import PropertiesSection from '../PropertiesSection';

import { GeometryMotion, GeometryNodeType } from './shared/GeometryMotion';

export const VolumePropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();

  // == Recoil
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  // == Hooks
  const propClasses = useCommonTreePropsStyles();
  assert(!!node, 'No selected volume row');

  const isTagChild = node.type === NodeType.TAGS_BODY;
  // In some state transitions, the volume id may not be available. Avoid crashing in that case.
  const volumeId = ((isTagChild && geometryTags.tagIds().size) ?
    mapDomainsToIds(
      staticVolumes,
      [geometryTags.domainFromTagEntityGroupId(node.id) || ''],
    ).at(0) :
    node.id) || '';

  const {
    staticVolume,
    fluidPhysics,
    heatPhysics,
    surfaceOrGroupIds,

    handleNewPorousModel,
    porousModelData,
    attachPorousModel,
    detachPorousModel,
    attachedPorousModelIds,

    handleNewHeatSource,
    heatSourceData,
    attachHeatSource,
    detachHeatSource,
    attachedHeatSourceIds,

    assignedMaterialId,
    assignedMaterial,
    materialsModelData,
    assignMaterial,
    unassignMaterial,
    createAndAssignMaterial,

    assignedPhysicsId,
    assignedPhysics,
    physicsModelData,
    assignPhysics,
    unassignPhysics,
    createAndAssignPhysics,
    availablePhysicsTypes,
  } = useVolumeNode(volumeId);
  const {
    disabledAddReason,
    getVolumePhysicsAssignmentDisabledReason,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);
  const treeSubselect = useSimulationTreeSubselect();

  const materialCreators = useMemo(() => typesToAdd.map((type) => ({
    label: getMaterialTypeLabel(type),
    onClick: () => createAndAssignMaterial(type),
  })), [createAndAssignMaterial]);

  const physicsModelSelectTooltip = getPhysicsAssignmentTooltip(
    readOnly,
    !!assignedPhysics,
    !!assignedMaterial,
  );

  const physicsCreators = useMemo(() => availablePhysicsTypes.map((type) => {
    const disabledReason = disabledAddReason(type);
    return {
      label: getPhysicsTypeLabel(type),
      onClick: () => createAndAssignPhysics(type),
      disabled: !!disabledReason,
      disabledReason,
    };
  }), [availablePhysicsTypes, createAndAssignPhysics, disabledAddReason]);

  const materialTooltip = getMaterialAssignmentTooltip(
    readOnly,
    !!assignedPhysicsId,
    !!assignedMaterialId,
  );

  // Always disable unlinking a material if the volume is assigned to a physics
  const materialLocked = !!(assignedPhysicsId && assignedMaterialId);

  const unassignPhysicsDisabledReason = (assignedPhysicsId && staticVolume) ?
    getVolumePhysicsAssignmentDisabledReason(assignedPhysicsId, staticVolume.domain) :
    '';

  return (
    <div>
      <AttributesDisplay attributes={[{ label: 'Type', value: 'Volume' }]} />
      <Divider />
      <LabeledSection label="Material">
        <ModelSelector
          creators={materialCreators}
          disabled={readOnly || materialLocked}
          icon={{ name: 'gridBlocks' }}
          models={materialsModelData}
          onSelect={({ model }) => assignMaterial(model)}
          onUnselect={unassignMaterial}
          selected={assignedMaterialId ? [assignedMaterialId] : []}
          tooltip={materialTooltip}
        />
      </LabeledSection>
      <Divider />
      <LabeledSection label="Physics">
        <ModelSelector<simulationpb.Physics>
          creators={physicsCreators}
          disabled={readOnly || !assignedMaterialId || !!unassignPhysicsDisabledReason}
          icon={{ name: 'atom' }}
          models={physicsModelData}
          onSelect={({ model }) => assignPhysics(model)}
          onUnselect={unassignPhysics}
          selected={assignedPhysicsId ? [assignedPhysicsId] : []}
          tooltip={unassignPhysicsDisabledReason || physicsModelSelectTooltip}
        />
      </LabeledSection>
      <Divider />
      <GeometryMotion
        nodeId={volumeId}
        nodeType={node.type as GeometryNodeType}
      />
      {fluidPhysics && (
        <>
          <Divider />
          <LabeledSection label="Porous Model">
            <ModelSelector
              creators={[
                { label: 'Porous Model', onClick: handleNewPorousModel },
              ]}
              disabled={readOnly}
              icon={{ name: 'triAxes' }}
              models={porousModelData}
              onSelect={({ model }) => attachPorousModel(model)}
              onUnselect={detachPorousModel}
              selected={attachedPorousModelIds}
              tooltip={
                readOnly ?
                  '' :
                  'Assign this volume to an existing porous model or create a new one'
              }
            />
          </LabeledSection>
        </>
      )}
      {heatPhysics && (
        <>
          <Divider />
          <LabeledSection label="Heat Source">
            <ModelSelector
              creators={[
                { label: 'Heat Source', onClick: handleNewHeatSource },
              ]}
              disabled={readOnly}
              icon={{ name: 'verticalWaves' }}
              models={heatSourceData}
              onSelect={({ model }) => attachHeatSource(model.heatSourceId)}
              onUnselect={detachHeatSource}
              selected={attachedHeatSourceIds}
              tooltip={
                readOnly ?
                  '' :
                  'Assign this volume to an existing heat source or create a new one'
              }
            />
          </LabeledSection>
        </>
      )}
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Bounding Surfaces"
          nodeId={volumeId}
          panelName="surfaces">
          <div className={propClasses.nodeTableDescription}>
            List of surfaces that bound this volume
          </div>
          <NodeTable
            editable={false}
            nodeIds={surfaceOrGroupIds}
            tableId="volumeSurfaces"
            tableType={NodeTableType.NONE}
          />
          <NodeIdsSelectButton
            disabled={treeSubselect.active}
            help={treeSubselect.active ? '' : 'Click to select all surfaces in the volume'}
            nodeIds={surfaceOrGroupIds}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
