import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { IconButton } from '../../../Button/IconButton';
import { SvgIcon } from '../../../Icon/SvgIcon';
import { createStyles, makeStyles } from '../../../Theme';
import { useProjectContext } from '../../../context/ProjectContext';
import { useAddNewAssistantMessage } from '../../../hooks/assistant/useAddNewAssistantMessage';
import { useAssistantSend } from '../../../hooks/assistant/useAssistantSend';

import { CircularLoader } from '@/components/visual/CircularLoader';
import { analytics } from '@/services/analytics';

const useSuggestedListStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      justifyContent: 'end',

      '&.disabled': {
        pointerEvents: 'none',
      },
    },
  }),
  { name: 'ThumbsFeedback' },
);

const ICON_SIZE = 12;

// These are the feedback commands that the assistant can read in the backend
export const FEEDBACKS = ['/thumbs_up', '/thumbs_down', '/deselect_feedback'] as const;
type Feedback = typeof FEEDBACKS[number];

type ThumbsFeedbackProps = {
  disabled: boolean,
}

export const ThumbsFeedback = (props: ThumbsFeedbackProps) => {
  // == Contexts
  const { projectId } = useProjectContext();

  // == Hooks
  const classes = useSuggestedListStyles();

  // == State
  const addNewAssistantMessage = useAddNewAssistantMessage(projectId);
  const { disabled: disabledSend } = useAssistantSend();
  const [loading, setLoading] = useState(false);
  // This stores the currently selected feedback. Resets immediately if the same button is clicked
  // twice but persists otherwise to keep the respective button engaged for older responses.
  const [feedback, setFeedback] = useState<null | Feedback>(null);
  // Temporarily stores the feedback to handle the loading and engaged state when the same button
  // is clicked again until the assistant processes the feedback.
  const feedbackRef = useRef<null | Feedback>();
  const disabled = disabledSend || props.disabled;

  const handleSubmit = (newFeedback: Feedback) => {
    if (disabled) {
      return;
    }

    setLoading(true);
    feedbackRef.current = newFeedback;

    // reset
    if (feedback === newFeedback) {
      setFeedback(null);
      addNewAssistantMessage(FEEDBACKS[2]);
      analytics.assistant('Feedback: reset');
      // set initial feedback
    } else {
      setFeedback(newFeedback);
      addNewAssistantMessage(newFeedback);
      analytics.assistant(`Feedback: ${newFeedback === '/thumbs_up' ? 'up' : 'down'}`);
    }
  };

  useEffect(() => {
    if (!disabledSend) {
      setLoading(false);
      feedbackRef.current = null;
    }
  }, [disabledSend]);

  return (
    <div className={cx(classes.root, { disabled })}>
      <IconButton
        data-locator="assistant-feedback-thumbs-up"
        disabled={disabled}
        engaged={feedback === FEEDBACKS[0] || feedbackRef.current === FEEDBACKS[0]}
        onClick={() => handleSubmit(FEEDBACKS[0])}>
        {loading && feedbackRef.current === FEEDBACKS[0] ?
          <CircularLoader size={ICON_SIZE} strokeRatio={0.2} /> :
          <SvgIcon maxHeight={ICON_SIZE} name="thumbsUp" />}
      </IconButton>
      <IconButton
        data-locator="assistant-feedback-thumbs-down"
        disabled={disabled}
        engaged={feedback === FEEDBACKS[1] || feedbackRef.current === FEEDBACKS[1]}
        onClick={() => handleSubmit(FEEDBACKS[1])}>
        {loading && feedbackRef.current === FEEDBACKS[1] ?
          <CircularLoader size={ICON_SIZE} strokeRatio={0.2} /> :
          <SvgIcon maxHeight={ICON_SIZE} name="thumbsDown" />}
      </IconButton>
    </div>
  );
};
