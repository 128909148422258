// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { quantities } from '../../QuantityDescriptor';

export const CATEGORY_MAP = quantities.reduce((result, { text, category }) => {
  if (!(category in result)) {
    result[category] = [];
  }

  result[category].push(text);
  return result;
}, {} as Record<string, string[]>);

function getItemCategory(name: string) {
  const UNIT_SEPARATOR = ' (';
  const nameWithoutUnit = name.split(UNIT_SEPARATOR)[0].trim();

  const [foundCategory] = Object.entries(CATEGORY_MAP)
    .find(([, categoryItems]) => categoryItems.includes(nameWithoutUnit)) || [name, []];

  return foundCategory;
}

export function groupVariables<T extends {name: string}>(data: T[]): Record<string, T[]> {
  return data.reduce<Record<string, T[]>>((result, item) => {
    const itemCategory = getItemCategory(item.name);
    const categoryExists = itemCategory in result;

    if (categoryExists) {
      return {
        ...result,
        [itemCategory]: [...result[itemCategory], item],
      };
    }

    return { ...result, [itemCategory]: [item] };
  }, {});
}
