// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useMemo } from 'react';

import { CurrentView } from '../../lib/componentTypes/context';
import { ConditionalFeature } from '../../proto/frontend/frontend_pb';
import { useCurrentUser } from '../../recoil/useAccountInfo';
import { analytics } from '../../services/analytics';
import { DEFAULT_EXPANDED_RAIL_WIDTH, MIN_EXPANDED_RAIL_WIDTH, MIN_RAIL_WIDTH, useAssistantSideRailWidthValue, useSetAssistantSideRailWidth } from '../../state/internal/assistant/assistantSideRailSize';
import { useCurrentView } from '../../state/internal/global/currentView';

export const useAssistantEnabled = () => {
  const currentView = useCurrentView();

  // check if ai assistant is enabled
  const conditionalFeatures = useCurrentUser()?.conditionalFeatures;
  const aiAssistantEnabled = conditionalFeatures
    ?.includes(ConditionalFeature.AI_ASSISTANT) ?? false;

  return useMemo(() => aiAssistantEnabled && [
    CurrentView.ADVANCED_ANALYSIS,
    CurrentView.ANALYSIS,
    CurrentView.GEOMETRY,
    CurrentView.SETUP,
    CurrentView.RESULTS,
  ].includes(currentView), [aiAssistantEnabled, currentView]);
};

export const useAssistantVisible = () => {
  const sideRailWidth = useAssistantSideRailWidthValue();
  return sideRailWidth >= MIN_EXPANDED_RAIL_WIDTH;
};

export const useToggleAssistant = () => {
  const setSideRailWidth = useSetAssistantSideRailWidth();
  const visible = useAssistantVisible();

  return useCallback(() => {
    setSideRailWidth(visible ? MIN_RAIL_WIDTH : DEFAULT_EXPANDED_RAIL_WIDTH);
    analytics.assistant(visible ? 'Close Assistant' : 'Open Assistant', {
      eventType: visible ? 'panel_closed' : 'panel_invoked',
    });
  }, [visible, setSideRailWidth]);
};
