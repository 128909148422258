// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Empty } from '@bufbuild/protobuf';
import { selector, useRecoilValueLoadable } from 'recoil';

import * as rpc from '../lib/rpc';
import * as serverinfopb from '../proto/frontend/serverinfo/serverinfo_pb';

// Caches the result of analyzer.ServerInfo RPC.
const serverInfoSelector = selector<serverinfopb.ServerInfoReply>({
  key: 'serverInfo',
  get: () => rpc.callRetry('ServerInfo', rpc.clientServerInfo.serverInfo, new Empty()),
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

// Returns the reply from frontendserver.ServerInfo RPC. It caches the response
// until useServerInfoRefresher() is called.
export default function useServerInfo(): serverinfopb.ServerInfoReply | null {
  const info = useRecoilValueLoadable(serverInfoSelector);
  if (info.state === 'hasValue') {
    return info.contents;
  }
  return null;
}
