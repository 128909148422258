// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useMemo } from 'react';

import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useTransparencySettings } from '../../recoil/lcvis/transparencySettings';
import { useIsAnalysisView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';

/* Applies transparency to the farfield in lcvis. */
export const useFarfieldTransparency = () => {
  const [, setTransparencySettings] = useTransparencySettings();
  const { projectId } = useProjectContext();
  const geometryTags = useGeometryTags(projectId);
  const analysisView = useIsAnalysisView();

  const farfieldSurfaces = useMemo(() => [...geometryTags.tagIds()].reduce((result, tagId) => {
    if (/far[\s-_]?field|symmetry[\s-_]plane/i.test(tagId)) {
      const surfaces = geometryTags.surfacesFromTagEntityGroupId(tagId) || [];

      surfaces.forEach((surfaceId) => result.add(surfaceId));
    }

    return result;
  }, new Set<string>()), [geometryTags]);

  const setFarfieldTransparency = useCallback(() => {
    if (analysisView) {
      return;
    }

    setTransparencySettings((oldSettings) => ({
      // Do not show the panel. If we set this to true we will see the ghost mode panel.
      active: false,
      surfaces: farfieldSurfaces,
      invert: false,
      collapsed: oldSettings.collapsed,
    }));
  }, [analysisView, setTransparencySettings, farfieldSurfaces]);

  return { setFarfieldTransparency, farfieldSurfaces };
};
