// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import * as flags from '../../../flags';
import { updateExploration } from '../../../lib/proto';
import * as explorationpb from '../../../proto/exploration/exploration_pb';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import useExplorationSet from '../../../recoil/useExplorationSet';
import { useCurrentConfig } from '../../../recoil/workflowConfig';
import { useProjectContext } from '../../context/ProjectContext';

type PolicyCase = explorationpb.Exploration['policy']['case'];

export function newDesignOfExperiment(): explorationpb.Exploration {
  return new explorationpb.Exploration({
    name: 'Design of Experiments',
    policy: { case: 'gridSearch', value: new explorationpb.GridSearch() },
  });
}

function newSensitivityAnalysis(): explorationpb.Exploration {
  return new explorationpb.Exploration({
    name: 'Local Sensitivity Model',
    policy: { case: 'sensitivityAnalysis', value: new explorationpb.SensitivityAnalysis() },
  });
}

/**
 * @returns a function that creates a new Exploration Set with the given policy case as the
 * selected exploration
 */
export function useCreateExploration() {
  // == Context
  const { projectId, workflowId, jobId, onNewWorkflowConfig } = useProjectContext();
  // == Recoil
  const currentConfig = useCurrentConfig(projectId, workflowId, jobId);
  const [explorationSet, setExplorationSet] = useExplorationSet(projectId);

  // Create a set of experiments if none exists.
  const createExploration = useCallback((policyCase: PolicyCase) => {
    const newExps = explorationSet.clone();
    if (explorationSet.exploration.length === 0) {
      newExps.exploration.push(newDesignOfExperiment(), newSensitivityAnalysis());
    }
    // Select the experiment.
    switch (policyCase) {
      case 'gridSearch':
        newExps.index = 0;
        break;
      case 'sensitivityAnalysis':
        newExps.index = 1;
        break;
      default:
        throw Error('Create experiment not implemented for this policy case.');
    }
    setExplorationSet(newExps);
    onNewWorkflowConfig(updateExploration(
      currentConfig,
      newExps.exploration[newExps.index],
    ));
  }, [currentConfig, explorationSet, onNewWorkflowConfig, setExplorationSet]);

  return createExploration;
}

export function useOnStartExplorationSetup() {
  const { projectId } = useProjectContext();

  const sensitivityEnabled = useIsEnabled(flags.adjointSA);
  const [explorationSet] = useExplorationSet(projectId);
  const createExploration = useCreateExploration();

  return useCallback(() => {
    if (!sensitivityEnabled && explorationSet.index < 0) {
      // if sensitivity is disabled and no exploration is selected, automatically create and set
      // grid search exploration
      createExploration('gridSearch');
    }
  }, [createExploration, explorationSet.index, sensitivityEnabled]);
}
