// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { MultipleChoiceParam } from '../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../SimulationParamDescriptor';
import {
  AnyBoundaryCondition,
  appendFluidBoundaryCondition,
  appendHeatBoundaryCondition,
  findPeriodicPairIdBySurface,
  getAllBoundaryConditions,
  getAllPeriodicPairs,
  getBoundaryConditionsByPhysics,
  getPeriodicPairsByPhysics,
  periodicPairId,
} from '../../lib/boundaryConditionUtils';
import { boldEscaped } from '../../lib/html';
import { subtractSet, unionSet } from '../../lib/lang';
import { findPhysicsByDomain, getPhysicsId, getPhysicsName } from '../../lib/physicsUtils';
import { getBoundaryCondName } from '../../lib/simulationTree/utils';
import { findSlidingInterfaceBySurfaceId, getAllSlidingInterfaces, getSlidingInterfacesByPhysics } from '../../lib/slidingInterfaceUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useSurfaceToBoundaryConditions } from '../../recoil/useSurfaceToBoundaryConditions';
import { StaticVolume, useStaticVolumes, useSurfaceToVolumes } from '../../recoil/volumes';
import { useSimulationBoundaryNames } from '../../state/external/project/simulation/param/boundaryNames';
import { useProjectContext } from '../context/ProjectContext';
import { ModelCreator, ModelData } from '../controls/ModelSelector';

import { useSimulationConfig } from './useSimulationConfig';

type ModelType = AnyBoundaryCondition | simulationpb.PeriodicPair | simulationpb.SlidingInterfaces;

const fluidBoundaryChoices = (
  paramDesc[ParamName.PhysicalBoundary] as MultipleChoiceParam
).choices;

const heatBoundaryChoices = (
  paramDesc[ParamName.HeatPhysicalBoundary] as MultipleChoiceParam
).choices;

export function useSurfaceListBoundaries(ids: Set<string>) {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const surfaceVolumeMap = useSurfaceToVolumes(projectId, workflowId, jobId);
  const boundaryNames = useSimulationBoundaryNames(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const surfaceToBoundaryConditions = useSurfaceToBoundaryConditions(projectId, workflowId, jobId);

  const { saveParamAsync, simParam } = useSimulationConfig();

  const volumes = useMemo(() => {
    const result: Set<StaticVolume> = new Set();
    ids.forEach((surfaceId) => {
      const surfaceVol = surfaceVolumeMap.get(surfaceId);
      if (surfaceVol) {
        result.add(surfaceVol);
      }
    });
    return result;
  }, [ids, surfaceVolumeMap]);

  const physicsSet = useMemo(() => {
    const result: Set<simulationpb.Physics> = new Set();
    volumes.forEach(({ domain }) => {
      const volumePhysics = findPhysicsByDomain(simParam, domain, geometryTags);
      if (volumePhysics) {
        result.add(volumePhysics);
      }
    });
    return result;
  }, [geometryTags, simParam, volumes]);

  const modelData = useMemo((): ModelData<ModelType>[] => {
    const multiPhysics = physicsSet.size > 1;

    const data: ModelData<ModelType>[] = [];
    physicsSet.forEach((physics) => {
      const physicsId = getPhysicsId(physics);

      const boundaryConditions = getBoundaryConditionsByPhysics(physics);
      const periodicPairs = getPeriodicPairsByPhysics(physics);
      const slidingInterfaces = getSlidingInterfacesByPhysics(physics);

      data.push(
        ...boundaryConditions.map((boundaryCondition) => ({
          id: boundaryCondition.boundaryConditionName,
          label: getBoundaryCondName(boundaryNames, boundaryCondition),
          model: boundaryCondition,
          icon: { name: 'hash' },
          hideFromMenu: multiPhysics,
        })) as ModelData<ModelType>[],
        ...periodicPairs.map((pair, i) => ({
          id: periodicPairId(physicsId, i),
          label: pair.periodicPairName || `Periodic Pair ${i + 1}`,
          model: pair,
          icon: { name: 'gridQuadOutlined' },
          hideFromMenu: true,
        })) as ModelData<ModelType>[],
        ...slidingInterfaces.map((intf) => ({
          id: intf.slidingInterfaceId,
          label: intf.slidingInterfaceName,
          model: intf,
          icon: { name: 'hash' },
          hideFromMenu: true,
        })) as ModelData<ModelType>[],
      );
    });
    return data;
  }, [boundaryNames, physicsSet]);

  const selected = useMemo(() => {
    const selectedIds: (string | undefined)[] = [];

    ids.forEach((id) => {
      const localIds: string[] = [];
      const assignedBoundaryCondition = surfaceToBoundaryConditions.get(id);
      const assignedPairId = findPeriodicPairIdBySurface(simParam, id, geometryTags, staticVolumes);
      const assignedInterface = findSlidingInterfaceBySurfaceId(simParam, id, geometryTags);

      if (assignedBoundaryCondition) {
        localIds.push(assignedBoundaryCondition.boundaryConditionName);
      }
      if (assignedPairId) {
        localIds.push(assignedPairId);
      }
      if (assignedInterface) {
        localIds.push(assignedInterface.slidingInterfaceId);
      }

      if (localIds.length) {
        selectedIds.push(...localIds);
      } else if (ids.size > 1) {
        selectedIds.push(undefined);
      }
    });
    // Dedupe
    const uniqueIds = new Set(selectedIds);

    if (uniqueIds.size === 1 && uniqueIds.has(undefined)) {
      // We don't want to pass a list of only 'undefined' values.  This means none of the surfaces
      // is assigned to BC, PP, or Interface, so we should just pass an empty list.
      return [];
    }

    return [...uniqueIds];
  }, [ids, simParam, geometryTags, staticVolumes, surfaceToBoundaryConditions]);

  const attachBoundaryCondition = useCallback(async (modelId?: string) => {
    await saveParamAsync((newParam) => {
      getAllBoundaryConditions(newParam).forEach((boundaryCondition) => {
        const surfaceSet = new Set(boundaryCondition.surfaces);
        const newSurfaceSet = (modelId === boundaryCondition.boundaryConditionName) ?
          unionSet(surfaceSet, ids) :
          subtractSet(surfaceSet, ids);
        boundaryCondition.surfaces = [...newSurfaceSet];
      });
      if (!modelId) {
        // Processing periodic pairs and sliding interfaces only for detaching
        getAllPeriodicPairs(newParam).forEach((pair) => {
          const surfaceSetA = new Set(pair.boundA);
          const surfaceSetB = new Set(pair.boundB);
          pair.boundA = [...subtractSet(surfaceSetA, ids)];
          pair.boundB = [...subtractSet(surfaceSetB, ids)];
        });
        getAllSlidingInterfaces(newParam).forEach((intf) => {
          const surfaceSetA = new Set(intf.slidingA);
          const surfaceSetB = new Set(intf.slidingB);
          intf.slidingA = [...subtractSet(surfaceSetA, ids)];
          intf.slidingB = [...subtractSet(surfaceSetB, ids)];
        });
      }
    });
  }, [ids, saveParamAsync]);

  const createFluidBoundaryCondition = useCallback(
    async (physicsId: string, boundaryType: simulationpb.PhysicalBoundary) => {
      await saveParamAsync((newParam) => {
        const newBc = appendFluidBoundaryCondition(newParam, physicsId, boundaryType);
        newBc.surfaces.push(...ids);
      });
    },
    [ids, saveParamAsync],
  );

  const createHeatBoundaryCondition = useCallback(
    async (physicsId: string, boundaryType: simulationpb.HeatPhysicalBoundary) => {
      await saveParamAsync((newParam) => {
        const newBc = appendHeatBoundaryCondition(newParam, physicsId, boundaryType);
        newBc.surfaces.push(...ids);
      });
    },
    [ids, saveParamAsync],
  );

  const creators = useMemo(() => {
    const items: ModelCreator[] = [];

    if (physicsSet.size === 1) {
      const physics = [...physicsSet][0];
      const physicsId = getPhysicsId(physics);

      switch (physics.params.case) {
        case 'fluid': {
          const name = getPhysicsName(physics!, simParam);
          items.push(
            ...fluidBoundaryChoices.map((choice) => ({
              label: choice.text,
              onClick: () => createFluidBoundaryCondition(physicsId, choice.enumNumber),
              tooltip: `On physics ${boldEscaped(name)}`,
            })),
          );
          break;
        }
        case 'heat': {
          const name = getPhysicsName(physics!, simParam);
          items.push(
            ...heatBoundaryChoices.map((choice) => ({
              label: choice.text,
              onClick: () => createHeatBoundaryCondition(physicsId, choice.enumNumber),
              tooltip: `On physics ${boldEscaped(name)}`,
            })),
          );
          break;
        }
        default: // no default
      }
    }

    return items;
  }, [createFluidBoundaryCondition, createHeatBoundaryCondition, physicsSet, simParam]);

  const disabledReason = useMemo(() => {
    if (!creators.length && !selected.length) {
      if (physicsSet.size > 1) {
        return `Boundary conditions cannot be managed collectively for this surface group, ` +
          `because its surfaces' volumes are assigned to multiple physics`;
      }
      if (!physicsSet.size) {
        if (volumes.size > 1) {
          return 'Assign parent volumes to a common physics to enable boundary condition ' +
            'management for this collection of surfaces';
        }
        return 'Assign parent volume to a physics to enable boundary conditions';
      }
    }
    return '';
  }, [creators, physicsSet, selected, volumes]);

  return {
    modelData,
    selected,
    attachBoundaryCondition,
    creators,
    disabledReason,
  };
}
