// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { CoreProps, MessagePanel } from './MessagePanel';

// Defines how long a non-error Toast stays up before being auto-dismissed
const KEEPALIVE = 10_000;

export interface ToastProps extends Omit<CoreProps, 'onDismiss'> {
  // onDismiss is required for Toasts
  onDismiss: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Toast = (props: ToastProps) => {
  const { level } = props;

  const [expiry, setExpiry] = useState<Date | undefined>(props.expiry);

  // 'error' toasts should never auto-expire. And if props.expiry is undefined,
  // we should set them to auto-expire after KEEPALIVE ms.
  useEffect(() => {
    if (level === 'error' || level === 'dev-error') {
      setExpiry(undefined);
    } else if (!props.expiry) {
      setExpiry(new Date(Date.now() + KEEPALIVE));
    } else {
      setExpiry(props.expiry);
    }
  }, [props.expiry, level]);

  return (
    <MessagePanel
      {...props}
      expiry={expiry}
      variant="toast"
    />
  );
};
