import { LCVManipulationMode, LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';

import { LcvWidget } from './LcvWidget';

export type LcvPlaneWidgetState = {
  position: [number, number, number],
  normal: [number, number, number],
}

type LcvPlaneCallback = (newState: LcvPlaneWidgetState) => void;

export class LcvPlaneWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
  ) {
    super(lcv, sessionHandle, 'plane');
  }

  getState(): LcvPlaneWidgetState {
    return {
      position: this.getProperty('position', LCVType.kLCVDataTypeFloat3),
      normal: this.getProperty('normal', LCVType.kLCVDataTypeFloat3),
    };
  }

  setState(newState: Partial<LcvPlaneWidgetState>) {
    const { position, normal } = newState;

    if (position) {
      this.setParam('position', LCVType.kLCVDataTypeFloat3, position);
    }

    if (normal) {
      this.setParam('normal', LCVType.kLCVDataTypeFloat3, normal);
    }
  }

  setCallback(callback: LcvPlaneCallback | null) {
    if (!callback) {
      this.setParam('callback', LCVType.kLCVDataTypeFunction, null);
      return;
    }

    const internalCallback = (_lcv: any, _session: LCVObject, obj: LCVObject, message: string) => {
      requestAnimationFrame(() => {
        const newState = this.getState();
        callback(newState);
      });
    };

    this.setParam('callback', LCVType.kLCVDataTypeFunction, internalCallback);
  }

  showControls(manipulationMode = LCVManipulationMode.kLCVManipulationModeTranslateRotate): void {
    this.setParam('manipulation_mode', LCVType.kLCVDataTypeUint, manipulationMode);
  }

  hideControls(): void {
    this.setParam(
      'manipulation_mode',
      LCVType.kLCVDataTypeUint,
      LCVManipulationMode.kLCVManipulationModeNone,
    );
  }
}
