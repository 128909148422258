// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { findFilterTreeNode } from '../../lib/visUtils';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useFilterStateValue } from '../../recoil/vis/filterState';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';

/**
 * Determines the parent node for a new visualization filter.
 *
 * When creating a new visualization filter, it can either be a child of another filter node
 * or directly attached to the root visualizations node.
 *
 * If a node matching the given parentId is found, that node is returned.
 * If no matching node is found, the root node is returned instead.
 */
export const useVisFilterParentNode = (parentId?: string) => {
  // context
  const { projectId, workflowId, jobId } = useProjectContext();
  const { viewState } = useParaviewContext();

  // recoil
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisRootFilterNode = useFilterStateValue({ projectId, workflowId, jobId });

  const rootNode = lcvisEnabled ? lcvisRootFilterNode : viewState?.root;

  return rootNode && parentId ? findFilterTreeNode(rootNode, parentId) : rootNode;
};
