
// Auto-generated by pvproto_generator.py. DO NOT EDIT.

import { WebsocketConnection } from 'wslink/src/WebsocketConnection';
export type ViewId = string & { __brand: 'An ID that identicies a render window on a remote server'};

// Interface implemented by ParaviewRpc.Client
// Here to prevent circular dependency.
export interface ParaviewRpcClient {
  runRpc: <Result>(methodName: string, args: any[]) => Promise<Result>;
  subscribe: <Arg>(topic: string, cb: (arg: Arg) => void) => Promise<()=>Promise<void>>;
  viewId: ViewId;
  connection: WebsocketConnection;
}
// Set of parameters that define a disk
export interface ActuatorDiskParam {
  typ: 'ActuatorDisk';
  // Actuator Disk plane parameters
  plane: PlaneParam;
  // if true, the plane normal will be used to orient the disk, otherwise,
  // the normal will be intepreted as an Euler angle.
  usenormal: boolean;
  // Actuator Disk circumferential resolution
  circumferentialresolution: number;
  // Actuator Disk radial resolution
  radialresolution: number;
  // Inner radius of the disk
  innerradius: number;
  // Outer radius of the disk
  outerradius: number;
  // The unique id of the particle group to represent
  particleGroupId: string;
}
// Set of params to define visualizations for airfoils (blade element theory).
export interface AirfoilParam {
  // Center and line direction for radial stations.
  planes: PlaneParam[];
  // List of radii for each disk model.
  radii: number[];
  // Direction vectors for the text annotation.
  textDirs: Vector3[];
  // List of airfoil points shared by all disks.
  points: AirfoilPoint[];
}
// A set of params to represent an airfoil data point.
export interface AirfoilPoint {
  // The radial station of this point [0.0-1.0]
  radialstation: number;
  // The unique id of the airfoil
  id: string;
  // The user-facing name to display the label
  name: string;
}
// Set of parameters that define an annular cylinder
export interface AnnularCylinderParam {
  typ: 'AnnularCylinder';
  // Location of the cylinder's first base's center.
  start: Vector3;
  // Location of the cylinder's second base's center.
  end: Vector3;
  // Outer radius of the annular cylinder
  outer_radius: number;
  // Inner radius of the annular cylinder
  inner_radius: number;
}
// Per-point/cell/face/ information stored in the mesh.
// JSON encoding of simple.ArrayInformation.
export interface ArrayInformation {
  // 'Density', etc.
  name: string;
  // Whether the array represents CellData or PointData.
  type: FieldAssociation;
  // Dimension of the value. E.g., 1 for density, 3 for velocity.
  dim: number;
  // The (min, max) value of each dimension. len(range)==dim + 1 for non-scalar variables.
  // range[0] holds the range of the variable magnitude, while range[i + 1] holds the range of the
  // ith variable component.
  range: [number, number][];
  // Number of values. (pv ArrayInformation.GetNumberOfTuples). Equals to #
  // of points (or cells) in the mesh for point (resp. cell) data.
  n: number;
}
// Set of parameters that define an axis aligned box.
export interface AxisAlignedBoxParam {
  typ: 'AxisAlignedBox';
  // The min point of the box
  min: Vector3;
  // The max point of the box.
  max: Vector3;
}
// Bounding box, in format [xmin, xmax, ymin, ymax, zmin, zmax]
export type Bounds = [number, number, number, number, number, number];
// Set of parameters that define a box for the box clip
export interface BoxClipParam {
  typ: 'BoxClip';
  // Center position for the clip.
  position: Vector3;
  // Degree rotation about x, y, z axes.
  rotation: Vector3;
  // Length along each axis inside the clipped volume.
  length: Vector3;
}
export interface BoxWidgetState {
  typ: 'BoxWidgetRepresentation';
  box: BoxClipParam;
}
// State of the camera (position, angle, zoom, etc.)
export interface CameraState {
  // The camera's center of rotation in world coordinates.
  center: Vector3;
  // The camera's focal point in world coordinates.
  focal: Vector3;
  // The camera's view up direction as a vector.
  up: Vector3;
  // The camera's position in world coordinates.
  position: Vector3;
  // Whether or not the camera is in parallel projection mode.
  parallelProjection: boolean;
  // The Camera's parallel scale, only meaningful if parallelProjection is true.
  scale: number;
  // The Camera's view angle.  The value is defined to be
  // 2*atan((h/2)/d) where h is the height of the screen, and d is the
  // distance from your eyes to the screen.  It is only meaningful if
  // parallelProjection is false.  This field was added in 2022-01.
  // Defaults to 30.
  angle: number;
}
export interface CellMetric {
  // Name of the metric, e.g., "Density", or "Momentum".
  name: string;
  // Set when the metric is a scalar value.
  // Exactly one of real or vector3 must be set
  real: number | null;
  // Set when the metric is a vector3 value.
  // Exactly one of real or vector3 must be set
  vector3: Vector3 | null;
}
export interface CellState {
  // The center of the cell, in the world coordinate.
  center: Vector3;
  // Screen X position of the centroid.
  screenX: number;
  // Screen Y position of the centroid.  Note: the Y axis grows up, opposite of
  // most computer screen coordinate systems.
  screenY: number;
  // The name of the surface hit.
  surfaceName: string;
  // Data associated with the cell,
  // such as density and momentum.
  data: CellMetric[];
}
// Current state of the client's render view. Used in the geometry
// rendering mode to communicate the state of the client-side view to the
// server
export interface ClientWindowState {
  camera: CameraState;
  screenWidth: number;
  screenHeight: number;
}
// Parameters for Clip and Slice filters
export interface ClipSliceParam {
  typ: 'Clip' | 'Slice';
  // Used to identify the exact filter type.
  paramType: string;
  filterParam: PlaneParam | BoxClipParam;
  // Whether to cut the boundary cells of a clip.
  smooth: boolean;
  // Whether to invert the clip.
  invert: boolean;
  // Parameter to project vectors onto slices. Only valid for slices and lcvis
  projectvectors: boolean | null;
}
// List of filter types for clip / slice.
export enum ClipSliceParamType {
  BOX_CLIP = 'BoxClip',
  CLIP = 'Clip',
  SLICE = 'Slice',
}
// Settings for a color map lookup table.
export interface ColorMap {
  // 'Viridis', etc.
  presetName: string;
  // A custom scalar range for color map. Initialize to range limit.
  range: [number, number];
  // The range limit of the scalar field associated with this color map.
  // This limit restricts valid range values. Defaults to the global scalar field
  // range in the volume.
  globalRange: [number, number];
  // Whether this color map should be displayed in the UI. If there is no
  // visible surface or other visualization with the displayDataName, then the
  // color map will be hidden in the UI until at least one is visible.
  visible: boolean;
  // The number of levels or table values used when discrete color map type
  // is selected.
  bins: number;
  // Whether this color map should use a user-defined number of discrete levels.
  discretize: boolean;
}
// Parameters for a Contour filter
export interface ContourParam {
  typ: 'Contour';
  // Variable to be contoured.
  contourVariable: DisplayPvVariable;
  // List of isosurface values.
  isosurfaces: number[];
  // The number of level sets to generate as part of a series.
  nlevels: number;
  // the lower bound for the series interval.
  lowerboundrange: number;
  // the upper bound for the series interval.
  upperboundrange: number;
}
// Set of parameters that define a Cylinder
export interface CylinderParam {
  typ: 'Cylinder';
  // Start of the cylinder
  start: Vector3;
  // End of the cylinder
  end: Vector3;
  // Radius of the cylinder
  radius: number;
}
// Common set of rendering parameters and options for tree nodes.
export interface DisplayProps {
  // Mesh representation.
  reprType?: RepresentationType;
  // Dataset to be displayed.
  displayVariable?: DisplayPvVariable | null;
  // Whether to color the surfaces. Ignored if displayVariable is truthy.
  showColors?: boolean | null;
  // Whether to highlight neighbor edges.
  highlightNeighborEdges?: boolean | null;
}
// Includes the needed information to identify a variable to be displayed.
export interface DisplayPvVariable {
  // 'Density', etc.
  displayDataName: string;
  // Used to select vector components.
  displayDataNameComponent: number;
}
// Parameter for extracting surface blocks into a subset.
export interface ExtractSurfacesParam {
  typ: 'ExtractSurfaces';
  // List of surface to extract.
  surfaces: string[];
  // List of actuator disks to extract.
  disks: string[] | null;
}
// Set of parameters that define the volume extraction
export interface ExtractVolumesParam {
  typ: 'ExtractVolumes';
  // List of volumes to extract.
  volumes: string[] | null;
}
// Parameters for a far field visualization.
export interface FarFieldParam {
  typ: 'FarField';
  // If we should be able to see through the far field or not.
  transparent: boolean;
  // Used to identify the exact filter type.
  paramType: string;
  // What type of far field are we visualizing
  param: SphereParam | HalfSphereParam | AxisAlignedBoxParam | CylinderParam;
}
// Field association type of a given array.
export enum FieldAssociation {
  POINT = 'Point',
  CELL = 'Cell',
}
// Parameters for a Glyph filter
export interface GlyphParam {
  typ: 'Glyph';
  // Name of the data array to use for orientation and scale, Must be a vector array.
  // (e.g., "Velocity", but not "Density").
  // Must be the name of one of the parent node's pointData list.
  //
  dataName: string;
  // Constant scale factor for size of the vectors.
  scaleFactor: number;
  // Maximum number of sample points to take for vectors.
  samplePoints: number;
  // Use every Nth point for sampling.
  everyNth: boolean;
  // If true, glyph size will not be scaled by vector magnitude.
  fixedLength: boolean;
}
// Parameters for the grid motion VTK source
export interface GridMotionParam {
  // Start time of the animation
  startTime: number;
  // End time of the animation
  endTime: number;
  // Number of steps of the animation
  numberOfSteps: number;
  // fvm.Param str representation with the MotionData proto to be imposed
  fvmMotionData: string;
  // Size of the axes relative to the screen in (0, 0.9)
  axesLength: number;
  // Whether to draw the reference frame axes
  drawAxes: boolean;
  // Whether to draw the reference frame axes links
  drawAxesLinks: boolean;
  // Whether to transform the boundary surfaces
  transformSurfaces: boolean;
  // If this variable is != from an empty string, then only
  // the frame with ID childFrameId and its parent will be rendered.
  childFrameId: string;
}
// Set of parameters that define a closed half sphere (dome).
export interface HalfSphereParam {
  typ: 'HalfSphere';
  // Center of the sphere
  center: Vector3;
  // Radius of the sphere
  radius: number;
  // Direction to the top of the dome
  normal: Vector3;
}
// Histogram data
export interface HistogramData {
  typ: 'Histogram';
  // Histogram results (inner list) per input node id (outer list).
  histogram: number[][];
  // Mean value for each bin.
  binMean: number[];
  // Bounds for each bin. Length is binCount + 1.
  binBounds: number[];
  // Total number of bins in result. Should match request on success
  binCount: number;
  // Provenance for histogram data.
  nodeIds: string[];
}
// Set of parameters that define a histogram chart
export interface HistogramParam {
  typ: 'Histogram';
  // The extents of the scalar quantity.
  range: [number, number];
  // The number of histogram bins.
  bins: number;
  // Variable to plot.
  quantity: DisplayPvVariable;
}
export interface ImplicitPlaneWidgetState {
  typ: 'ImplicitPlaneWidgetRepresentation';
  plane: PlaneParam;
  bounds: Bounds;
}
// List of particle advection integration directions for streamlines.
export enum IntegrationDirection {
  FORWARD = 'Forward',
  BACKWARD = 'Backward',
  BOTH = 'Both',
}
// Parameters for intersection curve filter
export interface IntersectionCurveParam {
  typ: 'IntersectionCurve';
  // The plane param of the parent slice
  plane: PlaneParam;
}
// Input surface type for LIC plot.
export enum LICSurfaceType {
  GEOMETRY_SURFACE = 'GeometrySurface',
  PLANE = 'Plane',
}
// Set of parameters that define a line
export interface LineParam {
  typ: 'Line';
  // First point on the line
  point1: Vector3;
  // 2nd point on the line
  point2: Vector3;
}
// Parameters for Line filter
export interface LineProbeParam {
  typ: 'Line';
  // A start and end point
  line: LineParam;
  // Line sampling strategy - uniform, segment center, cell intersection
  sampling: string;
  // Number of samples
  resolution: number;
}
export interface LineSourceWidgetState {
  typ: 'LineSourceWidgetRepresentation';
  line: LineParam;
  resolution: number;
}
// Set of parameters that define line rendering
export interface LineStreamline {
  typ: 'LineStreamline';
  // Width of the line
  width: number;
}
// Set of params to define a monitor plane.
export interface MonitorPlaneParam {
  typ: 'MonitorPlane';
  // Position and normal of monitor plane.
  plane: PlaneParam;
  // The unique id of the monitor plane fvm param.
  id: string;
  // Optional box clip to constrain plane.
  boxclip: BoxClipParam | null;
  // Optional list of volumes to constrain the plane.
  volumes: ExtractVolumesParam | null;
}
// Set of params to define a monitor point.
export interface MonitorPointParam {
  typ: 'MonitorPoint';
  // Position and radius of monitor point representation
  point: SphereParam;
  // The unique id of the monitor point represent
  id: string;
}
// Parameters for MultiSlice filter
export interface MultiSliceParam {
  typ: 'MultiSlice';
  // A point that lies on the start plane
  start: Vector3;
  // A point that lies on the start plane
  end: Vector3;
  // Parameter for number of slices
  nslices: number;
  // Parameter for current slice
  currentslice: number;
  // Parameter to show multiple slices.
  showmultiple: boolean;
  // Parameter to project vectors onto slices.
  projectvectors: boolean;
  // Parameter for result url
  url: string;
}
// Identify an object or location in the simulation environment.
export interface ObjectOrLocation {
  // Block/component name, e.g. "0/bounds/Wing".
  blockName?: string | null;
  // Bounding box in world coordinates.
  // Values are (xmin, xmax, ymin, ymax, zmin, zmax).
  bounds?: Bounds | null;
}
// Set of parameters that define an oriented cube
export interface OrientedCubeParam {
  typ: 'Cube';
  // Prism's corner closest to (-Inf, -Inf, -Inf)
  min: Vector3;
  // Prism's corner closest to (+Inf, +Inf, +Inf)
  max: Vector3;
  // Location of the prism reference frame's origin in meters.
  origin: Vector3;
  // Direction of the prism reference frame's x-axis.
  xAxis: Vector3;
  // Direction of the prism reference frame's y-axis.
  yAxis: Vector3;
}
// Specifies the direction of changing camera position. ViewUp and ViewRight are
// vectors which map to the "up" and "right" directions of the screen
export enum PanDirectionType {
  VIEW_UP = 'ViewUp',
  VIEW_RIGHT = 'ViewRight',
}
export interface PlaneLICParam {
  // Plane to use as a source.
  plane: PlaneParam;
  // Whether to use the visible bounds.
  useBounds: boolean;
  // Bounds to clip plane.
  bounds: Bounds;
}
// Set of parameters that define a plane
export interface PlaneParam {
  typ: 'Plane';
  // A point that lies on the plane
  origin: Vector3;
  // Plane normal vector
  normal: Vector3;
}
// Plot data
export interface PlotData {
  data: HistogramData | ScatterPlotData | null;
}
// Parameters for plot filter
export interface PlotParam {
  typ: 'Plot';
  // Data input node ids.
  nodeIds: string[];
  param: HistogramParam | ScatterPlotParam;
}
// An event reported by the "progress" message.
export interface Progress {
  // Value of vtkAlgorithm.ProgressText. If
  // vtkAlgorithm.ProgressText is unset, the algorithm's classname (e.g.,
  // "vtkStreamTracer") will be reported.
  text: string;
  // The last value set in
  // vtkAlgorithm.UpdateProgress. Typically, the value is in range [0, 1].
  progress: number;
}
// Parameter for a file reader node. It is always at the root of the node tree.
export interface ReaderParam {
  typ: 'Reader';
  // Path of the mesh to read
  url: string;
  // A subset of fvm params for derived field calculation
  fvmparams: string | null;
  // A string representing custom expressions to be evaluated.
  customexp: string | null;
}
// Parameters for local mesh region visualization
export interface RefinementRegionParam {
  typ: 'RefinementRegion';
  // Unique identifier for this refinement region
  id: string;
  // The type of refinement region to display
  param: OrientedCubeParam | SphereShellParam | AnnularCylinderParam;
  // If we should render this local mesh region as a wireframe or transparent solid.
  wireframe: boolean;
  // If the current region is selected by the user.
  selected: boolean;
  // If the current region should be shown or hidden in the visualizer.
  visible: boolean;
}
export enum RefinementRegionShapeType {
  CUBE = 'Cube',
  SPHERE_SHELL = 'SphereShell',
  ANNULAR_CYLINDER = 'AnnularCylinder',
}
//
// Defines how a mesh is displayed.
// The allowed values are defined in
// ParaView/Remoting/Views/Resources/views_and_representations.xml,
// vtkGeometryRepresentation, SetRepresentation enum.
export type RepresentationType = 'Points' | 'Wireframe' | 'Surface' | 'Surface With Edges';
// Specifies the axis of rotation. ViewUp (or ViewRight) is the world-coord
// vector that maps to the "up" (or right) direction on the screen.
// ViewToward is the world-coord vector that corresponds to the screen's
// normal vector.
export enum RotationAxisType {
  VIEW_UP = 'ViewUp',
  VIEW_RIGHT = 'ViewRight',
  VIEW_TOWARD = 'ViewToward',
}
// Result of a successful Paraview RPC
export interface RpcResult {
  viewState: ViewState | null;
}
// Scatterplot data - is used for both scatter plots and line plots as they share the underlying data.
export interface ScatterPlotData {
  typ: 'ScatterPlot';
  // Quantity results (inner list) per input node id (outer list).
  quantity: number[][];
  // X coordinates (inner list) per input node id (outer list).
  xcoords: number[][];
  // Y coordinates (inner list) per input node id (outer list).
  ycoords: number[][];
  // Z coordinates (inner list) per input node id (outer list).
  zcoords: number[][];
  // Arc lengths (inner list) per input node id (outer list).
  arc: number[][];
  // Provenance for scatterplot data.
  nodeIds: string[];
}
// Set of parameters that define a scatter plot chart
export interface ScatterPlotParam {
  typ: 'ScatterPlot';
  // The extents of the scalar quantity.
  range: [number, number];
  // Variable to plot.
  quantity: DisplayPvVariable;
}
// Set of parameters that define seeding globe
export interface SeedGlobeParam {
  typ: 'Globe';
  // Center of the globe.
  center: Vector3;
  // Radius of the globe
  radius: number;
}
// Set of parameters that define seeding grid
export interface SeedGridParam {
  typ: 'Grid';
  // Center of the grid.
  center: Vector3;
  // Unit vector parallel to rake.
  u: Vector3;
  // Unit vector for slider.
  v: Vector3;
  // Space between seeds.
  seedSpacing: number;
  // Number of seeds in a rake.
  seedRes: number;
  // Space between parallel rakes.
  rakeSpacing: number;
  // Number of rakes.
  rakeRes: number;
}
// Set of parameters that define surface LIC
export interface SeedLICParam {
  typ: 'LIC';
  // Input surface type
  surfaceType: LICSurfaceType;
  // LIC plot surface information.
  surfaces: string[] | PlaneLICParam;
  // Maximum number of cell hops.
  numberSteps: number;
}
// Parameters to specify positions of seed particles
export type SeedPlacementParams = SeedRakeParam | SeedGridParam | SeedGlobeParam | SeedSurfaceParam | SeedLICParam;
// List of seed placement strategies for streamlines.
export enum SeedPlacementType {
  RAKE = 'Rake',
  GRID = 'Grid',
  GLOBE = 'Globe',
  SURFACE = 'Surface',
  DISK = 'Disk',
  L_I_C = 'LIC',
}
// Set of parameters that define a seeding rake
export interface SeedRakeParam {
  typ: 'Rake';
  // Start point of the rake.
  start: Vector3;
  // End point of the rake
  end: Vector3;
}
// Set of parameters that define seeding surface
export interface SeedSurfaceParam {
  typ: 'Surface';
  // List of surface to use as a seed source.
  surfaces: string[];
  // Every N points sample rate.
  sampleRate: number;
  // Offset from the surface to place seeds.
  offset: number;
  // Compute surface constrained streamlines.
  projectOnSurface: boolean;
}
// Set of parameters that define a sphere
export interface SphereParam {
  typ: 'Sphere';
  // Center of the sphere
  center: Vector3;
  // Radius of the sphere
  radius: number;
}
// Set of parameters that define a sphere shell (one sphere around another)
export interface SphereShellParam {
  typ: 'SphereShell';
  // Location of the sphere's center
  center: Vector3;
  // Outer radius of the sphere
  outer_radius: number;
  // Inner radius of the sphere
  inner_radius: number;
}
export interface SphereWidgetState {
  typ: 'SphereWidgetRepresentation';
  sphere: SphereParam;
}
// Parameters for a StreamTracer filter
export interface StreamTracerParam {
  typ: 'StreamTracer';
  // Name of the data array, Must be a vector array
  // (e.g., "Velocity", but not "Density").
  // Must be the name of one of the parent node's pointData list.
  //
  dataName: string;
  // Seed type. Possible options are "line" and "sphere".
  //
  seedType: StreamTracerParamType;
  // Seed line
  seed: LineParam | SphereParam;
  // Maximum streamline length
  maxLength: number;
  // Resolution determines the number of streamlines generated
  resolution: number;
  // Stream line width
  width: number;
}
// List of seed types for stream tracer.
export enum StreamTracerParamType {
  LINE = 'Line',
  SPHERE = 'Sphere',
}
// Parameters for streamline rendering.
export type StreamlineRenderParams = LineStreamline | TubeStreamline;
// List of rendering options for streamline trajectories.
export enum StreamlineRendering {
  LINE_STREAMLINE = 'LineStreamline',
  TUBE_STREAMLINE = 'TubeStreamline',
}
// Parameters for surface LIC filter
export interface SurfaceLICParam {
  typ: 'SurfaceLIC';
  // Seed placement strategy.
  seedPlacementType: SeedPlacementType;
  // Name of the data array to use for particle advection, Must be a vector array.
  // (e.g., "Velocity", but not "Density").
  // Must be the name of one of the parent node's pointData list.
  //
  dataName: string;
  // Streamline integration direction.
  integrationDirection: IntegrationDirection;
  // Streamline maximum trajectory length.
  maximumLength: number;
  // Two values between 0 and 100 used to define the texture png.
  // The values are converted into an index between 0 and 128 to be used as control points.
  // The first value will control intensity of dark streaks.
  // The second value will control intensity of light streaks.
  textureContrastControl: [number, number] | null;
  // Parameters to specify streamline calculation for LIC
  seedPlacementParams?: SeedPlacementParams;
  // Parameter to version control surface LIC computation. Should not change frequently.
  version?: number | null;
  // Parameter for result url
  url: string;
}
// Parameters for a Threshold filter
export interface ThresholdParam {
  typ: 'Threshold';
  // Variable to be thresholded.
  thresholdVariable: DisplayPvVariable;
  // The lower bound for the threshold.
  lowerThreshold: number;
  // the upper bound for the threshold.
  upperThreshold: number;
  // Whether to select cells inside or outside the range.
  inclusiveMode: boolean;
  // If true a cell is thresholded iff all points are within a range. Else, thresholding happens if one of the cell points belongs to the range.
  allScalarsWithinRange: boolean;
  // If false, the vertex scalars are treated as a discrete set. If true, they are treated as a continuous interval over the minimum and maximum.
  useContinuousCellRange: boolean;
  // If false, the thresholded cells will not be clipped. If true, the thresholded cells will be clipped.
  clipCells: boolean;
}
// Information about a Paraview source or a filter
export interface TreeNode {
  // Name of this node. An arbitrary string
  name: string;
  // A id of this node, unique in this session
  id: string;
  // The mesh bounds in the world coordinate system.
  // Values are (xmin, xmax, ymin, ymax, zmin, zmax).
  //
  // This is a readonly parameter. In setnodes, this field is ignored.
  //
  bounds: Bounds | null;
  // List of point data sources available from this node.
  //
  // This is a readonly parameter. In setnodes, this field is ignored.
  //
  pointData: ArrayInformation[];
  // Parameters that define this node.
  // For example, for a clip filter, it contains ClipSliceParam that specifies the
  // plane origin and normal.
  param: TreeNodeParam;
  // A counter that increments every time the param field is updated.
  // Used to reliably and quickly detect changes to param.
  paramSeq: number;
  // Is the node marked as visible by the user?
  visible: boolean;
  // List of children
  child: TreeNode[];
  // Params for rendering and display.
  // TODO(matt): remove MaybeNull when moving to beta.
  //
  displayProps: DisplayProps | null;
}
// Possible node parameter types.
export type TreeNodeParam = ClipSliceParam | MultiSliceParam | ContourParam | ThresholdParam | GlyphParam | ReaderParam | StreamTracerParam | ActuatorDiskParam | VisStreamlinesParam | MonitorPointParam | FarFieldParam | ExtractSurfacesParam | MonitorPlaneParam | SurfaceLICParam | LineProbeParam | IntersectionCurveParam | PlotParam | RefinementRegionParam;
// TreeNode type names
export enum TreeNodeType {
  READER = 'Reader',
  CLIP = 'Clip',
  BOX_CLIP = 'BoxClip',
  SLICE = 'Slice',
  STREAM_TRACER = 'StreamTracer',
  GLYPH = 'Glyph',
  CONTOUR = 'Contour',
  THRESHOLD = 'Threshold',
  MULTI_SLICE = 'MultiSlice',
  ACTUATOR_DISK = 'ActuatorDisk',
  STREAMLINES = 'Streamlines',
  MONITOR_POINT = 'MonitorPoint',
  FAR_FIELD = 'FarField',
  EXTRACT_SURFACES = 'ExtractSurfaces',
  MONITOR_PLANE = 'MonitorPlane',
  SURFACE_L_I_C = 'SurfaceLIC',
  LINE = 'Line',
  INTERSECTION_CURVE = 'IntersectionCurve',
  PLOT = 'Plot',
  REFINEMENT_REGION = 'RefinementRegion',
}
// Set of parameters that define tube rendering
export interface TubeStreamline {
  typ: 'TubeStreamline';
  // Radius of the tube
  radius: number;
}
// A 3D vector
export interface Vector3 {
  x: number;
  y: number;
  z: number;
}
// View attributes that can be changed through UI.
export interface ViewAttrs {
  // Mesh representation.
  reprType?: RepresentationType | null;
  // Variable to be displayed.
  displayVariable?: DisplayPvVariable | null;
  // DEPRECATED. NOT BEING USED IN PRODUCTION.
  // Sphere size for representing points since PointSize is useless.
  sphereSize?: number | null;
  //
  // Maps the block name ("0/border/x_minus") to a boolean indicating if the block
  // is highlighted or not.
  blockHighlighted?: { [blockName:string]: boolean} | null;
  //
  // Maps the block name ("0/border/x_minus") to a boolean indicating whether the
  // block is visible (true) or hidden (false).  Blocks in the mesh, but not in
  // this map have the default visibility of true.
  blockVisibility?: { [blockName:string]: boolean} | null;
  // List of color maps used to sync state with the UI
  colorMaps?: [DisplayPvVariable, ColorMap][] | null;
  // Color to use for the 3D viewer background.
  backgroundColor?: [number, number, number] | null;
  // Proto version.
  viewAttrsVersion?: number | null;
}
// Information about the mesh file displayed in a Paraview window
export interface ViewState {
  // The URL of the mesh file.
  path: string;
  // List of cell and point data found in the volume mesh file.
  data: ArrayInformation[];
  // List of cell and point data found in the surface mesh file.
  surfaceData: ArrayInformation[];
  // List of blocks in the mesh file. A block is either the main grid, or a border.
  // "0/elem" is the main grid for the zone 0. For a cuboid mesh,
  // there will be border blocks such as
  // "0/border/x_minus", "0/border/x_plus", etc.
  blocks: string[];
  // TreeNode hierarchy.
  root: TreeNode;
  // Various display attributes.
  attrs: ViewAttrs;
  // Visibilty of axes and grid.
  axesGridVisibility: boolean | null;
}
// Parameters for streamline filter
export interface VisStreamlinesParam {
  typ: 'Streamlines';
  // Seed placement strategy.
  seedPlacementType: SeedPlacementType;
  // Name of the data array to use for particle advection, Must be a vector array.
  // (e.g., "Velocity", but not "Density").
  // Must be the name of one of the parent node's pointData list.
  //
  dataName: string;
  // Streamline integration direction.
  integrationDirection: IntegrationDirection;
  // Streamline maximum trajectory length.
  maximumLength: number;
  // Number of streamlines
  nstreamlines: number;
  // Parameters to specify streamline rendering.
  streamlineRenderParams?: StreamlineRenderParams;
  // Parameters to specify positions of seed particles
  seedPlacementParams?: SeedPlacementParams;
  // Parameter for current set of streamlines
  currentstreamlines: number;
  // Parameter to show multiple sets of streamlines.
  showmultiple: boolean;
  // Parameter for result url
  url: string;
}
// Parameters that specify a state of a widget
export type WidgetState = ImplicitPlaneWidgetState | LineSourceWidgetState | SphereWidgetState | BoxWidgetState;
// List of interactive widgets for setting filter parameters
export enum WidgetType {
  IMPLICIT_PLANE_WIDGET_REPRESENTATION = 'ImplicitPlaneWidgetRepresentation',
  LINE_SOURCE_WIDGET_REPRESENTATION = 'LineSourceWidgetRepresentation',
  SPHERE_WIDGET_REPRESENTATION = 'SphereWidgetRepresentation',
  BOX_WIDGET_REPRESENTATION = 'BoxWidgetRepresentation',
}
// Creates or updates a widget.  Arg bounds specifies the bounding
// box of the node (e.g., Reader) being edited.
//
// - If the widget does not exist now, a new widget is created with the given state.
//
// - If the widget of a different "typ" already exists, it is deleted first, then one with
// the correct type is created.
//
// - Otherwise, the existing widget is kept, and its state is updated to
// match the "state" arg.
export async function activatewidget(client: ParaviewRpcClient, bounds: Bounds, state: WidgetState)
  : Promise<RpcResult> {
  return client.runRpc<RpcResult>(
    'luminarycloud.activatewidget', [client.viewId, bounds, state]);
}
// Returns a list of all blocks within the screen-space rectangle
export async function boxblockselect(client: ParaviewRpcClient, xMin: number, xMax: number, yMin: number, yMax: number, frustumBased: boolean | null)
  : Promise<string[]> {
  return client.runRpc<string[]>(
    'luminarycloud.boxblockselect', [client.viewId, xMin, xMax, yMin, yMax, frustumBased]);
}
// Cleanup of sources/widgets which can be remnant of old states or may
// have remained in the source tree after a ParaView error/connection re-establishment.
export async function cleanup(client: ParaviewRpcClient)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.cleanup', [client.viewId]);
}
// Deletes the airfoil visualization source.
export async function clearairfoilsource(client: ParaviewRpcClient)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.clearairfoilsource', [client.viewId]);
}
// Deletes the coordinate axes source.
export async function clearcoordinateaxes(client: ParaviewRpcClient)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.clearcoordinateaxes', [client.viewId]);
}
// Deletes the farfield visualization source.
export async function clearfarfieldpreview(client: ParaviewRpcClient)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.clearfarfieldpreview', [client.viewId]);
}
// Deletes the seed grid preview.
export async function clearseedgridpreview(client: ParaviewRpcClient)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.clearseedgridpreview', [client.viewId]);
}
// Deletes the widget. No-op if the widget does not exist.
export async function deletewidget(client: ParaviewRpcClient)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.deletewidget', [client.viewId]);
}
// Controls the visibility of the farfield visualization source.
export async function displayfarfieldpreview(client: ParaviewRpcClient, visible: boolean)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.displayfarfieldpreview', [client.viewId, visible]);
}
// Return the ParaviewRpc.CameraState
export async function getcamerastate(client: ParaviewRpcClient)
  : Promise<CameraState> {
  return client.runRpc<CameraState>(
    'luminarycloud.getcamerastate', [client.viewId]);
}
// Find the cell that at the given screen location and return its quantities.
export async function getcelldataatpoint(client: ParaviewRpcClient, client_view: ClientWindowState | null, screenX: number, screenY: number)
  : Promise<CellState[]> {
  return client.runRpc<CellState[]>(
    'luminarycloud.getcelldataatpoint', [client.viewId, client_view, screenX, screenY]);
}
// Get the last known good state from paraview.
export async function getlastknowngoodstate(client: ParaviewRpcClient, name: string)
  : Promise<RpcResult> {
  return client.runRpc<RpcResult>(
    'luminarycloud.getlastknowngoodstate', [client.viewId, name]);
}
// Returns plot data.
export async function getplotdata(client: ParaviewRpcClient, plotParam: PlotParam)
  : Promise<PlotData | null> {
  return client.runRpc<PlotData | null>(
    'luminarycloud.getplotdata', [client.viewId, plotParam]);
}
// Returns the current screen visible data range.
export async function getscreenvisibledatarange(client: ParaviewRpcClient, client_view: ClientWindowState | null, displayVariable: DisplayPvVariable)
  : Promise<[number, number] | null> {
  return client.runRpc<[number, number] | null>(
    'luminarycloud.getscreenvisibledatarange', [client.viewId, client_view, displayVariable]);
}
// Returns the name of the surface at the given screen coordinates.
// Returns empty string if no surfaces are found at the given point.
export async function getsurfaceatpoint(client: ParaviewRpcClient, client_view: ClientWindowState | null, screenX: number, screenY: number)
  : Promise<string> {
  return client.runRpc<string>(
    'luminarycloud.getsurfaceatpoint', [client.viewId, client_view, screenX, screenY]);
}
// Generates and initialize grid motion source.
export async function initializegridmotionsource(client: ParaviewRpcClient, client_view: ClientWindowState | null, param: GridMotionParam)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.initializegridmotionsource', [client.viewId, client_view, param]);
}
// Change the camera position along the 'up-down' or 'right-left' axes.
export async function pancamera(client: ParaviewRpcClient, client_view: ClientWindowState | null, direction: PanDirectionType, amount: number)
  : Promise<CameraState> {
  return client.runRpc<CameraState>(
    'luminarycloud.pancamera', [client.viewId, client_view, direction, amount]);
}
// Ping is used only to inform the server of the liveness of the
// client.  The client sends this RPC with a random arg every 5
// seconds. The server-side proxy (paraviewmux) intercepts the call and
// echos the arg back immediately.  The server will drop the connection if
// no traffic is observed on the websocket for more than 5 * (1+c) seconds,
// where c is a small positive slack factor.
//
export async function ping(client: ParaviewRpcClient, arg: string)
  : Promise<string> {
  return client.runRpc<string>(
    'luminarycloud.ping', [client.viewId, arg]);
}
// Rescales the color bar to a custom data range.
export async function rescaletransferfunctiontocustomdatarange(client: ParaviewRpcClient, min_range: number, max_range: number, displayVariable: DisplayPvVariable)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.rescaletransferfunctiontocustomdatarange', [client.viewId, min_range, max_range, displayVariable]);
}
// Set the default values to all the camera-related fields
export async function resetcamera(client: ParaviewRpcClient)
  : Promise<CameraState> {
  return client.runRpc<CameraState>(
    'luminarycloud.resetcamera', [client.viewId]);
}
// Rotate the camera about the given axis passing through its center of rotation.
export async function rotatecamera(client: ParaviewRpcClient, client_view: ClientWindowState | null, axis: RotationAxisType, angle: number)
  : Promise<CameraState> {
  return client.runRpc<CameraState>(
    'luminarycloud.rotatecamera', [client.viewId, client_view, axis, angle]);
}
// Saves a PNG image of the current view (with the same aspect ratio as the
// current resolution and a minimum of 2000px in the horitonzal axis) and returns
// the decoded ascii string representation of the stored image.
export async function screenshot(client: ParaviewRpcClient, client_view: ClientWindowState | null, transparentBackground: boolean)
  : Promise<string> {
  return client.runRpc<string>(
    'luminarycloud.screenshot', [client.viewId, client_view, transparentBackground]);
}
// Initialize and display a airfoil for BET.
export async function setairfoilsource(client: ParaviewRpcClient, param: AirfoilParam)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.setairfoilsource', [client.viewId, param]);
}
// Push a camera state to paraview.
export async function setcamera(client: ParaviewRpcClient, cameraState: CameraState)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.setcamera', [client.viewId, cameraState]);
}
// Changes the camera position and angle while keeping the zoom
// level unchanged.
//
// ParallelProjection sets the camera's projection mode to either
// perspective (false) or orthographic (true).
//
// Args viewUp and cameraDirection must be either both null, or both set.
// If they are null, the camera position and the focal point remain
// unchanged.  viewUp sets the world-coord vector that corresponds to the
// "up" direction on the screen. cameraDirection sets the direction of the
// line that connects the camera's location and its focal point.
//
export async function setcameraangle(client: ParaviewRpcClient, client_view: ClientWindowState | null, parallelProjection: boolean, viewUp: Vector3 | null, cameraDirection: Vector3 | null)
  : Promise<CameraState> {
  return client.runRpc<CameraState>(
    'luminarycloud.setcameraangle', [client.viewId, client_view, parallelProjection, viewUp, cameraDirection]);
}
// Set the Center of Rotation for the Camera.
export async function setcenterofrotationcamera(client: ParaviewRpcClient, client_view: ClientWindowState | null, screenX: number, screenY: number)
  : Promise<CameraState> {
  return client.runRpc<CameraState>(
    'luminarycloud.setcenterofrotationcamera', [client.viewId, client_view, screenX, screenY]);
}
// Initializes the coordinate axes source used to display coordinate axes.
export async function setcoordinateaxes(client: ParaviewRpcClient, param: GridMotionParam)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.setcoordinateaxes', [client.viewId, param]);
}
// Initialize and displays a FarField preview.
export async function setfarfieldpreview(client: ParaviewRpcClient, param: FarFieldParam)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.setfarfieldpreview', [client.viewId, param]);
}
// Calls setviewattrs and setnodes with a given ViewState.
export async function setglobalviewstate(client: ParaviewRpcClient, state: ViewState)
  : Promise<RpcResult> {
  return client.runRpc<RpcResult>(
    'luminarycloud.setglobalviewstate', [client.viewId, state]);
}
// Set the state of nodes in the view.  The caller must pass the full node tree
// at the root.  It may add or delete filters.
export async function setnodes(client: ParaviewRpcClient, root: TreeNode | null)
  : Promise<RpcResult> {
  return client.runRpc<RpcResult>(
    'luminarycloud.setnodes', [client.viewId, root]);
}
// Sets the visible refinement regions.
export async function setrefinementregions(client: ParaviewRpcClient, refinementregions: RefinementRegionParam[])
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.setrefinementregions', [client.viewId, refinementregions]);
}
// Initialize and display a seed grid for streamlines.
export async function setseedgridpreview(client: ParaviewRpcClient, param: SeedGridParam)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.setseedgridpreview', [client.viewId, param]);
}
// Sets the view attributes of all the objects in the view, such as the
// representation type (wireframe, surface, etc) and opacity.
// It updates every data block in the mesh file.
export async function setviewattrs(client: ParaviewRpcClient, attrs: ViewAttrs)
  : Promise<RpcResult> {
  return client.runRpc<RpcResult>(
    'luminarycloud.setviewattrs', [client.viewId, attrs]);
}
// Show grid axes.
export async function showgridaxes(client: ParaviewRpcClient, client_view: ClientWindowState | null, show: boolean)
  : Promise<RpcResult> {
  return client.runRpc<RpcResult>(
    'luminarycloud.showgridaxes', [client.viewId, client_view, show]);
}
// Called when stopping the grid motion animation.
export async function stopgridmotionsource(client: ParaviewRpcClient, client_view: ClientWindowState | null)
  : Promise<void> {
  return client.runRpc<void>(
    'luminarycloud.stopgridmotionsource', [client.viewId, client_view]);
}
// Synchronize the the states of the client and the server.
// This is always the first RPC made after a new websocket connection is established.
// Arg root is the last state of the canvas as known to the browser. If the browser has
// no state, then it should send a single node as the root.
//
// {
// typ: "Reader",
// url: <the mesh file to be opened>
// }
//
// Arg attrs is the render view attributes to be set for the nodes in the "root".
//
// The server compares the root arg and its own view and takes the one that looks newer.
// There are two common situations:
//
// - When the connection is established because the browser has reloaded a page, then
// the client's root contains just the Reader node (see above). If the server
// has loaded the same mesh URL, and it has more filter nodes, then it ignores the root and attrs args
// and keeps using the server state. Else, it discards the server state, including all the filters,
// and loads the requested file.
//
// - When the connection is established because the server has rebooted, then the
// client typically has a full filter tree, and server has no state. In this
// case, the server takes the client's tree and attrs.
//
// - Otherwise, the server takes the bigger tree of the two. This case should be
// rare.
//
// Arg cameraState is the existing camera state info, if there is prior camera state that should be
// respected.  If specified, this will determine the camera's initial position, angle, etc.
//
//
export async function syncnodes(client: ParaviewRpcClient, root: TreeNode | null, attrs: ViewAttrs | null, cameraState: CameraState | null)
  : Promise<RpcResult> {
  return client.runRpc<RpcResult>(
    'luminarycloud.syncnodes', [client.viewId, root, attrs, cameraState]);
}
// Change the zoom level while keeping the camera angle.
// Arg scale is the change from the current
// zoom level. For example, it is 1.5, then
// the camera will move 1/1.5x the distance from the current position.
export async function zoomcamera(client: ParaviewRpcClient, client_view: ClientWindowState | null, scale: number)
  : Promise<CameraState> {
  return client.runRpc<CameraState>(
    'luminarycloud.zoomcamera', [client.viewId, client_view, scale]);
}
// Zoom to box.
export async function zoomtobox(client: ParaviewRpcClient, client_view: ClientWindowState | null, minX: number, maxX: number, minY: number, maxY: number)
  : Promise<CameraState> {
  return client.runRpc<CameraState>(
    'luminarycloud.zoomtobox', [client.viewId, client_view, minX, maxX, minY, maxY]);
}
// This message is sent immediately before the server disconnects
// the websocket connection.  The payload is a base64-encoded serialized
// base.Status. In practice, this message is sent by paraviewmux after it detects a
// crash of the paraview process. The payload contains the crash message.
//
// Note that we can't use the websocket close frame for this purpose, since it
// cannot be fragmented and thus its length is limited to 125 bytes.
export async function ondisconnect(
  client: ParaviewRpcClient,
  callback: (ev: string)=>void): Promise<()=>Promise<void>> {
  return await client.subscribe<string>('luminarycloud.disconnect', callback);
}
// Reports progress events from filter nodes.
//
// It is logically the same as 'paraview.progress' event (ParaViewWebProgressUpdate) defined in protocols.py, but
// it is reimplemented to provide better integration with the rest of the codebase.
export async function onprogress(
  client: ParaviewRpcClient,
  callback: (ev: Progress)=>void): Promise<()=>Promise<void>> {
  return await client.subscribe<Progress>('luminarycloud.progress', callback);
}
//
// A message from the server to the client to update the state of the widget.
// It is sent only while a widget (e.g., ImplicitPlaneWidgetRepresentation) is active.
//
export async function onupdatewidget(
  client: ParaviewRpcClient,
  callback: (ev: WidgetState)=>void): Promise<()=>Promise<void>> {
  return await client.subscribe<WidgetState>('luminarycloud.updatewidget', callback);
}
export const PVPROTO_VIEW_ATTRS_VERSION: number = 7;
