// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as clusterconfigpb from '../proto/clusterconfig/clusterconfig_pb';

import * as random from './random';

// Generates a new ID for an item represented in the simulation tree
export function newNodeId() {
  return random.string(32);
}

// Maps a GPU type to its name.  This list should be updated whenever GPUType in
// clusterconfig.proto is updated.
const gpuTypeNames: string[] = [
  'Auto',
  'CPU',
  'T4',
  'V100',
  'A100',
  'A100_80',
  'H100',
];

// Reports a human-readable name ('A100') for the GPU type.
export function gpuTypeName(gpuType: clusterconfigpb.GPUType): string {
  return gpuTypeNames[gpuType];
}
