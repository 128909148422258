// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectedFilterNode } from '../../visFilter/useFilterNode';

import { EmptyPropPanel } from './Empty';
import { ClipSlicePropPanel } from './filter/ClipSlice';
import { ContourPropPanel } from './filter/Contour';
import { ExtractSurfacesPropPanel } from './filter/ExtractSurfaces';
import { GlyphPropPanel } from './filter/Glyph';
import { IntersectionCurvePropPanel } from './filter/IntersectionCurve';
import { LineProbePropPanel } from './filter/LineProbe';
import { MultiSlicePropPanel } from './filter/MultiSlice';
import { StreamTracerPropPanel } from './filter/StreamTracer';
import { SurfaceLICPropPanel } from './filter/SurfaceLIC';
import { ThresholdPropPanel } from './filter/Threshold';
import { VisStreamlinesPropPanel } from './filter/VisStreamlines';

/**
 * A panel for displaying detailed properties of the current filter.
 */
export const FilterPropPanel = () => {
  const {
    displayProps,
    filterNode,
    nodeId,
    param,
    parentFilterNode,
    viewState,
  } = useSelectedFilterNode();

  const { projectId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  if (param?.typ === ParaviewRpc.TreeNodeType.READER) {
    return <EmptyPropPanel />;
  }

  if (lcvisEnabled) {
    if (filterNode && parentFilterNode && param && displayProps && nodeId) {
      const extraProps = {
        displayProps,
        nodeId,
        filterNode,
        parentFilterNode,
        param,
        viewState,
        key: nodeId,
      };
      switch (param.typ) {
        case ParaviewRpc.TreeNodeType.CLIP:
        case ParaviewRpc.TreeNodeType.SLICE:
          return <ClipSlicePropPanel {...extraProps} />;
        case ParaviewRpc.TreeNodeType.CONTOUR:
          return <ContourPropPanel {...extraProps} />;
        case ParaviewRpc.TreeNodeType.THRESHOLD:
          return <ThresholdPropPanel {...extraProps} />;
        default: {
          // no default}
        }
      }
    }
    return <></>;
  }

  if (filterNode && parentFilterNode && param && displayProps && nodeId && viewState) {
    const extraProps = {
      displayProps,
      nodeId,
      filterNode,
      parentFilterNode,
      param,
      viewState,
      key: nodeId,
    };

    switch (param.typ) {
      case ParaviewRpc.TreeNodeType.CONTOUR:
        return <ContourPropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.THRESHOLD:
        return <ThresholdPropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.CLIP:
      case ParaviewRpc.TreeNodeType.SLICE:
        return <ClipSlicePropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.STREAM_TRACER:
        return <StreamTracerPropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.GLYPH:
        return <GlyphPropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.MULTI_SLICE:
        return <MultiSlicePropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.STREAMLINES:
        return <VisStreamlinesPropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.EXTRACT_SURFACES:
        return <ExtractSurfacesPropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.SURFACE_L_I_C:
        return <SurfaceLICPropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.LINE:
        return <LineProbePropPanel {...extraProps} />;
      case ParaviewRpc.TreeNodeType.INTERSECTION_CURVE:
        return <IntersectionCurvePropPanel {...extraProps} />;
      default: {
        // no default}
      }
    }
  }

  return <></>;
};
