// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo, useRef } from 'react';

import { Logger } from '../lib/observability/logs';
import * as rpc from '../lib/rpc';
import { GEOMETRY_TREE_NODE_TYPES, NodeType } from '../lib/simulationTree/node';
import * as geometryservicepb from '../proto/api/v0/luminarycloud/geometry/geometry_pb';
import { useGeometryServerStatus } from '../recoil/geometry/geometryServerStatus';
import { DEFAULT_SELECTED_FEATURE, DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, useGeometrySelectedFeature, useGeometryState, useSetGeometryState } from '../recoil/geometry/geometryState';

import { useProjectContext } from './context/ProjectContext';
import { useSelectionContext } from './context/SelectionManager';

const logger = new Logger('GeometryFeatureSelectionManager');

const GEOMETRY_NODE_TYPES = [
  NodeType.GEOMETRY_MODIFICATION,
];

/** Handles geometry feature selection by sending RPCs to retrieve tessellation data corresponding
 * to the geometry state up to the selected feature. It also handles the deselection of the feature.
 */
export const GeometryFeatureSelectionManager = () => {
  const { projectId, geometryId } = useProjectContext();
  const geometryState = useGeometryState(projectId, geometryId);
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const [, setGeometryServerStatus] = useGeometryServerStatus(geometryId);
  const [selectedFeature, setSelectedFeature] = useGeometrySelectedFeature(geometryId);
  const [geometryServerStatus] = useGeometryServerStatus(geometryId);

  const selectedFeatureRef = useRef<string>('');
  const { selectedNode, selectedNodeIds, setSelection } = useSelectionContext();

  const selectedNodeIsFeature = useMemo(
    () => selectedNode && geometryState?.geometryFeatures.some(
      (feature) => feature.id === selectedNode?.id,
    ),
    [selectedNode, geometryState?.geometryFeatures],
  );

  useEffect(() => {
    // Focus on the selected feature whenever we click on them.
    if (selectedNode && GEOMETRY_NODE_TYPES.includes(selectedNode.type)) {
      setSelectedFeature({ id: selectedNode.id, ignoreUpdate: false });

      return;
    }

    // No selected node, make sure to reset the selected feature.
    if (!selectedNode && !selectedNodeIds.length) {
      setSelectedFeature(DEFAULT_SELECTED_FEATURE);
      return;
    }

    // If the selected node is not a feature (and also not in the geometry tree),
    // we've clicked something else, reset the selected feature.
    if (
      !selectedNodeIsFeature &&
      selectedNode &&
      !GEOMETRY_TREE_NODE_TYPES.has(selectedNode.type)
    ) {
      setSelectedFeature(DEFAULT_SELECTED_FEATURE);
    }
  }, [selectedNode, setSelectedFeature, selectedNodeIds.length, selectedNodeIsFeature]);

  // When the geometry server disconnects, reset the selection state so that we don't see weird
  // things after reconnecting.
  useEffect(() => {
    if (geometryServerStatus === 'disconnected' && selectedFeature !== DEFAULT_SELECTED_FEATURE) {
      setSelectedFeature(DEFAULT_SELECTED_FEATURE);
      setSelection([]);
    }
  }, [selectedFeature, geometryServerStatus, setSelectedFeature, setSelection]);

  useEffect(() => {
    // If the server is down, don't send requests because it would wake it up.
    if (geometryServerStatus === 'disconnected' || !geometryId ||
      geometryServerStatus === 'busy') {
      return;
    }

    // Initial ref is set, but the selected feature says that we should ignore its change. This
    // means that we have to just have to update our state. The useEffect above will reset the
    // feature to DEFAULT_SELECTED_FEATURE later on.
    if (selectedFeatureRef.current && selectedFeature === DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE) {
      selectedFeatureRef.current = '';
      return;
    }

    const updateTesselationForSelectedFeatureId = (featureId: string) => {
      const currentlySelectedIndex = geometryState?.geometryFeatures.findIndex(
        (feature) => feature.id === featureId,
      ) ?? -1;

      const currentFeature = currentlySelectedIndex !== -1 ?
        geometryState?.geometryFeatures.at(currentlySelectedIndex) :
        undefined;

      const nextFeature = currentlySelectedIndex !== -1 ?
        geometryState?.geometryFeatures.at(currentlySelectedIndex + 1) :
        undefined;

      const featureToUse = currentFeature?.operation.case === 'import' ?
        nextFeature :
        currentFeature;

      // Avoid fetching the latest tessellation, we already have it in memory.
      if (featureToUse) {
        setGeometryServerStatus('connected');
        const req = new geometryservicepb.TessellationUpToModificationRequest({
          geometryId,
          modificationId: featureToUse.id,
        });

        rpc.clientGeometry.tesselationUpToModification(req).catch((err: Error) => {
          logger.error(`tesselationUpToModification failed ${err}`);
        });
      }
    };

    // No initial ref and the feature selected is not empty. Request the feature tessellation. We
    // only do that if the feature is not new. For new features, the current tessellation is the
    // latest one.
    const hist = geometryState?.geometryHistory;
    const featureIsInHistory = (featureId: string) => (
      hist?.find((mod) => mod.historyEntry?.modification?.feature?.id === featureId) !== undefined
    );
    if (selectedFeatureRef.current === '' && selectedFeature.id) {
      selectedFeatureRef.current = selectedFeature.id;
      if (!featureIsInHistory(selectedFeature.id)) {
        return;
      }
      updateTesselationForSelectedFeatureId(selectedFeature.id);
      return;
    }

    if (!selectedFeatureRef.current) {
      return;
    }

    // Initial ref is set but the feature selected is different. Move to that tessellation. If the
    // feature is not in the history, go back to the latest so that we are up-to-date, see LC-21221
    // for why. Also only perform this operation if the selected feature is not nil. Else we'd be
    // requesting an invalid feature tessellation.
    if (selectedFeatureRef.current !== selectedFeature.id &&
      !selectedFeature.ignoreUpdate &&
      selectedFeature.id
    ) {
      selectedFeatureRef.current = selectedFeature.id;
      if (selectedFeature.id && !featureIsInHistory(selectedFeature.id)) {
        const req = new geometryservicepb.LatestTessellationRequest({
          geometryId,
        });
        rpc.clientGeometry.latestTessellation(req).catch((err: Error) => {
          logger.error(`Error ${err}`);
        });
        return;
      }

      updateTesselationForSelectedFeatureId(selectedFeature.id);
      return;
    }

    // Initial ref is not empty and the selected feature is the default. This means that we have
    // to request the latest tessellation (i.e. we lost focus on the feature and we want to go back
    // to the latest tessellation). We only do this if the selected feature is found in the
    // history since if it's not it means that we don't have to go back to the latest tessellation
    // since the feature was not sent to the server.
    if (selectedFeature === DEFAULT_SELECTED_FEATURE &&
      featureIsInHistory(selectedFeatureRef.current)) {
      setGeometryServerStatus('busy');
      selectedFeatureRef.current = '';

      const req2 = new geometryservicepb.LatestTessellationRequest({
        geometryId,
      });
      // The subscription part will handle the return value from here, we don't retry yet.
      rpc.clientGeometry.latestTessellation(req2).catch((err: Error) => {
        logger.error(`Error ${err}`);
      });
      return;
    }

    // This case happens when a user creates a feature and does not apply it before losing focus.
    // In that case, it has been decided that we don't want to leave non-acked features in the tree.
    if (selectedFeature === DEFAULT_SELECTED_FEATURE) {
      selectedFeatureRef.current = '';
      setGeometryState((oldGeometryState) => {
        if (!oldGeometryState) {
          return oldGeometryState;
        }
        const newGeometryState = { ...oldGeometryState };
        newGeometryState.geometryFeatures = newGeometryState.geometryFeatures.filter((feature) => (
          newGeometryState?.ackModifications.has(feature.id)
        ));
        return newGeometryState;
      });
    }
  }, [
    geometryId,
    geometryServerStatus,
    geometryState?.geometryFeatures,
    geometryState?.geometryHistory,
    selectedFeature,
    setGeometryState,
    setGeometryServerStatus,
  ]);

  return <></>;
};
