// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 64;
const baseHeight = 64;
export const CarIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      fill="none"
      height={finalHeight}
      ref={ref}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.9999 17H28.5999C24.2069 17 20.1049 19.195 17.6679 22.85L16.3079 24.89C15.4219
        26.22 16.3749 28 17.9719 28H28.9999V17Z"
        fill={getColor(0)}
      />
      <path
        d="M44.728 26.445L39.523 18.638C38.841 17.615 37.692 17 36.462 17H31V28H43.896C44.695
        28 45.171 27.11 44.728 26.445Z"
        fill={getColor(0)}
      />
      <path
        d="M15 46C16.6569 46 18 44.6569 18 43C18 41.3431 16.6569 40 15 40C13.3431 40 12 41.3431
        12 43C12 44.6569 13.3431 46 15 46Z"
        fill={getColor(0)}
      />
      <path
        d="M61.9 38V38.1H62H62.027C63.0608 38.1 63.9 38.9382 63.9 39.974V42.027C63.9 43.0608 63.0607
        43.9 62.028 43.9H56.932H56.8438L56.8328 43.9875C56.344 47.8784 53.0218 50.9 49.001
        50.9C44.9802 50.9 41.658 47.8784 41.1692 43.9875L41.1582 43.9H41.07H22.931H22.8428L22.8318
        43.9875C22.343 47.8784 19.0208 50.9 15 50.9C10.9792 50.9 7.65704 47.8784 7.16822
        43.9875L7.15722 43.9H7.069H1.973C0.939261 43.9 0.1 43.0608 0.1 42.026V39.973C0.1 39.2772
        0.48375 38.6762 1.04961 38.3528L1.1 38.324V38.266V37.468C1.1 32.0744 4.85782 27.4681
        10.0957 26.3588L10.1345 26.3506L10.1571 26.3181L13.2101 21.9251C17.0504 16.399 23.3607
        13.1 30.091 13.1H36.003C38.9153 13.1 41.6486 14.528 43.3119 16.9211L49.6609 26.0571L49.6907
        26.1H49.743H57C59.7018 26.1 61.9 28.2982 61.9 31V38ZM42.901 43.019L42.901 43.0193C42.9122
        46.3744 45.6428 49.1 49 49.1C52.3642 49.1 55.1 46.3642 55.1 43C55.1 39.6358 52.3642 36.9 49
        36.9C45.6428 36.9 42.9122 39.6256 42.901 42.9807V42.981C42.901 42.9882 42.9017 42.9946
        42.9025 42.9998C42.9017 43.0053 42.901 43.0118 42.901 43.019ZM60
        34.1H60.1V34V32V31.9H60H57.1V30.1H59.816H59.9578L59.9102 29.9664C59.4827 28.7676 58.3464
        27.9 57 27.9H49.221C48.9268 27.9 48.6504 27.7561 48.48 27.5118L41.8331 17.9469L41.8331
        17.9469C40.5054 16.0391 38.3258 14.899 36.002 14.899H30.09C23.9503 14.899 18.1916 17.909
        14.6879 22.9519C14.6879 22.9519 14.6879 22.9519 14.6879 22.9519L11.4029 27.6789L11.4027
        27.6792C11.2661 27.877 11.0556 28.0111 10.8188 28.0525C8.24707 28.5002 6.0702 29.9413
        4.65437 31.9422L4.54274 32.1H4.736H6.9V33.9H3.661H3.59292L3.56796 33.9633C3.31424 34.6073
        3.13032 35.2844 3.02119 35.9846L3.00321 36.1H3.12H5.9V37.9H3H2.9V38V41C2.9 41.6062 3.39271
        42.1 4 42.1H7.069H7.15722L7.16822 42.0125C7.65704 38.1216 10.9792 35.1 15 35.1C19.0208 35.1
        22.343 38.1216 22.8318 42.0125L22.8428 42.1H22.931H41.069H41.1572L41.1682 42.0125C41.657
        38.1216 44.9792 35.1 49 35.1C53.0208 35.1 56.343 38.1216 56.8318 42.0125L56.8428
        42.1H56.931H59C59.6073 42.1 60.1 41.6062 60.1 41V36V35.9H60H57.1V34.1H60ZM8.9 43C8.9
        46.3642 11.6358 49.1 15 49.1C18.3642 49.1 21.1 46.3642 21.1 43C21.1 39.6358 18.3642 36.9
        15 36.9C11.6358 36.9 8.9 39.6358 8.9 43Z"
        fill={getColor(0)}
        stroke="#2F3034"
        strokeWidth="0.2"
      />
      <path
        d="M35 30H31V32H35V30Z"
        fill={getColor(0)}
      />
      <path
        d="M49 46C50.6569 46 52 44.6569 52 43C52 41.3431 50.6569 40 49 40C47.3431 40 46 41.3431
        46 43C46 44.6569 47.3431 46 49 46Z"
        fill={getColor(0)}
      />

    </svg>
  );
});
