// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom } from 'recoil';

import { fetchProjects } from '@/lib/ProjectListPoller';
import { Logger } from '@/lib/observability/logs';
import * as frontendpb from '@/proto/frontend/frontend_pb';

const logger = new Logger('recoil/state');

// List of projects shown in the home page.  Caches the last reply from
// ListProjects RPC.
export const projectListState = atom<frontendpb.ListProjectsReply | null>({
  key: 'projectList',
  default: null,
  effects: [
    ({ setSelf }) => {
      fetchProjects().then(setSelf, (err) => logger.warn('Error trying to list projects', err));
    },
  ],
});
