// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { visibilityToggleTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import useMeshMultiPart from '../../../recoil/useMeshingMultiPart';
import { useRefinementRegionVisibility } from '../../../recoil/useRefinementRegions';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { AddRefinementRegionButton } from '../SimulationNodeAddButtons';
import { TreeRow } from '../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';
import environmentState from '@/state/environment';

export const RefinementRegionContainerTreeRow = (props: SimulationRowProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const multiPartMeshing = useMeshMultiPart(projectId, workflowId, jobId);
  const [refinementRegionVis, setRefinementRegionVis] = useRefinementRegionVisibility({
    projectId, jobId, workflowId,
  });

  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const { viewState } = useParaviewContext();

  // these are the IDs on the current tab, since refinementRegionVis may include IDs from other mesh
  // IDs.
  const currentIds = useMemo(
    () => (multiPartMeshing?.refinementParams ?? []).map((region) => region.id),
    [multiPartMeshing],
  );

  const visCtrlDisabled = lcvisEnabled ? !lcvisReady : !viewState;
  const toggleAllVis = () => {
    setRefinementRegionVis((oldVis) => {
      const newVis = { ...oldVis };
      const hideAll = currentIds.some((id) => !!oldVis[id]);
      currentIds.forEach((id) => {
        newVis[id] = !hideAll;
      });
      return newVis;
    });
  };

  const isSimulation = jobId !== '';

  const allHidden = useMemo(() => currentIds.every((
    id,
  ) => {
    const isVisible = refinementRegionVis[id] ?? !isSimulation;

    return !isVisible;
  }), [currentIds, refinementRegionVis, isSimulation]);

  const getContextMenuItems = () => {
    if (currentIds.length === 0) {
      return [];
    }
    return [visibilityToggleTreeNodeMenuItem(!allHidden, toggleAllVis, visCtrlDisabled)];
  };

  const visibilityButton = currentIds.length === 0 ? undefined : (
    <VisibilityButton disabled={visCtrlDisabled} isVisible={!allHidden} onClick={toggleAllVis} />
  );

  return (
    <TreeRow
      {...props}
      addControl={<AddRefinementRegionButton />}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={getNodeTypeIcon(props.node.type)}
      visibilityButton={visibilityButton}
    />
  );
};
