// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as plotpb from '../proto/plots/plots_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { EMPTY_2D_ARRAY } from './constants';
import { traverseTreeNodes } from './paraviewUtils';

interface PlotCoordinates {
  positions: number[][],
  data: number[][],
}

export const MONITOR_PLOT_NODE_ID = 'monitor-plot';

const SortingState = {
  INCREASING: 0,
  DECREASING: 1,
  NEUTRAL: 2,
};

// Takes a single array of positions and separates into multiple array where all the positions
// coordinates are increasing. Also splits the coordinates into another array if NaN is encountered.
export function sortPositions(
  positions: number[],
  data: number[],
): PlotCoordinates {
  const sorted: PlotCoordinates = {
    positions: [],
    data: [],
  };
  let currentPos: number[] = [];
  let currentData: number[] = [];
  let prevPos = NaN;
  let prevDatum = NaN;
  let state = SortingState.NEUTRAL;

  const handleCoords = (pos: number, datum: number) => {
    if (Number.isNaN(pos)) {
      state = SortingState.NEUTRAL;
      if (currentPos.length > 0) {
        sorted.positions.push(currentPos);
        sorted.data.push(currentData);
        currentPos = [];
        currentData = [];
      }
    } else {
      switch (state) {
        case SortingState.NEUTRAL:
          if (!Number.isNaN(prevPos)) {
            if (pos >= prevPos) {
              state = SortingState.INCREASING;
              currentPos = [prevPos, pos];
              currentData = [prevDatum, datum];
            } else {
              state = SortingState.DECREASING;
              currentPos = [pos, prevPos];
              currentData = [datum, prevDatum];
            }
          }
          break;
        case SortingState.INCREASING:
          if (pos >= prevPos) {
            currentPos.push(pos);
            currentData.push(datum);
          } else {
            state = SortingState.DECREASING;
            sorted.positions.push(currentPos);
            sorted.data.push(currentData);
            currentPos = [pos, prevPos];
            currentData = [datum, prevDatum];
          }
          break;
        case SortingState.DECREASING:
          if (pos <= prevPos) {
            currentPos.unshift(pos);
            currentData.unshift(datum);
          } else {
            state = SortingState.INCREASING;
            sorted.positions.push(currentPos);
            sorted.data.push(currentData);
            currentPos = [prevPos, pos];
            currentData = [prevDatum, datum];
          }
          break;
        default:
          break;
      }
    }
    prevPos = pos;
    prevDatum = datum;
  };

  for (let i = 0; i < positions.length; i += 1) {
    handleCoords(positions[i], data[i]);
  }
  handleCoords(NaN, NaN);
  return sorted;
}

export function renamePlot(
  plotState: plotpb.Plots,
  plotId: string,
  name: string,
) {
  const newName = name.trim();

  if (!newName) {
    return;
  }

  const newPlot = plotState.plots.find(
    (plot) => (plot.id === plotId),
  );
  if (newPlot) {
    newPlot.name = name;
  }
}

// Get a particular plot coordinate component from the plot data.
export const getPlotCoordinatesComponent = (
  plotData: ParaviewRpc.PlotData | null,
  xCoordinate?: plotpb.XAxisOptions,
): number[][] => {
  const data = plotData?.data;
  if (data && 'quantity' in data) {
    switch (xCoordinate) {
      case plotpb.XAxisOptions.ARC_LENGTH:
        return data.arc;
      case plotpb.XAxisOptions.X_COORD:
        return data.xcoords;
      case plotpb.XAxisOptions.Y_COORD:
        return data.ycoords;
      case plotpb.XAxisOptions.Z_COORD:
        return data.zcoords;
      default:
        return EMPTY_2D_ARRAY;
    }
  }
  return EMPTY_2D_ARRAY;
};

export const defaultPlotRange = () => [-1e15, 1e15];

// We find all the elements set to be plotted and calculate the minimum and maximum
// values across all plots to return the full range of possible values.
export const getPositionRangeBounds = (
  viewState: ParaviewRpc.ViewState,
  xCoordinate: plotpb.XAxisOptions,
  ids: string[],
) => {
  const plotRanges: [number, number][] = [];
  const findPlotRanges = (node: ParaviewRpc.TreeNode) => {
    if (ids.includes(node.id)) {
      switch (xCoordinate) {
        case plotpb.XAxisOptions.ARC_LENGTH: {
          node.pointData.forEach((ptData) => {
            if (ptData.name === 'arc_length') {
              plotRanges.push(ptData.range[0]);
            }
          });
          break;
        }
        case plotpb.XAxisOptions.X_COORD:
          node.bounds && plotRanges.push([node.bounds[0], node.bounds[1]]);
          break;
        case plotpb.XAxisOptions.Y_COORD:
          node.bounds && plotRanges.push([node.bounds[2], node.bounds[3]]);
          break;
        case plotpb.XAxisOptions.Z_COORD:
          node.bounds && plotRanges.push([node.bounds[4], node.bounds[5]]);
          break;
        default:
      }
    }
  };
  traverseTreeNodes(viewState.root, findPlotRanges);

  let rangeMin = Infinity;
  let rangeMax = -Infinity;
  plotRanges.forEach((element) => {
    rangeMin = Math.min(rangeMin, element[0]);
    rangeMax = Math.max(rangeMax, element[1]);
  });

  return [rangeMin, rangeMax];
};

// Get minimum and maximum quantity values from the viewstate
export const getQuantityRangeBounds = (
  surfaceData: ParaviewRpc.ArrayInformation[],
  displayName: string | undefined,
): number[] => {
  for (let i = 0; i < surfaceData.length; i += 1) {
    if (surfaceData[i].name === displayName) {
      return [surfaceData[i].range[0][0], surfaceData[i].range[0][1]];
    }
  }

  return defaultPlotRange();
};
