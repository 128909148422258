// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getViewFromPath } from '../../../lib/componentTypes/context';
import { geometryIdLink, geometryLink, isInProjectResult, projectLink, resultsLink } from '../../../lib/navigation';
import { ProjectParams } from '../../../lib/routeParamTypes';
import { TabInfo } from '../../../proto/projectstate/projectstate_pb';
import { useRefreshMeshUrlState } from '../../../recoil/meshState';
import { useProjectMetadataValue } from '../../../recoil/useProjectMetadata';
import { useTabsState } from '../../../recoil/useTabsState';
import { useLastOpenedResultsTabValue } from '../../../state/external/project/lastOpenedResultsTab';
import { useAllWarnings } from '../../../state/external/project/validator';
import { useIsAdjointSetup, useIsExplorationSetup, useIsGeometryView } from '../../../state/internal/global/currentView';
import PaneSwitcher from '../../Pane/PaneSwitcher';
import { useNeedToImportGeometry } from '../../hooks/useNeedToImportGeometry';

const isSelected = (
  tab: TabInfo,
  pathname: string,
  projectId: string,
  geometryId: string,
  isExplorationSetup: boolean,
  isAdjointSetup: boolean,
) => {
  switch (tab.text) {
    case 'Geometry': {
      return pathname === geometryLink(projectId) ||
        pathname === geometryIdLink(projectId, geometryId);
    }
    case 'Setup':
    case 'Simulation': {
      return pathname === projectLink(projectId);
    }
    case 'Results': {
      return pathname === resultsLink(projectId) || isInProjectResult(pathname, projectId);
    }
    case 'Advanced Analysis':
      return isExplorationSetup;
    case 'Adjoint Analysis':
      return isAdjointSetup;
    default: {
      return false;
    }
  }
};

export interface ProjectTopLevelLinksSectionProps {
  projectId: string;
}

export const ProjectTopLevelLinksSection = (props: ProjectTopLevelLinksSectionProps) => {
  // Props
  const { projectId } = props;
  const { workflowId = '', jobId = '', geometryId = '' } = useParams<ProjectParams>();

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  // Recoil
  const [tabsState] = useTabsState(projectId);
  const lastOpenedResultsTab = useLastOpenedResultsTabValue(projectId);
  const allWarnings = useAllWarnings(projectId, workflowId, jobId);
  const needToImportGeometry = useNeedToImportGeometry(projectId);
  const projectMetadata = useProjectMetadataValue(projectId);
  const isExplorationSetup = useIsExplorationSetup();
  const isAdjointSetup = useIsAdjointSetup();
  const isGeometryView = useIsGeometryView();
  const refreshMeshUrlState = useRefreshMeshUrlState(projectId);

  const hasSimulations = (projectMetadata?.workflow.length ?? 0) > 0;

  const isViewComplete = useCallback((link: string, text: string) => {
    if (text === 'Geometry') {
      return !needToImportGeometry;
    }
    if (text === 'Results') {
      return hasSimulations;
    }
    // Setup and Advanced Analysis
    const view = getViewFromPath(link);
    const warnings = allWarnings.get(view);
    return !warnings || warnings.size === 0;
  }, [allWarnings, hasSimulations, needToImportGeometry]);

  // Memoized state
  const nonClosableTabs = useMemo(() => {
    const permanentTabs = tabsState.tabs.filter((tab) => !tab.closable);

    return permanentTabs.map((tab) => {
      const completed = isViewComplete(tab.link, tab.text);
      const error = (
        isGeometryView &&
        needToImportGeometry &&
        (tab.text === 'Setup' || tab.text === 'Results')
      ) ? `Continue to ${tab.text} after completing the geometry phase` : '';

      return {
        onClick: async () => {
          const fromGeometry = isGeometryView;
          const toOutsideGeometry = tab.link !== geometryLink(projectId);
          // If we are opening the Results page for a project, we should go back to the last results
          // tab we were in for that browser session. Otherwise, just fallback to the Results table.
          if (tab.link === resultsLink(projectId) && lastOpenedResultsTab) {
            navigate(lastOpenedResultsTab);
          } else if (tab.link === geometryLink(projectId)) {
            // There have been several bugs related to the entityGroupState not being refreshed when
            // going to the geometry tab. Flush everything by reloading.
            window.location.href = tab.link;
          } else {
            navigate(tab.link);
          }
          // We need to re-evaluate the meshUrlState when going from geometry to another tab,
          // so that we know what needs to be displayed. If we don't do this, the mesh URL can be
          // stale and empty.
          if (fromGeometry && toOutsideGeometry) {
            refreshMeshUrlState();
          }
        },
        selected: isSelected(
          tab,
          location.pathname,
          projectId,
          geometryId,
          isExplorationSetup,
          isAdjointSetup,
        ),
        text: tab.text,
        title: error,
        disabled: !!error,
        completedPhase: completed,
        key: tab.text,
      };
    });
  }, [
    tabsState.tabs,
    location.pathname,
    projectId,
    geometryId,
    lastOpenedResultsTab,
    navigate,
    isViewComplete,
    isAdjointSetup,
    isExplorationSetup,
    isGeometryView,
    refreshMeshUrlState,
    needToImportGeometry,
  ]);

  return (
    <div data-locator="project-top-tabs">
      <PaneSwitcher buttons={nonClosableTabs} />
    </div>
  );
};
