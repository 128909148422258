import * as flags from '../../../flags';
import { useLcVisAllowedValue, useLcVisEnabledState } from '../../../recoil/lcvis/lcvisEnabledState';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import { useProjectContext } from '../../context/ProjectContext';

import type { RibbonToolbarTool } from '@/components/RibbonToolbar/RibbonToolbarButton';

export const useLcVisButtonData = () => {
  const { projectId } = useProjectContext();
  const hasLcVisFlag = useIsEnabled(flags.lcvis);
  const [lcVisEnabled, setLcVisEnabled] = useLcVisEnabledState(projectId);
  const allowLCVisToggle = useLcVisAllowedValue(projectId);

  const getLcvisSwitcher = (newValue: boolean) => () => {
    if (!hasLcVisFlag) {
      console.error("this function shouldn't be callable");
      return;
    }

    setLcVisEnabled(newValue);
  };

  // Enable users with access to lcvis to switch between lcvis and Paraview
  // Don't even show the toggle if in simulation results view.
  if (hasLcVisFlag) {
    return {
      disabled: !allowLCVisToggle,
      icon: { name: lcVisEnabled ? 'clientSide' : 'serverSide' },
      key: 'lcvisToggle',
      locator: 'toolbar-lcvis',
      items: [
        {
          label: 'Client-Side Rendering',
          selected: lcVisEnabled,
          onClick: getLcvisSwitcher(true),
          earlyAccess: true,
          startIcon: { name: 'clientSide' },
        },
        {
          label: 'Server-Side Rendering',
          selected: !lcVisEnabled,
          onClick: getLcvisSwitcher(false),
          startIcon: { name: 'serverSide' },
        },
      ],
      label: lcVisEnabled ? 'Client Rendering' : 'Server Rendering',
    } satisfies RibbonToolbarTool;
  }

  return null;
};
