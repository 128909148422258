import { useCallback, useMemo } from 'react';

import assert from '../../../lib/assert';
import { getPhysicsDomains } from '../../../lib/entityRelationships';
import { findPhysicsByDomain, getPhysicsId, getPhysicsName } from '../../../lib/physicsUtils';
import { findPorousModelById } from '../../../lib/porousModelUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { findStaticVolumeById, getVolumeName, mapDomainsToIds, mapIdsToDomains } from '../../../lib/volumeUtils';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useSimulationConfig } from '../useSimulationConfig';

export const usePorousModelVolumes = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected porous model row');

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  // == Hooks
  const { simParam, saveParamAsync } = useSimulationConfig();

  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    const filterVolume = (volumeId: string) => {
      const volume = findStaticVolumeById(volumeId, staticVolumes);
      const volumeName = getVolumeName(volumeId, staticVolumes, entityGroupMap);

      if (!volume) {
        return {
          related: true,
          disabled: true,
          tooltip: 'Could not find the volume with given node id',
        };
      }

      // TODO: disable tag and volume selection if a tag has already selected these volumes
      const physics = findPhysicsByDomain(simParam, volume.domain, geometryTags);

      if (!physics) {
        return {
          related: true,
          disabled: true,
          tooltip: `Volume ${volumeName} does not yet belong to a physics`,
        };
      }

      const allowedDomains =
        getPhysicsDomains(simParam, getPhysicsId(physics), geometryTags, staticVolumes);
      const isAssignedToPhysics = allowedDomains.has(volume.domain);

      if (!isAssignedToPhysics) {
        const physicsName = getPhysicsName(physics, simParam);

        return {
          related: true,
          disabled: true,
          tooltip: `Volume ${volumeName} is not assigned to physics ${physicsName}`,
        };
      }

      return { related: true, disabled: false };
    };

    if (nodeType === NodeType.VOLUME) {
      return filterVolume(nodeId);
    }

    if (nodeType === NodeType.SURFACE_GROUP) {
      const domains = geometryTags.domainsFromTag(nodeId);
      const tagName = geometryTags.tagNameFromId(nodeId);

      if (domains.length === 0) {
        return {
          related: true,
          disabled: true,
          tooltip: `Tag ${tagName} does not contain any volumes`,
        };
      }

      const errorMessages = mapDomainsToIds(staticVolumes, domains)
        .map((volumeId) => filterVolume(volumeId).tooltip)
        .filter(Boolean);

      return {
        related: true,
        disabled: errorMessages.length > 0,
        tooltip: errorMessages.join(', '),
      };
    }

    return defaultNodeFilter(nodeType);
  }, [entityGroupMap, geometryTags, simParam, staticVolumes]);

  const setAssigedVolumeNodeIds = useCallback(async (volumeNodeIds: string[]) => {
    await saveParamAsync((param) => {
      const model = findPorousModelById(param, node.id)!;
      const domains = volumeNodeIds.flatMap((volumeId) => {
        const tag = geometryTags.domainsFromTag(volumeId);
        if (tag.length) {
          return [volumeId];
        }
        return mapIdsToDomains(staticVolumes, [volumeId]);
      });
      model.zoneIds = domains;
    });
  }, [geometryTags, node.id, saveParamAsync, staticVolumes]);

  const zoneIds = useMemo(() => {
    const simParamIds = findPorousModelById(simParam, node.id)?.zoneIds || [];
    return simParamIds.flatMap((zoneId) => {
      if (geometryTags.isTagId(zoneId)) {
        return zoneId;
      }
      return mapDomainsToIds(staticVolumes, [zoneId]);
    });
  }, [geometryTags, node.id, simParam, staticVolumes]);

  const volumeDomains = useMemo(() => zoneIds.flatMap((zoneId) => {
    const domains = geometryTags.domainsFromTag(zoneId);
    if (domains.length > 0) {
      return domains;
    }
    return mapIdsToDomains(staticVolumes, [zoneId]);
  }), [geometryTags, staticVolumes, zoneIds]);

  return { nodeFilter, setAssigedVolumeNodeIds, zoneIds, volumeDomains };
};
