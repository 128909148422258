// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const WireframeInvertedIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="wireframeInverted"
      fill="none"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M5.53832 1.44509C3.2757 2.29521 1.56993 4.28326 1.11822 6.71204C1.44162 6.98394 1.92943
        7.25609 2.57648 7.49873C3.08957 7.69115 3.68316 7.85772 4.33944 7.99044C4.3404 5.72629
        4.68325 3.64738 5.25898 2.10981C5.34563 1.8784 5.43872 1.6561 5.53832 1.44509Z"
        fill={getColor(0)}
      />
      <path
        d="M1.00391 8.23673C1.10021 11.1349 2.95823 13.5869 5.5413 14.556C5.09203 13.6033 4.75445
        12.3764 4.55373 11.0054C4.45764 10.3491 4.39603 9.85078 4.36345 9.34954C3.531 9.19656
        2.77026 8.99184 2.10948 8.74405C1.71007 8.59427 1.3378 8.42528 1.00391 8.23673Z"
        fill={getColor(0)}
      />
      <path
        d="M7.36984 14.972C7.57741 14.9905 7.78759 15 7.99998 15C11.7875 15 14.8724 11.9919 14.9961
        8.23451C14.9598 8.25503 14.9232 8.27517 14.8865 8.29493C13.8953 8.82835 12.5408 9.22531
        11.0055 9.4504C10.0004 9.59777 9.00897 9.66502 7.99998 9.66502C7.20989 9.66502 6.44228
        9.62333 5.71228 9.54458C5.7452 9.91712 5.79648 10.3126 5.8697 10.8127C6.08097 12.2559
        6.44389 13.4469 6.87898 14.2566C7.05084 14.5765 7.21677 14.8077 7.36984 14.972Z"
        fill={getColor(0)}
      />
      <path
        d="M14.8817 6.71165C14.2769 3.46105 11.4259 1 7.99998 1C7.78744 1 7.57711 1.00947 7.36939
        1.02802C7.35698 1.04136 7.34445 1.05517 7.33181 1.06945C7.04653 1.39167 6.75925 1.89593
        6.50453 2.57619C5.99694 3.93179 5.66944 5.84919 5.66944 8V8.01324C5.66816 8.07742 5.66702
        8.1399 5.6663 8.20092C6.39843 8.28762 7.18234 8.33502 7.99998 8.33502C8.94871 8.33502 9.8755
        8.27186 10.8126 8.13447C12.2556 7.92289 13.4466 7.55946 14.2562 7.12375C14.5215 6.98101
        14.7259 6.84233 14.8817 6.71165Z"
        fill={getColor(0)}
      />
    </svg>
  );
});
