import { useMemo } from 'react';

import { RIBBON_TOOLBAR_HEIGHT } from '../RibbonToolbar/RibbonToolbar';
import { DEFAULT_CLOSE_SIZE } from '../layout/footer/InfoFooter';
import { PROJECT_HEADER_HEIGHT } from '../layout/page/Main';

import { useAssistantSideRailWidth } from '@/state/internal/assistant/assistantSideRailSize';

/**
 * Returns the available screen bounds, excluding the header, assistant side rail, and bottom panel.
 */
export const useVisualizerBounds = (
  padding: Partial<{ top: number; left: number; bottom: number; right: number }>,
) => {
  const [sideRailWidth] = useAssistantSideRailWidth();

  return useMemo(() => ({
    top: RIBBON_TOOLBAR_HEIGHT + PROJECT_HEADER_HEIGHT + (padding?.top ?? 0),
    left: 0 + (padding?.left ?? 0),
    right: sideRailWidth + (padding?.left ?? 0),
    bottom: +DEFAULT_CLOSE_SIZE.replace('px', '') + (padding?.right ?? 0),
  }), [sideRailWidth, padding]);
};
