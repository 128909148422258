// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/api/v0/luminarycloud/geometry/geometry.proto (package luminary.proto.api.v0.luminarycloud.geometry, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { MeshFileMetadata } from "../../../../lcn/lcmesh_pb.js";
import { CadMetadata } from "../../../../cadmetadata/cadmetadata_pb.js";
import { Body, Face, Feature, FeatureIssues, Modification, Tags, Volume } from "../../../../geometry/geometry_pb.js";

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.Geometry
 */
export const Geometry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.Geometry",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "create_time", kind: "message", T: Timestamp },
    { no: 5, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "last_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "uses_tags", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.TessellationData
 */
export const TessellationData = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.TessellationData",
  () => [
    { no: 1, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "meta_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "lcn_meta", kind: "message", T: MeshFileMetadata },
    { no: 4, name: "cad_metadata", kind: "message", T: CadMetadata },
    { no: 5, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "feature_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.GetGeometryRequest
 */
export const GetGeometryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.GetGeometryRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.GetGeometryResponse
 */
export const GetGeometryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.GetGeometryResponse",
  () => [
    { no: 1, name: "geometry", kind: "message", T: Geometry },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ListGeometryFeaturesRequest
 */
export const ListGeometryFeaturesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ListGeometryFeaturesRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ListGeometryFeaturesResponse
 */
export const ListGeometryFeaturesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ListGeometryFeaturesResponse",
  () => [
    { no: 1, name: "features", kind: "message", T: Feature, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ListGeometryFeatureIssuesRequest
 */
export const ListGeometryFeatureIssuesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ListGeometryFeatureIssuesRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ListGeometryFeatureIssuesResponse
 */
export const ListGeometryFeatureIssuesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ListGeometryFeatureIssuesResponse",
  () => [
    { no: 1, name: "features_issues", kind: "message", T: FeatureIssues, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ListGeometryEntitiesRequest
 */
export const ListGeometryEntitiesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ListGeometryEntitiesRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ListGeometryEntitiesResponse
 */
export const ListGeometryEntitiesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ListGeometryEntitiesResponse",
  () => [
    { no: 1, name: "faces", kind: "message", T: Face, repeated: true },
    { no: 2, name: "bodies", kind: "message", T: Body, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ListGeometriesRequest
 */
export const ListGeometriesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ListGeometriesRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ListGeometriesResponse
 */
export const ListGeometriesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ListGeometriesResponse",
  () => [
    { no: 1, name: "geometries", kind: "message", T: Geometry, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryRequest
 */
export const SubscribeGeometryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.GeometryHistory
 */
export const GeometryHistory = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.GeometryHistory",
  () => [
    { no: 1, name: "history_entry", kind: "message", T: GeometryHistory_HistoryEntry },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.GeometryHistory.HistoryEntry
 */
export const GeometryHistory_HistoryEntry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.GeometryHistory.HistoryEntry",
  () => [
    { no: 1, name: "modification", kind: "message", T: Modification },
    { no: 2, name: "geometry_version_initial_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "geometry_version_new_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "checkpoint_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GeometryHistory_HistoryEntry"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse
 */
export const SubscribeGeometryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "checkpoint", kind: "message", T: SubscribeGeometryResponse_GeometryCheckpoint, oneof: "ResponseType" },
    { no: 6, name: "busy", kind: "message", T: SubscribeGeometryResponse_BusyState, oneof: "ResponseType" },
    { no: 7, name: "worker_disconnected", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "ResponseType" },
    { no: 8, name: "received_error", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "ResponseType" },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.GeometryCheckpoint
 */
export const SubscribeGeometryResponse_GeometryCheckpoint = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.GeometryCheckpoint",
  () => [
    { no: 2, name: "mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "features", kind: "message", T: Feature, repeated: true },
    { no: 8, name: "features_issues", kind: "message", T: FeatureIssues, repeated: true },
    { no: 4, name: "geometry_history", kind: "message", T: GeometryHistory, repeated: true },
    { no: 5, name: "tessellation_data", kind: "message", T: TessellationData },
    { no: 6, name: "n_avail_undos", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "n_avail_redos", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "tags", kind: "message", T: Tags },
  ],
  {localName: "SubscribeGeometryResponse_GeometryCheckpoint"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState
 */
export const SubscribeGeometryResponse_BusyState = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState",
  () => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "feature_progress", kind: "message", T: SubscribeGeometryResponse_BusyState_FeatureProgress, oneof: "BusyStateType" },
    { no: 6, name: "feature_tessellation", kind: "message", T: SubscribeGeometryResponse_BusyState_FeatureTessellation, oneof: "BusyStateType" },
    { no: 7, name: "undo_redo", kind: "message", T: SubscribeGeometryResponse_BusyState_UndoRedo, oneof: "BusyStateType" },
    { no: 8, name: "reloading", kind: "message", T: SubscribeGeometryResponse_BusyState_Reloading, oneof: "BusyStateType" },
    { no: 9, name: "delete_feature", kind: "message", T: SubscribeGeometryResponse_BusyState_DeleteFeature, oneof: "BusyStateType" },
    { no: 10, name: "rename_feature", kind: "message", T: SubscribeGeometryResponse_BusyState_RenameFeature, oneof: "BusyStateType" },
    { no: 11, name: "tag_operation", kind: "message", T: SubscribeGeometryResponse_BusyState_TagOperation, oneof: "BusyStateType" },
  ],
  {localName: "SubscribeGeometryResponse_BusyState"},
);

/**
 * Indicates that the server is creating or updating a feature.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.FeatureProgress
 */
export const SubscribeGeometryResponse_BusyState_FeatureProgress = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.FeatureProgress",
  () => [
    { no: 2, name: "feature_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "SubscribeGeometryResponse_BusyState_FeatureProgress"},
);

/**
 * Indicates that the server is computing a tessellation of the geometry.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.FeatureTessellation
 */
export const SubscribeGeometryResponse_BusyState_FeatureTessellation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.FeatureTessellation",
  () => [
    { no: 3, name: "feature_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "SubscribeGeometryResponse_BusyState_FeatureTessellation"},
);

/**
 * Indicates that the server is undoing or redoing an operation.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.UndoRedo
 */
export const SubscribeGeometryResponse_BusyState_UndoRedo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.UndoRedo",
  [],
  {localName: "SubscribeGeometryResponse_BusyState_UndoRedo"},
);

/**
 * Indicates that the server is reloading the geometry.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.Reloading
 */
export const SubscribeGeometryResponse_BusyState_Reloading = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.Reloading",
  [],
  {localName: "SubscribeGeometryResponse_BusyState_Reloading"},
);

/**
 * Indicates that the server is deleting a feature.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.DeleteFeature
 */
export const SubscribeGeometryResponse_BusyState_DeleteFeature = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.DeleteFeature",
  () => [
    { no: 4, name: "feature_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "SubscribeGeometryResponse_BusyState_DeleteFeature"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.RenameFeature
 */
export const SubscribeGeometryResponse_BusyState_RenameFeature = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.RenameFeature",
  () => [
    { no: 1, name: "feature_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "SubscribeGeometryResponse_BusyState_RenameFeature"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.TagOperation
 */
export const SubscribeGeometryResponse_BusyState_TagOperation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.SubscribeGeometryResponse.BusyState.TagOperation",
  [],
  {localName: "SubscribeGeometryResponse_BusyState_TagOperation"},
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ModifyGeometryRequest
 */
export const ModifyGeometryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ModifyGeometryRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "modification", kind: "message", T: Modification },
    { no: 3, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.ModifyGeometryResponse
 */
export const ModifyGeometryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.ModifyGeometryResponse",
  () => [
    { no: 1, name: "features", kind: "message", T: Feature, repeated: true },
    { no: 2, name: "volumes", kind: "message", T: Volume, repeated: true },
    { no: 3, name: "features_issues", kind: "message", T: FeatureIssues, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.CreateGeometryRequest
 */
export const CreateGeometryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.CreateGeometryRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "wait", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "uses_tags", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.CreateGeometryResponse
 */
export const CreateGeometryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.CreateGeometryResponse",
  () => [
    { no: 1, name: "geometry", kind: "message", T: Geometry },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.CheckGeometryRequest
 */
export const CheckGeometryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.CheckGeometryRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.CheckGeometryResponse
 */
export const CheckGeometryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.CheckGeometryResponse",
  () => [
    { no: 1, name: "ok", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "issues", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.TessellationUpToModificationRequest
 */
export const TessellationUpToModificationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.TessellationUpToModificationRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "modification_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.TessellationUpToModificationResponse
 */
export const TessellationUpToModificationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.TessellationUpToModificationResponse",
  () => [
    { no: 1, name: "tessel_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.LatestTessellationRequest
 */
export const LatestTessellationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.LatestTessellationRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.LatestTessellationResponse
 */
export const LatestTessellationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.LatestTessellationResponse",
  () => [
    { no: 1, name: "tesselation_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.KeepAliveRequest
 */
export const KeepAliveRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.KeepAliveRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.KeepAliveResponse
 */
export const KeepAliveResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.KeepAliveResponse",
  [],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.PanicRequest
 */
export const PanicRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.PanicRequest",
  [],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.PanicResponse
 */
export const PanicResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.PanicResponse",
  [],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.StopWorkerRequest
 */
export const StopWorkerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.StopWorkerRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.StopWorkerResponse
 */
export const StopWorkerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.StopWorkerResponse",
  [],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.GetTagsRequest
 */
export const GetTagsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.GetTagsRequest",
  () => [
    { no: 1, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.geometry.GetTagsResponse
 */
export const GetTagsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.geometry.GetTagsResponse",
  () => [
    { no: 1, name: "tags", kind: "message", T: Tags },
  ],
);

