// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useMemo } from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { FixedSizeWrapper } from '../layout/FixedSizeWrapper';
import { DiskCheckIcon } from '../svg/DiskCheckIcon';
import { DottedCircleIcon } from '../svg/DottedCircleIcon';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      gap: '16px',

      '--active-border-size': '4px',
      '--font-size': '14px',
      '--font-weight': 400,
      '--line-height': '20px',
      '--padding': '14px 8px 10px',
      '--color': colors.lowEmphasisText,

      '&.footerSwitcher': {
        '--active-border-size': '3px',
        '--font-size': '13px',
        '--font-weight': 600,
        '--line-height': '16px',
        '--padding': '6px 0 8px 0',
        '--color': colors.neutral600,

      },

      '&.compact': {
        '--active-border-size': '2px',
        '--font-size': '13px',
        '--font-weight': 600,
        '--line-height': '16px',
        '--padding': '7px 0',
      },

      '&.withBottomBorder': {
        borderBottom: `1px solid ${colors.neutral50}`,
      },
    },
    span: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      borderBottom: 'var(--active-border-size) solid transparent',

      '&.selected': {
        borderBottomColor: colors.primaryCta,
        '--color': 'white',
      },
      '&.footerSwitcher': {
        '--color': colors.highEmphasisText,
        gap: '8px',
        '&:not(:disabled):hover': {
          '--color': colors.neutral800,
        },
      },
    },
    icon: {
      paddingTop: '4px',
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'var(--color)',
      fontSize: 'var(--font-size)',
      fontWeight: 'var(--font-weight)',
      lineHeight: 'var(--line-height)',
      padding: 'var(--padding)',
      margin: 0,
      outline: 0,
      border: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',

      '&.disabled': {
        color: colors.disabledType,
      },
    },
  }),
  { name: 'PaneSwitcher' },
);

export interface PaneButtonProps {
  // Whether the button reflects the current mode
  selected: boolean;
  // Button click handler
  onClick: () => void;
  // The text of the button.
  text: ReactNode;
  // The title that appears in the tooltip.
  title?: string;
  // Disables the button.
  disabled?: boolean;
  // Uses a smaller font size and padding
  compact?: boolean;
  // Uses footerSwitcher styles
  footerSwitcher?: boolean;
  // if defined, will show an empty circle if false, and a check if true
  completedPhase?: boolean;
  startIcon?: ReactNode;
  // `key` is used below, but eslint struggles to recognize it
  key: string; // eslint-disable-line react/no-unused-prop-types
  // The icon of the button.
  icon?: ReactNode;
}

// Draw a single button for switching to a particular mode.
const PaneButton = (props: PaneButtonProps) => {
  const {
    disabled,
    selected,
    compact,
    footerSwitcher,
    onClick,
    text,
    title = '',
    completedPhase,
    icon,
  } = props;
  const classes = useStyles();

  const phaseIcon = useMemo(() => (
    (completedPhase !== undefined || props.startIcon) && (
      <>
        {completedPhase ?
          <DiskCheckIcon color={colors.green500} /> :
          <DottedCircleIcon />}
        {props.startIcon}
      </>
    )
  ), [completedPhase, props.startIcon]);

  return (
    <div>
      <Tooltip title={title}>
        <span className={cx(classes.span, { selected, footerSwitcher })}>
          {phaseIcon && (
            <div className={classes.icon}>
              <FixedSizeWrapper height={12} width={12}>
                {phaseIcon}
              </FixedSizeWrapper>
            </div>
          )}
          <button
            className={cx(classes.button, { disabled, selected, compact })}
            data-pane-button={text}
            disabled={disabled}
            onClick={onClick}
            title={title}
            type="button">
            {text}
          </button>
          {icon}
        </span>
      </Tooltip>
    </div>
  );
};

interface PaneSwitcherProps {
  buttons: PaneButtonProps[];
  compact?: boolean;
  withBottomBorder?: boolean;
  footerSwitcher?: boolean;
}

// Draw several buttons for switching between modes in the right pane.
const PaneSwitcher = (props: PaneSwitcherProps) => {
  const { buttons, compact, withBottomBorder, footerSwitcher } = props;

  const classes = useStyles();

  return (
    <div className={cx(classes.root, { compact, withBottomBorder, footerSwitcher })}>
      {buttons.map(({ key, ...buttonProps }) => (
        <PaneButton {...buttonProps} footerSwitcher={footerSwitcher} key={key} />
      ))}
    </div>
  );
};

export default PaneSwitcher;
