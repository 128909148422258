// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import cx from 'classnames';

import assert from '../../lib/assert';
import { colors } from '../../lib/designSystem';
import { isUnmodifiedEnterKey } from '../../lib/event';
import { toSimpleDateTime, toSimpleTime } from '../../lib/formatDate';
import {
  JobStatusIconType,
  getJobCredits,
  getJobGpu,
  getJobRuntime,
  getJobStatus,
  getJobStatusIconProps,
  hasBatchMessage,
  isJobInActiveState,
  isJobInStoppedState,
} from '../../lib/jobUtils';
import { formatNumber, fromBigInt } from '../../lib/number';
import { isSimulationTransient } from '../../lib/simulationUtils';
import { detailedStatus } from '../../lib/stoppingCondsUtils';
import { useStoppingConditionStatus } from '../../model/hooks/useStoppingConditionStatus';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { SvgIcon } from '../Icon/SvgIcon';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { Flex } from '../visual/Flex';
import { ProgressBar } from '../visual/ProgressBar';

import { JobDataCategory, newJobDataCategory } from './JobDataCategory';

interface JobStatusProps {
  projectId: string,
  workflowId: string,
  job: frontendpb.GetWorkflowReply_Job;
  inRunStatusPanel: boolean;
}

const useStyles = makeStyles(
  () => createStyles({
    tooltip: {
      display: 'inline-block',
    },
    display: {
      display: 'flex',
      justifyContent: 'inherit',
      alignItems: 'center',
      overflow: 'hidden',
      gap: '8px',
      verticalAlign: 'bottom',
      '&.copyable': {
        cursor: 'copy',
      },
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '15px',
      '&.centered': {
        justifyContent: 'center',
      },
    },
    status: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&.pending': {
        color: colors.neutral700,
      },
    },
    info: {
      textAlign: 'center',
      color: colors.lowEmphasisText,
      margin: '4px 0',
    },
  }),
  { name: 'StatusIconMessage' },
);

interface StatusIconMessageProps {
  // Message displayed next to the dot
  message: string;
  // Message displayed on the tooltip when the status is hovered
  tooltipMsg: string;
  // Icon type to show before the text status. If omitted (for running jobs), no icon will be shown
  iconType?: JobStatusIconType;
  inRunStatusPanel: boolean;
}

const StatusIconMessage = (props: StatusIconMessageProps) => {
  const { message, tooltipMsg, iconType, inRunStatusPanel } = props;
  const [copied, setCopied] = useState<boolean>(false);

  const classes = useStyles();

  const copy = async () => {
    if (!tooltipMsg) {
      return;
    }

    setCopied(true);
    await navigator.clipboard.writeText(tooltipMsg);
    setTimeout(() => setCopied(false), 1000); // Reset the tooltip after 1 seconds.
  };

  return (
    <Tooltip
      className={cx(!inRunStatusPanel && classes.tooltip)}
      title={copied ? 'Copied to Clipboard' : tooltipMsg}>
      <div>
        <div
          className={cx(classes.display, tooltipMsg && 'copyable')}
          onClick={async () => {
            await copy();
          }}
          onKeyDown={async (event) => {
            if (isUnmodifiedEnterKey(event)) {
              await copy();
            }
          }}
          role="button"
          tabIndex={0}>
          <div className={cx(classes.iconWrapper, inRunStatusPanel && 'centered')}>
            <SvgIcon
              maxHeight={12}
              maxWidth={12}
              {...(iconType ?
                getJobStatusIconProps(iconType) :
                { color: 'var(--color-neutral-500', name: 'circle' })}
            />
          </div>
          <div
            className={cx(classes.status, !iconType && !inRunStatusPanel && 'pending')}
            id="job-status">
            {message}{!iconType && '...'}
          </div>
        </div>
        {inRunStatusPanel && iconType === 'warning' && (
          <div className={classes.info}>
            Unmet stopping condition
          </div>
        )}
      </div>
    </Tooltip>
  );
};

// JobStatus generates a text for the "Status" column.
// For an active job in the Run Status panel, it also shows an indeterminate progressbar.
// For finished jobs in the Run Status panel, it will show a small icon.
// For jobs in the JobTable (Results page), there will only the a short text + colored dot.
export const JobStatus = (props: JobStatusProps) => {
  const { projectId, workflowId, job, inRunStatusPanel } = props;
  const { state, message } = getJobStatus(job);
  let tooltipMessage = '';
  const stopStatus = useStoppingConditionStatus(projectId, workflowId, job.jobId);

  if (state === 'failed') {
    tooltipMessage = message;

    return (
      <StatusIconMessage
        iconType="error"
        inRunStatusPanel={inRunStatusPanel}
        message="Failed"
        tooltipMsg={tooltipMessage}
      />
    );
  }

  if (state === 'suspended') {
    return (
      <StatusIconMessage
        iconType="info"
        inRunStatusPanel={inRunStatusPanel}
        message={message}
        tooltipMsg={tooltipMessage}
      />
    );
  }

  if (stopStatus && isJobInStoppedState(job)) {
    tooltipMessage = detailedStatus(stopStatus);
    // If the job is completed and some of the stopping conds are not satisfied
    // we show a more detailed tooltip and semi-success icon
    if (tooltipMessage.length) {
      return (
        <StatusIconMessage
          iconType="warning"
          inRunStatusPanel={inRunStatusPanel}
          message={message}
          tooltipMsg={tooltipMessage}
        />
      );
    }
    // For everything else in stopped state, we show a success icon
    return (
      <StatusIconMessage
        iconType="success"
        inRunStatusPanel={inRunStatusPanel}
        message={message}
        tooltipMsg={tooltipMessage}
      />
    );
  }

  if (hasBatchMessage(message)) {
    // add 3 hours to the creation time to give indication when the batch job should start
    const batchStartTime = toSimpleTime(job.creationTime + 10800);
    tooltipMessage = `Queued for batch processing. Simulation will start by ${batchStartTime}.`;
  }

  // If we don't have stop cond status yet, i.e. solver has not started (or there's other pending
  // state), we show a pending status + progress, without any icons.
  return (
    <Flex flexDirection="column" gap={4}>
      <StatusIconMessage
        inRunStatusPanel={inRunStatusPanel}
        message={message}
        tooltipMsg={tooltipMessage}
      />
      {inRunStatusPanel && !isJobInStoppedState(job) && (
        <ProgressBar
          backgroundColor={colors.surfaceLight2}
          height={4}
          progress={null}
        />
      )}
    </Flex>
  );
};

export function getStopAndHoverText(job: frontendpb.GetWorkflowReply_Job):
  [string, string | undefined] {
  const { message } = getJobStatus(job);
  // Note: These strings we look for are emmitted from either FVM (fvm.cc) or the go backend.
  if (message === 'Running Solver' || message === 'Finished') {
    return ['Stop', 'A solution will be written prior to stopping.'];
  }
  return ['Cancel', undefined];
}

// Returns a set of data categories related to the status of the jobs. The
// "isExplorationList" is true if this is a just a list of the explorations.
export function getJobStatusData(
  projectId: string,
  workflowIds: string[],
  jobs: (frontendpb.GetWorkflowReply_Job | null)[],
  params: (simulationpb.SimulationParam | null)[],
  isExplorationList: boolean,
  isStaff: boolean,
  // true if we are displaying data in the Run Status panel
  inRunStatusPanel: boolean,
) {
  assert(jobs.length === workflowIds.length, 'Length mismatch: jobs/workflowId');
  assert(jobs.length === params.length, 'Length mismatch: jobs/params');

  const jobStatusData: JobDataCategory[] = [];
  const rowIds = jobs.map((job, index) => job?.jobId ?? workflowIds[index]);
  const { state } = getJobStatus(jobs[0]);

  // If all params are transient, change 'Iter' label to 'Timesteps' instead.
  const allTransient = params.every((param) => param && isSimulationTransient(param));

  if (jobs && (workflowIds.length !== jobs.length)) {
    throw Error(`workflowIds and jobs array must be same length:
    ${workflowIds.length} != ${jobs.length}`);
  }

  // In the RunStatus window, the status is shown first. In the JobTable it is near the end
  const statusData: JobDataCategory = {
    leftAlign: true,
    nameLines: ['Status'],
    subcategories: [],
    values: jobs.map((job, index) => (
      job ? (
        <JobStatus
          inRunStatusPanel={inRunStatusPanel}
          job={job}
          key={job.jobId}
          projectId={projectId}
          workflowId={workflowIds[index]}
        />
      ) : <React.Fragment key={workflowIds[index]} />)),
  };

  if (inRunStatusPanel) {
    jobStatusData.push({
      ...statusData,
      colspan: 2,
      showName: false,
    });
  }

  if (!(inRunStatusPanel && isJobInActiveState(jobs[0]))) {
    jobStatusData.push(
      newJobDataCategory(
        ['Date'],
        jobs.map((job) => (job ? toSimpleDateTime(job.updateTime) : '')),
        rowIds,
        inRunStatusPanel, // allow the date value to wrap on multiple lines for the Run Status panel
      ),
    );
  }

  // If we are in the run status panel, in-progress job run time should be given.
  // It's okay that it is just an estimate.
  if (inRunStatusPanel && isJobInActiveState(jobs[0])) {
    // But we should only show it if we are in the "Running" substate, and not show anything
    // for the other ACTIVE substates (like Queued for Batch, Allocating, Loading the Job, etc.)
    if (state === 'running') {
      jobStatusData.push(newJobDataCategory(
        ['Run Time (s)'],
        [jobs[0] ? formatNumber(fromBigInt(jobs[0].runTime), { numDecimals: 0 }) : ''],
        rowIds,
      ));
    }
  } else {
    jobStatusData.push(newJobDataCategory(['Run Time (s)'], jobs.map(getJobRuntime), rowIds));
  }

  if (!inRunStatusPanel || isJobInStoppedState(jobs[0]) || state === 'running') {
    jobStatusData.push(
      newJobDataCategory(
        [allTransient ? 'Timesteps' : 'Iter'],
        jobs.map((job) => (job?.latestIter ? `${job?.latestIter}` : '')),
        rowIds,
      ),
    );
    if (allTransient) {
      jobStatusData.push(
        newJobDataCategory(
          ['Simulation Time (s)'],
          jobs.map((job) => (job?.latestTime ? job?.latestTime.toPrecision(4) : '')),
          rowIds,
        ),
      );
    }
  }

  if (!inRunStatusPanel || isJobInStoppedState(jobs[0])) {
    jobStatusData.push(
      newJobDataCategory(['Credits'], jobs.map(getJobCredits), rowIds),
    );
  }

  if (isStaff && !isExplorationList) {
    if (inRunStatusPanel) {
      jobStatusData.push({
        colspan: 2,
        nameLines: ['Separator'],
        showName: false,
        subcategories: [],
        values: [
          <div
            key="sep"
            style={{ borderBottom: `1px solid ${colors.neutral300}`, margin: '6px 0' }}
          />,
        ],
      });
    }
    jobStatusData.push(
      newJobDataCategory(['JobId'], jobs.map((job) => (job ? job.jobId : '')), rowIds),
    );
    jobStatusData.push(
      newJobDataCategory(
        ['Sol'],
        jobs.map((job) => job?.lastSolution?.url ?? undefined),
        rowIds,
      ),
    );
    jobStatusData.push(
      newJobDataCategory(
        ['Mesh'],
        jobs.map((job, index) => (job && params[index]?.input?.url) || ''),
        rowIds,
      ),
    );
  }

  if (!inRunStatusPanel) {
    jobStatusData.push(statusData);
  }

  if (isStaff) {
    // Reports whether the job was restarted. Useful to debug simulations with hardware failures
    // or which underwent retries.
    const anyJobRestarted = jobs.some((job) => job && job.jobIncarnation > 0);
    if (anyJobRestarted) {
      jobStatusData.push(
        newJobDataCategory(
          ['Restarted'],
          jobs.map((job) => ((job && job.jobIncarnation > 0) ? 'Yes' : 'No')),
          rowIds,
        ),
      );
    }
  }

  if (isStaff && !isExplorationList) {
    // Show the GPU type and its count.
    jobStatusData.push(
      newJobDataCategory(['Config'], jobs.map(getJobGpu), rowIds),
    );
  }

  if (!inRunStatusPanel) {
    // prevent the date and other lines from wrapping in the job table
    return jobStatusData.map((jobStatus) => ({ ...jobStatus, noWrap: true }));
  }

  return jobStatusData;
}
