// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';

import { useProjectContext } from '@/components/context/ProjectContext';
import { useColorBy } from '@/recoil/geometry/geometryColorByState';
import { useGeometryTags } from '@/recoil/geometry/geometryTagsState';
import { useStaticVolumes } from '@/recoil/volumes';
import environmentState from '@/state/environment';

export const useLcvisTagSynchronizer = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const [colorBy] = useColorBy(projectId);

  const lcvisReady = environmentState.use.lcvisReady;

  useEffect(() => {
    if (lcvHandler.display?.workspace) {
      lcvHandler.display.workspace.tags = geometryTags;
      lcvHandler.display.workspace.staticVolumes = staticVolumes;
      lcvHandler.display.workspace.colorBy = colorBy;

      lcvHandler.display.workspace.updateColorMapBasedOnTag();
    }
  }, [lcvisReady, geometryTags, staticVolumes, colorBy]);
};
