// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState, waitForAll } from 'recoil';

import { CurrentView } from '../lib/componentTypes/context';
import { pendingWorkOrdersFixture } from '../lib/fixtures';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { currentViewState } from '../state/internal/global/currentView';

import { geometryLastVersionIdAtom } from './geometry/geometryState';
import { getCheckGeometry } from './getCheckGeometry';
import { routeParamsState } from './useRouteParams';

const pendingWorkOrdersKey = 'pendingWorkOrders';

const DEFAULT_ORDERS = new frontendpb.PendingWorkOrders();

function serialize(val: frontendpb.PendingWorkOrders): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): frontendpb.PendingWorkOrders {
  return (val.length ?
    frontendpb.PendingWorkOrders.fromBinary(val) :
    DEFAULT_ORDERS);
}

export const pendingWorkOrdersBase = selectorFamily<frontendpb.PendingWorkOrders, string>({
  key: 'pendingWorkOrdersSelectorBase',
  get: (projectId: string) => ({ get }) => {
    const currentView = get(currentViewState);
    if (currentView === CurrentView.GEOMETRY) {
      const { geometryId } = get(routeParamsState);
      if (!geometryId) {
        return DEFAULT_ORDERS;
      }
      // If there's a CheckGeometry on going for the current geometry version ID, we add it to
      // the pending work orders so that we can track its progress when refreshing webpages.
      const [getCheck, geometryVersionId] = get(waitForAll([
        getCheckGeometry(projectId),
        geometryLastVersionIdAtom({ projectId, geometryId }),
      ]));
      // Nothing to add as pending, it's already finished.
      if (!getCheck || getCheck.finished) {
        return DEFAULT_ORDERS;
      }
      return new frontendpb.PendingWorkOrders({
        workOrders: {
          [frontendpb.WorkOrderType.CHECK_GEOMETRY]: new frontendpb.PendingWorkOrder({
            typ: {
              case: 'checkGeometry',
              value: new frontendpb.CheckGeometryRequest({
                projectId,
                geometryId,
                geometryVersionId,
              }),
            },
          }),
        },
      });
    }
    return persist.getProjectState(projectId, [pendingWorkOrdersKey], deserialize);
  },
  dangerouslyAllowMutability: true,
});

const pendingWorkOrdersSelectorTesting = selectorFamily<frontendpb.PendingWorkOrders, string>({
  key: `${pendingWorkOrdersKey}/testing`,
  get: () => pendingWorkOrdersFixture,
  dangerouslyAllowMutability: true,
});

const pendingWorkOrdersSelector = isTestingEnv() ?
  pendingWorkOrdersSelectorTesting : pendingWorkOrdersBase;

export const pendingWorkOrdersState = atomFamily<frontendpb.PendingWorkOrders, string>({
  key: pendingWorkOrdersKey,
  default: pendingWorkOrdersSelector,
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, pendingWorkOrdersKey, deserialize, serialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const geometryCheckState = selectorFamily<boolean, string>({
  key: 'geometryCheckState',
  get: (projectId: string) => ({ get }) => {
    if (!projectId) {
      return false;
    }
    const pendingWorkOrders = get(pendingWorkOrdersState(projectId));
    return !!pendingWorkOrders.workOrders[frontendpb.WorkOrderType.CHECK_GEOMETRY];
  },
});

export const geometryPendingState = selectorFamily<boolean, string>({
  key: 'geometryPendingState',
  get: (projectId: string) => ({ get }) => {
    if (!projectId) {
      return false;
    }
    const pendingWorkOrders = get(pendingWorkOrdersState(projectId));
    return !!pendingWorkOrders.workOrders[frontendpb.WorkOrderType.GET_GEOMETRY];
  },
});

export const meshPendingState = selectorFamily<boolean, string>({
  key: 'meshPendingState',
  get: (projectId: string) => ({ get }) => {
    if (!projectId) {
      return false;
    }
    const pendingWorkOrders = get(pendingWorkOrdersState(projectId));
    return !!pendingWorkOrders.workOrders[frontendpb.WorkOrderType.GET_MESH];
  },
});

export const usePendingWorkOrders = (projectId: string) => (
  useRecoilState(pendingWorkOrdersState(projectId))
);

export const useSetPendingWorkOrders = (projectId: string) => (
  useSetRecoilState(pendingWorkOrdersState(projectId))
);

export const useIsGeometryCheck = (projectId: string) => (
  useRecoilValue(geometryCheckState(projectId))
);

export const useIsGeometryPending = (projectId: string) => (
  useRecoilValue(geometryPendingState(projectId))
);

export const useIsMeshPending = (projectId: string) => (
  useRecoilValue(meshPendingState(projectId))
);
