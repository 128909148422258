// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue } from 'recoil';

import * as rpc from '../../lib/rpc';
import * as frontendpb from '../../proto/frontend/frontend_pb';

type RecoilKey = {
  projectId: string,
}

// List of geometries in a given project.
export const geometryVersionsLoadedState = selectorFamily<
  frontendpb.GetGeometryVersionsLoadedReply | null,
  RecoilKey
>({
  key: 'geometryDetails',
  get: (key: RecoilKey) => async () => {
    const { projectId } = key;
    if (!projectId) {
      return null;
    }
    try {
      const response = await rpc.callRetry(
        'getGeometryVersionsLoaded',
        rpc.client.getGeometryVersionsLoaded,
        new frontendpb.GetGeometryVersionsLoadedRequest({ projectId }),
      );
      return response;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
});

export function useGeometryVersionsLoaded(projectId: string) {
  return useRecoilValue(geometryVersionsLoadedState({ projectId }));
}
