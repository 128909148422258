// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const ShrinkwrapIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="shrinkwrap"
      fill="none"
      height={finalHeight}
      ref={ref}
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.665 3.165
           C9.665 2.97999 9.81499 2.83 10 2.83
           H14.495
           V1.5
           H10
           C9.08045 1.5 8.335 2.24545 8.335 3.165
           L8.335 8
           C8.335 8.18502 8.18502 8.335 8 8.335
           H3.165
           C2.24545 8.335 1.5 9.08044 1.5 10
           V14.495
           H2.83
           V10
           C2.83 9.81499 2.97999 9.665 3.165 9.665
           H8
           C8.91956 9.665 9.665 8.91955 9.665 8
           L9.665 3.165Z"
        fill={getColor(0)}
      />
      <path
        d="M14.5 14.5
           H4.49499
           V11.33
           H7.99999
           C9.8391 11.33 11.33 9.8391 11.33 7.99999
           L11.33 4.49499
           H14.5
           V14.5Z"
        fill={getColor(0)}
      />
    </svg>
  );
});
