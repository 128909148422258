// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, noWait, selectorFamily, useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';

import { Logger } from '../lib/observability/logs';
import * as rpc from '../lib/rpc';
import { isTestingEnv } from '../lib/testing/utils';
import * as frontendpb from '../proto/frontend/frontend_pb';

import { selectedGeometryState } from './selectedGeometry';

const logger = new Logger('recoil/meshState');

export async function getAllMeshes(projectId: string): Promise<frontendpb.Mesh[]> {
  if (!projectId) {
    return [];
  }
  const req = new frontendpb.ListMeshesRequest({ projectId });
  try {
    const reply = await rpc.callRetry('ListMeshes', rpc.client.listMeshes, req);
    return reply.meshes;
  } catch (err) {
    logger.error('Unable to retrieve mesh list for projectId=%s: %s', projectId, err);
    return [];
  }
}

const getAllMeshesRpcCache = atomFamily<frontendpb.Mesh[], string>({
  key: 'getAllMeshesRpcCache',
  default: (projectId: string) => getAllMeshes(projectId),
});

function filterMeshList(meshList: frontendpb.Mesh[], geometryVersionId: string) {
  const meshListFilter1 = meshList.filter(includeInMeshList);
  if (!geometryVersionId) {
    // In this case we are working with a project that has an external mesh or a project created
    // before igeo was introduced. In this case we return all the meshes since we don't have a
    // geometry version to filter.
    return meshListFilter1;
  }
  return meshListFilter1.filter((mesh) => (
    mesh.geometryVersionId === geometryVersionId
  ));
}

// returns true if the given mesh should be included in the mesh list
function includeInMeshList(mesh: frontendpb.Mesh) {
  return (
    mesh.status === frontendpb.Mesh_MeshStatus.COMPLETED &&
    (mesh.meshOrigin === frontendpb.Mesh_MeshOrigin.USER_GENERATED ||
      mesh.meshOrigin === frontendpb.Mesh_MeshOrigin.ADAPTED_MESH)
  );
}

export const projectMeshListAtom = atomFamily<frontendpb.Mesh[], string>({
  key: 'projectMeshListAtom',
  default: selectorFamily<frontendpb.Mesh[], string>({
    key: 'projectMeshListAtom/default',
    get: (projectId: string) => async ({ get }) => {
      if (isTestingEnv()) {
        return [];
      }
      const selectedGeometry = get(
        noWait(selectedGeometryState({ projectId, workflowId: '', jobId: '' })),
      );
      const meshList = get(noWait(getAllMeshesRpcCache(projectId)));
      if (meshList.state !== 'hasValue' || selectedGeometry.state !== 'hasValue') {
        return [];
      }
      return filterMeshList(meshList.contents, selectedGeometry.contents.geometryVersionId);
    },
  }),
});

const projectAllMeshListAtom = atomFamily<frontendpb.Mesh[], string>({
  key: 'projectAllMeshListAtom',
  default: selectorFamily<frontendpb.Mesh[], string>({
    key: 'projectAllMeshListAtom/default',
    get: (projectId: string) => async ({ get }) => {
      if (isTestingEnv()) {
        return [];
      }
      const meshList = get(noWait(getAllMeshesRpcCache(projectId)));
      if (meshList.state !== 'hasValue') {
        return [];
      }
      return meshList.contents;
    },
  }),
});

export const useProjectMeshList = (projectId: string) => useRecoilState(
  projectMeshListAtom(projectId),
);

export const useSetProjectMeshList = (projectId: string) => useSetRecoilState(
  projectMeshListAtom(projectId),
);

export const useAllProjectMeshList = (projectId: string) => useRecoilState(
  projectAllMeshListAtom(projectId),
);

export const useRefetchProjectMeshList = (projectId: string) => {
  const setProjectMeshList = useSetRecoilState(projectMeshListAtom(projectId));

  const refetchMeshList = useRecoilCallback(({ snapshot, set }) => async () => {
    if (!projectId) {
      return;
    }
    try {
      const meshList = await getAllMeshes(projectId);
      const selectedGeometry = await snapshot.getPromise(
        selectedGeometryState({ projectId, workflowId: '', jobId: '' }),
      );
      set(
        projectMeshListAtom(projectId),
        filterMeshList(meshList, selectedGeometry.geometryVersionId),
      );
    } catch (err) {
      logger.error('Unable to retrieve mesh list for projectId=%s: %s', projectId, err);
    }
  }, [projectId, setProjectMeshList]);

  return { refetchMeshList };
};
