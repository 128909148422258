// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { CommonMenuItem } from '../../../../../lib/componentTypes/menu';
import { colors } from '../../../../../lib/designSystem';
import { geometryIdLink } from '../../../../../lib/navigation';
import * as rpc from '../../../../../lib/rpc';
import { truncatedWordList } from '../../../../../lib/text';
import { addRpcError } from '../../../../../lib/transientNotification';
import * as geometryservicepb from '../../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as frontendpb from '../../../../../proto/frontend/frontend_pb';
import { useGeometryVersionsLoaded } from '../../../../../recoil/geometry/geometryIdsLoaded';
import { useGeometryList } from '../../../../../recoil/geometry/geometryListState';
import { useSelectedGeometry } from '../../../../../recoil/selectedGeometry';
import { pushConfirmation, useSetConfirmations } from '../../../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../../../Button/ActionButton';
import { CardButton } from '../../../../Button/CardButton';
import { useCommonCardPickerStyles } from '../../../../Theme/commonStyles';
import { useProjectContext } from '../../../../context/ProjectContext';
import MeshImportDialogCommon from '../../../../project/MeshImportDialogCommon';

interface GeometrySelectCardProps {
  geometryId: string;
  geometryVersionId: string;
}

function makeSubtitle(faces: number, volumes: number, res: geometryservicepb.GetTagsResponse) {
  const tagNames = res.tags?.tags.map((tag) => tag.name) || [];
  return `${volumes} volumes, ${faces} faces. Tag names: ${truncatedWordList(tagNames, 3)}`;
}

const GeometrySelectCard = (props: GeometrySelectCardProps) => {
  // == Props
  const { geometryId, geometryVersionId } = props;

  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const [selectedGeometry] = useSelectedGeometry(projectId, workflowId, jobId);
  const geometryList = useGeometryList(projectId);
  const geometry = useMemo(() => geometryList?.geometries.find((geo) => (
    geo.id === geometryId
  )), [geometryId, geometryList]);

  const setConfirmStack = useSetConfirmations();
  const navigate = useNavigate();

  const selected = selectedGeometry.geometryVersionId === geometryVersionId;

  const [subtitle, setSubtitle] = useState<string>('');

  useEffect(() => {
    let resEntities = new geometryservicepb.ListGeometryEntitiesResponse();
    let resTags = new geometryservicepb.GetTagsResponse();
    const req = new geometryservicepb.ListGeometryEntitiesRequest({
      parentResourceId: {
        case: 'geometryVersionId',
        value: geometryVersionId,
      },
    });
    rpc.callRetryWithClient(
      rpc.clientGeometry!,
      'listGeometryEntities',
      rpc.clientGeometry!.listGeometryEntities,
      req,
      true,
      false,
    ).then((res) => {
      resEntities = res;
      setSubtitle(makeSubtitle(resEntities.faces.length, resEntities.bodies.length, resTags));
    }).catch((err) => {
      console.error('Error getting geometry entities', err);
    });

    rpc.callRetryWithClient(
      rpc.clientGeometry!,
      'GetTags',
      rpc.clientGeometry!.getTags,
      new geometryservicepb.GetTagsRequest({
        geometryId,
        geometryVersionId,
      }),
    ).then((res) => {
      resTags = res;
      setSubtitle(makeSubtitle(resEntities.faces.length, resEntities.bodies.length, resTags));
    }).catch((err) => {
      console.error('Error getting geometry tags', err);
    });
  }, [geometryId, geometryVersionId]);

  const loadToSetup = useCallback(async () => {
    const KeepType = frontendpb.LoadToSetupBackendOperationsRequest_KeepGeometricalEntities;
    const keepMode = KeepType.KEEP_TAG_GEOMETRICAL_ENTITIES;

    try {
      await rpc.callRetry(
        'LoadToSetupBackendOperations',
        rpc.client.loadToSetupBackendOperations,
        new frontendpb.LoadToSetupBackendOperationsRequest({
          projectId,
          geometryId,
          geometryVersionId,
          allowLcSurfaceTessellation: false,
          keepGeometricalEntities: keepMode,
          keepPreviousMeshesAndSimulations: true,
        }),
      );
      // Refresh the webpage to clear out recoil.
      window.location.reload();
    } catch (err) {
      addRpcError('Could not reset geometry', new Error());
    }
  }, [geometryId, geometryVersionId, projectId]);

  const loadToSetupWithWarning = useCallback(async () => {
    pushConfirmation(setConfirmStack, {
      continueLabel: 'Confirm',
      destructive: false,
      onContinue: async () => {
        await loadToSetup();
      },
      title: 'Warning',
      children: (
        <div>
          This will clear all settings that are not related to geometry tags. Are you sure you want
          to continue?
        </div>
      ),
    });
  }, [loadToSetup, setConfirmStack]);

  const renameItem = {
    label: 'Modify',
    onClick: () => {
      navigate(geometryIdLink(projectId, geometryId));
    },
  };
  const menuItems: CommonMenuItem[] = [renameItem];

  return (
    <CardButton
      compact
      disabled={selected}
      // disabledReason={disabledReason}
      editableDisabled
      icon={{ maxHeight: 12, name: 'cubeSolid', color: colors.highEmphasisText }}
      key={geometryVersionId}
      menuItems={menuItems}
      onClick={async () => {
        // If mesh is already active, just go to details panel
        if (selected) {
          return;
        }
        await loadToSetupWithWarning();
      }}
      selected={selected}
      subtitle={`${subtitle} Version ${geometryVersionId}`}
      title={`${geometry?.name}`}
    />
  );
};

const GeometrySelectContainer = () => {
  const classes = useCommonCardPickerStyles();

  const { projectId } = useProjectContext();

  const geometryVersionsLoaded = useGeometryVersionsLoaded(projectId);
  if (!geometryVersionsLoaded || !Object.entries(geometryVersionsLoaded.geometryVersions).length) {
    return null;
  }

  return (
    <div className={classes.cardProps}>
      {Object.entries(geometryVersionsLoaded.geometryVersions).map(
        ([geometryId, geometryVersions]) => (
          geometryVersions.geometryVersionIds.map((geometryVersionId) => (
            <GeometrySelectCard
              geometryId={geometryId}
              geometryVersionId={geometryVersionId}
              key={geometryVersionId}
            />
          ))
        ),
      )}
    </div>
  );
};

export const GeometryVersionSelectPanel = () => {
  const classes = useCommonCardPickerStyles();

  const headerText = 'Select a Geometry to Setup';
  const [showUpload, setShowUpload] = useState(false);

  return (
    <div className={classes.propPanelContainer}>
      <div className={classes.headerContainer}>
        <p className={classes.sectionHeader}>{headerText}</p>
      </div>

      <div className={classes.buttonContainer}>
        <ActionButton
          asBlock
          kind="secondary"
          name="newGeometryButton"
          onClick={() => setShowUpload(true)}
          size="small"
          startIcon={{ name: 'plus', maxWidth: 12 }}>
          New Geometry
        </ActionButton>
      </div>
      {showUpload && (
        <MeshImportDialogCommon
          forceUploadGeometry
          onClose={() => setShowUpload(false)}
          open={showUpload}
          type="CAD"
        />
      )}
      <GeometrySelectContainer />
    </div>
  );
};
