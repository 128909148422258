import BaseError from '@/errors/BaseError';

/**
 * DeveloperError indicates a well-known mistake that should be corrected in code.
 */
export default class DeveloperError extends BaseError {
  public data?: any;
  public name = 'DeveloperError';

  constructor(message: string, data?: any, source?: Error) {
    super(message, source);
    this.data = data;
  }

  public override toString() {
    const data = JSON.stringify(this.data);
    let response = `${this.name}: ${this.message}`;
    if (this.data) {
      response += `\n\nData: ${data}`;
    }
    if (this.source) {
      response += `\n\nSource: ${this.source.toString()}`;
    }
    response += `\n\nStack: ${this.fullstack}`;
    return response;
  }
}
