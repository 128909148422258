// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { ActionLink } from '../../components/Button/ActionLink';
import { IconButton } from '../../components/Button/IconButton';
import { createStyles, makeStyles } from '../../components/Theme';
import { MainPageLayout, useMainCommonStyles } from '../../components/layout/page/Main';
import { DuplicateIcon } from '../../components/svg/DuplicateIcon';
import { PRIVACY_URL, TOS_URL } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { formatDate } from '../../lib/formatDate';
import { getSession, loadSessionJwt } from '../../lib/jwt';
import { getFullName } from '../../lib/user';
import { useCurrentUser, useIsTrialAccount } from '../../recoil/useAccountInfo';
import { useIsStaff } from '../../state/external/user/frontendRole';

import { ApiKeySection } from './ApiKeySection';
import { NotificationSettingsSection } from './NotificationSettingsSection';

const useStyles = makeStyles(
  () => createStyles({
    defList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginTop: '36px',
    },
    defItem: {
      display: 'flex',
      gap: '36px',
    },
    defTerm: {
      width: '90px',
      color: colors.lowEmphasisText,
    },
    defDesc: {
      color: colors.highEmphasisText,
    },
    flexContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
    },
  }),
  { name: 'MyAccountPageBody' },
);

const MyAccountPageBody = () => {
  const classes = useStyles();
  const commonClasses = useMainCommonStyles();
  const currentUser = useCurrentUser();
  const jwt = loadSessionJwt();
  const isStaff = useIsStaff();

  return (
    <MainPageLayout loading={!currentUser} title="My Account">
      <div className={commonClasses.title}>Profile Information</div>
      <div className={cx(classes.defList, commonClasses.textContent)}>
        <div className={classes.defItem}>
          <div className={classes.defTerm}>Name</div>
          <div className={classes.defDesc}>
            {currentUser && getFullName(currentUser)}
          </div>
        </div>
        <div className={classes.defItem}>
          <div className={classes.defTerm}>Email</div>
          <div className={classes.defDesc}>{currentUser?.email}</div>
        </div>
        <div className={classes.defItem}>
          <div className={classes.defTerm}>Notifications</div>
          <div className={classes.defDesc}><NotificationSettingsSection /></div>
        </div>
        {isStaff && (
          <div className={classes.defItem}>
            <div className={classes.defTerm}>SDK Access Token</div>
            <div className={classes.defDesc}>
              <IconButton
                className="copyButton"
                onClick={() => navigator.clipboard.writeText(getSession())}>
                <DuplicateIcon maxHeight={12} />
              </IconButton>
              Copy access token (treat this like a password)
              <br />
              Expires: {formatDate(jwt?.exp || 0)}
            </div>
          </div>
        )}
      </div>
      <div className={commonClasses.title}>API Keys</div>
      {useIsTrialAccount() ? (
        <div className={commonClasses.textContent}>
          Trial accounts do not have access to API keys.
          Please upgrade your account to use this feature.
        </div>
      ) : (
        <div className={commonClasses.textContent}>
          <ApiKeySection />
        </div>
      )}
      <div className={commonClasses.title}>Legal Information</div>
      <div className={cx(commonClasses.textContent, classes.flexContent)}>
        <ActionLink href={TOS_URL}>
          Terms of Service
        </ActionLink>
        <ActionLink href={PRIVACY_URL}>
          Privacy Policy
        </ActionLink>
      </div>
    </MainPageLayout>
  );
};

export default MyAccountPageBody;
