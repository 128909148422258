// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter, isSubselectActive } from '../../../lib/subselectUtils';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter, useSimulationTreeSubselect } from '../../../recoil/simulationTreeSubselect';
import { useViewState } from '../../../recoil/useViewState';
import { useProjectContext } from '../../context/ProjectContext';

const DISK_WARNING = `Disks cannot be extracted in Setup view or before simulation is run.  An
  extracted disk has no associated data`;

const VISIBLE_TYPES = new Set([NodeType.SURFACE, NodeType.SURFACE_GROUP, NodeType.PARTICLE_GROUP]);

export const useExtractSurfacesSubselect = (subselectId: string) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const [viewState] = useViewState(projectId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  // == Hooks
  const treeSubselect = useSimulationTreeSubselect();

  // == Data
  const isActive = useMemo(
    () => isSubselectActive(treeSubselect, subselectId),
    [subselectId, treeSubselect],
  );

  const validParticleGroupNodeIds = useMemo(() => {
    const map: Record<string, boolean> = {};
    viewState?.root.child.forEach((child) => {
      if ('particleGroupId' in child.param && child.pointData.length) {
        map[child.param.particleGroupId] = true;
      }
    });
    return map;
  }, [viewState]);

  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (geometryTags.isTagId(nodeId)) {
      const surfaces = geometryTags.surfacesFromTagEntityGroupId(nodeId);
      if (surfaces === undefined || surfaces.length === 0) {
        return {
          related: true,
          disabled: true,
          tooltip: 'No surfaces available in this tag',
        };
      }
      return {
        related: true,
        disabled: false,
      };
    }
    if (viewState) {
      if (VISIBLE_TYPES.has(nodeType)) {
        if (nodeType === NodeType.PARTICLE_GROUP) {
          const valid = !!validParticleGroupNodeIds[nodeId];
          return {
            related: true,
            tooltip: valid ? '' : DISK_WARNING,
            disabled: !valid,
          };
        }
        return { related: true };
      }
    }
    return defaultNodeFilter(nodeType);
  }, [validParticleGroupNodeIds, viewState, geometryTags]);

  return {
    isActive,
    nodeFilter,
  };
};
