// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

let nextId = 1;

// For regular objects, we store the ID in field lcInternalObjectId.  For frozen
// objects, we keep the IDs in a weakmap.
const frozenObjects = new WeakMap();

/**
 * @deprecated This function will be removed soon.
 * Avoid using it, especially for generating React keys.
 *
 * For more details, see: [LC-23588](https://github.com/luminarycloud/core/pull/20226)
 */
export default function objectId(obj: any, serializeFirst = true): number | string {
  if (obj == null) {
    return -1;
  }

  if (serializeFirst) {
    try {
      return JSON.stringify(obj);
    } catch {
    // the object may contain non-serializable fields and fail here
    // we can still use the solution below tho
    }
  }

  if (!Object.isExtensible(obj)) {
    let value = frozenObjects.get(obj);
    if (!value) {
      nextId += 1;
      value = nextId;
      frozenObjects.set(obj, value);
    }
    return value;
  }
  if ((obj as any).lcInternalObjectId == null) {
    nextId += 1;
    (obj as any).lcInternalObjectId = nextId;
  }
  return (obj as any).lcInternalObjectId;
}
