// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { ParamGroupName, paramGroupDesc } from '../SimulationParamDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';

import { initParamGroupProto } from './initParam';

export function getDefaultAdjoint() {
  return initParamGroupProto(
    new simulationpb.Adjoint(),
    paramGroupDesc[ParamGroupName.Adjoint],
  );
}
