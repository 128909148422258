// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo, useRef } from 'react';

import { CommonMenuListItem } from '../../../lib/componentTypes/menu';
import { expandGroups, rollupGroups } from '../../../lib/entityGroupUtils';
import { getVolumeIdsFromSurfaces, surfacesFromVolumes } from '../../../lib/volumeUtils';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { EntitySelectionType, useEntitySelectionState } from '../../../recoil/selectionOptions';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { RibbonToolbarTool } from '../../RibbonToolbar/RibbonToolbarButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

import environmentState from '@/state/environment';

export const useSurfaceOrVolumeSelectDropdownData = (): RibbonToolbarTool => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { viewState } = useParaviewContext();
  const { setSelection, selectedNodeIds, setScrollTo, isTreeModal } = useSelectionContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const [entitySelectionState, setEntitySelectionStateBase] = useEntitySelectionState(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  // use a ref to track when only the entitySelectionState changes.
  const prevEntitySelectionState = useRef<EntitySelectionType>(entitySelectionState);

  const pvReady = !!viewState;
  const visReady = lcvisEnabled ? lcvisReady : pvReady;

  // if we switch modes, switch the selection from surfaces to volumes, and vice versa.
  const setEntitySelectionState = useCallback((newState: EntitySelectionType) => {
    if (newState === entitySelectionState) {
      return;
    }
    setEntitySelectionStateBase(newState);
    prevEntitySelectionState.current = newState;
    if (isTreeModal) {
      return;
    }

    let selectionTarget: string[] = [];

    if (newState === 'surface') {
      // if we go from 'volume' to 'surface', select the bounding surfaces of every volume that was
      // previously selected.
      const selectedNodeSet = new Set(selectedNodeIds);
      const volumesOfInterest = staticVolumes.filter((volume) => selectedNodeSet.has(volume.id));
      // the surface ids may compose a full group. So we should roll them up and only select the
      // top level group for any set of surfaces.
      const rollup = rollupGroups(entityGroupData);
      const surfaceIdsToSelect = rollup(surfacesFromVolumes(volumesOfInterest));

      selectionTarget = surfaceIdsToSelect;
    } else if (newState === 'volume') {
      // if we go from 'surface' to 'volume', select all the volumes that contain any previously
      // selected surface.
      const surfaceIds = expandGroups(entityGroupData.leafMap)(selectedNodeIds);
      const volumeIdsToSelect = getVolumeIdsFromSurfaces(surfaceIds, staticVolumes);

      selectionTarget = volumeIdsToSelect;
    }

    if (selectionTarget.length > 0) {
      const [nodeToSelect] = selectionTarget;
      setSelection(selectionTarget);

      setScrollTo({ node: nodeToSelect, fast: true });
    }
  }, [
    setEntitySelectionStateBase,
    selectedNodeIds,
    setSelection,
    setScrollTo,
    entitySelectionState,
    isTreeModal,
    staticVolumes,
    entityGroupData,
  ]);

  const entitySelectOptions: CommonMenuListItem[] = useMemo(() => [
    {
      disabled: !visReady,
      label: 'No Highlight',
      help: 'Select surfaces but disable highlighting when hovering over them',
      onClick: () => {
        setEntitySelectionState('surface_no_highlight');
      },
      selected: entitySelectionState === 'surface_no_highlight',
      startIcon: { name: 'cubeSparkle' },
    },
    {
      disabled: !visReady,
      label: 'Select Surfaces',
      startIcon: { name: 'cubeOutline' },
      onClick: () => {
        setEntitySelectionState('surface');
      },
      selected: entitySelectionState === 'surface',
    },
    {
      disabled: !visReady,
      label: 'Select Volumes',
      onClick: () => {
        setEntitySelectionState('volume');
      },
      startIcon: { name: 'cubeSolid' },
      selected: entitySelectionState === 'volume',
    },
  ], [visReady, entitySelectionState, setEntitySelectionState]);

  const [label, icon] = useMemo(() => {
    const selectOption = entitySelectOptions.find((option) => option.selected);
    return [String(selectOption!.label), selectOption!.startIcon!];
  }, [entitySelectOptions]);

  return {
    disabled: !visReady,
    icon,
    key: 'entitySelectionButton',
    locator: 'toolbar-entity-selection',
    label,
    items: entitySelectOptions,
  };
};
