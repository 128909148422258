// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { CollapsibleNodePanel } from '@/components/Panel/CollapsibleNodePanel';
import Divider from '@/components/Theme/Divider';
import { useCommonTreePropsStyles } from '@/components/Theme/commonStyles';
import { useProjectContext } from '@/components/context/ProjectContext';
import PropertiesSection from '@/components/treePanel/PropertiesSection';
import { AdjointFluidSolverSettings, AdjointHeatSolverSettings, AdjointOutput, AdjointSensitivitySurfaces } from '@/components/treePanel/propPanel/shared/AdjointInputs';
import { Flex } from '@/components/visual/Flex';
import { getPhysicsId, getPhysicsName } from '@/lib/physicsUtils';
import { usePhysicsSet } from '@/model/hooks/usePhysicsSet';
import { useSimulationParam } from '@/state/external/project/simulation/param';

export const AdjointSettingsPropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Hooks
  const propClasses = useCommonTreePropsStyles();

  // == State
  const {
    allPhysics,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  return (
    <>
      <div className={propClasses.properties}>
        <PropertiesSection>
          <Flex flexDirection="column" gap={8}>
            <AdjointOutput />
            <AdjointSensitivitySurfaces />
            {allPhysics.map((physic) => {
              const sectionLabel = `${getPhysicsName(physic, simParam)} - Adjoint Solver Settings`;
              return (
                <React.Fragment key={sectionLabel}>
                  <Divider />

                  <CollapsibleNodePanel
                    heading={sectionLabel}
                    nodeId={sectionLabel}
                    panelName="advanced_adjoint"
                    panelOptions={{ defaultExpanded: false }}>
                    {physic.params.case === 'fluid' && (
                      <AdjointFluidSolverSettings physicsId={getPhysicsId(physic)} />
                    )}

                    {physic.params.case === 'heat' && (
                      <AdjointHeatSolverSettings physicsId={getPhysicsId(physic)} />
                    )}
                  </CollapsibleNodePanel>
                </React.Fragment>
              );
            })}
          </Flex>
        </PropertiesSection>
      </div>
    </>
  );
};
