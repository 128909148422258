// Generated by generate_client_params.py. DO NOT EDIT

// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/client/simulation.proto (package luminary.proto.client, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { EntityIdentifier } from "./entity_pb.js";
import { AdFloatType, AdVector3, Int } from "../base/base_pb.js";
import { Output as Output$1, StoppingCondition } from "../output/output_pb.js";
import { EntityGroup } from "../entitygroup/entitygroup_pb.js";
import { Metadata, RectilinearTable } from "../table/table_pb.js";

/**
 * @generated from enum luminary.proto.client.DensityRelationship
 */
export const DensityRelationship = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.DensityRelationship",
  [
    {no: 0, name: "INVALID_DENSITY_RELATIONSHIP"},
    {no: 37484, name: "IDEAL_GAS"},
    {no: 28817, name: "CONSTANT_DENSITY"},
    {no: 41777, name: "CONSTANT_DENSITY_ENERGY"},
  ],
);

/**
 * @generated from enum luminary.proto.client.LaminarThermalConductivity
 */
export const LaminarThermalConductivity = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.LaminarThermalConductivity",
  [
    {no: 0, name: "INVALID_LAMINAR_THERMAL_CONDUCTIVITY"},
    {no: 46513, name: "LAMINAR_CONSTANT_THERMAL_PRANDTL"},
    {no: 39477, name: "LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY"},
    {no: 38127, name: "TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY"},
  ],
);

/**
 * @generated from enum luminary.proto.client.BoussinesqApproximation
 */
export const BoussinesqApproximation = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.BoussinesqApproximation",
  [
    {no: 0, name: "INVALID_BOUSSINESQ_APPROXIMATION"},
    {no: 12354, name: "BOUSSINESQ_OFF"},
    {no: 26001, name: "BOUSSINESQ_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.MaterialFluidPreset
 */
export const MaterialFluidPreset = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.MaterialFluidPreset",
  [
    {no: 0, name: "INVALID_MATERIAL_FLUID_PRESET"},
    {no: 41114, name: "UNSET_MATERIAL_FLUID_PRESET"},
    {no: 28216, name: "STANDARD_AIR"},
    {no: 24388, name: "WATER_NTP"},
    {no: 32460, name: "CUSTOM_MATERIAL_FLUID"},
  ],
);

/**
 * @generated from enum luminary.proto.client.LaminarViscosityModelNewtonian
 */
export const LaminarViscosityModelNewtonian = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.LaminarViscosityModelNewtonian",
  [
    {no: 0, name: "INVALID_LAMINAR_VISCOSITY_MODEL_NEWTONIAN"},
    {no: 36363, name: "SUTHERLAND"},
    {no: 9272, name: "LAMINAR_CONSTANT_VISCOSITY"},
    {no: 41732, name: "TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY"},
  ],
);

/**
 * @generated from enum luminary.proto.client.MaterialSolidPreset
 */
export const MaterialSolidPreset = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.MaterialSolidPreset",
  [
    {no: 0, name: "INVALID_MATERIAL_SOLID_PRESET"},
    {no: 35124, name: "ALUMINUM"},
    {no: 60508, name: "COPPER"},
    {no: 31466, name: "IRON"},
    {no: 46291, name: "NICKEL"},
    {no: 46073, name: "TITANIUM"},
    {no: 62886, name: "CUSTOM_MATERIAL_SOLID"},
  ],
);

/**
 * @generated from enum luminary.proto.client.FloatType
 */
export const FloatType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.FloatType",
  [
    {no: 0, name: "INVALID_FLOAT_TYPE"},
    {no: 26171, name: "DOUBLE"},
    {no: 56378, name: "ADT1D"},
    {no: 58692, name: "ADA1D"},
  ],
);

/**
 * @generated from enum luminary.proto.client.Gravity
 */
export const Gravity = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.Gravity",
  [
    {no: 0, name: "INVALID_GRAVITY"},
    {no: 61845, name: "GRAVITY_OFF"},
    {no: 61724, name: "GRAVITY_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.FlowBehavior
 */
export const FlowBehavior = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.FlowBehavior",
  [
    {no: 0, name: "INVALID_FLOW_BEHAVIOR"},
    {no: 64640, name: "STEADY"},
    {no: 36606, name: "TRANSIENT"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TimeMarching
 */
export const TimeMarching = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TimeMarching",
  [
    {no: 0, name: "INVALID_TIME_MARCHING"},
    {no: 33327, name: "TIME_IMPLICIT"},
    {no: 24337, name: "TIME_EXPLICIT"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TimeImplicitOrder
 */
export const TimeImplicitOrder = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TimeImplicitOrder",
  [
    {no: 0, name: "INVALID_TIME_IMPLICIT_ORDER"},
    {no: 50695, name: "TIME_SECOND"},
    {no: 39911, name: "TIME_FIRST"},
  ],
);

/**
 * @generated from enum luminary.proto.client.PhysicalTimeStepMethod
 */
export const PhysicalTimeStepMethod = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.PhysicalTimeStepMethod",
  [
    {no: 0, name: "INVALID_PHYSICAL_TIME_STEP_METHOD"},
    {no: 31808, name: "FIXED_TIME_STEP"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TimeStepRamp
 */
export const TimeStepRamp = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TimeStepRamp",
  [
    {no: 0, name: "INVALID_TIME_STEP_RAMP"},
    {no: 31507, name: "TIME_STEP_RAMP_OFF"},
    {no: 38748, name: "TIME_STEP_RAMP_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ComputeStatistics
 */
export const ComputeStatistics = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ComputeStatistics",
  [
    {no: 0, name: "INVALID_COMPUTE_STATISTICS"},
    {no: 40562, name: "COMPUTE_STATISTICS_OFF"},
    {no: 1075, name: "COMPUTE_STATISTICS_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.MotionType
 */
export const MotionType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.MotionType",
  [
    {no: 0, name: "INVALID_MOTION_TYPE"},
    {no: 21556, name: "NO_MOTION"},
    {no: 26751, name: "CONSTANT_TRANSLATION_MOTION"},
    {no: 47882, name: "CONSTANT_ANGULAR_MOTION"},
    {no: 46055, name: "CONSTANT_VELOCITY_MOTION"},
  ],
);

/**
 * @generated from enum luminary.proto.client.MotionFormulation
 */
export const MotionFormulation = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.MotionFormulation",
  [
    {no: 0, name: "INVALID_MOTION_FORMULATION"},
    {no: 46481, name: "AUTOMATIC_MOTION_FORMULATION"},
    {no: 44900, name: "MRF_MOTION_FORMULATION"},
  ],
);

/**
 * @generated from enum luminary.proto.client.MotionSpecification
 */
export const MotionSpecification = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.MotionSpecification",
  [
    {no: 0, name: "INVALID_MOTION_SPECIFICATION"},
    {no: 23057, name: "MOTION_SPECIFICATION_REPOSITION"},
    {no: 55326, name: "MOTION_SPECIFICATION_NORMAL"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TransformType
 */
export const TransformType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TransformType",
  [
    {no: 0, name: "INVALID_TRANSFORM_TYPE"},
    {no: 46032, name: "NO_TRANSFORM"},
    {no: 56422, name: "ROTATIONAL_TRANSFORM"},
    {no: 42619, name: "TRANSLATIONAL_TRANSFORM"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ParticleGroupType
 */
export const ParticleGroupType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ParticleGroupType",
  [
    {no: 0, name: "INVALID_PARTICLE_GROUP_TYPE"},
    {no: 28633, name: "ACTUATOR_DISK"},
    {no: 56370, name: "ACTUATOR_LINE"},
    {no: 35112, name: "SOURCE_POINTS"},
    {no: 8029, name: "PROBE_POINTS"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ActuatorDiskOrientationSelection
 */
export const ActuatorDiskOrientationSelection = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ActuatorDiskOrientationSelection",
  [
    {no: 0, name: "INVALID_ACTUATOR_DISK_ORIENTATION_SELECTION"},
    {no: 51954, name: "ACTUATOR_DISK_SPECIFY_ROTATION_ANGLES"},
    {no: 7230, name: "ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR"},
  ],
);

/**
 * @generated from enum luminary.proto.client.DebugOutput
 */
export const DebugOutput = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.DebugOutput",
  [
    {no: 0, name: "INVALID_DEBUG_OUTPUT"},
    {no: 40287, name: "SOLN_DEBUG_OUTPUT_OFF"},
    {no: 5251, name: "SOLN_DEBUG_OUTPUT_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.DebugOutputInteriorSurfaceData
 */
export const DebugOutputInteriorSurfaceData = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.DebugOutputInteriorSurfaceData",
  [
    {no: 0, name: "INVALID_DEBUG_OUTPUT_INTERIOR_SURFACE_DATA"},
    {no: 38426, name: "SOLN_DEBUG_OUTPUT_INT_SURF_DATA_OFF"},
    {no: 21622, name: "SOLN_DEBUG_OUTPUT_INT_SURF_DATA_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.MeshingMethod
 */
export const MeshingMethod = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.MeshingMethod",
  [
    {no: 0, name: "INVALID_MESHING_METHOD"},
    {no: 34850, name: "MESH_METHOD_MANUAL"},
    {no: 1659, name: "MESH_METHOD_AUTO"},
  ],
);

/**
 * @generated from enum luminary.proto.client.RelaxationMethod
 */
export const RelaxationMethod = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.RelaxationMethod",
  [
    {no: 0, name: "INVALID_RELAXATION_METHOD"},
    {no: 53024, name: "IMPLICIT"},
    {no: 27134, name: "EXPLICIT"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ImplicitMethod
 */
export const ImplicitMethod = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ImplicitMethod",
  [
    {no: 0, name: "INVALID_IMPLICIT_METHOD"},
    {no: 62903, name: "BACKWARD_EULER"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ExplicitMethod
 */
export const ExplicitMethod = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ExplicitMethod",
  [
    {no: 0, name: "INVALID_EXPLICIT_METHOD"},
    {no: 42347, name: "RK_4"},
    {no: 11958, name: "TVD_RK_3"},
    {no: 21402, name: "FORWARD_EULER"},
  ],
);

/**
 * @generated from enum luminary.proto.client.LinearSolverType
 */
export const LinearSolverType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.LinearSolverType",
  [
    {no: 0, name: "INVALID_LINEAR_SOLVER_TYPE"},
    {no: 16081, name: "GS"},
    {no: 14928, name: "GS_AMGX"},
    {no: 34601, name: "AMG_KRYLOV_AMGX"},
    {no: 22544, name: "AMG_AMGX"},
  ],
);

/**
 * @generated from enum luminary.proto.client.LinsolAmgCycleType
 */
export const LinsolAmgCycleType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.LinsolAmgCycleType",
  [
    {no: 0, name: "INVALID_LINSOL_AMG_CYCLE_TYPE"},
    {no: 27261, name: "LINSOL_AMG_CYCLE_TYPE_V"},
    {no: 60015, name: "LINSOL_AMG_CYCLE_TYPE_W"},
    {no: 25036, name: "LINSOL_AMG_CYCLE_TYPE_F"},
  ],
);

/**
 * @generated from enum luminary.proto.client.LinsolAmgSmoother
 */
export const LinsolAmgSmoother = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.LinsolAmgSmoother",
  [
    {no: 0, name: "INVALID_LINSOL_AMG_SMOOTHER"},
    {no: 45176, name: "LINSOL_AMG_SMOOTHER_JACOBI"},
    {no: 42943, name: "LINSOL_AMG_SMOOTHER_GS"},
    {no: 49105, name: "LINSOL_AMG_SMOOTHER_SYM_GS"},
  ],
);

/**
 * @generated from enum luminary.proto.client.SolutionControlsHeatPreset
 */
export const SolutionControlsHeatPreset = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.SolutionControlsHeatPreset",
  [
    {no: 0, name: "INVALID_SOLUTION_CONTROLS_HEAT_PRESET"},
    {no: 24195, name: "UNSET_SOLUTION_CONTROLS_HEAT_PRESET"},
    {no: 740, name: "DEFAULT_SOLUTION_CONTROLS_HEAT"},
    {no: 61301, name: "INTERMEDIATE_SOLUTION_CONTROLS_HEAT"},
    {no: 58935, name: "AGGRESSIVE_SOLUTION_CONTROLS_HEAT"},
    {no: 44012, name: "CUSTOM_SOLUTION_CONTROLS_HEAT"},
  ],
);

/**
 * @generated from enum luminary.proto.client.AdjointSolutionMethod
 */
export const AdjointSolutionMethod = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.AdjointSolutionMethod",
  [
    {no: 0, name: "INVALID_ADJOINT_SOLUTION_METHOD"},
    {no: 44791, name: "ADJOINT_METHOD_GMRES"},
    {no: 30098, name: "ADJOINT_METHOD_RICHARDSON"},
    {no: 7104, name: "ADJOINT_METHOD_ALGORITHMIC"},
  ],
);

/**
 * @generated from enum luminary.proto.client.GradientMethod
 */
export const GradientMethod = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.GradientMethod",
  [
    {no: 0, name: "INVALID_GRADIENT_METHOD"},
    {no: 62491, name: "HLSQ"},
    {no: 41255, name: "WEIGHTED_LEAST_SQUARES"},
    {no: 29799, name: "GREEN_GAUSS"},
    {no: 50544, name: "LC_HLSQ"},
    {no: 15192, name: "NODAL_GRADIENT"},
  ],
);

/**
 * @generated from enum luminary.proto.client.GeometryFixes
 */
export const GeometryFixes = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.GeometryFixes",
  [
    {no: 0, name: "INVALID_GEOMETRY_FIXES"},
    {no: 29529, name: "GEOMETRY_FIXES_ON"},
    {no: 55792, name: "GEOMETRY_FIXES_OFF"},
  ],
);

/**
 * @generated from enum luminary.proto.client.SpatialDiscretizationHeatPreset
 */
export const SpatialDiscretizationHeatPreset = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.SpatialDiscretizationHeatPreset",
  [
    {no: 0, name: "INVALID_SPATIAL_DISCRETIZATION_HEAT_PRESET"},
    {no: 37699, name: "UNSET_SPATIAL_DISCRETIZATION_HEAT_PRESET"},
    {no: 6705, name: "DEFAULT_SPATIAL_DISCRETIZATION_HEAT"},
    {no: 60394, name: "CONSERVATIVE_SPATIAL_DISCRETIZATION_HEAT"},
    {no: 5587, name: "HIGH_ACCURACY_SPATIAL_DISCRETIZATION_HEAT"},
    {no: 2742, name: "CUSTOM_SPATIAL_DISCRETIZATION_HEAT"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ProfileType
 */
export const ProfileType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ProfileType",
  [
    {no: 0, name: "INVALID_PROFILE_TYPE"},
    {no: 66439, name: "CARTESIAN_X"},
    {no: 39749, name: "CARTESIAN_Y"},
    {no: 23052, name: "CARTESIAN_Z"},
    {no: 63301, name: "RADIAL_X"},
    {no: 15041, name: "RADIAL_Y"},
    {no: 12670, name: "RADIAL_Z"},
    {no: 22665, name: "TIME"},
  ],
);

/**
 * @generated from enum luminary.proto.client.HeatPhysicalBoundary
 */
export const HeatPhysicalBoundary = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.HeatPhysicalBoundary",
  [
    {no: 0, name: "INVALID_HEAT_PHYSICAL_BOUNDARY"},
    {no: 25246, name: "HEAT_BC_ISOTHERMAL"},
    {no: 63012, name: "HEAT_BC_HEAT_FLUX"},
    {no: 61671, name: "HEAT_BC_INTEGRATED_HEAT_FLUX"},
    {no: 55632, name: "HEAT_BC_SYMMETRY"},
    {no: 57747, name: "HEAT_BC_CONVECTION"},
  ],
);

/**
 * @generated from enum luminary.proto.client.InitializationType
 */
export const InitializationType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.InitializationType",
  [
    {no: 0, name: "INVALID_INITIALIZATION_TYPE"},
    {no: 24354, name: "UNIFORM_VALUES"},
    {no: 3666, name: "FARFIELD_VALUES"},
    {no: 40255, name: "INITIALIZATION_POTENTIAL_FLOW"},
    {no: 11399, name: "VERIFICATION_SOLUTION"},
    {no: 26852, name: "EXISTING_SOLUTION"},
  ],
);

/**
 * @generated from enum luminary.proto.client.HeatSourceType
 */
export const HeatSourceType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.HeatSourceType",
  [
    {no: 0, name: "INVALID_HEAT_SOURCE_TYPE"},
    {no: 28994, name: "HEAT_SOURCE_TYPE_POWER"},
    {no: 61247, name: "HEAT_SOURCE_TYPE_POWER_PER_UNIT_OF_VOLUME"},
  ],
);

/**
 * @generated from enum luminary.proto.client.InterfaceType
 */
export const InterfaceType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.InterfaceType",
  [
    {no: 0, name: "INVALID_INTERFACE_TYPE"},
    {no: 53698, name: "GENERAL_INTERFACE"},
    {no: 44970, name: "MIXING_PLANE_INTERFACE"},
  ],
);

/**
 * @generated from enum luminary.proto.client.PeriodicBcType
 */
export const PeriodicBcType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.PeriodicBcType",
  [
    {no: 0, name: "INVALID_PERIODIC_BC_TYPE"},
    {no: 20185, name: "TRANSLATIONAL"},
    {no: 34414, name: "ROTATIONAL"},
  ],
);

/**
 * @generated from enum luminary.proto.client.FluidType
 */
export const FluidType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.FluidType",
  [
    {no: 0, name: "INVALID_FLUID_TYPE"},
    {no: 30813, name: "SINGLE_PHASE"},
    {no: 56617, name: "CAVITATING_FLUID"},
    {no: 26616, name: "VOF_FLUID"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ViscousModel
 */
export const ViscousModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ViscousModel",
  [
    {no: 0, name: "INVALID_VISCOUS_MODEL"},
    {no: 35474, name: "RANS"},
    {no: 55751, name: "DES"},
    {no: 65537, name: "LES"},
    {no: 48078, name: "LAMINAR"},
    {no: 8775, name: "INVISCID"},
  ],
);

/**
 * @generated from enum luminary.proto.client.DesFormulation
 */
export const DesFormulation = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.DesFormulation",
  [
    {no: 0, name: "INVALID_DES_FORMULATION"},
    {no: 34278, name: "DDES_VTM"},
    {no: 17788, name: "DDES_VTM_SIGMA"},
    {no: 63764, name: "IDDES"},
    {no: 6895, name: "DDES"},
    {no: 37035, name: "DES97"},
    {no: 25341, name: "ZDES"},
  ],
);

/**
 * @generated from enum luminary.proto.client.RansRegion
 */
export const RansRegion = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.RansRegion",
  [
    {no: 0, name: "INVALID_RANS_REGION"},
    {no: 14809, name: "INSIDE"},
    {no: 3117, name: "OUTSIDE"},
  ],
);

/**
 * @generated from enum luminary.proto.client.SubGridScaleModel
 */
export const SubGridScaleModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.SubGridScaleModel",
  [
    {no: 0, name: "INVALID_SUB_GRID_SCALE_MODEL"},
    {no: 50728, name: "NONE"},
    {no: 35746, name: "SMAGORINSKY"},
    {no: 22775, name: "VREMAN"},
    {no: 58568, name: "WALE"},
    {no: 13255, name: "SIGMA"},
    {no: 4052, name: "AMD"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TransitionModel
 */
export const TransitionModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TransitionModel",
  [
    {no: 0, name: "INVALID_TRANSITION_MODEL"},
    {no: 62497, name: "NO_TRANSITION"},
    {no: 20304, name: "GAMMA_2015"},
    {no: 37856, name: "GAMMA_RE_THETA_2009"},
    {no: 52077, name: "AFT_2019"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TransitionModelCrossFlow
 */
export const TransitionModelCrossFlow = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TransitionModelCrossFlow",
  [
    {no: 0, name: "INVALID_TRANSITION_MODEL_CROSS_FLOW"},
    {no: 29326, name: "TRANSITION_MODEL_CROSS_FLOW_OFF"},
    {no: 37250, name: "TRANSITION_MODEL_CROSS_FLOW_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TurbulenceModelConstants
 */
export const TurbulenceModelConstants = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TurbulenceModelConstants",
  [
    {no: 0, name: "INVALID_TURBULENCE_MODEL_CONSTANTS"},
    {no: 26932, name: "DEFAULT_TURB_CONSTANTS"},
    {no: 53831, name: "CUSTOM_TURB_CONSTANTS"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TurbulenceModel
 */
export const TurbulenceModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TurbulenceModel",
  [
    {no: 0, name: "INVALID_TURBULENCE_MODEL"},
    {no: 2762, name: "SPALART_ALLMARAS"},
    {no: 14358, name: "KOMEGA_SST"},
  ],
);

/**
 * @generated from enum luminary.proto.client.QcrSa
 */
export const QcrSa = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.QcrSa",
  [
    {no: 0, name: "INVALID_QCR_SA"},
    {no: 665, name: "QCR_OFF"},
    {no: 11469, name: "QCR2000"},
  ],
);

/**
 * @generated from enum luminary.proto.client.RotationCorrectionSa
 */
export const RotationCorrectionSa = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.RotationCorrectionSa",
  [
    {no: 0, name: "INVALID_ROTATION_CORRECTION_SA"},
    {no: 35194, name: "ROTATION_CORRECTION_OFF"},
    {no: 4526, name: "ROTATION_CORRECTION_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.QcrSst
 */
export const QcrSst = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.QcrSst",
  [
    {no: 0, name: "INVALID_QCR_SST"},
    {no: 30201, name: "SST_QCR_OFF"},
    {no: 28154, name: "SST_QCR2000"},
  ],
);

/**
 * @generated from enum luminary.proto.client.PseudoTimeStepMethod
 */
export const PseudoTimeStepMethod = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.PseudoTimeStepMethod",
  [
    {no: 0, name: "INVALID_PSEUDO_TIME_STEP_METHOD"},
    {no: 60768, name: "CFL_BASED"},
    {no: 32578, name: "FIXED_PSEUDO_TIME_STEP"},
  ],
);

/**
 * @generated from enum luminary.proto.client.LocalTimeStepping
 */
export const LocalTimeStepping = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.LocalTimeStepping",
  [
    {no: 0, name: "INVALID_LOCAL_TIME_STEPPING"},
    {no: 26520, name: "LOCAL_TIME_STEPPING_ON"},
    {no: 60494, name: "LOCAL_TIME_STEPPING_OFF"},
  ],
);

/**
 * @generated from enum luminary.proto.client.JacobianUpdateMethod
 */
export const JacobianUpdateMethod = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.JacobianUpdateMethod",
  [
    {no: 0, name: "INVALID_JACOBIAN_UPDATE_METHOD"},
    {no: 14966, name: "EXPLICIT_INTERVAL_AND_WARMUP"},
  ],
);

/**
 * @generated from enum luminary.proto.client.RobustStartup
 */
export const RobustStartup = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.RobustStartup",
  [
    {no: 0, name: "INVALID_ROBUST_STARTUP"},
    {no: 32536, name: "ROBUST_STARTUP_ON"},
    {no: 3695, name: "ROBUST_STARTUP_OFF"},
  ],
);

/**
 * @generated from enum luminary.proto.client.SolutionControlsFluidPreset
 */
export const SolutionControlsFluidPreset = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.SolutionControlsFluidPreset",
  [
    {no: 0, name: "INVALID_SOLUTION_CONTROLS_FLUID_PRESET"},
    {no: 51359, name: "UNSET_SOLUTION_CONTROLS_FLUID_PRESET"},
    {no: 57802, name: "DEFAULT_SOLUTION_CONTROLS_FLUID"},
    {no: 11033, name: "INTERMEDIATE_SOLUTION_CONTROLS_FLUID"},
    {no: 20860, name: "AGGRESSIVE_SOLUTION_CONTROLS_FLUID"},
    {no: 6315, name: "CUSTOM_SOLUTION_CONTROLS_FLUID"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ConvectiveSchemesDensityBased
 */
export const ConvectiveSchemesDensityBased = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ConvectiveSchemesDensityBased",
  [
    {no: 0, name: "INVALID_CONVECTIVE_SCHEMES_DENSITY_BASED"},
    {no: 40881, name: "ROE"},
    {no: 46617, name: "LD2"},
    {no: 52649, name: "EC2"},
    {no: 43767, name: "RHIE_CHOW"},
  ],
);

/**
 * @generated from enum luminary.proto.client.SkewSymmetricFormulation
 */
export const SkewSymmetricFormulation = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.SkewSymmetricFormulation",
  [
    {no: 0, name: "INVALID_SKEW_SYMMETRIC_FORMULATION"},
    {no: 8382, name: "CHANDRASEKHAR_EC2"},
    {no: 7955, name: "CHANDRASEKHAR_EC1"},
    {no: 7972, name: "KUYA"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ArtificialViscosityModel
 */
export const ArtificialViscosityModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ArtificialViscosityModel",
  [
    {no: 0, name: "INVALID_ARTIFICIAL_VISCOSITY_MODEL"},
    {no: 18186, name: "NO_MODEL"},
    {no: 32785, name: "LAD"},
  ],
);

/**
 * @generated from enum luminary.proto.client.UpwindSchemeOrder
 */
export const UpwindSchemeOrder = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.UpwindSchemeOrder",
  [
    {no: 0, name: "INVALID_UPWIND_SCHEME_ORDER"},
    {no: 33909, name: "SECOND"},
    {no: 9854, name: "FIRST"},
  ],
);

/**
 * @generated from enum luminary.proto.client.RobustDissipation
 */
export const RobustDissipation = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.RobustDissipation",
  [
    {no: 0, name: "INVALID_ROBUST_DISSIPATION"},
    {no: 40461, name: "ROBUST_DISS_OFF"},
    {no: 57825, name: "ROBUST_DISS_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.Limiter
 */
export const Limiter = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.Limiter",
  [
    {no: 0, name: "INVALID_LIMITER"},
    {no: 34181, name: "NO_LIMITER"},
    {no: 28008, name: "INVARIANT_VENKATAKRISHNAN_CV"},
    {no: 30237, name: "VAN_ALBADA_FACE"},
    {no: 33708, name: "VENKATAKRISHNAN_CV"},
  ],
);

/**
 * @generated from enum luminary.proto.client.Preconditioning
 */
export const Preconditioning = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.Preconditioning",
  [
    {no: 0, name: "INVALID_PRECONDITIONING"},
    {no: 64776, name: "PRECONDITIONING_ON"},
    {no: 41590, name: "PRECONDITIONING_OFF"},
  ],
);

/**
 * @generated from enum luminary.proto.client.SpatialDiscretizationFluidPreset
 */
export const SpatialDiscretizationFluidPreset = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.SpatialDiscretizationFluidPreset",
  [
    {no: 0, name: "INVALID_SPATIAL_DISCRETIZATION_FLUID_PRESET"},
    {no: 49649, name: "UNSET_SPATIAL_DISCRETIZATION_FLUID_PRESET"},
    {no: 38654, name: "DEFAULT_SPATIAL_DISCRETIZATION_FLUID"},
    {no: 2519, name: "CONSERVATIVE_SPATIAL_DISCRETIZATION_FLUID"},
    {no: 59227, name: "HIGH_ACCURACY_SPATIAL_DISCRETIZATION_FLUID"},
    {no: 10562, name: "CUSTOM_SPATIAL_DISCRETIZATION_FLUID"},
  ],
);

/**
 * @generated from enum luminary.proto.client.PhysicalBoundary
 */
export const PhysicalBoundary = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.PhysicalBoundary",
  [
    {no: 0, name: "INVALID_PHYSICAL_BOUNDARY"},
    {no: 64731, name: "WALL"},
    {no: 46673, name: "INLET"},
    {no: 55266, name: "OUTLET"},
    {no: 13215, name: "SYMMETRY"},
    {no: 32160, name: "FARFIELD"},
    {no: 51513, name: "OVERSET"},
  ],
);

/**
 * @generated from enum luminary.proto.client.WallMomentum
 */
export const WallMomentum = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.WallMomentum",
  [
    {no: 0, name: "INVALID_WALL_MOMENTUM"},
    {no: 11063, name: "NO_SLIP"},
    {no: 3491, name: "SLIP"},
    {no: 53449, name: "WALL_MODEL"},
  ],
);

/**
 * @generated from enum luminary.proto.client.WallEnergy
 */
export const WallEnergy = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.WallEnergy",
  [
    {no: 0, name: "INVALID_WALL_ENERGY"},
    {no: 814, name: "FIXED_HEAT_FLUX"},
    {no: 22055, name: "FIXED_TEMPERATURE"},
  ],
);

/**
 * @generated from enum luminary.proto.client.InletMomentum
 */
export const InletMomentum = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.InletMomentum",
  [
    {no: 0, name: "INVALID_INLET_MOMENTUM"},
    {no: 55562, name: "TOTAL_PRESSURE_INLET"},
    {no: 17311, name: "MASS_FLOW_INLET"},
    {no: 58910, name: "VELOCITY_INLET"},
    {no: 66019, name: "VELOCITY_COMPONENTS_INLET"},
    {no: 56265, name: "FAN_CURVE_INLET"},
    {no: 22425, name: "MACH_INLET"},
  ],
);

/**
 * @generated from enum luminary.proto.client.DirectionSpecification
 */
export const DirectionSpecification = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.DirectionSpecification",
  [
    {no: 0, name: "INVALID_DIRECTION_SPECIFICATION"},
    {no: 54246, name: "NORMAL_TO_BOUNDARY"},
    {no: 27183, name: "DIRECTION_VECTOR"},
  ],
);

/**
 * @generated from enum luminary.proto.client.InletEnergy
 */
export const InletEnergy = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.InletEnergy",
  [
    {no: 0, name: "INVALID_INLET_ENERGY"},
    {no: 63646, name: "TOTAL_TEMPERATURE_INLET"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TurbulenceSpecificationSpalartAllmaras
 */
export const TurbulenceSpecificationSpalartAllmaras = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TurbulenceSpecificationSpalartAllmaras",
  [
    {no: 0, name: "INVALID_TURBULENCE_SPECIFICATION_SPALART_ALLMARAS"},
    {no: 50655, name: "TURBULENT_VISCOSITY_RATIO_SA"},
    {no: 52501, name: "TURBULENT_VISCOSITY_SA"},
    {no: 6756, name: "BC_SA_VARIABLE"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TurbulenceSpecificationKomega
 */
export const TurbulenceSpecificationKomega = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TurbulenceSpecificationKomega",
  [
    {no: 0, name: "INVALID_TURBULENCE_SPECIFICATION_KOMEGA"},
    {no: 34446, name: "BC_TURBULENT_VISCOSITY_RATIO_AND_INTENSITY_KOMEGA"},
    {no: 66095, name: "BC_TURBULENT_VISCOSITY_AND_INTENSITY_KOMEGA"},
    {no: 10604, name: "BC_KOMEGA_VARIABLES"},
  ],
);

/**
 * @generated from enum luminary.proto.client.OutletStrategy
 */
export const OutletStrategy = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.OutletStrategy",
  [
    {no: 0, name: "INVALID_OUTLET_STRATEGY"},
    {no: 56304, name: "OUTLET_PRESSURE"},
    {no: 21851, name: "OUTLET_TARGET_MASS_FLOW_RATE"},
    {no: 14338, name: "OUTLET_TARGET_CORRECTED_MASS_FLOW_RATE"},
    {no: 44920, name: "FAN_CURVE_OUTLET"},
  ],
);

/**
 * @generated from enum luminary.proto.client.OutletPressureConstraint
 */
export const OutletPressureConstraint = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.OutletPressureConstraint",
  [
    {no: 0, name: "INVALID_OUTLET_PRESSURE_CONSTRAINT"},
    {no: 37894, name: "OUTLET_LOCAL_CONSTRAINT"},
    {no: 33055, name: "OUTLET_AVERAGE_CONSTRAINT"},
  ],
);

/**
 * @generated from enum luminary.proto.client.FarFieldFlowDirectionSpecification
 */
export const FarFieldFlowDirectionSpecification = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.FarFieldFlowDirectionSpecification",
  [
    {no: 0, name: "INVALID_FAR_FIELD_FLOW_DIRECTION_SPECIFICATION"},
    {no: 30342, name: "FARFIELD_DIRECTION"},
    {no: 62593, name: "FARFIELD_ANGLES"},
  ],
);

/**
 * @generated from enum luminary.proto.client.VerificationSolutions
 */
export const VerificationSolutions = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.VerificationSolutions",
  [
    {no: 0, name: "INVALID_VERIFICATION_SOLUTIONS"},
    {no: 57970, name: "TAYLOR_GREEN_VORTEX"},
    {no: 3183, name: "NS_MMS"},
    {no: 43874, name: "EULER_MMS"},
    {no: 7698, name: "SHOCK_TUBE"},
    {no: 12571, name: "NORMAL_SHOCK"},
    {no: 59115, name: "SHOCK_VORTEX"},
    {no: 10488, name: "SHU_OSHER"},
    {no: 34992, name: "DISTURBANCE_WAVE"},
    {no: 33985, name: "INVISCID_VORTEX"},
    {no: 60987, name: "INS_2D_LATTICE"},
    {no: 2909, name: "CHECK_FLUX_SYMMETRY"},
  ],
);

/**
 * @generated from enum luminary.proto.client.PotentialFlowPressureInitialization
 */
export const PotentialFlowPressureInitialization = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.PotentialFlowPressureInitialization",
  [
    {no: 0, name: "INVALID_POTENTIAL_FLOW_PRESSURE_INITIALIZATION"},
    {no: 7137, name: "INITIALIZATION_POTENTIAL_FLOW_PRESSURE_OFF"},
    {no: 8565, name: "INITIALIZATION_POTENTIAL_FLOW_PRESSURE_ON"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TurbulentVariableInitializationTypeSa
 */
export const TurbulentVariableInitializationTypeSa = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TurbulentVariableInitializationTypeSa",
  [
    {no: 0, name: "INVALID_TURBULENT_VARIABLE_INITIALIZATION_TYPE_SA"},
    {no: 25067, name: "INIT_TURBULENT_VISCOSITY_RATIO_SA"},
    {no: 57075, name: "INIT_TURBULENT_VISCOSITY_SA"},
    {no: 65587, name: "INIT_SA_VARIABLE"},
    {no: 14914, name: "INIT_FARFIELD_VALUES_SA"},
  ],
);

/**
 * @generated from enum luminary.proto.client.TurbulentVariableInitializationTypeKomega
 */
export const TurbulentVariableInitializationTypeKomega = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.TurbulentVariableInitializationTypeKomega",
  [
    {no: 0, name: "INVALID_TURBULENT_VARIABLE_INITIALIZATION_TYPE_KOMEGA"},
    {no: 2461, name: "INIT_TURBULENT_VISCOSITY_RATIO_AND_INTENSITY_KOMEGA"},
    {no: 10533, name: "INIT_TURBULENT_VISCOSITY_AND_INTENSITY_KOMEGA"},
    {no: 22789, name: "INIT_KOMEGA_VARIABLES"},
    {no: 43086, name: "INIT_FARFIELD_VALUES_KOMEGA"},
  ],
);

/**
 * @generated from enum luminary.proto.client.PhysicalBehaviorModel
 */
export const PhysicalBehaviorModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.PhysicalBehaviorModel",
  [
    {no: 0, name: "INVALID_PHYSICAL_BEHAVIOR_MODEL"},
    {no: 48090, name: "ACTUATOR_DISK_MODEL"},
    {no: 2007, name: "ACTUATOR_LINE_MODEL"},
    {no: 36795, name: "SOURCE_POINTS_MODEL"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ActuatorDiskModel
 */
export const ActuatorDiskModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ActuatorDiskModel",
  [
    {no: 0, name: "INVALID_ACTUATOR_DISK_MODEL"},
    {no: 55624, name: "ACTUATOR_DISK_UNIFORM_THRUST"},
    {no: 4518, name: "ACTUATOR_DISK_RADIAL_DISTRIBUTION"},
    {no: 32379, name: "ACTUATOR_DISK_BLADE_ELEMENT"},
    {no: 51439, name: "FAN_CURVE_INTERNAL"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ActuatorLineModel
 */
export const ActuatorLineModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ActuatorLineModel",
  [
    {no: 0, name: "INVALID_ACTUATOR_LINE_MODEL"},
    {no: 48609, name: "ACTUATOR_LINE_BLADE_ELEMENT"},
  ],
);

/**
 * @generated from enum luminary.proto.client.ParticleSourceModel
 */
export const ParticleSourceModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.ParticleSourceModel",
  [
    {no: 0, name: "INVALID_PARTICLE_SOURCE_MODEL"},
    {no: 36384, name: "GENERAL_MASS_SOURCE"},
    {no: 25728, name: "GENERAL_ACCELERATION_SOURCE"},
  ],
);

/**
 * @generated from enum luminary.proto.client.CavitationModel
 */
export const CavitationModel = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.CavitationModel",
  [
    {no: 0, name: "INVALID_CAVITATION_MODEL"},
    {no: 29874, name: "SAUER_SCHNERR"},
  ],
);

/**
 * @generated from enum luminary.proto.client.PorousModelType
 */
export const PorousModelType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.PorousModelType",
  [
    {no: 0, name: "INVALID_POROUS_MODEL_TYPE"},
    {no: 55595, name: "DARCY_FORCHHEIMER"},
  ],
);

/**
 * @generated from enum luminary.proto.client.MpCouplingLinSolCoupling
 */
export const MpCouplingLinSolCoupling = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.client.MpCouplingLinSolCoupling",
  [
    {no: 0, name: "INVALID_MP_COUPLING_LIN_SOL_COUPLING"},
    {no: 36695, name: "MP_COUPLING_LIN_SOL_COUPLING_ON"},
    {no: 40344, name: "MP_COUPLING_LIN_SOL_COUPLING_OFF"},
  ],
);

/**
 * Mesh file URL used to run the simulation.
 *
 * @generated from message luminary.proto.client.Input
 */
export const Input = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.Input",
  () => [
    { no: 10470, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 63861, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22090, name: "mesh_identifier", kind: "message", T: EntityIdentifier },
  ],
);

/**
 * Configuration for Fluid materials
 *
 * @generated from message luminary.proto.client.MaterialFluid
 */
export const MaterialFluid = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.MaterialFluid",
  () => [
    { no: 46978, name: "reference_pressure", kind: "message", T: AdFloatType },
    { no: 43428, name: "density_relationship", kind: "enum", T: proto3.getEnumType(DensityRelationship) },
    { no: 401, name: "molecular_weight", kind: "message", T: AdFloatType },
    { no: 35421, name: "specific_heat_cp", kind: "message", T: AdFloatType },
    { no: 63885, name: "constant_density_value", kind: "message", T: AdFloatType },
    { no: 14075, name: "laminar_thermal_conductivity", kind: "enum", T: proto3.getEnumType(LaminarThermalConductivity) },
    { no: 9255, name: "laminar_constant_thermal_prandtl_constant", kind: "message", T: AdFloatType },
    { no: 26018, name: "laminar_constant_thermal_conductivity_constant", kind: "message", T: AdFloatType },
    { no: 38841, name: "thermal_conductivity_table_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25086, name: "boussinesq_approximation", kind: "enum", T: proto3.getEnumType(BoussinesqApproximation) },
    { no: 21832, name: "boussinesq_temp_ref", kind: "message", T: AdFloatType },
    { no: 6413, name: "thermal_expansion_coefficient", kind: "message", T: AdFloatType },
    { no: 59434, name: "material_fluid_preset", kind: "enum", T: proto3.getEnumType(MaterialFluidPreset) },
    { no: 48404, name: "laminar_viscosity_model_newtonian", kind: "enum", T: proto3.getEnumType(LaminarViscosityModelNewtonian) },
    { no: 57038, name: "sutherland_viscosity_ref", kind: "message", T: AdFloatType },
    { no: 41145, name: "sutherland_viscosity_temp_ref", kind: "message", T: AdFloatType },
    { no: 47741, name: "sutherland_constant", kind: "message", T: AdFloatType },
    { no: 32214, name: "laminar_constant_viscosity_constant", kind: "message", T: AdFloatType },
    { no: 4633, name: "dynamic_viscosity_table_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Configuration for Solid materials
 *
 * @generated from message luminary.proto.client.MaterialSolid
 */
export const MaterialSolid = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.MaterialSolid",
  () => [
    { no: 17601, name: "constant_density_value_solid", kind: "message", T: AdFloatType },
    { no: 18023, name: "specific_heat_cp_solid", kind: "message", T: AdFloatType },
    { no: 8132, name: "thermal_conductivity_constant_solid", kind: "message", T: AdFloatType },
    { no: 38841, name: "thermal_conductivity_table_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 44551, name: "material_solid_preset", kind: "enum", T: proto3.getEnumType(MaterialSolidPreset) },
  ],
);

/**
 * Material entity.
 *
 * @generated from message luminary.proto.client.MaterialEntity
 */
export const MaterialEntity = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.MaterialEntity",
  () => [
    { no: 65313, name: "material_identifier", kind: "message", T: EntityIdentifier },
    { no: 20383, name: "material_fluid", kind: "message", T: MaterialFluid, oneof: "material" },
    { no: 56893, name: "material_solid", kind: "message", T: MaterialSolid, oneof: "material" },
  ],
);

/**
 * Volume entity.
 *
 * @generated from message luminary.proto.client.VolumeEntity
 */
export const VolumeEntity = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.VolumeEntity",
  () => [
    { no: 20638, name: "volume_identifier", kind: "message", T: EntityIdentifier },
  ],
);

/**
 * Basic parameters used by a solution.
 *
 * @generated from message luminary.proto.client.General
 */
export const General = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.General",
  () => [
    { no: 30847, name: "float_type", kind: "enum", T: proto3.getEnumType(FloatType) },
    { no: 54500, name: "gravity", kind: "enum", T: proto3.getEnumType(Gravity) },
    { no: 36257, name: "acceleration", kind: "message", T: AdVector3 },
    { no: 53947, name: "flow_behavior", kind: "enum", T: proto3.getEnumType(FlowBehavior) },
    { no: 13125, name: "solver_params", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Settings for adjoint sensitivity analysis.
 *
 * @generated from message luminary.proto.client.Adjoint
 */
export const Adjoint = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.Adjoint",
  () => [
    { no: 42179, name: "adjoint_output", kind: "message", T: Output$1 },
    { no: 3525, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 65892, name: "primal_simulation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Time parameters used by a transient solution (required if flow_behavior = transient).
 *
 * @generated from message luminary.proto.client.Time
 */
export const Time = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.Time",
  () => [
    { no: 59856, name: "time_marching", kind: "enum", T: proto3.getEnumType(TimeMarching) },
    { no: 33737, name: "time_implicit_order", kind: "enum", T: proto3.getEnumType(TimeImplicitOrder) },
    { no: 36975, name: "physical_time_step_method", kind: "enum", T: proto3.getEnumType(PhysicalTimeStepMethod) },
    { no: 30218, name: "time_step_val", kind: "message", T: AdFloatType },
    { no: 64152, name: "time_step_ramp", kind: "enum", T: proto3.getEnumType(TimeStepRamp) },
    { no: 21670, name: "time_step_ramp_initial_val", kind: "message", T: AdFloatType },
    { no: 64685, name: "time_step_ramp_iteration_begin", kind: "message", T: Int },
    { no: 27229, name: "time_step_ramp_iteration_end", kind: "message", T: Int },
    { no: 28104, name: "compute_statistics", kind: "enum", T: proto3.getEnumType(ComputeStatistics) },
    { no: 36588, name: "statistics_start_iteration", kind: "message", T: Int },
    { no: 31506, name: "statistics_update_interval", kind: "message", T: Int },
  ],
);

/**
 * TODO: no help
 *
 * @generated from message luminary.proto.client.FrameTransforms
 */
export const FrameTransforms = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.FrameTransforms",
  () => [
    { no: 16634, name: "transform_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23371, name: "transform_type", kind: "enum", T: proto3.getEnumType(TransformType) },
    { no: 21051, name: "transform_translation", kind: "message", T: AdVector3 },
    { no: 30984, name: "transform_rotation_angles", kind: "message", T: AdVector3 },
  ],
);

/**
 * Motion data.
 *
 * @generated from message luminary.proto.client.MotionData
 */
export const MotionData = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.MotionData",
  () => [
    { no: 51404, name: "frame_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 54894, name: "frame_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 42991, name: "frame_parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 54023, name: "attached_domains", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2115, name: "attached_boundaries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 63337, name: "motion_type", kind: "enum", T: proto3.getEnumType(MotionType) },
    { no: 9300, name: "motion_formulation", kind: "enum", T: proto3.getEnumType(MotionFormulation) },
    { no: 4781, name: "motion_specification", kind: "enum", T: proto3.getEnumType(MotionSpecification) },
    { no: 48436, name: "motion_translation_velocity", kind: "message", T: AdVector3 },
    { no: 20063, name: "motion_angular_velocity", kind: "message", T: AdVector3 },
    { no: 64681, name: "motion_translation", kind: "message", T: AdVector3 },
    { no: 18637, name: "motion_rotation_angles", kind: "message", T: AdVector3 },
    { no: 10438, name: "frame_transforms", kind: "message", T: FrameTransforms, repeated: true },
  ],
);

/**
 * Particle groups.
 *
 * @generated from message luminary.proto.client.ParticleGroup
 */
export const ParticleGroup = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.ParticleGroup",
  () => [
    { no: 22247, name: "particle_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25091, name: "particle_group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 61371, name: "particle_group_behavior_model_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12373, name: "particle_group_type", kind: "enum", T: proto3.getEnumType(ParticleGroupType) },
    { no: 11851, name: "actuator_disk_inner_radius", kind: "message", T: AdFloatType },
    { no: 17827, name: "actuator_disk_outer_radius", kind: "message", T: AdFloatType },
    { no: 12493, name: "actuator_disk_center", kind: "message", T: AdVector3 },
    { no: 51552, name: "actuator_disk_orientation_selection", kind: "enum", T: proto3.getEnumType(ActuatorDiskOrientationSelection) },
    { no: 66048, name: "actuator_disk_rotation_angle", kind: "message", T: AdVector3 },
    { no: 26533, name: "actuator_disk_normal_vector", kind: "message", T: AdVector3 },
    { no: 38264, name: "particle_positions_table", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32417, name: "source_particle_radius", kind: "message", T: AdFloatType },
    { no: 48131, name: "search_radius_scale_factor", kind: "message", T: AdFloatType },
    { no: 65362, name: "isotropic_gaussian_scale_factor", kind: "message", T: AdFloatType },
  ],
);

/**
 * Monitor plane.
 *
 * @generated from message luminary.proto.client.MonitorPlane
 */
export const MonitorPlane = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.MonitorPlane",
  () => [
    { no: 50843, name: "monitor_plane_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 53011, name: "monitor_plane_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 56529, name: "monitor_plane_point", kind: "message", T: AdVector3 },
    { no: 4128, name: "monitor_plane_normal", kind: "message", T: AdVector3 },
    { no: 53158, name: "monitor_plane_box_clip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 65193, name: "monitor_plane_clip_center", kind: "message", T: AdVector3 },
    { no: 48940, name: "monitor_plane_clip_size", kind: "message", T: AdVector3 },
    { no: 14264, name: "monitor_plane_clip_rotation", kind: "message", T: AdVector3 },
    { no: 46990, name: "monitor_plane_volume_clip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 56758, name: "monitor_plane_volumes", kind: "message", T: EntityIdentifier, repeated: true },
  ],
);

/**
 * Body frame.
 *
 * @generated from message luminary.proto.client.BodyFrame
 */
export const BodyFrame = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.BodyFrame",
  () => [
    { no: 55493, name: "body_frame_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Surface name map.
 *
 * @generated from message luminary.proto.client.SurfaceName
 */
export const SurfaceName = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.SurfaceName",
  () => [
    { no: 40160, name: "surface_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Solution output settings.
 *
 * @generated from message luminary.proto.client.Output
 */
export const Output = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.Output",
  () => [
    { no: 57861, name: "iters_per_output", kind: "message", T: Int },
    { no: 55708, name: "debug_output", kind: "enum", T: proto3.getEnumType(DebugOutput) },
    { no: 37206, name: "debug_output_interior_surface_data", kind: "enum", T: proto3.getEnumType(DebugOutputInteriorSurfaceData) },
  ],
);

/**
 * TODO: no help
 *
 * @generated from message luminary.proto.client.ConvergenceCriteria
 */
export const ConvergenceCriteria = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.ConvergenceCriteria",
  () => [
    { no: 15175, name: "stopping_condition", kind: "message", T: StoppingCondition, repeated: true },
    { no: 1841, name: "time_step_stopping_condition", kind: "message", T: StoppingCondition, repeated: true },
    { no: 34622, name: "entity_group", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: EntityGroup} },
  ],
);

/**
 * Tracks volume entity(1) -> material entity(1) relationship.
 *
 * @generated from message luminary.proto.client.VolumeMaterialRelationship
 */
export const VolumeMaterialRelationship = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.VolumeMaterialRelationship",
  () => [
    { no: 20638, name: "volume_identifier", kind: "message", T: EntityIdentifier },
    { no: 65313, name: "material_identifier", kind: "message", T: EntityIdentifier },
  ],
);

/**
 * Tracks volume entity(1) -> physics entity(1) relationship.
 *
 * @generated from message luminary.proto.client.VolumePhysicsRelationship
 */
export const VolumePhysicsRelationship = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.VolumePhysicsRelationship",
  () => [
    { no: 20638, name: "volume_identifier", kind: "message", T: EntityIdentifier },
    { no: 3378, name: "physics_identifier", kind: "message", T: EntityIdentifier },
  ],
);

/**
 * Relationships between different entities.
 *
 * @generated from message luminary.proto.client.EntityRelationships
 */
export const EntityRelationships = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.EntityRelationships",
  () => [
    { no: 37900, name: "volume_material_relationship", kind: "message", T: VolumeMaterialRelationship, repeated: true },
    { no: 49135, name: "volume_physics_relationship", kind: "message", T: VolumePhysicsRelationship, repeated: true },
  ],
);

/**
 * Define reference values needed for computing forces and moments.
 *
 * @generated from message luminary.proto.client.ReferenceValues
 */
export const ReferenceValues = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.ReferenceValues",
  () => [
    { no: 44547, name: "area_ref", kind: "message", T: AdFloatType },
    { no: 4444, name: "length_ref", kind: "message", T: AdFloatType },
    { no: 39901, name: "use_aero_moment_ref_lengths", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4449, name: "length_ref_pitch", kind: "message", T: AdFloatType },
    { no: 1924, name: "length_ref_roll", kind: "message", T: AdFloatType },
    { no: 60759, name: "length_ref_yaw", kind: "message", T: AdFloatType },
    { no: 50604, name: "p_ref", kind: "message", T: AdFloatType },
    { no: 57686, name: "t_ref", kind: "message", T: AdFloatType },
    { no: 65971, name: "v_ref", kind: "message", T: AdFloatType },
  ],
);

/**
 * TODO: no help
 *
 * @generated from message luminary.proto.client.BoundaryLayerProfile
 */
export const BoundaryLayerProfile = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.BoundaryLayerProfile",
  () => [
    { no: 36231, name: "n_layers", kind: "message", T: Int },
    { no: 33837, name: "initial_size", kind: "message", T: AdFloatType },
    { no: 47428, name: "growth_rate", kind: "message", T: AdFloatType },
    { no: 3525, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * Adaptive Mesh Refinement
 *
 * @generated from message luminary.proto.client.AdaptiveMeshRefinement
 */
export const AdaptiveMeshRefinement = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.AdaptiveMeshRefinement",
  () => [
    { no: 20259, name: "refinement_iterations", kind: "message", T: Int },
    { no: 1601, name: "refinement_dispatch_interval", kind: "message", T: Int },
    { no: 20535, name: "max_refinement_interval", kind: "message", T: Int },
    { no: 50823, name: "initial_target_complexity", kind: "message", T: AdFloatType },
    { no: 41667, name: "final_target_complexity", kind: "message", T: AdFloatType },
    { no: 23502, name: "meshing_method", kind: "enum", T: proto3.getEnumType(MeshingMethod) },
    { no: 50427, name: "target_cv_millions", kind: "message", T: Int },
    { no: 45801, name: "user_scaling", kind: "message", T: AdFloatType },
    { no: 8631, name: "boundary_layer_profile", kind: "message", T: BoundaryLayerProfile, repeated: true },
  ],
);

/**
 * Solution controls for a heat transfer physics solver.
 *
 * @generated from message luminary.proto.client.SolutionControlsHeat
 */
export const SolutionControlsHeat = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.SolutionControlsHeat",
  () => [
    { no: 58560, name: "relaxation_method", kind: "enum", T: proto3.getEnumType(RelaxationMethod) },
    { no: 66223, name: "implicit_method", kind: "enum", T: proto3.getEnumType(ImplicitMethod) },
    { no: 4402, name: "explicit_method", kind: "enum", T: proto3.getEnumType(ExplicitMethod) },
    { no: 64390, name: "linear_solver_type", kind: "enum", T: proto3.getEnumType(LinearSolverType) },
    { no: 10171, name: "linsol_iterations", kind: "message", T: Int },
    { no: 4037, name: "linsol_tolerance", kind: "message", T: AdFloatType },
    { no: 45398, name: "linsol_amg_pre_sweeps", kind: "message", T: Int },
    { no: 60527, name: "linsol_amg_post_sweeps", kind: "message", T: Int },
    { no: 30151, name: "linsol_amg_coarsening_size", kind: "message", T: Int },
    { no: 47752, name: "linsol_amg_levels", kind: "message", T: Int },
    { no: 25063, name: "linsol_amg_relaxation", kind: "message", T: AdFloatType },
    { no: 28904, name: "linsol_amg_cycle_type", kind: "enum", T: proto3.getEnumType(LinsolAmgCycleType) },
    { no: 9555, name: "linsol_amg_freeze_levels_threshold", kind: "message", T: Int },
    { no: 63515, name: "linsol_amg_smoother", kind: "enum", T: proto3.getEnumType(LinsolAmgSmoother) },
    { no: 56787, name: "controls_preset_version", kind: "message", T: Int },
    { no: 60554, name: "solution_controls_heat_preset", kind: "enum", T: proto3.getEnumType(SolutionControlsHeatPreset) },
  ],
);

/**
 * TODO: no help
 *
 * @generated from message luminary.proto.client.AdjointControlsHeat
 */
export const AdjointControlsHeat = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.AdjointControlsHeat",
  () => [
    { no: 4437, name: "adjoint_solution_method", kind: "enum", T: proto3.getEnumType(AdjointSolutionMethod) },
    { no: 15680, name: "adjoint_gmres_restart_iters", kind: "message", T: Int },
  ],
);

/**
 * Spatial discretization settings for a heat transfer physics solver.
 *
 * @generated from message luminary.proto.client.SpatialDiscretizationHeat
 */
export const SpatialDiscretizationHeat = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.SpatialDiscretizationHeat",
  () => [
    { no: 63007, name: "gradient_method", kind: "enum", T: proto3.getEnumType(GradientMethod) },
    { no: 33148, name: "hlsq_blend", kind: "message", T: AdFloatType },
    { no: 56210, name: "geometry_fixes", kind: "enum", T: proto3.getEnumType(GeometryFixes) },
    { no: 29873, name: "geometry_fixes_mitigations", kind: "message", T: AdFloatType },
    { no: 61478, name: "discretization_preset_version", kind: "message", T: Int },
    { no: 2213, name: "spatial_discretization_heat_preset", kind: "enum", T: proto3.getEnumType(SpatialDiscretizationHeatPreset) },
  ],
);

/**
 * Boundary conditions for a heat transfer physics solver.
 *
 * @generated from message luminary.proto.client.BoundaryConditionsHeat
 */
export const BoundaryConditionsHeat = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.BoundaryConditionsHeat",
  () => [
    { no: 56902, name: "profile_bc_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 46213, name: "profile_bc", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22295, name: "profile_type", kind: "enum", T: proto3.getEnumType(ProfileType) },
    { no: 13024, name: "heat_flux_col", kind: "message", T: Int },
    { no: 20742, name: "boundary_condition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10456, name: "boundary_condition_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36859, name: "boundary_condition_interface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3525, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 63683, name: "heat_physical_boundary", kind: "enum", T: proto3.getEnumType(HeatPhysicalBoundary) },
    { no: 64947, name: "heat_transfer_coefficient", kind: "message", T: AdFloatType },
    { no: 5733, name: "fixed_heat_flux", kind: "message", T: AdFloatType },
    { no: 20007, name: "fixed_integrated_heat_flux", kind: "message", T: AdFloatType },
    { no: 55825, name: "fixed_temperature", kind: "message", T: AdFloatType },
  ],
);

/**
 * Initialization settings for a heat transfer physics solver.
 *
 * @generated from message luminary.proto.client.InitializationHeat
 */
export const InitializationHeat = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.InitializationHeat",
  () => [
    { no: 31006, name: "initialization_type", kind: "enum", T: proto3.getEnumType(InitializationType) },
    { no: 9180, name: "existing_solution_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 60245, name: "existing_solution_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20600, name: "uniform_t", kind: "message", T: AdFloatType },
  ],
);

/**
 * TODO: no help
 *
 * @generated from message luminary.proto.client.HeatSource
 */
export const HeatSource = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.HeatSource",
  () => [
    { no: 10251, name: "profile_source_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 45289, name: "profile_source", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22295, name: "profile_type", kind: "enum", T: proto3.getEnumType(ProfileType) },
    { no: 11314, name: "heat_source_col", kind: "message", T: Int },
    { no: 4368, name: "heat_source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13550, name: "heat_source_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50486, name: "heat_source_type", kind: "enum", T: proto3.getEnumType(HeatSourceType) },
    { no: 51055, name: "heat_source_zone_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20164, name: "heat_source_power", kind: "message", T: AdFloatType },
    { no: 1483, name: "heat_source_power_per_unit_volume", kind: "message", T: AdFloatType },
  ],
);

/**
 * Defines the two sides that form a sliding interface. The two
 * sides are abutting, possibly in combination with a periodic transformation,
 * and should have the same normal grid velocity. The tangential grid velocity
 * can, and usually will, be different. Each side of the sliding interface can
 * be composed out of an arbitrary number of surfaces.
 *
 * @generated from message luminary.proto.client.SlidingInterfaces
 */
export const SlidingInterfaces = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.SlidingInterfaces",
  () => [
    { no: 39043, name: "sliding_interface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40557, name: "sliding_interface_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 59921, name: "sliding_a", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 27792, name: "sliding_b", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 33374, name: "sliding_matching_translation_transform", kind: "message", T: AdVector3 },
    { no: 11563, name: "sliding_matching_periodic_rotation_angles", kind: "message", T: AdVector3 },
    { no: 50745, name: "sliding_matching_periodic_center_of_rotation", kind: "message", T: AdVector3 },
    { no: 12200, name: "interface_type", kind: "enum", T: proto3.getEnumType(InterfaceType) },
  ],
);

/**
 * Defines the input options needed for periodic boundaries. We assume each
 * periodic BC can have translational OR rotational periodicity. To transform
 * a point on boundary A to its periodically matching point on boundary B, we first
 * subtract the center of rotation from the point coordinates to get the distance vector
 * from the center to the point of interest, then we apply rotation around the periodicity
 * axis and add back the center of rotation to get the coordinates of the transformed point.
 *
 * @generated from message luminary.proto.client.PeriodicPair
 */
export const PeriodicPair = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.PeriodicPair",
  () => [
    { no: 14322, name: "periodic_pair_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14165, name: "bound_a", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7076, name: "bound_b", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 23247, name: "periodic_bc_type", kind: "enum", T: proto3.getEnumType(PeriodicBcType) },
    { no: 20315, name: "translational", kind: "message", T: AdFloatType },
    { no: 12868, name: "periodic_translation", kind: "message", T: AdVector3 },
    { no: 11946, name: "periodic_center_of_rotation", kind: "message", T: AdVector3 },
    { no: 40976, name: "periodic_rotation_angles", kind: "message", T: AdVector3 },
  ],
);

/**
 * Configuration for a heat transfer physics solver - simulates heat transfer in solid media.
 *
 * @generated from message luminary.proto.client.Heat
 */
export const Heat = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.Heat",
  () => [
    { no: 23708, name: "solution_controls_heat", kind: "message", T: SolutionControlsHeat },
    { no: 65793, name: "adjoint_controls_heat", kind: "message", T: AdjointControlsHeat },
    { no: 13222, name: "spatial_discretization_heat", kind: "message", T: SpatialDiscretizationHeat },
    { no: 32033, name: "boundary_conditions_heat", kind: "message", T: BoundaryConditionsHeat, repeated: true },
    { no: 23696, name: "initialization_heat", kind: "message", T: InitializationHeat },
    { no: 38413, name: "heat_source", kind: "message", T: HeatSource, repeated: true },
    { no: 62254, name: "sliding_interfaces", kind: "message", T: SlidingInterfaces, repeated: true },
    { no: 65772, name: "periodic_pair", kind: "message", T: PeriodicPair, repeated: true },
  ],
);

/**
 * Basic settings for a fluid flow physics solver.
 *
 * @generated from message luminary.proto.client.BasicFluid
 */
export const BasicFluid = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.BasicFluid",
  () => [
    { no: 39794, name: "fluid_type", kind: "enum", T: proto3.getEnumType(FluidType) },
    { no: 16619, name: "viscous_model", kind: "enum", T: proto3.getEnumType(ViscousModel) },
  ],
);

/**
 * Turbulence settings for a fluid flow physics solver.
 *
 * @generated from message luminary.proto.client.Turbulence
 */
export const Turbulence = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.Turbulence",
  () => [
    { no: 2811, name: "des_formulation", kind: "enum", T: proto3.getEnumType(DesFormulation) },
    { no: 24914, name: "rans_region", kind: "enum", T: proto3.getEnumType(RansRegion) },
    { no: 28692, name: "zdes_rmin", kind: "message", T: AdVector3 },
    { no: 3921, name: "zdes_rmax", kind: "message", T: AdVector3 },
    { no: 3479, name: "sub_grid_scale_model", kind: "enum", T: proto3.getEnumType(SubGridScaleModel) },
    { no: 57832, name: "c_s", kind: "message", T: AdFloatType },
    { no: 36294, name: "c_vreman", kind: "message", T: AdFloatType },
    { no: 7682, name: "c_wale", kind: "message", T: AdFloatType },
    { no: 31545, name: "c_sigma", kind: "message", T: AdFloatType },
    { no: 41972, name: "c_amd", kind: "message", T: AdFloatType },
    { no: 22626, name: "turbulent_thermal_conductivity_prandtl_constant", kind: "message", T: AdFloatType },
    { no: 36142, name: "transition_model", kind: "enum", T: proto3.getEnumType(TransitionModel) },
    { no: 48135, name: "transition_model_cross_flow", kind: "enum", T: proto3.getEnumType(TransitionModelCrossFlow) },
    { no: 10495, name: "transition_free_stream_turbulence_intensity", kind: "message", T: AdFloatType },
    { no: 38201, name: "turbulence_model_constants", kind: "enum", T: proto3.getEnumType(TurbulenceModelConstants) },
    { no: 29392, name: "turbulence_model", kind: "enum", T: proto3.getEnumType(TurbulenceModel) },
    { no: 5075, name: "c_b_1", kind: "message", T: AdFloatType },
    { no: 14559, name: "sigma", kind: "message", T: AdFloatType },
    { no: 62902, name: "c_b_2", kind: "message", T: AdFloatType },
    { no: 27185, name: "kappa", kind: "message", T: AdFloatType },
    { no: 50313, name: "c_w_2", kind: "message", T: AdFloatType },
    { no: 23269, name: "c_w_3", kind: "message", T: AdFloatType },
    { no: 54413, name: "c_v_1", kind: "message", T: AdFloatType },
    { no: 42644, name: "c_t_3", kind: "message", T: AdFloatType },
    { no: 54016, name: "c_t_4", kind: "message", T: AdFloatType },
    { no: 4079, name: "c_r_1", kind: "message", T: AdFloatType },
    { no: 37059, name: "C_sa_des", kind: "message", T: AdFloatType },
    { no: 27869, name: "qcr_sa", kind: "enum", T: proto3.getEnumType(QcrSa) },
    { no: 40452, name: "rotation_correction_sa", kind: "enum", T: proto3.getEnumType(RotationCorrectionSa) },
    { no: 39611, name: "c_rot", kind: "message", T: AdFloatType },
    { no: 50152, name: "sigma_k_1", kind: "message", T: AdFloatType },
    { no: 33553, name: "sigma_k_2", kind: "message", T: AdFloatType },
    { no: 22346, name: "sigma_w_1", kind: "message", T: AdFloatType },
    { no: 64043, name: "sigma_w_2", kind: "message", T: AdFloatType },
    { no: 50080, name: "beta_1", kind: "message", T: AdFloatType },
    { no: 55710, name: "beta_2", kind: "message", T: AdFloatType },
    { no: 50108, name: "beta_star", kind: "message", T: AdFloatType },
    { no: 35575, name: "kappa_sst", kind: "message", T: AdFloatType },
    { no: 5159, name: "a_1", kind: "message", T: AdFloatType },
    { no: 45716, name: "gamma_1", kind: "message", T: AdFloatType },
    { no: 13270, name: "gamma_2", kind: "message", T: AdFloatType },
    { no: 2789, name: "C_sst_des1", kind: "message", T: AdFloatType },
    { no: 21116, name: "C_sst_des2", kind: "message", T: AdFloatType },
    { no: 40065, name: "qcr_sst", kind: "enum", T: proto3.getEnumType(QcrSst) },
  ],
);

/**
 * Solution controls for a fluid flow physics solver.
 *
 * @generated from message luminary.proto.client.SolutionControlsFluid
 */
export const SolutionControlsFluid = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.SolutionControlsFluid",
  () => [
    { no: 4496, name: "pseudo_time_step_method", kind: "enum", T: proto3.getEnumType(PseudoTimeStepMethod) },
    { no: 34836, name: "cfl", kind: "message", T: AdFloatType },
    { no: 49104, name: "pseudo_time_step_val", kind: "message", T: AdFloatType },
    { no: 59040, name: "local_time_stepping", kind: "enum", T: proto3.getEnumType(LocalTimeStepping) },
    { no: 58560, name: "relaxation_method", kind: "enum", T: proto3.getEnumType(RelaxationMethod) },
    { no: 66223, name: "implicit_method", kind: "enum", T: proto3.getEnumType(ImplicitMethod) },
    { no: 4402, name: "explicit_method", kind: "enum", T: proto3.getEnumType(ExplicitMethod) },
    { no: 64390, name: "linear_solver_type", kind: "enum", T: proto3.getEnumType(LinearSolverType) },
    { no: 10171, name: "linsol_iterations", kind: "message", T: Int },
    { no: 4037, name: "linsol_tolerance", kind: "message", T: AdFloatType },
    { no: 45398, name: "linsol_amg_pre_sweeps", kind: "message", T: Int },
    { no: 60527, name: "linsol_amg_post_sweeps", kind: "message", T: Int },
    { no: 30151, name: "linsol_amg_coarsening_size", kind: "message", T: Int },
    { no: 47752, name: "linsol_amg_levels", kind: "message", T: Int },
    { no: 25063, name: "linsol_amg_relaxation", kind: "message", T: AdFloatType },
    { no: 28904, name: "linsol_amg_cycle_type", kind: "enum", T: proto3.getEnumType(LinsolAmgCycleType) },
    { no: 9555, name: "linsol_amg_freeze_levels_threshold", kind: "message", T: Int },
    { no: 63515, name: "linsol_amg_smoother", kind: "enum", T: proto3.getEnumType(LinsolAmgSmoother) },
    { no: 61281, name: "jacobian_update_method", kind: "enum", T: proto3.getEnumType(JacobianUpdateMethod) },
    { no: 58635, name: "jacobian_update_interval", kind: "message", T: AdFloatType },
    { no: 11315, name: "jacobian_warmup_threshold", kind: "message", T: Int },
    { no: 37032, name: "robust_startup", kind: "enum", T: proto3.getEnumType(RobustStartup) },
    { no: 15234, name: "robust_startup_initial_cfl", kind: "message", T: AdFloatType },
    { no: 5585, name: "robust_startup_iterations", kind: "message", T: Int },
    { no: 23154, name: "relax_flow", kind: "message", T: AdFloatType },
    { no: 49710, name: "relax_turb", kind: "message", T: AdFloatType },
    { no: 54626, name: "update_limit_flow", kind: "message", T: AdFloatType },
    { no: 36998, name: "update_limit_turb", kind: "message", T: AdFloatType },
    { no: 56787, name: "controls_preset_version", kind: "message", T: Int },
    { no: 53460, name: "solution_controls_fluid_preset", kind: "enum", T: proto3.getEnumType(SolutionControlsFluidPreset) },
  ],
);

/**
 * TODO: no help
 *
 * @generated from message luminary.proto.client.AdjointControlsFluid
 */
export const AdjointControlsFluid = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.AdjointControlsFluid",
  () => [
    { no: 4437, name: "adjoint_solution_method", kind: "enum", T: proto3.getEnumType(AdjointSolutionMethod) },
    { no: 15680, name: "adjoint_gmres_restart_iters", kind: "message", T: Int },
    { no: 28888, name: "adjoint_second_order_damping", kind: "message", T: AdFloatType },
    { no: 9304, name: "adjoint_frozen_turbulence", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35318, name: "adjoint_frozen_transition", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Spatial discretization settings for a fluid flow physics solver.
 *
 * @generated from message luminary.proto.client.SpatialDiscretizationFluid
 */
export const SpatialDiscretizationFluid = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.SpatialDiscretizationFluid",
  () => [
    { no: 63007, name: "gradient_method", kind: "enum", T: proto3.getEnumType(GradientMethod) },
    { no: 33148, name: "hlsq_blend", kind: "message", T: AdFloatType },
    { no: 49440, name: "convective_schemes_density_based", kind: "enum", T: proto3.getEnumType(ConvectiveSchemesDensityBased) },
    { no: 49334, name: "entropy_epsilon", kind: "message", T: AdFloatType },
    { no: 7044, name: "alpha_hybrid", kind: "message", T: AdFloatType },
    { no: 11007, name: "skew_symmetric_formulation", kind: "enum", T: proto3.getEnumType(SkewSymmetricFormulation) },
    { no: 8224, name: "artificial_viscosity_model", kind: "enum", T: proto3.getEnumType(ArtificialViscosityModel) },
    { no: 3652, name: "upwind_scheme_order", kind: "enum", T: proto3.getEnumType(UpwindSchemeOrder) },
    { no: 57544, name: "robust_dissipation", kind: "enum", T: proto3.getEnumType(RobustDissipation) },
    { no: 52346, name: "limiter", kind: "enum", T: proto3.getEnumType(Limiter) },
    { no: 59115, name: "limiter_kappa", kind: "message", T: AdFloatType },
    { no: 30347, name: "umuscl_chi", kind: "message", T: AdFloatType },
    { no: 38381, name: "alpha_ld2", kind: "message", T: AdFloatType },
    { no: 56210, name: "geometry_fixes", kind: "enum", T: proto3.getEnumType(GeometryFixes) },
    { no: 29873, name: "geometry_fixes_mitigations", kind: "message", T: AdFloatType },
    { no: 37908, name: "order_blend", kind: "message", T: AdFloatType },
    { no: 4422, name: "preconditioning", kind: "enum", T: proto3.getEnumType(Preconditioning) },
    { no: 61478, name: "discretization_preset_version", kind: "message", T: Int },
    { no: 30665, name: "spatial_discretization_fluid_preset", kind: "enum", T: proto3.getEnumType(SpatialDiscretizationFluidPreset) },
    { no: 14588, name: "fds_min_low_dissipation", kind: "message", T: AdFloatType },
  ],
);

/**
 * Boundary conditions for a fluid flow physics solver.
 *
 * @generated from message luminary.proto.client.BoundaryConditionsFluid
 */
export const BoundaryConditionsFluid = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.BoundaryConditionsFluid",
  () => [
    { no: 3525, name: "surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20742, name: "boundary_condition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10456, name: "boundary_condition_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36859, name: "boundary_condition_interface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 56902, name: "profile_bc_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 46213, name: "profile_bc", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22295, name: "profile_type", kind: "enum", T: proto3.getEnumType(ProfileType) },
    { no: 60292, name: "physical_boundary", kind: "enum", T: proto3.getEnumType(PhysicalBoundary) },
    { no: 39318, name: "wall_momentum", kind: "enum", T: proto3.getEnumType(WallMomentum) },
    { no: 486, name: "roughness_control", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 47205, name: "equivalent_sand_grain_roughness", kind: "message", T: AdFloatType },
    { no: 20007, name: "fixed_integrated_heat_flux", kind: "message", T: AdFloatType },
    { no: 64947, name: "heat_transfer_coefficient", kind: "message", T: AdFloatType },
    { no: 31507, name: "wall_energy", kind: "enum", T: proto3.getEnumType(WallEnergy) },
    { no: 5733, name: "fixed_heat_flux", kind: "message", T: AdFloatType },
    { no: 55825, name: "fixed_temperature", kind: "message", T: AdFloatType },
    { no: 50007, name: "wall_movement_translation", kind: "message", T: AdVector3 },
    { no: 27882, name: "wall_movement_rotation_center", kind: "message", T: AdVector3 },
    { no: 13572, name: "wall_movement_angular_velocity", kind: "message", T: AdVector3 },
    { no: 48391, name: "inlet_momentum", kind: "enum", T: proto3.getEnumType(InletMomentum) },
    { no: 59408, name: "inlet_velocity_magnitude", kind: "message", T: AdFloatType },
    { no: 8823, name: "inlet_velocity_magnitude_col", kind: "message", T: Int },
    { no: 65674, name: "direction_specification", kind: "enum", T: proto3.getEnumType(DirectionSpecification) },
    { no: 40433, name: "flow_direction", kind: "message", T: AdVector3 },
    { no: 58653, name: "inlet_velocity_components", kind: "message", T: AdVector3 },
    { no: 51712, name: "total_pressure", kind: "message", T: AdFloatType },
    { no: 8030, name: "total_pressure_col", kind: "message", T: Int },
    { no: 42329, name: "mass_flow_rate", kind: "message", T: AdFloatType },
    { no: 4597, name: "fan_curve_table_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10187, name: "head_loss_coefficient", kind: "message", T: AdFloatType },
    { no: 1769, name: "farfield_mach_number", kind: "message", T: AdFloatType },
    { no: 12144, name: "farfield_pressure", kind: "message", T: AdFloatType },
    { no: 63504, name: "farfield_temperature", kind: "message", T: AdFloatType },
    { no: 20180, name: "inlet_energy", kind: "enum", T: proto3.getEnumType(InletEnergy) },
    { no: 34783, name: "total_temperature", kind: "message", T: AdFloatType },
    { no: 15478, name: "total_temperature_col", kind: "message", T: Int },
    { no: 57931, name: "turbulence_specification_spalart_allmaras", kind: "enum", T: proto3.getEnumType(TurbulenceSpecificationSpalartAllmaras) },
    { no: 38905, name: "turbulent_viscosity", kind: "message", T: AdFloatType },
    { no: 46276, name: "turbulent_viscosity_ratio", kind: "message", T: AdFloatType },
    { no: 61212, name: "bc_uniform_nu_tilde", kind: "message", T: AdFloatType },
    { no: 57932, name: "bc_nu_tilde_col", kind: "message", T: Int },
    { no: 34556, name: "turbulence_specification_komega", kind: "enum", T: proto3.getEnumType(TurbulenceSpecificationKomega) },
    { no: 52235, name: "turbulence_intensity", kind: "message", T: AdFloatType },
    { no: 9612, name: "bc_uniform_tke", kind: "message", T: AdFloatType },
    { no: 15841, name: "bc_uniform_omega", kind: "message", T: AdFloatType },
    { no: 61786, name: "bc_tke_col", kind: "message", T: Int },
    { no: 34393, name: "bc_omega_col", kind: "message", T: Int },
    { no: 61460, name: "outlet_strategy", kind: "enum", T: proto3.getEnumType(OutletStrategy) },
    { no: 62393, name: "outlet_pressure", kind: "message", T: AdFloatType },
    { no: 3251, name: "outlet_target_mass_flow_rate", kind: "message", T: AdFloatType },
    { no: 21126, name: "outlet_reference_pressure", kind: "message", T: AdFloatType },
    { no: 52072, name: "outlet_reference_temperature", kind: "message", T: AdFloatType },
    { no: 54786, name: "outlet_pressure_constraint", kind: "enum", T: proto3.getEnumType(OutletPressureConstraint) },
    { no: 63708, name: "farfield_velocity_magnitude", kind: "message", T: AdFloatType },
    { no: 42757, name: "far_field_flow_direction_specification", kind: "enum", T: proto3.getEnumType(FarFieldFlowDirectionSpecification) },
    { no: 59095, name: "farfield_flow_direction", kind: "message", T: AdVector3 },
    { no: 59601, name: "farfield_angle_alpha", kind: "message", T: AdFloatType },
    { no: 35349, name: "farfield_angle_beta", kind: "message", T: AdFloatType },
  ],
);

/**
 * Initialization settings for a fluid flow physics solver.
 *
 * @generated from message luminary.proto.client.InitializationFluid
 */
export const InitializationFluid = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.InitializationFluid",
  () => [
    { no: 31006, name: "initialization_type", kind: "enum", T: proto3.getEnumType(InitializationType) },
    { no: 9180, name: "existing_solution_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 60245, name: "existing_solution_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 62660, name: "verification_solutions", kind: "enum", T: proto3.getEnumType(VerificationSolutions) },
    { no: 2195, name: "potential_flow_pressure_initialization", kind: "enum", T: proto3.getEnumType(PotentialFlowPressureInitialization) },
    { no: 52804, name: "uniform_p", kind: "message", T: AdFloatType },
    { no: 34087, name: "uniform_v", kind: "message", T: AdVector3 },
    { no: 20600, name: "uniform_t", kind: "message", T: AdFloatType },
    { no: 4613, name: "turbulent_variable_initialization_type_sa", kind: "enum", T: proto3.getEnumType(TurbulentVariableInitializationTypeSa) },
    { no: 10007, name: "turbulent_variable_initialization_type_komega", kind: "enum", T: proto3.getEnumType(TurbulentVariableInitializationTypeKomega) },
    { no: 37344, name: "init_turbulent_viscosity_ratio", kind: "message", T: AdFloatType },
    { no: 42365, name: "init_turbulent_viscosity", kind: "message", T: AdFloatType },
    { no: 61598, name: "init_turbulence_intensity", kind: "message", T: AdFloatType },
    { no: 48786, name: "uniform_nu_tilde", kind: "message", T: AdFloatType },
    { no: 40387, name: "uniform_tke", kind: "message", T: AdFloatType },
    { no: 26587, name: "uniform_omega", kind: "message", T: AdFloatType },
    { no: 34718, name: "tgv_rho", kind: "message", T: AdFloatType },
    { no: 20871, name: "tgv_p", kind: "message", T: AdFloatType },
    { no: 52567, name: "tgv_v", kind: "message", T: AdFloatType },
    { no: 2712, name: "shocktube_p1", kind: "message", T: AdFloatType },
    { no: 48890, name: "shocktube_p4", kind: "message", T: AdFloatType },
    { no: 54109, name: "shocktube_t1", kind: "message", T: AdFloatType },
    { no: 48956, name: "shocktube_t4", kind: "message", T: AdFloatType },
    { no: 23766, name: "shuosher_p1", kind: "message", T: AdFloatType },
    { no: 25082, name: "shuosher_rho1", kind: "message", T: AdFloatType },
    { no: 8138, name: "shuosher_m1", kind: "message", T: AdFloatType },
    { no: 30109, name: "shuosher_p2", kind: "message", T: AdFloatType },
    { no: 54158, name: "shuosher_rho2", kind: "message", T: AdFloatType },
    { no: 43331, name: "shuosher_m2", kind: "message", T: AdFloatType },
    { no: 9071, name: "shuosher_amp", kind: "message", T: AdFloatType },
    { no: 22253, name: "shuosher_kx", kind: "message", T: AdFloatType },
    { no: 8586, name: "disturbancewave_p", kind: "message", T: AdFloatType },
    { no: 24730, name: "disturbancewave_t", kind: "message", T: AdFloatType },
    { no: 56365, name: "disturbancewave_m", kind: "message", T: AdFloatType },
    { no: 57064, name: "acousticwave_amp", kind: "message", T: AdFloatType },
    { no: 57170, name: "vorticitywave_amp", kind: "message", T: AdFloatType },
    { no: 5446, name: "entropywave_amp", kind: "message", T: AdFloatType },
    { no: 44686, name: "disturbancewave_kx", kind: "message", T: AdFloatType },
    { no: 61711, name: "normalshock_p1", kind: "message", T: AdFloatType },
    { no: 9540, name: "normalshock_t1", kind: "message", T: AdFloatType },
    { no: 54018, name: "normalshock_m1", kind: "message", T: AdFloatType },
    { no: 30903, name: "shockvortex_p1", kind: "message", T: AdFloatType },
    { no: 14617, name: "shockvortex_t1", kind: "message", T: AdFloatType },
    { no: 61394, name: "shockvortex_m1", kind: "message", T: AdFloatType },
    { no: 4738, name: "shockvortex_mv", kind: "message", T: AdFloatType },
    { no: 27163, name: "shockvortex_xv", kind: "message", T: AdFloatType },
    { no: 42588, name: "shockvortex_zv", kind: "message", T: AdFloatType },
    { no: 52754, name: "shockvortex_av", kind: "message", T: AdFloatType },
    { no: 13070, name: "shockvortex_bv", kind: "message", T: AdFloatType },
    { no: 60212, name: "mms_k", kind: "message", T: AdFloatType },
    { no: 5127, name: "mms_a", kind: "message", T: AdFloatType },
    { no: 62870, name: "inviscid_vortex_minfty", kind: "message", T: AdFloatType },
    { no: 12017, name: "inviscid_vortex_pinfty", kind: "message", T: AdFloatType },
    { no: 2138, name: "inviscid_vortex_tinfty", kind: "message", T: AdFloatType },
    { no: 20123, name: "inviscid_vortex_xv", kind: "message", T: AdFloatType },
    { no: 46996, name: "inviscid_vortex_yv", kind: "message", T: AdFloatType },
    { no: 36352, name: "inviscid_vortex_xflowdir", kind: "message", T: AdFloatType },
    { no: 20498, name: "inviscid_vortex_yflowdir", kind: "message", T: AdFloatType },
    { no: 12667, name: "inviscid_vortex_rvortex", kind: "message", T: AdFloatType },
    { no: 1501, name: "inviscid_vortex_betavortex", kind: "message", T: AdFloatType },
  ],
);

/**
 * Construct a blade by assigning airfoil profiles to different radial stations
 *
 * @generated from message luminary.proto.client.BladeElementAirfoilData
 */
export const BladeElementAirfoilData = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.BladeElementAirfoilData",
  () => [
    { no: 49841, name: "airfoil_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30380, name: "airfoil_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9146, name: "airfoil_radial_station", kind: "message", T: AdFloatType },
    { no: 8266, name: "airfoil_performance_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Physical behavior settings for a fluid flow physics solver.
 *
 * @generated from message luminary.proto.client.PhysicalBehavior
 */
export const PhysicalBehavior = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.PhysicalBehavior",
  () => [
    { no: 65183, name: "physical_behavior_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20421, name: "physical_behavior_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15783, name: "physical_behavior_model", kind: "enum", T: proto3.getEnumType(PhysicalBehaviorModel) },
    { no: 7729, name: "actuator_disk_model", kind: "enum", T: proto3.getEnumType(ActuatorDiskModel) },
    { no: 21918, name: "actuator_line_model", kind: "enum", T: proto3.getEnumType(ActuatorLineModel) },
    { no: 35753, name: "particle_source_model", kind: "enum", T: proto3.getEnumType(ParticleSourceModel) },
    { no: 1771, name: "actuator_disk_thrust", kind: "message", T: AdFloatType },
    { no: 17603, name: "actuator_disk_torque", kind: "message", T: AdFloatType },
    { no: 14451, name: "actuator_disk_radial_force", kind: "message", T: AdFloatType },
    { no: 28203, name: "actuator_disk_rotation_rate", kind: "message", T: AdFloatType },
    { no: 12536, name: "actuator_disk_blade_count", kind: "message", T: Int },
    { no: 14811, name: "blade_element_pitch_schedule", kind: "message", T: AdVector3 },
    { no: 3013, name: "blade_element_flap_schedule", kind: "message", T: AdVector3 },
    { no: 29421, name: "blade_element_lag_schedule", kind: "message", T: AdVector3 },
    { no: 42019, name: "blade_element_geometry_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11371, name: "blade_element_reference_sound_speed", kind: "message", T: AdFloatType },
    { no: 4348, name: "actuator_disk_radial_table_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28715, name: "source_particle_acceleration_vector", kind: "message", T: AdVector3 },
    { no: 27900, name: "source_particle_mass_injection_rate", kind: "message", T: AdFloatType },
    { no: 51165, name: "blade_element_airfoil_data", kind: "message", T: BladeElementAirfoilData, repeated: true },
    { no: 4597, name: "fan_curve_table_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10187, name: "head_loss_coefficient", kind: "message", T: AdFloatType },
  ],
);

/**
 * Cavitation settings for a fluid flow physics solver.
 *
 * @generated from message luminary.proto.client.Cavitation
 */
export const Cavitation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.Cavitation",
  () => [
    { no: 14893, name: "cavitation_model", kind: "enum", T: proto3.getEnumType(CavitationModel) },
    { no: 41934, name: "density_vapor_phase_cavitation", kind: "message", T: AdFloatType },
    { no: 20164, name: "saturation_pressure_cavitation", kind: "message", T: AdFloatType },
    { no: 40978, name: "n0_sauer_schnerr", kind: "message", T: AdFloatType },
    { no: 51973, name: "n_reboud", kind: "message", T: AdFloatType },
  ],
);

/**
 * Porous model settings for a fluid flow physics solver.
 *
 * @generated from message luminary.proto.client.PorousBehavior
 */
export const PorousBehavior = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.PorousBehavior",
  () => [
    { no: 5997, name: "porous_behavior_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14303, name: "porous_behavior_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38142, name: "zone_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 42172, name: "porous_model_type", kind: "enum", T: proto3.getEnumType(PorousModelType) },
    { no: 63477, name: "darcy_coeff", kind: "message", T: AdVector3 },
    { no: 57960, name: "forchheimer_coeff", kind: "message", T: AdVector3 },
    { no: 51158, name: "porous_heat_source_power", kind: "message", T: AdFloatType },
    { no: 4125, name: "porous_heat_source_power_per_unit_volume", kind: "message", T: AdFloatType },
    { no: 45291, name: "ref_frame_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Configuration for a fluid flow physics solver - simulates the flow of liquids and gases.
 *
 * @generated from message luminary.proto.client.Fluid
 */
export const Fluid = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.Fluid",
  () => [
    { no: 14984, name: "basic_fluid", kind: "message", T: BasicFluid },
    { no: 22529, name: "turbulence", kind: "message", T: Turbulence },
    { no: 32378, name: "solution_controls_fluid", kind: "message", T: SolutionControlsFluid },
    { no: 13602, name: "adjoint_controls_fluid", kind: "message", T: AdjointControlsFluid },
    { no: 48561, name: "spatial_discretization_fluid", kind: "message", T: SpatialDiscretizationFluid },
    { no: 21469, name: "boundary_conditions_fluid", kind: "message", T: BoundaryConditionsFluid, repeated: true },
    { no: 62254, name: "sliding_interfaces", kind: "message", T: SlidingInterfaces, repeated: true },
    { no: 65772, name: "periodic_pair", kind: "message", T: PeriodicPair, repeated: true },
    { no: 33568, name: "initialization_fluid", kind: "message", T: InitializationFluid },
    { no: 10968, name: "physical_behavior", kind: "message", T: PhysicalBehavior, repeated: true },
    { no: 3243, name: "cavitation", kind: "message", T: Cavitation },
    { no: 36971, name: "porous_behavior", kind: "message", T: PorousBehavior, repeated: true },
  ],
);

/**
 * Single physics solver entity.
 *
 * @generated from message luminary.proto.client.Physics
 */
export const Physics = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.Physics",
  () => [
    { no: 3378, name: "physics_identifier", kind: "message", T: EntityIdentifier },
    { no: 23083, name: "heat", kind: "message", T: Heat, oneof: "params" },
    { no: 24070, name: "fluid", kind: "message", T: Fluid, oneof: "params" },
  ],
);

/**
 * Defines coupling solution strategies between different physics.
 *
 * @generated from message luminary.proto.client.MultiPhysicsCouplingOptions
 */
export const MultiPhysicsCouplingOptions = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.MultiPhysicsCouplingOptions",
  () => [
    { no: 4464, name: "mp_coupling_lin_sol_coupling", kind: "enum", T: proto3.getEnumType(MpCouplingLinSolCoupling) },
  ],
);

/**
 * Simulation configuration that supports multiple physics.
 *
 * @generated from message luminary.proto.client.SimulationParam
 */
export const SimulationParam = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.client.SimulationParam",
  () => [
    { no: 57099, name: "schema", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 51565, name: "input", kind: "message", T: Input },
    { no: 35464, name: "material_entity", kind: "message", T: MaterialEntity, repeated: true },
    { no: 5276, name: "volume_entity", kind: "message", T: VolumeEntity, repeated: true },
    { no: 4075, name: "general", kind: "message", T: General },
    { no: 31764, name: "adjoint", kind: "message", T: Adjoint },
    { no: 13153, name: "time", kind: "message", T: Time },
    { no: 53858, name: "motion_data", kind: "message", T: MotionData, repeated: true },
    { no: 1237, name: "particle_group", kind: "message", T: ParticleGroup, repeated: true },
    { no: 28042, name: "monitor_plane", kind: "message", T: MonitorPlane, repeated: true },
    { no: 2403, name: "body_frame", kind: "message", T: BodyFrame },
    { no: 40160, name: "surface_name", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SurfaceName} },
    { no: 3408, name: "table", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: RectilinearTable} },
    { no: 52068, name: "table_references", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Metadata} },
    { no: 57583, name: "output", kind: "message", T: Output },
    { no: 60954, name: "convergence_criteria", kind: "message", T: ConvergenceCriteria },
    { no: 11350, name: "entity_relationships", kind: "message", T: EntityRelationships },
    { no: 36530, name: "reference_values", kind: "message", T: ReferenceValues },
    { no: 23167, name: "adaptive_mesh_refinement", kind: "message", T: AdaptiveMeshRefinement },
    { no: 29740, name: "physics", kind: "message", T: Physics, repeated: true },
    { no: 629, name: "interfaces", kind: "message", T: SlidingInterfaces, repeated: true },
    { no: 2535, name: "mp_coupling_options", kind: "message", T: MultiPhysicsCouplingOptions },
  ],
);

