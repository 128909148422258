// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

/**
 * For a given node ID and panel name, this hook may be used to manage the expanded/collapsed state
 * of a CollapsiblePanel in a prop panel component.
 *
 * To manage a CollapsiblePanel named 'coordinates' that should be collapsed by default, invoke
 * the hook:
 *
 * const coordsPanel = useNodePanel(
 *   node.id,
 *   'coordinates',
 *   { tagsToExpand: [], defaultExpanded: false },
 * );
 *
 * Then use it in the JSX like so:
 *
 * <CollapsiblePanel
 *   collapsed={!coordsPanel.expanded}
 *   heading="Coordinates"
 *   onToggle={coordsPanel.toggle} />
 *
 * Options:
 *   - defaultExpanded: the initial state of the CollapsiblePanel
 *   - tagsToExpand: when a new item (e.g. a motion frame) is created in the control panel, a data
 *          structure representing the new node will be pushed to a stack in the nodeSession recoil
 *          state.  That data structure may contain tags that add color to the node.  When this
 *          hook (useNodePanel) detects a new node, it compares the new node data's tags to the
 *          tagsToExpand option to determine whether the panel should be expanded or not.
 */

import { useCallback, useEffect } from 'react';

import { PanelName, usePanel } from '../recoil/expandedPanels';
import { useNewNodes, useProcessedNewNode } from '../recoil/nodeSession';

import { isSuperset } from './lang';

export interface Options {
  tagsToExpand?: string[];
  defaultExpanded?: boolean;
  forceExpanded?: boolean;
}

export const useNodePanel = (nodeId: string, panelName: PanelName, options: Options = {}) => {
  const { defaultExpanded = true, forceExpanded, tagsToExpand } = options;

  const [processed, setProcessed] = useProcessedNewNode(nodeId);
  const [newNodes, setNewNodes] = useNewNodes();
  const [expanded, setExpanded] = usePanel({ nodeId, panelName, defaultExpanded });

  const subscriberKey = `use-node-panel-${panelName}`;

  useEffect(() => {
    // If a new node has been created matching our nodeId, set the initial expanded state based on
    // tags.
    const newNode = newNodes.find((item) => item.nodeId === nodeId);
    if (newNode && !processed.includes(subscriberKey)) {
      // If there's a newNode in the queue that hasn't yet been processed by this hook, then
      // initialize it.  If tagsToExpand is defined, compare it to newNode.tags to determine
      // whether the panel should be expanded;  otherwise, default to defaultValue.
      setExpanded(tagsToExpand ? isSuperset(newNode.tags, tagsToExpand) : defaultExpanded);
      setProcessed((prevKeys) => [...prevKeys, subscriberKey]);
    }
  }, [
    defaultExpanded,
    newNodes,
    nodeId,
    processed,
    subscriberKey,
    tagsToExpand,
    setExpanded,
    setNewNodes,
    setProcessed,
  ]);

  /*
    Synchronizes the expanded state with the `forceExpanded` value.
    If `forceExpanded` is defined, it updates the local `expanded` state.
  */
  useEffect(() => {
    if (forceExpanded === undefined) {
      return;
    }

    const frameId = requestAnimationFrame(() => {
      setExpanded(forceExpanded);
    });
    return () => cancelAnimationFrame(frameId);
  }, [forceExpanded, setExpanded]);

  const toggle = () => {
    setExpanded(!expanded);
  };

  const setCollapsed = useCallback(
    (newCollapsed: boolean | ((prevCollapsed: boolean) => boolean)) => {
      if (typeof newCollapsed === 'boolean') {
        setExpanded(!newCollapsed);
      } else {
        setExpanded(!newCollapsed(!expanded));
      }
    },
    [expanded, setExpanded],
  );

  return { expanded, setExpanded, collapsed: !expanded, setCollapsed, toggle };
};
