// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

// Fetches the server info and displays a banner if the page version is outdated. Users can then
// click on a href to reload the page and update the version.

import React, { useEffect, useState } from 'react';

import { Empty } from '@bufbuild/protobuf';

import * as RuntimeParams from '../lib/RuntimeParams';
import { Logger } from '../lib/observability/logs';
import * as rpc from '../lib/rpc';
import useServerInfo from '../recoil/useServerInfo';

import { createStyles, makeStyles } from './Theme';
import { Banner, BannerProps } from './notification/Banner';

const logger = new Logger('AppUpdateNotice');

const FETCH_INTERVAL = 15 * 1000; // 15 seconds

const useStyles = makeStyles(() => createStyles({
  statusContainer: {
    width: '100%',
    position: 'relative',
    z_index: 20,
  },
}), { name: 'AppUpdateNotice' });

export const AppUpdateNotice = () => {
  const classes = useStyles();
  const [bannerProp, setBannerProp] = useState<BannerProps>();
  const serverInfoInitial = useServerInfo();

  const localVersion = RuntimeParams.pageVersion;
  const [appUpdateNotice, setAppUpdateNoticeState] = useState(
    serverInfoInitial && serverInfoInitial.pageVersion !== localVersion,
  );

  useEffect(() => {
    if (__DEV__) {
      // With realend we mock the RuntimeParams, hence the app update notice would always be shown.
      return;
    }
    if (appUpdateNotice) {
      setBannerProp(
        {
          message: `A new version of the application is available. Please refresh the page to
          update.`,
          level: 'info',
          onDismiss: () => { },
          link: {
            label: 'Refresh',
            href: window.location.href,
            external: false,
          },
        },
      );
      return;
    }
    let didCancel = false;

    const fetchServerInfo = async () => {
      const client = rpc.clientServerInfo.serverInfo;
      rpc.callRetry('serverInfo', client, new Empty()).then((serverInfo) => {
        if (!didCancel) {
          setAppUpdateNoticeState(serverInfo.pageVersion !== localVersion);
        }
      }).catch((err) => logger.warn(err));
    };
    fetchServerInfo().catch((err) => logger.warn(err));

    const interval = setInterval(() => {
      fetchServerInfo().catch((err) => logger.warn(err));
    }, FETCH_INTERVAL);

    return () => {
      didCancel = true;
      clearInterval(interval);
    };
  }, [appUpdateNotice, localVersion]);

  return (
    <>
      <div className={classes.statusContainer}>
        {bannerProp && (
          <Banner
            {...bannerProp}
            key={bannerProp.message}
          />
        )}
      </div>
    </>
  );
};
