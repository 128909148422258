// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { UploadProgress } from '../../../../lib/UploadProgress';
import assert from '../../../../lib/assert';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { addRpcError } from '../../../../lib/transientNotification';
import { CAD_FILE_TYPES, DIRECTORY_FILE_TYPES, DISCRETE_GEOMETRY_FILE_TYPES, fileTypesToSelectOption } from '../../../../lib/upload/fileTypes';
import { isGeometryFile } from '../../../../lib/upload/uploadUtils';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as frontendpb from '../../../../proto/frontend/frontend_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import * as uploadpb from '../../../../proto/upload/upload_pb';
import { DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, createOrUpdateFeature, useGeometrySelectedFeature, useGeometryState, useSetGeometryState } from '../../../../recoil/geometry/geometryState';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import Tooltip from '../../../Tooltip';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import MeshImportDialog from '../../../project/MeshImportDialog';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

import Form from '@/components/Form';
import { NumberInput } from '@/components/Form/NumberInput';
import { CollapsiblePanel } from '@/components/Panel/CollapsiblePanel';
import Divider from '@/components/Theme/Divider';
import { SimpleSlider } from '@/components/controls/slider/SimpleSlider';
import { getAdValue, newAdFloat } from '@/lib/adUtils';

const FILE_TYPE_OPTIONS =
  fileTypesToSelectOption([...CAD_FILE_TYPES, ...DISCRETE_GEOMETRY_FILE_TYPES]);

export function getNameFromUrl(url: string) {
  const urlParams = new URLSearchParams(url);
  return urlParams.get('name') || 'Unknown Filename';
}

interface GeometryModificationParameterFormProps {
  param: geometrypb.Import['parameters'][number];
  onCommit: (value: number) => void;
  label: string;
}

const getParamValue = (param: geometrypb.Import['parameters'][number]) => {
  if (param.type.case !== 'float') {
    return null;
  }

  return param.type.value;
};

const GeometryModificationParameterForm = ({
  param,
  onCommit,
  label,
}: GeometryModificationParameterFormProps) => {
  const currentParam = getParamValue(param);
  const currentParamValue = getAdValue(currentParam?.adType);
  const [value, setValue] = useState(currentParamValue);

  // synchronize component state when param changes
  useEffect(() => {
    setValue(currentParamValue);
  }, [currentParamValue]);

  const renderSlider = currentParam?.min !== undefined && currentParam?.max !== undefined;

  return (
    <Form.LabeledInput
      help={param.description}
      label={label}
      multiline={2}>
      <NumberInput
        asBlock
        disabled={false}
        onCommit={(updatedValue) => {
          setValue(updatedValue);
          onCommit(updatedValue);
        }}
        size="small"
        value={value}
      />
      {renderSlider && (
        <div style={{ marginTop: '4px' }}>
          <SimpleSlider
            max={currentParam.max!}
            min={currentParam.min!}
            onChange={setValue}
            onCommit={(updatedValue) => {
              const limitedValue = Math.max(
                Math.min(currentParam.max!, updatedValue),
                currentParam.min!,
              );

              setValue(limitedValue);
              onCommit(limitedValue);
            }}
            readoutConfig={{ disabled: true }}
            value={value}
          />
        </div>
      )}
    </Form.LabeledInput>
  );
};

// Handles the import of a geometry within the current geometry.
export const GeometryModificationImportPropPanel = () => {
  // == Contexts
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();
  assert(!!node, 'No selected geometry modification import row');

  // == Recoil
  const geometryState = useGeometryState(projectId, geometryId);
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);

  // == Hooks
  const propClasses = useCommonTreePropsStyles();

  // == State
  const [openDialog, setOpenDialog] = useState(false);
  const [fileParametersCollapsed, setFileParametersCollapsed] = useState(false);

  // == Derived data
  const mod = geometryState?.geometryFeatures.find((feature) => feature.id === node.id);
  assert(!!mod, 'No selected geometry modification import');
  const acknowledgedMod = geometryState?.ackModifications.has(mod?.id || '');
  useEffect(() => {
    setOpenDialog(!acknowledgedMod);
  }, [acknowledgedMod]);

  const [currentImport, setCurrentImport] = useState(mod.operation.value as geometrypb.Import);
  const urlFeature = currentImport.geometryUrl;
  const fileUrl = getNameFromUrl(urlFeature);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelection([]);
  };

  const processInputFile = async (
    url: string,
    scaling: number,
    conversion: frontendpb.MeshConversionStatus,
    setUploadProgress: (value: React.SetStateAction<UploadProgress>) => void,
    fileNames: string[],
    fileTyp: uploadpb.MeshType | undefined,
    forceDiscrete: boolean,
  ) => {
    if (readOnly) {
      return null;
    }
    if (!fileNames.every((name) => isGeometryFile(name))) {
      addRpcError('Invalid file type(s)', new Error());
      return null;
    }
    const feature = new geometrypb.Feature({
      id: node.id,
      operation: {
        case: 'import',
        value: new geometrypb.Import({
          geometryUrl: url,
          scaling,
        }),
      },
      // This is consistent with how the backend generates the first import feature name (i.e. w/o
      // the extension).
      featureName: `Import ${fileNames[0]}`,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      geometryId,
      modification: new geometrypb.Modification({
        modType: createOrUpdateFeature(geometryState, node.id),
        feature,
      }),
    });
    req.requestId = random.string(32);

    rpc.clientGeometry.modifyGeometry(req).catch((err) => {
      console.error('Failed to import geometry', err);
    });

    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);

    setSelection([]);
    return null;
  };

  const onSetImport = (newImport: geometrypb.Import) => {
    setCurrentImport(newImport);
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures.forEach((feature) => {
        if (feature.id !== mod.id) {
          return;
        }
        feature.operation.value = newImport;
      });
      return newGeometryState;
    });
  };

  const onPatternSave = async () => {
    const feature = new geometrypb.Feature({
      operation: mod.operation,
      id: node.id,
      featureName: mod.featureName,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: createOrUpdateFeature(geometryState, node.id),
        feature,
      }),
    });
    rpc.clientGeometry!.modifyGeometry(req).catch((err) => (
      addRpcError(`Server error ${err}`, err)
    ));

    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);

    // Remove the focus on the panel, the geometry being displayed may not be linked to the
    // modification.
    setSelection([]);
  };

  const parameterPanel = currentImport.parameters?.length === 0 ? null : (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '12px' }}>
      {currentImport.parameters.map((param, index) => {
        if (param.type.case !== 'float') {
          console.error(`${param.type.case} not yet implemented`);
          return null;
        }

        let label = (
          `${param.name} [${param.type.value.min ?? '-∞'}, ${param.type.value.max ?? '∞'}]`
        );

        if (param.owner) {
          label = `${param.owner}: ${label}`;
        }

        const onCommit = (value: number) => {
          const newImport = currentImport.clone();
          const newParam = newImport.parameters[index];
          if (newParam.type.case === 'float') {
            newParam.type.value.adType = newAdFloat(value);
          }
          onSetImport(newImport);
        };

        return (
          <GeometryModificationParameterForm
            key={label}
            label={label}
            onCommit={onCommit}
            param={param}
          />
        );
      })}
    </div>
  );

  return (
    <>
      <MeshImportDialog
        acceptNumericExtensions
        directoryTypeOptions={DIRECTORY_FILE_TYPES}
        fileTypeOptions={FILE_TYPE_OPTIONS}
        onClose={handleDialogClose}
        open={openDialog}
        processInputFile={processInputFile}
        projectId={projectId}
        type="CAD"
      />
      <div className={propClasses.properties}>
        <EditModificationMessage nodeId={node.id} />
        <div className={propClasses.typeRow}>
          <div className={propClasses.typeKey} style={{ whiteSpace: 'nowrap' }}>File Name</div>
          <Tooltip title={fileUrl}>
            <span
              className={propClasses.typeValue}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginLeft: 'auto',
              }}>
              {fileUrl}
            </span>
          </Tooltip>
        </div>

        <div style={{ margin: '8px -12px' }}>
          <Divider />
        </div>
      </div>
      <CollapsiblePanel
        collapsed={fileParametersCollapsed}
        heading="File Parameters"
        onToggle={setFileParametersCollapsed}>
        <Form.LabeledInput
          help="A value other than 1 will resize the model."
          label="Scaling">
          <NumberInput
            asBlock
            disabled={false}
            onCommit={(scaling) => {
              const newImport = currentImport.clone();
              newImport.scaling = scaling;
              onSetImport(newImport);
            }}
            size="small"
            value={currentImport.scaling}
          />
        </Form.LabeledInput>
        {parameterPanel}
      </CollapsiblePanel>
      <GeometryModificationPanelFooter
        featureId={node.id}
        onModificationSave={onPatternSave}
      />
    </>
  );
};
