// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, selectorFamily, useRecoilValue } from 'recoil';

import { RecoilProjectKey } from '../lib/persist';
import { surfacesFromVolumes } from '../lib/volumeUtils';

import { staticVolumesSetupTabState } from './volumes';

// Represents the atom state for mesh-derived surfaces
export const meshSurfacesState = atomFamily<string[], RecoilProjectKey>({
  key: 'meshSurfacesState',
  default: selectorFamily<string[], RecoilProjectKey>({
    key: 'meshSurfacesState/Default',
    get: (key: RecoilProjectKey) => ({ get }) => {
      const volumes = get(staticVolumesSetupTabState(key));
      return surfacesFromVolumes(volumes);
    },
  }),
});

export function useMeshSurfaces(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(meshSurfacesState({ projectId, workflowId, jobId }));
}
