// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useState } from 'react';

import IconButton from '@mui/material/IconButton';

import { CollapsiblePanel } from '../Panel/CollapsiblePanel';
import { useFloatingCollapsiblePanelStyles } from '../Theme/commonStyles';
import { XIcon } from '../svg/XIcon';

interface JobDataPanelProps {
  title: ReactElement;
  body: ReactElement;
  onClose?: () => void;
}

const JobDataPanel = (props: JobDataPanelProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const classes = useFloatingCollapsiblePanelStyles();

  return (
    <div className={classes.root}>
      <CollapsiblePanel
        collapsed={collapsed}
        headerRight={props.onClose ? (
          <div className={classes.closePanelButton}>
            <IconButton
              onClick={props.onClose}>
              <XIcon maxHeight={8} maxWidth={8} />
            </IconButton>
          </div>
        ) : undefined}
        heading={props.title}
        onToggle={() => setCollapsed(!collapsed)}
        primaryHeading>
        <div className={classes.content}>
          {props.body}
        </div>
      </CollapsiblePanel>
    </div>
  );
};

export default JobDataPanel;
