// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useRef } from 'react';

import { ConnectError } from '@connectrpc/connect';
import {
  GetRecoilValue,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import assert from '../../lib/assert';
import { CurrentView } from '../../lib/componentTypes/context';
import { lcvResetCameraImmediate } from '../../lib/lcvis/api';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { LeveledMessage } from '../../lib/notificationUtils';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import { isTestingEnv } from '../../lib/testing/utils';
import { addRpcError } from '../../lib/transientNotification';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import { CadMetadata } from '../../proto/cadmetadata/cadmetadata_pb';
import * as geometrypb from '../../proto/geometry/geometry_pb';
import { MeshFileMetadata } from '../../proto/lcn/lcmesh_pb';
import * as codespb from '../../proto/lcstatus/codes_pb';
import * as geometrydetailspb from '../../proto/lcstatus/details/geometry/geometry_pb';
import { currentViewState } from '../../state/internal/global/currentView';
import { routeParamsState } from '../useRouteParams';

import {
  BusyState,
  emptyBusyState,
  geometryBusyState,
  geometryServerStatusAtom,
  useGeometryBusyState,
  useGeometryServerStatus,
  useUpdateGeometryServerStatus,
} from './geometryServerStatus';

import environmentState from '@/state/environment';

const rpcPool = new rpc.StreamingRpcPool<
  geometryservicepb.SubscribeGeometryRequest,
  geometryservicepb.SubscribeGeometryResponse
>('SubscribeGeometry', rpc.clientGeometry.subscribeGeometry, true);

const logger = new Logger('geometryState');

/**
 * Used to signal the geometry server to restart the streaming RPC. This is useful when the server
 * drops the connection and we want to try to restablish the streaming RPC. Set to true when
 * reconnection is requested. The application should not set it to false.
 */
const rpcGeometryRestartTrigger = atomFamily<boolean, string>({
  key: 'rpcGeometryRestartTrigger',
  default: false,
});

export function useRpcGeometryRestartTrigger(geometryId: string) {
  return useRecoilState(rpcGeometryRestartTrigger(geometryId));
}

export function useSetRpcGeometryRestartTrigger(geometryId: string) {
  return useSetRecoilState(rpcGeometryRestartTrigger(geometryId));
}

/** Used as type to assess state transitions when selecting features on the screen. */
export type SelectedFeature = {
  // Feature id. Empty means no feature is selected.
  id: string;
  // Used by the UI when request a feature modification. In that case, the server will always return
  // the latest tessellation, hence we need to avoid requesting it again on our side.
  ignoreUpdate: boolean;
};

export const DEFAULT_SELECTED_FEATURE: SelectedFeature = { id: '', ignoreUpdate: false };
export const DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE: SelectedFeature = {
  id: '', ignoreUpdate: true,
};
export const geometrySelectedFeature = atomFamily<SelectedFeature, string>({
  key: 'geometrySelectedFeature',
  default: DEFAULT_SELECTED_FEATURE,
});

export function useGeometrySelectedFeature(geometryId: string) {
  return useRecoilState(geometrySelectedFeature(geometryId));
}

export function useSetGeometrySelectedFeature(geometryId: string) {
  return useSetRecoilState(geometrySelectedFeature(geometryId));
}

/** The result from the most recent call to latesttessellation */
const geometryLatestTessellation = atomFamily<
  geometryservicepb.TessellationData | undefined, string
>({
  key: 'geometryLatestTessellation',
  default: undefined,
});

export function useLatestTessellationValue(geometryId: string) {
  return useRecoilValue(geometryLatestTessellation(geometryId));
}

export function useSetLatestTessellation(geometryId: string) {
  return useSetRecoilState(geometryLatestTessellation(geometryId));
}

function isFullTessellation(tessellationData: geometryservicepb.TessellationData | undefined) {
  if (tessellationData === undefined) {
    return false;
  }
  return tessellationData.lcnMeta !== undefined;
}

/**
 * Given some tessellation data, returns whether it was produced by a call to latesttessellation.
 */
function isLatestTessellation(tessellationData: geometryservicepb.TessellationData | undefined) {
  if (tessellationData === undefined) {
    return false;
  }
  return tessellationData.featureId === '';
}

// Used to store the last version id of the geometry. This allows components to avoid subscribing
// to all changes of the geometry state.
export const geometryLastVersionIdAtom = atomFamily<string, GeometryKey>({
  key: 'geometryLastVersionId',
  default: '',
});

export function useLatestGeometryVersionid(projectId: string, geometryId: string) {
  return useRecoilValue(geometryLastVersionIdAtom({ projectId, geometryId }));
}

/**
 * @returns a map of feature ids to a list of LeveledMessage. This is useful for displaying
 * errors and extra information about the modifications in the tree.
 */
export function featuresErrors(geoState: GeometryState) {
  const clientFeatures = geoState.geometryFeatures;
  const ack = geoState.ackModifications;
  const messages: Map<string, LeveledMessage[]> = new Map();
  geoState.featureIssuesServer.forEach((featureIssues) => {
    featureIssues.issues.forEach((issue) => {
      let errorMessage = 'Unknown error';
      switch (issue.code) {
        case codespb.Code.GEO_FEATURE_UNKNOWN_ERROR: {
          const details = new geometrydetailspb.GeoFeatureUnknownErrorDetails();
          assert(!!issue.details?.unpackTo(details), 'Failed to unpack geo feature unknown error');
          errorMessage = details.message;
          break;
        }
        case codespb.Code.GEO_INVALID_REQUEST: {
          const details = new geometrydetailspb.GeoWorkerInvalidRequestDetails();
          assert(!!issue.details?.unpackTo(details), 'Failed to unpack geo invalid request');
          errorMessage = details.message;
          break;
        }
        default:
          throw Error(`Unknown error code: ${issue.code}`);
      }
      const message: LeveledMessage = {
        level: 'error',
        message: errorMessage,
      };
      if (messages.has(featureIssues.featureId)) {
        messages.get(featureIssues.featureId)!.push(message);
      } else {
        messages.set(featureIssues.featureId, [message]);
      }
    });

    // If the server has not acknowledged this modification, we let the user know that it's not yet
    // persisted.
    if (!ack.has(featureIssues.featureId)) {
      const message: LeveledMessage = {
        level: 'info',
        message: 'This modification has not been acknowledged by the server yet.',
      };

      // Append to the map.
      if (messages.has(featureIssues.featureId)) {
        messages.get(featureIssues.featureId)!.push(message);
      } else {
        messages.set(featureIssues.featureId, [message]);
      }
    } else {
      // If the server has acknowledged this feature, we check if the feature is
      // up-to-date with the server by comparing the proto messages. If it's not, then we let the
      // user know that the server does not know about the changes to the feature.
      const serverFeature = geoState.geometryFeaturesServer.find(
        (feature) => feature.id === featureIssues.featureId,
      );

      const clientFeature = clientFeatures.find((clientFeatureLoop) => (
        clientFeatureLoop.id === featureIssues.featureId
      ));
      if (!serverFeature?.equals(clientFeature)) {
        const message: LeveledMessage = {
          level: 'info',
          message: 'This modification is not up-to-date with the server.',
        };
        messages.set(featureIssues.featureId, [message]);
      }
    }
  });
  return messages;
}

/**
 * Reconciles the client features with the ones received from the server. This function does
 * not yet support deletion of features that were not originated by this client.
 * @param clientFeatures feature list as seen by the client.
 * @param serverFeatures feature list as seen by the server.
 * @param serverHistory history of modifications as seen by the server.
 * @returns a list of features that are the result of the reconciliation.
*/
export function reconcileServerFeatures(
  clientFeatures: geometrypb.Feature[] | undefined,
  serverFeatures: geometrypb.Feature[],
  serverHistory: geometryservicepb.GeometryHistory[],
) {
  // Nothing to reconcile. The server state wins.
  if (!clientFeatures) {
    return serverFeatures;
  }

  // Features that have been acked by the server. This will always exist.
  const ackFeatures = serverFeatures.reduce((result, item, index) => {
    result.set(item.id, index);
    return result;
  }, new Map<string, number>());
  const result: geometrypb.Feature[] = [];
  const addedServerIds: Set<string> = new Set();

  // In the event a different client deleted a feature, this client does not know about it. A naive
  // solution to this problem is to look at the last modification in the history, if the last
  // modification was a delete, then we will not add the feature to the list of features.
  const lastHist = serverHistory[serverHistory.length - 1].historyEntry?.modification;
  const deleteLast = lastHist?.modType === geometrypb.Modification_ModificationType.DELETE_FEATURE;
  const undoRedoLast = (
    lastHist?.modType === geometrypb.Modification_ModificationType.UNDO ||
    lastHist?.modType === geometrypb.Modification_ModificationType.REDO
  );
  const lastDeleteId = deleteLast ? lastHist?.feature?.id : undefined;

  // For each client feature, assess if it's in the acked list from the server. If so, add the
  // server version since the server is the source of truth. Else, add the client version. Make sure
  // that the server has not deleted this feature in the last modification. In that case, do not
  // add it to the list. If the last operation was an undo/redo, take the server state for granted
  // and ignore the client state.
  clientFeatures.forEach((clientMod) => {
    const entry = ackFeatures.get(clientMod.id);
    if (entry !== undefined) {
      addedServerIds.add(clientMod.id);
      result.push(serverFeatures[entry]);
    } else if (lastDeleteId !== clientMod.id && !undoRedoLast) {
      result.push(clientMod);
    }
  });

  // Add the server feature that were not in the client feature. Take them for granted.
  serverFeatures.forEach((mod) => {
    if (!addedServerIds.has(mod.id)) {
      result.push(mod);
    }
  });
  return result;
}

/// WIP type that will store the geometry state as received from the server.
export type GeometryState = {
  geometryId: string;
  geometryModificationCurrentId: string;
  metadata: MeshFileMetadata;
  cadMetadata: CadMetadata;
  geometryFeatures: geometrypb.Feature[];
  geometryFeaturesServer: geometrypb.Feature[],
  featureIssuesServer: geometrypb.FeatureIssues[],
  geometryHistory: geometryservicepb.GeometryHistory[],
  nUndosAvailable: number,
  nRedosAvailable: number,
  // Ids of server-acnkowledged modifications. Useful to display extra information on whether the
  // modification is up-to-date with the server.
  ackModifications: Set<string>,
  tags?: geometrypb.Tags,
};

type GeometryKey = {
  projectId: string;
  geometryId: string;
};

const startSubscribeGeometryRpc = (
  projectId: string,
  geometryId: string,
  onUpdate: (reply: geometryservicepb.SubscribeGeometryResponse) => void,
  onError: (err: ConnectError) => void,
  onStop: () => void,
  onStart: () => void,
  onRestart: () => void,
) => {
  if (!projectId || !geometryId || !rpcPool) {
    return () => { };
  }
  return rpcPool.start(
    `${projectId}/geometryId=${geometryId}`,
    (): geometryservicepb.SubscribeGeometryRequest => {
      const req = new geometryservicepb.SubscribeGeometryRequest({
        projectId,
        geometryId,
      });
      return req;
    },
    onUpdate,
    onError,
    rpc.clientGeometry,
    onStop,
    onStart,
    onRestart,
    1,
  );
};

// Represents the geometry state of a given geometryId as it is streamed from the server.
export const geometryState = atomFamily<GeometryState | undefined, GeometryKey>({
  key: 'geometryState',
  default: undefined, // default value
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

export function useGeometryState(
  projectId: string,
  geometryId: string,
) {
  return useRecoilValue(geometryState({ projectId, geometryId }));
}

export function useSetGeometryState(
  projectId: string,
  geometryId: string,
) {
  return useSetRecoilState(geometryState({ projectId, geometryId }));
}

export function useRpcGeometryState(
  projectId: string,
  geometryId: string,
): GeometryState | undefined {
  const [geoState, setGeoState] = useRecoilState(geometryState({ projectId, geometryId }));
  const [geoServerStatus, setGeometryServerStatus] = useGeometryServerStatus(geometryId);
  const updateGeoServerStatus = useUpdateGeometryServerStatus(geometryId);
  const lcvisReady = environmentState.use.lcvisReady;
  const [rpcRestart, setRpcRestart] = useRpcGeometryRestartTrigger(geometryId);
  const [, setGeometryBusyState] = useGeometryBusyState(geometryId);
  const setGeometryLatestTessellation = useSetLatestTessellation(geometryId);

  const resetCamera = useRef(0);

  // Recoil callback useful to improve interactivity avoiding sequential set operations.
  const setGeoStateCallback = useRecoilCallback(({ snapshot, set }) => (
    restart: boolean,
    checkpoint: geometryservicepb.SubscribeGeometryResponse_GeometryCheckpoint,
  ) => {
    set(geometryState({ projectId, geometryId }), (oldGeoState) => {
      const { features, geometryHistory, nAvailUndos, nAvailRedos, tessellationData } = checkpoint;

      if (!isFullTessellation(tessellationData) && !oldGeoState) {
        // Wait until we get a valid checkpoint before setting the geometry state for the first
        // time.
        return undefined;
      }

      const ackFeatures: Set<string> = features.reduce((setOut: Set<string>, item) => {
        setOut.add(item.id);
        return setOut;
      }, new Set<string>());

      // If we are restarting the RPC connection, take the server modifications as the source of
      // truth. This is because a lot of things could have happened on the server-side since the
      // client got disconnected.
      const reconciledFeatures = (
        restart ?
          features :
          reconcileServerFeatures(
            oldGeoState?.geometryFeatures,
            checkpoint.features,
            checkpoint.geometryHistory,
          )
      );

      // We received a checkpoint without the tessellation data. This means that the server is
      // sending us an update that does not change the tessellation. Hence, we just keep the old
      // tessellation data-related fields and update the features. When receiving updates from
      // tags, we will not get lcn/lcvis data but we'll get back some updates of the cadMetadata
      // to show the new entity groups.
      if (oldGeoState && (!tessellationData ||
        (tessellationData.lcnMeta === undefined && tessellationData.cadMetadata !== undefined))) {
        const newState = {
          ...oldGeoState,
          features: reconciledFeatures,
          geometryFeaturesServer: features.map((feature) => feature.clone()),
          nUndosAvailable: nAvailUndos,
          nRedosAvailable: nAvailRedos,
          ackModifications: ackFeatures,
          tags: checkpoint.tags,
          geometryHistory: geometryHistory || [],
        } as GeometryState;
        if (checkpoint.tessellationData?.cadMetadata) {
          // We are no longer associating names/tags with the entity groups names. Hence, reuse the
          // old entity groups. This reduces rerenders and ensures that the entity group collapsible
          // state is preserved.
          const oldEntityGroups = oldGeoState.cadMetadata.entityGroups;
          newState.cadMetadata = checkpoint.tessellationData!.cadMetadata;
          newState.cadMetadata.entityGroups = oldEntityGroups;
        }
        return newState;
      }

      return {
        geometryId,
        geometryModificationCurrentId: '',
        metadata: checkpoint.tessellationData!.lcnMeta,
        cadMetadata: checkpoint.tessellationData!.cadMetadata,
        geometryHistory: geometryHistory || [],
        geometryFeatures: reconciledFeatures.map((feature) => feature.clone()),
        geometryFeaturesServer: features.map((feature) => feature.clone()),
        featureIssuesServer: checkpoint.featuresIssues,
        nUndosAvailable: nAvailUndos,
        nRedosAvailable: nAvailRedos,
        ackModifications: ackFeatures,
        tags: checkpoint.tags,
      } as GeometryState;
    });
    // Avoid overwritting the server status if it is not needed. Every ms counts.
    const currentStatus = snapshot.getLoadable(geometryServerStatusAtom(geometryId));
    if (currentStatus.contents !== 'connected') {
      set(geometryServerStatusAtom(geometryId), 'connected');
    }
    set(geometryBusyState(geometryId), emptyBusyState);
    if (isFullTessellation(checkpoint.tessellationData) &&
      isLatestTessellation(checkpoint.tessellationData)) {
      set(geometryLatestTessellation(geometryId), checkpoint.tessellationData);
    } else if (checkpoint.tessellationData?.cadMetadata) {
      set(geometryLatestTessellation(geometryId), (old) => {
        if (old) {
          return {
            ...old,
            cadMetadata: checkpoint.tessellationData!.cadMetadata,
            geometryVersionId: checkpoint.tessellationData!.geometryVersionId,
            featureId: checkpoint.tessellationData!.featureId,
          } as geometryservicepb.TessellationData;
        }
        return undefined;
      });
    }
    const entry = checkpoint.geometryHistory[checkpoint.geometryHistory.length - 1];
    if (entry) {
      const newVersionId = entry.historyEntry?.geometryVersionNewId || '';
      set(geometryLastVersionIdAtom({ projectId, geometryId }), newVersionId);
    }
  }, [geometryId, projectId]);

  // Resets the RPC reset state when the server is connected. This is needed to ensure that we can
  // re-request a reconnection when the server drops the initial connection.
  useEffect(() => {
    if (rpcRestart && geoServerStatus === 'connected') {
      setRpcRestart(false);
    }
  }, [geoServerStatus, rpcRestart, setRpcRestart]);

  useEffect(() => {
    if (!lcvisReady || !geometryId) {
      return;
    }
    // We make this async so that we can await the reconciliation between client and server features
    const convertReplyToGeoState = (reply: geometryservicepb.SubscribeGeometryResponse) => {
      if (reply.geometryId !== geometryId) {
        throw Error('Not recognized geometry');
      }

      // The server informed us that there's something it's working on. This will allow us to put
      // some UI elements in a loading state to avoid concurrent modifications.
      if (reply.ResponseType.case === 'busy') {
        updateGeoServerStatus('busy');
        setGeometryBusyState(reply.ResponseType.value as BusyState);
        return;
      }

      if (reply.ResponseType.case === 'workerDisconnected') {
        updateGeoServerStatus('disconnected');
        return;
      }

      // The last operation was an error, since we previously received a busy state, we need to
      // reset the server status to connected to avoid blocking the user.
      if (reply.ResponseType.case === 'receivedError') {
        updateGeoServerStatus('connected');
        return;
      }

      // The server informed us that there's a new geometry state available.
      type Checkpoint = geometryservicepb.SubscribeGeometryResponse_GeometryCheckpoint;
      const checkpoint = reply.ResponseType.value as Checkpoint;
      assert(!!checkpoint, 'Missing subscribe-geometry reply checkpoint');

      // LcVis must be up to handle the new tessellation.
      assert(lcvHandler.display != null, 'LCV not ready for tessellation');

      const { tessellationData } = checkpoint;

      // Import the new tessellation into LcVis. These functions are async but we cannot await. We
      // also do not have a cancel operation of these operations.
      if (isFullTessellation(tessellationData)) {
        const workspace = lcvHandler.display?.getWorkspace();
        const tessel = tessellationData!.data;
        const meta = tessellationData!.metaData;
        workspace?.importFromData(tessel, meta)
          .then(() => {
            if (resetCamera.current === 0) {
              resetCamera.current += 1;
              lcvResetCameraImmediate();
            }
          })
          .catch((err) => {
            logger.error(err);
          });
      }

      setGeoStateCallback(rpcRestart, checkpoint);
    };

    // We want to show users that there is some progress being made while establishing the
    // connection. The server does not return any streaming update until its worker is up, so
    // instead we show a busy state on the client side. Make sure to do this only the first time the
    // RPC is added to the list of active RPCs, in case this useState is mounted in different
    // components.
    const onStart = () => setGeometryServerStatus((old) => {
      if (old === 'disconnected') {
        return 'busy';
      }
      return old;
    });

    // We don't retry in this case, so we ask the clients to reconnect.
    const onError = (err: ConnectError) => {
      updateGeoServerStatus('disconnected');
      addRpcError('SubscribeGeometry failed', err);
    };

    const onStop = () => {
      // Busy so that the next time we mount, we don't show a disconnected error.
      updateGeoServerStatus('busy');
      // Clear the geometry state to make sure we don't show stale data.
      setGeoState(undefined);
      resetCamera.current = 0;
    };

    // When we restart, the FE clients need to stop allowing interactions until we have reconnected.
    // These retries are tricky to handle.
    const onRestart = () => {
      logger.warn(`Restarting geometry RPC for ${geometryId}`);
      onStop();
    };

    const cancelRpc = startSubscribeGeometryRpc(
      projectId,
      geometryId,
      convertReplyToGeoState,
      onError,
      onStop,
      onStart,
      onRestart,
    );
    return () => {
      cancelRpc();
    };
  }, [
    geometryId,
    lcvisReady,
    projectId,
    rpcRestart,
    setGeoState,
    setGeometryServerStatus,
    setGeoStateCallback,
    setGeometryLatestTessellation,
    setGeometryBusyState,
    updateGeoServerStatus,
  ]);
  return geoState;
}

// Abstracts away the logic used to get the geometryState based on the route params and the
// currentViewAtom.
export function getGeoState(get: GetRecoilValue, projectId: string) {
  if (isTestingEnv()) {
    return undefined;
  }
  const currentView = get(currentViewState);
  if (currentView === CurrentView.GEOMETRY) {
    const params = get(routeParamsState);
    const geometryId = params.geometryId;
    if (geometryId) {
      return get(geometryState({ projectId, geometryId }));
    }
  }
  return undefined;
}

// Whether we are in the geometry tab.
// TODO(LC-18808): this is used to identify recoil states that we want to deactivate in the geometry
// tab. We need to figure out how to avoid to add recoil dependencies that we don't need in the
// geometry tab.
export const onGeometryTabSelector = selector({
  key: 'onGeometryTabSelector',
  get: ({ get }) => {
    if (isTestingEnv()) {
      return false;
    }
    return get(currentViewState) === CurrentView.GEOMETRY;
  },
});

/**
 * Returns where a feature should be modified using UPDATE or CREATE options. This decision is made
 * based on whether the server is aware that the feature exists or not.
 * @param geoState: GeometryState object where the feature is being modified and which has access
 * to the server's acknowledged features.
 * @param featureId: id of the feature being modified/created.
 * @returns proto enum with the modification type.
 */
export function createOrUpdateFeature(geoState: GeometryState | undefined, featureId: string) {
  if (geoState === undefined) {
    return geometrypb.Modification_ModificationType.UNSPECIFIED;
  }

  if (geoState.ackModifications.has(featureId)) {
    return geometrypb.Modification_ModificationType.UPDATE_FEATURE;
  }
  return geometrypb.Modification_ModificationType.CREATE_FEATURE;
}

type LastAckedModKey = GeometryKey & { nodeId: string; };
/**
 * For a given node id, return whether that node corresponds to the most recently created node
 * present in the features list that has been acknowledged by the server.
 */
const lastAckedModState = selectorFamily<boolean, LastAckedModKey>({
  key: 'lastAckedModState',
  get: (key: LastAckedModKey) => ({ get }) => {
    const { geometryId, projectId, nodeId } = key;
    const geoState = get(geometryState({ geometryId, projectId }));

    // the last acked mod is the final modification in the features list which has been acknowledged
    // by the server.
    const features = geoState?.geometryFeatures;
    if (features?.length) {
      for (let i = features.length - 1; i > -1; i -= 1) {
        const feature = features[i];
        if (geoState?.ackModifications.has(feature.id)) {
          return nodeId === feature.id;
        }
      }
    }
    return false;
  },
});
export const useIsLastAckedMod = (key: LastAckedModKey) => useRecoilValue(lastAckedModState(key));

// Returns whether the geometry has features with issues. Can be used to block certain operations
// until the issues are resolved.
const geometryHasFeaturesWithIssues = selectorFamily<boolean, GeometryKey>({
  key: 'geometryHasFeaturesWithIssues',
  get: (key: GeometryKey) => ({ get }) => {
    const geoState = get(geometryState(key));
    return (geoState?.featureIssuesServer?.length ?? 0) > 0 || false;
  },
});

export const useGeometryHasFeaturesWithIssues = (key: GeometryKey) => (
  useRecoilValue(geometryHasFeaturesWithIssues(key))
);
