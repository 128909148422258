// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import {
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  waitForAll,
} from 'recoil';

import ImageRenderer from '../components/Paraview/ImageRenderer';
import { Client } from '../lib/ParaviewClient';
import { axesToEuler, toArray } from '../lib/Vector';
import * as AnnotationType from '../lib/lcvis/classes/annotations/types';
import { lcvHandler } from '../lib/lcvis/handler/LcvHandler';
import { safeLcvVec3, toVector3 } from '../lib/lcvis/lcvisUtils';
import { Logger } from '../lib/observability/logs';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import Renderer from '../lib/renderer';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';
import * as Paraviewrpc from '../pvproto/ParaviewRpc';

import { onGeometryTabSelector } from './geometry/geometryState';
import { meshingMultiPartSelector } from './useMeshingMultiPart';

const logger = new Logger('recoil/useRefinementRegions');

const refinementRegionVisibilityKey = 'refinementRegionVisibility';

export type RefinementRegionSelection = {
  [id: string]: boolean
}
export type RefinementRegionVisibilities = {
  [id: string]: boolean
}

const refinementRegionSelectionState = atomFamily<
  string, string
>({
  key: 'refinementRegionSelectionState',
  default: '',
});

function serialize(val: RefinementRegionVisibilities): Uint8Array {
  const visibilities: Array<meshgenerationpb.RefinementRegionVisibilityMap_Entry> = [];
  Object.keys(val).forEach((id) => visibilities.push(
    new meshgenerationpb.RefinementRegionVisibilityMap_Entry({ id, visible: val[id] }),
  ));
  const protoMap = new meshgenerationpb.RefinementRegionVisibilityMap({ visibilities });
  return protoMap.toBinary();
}
function deserialize(val: Uint8Array): RefinementRegionVisibilities {
  const visMap = (
    val.length ?
      meshgenerationpb.RefinementRegionVisibilityMap.fromBinary(val) :
      new meshgenerationpb.RefinementRegionVisibilityMap({ visibilities: [] })
  );
  const fullMap: RefinementRegionVisibilities = {};
  visMap.visibilities.forEach(({ id, visible }) => {
    fullMap[id] = visible;
  });
  return fullMap;
}

/** The visibility of each refinement region. This is stored in the kvstore per-project. */
export const refinementRegionVisibilityState = atomFamily<
  RefinementRegionVisibilities,
  persist.RecoilProjectKey
>({
  key: 'refinementRegionVisibility',
  default: ({ projectId, jobId }) => (
    persist.getProjectState(
      projectId,
      [refinementRegionVisibilityKey],
      deserialize,
    ).then((visibilities) => {
      const isSimulation = jobId !== '';

      if (isSimulation) {
        return Object.fromEntries(
          Object.entries(visibilities).map(([identifier]) => ([identifier, false] as const)),
        );
      }

      return visibilities;
    })
  ),
  effects: ({ projectId, jobId }) => {
    const isSimulation = jobId !== '';

    if (isSimulation) {
      return [];
    }

    return [
      syncProjectStateEffect(projectId, refinementRegionVisibilityKey, deserialize, serialize),
    ];
  },
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

/** Convert a refinement region shape to the corresponding pvproto struct */
const getPvRrParam = (
  region: meshgenerationpb.MeshingMultiPart_RefinementRegionParams,
): Paraviewrpc.OrientedCubeParam |
  Paraviewrpc.SphereShellParam |
  Paraviewrpc.AnnularCylinderParam | undefined => {
  switch (region.shape.case) {
    case 'orientedCube': {
      const cube = region.shape.value;
      return {
        typ: 'Cube',
        max: cube.max!,
        min: cube.min!,
        origin: cube.origin!,
        xAxis: cube.xAxis!,
        yAxis: cube.yAxis!,
      };
    }
    case 'annularCylinder': {
      const cylinder = region.shape.value;
      return {
        typ: 'AnnularCylinder',
        start: cylinder.start!,
        end: cylinder.end!,
        outer_radius: cylinder.radius!,
        inner_radius: cylinder.radiusInner!,
      };
    }
    case 'sphereShell': {
      const sphere = region.shape.value;
      return {
        typ: 'SphereShell',
        inner_radius: sphere.radiusInner!,
        outer_radius: sphere.radius!,
        center: sphere.center!,
      };
    }
    default:
      // undefined, should not happen
      logger.error('unsupported shape specified for refinement region');
  }
  return undefined;
};

/** Convert a refinement region shape to the corresponding lcv annotation struct */
const getLcvRrParam = (
  region: meshgenerationpb.MeshingMultiPart_RefinementRegionParams,
): AnnotationType.ParamOptions | undefined => {
  switch (region.shape.case) {
    case 'orientedCube': {
      const { min, max, origin, xAxis, yAxis } = region.shape.value;
      const cubeMin = min!;
      const cubeMax = max!;
      // TODO - verify correctness by checking UI params to meshgenerationpb conversion
      const start = safeLcvVec3(toVector3(origin!));
      const extents = safeLcvVec3([
        cubeMax.x - cubeMin.x,
        cubeMax.y - cubeMin.y,
        cubeMax.z - cubeMin.z,
      ]);
      const orientation = safeLcvVec3(toArray(axesToEuler(xAxis!, yAxis!)));
      return {
        typ: AnnotationType.REFINEMENT_BOX,
        start,
        extents,
        orientation,
      };
    }
    case 'annularCylinder': {
      const cylinder = region.shape.value;
      return {
        typ: AnnotationType.REFINEMENT_CYLINDER,
        start: safeLcvVec3(toVector3(cylinder.start!)),
        end: safeLcvVec3(toVector3(cylinder.end!)),
        outerRadius: cylinder.radius!,
        innerRadius: cylinder.radiusInner!,
      };
    }
    case 'sphereShell': {
      const sphere = region.shape.value;
      return {
        typ: AnnotationType.REFINEMENT_SPHERE,
        center: safeLcvVec3(toVector3(sphere.center!)),
        outerRadius: sphere.radius!,
        innerRadius: sphere.radiusInner!,
      };
    }
    default:
      // undefined, should not happen
      logger.error('unsupported shape specified for lcv refinement region');
  }
  return undefined;
};

/**
 * Converts refinement regions and their visibilities to Paraviewrpc.RefinementRegionParams to
 * be used by the visualizer. Gets updated whenever a refinement region's parameters or its
 * visibility attributes change.
 */
export const refinementRegionSelector = selectorFamily<
  Paraviewrpc.RefinementRegionParam[],
  persist.RecoilProjectKey
>({
  key: 'refinementRegionSelector',
  get: (key: persist.RecoilProjectKey) => async ({ get }) => {
    const [meshingMultiPart, visibilities, selection] = get(waitForAll([
      meshingMultiPartSelector(key),
      refinementRegionVisibilityState(key),
      refinementRegionSelectionState(key.projectId),
    ]));

    if (!meshingMultiPart) {
      return [];
    }
    const refinementRegions = meshingMultiPart.refinementParams;
    const isSimulation = key.jobId !== '';
    const visibilityFallback = !isSimulation;
    const params = refinementRegions.map((region) => {
      const visible = visibilities[region.id];
      const selected = region.id === selection;
      const regionParam: Paraviewrpc.RefinementRegionParam = {
        param: getPvRrParam(region)!,
        typ: 'RefinementRegion',
        id: region.id,
        wireframe: true,
        visible: visible ?? visibilityFallback,
        selected: selected ?? false,
      };
      return regionParam;
    });

    return params;
  },
});

/**
 * LCVis version
 * Converts refinement regions and their visibilities to Annotation refinement region params to
 * be used by lcvis. Gets updated whenever a refinement region's parameters or its
 * visibility attributes change.
 */
export const refinementRegionLcvSelector = selectorFamily<
  Map<string, AnnotationType.AnnotationParam>,
  persist.RecoilProjectKey
>({
  key: 'refinementRegionSelector',
  get: (key: persist.RecoilProjectKey) => async ({ get }) => {
    const [meshingMultiPart, visibilities, selection, isGeoTab] = get(waitForAll([
      meshingMultiPartSelector(key),
      refinementRegionVisibilityState(key),
      refinementRegionSelectionState(key.projectId),
      onGeometryTabSelector,
    ]));

    if (!meshingMultiPart || isGeoTab) {
      return new Map();
    }

    const refinementRegions = meshingMultiPart.refinementParams;
    const paramsMap = new Map<string, AnnotationType.AnnotationParam>();
    const isSimulation = key.jobId !== '';
    const visibilityFallback = !isSimulation;

    refinementRegions.forEach((region) => {
      const visible = visibilities[region.id];
      const selected = region.id === selection;
      const regionParam: AnnotationType.AnnotationParam = {
        typ: AnnotationType.REFINEMENT_REGION,
        id: region.id,
        param: getLcvRrParam(region)!,
        wireframe: true,
        visible: visible ?? visibilityFallback,
        selected: selected ?? false,
      };
      paramsMap.set(region.id, regionParam);
    });
    return paramsMap;
  },
});

export const useSetRefinementRegionVisibility = (
  key: persist.RecoilProjectKey,
) => useSetRecoilState(refinementRegionVisibilityState(key));

export const useSetRefinementRegionSelection = (
  projectId: string,
) => useSetRecoilState(refinementRegionSelectionState(projectId));

export const useRefinementRegionVisibility = (
  key: persist.RecoilProjectKey,
) => useRecoilState(refinementRegionVisibilityState(key));

/**
 * Calls Paraviewrpc.setrefinementregions whenever the refinement regions change to keep the
 * visualizer in sync with the refinement region state and visibilities.
 * */
export const useRefinementRegionsVisEffect = (
  key: persist.RecoilProjectKey,
  paraviewRenderer: Renderer,
  syncing: boolean,
  client: Client | null,
) => {
  const refinementParams = useRecoilValue(refinementRegionSelector(key));

  useEffect(() => {
    if (paraviewRenderer && !syncing && client) {
      (paraviewRenderer as ImageRenderer).setRefinementRegions(refinementParams);
    }
  }, [paraviewRenderer, refinementParams, syncing, client]);
};

/**
 * Calls into the LcvHandler whenever the refinement regions change to keep the
 * visualizer in sync with the refinement region state and visibilities.
 * */
export const useRefinementRegionsLcvEffect = (
  key: persist.RecoilProjectKey,
  lcvisReady: boolean,
) => {
  const refinementParams = useRecoilValue(refinementRegionLcvSelector(key));

  useEffect(() => {
    // Make a call to the handler and queue the update refinement regions callback
    if (lcvisReady) {
      lcvHandler.queueDisplayFunction(
        'updateRrCallback',
        (display) => display.annotationHandler?.updateRefinementRegionAnnotations(refinementParams),
      );
    }
  }, [refinementParams, lcvisReady]);
};
