import { useUserCanEdit } from '../../lib/projectRoles';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useProjectContext } from '../context/ProjectContext';

export const sharedReason = (
  <T extends string>(action: T) => `Cannot ${action} in view only projects` as const
);

export const resultReason = (
  <T extends string>(action: T) => `Cannot ${action} while viewing simulation results` as const
);

export const useNonEditableReason = <A extends string, F extends string = ''>(
  action: A,
  fallback: F = '' as F,
  config: { showShared?: boolean; showResult?: boolean } = {},
) => {
  const { projectId, readOnly } = useProjectContext();
  const projectMetadata = useProjectMetadata(projectId);
  const canEdit = useUserCanEdit(projectMetadata?.summary);
  const { showShared = true, showResult = true } = config;

  if (!canEdit && showShared) {
    return sharedReason(action);
  }

  if (readOnly && showResult) {
    return resultReason(action);
  }

  return fallback;
};
