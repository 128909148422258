// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';

import { ParamScope, createParamScope } from './ParamScope';
import { newAdFloat } from './adUtils';
import assert from './assert';

const { CUSTOM_MATERIAL_FLUID, STANDARD_AIR, WATER_NTP } = simulationpb.MaterialFluidPreset;
const { CONSTANT_DENSITY, IDEAL_GAS } = simulationpb.DensityRelationship;
const { LAMINAR_CONSTANT_VISCOSITY, TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY } =
  simulationpb.LaminarViscosityModelNewtonian;
const { LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY, TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY } =
  simulationpb.LaminarThermalConductivity;

// Function which checks that there only exists one preset whose conditional evaluates to true.
export function checkPresets(presets: Map<string, ProtoDescriptor.Cond>, paramScope: ParamScope) {
  let enabledPresets = 0;
  presets.forEach((cond, key) => {
    if (paramScope.isEnabled(cond)) {
      enabledPresets += 1;
    }
  });
  assert(enabledPresets === 1, 'Incompatible presets');
}

// Sets the parameters for each material preset.
export function applyFluidMaterialPreset(
  material: simulationpb.MaterialFluid,
): boolean {
  /* eslint-disable-next-line id-length */
  const p = material;
  const value = p.materialFluidPreset;
  if (value === CUSTOM_MATERIAL_FLUID) {
    return true;
  }

  if (material.laminarViscosityModelNewtonian === TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY) {
    material.laminarViscosityModelNewtonian = LAMINAR_CONSTANT_VISCOSITY;
    material.dynamicViscosityTableData = '';
  }
  if (material.laminarThermalConductivity === TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY) {
    material.laminarThermalConductivity = LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY;
    material.thermalConductivityTableData = '';
  }

  const paramScope = createParamScope(material, []);

  // BEGIN GENERATED CODE (see gen/presets.py)
  const presets = new Map<string, ProtoDescriptor.Cond>();
  presets.set(
    'FLUID_MATERIAL',
    {
      type: ProtoDescriptor.CondType.TRUE,
    },
  );
  checkPresets(presets, paramScope);
  if (paramScope.isEnabled(presets.get('FLUID_MATERIAL')!)) {
    if (value === STANDARD_AIR) {
      p.laminarConstantViscosityConstant = newAdFloat(1.7894e-05);
      p.sutherlandViscosityRef = newAdFloat(1.716e-05);
      p.sutherlandViscosityTempRef = newAdFloat(273.11);
      p.sutherlandConstant = newAdFloat(110.56);
      p.laminarConstantThermalPrandtlConstant = newAdFloat(0.72);
      p.laminarConstantThermalConductivityConstant = newAdFloat(0.0257);
      p.molecularWeight = newAdFloat(28.966);
      p.specificHeatCp = newAdFloat(1006.43);
      p.constantDensityValue = newAdFloat(1.225);
      p.boussinesqTempRef = newAdFloat(288.15);
      p.thermalExpansionCoefficient = newAdFloat(0.0035);
    }
    if (value === WATER_NTP) {
      p.constantDensityValue = newAdFloat(998.2);
      p.laminarViscosityModelNewtonian = simulationpb.LaminarViscosityModelNewtonian.LAMINAR_CONSTANT_VISCOSITY;
      p.laminarConstantViscosityConstant = newAdFloat(0.001002);
      p.molecularWeight = newAdFloat(18.0153);
      p.specificHeatCp = newAdFloat(4183.0);
      p.laminarConstantThermalPrandtlConstant = newAdFloat(7.152);
      p.boussinesqTempRef = newAdFloat(293.15);
      p.thermalExpansionCoefficient = newAdFloat(0.00021);
    }
  }
  // END GENERATED CODE

  if (value === WATER_NTP) {
    // If constant density (with or without energy) was already selected we do
    // not override the choice. Otherwise, we switch to constant density because
    // the ideal gas model is not suitable for liquid water.
    if (material.densityRelationship === IDEAL_GAS) {
      material.densityRelationship = CONSTANT_DENSITY;
    }
  }

  return true;
}
