import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 10;
const baseHeight = 10;
export const WarningTriangleIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);
  return (
    <svg
      data-icon-name="warning-triangle"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
        fill: 'none',
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M0.636282 7.49834L1.06833 7.75L0.636282 7.49834C0.244109 8.17164 0.738821 9 1.50519
        9H8.49481C9.26118 9 9.75589 8.17164 9.36372 7.49834L5.8689 1.49834C5.48188 0.833886
        4.51812 0.833886 4.1311 1.49834L0.636282 7.49834Z"
        fill={getColor(0)}
        stroke={getColor(1)}
      />
    </svg>
  );
});
