// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';

const lcvObjectRegistry: LcvObject[] = [];

/**
 * A base wrapper for interacting with lcvis objects.
 * https://docs.google.com/document/d/10ZPFk3yaE4Now_wJsc3FOSq-ZOuAE88Xv0HPIeOTVmw/
  */
export abstract class LcvObject {
  /**
   * The lcvis module. This gives us access to lcvis functions. Must be initialized
   * once somewhere in the app using initLCVis().
   */
  lcv: LcvModule;
  /** pointer to the session object in wasm memory. */
  readonly sessionHandle: number;
  /** pointer to the object in the wasm memory ArrayBuffer. */
  readonly handle: number;
  /** the session-unique ID for this object, returned when this is selected */
  readonly objectId: number;
  /** the base type of the object */
  readonly objectType: LCVType;

  /**
   * The app refCount of this object. When the refCount reaches 0, calling any functions with this
   * object will throw an error.
   * */
  refCount: number;

  constructor(lcv: LcvModule, handle: number, sessionHandle: number) {
    this.handle = handle;
    this.lcv = lcv;
    this.sessionHandle = sessionHandle;
    this.refCount = 1;
    this.objectId = lcv.getObjectId(sessionHandle, handle, 0).id;
    this.objectType = lcv.getObjectType(sessionHandle, handle, 0).type;

    lcvObjectRegistry.push(this);
  }

  // We convert nulls to 0s before passing them to lcvis
  setParam(propertyName: string, type: LCVType, value: any) {
    this.lcv.setParameter(this.sessionHandle, this.handle, propertyName, type, value);
  }

  /**
   * Check if the object has the specified property and return its type if so.
   * Returns LCVType.kLCVDataTypeUnknown if the object does not have the property.
   */
  hasProperty(propertyName: string) {
    return this.lcv.objectHasProperty(
      this.sessionHandle,
      this.handle,
      propertyName,
      0,
    ).property_type;
  }

  getProperty(propertyName: string, type: LCVType): any {
    return this.lcv.getProperty(
      this.sessionHandle,
      this.handle,
      propertyName,
      type,
      0,
    ).property;
  }

  /**
   * Objects in lcvis can also be sent signals if they expose signals. Signals are different
   * from parameters in that the signal is sent immediately instead of being buffered until
   * the next render frame when the object updates.
   *
   * @param signalName The name of the signal to call, from the enums in lcvis/signals.ts
   * @param parameterType The type of the optional parameter being passed to the signal.
   *                      See the LCVis docs for the object's signals to see what parameters
   *                      the signal takes.
   * @param value The parameter value for the signal
   */
  sendSignal(signalName: string, parameterType: LCVType, value: any) {
    this.lcv.sendSignal(this.sessionHandle, this.handle, signalName, parameterType, value);
  }

  /**
   * Objects in lcvis are ref-counted. Release decrements the object's reference count by 1.
   * Objects aren't necessarily destroyed when the UI ref count reaches 0, if the object is still
   * being used as a parameter of some other object (or in another case where the using object
   * keeps it alive), it will stay alive until that referring object is destroyed.
   *
   * Calling lcv.new* creates a new object and initializes its refcount as 1. So when we
   * no longer need one of these objects we must call release on it to tell lcvis we no longer
   * need it.
   *  */
  release() {
    this.refCount = this.lcv.release(this.sessionHandle, this.handle, 0).app_ref_count;
  }

  retain() {
    this.refCount = this.lcv.retain(this.sessionHandle, this.handle, 0).app_ref_count;
  }
}

/** Return every LcvObject created in LCVis. */
export const getEveryLcvObject = () => lcvObjectRegistry;

/** Return every LcvObject created in LCVis that has a refCount greater than 0. */
export const getLiveLcvObjects = () => lcvObjectRegistry.filter((obj) => obj.refCount > 0);

/** Clear the lcvObjectRegistry */
export const clearAllLcvObjects = () => {
  lcvObjectRegistry.length = 0;
};
