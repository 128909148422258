// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as ProtoDescriptor from '../../ProtoDescriptor';
import { ParamGroup } from '../../ProtoDescriptor';
import * as entitygrouppb from '../../proto/entitygroup/entitygroup_pb';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { NamesRecord } from '../../state/external/project/simulation/param/boundaryNames';
import { ParamScope } from '../ParamScope';
import { AnyBoundaryCondition } from '../boundaryConditionUtils';
import { cameraNodeId } from '../cameraUtils';
import { EntityGroup, EntityGroupMap } from '../entityGroupMap';
import { DescendantCountMap } from '../entityGroupUtils';
import { isImposter } from '../imposterFilteringUtils';
import { NodeGroup, NodeGroupMap } from '../nodeGroupMap';
import { lowerFirst, plural } from '../text';

import { NodeType, ROOT_CONTAINER_ID, SimulationTreeNode } from './node';

// getSimCount calculates the number of simulations that would be created by the
// workflow config based on the exploration type
export function getSimCount(config: workflowpb.Config): number {
  const { exploration } = config;
  if (!exploration) {
    return 0;
  }

  switch (exploration.policy.case) {
    // the calculations for each case are based on the exploration policy
    // implementations in go/core/exploration/*
    case 'baseline': {
      return 1; // baseline workflow always creates just 1 simulation
    }
    case 'gridSearch': {
      // each variable is an axis on the "grid", so the overall search space is
      // the product of all the counts
      return exploration.var.reduce((result, expVar) => {
        if (expVar.valueTyp.case === 'range') {
          const { nSamples, nInterval } = expVar.valueTyp.value;
          // If using nSample, we can just multiply by the number of samples
          if (nSamples) {
            return result * nSamples;
          }
          // need to add 1 to the number of intervals:
          // |----|      1 interval = 2 sims (beginning, end)
          // |----|----| 2 intervals = 3 sims (beginning, middle, end), etc.
          return result * (1 + nInterval);
        }
        if (expVar.valueTyp.case === 'enumerated') {
          const enumerated = expVar.valueTyp.value;
          return result * (enumerated.value.length);
        }
        return result;
      }, 1);
    }
    case 'sensitivityAnalysis': {
      return exploration.var.length;
    }
    case 'latinHypercube': {
      return exploration.policy.value.nSamples;
    }
    case 'custom': {
      return exploration.policy.value.nSamples;
    }

    default:
      // if we reach here, something's wrong with the workflow config so it's likely
      // that no simulations will be created
      return 0;
  }
}

// The button runs a simulation or exploration.
export function getRunButtonText(
  isBaselineMode: boolean,
  isSensitivityAnalysis: boolean,
  isAdjointSetup: boolean,
  workflowConfig: workflowpb.Config,
  isLMA: boolean,
) {
  let label = '';
  let help = '';
  if (isBaselineMode) {
    if (isAdjointSetup) {
      label = 'Run Adjoint Analysis';
    } else {
      label = isLMA ? 'Run Adaptive Simulation' : 'Run Simulation';
    }
  } else {
    label = isSensitivityAnalysis ? 'Run Local Sensitivity' : 'Run Design of Experiments';
    const simCount = getSimCount(workflowConfig);
    if (simCount > 0) {
      label = `${label} (${simCount})`;
      help = `${simCount} simulation${plural(simCount)}`;
    }
  }

  return { label, help };
}

// Convert a ParaviewRpc.TreeNode to a SimulationTreeNode for the given node and
// all of its children.
export const paraviewToSimulationNode = (
  inputNode: ParaviewRpc.TreeNode,
  lcvisEnabled: boolean,
): SimulationTreeNode => {
  let filterNode = inputNode;

  // LCVis filters don't support creating ExtractSurfaces, but that filter acts as a wrapper
  // holding surfaceNames for the IntersectionFilter. In the future, we might migrate and
  // extend it with fields for defining surfaces. For now, the trick is to use ExtractSurfaces
  // in LCVis purely as a data wrapper, so we don't show it in the trees.
  // see: https://github.com/luminarycloud/core/pull/20186#issuecomment-2669126710
  if (lcvisEnabled && inputNode.param.typ === 'ExtractSurfaces') {
    const hasOnlyIntersectionCurve = (
      inputNode.child.length === 1 && inputNode.child[0].param.typ === 'IntersectionCurve'
    );

    if (hasOnlyIntersectionCurve) {
      filterNode = inputNode.child[0];
    }
  }

  // We represent certain setup simulation tree nodes as imposter (i.e., show a visualization of
  // an actuator disk). These items are in the visualization tree, but need to be filtered out
  // since they are controlled via a object attached to the mesh.
  const children = filterNode.child.filter(
    (child) => !isImposter(child),
  ).map((child) => paraviewToSimulationNode(child, lcvisEnabled));

  const name = (filterNode.param.typ === ParaviewRpc.TreeNodeType.READER) ?
    'Visualizations' :
    filterNode.name;

  return new SimulationTreeNode(NodeType.FILTER, filterNode.id, name, children);
};

// Returns the name of the boundary condition at the given index.
export const getBoundaryCondName = (
  bcNames: NamesRecord,
  boundaryCondition: AnyBoundaryCondition,
): string => {
  const id = boundaryCondition.boundaryConditionName;
  const name = bcNames[id];
  if (!name) {
    throw Error(`Name not found for boundary condition ${id}`);
  }
  return name;
};

// Creates a new simulation tree from the simulation parameters.
export const newSimulationTree = (children: SimulationTreeNode[]): SimulationTreeNode => (
  new SimulationTreeNode(NodeType.ROOT, ROOT_CONTAINER_ID, '', children)
);

// Creates the simulation tree node structure from a group
export const groupToSimTreeNode = (
  group: EntityGroup,
  entityGroupMap: EntityGroupMap,
): SimulationTreeNode => {
  const children = entityGroupMap.getChildren(group.id).map(
    (node) => groupToSimTreeNode(entityGroupMap.get(node)!, entityGroupMap),
  );
  if (!group.children.size) {
    switch (group.entityType) {
      case entitygrouppb.EntityType.PARTICLE_GROUP:
        return new SimulationTreeNode(NodeType.PARTICLE_GROUP, group.id, group.name);
      case entitygrouppb.EntityType.SURFACE:
        return new SimulationTreeNode(NodeType.SURFACE, group.id, group.name);
      case entitygrouppb.EntityType.MONITOR_PLANE:
        return new SimulationTreeNode(NodeType.MONITOR_PLANE, group.id, group.name);
      case entitygrouppb.EntityType.PROBE_POINTS:
        return new SimulationTreeNode(NodeType.PROBE_POINT, group.id, group.name);
      case entitygrouppb.EntityType.VOLUME:
        return new SimulationTreeNode(NodeType.VOLUME, group.id, group.name);
      case entitygrouppb.EntityType.BODY_TAG:
        return new SimulationTreeNode(NodeType.TAGS_BODY, group.id, group.name);
      case entitygrouppb.EntityType.FACE_TAG:
        return new SimulationTreeNode(NodeType.TAGS_FACE, group.id, group.name);
      default:
        throw Error('Undefined entity type');
    }
  }
  return new SimulationTreeNode(
    NodeType.SURFACE_GROUP,
    group.id,
    group.name,
    children,
  );
};

// Sorts groupMap elements by putting the nodes w/o children first and nodes w/ children at the end.
const sortByHavingChildren = (groupMap: NodeGroupMap) => (a: string, b: string) => {
  if (groupMap.get(a).children.size) {
    return 1;
  }
  if (groupMap.get(b).children.size) {
    return -1;
  }
  return 0;
};

// Creates the simulation tree node structure from a group. It returns either the appropriate
// TreeNode for the nodeType or if the node has children - a particular GroupNode.
// Can be extended to include other non-geometry items that are not part of the entityGroupMap.
const nodeGroupToSimTreeNode = (
  group: NodeGroup,
  groupMap: NodeGroupMap,
): SimulationTreeNode => {
  // If the node doesn't have children, return the TreeNode that matches the nodeType.
  if (!group.children.size) {
    switch (group.nodeType) {
      case NodeType.CAMERA:
        return new SimulationTreeNode(
          NodeType.CAMERA,
          cameraNodeId(group.item.cameraId),
          group.item.name,
        );
      default:
        throw Error('Undefined item type');
    }
  }

  // If the node DO have children, return a group node - either a specific Group node
  // that matches the NodeType or the generic surface group tree node.
  const children = Array.from(group.children).map(
    (node) => nodeGroupToSimTreeNode(groupMap.get(node)!, groupMap),
  );

  switch (group.nodeType) {
    case NodeType.CAMERA_GROUP:
      return new SimulationTreeNode(
        NodeType.CAMERA_GROUP,
        group.id,
        group.name,
        children,
      );
    default:
      return new SimulationTreeNode(
        NodeType.SURFACE_GROUP,
        group.name,
        group.id,
        children,
      );
  }
};

// This is used to transform a groupМap into a list of tree nodes (either a particular NodeType
// node or a Group node). This can be used by other non-geometry entity items once the
// nodeGroupToSimTreeNode is updated to support the new NodeTypes.
export const createSimTreeNodes = (
  groupMap: NodeGroupMap,
  types: NodeType[],
) => {
  const rootChildren = Array.from(groupMap.root().children).sort(sortByHavingChildren(groupMap));
  const filteredNodes = types.length ?
    rootChildren.filter((childId) => types.includes(groupMap.get(childId).nodeType)) :
    rootChildren;
  return filteredNodes.map((childId) => (nodeGroupToSimTreeNode(groupMap.get(childId), groupMap)));
};

// Returns an array of warnings generated using the current values of parameters in a param group.
// The returned array is empty if there are no warnings.
export function paramGroupWarnings(
  group: ParamGroup,
  paramScope: ParamScope,
) {
  const warnings: string[] = [];
  Object.values(group.params).forEach((param) => {
    if (param.type === ProtoDescriptor.ParamType.INT ||
      param.type === ProtoDescriptor.ParamType.REAL) {
      const warning = ProtoDescriptor.checkBounds(param, paramScope.value(param) as number);
      if (warning) {
        warnings.push(`${param.text} ${lowerFirst(warning)}`);
      }
    }
  });
  return warnings;
}

export function formatDescendantCount(count?: number) {
  if (count) {
    return `(${count})`;
  }
  return '';
}

export function getFormattedDescendantCount(countMap: DescendantCountMap, nodeId: string) {
  const numDescendants = countMap.get(nodeId);
  return formatDescendantCount(numDescendants);
}
