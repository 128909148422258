import { useCallback } from 'react';

import { useSetAssistantMessages } from '../../../state/external/assistant/assistantMessages';
import { useSetAssistantThinking } from '../../../state/external/assistant/assistantThinking';

export const useAddNewAssistantMessage = (projectId: string) => {
  const setMessages = useSetAssistantMessages(projectId);
  const setAssistantThinking = useSetAssistantThinking();

  return useCallback((text: string) => {
    setMessages((currMessages) => currMessages.addUserMessage(text));
    setAssistantThinking(true);
  }, [setMessages, setAssistantThinking]);
};
