// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useState } from 'react';

import { deepEqual } from 'fast-equals';

import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { Logger } from '../../lib/observability/logs';
import { traverseTreeNodes } from '../../lib/paraviewUtils';
import { defaultCmap, displayVariableToTextFromData } from '../../lib/visUtils';
import { ColorMap, DisplayPvVariable } from '../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useUpdateLcvisColorMap, useViewStateOverflow } from '../../recoil/lcvis/viewStateOverflow';
import { useViewState } from '../../recoil/useViewState';
import { useFilterStateValue } from '../../recoil/vis/filterState';
import { useProjectContext } from '../context/ProjectContext';

import { useParaviewContext } from './ParaviewManager';

const logger = new Logger('ColorPanelManager');

// The maximum number of discrete levels for color maps.
export const MAX_BINS = 256;

// The maximum number of ticks/labels used for legend colorbar.
export const MAX_TICKS = 9;

export type ColorPanel = {
  name: string;
  isVertical: boolean;
}

interface ColorDialogState {
  displayVariable: DisplayPvVariable;
  name: string;
  initialCmap: ColorMap;
  editedCmap: ColorMap;
}

export const useColorPanelState = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const filterState = useFilterStateValue({ projectId, workflowId, jobId });
  const updateLcvisColorMap = useUpdateLcvisColorMap();
  // Tracks inputs to the singleton color bar dialog (EditColorsBox)
  const [colorDialogState, setColorDialogState] = useState<ColorDialogState | null>(null);
  const [verticalColorPanels, setVerticalColorPanel] = useState<Map<string, boolean>>();

  const { getColorMap: getColorMapPv, updateColorMap: updateColorMapPv } = useParaviewContext();
  const [viewState] = useViewState(projectId);

  const getColorMap = useCallback((displayVariable: DisplayPvVariable) => {
    if (lcvisEnabled) {
      const lcvisColorMap = lcvisData.attrs.colorMaps?.find((keyAndValue) => (
        keyAndValue[0].displayDataName === displayVariable.displayDataName &&
        keyAndValue[0].displayDataNameComponent === displayVariable.displayDataNameComponent
      ))?.[1];
      if (lcvisColorMap) {
        return lcvisColorMap;
      }
    }
    return getColorMapPv(displayVariable);
  }, [lcvisData, lcvisEnabled, getColorMapPv]);

  const updateColorMap = (displayVariable: DisplayPvVariable, newCmap: ColorMap) => {
    if (lcvisEnabled) {
      try {
        updateLcvisColorMap(displayVariable, newCmap);
      } catch (error) {
        logger.error('Failed to update lcvis color map', displayVariable, newCmap);
      }
      return;
    }
    updateColorMapPv(displayVariable, newCmap);
  };

  const closeEditState = () => {
    setColorDialogState(null);
  };

  const updateMap = (name: string, isNewVertical: boolean) => {
    const newMap = new Map(verticalColorPanels);
    newMap.set(name, isNewVertical);

    // Set the new Map as the state
    setVerticalColorPanel(newMap);
  };

  const changeOrientation = (isVertical: boolean) => {
    if (colorDialogState) {
      updateMap(colorDialogState.name, isVertical);
    }
  };

  const getVertical = (name: string) => verticalColorPanels?.get(name) ?? true;

  const cancelEdit = () => {
    if (colorDialogState) {
      updateColorMap(colorDialogState.displayVariable, colorDialogState.initialCmap);
      closeEditState();
    }
  };

  const updateCmap = (cmap: ColorMap) => {
    if (colorDialogState && !deepEqual(colorDialogState.editedCmap, cmap)) {
      setColorDialogState({ ...colorDialogState, editedCmap: cmap });
    }
  };

  const generateColorMap = useCallback((displayVariable: DisplayPvVariable): ColorMap => {
    if (lcvisEnabled) {
      const cMap = getColorMap(displayVariable);
      if (cMap) {
        return cMap;
      }
      return lcvHandler.display?.workspace?.getColorMap(displayVariable) ?? defaultCmap();
    }
    return getColorMap(displayVariable) || defaultCmap();
  }, [getColorMap, lcvisEnabled]);

  const getName = useCallback(
    (displayVariable: DisplayPvVariable) => (lcvisEnabled ?
      displayVariableToTextFromData(displayVariable, lcvisData.data) :
      displayVariableToTextFromData(displayVariable, viewState?.data ?? [])
    ),
    [lcvisData, viewState, lcvisEnabled],
  );

  const initColorDialogState = (displayVariable: DisplayPvVariable) => {
    const name = getName(displayVariable);

    if (colorDialogState) {
      // There's already a dialog up
      if (colorDialogState.name !== name) {
        // If we've clicked a different color, reset the one we're editing
        cancelEdit();
      } else {
        // If we've clicked the same color bar, do nothing
        return;
      }
    }

    const initialCmap = generateColorMap(displayVariable);

    setColorDialogState({
      name,
      displayVariable,
      initialCmap,
      editedCmap: initialCmap,
    });
  };

  const colorBarsToShow = useCallback((displayVars: DisplayPvVariable[]) => (
    displayVars.map((displayVar, index) => {
      const label = getName(displayVar);
      return {
        displayVar,
        label,
        colorMap: generateColorMap(displayVar),
        dragId: `panel-${index}`,
      };
    })
  ), [generateColorMap, getName]);

  const lcvColorBarsToShow = useCallback(() => {
    if (!lcvisData) {
      return [];
    }
    const result = [] as {
      displayVar: DisplayPvVariable;
      label: string;
      colorMap: ColorMap;
      dragId: string;
    }[];

    const displayVariable = lcvisData.attrs.displayVariable;
    const mapOfColorMaps = new Map<string, ColorMap>();
    lcvisData.attrs.colorMaps?.forEach(([displayVar, colorMap]) => {
      mapOfColorMaps.set(
        `${displayVar.displayDataName}-${displayVar.displayDataNameComponent}`,
        colorMap,
      );
    });

    // Add the root color map (the one shown on the import dataset filter)
    if (displayVariable && displayVariable.displayDataName !== 'None') {
      const label = displayVariableToTextFromData(displayVariable, lcvisData.data);
      result.push({
        displayVar: displayVariable,
        label,
        colorMap: mapOfColorMaps.get(
          `${displayVariable.displayDataName}-${displayVariable.displayDataNameComponent}`,
        ) ?? generateColorMap(displayVariable),
        dragId: 'panel-x',
      });
    }
    // Check the filter state to see if there are any filter nodes with display variables
    // and attach the relevant color maps
    traverseTreeNodes(filterState, (node) => {
      const displayVar = node?.displayProps?.displayVariable;
      if (
        node.visible &&
        displayVar &&
        !result.some((bar) => deepEqual(bar.displayVar, displayVar))
      ) {
        const label = displayVariableToTextFromData(displayVar, lcvisData.data);
        if (displayVar.displayDataName !== 'None') {
          result.push({
            displayVar,
            label,
            colorMap: mapOfColorMaps.get(
              `${displayVar.displayDataName}-${displayVar.displayDataNameComponent}`,
            ) ?? generateColorMap(displayVar),
            dragId: `panel-${result.length}`,
          });
        }
      }
    });

    return result;
  }, [filterState, generateColorMap, lcvisData]);

  return {
    colorDialogState,
    setColorDialogState,
    closeEditState,
    cancelEdit,
    updateCmap,
    generateColorMap,
    initColorDialogState,
    colorBarsToShow,
    lcvColorBarsToShow,
    MAX_BINS,
    MAX_TICKS,
    changeOrientation,
    verticalColorPanels,
    getVertical,
  };
};
