// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { deepEqual } from 'fast-equals';
import { DefaultValue, atom, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { MeshMetadata } from '../lib/mesh';
import { Logger } from '../lib/observability/logs';
import * as path from '../lib/path';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import upgradePvproto from '../lib/upgradePvproto';
import { EditState, stripTreeNodeForLogging } from '../lib/visUtils';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

const logger = new Logger('recoil/paraviewState');

type MeshKeyPrefix = 'paraviewSettings' | 'cameraMode' | 'cameraPosition';

// The type of ParaView file being displayed. Used as part of the V1 key for Paraview related state.
// I.e. we use a different state key based on whether the file being displayed is a solution or mesh
// file.
type ParaviewFileType = 'solution' | 'mesh'

// V1 (current) version for all the ParaView-related state keys. It's a simplification over the V0
// which required accessing file metadata and whatnot. The new key version reflects the fact that
// the ParaView state for solution files is shared among them. Same for the mesh files.
function makeMeshKeyV1(prefix: MeshKeyPrefix, paraviewFileType: ParaviewFileType) {
  return `${prefix}/paraviewFileType=${paraviewFileType}`;
}

// V0 version for all the ParaView-related state keys. The key was based on the extension of the
// file being opened, the sha256= tag in the filename and the file::BaseComponent "out.*" tag also
// found in the file name. This key required reading the mesh metadata and did not really express
// the design intentions in terms of state management between setup and solution tabs. Moreover,
// when we added mesh generation to our software, the output lcmesh files stopped using the
// name=...&sha256=...& scheme for the filename and instead ended up being just a simple file name
// schema: lc_computational_mesh.lcmesh. See the V1 version of this function for more information.
/**
 * @deprecated This function is only used to ensure backwards compatibility with old keys in the
 * kvstore.
*/
function makeMeshKeyV0(prefix: MeshKeyPrefix, ext: string, sha256: string, name: string): string {
  return `${prefix}/ext=${ext}&sha256=${sha256}&name=${name}`;
}

// Create a unique mesh key for each project/mesh pairing.
//
// If url refers to a solution, then use the matching mesh URL for the sha256
// and name fields. This will cause all the paraview windows for the
// same workflow to share settings.
//
// The ext field separates mesh and solution files for the same mesh,
// because a solution file may contain filters, e.g., contour, that don't apply to a mesh.
export function newMeshKeys(
  prefix: MeshKeyPrefix,
  url: string,
  meshMeta: MeshMetadata | null,
): string[] {
  if (!url || !meshMeta) {
    return [];
  }

  // TODO(LC-7313): remove once we figure out why we are trying to open lcmeshbundle files here.
  if (url.endsWith('lcmeshbundle')) {
    throw Error(`Url not supported url=${url}`);
  }

  // In the simulation tabs we have a design constraint when it comes to state management. When we
  // create a simulation, the simulation tab is opened displaying the mesh file. In this
  // case we want to keep the Setup and Simulation tab in sync. As soon as a simulation file is
  // displayed, the state from the setup tab is copied over to the simulation tab (if the solution
  // file ParaView state has not been yet set) and from then on both states are allowed to diverge
  // i.e. the setup and simulation files state evolves differently).
  // NOTE: ideally we should check if url is a solution file. However, if url is a solution file
  // meshMeta.solnMetadata can still be null, resulting in issues when upgrading from V0 -> V1 keys.
  if (meshMeta.solnMetadata) { // solution file
    const lcmeshUrl = meshMeta.solnMetadata.meshUrl;
    const sha256 = path.urlParam(lcmeshUrl, 'sha256');
    const name = path.urlParam(lcmeshUrl, 'name');
    return [
      // The solution V1 key is first requested and if it's not found we fallback to the V0
      // of the solution file key. If the V0 solution is not found then fallback to the mesh/setup
      // page state.
      // Note that in the process of updating V0 to V1 keys, the V1 mesh key will not always exist
      // if the first two keys are not found, since the ParaView state may not have been updated,
      // when passing through the setup tab.
      makeMeshKeyV1(prefix, 'solution'),
      makeMeshKeyV0(prefix, path.extname(url), sha256, name),
      makeMeshKeyV1(prefix, 'mesh'),
      makeMeshKeyV0(prefix, path.extname(url), sha256, name),
    ];
  }
  // mesh file.
  const sha256 = path.urlParam(url, 'sha256');
  const name = path.urlParam(url, 'name');

  // Default to the V1 key and if not found fallback to the V0 one.
  return [makeMeshKeyV1(prefix, 'mesh'), makeMeshKeyV0(prefix, path.extname(url), sha256, name)];
}

// Parameter to use when connecting to paraview to initialize a view. it is a
// subset of ParaviewRpc.ViewState that can be applied to another related
// mesh.  See ParaviewRpc.ViewState for descriptions of fields.
export interface ParaviewInitialSettings {
  // The list of filters created under the root reader.
  filters: ParaviewRpc.TreeNode[];
  // Attrbutes, e.g., data representation and array types.
  attrs: ParaviewRpc.ViewAttrs;
}

const DEFAULT_SETTINGS = {
  filters: [],
  attrs: { viewAttrsVersion: ParaviewRpc.PVPROTO_VIEW_ATTRS_VERSION },
};

export type RecoilKey = {
  projectId: string;
  // Ordered list of keys to fetch.  Each elem is of form
  // 'paraviewSettings/makeMeshKey(ext,sha,name)`.  For a solution, it lists key
  // for lcsoln, then key for lcmesh.  For a mesh, it only contains the key for
  // lcmesh.
  meshKeys: string[];
}

export enum CameraMode {
  PERSPECTIVE,
  Z_MINUS,
  Z_PLUS,
  X_PLUS,
  X_MINUS,
  Y_PLUS,
  Y_MINUS,
  ORTHOGRAPHIC,
  NE,
  NW,
  SE,
  SW,
}

function serialize<T>(val: T): Uint8Array {
  const value = new projectstatepb.ParaviewSetting({
    json: JSON.stringify(val),
  });
  return value.toBinary();
}

function deserializeInternal<T>(defaultVal: T) {
  return (val: Uint8Array, _?: number): T => (val.length ?
    JSON.parse(projectstatepb.ParaviewSetting.fromBinary(val).json) :
    defaultVal) as T;
}

function deserializeSettings(val: Uint8Array, index?: number): ParaviewInitialSettings {
  let settings = (val.length ?
    JSON.parse(projectstatepb.ParaviewSetting.fromBinary(val).json) :
    DEFAULT_SETTINGS as ParaviewInitialSettings);
  if (!val.length) {
    logger.warn('Failed to fetch paraview initial settings. ', JSON.stringify(projectstatepb));
  }
  // Only upgrade the _proto_ if it already existed in the project state.
  if (settings !== DEFAULT_SETTINGS) {
    settings = upgradePvproto(settings);
  }
  if (index && index > 0) { // found settings for lcmesh, not lcsoln.
    delete settings.attrs.reprType;
  }
  return settings;
}

const deserializeCameraMode = deserializeInternal<CameraMode>(CameraMode.PERSPECTIVE);

const deserializeCameraPosition = deserializeInternal<ParaviewRpc.CameraState | null>(null);

export const paraviewSettingsKeyPrefix = 'paraviewSettings';

export const paraviewInitialSettingsState = atomFamily<ParaviewInitialSettings, RecoilKey>({
  key: paraviewSettingsKeyPrefix,
  default: (key: RecoilKey) => {
    if (!key.meshKeys.length) {
      return DEFAULT_SETTINGS;
    }
    return persist.getProjectState(key.projectId, key.meshKeys, deserializeSettings);
  },
  effects: (key: RecoilKey) => (!key.meshKeys.length ?
    [] :
    [
      syncProjectStateEffect(
        key.projectId,
        key.meshKeys[0],
        deserializeSettings,
        serialize,
      ),
      ({ onSet }) => {
        onSet((newVal, oldVal) => {
          if (oldVal instanceof DefaultValue) {
            logger.info(
              `Setting vis filter state. projectId=${key.projectId}, meshKeys=${key.meshKeys}. `,
              JSON.stringify(newVal.filters.map(stripTreeNodeForLogging)),
            );
            return;
          }

          const newSettings = newVal.filters.map(stripTreeNodeForLogging);
          const oldSettings = oldVal.filters.map(stripTreeNodeForLogging);
          if (!deepEqual(newSettings, oldSettings)) {
            logger.info(
              `Updating vis filter state. projectId=${key.projectId}, meshKeys=${key.meshKeys}. `,
              JSON.stringify(newSettings),
            );
          }
        });
      },
    ]),
  dangerouslyAllowMutability: true,
});

const cameraModeState = atomFamily<CameraMode, RecoilKey>({
  key: 'cameraMode',
  default: (key: RecoilKey) => {
    if (!key.meshKeys.length) {
      return CameraMode.PERSPECTIVE;
    }
    return persist.getProjectState(key.projectId, key.meshKeys, deserializeCameraMode);
  },
  effects: (key: RecoilKey) => (!key.meshKeys.length ?
    [] :
    [
      syncProjectStateEffect(
        key.projectId,
        key.meshKeys[0],
        deserializeCameraMode,
        serialize,
      ),
    ]),
});

export const cameraPositionState = atomFamily<ParaviewRpc.CameraState | null, RecoilKey>({
  key: 'cameraPosition',
  default: (key: RecoilKey) => {
    if (!key.meshKeys.length) {
      return null;
    }
    return persist.getProjectState(key.projectId, key.meshKeys, deserializeCameraPosition);
  },
  effects: (key: RecoilKey) => (!key.meshKeys.length ?
    [] :
    [
      syncProjectStateEffect(
        key.projectId,
        key.meshKeys[0],
        deserializeCameraPosition,
        serialize,
      ),
    ]),
});

// Get the paraview settings that can be used as parameters to syncNodes.  Url
// is a *.lcmesh or *.lcsoln URL. MeshMeta is the attributes of the URL.  If
// either url or meshMeta is unset, it returns empty settings.
export function useParaviewInitialSettings(
  projectId: string,
  url: string,
  meshMeta: MeshMetadata | null,
): [ParaviewInitialSettings | null, (newSettings: ParaviewInitialSettings) => void] {
  const key: RecoilKey = {
    projectId,
    meshKeys: newMeshKeys(paraviewSettingsKeyPrefix, url, meshMeta),
  };
  return useRecoilState(paraviewInitialSettingsState(key));
}

// A variation of useParaviewInitialSettings that returns only the setter function.
export function useSetParaviewInitialSettings(
  projectId: string,
  url: string,
  meshMeta: MeshMetadata | null,
): (newSettings: ParaviewInitialSettings) => void {
  const key: RecoilKey = {
    projectId,
    meshKeys: newMeshKeys(paraviewSettingsKeyPrefix, url, meshMeta),
  };
  return useSetRecoilState(paraviewInitialSettingsState(key));
}

export function useCameraMode(
  projectId: string,
  url: string,
  meshMeta: MeshMetadata | null,
): [CameraMode, (newMode: CameraMode) => void] {
  const key: RecoilKey = {
    projectId,
    meshKeys: newMeshKeys('cameraMode', url, meshMeta),
  };
  return useRecoilState(cameraModeState(key));
}

export function useCameraPosition(
  projectId: string,
  url: string,
  meshMeta: MeshMetadata | null,
): [ParaviewRpc.CameraState | null, (newPos: ParaviewRpc.CameraState | null) => void] {
  const key: RecoilKey = {
    projectId,
    meshKeys: newMeshKeys('cameraPosition', url, meshMeta),
  };
  return useRecoilState(cameraPositionState(key));
}

// Records the ongoing filter edit activity. Value of null means no editing is
// going on.
export const editStateState = atom<EditState | null>({
  key: 'paraviewEditState',
  default: null,
});

// Returns a [getter, setter] combo for the ongoing filter edit activity.
// Must be called inside a react component.
export function useEditState() {
  return useRecoilState(editStateState);
}

// Returns the current filter edit activity.
// Must be called inside a react component.
export function useEditStateValue(): EditState | null {
  return useRecoilValue(editStateState);
}

// Returns a setter for the filter edit activity.
// Must be called inside a react component.
export function useSetEditState(): (value: EditState | null) => void {
  return useSetRecoilState(editStateState);
}

export function useIsEditingFilterType() {
  const editState = useEditStateValue();

  return useCallback((type) => {
    if (editState) {
      return editState.param.typ === type;
    }
    return false;
  }, [editState]);
}
