export default abstract class BaseError extends Error {
  private sourceError?: Error;

  constructor(message: string, source?: Error) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.sourceError = source;
  }

  // Public Properties
  public abstract override name: string;
  public handled = false;
  public get source() {
    return this.sourceError;
  }

  // Computed Properties
  /**
   * The concatenated stacktraces of this error, and all chained source Errors.
   */
  public get fullstack() {
    let current: Error | undefined = this;
    const stack = [];
    while (current) {
      stack.push(current.stack);
      if (current instanceof BaseError) {
        current = current.source;
      } else {
        break;
      }
    }
    return stack.join('\n');
  }

  /**
   * The original Error in the chain.
   */
  public get root() {
    return (this.source as any)?.root ?? this.source ?? this;
  }

  /**
   * The lowest BaseError in the chain.
   */
  public get trunk(): BaseError {
    if (this.source instanceof BaseError) {
      return this.source.trunk;
    }
    return this;
  }
}
