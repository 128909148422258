// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useCallback, useEffect, useMemo } from 'react';

import { Message } from '@bufbuild/protobuf';

import * as ProtoDescriptor from '../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../SimulationParamDescriptor';
import { ParamScope } from '../../lib/ParamScope';
import assert from '../../lib/assert';
import { findFluidBoundaryCondition, findHeatBoundaryCondition } from '../../lib/boundaryConditionUtils';
import { SelectOption } from '../../lib/componentTypes/form';
import { colors } from '../../lib/designSystem';
import { protoChoicesToSelectOptions } from '../../lib/form';
import { findHeatSourceById } from '../../lib/heatSourceUtils';
import newInt from '../../lib/intUtils';
import { findMaterialEntityById } from '../../lib/materialUtils';
import { fromBigInt } from '../../lib/number';
import { setParamValue } from '../../lib/paramCallback';
import { extractProtoField } from '../../lib/proto';
import {
  getCompatibleTablesMap,
  hasKeyReference,
  updateEntryReference,
} from '../../lib/rectilinearTable/globalMap';
import {
  ProfileBCTableDefintion,
  TempVaryingConductivityTableDefinition,
  TempVaryingViscosityTableDefinition,
  getProfileBCTableDefintion,
} from '../../lib/rectilinearTable/model';
import { checkProfileBC, checkTempVary } from '../../lib/rectilinearTable/util';
import { useFluidBoundaryCondition } from '../../model/hooks/useFluidBoundaryCondition';
import { useHeatBoundaryCondition } from '../../model/hooks/useHeatBoundaryCondition';
import { useHeatSource } from '../../model/hooks/useHeatSource';
import { useMaterialEntity } from '../../model/hooks/useMaterialEntity';
import { AdFloatType } from '../../proto/base/base_pb';
import * as basepb from '../../proto/base/base_pb';
import * as simulationpb from '../../proto/client/simulation_pb';
import { DataSelect } from '../Form/DataSelect';
import LabeledInput, { LabeledInputProps } from '../Form/LabeledInput';
import { InsertElement } from '../ParamForm';
import ParamRow, { ParamRowProps } from '../ParamRow';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { ChangeOperation, TableMapConfig } from '../controls/TableMapInput';
import { NumberAndTableInput } from '../controls/TableMapInput/NumberAndTableInput';
import {
  TableMapColumnSelector,
  TableMapColumnSelectorProps,
} from '../controls/TableMapInput/TableMapColumnSelector';

import { useSimulationConfig } from './useSimulationConfig';

// #region Constants

export const FluidBCColumnParams = [
  ParamName.InletVelocityMagnitudeCol,
  ParamName.TotalTemperatureCol,
  ParamName.TotalPressureCol,
  ParamName.BcNuTildeCol,
  ParamName.BcTkeCol,
  ParamName.BcOmegaCol,
];
const HeatColumnParams = [ParamName.HeatFluxCol, ParamName.HeatSourceCol];

/** Params for materials that the UI should represent as having tabular data */
const MaterialTableParams = [
  ParamName.LaminarConstantThermalConductivityConstant,
  ParamName.LaminarConstantViscosityConstant,
];

const TabularDataRemoveParams = ['ProfileBcData', 'ProfileSourceData'];

/** Params that need to be removed from the generated ParamForm */
export const FluidBCRemoveParams = [
  ...TabularDataRemoveParams,
  ...FluidBCColumnParams.map((paramName) => paramDesc[paramName].pascalCaseName),
];
/** Params that need to be removed from the generated ParamForm */
export const HeatRemoveParams = [
  ...TabularDataRemoveParams,
  ...HeatColumnParams.map((paramName) => paramDesc[paramName].pascalCaseName),
];
/** Params that need to be removed from the generated ParamForm */
export const TempDependentMaterialRemoveParams = [
  'ThermalConductivityTableData',
  'DynamicViscosityTableData',
];

export const ThermalConductivityRemoveParams = ['ThermalConductivityTableData'];

const profileBcDesc = paramDesc[ParamName.ProfileBc];
const profileBcDataDesc = paramDesc[ParamName.ProfileBcData];
const profileTypeDesc = paramDesc[ParamName.ProfileType];

const inletEnergyDesc = paramDesc[ParamName.InletEnergy];
const inletMomentumDesc = paramDesc[ParamName.InletMomentum];
const inletVelMagDesc = paramDesc[ParamName.InletVelocityMagnitude];
const turbSpecSaDesc = paramDesc[ParamName.TurbulenceSpecificationSpalartAllmaras];
const turbSpecKomegaDesc = paramDesc[ParamName.TurbulenceSpecificationKomega];
const totPresDesc = paramDesc[ParamName.TotalPressure];
const totTempDesc = paramDesc[ParamName.TotalTemperature];
const bcNuTildeDesc = paramDesc[ParamName.BcUniformNuTilde];
const bcUniformTkeDesc = paramDesc[ParamName.BcUniformTke];
const bcUniformOmegaDesc = paramDesc[ParamName.BcUniformOmega];

const fixedHeatFluxDesc = paramDesc[ParamName.FixedHeatFlux];
const fixedIntHeatFluxDesc = paramDesc[ParamName.FixedIntegratedHeatFlux];

const profileSourceDesc = paramDesc[ParamName.ProfileSource];
const heatSourcePowerDesc = paramDesc[ParamName.HeatSourcePower];
const heatSourcePowerPerUnitDesc = paramDesc[ParamName.HeatSourcePowerPerUnitVolume];

const thermalConductivityFluidModelDesc = paramDesc[ParamName.LaminarThermalConductivity];
const thermalConductivityFluidConstantDesc = paramDesc[
  ParamName.LaminarConstantThermalConductivityConstant
];
const thermalViscosityModelDesc = paramDesc[ParamName.LaminarViscosityModelNewtonian];
const thermalViscosityConstantDesc = paramDesc[
  ParamName.LaminarConstantViscosityConstant
];
const thermalConductivitySolidConstantDesc = paramDesc[
  ParamName.ThermalConductivityConstantSolid
];

const { HEAT_BC_INTEGRATED_HEAT_FLUX } = simulationpb.HeatPhysicalBoundary;
const { HEAT_SOURCE_TYPE_POWER_PER_UNIT_OF_VOLUME } = simulationpb.HeatSourceType;
const {
  LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY,
  TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY,
} = simulationpb.LaminarThermalConductivity;
const {
  LAMINAR_CONSTANT_VISCOSITY,
  TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY,
} = simulationpb.LaminarViscosityModelNewtonian;

// #region Helper Functions

/**
 * @param paramNames name of params that take in a table value
 * @returns a list of param choices that can enable a profile bc as a field
 */
function findProfileParamChoices(paramNames: ParamName[]) {
  // recursively filter for conds that can enable a profile bc field as some conds are dependent
  // on other params
  const recursiveFilterChoices = (conds: ProtoDescriptor.Cond) => {
    const result: ProtoDescriptor.CondChoice[] = [];
    if (
      conds.type === ProtoDescriptor.CondType.ALL || conds.type === ProtoDescriptor.CondType.ANY
    ) {
      result.push(...conds.list.flatMap((choice) => recursiveFilterChoices(choice)));
    } else if (conds.type === ProtoDescriptor.CondType.CHOICE) {
      result.push(conds);
    }
    return result;
  };
  return paramNames.reduce((acc, paramName) => {
    const param = paramDesc[paramName];
    if (param.cond) {
      acc.push(...recursiveFilterChoices(param.cond));
    }
    return acc;
  }, [] as ProtoDescriptor.CondChoice[]);
}

/**
 * @returns an error message if the sim param already contains the provided table name, or an empty
 * otherwise
 */
function tableNameValidation(name: string, simParam: simulationpb.SimulationParam) {
  if (hasKeyReference(simParam, name)) {
    return 'Name is already in use';
  }
  return '';
}

// #region Helper Components

interface ProfileSelectorProps
  extends Omit<ParamRowProps, 'nestLevel' | 'param'>,
  Omit<TableMapConfig, 'tableMap' | 'nameErrorFunc'> {
  simParam: simulationpb.SimulationParam;
  onlyNonnegative?: boolean;
}

/** Create a TableMapInput ParamRow with the given props */
function ProfileSelector(props: ProfileSelectorProps) {
  const {
    simParam, projectId, dialogTitle, dialogSubtitle, tableDefinition, onlyNonnegative, readOnly,
    setValue, value, gridPreview, dialogPreview, uploadHelp,
  } = props;
  const paramRowProps: ParamRowProps = {
    inputOptions: {
      tableMapOptions: {
        dialogTitle,
        dialogSubtitle,
        tableMap: getCompatibleTablesMap(simParam, tableDefinition),
        tableDefinition,
        tableErrorFunc: (table) => checkProfileBC(table, onlyNonnegative),
        nameErrorFunc: (name) => tableNameValidation(name, simParam),
        uploadOptions: {
          inputAccept: '.csv',
        },
        unlinkTooltip: 'Unlink profile file',
        gridPreview,
        dialogPreview,
        uploadHelp,
        disableHelp: !uploadHelp,
      },
    },
    nestLevel: 0,
    projectId,
    param: profileBcDataDesc,
    readOnly,
    setValue,
    value,
  };
  return <ParamRow key="profile-file-select" {...paramRowProps} />;
}

/**
 * @returns a DataSelect with only the "Time" option selected
 */
function ProfileTypeTimeDataSelect() {
  return (
    <LabeledInput label="Profile Type">
      <DataSelect
        asBlock
        disabled
        onChange={() => { }}
        options={[{ name: 'Time', value: 'Time', selected: true }]}
        size="small"
      />
    </LabeledInput>
  );
}

const defaultTooltip = 'You can assign a table as a value';

interface DataSelectProfileProps {
  /** The multiple choice param to be rendered as a DataSelect */
  multipleChoiceDescriptor: ProtoDescriptor.MultipleChoiceParam,
  /** The param to be modified */
  param: Message,
  paramScope: ParamScope,
  /** The choices in the param that can have a table value */
  columnChoices: ProtoDescriptor.CondChoice[],
  /** Callback for when the data select changes */
  onChange?: ((value: number) => void),
  /** Modify the select options for the data select */
  modifyOptions?: (options: SelectOption<number>[]) => SelectOption<number>[],
  /** Default tooltip */
  tooltip?: string,
}

/**
 * @returns a DataSelect with options that have an auxIcon if the option can have a table value
 */
function DataSelectProfile(props: DataSelectProfileProps) {
  const {
    multipleChoiceDescriptor, param, paramScope, columnChoices, onChange, modifyOptions,
  } = props;

  // == Contexts
  const { readOnly } = useProjectContext();

  // == Data
  const enabledChoices = new Set(paramScope.enabledChoices(multipleChoiceDescriptor));

  const filteredChoices = multipleChoiceDescriptor.choices.filter(
    (choice) => enabledChoices.has(choice),
  );

  const selectOptions = useMemo(() => {
    const selection = protoChoicesToSelectOptions(
      filteredChoices,
      extractProtoField(param, multipleChoiceDescriptor),
    ).map((option) => {
      if (columnChoices.find((choice) => choice.choice === option.value) !== undefined) {
        option.auxIcon = {
          name: 'tableOutlined',
          color: colors.inputPlaceholderText,
          tooltip: props.tooltip || defaultTooltip,
        };
      }
      return option;
    });
    return modifyOptions ? modifyOptions(selection) : selection;
  }, [columnChoices, filteredChoices, modifyOptions, multipleChoiceDescriptor, param, props]);

  return (
    <LabeledInput
      help={multipleChoiceDescriptor.help}
      label={multipleChoiceDescriptor.text}>
      <DataSelect
        asBlock
        disabled={readOnly}
        locator={multipleChoiceDescriptor.name}
        onChange={onChange}
        options={selectOptions}
        size="small"
      />
    </LabeledInput>
  );
}

interface LabeledColumnSelectorProps
  extends LabeledInputProps,
  Omit<TableMapColumnSelectorProps, 'selectedTableData'> {
  simParam: simulationpb.SimulationParam;
  profileBcData: string;
}

/** Create a TableMapColumnSelector with a labeled input */
function LabeledColumnSelector(props: LabeledColumnSelectorProps) {
  const {
    projectId, simParam, value, updateValue, columnIndex, updateColumnIndex, disabledColumns,
    profileBcData, param, label, help, disabled,
  } = props;
  return (
    <LabeledInput
      help={help ?? 'Select data series for profile.'}
      label={label}>
      <TableMapColumnSelector
        columnIndex={columnIndex}
        disabled={disabled}
        // undefined values should be filtered out already
        disabledColumns={disabledColumns}
        param={param}
        projectId={projectId}
        selectedTableData={
          getCompatibleTablesMap(simParam, ProfileBCTableDefintion).get(
            profileBcData,
          )!
        }
        updateColumnIndex={updateColumnIndex}
        updateValue={updateValue}
        value={value}
      />
    </LabeledInput>
  );
}

export interface TabularDataResult {
  /** ParamForm elements that need to be inserted into the generated ParamRow */
  insertTabularElements: InsertElement[];
}

// #region Hooks

/**
 * Hook to generate the tabular data form elements for the fluid boundary condition
 *
 * In addition to creating the profile and column selectors, some of the data select params are
 * also modified in order to display a "profile bc" icon next to the option that can have a table
 * value.
 *
 * @param nodeId the currently selected heat boundary condition node ID
 * @param paramScope the current paramscope
 * @param isInletFanCurve whether the inlet fan curve is enabled for this boundary condition
 */
export function useFluidBCTabularData(
  nodeId: string,
  paramScope: ParamScope,
  isInletFanCurve: boolean,
): TabularDataResult {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const { simParam, saveParam } = useSimulationConfig();

  // == Custom hooks
  const { boundaryCondition, saveBoundaryCondition } = useFluidBoundaryCondition(
    projectId,
    workflowId,
    jobId,
    readOnly,
    nodeId,
  );
  assert(!!boundaryCondition, 'Fluid boundary condition not found (in tabular data)');

  // == Data
  const profileBcOn = boundaryCondition.profileBc;

  const columnChoices = useMemo(() => findProfileParamChoices(FluidBCColumnParams), []);

  // when the profile BC is on, do not let the user select Fan Curve, LC-18169
  const filteredInletMomentumDesc = useMemo(() => {
    const descriptor = structuredClone(
      inletMomentumDesc,
    ) as ProtoDescriptor.MultipleChoiceParam;
    descriptor.choices = descriptor.choices.filter((choice) => choice.name !== 'FAN_CURVE_INLET');
    return descriptor;
  }, []);

  const selectedColumns = useMemo(() => [
    fromBigInt(boundaryCondition.inletVelocityMagnitudeCol?.value ?? 0n),
    fromBigInt(boundaryCondition.totalPressureCol?.value ?? 0n),
    fromBigInt(boundaryCondition.totalTemperatureCol?.value ?? 0n),
    fromBigInt(boundaryCondition.bcNuTildeCol?.value ?? 0n),
    fromBigInt(boundaryCondition.bcTkeCol?.value ?? 0n),
    fromBigInt(boundaryCondition.bcOmegaCol?.value ?? 0n),
  ].filter((col) => col !== 0), [boundaryCondition]);

  const changeProfileTableField = (value: ChangeOperation) => {
    saveParam((newParam) => {
      const newCondition = findFluidBoundaryCondition(newParam, nodeId);
      if (newCondition) {
        const metadata = 'metadata' in value ? value.metadata : undefined;
        const link = updateEntryReference(value.type, value.name, newParam, metadata);
        if (link) {
          newCondition.profileBcData = value.name as string;
          newCondition.inletVelocityMagnitudeCol = newInt(0);
          newCondition.totalPressureCol = newInt(0);
          newCondition.totalTemperatureCol = newInt(0);
          newCondition.bcNuTildeCol = newInt(0);
          newCondition.bcTkeCol = newInt(0);
          newCondition.bcOmegaCol = newInt(0);
        }
      }
    });
  };

  const insertElements: InsertElement[] = [];
  if (profileBcOn) {
    insertElements.push(
      {
        element: (
          <ProfileSelector
            dialogSubtitle={(
              <div>
                Upload a profile distribution file to model your inlet. The CSV file must include
                multiple columns. The initial column should consist of coordinates representing the
                spatial or temporal variation of the profile. Subsequent columns should contain
                quantities (such as velocity) that can be specified at the boundary condition.
                You can use a header row to label these columns.
              </div>
            )}
            dialogTitle="Profile File Upload"
            disableHelp
            projectId={projectId}
            readOnly={readOnly}
            setValue={changeProfileTableField}
            simParam={simParam}
            tableDefinition={ProfileBCTableDefintion}
            value={boundaryCondition.profileBcData}
          />
        ),
        insert: profileBcDesc,
        replace: false,
      },
      ...[inletEnergyDesc, filteredInletMomentumDesc, turbSpecSaDesc, turbSpecKomegaDesc].map(
        (descriptor) => ({
          element: (
            <DataSelectProfile
              columnChoices={columnChoices}
              multipleChoiceDescriptor={descriptor as ProtoDescriptor.MultipleChoiceParam}
              onChange={async (newValue) => {
                await saveBoundaryCondition((newBoundaryCondition) => {
                  setParamValue(newBoundaryCondition, descriptor, newValue);
                  return newBoundaryCondition;
                });
              }}
              param={boundaryCondition}
              paramScope={paramScope}
            />
          ),
          insert: descriptor,
          replace: true,
        }),
      ),
      {
        element: (
          <LabeledColumnSelector
            columnIndex={fromBigInt(boundaryCondition?.totalPressureCol?.value ?? 0n)}
            // when a fan curve is used, the total pressure is not dependent on a multiple
            // choice param so its label must be displayed
            disabledColumns={selectedColumns}
            label={isInletFanCurve ? 'Total Pressure' : ''}
            param={totPresDesc}
            profileBcData={boundaryCondition.profileBcData}
            projectId={projectId}
            simParam={simParam}
            updateColumnIndex={(val: number) => saveBoundaryCondition((newCond) => {
              newCond.totalPressureCol = newInt(val);
            })}
            updateValue={(val: AdFloatType) => saveBoundaryCondition((newCond) => {
              newCond.totalPressure = val;
            })}
            value={boundaryCondition.totalPressure!}
          />
        ),
        insert: totPresDesc,
        replace: true,
      },
      {
        element: (
          <LabeledColumnSelector
            columnIndex={fromBigInt(boundaryCondition.totalTemperatureCol?.value ?? 0)}
            disabledColumns={selectedColumns}
            label=""
            param={totTempDesc}
            profileBcData={boundaryCondition.profileBcData}
            projectId={projectId}
            simParam={simParam}
            updateColumnIndex={(val: number) => saveBoundaryCondition((newCond) => {
              newCond.totalTemperatureCol = newInt(val);
            })}
            updateValue={(val: AdFloatType) => saveBoundaryCondition((newCond) => {
              newCond.totalTemperature = val;
            })}
            value={boundaryCondition.totalTemperature!}
          />
        ),
        insert: totTempDesc,
        replace: true,
      },
      {
        element: (
          <LabeledColumnSelector
            columnIndex={fromBigInt(boundaryCondition.inletVelocityMagnitudeCol?.value ?? 0n)}
            disabledColumns={selectedColumns}
            label=""
            param={inletVelMagDesc}
            profileBcData={boundaryCondition.profileBcData}
            projectId={projectId}
            simParam={simParam}
            updateColumnIndex={(val: number) => saveBoundaryCondition((newCond) => {
              newCond.inletVelocityMagnitudeCol = newInt(val);
            })}
            updateValue={(val: AdFloatType) => saveBoundaryCondition((newCond) => {
              newCond.inletVelocityMagnitude = val;
            })}
            value={boundaryCondition?.inletVelocityMagnitude!}
          />
        ),
        insert: inletVelMagDesc,
        replace: true,
      },
      {
        element: (
          <LabeledColumnSelector
            columnIndex={fromBigInt(boundaryCondition.bcNuTildeCol?.value ?? 0n)}
            disabledColumns={selectedColumns}
            label=""
            param={bcNuTildeDesc}
            profileBcData={boundaryCondition.profileBcData}
            projectId={projectId}
            simParam={simParam}
            updateColumnIndex={(val: number) => saveBoundaryCondition((newCond) => {
              newCond.bcNuTildeCol = newInt(val);
            })}
            updateValue={(val: AdFloatType) => saveBoundaryCondition((newCond) => {
              newCond.bcUniformNuTilde = val;
            })}
            value={boundaryCondition.bcUniformNuTilde!}
          />
        ),
        insert: bcNuTildeDesc,
        replace: true,
      },
      {
        element: (
          <LabeledColumnSelector
            columnIndex={fromBigInt(boundaryCondition.bcTkeCol?.value ?? 0n)}
            disabledColumns={selectedColumns}
            help={bcUniformTkeDesc.help}
            label={bcUniformTkeDesc.text}
            param={bcUniformTkeDesc}
            profileBcData={boundaryCondition.profileBcData}
            projectId={projectId}
            simParam={simParam}
            updateColumnIndex={(val: number) => saveBoundaryCondition((newCond) => {
              newCond.bcTkeCol = newInt(val);
            })}
            updateValue={(val: AdFloatType) => saveBoundaryCondition((newCond) => {
              newCond.bcUniformTke = val;
            })}
            value={boundaryCondition.bcUniformTke!}
          />
        ),
        insert: bcUniformTkeDesc,
        replace: true,
      },
      {
        element: (
          <LabeledColumnSelector
            columnIndex={fromBigInt(boundaryCondition.bcOmegaCol?.value ?? 0n)}
            disabledColumns={selectedColumns}
            help={bcUniformOmegaDesc.help}
            label={bcUniformOmegaDesc.text}
            param={bcUniformOmegaDesc}
            profileBcData={boundaryCondition.profileBcData}
            projectId={projectId}
            simParam={simParam}
            updateColumnIndex={(val: number) => saveBoundaryCondition((newCond) => {
              newCond.bcOmegaCol = newInt(val);
            })}
            updateValue={(val: AdFloatType) => saveBoundaryCondition((newCond) => {
              newCond.bcUniformOmega = val;
            })}
            value={boundaryCondition.bcUniformOmega!}
          />
        ),
        insert: bcUniformOmegaDesc,
        replace: true,
      },
    );
  }

  return { insertTabularElements: insertElements };
}

/**
 * Hook to generate the tabular data form elements for the heat boundary condition
 *
 * If the profile BC is on, then the profile type is automatically set to Time. Otherwise, it is
 * set to Cartesian X.
 *
 * @param nodeId the currently selected heat boundary condition node ID
 */
export function useHeatBCTabularData(nodeId: string): TabularDataResult {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const { simParam, saveParam } = useSimulationConfig();

  // == Custom hooks
  const { boundaryCondition, saveBoundaryCondition } = useHeatBoundaryCondition(
    projectId,
    workflowId,
    jobId,
    readOnly,
    nodeId,
  );
  assert(!!boundaryCondition, 'Heat boundary condition not found (in tabular data)');

  // == Data
  const profileBcOn = boundaryCondition.profileBc;

  useEffect(() => {
    if (profileBcOn && boundaryCondition.profileType !== simulationpb.ProfileType.TIME) {
      // transient CHT must have the Time profile type
      saveParam((newParam) => {
        const newCondition = findHeatBoundaryCondition(newParam, nodeId);
        if (newCondition) {
          newCondition.profileType = simulationpb.ProfileType.TIME;
        }
      });
    } else if (
      !profileBcOn && boundaryCondition.profileType === simulationpb.ProfileType.TIME
    ) {
      // the default profile type is cartesian X
      saveParam((newParam) => {
        const newCondition = findHeatBoundaryCondition(newParam, nodeId);
        if (newCondition) {
          newCondition.profileType = simulationpb.ProfileType.CARTESIAN_X;
        }
      });
    }
  }, [boundaryCondition, nodeId, profileBcOn, saveParam]);

  const selectedColumns = useMemo(
    () => [fromBigInt(boundaryCondition.heatFluxCol?.value ?? 0)].filter((col) => col && col !== 0),
    [boundaryCondition],
  );

  const changeProfileTableField = (value: ChangeOperation) => {
    saveParam((newParam) => {
      const newCondition = findHeatBoundaryCondition(newParam, nodeId);
      if (newCondition) {
        const metadata = 'metadata' in value ? value.metadata : undefined;
        const link = updateEntryReference(
          value.type,
          value.name,
          newParam,
          metadata,
        );
        if (link) {
          newCondition.profileBcData = value.name as string;
          newCondition.heatFluxCol = newInt(0);
        }
      }
    });
  };

  const tableDefinition = getProfileBCTableDefintion('Time (s)');

  const insertElements: InsertElement[] = [];

  if (profileBcOn) {
    insertElements.push(
      {
        element: (
          <ProfileSelector
            dialogPreview
            dialogSubtitle={(
              <div>
                Upload a profile distribution file to model your heat flux. The CSV file must
                include multiple columns. The initial column should consist of time (s). Subsequent
                columns should contain Heat Flux (W/m<sup>2</sup>) quantities. You can use a header
                row to label these columns.
              </div>
            )}
            dialogTitle="Profile File Upload"
            gridPreview
            onlyNonnegative={boundaryCondition.profileType === simulationpb.ProfileType.TIME}
            projectId={projectId}
            readOnly={readOnly}
            setValue={changeProfileTableField}
            simParam={simParam}
            tableDefinition={tableDefinition}
            value={boundaryCondition.profileBcData}
          />
        ),
        insert: profileBcDesc,
        replace: false,
      },
      {
        element: (
          <ProfileTypeTimeDataSelect />
        ),
        insert: profileTypeDesc,
        replace: true,
      },
    );

    const isIntegrated = boundaryCondition.heatPhysicalBoundary === HEAT_BC_INTEGRATED_HEAT_FLUX;
    const descriptor = isIntegrated ? fixedIntHeatFluxDesc : fixedHeatFluxDesc;
    const value = isIntegrated ?
      boundaryCondition.fixedIntegratedHeatFlux! :
      boundaryCondition.fixedHeatFlux!;

    insertElements.push(
      {
        element: (
          <LabeledColumnSelector
            columnIndex={fromBigInt(boundaryCondition?.heatFluxCol?.value ?? 0)}
            disabledColumns={selectedColumns}
            label={descriptor.text}
            param={descriptor}
            profileBcData={boundaryCondition.profileBcData}
            projectId={projectId}
            simParam={simParam}
            updateColumnIndex={(val: number) => saveBoundaryCondition((newCond) => {
              newCond.heatFluxCol = newInt(val);
            })}
            updateValue={(val: AdFloatType) => saveBoundaryCondition((newCond) => {
              if (isIntegrated) {
                newCond.fixedIntegratedHeatFlux = value;
              } else {
                newCond.fixedHeatFlux = value;
              }
            })}
            value={value}
          />
        ),
        insert: descriptor,
        replace: true,
      },
    );
  }

  return { insertTabularElements: insertElements };
}

export function useHeatSourceTabularData(nodeId: string): TabularDataResult {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const { simParam, saveParam } = useSimulationConfig();

  // == Custom hooks
  const {
    heatSource,
    saveHeatSource,
  } = useHeatSource(projectId, workflowId, jobId, readOnly, nodeId);
  assert(!!heatSource, 'Heat source not found (in tabular data)');

  const profileSourceOn = heatSource?.profileSource as boolean;

  useEffect(() => {
    if (profileSourceOn && heatSource.profileType !== simulationpb.ProfileType.TIME) {
      // transient CHT must have the Time profile type
      saveParam((newParam) => {
        const newCondition = findHeatSourceById(newParam, nodeId);
        if (newCondition) {
          newCondition.profileType = simulationpb.ProfileType.TIME;
        }
      });
    } else if (
      !profileSourceOn && heatSource.profileType === simulationpb.ProfileType.TIME
    ) {
      // the default profile type is cartesian X
      saveParam((newParam) => {
        const newCondition = findHeatSourceById(newParam, nodeId);
        if (newCondition) {
          newCondition.profileType = simulationpb.ProfileType.CARTESIAN_X;
        }
      });
    }
  }, [heatSource, nodeId, profileSourceOn, saveParam]);

  const selectedColumns = useMemo(
    () => [fromBigInt(heatSource?.heatSourceCol?.value ?? 0)].filter((col) => col && col !== 0),
    [heatSource],
  );

  const changeProfileTableField = (value: ChangeOperation) => {
    saveParam((newParam) => {
      const newCondition = findHeatSourceById(newParam, nodeId);
      if (newCondition) {
        const metadata = 'metadata' in value ? value.metadata : undefined;
        const link = updateEntryReference(
          value.type,
          value.name,
          newParam,
          metadata,
        );
        if (link) {
          newCondition.profileSourceData = value.name;
          newCondition.heatSourceCol = newInt(0);
        }
      }
    });
  };

  const tableDefinition = getProfileBCTableDefintion('Time (s)');

  const insertElements: InsertElement[] = [];

  if (profileSourceOn) {
    const isPerUnit = heatSource?.heatSourceType === HEAT_SOURCE_TYPE_POWER_PER_UNIT_OF_VOLUME;
    const value = isPerUnit ?
      heatSource.heatSourcePowerPerUnitVolume! :
      heatSource.heatSourcePower!;
    const descriptor = isPerUnit ? heatSourcePowerPerUnitDesc : heatSourcePowerDesc;
    insertElements.push(
      {
        element: (
          <ProfileSelector
            dialogPreview
            dialogSubtitle={(
              <div>
                Upload a profile distribution file to model your heat source. The CSV file must
                include multiple columns. The initial column should consist of time (s). Subsequent
                columns should contain power (W) quantities. You can use a header row to label these
                columns.
              </div>
            )}
            dialogTitle="Profile File Upload"
            gridPreview
            onlyNonnegative
            projectId={projectId}
            readOnly={readOnly}
            setValue={changeProfileTableField}
            simParam={simParam}
            tableDefinition={tableDefinition}
            value={heatSource?.profileSourceData}
          />
        ),
        insert: profileSourceDesc,
        replace: false,
      },
      {
        element: (
          <ProfileTypeTimeDataSelect />
        ),
        insert: profileTypeDesc,
        replace: true,
      },
      {
        element: (
          <LabeledColumnSelector
            columnIndex={fromBigInt(heatSource.heatSourceCol?.value ?? 0)}
            disabledColumns={selectedColumns}
            label={descriptor.text}
            param={descriptor}
            profileBcData={heatSource.profileSourceData}
            projectId={projectId}
            simParam={simParam}
            updateColumnIndex={(val: number) => saveHeatSource((newSource) => {
              newSource.heatSourceCol = newInt(val);
            })}
            updateValue={(val: AdFloatType) => saveHeatSource((newSource) => {
              if (isPerUnit) {
                newSource.heatSourcePowerPerUnitVolume = val;
              } else {
                newSource.heatSourcePower = val;
              }
            })}
            value={value}
          />
        ),
        insert: descriptor,
        replace: true,
      },
    );
  }

  return { insertTabularElements: insertElements };
}

export function useMaterialFluidTempVaryingTabularData(paramScope: ParamScope): TabularDataResult {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected fluid material row');

  // == Recoil
  const { simParam, saveParam } = useSimulationConfig();

  // == Custom hooks
  const {
    fluid,
    saveFluidMaterial,
  } = useMaterialEntity(projectId, workflowId, jobId, readOnly, node.id);
  assert(!!fluid, 'No fluid material found with id');

  const isCustom =
    fluid.materialFluidPreset === simulationpb.MaterialFluidPreset.CUSTOM_MATERIAL_FLUID;

  const modifyConductivityOptions = useCallback(
    (options: SelectOption<number>[]) => options.map((option) => {
      if (
        fluid.laminarThermalConductivity === TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY &&
        fluid.thermalConductivityTableData !== '' &&
        option.value === LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY
      ) {
        // if a table has been linked, we must show that a different option has been
        // selected as the temperature dependent option is hidden to the user
        option.selected = true;
      }
      return option;
    }),
    [fluid.laminarThermalConductivity, fluid.thermalConductivityTableData],
  );

  const modifyViscosityOptions = useCallback(
    (options: SelectOption<number>[]) => options.map((option) => {
      if (
        fluid.laminarViscosityModelNewtonian === TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY &&
        fluid.dynamicViscosityTableData !== '' &&
        option.value === LAMINAR_CONSTANT_VISCOSITY
      ) {
        // if a table has been linked, we must show that a different option has been
        // selected as the temperature dependent option is hidden to the user
        option.selected = true;
      }
      return option;
    }),
    [fluid.dynamicViscosityTableData, fluid.laminarViscosityModelNewtonian],
  );

  const onConductivityOptionChange = useCallback(async (newValue) => {
    const newMaterial = fluid.clone();
    if (
      newValue === LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY &&
      newMaterial.thermalConductivityTableData !== ''
    ) {
      // if the user selects constant but has a table linked, set the value to be
      // temperature dependent
      newValue = TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY;
    }
    newMaterial.laminarThermalConductivity = newValue;
    saveFluidMaterial(newMaterial);
  }, [fluid, saveFluidMaterial]);

  const onViscosityOptionChange = useCallback(async (newValue) => {
    const newMaterial = fluid.clone();
    if (
      newValue === LAMINAR_CONSTANT_VISCOSITY &&
      newMaterial.dynamicViscosityTableData !== ''
    ) {
      // if the user selects constant but has a table linked, set the value to be
      // temperature dependent
      newValue = TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY;
    }
    newMaterial.laminarViscosityModelNewtonian = newValue;
    saveFluidMaterial(newMaterial);
  }, [fluid, saveFluidMaterial]);

  const setFluidConductivity = useCallback((newValue: basepb.AdFloatType) => {
    const newMaterial = fluid.clone();
    newMaterial.laminarConstantThermalConductivityConstant = newValue;
    saveFluidMaterial(newMaterial);
  }, [fluid, saveFluidMaterial]);

  const setFluidViscosity = useCallback((newValue: basepb.AdFloatType) => {
    const newMaterial = fluid.clone();
    newMaterial.laminarConstantViscosityConstant = newValue;
    saveFluidMaterial(newMaterial);
  }, [fluid, saveFluidMaterial]);

  // an empty name represents unlinking the table
  // because the time dependent option is not surfaced to the user, we must set the conductivity
  // or viscosity model to be temperature dependent if a table is provided
  const setConductivityTable = (name: string, material: simulationpb.MaterialFluid) => {
    material.laminarThermalConductivity = name === '' ?
      LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY : TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY;
    material.thermalConductivityTableData = name;
  };
  const setViscosityTable = (name: string, material: simulationpb.MaterialFluid) => {
    material.laminarViscosityModelNewtonian = name === '' ?
      LAMINAR_CONSTANT_VISCOSITY : TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY;
    material.dynamicViscosityTableData = name;
  };

  const onConductivityTableChange = useCallback((value: ChangeOperation) => {
    saveParam((newParam) => {
      const newMaterial = findMaterialEntityById(
        newParam,
        node.id,
      )?.material.value as simulationpb.MaterialFluid | undefined;
      if (newMaterial) {
        const metadata = 'metadata' in value ? value.metadata : undefined;
        const link = updateEntryReference(value.type, value.name, newParam, metadata);
        if (link) {
          setConductivityTable(value.name as string, newMaterial);
        }
      }
      return newMaterial;
    });
  }, [node.id, saveParam]);

  const onViscosityTableChange = useCallback((value: ChangeOperation) => {
    saveParam((newParam) => {
      const newMaterial = findMaterialEntityById(
        newParam,
        node.id,
      )?.material.value as simulationpb.MaterialFluid | undefined;
      if (newMaterial) {
        const metadata = 'metadata' in value ? value.metadata : undefined;
        const link = updateEntryReference(value.type, value.name, newParam, metadata);
        if (link) {
          setViscosityTable(value.name as string, newMaterial);
        }
      }
      return newMaterial;
    });
  }, [node.id, saveParam]);

  const onConductivityUnlinkTable = useCallback(() => {
    saveParam((newParam) => {
      const newMaterial = findMaterialEntityById(
        newParam,
        node.id,
      )?.material.value as simulationpb.MaterialFluid | undefined;
      if (newMaterial) {
        setConductivityTable('', newMaterial);
      }
      return newMaterial;
    });
  }, [node.id, saveParam]);

  const onViscosityUnlinkTable = useCallback(() => {
    saveParam((newParam) => {
      const newMaterial = findMaterialEntityById(
        newParam,
        node.id,
      )?.material.value as simulationpb.MaterialFluid | undefined;
      if (newMaterial) {
        setViscosityTable('', newMaterial);
      }
      return newMaterial;
    });
  }, [node.id, saveParam]);

  const insertElements = useMemo(() => {
    const materialColumnChoices = findProfileParamChoices(MaterialTableParams);

    const hasEnergy =
      fluid.densityRelationship !== simulationpb.DensityRelationship.CONSTANT_DENSITY;

    const filteredThermalConductivityDesc = (
      { ...thermalConductivityFluidModelDesc } as ProtoDescriptor.MultipleChoiceParam
    );
    filteredThermalConductivityDesc.choices = filteredThermalConductivityDesc.choices.filter(
      (choice) => choice.enumNumber !== TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY,
    );
    const filteredThermalViscosityDesc = (
      { ...thermalViscosityModelDesc } as ProtoDescriptor.MultipleChoiceParam
    );
    filteredThermalViscosityDesc.choices = filteredThermalViscosityDesc.choices.filter(
      (choice) => choice.enumNumber !== TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY,
    );

    const elements: InsertElement[] = [
      {
        element: (
          <DataSelectProfile
            columnChoices={materialColumnChoices}
            modifyOptions={modifyConductivityOptions}
            multipleChoiceDescriptor={filteredThermalConductivityDesc}
            onChange={onConductivityOptionChange}
            param={fluid}
            paramScope={paramScope}
          />),
        insert: thermalConductivityFluidModelDesc,
        replace: true,
      },
      {
        element: (
          <DataSelectProfile
            columnChoices={materialColumnChoices}
            modifyOptions={modifyViscosityOptions}
            multipleChoiceDescriptor={filteredThermalViscosityDesc}
            onChange={onViscosityOptionChange}
            param={fluid}
            paramScope={paramScope}
            tooltip={hasEnergy ? defaultTooltip : 'Temperature-dependent viscosity is only ' +
              'available with "Ideal Gas" or "Constant Density with Energy Equation"'}
          />),
        insert: thermalViscosityModelDesc,
        replace: true,
      },
    ];
    if (
      fluid.laminarThermalConductivity === TEMPERATURE_DEPENDENT_THERMAL_CONDUCTIVITY ||
      fluid.laminarThermalConductivity === LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY
    ) {
      elements.push({
        element: (
          <NumberAndTableInput
            linkedTable={
              getCompatibleTablesMap(simParam, TempVaryingConductivityTableDefinition).get(
                fluid.thermalConductivityTableData,
              )!
            }
            param={thermalConductivityFluidConstantDesc}
            readOnly={readOnly || !isCustom}
            setValue={setFluidConductivity}
            subtitle="Please upload a CSV file with temperature-dependent conductivity data for
            your material, including Temperature (K) and Conductivity (W/K/m)."
            tableDefinition={TempVaryingConductivityTableDefinition}
            tableErrorFunc={(table) => checkTempVary(table)}
            tableNameErrorFunc={(name) => tableNameValidation(name, simParam)}
            tableOnChange={onConductivityTableChange}
            title="Temperature-dependent Conductivity"
            unlinkTable={onConductivityUnlinkTable}
            value={fluid.laminarConstantThermalConductivityConstant!}
          />
        ),
        insert: fluid.laminarThermalConductivity === LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY ?
          thermalConductivityFluidConstantDesc : thermalConductivityFluidModelDesc,
        replace: fluid.laminarThermalConductivity === LAMINAR_CONSTANT_THERMAL_CONDUCTIVITY,
      });
    }
    if (
      hasEnergy &&
      (fluid.laminarViscosityModelNewtonian === TEMPERATURE_DEPENDENT_LAMINAR_VISCOSITY ||
        fluid.laminarViscosityModelNewtonian === LAMINAR_CONSTANT_VISCOSITY)
    ) {
      elements.push({
        element: (
          <NumberAndTableInput
            linkedTable={
              getCompatibleTablesMap(simParam, TempVaryingViscosityTableDefinition).get(
                fluid.dynamicViscosityTableData,
              )!
            }
            param={thermalViscosityConstantDesc}
            readOnly={readOnly || !isCustom}
            setValue={setFluidViscosity}
            subtitle={(
              <div>
                Please upload a CSV file with temperature-dependent laminar viscosity data for
                your material, including Temperature (K) and Viscosity (N·s/m<sup>2</sup>).
              </div>
            )}
            tableDefinition={TempVaryingViscosityTableDefinition}
            tableErrorFunc={(table) => checkTempVary(table)}
            tableNameErrorFunc={(name) => tableNameValidation(name, simParam)}
            tableOnChange={onViscosityTableChange}
            title="Temperature-dependent Laminar Viscosity"
            unlinkTable={onViscosityUnlinkTable}
            value={fluid.laminarConstantViscosityConstant!}
          />
        ),
        insert: fluid.laminarViscosityModelNewtonian === LAMINAR_CONSTANT_VISCOSITY ?
          thermalViscosityConstantDesc : thermalViscosityModelDesc,
        replace: fluid.laminarViscosityModelNewtonian === LAMINAR_CONSTANT_VISCOSITY,
      });
    }
    return elements;
  }, [
    fluid,
    isCustom,
    readOnly,
    modifyConductivityOptions,
    modifyViscosityOptions,
    onConductivityOptionChange,
    onConductivityTableChange,
    onConductivityUnlinkTable,
    onViscosityOptionChange,
    onViscosityTableChange,
    onViscosityUnlinkTable,
    paramScope,
    setFluidConductivity,
    setFluidViscosity,
    simParam,
  ]);

  return { insertTabularElements: insertElements };
}

export function useMaterialSolidTempVaryingTabularData(): TabularDataResult {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected fluid material row');

  // == Recoil
  const { simParam, saveParam } = useSimulationConfig();

  // == Custom hooks
  const {
    solid,
    saveSolidMaterial,
  } = useMaterialEntity(projectId, workflowId, jobId, readOnly, node.id);
  assert(!!solid, 'No solid material found with id');

  const isCustom =
    solid.materialSolidPreset === simulationpb.MaterialSolidPreset.CUSTOM_MATERIAL_SOLID;

  const setSolidConductivity = useCallback((newValue: basepb.AdFloatType) => {
    const newMaterial = solid.clone();
    newMaterial.thermalConductivityConstantSolid = newValue;
    saveSolidMaterial(newMaterial);
  }, [saveSolidMaterial, solid]);

  const onConductivityTableChange = useCallback((value: ChangeOperation) => {
    saveParam((newParam) => {
      const newMaterial = findMaterialEntityById(
        newParam,
        node.id,
      )?.material.value as simulationpb.MaterialFluid | undefined;
      if (newMaterial) {
        const metadata = 'metadata' in value ? value.metadata : undefined;
        const link = updateEntryReference(value.type, value.name, newParam, metadata);
        if (link) {
          newMaterial.thermalConductivityTableData = value.name as string;
        }
      }
      return newMaterial;
    });
  }, [node.id, saveParam]);

  const onConductivityUnlinkTable = useCallback(() => {
    saveParam((newParam) => {
      const newMaterial = findMaterialEntityById(
        newParam,
        node.id,
      )?.material.value as simulationpb.MaterialFluid | undefined;
      if (newMaterial) {
        newMaterial.thermalConductivityTableData = '';
      }
      return newMaterial;
    });
  }, [node.id, saveParam]);

  const insertElements = useMemo(() => {
    const elements: InsertElement[] = [{
      element: (
        <NumberAndTableInput
          label="Thermal Conductivity"
          linkedTable={
            getCompatibleTablesMap(simParam, TempVaryingConductivityTableDefinition).get(
              solid.thermalConductivityTableData,
            )!
          }
          param={thermalConductivitySolidConstantDesc}
          readOnly={readOnly || !isCustom}
          setValue={setSolidConductivity}
          subtitle="Please upload a CSV file with temperature-dependent conductivity data for
          your material, including Temperature (K) and Conductivity (W/K/m)."
          tableDefinition={TempVaryingConductivityTableDefinition}
          tableErrorFunc={(table) => checkTempVary(table)}
          tableNameErrorFunc={(name) => tableNameValidation(name, simParam)}
          tableOnChange={onConductivityTableChange}
          title="Temperature-dependent Conductivity"
          unlinkTable={onConductivityUnlinkTable}
          value={solid.thermalConductivityConstantSolid!}
        />
      ),
      insert: thermalConductivitySolidConstantDesc,
      replace: true,
    }];
    return elements;
  }, [
    isCustom, readOnly, onConductivityTableChange, onConductivityUnlinkTable,
    setSolidConductivity, simParam, solid.thermalConductivityConstantSolid,
    solid.thermalConductivityTableData,
  ]);

  return { insertTabularElements: insertElements };
}
