// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Choice } from '../ProtoDescriptor';
import { ParamGroupName, paramGroupDesc } from '../SimulationParamDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';

import { initParamGroupProto } from './initParam';
import { prefixNameGen, uniqueSequenceName } from './name';
import { findParticleGroupById } from './particleGroupUtils';
import { findPhysicsById, getFluid } from './physicsUtils';
import { newNodeId } from './projectDataUtils';

// While the underlying param data model refers to physical behaviors (e.g.
// physicalBehavior), Design has requested that we label them as physical models instead--
// to differentiate these objects from motion.
export const PHYSICAL_BEHAVIOR_LABEL = 'physical model';
export const PHYSICAL_BEHAVIOR_TITLE = 'Physical Model';

// Return all physical models across all fluid physics
export function getAllPhysicalBehaviors(param: simulationpb.SimulationParam) {
  return param.physics.reduce((result, physics) => {
    result.push(...getFluid(physics)?.physicalBehavior || []);
    return result;
  }, [] as simulationpb.PhysicalBehavior[]);
}

// Generate a new name for a physical behavior, based on the selected model type
function newName(fluid: simulationpb.Fluid, type: Choice): string {
  const existingNamesOfType: string[] = [];

  fluid.physicalBehavior.forEach((behavior) => {
    if (behavior.physicalBehaviorModel === type.enumNumber) {
      existingNamesOfType.push(behavior.physicalBehaviorName);
    }
  });

  return uniqueSequenceName(existingNamesOfType, prefixNameGen(type.text));
}

// Return a physical behavior identified by the given ID or undefined if the ID is invalid
export function findPhysicalBehaviorById(param: simulationpb.SimulationParam, id: string) {
  return getAllPhysicalBehaviors(param).find((item) => item.physicalBehaviorId === id);
}

// Return a map of physical behaviors bucketed by behavior model type.
function getPhysicalBehaviorsMap(param: simulationpb.SimulationParam) {
  const map: Map<simulationpb.PhysicalBehaviorModel, simulationpb.PhysicalBehavior[]> = new Map();

  getAllPhysicalBehaviors(param).forEach((behavior) => {
    const modelType = behavior.physicalBehaviorModel;
    if (map.has(modelType)) {
      map.get(modelType)?.push(behavior);
    } else {
      map.set(modelType, [behavior]);
    }
  });

  return map;
}

// Instantiate a physical behavior and initialize the physical behavior's ID, model, type, and name
function newPhysicalBehavior(fluid: simulationpb.Fluid, type: Choice) {
  const name = newName(fluid, type);

  const behavior = initParamGroupProto(
    new simulationpb.PhysicalBehavior(),
    paramGroupDesc[ParamGroupName.PhysicalBehavior],
  );

  behavior.physicalBehaviorId = newNodeId();
  behavior.physicalBehaviorName = name;
  behavior.physicalBehaviorModel = type.enumNumber;
  return behavior;
}

// For a given particle group type, return a list of compatible physical behavior model types
export function compatiblePhysicalBehaviorModelTypes(type: simulationpb.ParticleGroupType) {
  switch (type) {
    case simulationpb.ParticleGroupType.ACTUATOR_DISK: {
      return [
        simulationpb.PhysicalBehaviorModel.ACTUATOR_DISK_MODEL,
        simulationpb.PhysicalBehaviorModel.ACTUATOR_LINE_MODEL,
      ];
    }
    case simulationpb.ParticleGroupType.ACTUATOR_LINE: {
      return [
        simulationpb.PhysicalBehaviorModel.ACTUATOR_LINE_MODEL,
      ];
    }
    case simulationpb.ParticleGroupType.SOURCE_POINTS: {
      return [
        simulationpb.PhysicalBehaviorModel.SOURCE_POINTS_MODEL,
      ];
    }
    case simulationpb.ParticleGroupType.PROBE_POINTS:
    case simulationpb.ParticleGroupType.INVALID_PARTICLE_GROUP_TYPE: {
      return [];
    }
    // no default
  }
}

// For a particle group type, return a list of physical behaviors of corresponding physical behavior
// model type.
export function getPhysicalBehaviorsByParticleType(
  param: simulationpb.SimulationParam,
  type: simulationpb.ParticleGroupType,
) {
  const modelTypes = compatiblePhysicalBehaviorModelTypes(type);

  return modelTypes.flatMap(
    (modelType) => getPhysicalBehaviorsMap(param).get(modelType) || [],
  );
}

// Generate a new physical behavior, optionally attaching it to a particle group
function createPhysicalBehavior(
  param: simulationpb.SimulationParam,
  fluid: simulationpb.Fluid,
  type: Choice,
  particleGroupId?: string,
): simulationpb.PhysicalBehavior {
  const behavior = newPhysicalBehavior(fluid, type);

  if (particleGroupId !== undefined) {
    const particleGroup = findParticleGroupById(param, particleGroupId);
    if (particleGroup) {
      particleGroup.particleGroupBehaviorModelRef = behavior.physicalBehaviorId;
    }
  }

  return behavior;
}

/**
 * Find the physics containing the physical behavior. Returns undefined if a
 * physics cannot be found.
 * @param param
 * @param id
 * @returns physics or undefined
 */
export function findParentPhysicsByPhysicalBehaviorId(
  param: simulationpb.SimulationParam,
  id: string,
) {
  return param.physics.find((physics) => (
    getFluid(physics)?.physicalBehavior.some((item) => item.physicalBehaviorId === id)
  ));
}

// Create a new physical behavior and append it to a fluid
export function appendPhysicalBehavior(
  param: simulationpb.SimulationParam,
  fluidPhysicsId: string,
  type: Choice,
  particleGroupId?: string,
): simulationpb.PhysicalBehavior {
  const physics = findPhysicsById(param, fluidPhysicsId);
  const fluid = physics ? getFluid(physics) : null;

  if (!physics || !fluid) {
    throw Error('Invalid fluid physics ID');
  }

  const behavior = createPhysicalBehavior(param, fluid, type, particleGroupId);
  fluid.physicalBehavior.push(behavior);
  return behavior;
}

// Given a physical behavior ID, return a map of particle groups that reference
// the behavior, keyed by particle group ID.
export function getParticleGroupMapByPhysicalBehavior(
  param: simulationpb.SimulationParam,
  id: string,
) {
  const map: Record<string, string> = {};

  param.particleGroup.forEach((particleGroup) => {
    if (particleGroup.particleGroupBehaviorModelRef === id) {
      map[particleGroup.particleGroupId] = particleGroup.particleGroupName;
    }
  });

  return map;
}

// Rename the identified physical behavior, returning true if successful
export function renamePhysicalBehavior(
  param: simulationpb.SimulationParam,
  id: string,
  name: string,
): boolean {
  const behavior = findPhysicalBehaviorById(param, id);
  if (behavior && behavior.physicalBehaviorName !== name) {
    behavior.physicalBehaviorName = name;
    return true;
  }
  return false;
}

// Remove the identified physical behavior, returning true if successful
export function removePhysicalBehavior(param: simulationpb.SimulationParam, id: string): boolean {
  return param.physics.reduce((result, physics) => {
    const fluid = getFluid(physics);
    if (fluid) {
      const behaviors = fluid.physicalBehavior;
      const newBehaviors = behaviors.filter((item) => item.physicalBehaviorId !== id);

      if (newBehaviors.length < behaviors.length) {
        // Remove any references to this physical behavior in the particle groups list
        const particleGroups = param.particleGroup;
        particleGroups.forEach((particleGroup) => {
          if (particleGroup.particleGroupBehaviorModelRef === id) {
            particleGroup.particleGroupBehaviorModelRef = '';
          }
        });

        param.particleGroup = particleGroups;

        // Update physical behaviors list
        fluid.physicalBehavior = newBehaviors;
        return true;
      }
    }
    return result;
  }, false);
}

// Modify the physical behavior to have the given particle groups IDs.
export function setParticleGroupsForBehavior(
  newParticleIds: string[],
  behaviorId: string,
  param: simulationpb.SimulationParam,
): string {
  const newBehavior = findPhysicalBehaviorById(param, behaviorId);
  if (!newBehavior) {
    return `Error in copying new ${PHYSICAL_BEHAVIOR_LABEL}`;
  }

  const particleGroupMap = getParticleGroupMapByPhysicalBehavior(param, behaviorId);
  const oldParticleIds = Object.keys(particleGroupMap);

  // Add the new particle IDs. First, check that each particle group type label
  // is compatible with the physical behavior.
  const errors: string[] = [];
  newParticleIds.forEach((particleId) => {
    const particleGroup = findParticleGroupById(param, particleId);
    if (!particleGroup) {
      errors.push(`Missing particle group ${particleId}.`);
      return;
    }

    // Attach the particle group to the behavior if it's behavior is not set.
    if (!particleGroup.particleGroupBehaviorModelRef) {
      particleGroup.particleGroupBehaviorModelRef = behaviorId;
    }
  });
  if (errors.length) {
    return errors.join(' ');
  }

  // Remove any old particle IDs that are not in the new set.
  oldParticleIds.forEach((particleId) => {
    if (!newParticleIds.includes(particleId)) {
      const particleGroup = findParticleGroupById(param, particleId);
      if (!particleGroup) {
        errors.push(`Missing particle group ${particleId}.`);
      } else {
        particleGroup.particleGroupBehaviorModelRef = '';
      }
    }
  });
  return errors.join(' ');
}
