// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVObject, LCVType } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';
import { LcvFrame } from '../LcvFrame';

import { LcvWidget } from './LcvWidget';

export class LcvTriadWidget extends LcvWidget {
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    frame: LcvFrame,
  ) {
    super(lcv, sessionHandle, 'triad');
    this.attachFrame(frame);
  }

  setHoverCallback(fn: ((hovered: boolean) => void) | null) {
    if (fn === null) {
      this.setParam('mouse_callback', LCVType.kLCVDataTypeFunction, null);
    } else {
      this.setParam('mouse_callback', LCVType.kLCVDataTypeFunction, (
        _lcv: LcvModule,
        _session: LCVObject,
        _obj: LCVObject,
        event: 'enter' | 'leave',
      ) => {
        fn(event === 'enter');
      });
    }
  }
}
