// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import { useRecoilCallback } from 'recoil';

import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { extname, isLcSolnExtension } from '../../../lib/path';
import * as rpc from '../../../lib/rpc';
import { addError, addInfo } from '../../../lib/transientNotification';
import * as projectstatepb from '../../../proto/projectstate/projectstate_pb';
import * as visualizationservicepb from '../../../proto/visualizationservice/visualizationservice_pb';
import { meshUrlState } from '../../../recoil/meshState';
import { selectedGeometryState } from '../../../recoil/selectedGeometry';
import { activeVisUrlState } from '../../../recoil/vis/activeVisUrl';
import { postProcessingExtractsPollerAtom } from '../../../recoil/vis/postProcessingExtract';
import { useProjectContext } from '../../context/ProjectContext';

// Button that calls an endpoint to generate a postprocessing extract from the current view state.
export const useGeneratePostProcessingExtract = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const onClickCallback = useRecoilCallback(({ snapshot, set }) => async (name: string) => {
    const meshUrl = await snapshot.getPromise(meshUrlState(projectId));
    const entityType = new visualizationservicepb.ExtractEntity();
    const activeVisUrl = await snapshot.getPromise(activeVisUrlState(
      { projectId, workflowId, jobId },
    ));
    const hasSolution = isLcSolnExtension(extname(activeVisUrl));
    if (!hasSolution) {
      if (meshUrl.activeType === projectstatepb.UrlType.MESH) {
        entityType.entity.case = 'mesh';
        entityType.entity.value = new visualizationservicepb.MeshEntity({
          id: meshUrl.meshId,
        });
      } else {
        const selectedGeometry = await snapshot.getPromise(
          selectedGeometryState({ projectId, workflowId, jobId }),
        );
        if (selectedGeometry.geometryId) {
          entityType.entity.case = 'geometry';
          entityType.entity.value = new visualizationservicepb.GeometryEntity({
            id: selectedGeometry.geometryId,
            geometryVersionId: selectedGeometry.geometryVersionId,
          });
        }
      }
    }
    // This can happen with old projects where there is no mesh and the geometry is not an igeo
    // geometry.
    // We allow passing an empty entityType in case we are looking at a simulation result. In that
    // case, the backend will concert from activeVisUrl into the entityType.
    if (!entityType.entity && !hasSolution) {
      return;
    }
    const widthHeight = lcvHandler.display?.getCanvasWidthHeight();
    if (widthHeight === undefined || widthHeight.length !== 2 || widthHeight[0] === undefined ||
      widthHeight[1] === undefined
    ) {
      addError('Unable to get canvas size for the current view');
    }
    const resolution = new visualizationservicepb.Resolution({
      width: widthHeight![0]!,
      height: widthHeight![1]!,
    });
    const req = new visualizationservicepb.CreateExtractFromViewStateRequest({
      projectId,
      entityType,
      jobId,
      solutionUrl: hasSolution ? activeVisUrl : '',
      resolution,
      name,
    });
    rpc.callRetryWithClient(
      rpc.clientVisualization!,
      'createExtractFromViewState',
      rpc.clientVisualization!.createExtractFromViewState,
      req,
    ).then(() => {
      addInfo(`Generation of ${name} started`);
      // Refresh the state to show the new extract being generated.
      set(postProcessingExtractsPollerAtom(projectId), true);
    }).catch(console.error);
  }, [projectId]);

  return onClickCallback;
};
