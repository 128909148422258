// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo } from 'react';

import { ParamGroupName, paramGroupDesc } from '../../SimulationParamDescriptor';
import { newAdFloat, newScalarAdVector } from '../../lib/adUtils';
import { periodicPairId, potentialPeriodicSurfaces } from '../../lib/boundaryConditionUtils';
import { initParamGroupProto } from '../../lib/initParam';
import { findPhysicsById, getSubPhysics } from '../../lib/physicsUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useStaticVolumes } from '../../recoil/volumes';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { useSimulationConfig } from './useSimulationConfig';

export const usePeriodicPairNodes = (physicsId: string) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds, setSelection } = useSelectionContext();

  // == Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const setNewNodes = useSetNewNodes();

  // == Hooks
  const { simParam, saveParamAsync } = useSimulationConfig();

  // == Data
  const periodicSurfaces = useMemo(
    () => potentialPeriodicSurfaces(
      simParam,
      physicsId,
      geometryTags,
      staticVolumes,
      entityGroupData,
    ),
    [entityGroupData, physicsId, simParam, geometryTags, staticVolumes],
  );

  // Adds a periodic bound pair
  const addPeriodicBoundPair = async () => {
    // If two potential surfaces are selected, add those. Otherwise, don't add
    // any surfaces.
    const surfaces = selectedNodeIds.map((nodeId) => (
      entityGroupData.groupMap.has(nodeId) && !entityGroupData.groupMap.get(nodeId).children.size ?
        nodeId : ''));

    const newId = await saveParamAsync(
      (newParam) => {
        const physics = findPhysicsById(newParam, physicsId);
        const subPhysics = physics ? getSubPhysics(physics) : null;
        const newPair = initParamGroupProto(
          new simulationpb.PeriodicPair(),
          paramGroupDesc[ParamGroupName.PeriodicPair],
        );
        newPair.translational = newAdFloat(0);
        newPair.periodicCenterOfRotation = newScalarAdVector();
        newPair.periodicRotationAngles = newScalarAdVector();
        newPair.periodicTranslation = newScalarAdVector();
        if (surfaces.length === 2 &&
          periodicSurfaces.includes(surfaces[0]) &&
          periodicSurfaces.includes(surfaces[1])) {
          newPair.boundA = [surfaces[0]];
          newPair.boundB = [surfaces[1]];
        }

        if (subPhysics) {
          subPhysics.periodicPair.push(newPair);
          return periodicPairId(physicsId, subPhysics.periodicPair.length - 1);
        }

        return null;
      },
    );
    if (newId) {
      setNewNodes((nodes) => [...nodes, initializeNewNode(newId)]);
      setSelection([newId]);
    }
  };

  return {
    addPeriodicBoundPair,
  };
};
