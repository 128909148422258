import * as ParaviewRpc from '../pvproto/ParaviewRpc';

// Get a best-effort default scale for the glyph using the size of the parent node's
// bounds and the maximum values of the requested data.
export function defaultGlyphScale(
  parent: ParaviewRpc.TreeNode,
  data: ParaviewRpc.ArrayInformation | undefined,
): number {
  if (!parent.bounds || !data) {
    // Arbitrary value since we don't have anything better to go off of.
    return 0.0005;
  }
  // Best effort to scale to size of the parent node.  The reason I use the
  // maximum dimension, rather than the volume, for example, is that the parent
  // node of the Glyph may be 3d or 2d (like a clip or slice).
  const maxDim = Math.max(
    parent.bounds[1] - parent.bounds[0],
    parent.bounds[3] - parent.bounds[2],
    parent.bounds[5] - parent.bounds[4],
  );
  // Using the max data value rather than something else does mean that for data
  // with outliers on the upper end, most of the vectors may be pretty small, but
  // there are limits to how well we can do only having the min and max of each dimension.
  const maxDataVal = Math.max(data.range[0][1], data.range[1][1], data.range[2][1]);
  if (maxDataVal === 0) {
    return 0.0005;
  }
  // The constant factor of 8 was chosen using trial-and-error.
  return maxDim / (8 * maxDataVal);
}
