// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../../../lib/componentTypes/svgIcon';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../../../lib/treeUtils';
import { useFluidBoundaryCondition } from '../../../../../model/hooks/useFluidBoundaryCondition';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useCopyFluidBC } from '../../../../hooks/nodeDuplication/useCopyBoundaryCondition';
import { useGeometryStatus } from '../../../../hooks/useGeometryStatus';
import { useNodeDeletion } from '../../../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../../../hooks/useNodeRenaming';
import { TreeRow } from '../../../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'hash' };

export const PhysicsFluidBoundaryConditionTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteBoundaryNode, postDeleteNodeIds } = useNodeDeletion();
  const {
    isDependent,
  } = useFluidBoundaryCondition(projectId, workflowId, jobId, readOnly, node.id);
  const copyFluidBC = useCopyFluidBC();
  const { working } = useGeometryStatus();

  const deleteRow = useCallback(async () => {
    if (await deleteBoundaryNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteBoundaryNode, node.id, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => {
    if (isDependent) {
      return [];
    }
    return [
      duplicateTreeNodeMenuItem(() => copyFluidBC(node.name, node.id), working || readOnly),
      deleteTreeNodeMenuItem(deleteRow, !canDelete(node.type, node.id)),
    ];
  }, [
    canDelete,
    deleteRow,
    isDependent,
    node.id,
    node.type,
    node.name,
    copyFluidBC,
    working,
    readOnly,
  ]);

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      renaming={renaming}
    />
  );
};
