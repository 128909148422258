// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Timestamp } from '@bufbuild/protobuf';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DateTime } from 'luxon';

import BarStackChart, { Datum } from '../../components/BarStackChart';
import DownloadButton from '../../components/Button/DownloadButton';
import { DataSelect } from '../../components/Form/DataSelect';
import { DateRangeSelect } from '../../components/Form/DateRangeSelect';
import { RadioButtonGroup } from '../../components/Form/RadioButtonGroup';
import { createStyles, makeStyles } from '../../components/Theme';
import { Table as NewTable } from '../../components/data/Table';
import { DataTabs } from '../../components/layout/Tabs';
import { TabConfig } from '../../components/layout/Tabs/useDraggableTabs';
import { useMainCommonStyles } from '../../components/layout/page/Main';
import { CircularLoader } from '../../components/visual/CircularLoader';
import { getTertiaryColor } from '../../lib/color';
import { SelectOption } from '../../lib/componentTypes/form';
import { ColumnConfig, PrimitiveValue, RowConfig } from '../../lib/componentTypes/table';
import { colors } from '../../lib/designSystem';
import { fromBigInt } from '../../lib/number';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import { addRpcError } from '../../lib/transientNotification';
import useResizeObserver from '../../lib/useResizeObserver';
import * as clusterconfigpb from '../../proto/clusterconfig/clusterconfig_pb';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import useAccountInfo from '../../recoil/useAccountInfo';

const logger = new Logger('AccountPage/UsagePageBody');

const useStyles = makeStyles(
  () => createStyles({
    cellContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dot: {
      height: '8px',
      width: '8px',
      borderRadius: '50%',
      marginLeft: '8px',
      marginRight: '8px',
    },
    menuContainer: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      '& span': {
        color: colors.lowEmphasisText,
        fontSize: '14px',
      },
    },
    gridContainer: {
      backgroundColor: 'var(--color-surface-medium2)',
      padding: '10px',
      overflow: 'auto',
    },
    tableContainer: {
      margin: '16px 0 48px 0',
      boxShadow: 'none',
      borderRadius: 'none',
    },
  }),
  { name: 'UsageSection' },
);

// The maximum number of colors in the graph. If the number of categories
// exceeds this maximum, they are grouped into an "other" category.
const MAX_COLORS = 8;
// Must be in sync with `maxDaysForUsageDetailFile` from
// go/core/jobmaster/frontendserver/frontendserver.go
const MAX_DAYS_FOR_DOWNLOAD_USAGE_DATA = 90;

// A mutually-exclusive type of usage.  The usage screen will only
// show one of these usage types at a time.
interface UsageType {
  // The user-facing name of this usage type.
  name: string;
  // The rule used to filter for ResourceUsage of this usage type.
  // Should return true iff usage is of this usage type.
  rule: (usage: frontendpb.ResourceUsage) => boolean;
  // The user-facing unit of this usage type.
  unit: string;
  // A function to transform the raw unit in the ResourceUsage entries
  // (ResourceUsage.getPrice()) into the user-facing unit.
  unitTransform: (rawUnit: number) => number;
}

const mbHrsToGBMonth = 737280;

const newTableTabs = new Set([
  'simulationCostsTab',
  'meshingCostsTab',
  'visualizationCostsTab',
  'cadImportCostsTab',
  'geometryModificationCostsTab',
  'storageTab',
  'networkingTab',
]);

// Available usage types.
const usageTypes: UsageType[] = [
  {
    name: 'Compute',
    rule: (usage: frontendpb.ResourceUsage) => (
      usage.type !== clusterconfigpb.ResourceUnit.RESOURCE_GCS &&
      usage.type !== clusterconfigpb.ResourceUnit.RESOURCE_GCN
    ),
    unit: 'Credits',
    // mCredits * (1 Credit / 1000 mCredits)
    unitTransform: (rawUnit: number) => rawUnit / 1000,
  },
  {
    name: 'Networking',
    rule: (usage: frontendpb.ResourceUsage) => (
      usage.type === clusterconfigpb.ResourceUnit.RESOURCE_GCN
    ),
    unit: 'MB',
    // Is MB the right unit?  Could change to GB.
    unitTransform: (rawUnit: number) => rawUnit,
  },
  {
    name: 'Storage',
    rule: (usage: frontendpb.ResourceUsage) => (
      usage.type === clusterconfigpb.ResourceUnit.RESOURCE_GCS
    ),
    unit: 'GB x month',
    // (MB x hr) * (1 GB / 1024 MB) * (1 month / ~720 hr)
    unitTransform: (rawUnit: number) => rawUnit / mbHrsToGBMonth,
  },
];

// A rule for classifying ResourceUsage entries.
interface Classifier {
  // The user-facing name for this rule.
  name: string,
  // Classify a single ResourceUsage.  Return the name of the
  // classification for the usage.
  classify: (
    usage: frontendpb.ResourceUsage,
    users: frontendpb.AccountInfoReply_UserInfo[],
  ) => string,
}

// The available rules for classifying ResourceUsage entries.
const classifiers: Classifier[] = [
  {
    name: 'Product SKU',
    classify: (
      usage: frontendpb.ResourceUsage,
      users: frontendpb.AccountInfoReply_UserInfo[],
    ) => usage.productName,
  },
  {
    name: 'User',
    classify: (
      usage: frontendpb.ResourceUsage,
      users: frontendpb.AccountInfoReply_UserInfo[],
    ) => {
      const user = users.find((item) => (item.lcUserId === usage.userId));
      if (!user) {
        logger.error(`Unable to find user ${usage.userId}`);
        return 'Unknown user';
      }
      return user.email;
    },
  },
  // TODO(bamo): Should have a 'Project' Classifier.
];

// A group of ResourceUsage entries with the same classification,
// as divided by a Classifier.
interface Classification {
  // name of this classification.
  name: string;
  // The keys associated with this classification.
  keys: string[],
  // Sum of usage.getPrice() for all usages in this classification.
  totalPrice: number;
  // Individual usages tied to this classification.
  usage: frontendpb.ResourceUsage[];
}

// Given a list of ResourceUsage entries and a classifier, classify the
// entries according to the classifier's rule, and return a list of classifications.
// Each entry will appear in exactly one classification. The returned list is
// sorted by classification name.
// users is required as metadata, used by some rules.
const classify = (
  allUsage: frontendpb.ResourceUsage[],
  users: frontendpb.AccountInfoReply_UserInfo[],
  classifier: Classifier,
) => {
  const classifications = new Map<string, Classification>();
  allUsage.forEach((usage: frontendpb.ResourceUsage) => {
    const className = classifier.classify(usage, users);
    let classification: Classification | undefined = classifications.get(className);
    if (!classification) {
      classification = {
        name: className,
        keys: [className],
        totalPrice: 0,
        usage: [],
      };
      classifications.set(className, classification);
    }
    classification.totalPrice += fromBigInt(usage.price);
    classification.usage.push(usage);
  });
  return Array.from(classifications.values()).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

// A bucket of ResourceUsage entries that fall in the same time period.
interface TimeBucket {
  // The name of this TimeBucket, as it will be shown to the user.
  name: string;
  // The list of ResourceUsage entries that fall in this TimeBucket.
  usage: frontendpb.ResourceUsage[];
}

// Date formatting helper
const formatDate = (date: Date) => (
  date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
);

const bucketizeByDateRange = (start: Date, end: Date, usages: frontendpb.ResourceUsage[]) => {
  const buckets: TimeBucket[] = [];
  const curDate = new Date(start);
  curDate.setHours(0, 0, 0, 0);

  while (curDate <= end) {
    buckets.push({
      name: formatDate(curDate),
      usage: [],
    });
    curDate.setDate(curDate.getDate() + 1);
  }

  const startTime = start.getTime();
  const endTime = end.getTime();

  usages.forEach((usage: frontendpb.ResourceUsage) => {
    const usageTime = usage.time * 1000;
    if (usageTime >= startTime && usageTime <= endTime) {
      // Calculate index based on usage time and elapsed days from startTime.
      const bucketIndex = Math.floor((usageTime - startTime) / (1000 * 60 * 60 * 24));
      if (bucketIndex >= 0 && bucketIndex < buckets.length) {
        buckets[bucketIndex].usage.push(usage);
      }
    }
  });

  return buckets;
};

const defaultDateRangeBucket = () => {
  const now = new Date();
  const thisMonth = new Date(now.getFullYear(), now.getMonth());
  return {
    name: '',
    time: thisMonth,
    usage: [],
  };
};

const tabPageSize = [20, 50, 100];

interface ResourceUsageDetailRecord {
  projectId: string;
  projectName: string;
  email: string;
  time: Date;
  credits: number;
  nUnit: bigint;
  productName: string;
  simulationName: string;
  jobId: string;
  geometryId: string;
  geometryName: string;
  discountAmount: number;
  chargeAmount: number;
}

const baseColumnConfigs: { [key: string]: ColumnConfig[] } = {
  simulation: [
    { id: 'projectName', label: 'Project Name', type: 'string' },
    { id: 'simulationName', label: 'Simulation Name', type: 'string' },
    { id: 'email', label: 'Email', type: 'string' },
    { id: 'time', label: 'Time', type: 'string' },
    { id: 'productName', label: 'Job Type', type: 'string' },
    { id: 'credits', label: 'Credits', type: 'string', displayOptions: { justify: 'start' } },
    {
      id: 'projectId',
      label: 'Project ID',
      type: 'string',
      displayOptions: { nowrap: true },
      defaultHidden: true,
    },
    { id: 'jobId', label: 'Job ID', type: 'string', defaultHidden: true },
    { id: 'discountAmount', label: 'Discount ($)', type: 'string', defaultHidden: false },
    { id: 'chargeAmount', label: 'Charge ($)', type: 'string', defaultHidden: false },
  ],
  meshing: [
    { id: 'projectName', label: 'Project Name', type: 'string' },
    { id: 'email', label: 'Email', type: 'string' },
    { id: 'time', label: 'Time', type: 'string' },
    { id: 'productName', label: 'Job Type', type: 'string' },
    { id: 'credits', label: 'Credits', type: 'string', displayOptions: { justify: 'start' } },
    {
      id: 'projectId',
      label: 'Project ID',
      type: 'string',
      displayOptions: { nowrap: true },
      defaultHidden: true,
    },
    { id: 'jobId', label: 'Job ID', type: 'string', defaultHidden: true },
    { id: 'discountAmount', label: 'Discount ($)', type: 'string', defaultHidden: false },
    { id: 'chargeAmount', label: 'Charge ($)', type: 'string', defaultHidden: false },
  ],
  visualization: [
    { id: 'projectName', label: 'Project Name', type: 'string' },
    { id: 'email', label: 'Email', type: 'string' },
    { id: 'time', label: 'Time', type: 'string' },
    { id: 'productName', label: 'Job Type', type: 'string' },
    { id: 'credits', label: 'Credits', type: 'string', displayOptions: { justify: 'start' } },
    {
      id: 'projectId',
      label: 'Project ID',
      type: 'string',
      displayOptions: { nowrap: true },
      defaultHidden: true,
    },
    { id: 'jobId', label: 'Job ID', type: 'string', defaultHidden: true },
    { id: 'discountAmount', label: 'Discount ($)', type: 'string', defaultHidden: false },
    { id: 'chargeAmount', label: 'Charge ($)', type: 'string', defaultHidden: false },
  ],
  cadImport: [
    { id: 'projectName', label: 'Project Name', type: 'string' },
    { id: 'email', label: 'Email', type: 'string' },
    { id: 'time', label: 'Time', type: 'string' },
    { id: 'productName', label: 'Job Type', type: 'string' },
    { id: 'credits', label: 'Credits', type: 'string', displayOptions: { justify: 'start' } },
    {
      id: 'projectId',
      label: 'Project ID',
      type: 'string',
      displayOptions: { nowrap: true },
      defaultHidden: true,
    },
    { id: 'jobId', label: 'Job ID', type: 'string', defaultHidden: true },
    { id: 'discountAmount', label: 'Discount ($)', type: 'string', defaultHidden: false },
    { id: 'chargeAmount', label: 'Charge ($)', type: 'string', defaultHidden: false },
  ],
  storage: [
    { id: 'projectName', label: 'Project Name', type: 'string' },
    { id: 'email', label: 'Email', type: 'string' },
    { id: 'time', label: 'Time', type: 'string' },
    { id: 'productName', label: 'Job Type', type: 'string' },
    { id: 'credits', label: 'GB-hrs', type: 'string', displayOptions: { justify: 'start' } },
    {
      id: 'projectId',
      label: 'Project ID',
      type: 'string',
      displayOptions: { nowrap: true },
      defaultHidden: true,
    },
    { id: 'discountAmount', label: 'Discount ($)', type: 'string', defaultHidden: false },
    { id: 'chargeAmount', label: 'Charge ($)', type: 'string', defaultHidden: false },
  ],
  networking: [
    { id: 'projectName', label: 'Project Name', type: 'string' },
    { id: 'email', label: 'Email', type: 'string' },
    { id: 'time', label: 'Time', type: 'string' },
    { id: 'productName', label: 'Job Type', type: 'string' },
    { id: 'credits', label: 'MB', type: 'string', displayOptions: { justify: 'start' } },
    {
      id: 'projectId',
      label: 'Project ID',
      type: 'string',
      displayOptions: { nowrap: true },
      defaultHidden: true,
    },
    { id: 'discountAmount', label: 'Discount ($)', type: 'string', defaultHidden: false },
    { id: 'chargeAmount', label: 'Charge ($)', type: 'string', defaultHidden: false },
  ],
  geometryModification: [
    { id: 'projectName', label: 'Project Name', type: 'string' },
    { id: 'geometryName', label: 'Geometry Name', type: 'string' },
    { id: 'email', label: 'Email', type: 'string' },
    { id: 'time', label: 'Time', type: 'string' },
    { id: 'productName', label: 'Product Name', type: 'string' },
    { id: 'credits', label: 'Credits', type: 'string', displayOptions: { justify: 'start' } },
    {
      id: 'projectId',
      label: 'Project ID',
      type: 'string',
      displayOptions: { nowrap: true },
      defaultHidden: true,
    },
    { id: 'geometryId', label: 'Geometry ID', type: 'string', defaultHidden: true },
    { id: 'discountAmount', label: 'Discount ($)', type: 'string', defaultHidden: false },
    { id: 'chargeAmount', label: 'Charge ($)', type: 'string', defaultHidden: false },
  ],
};

const generateRowConfigs = (
  tableData: ResourceUsageDetailRecord[],
  columns: (keyof ResourceUsageDetailRecord)[],
) => tableData.map((usage) => {
  let computedCredits: number;
  let formattedCredits: number;

  if (usage.productName === 'Storage') {
    computedCredits = (usage.credits / mbHrsToGBMonth);
    formattedCredits = parseFloat(computedCredits.toFixed(10));
  } else if (usage.productName === 'Networking') {
    computedCredits = usage.credits;
    formattedCredits = parseFloat(computedCredits.toFixed(2));
  } else {
    computedCredits = (usage.credits / 1000);
    formattedCredits = parseFloat(computedCredits.toFixed(3));
  }

  return {
    id: `${usage.projectId}-${usage.jobId}-${usage.time.getTime()}`,
    values: columns.reduce((acc, col) => {
      if (col === 'chargeAmount') {
        acc[col] = parseFloat((usage[col] as number).toFixed(2));
      } else if (col === 'discountAmount') {
        acc[col] = parseFloat((usage[col] as number).toFixed(2));
      } else if (col === 'credits') {
        acc[col] = formattedCredits;
      } else if (usage[col] instanceof Date) {
        acc[col] = (usage[col] as Date).toLocaleString();
      } else {
        acc[col] = usage[col] as unknown as string;
      }
      return acc;
    }, {} as Record<string, PrimitiveValue>),
  };
});

const tabColumnMap: { [key: string]: string } = {
  simulationCostsTab: 'simulation',
  meshingCostsTab: 'meshing',
  visualizationCostsTab: 'visualization',
  cadImportCostsTab: 'cadImport',
  geometryModificationCostsTab: 'geometryModification',
  storageTab: 'storage',
  networkingTab: 'networking',
};

const getRowData = (resourceUsageDetailsRowConfigs: any[], selectedTab: string) => {
  const productFilters: { [key: string]: string[] } = {
    simulationCostsTab: ['Steady State Simulation', 'Transient Simulation'],
    meshingCostsTab: ['Mesh Conversion', 'Mesh Generation'],
    visualizationCostsTab: ['Visualization & Analysis (Post-Processing)'],
    cadImportCostsTab: ['CAD Import'],
    geometryModificationCostsTab: ['Geometry Modification'],
    storageTab: ['Storage'],
    networkingTab: ['Networking'],
  };
  return resourceUsageDetailsRowConfigs.filter(
    (x) => productFilters[selectedTab].includes(x.values.productName),
  );
};

export const UsageSection = ({ isAdmin = false }) => {
  const accountInfo = useAccountInfo();
  const commonClasses = useMainCommonStyles();
  const classes = useStyles();

  // Indices for the classifier, usage-type and month selected by the user.
  const [classifierIdx, setClassifierIdx] = useState<number>(0);
  const [monthIdx, setMonthIdx] = useState<number>(0);
  const [usageTypeIdx, setUsageTypeIdx] = useState<number>(0);
  const [updating, setUpdating] = useState<boolean>(true);

  const [dateRangeBucket, setDateRangeBucket] = useState<TimeBucket>(defaultDateRangeBucket());

  // DATE SELECT
  const [selectedUsageBeginDate, setSelectedUsageBeginDate] = useState<Date | null>(null);
  const [selectedUsageEndDate, setSelectedUsageEndDate] = useState<Date | null>(null);
  const [tableData, setTableData] = useState<ResourceUsageDetailRecord[]>([]);
  const [remainingCredits, setRemainingCredits] = useState<string>('0');
  const [selectedTab, setSelectedTab] = useState('simulationCostsTab');
  const [tabHidden] = useState<Record<string, boolean>>({});
  const [elastic] = useState(false);

  const columnConfigKey = tabColumnMap[selectedTab];
  const columnConfigs = baseColumnConfigs[columnConfigKey];

  const DateFilteringOptions = [
    { label: 'Monthly', value: false },
    { label: 'Custom', value: true },
  ];

  const [selectedCustomDate, setSelectedCustomDate] = useState(false);

  const tabProps: TabConfig[] = [
    {
      id: 'simulationCostsTab',
      label: 'Simulation Costs',
      onClick: () => setSelectedTab('simulationCostsTab'),
      foreground: selectedTab === 'simulationCostsTab',
      elastic,
    },
    {
      id: 'meshingCostsTab',
      label: 'Meshing Costs',
      onClick: () => setSelectedTab('meshingCostsTab'),
      foreground: selectedTab === 'meshingCostsTab',
      elastic,
    },
    {
      id: 'visualizationCostsTab',
      label: 'Visualization Costs',
      onClick: () => setSelectedTab('visualizationCostsTab'),
      foreground: selectedTab === 'visualizationCostsTab',
      elastic,
    },
    {
      id: 'cadImportCostsTab',
      label: 'CAD Import Costs',
      onClick: () => setSelectedTab('cadImportCostsTab'),
      foreground: selectedTab === 'cadImportCostsTab',
      elastic,
    },
    {
      id: 'geometryModificationCostsTab',
      label: 'Geometry Modification Costs',
      onClick: () => setSelectedTab('geometryModificationCostsTab'),
      foreground: selectedTab === 'geometryModificationCostsTab',
      elastic,
    },
    {
      id: 'storageTab',
      label: 'Storage',
      onClick: () => setSelectedTab('storageTab'),
      foreground: selectedTab === 'storageTab',
      elastic,
    },
    {
      id: 'networkingTab',
      label: 'Networking',
      onClick: () => setSelectedTab('networkingTab'),
      foreground: selectedTab === 'networkingTab',
      elastic,
    },
  ].filter((config) => !tabHidden[config.id]);

  const handleBeginDateChange = (date: Date | null) => {
    setSelectedUsageBeginDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setSelectedUsageEndDate(date);
  };

  const months = useMemo(() => {
    // Return a list of all months from account creation until now.
    // Before account info is available, only include this month.
    const now = new Date();
    let startMonth = new Date(now.getFullYear(), now.getMonth());
    let endMonth = startMonth;
    if (accountInfo) {
      const startTime = new Date(fromBigInt(accountInfo.createdTime) * 1000);
      startMonth = new Date(startTime.getFullYear(), startTime.getMonth());
    }
    const ret: Date[] = [];
    while (endMonth >= startMonth) {
      ret.push(endMonth);
      endMonth = new Date(endMonth.getFullYear(), endMonth.getMonth() - 1);
    }
    return ret;
  }, [accountInfo]);

  // The bar chart element and its size.
  const barChartRef = useRef<HTMLDivElement>(null);
  const barChartSize = useResizeObserver(barChartRef, { name: 'usagePage/barChart' });

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const downloadDataError = useMemo(() => {
    if (!selectedUsageBeginDate || !selectedUsageEndDate || !selectedCustomDate) {
      return '';
    }
    const start = DateTime.fromJSDate(selectedUsageBeginDate);
    const end = DateTime.fromJSDate(selectedUsageEndDate);
    const diff = end.diff(start, 'days').days;
    if (diff < 0) {
      return 'The end date must be after the start date';
    }
    if (diff > MAX_DAYS_FOR_DOWNLOAD_USAGE_DATA) {
      return `The maximum period for downloading the usage data
        is ${MAX_DAYS_FOR_DOWNLOAD_USAGE_DATA} days`;
    }
    return '';
  }, [selectedUsageBeginDate, selectedUsageEndDate, startDate, endDate, selectedCustomDate]);

  interface DownloadResponse {
    fileContents: Uint8Array;
    filename: string;
  }

  const downloadCSVFile = async (): Promise<DownloadResponse> => {
    let startDateSelection = months[monthIdx];
    let endDateSelection =
      new Date(startDateSelection.getFullYear(), startDateSelection.getMonth() + 1);

    if (selectedCustomDate && selectedUsageBeginDate && selectedUsageEndDate) {
      startDateSelection = new Date(selectedUsageBeginDate);
      startDateSelection.setHours(0, 0, 0, 0); // current day at midnight

      endDateSelection = new Date(selectedUsageEndDate);
      endDateSelection.setDate(selectedUsageEndDate.getDate() + 1); // get next day
      endDateSelection.setHours(0, 0, 0, 0); // next day at midnight
    }

    // assuming that start and end have the same timezone, we will use start time
    const tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const req = new frontendpb.UsageDetailFileRequest({
      startTime: Timestamp.fromDate(startDateSelection),
      endTime: Timestamp.fromDate(endDateSelection),
      timezone: tzName,
      accountLevelUsageData: isAdmin,
    });
    let fileContents = Uint8Array.from([]);
    let filename = '';
    try {
      const fileReply: frontendpb.UsageDetailFileReply = await rpc.callRetry(
        'UsageDetailFileRequest',
        rpc.client.downloadUsageDetailFile,
        req,
      );
      if (!fileReply.usageDetailFile) {
        throw new Error('No file data received');
      }
      fileContents = fileReply.usageDetailFile.contents;
      filename = fileReply.usageDetailFile.filename;
    } catch (err) {
      console.error('Download failed: ', err);
      addRpcError('Error during file download.', err);
    }
    return {
      fileContents,
      filename,
    };
  };

  useEffect(() => {
    if (!selectedCustomDate || (selectedUsageBeginDate && selectedUsageEndDate)) {
      setUpdating(true);

      let startDateSelection = months[monthIdx];
      let endDateSelection =
        new Date(startDateSelection.getFullYear(), startDateSelection.getMonth() + 1);
      if (selectedCustomDate && selectedUsageBeginDate && selectedUsageEndDate) {
        startDateSelection = new Date(selectedUsageBeginDate);
        startDateSelection.setHours(0, 0, 0, 0);
        endDateSelection = new Date(selectedUsageEndDate);
        endDateSelection.setHours(23, 59, 59, 999);
      }

      setStartDate(startDateSelection);
      setEndDate(endDateSelection);

      const req = new frontendpb.ResourceUsageRequest({
        startTime: Timestamp.fromDate(startDateSelection),
        endTime: Timestamp.fromDate(endDateSelection),
        accountLevelUsageData: isAdmin,
      });
      const reqDetails = new frontendpb.ResourceUsageDetailRequest({
        startTime: Timestamp.fromDate(startDateSelection),
        endTime: Timestamp.fromDate(endDateSelection),
        accountLevelUsageData: isAdmin,
      });

      const getResourceUsage = () => rpc.callRetry(
        'ResourceUsage',
        rpc.client.resourceUsage,
        req,
      );
      const getResourceUsageDetail = () => rpc.callRetry(
        'ResourceUsageDetail',
        rpc.client.resourceUsageDetail,
        reqDetails,
      );
      const reqCreditsRemaining = new frontendpb.CreditsRemainingRequest();
      const getCreditsRemaining =
        () => rpc.callRetry('CreditsRemaining', rpc.client.creditsRemaining, reqCreditsRemaining);

      Promise.all([getResourceUsage(), getResourceUsageDetail(), getCreditsRemaining()])
        .then(([resourceUsageData, usageDetailData, creditsRemainingData]) => {
          if (accountInfo?.billingType === frontendpb.BillingType.UNLIMITED_BILLING_TYPE) {
            setRemainingCredits('Unlimited');
          } else {
            const creditsValue = creditsRemainingData.creditsRemaining?.value ?? 0;
            setRemainingCredits(Number.isNaN(creditsValue) ? 'n/a' : creditsValue.toFixed(2));
          }

          setDateRangeBucket({ name: '', usage: resourceUsageData.usage });

          const mappedTableData: ResourceUsageDetailRecord[] =
            usageDetailData.usage.map((usageRecord) => ({
              ...usageRecord,
              time: new Date(usageRecord.time * 1000),
              credits: Number(usageRecord.credits),
              discountAmount: usageRecord.discountAmount,
              chargeAmount: usageRecord.chargeAmount,
            }));

          setTableData(mappedTableData);
        }).catch((err: Error) => {
          addRpcError('Error fetching resource usage records', err);
        }).finally(() => {
          setUpdating(false);
        });
    }
  }, [monthIdx, selectedUsageBeginDate, selectedUsageEndDate]);

  const selectedClassifier = classifiers[classifierIdx];
  const selectedUsageType = usageTypes[usageTypeIdx];

  const users = useMemo(() => accountInfo?.user || [], [accountInfo]);
  const usageTotals = useMemo(() => usageTypes.map((usageType) => {
    const totalRaw = dateRangeBucket.usage.filter(usageType.rule).reduce((total, usage) => (
      total + fromBigInt(usage.price)
    ), 0);
    const usageCount = usageType.unitTransform(totalRaw);
    return usageCount.toFixed(usageCount > 1000 ? 0 : 2);
  }), [dateRangeBucket]);

  // Filter and classify the ResourceUsage entries for the selected month.
  const classifiedUsage = useMemo(() => {
    const filteredUsage = dateRangeBucket.usage.filter(usageTypes[usageTypeIdx].rule);
    const unsortedUsage = classify(filteredUsage, users, selectedClassifier);
    return unsortedUsage.sort((a, b) => b.totalPrice - a.totalPrice);
  }, [dateRangeBucket, selectedClassifier, usageTypeIdx, users]);

  // Sort the usage by total price. If the maximum colors are exceeded. Group
  // the smallest categories into an "other" category.
  const categories = useMemo(() => {
    let hasOtherCategory = false;
    while (classifiedUsage.length > MAX_COLORS) {
      const leastUsage = classifiedUsage.pop();
      const otherUsage = classifiedUsage[MAX_COLORS - 1];
      otherUsage!.name = 'other';
      otherUsage!.totalPrice += leastUsage!.totalPrice;
      otherUsage!.usage = otherUsage!.usage.concat(leastUsage!.usage);
      otherUsage!.keys = otherUsage!.keys.concat(leastUsage!.keys);
      hasOtherCategory = true;
    }

    // Pick colors for the daily usage chart.
    const cats = classifiedUsage.map((classification, index) => ({
      name: classification.name,
      color: getTertiaryColor(index),
    }));
    // Give the other category a gray color.
    if (hasOtherCategory) {
      cats[MAX_COLORS - 1].color = colors.neutral600;
    }
    return cats;
  }, [classifiedUsage]);

  // Split the month's usage into daily buckets for the daily usage chart.
  const data = useMemo(() => {
    if (!startDate || !endDate) {
      return [];
    }

    const dailyUsage = bucketizeByDateRange(startDate, endDate, dateRangeBucket.usage);
    return dailyUsage.map((dateBucket) => {
      // Filter and classify the daily usage.
      const filteredBucket = dateBucket.usage.filter(selectedUsageType.rule);
      const dayUsage = classify(filteredBucket, users, selectedClassifier);
      const datum: Datum = {
        label: dateBucket.name,
      };
      classifiedUsage.forEach((classification) => {
        let sum = 0;
        classification.keys.forEach((key) => {
          // Some days may not have values for all keys.
          const usage = dayUsage.find((item) => item.name === key);
          sum += usage ?
            parseFloat(selectedUsageType.unitTransform(usage.totalPrice).toFixed(2)) :
            0;
        });
        datum[classification.name] = sum;
      });
      return datum;
    });
  }, [classifiedUsage, dateRangeBucket, selectedClassifier,
    selectedUsageType, users, startDate, endDate]);

  const monthOptions: SelectOption<number>[] = months.map((month, i) => ({
    value: i,
    name: formatDate(month),
    selected: i === monthIdx,
  }));

  const classifierOptions: SelectOption<number>[] = classifiers.map((classifier, i) => ({
    value: i,
    name: classifier.name,
    selected: i === classifierIdx,
  }));

  const usageTypeOptions: SelectOption<number>[] = usageTypes.map((usageType, i) => ({
    value: i,
    name: usageType.name,
    selected: i === usageTypeIdx,
  }));

  const usageTableColumnConfigs: ColumnConfig[] = [
    { id: 'classifier', label: selectedClassifier.name.toUpperCase(), type: 'string' },
    { id: 'usageType', label: selectedUsageType.unit.toUpperCase(), type: 'number' },
  ];

  const usageTableRowConfigs: RowConfig[] = classifiedUsage.map((classification, index) => ({
    id: classification.name,
    values: {
      classifier: classification.name,
      usageType: selectedUsageType.unitTransform(classification.totalPrice).toFixed(2),
    },
    cellDisplay: {
      classifier: [
        { type: 'bullet', color: categories[index].color },
      ],
    },
  }));

  const rowConfigs = generateRowConfigs(
    tableData,
    columnConfigs.map((config) => config.id as keyof ResourceUsageDetailRecord),
  );
  const usageDetailRowData = getRowData(rowConfigs, selectedTab);

  const showNewTable = newTableTabs.has(selectedTab);

  return (
    <div>
      <div className={classes.menuContainer}>
        <RadioButtonGroup
          disabled={false}
          kind="secondary"
          name="dateOptions"
          onChange={setSelectedCustomDate}
          options={DateFilteringOptions}
          value={!!selectedCustomDate}
        />
      </div>
      <br />
      <br />
      <div className={classes.menuContainer}>
        {!selectedCustomDate ? (
          <>
            <span>{'See one month\'s usage starting from'}</span>
            <DataSelect
              disabled={updating}
              onChange={setMonthIdx}
              options={monthOptions}
            />
          </>
        ) : (
          <DateRangeSelect
            alwaysShowEndDateSelector
            beginDatePlaceholder="Period Start"
            endDatePlaceholder="Period End"
            label="Select a date range"
            maxRange={{ value: 1, unit: 'year' }}
            onBeginDateChange={handleBeginDateChange}
            onEndDateChange={handleEndDateChange}
            selectedBeginDate={selectedUsageBeginDate}
            selectedEndDate={selectedUsageEndDate}
          />
        )}
      </div>

      <div className={commonClasses.summaryContainer}>
        {usageTypes.map((usageType, index) => (
          <div className={commonClasses.summary} key={usageType.name}>
            <span>{`Total ${usageType.name}`}</span>
            <div className={commonClasses.summaryMain}>
              {updating ? (
                <CircularLoader size={20} />
              ) : (
                `${usageTotals[index]} ${usageType.unit}`
              )}
            </div>
            <span>{selectedCustomDate ? 'Used this period' : 'Used this month'}</span>
          </div>
        ))}
        <div className={commonClasses.summary} key="remaining-credits">
          <span>Remaining Credits (approx.)</span>
          <div className={commonClasses.summaryMain}>
            {updating ? <CircularLoader size={20} /> : <>{remainingCredits}</>}
          </div>
        </div>
      </div>

      <div className={commonClasses.title}>Group By</div>
      <div className={classes.menuContainer}>
        <span>Group usage by</span>
        <DataSelect
          onChange={setClassifierIdx}
          options={classifierOptions}
        />
        <span>and filter by</span>
        <DataSelect
          onChange={setUsageTypeIdx}
          options={usageTypeOptions}
        />
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        ref={barChartRef}>
        <Table>
          <TableHead className={commonClasses.tableHead}>
            <TableRow>
              <TableCell>DAILY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={commonClasses.tableBody}>
            <TableRow>
              <TableCell>
                <BarStackChart
                  axisColor={colors.neutral300}
                  bgColor={colors.surfaceDark2}
                  categories={categories}
                  data={data}
                  height={200}
                  labelColor={colors.lowEmphasisText}
                  unit={selectedUsageType.unit}
                  // The width is shortened because of the padding in TableCell.
                  width={barChartSize.width - 34}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <NewTable
        asBlock
        columnConfigs={usageTableColumnConfigs}
        defaultSort={{ columnId: 'usageType', descending: true }}
        disableColumnSettings
        name="usage-table"
        rowConfigs={usageTableRowConfigs}
      />
      <br />
      <div>
        <div className={classes.gridContainer}>
          <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
            <div style={{ flex: '1 1 0%' }}>
              <DataTabs tabs={tabProps} />
            </div>
            <div style={{ flex: '1 1 0%' }}>
              <DownloadButton
                disabled={!!downloadDataError}
                downloadFile={downloadCSVFile}
                title={downloadDataError || 'Download Usage Data'}
              />
            </div>
          </div>
          {showNewTable && (
            <NewTable
              columnConfigs={columnConfigs}
              name={`usage-detail-${selectedTab.replace('Tab', '').toLowerCase()}-table`}
              pagination={{
                availablePageSizes: tabPageSize,
                persist: true,
              }}
              rowConfigs={usageDetailRowData}
            />
          )}
        </div>
      </div>
    </div>
  );
};
