import React from 'react';

import { SvgIcon } from './Icon/SvgIcon';
import { createStyles, makeStyles } from './Theme';
import Tooltip from './Tooltip';

import { colors } from '@/lib/designSystem';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    button: {
      background: 'none',
      border: '0',
      padding: '0',
      cursor: 'pointer',
      transition: 'filter 0.25s ease',
      transform: 'translate3d(0, 0, 0)',

      '&:hover': {
        filter: 'brightness(1.15)',
      },
    },
  }),
  { name: 'FavoriteStar' },
);

type FavoriteStarProps = {
  active: boolean;
  onChange: (value: boolean) => void;
}

export function FavoriteStar({ active, onChange }: FavoriteStarProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip title={active ? 'Remove from favorites' : 'Add to favorites'}>
        <button
          className={classes.button}
          onClick={() => {
            onChange(!active);
          }}
          type="button">
          <SvgIcon
            color={active ? colors.yellow600 : colors.neutral800}
            maxHeight={12}
            maxWidth={12}
            name={active ? 'starFilled' : 'starOutlined'}
          />
        </button>
      </Tooltip>
    </div>
  );
}
