import { Struct } from '@bufbuild/protobuf';
import mixpanel from 'mixpanel-browser';

import { isHostedEnvironment, isProd } from '../lib/RuntimeParams';
import { MIXPANEL_TOKEN_DEV, MIXPANEL_TOKEN_PROD } from '../lib/constants';
import { Logger } from '../lib/observability/logs';
import * as rpc from '../lib/rpc';
import * as frontendpb from '../proto/frontend/frontend_pb';

const logger = new Logger('analytics');

export interface IAnalytics {
  init(): void;
  identify(userId: string, traits?: Record<string, any>): void;
  updateUserProperties(properties: Record<string, any>): void;
  track(eventName: string, properties?: Record<string, any>): void;
  page(pageName: string, properties?: Record<string, any>): void;
}

class MixpanelAnalytics implements IAnalytics {
  private shouldSendEvents: boolean = true;

  init(): void {
    if (!isHostedEnvironment) {
      return console.warn('Not Hosted Environment, Mixpanel is not initialized');
    }

    const token = isProd ? MIXPANEL_TOKEN_PROD : MIXPANEL_TOKEN_DEV;
    mixpanel.init(token, { debug: !isHostedEnvironment });
  }

  private checkShouldSendEvents(userId: string, traits?: Record<string, any>): boolean {
    const email = traits?.$email;
    const isInternal = email?.endsWith('@luminarycloud.com');
    const isAutomation =
      (email?.startsWith('automation') || email?.startsWith('dev')) && isInternal;

    return !isAutomation;
  }

  identify(userId: string, traits?: Record<string, any>): void {
    if (!isHostedEnvironment || !this.checkShouldSendEvents(userId, traits)) {
      return console.warn(`Mixpanel identify: ${userId}`, traits);
    }

    mixpanel.identify(userId);
    if (traits) {
      try {
        // JSON.stringify may throw if a value is undefined.
        rpc.callRetry(
          'Analytics',
          rpc.client.analytics,
          new frontendpb.AnalyticsRequest({
            event: frontendpb.AnalyticsRequest_Event.UPDATE_USER_PROPERTIES,
            properties: traits && new Struct().fromJsonString(JSON.stringify(traits)),
          }),
        ).catch(logger.error);
      } catch (err) {
        logger.error(err);
      }
    }
  }

  updateUserProperties(properties: Record<string, any>): void {
    if (!isHostedEnvironment || !this.shouldSendEvents) {
      return console.warn(`Mixpanel update user properties:`, properties);
    }

    try {
      // JSON.stringify may throw if a value is undefined.
      rpc.callRetry(
        'Analytics',
        rpc.client.analytics,
        new frontendpb.AnalyticsRequest({
          event: frontendpb.AnalyticsRequest_Event.UPDATE_USER_PROPERTIES,
          properties: properties && new Struct().fromJsonString(JSON.stringify(properties)),
        }),
      ).catch(logger.error);
    } catch (err) {
      logger.error(err);
    }
  }

  track(eventName: string, properties?: Record<string, any>): void {
    if (!isHostedEnvironment || !this.shouldSendEvents) {
      return console.warn(`Mixpanel event: ${eventName}`, properties);
    }
    try {
      rpc.callRetry(
        'Analytics',
        rpc.client.analytics,
        new frontendpb.AnalyticsRequest({
          event: frontendpb.AnalyticsRequest_Event.TRACK,
          properties: properties && new Struct().fromJsonString(JSON.stringify(properties)),
        }),
      ).catch(logger.error);
    } catch (err) {
      logger.error(err);
    }
  }

  page(pageName: string, properties?: Record<string, any>): void {
    if (!isHostedEnvironment || !this.shouldSendEvents) {
      return console.warn(`Mixpanel page view: ${pageName}`, properties);
    } mixpanel.track('Page View', { page: pageName, ...properties });
  }
}

export const analytics: IAnalytics = new MixpanelAnalytics();
