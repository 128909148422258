// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { globalDisabledReason } from '../../../lib/geometryUtils';
import { filteredMenuItems } from '../../../lib/menuUtil';
import {
  createTagsFromRightClickTreeNodeMenuItems,
} from '../../../lib/treeUtils';
import { useIsGeometryServerActive } from '../../../recoil/geometry/geometryServerStatus';
import { useGeometrySelectedFeature } from '../../../recoil/geometry/geometryState';
import { useSetTagSelectionSettings } from '../../../recoil/lcvis/tagsSelection';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useProjectContext } from '../../context/ProjectContext';
import { TreeRow } from '../TreeRow';

export const TagContainerTreeRow = (props: SimulationRowProps) => {
  const { geometryId, readOnly } = useProjectContext();
  const setTagSelection = useSetTagSelectionSettings();
  const isGeometryView = useIsGeometryView();

  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const isGeoServerActive = useIsGeometryServerActive(geometryId);

  const createTags = useCallback(() => {
    setTagSelection((prev) => ({
      ...prev,
      active: !prev.active,
    }));
  }, [setTagSelection]);

  const defaultTagDisabledReason = globalDisabledReason(
    selectedFeature,
    readOnly,
    isGeoServerActive,
  );

  const getContextMenuItems = useCallback(() => filteredMenuItems([
    {
      itemConfig: createTagsFromRightClickTreeNodeMenuItems(
        createTags,
        !!defaultTagDisabledReason,
        defaultTagDisabledReason,
      ),
      shouldShow: isGeometryView,
    },
  ]), [createTags, defaultTagDisabledReason, isGeometryView]);

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
    />
  );
};
