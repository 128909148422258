// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useRecoilCallback } from 'recoil';

import { useSetupNewMeshWorkflow } from '../components/hooks/useMesh';
import * as persist from '../lib/persist';
import { useForceViewStateSync } from '../state/external/project/sharing';

import { frontendMenuStateRpc } from './frontendMenuState';
import { meshMetadataSelector } from './meshState';
import { outputNodesSelectorRpc, outputNodesState } from './outputNodes';
import { newMeshKeys, paraviewInitialSettingsState, paraviewSettingsKeyPrefix } from './paraviewState';
import { plotNodesSelectorRpc, plotNodesState } from './plotNodes';
import { selectedGeometryState } from './selectedGeometry';
import { meshGenParamsSelector, meshGenerationStateRpc } from './useMeshGeneration';
import { meshingMultiPartSelectorRpc, meshingMultiPartState } from './useMeshingMultiPart';
import { stoppingConditionsSelectorRpc, stoppingConditionsState } from './useStoppingConditions';
import { projectConfigSelectorRpc, projectConfigState } from './workflowConfig';

// Refresh all of the recoil state related to library state, so we can ensure state is
// properly shown after import.  Refreshes selector caches to re-fetch from SessionState,
// and additionally resets atoms so that they properly reflect the selector changes.
// This is really only necessary due to LC-15045, because session state streaming is broken.
export const useRefreshLibraryState = (projectId: string, activeUrl: string) => {
  const recoilKey: persist.RecoilProjectKey = {
    projectId,
    workflowId: '',
    jobId: '',
  };
  const forceViewStateSync = useForceViewStateSync(projectId);
  const { setupNewMeshWorkflowWithoutSettingsUpdateWithoutParamUpdate } = useSetupNewMeshWorkflow(
    projectId,
  );
  return useRecoilCallback(
    ({ refresh, reset, set, snapshot }) => async () => {
      // Refresh all the selectors first
      refresh(projectConfigSelectorRpc({ projectId, geometryUrl: activeUrl }));
      refresh(outputNodesSelectorRpc(recoilKey));
      refresh(stoppingConditionsSelectorRpc(recoilKey));
      refresh(meshGenerationStateRpc(projectId));
      refresh(meshingMultiPartSelectorRpc(projectId));
      refresh(plotNodesSelectorRpc(projectId));
      const meshMeta = await snapshot.getPromise(meshMetadataSelector({
        projectId,
        meshUrl: activeUrl,
      }));

      // Now, reset all the atoms to re-evaluate their default
      reset(projectConfigState(projectId));
      reset(outputNodesState(recoilKey));
      reset(stoppingConditionsState(recoilKey));
      refresh(meshGenParamsSelector({ projectId, meshUrl: activeUrl }));
      reset(meshingMultiPartState(projectId));
      reset(frontendMenuStateRpc(recoilKey));
      reset(plotNodesState(projectId));
      reset(paraviewInitialSettingsState({
        projectId,
        meshKeys: newMeshKeys(paraviewSettingsKeyPrefix, activeUrl, meshMeta),
      }));
      // Force pv to reload
      forceViewStateSync();
      // If we have a geometry in the setup stage, it means that we can use the library settings for
      // meshing and therefore, that we should be showing the mesh panel in edit mode with the
      // settings coming from the library.
      const selectedGeometry = await snapshot.getPromise(selectedGeometryState(recoilKey));
      if (selectedGeometry.geometryId) {
        // Make sure that this function does not update the simulation params, since that will race
        // and interact with the refresh and resets above.
        setupNewMeshWorkflowWithoutSettingsUpdateWithoutParamUpdate();
      }
    },
    [activeUrl, projectId],
  );
};
