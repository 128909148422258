// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { NodeSubselect } from '../../NodeSubselect';

import { ParamGroupName, paramGroupDesc } from '@/SimulationParamDescriptor';
import { DataSelect } from '@/components/Form/DataSelect';
import LabeledInput from '@/components/Form/LabeledInput';
import { CollapsibleNodePanel } from '@/components/Panel/CollapsibleNodePanel';
import { ParamForm } from '@/components/ParamForm';
import { useProjectContext } from '@/components/context/ProjectContext';
import { useSelectionContext } from '@/components/context/SelectionManager';
import assert from '@/lib/assert';
import { SelectOption } from '@/lib/componentTypes/form';
import { GENERAL_SETTINGS_NODE_ID, NodeType } from '@/lib/simulationTree/node';
import { defaultNodeFilter } from '@/lib/subselectUtils';
import { useAdjoint } from '@/model/hooks/useAdjoint';
import { useFluidPhysics } from '@/model/hooks/useFluidPhysics';
import { useHeatPhysics } from '@/model/hooks/useHeatPhysics';
import * as simulationpb from '@/proto/client/simulation_pb';
import { useOutputNodes } from '@/recoil/outputNodes';
import { NodeFilter } from '@/recoil/simulationTreeSubselect';
import { useSimulationParamScope } from '@/state/external/project/simulation/paramScope';

export const AdjointOutput = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == State
  const { adjoint, setAdjoint } = useAdjoint(projectId, workflowId, jobId, readOnly);
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);

  // Make selection options from compatible output nodes.
  const outputOptions: SelectOption<string>[] = [];

  outputNodes.nodes.forEach((node) => {
    switch (node.nodeProps.case) {
      case 'surfaceAverage':
      case 'force':
      case 'derived':
      case 'volumeReduction':
        outputOptions.push({
          name: node.name,
          value: node.id,
          selected: adjoint?.adjointOutput?.id === node.id,
        });
        break;
      default:
        break;
    }
  });

  return (
    <LabeledInput
      help="Output of interest for which geometric sensitivities are computed."
      key="adjoint-output"
      label="Adjoint Output">
      <DataSelect
        asBlock
        disabled={readOnly || !outputOptions.length}
        onChange={async (value: string) => {
          const newAdjoint = adjoint!.clone();
          newAdjoint.adjointOutput!.id = value;
          await setAdjoint(newAdjoint);
        }}
        options={outputOptions}
        size="small"
        tooltip={!outputOptions.length ?
          'There are no "Surface", "Volume", or "Custom" outputs defined.' : ''}
      />
    </LabeledInput>
  );
};

export const AdjointSensitivitySurfaces = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode } = useSelectionContext();
  assert(!!selectedNode, 'No selected adjoint row');

  // == Hooks
  const {
    adjoint,
    selectedSurfaces,
    setSurfaces,
  } = useAdjoint(projectId, workflowId, jobId, readOnly);

  // == State
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);

  const nodeFilter = useCallback<NodeFilter>((nodeType) => {
    if ([NodeType.SURFACE, NodeType.SURFACE_GROUP].includes(nodeType)) {
      return {
        related: true,
      };
    }

    return defaultNodeFilter(nodeType);
  }, []);

  // Make selection options from compatible output nodes.
  const outputOptions: SelectOption<string>[] = [];

  outputNodes.nodes.forEach((node) => {
    switch (node.nodeProps.case) {
      case 'surfaceAverage':
      case 'force':
      case 'derived':
      case 'volumeReduction':
        outputOptions.push({
          name: node.name,
          value: node.id,
          selected: adjoint?.adjointOutput?.id === node.id,
        });
        break;
      default:
        break;
    }
  });

  return (
    <CollapsibleNodePanel
      heading="Sensitivity Surfaces"
      nodeId={GENERAL_SETTINGS_NODE_ID}
      panelName="sensitivity surfaces">
      <NodeSubselect
        id="adjoint-sensitivity-surfaces"
        labels={['surfaces']}
        nodeFilter={nodeFilter}
        nodeIds={selectedSurfaces}
        onChange={setSurfaces}
        readOnly={readOnly}
        referenceNodeIds={[selectedNode?.id]}
        title="Geometry (Optional)"
        visibleTreeNodeTypes={[NodeType.SURFACE, NodeType.SURFACE_GROUP]}
      />
    </CollapsibleNodePanel>
  );
};

const paramAdjointControlsFluid = paramGroupDesc[ParamGroupName.AdjointControlsFluid];
const paramAdjointControlsHeat = paramGroupDesc[ParamGroupName.AdjointControlsHeat];

interface AdjointSolverSettingsProps {
  physicsId: string;
}

export const AdjointFluidSolverSettings = (props: AdjointSolverSettingsProps) => {
  // == Props
  const { physicsId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == State
  const {
    getParamScope,
    adjointSolutionControls,
    setAdjointSolutionControls,
  } = useFluidPhysics(projectId, workflowId, jobId, readOnly, physicsId);

  // == Data
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const physicsScope = getParamScope(paramScope);

  if (!adjointSolutionControls) {
    return null;
  }

  return (
    <ParamForm<simulationpb.AdjointControlsFluid>
      group={paramAdjointControlsFluid}
      onUpdate={setAdjointSolutionControls}
      paramScope={physicsScope}
      proto={adjointSolutionControls}
      readOnly={readOnly}
    />
  );
};

export const AdjointHeatSolverSettings = (props: AdjointSolverSettingsProps) => {
  // == Props
  const { physicsId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == State
  const {
    getParamScope,
    adjointSolutionControls,
    setAdjointSolutionControls,
  } = useHeatPhysics(projectId, workflowId, jobId, readOnly, physicsId);

  // == Data
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const physicsScope = getParamScope(paramScope);

  if (!adjointSolutionControls) {
    return null;
  }

  return (
    <ParamForm<simulationpb.AdjointControlsHeat>
      group={paramAdjointControlsHeat}
      onUpdate={setAdjointSolutionControls}
      paramScope={physicsScope}
      proto={adjointSolutionControls}
      readOnly={readOnly}
    />
  );
};
