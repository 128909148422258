// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/parametricworker/parametricworker.proto (package luminary.proto.parametricworker, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AdFloatType } from "../base/base_pb.js";

/**
 * Single parameter in the model.
 *
 * @generated from message luminary.proto.parametricworker.Parameter
 */
export const Parameter = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.Parameter",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "owner", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "float", kind: "message", T: Parameter_Float, oneof: "type" },
  ],
);

/**
 * @generated from message luminary.proto.parametricworker.Parameter.Float
 */
export const Parameter_Float = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.Parameter.Float",
  () => [
    { no: 1, name: "ad_type", kind: "message", T: AdFloatType },
    { no: 2, name: "min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
  {localName: "Parameter_Float"},
);

/**
 * @generated from message luminary.proto.parametricworker.OpenRequest
 */
export const OpenRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.OpenRequest",
  () => [
    { no: 1, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.parametricworker.OpenReply
 */
export const OpenReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.OpenReply",
  [],
);

/**
 * @generated from message luminary.proto.parametricworker.GetParametersRequest
 */
export const GetParametersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.GetParametersRequest",
  [],
);

/**
 * @generated from message luminary.proto.parametricworker.GetParametersReply
 */
export const GetParametersReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.GetParametersReply",
  () => [
    { no: 1, name: "parameters", kind: "message", T: Parameter, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.parametricworker.UpdateParametersRequest
 */
export const UpdateParametersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.UpdateParametersRequest",
  () => [
    { no: 1, name: "parameters", kind: "message", T: Parameter, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.parametricworker.UpdateParametersReply
 */
export const UpdateParametersReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.UpdateParametersReply",
  [],
);

/**
 * @generated from message luminary.proto.parametricworker.ExportRequest
 */
export const ExportRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.ExportRequest",
  () => [
    { no: 1, name: "dir", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.parametricworker.ExportReply
 */
export const ExportReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.ExportReply",
  () => [
    { no: 1, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.parametricworker.ShutdownRequest
 */
export const ShutdownRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.ShutdownRequest",
  [],
);

/**
 * @generated from message luminary.proto.parametricworker.ShutdownReply
 */
export const ShutdownReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.parametricworker.ShutdownReply",
  [],
);

