// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as entitygrouppb from '../proto/entitygroup/entitygroup_pb';
import { EntityGroupData } from '../recoil/entityGroupState';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';

import GroupMap, { GroupLeafMap } from './GroupMap';
import { SvgIconSpec } from './componentTypes/svgIcon';
import { EntityGroup, EntityGroupMap } from './entityGroupMap';
import { isSuperset, subtractSet, unionSet } from './lang';

export type DescendantCountMap = Map<string, number>;

export const convertToProto = (entityGroupMap: EntityGroupMap): entitygrouppb.EntityGroups => {
  const surfaceGroups = new entitygrouppb.EntityGroups({
    upgraded: true,
    groupPrefixRemoved: true,
  });
  entityGroupMap.getGroups().forEach((group) => {
    const { id, name, parentId, children, entityType, index } = group;
    const entityGroup = new entitygrouppb.EntityGroup({
      id,
      name,
      parent: parentId,
      children: [...children],
      type: entityType,
    });
    if (index) {
      entityGroup.index = group.index;
    }
    surfaceGroups.groups[group.id] = entityGroup;
  });
  return surfaceGroups;
};

// Adds a group and its parent if they are not in the group map yet.
const maybeAddGroup = (
  entityGroup: entitygrouppb.EntityGroup,
  surfaceGroups: entitygrouppb.EntityGroups,
  groupMap: EntityGroupMap,
) => {
  const { id, name, parent, index, type } = entityGroup;
  if (!groupMap.has(parent) && !GroupMap.isRoot(parent)) {
    maybeAddGroup(
      surfaceGroups.groups[parent]!,
      surfaceGroups,
      groupMap,
    );
  }
  // Group maybe already added if it is a parent of another group
  if (!groupMap.has(id)) {
    groupMap.add({
      parentId: parent,
      name,
      index,
      id,
      entityType: type === entitygrouppb.EntityType.INVALID_ENTITY_TYPE ?
        entitygrouppb.EntityType.SURFACE : type,
    });
  }
};

export function convertFromProto(
  surfaceGroups: entitygrouppb.EntityGroups,
  disableCallback?: boolean,
): EntityGroupMap {
  const entityGroupMap = new EntityGroupMap(undefined, disableCallback);
  Object.entries(surfaceGroups.groups).forEach(([_, entityGroup]) => {
    // We cannot make any assumption on the order of items in the map coming from the database.
    // The frontend group structure requires that a parent is added before its children. The
    // call to "maybeAddGroup" ensures that this is the case.
    maybeAddGroup(entityGroup, surfaceGroups, entityGroupMap);
  });
  return entityGroupMap;
}

// Remove a set of strings in setA and replace them with another set.
export function replaceStrings(setA: string[], remove: string[], replace: string[]): string[] {
  if (!remove.length || !isSuperset(setA, remove)) {
    return setA;
  }
  return [...unionSet(subtractSet(setA, remove), replace)];
}

/**
 * Removes a set of strings in setA and replaces them with another set. setA is modified in place.
 * If any of the strings to remove are not in setA, we return early and don't modify setA.
 *
 * @param setA the set to modify
 * @param remove the set of strings to remove
 * @param replace the set of strings to add
 * @returns void
 */
export function replaceStringsInPlace(
  setA: Set<string>,
  remove: Set<string>,
  replace: Set<string>,
): void {
  if (!remove.size) {
    return;
  }
  // disable eslint so we can return early if needed
  // eslint-disable-next-line no-restricted-syntax
  for (const elem of remove) {
    if (!setA.has(elem)) {
      return;
    }
  }
  remove.forEach((elem) => {
    setA.delete(elem);
  });
  replace.forEach((elem) => {
    setA.add(elem);
  });
}

/**
 * Given a list of entities and groups, forms as many groups as possible by rolling up the entities
 * into their parent groups. The resulting list will contain the rolled up groups and the entities
 * that do not completely form another group.
 *
 * @param entityGroupMap the entity group map
 * @returns a function that takes a list of entities, and returns a list of rolled up groups and
 * entities
 */
export function rollupGroups(groupData: EntityGroupData) {
  // rollup and perLeaf are named functions so they show up in the performance profiler
  const rollup = (entities: string[]) => {
    const rolledUp = new Set(entities);
    const perLeaf = (childIds: Set<string>, id: string) => {
      replaceStringsInPlace(rolledUp, childIds, new Set([id]));
    };

    // Process IDs in order of depth (level).  If "Group 1" contains only "Group 2" and "Group 2"
    // contains only "Item1" and "Item2", this ensures "Group 1" is processed before "Group 2".  In
    // other words, we make sure to roll up as high in the hierarachy as possible.
    groupData.idsOrderedByLevel.forEach(
      (id) => perLeaf(groupData.leafMap.get(id) ?? new Set([]), id),
    );

    return [...rolledUp];
  };
  return rollup;
}

// Expands every group in the entities list, i.e. replaces every occurrence of a group with its
// contained leaf children.
export function expandGroups(leafMap: GroupLeafMap) {
  return (entities: string[]) => {
    const expanded = entities.reduce((result, id) => {
      const idExpanded = leafMap.has(id) ? leafMap.get(id)! : [id];
      return unionSet(result, idExpanded);
    }, new Set<string>());
    return [...expanded];
  };
}

// Expands all groups in the entities list, excluding those which have TAG_CONTAINER as entity type.
// No duplicate entities are returned. Those groups that have TAG_CONTAINER as entity type are
// not expanded.
export function expandGroupsExcludingTags(entityGroupData: EntityGroupData, entities: string[]) {
  const entitiesNoTags: string[] = [];
  const entitiesWithTags: string[] = [];

  entities.forEach((entity) => {
    if (entityGroupData.groupMap.has(entity)) {
      if (entityGroupData.groupMap.get(entity).entityType ===
        entitygrouppb.EntityType.TAG_CONTAINER) {
        entitiesWithTags.push(entity);
      } else {
        entitiesNoTags.push(entity);
      }
    } else {
      entitiesNoTags.push(entity);
    }
  });
  return Array.from(
    unionSet(expandGroups(entityGroupData.leafMap)(entitiesNoTags), new Set(entitiesWithTags)),
  );
}

// Returns true if a group is visible based on the entries in the visibility map. A group is visible
// if any of its children are visible.
export function isGroupVisible(
  visibility: Map<string, boolean>,
  entityGroupMap: EntityGroupMap,
  groupId: string,
): boolean {
  if (!entityGroupMap.has(groupId)) {
    return false;
  }
  const children = entityGroupMap.get(groupId).children;
  if (!children.size) {
    return visibility.get(groupId) || false;
  }
  return Array.from(children).some((id) => isGroupVisible(visibility, entityGroupMap, id)) || false;
}

// Toggles visibility of groups. If a group is toggled, all of its children will be toggled too.
export function toggleVisibility(
  visibility: Map<string, boolean>,
  entityGroupMap: EntityGroupMap,
  nodeIds: Set<string>,
  toggleOn: boolean,
): Map<string, boolean> {
  let newVisibility = new Map(visibility);
  nodeIds.forEach((id) => {
    if (!entityGroupMap.has(id)) {
      return;
    }
    const group = entityGroupMap.get(id);
    if (group.children.size) {
      newVisibility = toggleVisibility(
        newVisibility,
        entityGroupMap,
        group.children,
        toggleOn,
      );
    } else {
      newVisibility.set(id, toggleOn);
    }
  });
  return newVisibility;
}

/**
 * Return a mapping of group ID lists keyed by entity type
 * @param groupIds
 * @param entityGroupMap
 * @returns
 */
export function bucketGroupsByType(groupIds: string[], entityGroupMap: EntityGroupMap) {
  const result = new Map<entitygrouppb.EntityType, string[]>();

  groupIds.forEach((id) => {
    const group = entityGroupMap.get(id);
    const type = group.entityType;
    result.set(type, [group.id, ...result.get(type) || []]);
  });

  return result;
}

export function getIconForEntityGroup(entityGroup: EntityGroup): SvgIconSpec | undefined {
  if (entityGroup.children.size) {
    return { name: 'groupAction' };
  }
  switch (entityGroup.entityType) {
    case entitygrouppb.EntityType.SURFACE:
      return { name: 'cubeOutline' };
    case entitygrouppb.EntityType.PARTICLE_GROUP:
      return { name: 'circle' };
    case entitygrouppb.EntityType.MONITOR_PLANE:
      return { name: 'parallelogramOutline' };
    case entitygrouppb.EntityType.PROBE_POINTS:
      return { name: 'circle', maxHeight: 4.5, maxWidth: 4.5 };
    case entitygrouppb.EntityType.MIXED:
      return { name: 'groupAction' };
    case entitygrouppb.EntityType.VOLUME:
      return { name: 'cubeSolid' };
    default:
      return undefined;
  }
}

/**
 * Makes a map of group ids to the number of descendants in the group.
 * @param entityGroupMap the entity group map
 * @returns the map of group id to numDescendants
 */
export const makeNumDescendantsMap = (entityGroupMap: EntityGroupMap): DescendantCountMap => {
  const numDescendantsMap = new Map<string, number>();

  const getNumDescendants = (groupId: string) => {
    if (numDescendantsMap.has(groupId)) {
      return numDescendantsMap.get(groupId) as number;
    }
    const group = entityGroupMap.get(groupId);
    let numDescendants = 0;
    group.children.forEach((childId) => {
      numDescendants += getNumDescendants(childId) || 1;
    });
    numDescendantsMap.set(groupId, numDescendants);
    return numDescendants;
  };
  getNumDescendants(EntityGroupMap.rootId);
  return numDescendantsMap;
};

export function getSurfaceGroupSurfaces(id: string, entityGroupData: EntityGroupData): Set<string> {
  const surfaceIds = new Set<string>([]);

  if (entityGroupData.groupMap.has(id)) {
    switch (entityGroupData.groupMap.get(id).entityType) {
      case entitygrouppb.EntityType.MIXED:
      case entitygrouppb.EntityType.SURFACE: {
        // Either type may be a group of surfaces, but if it's MIXED, it may also contain
        // non-surface entities that need to be removed
        entityGroupData.leafMap.get(id)?.forEach((leafId) => {
          if (
            entityGroupData.groupMap.has(leafId) &&
            entityGroupData.groupMap.get(leafId).entityType === entitygrouppb.EntityType.SURFACE
          ) {
            surfaceIds.add(leafId);
          }
        });
        break;
      }
      default: // no default
    }
  }
  return surfaceIds;
}

export const unwrapSurfaceIds = (
  ids: string[],
  geometryTags: GeometryTags,
  entityGroupData: EntityGroupData,
) => [...new Set(ids.flatMap((identifier) => {
  const isTag = geometryTags.isTagId(identifier);

  if (isTag) {
    return geometryTags.surfacesFromTagEntityGroupId(identifier) || [];
  }

  const isGroup = entityGroupData.groupMap.has(identifier) &&
    entityGroupData.groupMap.get(identifier).children.size > 0;

  if (isGroup) {
    return [...getSurfaceGroupSurfaces(identifier, entityGroupData)];
  }

  return [identifier];
}))];

// Unwraps volume IDs from items, whether they're tag IDs or volume IDs.
export const unwrapVolumeIds = (
  ids: string[],
  geometryTags: GeometryTags,
) => ids.flatMap((domain) => {
  if (geometryTags.isTagId(domain)) {
    return geometryTags.domainsFromTag(domain);
  }

  return [domain];
});

// Unwraps surface IDs supposing that none of the IDs are entity groups.
export const unwrapSurfaceIdsNoEntityGroups = (
  ids: string[],
  geometryTags: GeometryTags,
): string[] => [...new Set(ids.flatMap((identifier) => {
  const isTag = geometryTags.isTagId(identifier);

  if (isTag) {
    return geometryTags.surfacesFromTagEntityGroupId(identifier) || [];
  }

  return [identifier];
}))];
