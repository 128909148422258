// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import { CurrentView, isIntermediateView } from '../../lib/componentTypes/context';
import { IconSpec } from '../../lib/componentTypes/svgIcon';
import { OUTPUT_SCALARS } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { isUnmodifiedEscapeKey } from '../../lib/event';
import { clamp } from '../../lib/number';
import { CREATE_FARFIELD_NODE_ID, ITERS_PER_OUTPUT_NODE_ID, MODIFICATIONS_TREE_DATA_LOCATOR } from '../../lib/simulationTree/node';
import { getAllNodeMessages } from '../../lib/simulationValidation';
import { VIEWER_PADDING } from '../../lib/visUtils';
import { usePanel } from '../../recoil/expandedPanels';
import { useControlPanelModeValue } from '../../recoil/useProjectPage';
import { useProjectValidator } from '../../state/external/project/validator';
import { useCurrentView } from '../../state/internal/global/currentView';
import { useSimulationTree } from '../../state/internal/tree/simulation';
import { useVisHeightValue } from '../../state/internal/vis/visHeight';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { CollapsiblePanel } from '../Panel/CollapsiblePanel';
import { ROW_OUTER_HEIGHT } from '../Theme/commonStyles';
import { TOOLBAR_HEIGHT } from '../Toolbar/Toolbar';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { Dialog } from '../dialog/Base';
import { useIsExplorationSetup } from '../hooks/exploration/useCreateExploration';
import { useHasFarfield } from '../hooks/useHasFarfield';
import { useTree } from '../hooks/useTree';
import { SectionMessage } from '../notification/SectionMessage';
import NodeLink from '../treePanel/NodeLink';
import { SimulationRowContainer } from '../treePanel/SimulationRowContainer';
import { useArrowKeyNav } from '../treePanel/useArrowKeyNav';

import RightPaneTopControls from './RightPaneTopControls';
import { useGetTopInfoMessage } from './SimulationSettings';
import { MIN_HEIGHT, TREE_VERTICAL_PADDING, useTreePanelStyles } from './treePanelShared';

/**
 * The FloatingSimTree component is a card window that appears in the 3D viewer and displays
 * the SimulationTreePanel.
 * @returns CollapsiblePanel with the Simulation Tree
 */
export const FloatingSimTree = () => {
  // == Context
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds, isTreeModal } = useSelectionContext();
  // == Hooks
  const classes = useTreePanelStyles();

  // == Recoil
  const currentView = useCurrentView();
  const visHeight = useVisHeightValue();
  const workflowFlag = useWorkflowFlagValue();
  const controlPanelMode = useControlPanelModeValue();
  const isExplorationSetup = useIsExplorationSetup();
  const simTree = useSimulationTree(projectId, workflowId, jobId);
  const [expanded, setExpanded] = usePanel({
    nodeId: `floating-sim-tree-${projectId}`,
    panelName: 'floating-sim-tree',
    defaultExpanded: true,
  });
  const validator = useProjectValidator(projectId, workflowId, jobId);
  const hasFarfield = useHasFarfield();

  // == Data
  const [listContainerHeight, setListContainerHeight] = useState(MIN_HEIGHT);
  const [filter, setFilter] = useState<null | string>(null);
  const filterActive = filter !== null;
  const filterFilled = filterActive && filter !== '';
  const [outputHelpVisible, setOutputHelpVisible] = useState(false);

  const topInfoMessage = useGetTopInfoMessage();

  // This may break eventually (simTree.children[0]), but for now there is an extra parent node that
  // wraps modifications and history.
  const {
    listRef,
    rowProps,
    maybeUpdateRowsOpened,
  } = useTree(simTree.children[0], filterFilled, false);

  const handleKeyPress = useCallback((event) => {
    if (filterActive && isUnmodifiedEscapeKey(event)) {
      setFilter(null);
    }
  }, [filterActive]);

  // We are using the regular addEventListener because useEventListener doesn't work properly here
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // Listen to arrow keys for navigating in the geometry tree with keyboard shortcuts
  useArrowKeyNav(simTree, rowProps, listRef);

  const warnings = useMemo(() => getAllNodeMessages(validator, simTree), [simTree, validator]);

  const renderRow = useCallback((index, row) => {
    const { depth } = row;
    let icon: IconSpec | undefined;
    if (depth === 0 && currentView !== CurrentView.ANALYSIS) {
      if (warnings.has(row.node.id) || (row.node.id === CREATE_FARFIELD_NODE_ID && !hasFarfield)) {
        icon = { name: 'dottedCircle' };
      } else {
        icon = { name: 'diskCheck', color: colors.green600 };
      }
    }
    return (
      <SimulationRowContainer
        {...row}
        disableToggle={filterFilled}
        key={row.node.id}
        overrideIcon={icon}
      />
    );
  }, [filterFilled, warnings, currentView, hasFarfield]);

  useEffect(() => {
    maybeUpdateRowsOpened(simTree, selectedNodeIds);
  }, [selectedNodeIds, maybeUpdateRowsOpened, simTree]);

  // Make sure the List's parent container has some reasonable height depending on the content
  useLayoutEffect(() => {
    if (!visHeight) {
      return;
    }

    // Calculate the available height
    let maxHeight = (
      visHeight - (
        // Remove the paddings around the edges of the 3D viewer
        2 * VIEWER_PADDING
      ) - (
        // Remove the collapsible header for the Geometry panel + internal padding around the list
        36 + 2 * TREE_VERTICAL_PADDING
      )
    );

    // We should put a hardcap of 70% from the 3D viewer's height
    maxHeight = Math.min(visHeight * 0.7, maxHeight);

    // Set the height depending on the amount of rows, but no more than the calculated limit
    setListContainerHeight(
      clamp(rowProps.length * ROW_OUTER_HEIGHT, [MIN_HEIGHT, maxHeight]),
    );
  }, [rowProps, visHeight, listContainerHeight]);

  if (!simTree) {
    return (
      <></>
    );
  }

  let title = 'Simulation Checklist';
  switch (currentView) {
    case CurrentView.GEOMETRY:
      title = 'Geometry Checklist';
      break;
    case CurrentView.ANALYSIS:
      title = 'Results';
      break;
    case CurrentView.MESH:
      title = 'Mesh Checklist';
      break;
    case CurrentView.OUTPUTS:
      title = 'Outputs Checklist';
      break;
    case CurrentView.SOLVER:
      title = workflowFlag && controlPanelMode === 'exploration' ?
        'Design of Experiments' : 'Solver Checklist';
      break;
    case CurrentView.PHYSICS:
      title = 'Physics Checklist';
      break;
    default:
      break;
  }

  return (
    <>
      <div
        className={cx(classes.root, { inSelectionMode: isTreeModal })}
        data-locator="floatingSimTreePanel">
        <CollapsiblePanel
          collapsed={!expanded}
          disabled={filterActive}
          expandWhenDisabled
          headerRight={
            isIntermediateView(currentView) || isExplorationSetup ?
              <RightPaneTopControls height={16} /> : undefined
          }
          heading={(
            <div className={classes.heading}>
              {title}
            </div>
          )}
          onToggle={() => setExpanded(!expanded)}
          primaryHeading>
          <div className={classes.content}>
            {currentView === CurrentView.ANALYSIS && !isExplorationSetup &&
            <RightPaneTopControls height={TOOLBAR_HEIGHT} />}
            {topInfoMessage && (
              <div style={{ padding: '0px 8px' }}>
                <SectionMessage
                  level="info"
                  message={topInfoMessage}
                />
              </div>
            )}
            {currentView === CurrentView.OUTPUTS && (
              <div style={{
                padding: '8px',
                color: colors.lowEmphasisText,
                fontSize: '12px',
              }}>
                We output equation residuals and surface-integrated scalar outputs at every
                iteration, on all surfaces, volumes, monitor planes, and monitor points.

                If you would like to use one of these quantities as a solver stopping condition,
                declare it below. Otherwise, declaring outputs is optional during setup.
                <br />
                <br />
                If you’d like to define a stopping condition, you must define a specific output
                below.
                <br />
                <br />
                Click&nbsp;
                <span
                  onClick={() => setOutputHelpVisible(true)}
                  onKeyDown={() => { }}
                  role="button"
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: colors.purple700,
                  }}
                  tabIndex={0}>
                  here
                </span> to see a list of all scalars we
                output and store.
              </div>
            )}
            {!!rowProps.length && (
              <div
                className={classes.list}
                data-locator={MODIFICATIONS_TREE_DATA_LOCATOR}
                style={{ height: listContainerHeight }}>
                <div style={{ overflowY: 'auto', height: listContainerHeight }}>
                  {rowProps.map((row, index) => renderRow(index, row))}
                </div>
              </div>
            )}
          </div>
        </CollapsiblePanel>
      </div>
      {outputHelpVisible && (
        <Dialog
          modal
          onClose={() => setOutputHelpVisible(false)}
          open={outputHelpVisible}
          title="Scalars">
          Scalars we output and store for every solver iteration:
          {Object.entries(OUTPUT_SCALARS).map(([category, scalars]) => (
            <React.Fragment key={category}>
              <h4>{category}</h4>
              <ul>
                {scalars.map((scalar) => (
                  <li key={scalar}>{scalar}</li>
                ))}
              </ul>
            </React.Fragment>
          ))}
          We store the whole fluid volume solution every N iterations, defined in&nbsp;
          <NodeLink
            nodeIds={[ITERS_PER_OUTPUT_NODE_ID]}
            onClick={() => {
              setOutputHelpVisible(false);
            }}
            text="iterations per full solution output"
            underline
            unstyled
          />
        </Dialog>
      )}
    </>
  );
};
