import React from 'react';

import DeveloperError from '@/errors/DeveloperError';

/* eslint-disable max-len */
export default class ReactiveState {
  // Private Properties
  private reactiveProxy?: Omit<typeof this, 'use'>;
  private stateSetters: Partial<Record<keyof typeof this, Set<(value: any)=>void>>> = {};
  private stateValues: Partial<Record<keyof typeof this, any>> = {};

  // Public Properties
  /**
   * Gets a reactive version of the current state module for use during rendering.
   * Note: this uses react state and effect hooks, and may not be called outside of the render cycle.
   */
  public get use() {
    if (!this.reactiveProxy) {
      this.reactiveProxy = this.getReactiveProxy();
    }
    return this.reactiveProxy;
  }

  // Private Methods
  private getProperty(property: string | symbol) {
    // Look for an existing property implementation
    let target = this;
    let existingProperty;
    while (target && target !== Object.prototype) {
      existingProperty = Object.getOwnPropertyDescriptor(target, property);
      if (existingProperty) {
        break;
      }
      target = Object.getPrototypeOf(target);
    }
    return existingProperty;
  }

  private getReactiveProxy() {
    return new Proxy<typeof this>(
      this,
      {
        deleteProperty(target, property:string) {
          throw new DeveloperError(`Cannot delete reactive state property '${property}' on state '${target.constructor.name}'.`);
        },
        get: function useGet(target, property: string | symbol) {
          if (!(property in target)) {
            throw new DeveloperError(`Property '${String(property)}' does not exist on target state.`);
          }
          const typedProperty = property as keyof typeof target;
          let reactiveSetter: (value: any) => void;
          try {
            // Collect a state updater to invalidate the currently rendering component
            reactiveSetter = React.useState(target[typedProperty])[1];
          } catch (error) {
            throw new DeveloperError(`Unable to acquire state for property '${String(typedProperty)}' on state '${target.constructor.name}'. Does it really need to be reactive?`, undefined, error);
          }
          if (!(typedProperty in target.stateSetters)) {
            target.overrideProperty(property);
          }
          React.useEffect(() => {
            // Add state updater to call when property is changed
            target.stateSetters[typedProperty]?.add(reactiveSetter);
            // console.debug('Adding reactive state: ', Object.keys(target.stateSetters).map((key)=>`${key}: ${target.stateSetters[key as keyof typeof target]?.size}`).join())
            return () => {
              // Remove stale state updater when component is re-rendered or unmounted
              target.stateSetters[typedProperty]?.delete(reactiveSetter);
              // console.debug('Removing reactive state: ', Object.keys(target.stateSetters).map((key)=>`${key}: ${target.stateSetters[key as keyof typeof target]?.size}`).join())
            };
          }, [target, typedProperty, reactiveSetter]);
          return target[typedProperty];
        },
        set(target, property:string, value, receiver) {
          if (!(property in target)) {
            throw new DeveloperError(`Property '${property}' does not exist on state '${target.constructor.name}'.`);
          }
          target[property as keyof typeof target] = value;
          return true;
        },
      },
    );
  }

  private overrideProperty(property: string | symbol) {
    const existingProperty = this.getProperty(property);
    const propertyDescriptor = {
      get: () => {
        if (existingProperty?.get) {
          return existingProperty.get.call(this);
        }
        return this.stateValues[property as keyof typeof this];
      },
      set: (value: any): void => {
        const typedProperty = property as keyof typeof this;
        if (existingProperty?.set) {
          existingProperty.set.call(this, value);
        } else if (existingProperty?.writable) {
          this.stateValues[typedProperty] = value;
        }
        // Update all currently held state references with the new value
        this.stateSetters[typedProperty]?.forEach((setter) => setter(value));
      },
    };
    const typedProperty = property as keyof typeof this;
    this.stateSetters[typedProperty] = new Set();
    if (!existingProperty?.get) {
      this.stateValues[typedProperty] = this[typedProperty];
    }
    Object.defineProperty(this, typedProperty, propertyDescriptor);
  }
}
