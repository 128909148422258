// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo, useState } from 'react';

import { ActionButton } from '../../components/Button/ActionButton';
import { useCommonUserTableStyles } from '../../components/Theme/commonStyles';
import { Table } from '../../components/data/Table';
import { UserDialog } from '../../components/dialog/User';
import { MainPageLayout } from '../../components/layout/page/Main';
import { Emphasis } from '../../components/visual/Emphasis';
import { CommonMenuItem, CommonMenuListItem } from '../../lib/componentTypes/menu';
import { ColumnConfig, RowConfig } from '../../lib/componentTypes/table';
import { colors } from '../../lib/designSystem';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import * as status from '../../lib/status';
import useButtonMenu from '../../lib/useButtonMenu';
import { getFullName, getUserName } from '../../lib/user';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import useAccountInfo, { useIsAdmin } from '../../recoil/useAccountInfo';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';

const logger = new Logger('AccountPage/UsersPageBody');

// user login methods
enum LoginType {
  GOOGLE = 'Google',
  USERNAME_PASSWORD = 'Username/Password',
  INVALID = 'NA',
}

type User = frontendpb.AccountInfoReply_UserInfo;

const getLoginTypeFromId = (userId: string) => {
  if (userId.indexOf('google-oauth2') === 0) {
    return LoginType.GOOGLE;
  } if (userId.indexOf('auth0') === 0) {
    return LoginType.USERNAME_PASSWORD;
  }
  return LoginType.INVALID;
};

const getUserLoginTypes = (user: User) => (
  user.loginInfo.map(
    (loginInfo) => (getLoginTypeFromId(loginInfo.loginId)),
  ).filter((type) => type !== LoginType.INVALID)
);

const getLoginIdForType = (user: User, loginType: LoginType) => {
  for (let i = 0; i < user.loginInfo.length; i += 1) {
    const loginInfo = user.loginInfo[i];
    if (loginType === getLoginTypeFromId(loginInfo.loginId)) {
      return loginInfo.loginId;
    }
  }
  return '';
};

interface UserDialogState {
  open: boolean;
  user?: User;
}

const columnConfigs: ColumnConfig[] = [
  { id: 'firstName', label: 'First Name', type: 'string' },
  { id: 'lastName', label: 'Last Name', type: 'string' },
  { id: 'email', label: 'Email', type: 'string' },
  { id: 'status', label: 'Status', type: 'string' },
  { id: 'role', label: 'Role', type: 'string' },
  { id: 'loginType', label: 'Login Type', type: 'string' },
];

function getUserStatusBulletInfo(userStatus: frontendpb.UserStatus) {
  if (userStatus === frontendpb.UserStatus.ENABLED) {
    return {
      color: colors.green500,
      statusText: 'Enabled',
    };
  } if (userStatus === frontendpb.UserStatus.DISABLED) {
    return {
      color: colors.red500,
      statusText: 'Disabled',
    };
  }
  return {
    color: colors.neutral750,
    statusText: 'Pending',
  };
}

interface UsersTableProps {
  users: frontendpb.AccountInfoReply_UserInfo[],
  isAdmin: boolean,
  setUserDialogState: React.Dispatch<React.SetStateAction<UserDialogState>>
}

const UsersTable = (props: UsersTableProps) => {
  // == Props
  const { users, isAdmin, setUserDialogState } = props;

  // == Hooks
  const classes = useCommonUserTableStyles();
  const { onCloseMenu } = useButtonMenu();
  const setConfirmStack = useSetConfirmations();

  async function handleDeleteMFA(userId: string) {
    const req = new frontendpb.DeleteMFARequest({
      userId,
    });
    await rpc.callRetry('DeleteMFA', rpc.client.deleteMFA, req).catch((error) => {
      logger.info(`DeleteMFA error: ${status.stringifyError(error)}`);
    });
  }

  async function handleDeletePassword(userId: string) {
    const req = new frontendpb.DeletePasswordRequest({
      userId,
      newLogin: true,
    });
    await rpc.callRetry('DeletePassword', rpc.client.deletePassword, req).catch((error) => {
      logger.info(`DeletePassword error: ${status.stringifyError(error)}`);
    });
  }

  async function handleDeleteUser(userToDelete: User) {
    const req = new frontendpb.DeleteUserRequest({
      userId: userToDelete.lcUserId,
    });
    await rpc.callRetry('DeleteUser', rpc.client.deleteUser, req).then(
      () => { },
    ).catch((error) => {
      logger.info(`DeleteUser error: ${status.stringifyError(error)}`);
    });
  }

  const queueDeleteMfa = (user: frontendpb.AccountInfoReply_UserInfo, loginId: string) => {
    const names = getUserName(user);

    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: () => handleDeleteMFA(loginId),
      title: 'Reset Multi-Factor Authentication (MFA)',
      children: (
        <div className={classes.confirmationContent}>
          Are you sure you want to reset multi-factor authentication
          for <Emphasis content={names.full} />?  This action cannot be undone,
          and <Emphasis content={names.given} /> will need to set up multi-factor authentication
          again on the next login.
        </div>
      ),
    });
  };

  const queueResetPassword = (user: frontendpb.AccountInfoReply_UserInfo) => {
    const names = getUserName(user);
    // we are guaranteed to have username/password login method for every user
    const loginIdToDelete = getLoginIdForType(user, LoginType.USERNAME_PASSWORD);

    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: () => handleDeletePassword(loginIdToDelete),
      title: 'Reset Password',
      children: (
        <div className={classes.confirmationContent}>
          Are you sure you want to reset the password for <Emphasis content={names.full} />? This
          action cannot be undone, and <Emphasis content={names.given} /> will need to set up a new
          password on the next login.
        </div>
      ),
    });
  };

  const queueDeleteUser = (user: frontendpb.AccountInfoReply_UserInfo) => {
    const names = getUserName(user);

    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: () => handleDeleteUser(user),
      title: 'Delete User',
      children: (
        <div className={classes.confirmationContent}>
          Are you sure you want to delete <Emphasis content={names.full} />? This action cannot
          be undone, and <Emphasis content={names.given} /> will need to be invited to the platform
          again.
        </div>
      ),
    });
  };

  const usersRowData: RowConfig[] = users.map((user: frontendpb.AccountInfoReply_UserInfo) => {
    const { color, statusText } = getUserStatusBulletInfo(user.status);

    const deleteMfaMenuItems: CommonMenuListItem[] = [];
    const googleLoginId = getLoginIdForType(user, LoginType.GOOGLE);
    if (googleLoginId) {
      deleteMfaMenuItems.push({
        destructive: true,
        label: 'Google',
        onClick: () => {
          onCloseMenu();
          queueDeleteMfa(user, googleLoginId);
        },
      });
    }

    // Used to set extra menu items
    const passwordLoginId = getLoginIdForType(user, LoginType.USERNAME_PASSWORD);
    if (passwordLoginId) {
      deleteMfaMenuItems.push({
        destructive: true,
        label: 'Username/Password',
        onClick: () => {
          onCloseMenu();
          queueDeleteMfa(user, passwordLoginId);
        },
      });
    }

    const menuItems: CommonMenuItem[] = isAdmin ? [
      {
        label: 'Edit User',
        startIcon: { name: 'pencil' },
        onClick: () => setUserDialogState({ open: true, user }),
      },
      { separator: true },
      {
        destructive: true,
        label: 'Reset Multi-Factor Authentication',
        startIcon: { name: 'reset' },
        items: deleteMfaMenuItems,
      },
      {
        destructive: true,
        label: 'Reset Password',
        startIcon: { name: 'reset' },
        onClick: () => queueResetPassword(user),
      },
      {
        destructive: true,
        label: 'Delete User',
        startIcon: { name: 'trash' },
        onClick: () => queueDeleteUser(user),
      },
    ] : [];

    return {
      id: user.lcUserId,
      values: {
        firstName: user.givenName,
        lastName: user.familyName,
        email: user.email,
        status: statusText,
        role: user.accountRole.charAt(0).toUpperCase() + user.accountRole.slice(1),
        loginType: getUserLoginTypes(user).join(', '),
      },
      cellDisplay: {
        status: [
          { type: 'bullet', color },
        ],
      },
      menuItems,
    };
  });

  return (
    <Table
      asBlock
      columnConfigs={columnConfigs}
      controls={{ search: true }}
      name="users-table"
      pagination={{ availablePageSizes: [25, 50, 100] }}
      rowConfigs={usersRowData}
    />
  );
};

const UsersPageBody = () => {
  const accountInfo = useAccountInfo();
  const [userDialogState, setUserDialogState] = useState<UserDialogState>({ open: false });

  const users = useMemo(() => (accountInfo?.user.slice() || []).sort(
    (a, b) => getFullName(a).localeCompare(getFullName(b)),
  ), [accountInfo]);

  const isAdmin = useIsAdmin();

  return (
    <MainPageLayout
      permission={isAdmin}
      primaryAction={isAdmin && (
        <div>
          <ActionButton
            kind="primary"
            onClick={() => setUserDialogState({ open: true })}
            title="Add a user to the curent organization">
            Add User
          </ActionButton>
        </div>
      )}
      title="Users">
      <UsersTable isAdmin={isAdmin} setUserDialogState={setUserDialogState} users={users} />
      <UserDialog
        {...userDialogState}
        onClose={() => setUserDialogState({ open: false })}
      />
    </MainPageLayout>
  );
};

export default UsersPageBody;
