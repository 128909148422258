// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as userstatepb from '../proto/userstate/userstate_pb';

import { authClientId } from './RuntimeParams';
import { Logger } from './observability/logs';
import { isStorybookEnv } from './testing/utils';

const logger = new Logger('jwt');

// Key used to store the user's bearer token in localStorage.
export const sessionKey = 'lcSession2';

// A parsed JWT object has these attributes, among others.
export interface Jwt {
  aud: string;
  email: string;
  exp: number;
  family_name: string;
  given_name: string;
  name: string;
  sub: string;
  'https://luminarycloud.com/system_role': string;
  'https://luminarycloud.com/new_user': boolean;
  'https://luminarycloud.com/lc_user_id'?: string;
  'https://luminarycloud.com/account_id'?: string;
}

// Get the user's system role from the Jwt object.
export function systemRole(jwt: Jwt): userstatepb.SystemRole {
  const roleStr = jwt['https://luminarycloud.com/system_role'];
  switch (roleStr) {
    case 'system_admin':
      return userstatepb.SystemRole.ADMIN;
    case 'staff':
      return userstatepb.SystemRole.STAFF;
    case '':
    case undefined:
      return userstatepb.SystemRole.USER;
    default:
      logger.warn(`Unknown system role ${roleStr}`);
      return userstatepb.SystemRole.USER;
  }
}

// Returns true iff the input jwt is invalid or expired
export function isExpired(jwt: Jwt | null): boolean {
  if (!jwt) {
    // No/unparseable jwt.
    return true;
  }
  if (new Date(jwt.exp * 1000) < new Date()) {
    // Expired jwt.
    return true;
  }
  return false;
}

// Returns true iff the input is valid (non-expired and from the correct auth tenant).
export function isValid(jwt: Jwt | null): boolean {
  if (!jwt) {
    // No/unparseable jwt.
    return false;
  }
  if (new Date(jwt.exp * 1000) < new Date()) {
    // Expired jwt.
    return false;
  }
  // iss/authTenant is brittle because it can be messed up by formatting, so we use the ID.
  if (jwt.aud !== authClientId) {
    // jwt is from the wrong auth tenant.
    return false;
  }
  return true;
}

// Parse a JWT (JSON web token) encoded in Base64Url.
// See https://tools.ietf.org/html/rfc7519 for the JWT spec.
export function parseJwt(jwt: string): Jwt | null {
  if (!jwt) {
    return null;
  }
  try {
    const base64 = jwt.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    const jsonStr = decodeURIComponent(
      atob(base64).split('').map(
        (char) => `%${(`00${char.charCodeAt(0).toString(16)}`).slice(-2)}`,
      ).join(''),
    );
    return JSON.parse(jsonStr);
  } catch (error) {
    console.log(error);
    return null;
  }
}

// Get and parse the JWT from localStorage.
// If there is no session, returns null.
export function loadSessionJwt(): Jwt | null {
  if (isStorybookEnv()) {
    return null;
  }
  return parseJwt(localStorage.getItem(sessionKey) || '');
}

// Get lcUserID from jwt. Returns empty string if jwt is null.
export function getLcUserIdFromToken(jwt: Jwt | null) {
  return jwt?.['https://luminarycloud.com/lc_user_id'] || '';
}

// Set the legacy Luminary session cookie.  Used only for Paraview.
function setLegacyCookie(session: string) {
  const j = parseJwt(session);
  if (!j) {
    logger.error('No JWT');
    return;
  }
  document.cookie = `${sessionKey}=${session}; ` +
    `expires=${new Date(j.exp * 1000).toUTCString()}; path=/`;
}

// Store the provided base64-encoded JWT in localStorage.
export function setSession(session: string) {
  localStorage.setItem(sessionKey, session);
  setLegacyCookie(session);
}

// Get the stored base64-encoded JWT from localStorage
export function getSession() {
  return localStorage.getItem(sessionKey) || '';
}

// Clear the legacy Luminary session cookie from the browser.
function clearLegacyCookie() {
  document.cookie = `${sessionKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}

// Clear the JWT stored in localStorage.
export function clearSession() {
  localStorage.removeItem(sessionKey);
  clearLegacyCookie();
}

// Returns true if the session belongs to a STAFF user; false otherwise.
// TODO(LC-14071): only used in one place; remove this once that temporary code
// is removed
export function isStaff(): boolean {
  const role = systemRole(loadSessionJwt()!);
  return role === userstatepb.SystemRole.STAFF || role === userstatepb.SystemRole.ADMIN;
}

export function getLcUserId() {
  return getLcUserIdFromToken(loadSessionJwt());
}

// Default threshold is 12 hours (half the token's 24-hour lifespan) to balance timely refresh
// and minimal resouce usage.
export function isNearExpiration(token: Jwt | null, thresholdMinutes: number = 12 * 60): boolean {
  if (!token) {
    return false;
  }
  const expirationTime = token.exp * 1000;
  const currentTime = Date.now();
  const timeUntilExpiration = expirationTime - currentTime;
  return timeUntilExpiration < thresholdMinutes * 60 * 1000;
}
