// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useState } from 'react';

import { localPoint } from '@visx/event';
import { Circle, Line } from '@visx/shape';

import { colors } from '../../../lib/designSystem';
import { clamp, formatNumber } from '../../../lib/number';
import { AxisRange, getDerivative, getYRange } from '../../../lib/sensitivityUtils';
import * as basepb from '../../../proto/base/base_pb';
import { IconButton } from '../../Button/IconButton';
import { JobDataCategory } from '../../JobPanel/JobDataCategory';
import JobVerticalDataTable from '../../JobPanel/JobVerticalDataTable';
import { createStyles, makeStyles } from '../../Theme';
import { EditableText } from '../../controls/EditableText';
import { RingPlusIcon } from '../../svg/RingPlusIcon';
import { RingXIcon } from '../../svg/RingXIcon';

import { NumberDisplay } from './NumberDisplay';
import { SensitivityGraph, X_PIXELS, Y_PIXELS } from './SensitivityGraph';

const useStyles = makeStyles(
  () => createStyles({
    inputColumn: {
      backgroundColor: colors.surfaceMedium2,
      borderRadius: '4px',
      display: 'inline-block',
      fontSize: '13px',
      width: '219px',
      padding: '16px 10px',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    inputBar: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    iconButton: {
      flex: '0 0 auto',
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
      color: colors.neutral650,
      border: 0,
      margin: 0,
      padding: 0,
      marginBottom: '2px',
    },
    xRange: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    numberText: {
      color: colors.neutral650,
      fontSize: '12px',
    },
    divider: {
      backgroundColor: colors.neutral450,
      height: '1px',
      margin: '26px 0',
      width: '100%',
    },
    sensitivities: {
      padding: '6px 0 1px 0',
    },
    inputControl: {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'center',
    },
    slider: {
      background: colors.surfaceMedium2,
      border: 0,
      padding: 0,
      margin: 0,
    },
    valueRow: {
      margin: '14px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
    },
    editableValue: {
      minWidth: '60px',
      textAlign: 'right',
    },
  }),
  { name: 'InputColumn' },
);

interface EditableInputValueProps {
  // The input value as a string.
  inputValue: string;
  // The amount the input value has changed from its original value.
  percentChange: number;
  // Sets a new value for the input.
  setInputValue: (newValue: number) => void;
}

// Shows the current input value and percent change. The value can be edited by
// clicking on it.
const EditableInputValue = (props: EditableInputValueProps) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className={classes.valueRow}>
      {/* The extra div here is containing the EditableText to not expand to fullWidth */}
      <div className={classes.editableValue}>
        <EditableText
          active={isEditing}
          asBlock
          hoverOutline
          onChange={(value: string) => {
            props.setInputValue(parseFloat(value));
            setIsEditing(false);
          }}
          onClick={() => setIsEditing(true)}
          value={props.inputValue}
        />
      </div>
      <span>
        <span>(</span>
        <NumberDisplay digits={1} percent={props.percentChange} />
        <span>)</span>
      </span>
    </div>
  );
};

function createTicks(xMin: number, xMax: number): ReactElement[] {
  // Add a set of major and minor tick marks.
  const ticks: ReactElement[] = [];
  const nTicks = 20;
  const majorTickIntervals = 5;
  const yMajorTickTop = 2;
  const yMinorTickTop = 7;
  const yTickBottom = 10;
  for (let i = 0; i <= nTicks; i += 1) {
    const x = xMin + (i / nTicks) * (xMax - xMin);
    ticks.push(
      <Line
        key={(`tick ${i}`)}
        stroke={colors.neutral450}
        strokeWidth="1"
        x1={x}
        x2={x}
        y1={i % majorTickIntervals === 0 ? yMajorTickTop : yMinorTickTop}
        y2={yTickBottom}
      />,
    );
  }
  return ticks;
}

interface InputColumnProps {
  // The name of the input variable.
  name: string;
  // The range of the x-axis.
  xRange: AxisRange;
  // The index of the input in the computedOutputs.
  inputIndex: number;
  // The set of computed outputs. One for each output.
  computedOutputs: basepb.AdFloatType[][];
  // The adjusted value of the input.
  adjustedValue: number;
  // Set the adjusted value of the input.
  setAdjustedValue: (newValue: number) => void;
  // The names of the outputs.
  outputCategories: JobDataCategory[];
}

const InputColumn = (props: InputColumnProps) => {
  const classes = useStyles();
  const [mouseDown, setMouseDown] = useState(false);

  const yAxis = 25;
  const xMin = 8;
  const xMax = 142;
  const xCenter = (xMin + xMax) / 2;

  // Convert to and from the value and the x-position.
  const xToValue = (x: number) => (
    ((x - xMin) / (xMax - xMin)) *
    (props.xRange.max - props.xRange.min) + props.xRange.min
  );
  const valueToX = (value: number) => (
    ((value - props.xRange.min) / (props.xRange.max - props.xRange.min)) *
    (xMax - xMin) + xMin
  );

  // Clamp the adjusted value to range of the x-axis.
  const adjustValue = (newValue: number) => {
    props.setAdjustedValue(clamp(newValue, [props.xRange.min, props.xRange.max]));
  };
  const adjustFromEvent = (event: React.MouseEvent<HTMLSpanElement>) => {
    const { x } = localPoint(event) || { x: 0 };
    adjustValue(xToValue(x));
  };

  const xAdjusted = valueToX(props.adjustedValue);
  const percentChange = 100 * (props.adjustedValue / props.xRange.center - 1);

  // Add the graphs. Compute the slope of each line. Scale the slope based on
  // the pixel size in the x and y directions.
  const xPixelSize = (props.xRange.max - props.xRange.min) / (X_PIXELS.max - X_PIXELS.min);
  const createGraph = (outputName: string, index: number, marginTop: number) => {
    const derivative = getDerivative(props.inputIndex, index, props.computedOutputs);
    const yRange = getYRange(index, props.computedOutputs);
    const xCircle = xAdjusted + X_PIXELS.center - xCenter;
    const yPixelSize = (yRange.max - yRange.min) / (Y_PIXELS.max - Y_PIXELS.min);
    const slope = -(xPixelSize / yPixelSize) * derivative;
    return (
      <SensitivityGraph
        derivative={derivative}
        inputName={props.name}
        key={outputName}
        marginTop={marginTop}
        outputName={outputName}
        slope={slope}
        xCircle={xCircle}
      />
    );
  };

  const outputCategoriesCopy = props.outputCategories.slice();

  let outputIndex = 0;
  const changeValue = (category: JobDataCategory, level: number) => {
    if (category.subcategories.length === 0) {
      if (level > 0) {
        // A Level 0 category corresponds to an output node. If it has no subcategories, it is
        // empty. A Level 1 or 2 category corresponds to one of the output values and should be
        // displayed as a graph.
        category.values = [
          createGraph(category.nameLines[0], outputIndex, category.marginTop ?? 24),
        ];
        outputIndex += 1;
      }
    } else {
      category.subcategories.forEach(
        (subcategory: JobDataCategory) => changeValue(subcategory, level + 1),
      );
    }
  };

  outputCategoriesCopy.forEach((category: JobDataCategory) => changeValue(category, 0));

  const adjustedStr = props.adjustedValue.toFixed(3);
  const ticks = createTicks(xMin, xMax);

  // A slider bar to set the input.
  const inputBar = (
    <div className={classes.inputBar}>
      <IconButton
        className={classes.iconButton}
        onClick={() => {
          adjustValue(props.adjustedValue - props.xRange.center / 100);
        }}>
        <RingXIcon maxHeight={15} maxWidth={15} />
      </IconButton>
      <div style={{ flex: '1 1 auto' }}>
        <div className={classes.xRange}>
          <span className={classes.numberText}>
            {formatNumber(props.xRange.min)}
          </span>
          <span className={classes.numberText}>
            {formatNumber(props.xRange.max)}
          </span>
        </div>
        <div className={classes.inputControl}>
          <button
            className={classes.slider}
            onMouseDown={(event) => {
              setMouseDown(true);
              adjustFromEvent(event);
            }}
            onMouseLeave={() => setMouseDown(false)}
            onMouseMove={(event) => mouseDown && adjustFromEvent(event)}
            onMouseUp={() => setMouseDown(false)}
            type="button">
            <svg height="35px" width="150px">
              <g>
                <Circle cx={xMin} cy={yAxis} fill={colors.neutral300} key="neg-endpoint" r={4} />
                <Circle cx={xMin} cy={yAxis} fill={colors.neutral100} key="pos-endpoint" r={3} />
                <Circle cx={xMax} cy={yAxis} fill={colors.neutral300} key="pos-outer" r={4} />
                {ticks}
                <Line
                  key="axis"
                  stroke={colors.neutral300}
                  strokeWidth="8"
                  x1={xMin}
                  x2={xMax}
                  y1={yAxis}
                  y2={yAxis}
                />
                <Line
                  key="negative axis"
                  stroke={colors.neutral100}
                  strokeWidth="6"
                  x1={xMin}
                  x2={xCenter}
                  y1={yAxis}
                  y2={yAxis}
                />
                <Line
                  key="highlighted"
                  stroke={colors.primaryCta}
                  strokeWidth="8"
                  x1={xCenter}
                  x2={xAdjusted}
                  y1={yAxis}
                  y2={yAxis}
                />
                <Circle
                  cx={xAdjusted}
                  cy={yAxis}
                  fill={colors.highEmphasisText}
                  key="adjusted-circle"
                  r={8}
                  stroke={colors.neutral650}
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <IconButton
        className={classes.iconButton}
        onClick={() => {
          adjustValue(props.adjustedValue + props.xRange.center / 100);
        }}>
        <div>
          <RingPlusIcon maxHeight={15} maxWidth={15} />
        </div>
      </IconButton>
    </div>
  );
  return (
    <div
      className={classes.inputColumn}>
      {props.name}
      <EditableInputValue
        inputValue={adjustedStr}
        percentChange={percentChange}
        setInputValue={adjustValue}
      />
      {inputBar}
      <div className={classes.numberText}>
        {formatNumber(props.xRange.center)}
      </div>
      <div className={classes.divider} />
      <div className={classes.sensitivities}>Sensitivities</div>
      <JobVerticalDataTable
        categories={outputCategoriesCopy}
        name="sensitivities"
      />
    </div>
  );
};

export default InputColumn;
