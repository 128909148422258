// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { newNode } from '../../lib/paraviewUtils';
import { SimulationTreeNode } from '../../lib/simulationTree/node';
import { EditState, findFilterTreeNode, findParentFilterNode, getFilterNodeIcon } from '../../lib/visUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useEditState } from '../../recoil/paraviewState';
import { useFilterStateValue } from '../../recoil/vis/filterState';
import { useSimulationTree } from '../../state/internal/tree/simulation';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

/** Based on the 'node` argument (a SimulationTreeNode instance), this custom hook finds the
 * simulation tree node and the filter tree node.  Taking into account the editState, it also finds
 * and returns the parent, param, and displayProps values.  This is all common boilerplate
 * previously repeated in many components (like prop panels and the shared FilterDisplayPanel).
 * Now those components simply invoke this hook.
 */

export const useFilterNode = (
  simulationNode: SimulationTreeNode | null,
  /**
   * Determines whether to retrieve parent node.
   * This is only relevant for filters; set to `false` to skip obtaining parents when not needed.
   */
  getParentFilterNode = true,
) => {
  const { viewState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  const simulationTree = useSimulationTree(projectId, workflowId, jobId);

  const [editState, setEditState] = useEditState();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const filterState = useFilterStateValue({ projectId, workflowId, jobId });

  const nodeId = useMemo(() => simulationNode?.id, [simulationNode?.id]);

  const filterNode = useMemo(
    () => {
      let result: ParaviewRpc.TreeNode | null = null;

      if (lcvisEnabled && nodeId) {
        result = findFilterTreeNode(filterState, nodeId) ?? null;
      } else if (viewState && nodeId) {
        result = findFilterTreeNode(viewState.root, nodeId) ?? null;
      }

      if (result === null && editState?.newNodeId) {
        result = newNode(editState.param, null, false, editState.displayProps, editState.newNodeId);
      }

      return result;
    },
    [
      editState?.param,
      editState?.displayProps,
      editState?.newNodeId,
      lcvisEnabled,
      nodeId,
      viewState,
      filterState,
    ],
  );

  const param = useMemo(
    () => (editState ? editState.param : filterNode?.param),
    [editState, filterNode],
  );

  const iconName = (
    filterNode ?
      getFilterNodeIcon(filterNode.param.typ as ParaviewRpc.TreeNodeType) :
      undefined
  );

  const displayProps = useMemo(
    () => (editState ? editState.displayProps : filterNode?.displayProps),
    [editState, filterNode],
  );

  const parentFilterNode = useMemo(() => {
    if (
      simulationNode &&
      simulationTree &&
      param &&
      (param.typ !== ParaviewRpc.TreeNodeType.READER) &&
      getParentFilterNode
    ) {
      if (lcvisEnabled) {
        return findParentFilterNode(editState, filterState, simulationNode, simulationTree);
      }
      if (viewState) {
        return findParentFilterNode(editState, viewState.root, simulationNode, simulationTree);
      }
    }
    return null;
  }, [
    editState,
    param,
    simulationNode,
    simulationTree,
    viewState,
    filterState,
    lcvisEnabled,
    getParentFilterNode,
  ]);

  const updateEditState = useCallback((partialEditState: Partial<EditState>) => {
    setEditState((oldEditState) => {
      if (oldEditState) {
        return { ...oldEditState, ...partialEditState };
      }
      return null;
    });
  }, [setEditState]);

  // Return any geometry IDs associated with a filter node
  const geometryIds = useMemo(() => {
    const idSet: Set<string> = new Set();
    switch (filterNode?.param.typ) {
      case ParaviewRpc.TreeNodeType.EXTRACT_SURFACES:
        filterNode.param.surfaces.forEach(idSet.add, idSet);
        filterNode.param.disks?.forEach(idSet.add, idSet);
        break;
      case ParaviewRpc.TreeNodeType.STREAMLINES: {
        if (filterNode?.param.seedPlacementType === ParaviewRpc.SeedPlacementType.SURFACE) {
          const { surfaces } = filterNode.param.seedPlacementParams as ParaviewRpc.SeedSurfaceParam;
          surfaces.forEach(idSet.add, idSet);
        }
        break;
      }
      case ParaviewRpc.TreeNodeType.SURFACE_L_I_C: {
        const licParam = filterNode?.param.seedPlacementParams as ParaviewRpc.SeedLICParam;
        if (Array.isArray(licParam.surfaces)) {
          (licParam.surfaces as string[]).forEach(idSet.add, idSet);
        }
        break;
      }
      default: // no default
    }
    return idSet;
  }, [filterNode]);

  return {
    displayProps,
    filterNode,
    nodeId,
    parentFilterNode,
    param,
    viewState,
    updateEditState,
    iconName,
    geometryIds,
  };
};

// Return the useFilterNode hook using the currently selectedNode
export const useSelectedFilterNode = () => {
  const { selectedNode } = useSelectionContext();

  return useFilterNode(selectedNode);
};
