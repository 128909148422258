// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useEffect } from 'react';

import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import * as transfpb from '../../proto/cad/transformation_pb';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../recoil/lcvis/lcvisReadyState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useProjectContext } from '../context/ProjectContext';

export const useLcvCoordinateVisualizer = (
  simParam: simulationpb.SimulationParam,
) => {
  const { projectId } = useProjectContext();
  const lcvEnabled = useLcVisEnabledValue(projectId);
  const lcvReady = useLcVisReadyValue();

  const staticVolumes = useStaticVolumes(projectId);

  useEffect(() => {
    if (lcvEnabled && lcvReady) {
      const display = lcvHandler.display;
      const lcvWorkspace = display?.getWorkspace();
      lcvWorkspace?.setMotionData(
        simParam,
        staticVolumes,
      );
    }
  }, [simParam, lcvEnabled, lcvReady, staticVolumes]);

  const clear = useCallback(() => {
    if (lcvEnabled && lcvReady) {
      const display = lcvHandler.display;
      const lcvWorkspace = display?.getWorkspace();
      lcvWorkspace?.clearSelectedFrame();
    }
  }, [lcvEnabled, lcvReady]);

  const show = useCallback((frameId: string) => {
    if (!(lcvEnabled && lcvReady)) {
      return;
    }

    const display = lcvHandler.display;
    const lcvWorkspace = display?.getWorkspace();
    lcvWorkspace?.showSelectedFrame(frameId);
  }, [lcvEnabled, lcvReady]);

  useEffect(() => () => clear(), [clear]);

  return { show, clear };
};

export const useLcvCoordinateVisualizerGeo = (
  transf:
    transfpb.AugmentedMatrix |
    transfpb.Translation |
    transfpb.Rotation |
    transfpb.Scaling |
    transfpb.Reflection |
    undefined,
  id: string,
) => {
  const { projectId } = useProjectContext();
  const lcvEnabled = useLcVisEnabledValue(projectId);
  const lcvReady = useLcVisReadyValue();

  useEffect(() => {
    if (lcvEnabled && lcvReady) {
      const display = lcvHandler.display;
      const lcvWorkspace = display?.getWorkspace();
      lcvWorkspace?.showGeoReferenceFrame(transf, id);
    }
  }, [id, lcvEnabled, lcvReady, transf]);

  const clear = useCallback(() => {
    if (lcvEnabled && lcvReady) {
      const display = lcvHandler.display;
      const lcvWorkspace = display?.getWorkspace();
      lcvWorkspace?.clearSelectedFrame();
    }
  }, [lcvEnabled, lcvReady]);

  const show = useCallback(() => {
    if (!(lcvEnabled && lcvReady)) {
      return;
    }

    const display = lcvHandler.display;
    const lcvWorkspace = display?.getWorkspace();
    lcvWorkspace?.showSelectedFrame(id);
  }, [lcvEnabled, lcvReady, id]);

  useEffect(() => () => clear(), [clear]);

  return { show, clear };
};
