import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useProjectContext } from '../context/ProjectContext';

import { useTagsInteractiveGeometry } from './useInteractiveGeometry';

export const useDeleteAllTags = () => {
  const { projectId } = useProjectContext();
  const { removeTags } = useTagsInteractiveGeometry();
  const geometryTags = useGeometryTags(projectId, '', '');
  const setConfirmStack = useSetConfirmations();

  const sendRemoveAllTagsRequest = () => {
    const geometryTagIds = geometryTags.tags.map(({ id }) => id);

    return removeTags(geometryTagIds);
  };

  const deleteAllTags = () => {
    pushConfirmation(setConfirmStack, {
      continueLabel: 'Delete',
      destructive: true,
      onContinue: sendRemoveAllTagsRequest,
      title: 'Delete All Tags',
      children: 'Are you sure you want to delete all tags?',
    });
  };

  return deleteAllTags;
};
