// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo, useState } from 'react';

import { globalDisabledReason } from '../../lib/geometryUtils';
import { boldEscaped } from '../../lib/html';
import { getMaterialName } from '../../lib/materialUtils';
import { assembleMenuSections, filteredMenuItems } from '../../lib/menuUtil';
import { getPhysicsName } from '../../lib/physicsUtils';
import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { visibilityToggleTreeNodeMenuItem } from '../../lib/treeUtils';
import { getMaterialAssignmentTooltip, getPhysicsAssignmentTooltip } from '../../lib/volumeUtils';
import { usePhysicsSet } from '../../model/hooks/usePhysicsSet';
import { useIsGeometryServerActive } from '../../recoil/geometry/geometryServerStatus';
import { useGeometrySelectedFeature } from '../../recoil/geometry/geometryState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useGeometryUsesTags } from '../../recoil/geometry/geometryUsesTags';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';

import { useTagsInteractiveGeometry } from './useInteractiveGeometry';
import { useVolumeNode } from './useVolumeNode';

export const useVolumeNodeRowMenu = (
  id: string,
  node: SimulationTreeNode | null,
  isVisible: boolean,
  visControlsDisabled: boolean,
  toggleVis: () => void,
) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly, geometryId } = useProjectContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  // == Hooks
  const {
    staticVolume,

    assignedMaterial,
    materialsMenuItems,
    unassignMaterial,

    assignedPhysicsId,
    assignedPhysics,
    physicsMenuItems,
    unassignPhysics,
  } = useVolumeNode(id);
  const {
    getVolumePhysicsAssignmentDisabledReason,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);
  const {
    isCreateTagDisabled,
    addVolumesToExistingTag,
    removeItemFromTag,
  } = useTagsInteractiveGeometry();
  const isGeometryView = useIsGeometryView();
  const geometryTags = useGeometryTags(projectId);
  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const isGeoServerActive = useIsGeometryServerActive(geometryId);
  const geoUsesTags = useGeometryUsesTags(projectId);

  // With tag creation, we request the users to provide a name for the tag via a dialog.
  const [isCreateTagDialogOpen, setIsCreateTagDialogOpen] = useState(false);
  const [isCreateTagFacesDialogOpen, setIsCreateTagFacesDialogOpen] = useState(false);

  const parentTagId = node?.parent?.id || '';

  const defaultTagDisabledReason = globalDisabledReason(
    selectedFeature,
    readOnly,
    isGeoServerActive,
  );
  const addToTagDisabledReason = geometryTags.tags.length === 0 ?
    'There are no existing Tags yet.' :
    defaultTagDisabledReason;
  const removeTagChildDisabledReason = useMemo(() => {
    const tagChildren = [
      ...(geometryTags.surfacesFromTagEntityGroupId(parentTagId) || []),
      ...geometryTags.domainsFromTag(parentTagId),
    ];
    return tagChildren.length > 1 ? undefined : 'Tags must contain at least one item';
  }, [geometryTags, parentTagId]);

  const taggingEnabled = !isCreateTagDisabled && geoUsesTags;
  const isTagChildren = node?.type === NodeType.TAGS_BODY;

  const visibilityContextMenuItems = filteredMenuItems([
    {
      itemConfig: visibilityToggleTreeNodeMenuItem(isVisible, toggleVis, visControlsDisabled),
      shouldShow: !!staticVolume,
    },
  ]);

  const contextMenuItemsForGeometryView = assembleMenuSections(
    visibilityContextMenuItems,
    filteredMenuItems([
      {
        itemConfig: {
          label: 'Create Tag',
          onClick: () => {
            setIsCreateTagDialogOpen(true);
          },
          disabled: !!defaultTagDisabledReason,
          disabledReason: defaultTagDisabledReason,
        },
        shouldShow: taggingEnabled,
      },
      {
        itemConfig: {
          label: 'Create Tag on Faces',
          onClick: () => {
            setIsCreateTagFacesDialogOpen(true);
          },
          disabled: !!defaultTagDisabledReason,
          disabledReason: defaultTagDisabledReason,
        },
        shouldShow: taggingEnabled,
      },
      {
        itemConfig: {
          label: 'Add to Tag',
          disabled: !!addToTagDisabledReason,
          disabledReason: addToTagDisabledReason,
          onClick: () => { },
          ...(addToTagDisabledReason ? {} : {
            items: geometryTags.tags.map((tag) => ({
              label: tag.name,
              onClick: async () => {
                await addVolumesToExistingTag(tag.id, [id]);
              },
            })),
          }),
        },
        shouldShow: taggingEnabled,
      },
      {
        itemConfig: {
          label: 'Remove from Tag',
          onClick: async () => {
            await removeItemFromTag(parentTagId, { volumeId: id });
          },
          disabled: !!removeTagChildDisabledReason,
          disabledReason: removeTagChildDisabledReason,
        },
        shouldShow: taggingEnabled && isTagChildren,
      },
    ]),
  );

  const getContextMenuItems = () => {
    if (isGeometryView) {
      return contextMenuItemsForGeometryView;
    }

    const assignmentMenuItems = [];

    // in theory, `!!assignedPhysics` is sufficient, but the body of the conditional that branches
    // on `physicsIsAssigned` needs `assignedPhysicsId` and `staticVolume` to be defined, so
    // including those in this boolean value will narrow their types to defined ones inside those
    // conditionals. it's a convenience, not a correctness issue.
    const physicsIsAssigned = !!(assignedPhysics && assignedPhysicsId && staticVolume);

    if (!assignedMaterial && materialsMenuItems.length > 0) {
      assignmentMenuItems.push({
        label: 'Assign Material',
        onClick: () => { },
        items: materialsMenuItems,
      });
    }

    if (assignedMaterial) {
      const materialLocked = !!(assignedPhysics && assignedMaterial);
      const tooltip = getMaterialAssignmentTooltip(readOnly, !!assignedPhysics, !!assignedMaterial);
      assignmentMenuItems.push({
        label: `Unassign ${boldEscaped(getMaterialName(assignedMaterial, simParam))}`,
        help: materialLocked ? '' : tooltip,
        onClick: unassignMaterial,
        disabled: materialLocked,
        disabledReason: materialLocked ? tooltip : '',
      });
    }

    if (assignedMaterial && !physicsIsAssigned && physicsMenuItems.length > 0) {
      assignmentMenuItems.push({
        label: 'Assign Physics',
        onClick: () => { },
        items: physicsMenuItems,
      });
    }

    if (assignedMaterial && physicsIsAssigned) {
      const tooltip = getPhysicsAssignmentTooltip(readOnly, !!assignedPhysics, !!assignedMaterial);
      const unassignPhysicsDisabledReason = getVolumePhysicsAssignmentDisabledReason(
        assignedPhysicsId,
        staticVolume.domain,
      );
      assignmentMenuItems.push({
        label: `Unassign ${boldEscaped(getPhysicsName(assignedPhysics, simParam))}`,
        help: tooltip,
        onClick: unassignPhysics,
        disabled: !!unassignPhysicsDisabledReason,
        disabledReason: unassignPhysicsDisabledReason || tooltip,
      });
    }

    return assembleMenuSections(
      visibilityContextMenuItems,
      assignmentMenuItems,
    );
  };

  return {
    getContextMenuItems,
    isCreateTagDialogOpen,
    isCreateTagFacesDialogOpen,
    setIsCreateTagDialogOpen,
    setIsCreateTagFacesDialogOpen,
    staticVolume,
  };
};
