// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import assert from 'assert';

import {
  LCVConstant,
  LCVError,
  LCVImportSource,
  LCVMapMode,
  LCVObject,
  LCVProgressStatus,
  LCVType,
} from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';
import { SetterOrUpdater } from 'recoil';

import { AdVector3 } from '../../../../proto/base/base_pb';
import * as transfpb from '../../../../proto/cad/transformation_pb';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { LCStatus } from '../../../../proto/lcstatus/lcstatus_pb';
import { ArrayInformation, ColorMap, DisplayProps, DisplayPvVariable, FieldAssociation, TreeNode } from '../../../../pvproto/ParaviewRpc';
import { GeometryTags } from '../../../../recoil/geometry/geometryTagsObject';
import { FilterStatus } from '../../../../recoil/lcvis/lcvisFilterStatus';
import { setLcVisReady } from '../../../../recoil/lcvis/lcvisReadyState';
import { EntitySelectionType } from '../../../../recoil/selectionOptions';
import { StaticVolume } from '../../../../recoil/volumes';
import { isItarEnv } from '../../../RuntimeParams';
import { pvToList } from '../../../Vector';
import { RgbColor, colors, hexToRgbList } from '../../../designSystem';
import { unwrapSurfaceIdsNoEntityGroups } from '../../../entityGroupUtils';
import { boldEscaped } from '../../../html';
import { areArraysNear } from '../../../lang';
import { orderedFrames } from '../../../motionDataUtils';
import { Logger } from '../../../observability/logs';
import { traverseTreeNodes } from '../../../paraviewUtils';
import { choice, seededRandom } from '../../../random';
import { TwoWayMap } from '../../../tsUtils';
import { DEFAULT_FILTER_ROOT, remapComponentIndex, traverseTreeNodeAndParent, unionBounds } from '../../../visUtils';
import { findStaticVolumeByDomain } from '../../../volumeUtils';
import { getFailedFilters } from '../../handler/failedFiltersExtractor';
import {
  Bounds,
  FilterProgressCallback,
  ImportFilterRealOptions,
  ImportFilterSetupOptions,
  LcvIdPair,
  LcvModule,
  defaultDisplayProps,
} from '../../types';
import { LcvReferenceFrame } from '../LcvReferenceFrame';
import { LcvObject } from '../base/LcvObject';

import { LcvClip } from './LcvClip';
import { LcvColorMap } from './LcvColorMap';
import { LcvContour } from './LcvContour';
import { LcvFilter } from './LcvFilter';
import {
  FarFieldOptions,
  LcvFarFieldFilter,
  LcvImportDatasetFilter,
} from './LcvImportDatasetFilter';
import { LcvSlice } from './LcvSlice';
import { LcvThreshold } from './LcvThreshold';
import { AsyncQueue } from './asyncQueue';

const {
  CONSTANT_ANGULAR_MOTION,
  CONSTANT_TRANSLATION_MOTION,
  CONSTANT_VELOCITY_MOTION,
} = simulationpb.MotionType;
const { ROTATIONAL_TRANSFORM, TRANSLATIONAL_TRANSFORM } = simulationpb.TransformType;

const VOLUME_SELECTED_COLOR = hexToRgbList(colors.selectedVolume);
const SURFACE_SELECTED_COLOR = hexToRgbList(colors.selectedSurface);
const SURFACE_DESELECTED_COLOR = hexToRgbList(colors.deselectedSurface);
const SURFACE_HOVERED_COLOR = hexToRgbList(colors.hoveredSurface);
const VOLUME_HOVERED_COLOR = hexToRgbList(colors.hoveredVolume);
const palette = ['#8aa2fc', '#fdd1b1', '#fcf7f9', '#9ca3b2', '#ffd280', '#aec6cf', '#ec5f10',
  '#1f7bd8', '#1c3c97', '#2b3a67', '#1e293b', '#ffcba4', '#809bb1', '#29a0ee', '#2a5fb8', '#323a57',
  '#f3e6c9', '#7a9bae', '#68b0ff', '#252f4f', '#ffb366', '#e67e22', '#d35400'];
const purple = '#6e55e9';

const logger = new Logger('LcvWorkspace');
/**
 * A workspace holds filters and provides methods to interact with the surfaces in its dataset.
 * When workspace.release is called, its filters are implicitly released.
*/
export class LcvWorkspace extends LcvObject {
  // Map of id: color, where id is a string and may be either the id of a surface in the
  // importDatasetFilter, or the id of an analysis filter (e.g. an LcvClip)
  colorMap: Map<string, RgbColor> = new Map();
  // The color of surfaces when they are selected
  selectionColor: RgbColor = SURFACE_SELECTED_COLOR;

  // Maps the id of a surface to its [objectId, primitiveId] in the workspace's importDatasetFilter.
  private importDatasetSurfaceNameIndexMap: TwoWayMap<string, number> = new TwoWayMap();

  // Lines have a separate map to prevent regressions. Some parts of the code already use this
  // map's keys as the list of available surfaces.
  private importDatasetLineNameIndexMap: TwoWayMap<string, number> = new TwoWayMap();

  importDatasetFilter: LcvImportDatasetFilter;
  farFieldFilter: LcvFarFieldFilter | null = null;

  // Maps the objectId of a filter to the LcvFilter object.
  filterIndexToObj: Map<number, LcvFilter> = new Map();
  // Maps the UI id of a filter to the LcvFilter object.
  filterIdToObj: Map<string, LcvFilter> = new Map();

  savedImportDatasetOptions: ImportFilterSetupOptions | null = null;

  importingData: boolean = false;

  lastHoveredIndices: LcvIdPair[] = [];
  lastHoveredColors: RgbColor[] = [];

  selectedSurfaces: Set<string> = new Set();
  referenceFrames: Map<string, LcvReferenceFrame> = new Map<string, LcvObject>();

  // The display properties for the import dataset filter. Analysis filters use their own display
  // properties from the filterState.
  displayProps: DisplayProps = defaultDisplayProps();

  // Animation variables
  isAnimating: boolean = false;
  nextFrameId: number = 0;
  currentTime: number = 0;
  stepsRemaining: number = 0;
  stepSize: number = 0.1;
  onAnimationDone: (() => void) | undefined = undefined;

  // selectedReferenceFrame: LcvReferenceFrame | null = null;
  selectedReferenceFrame: string | null = null;

  progressCallback: FilterProgressCallback | null = null;
  /** a collection of callbacks that will fire each time after lcv.executeWorkspace has completed */
  onExecuteWorkspace: Map<string, () => void> = new Map();

  // Random number generator for generating random colors
  random = seededRandom(42);
  // A queue to serialize aync calls that modify workspace filters
  // and calls to execute workspace.
  workspaceQueue: AsyncQueue = new AsyncQueue();

  fieldList: string[] = [];
  fieldData: ArrayInformation[] = [];

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    setupOptions: ImportFilterSetupOptions,
    onExecuteWorkspace?: () => void,
  ) {
    super(lcv, lcv.newWorkspace(sessionHandle, 0).workspace, sessionHandle);
    this.importDatasetFilter = new LcvImportDatasetFilter(
      lcv,
      sessionHandle,
      setupOptions,
      this.handle,
      DEFAULT_FILTER_ROOT,
      this.displayProps,
    );

    const opts = (setupOptions as ImportFilterRealOptions);
    if (opts?.fvmParams) {
      this.importDatasetFilter.setFvmParams(opts.fvmParams);
    }
    this.savedImportDatasetOptions = setupOptions;
    const { projectId, meshUrl, isGeometry } = (setupOptions as ImportFilterRealOptions);
    this.setParam(
      'project_id',
      LCVType.kLCVDataTypeString,
      projectId,
    );
    this.setParam(
      'mesh_lines',
      LCVType.kLCVDataTypeUint,
      isGeometry ? 0 : 1,
    );
    // In ITAR or run_backend.py environments, we use the fetch endpoint because we cannot use
    // signed URLs. The fetch endpoint requires the JWT auth header.
    // In non-ITAR, we use signed URLs, which should not include an auth header.
    // The workspace will propagate the JWT auth setting on to the child filters
    const isRunBackend = meshUrl.startsWith('/home/');
    const sendJwtAuth = isItarEnv || isRunBackend;
    this.setParam('send_jwt_auth', LCVType.kLCVDataTypeInt, sendJwtAuth ? 1 : 0);
    if (onExecuteWorkspace) {
      this.addOnExecuteWorkspaceCallback('initialCb', onExecuteWorkspace);
    }
  }

  addOnExecuteWorkspaceCallback(id: string, callback: () => void) {
    this.onExecuteWorkspace.set(id, callback);
  }

  async showGeometry(url: string) {
    this.savedImportDatasetOptions = {
      ...this.savedImportDatasetOptions as ImportFilterRealOptions,
      isGeometry: true,
    };
    this.removeFilters();
    this.setParam('mesh_lines', LCVType.kLCVDataTypeUint, 0);
    await this.updateMeshURL(url, true);
  }

  async showMesh(url: string) {
    this.savedImportDatasetOptions = {
      ...this.savedImportDatasetOptions as ImportFilterRealOptions,
      isGeometry: false,
    };
    this.setParam('mesh_lines', LCVType.kLCVDataTypeUint, 1);
    await this.updateMeshURL(url, true);
  }

  /**
   * @returns true if the workspace owns some filter with the given id.
   */
  hasFilter(filterObjectId: number): boolean {
    return (
      this.importDatasetFilter.objectId === filterObjectId ||
      this.filterIndexToObj.has(filterObjectId)
    );
  }

  getFilter(filterObjectId: number): LcvFilter | undefined {
    if (this.importDatasetFilter.objectId === filterObjectId) {
      return this.importDatasetFilter;
    }
    return this.filterIndexToObj.get(filterObjectId);
  }

  getImportDatasetIndices(surfaceIds: string[]) {
    const result: number[] = [];
    surfaceIds.forEach((id) => {
      if (this.importDatasetSurfaceNameIndexMap.hasKey(id)) {
        result.push(this.importDatasetSurfaceNameIndexMap.getByKey(id)!);
      }
    });
    return result;
  }

  /**
   * Makes the grpc request for render data from the backend. Accepts a progress callback
   * which gets called whenever the filter progress updates.
   * The callback is set once by an outside code, then saved in the workspace, so that calls to
   * executeWorkspace from within the LcvWorkspace class can just pass in null.
   * @param updateReadyState: whether to update the Recoil LcVis ready state. Updating a Recoil
   * state can be relatively expensive, so use with caution.
  */
  private async executeWorkspace(
    buildIdMaps: boolean,
    updateReadyState: boolean,
    progressCallback: FilterProgressCallback | null,
  ) {
    this.importingData = true;
    if (progressCallback) {
      this.progressCallback = progressCallback;
    }

    const internalCallback = (
      lcv: LcvModule,
      session: LCVObject,
      object: LCVObject,
      error: LCVError,
      status: LCVProgressStatus,
      completed_units: number,
      total_units: number,
      indeterminate: boolean,
      message: string,
    ) => {
      if (!this.progressCallback) {
        return;
      }

      const objectId = object !== 0 ? lcv.getObjectId(session, object, 0).id : null;
      const filter = (
        objectId === this.importDatasetFilter.objectId ?
          this.importDatasetFilter :
          this.filterIndexToObj.get(objectId)
      );
      const filterId = filter?.id;

      const filterNameMap = new Map<string, string>();
      if (this.prevFilterState) {
        traverseTreeNodes(this.prevFilterState, (node) => {
          filterNameMap.set(node.id, node.name);
        });
      }

      switch (status) {
        case LCVProgressStatus.kLCVProgressStatusComplete:
          // If the workspace is complete, set all filters to completed
          this.progressCallback((prev) => {
            const newStatus = new Map(prev);
            newStatus.forEach((value, key) => {
              newStatus.set(
                key,
                {
                  ...value,
                  status: 'completed',
                },
              );
            });
            return newStatus;
          });
          break;
        case LCVProgressStatus.kLCVProgressStatusFailed:
          this.progressCallback((prev) => {
            if (!filterId) {
              return prev;
            }
            const newStatus = new Map(prev);
            logger.error('LCVis filter failed: ', filterId, message, LCVError[error]);
            newStatus.set(
              filterId,
              {
                status: 'completed',
                error: 'There was an internal error creating this filter.',
              },
            );
            return newStatus;
          });
          break;
        case LCVProgressStatus.kLCVProgressStatusQueued:
          this.progressCallback((prev) => {
            if (!filterId) {
              return prev;
            }
            const newStatus = new Map(prev);
            newStatus.set(
              filterId,
              {
                status: 'queued',
                error: '',
              },
            );
            return newStatus;
          });
          break;
        case LCVProgressStatus.kLCVProgressStatusDownloading:
        case LCVProgressStatus.kLCVProgressStatusRunning:
          {
            if (!filterId) {
              return;
            }
            const total_progress = completed_units / (total_units + 1);
            this.progressCallback((prev) => {
              const newStatus = new Map(prev);
              newStatus.set(
                filterId,
                {
                  status: Math.round(total_progress * 100),
                  error: '',
                },
              );
              return newStatus;
            });
          }
          break;
        case LCVProgressStatus.kLCVProgressStatusFilterComplete:
          this.progressCallback((prev) => {
            if (!filterId) {
              return prev;
            }
            let err = '';
            if (filterId !== this.importDatasetFilter.id) {
              if (!filter?.hasSurfaces()) {
                err = filterNameMap.has(filterId) ?
                  `Filter ${boldEscaped(filterNameMap.get(filterId)!)} produced no data.` :
                  'Filter produced no data.';
              }
            }
            const newStatus = new Map(prev);
            newStatus.set(
              filterId,
              {
                status: 'completed',
                error: err,
              },
            );
            return newStatus;
          });
          break;
        default:
          break;
      }

      if (error === LCVError.kLCVErrorWorkspaceGRPCFailed) {
        const failedFilters = getFailedFilters(message);

        if (progressCallback && failedFilters.length > 0) {
          progressCallback((currentValue) => {
            const result = new Map(currentValue);

            failedFilters.forEach((identifier) => {
              const filterState = result.get(identifier) || { status: 'error', error: '' };

              result.set(identifier, { ...filterState, status: 'error' });
            });

            return result;
          });
        }
      }
    };
    this.importingData = true;
    const prevDisplayProps = (
      this.importDatasetFilter.viewProps?.displayProps ?? defaultDisplayProps()
    );
    if (updateReadyState) {
      setLcVisReady(false);
    }
    // TODO (will/jared): see how we should be handling cancellation cleanly
    // For now we just let the throw propagate up, but it seems to not be
    // quite caught where we'd want? It doesn't crash the app though.
    await this.lcv.executeWorkspace(this.sessionHandle, this.handle, internalCallback);

    if (buildIdMaps) {
      this.buildIdMaps();
    }

    if (!progressCallback) {
      // Only set this to true when it's not the first time we call executeWorkspace (i.e., when
      // lcvis is actually ready and we are just waiting for the new mesh url to load).
      // The first time this is called, the callback will be set by the caller, and lcvis won't be
      // quite ready even after the model loads.

      // (LC-17037) We need to use a setTimeout here to ensure we trigger a render with the
      // setLcVisReady call. For some reason, calling this immediately after setLcVisReady(false)
      // was not triggering an update in the Loading Overlay. But we can make sure it triggers an
      // update by only calling it once all the other pending tasks have finished.
      if (updateReadyState) {
        setTimeout(() => setLcVisReady(true), 0);
      }
    }

    const field_name_data = this.importDatasetFilter.getProperty(
      'field_names',
      LCVType.kLCVDataTypeData1D,
    );
    this.fieldList = [];
    this.fieldData = [];

    const data_mapping = this.lcv.mapData(
      this.sessionHandle,
      field_name_data,
      LCVMapMode.kLCVMapModeRead,
      0,
      0,
    );

    const string_view = new Uint32Array(this.lcv.memory(), data_mapping.mapping, data_mapping.size);
    for (let i = 0; i < string_view.length; i += 1) {
      const name: string = this.lcv.readString(string_view[i]);
      const ncomps = this.lcv.getFieldComponents(
        this.sessionHandle,
        this.handle,
        name,
        0,
      ).ncomponents;
      const rangeSize = ncomps === 3 ? 4 : 1;
      const ranges = [];
      for (let comp = 0; comp < rangeSize; comp += 1) {
        const range = this.lcv.getFieldComponentRange(
          this.sessionHandle,
          this.handle,
          name,
          comp,
          0,
        ).range;
        ranges.push(range);
      }
      // In Paraview, order of ranges is mag, x, y, z.
      // In LCVis, it's returned as x, y, z, mag.
      if (ranges.length) {
        // switches the LCVis ranges to match Paraview as mag, x, y, z
        // don't care if it's a vector or not, we do it in the scalar case too bc
        // it's simpler to write.
        ranges.unshift(ranges.pop());
      }
      this.fieldList.push(name);

      this.fieldData.push({
        name,
        type: FieldAssociation.POINT,
        dim: ncomps,
        range: ranges as [number, number][],
        n: 0, // TODO: do we need this number? It's wrong here btw.
      });
    }
    this.lcv.unmapData(this.sessionHandle, field_name_data);
    this.lcv.release(this.sessionHandle, field_name_data, 0);

    // Assign colors to new surfaces if needed
    this.importDatasetSurfaceNameIndexMap.forEach((_, id) => {
      if (!this.colorMap.has(id)) {
        this.colorMap.set(id, this.getRandomColor());
      }
    });
    this.importDatasetFilter.setDisplayProps(true, prevDisplayProps);
    this.setColorMode(!!this.displayProps.showColors);

    this.filterIdToObj.forEach((filter) => {
      filter.setFlatShading(true);
    });

    this.onExecuteWorkspace.forEach((callback) => callback());
    // Reset the import data flag and promise
    this.importingData = false;
  }

  // We blend the palette with a light purple with random ratios to get a nice purpleish palette.
  getRandomColor(): RgbColor {
    const c1 = hexToRgbList(choice(palette, this.random));
    const c2 = hexToRgbList(purple);
    const t1 = this.random() * 0.3 + 0.4;
    const t2 = this.random() * 0.3 + 0.4;
    const t3 = this.random() * 0.3 + 0.4;
    const red = (c1[0] * t1 + c2[0] * (1 - t1));
    const green = (c1[1] * t2 + c2[1] * (1 - t2));
    const blue = (c1[2] * t3 + c2[2] * (1 - t3));
    return [red, green, blue];
  }

  // Wrap the private function so it can be called by the display.
  async executeWorkspaceExternal(
    buildIdMaps: boolean,
    updateReadyState: boolean,
    progressCallback: FilterProgressCallback | null,
  ) {
    return this.workspaceQueue.enqueue(
      async () => this.executeWorkspace(buildIdMaps, updateReadyState, progressCallback),
    );
  }

  setColorMode(colorOn: boolean) {
    this.displayProps = {
      ...this.displayProps,
      showColors: colorOn,
    };
    if (colorOn) {
      this.importDatasetSurfaceNameIndexMap.forEach((index, id) => {
        this.setSurfaceColor(id, this.colorMap.get(id)!);
      });
    } else {
      this.importDatasetSurfaceNameIndexMap.forEach((_, id) => {
        this.setSurfaceColor(id, SURFACE_DESELECTED_COLOR);
      });
    }
  }

  updateDisplayProps(displayProps: DisplayProps, visibilityMap?: Map<string, boolean>) {
    const { showColors } = displayProps;
    this.setColorMode(!!showColors);

    // Build map of surface ID -> visibility state
    const surfaceVisibilityMap = new Map<number, boolean>();
    visibilityMap?.forEach((visible, id) => {
      const surfaceId = this.importDatasetSurfaceNameIndexMap.getByKey(id);
      if (surfaceId !== undefined && surfaceId < this.getImportDatasetNumSurfaces()) {
        surfaceVisibilityMap.set(surfaceId, visible);
      }
    });

    this.importDatasetFilter.setViewProps(displayProps, surfaceVisibilityMap);
    this.displayProps = displayProps;
  }

  setFvmParams(fvmParams: string) {
    this.importDatasetFilter.setFvmParams(fvmParams);
  }

  setExplodeFactor(factor: number) {
    this.setParam('explode_factor', LCVType.kLCVDataTypeFloat, factor);
  }

  toggleColorMode() {
    this.updateDisplayProps({
      ...this.displayProps,
      showColors: !this.displayProps.showColors,
    });
    return this.displayProps.showColors;
  }

  /**
   * @returns the number of surfaces in the workspace's ImportDatasetFilter.
   */
  private getImportDatasetNumSurfaces(): number {
    return this.importDatasetFilter?.getNumSurfaces() ?? 0;
  }

  /**
   * @returns the number of lines in the workspace's ImportDatasetFilter.
   */
  private getImportDatasetNumLines(): number {
    return this.importDatasetFilter?.getNumLines() ?? 0;
  }

  /**
   * Creates a NameIndexMap which maps the id of a surface to its index in the workspace's dataset,
   * and the reverse: the index of the surface back to its id.
   * The 'names' are ids for the UI to call methods like setSelection(...ids)
   * The 'indices' are needed because the selection widget returns a list of indices in the
   * dataset, so we use it to get the ids of the surfaces before we can call setSelection.
   * */
  buildIdMaps() {
    this.importDatasetSurfaceNameIndexMap.clear();
    this.importDatasetLineNameIndexMap.clear();

    const nSurfaces = this.getImportDatasetNumSurfaces();
    for (let index = 0; index < nSurfaces; index += 1) {
      const name: string = this.importDatasetFilter.getSurfaceName(index);
      this.importDatasetSurfaceNameIndexMap.set(name, index);
    }

    const nLines = this.getImportDatasetNumLines();

    if (nLines === 0) {
      return;
    }

    const firstLineIndex = this.importDatasetFilter.getFirstLineIndex();

    for (let index = firstLineIndex; index < firstLineIndex + nLines; index += 1) {
      const name = this.importDatasetFilter.getLineName(index);

      this.importDatasetLineNameIndexMap.set(name, index);
    }
  }

  /**
   * Given a list of UI surface ids, gets the bounds of each surface and
   * returns the union of all the bounds.
   */
  public getUnionBounds(ids: string[]): Bounds | null {
    const boundsList: Bounds[] = [];
    ids.forEach((id) => {
      const surfaceIndex = this.getIndexFromId(id);
      if (surfaceIndex !== null) {
        const filter = this.importDatasetSurfaceNameIndexMap.hasKey(id) ?
          this.importDatasetFilter : this.filterIdToObj.get(id)!;

        // surfaceIndex[1] is the index of the surface in the filter.
        boundsList.push(filter.getSurfaceBounds(surfaceIndex[1]));
      }
    });
    if (boundsList.length === 0) {
      return null;
    }

    const bounds = unionBounds(boundsList);
    if (!bounds || bounds.some((bound) => Math.abs(bound) === Infinity)) {
      return null;
    }
    return bounds;
  }

  /**
   * @returns the ids of all the surfaces in the workspace. This includes the surfaces in the
   * ImportDatasetFilter and the surfaces in the analysis filters.
   */
  getAllSurfaceIds(): string[] {
    const importDatasetIds = Array.from(this.importDatasetSurfaceNameIndexMap.keys());
    const filterIds = Array.from(this.filterIdToObj.keys());
    return importDatasetIds.concat(filterIds);
  }

  /**
   * Sets the color of the surface with the given id in the workspace's ImportDatasetFilter,
   * or, if the id corresponds to a filter object, sets the color of the entire filter.
   */
  private setSurfaceColor(id: string, color: RgbColor) {
    if (!this.importingData) {
      const index = this.importDatasetSurfaceNameIndexMap.getByKey(id);
      if (index !== undefined && index < this.getImportDatasetNumSurfaces()) {
        this.importDatasetFilter.setSurfaceColor(index, color);
      }
      if (this.filterIdToObj.has(id)) {
        this.filterIdToObj.get(id)?.setAllSurfacesColor(color);
      }
    }
  }

  /**
   * Enable or disable field color blending for the specified surface
   * If the filter that contains the surface has field data enabling this will
   * cause the surface's color to be blended with its field colormap color.
   */
  private setSurfaceBlendEnabled(id: string, enabled: boolean) {
    if (!this.importingData) {
      const index = this.importDatasetSurfaceNameIndexMap.getByKey(id);
      if (index !== undefined && index < this.getImportDatasetNumSurfaces()) {
        this.importDatasetFilter.setPrimitiveBlendFieldColor(index, enabled);
      }
      this.filterIdToObj.get(id)?.setAllBlendFieldColor(enabled);
    }
  }

  /**
   * Sets the visibility of the surface with the given id in the workspace's ImportDatasetFilter,
   * or, if the id corresponds to a filter object, sets the visibility of the whole filter.
   */
  private setSurfaceVisibility(id: string, show: boolean) {
    if (this.importingData) {
      return;
    }
    const index = this.importDatasetSurfaceNameIndexMap.getByKey(id);
    if (index !== undefined && index < this.getImportDatasetNumSurfaces()) {
      this.importDatasetFilter.updateSurfaceAndLineVisibility(index, show);
    }
    if (this.filterIdToObj.has(id)) {
      this.filterIdToObj.get(id)?.updateAllSurfacesAndLinesVisibility(show);
    }
  }

  setSurfaceOrFilterTransparent(id: string, transparent: boolean) {
    this.workspaceQueue.enqueue(async () => {
      const index = this.importDatasetSurfaceNameIndexMap.getByKey(id);
      if (index !== undefined && index < this.getImportDatasetNumSurfaces()) {
        this.importDatasetFilter.setSurfaceTransparent(index, transparent);
      } else if (this.filterIdToObj.has(id)) {
        this.filterIdToObj.get(id)?.setAllSurfacesTransparent(transparent);
      }
    }).catch((err) => { });
  }

  async hideSurfaces(ids: Set<string>) {
    return this.workspaceQueue.enqueue(async () => {
      ids.forEach((id) => {
        this.setSurfaceVisibility(id, false);
      });
    });
  }

  /**
   * Shows the surfaces with the given ids in the workspace's ImportDatasetFilter.
   * @param ids the ids of the surfaces to show
   */
  async showSurfaces(ids: Set<string>) {
    return this.workspaceQueue.enqueue(async () => {
      ids.forEach((id) => this.setSurfaceVisibility(id, true));
    });
  }

  /**
   * Select some set of surfaces, and make the other surfaces transparent. If no ids are specified,
   * make everything opaque and deselected.
   */
  private selectEntities(ids: Set<string>, farfieldSurfaces: Set<string>) {
    // update selected edges (neighboring to the selected surfaces)
    const selectedEdges = this.getSelectedNeighbors(ids, farfieldSurfaces);
    this.importDatasetFilter.setSelectedEdges(selectedEdges);

    const selectedColor = this.selectionColor;
    const deselectedColor = SURFACE_DESELECTED_COLOR;
    if (this.selectedSurfaces.size === 0 && ids.size === 0) {
      return;
    }
    this.selectedSurfaces = ids;
    // Make the non-selected surfaces transparent
    this.importDatasetSurfaceNameIndexMap.forEach((index, id) => {
      if (!ids.has(id)) {
        if (this.displayProps.showColors) {
          this.setSurfaceColor(id, this.colorMap.get(id)!);
        } else {
          this.setSurfaceColor(id, deselectedColor);
        }
        this.setSurfaceBlendEnabled(id, false);
      }
    });
    const deselectedFilterColors = new Map<string, RgbColor>();
    if (this.prevFilterState) {
      traverseTreeNodes(this.prevFilterState, (node) => {
        if (node.displayProps?.showColors) {
          deselectedFilterColors.set(node.id, this.colorMap.get(node.id)!);
        } else {
          deselectedFilterColors.set(node.id, SURFACE_DESELECTED_COLOR);
        }
      });
    }
    this.filterIdToObj.forEach((filter, id) => {
      if (!ids.has(id)) {
        const color = deselectedFilterColors.get(id);
        if (color) {
          filter.setAllSurfacesColor(color);
        } else if (this.displayProps.showColors) {
          filter.setAllSurfacesColor(this.colorMap.get(id)!);
        } else {
          filter.setAllSurfacesColor(deselectedColor);
        }
      }
    });
    // For the selected surfaces, make them opaque
    ids.forEach((id) => {
      this.setSurfaceColor(id, selectedColor);
      this.setSurfaceBlendEnabled(id, true);
    });
  }

  async selectVolumes(volumeIds: Set<string>, farfieldSurfaces: Set<string>) {
    return this.workspaceQueue.enqueue(async () => {
      const surfaces = [...this.importDatasetSurfaceNameIndexMap.keys()].filter(
        (id) => volumeIds.has(id.split('/')[0]),
      );
      this.selectionColor = VOLUME_SELECTED_COLOR;
      this.selectEntities(new Set(surfaces), farfieldSurfaces);
    });
  }

  getSelectedNeighbors(surfaceIds: Set<string>, farfieldSurfaces: Set<string>) {
    return [...surfaceIds].reduce((result, surfaceId) => {
      // skip farfield surfaces from line color modification
      if (farfieldSurfaces.has(surfaceId)) {
        return result;
      }

      const primitiveId = this.importDatasetSurfaceNameIndexMap.getByKey(surfaceId);

      if (primitiveId !== undefined) {
        this.importDatasetFilter.getNeighboringIndices(primitiveId).forEach(
          (neighborPrimitiveId) => {
            result.add(neighborPrimitiveId);
          },
        );
      }

      return result;
    }, new Set<number>());
  }

  getSelectedNeighborIds(surfaceIds: Set<string>, farfieldSurfaces: Set<string>) {
    const result = new Set<string>();

    this.getSelectedNeighbors(surfaceIds, farfieldSurfaces).forEach((edgeIndex) => {
      const edgeId = this.importDatasetLineNameIndexMap.getByValue(edgeIndex);

      if (edgeId !== undefined) {
        result.add(edgeId);
      }
    });

    return result;
  }

  async selectSurfaces(surfaceIds: Set<string>, farfieldSurfaces: Set<string>) {
    return this.workspaceQueue.enqueue(async () => {
      this.selectionColor = SURFACE_SELECTED_COLOR;
      this.selectEntities(surfaceIds, farfieldSurfaces);
    });
  }

  hoverSurfacesById(ids: string[], type: EntitySelectionType) {
    if (this.importingData) {
      return;
    }
    const indices: [number, number][] = ids.reduce((acc, id) => {
      const possibleIndices = this.getIndexFromId(id);
      if (possibleIndices !== null) {
        acc.push(possibleIndices);
      }
      return acc;
    }, [] as [number, number][]);
    this.hoverSurfaces(indices, type);
  }

  /** Given an object id and primitive index, return the UI id of the object. */
  getIdFromIndex(objectId: number, primitiveId: number): string {
    if (this.hasFilter(objectId)) {
      const id = (
        this.filterIndexToObj.get(objectId)?.id ??
        this.importDatasetSurfaceNameIndexMap.getByValue(primitiveId) ??
        ''
      );
      return id;
    }
    return '';
  }

  /**
   * Given the string UI id, return the object id and primitive index of the object if it exists
   * in the workspace.
   */
  getIndexFromId(id: string): [number, number] | null {
    if (this.importDatasetSurfaceNameIndexMap.hasKey(id)) {
      return [
        this.importDatasetFilter.objectId,
        this.importDatasetSurfaceNameIndexMap.getByKey(id)!,
      ];
    }
    const filter = this.filterIdToObj.get(id);
    if (filter) {
      // For these filters, we only need to return the objectId and the primitive id can be 0.
      return [filter.objectId, 0];
    }
    return null;
  }

  hoverSurfaces(idPairs: LcvIdPair[], type: EntitySelectionType) {
    if (this.importingData) {
      return;
    }
    // exit the previous hover state
    this.exitHover();
    this.lastHoveredIndices = [];
    this.lastHoveredColors = [];
    idPairs.forEach((idPair) => {
      const [objectId, primitiveId] = idPair;
      const filter = this.getFilter(objectId);
      if (filter && primitiveId !== LCVConstant.kLCVInvalidObjectId &&
        primitiveId < filter.getNumSurfaces()) {
        this.lastHoveredIndices.push([objectId, primitiveId]);
        this.lastHoveredColors.push(filter.getSurfaceColor(primitiveId));
        if (filter === this.importDatasetFilter) {
          filter.setSurfaceColor(
            primitiveId,
            type === 'volume' ? VOLUME_HOVERED_COLOR : SURFACE_HOVERED_COLOR,
          );
          filter.setPrimitiveBlendFieldColor(primitiveId, true);
        } else {
          filter.setAllSurfacesColor(SURFACE_HOVERED_COLOR);
          filter.setAllBlendFieldColor(true);
        }
      }
    });
  }

  exitHover() {
    if (this.importingData) {
      return;
    }
    this.lastHoveredIndices.forEach(([objectId, primitiveId], i) => {
      const filter = this.getFilter(objectId);
      if (
        filter &&
        primitiveId !== LCVConstant.kLCVInvalidObjectId &&
        primitiveId < filter.getNumSurfaces()
      ) {
        // check if the previous hovered surface still has the hover color. If it doesn't, that
        // means its color has already changed to something different (e.g. it was selected),
        // so we shouldn't reset it.
        // We need to use areArraysNear instead of equality comparison because JS uses double
        // precision but WASM uses single precision floats, so there's
        // some inaccuracy in the conversion.
        if (
          areArraysNear(filter.getSurfaceColor(primitiveId), VOLUME_HOVERED_COLOR) ||
          areArraysNear(filter.getSurfaceColor(primitiveId), SURFACE_HOVERED_COLOR)
        ) {
          if (filter === this.importDatasetFilter) {
            filter.setSurfaceColor(primitiveId, this.lastHoveredColors[i]);
            filter.setPrimitiveBlendFieldColor(primitiveId, false);
          } else {
            filter.setAllSurfacesColor(this.lastHoveredColors[i]);
            filter.setAllBlendFieldColor(false);
          }
        }
      }
    });
    this.lastHoveredIndices = [];
    this.lastHoveredColors = [];
  }

  async addFarField(options: FarFieldOptions) {
    // This function executes the worspace so serialize
    // async calls to it to avoid race conditions.
    return this.workspaceQueue.enqueue(async () => {
      if (this.farFieldFilter) {
        this.farFieldFilter.changeFarFieldOptions(options);
      } else {
        this.farFieldFilter = new LcvFarFieldFilter(
          this.lcv,
          this.sessionHandle,
          options,
          this.handle,
          'far_field_filter',
        );
      }
      await this.executeWorkspace(false, false, null);
      this.farFieldFilter.setAllSurfacesTransparent(true);
      this.filterIndexToObj.set(this.farFieldFilter.objectId, this.farFieldFilter);
    });
  }

  async removeFarField() {
    return this.workspaceQueue.enqueue(async () => {
      if (this.farFieldFilter) {
        this.filterIndexToObj.delete(this.farFieldFilter.objectId);
        this.farFieldFilter.release();
        this.farFieldFilter = null;
      }
    });
  }

  async setTransientFarFieldVisibility(visible: boolean) {
    return this.workspaceQueue.enqueue(async () => {
      if (this.farFieldFilter) {
        this.farFieldFilter.setViewProps({ reprType: 'Surface' }, true);
      }
    });
  }

  /**
   * Update the current meshUrl.
   * If forceUpdate is true, we will reinitialize the importDatasetFilter.
   * */
  async updateMeshURL(meshURL: string, forceUpdate = false) {
    // This function executes the worspace so serialize
    // async calls to it to avoid race conditions.
    return this.workspaceQueue.enqueue(async () => {
      if (this.savedImportDatasetOptions) {
        if (
          'projectId' in this.savedImportDatasetOptions &&
          'meshUrl' in this.savedImportDatasetOptions &&
          'meshName' in this.savedImportDatasetOptions
        ) {
          const options = this.savedImportDatasetOptions! as ImportFilterRealOptions;
          if (meshURL !== options.meshUrl || forceUpdate) {
            this.importDatasetFilter.release();
            if (this.farFieldFilter) {
              this.farFieldFilter.release();
              this.farFieldFilter = null;
            }
            // Release all the filters
            this.filterIndexToObj.forEach((filter: LcvFilter) => {
              filter.release();
            });
            this.filterIndexToObj.clear();
            this.filterIdToObj.clear();

            options.meshUrl = meshURL;
            this.importDatasetFilter = new LcvImportDatasetFilter(
              this.lcv,
              this.sessionHandle,
              options,
              this.handle,
              DEFAULT_FILTER_ROOT,
              this.displayProps,
            );
            this.importDatasetFilter.setParam(
              'import_source',
              LCVType.kLCVDataTypeUint,
              meshURL ? LCVImportSource.kLCVNetwork : LCVImportSource.kLCVMemory,
            );
            await this.executeWorkspace(true, true, null);
          }
        }
      }
    });
  }

  addReferenceFrame(frameId: string, refFrame: LcvReferenceFrame): void {
    if (this.importingData) {
      return;
    }
    this.referenceFrames.set(frameId, refFrame);
    this.lcv.addReferenceFrame(this.sessionHandle, this.handle, refFrame.handle);
  }

  removeReferenceFrame(refFrame: LcvReferenceFrame): void {
    if (this.importingData) {
      return;
    }
    this.lcv.removeReferenceFrame(this.sessionHandle, this.handle, refFrame.handle);
    if (this.selectedReferenceFrame !== null &&
      this.referenceFrames.get(this.selectedReferenceFrame)?.handle === refFrame.handle) {
      this.setParam('selected_frame', LCVType.kLCVDataTypeReferenceFrame, null);
    }
    refFrame.release();
  }

  clearMotionData(): void {
    if (this.importingData) {
      return;
    }
    this.setParam('selected_frame', LCVType.kLCVDataTypeReferenceFrame, null);
    this.referenceFrames.forEach((lcvRefFrame: LcvReferenceFrame) => {
      this.removeReferenceFrame(lcvRefFrame);
    });
    this.referenceFrames.clear();
  }

  setMotionData(
    params: simulationpb.SimulationParam,
    staticVolumes: StaticVolume[],
    geometryTags: GeometryTags,
  ) {
    if (this.importingData) {
      return;
    }
    // Rebuild the list from scratch each time.
    this.clearMotionData();

    // orderedFrames returns both the valid frames and the invalid ones (orphaned). We only care
    // about the valid ones. The ordering is such that a frame that has children will always precede
    // its children in the list.
    const { frames } = orderedFrames(params);

    // Each frame has a list of surfaces and volumes. Sometimes only surfaces are listed, sometimes
    // only volumes are listed, and sometimes both surfaces and volumes are listed. An attached
    // volume means that all surfaces in the volume are attached to the frame, EXCEPT when a surface
    // is explicitly attached to another frame. Thus, we have to resolve all of this and come up
    // with an explicit surface list for each frame.

    let error = false;
    // Populate a map of volume IDs to lcvis surface IDs (numbers). This should be more performant
    // than working directly with strings.
    const lcvisIdsByDomain: Map<string, Set<number>> = new Map();
    frames.forEach((frame: simulationpb.MotionData) => {
      const domainsWithTags = frame.attachedDomains;
      const domains = domainsWithTags.flatMap((domain) => (
        geometryTags.domainsFromTagEntityGroupId(domain) || domain
      ));
      domains.forEach((domain: string) => {
        const staticVolume = findStaticVolumeByDomain(domain, staticVolumes);
        const lcvisIds: Set<number> = new Set();
        staticVolume?.bounds.forEach((surface: string) => {
          const lcvisId = this.importDatasetSurfaceNameIndexMap.getByKey(surface);
          if (lcvisId !== undefined && lcvisId < this.getImportDatasetNumSurfaces()) {
            lcvisIds.add(lcvisId);
          } else {
            // error case
            logger.error('ID not found for volume', domain);
            error = true;
          }
        });
        lcvisIdsByDomain.set(domain, lcvisIds);
      });
    });

    // Iterate through all the explicitly listed surfaces and remove any
    // duplicate surface ids from the volumes.
    frames.forEach((frame: simulationpb.MotionData) => {
      const surfaces = unwrapSurfaceIdsNoEntityGroups(
        frame.attachedBoundaries,
        geometryTags,
      );
      surfaces.forEach((id: string) => {
        const lcvisId = this.importDatasetSurfaceNameIndexMap.getByKey(id);
        if (lcvisId !== undefined && lcvisId < this.getImportDatasetNumSurfaces()) {
          lcvisIdsByDomain.forEach((idSet: Set<number>) => {
            if (idSet.has(lcvisId)) {
              idSet.delete(lcvisId);
            }
          });
        } else {
          // error case
          logger.error('Id not found for surface', id);
          error = true;
        }
      });
    });

    if (error) {
      logger.error('Error: failed to lookup lcvis ids. Bailing on motion preview.');
      return;
    }

    frames.forEach((frame: simulationpb.MotionData) => {
      const refFrame = new LcvReferenceFrame(this.lcv, this.sessionHandle);
      this.addReferenceFrame(frame.frameId, refFrame);

      // Set the list of lcvis surface ids to the reference frame.
      const surfaces = unwrapSurfaceIdsNoEntityGroups(
        frame.attachedBoundaries,
        geometryTags,
      );
      const volumesWithTags = frame.attachedDomains;
      const volumes = volumesWithTags.flatMap((domain) => (
        geometryTags.domainsFromTagEntityGroupId(domain) || domain
      ));
      let nSurfaces = surfaces.length;
      volumes.forEach((volumeId: string) => {
        const size = lcvisIdsByDomain.get(volumeId)?.size;
        if (size) {
          nSurfaces += size;
        }
      });

      const data = this.lcv.newData1D(
        this.sessionHandle,
        LCVType.kLCVDataTypeInt,
        nSurfaces,
        0,
      ).data;

      const mapInfo = this.lcv.mapData(
        this.sessionHandle,
        data,
        LCVMapMode.kLCVMapModeWrite,
        0,
        0,
      );

      const mapping = new Int32Array(this.lcv.memory(), mapInfo.mapping, mapInfo.size);
      let counter = 0;
      surfaces.forEach((surface: string) => {
        const lcvisId = this.importDatasetSurfaceNameIndexMap.getByKey(surface);
        if (lcvisId !== undefined && lcvisId < this.getImportDatasetNumSurfaces()) {
          mapping[counter] = lcvisId;
          counter += 1;
        }
      });

      volumes.forEach((volumeId: string) => {
        const idsSet = lcvisIdsByDomain.get(volumeId);
        if (idsSet) {
          idsSet.forEach((lcvisId: number) => {
            mapping[counter] = lcvisId;
            counter += 1;
          });
        }
      });

      assert(counter === nSurfaces);
      // Set the parent frames if applicable.
      const parentId = frame.frameParent;
      if (parentId.length > 0) {
        const parentFrame = this.referenceFrames.get(parentId);
        if (parentFrame) {
          refFrame.setParam('parent', LCVType.kLCVDataTypeReferenceFrame, parentFrame.handle);
        }
      }

      this.lcv.unmapData(this.sessionHandle, data);
      refFrame.setParam('surface_ids', LCVType.kLCVDataTypeData1D, data);
      this.lcv.release(this.sessionHandle, data, 0);

      // Helper for dealing with AdVectors
      const getVec = (vec: AdVector3 | undefined): number[] => {
        const x = vec?.x?.adTypes.case === 'value' ? vec.x.adTypes.value : 0;
        const y = vec?.y?.adTypes.case === 'value' ? vec.y.adTypes.value : 0;
        const z = vec?.z?.adTypes.case === 'value' ? vec.z.adTypes.value : 0;

        return [x ?? 0, y ?? 0, z ?? 0];
      };

      const setTransform = (
        tranform: AdVector3 | undefined,
        propName: string,
        rFrame: LcvReferenceFrame,
      ) => {
        const vec = getVec(tranform);
        rFrame.setParam(propName, LCVType.kLCVDataTypeFloat3, vec);
      };

      // The static transforms are the change in coordinate systems.
      const staticTransforms = frame.frameTransforms;
      staticTransforms.forEach((transform: simulationpb.FrameTransforms) => {
        if (transform.transformType === TRANSLATIONAL_TRANSFORM) {
          setTransform(transform.transformTranslation, 'translation', refFrame);
        } else if (transform.transformType === ROTATIONAL_TRANSFORM) {
          setTransform(transform.transformRotationAngles, 'rotation', refFrame);
        }
      });

      // Motion transforms
      if (frame.motionType === CONSTANT_ANGULAR_MOTION) {
        setTransform(frame.motionAngularVelocity, 'motion_rotation', refFrame);
      } else if (frame.motionType === CONSTANT_TRANSLATION_MOTION) {
        setTransform(frame.motionTranslationVelocity, 'motion_translation', refFrame);
      } else if (frame.motionType === CONSTANT_VELOCITY_MOTION) {
        // This motion types is deprecated.
      }
    });
  }

  /** Shows annotations for interactive geometry transformations. */
  showGeoReferenceFrame(
    transf:
      transfpb.AugmentedMatrix |
      transfpb.Translation |
      transfpb.Rotation |
      transfpb.Scaling |
      transfpb.Reflection |
      undefined,
    id: string,
  ) {
    if (this.importingData) {
      return;
    }
    // Rebuild the list from scratch each time.
    this.clearMotionData();

    const getOrigin = () => {
      if (transf instanceof transfpb.Rotation) {
        assert(transf.axis.case === 'arbitrary');
        return transf.axis.value.origin;
      } if (transf instanceof transfpb.Reflection) {
        assert(transf.plane.case === 'arbitrary');
        return transf.plane.value.origin;
      } if (transf instanceof transfpb.Scaling) {
        assert(transf.origin.case === 'arbitrary');
        return transf.origin.value;
      }
      return undefined;
    };

    const orig = getOrigin();
    if (orig === undefined) {
      return;
    }

    const refFrame = new LcvReferenceFrame(this.lcv, this.sessionHandle);
    this.addReferenceFrame(id, refFrame);
    const translation = [orig.x, orig.y, orig.z];

    refFrame.setParam('translation', LCVType.kLCVDataTypeFloat3, translation);
    refFrame.setParam('rotation', LCVType.kLCVDataTypeFloat3, [0, 0, 0]);
  }

  animateNextFrame(): void {
    if (this.importingData) {
      return;
    }
    if (this.stepsRemaining > 0) {
      this.setParam('time', LCVType.kLCVDataTypeFloat, this.currentTime);
      this.currentTime += this.stepSize;
      this.stepsRemaining -= 1;
      this.nextFrameId = requestAnimationFrame(() => {
        this.animateNextFrame();
      });
    } else {
      this.stopAnimation();
    }
  }

  // Restore the previously selected frame if one was present.
  restoreSelectedFrame(): void {
    if (this.selectedReferenceFrame) {
      this.showSelectedFrame(this.selectedReferenceFrame);
    }
  }

  // Stop an ongoing motion animation.  Idempotent.
  // Also resets time to 0 and stops drawing reference frame axes.
  stopAnimation(): void {
    if (!this.isAnimating) {
      return;
    }
    cancelAnimationFrame(this.nextFrameId);
    this.nextFrameId = 0;
    this.isAnimating = false;
    this.onAnimationDone?.();
    this.onAnimationDone = undefined;
    // Reset to time 0 when animation is done
    this.setParam('time', LCVType.kLCVDataTypeFloat, 0);
    // Disable the axes for the frames.
    this.setParam('draw_reference_frames', LCVType.kLCVDataTypeUint, 0);
    this.restoreSelectedFrame();
  }

  // Start a motion animation.  onAnimationDone() will be called when the
  // animation completes, whether it is canceled or completes successfully.
  // No-op if there is already an animation playing.
  animateMotion(
    nSteps: number,
    stepSize: number,
    drawAxes: boolean,
    onAnimationDone: () => void,
  ): void {
    if (this.isAnimating) {
      // Don't let multiple animation request happen at the same time.
      return;
    }

    // If we have a selected reference frame, disable it so all frame are shown
    // during the animation. We are tracking the selected frame and stopping
    // the animation will restore the selected frame.
    this.setParam('selected_frame', LCVType.kLCVDataTypeReferenceFrame, null);

    this.isAnimating = true;
    if (drawAxes) {
      this.setParam('draw_reference_frames', LCVType.kLCVDataTypeUint, 1);
    } else {
      this.setParam('draw_reference_frames', LCVType.kLCVDataTypeUint, 0);
    }

    this.currentTime = 0;
    this.stepsRemaining = nSteps;
    this.stepSize = stepSize;
    this.onAnimationDone = onAnimationDone;
    this.nextFrameId = requestAnimationFrame(() => {
      this.animateNextFrame();
    });
  }

  /**
   * Release all filters (excluding the importDatasetFilter) in the workspace and
   * clear their associated maps
   */
  public removeFilters() {
    this.cancelExecution();
    this.filterIdToObj.forEach((filter) => {
      filter.release();
    });
    this.filterIndexToObj.clear();
    this.filterIdToObj.clear();
  }

  public release() {
    this.stopAnimation();
    this.clearMotionData();
    this.removeFilters();
    this.importDatasetFilter.release();
    super.release();
  }

  // Cancel execution of the workspace
  public cancelExecution() {
    this.lcv.cancelWorkspaceExecution(this.sessionHandle, this.handle);
  }

  // Assumes that motion data has already been populated.
  showSelectedFrame(frameId: string): void {
    if (this.isAnimating) {
      // If we are animating, cache the new highlighted frame
      // so it will be restored when the animation stops.
      this.selectedReferenceFrame = frameId;
      return;
    }

    const refFrame = this.referenceFrames.get(frameId);
    if (refFrame) {
      this.setParam('draw_reference_frames', LCVType.kLCVDataTypeUint, 1);
      this.setParam('selected_frame', LCVType.kLCVDataTypeReferenceFrame, refFrame.handle);
      this.selectedReferenceFrame = frameId;
    } else {
      logger.error(`Reference frame '${frameId}' not found.`);
    }
  }

  clearSelectedFrame(): void {
    this.setParam('draw_reference_frames', LCVType.kLCVDataTypeUint, 0);
    this.setParam('selected_frame', LCVType.kLCVDataTypeReferenceFrame, null);
    this.selectedReferenceFrame = null;
  }

  arrayBufferToData1D(arrayBuffer: ArrayBuffer) {
    const data = this.lcv.newData1D(
      this.sessionHandle,
      LCVType.kLCVDataTypeByte,
      arrayBuffer.byteLength,
      0,
    ).data;

    const mapInfo = this.lcv.mapData(
      this.sessionHandle,
      data,
      LCVMapMode.kLCVMapModeWrite,
      0,
      0,
    );

    const mapping = new Uint8Array(this.lcv.memory(), mapInfo.mapping, mapInfo.size);
    mapping.set(new Uint8Array(arrayBuffer));
    this.lcv.unmapData(this.sessionHandle, data);

    return data;
  }

  async importFromData(
    meshData: ArrayBuffer,
    metaData: ArrayBuffer,
  ) {
    return this.workspaceQueue.enqueue(async () => {
      this.importDatasetFilter.release();
      this.importDatasetFilter = new LcvImportDatasetFilter(
        this.lcv,
        this.sessionHandle,
        this.savedImportDatasetOptions!,
        this.handle,
        DEFAULT_FILTER_ROOT,
        this.displayProps,
      );

      const lcvMeshData = this.arrayBufferToData1D(meshData);
      const lcvMetaData = this.arrayBufferToData1D(metaData);

      this.importDatasetFilter.setParam(
        'import_source',
        LCVType.kLCVDataTypeUint,
        LCVImportSource.kLCVMemory,
      );
      this.importDatasetFilter.setParam('mesh_data', LCVType.kLCVDataTypeData1D, lcvMeshData);
      this.importDatasetFilter.setParam('meta_data', LCVType.kLCVDataTypeData1D, lcvMetaData);
      this.lcv.release(this.sessionHandle, lcvMeshData, 0);
      this.lcv.release(this.sessionHandle, lcvMetaData, 0);
      await this.executeWorkspace(true, false, null);
    });
  }

  prevFilterState: TreeNode | null = null;

  async applyFilterState(
    filterState: TreeNode | null,
    setStatus: SetterOrUpdater<FilterStatus>,
  ) {
    return this.workspaceQueue.enqueue(async () => {
      // If refCount == 0 this is happening during teardown and we should just discard this state
      if (this.refCount === 0) {
        return;
      }
      if (!this.savedImportDatasetOptions || deepEqual(this.prevFilterState, filterState)) {
        // can't apply this filter state
        return;
      }
      const filters: Map<string, LcvFilter> = this.filterIdToObj;
      const visited = new Set<string>();

      if (filterState) {
        traverseTreeNodeAndParent(filterState, (node, parentNode) => {
          visited.add(node.id);
          switch (node.param.typ) {
            case 'Reader': {
              break;
            }
            case 'Slice': {
              assert(node.param.filterParam.typ === 'Plane');
              const { origin, normal } = node.param.filterParam;
              const newState = {
                origin: pvToList(origin),
                normal: pvToList(normal),
              };
              if (filters.has(node.id)) {
                (filters.get(node.id)! as LcvSlice).setState(newState);
              } else {
                assert(parentNode);
                // If we don't find the parent filter in the map, it must be the importDatasetFilter
                const parentFilter = filters.get(parentNode.id) as LcvFilter ??
                  this.importDatasetFilter;
                assert(parentFilter);
                const newFilter = new LcvSlice(
                  this.lcv,
                  this.sessionHandle,
                  this.handle,
                  node.id,
                  parentFilter,
                  newState,
                );
                filters.set(node.id, newFilter);
                this.filterIndexToObj.set(newFilter.objectId, newFilter);
              }
              break;
            }
            case 'Clip': {
              if (node.param.filterParam.typ === 'BoxClip') {
                const { position, rotation, length } = node.param.filterParam;
                const newState = {
                  clipFunction: {
                    position: pvToList(position),
                    rotation: pvToList(rotation),
                    length: pvToList(length),
                  },
                  smooth: node.param.smooth,
                  // The vis lib box clip is backward. Its removing the region the user
                  // expects us to keep. Just flip it.
                  invert: !node.param.invert,
                };

                if (filters.has(node.id)) {
                  (filters.get(node.id)! as LcvClip).setState(newState);
                } else {
                  assert(parentNode);
                  // If we don't find the parent filter in the map,
                  // it must be the importDatasetFilter.
                  const parentFilter = filters.get(parentNode.id) as LcvFilter ??
                    this.importDatasetFilter;
                  assert(parentFilter);
                  const newFilter = new LcvClip(
                    this.lcv,
                    this.sessionHandle,
                    this.handle,
                    node.id,
                    parentFilter,
                    newState,
                  );
                  filters.set(node.id, newFilter);
                  this.filterIndexToObj.set(newFilter.objectId, newFilter);
                }
              } else if (node.param.filterParam.typ === 'Plane') {
                const { origin, normal } = node.param.filterParam;
                const newState = {
                  clipFunction: {
                    origin: pvToList(origin),
                    normal: pvToList(normal),
                  },
                  smooth: node.param.smooth,
                  invert: node.param.invert,
                };

                if (filters.has(node.id)) {
                  (filters.get(node.id)! as LcvClip).setState(newState);
                } else {
                  assert(parentNode);
                  // If we don't find the parent filter in the map, it must be
                  // the importDatasetFilter.
                  const parentFilter = filters.get(parentNode.id) as LcvFilter ??
                    this.importDatasetFilter;
                  assert(parentFilter);
                  const newFilter = new LcvClip(
                    this.lcv,
                    this.sessionHandle,
                    this.handle,
                    node.id,
                    parentFilter,
                    newState,
                  );
                  filters.set(node.id, newFilter);
                  this.filterIndexToObj.set(newFilter.objectId, newFilter);
                }
              }
              break;
            }
            case 'Contour': {
              const { contourVariable, isosurfaces } = node.param;
              let componentIndex = 0;
              // Check if we're contouring a vector field component and remap the
              // component index if so
              const ncomps = this.lcv.getFieldComponents(
                this.sessionHandle,
                this.handle,
                contourVariable.displayDataName,
                0,
              ).ncomponents;
              if (ncomps === 3) {
                componentIndex = remapComponentIndex(componentIndex);
              }

              const newState = {
                isosurface_field: contourVariable.displayDataName,
                component: componentIndex,
                iso_values: isosurfaces,
              };
              if (filters.has(node.id)) {
                (filters.get(node.id)! as LcvContour).setState(newState);
              } else {
                assert(parentNode);
                // If we don't find the parent filter in the map,
                // it must be the importDatasetFilter.
                const parentFilter = filters.get(parentNode.id) as LcvFilter ??
                  this.importDatasetFilter;
                assert(parentFilter);
                const newFilter = new LcvContour(
                  this.lcv,
                  this.sessionHandle,
                  this.handle,
                  node.id,
                  parentFilter,
                  newState,
                );
                filters.set(node.id, newFilter);
                this.filterIndexToObj.set(newFilter.objectId, newFilter);
              }
              break;
            }
            case 'Threshold': {
              // TODO(LC-22403): compute new state based on node.param
              const newState = {};

              if (filters.has(node.id)) {
                (filters.get(node.id)! as LcvThreshold).setState(newState);
              } else {
                assert(parentNode);
                // If we don't find the parent filter in the map,
                // it must be the importDatasetFilter.
                const parentFilter = filters.get(parentNode.id) as LcvFilter ??
                  this.importDatasetFilter;
                assert(parentFilter);
                const newFilter = new LcvThreshold(
                  this.lcv,
                  this.sessionHandle,
                  this.handle,
                  node.id,
                  parentFilter,
                  newState,
                );
                filters.set(node.id, newFilter);
                this.filterIndexToObj.set(newFilter.objectId, newFilter);
              }
              break;
            }
            default:
            // unsupported filter type, do nothing
          }
          // Maybe add a color for this filter, if it was just added.
          if (!this.colorMap.has(node.id)) {
            this.colorMap.set(node.id, this.getRandomColor());
          }
        });
      }

      // if some filter was removed, delete it from lcvis and remove any references to it.
      this.filterIdToObj.forEach((_, id) => {
        if (!visited.has(id)) {
          const filter = this.filterIdToObj.get(id);
          const objectId = filter!.objectId;
          this.filterIdToObj.delete(id);
          this.filterIndexToObj.delete(objectId);
          filter!.release();
        }
      });

      const filterNameMap = new Map<string, string>();
      if (filterState) {
        traverseTreeNodes(filterState, (node) => {
          filterNameMap.set(node.id, node.name);
        });
      }

      setStatus((currentStatus) => {
        const newState: FilterStatus = new Map();

        this.filterIdToObj.forEach((_, id) => {
          const existingState = currentStatus.get(id);

          newState.set(id, existingState || { status: 'queued', error: '' });
        });

        return newState;
      });

      await this.executeWorkspace(true, false, setStatus);

      if (filterState) {
        traverseTreeNodes(filterState, (node) => {
          const filter = this.filterIdToObj.get(node.id);
          if (!node.displayProps) {
            return;
          }
          filter?.setViewProps(
            node.displayProps,
            node.visible,
            this.colorMap.get(node.id),
          );
          if (this.selectedSurfaces.has(node.id)) {
            this.setSurfaceColor(node.id, SURFACE_SELECTED_COLOR);
          }
        });
      }
      this.prevFilterState = filterState;
    });
  }

  setDisplayVariable(fieldName: string, component?: number): number {
    return this.importDatasetFilter.setDisplayVariable({
      displayDataName: fieldName,
      displayDataNameComponent: component ?? 0,
    });
  }

  getColorMap(displayVariable: DisplayPvVariable): ColorMap {
    const colorMap = new LcvColorMap(this.lcv, this.sessionHandle, this.handle, displayVariable);
    const minVal = colorMap.getProperty('min_value', LCVType.kLCVDataTypeFloat);
    const maxVal = colorMap.getProperty('max_value', LCVType.kLCVDataTypeFloat);

    // In Paraview, order of ranges is mag, x, y, z.
    // In LCVis, it's returned as x, y, z, mag.
    // The UI only understands the Paraview mapping so we have to rearrange here so the UI is less
    // confusing.
    // so we need to change the index of the range we're getting here.
    const ncomps = this.lcv.getFieldComponents(
      this.sessionHandle,
      this.handle,
      displayVariable.displayDataName,
      0,
    ).ncomponents;
    let index = displayVariable.displayDataNameComponent;
    if (ncomps === 3) {
      index = remapComponentIndex(index);
    }

    const globalRange = this.lcv.getFieldComponentRange(
      this.sessionHandle,
      this.handle,
      displayVariable.displayDataName,
      index,
      0,
    ).range;

    const translatedColorMap: ColorMap = {
      // 'Viridis', etc.
      presetName: colorMap.getProperty('texture_name', LCVType.kLCVDataTypeString),
      // A custom scalar range for color map. Initialize to range limit.
      range: [minVal, maxVal],
      // The range limit of the scalar field associated with this color map.
      // This limit restricts valid range values. Defaults to the global scalar field
      // range in the volume.
      globalRange,
      // Whether this color map should be displayed in the UI. If there is no
      // visible surface or other visualization with the displayDataName, then the
      // color map will be hidden in the UI until at least one is visible.
      visible: colorMap.getProperty('visible', LCVType.kLCVDataTypeUint) === 1,
      // The number of levels or table values used when discrete color map type
      // is selected.
      bins: colorMap.getProperty('bins', LCVType.kLCVDataTypeUint),
      // Whether this color map should use a user-defined number of discrete levels.
      discretize: colorMap.getProperty('discretize', LCVType.kLCVDataTypeUint) === 1,
    };

    return translatedColorMap;
  }

  updateColorMap(displayVariable: DisplayPvVariable, newColorMap: ColorMap) {
    const colorMap = new LcvColorMap(this.lcv, this.sessionHandle, this.handle, displayVariable);
    colorMap.setParam('texture_name', LCVType.kLCVDataTypeString, newColorMap.presetName);
    colorMap.setParam('bins', LCVType.kLCVDataTypeUint, newColorMap.bins);
    colorMap.setParam('discretize', LCVType.kLCVDataTypeUint, newColorMap.discretize ? 1 : 0);

    const [minVal, maxVal] = newColorMap.range;
    colorMap.setParam('min_value', LCVType.kLCVDataTypeFloat, minVal);
    colorMap.setParam('max_value', LCVType.kLCVDataTypeFloat, maxVal);
  }

  setProblematicEdges(edgeIds: Map<string, LCStatus['level']>) {
    const problematicEdges = [...edgeIds.entries()].reduce((result, [edgeId, level]) => {
      const primitiveId = this.importDatasetLineNameIndexMap.getByKey(edgeId);

      if (primitiveId !== undefined) {
        result.set(primitiveId, level);
      }

      return result;
    }, new Map<number, LCStatus['level']>());

    this.importDatasetFilter.setProblematicEdges(problematicEdges);
  }
}
