// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { copiedName } from '../../../lib/name';
import { generateRegionId } from '../../../lib/refinementRegionUtils';
import { useSetMeshMultiPart } from '../../../recoil/useMeshingMultiPart';
import { useSetRefinementRegionVisibility } from '../../../recoil/useRefinementRegions';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

/**
 * @returns a callback to duplicate the refinement region with the given id.
 * */
export const useCopyRefinementRegion = () => {
  // == Contexts
  const { setSelection, setScrollTo } = useSelectionContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const setRefinementRegionVis = useSetRefinementRegionVisibility({ projectId, workflowId, jobId });

  // == Hooks
  const setVisible = useCallback((rrId: string) => {
    setRefinementRegionVis((oldVis) => {
      const newVis = { ...oldVis };
      newVis[rrId] = true;
      return newVis;
    });
  }, [setRefinementRegionVis]);

  const duplicateRefinementRegion = useCallback(async (id: string) => {
    const newId = generateRegionId();

    setMeshMultiPart((meshMultiPart) => {
      if (!meshMultiPart) {
        return meshMultiPart;
      }
      const newMultiPart = meshMultiPart.clone();
      const rrToCopy = newMultiPart.refinementParams.find((rrParam) => rrParam.id === id);
      if (!rrToCopy) {
        return meshMultiPart;
      }
      const newRr = rrToCopy.clone();
      newRr.name = copiedName(rrToCopy.name);
      newRr.id = newId;
      newMultiPart.refinementParams.push(newRr);
      return newMultiPart;
    });

    setSelection([newId]);
    setScrollTo({ node: newId });
    setVisible(newId);
  }, [setScrollTo, setSelection, setVisible, setMeshMultiPart]);

  return duplicateRefinementRegion;
};
