import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 14;
const baseHeight = 14;
export const ServerSideIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="server-side"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        clipRule="evenodd"
        d="M2.78086 4.32465C3.08648 2.32812 4.8842 0.834961 7.00102 0.834961C8.71334 0.834961
           10.206 1.80981 10.8806 3.23723C12.455 3.61201 13.665 4.96583 13.665 6.64306C13.665
           8.6183 11.9928 10.165 9.99978
           10.165H7.66496V11.835H12.665V13.165H1.99996V11.835H6.33496V10.165H3.4001C1.73815
           10.165 0.334961 8.87441 0.334961 7.21444C0.334961 5.7656 1.4039 4.59818 2.78086
           4.32465ZM7.00102 2.16496C5.34796 2.16496 4.0651 3.43218 4.0651 4.92892C4.0651
           5.29619 3.76737 5.59392 3.4001 5.59392C2.41061 5.59392 1.66496 6.34958 1.66496
           7.21444C1.66496 8.0793 2.41061 8.83496 3.4001 8.83496H9.99978C11.3204 8.83496
           12.335 7.82318 12.335 6.64306C12.335 5.56584 11.4861 4.63628 10.3193 4.4846C10.0714
           4.45238 9.86259 4.28393 9.77863 4.04854C9.39334 2.96829 8.30769 2.16496 7.00102
           2.16496Z"
        fill={getColor(0)}
        fillRule="evenodd"
      />

    </svg>
  );
});
