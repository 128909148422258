// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import assert from '../../lib/assert';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import { truncatedWordList } from '../../lib/text';
import { volumeNodeId } from '../../lib/volumeUtils';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as entitygrouppb from '../../proto/entitygroup/entitygroup_pb';

export type GeometryTag = {
  id: string,
  name: string,
  bodyIds: number[],
  faceIds: number[],
  bodiesOfFaces: number[],
  lcnBodiesOfFaces: number[],
  lcnBodies: number[],
};

type ServerReply = geometryservicepb.GetTagsResponse['tags'];

const SUFFIX_BODY_TAG = 'body-id-tag-';
const SUFFIX_FACE_TAG = 'face-tag-face-id-';

/**
 * GeometryTags is a class that provides several helper functions to map from entityGroupMap IDs to
 * geometry entity IDs. It is in charge of inserting such tags within the entityGroupMap and it is
 * the owner of the ID mapping.
*/
export class GeometryTags {
  tags: GeometryTag[];

  // Maps a given face ID to the body ID it belongs to.
  faceIdToBodyId: Map<number, number>;

  // Set of all tag container IDs as they are inserted in the entityGroupMap.
  tagContainerIds: Set<string>;

  // Maps a face entity group ID to the face ID it represents.
  faceEntityGroupMapIdToFaceId = new Map<string, number>();

  tagIdToName: Map<string, string>;

  // Links a given face ID with a potential issue (for example if the face belongs to multiple
  // tags).
  faceIdToIssue: Map<number, string>;

  // Same as above but for LCN body IDs.
  lcnBodyIdToIssue: Map<number, string>;

  // Maps native body IDs to LCN ids.
  bodyIdToBodyIndexMap: Map<number, number>;

  constructor(serverReply: ServerReply | undefined) {
    this.tags = serverReply?.tags.map((tag) => ({
      id: tag.id,
      name: tag.name,
      bodyIds: tag.bodies,
      faceIds: tag.faces,
      bodiesOfFaces: tag.bodiesOfFaces,
      lcnBodies: tag.lcnBodies,
      lcnBodiesOfFaces: tag.lcnBodiesOfFaces,
    })) || [];
    this.faceIdToBodyId = new Map();
    this.tagContainerIds = new Set();
    this.tagIdToName = new Map();
    this.bodyIdToBodyIndexMap = new Map();
    this.updateFaceEntityGroupMapIdToFaceId();
    this.tags.forEach((tag) => {
      tag.faceIds.forEach((faceId, index) => {
        const bodyId = tag.bodiesOfFaces[index];
        const bodyLcnId = tag.lcnBodiesOfFaces[index];
        this.bodyIdToBodyIndexMap.set(bodyId, bodyLcnId);

        this.faceIdToBodyId.set(faceId, tag.bodiesOfFaces[index]);
      });
      tag.bodyIds.forEach((bodyId, index) => {
        const lcnId = tag.lcnBodies[index];
        this.bodyIdToBodyIndexMap.set(bodyId, lcnId);
      });
      this.tagContainerIds.add(tag.id);
      this.tagIdToName.set(tag.id, tag.name);
    });
    this.faceIdToIssue = new Map();
    this.lcnBodyIdToIssue = new Map();
    this.assessIssues();
  }

  private assessIssues() {
    const facesToTagNames = new Map<number, string[]>();
    const bodiesToTagNames = new Map<number, string[]>();
    this.tags.forEach((tag) => {
      tag.faceIds.forEach((faceId) => {
        if (facesToTagNames.has(faceId)) {
          facesToTagNames.get(faceId)?.push(tag.name);
          return;
        }
        facesToTagNames.set(faceId, [tag.name]);
      });
      tag.bodyIds.forEach((bodyId) => {
        if (bodiesToTagNames.has(bodyId)) {
          bodiesToTagNames.get(bodyId)?.push(tag.name);
          return;
        }
        bodiesToTagNames.set(bodyId, [tag.name]);
      });
    });

    // Make sure that we don't print massive strings.
    const maxTagNames = 4;
    facesToTagNames.forEach((tags, faceId) => {
      if (tags.length > 1) {
        const tagNames = truncatedWordList(tags, maxTagNames);
        this.faceIdToIssue.set(faceId, `Face belongs to multiple tags: ${tagNames}`);
      }
    });
    bodiesToTagNames.forEach((tags, bodyId) => {
      if (tags.length > 1) {
        const tagNames = truncatedWordList(tags, maxTagNames);
        const lcnBodyId = this.bodyIdToBodyIndex(bodyId);
        this.lcnBodyIdToIssue.set(lcnBodyId, `Body belongs to multiple tags: ${tagNames}`);
      }
    });
  }

  faceTagIssue(tagEntityGroupId: string) {
    const faceId = this.faceEntityGroupMapIdToFaceId.get(tagEntityGroupId);
    if (faceId === undefined) {
      return '';
    }
    return this.faceIdToIssue.get(faceId) || '';
  }

  bodyTagIssue(tagEntityGroupId: string) {
    const volumeId = this.domainFromTagEntityGroupId(tagEntityGroupId);
    if (volumeId === undefined) {
      return '';
    }
    return this.lcnBodyIdToIssue.get(parseInt(volumeId, 10)) || '';
  }

  private entityGroupIdForFaceIdOfTag(tagId: string, faceId: number) {
    return `${tagId}${SUFFIX_FACE_TAG}${faceId}`;
  }

  // Maps an entity group ID entry for a face to its correspondent face ID in the CAD.
  private updateFaceEntityGroupMapIdToFaceId() {
    this.faceEntityGroupMapIdToFaceId = new Map();
    this.tags.forEach((tag) => {
      tag.faceIds.forEach((faceId) => {
        const faceEntityGroupId = this.entityGroupIdForFaceIdOfTag(tag.id, faceId);
        this.faceEntityGroupMapIdToFaceId.set(faceEntityGroupId, faceId);
      });
    });
  }

  tagIds() {
    return this.tagContainerIds;
  }

  tagNameFromId(id: string) {
    return this.tagIdToName.get(id);
  }

  /**
   * Removes any tag identifier suffixes (faces or bodies) from the given identifier.
   * This ensures that the identifier represents the core node.
   */
  getCoreNodeIdentifier(identifier: string) {
    const surfaceId = this.surfaceFromTagEntityGroupId(identifier);
    if (surfaceId) {
      return surfaceId;
    }

    const domain = this.domainFromTagEntityGroupId(identifier);
    if (domain !== undefined) {
      return volumeNodeId(+domain);
    }

    return identifier;
  }

  // Needed to convert from CAD ids into LCN ids (i.e. id vs ids[index]).
  private bodyIdToBodyIndex(bodyId: number) {
    const val = this.bodyIdToBodyIndexMap.get(bodyId);
    return val !== undefined ? val : bodyId;
  }

  // Provided an ID of a BODY_TAG it will return the domain ID.
  domainFromTagEntityGroupId(tagEntityGroupId: string) {
    if (!tagEntityGroupId.includes(SUFFIX_BODY_TAG)) {
      return undefined;
    }
    const vals = tagEntityGroupId.split('-');
    const bodyId = parseInt(vals[vals.length - 1], 10);
    return this.bodyIdToBodyIndex(bodyId).toString();
  }

  // Provided an ID of a geometry tag (TAG_CONTAINER) it will return the domain IDs associated with
  // it.
  domainsFromTag(tagId: string): Array<string> {
    const tag = this.tags.find((tagFind) => tagFind.id === tagId);
    if (!tag) {
      return [];
    }
    return tag.bodyIds.map((bodyId) => (
      this.bodyIdToBodyIndex(bodyId).toString()
    ));
  }

  // Provided an ID of a geometry tag it will return the domain IDs associated with it.
  domainsFromTagEntityGroupId(tagEntityGroupId: string) {
    if (!this.tagContainerIds.has(tagEntityGroupId)) {
      return undefined;
    }
    return this.domainsFromTag(tagEntityGroupId);
  }

  // Given a face entity group ID child of a tag ID, it returns the correspondent face ID.
  surfaceFromTagEntityGroupId(tagEntityGroupId: string) {
    const faceId = this.faceEntityGroupMapIdToFaceId.get(tagEntityGroupId);
    if (!faceId) {
      return '';
    }
    assert(faceId !== undefined, 'Invalid geometry metadata');
    const bodyId = this.faceIdToBodyId.get(faceId);
    assert(bodyId !== undefined, 'Invalid geometry metadata');
    return `${this.bodyIdToBodyIndex(bodyId)}/bound/BC_${faceId}`;
  }

  surfacesFromTagEntityGroupId(tagEntityGroupId: string) {
    if (!this.tagContainerIds.has(tagEntityGroupId)) {
      return undefined;
    }
    const tagName = this.tagIdToName.get(tagEntityGroupId);
    return tagName ? this.surfacesFromTag(tagName) : [];
  }

  private surfacesFromTag(tagName: string): Array<string> {
    const tag = this.tags.find((tagFind) => tagFind.name === tagName);
    if (!tag) {
      return [];
    }
    return tag.faceIds.map((faceId) => {
      const bodyId = this.faceIdToBodyId.get(faceId);
      assert(bodyId !== undefined, 'Invalid geometry metadata');
      return `${this.bodyIdToBodyIndex(bodyId)}/bound/BC_${faceId}`;
    });
  }

  // Adds all the geometry tags to the entity group map.
  addToEntityGroup(groupMap: EntityGroupMap) {
    this.tags.forEach((tag) => {
      groupMap.delete(tag.id);
    });
    this.tags.forEach((tag) => {
      const tagName = tag.name;
      const rootTagId = tag.id;
      const container = groupMap.add({
        name: tagName,
        parentId: EntityGroupMap.rootId,
        entityType: entitygrouppb.EntityType.TAG_CONTAINER,
        id: rootTagId,
      });
      tag.faceIds.forEach((face) => {
        const faceId = this.entityGroupIdForFaceIdOfTag(tag.id, face);
        container.add({
          name: `Surface ${face}`,
          parentId: rootTagId,
          entityType: entitygrouppb.EntityType.FACE_TAG,
          id: faceId,
        });
      });
      tag.bodyIds.forEach((body) => {
        const bodyIdx = this.bodyIdToBodyIndex(body);
        container.add({
          name: `Volume ${bodyIdx + 1}`,
          parentId: rootTagId,
          entityType: entitygrouppb.EntityType.BODY_TAG,
          id: `${rootTagId}${SUFFIX_BODY_TAG}${body}`,
        });
      });
    });
  }

  // Unrolls all the tags in the entities array by replacing the tag with the actual surfaces of the
  // tag. If the tag does not have surfaces, it will be erased.
  unrollFaceTags(entities: string[]) {
    const set = entities.reduce((acc, entity) => {
      if (this.isTagId(entity)) {
        this.surfacesFromTagEntityGroupId(entity)?.forEach((surface) => acc.add(surface));
      } else {
        acc.add(entity);
      }
      return acc;
    }, new Set<string>());
    return Array.from(set);
  }

  // Unrolls all the tags in the entities array by replacing the tag with the actual volumes of the
  // tag. If the tag does not have surfaces, it will be erased.
  unrollBodyTags(entities: string[]) {
    const set = entities.reduce((acc, entity) => {
      if (this.isTagId(entity)) {
        this.domainsFromTagEntityGroupId(entity)?.forEach((vol) => acc.add(vol));
      } else {
        acc.add(entity);
      }
      return acc;
    }, new Set<string>());
    return Array.from(set);
  }

  /**
   * Returns a set of node identifiers assigned to at least one tag.
   */
  getAssignedNodeIds<T extends boolean>(
    tagIds: Set<string>,
    categorize: T,
  ): T extends true ? { surfaces: Set<string>; volumes: Set<string> } : Set<string> {
    const categorized = [...tagIds].reduce((result, tagId) => {
      const domains = this.domainsFromTag(tagId);
      const volumeIds = domains.map((domain) => volumeNodeId(+domain));
      const surfaceIds = this.surfacesFromTagEntityGroupId(tagId) || [];

      surfaceIds.forEach((surfaceId) => {
        result.surfaces.add(surfaceId);
      });

      volumeIds.forEach((volumeId) => {
        result.volumes.add(volumeId);
      });

      return result;
    }, { surfaces: new Set<string>(), volumes: new Set<string>() });

    if (categorize) {
      return (
        categorized as T extends true ?
          { surfaces: Set<string>; volumes: Set<string> } :
          Set<string>
      );
    }

    const allItems = categorized.surfaces;
    categorized.volumes.forEach((volumeId) => {
      allItems.add(volumeId);
    });

    return (
      allItems as T extends true ?
        { surfaces: Set<string>; volumes: Set<string> } :
        Set<string>
    );
  }

  isTagId(id: string) {
    return this.tagContainerIds.has(id);
  }
}

export const EMPTY_GEOMETRY_TAGS = new GeometryTags(undefined);
