// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { useRecoilValue } from 'recoil';

import * as flags from '../../../../../flags';
import { colors } from '../../../../../lib/designSystem';
import { isDefaultMeshMode } from '../../../../../lib/mesh';
import { isGeometryFile } from '../../../../../lib/upload/uploadUtils';
import { useMeshUrlState } from '../../../../../recoil/meshState';
import { useSelectedGeometry } from '../../../../../recoil/selectedGeometry';
import { useEnabledExperiments, useIsEnabled } from '../../../../../recoil/useExperimentConfig';
import { MeshPanelType, useSetMeshPanelState } from '../../../../../recoil/useMeshPanelState';
import { useMeshReadOnly } from '../../../../../recoil/useMeshReadOnly';
import { useMeshValidator } from '../../../../../recoil/useMeshValidator';
import useMeshMultiPart from '../../../../../recoil/useMeshingMultiPart';
import { usePreviouslySelectedMesh } from '../../../../../recoil/usePreviouslyActiveMesh';
import { projectActiveMeshSelector, useHasActiveMesh } from '../../../../../recoil/useProjectActiveMesh';
import { ActionButton } from '../../../../Button/ActionButton';
import { createStyles, makeStyles } from '../../../../Theme';
import Divider from '../../../../Theme/Divider';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useHandleMeshSelect } from '../../../../hooks/useHandleMeshSelect';
import { useIsLMAActive, useIsMinimalMeshMode, useSetLMA } from '../../../../hooks/useMesh';
import { AdvancedMeshingPanel } from '../../../../meshing/AdvancedMeshingPanel';
import { SectionMessage } from '../../../../notification/SectionMessage';
import { Flex } from '../../../../visual/Flex';
import PropertiesSection from '../../../PropertiesSection';
import { LumiMeshAdaptationSection, MeshAutomationScaling } from '../Automation';
import { MeshBoundaryParams } from '../Boundary';
import { MeshModelParams } from '../Model';
import { MeshVolumeParams } from '../Size';
import { GenerateMeshButton } from '../components/GenerateMeshButton';

import { OptionalParamsPanel } from './OptionalParamsPanel';

const useStyles = makeStyles(
  () => createStyles({
    meshingProps: {
      flex: '1 1 auto',
      overflow: 'auto',
    },
    meshingContainer: {
      flex: '0 0 auto',
      borderTop: '1px solid',
      borderColor: colors.neutral50,
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
  }),
  { name: 'MeshEditPanel' },
);

// A panel that displays the mesh generation parameters.
const MeshGenPanel = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const volume = meshMultiPart?.volumeParams[0];
  const boundary = meshMultiPart?.blParams[0];
  const model = meshMultiPart?.modelParams[0];
  const experimentConfig = useEnabledExperiments();
  const activeMesh = useRecoilValue(projectActiveMeshSelector({ projectId }));
  if (!volume || !model || !boundary || activeMesh) {
    return null;
  }

  // only show advanced meshing parameters if the advancedMeshParam feature flag is on
  const advancedMeshing = experimentConfig.includes(flags.advancedMeshParam);

  return (
    <>
      {isDefaultMeshMode(meshMultiPart) && (
        <>
          <MeshVolumeParams isInput={false} volumeMeshingIndex={0} />
          <Divider />
          <MeshModelParams isInput={false} modelIndex={0} />
          <Divider />
        </>
      )}
      <MeshBoundaryParams boundaryIndex={0} isInput={false} />

      {isDefaultMeshMode(meshMultiPart) && (
        <>
          <Divider />
          <OptionalParamsPanel meshUrl="" />
        </>
      )}
      {advancedMeshing && (
        <>
          <Divider />
          <PropertiesSection>
            <AdvancedMeshingPanel />
          </PropertiesSection>
        </>
      )}
    </>
  );
};

// A panel for displaying the mesh statistics and the mesh generation.
export const MeshEditPanel = () => {
  const classes = useStyles();
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const hasActiveMesh = useHasActiveMesh(projectId);
  const [meshUrlState] = useMeshUrlState(projectId);
  const setMeshPanelState = useSetMeshPanelState(projectId);
  const previousMeshId = usePreviouslySelectedMesh(projectId);
  const { handleMeshSelect } = useHandleMeshSelect();
  const isMinimalMeshMode = useIsMinimalMeshMode(projectId);
  const meshGenerating = useMeshReadOnly(projectId);
  const remeshingEnabled = useIsEnabled(flags.remeshing);
  const [selectedGeometry] = useSelectedGeometry(projectId, workflowId, jobId);

  const { setLMA } = useSetLMA();
  const isLMA = useIsLMAActive();
  const isGeometry = isGeometryFile(meshUrlState.url) || remeshingEnabled;
  const isMeshable = isGeometry || !!selectedGeometry.geometryId;

  const warning = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const { disabledReason, disabledLevel } = warning;

  const handleCancelLMA = () => {
    setLMA(false);
    // If there is no mesh selected go back to the select panel, else we must be on the details
    // panel and can stay there.
    if (!hasActiveMesh) {
      setMeshPanelState(MeshPanelType.SELECT);
    }
  };

  const handleCancelButtonClick = async () => {
    // If there is a previously selected mesh, load it back in
    if (previousMeshId) {
      await handleMeshSelect(previousMeshId);
    } else {
      // If there is no previously selected mesh, go back to the select panel
      setMeshPanelState(MeshPanelType.SELECT);
    }
  };

  const propertiesSection = (
    <>
      {isLMA && <LumiMeshAdaptationSection />}
      {!isLMA && isMeshable && <><MeshAutomationScaling /><Divider /></>}
      {isMeshable && !isMinimalMeshMode && (
        <>
          <Divider />
          <MeshGenPanel />
        </>
      )}
    </>
  );

  return (
    <>
      <div className={classes.meshingProps}>
        {propertiesSection}
      </div>
      {!isLMA ? (
        <div className={classes.meshingContainer}>
          {disabledReason && isMeshable && (
            <SectionMessage level={disabledLevel}>
              {disabledReason}
            </SectionMessage>
          )}
          <Flex gap={12}>
            {isMeshable && (
              <>
                <ActionButton
                  disabled={meshGenerating}
                  kind="cancel"
                  onClick={handleCancelButtonClick}
                  size="small">
                  Cancel
                </ActionButton>
                <GenerateMeshButton disabled={!!disabledReason || meshGenerating} />
              </>
            )}
          </Flex>
        </div>
      ) : (
        <div className={classes.meshingContainer}>
          <ActionButton
            disabled={readOnly || (meshGenerating && !hasActiveMesh)}
            kind="cancel"
            onClick={handleCancelLMA}
            size="small">
            Exit Lumi Mesh Adaptation
          </ActionButton>
        </div>
      )}
    </>
  );
};
