// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useLayoutEffect, useRef, useState } from 'react';

import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'TooltippedText' },
);

interface TooltippedTextProps {
  text: string;
}
export const TooltippedText = (props: TooltippedTextProps) => {
  const { text } = props;

  const classes = useStyles();
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
      setShow(true);
    }
  }, [ref.current?.scrollWidth, ref.current?.clientWidth]);

  return (
    <div className={classes.root} ref={ref}>
      <Tooltip title={show ? text : ''}>
        <span>{text}</span>
      </Tooltip>
    </div>
  );
};
