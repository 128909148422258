// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getQuantityTags } from '../../../../../QuantityDescriptor';
import { getAdValue } from '../../../../../lib/adUtils';
import assert from '../../../../../lib/assert';
import { unwrapSurfaceIds } from '../../../../../lib/entityGroupUtils';
import { Bounds } from '../../../../../lib/lcvis/types';
import { Logger } from '../../../../../lib/observability/logs';
import { calcForceOptions, calcIntegralOptions, createOutputs, getOutputNodeWarnings, getOutputQuantity } from '../../../../../lib/outputNodeUtils';
import { findParticleGroupById } from '../../../../../lib/particleGroupUtils';
import * as rpc from '../../../../../lib/rpc';
import { NodeType, TAGS_NODE_TYPES } from '../../../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../../../lib/subselectUtils';
import { Vector } from '../../../../../lib/vectorAlgebra';
import { useOutput } from '../../../../../model/hooks/useOutput';
import * as simulationpb from '../../../../../proto/client/simulation_pb';
import * as frontendpb from '../../../../../proto/frontend/frontend_pb';
import * as feoutputpb from '../../../../../proto/frontend/output/output_pb';
import { QuantityTag } from '../../../../../proto/quantity/quantity_options_pb';
import { useEntityGroupData } from '../../../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../../../recoil/geometry/geometryTagsState';
import { useLcvisArrowState } from '../../../../../recoil/lcvis/lcvisArrowState';
import { useMeshMetadata } from '../../../../../recoil/meshState';
import { useOutputNodes } from '../../../../../recoil/outputNodes';
import { NodeFilter } from '../../../../../recoil/simulationTreeSubselect';
import { useActiveVisUrlValue } from '../../../../../recoil/vis/activeVisUrl';
import { useStaticVolumes } from '../../../../../recoil/volumes';
import { useSimulationParam } from '../../../../../state/external/project/simulation/param';
import { useSimulationParamScope } from '../../../../../state/external/project/simulation/paramScope';
import { DataSelect } from '../../../../Form/DataSelect';
import LabeledInput from '../../../../Form/LabeledInput';
import { useProjectContext } from '../../../../context/ProjectContext';
import { usePorousForceOutput } from '../../../../hooks/output/usePorousForceOutput';
import { inSurfacesError } from '../../../../hooks/useNodeTableTreeRowError';
import { SectionMessage } from '../../../../notification/SectionMessage';
import { NodeSubselect } from '../../../NodeSubselect';

const logger = new Logger('OutputSurfaces');

const { CALCULATION_DIFFERENCE } = feoutputpb.CalculationType;

interface OutputSurfacesProps {
  outputNode: feoutputpb.OutputNode;
  projectId: string;
  idBase: string;
}

function compareUint8Array(arr1: Uint8Array, arr2: Uint8Array): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export function OutputSurfaces(props: OutputSurfacesProps) {
  const { idBase, outputNode, projectId } = props;

  const [, setLcvisArrowState] = useLcvisArrowState();
  useEffect(
    () => () => {
      setLcvisArrowState({ active: false, scale: 1 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const quantity = getOutputQuantity(outputNode);
  if (!quantity) {
    throw Error('No quantity found.');
  }
  const { updateOutputNode } = useOutput(projectId, outputNode.id);
  const { workflowId, jobId } = useProjectContext();
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const outputNodeWarnings = getOutputNodeWarnings(
    outputNode,
    outputNodes,
    simParam,
    entityGroupData,
    paramScope,
    staticVolumes,
    geometryTags,
    outputNodes.referenceValues?.referenceValueType,
    true,
    [],
  );
  const paraviewActiveUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const meshMetadata = useMeshMetadata(projectId, paraviewActiveUrl);

  // Output direction, only valid if different from undefined.
  const [direction, setDirection] = useState<Vector<3> | undefined>(undefined);

  // Moment center, only valid if different from undefined and if the output has the moment tag.
  const [momentCenter, setMomentCenter] = useState<Vector<3> | undefined>(undefined);

  // Used to keep track of the generation of the RPCs to obtain the output direction. If we
  // receive an update from an older generation we discard it.
  const generation = useRef<number>(0);

  // Used to avoid rerenders or calling multiple times the same RPC.
  const latestOutputNodeSentRpc = useRef<feoutputpb.OutputNode | null>(null);

  // Stripped params to be sent to compute the outputs directions. We only need Farfield boundary
  // conditions, so we can save some bandwidth.
  const simParamStripped = useMemo(() => {
    const cloned = simParam.clone();
    cloned.physics.forEach((phys) => {
      if (phys.params.case === 'fluid') {
        for (let i = 0; i < phys.params.value.boundaryConditionsFluid.length; i += 1) {
          if (phys.params.value.boundaryConditionsFluid[i].physicalBoundary !==
            simulationpb.PhysicalBoundary.FARFIELD) {
            phys.params.value.boundaryConditionsFluid.splice(i, 1);
            i -= 1;
          }
        }
      } else if (phys.params.case === 'heat') {
        phys.params.value.boundaryConditionsHeat = [];
      }
    });
    return cloned;
  }, [simParam]);

  const surfaces = useMemo(() => (
    new Set(unwrapSurfaceIds(outputNode.inSurfaces, geometryTags, entityGroupData))
  ), [entityGroupData, geometryTags, outputNode.inSurfaces]);

  // NOTE: It seems that `getUnionBounds` from LcVis is a big flaky, see LC-22874. We will use the
  // bounds from the mesh metadata instead.
  const bounds = useMemo(() => {
    const metadata = meshMetadata?.meshMetadata;
    const boundsSurf: Bounds = [Infinity, Infinity, Infinity, -Infinity, -Infinity, -Infinity];
    let found = false;
    metadata?.zone.forEach((zone) => {
      zone.bound.forEach((boundary) => {
        if (surfaces.has(boundary.name)) {
          found = true;
          boundsSurf[0] = Math.min(boundsSurf[0], boundary.stats?.minCoord?.x!);
          boundsSurf[1] = Math.min(boundsSurf[1], boundary.stats?.minCoord?.y!);
          boundsSurf[2] = Math.min(boundsSurf[2], boundary.stats?.minCoord?.z!);
          boundsSurf[3] = Math.max(boundsSurf[3], boundary.stats?.maxCoord?.x!);
          boundsSurf[4] = Math.max(boundsSurf[4], boundary.stats?.maxCoord?.y!);
          boundsSurf[5] = Math.max(boundsSurf[5], boundary.stats?.maxCoord?.z!);
        }
      });
    });
    // This can happen when dealing with actuator disks.
    if (!found) {
      return undefined;
    }
    return boundsSurf;
  }, [meshMetadata, surfaces]);

  const titleBase = 'Surfaces and Groups';
  const isDifference = (outputNode.calcType === CALCULATION_DIFFERENCE);
  const titleA = isDifference ? `IN ${titleBase}` : titleBase;

  const {
    showWarning,
    addAllMissingSurfaces,
    dismissWarning,
    warningText,
  } = usePorousForceOutput(outputNode, idBase);

  const isActuatorDiskOutput = useMemo(() => {
    // Actuator disk outputs must be connected to actuator disk surfaces.
    if (
      !(outputNode.nodeProps.value && 'quantityType' in outputNode.nodeProps.value)
    ) {
      return false;
    }
    return getQuantityTags(
      outputNode.nodeProps.value.quantityType,
    ).includes(QuantityTag.TAG_ACTUATOR_DISK);
  }, [outputNode.nodeProps.value]);

  const isMomentOutput = useMemo(() => {
    // Actuator disk outputs must be connected to actuator disk surfaces.
    const isForce = outputNode.nodeProps.case === 'force';
    if (!isForce || outputNode.nodeProps.case === undefined) {
      return false;
    }
    assert(outputNode.nodeProps.case === 'force', 'Node must contain momentNode');
    const nodeProps = outputNode.nodeProps.value as feoutputpb.ForceNode;
    const quantityType = nodeProps.quantityType;
    return getQuantityTags(quantityType).includes(QuantityTag.TAG_MOMENT);
  }, [outputNode.nodeProps.case, outputNode.nodeProps.value]);

  const hasActuatorDiskError = useMemo(() => {
    // Actuator disk outputs must be connected to actuator disk surfaces.
    if (!isActuatorDiskOutput) {
      return false;
    }
    return outputNode.inSurfaces.some((surfaceId) => (
      findParticleGroupById(simParam, surfaceId)?.particleGroupType !==
      simulationpb.ParticleGroupType.ACTUATOR_DISK));
  }, [isActuatorDiskOutput, outputNode.inSurfaces, simParam]);

  const canComputeActDiskDirection = useMemo(() => {
    if (!isActuatorDiskOutput || hasActuatorDiskError || showWarning || surfaces.size === 0) {
      return false;
    }
    // With only one actuator disk we are fine because thrust is well-defined in that case.
    if (surfaces.size === 1) {
      return true;
    }
    // With one physics, we cannot convert from client to solver params.
    if (simParam.physics.length === 0) {
      return false;
    }
    // For DISK_TORQUE, the moment center has to be unique, so it does not make much sense to
    // to show an arrow when there are multiple centers/actuator disks.
    if (surfaces.size > 1 && isMomentOutput) {
      return false;
    }
    // Grab the normal from the first entry in the surfaces set. This entry should be an actuator
    // disk since we already checked for that. If not, bail out just to be safe.
    const key = surfaces.keys().next().value;
    const firstNormal = findParticleGroupById(simParam, key)?.actuatorDiskNormalVector;
    if (!firstNormal) {
      return false;
    }
    let allNormalsEqual = true;
    surfaces.forEach((surfaceId) => {
      const phys = findParticleGroupById(simParam, surfaceId);
      const otherNormal = phys?.actuatorDiskNormalVector;
      if (!otherNormal) {
        allNormalsEqual = false;
        return;
      }
      if (getAdValue(firstNormal.x) !== getAdValue(otherNormal.x) ||
        getAdValue(firstNormal.y) !== getAdValue(otherNormal.y) ||
        getAdValue(firstNormal.z) !== getAdValue(otherNormal.z)) {
        allNormalsEqual = false;
      }
    });
    return allNormalsEqual;
  }, [hasActuatorDiskError, isActuatorDiskOutput, isMomentOutput, showWarning, simParam, surfaces]);

  // The scale of the arrow is determined by the maximum dimension of the surfaces bbox. For act
  // disk outputs, the scale is determined by the maximum radius of the actuator disks for
  // the sake of simplicity.
  const scale = useMemo(() => {
    if (bounds) {
      const maxLengthX = bounds[3] - bounds[0];
      const maxLengthY = bounds[4] - bounds[1];
      const maxLengthZ = bounds[5] - bounds[2];
      return Math.max(maxLengthX, maxLengthY, maxLengthZ) * 1.5;
    }
    if (isActuatorDiskOutput && !hasActuatorDiskError) {
      let maxRadius = 0;
      surfaces.forEach((surfaceId) => {
        const phys = findParticleGroupById(simParam, surfaceId);
        maxRadius = Math.max(getAdValue(phys?.actuatorDiskOuterRadius) || 0, maxRadius);
      });
      return maxRadius;
    }
    return undefined;
  }, [bounds, surfaces, isActuatorDiskOutput, hasActuatorDiskError, simParam]);

  // The center of the arrow is determined by the center of the surfaces bbox. With actuator disks,
  // the center is determined by the average center of the actuator disks.
  const center = useMemo(() => {
    if (bounds) {
      return [bounds[3] + bounds[0], bounds[4] + bounds[1], bounds[5] + bounds[2]].map((vec) => (
        vec / 2
      ));
    }
    if (isActuatorDiskOutput && !hasActuatorDiskError) {
      let centerActDiskAvg: Vector<3> = [0, 0, 0];
      surfaces.forEach((surfaceId) => {
        const phys = findParticleGroupById(simParam, surfaceId);
        const centerPhys = phys?.actuatorDiskCenter;
        if (centerPhys) {
          centerActDiskAvg = [
            centerActDiskAvg[0] + getAdValue(centerPhys.x) || 0,
            centerActDiskAvg[1] + getAdValue(centerPhys.y) || 0,
            centerActDiskAvg[2] + getAdValue(centerPhys.z) || 0,
          ];
        }
      });
      centerActDiskAvg = [
        centerActDiskAvg[0] / surfaces.size,
        centerActDiskAvg[1] / surfaces.size,
        centerActDiskAvg[2] / surfaces.size,
      ];
      return centerActDiskAvg;
    }
    return undefined;
  }, [bounds, surfaces, isActuatorDiskOutput, hasActuatorDiskError, simParam]);

  const setSurfaceInIds = useCallback((surfaceIds: string[]) => {
    updateOutputNode((newOutput) => {
      newOutput.inSurfaces = surfaceIds;
    });
  }, [updateOutputNode]);
  const setSurfaceOutIds = useCallback((surfaceIds: string[]) => {
    updateOutputNode((newOutput) => {
      newOutput.outSurfaces = surfaceIds;
    });
  }, [updateOutputNode]);
  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (outputNode.inSurfaces.includes(nodeId)) {
      return {
        related: true,
        disabled: true,
        tooltip: 'This surface is already selected',
      };
    }

    if (nodeType !== NodeType.SURFACE && nodeType !== NodeType.SURFACE_GROUP &&
      nodeType !== NodeType.PARTICLE_GROUP && nodeType !== NodeType.MONITOR_PLANE) {
      return defaultNodeFilter(nodeType);
    }

    // Actuator disk outputs must be connected to actuator disk surfaces.
    if (
      outputNode.nodeProps.value &&
      'quantityType' in outputNode.nodeProps.value &&
      getQuantityTags(outputNode.nodeProps.value.quantityType).includes(
        QuantityTag.TAG_ACTUATOR_DISK,
      )
    ) {
      if (findParticleGroupById(simParam, nodeId)?.particleGroupType !==
        simulationpb.ParticleGroupType.ACTUATOR_DISK) {
        return {
          related: true,
          disabled: true,
          tooltip: 'Actuator disk outputs must be connected to actuator disk surfaces',
        };
      }
    }

    if (geometryTags.isTagId(nodeId)) {
      const tagSurfaces = geometryTags.surfacesFromTagEntityGroupId(nodeId) || [];

      if (tagSurfaces.length === 0) {
        return {
          related: true,
          disabled: true,
          tooltip: 'You can only select tags that contain some surfaces.',
        };
      }
    }

    const filter = inSurfacesError(
      outputNode,
      entityGroupData,
      nodeType,
      nodeId,
      simParam,
      geometryTags,
      staticVolumes,
    );
    return {
      related: true,
      disabled: filter.disabled,
      tooltip: filter.disabledReason,
    };
  }, [geometryTags, entityGroupData, outputNode, simParam, staticVolumes]);

  // There seems to be less restrictions on the out surfaces.
  const nodeFilterOutFilters = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (outputNode.outSurfaces.includes(nodeId)) {
      return {
        related: true,
        disabled: true,
      };
    }
    if (nodeType !== NodeType.SURFACE && nodeType !== NodeType.SURFACE_GROUP &&
      nodeType !== NodeType.PARTICLE_GROUP) {
      return defaultNodeFilter(nodeType);
    }
    if (geometryTags.isTagId(nodeId)) {
      return {
        related: true,
        disabled: false,
      };
    }
    return {
      related: true,
      disabled: false,
    };
  }, [geometryTags, outputNode]);

  const readOnlyCalcType = getQuantityTags(quantity).includes(QuantityTag.TAG_DROP);
  const visibleNodeTypes = [
    NodeType.SURFACE, NodeType.SURFACE_CONTAINER,
    NodeType.PHYSICAL_BEHAVIOR, NodeType.MONITOR_PLANE,
    ...TAGS_NODE_TYPES,
  ];

  useEffect(() => {
    generation.current += 1;
    // If showWarning is true, we do not want to send the RPC since that may return errors from the
    // analyzer. For actuator disks, we need to be extra careful because thrust directions may be
    // different for each actuator disk and that raises an error in the analyzer.
    if (outputNodeWarnings.length > 0 || showWarning ||
      (isActuatorDiskOutput && !canComputeActDiskDirection)) {
      setDirection(undefined);
      setMomentCenter(undefined);
      latestOutputNodeSentRpc.current = null;
      return;
    }

    // Avoid recalling if it's not necessary.
    if (latestOutputNodeSentRpc.current !== null &&
      compareUint8Array(outputNode.toBinary(), latestOutputNodeSentRpc.current.toBinary())) {
      return;
    }

    const { outputList } = createOutputs(
      outputNode,
      outputNodes,
      simParamStripped,
      entityGroupData,
      false,
      false,
      [],
    );
    const outputFromList = outputList[0];
    latestOutputNodeSentRpc.current = outputNode;
    const req = new frontendpb.OutputDirectionRequest({
      projectId,
      output: outputFromList,
      clientParams: simParamStripped,
    });
    const generationSent = generation.current;
    rpc.callRetry('OutputDirection', rpc.client.outputDirection, req).then((reply) => {
      // Only set the direction if the generation is the same as the one sent since the rpc is
      // called async.
      if (generation.current === generationSent) {
        if (reply.direction) {
          setDirection([reply.direction.x, reply.direction.y, reply.direction.z]);
        }
        if (reply.momentCenter) {
          setMomentCenter([reply.momentCenter.x, reply.momentCenter.y, reply.momentCenter.z]);
        }
      }
    }).catch((err) => {
      logger.error(err);
    });
  }, [
    canComputeActDiskDirection,
    entityGroupData,
    isActuatorDiskOutput,
    outputNode,
    outputNodeWarnings.length,
    outputNodes,
    projectId,
    showWarning,
    simParamStripped,
  ]);

  // Takes care of updating the arrow state.
  useEffect(() => {
    if (direction === undefined || center === undefined) {
      setLcvisArrowState({ active: false, scale: 1 });
      return;
    }
    if (isMomentOutput && momentCenter === undefined) {
      setLcvisArrowState({ active: false, scale: 1 });
      return;
    }
    const startCoordinates = isMomentOutput ? momentCenter! : center;
    setLcvisArrowState({
      active: true,
      scale: scale || 1,
      startCoordinates: [startCoordinates[0], startCoordinates[1], startCoordinates[2]],
      endCoordinates: [direction[0], direction[1], direction[2]],
    });
  }, [direction, center, scale, setLcvisArrowState, isMomentOutput, momentCenter]);

  return (
    <>
      <NodeSubselect
        id={`output-${idBase}-in`}
        independentSelection
        labels={['surfaces']}
        nodeFilter={nodeFilter}
        nodeIds={outputNode.inSurfaces}
        onChange={setSurfaceInIds}
        referenceNodeIds={[outputNode.id]}
        showNotFoundNodes
        title={titleA}
        visibleTreeNodeTypes={visibleNodeTypes}
      />
      {showWarning && (
        <div style={{ marginTop: '8px' }}>
          <SectionMessage
            level="warning"
            links={[
              { label: 'Add All', onClick: addAllMissingSurfaces },
            ]}
            onDismiss={dismissWarning}>
            {warningText}
          </SectionMessage>
        </div>
      )}
      {hasActuatorDiskError && (
        <div style={{ marginTop: '8px' }}>
          <SectionMessage level="warning">
            Actuator disk outputs must be connected to actuator disk surfaces.
          </SectionMessage>
        </div>
      )}
      {isDifference && (
        <NodeSubselect
          id={`output-${idBase}-out`}
          independentSelection
          labels={['surfaces']}
          nodeFilter={nodeFilterOutFilters}
          nodeIds={outputNode.outSurfaces}
          onChange={setSurfaceOutIds}
          referenceNodeIds={[outputNode.id]}
          showNotFoundNodes
          title={`OUT ${titleBase}`}
          visibleTreeNodeTypes={visibleNodeTypes}
        />
      )}
      <LabeledInput
        help="Select the calculation type for multiple surfaces"
        label="Calculation">
        <DataSelect
          asBlock
          disabled={readOnlyCalcType}
          onChange={(newType) => updateOutputNode((newOutput) => {
            newOutput.calcType = newType;
            if (newType !== feoutputpb.CalculationType.CALCULATION_DIFFERENCE) {
              newOutput.outSurfaces = [];
            }
          })}
          options={
            outputNode.nodeProps.case === 'force' ?
              calcForceOptions(false, outputNode.calcType) :
              calcIntegralOptions(false, outputNode.calcType)
          }
          readOnly={readOnlyCalcType}
          size="small"
        />
      </LabeledInput>
    </>
  );
}
