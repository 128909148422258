// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useCallback, useMemo, useRef } from 'react';

import cx from 'classnames';

import * as ProtoDescriptor from '../../../../ProtoDescriptor';
import { ParamScope } from '../../../../lib/ParamScope';
import { intVectorToArray, newIntArray, setAdVector3AxisWithIndex, setIntVector3AxisWithIndex } from '../../../../lib/Vector';
import { newAdFloat } from '../../../../lib/adUtils';
import assert from '../../../../lib/assert';
import { varSpecToParamState } from '../../../../lib/explorationUtils';
import { VECTOR3_BREAK_POINT_WIDTH, checkBreakpointVertical } from '../../../../lib/layoutUtils';
import { getLabelText } from '../../../../lib/rectilinearTable/util';
import useResizeObserver from '../../../../lib/useResizeObserver';
import * as basepb from '../../../../proto/base/base_pb';
import * as explorationpb from '../../../../proto/exploration/exploration_pb';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import useTableState from '../../../../recoil/tableState';
import { useStaticVolumes } from '../../../../recoil/volumes';
import { useSimulationParam } from '../../../../state/external/project/simulation/param';
import Form from '../../../Form';
import { useProjectContext } from '../../../context/ProjectContext';
import { ColumnOnlySelector } from '../../../controls/TableMapInput/ColumnOnlySelector';

import './ColumnForm.scss';

export interface ColumnFormProps {
  /** Param to be modified */
  param: ProtoDescriptor.Param;
  /** The exploration */
  exploration: explorationpb.Exploration;
  /** The variable being edited. */
  variable: explorationpb.Var;
  /** The param scope */
  paramScope: ParamScope,
  /** If readOnly, the dialog becomes readonly. onUpdate and onChange will not be called. */
  readOnly: boolean;
  /** Called after the variable spec has been updated. */
  onChange: (newVar: explorationpb.TableColumn) => void;
}

export function ColumnForm(props: ColumnFormProps) {
  // Props
  const { param, exploration, variable, paramScope, readOnly, onChange } = props;
  const policy = exploration.policy.case === 'custom' ? exploration.policy.value : undefined;
  assert(!!policy, 'Incorrect policy type selected.');

  // Context
  const { projectId, workflowId, jobId } = useProjectContext();

  // Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);

  // Hooks
  const ref = useRef<HTMLDivElement>(null);
  const domSize = useResizeObserver(ref, { refreshPeriod: 0 });

  const vertical = checkBreakpointVertical(undefined, domSize.width, VECTOR3_BREAK_POINT_WIDTH);
  const isVector = param.type === ProtoDescriptor.ParamType.VECTOR3;

  const selectedTableName = policy.table;
  const tableMap = exploration.tableReferences;
  const tableUrl = tableMap[selectedTableName]?.url;
  const tableProto = useTableState(projectId, tableUrl ?? null);

  const availableColumns: string[] = useMemo(() => {
    const header = tableProto?.header;
    if (!header) {
      return [];
    }
    return header.recordLabel.map(getLabelText);
  }, [tableProto]);

  // an array of selected table columns for this variable
  // if the variable is a vector, the array will have 3 elements, otherwise if the variable is a
  // scalar, the array will have 1 element
  // an undefined value means that the column is not selected
  // the values from the proto are off by 1 because a 0 represents an unselected column
  const selected: (number | undefined)[] = useMemo(() => {
    if (
      isVector &&
      variable.valueTyp.case === 'column' &&
      variable.valueTyp.value.selectedColumns.case === 'columnIndexes'
    ) {
      return intVectorToArray(variable.valueTyp.value.selectedColumns.value).map(
        (index) => (index === 0 ? undefined : index - 1),
      );
    }
    if (
      variable.valueTyp.case === 'column' &&
      variable.valueTyp.value.selectedColumns.case === 'columnIndex' &&
      variable.valueTyp.value.selectedColumns.value !== 0
    ) {
      return [variable.valueTyp.value.selectedColumns.value - 1];
    }
    return isVector ? Array(3).fill(undefined) : [undefined];
  }, [isVector, variable]);

  const createFloatVariable = useCallback((selectedColumn: number) => {
    if (!tableProto) {
      return;
    }
    const defaultValue =
      varSpecToParamState(variable.spec!, simParam, paramScope, geometryTags, staticVolumes)?.value;
    return new explorationpb.TableColumn({
      selectedColumns: { case: 'columnIndex', value: selectedColumn + 1 },
      table: selectedTableName,
      value: tableProto.record.map((record) => {
        const entry = record.entry[selectedColumn];
        return new explorationpb.Value({
          typ: {
            case: 'real',
            value: entry.type.case === 'adfloat' ? entry.type.value : newAdFloat(defaultValue),
          },
        });
      }),
    });
  }, [paramScope, selectedTableName, simParam, tableProto, variable, geometryTags, staticVolumes]);

  const createVectorVariable = useCallback((selectedColumn: number, selectedAxis: number) => {
    if (!tableProto) {
      // no table has been selected so we cannot link a column from it
      return;
    }
    const paramState =
      varSpecToParamState(variable.spec!, simParam, paramScope, geometryTags, staticVolumes);
    if (paramState === null) {
      // param state is null so we cannot get a default value
      return;
    }
    const rawDefaultValue = paramState.value as ProtoDescriptor.Vector3;
    const defaultValue = [rawDefaultValue.x, rawDefaultValue.y, rawDefaultValue.z];
    if (!defaultValue) {
      // default value is undefined so we cannot set a default value
      return;
    }
    const newVar = variable.valueTyp.case === 'column' ?
      variable.valueTyp.value : new explorationpb.TableColumn();
    newVar.table = selectedTableName;
    const newColIndexes = newVar.selectedColumns.case === 'columnIndexes' ?
      newVar.selectedColumns.value : newIntArray();

    setIntVector3AxisWithIndex(newColIndexes, selectedAxis, selectedColumn + 1);
    newVar.selectedColumns = {
      case: 'columnIndexes',
      value: newColIndexes,
    };

    const valueList = newVar.value;
    newVar.value = tableProto.record.map((record, rowIndex) => {
      const oldValue = valueList[rowIndex] ?? new explorationpb.Value();
      const newVector = oldValue.typ.case === 'vector3' ?
        oldValue.typ.value : new basepb.AdVector3();
      const entry = record.entry[selectedColumn];
      setAdVector3AxisWithIndex(
        newVector,
        selectedAxis,
        entry.type.case === 'adfloat' ? entry.type.value : defaultValue[selectedAxis],
      );
      return new explorationpb.Value({ typ: { case: 'vector3', value: newVector } });
    });

    return newVar;
  }, [paramScope, selectedTableName, simParam, tableProto, variable, geometryTags, staticVolumes]);

  // for a custom DOE table, the first column is always the index column which cannot be selected
  const highlightIndex = selected.map((index) => (index !== undefined ? index + 1 : undefined));

  const disabled = readOnly || selectedTableName === '';
  const disabledReason = selectedTableName === '' ?
    'No table has been selected for this exploration' : undefined;

  const onColumnSelect = (value: number, axis: number) => {
    if (isVector) {
      const newVar = createVectorVariable(value, axis);
      newVar && onChange(newVar);
    } else {
      const newVar = createFloatVariable(value);
      newVar && onChange(newVar);
    }
  };

  const onColumnDeselect = (axis: number) => {
    if (isVector) {
      const newVar = variable.valueTyp.case === 'column' ?
        variable.valueTyp.value : new explorationpb.TableColumn({ table: selectedTableName });
      const { selectedColumns } = newVar;
      const newColIndexes = selectedColumns.case === 'columnIndexes' ?
        selectedColumns.value : newIntArray();
      setIntVector3AxisWithIndex(newColIndexes, axis, 0);
      newVar.selectedColumns = {
        case: 'columnIndexes',
        value: newColIndexes,
      };
      onChange(newVar);
      return;
    }
    onChange(new explorationpb.TableColumn({ table: selectedTableName }));
  };

  const getAxisAdornment = (axis: number) => {
    switch (axis) {
      case 0:
        return 'x';
      case 1:
        return 'y';
      case 2:
        return 'z';
      default:
        return undefined;
    }
  };

  return (
    <Form.LabeledInput label="Value">
      <div className={cx('columnForm', 'inputGroup', { vertical })} ref={ref}>
        {selected?.map((_, axis) => (
          <ColumnOnlySelector
            axisAdornment={isVector ? getAxisAdornment(axis) : undefined}
            columns={availableColumns}
            disabled={disabled}
            disabledReason={disabledReason}
            explorationPreview={{ exploration, paramScope }}
            highlightIndex={highlightIndex?.[axis]}
            key={`column-form-selector-${getAxisAdornment(axis)}`}
            onChange={(value) => onColumnSelect(value, axis)}
            onUnlink={() => onColumnDeselect(axis)}
            param={param}
            selected={selected?.[axis]}
            tableName={selectedTableName}
            tableUrl={tableUrl}
          />
        ))}
      </div>
    </Form.LabeledInput>
  );
}
