import { useCallback } from 'react';

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from '../../../lib/browserStorage';
import { localStorageEffect } from '../../../lib/persist';

const AI_SIZE_STORAGE_KEY = 'assistantSideRailSize';
const CHAT_HEIGHT_STORAGE_KEY = 'chatMessagePanelHeight';

export const MIN_RAIL_WIDTH = 0;
export const MIN_EXPANDED_RAIL_WIDTH = 200;
export const DEFAULT_EXPANDED_RAIL_WIDTH = 350;
export const MAX_EXPANDED_RAIL_WIDTH = 500;

export const DEFAULT_MESSAGE_BOX_HEIGHT = 110;
export const MIN_MESSAGE_BOX_HEIGHT = 60;
export const MAX_MESSAGE_BOX_HEIGHT = 400;

const assistantSideRailWidth = atom<number>({
  key: AI_SIZE_STORAGE_KEY,
  default: getLocalStorageData(AI_SIZE_STORAGE_KEY, DEFAULT_EXPANDED_RAIL_WIDTH),
  effects: [
    localStorageEffect(AI_SIZE_STORAGE_KEY),
  ],
});

export const useAssistantSideRailWidth = () => useRecoilState(assistantSideRailWidth);
export const useSetAssistantSideRailWidth = () => useSetRecoilState(assistantSideRailWidth);
export const useAssistantSideRailWidthValue = () => useRecoilValue(assistantSideRailWidth);

export const useShowAssistant = () => {
  const setAssistantSideRailWidth = useSetRecoilState(assistantSideRailWidth);
  return useCallback(() => {
    setAssistantSideRailWidth(DEFAULT_EXPANDED_RAIL_WIDTH);
  }, [setAssistantSideRailWidth]);
};

const assistantMessagePanelHeight = atom<number>({
  key: CHAT_HEIGHT_STORAGE_KEY,
  default: getLocalStorageData(CHAT_HEIGHT_STORAGE_KEY, DEFAULT_MESSAGE_BOX_HEIGHT),
  effects: [localStorageEffect(CHAT_HEIGHT_STORAGE_KEY)],
});

export const useChatPanelHeight = () => useRecoilState(assistantMessagePanelHeight);
export const useSetChatPanelHeight = () => useSetRecoilState(assistantMessagePanelHeight);
