// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { noWait, selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import * as rpc from '../../lib/rpc';
import { isTestingEnv } from '../../lib/testing/utils';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import { selectedGeometryState } from '../selectedGeometry';
import { routeParamsState } from '../useRouteParams';
import { workflowIdsSelector, workflowMapSelector } from '../workflowState';

import { DEFAULT_SELECTED_FEATURE, geometrySelectedFeature, geometryState, onGeometryTabSelector } from './geometryState';
import { GeometryTags } from './geometryTagsObject';
import { geometryUsesTagsSelector } from './geometryUsesTags';

type RecoilKey = {
  projectId: string,
  workflowId: string,
  jobId: string,
};

const geometryTagsStateTesting = selectorFamily<
  GeometryTags,
  RecoilKey
>({
  key: 'geometryTags/testing',
  get: () => () => new GeometryTags(undefined),
  dangerouslyAllowMutability: true,
});

// List of geometries in a given project.
export const geometryTagsStateNonTesting = selectorFamily<
  GeometryTags,
  RecoilKey
>({
  key: 'geometryTags',
  get: (key: RecoilKey) => async ({ get }) => {
    const params = get(routeParamsState);
    const geometryId = params.geometryId || '';
    const [geoTab, geoState] = get(waitForAll([
      onGeometryTabSelector,
      geometryState({ projectId: key.projectId, geometryId }),
    ]));
    if (geoTab) {
      if (!geoState) {
        return new GeometryTags(undefined);
      }
      // In the geometry tab with a valid state, we don't want users to see tags while operations
      // are in progress or while users are looking at an arbitrary feature. Hence, we return empty
      // tags in such cases to avoid confussion and intricate behaviors.
      const geometrySelectedFeatureValue = get(geometrySelectedFeature(params.geometryId || ''));
      if (geometrySelectedFeatureValue === DEFAULT_SELECTED_FEATURE) {
        const geoUsesTags = get(noWait(geometryUsesTagsSelector(key)));
        if (geoUsesTags.state !== 'hasValue' || !geoUsesTags.contents) {
          return new GeometryTags(undefined);
        }
        return new GeometryTags(geoState.tags);
      }
      return new GeometryTags(undefined);
    }
    const selectedGeometry = get(selectedGeometryState(key));
    const geoUsesTags = get(geometryUsesTagsSelector(key));
    if (!geoUsesTags) {
      return new GeometryTags(undefined);
    }
    if (!selectedGeometry.geometryVersionId) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return get(geometryTagsStateSetupTab(key));
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return get(geometryTagsStateRpcCache({
      projectId: key.projectId,
      geometryId: selectedGeometry.geometryId,
      geometryVersionId: selectedGeometry.geometryVersionId,
    }));
  },
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

type RpcCache = {
  projectId: string,
  geometryId: string,
  geometryVersionId: string,
}

// Cache the setup tab geometry tags so that we don't have to fetch them constantly in the geometry
// tab.
const geometryTagsStateRpcCache = selectorFamily<
  GeometryTags,
  RpcCache
>({
  key: 'geometryTagsRpcCache',
  get: (keyCache: RpcCache) => async ({ get }) => {
    if (!keyCache.projectId || !keyCache.geometryId) {
      return new GeometryTags(undefined);
    }
    try {
      const response = await rpc.callRetryWithClient(
        rpc.clientGeometry,
        'GetTags',
        rpc.clientGeometry.getTags,
        new geometryservicepb.GetTagsRequest({
          geometryId: keyCache.geometryId,
          geometryVersionId: keyCache.geometryVersionId,
        }),
      );
      return new GeometryTags(response.tags);
    } catch (error) {
      console.error('Failed to get tags', error);
      return new GeometryTags(undefined);
    }
  },
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

const geometryTagsStateSetupTabNonTesting = selectorFamily<
  GeometryTags,
  RecoilKey
>({
  key: 'geometryTagsSetupTab',
  get: (key: RecoilKey) => async ({ get }) => {
    const { projectId } = key;
    const selectedGeometry = get(selectedGeometryState(key));
    if (!rpc.clientGeometry || !projectId || !selectedGeometry.geometryId) {
      return new GeometryTags(undefined);
    }
    return get(geometryTagsStateRpcCache({
      projectId,
      geometryId: selectedGeometry.geometryId,
      geometryVersionId: selectedGeometry.geometryVersionId,
    }));
  },
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

export const geometryTagsState = isTestingEnv() ?
  geometryTagsStateTesting : geometryTagsStateNonTesting;

export const geometryTagsStateSetupTab = isTestingEnv() ?
  geometryTagsStateTesting : geometryTagsStateSetupTabNonTesting;

export function useGeometryTags(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(geometryTagsState({ projectId, workflowId, jobId }));
}

// Maps a workflow ID to its corresponding geometry tags. It is supposed that one workflow is
// associated with a single topology.
export const geometryTagsAllWorkflowsState = selectorFamily<
  Map<string, GeometryTags>,
  string
>({
  key: 'geometryTagsAllWorkflows',
  get: (projectId: string) => async ({ get }) => {
    const map = new Map<string, GeometryTags>();
    const workflowIds = get(workflowIdsSelector(projectId));
    const workflowMap = get(workflowMapSelector({ projectId, workflowIds }));
    workflowIds.forEach((workflowId) => {
      const wf = workflowMap[workflowId];
      if (!wf) {
        return;
      }
      // We take whichever job since we suppose we are sharing the same topology.
      const jobIds = Object.keys(wf.job);
      if (jobIds.length === 0) {
        return;
      }
      const jobId = jobIds[0];
      const tags = get(geometryTagsState({ projectId, workflowId, jobId }));
      map.set(workflowId, tags);
    });
    return map;
  },
});

export function useGeometryTagsAllWorkflows(projectId: string) {
  return useRecoilValue(geometryTagsAllWorkflowsState(projectId));
}
