// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
} from 'recoil';

import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import * as basepb from '../../proto/base/base_pb';
import * as visualizationservicepb from '../../proto/visualizationservice/visualizationservice_pb';

const logger = new Logger('recoil/postProcessingExtract');

type RpcKey = {
  projectId: string;
}

type PostProcessingExtractList = {
  extracts: visualizationservicepb.PostProcessingExtract[];
  fetched: boolean
}

const getFromRpc = async (projectId: string) => {
  if (!rpc.clientVisualization) {
    return {
      extracts: [],
      fetched: false,
    };
  }
  try {
    const extracts = await rpc.clientVisualization.listPostProcessingExtracts(
      new visualizationservicepb.ListPostProcessingExtractsRequest({
        projectId,
      }),
    );
    return {
      extracts: extracts.extracts ?? [],
      fetched: true,
    };
  } catch (err) {
    logger.error('Error fetching post processing extracts', err);
    return {
      extracts: [],
      fetched: false,
    };
  }
};

const listPostProcessingExtractsRpc = selectorFamily<PostProcessingExtractList, RpcKey>({
  key: 'listPostProcessingExtractsRpc',
  get: ({ projectId }) => async () => (
    getFromRpc(projectId)
  ),
});

export const listPostProcessingExtractsState = atomFamily<PostProcessingExtractList, RpcKey>({
  key: 'listPostProcessingExtractsState',
  default: ({ projectId }) => listPostProcessingExtractsRpc({ projectId }),
});

export const useListPostProcessingExtracts = (projectId: string) => (
  useRecoilValue(listPostProcessingExtractsState({ projectId }))
);

// Used to activate the RPC poller to keep up-to-date with the status of the extracts.
export const postProcessingExtractsPollerAtom = atomFamily<boolean, string>({
  key: 'postProcessingExtractsPoller',
  default: false,
});

// Starts a poller to keep up-to-date with the status of the extracts. The poller only starts when
// there are active extracts and postProcessingExtractsPollerAtom is set to true. The poller
// automatically stops when no extract status is Active. To reactivate the poller, set the atom
// to true.
export const usePostProcessingExtractsPoller = (projectId: string) => {
  const poller = useRecoilValue(postProcessingExtractsPollerAtom(projectId));

  const callback = useRecoilCallback(({ set }) => async () => {
    const extracts = await getFromRpc(projectId);
    set(listPostProcessingExtractsState({ projectId }), extracts);
    const anyRunning = extracts.extracts.some((extract) => (
      extract.status?.typ === basepb.JobStatusType.Active
    ));
    if (!anyRunning) {
      set(postProcessingExtractsPollerAtom(projectId), false);
    }
  });
  useEffect(() => {
    if (!poller) {
      return;
    }
    callback().catch((err) => {
      logger.error('Error polling post processing extracts', err);
    });
    const interval = setInterval(callback, 5000);
    return () => clearInterval(interval);
  }, [poller, callback]);
};
