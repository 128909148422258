import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 12;
const baseHeight = 12;
export const ClientSideIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="client-side"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        d="M3.26448 4.52496V3.27504H4.53552C4.81152 3.27504 5.03544 3.05112 5.03544
           2.77512C5.03544 2.49912 4.81152 2.27496 4.53552 2.27496H2.76456C2.48856
           2.27496 2.2644 2.49912 2.2644 2.77512V4.52496C2.2644 4.80096 2.48856
           5.02512 2.76456 5.02512C3.04056 5.02512 3.26448 4.80096 3.26448 4.52496Z"
        fill={getColor(0)}
      />
      <path
        d="M8.7499 5.7499V4.49998C8.7499 4.22398 8.97406 4.00006 9.25006 4.00006C9.52606
           4.00006 9.74998 4.22398 9.74998 4.49998V6.25006C9.74998 6.52606 9.52606 6.74998
           9.25006 6.74998H7.4791C7.2031 6.74998 6.97894 6.52606 6.97894 6.25006C6.97894
           5.97406 7.2031 5.7499 7.4791 5.7499H8.7499Z"
        fill={getColor(0)}
      />
      <path
        clipRule="evenodd"
        d="M11.25 0H0.75C0.33744 0 0 0.33744 0 0.75V8.25C0 8.66256 0.33744 9 0.75
           9H4.31256L3.93744 10.5H3C2.58744 10.5 2.25 10.8374 2.25 11.25C2.25 11.6626
           2.58744 12 3 12H9C9.41256 12 9.75 11.6626 9.75 11.25C9.75 10.8374 9.41256
           10.5 9 10.5H8.06256L7.68744 9H11.25C11.6626 9 12 8.66256 12 8.25V0.75C12
           0.33744 11.6626 0 11.25 0ZM10.5 7.5H1.5V1.5H10.5V7.5Z"
        fill={getColor(0)}
        fillRule="evenodd"
      />

    </svg>
  );
});
