// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

// A node table type that affects behavior.
// Note: If new type is added, it should be included to 1 of the volumes/surfaces tables at the end
export enum NodeTableType {
  NONE,
}

export interface NodeTableIdentifier {
  type: NodeTableType;
  id?: string;
  index?: number;
}
