// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../../../lib/componentTypes/simulationTree';
import { parsePhysicsIdFromSubId } from '../../../../../lib/physicsUtils';
import { AddPhysicalModel } from '../../../SimulationNodeAddButtons';
import { TreeRow } from '../../../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const PhysicsFluidPhysicalModelContainerTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Inputs
  const physicsId = parsePhysicsIdFromSubId(node.id);

  return (
    <TreeRow
      {...props}
      addControl={<AddPhysicalModel physicsId={physicsId} />}
      forceUserCanEdit
      primaryIcon={getNodeTypeIcon(props.node.type)}
    />
  );
};
