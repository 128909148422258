import BaseError from '@/errors/BaseError';
import { ProjectDeniedStatus } from '@/state/external/project/sharing';

/**
 * Represents a problem with the current project.
 */
export default class ProjectError extends BaseError {
  // Public Properties
  public name = 'ProjectError';
  public projectId = '';
  public type = ProjectDeniedStatus.NONE;

  constructor(projectId: string, source?: Error) {
    let message;
    let type;
    if (source) {
      if (ProjectError.isProjectAccessDeniedError(source.message)) {
        message = 'You need permission to view this project.';
        type = ProjectDeniedStatus.NO_ACCESS;
      } else if (ProjectError.isProjectDeletedError(source.message)) {
        message = 'This project is no longer available.';
        type = ProjectDeniedStatus.DELETED;
      }
    }
    if (!message) {
      message = 'Failed to get project.';
    }
    if (!type) {
      type = ProjectDeniedStatus.NONE;
    }
    super(message, source);
    this.type = type;
    this.projectId = projectId;
  }

  // Private Static Methods
  private static isProjectAccessDeniedError(error: string) {
    return error.includes('requires role');
  }

  private static isProjectDeletedError(error: string) {
    return error.match(/project.* deleted/gi) ||
           error.match(/FROM project(\n|.)+no rows in result set/);
  }
}
