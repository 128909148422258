// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as ProtoDescriptor from '../ProtoDescriptor';
import * as simulationpb from '../proto/client/simulation_pb';

import { ParamScope, createParamScope } from './ParamScope';
import { newAdFloat } from './adUtils';
import assert from './assert';

const {
  ALUMINUM,
  COPPER,
  IRON,
  NICKEL,
  TITANIUM,
  CUSTOM_MATERIAL_SOLID,
  INVALID_MATERIAL_SOLID_PRESET,
} = simulationpb.MaterialSolidPreset;

// Function which checks that there only exists one preset whose conditional evaluates to true.
export function checkPresets(presets: Map<string, ProtoDescriptor.Cond>, paramScope: ParamScope) {
  let enabledPresets = 0;
  presets.forEach((cond, key) => {
    if (paramScope.isEnabled(cond)) {
      enabledPresets += 1;
    }
  });
  assert(enabledPresets === 1, 'Incompatible presets');
}

// Sets the parameters for each material preset.
export function applySolidMaterialPreset(
  material: simulationpb.MaterialSolid,
): boolean {
  /* eslint-disable-next-line id-length */
  const p = material;
  const value = p.materialSolidPreset;

  const paramScope = createParamScope(material, []);

  if (value === CUSTOM_MATERIAL_SOLID || value === INVALID_MATERIAL_SOLID_PRESET) {
    return true;
  }

  // BEGIN GENERATED CODE (see gen/presets.py)
  const presets = new Map<string, ProtoDescriptor.Cond>();
  presets.set(
    'SOLID_MATERIAL',
    {
      type: ProtoDescriptor.CondType.TRUE,
    },
  );
  checkPresets(presets, paramScope);
  if (paramScope.isEnabled(presets.get('SOLID_MATERIAL')!)) {
    if (value === ALUMINUM) {
      p.constantDensityValueSolid = newAdFloat(2698.0);
      p.specificHeatCpSolid = newAdFloat(900.0);
      p.thermalConductivityConstantSolid = newAdFloat(210.0);
    }
    if (value === COPPER) {
      p.constantDensityValueSolid = newAdFloat(8960.0);
      p.specificHeatCpSolid = newAdFloat(385.0);
      p.thermalConductivityConstantSolid = newAdFloat(385.0);
    }
    if (value === IRON) {
      p.constantDensityValueSolid = newAdFloat(7870.0);
      p.specificHeatCpSolid = newAdFloat(440.0);
      p.thermalConductivityConstantSolid = newAdFloat(76.0);
    }
    if (value === NICKEL) {
      p.constantDensityValueSolid = newAdFloat(8880.0);
      p.specificHeatCpSolid = newAdFloat(460.0);
      p.thermalConductivityConstantSolid = newAdFloat(61.0);
    }
    if (value === TITANIUM) {
      p.constantDensityValueSolid = newAdFloat(4500.0);
      p.specificHeatCpSolid = newAdFloat(528.0);
      p.thermalConductivityConstantSolid = newAdFloat(17.0);
    }
  }
  // END GENERATED CODE

  p.thermalConductivityTableData = '';

  return true;
}
