// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as ProtoDescriptor from '../../../../../ProtoDescriptor';
import {
  ParamGroupName,
  ParamName,
  paramDesc,
  paramGroupDesc,
} from '../../../../../SimulationParamDescriptor';
import { createParamScope } from '../../../../../lib/ParamScope';
import assert from '../../../../../lib/assert';
import { getMultiphysicsInterfaceName } from '../../../../../lib/multiphysicsInterfaceUtils';
import { SURFACE_NODE_TYPES, TAGS_NODE_TYPES } from '../../../../../lib/simulationTree/node';
import { useHeatBoundaryCondition } from '../../../../../model/hooks/useHeatBoundaryCondition';
import * as simulationpb from '../../../../../proto/client/simulation_pb';
import { useEnabledExperiments } from '../../../../../recoil/useExperimentConfig';
import { useSimulationParamScope } from '../../../../../state/external/project/simulation/paramScope';
import { CollapsibleNodePanel } from '../../../../Panel/CollapsibleNodePanel';
import { ParamForm, paramsToShow } from '../../../../ParamForm';
import Divider from '../../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../../Theme/commonStyles';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useSelectionContext } from '../../../../context/SelectionManager';
import { useCommonBoundaryCondition } from '../../../../hooks/subselect/useCommonBoundaryCondition';
import { useSimulationConfig } from '../../../../hooks/useSimulationConfig';
import { HeatRemoveParams, useHeatBCTabularData } from '../../../../hooks/useTabularData';
import { AttributesDisplay } from '../../../AttributesDisplay';
import { LabeledSection } from '../../../LabeledSection';
import { NodeLink } from '../../../NodeLink';
import { NodeSubselect } from '../../../NodeSubselect';
import PropertiesSection from '../../../PropertiesSection';

export const removeParams: string[] = [
  'HeatPhysicalBoundary',
  // tabular transient CHT params
  ...HeatRemoveParams,
];

const heatPhysicalBoundDesc = paramDesc[ParamName.HeatPhysicalBoundary];
const bcParamGroup = paramGroupDesc[ParamGroupName.BoundaryConditionsHeat];

interface BoundaryConditionDefinitionProps {
  nodeId: string;
}

const BoundaryConditionDefinition = (props: BoundaryConditionDefinitionProps) => {
  // == Props
  const { nodeId } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected heat boundary condition row (definition panel)');

  // == Recoil
  const { paramScope } = useSimulationConfig();

  // == Custom hooks
  const {
    boundaryCondition,
    getParamScope,
    replaceBoundaryCondition,
    isDependent,
  } = useHeatBoundaryCondition(projectId, workflowId, jobId, readOnly, nodeId);
  assert(!!boundaryCondition, 'No selected heat boundary condition (definition panel)');
  const { insertTabularElements } = useHeatBCTabularData(nodeId);

  // No fields are editable for isothermal BCs that are dependent on a coupling interface, so hide
  // this whole section.
  if (isDependent) {
    return null;
  }

  // == Data
  const bcParamScope = getParamScope(paramScope);

  return (
    <>
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Definition"
          nodeId={nodeId}
          panelName="definition">
          <div style={{ position: 'relative' }}>
            <ParamForm<simulationpb.BoundaryConditionsHeat>
              group={bcParamGroup}
              ignoreNestLevel
              insertElement={insertTabularElements}
              onUpdate={replaceBoundaryCondition}
              paramScope={bcParamScope}
              proto={boundaryCondition}
              readOnly={readOnly}
              removeParams={removeParams}
            />
          </div>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </>
  );
};

/**
 * A panel displaying the properties of a heat boundary condition
 * @param props
 * @returns
 */
export const PhysicsHeatBoundaryConditionPropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected heat boundary condition row');

  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // == Custom hooks
  const commonClasses = useCommonTreePropsStyles();
  const {
    boundaryCondition,
    isDependent,
    couplingInterface,
  } = useHeatBoundaryCondition(projectId, workflowId, jobId, readOnly, node.id);
  assert(!!boundaryCondition, 'No selected heat boundary condition');
  const {
    nodeIds,
    nodeFilter,
    setSurfacesIds,
  } = useCommonBoundaryCondition(projectId, workflowId, jobId, readOnly, node.id, isDependent);

  // == Data
  const physicalBoundary = boundaryCondition.heatPhysicalBoundary;
  const boundaryLabel = (heatPhysicalBoundDesc as ProtoDescriptor.MultipleChoiceParam).choices.find(
    (choice) => choice.enumNumber === physicalBoundary,
  )?.text;
  const bcParamScope = createParamScope(boundaryCondition, experimentConfig, paramScope);
  // Get the list of params that ParamForm would show.  If it's empty, don't show the collapsible
  // panel
  const formParams = paramsToShow(bcParamScope, bcParamGroup, removeParams);

  return (
    <div className={commonClasses.properties}>
      {couplingInterface ? (
        <LabeledSection label="Multiphysics Interface">
          <NodeLink
            nodeIds={[couplingInterface.slidingInterfaceId]}
            text={getMultiphysicsInterfaceName(couplingInterface)}
          />
        </LabeledSection>
      ) : (
        <AttributesDisplay attributes={[{ label: 'Type', value: boundaryLabel }]} />
      )}
      <Divider />
      {!!formParams.length && (
        //  Only show the definition section if it contains options
        <BoundaryConditionDefinition nodeId={node.id} />
      )}
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Geometry"
          nodeId={node.id}
          panelName="surfaces">
          <NodeSubselect
            id={node.id}
            independentSelection
            labels={['surfaces']}
            nodeFilter={nodeFilter}
            nodeIds={nodeIds}
            onChange={setSurfacesIds}
            readOnly={readOnly || isDependent}
            referenceNodeIds={[node.id]}
            title="Surfaces"
            visibleTreeNodeTypes={[...SURFACE_NODE_TYPES, ...TAGS_NODE_TYPES]}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
