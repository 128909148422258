// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Code } from '@connectrpc/connect';
import {
  atomFamily,
  constSelector,
  selectorFamily,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
  waitForAll,
} from 'recoil';

import { meshMetadataFixture, meshUrlFixture } from '../lib/fixtures';
import { MeshMetadata, readMeshMetadata } from '../lib/mesh';
import { Logger } from '../lib/observability/logs';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import * as rpc from '../lib/rpc';
import * as status from '../lib/status';
import { isTestingEnv } from '../lib/testing/utils';
import { addRpcError } from '../lib/transientNotification';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

import { getGeoState } from './geometry/geometryState';
import { selectedGeometryState } from './selectedGeometry';
import { getAllMeshes, projectMeshListAtom } from './useProjectMeshList';

const logger = new Logger('recoil/meshState');

type RecoilKey = {
  projectId: string;
  meshUrl: string;
}

// Cache of mesh metadata reported by ReadFileIndex RPC.  We assume mesh files are immutable, so
// entries can stay in the cache as long as there is enough memory.
const meshMetadataSelectorRpc = selectorFamily<MeshMetadata | null, RecoilKey>({
  key: 'meshMetadataSelector',
  get: (key: RecoilKey) => async ({ get }) => {
    const geoState = getGeoState(get, key.projectId);
    if (geoState) {
      return {
        meshMetadata: geoState.metadata,
        solnMetadata: null,
      };
    }
    if (!key.meshUrl) {
      return null;
    }
    return readMeshMetadata(key.projectId, key.meshUrl);
  },
  dangerouslyAllowMutability: true,
});

const meshMetadataSelectorTesting = selectorFamily<MeshMetadata | null, RecoilKey>({
  key: 'meshMetadataSelector',
  get: () => meshMetadataFixture,
  dangerouslyAllowMutability: true,
});

export const meshMetadataSelector = isTestingEnv() ?
  meshMetadataSelectorTesting : meshMetadataSelectorRpc;

// Return the mesh metadata for the given mesh. It caches the metadata in recoil
// on the first read. If the state is not in recoil, issues an RPC to read the
// metadata.  If !meshUrl, it always returns null.
// This is async state; see src/recoil/README for more information.
export function useMeshMetadata(projectId: string, meshUrl: string): MeshMetadata | null {
  return useRecoilValue(meshMetadataSelector({ projectId, meshUrl }));
}

const meshKey = 'meshUrl';

const DEFAULT_URLS = new projectstatepb.MeshUrl();

const serialize = (val: projectstatepb.MeshUrl) => val.toBinary();

function deserialize(val: Uint8Array): projectstatepb.MeshUrl {
  return (val.length ?
    projectstatepb.MeshUrl.fromBinary(val) :
    DEFAULT_URLS.clone());
}

const meshUrlSelectorTesting = selectorFamily<projectstatepb.MeshUrl, string>({
  key: `${meshKey}/testing`,
  get: () => meshUrlFixture,
  dangerouslyAllowMutability: true,
});

const meshUrlSelectorRpc = selectorFamily<projectstatepb.MeshUrl, string>({
  key: `${meshKey}/rpc`,
  get: (projectId: string) => () => (
    persist.getProjectState(projectId, [meshKey], deserialize)
  ),
  dangerouslyAllowMutability: true,
});

const meshUrlSelector = isTestingEnv() ? meshUrlSelectorTesting : meshUrlSelectorRpc;

type GetGeometryTessellationRequestKey = {
  projectId: string;
  geometryId: string;
  geometryVersionId: string;
}

type GetGeometryTessellationReply = {
  reply: frontendpb.GetGeometryTessellationReply;
  rpcSuccess: boolean;
}

// Cache the response of the getGeometryTessellation RPC since it's immutable.
export const getGeometryTessellationRpc = selectorFamily<
  GetGeometryTessellationReply,
  GetGeometryTessellationRequestKey
>({
  key: `${meshKey}/getGeometryTessellationRpc`,
  get: (key: GetGeometryTessellationRequestKey) => async () => {
    try {
      const result = await rpc.callRetry(
        'getGeometryTessellation',
        rpc.client.getGeometryTessellation,
        new frontendpb.GetGeometryTessellationRequest({
          projectId: key.projectId,
          geometryId: key.geometryId,
          geometryVersionId: key.geometryVersionId,
        }),
        true,
        // Intentionally, do not show toasts, an error here may be fine.
        false,
      );
      return {
        reply: result,
        rpcSuccess: true,
      };
    } catch (err) {
      // This can happen if the opening a project through the SDK or if the geometry tessellation
      // is being generated. In that case the error is NotFound.
      const grpcErr = status.getGrpcError(err);
      if (!grpcErr) {
        logger.error('Failed to get geometry tessellation', err);
        addRpcError('Failed to get geometry tessellation', err);
      } else if (grpcErr.code !== Code.NotFound) {
        logger.error('Failed to get geometry tessellation', err);
        addRpcError('Failed to get geometry tessellation', err);
      }
      return {
        reply: new frontendpb.GetGeometryTessellationReply(),
        rpcSuccess: false,
      };
    }
  },
});

// The persisted mesh URL for a project.
export const meshUrlState = atomFamily<projectstatepb.MeshUrl, string>({
  key: 'meshUrl',
  default: selectorFamily<projectstatepb.MeshUrl, string>({
    key: `${meshKey}/default`,
    get: (projectId: string) => async ({ get }) => {
      const [
        selectedGeometry,
        meshUrl,
        listMeshes,
      ] = get(waitForAll([
        selectedGeometryState({ projectId, workflowId: '', jobId: '' }),
        meshUrlSelector(projectId),
        projectMeshListAtom(projectId),
      ]));
      if (!selectedGeometry.geometryId) {
        // This happens when creating an SDK project from an external mesh. We take the first mesh
        // in the list. This can also happen if a user uploads a mesh, closes the tab and comes
        // back. We do not have a valid mesh URL state.
        if (!meshUrl.url && !meshUrl.mesh && !meshUrl.meshId) {
          // List meshes filters out some meshes that we want to use here.
          const meshes = await getAllMeshes(projectId);
          // Include Unknown as external meshes since we allow uploading native lcmesh files.
          const meshExternal = meshes.find((msh) => (
            msh.status === frontendpb.Mesh_MeshStatus.COMPLETED &&
            (msh.meshOrigin === frontendpb.Mesh_MeshOrigin.CONVERTED ||
              msh.meshOrigin === frontendpb.Mesh_MeshOrigin.UNKNOWN)));
          const meshGenerated = meshes.sort((mA, mB) => (
            Number((mB.createTime?.seconds || BigInt(0)) - (mA.createTime?.seconds || BigInt(0)))
          )).find((msh) => (
            msh.status === frontendpb.Mesh_MeshStatus.COMPLETED &&
            msh.meshOrigin === frontendpb.Mesh_MeshOrigin.USER_GENERATED));
          // We found a user-generated mesh with a geometry version associated to it but that
          // geometry version is not selected in the setup workflow. Ask the user to do it first.
          if (meshGenerated?.geometryVersionId) {
            logger.error('No geometry selected', meshGenerated);
            addRpcError('Please load the geometry first', new Error('No geometry loaded'));
            return meshUrl;
          }
          // We found an external mesh, use it.
          if (meshExternal) {
            return new projectstatepb.MeshUrl({
              meshId: meshExternal.id,
              geometry: meshExternal.meshUrl,
              mesh: meshExternal.meshUrl,
              url: meshExternal.uploadUrl,
              activeType: projectstatepb.UrlType.MESH,
            });
          }
        }

        // The mesh may have been deleted, set the default value in that case. We cannot show a
        // geometry because there's none.
        if (meshUrl.meshId) {
          const foundMesh = listMeshes.find((mData) => mData.id === meshUrl.meshId);
          if (!foundMesh) {
            return DEFAULT_URLS;
          }
        }

        // Before igeo or when importing a mesh, it's tough to make this idempotentish since we do
        // not have a concept of geometry. So we need to base the decision on the history of
        // operations done on meshUrl by the frontend clients.
        return meshUrl;
      }
      // Idempotent version of meshUrl which does not depend on the history of operations done by
      // the frontend clients besides the activeType setting.
      const meshUrlRpc = meshUrl.clone();
      const resp = get(getGeometryTessellationRpc({
        projectId,
        geometryId: selectedGeometry.geometryId,
        geometryVersionId: selectedGeometry.geometryVersionId,
      }));
      if (resp.rpcSuccess) {
        meshUrlRpc.url = resp.reply.geometryUrl;
        meshUrlRpc.geometry = resp.reply.tessellationUrl;
      }
      if (!meshUrlRpc.meshId) {
        meshUrlRpc.activeType = projectstatepb.UrlType.GEOMETRY;
        return meshUrlRpc;
      }
      const mesh = listMeshes.find((mData) => mData.id === meshUrl.meshId);
      if (mesh) {
        meshUrlRpc.mesh = mesh.meshUrl;
      } else {
        // Maybe the mesh was deleted, restart the meshUrl state to something that makes sense.
        meshUrlRpc.meshId = '';
        meshUrlRpc.mesh = '';
        meshUrlRpc.activeType = projectstatepb.UrlType.GEOMETRY;
      }
      return meshUrlRpc;
    },
  }),
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, meshKey, deserialize, serialize),
  ],
  dangerouslyAllowMutability: true,
});

// Return [mesh URL used in the setup page, setter for the mesh URL].
// This is async state; see src/recoil/README for more information.
export const useMeshUrlState = (projectId: string) => useRecoilState(meshUrlState(projectId));
// Return the setter for a project's meshUrl.
export const useSetMeshUrlState = (projectId: string) => (
  useSetRecoilState(meshUrlState(projectId))
);

export const useRefreshMeshUrlState = (projectId: string) => (
  useRecoilRefresher_UNSTABLE(meshUrlState(projectId))
);

export function getActiveUrl(meshUrl: projectstatepb.MeshUrl) {
  const activeUrl = meshUrl.activeType === projectstatepb.UrlType.GEOMETRY ?
    meshUrl.geometry : meshUrl.mesh;

  // Use preview if other URLs are not available.
  return activeUrl || meshUrl.preview;
}

/**
 * Get the base file url from the meshUrl state. It points to the either a mesh file or a cad file
 * depending on what was uploaded.
 * @param meshUrl meshUrl state
 * @returns url
 */
export function getBaseFileUrl(meshUrl: projectstatepb.MeshUrl) {
  return meshUrl.url;
}

// Returns null if the hook is used with projectId = ''. Returns true or false for actual projects.
export const useIsMeshReady = (projectId: string) => {
  const meshUrl = useRecoilValueLoadable(projectId ? meshUrlState(projectId) : constSelector(null));
  const metaUrl = meshUrl.state === 'hasValue' ? meshUrl.contents?.mesh ||
    meshUrl.contents?.geometry : undefined;
  const meshSelector = metaUrl ?
    meshMetadataSelector({ projectId, meshUrl: metaUrl }) :
    constSelector(false);
  const value = useRecoilValueLoadable<MeshMetadata | null | boolean>(
    projectId ? (meshSelector) : constSelector(null),
  );
  if (value.state === 'hasValue' && value.contents) {
    return true;
  }
  if (value.state === 'loading') {
    return false;
  }
  return value.contents;
};
