// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo, useRef, useState } from 'react';

import cx from 'classnames';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { deleteTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useGeometryState } from '../../../recoil/geometry/geometryState';
import { useProjectContext } from '../../context/ProjectContext';
import { useDeleteGeometryModification } from '../../hooks/useInteractiveGeometry';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

import { CommonMenu } from '@/components/Menu/CommonMenu';
import { CollapsiblePanel } from '@/components/Panel/CollapsiblePanel';
import { createStyles, makeStyles } from '@/components/Theme';
import { useCommonTreeRowStyles } from '@/components/Theme/commonStyles';
import { useSelectionContext } from '@/components/context/SelectionManager';
import { getAdValue } from '@/lib/adUtils';
import { colors } from '@/lib/designSystem';
import { SelectionAction } from '@/lib/selectionUtils';
import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';
import * as geometrypb from '@/proto/geometry/geometry_pb';
import { useSetPropertiesPanelVisible } from '@/recoil/propertiesPanel';

export const useGeometryImportRowStyles = makeStyles(
  () => createStyles({
    root: {
      padding: '0 27px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    content: {
      border: '0',
      width: 'calc(100% + 26px)',
      marginTop: '-8px',
      marginLeft: '-25px',
      textAlign: 'left',
      fontFamily: 'inherit',
      paddingLeft: '39px',
      backgroundColor: 'transparent',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    parameter: {
      color: colors.neutral800,
      padding: '4px 0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'GeometryImportRowStyles' },
);

const GeometryImportRow = ({ feature, ...props }: SimulationRowProps & {
  feature: geometrypb.Import;
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [contextMenuActive, setContextMenuActive] = useState(false);
  const treeClasses = useCommonTreeRowStyles();
  const rowClasses = useGeometryImportRowStyles();
  const setPropertiesPanelVisible = useSetPropertiesPanelVisible();
  const rowRef = useRef<HTMLDivElement>(null);
  const { modifySelection, selectedNodeIds } = useSelectionContext();
  const { deleteRow, disabledDeleteReason } = useDeleteGeometryModification(props.node.id);

  return (
    <>
      <div
        className={cx(
          treeClasses.rowRoot,
          selectedNodeIds.includes(props.node.id) && treeClasses.highlighted,
          rowClasses.root,
        )}
        onContextMenu={(event) => {
          event.preventDefault();
          setContextMenuActive(true);
        }}
        ref={rowRef}>
        <CollapsiblePanel
          collapsed={collapsed}
          heading={props.node.name}
          key={`${collapsed}`}
          onToggle={setCollapsed}
          whiteHeader>
          <button
            className={rowClasses.content}
            onClick={() => {
              modifySelection({
                action: SelectionAction.OVERWRITE,
                modificationIds: [props.node.id],
              });
              setPropertiesPanelVisible(true);
            }}
            type="button">
            {feature.parameters.map((parameter) => {
              const label = `${parameter.owner ? `${parameter.owner}: ` : ''}${parameter.name}`;
              const value = parameter.type.case === 'float' ?
                getAdValue(parameter.type.value.adType) : '-';

              return (
                <div className={rowClasses.parameter} key={label}>
                  {label}: {value}
                </div>
              );
            })}
          </button>
        </CollapsiblePanel>
      </div>
      {contextMenuActive && (
        <CommonMenu
          anchorEl={rowRef.current}
          closeOnSelect
          menuItems={[
            deleteTreeNodeMenuItem(deleteRow, !!disabledDeleteReason, disabledDeleteReason),
          ]}
          onClose={() => setContextMenuActive(false)}
          open
          position="right-down"
        />
      )}
    </>
  );
};

export const GeometryModification = (props: SimulationRowProps) => {
  const { node } = props;
  const { projectId, geometryId } = useProjectContext();
  const geoState = useGeometryState(projectId, geometryId);
  const { deleteRow, disabledDeleteReason } = useDeleteGeometryModification(props.node.id);
  const renaming = useNodeRenaming(node);
  const featureIndex = useMemo(() => (
    geoState?.geometryFeatures.findIndex((item) => item.id === props.node.id)
  ), [geoState?.geometryFeatures, props.node.id]);
  // featureIndex is only undefined if geoState is undefined.
  const feature = geoState?.geometryFeatures[featureIndex!];

  const getContextMenuItems = () => [
    deleteTreeNodeMenuItem(deleteRow, !!disabledDeleteReason, disabledDeleteReason),
  ];

  const serverFeature = geoState?.geometryFeaturesServer.at(featureIndex!) ?? feature;

  if (
    serverFeature?.operation.case === 'import' &&
    serverFeature.operation.value.parameters.length > 0
  ) {
    return <GeometryImportRow feature={serverFeature.operation.value} {...props} />;
  }

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={getNodeTypeIcon(props.node.type, { feature })}
      renaming={renaming}
    />
  );
};
