import { CommonMenuItem, CommonMenuListItem } from './componentTypes/menu';
import { IconName } from './componentTypes/svgIcon';

type OptionalMenuItem = {
  itemConfig: CommonMenuListItem;
  shouldShow: boolean;
}

// Given a list of OptionalMenuItems, return a list of the ones that should be shown.
export function filteredMenuItems(
  items: OptionalMenuItem[],
): CommonMenuListItem[] {
  return items.filter((item) => item.shouldShow).map((item) => item.itemConfig);
}

// Given a list of menu items, return a list of the same items but with the given icon added to the
// first one.
export function leadWithIcon(iconName: IconName, items: CommonMenuListItem[]) {
  return items.length === 0 ? items : [
    {
      ...items[0],
      startIcon: { name: iconName },
    },
    ...items.slice(1),
  ];
}

// Given a list of OptionalMenuItems, return a list of the ones that should be shown, plus add the
// given icon to the first one.
export function filteredWithLeadIcon(
  iconName: IconName,
  items: OptionalMenuItem[],
): CommonMenuListItem[] {
  return leadWithIcon(iconName, filteredMenuItems(items));
}

// Concatenate and flatten lists of menu items, adding separators between non-empty ones
export function assembleMenuSections(...sections: CommonMenuItem[][]) {
  const nonEmptySections = sections.filter((section) => section.length > 0);
  const finalItems: CommonMenuItem[] = [];

  nonEmptySections.forEach((section) => {
    finalItems.push(...section);
    finalItems.push({ separator: true });
  });

  return finalItems.slice(0, -1);
}
