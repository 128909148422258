// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as rpc from '../rpc';

import { RpcQueue } from './RpcQueue';

class JobConfigRequestRpcQueue extends RpcQueue<
  frontendpb.JobConfigRequest,
  frontendpb.JobConfigBatchRequest,
  frontendpb.JobConfigReply,
  frontendpb.JobConfigBatchReply
> {
  packData(
    configRequests: frontendpb.JobConfigRequest[],
    req: frontendpb.JobConfigBatchRequest,
  ): void {
    configRequests.forEach((request) => {
      req.configs.push(request);
    });
  }

  unpackData(index: number, reply: frontendpb.JobConfigBatchReply):
  frontendpb.JobConfigReply {
    return reply.configs[index];
  }

  getDataSize(req: frontendpb.JobConfigBatchRequest): number {
    return req.configs.length;
  }

  sendRequest(
    req: frontendpb.JobConfigBatchRequest,
  ): Promise<frontendpb.JobConfigBatchReply> {
    return rpc.callRetry(
      'jobConfigBatch',
      rpc.client.jobConfigBatch,
      req,
    );
  }

  clearData(req: frontendpb.JobConfigBatchRequest): void {
    req.configs = [];
  }
}

export default JobConfigRequestRpcQueue;
