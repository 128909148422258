// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { useRecoilCallback } from 'recoil';

import { cameraNodeId } from '../../lib/cameraUtils';
import { RecoilProjectKey } from '../../lib/persist';
import { CameraAccess } from '../../proto/frontend/frontend_pb';
import { saveCameraCallback } from '../../recoil/cameraState';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useGeometryStatus } from '../hooks/useGeometryStatus';
import { useNonEditableReason } from '../hooks/useNonEditableReason';

import { AddNodeMenuButton } from './AddNodeButton';

import environmentState from '@/state/environment';

export const AddCameraButton = () => {
  // == Contexts
  const { viewState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil
  const saveCamera = useRecoilCallback(saveCameraCallback);

  // == Custom hooks
  const { working } = useGeometryStatus();
  const nonEditableReason = useNonEditableReason('add Cameras', '', { showResult: false });

  const cameraKey: RecoilProjectKey = { projectId, workflowId, jobId };
  const handleSaveCamera = async (cameraAccess: CameraAccess) => {
    const newCamera = await saveCamera(cameraKey, cameraAccess);
    if (newCamera) {
      // Only highlight, but do not scroll to the node. This will be handled later when the
      // transient node is replaced with the permanent node with the real id.
      setSelection([cameraNodeId(newCamera.cameraId)]);
    }
  };

  const lcvisReady = environmentState.use.lcvisReady;
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const isDisabled = (lcvisEnabled ? !lcvisReady : !viewState);

  return (
    <AddNodeMenuButton
      disabled={working || !!nonEditableReason}
      menuItems={[
        {
          description: 'Save to local project',
          disabledReason: 'The camera view can be saved only after the visualizer is connected',
          disabled: isDisabled,
          label: 'Local',
          onClick: () => handleSaveCamera(CameraAccess.LOCAL),
        },
        {
          description: 'Make camera reusable across projects',
          disabledReason: 'The camera view can be saved only after the visualizer is connected',
          disabled: isDisabled,
          label: 'Global',
          onClick: () => handleSaveCamera(CameraAccess.GLOBAL),
        },
      ]}
      tooltip={nonEditableReason}
    />
  );
};
