// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

// Accepts a string containing an arbitrary amount of {1}, {2}, etc. tokens and
// an array of strings which will be used to replace the tokesn.
// The tokens must start from {1} and be in ascending order.
export const parseError = (error: string, args: string[]) => {
  // Regular expression to match {1}, {2}, etc. in the error string.
  const tokenRegExp = /{(\d+)}/g;

  // Replace each token in the input string with the corresponding value from the array.
  return error.replace(tokenRegExp, (match, index) => args[parseInt(index, 10) - 1] || match);
};

export const ERRORS = {
  Default: 'An error occurred. Please try again.',
  NewProject: 'Creating new project failed',
  EditProject: 'Updating {1} failed',
  RemoveAccessProject: 'Leaving {1} failed.',
  DeleteProject: 'Deleting {1} failed.',
  CreateLibraryItem: 'Item could not be saved to Library.',
  UpdateLibraryItem: 'Library item could not be updated.',
  ImportLibraryItem: 'Library item could not be imported.',
  // Suppress StartUpload error toasts, since we handle errors in that RPC
  // at a higher level.
  StartUpload: '',
  // 'CopyProject': 'Copying {1} failed',
};

// Logs the original error and returns a generic message.
// This will be updated to return a more specific message in the future.
export const getUserErrorMessage = (err: Error, customMessage?: string) => {
  // Log the original error for debugging
  if (err.message) {
    console.error('Original error:', err.message);
  }

  // Return custom message if provided, otherwise return generic message
  return customMessage || ERRORS.Default;
};
