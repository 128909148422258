// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Autocomplete, Popper, TextField } from '@mui/material';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { SetterOrUpdater, useSetRecoilState } from 'recoil';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { EMPTY_VALUE } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { getLcUserId } from '../../lib/jwt';
import { locationOriginRoute, projectLink, projectsLink } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';
import { useUserHasProjectRole } from '../../lib/projectRoles';
import { LUMINARY_SUPPORT_USER_LABEL, isSupportAclEntry, newProjectAclLCEntry } from '../../lib/projectShareUtils';
import * as rpc from '../../lib/rpc';
import { stringifyError } from '../../lib/status';
import { addRpcError } from '../../lib/transientNotification';
import useButtonMenu from '../../lib/useButtonMenu';
import { getFullName } from '../../lib/user';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as supportpb from '../../proto/support/support_pb';
import { useGeometryList } from '../../recoil/geometry/geometryListState';
import { useIsMeshReady } from '../../recoil/meshState';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useCurrentUser } from '../../recoil/useAccountInfo';
import useProjectMetadata, { projectMetadataHasSupportAclEntry } from '../../recoil/useProjectMetadata';
import { useListSupportUsers } from '../../state/external/platform/supportUsers';
import { useSetProjectShareDialog } from '../../state/external/project/sharing';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { ActionButton } from '../Button/ActionButton';
import { TextInput } from '../Form/TextInput';
import { CommonMenu } from '../Menu/CommonMenu';
import { createStyles, makeStyles } from '../Theme';
import { CaretDownIcon } from '../svg/CaretDownIcon';
import Tag from '../treePanel/Tag';
import { AutoCollapsingMessage } from '../visual/AutoCollapsingMessage';
import { CircularLoader } from '../visual/CircularLoader';
import { Propeller } from '../visual/Propeller';
import { UserCard } from '../visual/UserCard';

import { Dialog } from './Base';

import { projectListState } from '@/recoil/state';

const logger = new Logger('ProjectShare');

const PROJECT_OWNER_ROLE = 'admin';
const PROJECT_VIEWER_ROLE = 'reader';

const PROJECT_OWNER_LABEL = 'Owner';
const PROJECT_VIEWER_LABEL = 'Viewer';

const LUMINARY_SUPPORT_PSEUDO_USER_ID = 'luminarycloud_support_id';

export interface ShareDialogProps {
  // Whether the dialog is open.
  open: boolean;
  // Called when the dialog is closed
  onCancel: () => void;
  // The Project Id.
  projectId: string;
  // Account info
  accountInfo: frontendpb.AccountInfoReply | null;
}

export const useAutocompleteStyles = makeStyles(
  () => createStyles({
    root: {
      // Material is overwriting our overwrites, so we need these classes to make a higher
      // selector priority. We can only avoid them if we use !important declaration.
      '& > .MuiFormControl-root > .MuiOutlinedInput-root': {
        padding: '4px',
      },
      '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
        minWidth: '120px',
      },
    },
    listbox: {
      '& .MuiAutocomplete-option': {
        paddingLeft: '16px',
        paddingRight: '16px',
        height: '50px',

        '&.isSupport': {
          borderBottom: `1px solid ${colors.neutral300}`,
        },
      },
    },
    inputRoot: {
      backgroundColor: colors.surfaceDark3,
      borderRadius: '4px',
      fontSize: '14px',
      display: 'flex',
      gap: '4px',
    },
    input: {
      height: '20px',
    },
    paper: {
      border: `1px solid ${colors.neutral550}`,
      borderRadius: '6px',
    },
    popper: {
      width: '460px',
    },
  }),
  { name: 'ProjectShareAutocomplete' },
);

const useStyles = makeStyles(
  () => createStyles({
    emptyProjectText: {
      color: colors.lowEmphasisText,
    },
    bodyText: {
      color: colors.lowEmphasisText,
      fontSize: '14px',
    },
    addUsersRow: {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
      marginTop: '32px',
    },
    autocomplete: {
      flex: 1,
    },
    usersListTitle: {
      color: colors.lowEmphasisText,
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '16px',
      marginTop: '24px',
    },
    usersList: {
      marginTop: '12px',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    },
    userRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '8px',
    },
    label: {
      color: colors.neutral900,
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '16px',
    },
    userRoleMenuButton: {
      marginRight: '8px',
    },
    supportInfo: {
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    supportInfoTitle: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      color: colors.highEmphasisText,
    },
  }),
  { name: 'ProjectShareDialog' },
);

interface UserWithAccessRowProps {
  setProjectList: SetterOrUpdater<frontendpb.ListProjectsReply | null>;
  isLoadingUser?: boolean;
  viewerIsOwner?: boolean
  projectId: string;
  projectInfo: frontendpb.GetProjectReply | null;
  user: frontendpb.AccountInfoReply_UserInfo;
  userRoles?: string[];
}

const isSupportUser = (user: frontendpb.AccountInfoReply_UserInfo) => (
  user.lcUserId === LUMINARY_SUPPORT_PSEUDO_USER_ID &&
  user.givenName === LUMINARY_SUPPORT_USER_LABEL
);

// This is used for the autocomplete to generate a support pseudo user
const getSupportItem = () => {
  const support = new frontendpb.AccountInfoReply_UserInfo({
    lcUserId: LUMINARY_SUPPORT_PSEUDO_USER_ID,
    givenName: LUMINARY_SUPPORT_USER_LABEL,
  });
  return support;
};

const RemoveAccessConfirmContent = (props: {
  removeUser: frontendpb.AccountInfoReply_UserInfo, // the user we are removing the access for
  userId: string, // the user doing the op
  projectName: string,
}) => {
  const { removeUser, userId, projectName } = props;

  if (isSupportUser(removeUser)) {
    return (
      <>
        <p>
          Before removing Luminary Support from your project, make sure all project issues
          have been resolved. This action can not be undone.
        </p>
        <p>
          Luminary Support will no longer have access to this project unless you share it again.
        </p>
      </>
    );
  }

  if (userId === removeUser.lcUserId) {
    return (
      <div>
        Are you sure you want to remove your access to this shared project
        <span className="fontWeight700"> {projectName} </span>? You will
        no longer be able to access it unless the owner re-shares it with you.
      </div>
    );
  }

  return (
    <div>
      Are you sure you want to
      remove {getFullName(removeUser)}?
      They may not be able to access this project anymore.
    </div>
  );
};

// This represents a single user who already has access
const UserWithAccessRow = (props: UserWithAccessRowProps) => {
  // Props
  const { isLoadingUser, viewerIsOwner, projectId, projectInfo, user, userRoles, setProjectList } =
  props;

  // When we remove the access for some user and the RPC finishes, the project gets refreshed after
  // a few seconds and not immediately which causes the removed user to keep showing in the UI.
  // We'll save the removed state in a pending variable so that we can show the user with loading
  // until the project data is updated.
  const [userRemovedPending, setUserRemovedPending] = useState(false);

  // Derived state
  const projectName = projectInfo ? projectInfo.summary!.name : projectId;

  // Hooks
  const { anchorEl: anchorRoleMenuEl, anchorRef, onClickButton, onCloseMenu } = useButtonMenu();
  const navigate = useNavigate();
  const setConfirmStack = useSetConfirmations();
  const setProjectShareDialog = useSetProjectShareDialog();
  const classes = useStyles();

  // Data
  const userId = getLcUserId();

  const userRoleText = useMemo(() => {
    if (userRoles?.includes(PROJECT_OWNER_ROLE)) {
      return PROJECT_OWNER_LABEL;
    }
    if (userRoles?.includes(PROJECT_VIEWER_ROLE)) {
      return PROJECT_VIEWER_LABEL;
    }
    return EMPTY_VALUE;
  }, [userRoles]);

  // Handlers
  const handleRemoveAccess = useCallback(async () => {
    let isProjectPermissionUpdateSuccesfull = true;

    const req = new frontendpb.UpdateProjectPermissionRequest({
      projectId,
      aclUpdate: [
        newProjectAclLCEntry(user.lcUserId, [], isSupportUser(user)),
      ],
    });
    await rpc.client.updateProjectPermission(req).then(async () => {
      // If the removed user is the current user, redirect them to the projects list
      if (userId === user.lcUserId) {
        setProjectShareDialog({ open: false, projectId: '' });
        navigate(projectsLink());
      }

      // Mark the user as removed, so we can show as loading until the project updates
      setUserRemovedPending(true);
    }).catch((err: Error) => {
      isProjectPermissionUpdateSuccesfull = false;
      addRpcError('Error updating project permissions', err, { projectId, projectName });
    });
    if (isProjectPermissionUpdateSuccesfull) {
      setProjectList((prevValue) => ({
        ...prevValue,
        project: prevValue?.project.map((project) => (project.projectId === projectId ?
          {
            ...project,
            acl: project.acl.filter(
              (entry) => entry.subject?.id !== user.lcUserId,
            ),
          } :
          project)),
      }) as frontendpb.ListProjectsReply);
    }
  }, [projectId, user, setProjectList, userId, setProjectShareDialog, navigate, projectName]);

  // Queue confirmation dialog to remove project access for some user
  const queueRemoveAccess = useCallback(() => {
    pushConfirmation(setConfirmStack, {
      continueLabel: 'Remove Access',
      destructive: isSupportUser(user),
      onContinue: () => handleRemoveAccess(),
      title: isSupportUser(user) ? 'Luminary Support Access' : 'Remove Access',
      children: (
        <RemoveAccessConfirmContent projectName={projectName} removeUser={user} userId={userId} />
      ),
    });
  }, [setConfirmStack, handleRemoveAccess, user, userId, projectName]);

  // This should be wrapped with useMemo and handleRemoveAccess/queueRemoveAccess - with
  // useCallback or else the CommonMenu will become unclickable when it is re-rerendered.
  const userMenuItems: CommonMenuItem[] = useMemo(() => [
    {
      label: userRoleText,
      onClick: () => { },
      startIcon: { name: 'checkmark' },
    },
    { separator: true },
    {
      label: 'Remove access',
      help: userId === user.lcUserId ?
        'Permanently remove your access to this project' :
        'Permanently remove access to this project for the user',
      startIcon: { name: 'ringX' },
      destructive: true,
      // Project owners can remove everyone's access. Other users can only remove their own access.
      disabled: !(viewerIsOwner || userId === user.lcUserId),
      onClick: () => {
        queueRemoveAccess();
      },
    },
  ], [queueRemoveAccess, user, userId, userRoleText, viewerIsOwner]);

  // Users that are currently being added or removed are shown with a spinner.
  // Owner of the project is shown with a simple label.
  // Permanently added users are shown with a dropdown menu for editing.
  const renderUserDropdownOrSpinner = () => {
    if (isLoadingUser || userRemovedPending) {
      return <div data-locator="pendingUserLoading"><Propeller size={16} /></div>;
    }
    if (userRoles?.includes(PROJECT_OWNER_ROLE)) {
      return <div className={classes.label}>{PROJECT_OWNER_LABEL}</div>;
    }
    return (
      <div ref={anchorRef}>
        <ActionButton kind="minimal" onClick={(event) => onClickButton(event)} size="small">
          <span className={cx(classes.label, classes.userRoleMenuButton)}>
            {userRoleText}
          </span>
          <CaretDownIcon maxHeight={6} maxWidth={6} />
        </ActionButton>
        <CommonMenu
          anchorEl={anchorRoleMenuEl}
          closeOnSelect
          menuItems={userMenuItems}
          onClose={onCloseMenu}
          open={!!anchorRoleMenuEl}
        />
      </div>
    );
  };

  return (
    <div className={classes.userRow}>
      <UserCard
        email={user.email}
        isSupport={isSupportUser(user)}
        name={getFullName(user)}
      />
      <div>{renderUserDropdownOrSpinner()}</div>
    </div>
  );
};

export const ProjectShareDialog = (props: ShareDialogProps) => {
  const { accountInfo, onCancel, open, projectId } = props;

  // When we share the project to some user and the RPC finishes, the project gets refreshed after
  // a few seconds which causes the added user to not appear immediatelly in the UI.
  // We'll save this added user in a pending variable so that we can update the UI with the pending
  // user until the project refreshes and the updated users list becomes available.
  const [
    usersWithAccessPending,
    setUsersWithAccessPending,
  ] = useState<frontendpb.AccountInfoReply_UserInfo[]>([]);

  const classes = useStyles();
  const autocompleteClasses = useAutocompleteStyles();
  const url = locationOriginRoute(projectLink(projectId));
  const projectInfo = useProjectMetadata(projectId);
  const currentUser = useCurrentUser();
  const supportUsers = useListSupportUsers();
  const isMeshReady = useIsMeshReady(projectId);
  const geometryList = useGeometryList(projectId);
  const isGeometryPending = useIsGeometryPending(projectId);
  const viewerIsOwner = useUserHasProjectRole(projectInfo?.summary, PROJECT_OWNER_ROLE);

  // The users that are added to the autocomplete but the project is still not shared to them
  const [selectedUsers, setSelectedUsers] = useState<frontendpb.AccountInfoReply_UserInfo[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [supportDescription, setSupportDescription] = useState('');
  const [error, setError] = useState('');
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const trimmedSupportDescription = supportDescription.trim();
  const setProjectList = useSetRecoilState(projectListState);

  // Keep a {userId: [...permissions]} map with all the users that have access to the project
  const userRolesMap = useMemo(() => projectInfo?.summary?.acl!.reduce((obj, entry) => {
    const userId = entry.subject?.id;
    if (userId) {
      obj[userId] = entry.role;
    } else if (isSupportAclEntry(entry)) {
      obj[LUMINARY_SUPPORT_PSEUDO_USER_ID] = entry.role;
    }

    return obj;
  }, {} as Record<string, string[]>), [projectInfo]);

  // All active users in the account, except the users that already have access for the project.
  // This list will be used for the autocomplete menu when selecting new users.
  const usersWithoutAccess = useMemo(() => {
    if (!userRolesMap) {
      return [];
    }

    const users = (accountInfo?.user || []).filter((user) => (
      user.status === frontendpb.UserStatus.ENABLED && !userRolesMap[user.lcUserId]
    )).sort((a, b) => (
      a.givenName.localeCompare(b.givenName, undefined, { sensitivity: 'base' })
    ));

    // If the user is not shared with support yet, add a pseudo-user suport item to the list
    if (!projectMetadataHasSupportAclEntry(projectInfo)) {
      users.unshift(getSupportItem());
    }

    return users;
  }, [accountInfo, userRolesMap, projectInfo]);

  // Users with whom the project is currently shared. Project owner is always first.
  const usersWithAccess = useMemo(() => {
    if (!userRolesMap) {
      return [];
    }

    const users: frontendpb.AccountInfoReply_UserInfo[] = [];

    // If the user is shared with support, add a pseudo-user support item to the list
    if (userRolesMap[LUMINARY_SUPPORT_PSEUDO_USER_ID]) {
      users.push(getSupportItem());
    }

    projectInfo?.summary?.acl!.forEach((projectUser) => {
      const user = (accountInfo?.user || []).find((accountUser) => (
        accountUser.lcUserId === projectUser.subject?.id
      ));

      if (user) {
        // Put project owner (the admin) at the beginning of the list
        if (userRolesMap[user.lcUserId].includes(PROJECT_OWNER_ROLE)) {
          users.unshift(user);
        } else {
          users.push(user);
        }
      }
    });

    return users;
  }, [
    accountInfo,
    projectInfo,
    userRolesMap,
  ]);

  const supportItemSelected = useMemo(() => (
    selectedUsers.some((user) => isSupportUser(user))
  ), [selectedUsers]);

  const initializeForm = () => {
    // Remove the newly added users from the autocomplete field
    setSelectedUsers([]);
    setSupportDescription('');
    setError('');
  };

  const handleShare = async () => {
    setIsUpdating(true);
    let isProjectPermissionUpdateSuccesful = true;

    try {
      const req = new frontendpb.UpdateProjectPermissionRequest({
        projectId,
        // Get all users from the autocomplete and add them a 'reader' permission
        aclUpdate: selectedUsers.map(
          (user) => newProjectAclLCEntry(
            user.lcUserId,
            [PROJECT_VIEWER_ROLE],
            isSupportUser(user),
          ),
        ),
      });

      await rpc.client.updateProjectPermission(req).then(async () => {
        // Update the variable with pending users so that we can show the user with a spinner
        // until the actual project data refreshes.
        setUsersWithAccessPending((prevValue) => [
          ...prevValue,
          ...selectedUsers,
        ]);
      });

      if (supportItemSelected) {
        const supportReq = new supportpb.CreateSupportTicketRequest({
          description: `${trimmedSupportDescription}`,
          url: `${url}`,
        });
        await rpc.client.createSupportTicket(supportReq);
      }

      initializeForm();
    } catch (err) {
      isProjectPermissionUpdateSuccesful = false;
      let errorMessage = 'Error updating project permissions.';
      const match = err.message.match(/unknown user\s(\S+)/);
      const deniedUser = match && selectedUsers.find((user) => user.lcUserId === match[1]);
      if (
        currentUser &&
        supportUsers?.includes(currentUser.lcUserId) &&
        deniedUser &&
        !supportUsers.includes(deniedUser.lcUserId)
      ) {
        errorMessage += ` If this is a support project, this project can only be shared
          with other support users.`;
      }
      setError(errorMessage);
      logger.warn(errorMessage, stringifyError(err));
    }
    setIsUpdating(false);
    if (isProjectPermissionUpdateSuccesful) {
      setProjectList((prevValue) => ({
        ...prevValue,
        project: prevValue?.project.map((project) => (project.projectId === projectId ?
          {
            ...project,
            acl: project.acl.concat(
              selectedUsers.map((user) => newProjectAclLCEntry(
                user.lcUserId,
                [PROJECT_VIEWER_ROLE],
                isSupportUser(user),
              )),
            ),
          } : project)),
      }) as frontendpb.ListProjectsReply);
    }
  };

  // Effects
  useEffect(() => {
    if (open) {
      initializeForm();
    }
  }, [open]);

  useEffect(() => {
    setError('');
  }, [selectedUsers]);

  // When the project updates itself, remove the pending variable of added users
  useEffect(() => {
    setUsersWithAccessPending([]);
  }, [userRolesMap]);

  const commonProps = {
    modal: true,
    onClose: onCancel,
    open,
    title: 'Share project',
    width: '600px',
  };

  if (!projectInfo || isMeshReady === null) {
    return (
      <Dialog
        continueButton={{
          name: 'shdLoadingButton',
          label: 'Share',
          disabled: true,
        }}
        onContinue={onCancel}
        {...commonProps}>
        <div style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CircularLoader size={48} />
        </div>
      </Dialog>
    );
  }

  // Skip this dialogue if there is at least one workflow in the project. The API allows users
  // to create projects and launch simulations without loading a mesh or geometry into the setup
  // tab. This may also become common in the future when the UI actively supports multiple
  // geometries/meshes per project. If the GetGeometry RPC is pending we disallow sharing since it
  // could result in the copied project not having a natural way of starting the import process
  // again.
  if ((isMeshReady === false && projectInfo.workflow.length === 0 &&
    (geometryList?.geometries.length ?? 0) === 0) || isGeometryPending) {
    return (
      <Dialog
        continueButton={{
          name: 'emptyProjectOkButton',
          label: 'OK',
        }}
        onContinue={onCancel}
        {...commonProps}>
        <div className={classes.emptyProjectText}>
          This project cannot be shared until either a mesh, a geometry has been uploaded or while
          a geometry import is ongoing. Please try again once that is completed.
        </div>
      </Dialog>
    );
  }

  let disableReason = '';

  if (!viewerIsOwner) {
    disableReason = 'Only the project owner can share it.';
  } else if (!selectedUsers.length) {
    disableReason = 'Please select users to invite.';
  } else if (supportItemSelected && !trimmedSupportDescription) {
    disableReason = 'Description is required.';
  }

  return (
    <Dialog
      cancelButton={{ name: 'shdCancelButton' }}
      continueButton={{
        name: 'shdSubmitButton',
        label: 'Share',
        disabled: !!disableReason,
        help: disableReason,
      }}
      controlState={isUpdating ? 'working' : 'normal'}
      onContinue={handleShare}
      tertiaryButtons={[
        {
          key: 'copylink',
          icon: { name: 'link' },
          label: 'Copy Link',
          name: 'shdCopyButton',
          onClick: () => navigator.clipboard.writeText(url),
        },
      ]}
      {...commonProps}>
      <div>
        <div className={classes.bodyText}>
          When you share a project, all data associated with this project will be shared
          with the recipient.
        </div>
        <div className={classes.addUsersRow}>
          <Autocomplete
            classes={autocompleteClasses}
            className={classes.autocomplete}
            disableClearable
            disabled={isUpdating || !viewerIsOwner}
            filterOptions={(options, state) => {
              const str = state.inputValue.toLowerCase();
              if (str === '') {
                return options;
              }
              return options.filter((item) => (
                `${item.givenName} ${item.familyName
                }${item.email}`
              ).toLowerCase().includes(str));
            }}
            filterSelectedOptions
            forcePopupIcon={false}
            getOptionLabel={(user) => user.email}
            multiple
            onChange={(event, newValue) => setSelectedUsers(newValue)}
            options={usersWithoutAccess}
            PopperComponent={(popperProps) => {
              // Position the popper below the user's text input.
              const { anchorEl, placement, style, ...other } = popperProps;
              return (
                <Popper
                  anchorEl={autocompleteRef.current}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  placement="bottom-start"
                  {...other}
                />
              );
            }}
            ref={autocompleteRef}
            renderInput={(textFieldProps) => {
              // TODO(bamo): Once we update MUI and minRows is supported,
              // use that prop to make the text field taller (3-4 rows).
              const { InputProps, ...other } = textFieldProps;
              return (
                <TextField
                  error={!!error}
                  InputProps={{
                    disableUnderline: true,
                    placeholder: 'Enter name, or email',
                    ...InputProps,
                  }}
                  {...other}
                />
              );
            }}
            renderOption={(renderProps, user) => {
              // The className comes from MaterialUI and is MuiAutocomplete-option
              const { className, ...otherProps } = renderProps;
              return (
                <li
                  className={cx(className, { isSupport: isSupportUser(user) })}
                  key={user.lcUserId}
                  {...otherProps}>
                  <UserCard
                    email={user.email}
                    isSupport={isSupportUser(user)}
                    name={getFullName(user)}
                  />
                </li>
              );
            }}
            renderTags={(tagValue) => tagValue.map((user) => (
              <React.Fragment key={user.lcUserId}>
                <Tag
                  closing={{
                    onClick: () => {
                      setSelectedUsers(
                        selectedUsers.slice().filter((selectedUser) => (selectedUser !== user)),
                      );
                    },
                  }}
                  kind={isSupportUser(user) ? 'primary' : 'secondary'}
                  text={getFullName(user)}
                />
              </React.Fragment>
            ))}
            size="small"
            value={selectedUsers}
          />
          <div className={classes.label}>Can View</div>
        </div>
        <div style={error ? { paddingTop: '8px' } : {}}>
          <AutoCollapsingMessage level="error" message={error} />
        </div>
        {!supportItemSelected && (
          <>
            <div className={classes.usersListTitle}>People with access</div>
            <div className={classes.usersList}>
              {usersWithAccess.map((user) => (
                <React.Fragment key={user.lcUserId}>
                  <UserWithAccessRow
                    projectId={projectId}
                    projectInfo={projectInfo}
                    setProjectList={setProjectList}
                    user={user}
                    userRoles={userRolesMap?.[user.lcUserId]}
                    viewerIsOwner={viewerIsOwner}
                  />
                </React.Fragment>
              ))}
              {usersWithAccessPending.map((user) => (
                <React.Fragment key={user.lcUserId}>
                  <UserWithAccessRow
                    isLoadingUser
                    projectId={projectId}
                    projectInfo={projectInfo}
                    setProjectList={setProjectList}
                    user={user}
                  />
                </React.Fragment>
              ))}
            </div>
          </>
        )}
        {supportItemSelected && (
          <div className={classes.supportInfo}>
            <div className={classes.supportInfoTitle}>
              Description for Luminary Support (Required)
            </div>
            <div className={classes.bodyText}>
              Sharing with support will create a support ticket.
            </div>
            <TextInput
              multiline
              onChange={setSupportDescription}
              placeholder={'Include a short message to help Luminary Support quickly route and ' +
                'resolve your request.'}
              rows={4}
              value={supportDescription}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};
