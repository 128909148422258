// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { rollupGroups } from '../../../lib/entityGroupUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import { NodeFilterData, defaultNodeFilter } from '../../../lib/subselectUtils';
import { useMultiphysicsInterface } from '../../../model/hooks/useMultiphysicsInterface';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useProjectContext } from '../../context/ProjectContext';

export const useMultiphysicsInterfaceSurfaces = (interfaceId: string, sideA: boolean) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // == Model
  const {
    surfaceIdsA,
    surfaceIdsB,

    physicsBySurfaceId,
    surfaceAssignmentDisabledReason,
    saveSurfaceIds,
  } = useMultiphysicsInterface(projectId, workflowId, jobId, readOnly, interfaceId);

  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);

  const currentAssignments = useMemo(
    () => new Set(sideA ? surfaceIdsA : surfaceIdsB),
    [sideA, surfaceIdsA, surfaceIdsB],
  );

  const nodeIds = useMemo(() => {
    const surfaceIds = (sideA ? surfaceIdsA : surfaceIdsB) || [];
    return rollup(surfaceIds);
  }, [rollup, sideA, surfaceIdsA, surfaceIdsB]);

  /**
   * Ordinarily, nodeFilter below would have to lookup a surface by ID and call
   * surfaceAssignmentDisabledReason to get some meaningful result.  We can optimize this by
   * generating a lookup object of each surface's disabled reason ahead of time.
   */
  const geometryState = useMemo(() => {
    const state: Record<string, NodeFilterData<NodeType.SURFACE | NodeType.SURFACE_GROUP>> = {};

    staticVolumes.forEach((volume) => {
      volume.bounds.forEach((surfaceId) => {
        const reason = currentAssignments.has(surfaceId) ?
          '' : surfaceAssignmentDisabledReason(surfaceId, sideA);
        state[surfaceId] = {
          tooltip: reason,
          disabled: !!reason,
          type: NodeType.SURFACE,
        };
      });
    });

    entityGroupData.leafMap.forEach((leafIds, groupId) => {
      if (leafIds.size > 1) {
        // groupId must be a reference to a surface group

        let someDisabled = false;
        const physicsSet = new Set<string>();
        leafIds.forEach((leafId) => {
          if (state[leafId]?.disabled ?? true) {
            someDisabled = true;
          }
          const physicsId = physicsBySurfaceId[leafId];
          if (physicsId) {
            physicsSet.add(physicsId);
          }
        });

        let reason = '';
        if (someDisabled) {
          reason = `Some of this surface group's surfaces are unavailable toggled`;
        } else if (physicsSet.size > 1) {
          reason = `This surface group's surfaces are assigned to multiple physics.  Please
            select individual surfaces.`;
        }

        state[groupId] = {
          tooltip: reason,
          disabled: !!reason,
          type: NodeType.SURFACE_GROUP,
        };
      }
    });

    return state;
  }, [
    currentAssignments,
    entityGroupData,
    physicsBySurfaceId,
    sideA,
    staticVolumes,
    surfaceAssignmentDisabledReason,
  ]);

  /** Memoize the nodeFilter object passed to NodeSubselect to avoid infinite looping */
  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    const geoItem = geometryState[nodeId];
    if (nodeId in geometryState) {
      const { tooltip, disabled } = geoItem;
      return {
        related: true,
        tooltip,
        disabled,
      };
    }
    return defaultNodeFilter(nodeType);
  }, [geometryState]);

  const setSurfaceIds = useCallback(async (newIds: string[]) => {
    await saveSurfaceIds(newIds, sideA);
  }, [saveSurfaceIds, sideA]);

  return {
    nodeFilter,
    nodeIds,
    setSurfaceIds,
  };
};
