// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../lib/componentTypes/simulationTree';
import { NodeType } from '../../lib/simulationTree/node';

import { TreeRow } from './TreeRow';
import { CameraTreeRow } from './row/Camera';
import { CameraContainerTreeRow } from './row/CameraContainer';
import { CameraGlobalContainerTreeRow } from './row/CameraGlobalContainer';
import { CameraGroupTreeRow } from './row/CameraGroup';
import { FarFieldTreeRow } from './row/FarField';
import { FilterRow } from './row/Filter';
import { GeneralSettingsTreeRow } from './row/GeneralSettings';
import { GeometryContactTreeRow } from './row/GeometryContact';
import { GeometryContactContainerTreeRow } from './row/GeometryContactContainer';
import { GeometryModification } from './row/GeometryModification';
import { ItersPerOutputTreeRow } from './row/ItersPerOutput';
import { MaterialContainerTreeRow } from './row/MaterialContainer';
import { MonitorPlaneTreeRow } from './row/MonitorPlane';
import { OutputTreeRow } from './row/Output';
import { OutputContainerTreeRow } from './row/OutputContainer';
import { ParticleGroupTreeRow } from './row/ParticleGroup';
import { PhysicalBehaviorTreeRow } from './row/PhysicalBehavior';
import { PhysicsContainerTreeRow } from './row/PhysicsContainer';
import { PlotTreeRow } from './row/Plot';
import { PlotContainerTreeRow } from './row/PlotContainer';
import { PointContainerTreeRow } from './row/PointContainer';
import { PorousModelTreeRow } from './row/PorousModel';
import { ProbePointTreeRow } from './row/ProbePoint';
import { ReferenceValueTreeRow } from './row/ReferenceValue';
import { RefinementRegionTreeRow } from './row/RefinementRegion';
import { RefinementRegionContainerTreeRow } from './row/RefinementRegionContainer';
import { SolverSettingsTreeRow } from './row/SolverSettings';
import { StoppingConditionsTreeRow } from './row/StoppingConditions';
import { SurfaceTreeRow } from './row/Surface';
import { SurfaceContainerTreeRow } from './row/SurfaceContainer';
import { SurfaceGroupTreeRow } from './row/SurfaceGroup';
import { TagContainerTreeRow } from './row/TagContainer';
import { TransientSettingsTreeRow } from './row/TransientSettings';
import { VolumeTreeRow } from './row/Volume';
import { VolumeContainerTreeRow } from './row/VolumeContainer';
import { ExplorationPolicyTreeRow } from './row/exploration/Policy';
import { ExplorationVariableTreeRow } from './row/exploration/Variable';
import { ExplorationVariableContainerTreeRow } from './row/exploration/VariableContainer';
import { MaterialFluidTreeRow } from './row/material/Fluid';
import { MaterialSolidTreeRow } from './row/material/Solid';
import { BoundaryLayerTreeRow } from './row/mesh/BoundaryLayer';
import { ModelTreeRow } from './row/mesh/Model';
import { SizeTreeRow } from './row/mesh/Size';
import { MotionFrameTreeRow } from './row/motion/Frame';
import { MotionGlobalFrameTreeRow } from './row/motion/GlobalFrame';
import { PhysicsFluidTreeRow } from './row/physics/Fluid';
import { PhysicsHeatTreeRow } from './row/physics/Heat';
import { PhysicsMultiTreeRow } from './row/physics/Multi';
import { PhysicsPeriodicPairTreeRow } from './row/physics/PeriodicPair';
import { PhysicsSlidingInterfaceTreeRow } from './row/physics/SlidingInterface';
import { PhysicsVolumeSelectionTreeRow } from './row/physics/VolumeSelection';
import { PhysicsFluidBoundaryConditionTreeRow } from './row/physics/fluid/BoundaryCondition';
import { PhysicsFluidBoundaryConditionContainerTreeRow } from './row/physics/fluid/BoundaryConditionContainer';
import { PhysicsFluidInitializationTreeRow } from './row/physics/fluid/Initialization';
import { PhysicsFluidPhysicalModelContainerTreeRow } from './row/physics/fluid/PhysicalModelContainer';
import { PhysicsHeatBoundaryConditionTreeRow } from './row/physics/heat/BoundaryCondition';
import { PhysicsHeatBoundaryConditionContainerTreeRow } from './row/physics/heat/BoundaryConditionContainer';
import { PhysicsHeatHeatSourceTreeRow } from './row/physics/heat/HeatSource';
import { PhysicsHeatHeatSourceContainerTreeRow } from './row/physics/heat/HeatSourceContainer';
import { PhysicsHeatInitializationTreeRow } from './row/physics/heat/Initialization';
import { PhysicsMultiInterfaceTreeRow } from './row/physics/multi/Interface';
import { GeometryOrMeshRow } from './row/shared/GeometryOrMeshRow';

// A component that routes row rendering based on node type
export const SimulationRowContainer = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  switch (node.type) {
    case NodeType.CAMERA:
      return <CameraTreeRow {...props} />;
    case NodeType.CAMERA_CONTAINER:
      return <CameraContainerTreeRow {...props} />;
    case NodeType.CAMERA_GLOBAL_CONTAINER:
      return <CameraGlobalContainerTreeRow {...props} />;
    case NodeType.CAMERA_GROUP:
      return <CameraGroupTreeRow {...props} />;
    case NodeType.EXPLORATION_POLICY:
      return <ExplorationPolicyTreeRow {...props} />;
    case NodeType.EXPLORATION_VARIABLE_CONTAINER:
      return <ExplorationVariableContainerTreeRow {...props} />;
    case NodeType.EXPLORATION_VARIABLE:
      return <ExplorationVariableTreeRow {...props} />;
    case NodeType.FAR_FIELD:
      return <FarFieldTreeRow {...props} />;
    case NodeType.FILTER:
      return <FilterRow {...props} />;
    case NodeType.GENERAL_SETTINGS:
      return <GeneralSettingsTreeRow {...props} />;
    case NodeType.GEOMETRY_CONTACT:
      return <GeometryContactTreeRow {...props} />;
    case NodeType.GEOMETRY_CONTACT_CONTAINER:
      return <GeometryContactContainerTreeRow {...props} />;
    case NodeType.GEOMETRY_MODIFICATION:
      return <GeometryModification {...props} />;
    case NodeType.ITERS_PER_OUTPUT:
      return <ItersPerOutputTreeRow {...props} />;
    case NodeType.GEOMETRY:
    case NodeType.MESH:
      return <GeometryOrMeshRow {...props} isGeometry={node.type === NodeType.GEOMETRY} />;
    case NodeType.MATERIAL_CONTAINER:
      return <MaterialContainerTreeRow {...props} />;
    case NodeType.MATERIAL_FLUID:
      return <MaterialFluidTreeRow {...props} />;
    case NodeType.MATERIAL_SOLID:
      return <MaterialSolidTreeRow {...props} />;
    case NodeType.MESH_SIZE:
      return <SizeTreeRow {...props} />;
    case NodeType.MESH_ADAPTATION_BOUNDARY:
      return <BoundaryLayerTreeRow {...props} />;
    case NodeType.MESH_BOUNDARY:
      return <BoundaryLayerTreeRow {...props} />;
    case NodeType.MESH_MODEL:
      return <ModelTreeRow {...props} />;
    case NodeType.MONITOR_PLANE:
      return <MonitorPlaneTreeRow {...props} />;
    case NodeType.MOTION_FRAME:
      return <MotionFrameTreeRow {...props} />;
    case NodeType.MOTION_GLOBAL_FRAME:
      return <MotionGlobalFrameTreeRow {...props} />;
    case NodeType.OUTPUT:
      return <OutputTreeRow {...props} />;
    case NodeType.OUTPUT_CONTAINER:
      return <OutputContainerTreeRow {...props} />;
    case NodeType.PARTICLE_GROUP:
      return <ParticleGroupTreeRow {...props} />;
    case NodeType.PHYSICAL_BEHAVIOR:
      return <PhysicalBehaviorTreeRow {...props} />;
    case NodeType.PHYSICS_CONTAINER:
      return <PhysicsContainerTreeRow {...props} />;
    case NodeType.PHYSICS_FLUID:
      return <PhysicsFluidTreeRow {...props} />;
    case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION:
      return <PhysicsFluidBoundaryConditionTreeRow {...props} />;
    case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION_CONTAINER:
      return <PhysicsFluidBoundaryConditionContainerTreeRow {...props} />;
    case NodeType.PHYSICS_FLUID_INITIALIZATION:
      return <PhysicsFluidInitializationTreeRow {...props} />;
    case NodeType.PHYSICS_PERIODIC_PAIR:
      return <PhysicsPeriodicPairTreeRow {...props} />;
    case NodeType.PHYSICS_FLUID_PHYSICAL_MODEL_CONTAINER:
      return <PhysicsFluidPhysicalModelContainerTreeRow {...props} />;
    case NodeType.PHYSICS_SLIDING_INTERFACE:
      return <PhysicsSlidingInterfaceTreeRow {...props} />;
    case NodeType.PHYSICS_HEAT:
      return <PhysicsHeatTreeRow {...props} />;
    case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION:
      return <PhysicsHeatBoundaryConditionTreeRow {...props} />;
    case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION_CONTAINER:
      return <PhysicsHeatBoundaryConditionContainerTreeRow {...props} />;
    case NodeType.PHYSICS_HEAT_HEAT_SOURCE:
      return <PhysicsHeatHeatSourceTreeRow {...props} />;
    case NodeType.PHYSICS_HEAT_HEAT_SOURCE_CONTAINER:
      return <PhysicsHeatHeatSourceContainerTreeRow {...props} />;
    case NodeType.PHYSICS_HEAT_INITIALIZATION:
      return <PhysicsHeatInitializationTreeRow {...props} />;
    case NodeType.PHYSICS_MULTI:
      return <PhysicsMultiTreeRow {...props} />;
    case NodeType.PHYSICS_MULTI_INTERFACE:
      return <PhysicsMultiInterfaceTreeRow {...props} />;
    case NodeType.PHYSICS_VOLUME_SELECTION:
      return <PhysicsVolumeSelectionTreeRow {...props} />;
    case NodeType.PLOT:
      return <PlotTreeRow {...props} />;
    case NodeType.PLOT_CONTAINER:
      return <PlotContainerTreeRow {...props} />;
    case NodeType.POINT_CONTAINER:
      return <PointContainerTreeRow {...props} />;
    case NodeType.POROUS_MODEL:
      return <PorousModelTreeRow {...props} />;
    case NodeType.PROBE_POINT:
      return <ProbePointTreeRow {...props} />;
    case NodeType.REFERENCE_VALUE:
      return <ReferenceValueTreeRow {...props} />;
    case NodeType.REFINEMENT_REGION:
      return <RefinementRegionTreeRow {...props} />;
    case NodeType.REFINEMENT_REGION_CONTAINER:
      return <RefinementRegionContainerTreeRow {...props} />;
    case NodeType.SOLVER_SETTINGS:
      return <SolverSettingsTreeRow {...props} />;
    case NodeType.STOPPING_CONDITIONS:
      return <StoppingConditionsTreeRow {...props} />;
    case NodeType.SURFACE:
    case NodeType.TAGS_FACE:
      return <SurfaceTreeRow {...props} />;
    case NodeType.SURFACE_GROUP:
      return <SurfaceGroupTreeRow {...props} />;
    case NodeType.SURFACE_CONTAINER:
      return <SurfaceContainerTreeRow {...props} />;
    case NodeType.VOLUME:
    case NodeType.TAGS_BODY:
      return <VolumeTreeRow {...props} />;
    case NodeType.VOLUME_CONTAINER:
      return <VolumeContainerTreeRow {...props} />;
    case NodeType.TAGS_CONTAINER:
      return <TagContainerTreeRow {...props} />;
    case NodeType.UPLOAD_CAD_OR_MESH:
    case NodeType.CAD_SOLID_OR_FLUID:
    case NodeType.GEOMETRY_CHECK:
    case NodeType.SHRINK_WRAP:
    case NodeType.CREATE_FARFIELD:
      return <TreeRow {...props} />;
    case NodeType.TRANSIENT_SETTINGS:
      return <TransientSettingsTreeRow {...props} />;
    default:
      console.warn(`Unmapped tree row type ${node.type}`);
      return <TreeRow {...props} />;
  }
};
