// This file defines the format of lcvis files.
//
// A lcvis file authoritatively describes extracts created by the vis library.

// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/lcn/lcvis.proto (package luminary.proto.lcn, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Vector3 } from "../base/base_pb.js";
import { QuantityType } from "../quantity/quantity_pb.js";
import { FieldRange } from "./lcsoln_pb.js";

/**
 * Defines the encoding of a grid.
 *
 * @generated from enum luminary.proto.lcn.VisGridType
 */
export const VisGridType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.lcn.VisGridType",
  [
    {no: 0, name: "InvalidVisGridType"},
    {no: 1, name: "Unstructured"},
  ],
);

/**
 * Defines association of a field to mesh topology.
 *
 * @generated from enum luminary.proto.lcn.FieldAssociation
 */
export const FieldAssociation = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.lcn.FieldAssociation",
  [
    {no: 0, name: "InvalidFieldAssociation"},
    {no: 1, name: "Cells"},
    {no: 2, name: "Points"},
  ],
);

/**
 * @generated from message luminary.proto.lcn.VisFileMetadata
 */
export const VisFileMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.VisFileMetadata",
  () => [
    { no: 1, name: "zone", kind: "message", T: VisZoneMetadata, repeated: true },
  ],
);

/**
 * Zone defines a logical collection of blocks.
 *
 * @generated from message luminary.proto.lcn.VisZoneMetadata
 */
export const VisZoneMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.VisZoneMetadata",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "block", kind: "message", T: BlockMetadata, repeated: true },
    { no: 4, name: "stats", kind: "message", T: VisMeshStats },
  ],
);

/**
 * Stats about the topololgy.
 *
 * @generated from message luminary.proto.lcn.VisMeshStats
 */
export const VisMeshStats = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.VisMeshStats",
  () => [
    { no: 1, name: "n_points", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "n_cells", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "n_conn", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "min_coord", kind: "message", T: Vector3 },
    { no: 6, name: "max_coord", kind: "message", T: Vector3 },
  ],
);

/**
 * Block definition: a self contianed mesh that can belong to
 * a logical grouping of other blocks.
 *
 * @generated from message luminary.proto.lcn.BlockMetadata
 */
export const BlockMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.BlockMetadata",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "grid_type", kind: "enum", T: proto3.getEnumType(VisGridType) },
    { no: 3, name: "shapes_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "points_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "offsets_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "connectivity_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "stats", kind: "message", T: VisMeshStats },
    { no: 8, name: "field", kind: "message", T: FieldMetadata, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.lcn.FieldMetadata
 */
export const FieldMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.FieldMetadata",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "quantity", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 3, name: "range", kind: "message", T: FieldRange },
    { no: 4, name: "assoc", kind: "enum", T: proto3.getEnumType(FieldAssociation) },
    { no: 5, name: "quantity_fallback_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.lcn.CustomExpression
 */
export const CustomExpression = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.CustomExpression",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "expression", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.lcn.CustomExpressions
 */
export const CustomExpressions = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcn.CustomExpressions",
  () => [
    { no: 1, name: "expressions", kind: "message", T: CustomExpression, repeated: true },
  ],
);

