// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ForwardedRef, MutableRefObject, forwardRef, useCallback, useEffect, useLayoutEffect, useRef } from 'react';

import { LCVError } from '@luminarycloudinternal/lcvis';

import * as flags from '../../flags';
import computeBackoff from '../../lib/backoff';
import { CurrentView, isIntermediateView } from '../../lib/componentTypes/context';
import { colors } from '../../lib/designSystem';
import { Jwt, sessionKey } from '../../lib/jwt';
import { CANVAS_SIZE_ERROR, FRAMEBUFFER_SIZE_ERROR } from '../../lib/lcvis/classes/LcvDisplay';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { isLCVError } from '../../lib/lcvis/lcvisUtils';
import {
  FilterProgressCallback,
  ImportFilterRealOptions,
  ImportFilterSetupOptions,
  LcVisVisibilityMap,
  LcvisCameraStateType,
} from '../../lib/lcvis/types';
import { Logger } from '../../lib/observability/logs';
import { extname, isLcMeshExtension, isLcSolnExtension } from '../../lib/path';
import { protoToJson } from '../../lib/proto';
import { getReferenceValues } from '../../lib/referenceValueUtils';
import * as rpc from '../../lib/rpc';
import { addError, addRpcError } from '../../lib/transientNotification';
import useResizeObserver from '../../lib/useResizeObserver';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as simulationpb from '../../proto/client/simulation_pb';
import { UrlType } from '../../proto/projectstate/projectstate_pb';
import { useGeometryState } from '../../recoil/geometry/geometryState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useLcvisCameraValue } from '../../recoil/lcvis/lcvisCameraState';
import { useSetLcVisEnabledState } from '../../recoil/lcvis/lcvisEnabledState';
import { useSetLcvisFilterStatus } from '../../recoil/lcvis/lcvisFilterStatus';
import { useLcvisVisibilityMapValue } from '../../recoil/lcvis/lcvisVisibilityMap';
import { useMeshUrlState } from '../../recoil/meshState';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useEnabledExperiments, useIsEnabled } from '../../recoil/useExperimentConfig';
import { useActiveVisUrlValue } from '../../recoil/vis/activeVisUrl';
import { useStaticVolumes } from '../../recoil/volumes';
import { useAuthInfoV2Value } from '../../state/external/auth/authInfo';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useCurrentView, useIsAnalysisView } from '../../state/internal/global/currentView';
import { useSetVisHeight } from '../../state/internal/vis/visHeight';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';

const useStyles = makeStyles(
  () => createStyles({
    // Renderer and the overlays.
    rendererChild: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      minHeight: 0,
      boxSizing: 'border-box',
      overflow: 'hidden',
      filter: `drop-shadow(2px 2px 1px ${colors.neutral100})`,
    },
  }),
  { name: 'LcVisCanvasHandler' },
);

const logger = new Logger('LcVisCanvasHandler');

/**
 * When we reload the LCVis display, we need to reapply the initial camera and visibility states.
 * This can't be completely handled in recoil, since switching between pages will preserve
 * recoil state but not the LCVis display's states.
 */
const applyInitialStates = (camera: LcvisCameraStateType, visibility: LcVisVisibilityMap) => {
  // set the camera state
  lcvHandler.queueDisplayFunction('setupCamera', (display) => {
    const { arcballWidget } = display.widgets;
    if (camera.isDefault) {
      arcballWidget?.resetCamera();
    } else {
      arcballWidget?.setCameraState(camera);
    }
  });
};

/**
 * Starts the lcvis session and creates a new display. If the session was already started,
 * this just creates a new display on the specified canvas. It also handles tearing down
 * the old display on unmount.
 */
const useLcVis = (
  canvasId: string,
  jwt: Jwt | null,
  projectId: string,
  workflowId: string,
  jobId: string,
  setupOptions: ImportFilterSetupOptions,
  initialCamera: LcvisCameraStateType,
  initialVisibilities: LcVisVisibilityMap,
  geoModEnabled: boolean,
  canvasRef: ForwardedRef<HTMLCanvasElement | null>,
) => {
  const { geometryId } = useProjectContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  // whether setup has already been called successfully. If it has, calling setup() will be a noop.
  const setupRef = useRef<boolean>(false);
  // Curently loaded meshUrl. Used to retrigger the setup if the mesh URL changes.
  const currentMeshUrl = useRef<string>('');
  const setFilterStatus = useSetLcvisFilterStatus();
  const geoState = useGeometryState(projectId, geometryId);
  // the camera and visibility states might change frequently. We only want to use their values
  // when setup is first called, so store a snapshot of each in a ref here to avoid rerunning
  // the effects.
  const initialCameraSnapshot = useRef(initialCamera);
  // Derived field hooks.
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const [perJobOutputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const experimentConfig = useEnabledExperiments();
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const isAnalysisView = useIsAnalysisView();
  // --- end derived field hooks

  const initialVisMapSnapshot = useRef(initialVisibilities);

  const setLcvisEnabled = useSetLcVisEnabledState();

  // The lcvis progress callback. This is called during the fetch and data download steps
  // before anything is displayed.
  const progressCallback: FilterProgressCallback = setFilterStatus;

  /** Initialize the LcVis session, display, and frame */
  const setup = useCallback(async () => {
    const opts = (setupOptions as ImportFilterRealOptions);
    if (!jwt || setupRef.current) {
      return;
    }
    // Need to allow LcVis to initialize if the meshUrl is empty for interactive geometry.
    if (
      opts.meshUrl !== '' &&
      !isLcMeshExtension(extname(opts.meshUrl)) &&
      !isLcSolnExtension(extname(opts.meshUrl))
    ) {
      return;
    }

    try {
      setupRef.current = true;
      currentMeshUrl.current = opts.meshUrl;
      await lcvHandler.startSession(localStorage.getItem(sessionKey) || '', projectId);
      if (isAnalysisView && simParam) {
        // Grab the giant params proto and convert it to a json string literal.
        const newParam = simParam.clone();
        const refValues = getReferenceValues(
          perJobOutputNodes,
          simParam,
          experimentConfig,
          geometryTags,
          staticVolumes,
        );

        if (experimentConfig.includes(flags.referenceValues)) {
          newParam.referenceValues = new simulationpb.ReferenceValues({ ...refValues });
        }
        await protoToJson(newParam, false).then((jsonData: string) => {
          opts.fvmParams = jsonData;
        }).catch((err) => {
          logger.error('Could not convert to JSON', err);
        });
      }

      await lcvHandler.startDisplay(
        canvasId,
        setupOptions,
        progressCallback,
      );

      applyInitialStates(initialCameraSnapshot.current, initialVisMapSnapshot.current);

      // If we click twice on the geometry tab, we don't restart the streaming RPC. This results in
      // lcvis showing nothing on screen. We need to request the latest tessellation again to keep
      // things simple.
      if (geoState) {
        const req = new geometryservicepb.LatestTessellationRequest({ geometryId });
        try {
          await rpc.clientGeometry!.latestTessellation(req);
        } catch (error) {
          logger.error('Failed to request latest tessellation', error);
          addRpcError('Failed to update the tessellation', error);
        }
      }
    } catch (error) {
      currentMeshUrl.current = '';
      throw error;
    }
  }, [setupOptions, jwt, projectId, canvasId, progressCallback, geoState, geometryId,
    simParam, staticVolumes, isAnalysisView, perJobOutputNodes, experimentConfig, geometryTags]);

  /**
   * Keep trying to set up the frame and display. setup() will fail if the canvas width or height
   * are 0 or undefined. Because react sometimes performs resizes/relayouts unpredictably, we should
   * keep retrying setup until one call succeeds. Once that happens setupRef.current will be true
   * and we can exit.
   */
  useEffect(() => {
    let animationFrame = 0;
    let retryTimeout: ReturnType<typeof setTimeout>;
    // Retry setup up to 10 times with exponential backoff.
    let nRetry = 0;
    const maxRetry = 10;
    const retrySetup = () => {
      if (nRetry >= maxRetry) {
        addError('Failed to connect to Luminary 3D');
        return;
      }
      animationFrame = requestAnimationFrame(() => {
        const opts = (setupOptions as ImportFilterRealOptions);
        // Allow to reload the URL when using the geometry modifications feature flag. This is to
        // ease the development of the new feature by loading new tesselations.
        const urlCond = geoModEnabled && currentMeshUrl.current !== opts.meshUrl;
        if (!setupRef.current || urlCond) {
          setup().catch((error: Error | LCVError) => {
            if (error === LCVError.kLCVErrorWorkspaceCanceled) {
              // If the workspace was cancelled, do nothing since the user navigated away
              return;
            }
            if (isLCVError(error)) {
              logger.error('lcvis error', LCVError[error]);
              throw error;
            }
            // Some errors indicate a problem with the client, and we should not re-try
            // setup because the same error will happen again. The error will be reported
            // and displayed through the status callback so here we should just stop trying
            // to set up
            const unsupportedClient = (
              error.message.includes(LCVError[LCVError.kLCVErrorUnsupportedClient]) ||
              error.message.includes(LCVError[LCVError.kLCVErrorWebGLContextCreationFailed])
            );
            if (unsupportedClient) {
              // Client doesn't support LCVis/client-side rendering, disable it
              setLcvisEnabled(false);
              return;
            }
            const invalidSize = (
              error.message.includes(CANVAS_SIZE_ERROR) ||
              error.message.includes(FRAMEBUFFER_SIZE_ERROR)
            );
            if (invalidSize) {
              // only retry if the error was due to the canvas size being 0
              setupRef.current = false;
              retryTimeout = setTimeout(retrySetup, computeBackoff(nRetry));
              nRetry += 1;
            } else {
              throw error;
            }
          });
        }
      });
    };
    retrySetup();
    return () => {
      cancelAnimationFrame(animationFrame);
      clearTimeout(retryTimeout);
    };
  }, [geoModEnabled, setup, setLcvisEnabled, setupOptions]);

  // when the component unmounts, clean up wasm memory by releasing all the display's objects.
  useEffect(() => () => {
    if (setupRef.current && !(canvasRef as MutableRefObject<HTMLCanvasElement>).current) {
      lcvHandler.teardown().catch((error) => logger.error('lcvis teardown error', error));
    }
  }, [canvasRef]);

  return {
    canvasRef,
    containerRef,
    setupRef,
  };
};
/**
 * The component which renders the LCVis canvas, and runs LCVis setup and teardown steps when
 * it is mounted and unmounted.
 */
export const LcVisCanvas = forwardRef((
  props,
  ref: ForwardedRef<HTMLCanvasElement | null>,
) => {
  // == Contexts
  const { projectId, workflowId, jobId, geometryId } = useProjectContext();

  // == Hooks
  const classes = useStyles();

  // == Recoil
  const meshUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const geoModEnabled = useIsEnabled(flags.geoModifications);
  const authInfo = useAuthInfoV2Value();
  const cameraState = useLcvisCameraValue({ projectId, workflowId, jobId });
  const visibilityMap = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const setVisHeight = useSetVisHeight();
  const [meshUrlState] = useMeshUrlState(projectId);
  const currentView = useCurrentView();

  // == State
  const meshName = 'cad';
  const canvasId = `lcvisCanvas-${projectId}${workflowId}${jobId}${geometryId}`;
  const jwt = authInfo.jwt;
  const isSetupOrIntermediate = (
    currentView === CurrentView.SETUP ||
    isIntermediateView(currentView)
  );
  const isGeometry = (
    (isSetupOrIntermediate && meshUrlState.activeType === UrlType.GEOMETRY) ||
    currentView === CurrentView.GEOMETRY
  );
  const setupOptions: ImportFilterSetupOptions = { projectId, meshUrl, meshName, isGeometry };

  // == Custom effects
  const { containerRef } = useLcVis(
    canvasId,
    jwt,
    projectId,
    workflowId,
    jobId,
    setupOptions,
    cameraState,
    visibilityMap,
    geoModEnabled,
    ref,
  );

  const lcVisSize = useResizeObserver(containerRef);

  useLayoutEffect(() => {
    setVisHeight(lcVisSize.height);
  }, [lcVisSize, setVisHeight]);

  return (
    <div
      className={classes.rendererChild}
      id="lcVisManager"
      ref={containerRef}
      style={{ height: '100%', width: '100%' }}>
      <canvas
        id={canvasId}
        ref={ref}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
});
