// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { MultiCheckBoxItemProps } from '../../lib/componentTypes/form';
import { Logger } from '../../lib/observability/logs';
import { GetPlatformAdminRole, PlatformAdminRoleNames, PlatformRoles } from '../../lib/platformAdminUtils';
import * as rpc from '../../lib/rpc';
import * as status from '../../lib/status';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as papb from '../../proto/platformadmin/platformadmin_pb';
import Form from '../Form';

import { Dialog } from './Base';

const logger = new Logger('dialog/PlatformAdminUser');

export interface PlatformAdminUserDialogProps {
  open: boolean;
  onClose: () => void;
  user?: frontendpb.AccountInfoReply_UserInfo;
}

export const PlatformAdminUserDialog = (props: PlatformAdminUserDialogProps) => {
  // Props
  const { open, user } = props;
  const email = user?.email || '';
  const roles = user?.platformAdmin?.roles || [];

  // Methods
  function handleModifyAdminUser(add: boolean, role: papb.PlatformRole) {
    const req = new papb.UpdatePlatformAdminRequest({
      userId: user!.lcUserId,
    });
    if (add) {
      req.addRoles.push(role);
    } else {
      req.removeRoles.push(role);
    }
    rpc.callRetry('PlatformAdminSet', rpc.client.updatePlatformAdmin, req).then(() => {
      // This cause us to exit the modal and reload with the result, but meh...
      window.location.reload();
    }).catch((error) => {
      logger.info(`PlatformAdminSet error: ${status.stringifyError(error)}`);
    });
  }

  // Checkbox inputs.
  const checkboxOptions: MultiCheckBoxItemProps[] = [];
  PlatformRoles?.forEach((role) => {
    const roleName = PlatformAdminRoleNames(GetPlatformAdminRole(role));
    checkboxOptions.push({
      checked: roles.includes(role),
      help: `Set ${roleName}`,
      key: roleName,
      optionText: roleName,
      onChange: (checked) => {
        handleModifyAdminUser(checked, role);
      },
    });
  });

  return (
    <Dialog
      continueButton={{ label: 'Done' }}
      modal
      onClose={props.onClose}
      onContinue={props.onClose}
      open={open}
      subtitle={`Set platform admin roles for user: ${email}`}
      title="Edit Platform Admin Roles">
      <Form.LabeledInput
        help="Platform Admin Roles"
        label="Roles">
        <Form.MultiCheckBox checkBoxProps={checkboxOptions} />
      </Form.LabeledInput>
    </Dialog>
  );
};
