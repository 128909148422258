// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo } from 'react';

import { MultiCheckBoxItemProps } from '../../lib/componentTypes/form';
import { colors } from '../../lib/designSystem';
import { isMotionValid } from '../../lib/motionDataUtils';
import { OVERLAY_CARD_WIDTH } from '../../lib/visUtils';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useMotionAnimationParamState } from '../../state/internal/vis/motionAnimation';
import { ActionButton } from '../Button/ActionButton';
import { IconButton } from '../Button/IconButton';
import Form from '../Form';
import { NumberInput } from '../Form/NumberInput';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { XIcon } from '../svg/XIcon';

// Input validation for paraview to prevent run-away server actions
const MAX_NUMBER_TIMESTEPS_GRID_MOTION = 200;

// Expose dialog height to shift properties panel if needed
export const MOTION_PANEL_HEIGHT = 209;

interface MotionAnimationSettingsProps {
  // Closes the dialog. Passes in the motion animation settings set by the user.
  onClose: () => void;
  // Indicates dialog is being dragged
  isDragging?: boolean;

  cancelEdit: () => void;
}

const useStyles = makeStyles(
  () => createStyles({
    root: {
      width: OVERLAY_CARD_WIDTH,
      backgroundColor: colors.neutral150,
      borderRadius: '4px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 12px',
      fontWeight: 600,
      lineHeight: '16px',
      fontSize: '13px',
      gap: '12px',
    },
    headerMain: {
      flexGrow: 1,
    },
    content: {
      padding: '8px 12px',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '8px 12px',
      borderTop: `1px solid ${colors.neutral50}`,
    },
  }),
  { name: 'MotionAnimationSettings' },
);

// A dialog for editing the grid motion animation parameters and to launch the
// grid motion animation.
// TODO(LC-5840): Develop a more generic implementation of draggable panels to
// avoid code duplication.
const MotionAnimationSettings = (props: MotionAnimationSettingsProps) => {
  // Props
  const { cancelEdit, isDragging, onClose } = props;

  // Hooks
  const classes = useStyles();

  // Context
  const { projectId, workflowId, jobId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  // Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  // The current parameters for a motion animation
  const [gridMotionParam, setGridMotionParam] = useMotionAnimationParamState(projectId);

  const motionWarning = useMemo(() => {
    // Disable motion animation if config isn't valid
    if (!isMotionValid(simParam, entityGroupData, staticVolumes, geometryTags)) {
      return 'Correct errors in Frames & Motion settings';
    }
    return '';
  }, [entityGroupData, simParam, staticVolumes, geometryTags]);

  useEffect(() => {
    if (!lcvisEnabled) {
      setGridMotionParam(
        (curr) => ({
          ...curr,
          numberOfSteps: Math.min(curr.numberOfSteps, MAX_NUMBER_TIMESTEPS_GRID_MOTION),
        }),
      );
    }
  }, [lcvisEnabled, setGridMotionParam]);

  // Checkbox inputs.
  const checkboxOptions: MultiCheckBoxItemProps[] = [{
    checked: gridMotionParam.drawAxes,
    disabled: false,
    help: 'Draw the reference frame axes.',
    optionText: 'Draw frames',
    onChange: (checked) => {
      setGridMotionParam({ ...gridMotionParam, drawAxes: checked });
    },
    key: 'draw-frames',
  }];

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerMain}>
          Motion Animation
        </div>
        <IconButton onClick={cancelEdit}>
          <XIcon maxHeight={12} />
        </IconButton>
      </div>
      <div className={classes.content}>
        <Form.LabeledInput
          help="Time Interval."
          label="Time Interval">
          <NumberInput
            disabled={isDragging}
            onCommit={(newEnd: number) => {
              // Do not allow the end time to be lower than start time.
              newEnd = Math.max(newEnd, gridMotionParam.startTime);
              setGridMotionParam({ ...gridMotionParam, endTime: newEnd });
            }}
            value={gridMotionParam.endTime}
          />
        </Form.LabeledInput>
        <Form.LabeledInput
          help="Number of steps."
          label="Number of steps">
          <NumberInput
            disabled={isDragging}
            onCommit={(newNumberOfSteps) => {
              // Clip the number of steps.
              newNumberOfSteps = Math.max(newNumberOfSteps, 0);
              let maxSteps = MAX_NUMBER_TIMESTEPS_GRID_MOTION;
              if (lcvisEnabled) {
                // Allow lcvis to animate longer.
                maxSteps *= 5;
              }
              newNumberOfSteps = Math.min(newNumberOfSteps, maxSteps);
              setGridMotionParam({ ...gridMotionParam, numberOfSteps: newNumberOfSteps });
            }}
            value={gridMotionParam.numberOfSteps}
          />
        </Form.LabeledInput>

        <Form.LabeledInput
          help="Visualization Options"
          label="Visualization Options">
          <Form.MultiCheckBox checkBoxProps={checkboxOptions} />
        </Form.LabeledInput>
      </div>
      <div className={classes.footer}>
        <ActionButton
          disabled={!!motionWarning}
          kind="primary"
          onClick={onClose}
          size="small"
          title={motionWarning}
          type="button">
          Play
        </ActionButton>
      </div>
    </div>
  );
};

export default MotionAnimationSettings;
