// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { RefObject, useEffect, useRef, useState } from 'react';

import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useProjectContext } from '../../components/context/ProjectContext';
import { rectsOverlap } from '../../lib/layoutUtils';
import { RecoilProjectKey } from '../../lib/persist';
import { VIEWER_PADDING } from '../../lib/visUtils';

import { RIGHT_OVERLAY_CARDS_WIDTH } from '@/lib/constants';

export const infoCardDims = atomFamily<DOMRect | null, RecoilProjectKey>({
  key: 'infoCardDims',
  default: null,
});

export const useInfoCardDims = (key: RecoilProjectKey) => useRecoilState(infoCardDims(key));
export const useSetInfoCardDims = (key: RecoilProjectKey) => useSetRecoilState(infoCardDims(key));
export const useInforCardDimsValue = (key: RecoilProjectKey) => useRecoilValue(infoCardDims(key));

/**
 * Hook to sync the dimensions of an info card component with the recoil state.
 *
 * @returns A ref to attach to the component to measure.
 */
export const useSyncInfoCardDims = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const cardRef = useRef<HTMLDivElement>(null);
  const setDims = useSetInfoCardDims({ projectId, workflowId, jobId });

  useEffect(() => {
    setDims(cardRef.current?.getBoundingClientRect() ?? null);
    return () => setDims(null);
  }, [setDims]);

  return cardRef;
};

/**
 * Hook to calculate the top right position of a floating panel based on whether it overlaps with
 * an LCVis info card.
 */
export const useFloatingPanelTopRight = (panelNode: RefObject<HTMLDivElement>) => {
  const { projectId, workflowId, jobId } = useProjectContext();

  const lcvisInfoDims = useInforCardDimsValue({ projectId, workflowId, jobId });
  const [topRight, setTopRight] = useState({
    // Current toolbar height and modification panel width (plus padding)
    top: 52,
    right: RIGHT_OVERLAY_CARDS_WIDTH + VIEWER_PADDING * 2,
  });

  useEffect(() => {
    if (panelNode.current && lcvisInfoDims) {
      // check whether dialogNode overlaps at all with lcvisInfoDims
      const dialogRect = panelNode.current.getBoundingClientRect();
      const overlaps = rectsOverlap(dialogRect, lcvisInfoDims);
      if (overlaps) {
        setTopRight((prev) => ({
          ...prev,
          top: prev.top + (lcvisInfoDims.bottom - dialogRect.top) + 8,
        }));
      }
    }
  }, [lcvisInfoDims, panelNode]);

  return topRight;
};
