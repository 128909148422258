// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getWallSurfacesWithTags } from '../../../lib/boundaryConditionUtils';
import { unwrapSurfaceIds } from '../../../lib/entityGroupUtils';
import { PorousSurfaces, getMissingPorousSurfaces, showPorousSurfacesWarning } from '../../../lib/outputNodeUtils';
import { getAllPorousModels, getPorousInterfaces, getPorousSurfaces } from '../../../lib/porousModelUtils';
import { wordsToList } from '../../../lib/text';
import { useOutput } from '../../../model/hooks/useOutput';
import * as feoutputpb from '../../../proto/frontend/output/output_pb';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useProjectContext } from '../../context/ProjectContext';
import { useSimulationConfig } from '../useSimulationConfig';

export interface PorousForceOutputResult {
  /** True when a warning for porous output should be shown */
  showWarning: boolean;
  /** Add the surfaces to the output node that bound the already selected porous volume */
  addAllMissingSurfaces: () => void;
  /** Temporarily dismiss the warning */
  dismissWarning: () => void;
  /** The warning text which includes the name of porous volumes that have missing surfaces */
  warningText: string;
}

function getForceNode(node: feoutputpb.OutputNode): feoutputpb.ForceNode | null {
  if (
    node.nodeProps.case === 'force' &&
    node.type === feoutputpb.OutputNode_Type.SURFACE_OUTPUT_TYPE
  ) {
    return node.nodeProps.value;
  }
  return null;
}

/**
 * Custom hook for handling porous surfaces in a surface force output node.
 *
 * LC-17106 LC-18838
 *
 * When a porous interface surface is selected, then the output node must be in a "porous" mode
 * where non-porous surfaces cannot be selected. In addition, all surfaces that bound the same
 * porous volume must also be selected.
 *
 *
 * @param node the surface force output node
 * @param idBase the ID for the node select table
 *
 * @returns the warnings for porous surfaces
 */
export function usePorousForceOutput(
  node: feoutputpb.OutputNode,
  idBase: string,
): PorousForceOutputResult {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  // == Hooks
  const { simParam } = useSimulationConfig();
  const { updateOutputNode } = useOutput(projectId, node.id);

  const [missingVolumes, setMissingVolumes] = useState<PorousSurfaces[]>([]);

  const expand = useCallback((ids: string[]) => unwrapSurfaceIds(
    ids,
    geometryTags,
    entityGroupData,
  ), [entityGroupData, geometryTags]);

  useEffect(() => {
    const forceNode = getForceNode(node);
    if (!forceNode) {
      return;
    }
    const inSurfaces = expand(node.inSurfaces);
    const porousModels = getAllPorousModels(simParam);
    setMissingVolumes(
      getMissingPorousSurfaces(porousModels, inSurfaces, staticVolumes, geometryTags),
    );
    const porousInterfaces = new Set(getPorousInterfaces(simParam, staticVolumes, geometryTags));
    const hasPorousInterfaces = inSurfaces.some((surface) => porousInterfaces.has(surface));
    if (hasPorousInterfaces !== forceNode.props?.porous) {
      updateOutputNode((newOutput) => {
        if (newOutput.nodeProps.case === 'force' && newOutput.nodeProps.value.props) {
          newOutput.nodeProps.value.props.porous = hasPorousInterfaces;
        }
      });
    }
  }, [expand, geometryTags, node, simParam, staticVolumes, updateOutputNode]);

  const showWarning = useMemo(() => {
    if (!getForceNode(node)) {
      return false;
    }
    const inSurfaces = expand(node.inSurfaces);
    const porousSurfaces = new Set(getPorousSurfaces(simParam, staticVolumes, geometryTags));
    const wallSurfaces = getWallSurfacesWithTags(simParam, geometryTags);

    return showPorousSurfacesWarning(
      inSurfaces,
      porousSurfaces,
      wallSurfaces,
      missingVolumes.length > 0,
    );
  }, [expand, geometryTags, missingVolumes.length, node, simParam, staticVolumes]);

  // this is for the edge case where the user uploads settings and the porous models do not have
  // a name
  const warningText = useMemo(() => {
    const list = wordsToList(missingVolumes.map((volume) => volume.porousName));
    const missing = list.length ? list : 'the selected porous media';
    return `Please select all bounding surfaces of ${missing} for an accurate aggregate force.`;
  }, [missingVolumes]);

  /** Add all missing porous surfaces to the existing In Surfaces */
  const addAllMissingSurfaces = useCallback(() => {
    updateOutputNode((newOutput) => {
      // The elements in In Surfaces must be unique and keep existing group structure
      const newSurfaces = newOutput.inSurfaces;
      const existingSurfaces = new Set(expand(newSurfaces));
      const missingSurfaces = missingVolumes.flatMap((volume) => volume.surfaces);
      missingSurfaces.forEach(
        (surface) => !existingSurfaces.has(surface) && newSurfaces.push(surface),
      );
      newOutput.inSurfaces = newSurfaces;
    });
  }, [expand, missingVolumes, updateOutputNode]);

  const dismissWarning = () => {
    setMissingVolumes([]);
  };

  return { showWarning, addAllMissingSurfaces, dismissWarning, warningText };
}
