// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useEffect, useMemo } from 'react';

import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import * as transfpb from '../../proto/cad/transformation_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useStaticVolumes } from '../../recoil/volumes';
import environmentState from '../../state/environment';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useProjectContext } from '../context/ProjectContext';

export const useLcvCoordinateVisualizer = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const lcvEnabled = useLcVisEnabledValue(projectId);
  const lcvReady = environmentState.use.lcvisReady;
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  const clear = useCallback(() => {
    if (lcvEnabled && lcvReady) {
      const display = lcvHandler.display;
      const lcvWorkspace = display?.getWorkspace();
      lcvWorkspace?.clearSelectedFrame();
      lcvWorkspace?.clearMotionData();
    }
  }, [lcvEnabled, lcvReady]);

  const show = useCallback((frameId: string) => {
    if (!(lcvEnabled && lcvReady)) {
      return;
    }

    const display = lcvHandler.display;
    const lcvWorkspace = display?.getWorkspace();
    lcvWorkspace?.setMotionData(simParam, staticVolumes, geometryTags);
    lcvWorkspace?.showSelectedFrame(frameId);
  }, [lcvEnabled, lcvReady, simParam, staticVolumes, geometryTags]);

  useEffect(() => () => clear(), [clear]);

  return { show, clear };
};

export const useLcvOriginVisualizer = () => {
  const { projectId } = useProjectContext();
  const lcvEnabled = useLcVisEnabledValue(projectId);
  const lcvReady = environmentState.use.lcvisReady;

  const clear = useCallback(() => {
    if (lcvEnabled && lcvReady) {
      lcvHandler.display?.setOriginVisibility(false);
    }
  }, [lcvEnabled, lcvReady]);

  const show = useCallback(() => {
    if (lcvEnabled && lcvReady) {
      lcvHandler.display?.setOriginVisibility(true);
    }
  }, [lcvEnabled, lcvReady]);

  useEffect(() => () => clear(), [clear]);

  return { show, clear };
};

export const useLcvCoordinateVisualizerGeo = (
  transf:
    transfpb.AugmentedMatrix |
    transfpb.Translation |
    transfpb.Rotation |
    transfpb.Scaling |
    transfpb.Reflection |
    undefined,
  id: string,
) => {
  const { projectId } = useProjectContext();
  const lcvEnabled = useLcVisEnabledValue(projectId);
  const lcvReady = environmentState.use.lcvisReady;
  const supportsCoordinateVisualizer = useMemo(() => (
    transf instanceof transfpb.Rotation ||
    transf instanceof transfpb.Reflection || transf instanceof transfpb.Scaling
  ), [transf]);

  useEffect(() => {
    if (lcvEnabled && lcvReady && supportsCoordinateVisualizer) {
      const display = lcvHandler.display;
      const lcvWorkspace = display?.getWorkspace();
      lcvWorkspace?.showGeoReferenceFrame(transf, id);
    }
  }, [id, lcvEnabled, lcvReady, transf, supportsCoordinateVisualizer]);

  const clear = useCallback(() => {
    if (lcvEnabled && lcvReady && supportsCoordinateVisualizer) {
      const display = lcvHandler.display;
      const lcvWorkspace = display?.getWorkspace();
      lcvWorkspace?.clearSelectedFrame();
    }
  }, [lcvEnabled, lcvReady, supportsCoordinateVisualizer]);

  const show = useCallback(() => {
    if (!(lcvEnabled && lcvReady && supportsCoordinateVisualizer)) {
      return;
    }

    const display = lcvHandler.display;
    const lcvWorkspace = display?.getWorkspace();
    lcvWorkspace?.showSelectedFrame(id);
  }, [lcvEnabled, lcvReady, id, supportsCoordinateVisualizer]);

  useEffect(() => () => clear(), [clear]);

  return { show, clear };
};
