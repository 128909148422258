// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { RefObject, forwardRef, useCallback, useMemo, useRef } from 'react';

import { rollupGroups } from '../../../lib/entityGroupUtils';
import { GEOMETRY_TREE_NODE_TYPES, NodeType, TRANSPARENCY_NODE_TYPES } from '../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { OVERLAY_CARD_WIDTH } from '../../../lib/visUtils';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useFloatingPanelTopRight } from '../../../recoil/lcvis/infoCardDims';
import { TRANSPARENCY_MODE_SUBTITLE, defaultTransparencySettings, useTransparencySettings } from '../../../recoil/lcvis/transparencySettings';
import { useSetEntitySelection } from '../../../recoil/selectionOptions';
import CheckBox from '../../Form/CheckBox';
import LabeledInput from '../../Form/LabeledInput';
import { useDraggable } from '../../Paraview/draggable';
import { useProjectContext } from '../../context/ProjectContext';
import { Dialog } from '../../dialog/Base';
import { useFarfieldTransparency } from '../../hooks/useFarfieldTransparency';
import { NodeSubselect } from '../../treePanel/NodeSubselect';

const TRANSPARENCY_DRAG_ID = 'transparency-panel';

const TransparencyDialog = forwardRef((
  props,
  ref,
) => {
  const overlayRef = ref as RefObject<HTMLDivElement>;
  const { projectId, workflowId, jobId } = useProjectContext();
  const { initDrag, draggedPositions, dragPositionToStyle, currentDragId } = useDraggable();
  const dialogNode = useRef<HTMLDivElement>(null);
  const isDragging = currentDragId === TRANSPARENCY_DRAG_ID;
  const [transparencySettings, setTransparencySettings] = useTransparencySettings();
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const setEntitySelection = useSetEntitySelection(projectId);
  const { setFarfieldTransparency } = useFarfieldTransparency();

  const { active, surfaces, invert, collapsed } = transparencySettings;

  const topRight = useFloatingPanelTopRight(dialogNode);

  const groupedSurfaces = useMemo(
    () => rollupGroups(entityGroupData)([...surfaces]),
    [entityGroupData, surfaces],
  );

  const onMouseDown = (event: any) => {
    if (overlayRef.current && dialogNode.current) {
      initDrag(
        TRANSPARENCY_DRAG_ID,
        event,
        dialogNode.current,
        overlayRef.current,
      );
    }
  };

  const nodeFilter = useCallback((node: NodeType) => {
    const nodeType = node as NodeType;
    if (NodeType[nodeType].includes('ROOT') || NodeType[nodeType].includes('CONTAINER')) {
      // check if the nodetype name contains ROOT or CONTAINER
      if (GEOMETRY_TREE_NODE_TYPES.has(nodeType)) {
        return { related: true };
      }
      return { related: false };
    }
    if (
      [NodeType.PHYSICAL_BEHAVIOR, NodeType.PARTICLE_GROUP, NodeType.FILTER].includes(nodeType)
    ) {
      // We don't allow transparency for these nodes
      return {
        related: true,
        disabled: true,
        tooltip: 'Only surfaces are eligible for being made transparent.',
      };
    }
    if (GEOMETRY_TREE_NODE_TYPES.has(nodeType)) {
      return { related: true, disabled: false };
    }
    return defaultNodeFilter(node);
  }, []);

  const child = (
    <>
      <NodeSubselect
        autoStart={!transparencySettings.surfaces.size}
        id="transparencySelect"
        labels={['Surfaces']}
        nodeFilter={nodeFilter}
        nodeIds={groupedSurfaces}
        onChange={(selected) => {
          setTransparencySettings((currentState) => ({
            ...currentState,
            surfaces: new Set<string>(selected),
          }));
        }}
        onStart={() => setEntitySelection('surface')}
        referenceNodeIds={[NodeType[NodeType.SURFACE_CONTAINER]]}
        title="Select Surfaces"
        visibleTreeNodeTypes={TRANSPARENCY_NODE_TYPES}
      />
      <LabeledInput
        help={
          `Rather than making the selected surfaces transparent,
          make all other surfaces transparent.`
        }
        label="Invert Transparency"
        layout="favorLabel">
        <CheckBox
          checked={invert}
          onChange={() => setTransparencySettings((currentState) => ({
            ...currentState,
            invert: !invert,
          }))}
        />
      </LabeledInput>
      <br />
      <span style={{ fontSize: '12px' }}>
        {TRANSPARENCY_MODE_SUBTITLE}
        Non-geometry surfaces are not yet supported.
      </span>
    </>
  );

  return (
    <div
      ref={dialogNode}
      style={{
        position: 'absolute',
        zIndex: 1000,
        transition: 'top 0.2s',
        ...topRight,
        ...dragPositionToStyle(draggedPositions[TRANSPARENCY_DRAG_ID]),
      }}>
      <Dialog
        collapsible={{
          collapsed,
          onToggle: () => {
            setTransparencySettings((currentState) => ({
              ...currentState,
              collapsed: !currentState.collapsed,
            }));
          },
        }}
        compact
        draggable={!!onMouseDown}
        dragging={isDragging}
        onClose={() => {
          setTransparencySettings(defaultTransparencySettings());
          setFarfieldTransparency();
        }}
        onFrameMousedown={onMouseDown}
        open={active}
        title="Transparency Mode"
        width={OVERLAY_CARD_WIDTH}>
        {child}
      </Dialog>
    </div>
  );
});

export default TransparencyDialog;
