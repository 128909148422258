// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { getNodeTypeIcon } from '../../../lib/simulationTree/nodeIcon';
import * as basepb from '../../../proto/base/base_pb';
import { useListPostProcessingExtracts } from '../../../recoil/vis/postProcessingExtract';
import { useProjectContext } from '../../context/ProjectContext';
import { TreeRow } from '../TreeRow';

export const PostProcessingExtract = (props: SimulationRowProps) => {
  const { projectId } = useProjectContext();
  const { node } = props;
  const postProcessingExtracts = useListPostProcessingExtracts(projectId);
  const extract = useMemo(() => postProcessingExtracts.extracts.find((ext) => (
    ext.id === node.id
  )), [node.id, postProcessingExtracts]);

  const nodeIcon = useMemo(() => {
    if (extract?.status?.typ === basepb.JobStatusType.Active) {
      return { name: 'circle' } as IconSpec;
    }
    return getNodeTypeIcon(node.type);
  }, [extract?.status?.typ, node.type]);

  return (
    <TreeRow
      {...props}
      primaryIcon={nodeIcon}
    />
  );
};
