// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as geometryservicepb from '../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometry from '../../../proto/geometry/geometry_pb';
import { BusyState, useGeometryBusyMessageSelector, useGeometryBusyState, useIsGeoServerCreatingFeature } from '../../../recoil/geometry/geometryServerStatus';
import { GeometryState, useGeometryState } from '../../../recoil/geometry/geometryState';
import { useProjectContext } from '../../context/ProjectContext';

function getFeatureInProgress(
  isGeoServerCreatingFeature: boolean,
  geometryBusyMessage: string,
  geometryBusyState: BusyState,
  geometryState: GeometryState | undefined,
): [string | undefined, geometry.Feature | undefined] {
  // Progress reporting is only valid when the status is busy and when the progress messages
  // is not empty. This is because the server may report a busy state without a progress
  // message.
  if (!isGeoServerCreatingFeature || !geometryBusyState || !geometryBusyMessage) {
    return [undefined, undefined];
  }

  const isFeatureProgress = geometryBusyState?.BusyStateType?.case === 'featureProgress';
  // Only show the progress panel if the feature is an import operation or during the initial load
  // step.
  if (!isFeatureProgress) {
    return [undefined, undefined];
  }
  const { featureId = '' } = geometryBusyState?.BusyStateType?.value as
    geometryservicepb.SubscribeGeometryResponse_BusyState_FeatureProgress;

  return [
    featureId,
    geometryState?.geometryFeatures.find((feature) => feature.id === featureId),
  ];
}

export function useIsInitialLoadOrImportFeature() {
  // == Context
  const { projectId, geometryId } = useProjectContext();

  // == State
  const geometryState = useGeometryState(projectId, geometryId);
  const [geometryBusyState] = useGeometryBusyState(geometryId);
  const geometryBusyMessage = useGeometryBusyMessageSelector(geometryId);
  const isGeoServerCreatingFeature = useIsGeoServerCreatingFeature(geometryId);

  const [, feature] = getFeatureInProgress(
    isGeoServerCreatingFeature,
    geometryBusyMessage,
    geometryBusyState,
    geometryState,
  );
  return feature?.operation?.value instanceof geometry.Import;
}

export function useReportGeometryProgress() {
  // == Context
  const { projectId, geometryId } = useProjectContext();

  // == State
  const geometryState = useGeometryState(projectId, geometryId);
  const [geometryBusyState] = useGeometryBusyState(geometryId);
  const geometryBusyMessage = useGeometryBusyMessageSelector(geometryId);
  const isGeoServerCreatingFeature = useIsGeoServerCreatingFeature(geometryId);

  const [featureIdInProgress, feature] = getFeatureInProgress(
    isGeoServerCreatingFeature,
    geometryBusyMessage,
    geometryBusyState,
    geometryState,
  );
  // We have the feature in our state, it means that we started the operation. Report progress for
  // import and shrinkwrap operations since they are the slowest.
  if (feature) {
    const val = feature.operation?.value;
    return val instanceof geometry.Import || val instanceof geometry.Shrinkwrap;
  }
  // If featureIdInProgress is not undefined or empty, it means that the server is reporting
  // progress for a feature we are not aware of in our state. Report progress to make sure the
  // users see the progress. This happens when using the SDK for example.
  return !!featureIdInProgress;
}
