// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 64;
const baseHeight = 64;
export const AirplaneOutlineIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      fill="none"
      height={finalHeight}
      ref={ref}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37 31.999C37 33.104 36.105 33.999 35 33.999C33.895 33.999 33 33.104 33
        31.999C33 30.894 33.895 29.999 35 29.999C36.105 29.999 37 30.895 37 31.999Z"
        fill={getColor(0)}
      />
      <path
        d="M27 29.999C26.7374 29.999 26.4773 30.0507 26.2346 30.1512C25.992 30.2518
        25.7715 30.3991 25.5858 30.5848C25.4001 30.7705 25.2528 30.991 25.1522
        31.2336C25.0517 31.4763 25 31.7364 25 31.999C25 32.2616 25.0517 32.5217 25.1522
        32.7644C25.2528 33.007 25.4001 33.2275 25.5858 33.4132C25.7715 33.5989 25.992
        33.7463 26.2346 33.8468C26.4773 33.9473 26.7374 33.999 27 33.999C27.2626 33.999
        27.5227 33.9473 27.7654 33.8468C28.008 33.7463 28.2285 33.5989 28.4142
        33.4132C28.5999 33.2275 28.7472 33.007 28.8478 32.7644C28.9483 32.5217 29 32.2616
        29 31.999C29 31.7364 28.9483 31.4763 28.8478 31.2336C28.7472 30.991 28.5999
        30.7705 28.4142 30.5848C28.2285 30.3991 28.008 30.2518 27.7654 30.1512C27.5227
        30.0507 27.2626 29.999 27 29.999Z"
        fill={getColor(0)}
      />
      <path
        d="M36.0505 25.1L36.0205 25.0644L24.2876 11.1315C23.7355 10.477 22.9265 10.1 22.068
        10.1H18.655C17.983 10.1 17.3755 10.4427 17.0297 11.0176L17.0296 11.0177C16.6827
        11.5917 16.6618 12.2894 16.9745 12.8844L36.0505 25.1ZM36.0505 25.1H36.097H52C56.9078
        25.1 60.9 29.0922 60.9 34C60.9 36.7018 58.7018 38.9 56 38.9H36.097H36.0505L36.0205
        38.9356L24.2875 52.8676L24.2875 52.8676C23.7365 53.523 22.9275 53.9 22.068
        53.9H18.655C17.9831 53.9 17.3765 53.5574 17.0286 52.9813L17.0286 52.9812C16.6808 52.4064
        16.6617 51.7097 16.9745 51.1145L23.3265 39.0466L23.4036 38.9H23.238H12C7.09224 38.9 3.1
        34.9068 3.1 29.999V17.999C3.1 16.4013 4.40121 15.1 6 15.1H7.293C9.30811 15.1 11.0933
        16.3081 11.8421 18.1801C11.8421 18.1801 11.8422 18.1801 11.8422 18.1801L14.5841
        25.0371L14.6093 25.1H14.677H23.239H23.4046L23.3275 24.9534L16.9745 12.8845L36.0505
        25.1ZM22.9115 12.2916L22.9114 12.2915C22.7014 12.043 22.3945 11.9 22.068
        11.9H18.655H18.4893L18.5665 12.0466L19.6195 14.0466L19.6476
        14.1H19.708H24.219H24.4339L24.2955 13.9356L22.9115 12.2916ZM20.761
        15.9H20.5953L20.6725 16.0466L25.4105 25.0466L25.4386 25.1H25.499H33.482H33.6969L33.5585
        24.9356L25.9795 15.9356L25.9495 15.9H25.903H20.761ZM25.902 48.1H25.9485L25.9785
        48.0644L36.9275 35.0644L37.066 34.9H36.851H28.21C27.7991 34.9 27.4239 35.1254 27.2325
        35.4894C27.2325 35.4894 27.2325 35.4895 27.2325 35.4895L20.6725 47.9534L20.5954
        48.1H20.761H25.902ZM18.5675 51.9534L18.4904 52.1L22.833 51.644L22.9095 51.7084L22.9097
        51.7082C22.9097 51.7082 22.9097 51.7082 22.9097 51.7082L24.2935 50.0644L24.4319
        49.9H24.217H19.708H19.6476L19.6195 49.9534L18.5675 51.9534ZM37.7045 36.9356L37.566
        37.1H37.781H56C57.7092 37.1 59.1 35.7092 59.1 34C59.1 30.0858 55.9142 26.9 52
        26.9H13.3907L10.1698 18.8479C9.69666 17.6649 8.56689 16.9 7.293 16.9H6C5.39284 16.9 4.9
        17.3927 4.9 17.999V29.999C4.9 33.9142 8.08576 37.1 12 37.1H24.291H24.3514L24.3795
        37.0466L25.6405 34.6506C26.1442 33.6935 27.1291 33.1 28.21 33.1H36.85C37.5981 33.1 38.2577
        33.5204 38.5733 34.1991C38.888 34.8769 38.7854 35.6524 38.3035 36.2246L37.7045 36.9356Z"
        fill={getColor(0)}
        stroke="#2F3034"
        strokeWidth="0.2"
      />
      <path
        d="M51 29.999C50.7374 29.999 50.4773 30.0507 50.2346 30.1512C49.992 30.2518 49.7715 30.3991
        49.5858 30.5848C49.4001 30.7705 49.2528 30.991 49.1522 31.2336C49.0517 31.4763 49 31.7364 49
        31.999C49 32.2616 49.0517 32.5217 49.1522 32.7644C49.2528 33.007 49.4001 33.2275 49.5858
        33.4132C49.7715 33.5989 49.992 33.7463 50.2346 33.8468C50.4773 33.9473 50.7374 33.999 51
        33.999C51.2626 33.999 51.5227 33.9473 51.7654 33.8468C52.008 33.7463 52.2285 33.5989 52.4142
        33.4132C52.5999 33.2275 52.7472 33.007 52.8478 32.7644C52.9483 32.5217 53 32.2616 53
        31.999C53 31.7364 52.9483 31.4763 52.8478 31.2336C52.7472 30.991 52.5999 30.7705 52.4142
        30.5848C52.2285 30.3991 52.008 30.2518 51.7654 30.1512C51.5227 30.0507 51.2626 29.999 51
        29.999Z"
        fill={getColor(0)}
      />
      <path
        d="M43 29.999C42.7374 29.999 42.4773 30.0507 42.2346 30.1512C41.992 30.2518 41.7715 30.3991
        41.5858 30.5848C41.4001 30.7705 41.2528 30.991 41.1522 31.2336C41.0517 31.4763 41 31.7364 41
        31.999C41 32.2616 41.0517 32.5217 41.1522 32.7644C41.2528 33.007 41.4001 33.2275 41.5858
        33.4132C41.7715 33.5989 41.992 33.7463 42.2346 33.8468C42.4773 33.9473 42.7374 33.999 43
        33.999C43.2626 33.999 43.5227 33.9473 43.7654 33.8468C44.008 33.7463 44.2285 33.5989 44.4142
        33.4132C44.5999 33.2275 44.7472 33.007 44.8478 32.7644C44.9483 32.5217 45 32.2616 45
        31.999C45 31.7364 44.9483 31.4763 44.8478 31.2336C44.7472 30.991 44.5999 30.7705 44.4142
        30.5848C44.2285 30.3991 44.008 30.2518 43.7654 30.1512C43.5227 30.0507 43.2626 29.999 43
        29.999Z"
        fill={getColor(0)}
      />
      <path
        d="M19 29.999C18.7374 29.999 18.4773 30.0507 18.2346 30.1512C17.992 30.2518 17.7715 30.3991
        17.5858 30.5848C17.4001 30.7705 17.2528 30.991 17.1522 31.2336C17.0517 31.4763 17 31.7364
        17 31.999C17 32.2616 17.0517 32.5217 17.1522 32.7644C17.2528 33.007 17.4001 33.2275 17.5858
        33.4132C17.7715 33.5989 17.992 33.7463 18.2346 33.8468C18.4773 33.9473 18.7374 33.999 19
        33.999C19.2626 33.999 19.5227 33.9473 19.7654 33.8468C20.008 33.7463 20.2285 33.5989 20.4142
        33.4132C20.5999 33.2275 20.7472 33.007 20.8478 32.7644C20.9483 32.5217 21 32.2616 21
        31.999C21 31.7364 20.9483 31.4763 20.8478 31.2336C20.7472 30.991 20.5999 30.7705 20.4142
        30.5848C20.2285 30.3991 20.008 30.2518 19.7654 30.1512C19.5227 30.0507 19.2626 29.999 19
        29.999Z"
        fill={getColor(0)}
      />
    </svg>
  );
});
