// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { PartialMessage } from '@bufbuild/protobuf';

import { MultipleChoiceParam } from '../ProtoDescriptor';
import { ParamName, paramDesc } from '../SimulationParamDescriptor';
import * as basepb from '../proto/base/base_pb';
import * as cadmetadatapb from '../proto/cadmetadata/cadmetadata_pb';
import { EntityIdentifier } from '../proto/client/entity_pb';
import * as simulationpb from '../proto/client/simulation_pb';
import { EntityGroup, EntityGroups, EntityType } from '../proto/entitygroup/entitygroup_pb';
import { Exploration, GridSearch, Range, Var, VarSpec, VarType } from '../proto/exploration/exploration_pb';
import {
  GetWorkflowReply,
  GetWorkflowReply_Job,
  PendingWorkOrders,

  Solution,
} from '../proto/frontend/frontend_pb';
import {
  BasicNode,
  CalculationType,
  CustomField,
  CustomFields,
  DerivedNode,
  DerivedNodeDependency,
  ExpressionElement,
  ForceNode,
  OutputIncludes,
  OutputNode,
  OutputNode_Type,
  OutputNodes,
  PointProbeNode,
  ResidualNode,
  SurfaceAverageNode,
  VolumeReductionNode,
} from '../proto/frontend/output/output_pb';
import { MeshFileMetadata } from '../proto/lcn/lcmesh_pb';
import {
  MeshingMultiPart,
  MeshingMultiPart_BoundaryLayerParams_SelectionType,
  MeshingMultiPart_ModelParams_SelectionType,
  MeshingMultiPart_VolumeParams_SelectionType,
  UserMeshingParams,
} from '../proto/meshgeneration/meshgeneration_pb';
import {
  BasicType,
  DerivedType,
  ForceDirectionType,
  ForceProperties,
  Output,
  PointProbeType,
  ResidualProperties,
  ResidualType,
  SpaceAveragingType,
  SurfaceAverageProperties,
  TimeAnalysisType,
  VolumeReductionProperties,
  VolumeReductionType,
} from '../proto/output/output_pb';
import * as papb from '../proto/platformadmin/platformadmin_pb';
import { PlotSettings, PlotSettings_MonitorPlot, Plots } from '../proto/plots/plots_pb';
import {
  CheckedUrls,
  FrontendMenuState,
  InputFilename,
  MeshUrl,
} from '../proto/projectstate/projectstate_pb';
import { QuantityType } from '../proto/quantity/quantity_pb';
import { Axis, Axis_Coordinate, Metadata, Record, Record_Entry, RectilinearTable, TableType } from '../proto/table/table_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';
import { ViewState } from '../pvproto/ParaviewRpc';
import { GeometryTags } from '../recoil/geometry/geometryTagsObject';
import { JobState } from '../recoil/jobState';
import { StaticVolume } from '../recoil/volumes';

import { ParamScope, chainParamScopes, createParamScope } from './ParamScope';
import { getAdValue, newAdFloat, newScalarAdVector } from './adUtils';
import assert from './assert';
import { findFarfield } from './boundaryConditionUtils';
import { getOrCreateEntityRelationship } from './entityRelationships';
import { newRealValue } from './explorationUtils';
import { appendMaterial, findMaterialEntityById } from './materialUtils';
import { MeshMetadata } from './mesh';
import {
  ALL_RESIDUALS,
  INNER_ITER_ENUM_NUMBER,
  RESIDUAL_ENUM_NUMBER,
  createResNode,
  includePrefix,
  pointTypeChoices,
  quantityTypeToEnumNumber,
  updateResidualChoices,
  volumeTypeChoices,
} from './outputNodeUtils';
import { DEFAULT_URLS } from './paramDefaults/checkedUrls';
import { DEFAULT_FRONTEND_MENU_STATE } from './paramDefaults/frontendMenuState';
import { DEFAULT_MESH_GENERATION } from './paramDefaults/meshGenerationState';
import { DEFAULT_MESHING_MULTIPART } from './paramDefaults/meshingMultiPartState';
import { DEFAULT_OUTPUT_NODES } from './paramDefaults/outputNodesState';
import { defaultConfig } from './paramDefaults/workflowConfig';
import { appendPhysicalBehavior } from './physicalBehaviorUtils';
import { appendFluidPhysics, getFluid, getOrCreatePhysicsIdentifier, getPhysicsId } from './physicsUtils';
import { newNodeId } from './projectDataUtils';
import { getSimulationParam } from './simulationParamUtils';

const { FARFIELD_DIRECTION } = simulationpb.FarFieldFlowDirectionSpecification;

export const TestExperiments: string[] = [];

// The following are fixtures for various recoil states that are used for testing.
export function entityGroupFixture(): EntityGroups {
  const group = new EntityGroups({ upgraded: true, groupPrefixRemoved: true });

  const wing1 = new EntityGroup({
    parent: 'wings',
    id: '0/bound/wing1',
    name: 'Wing 1',
    type: EntityType.SURFACE,
  });
  const wing2 = new EntityGroup({
    parent: 'wings',
    id: '0/bound/wing2',
    name: 'Wing 2',
    type: EntityType.SURFACE,
  });
  const wingsGroup = new EntityGroup({
    id: 'wings',
    children: ['0/bound/wing1', '0/bound/wing2'],
    name: 'Wings',
    parent: 'airplane',
    type: EntityType.SURFACE,
  });
  const fuselage = new EntityGroup({
    id: '0/bound/fuselage',
    parent: 'airplane',
    name: 'Fuselage',
    type: EntityType.SURFACE,
  });
  const propeller1 = new EntityGroup({
    name: 'Propeller 1',
    id: 'propeller1',
    parent: 'propeller',
    type: EntityType.PARTICLE_GROUP,
  });
  const propeller2 = new EntityGroup({
    children: ['propeller2'],
    name: 'Propeller 2',
    id: 'propeller2',
    parent: 'propeller',
    type: EntityType.PARTICLE_GROUP,
  });
  const propellerGroup = new EntityGroup({
    name: 'Propeller',
    children: ['propeller1', 'propeller2'],
    id: 'propeller',
    parent: 'airplane',
    type: EntityType.PARTICLE_GROUP,
  });
  const airplaneGroup = new EntityGroup({
    id: 'airplane',
    parent: 'root',
    name: 'Airplane',
    children: ['propeller', 'fuselage', 'wings'],
    type: EntityType.MIXED,
  });
  const farfield = new EntityGroup({
    parent: 'root',
    id: '0/bound/farfield',
    name: 'Farfield',
    type: EntityType.SURFACE,
  });
  const volume = new EntityGroup({
    parent: 'root',
    id: 'volume-0',
    name: 'Volume 1',
    type: EntityType.VOLUME,
  });

  const groups = [
    airplaneGroup,
    propellerGroup,
    wingsGroup,
    wing1,
    wing2,
    fuselage,
    propeller1,
    propeller2,
    farfield,
    volume,
  ];

  groups.forEach((groupToAdd) => {
    group.groups[groupToAdd.id] = groupToAdd;
  });

  return group;
}

interface SinglePhysicsFixtureData {
  config: workflowpb.Config;
  param: simulationpb.SimulationParam;
  physics: simulationpb.Physics;
  fluid: simulationpb.Fluid;
  material: simulationpb.MaterialEntity;
  geometryTags: GeometryTags;
  staticVolumes: StaticVolume[];
}

export function meshMetadataFixture(): MeshMetadata {
  return {
    meshMetadata: new MeshFileMetadata({
      zone: [
        {
          name: '0',
          bound: [
            { name: '0/bound/wing1' },
            { name: '0/bound/wing2' },
            { name: '0/bound/fuselage' },
          ],
        },
      ],
    }),
    solnMetadata: null,
  };
}

export function meshUrlFixture(): MeshUrl {
  return new MeshUrl({
    url: 'testurl',
    geometry: 'testurl',
    meshId: 'testmeshid',
  });
}

export function singlePhysicsParamScope(fixture: SinglePhysicsFixtureData): ParamScope {
  const paramScope = createParamScope(fixture.param, TestExperiments);
  return chainParamScopes(
    [fixture.physics, fixture.material],
    TestExperiments,
    paramScope,
  );
}

export function singlePhysicsWorkflowConfigFixture(): SinglePhysicsFixtureData {
  const meshUrl = meshUrlFixture();
  const config = defaultConfig(meshUrl.geometry, null, meshUrl.meshId);
  const param = getSimulationParam(config);

  // Initialize test fluid physics
  const physics = appendFluidPhysics(param);
  getOrCreatePhysicsIdentifier(physics).id = 'physics-1';
  const physicsId = getPhysicsId(physics);
  const fluid = getFluid(physics)!;

  // Initialize test fluid material
  const materialId = appendMaterial(param, 'materialFluid');
  const material = findMaterialEntityById(param, materialId)!;

  const entityRels = getOrCreateEntityRelationship(param);
  entityRels.volumeMaterialRelationship.push(
    new simulationpb.VolumeMaterialRelationship({
      materialIdentifier: new EntityIdentifier({ id: materialId }),
      volumeIdentifier: new EntityIdentifier({ id: '0' }),
    }),
  );
  entityRels.volumePhysicsRelationship.push(
    new simulationpb.VolumePhysicsRelationship({
      physicsIdentifier: new EntityIdentifier({ id: physicsId }),
      volumeIdentifier: new EntityIdentifier({ id: '0' }),
    }),
  );

  fluid.boundaryConditionsFluid.push(
    new simulationpb.BoundaryConditionsFluid({
      farfieldMomentum: simulationpb.FarfieldMomentum.FARFIELD_MACH_NUMBER,
      farfieldPressure: newAdFloat(90000),
      farfieldTemperature: newAdFloat(300),
      farfieldMachNumber: newAdFloat(0.5),
      farfieldVelocityMagnitude: newAdFloat(1.1),
      boundaryConditionName: 'Farfield',
      farFieldFlowDirectionSpecification: FARFIELD_DIRECTION,
      boundaryConditionDisplayName: 'FarfieldName',
      physicalBoundary: simulationpb.PhysicalBoundary.FARFIELD,
      surfaces: ['0/bound/farfield'],
    }),
    new simulationpb.BoundaryConditionsFluid({
      physicalBoundary: simulationpb.PhysicalBoundary.WALL,
      boundaryConditionName: 'Wall',
      boundaryConditionDisplayName: 'WallName',
      surfaces: ['0/bound/wing1', '0/bound/wing2', 'propeller', 'propeller1', 'propeller2'],
    }),
  );
  const behavior = appendPhysicalBehavior(
    param,
    physicsId,
    (paramDesc[ParamName.PhysicalBehaviorModel] as MultipleChoiceParam).choices.find(
      (choice) => choice.enumNumber === simulationpb.PhysicalBehaviorModel.ACTUATOR_DISK_MODEL,
    )!,
  );
  behavior.physicalBehaviorId = 'behavior-1';
  behavior.physicalBehaviorName = 'Behavior 1';

  param.particleGroup.push(
    new simulationpb.ParticleGroup({
      particleGroupId: 'propeller1',
      particleGroupName: 'Propeller 1',
      particleGroupBehaviorModelRef: 'behavior-1',
    }),
    new simulationpb.ParticleGroup({
      particleGroupId: 'propeller2',
      particleGroupName: 'Propeller 2',
      particleGroupBehaviorModelRef: 'behavior-1',
    }),
  );

  const rotationFrameId = 'rotation-frame';
  param.motionData.push(
    new simulationpb.MotionData({
      frameId: rotationFrameId,
      frameName: rotationFrameId,
      motionType: simulationpb.MotionType.CONSTANT_ANGULAR_MOTION,
      motionAngularVelocity: newScalarAdVector(1),
      motionRotationAngles: newScalarAdVector(1),
    }),
  );

  config.jobConfigTemplate = new workflowpb.JobConfig({
    typ: { case: 'simulationParam', value: param },
  });
  config.exploration = new Exploration({
    name: 'Design of Experiments',
    policy: { case: 'gridSearch', value: new GridSearch() },
    var: [
      new Var({
        spec: new VarSpec({
          field: 'time_step_val',
          type: VarType.GLOBAL,
          text: 'Physical Time Step',
        }),
        valueTyp: {
          case: 'range',
          value: new Range({
            min: newRealValue(newAdFloat(100_000)),
            max: newRealValue(newAdFloat(103_000)),
            nSamples: 4,
          }),
        },
      }),
      new Var({
        spec: new VarSpec({
          field: 'farfield_angle_alpha',
          type: VarType.BOUNDARY,
          text: 'Farfield Angle Alpha',
          id: 'Farfield',
        }),
        valueTyp: {
          case: 'range',
          value: new Range({
            min: newRealValue(newAdFloat(101325)),
            max: newRealValue(newAdFloat(101325)),
            nSamples: 2,
          }),
        },
      }),
    ],
  });

  const geometryTags = new GeometryTags(undefined);
  const staticVolumes: StaticVolume[] = [];

  return { config, param, physics, fluid, material, geometryTags, staticVolumes };
}

export function cadMetadataFixture(): cadmetadatapb.CadMetadata {
  return new cadmetadatapb.CadMetadata();
}

export function inputFilenameFixture(): InputFilename {
  return new InputFilename({ name: 'testurl' });
}

export function pendingWorkOrdersFixture(): PendingWorkOrders {
  return new PendingWorkOrders();
}

// Analyzer output messages corresponding to selected frontend output nodes
// defined in singlePhysicsOutputNodesFixture
export const residualOutputId = 'residual-1';
export function residualOutputFixture(): Output {
  return new Output({
    id: residualOutputId,
    timeAnalysis: TimeAnalysisType.TIME_AVERAGE,
    avgIters: 1,
    analysisIters: 1,
    outputProperties: {
      case: 'residualProperties',
      value: new ResidualProperties({ type: ResidualType.RESIDUAL_RELATIVE, physicsIndex: 1 }),
    },
  });
}

export const dragOutputId = 'drag-1';
export function dragOutputFixture(): Output {
  return new Output({
    quantity: QuantityType.DRAG,
    id: dragOutputId,
    inSurfaces: ['0/bound/wing1', '0/bound/wing2', 'propeller1', 'propeller2'],
    avgIters: 15,
    analysisIters: 20,
    outputProperties: {
      case: 'forceProperties',
      value: new ForceProperties({
        forceDirType: ForceDirectionType.FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR,
      }),
    },
  });
}

const derivedOutput3Dependencies: Output[] = [];
const derivedOutput3 = dragOutputFixture();
derivedOutput3.timeAnalysis = TimeAnalysisType.TIME_SERIES;
derivedOutput3.avgIters = 1;
derivedOutput3.id = `${includePrefix(OutputIncludes.OUTPUT_INCLUDE_BASE)}${dragOutputId}`;
derivedOutput3Dependencies.push(derivedOutput3);
export const derivedOutput3Id = 'derived-3';
export function derivedOutput3Fixture(): Output {
  return new Output({
    id: `${includePrefix(OutputIncludes.OUTPUT_INCLUDE_BASE)}${derivedOutput3Id}`,
    name: 'Custom Output 3 ',
    outputProperties: {
      case: 'derivedProperties',
      value: new DerivedType({
        expression: `${includePrefix(OutputIncludes.OUTPUT_INCLUDE_BASE)}${dragOutputId}`,
        dependencies: derivedOutput3Dependencies,
      }),
    },
    timeAnalysis: TimeAnalysisType.TIME_AVERAGE,
    avgIters: 1,
    analysisIters: 3,
  });
}

export const velocityOutputId = 'velocity-1';
export function velocityOutputFixture(): Output {
  return new Output({
    quantity: QuantityType.VELOCITY,
    id: velocityOutputId,
    inSurfaces: ['0/bound/wing1'],
    vectorComponent: basepb.Vector3Component.VECTOR_3_COMPONENT_Y,
    avgIters: 3,
    analysisIters: 2,
    outputProperties: {
      case: 'surfaceAverageProperties',
      value: new SurfaceAverageProperties({
        averagingType: SpaceAveragingType.SPACE_AREA_AVERAGING,
      }),
    },
  });
}

export const pointTempOutputId = 'temp-1';
export function pointTempOutputFixture(data?: PartialMessage<Output>): Output {
  return new Output({
    quantity: QuantityType.TEMPERATURE,
    id: pointTempOutputId,
    inSurfaces: ['point_1'],
    avgIters: 4,
    analysisIters: 3,
    outputProperties: { case: 'probeProperties', value: new PointProbeType() },
    ...data,
  });
}

export const innerIterCountId = 'inner-iter-count-1';
export function innerIterCountFixture(): Output {
  return new Output({
    quantity: QuantityType.INNER_ITERATION_COUNT,
    id: innerIterCountId,
    name: 'Inner Iteration Count',
    timeAnalysis: TimeAnalysisType.TIME_AVERAGE,
    avgIters: 1,
    analysisIters: 1,
    outputProperties: { case: 'basicProperties', value: new BasicType() },
  });
}

export const derivedOutput1Id = 'derived-1';
export function derivedOutput1Fixture(data?: PartialMessage<Output>): Output {
  return new Output({
    id: `${includePrefix(OutputIncludes.OUTPUT_INCLUDE_BASE)}${derivedOutput1Id}`,
    name: 'Custom Output 1',
    timeAnalysis: TimeAnalysisType.TIME_AVERAGE,
    avgIters: 1,
    analysisIters: 9,
    outputProperties: {
      case: 'derivedProperties',
      value: new DerivedType({ expression: '8*2', dependencies: [] }),
    },
    ...data,
  });
}

const derivedOutput2Dependencies = [
  derivedOutput1Fixture({
    timeAnalysis: TimeAnalysisType.TIME_SERIES,
    avgIters: 1,
    id: `${includePrefix(OutputIncludes.OUTPUT_INCLUDE_BASE)}${derivedOutput1Id}`,
  }),
  pointTempOutputFixture({
    timeAnalysis: TimeAnalysisType.TIME_SERIES,
    avgIters: 1,
    id: `${includePrefix(OutputIncludes.OUTPUT_INCLUDE_BASE)}${pointTempOutputId}`,
    name: 'Temperature (K)',
    shortName: '',
  }),
];

const derivedOutput2Expression = (
  `${includePrefix(OutputIncludes.OUTPUT_INCLUDE_BASE)}${derivedOutput1Id}/(8+` +
  `${includePrefix(OutputIncludes.OUTPUT_INCLUDE_BASE)}${pointTempOutputId})`
);

export const derivedOutput2Id = 'derived-2';
export function derivedOutput2Fixture(): Output {
  return new Output({
    id: `${includePrefix(OutputIncludes.OUTPUT_INCLUDE_BASE)}${derivedOutput2Id}`,
    name: 'Custom Output 2',
    timeAnalysis: TimeAnalysisType.TIME_AVERAGE,
    avgIters: 1,
    analysisIters: 9,
    outputProperties: {
      case: 'derivedProperties',
      value: new DerivedType({
        expression: derivedOutput2Expression,
        dependencies: derivedOutput2Dependencies,
      }),
    },
  });
}

const volumeOutputQuantity = QuantityType.VELOCITY;
const volumeVelocityChoice = volumeTypeChoices[0].choices
  .find((choice) => choice.data === volumeOutputQuantity);

export const volumeVelocityId = 'vol-vel-1';
export function volumeVelocityOutputFixture(): Output {
  return new Output({
    quantity: volumeOutputQuantity,
    vectorComponent: basepb.Vector3Component.VECTOR_3_COMPONENT_X,
    id: volumeVelocityId,
    inSurfaces: ['volume-0'],
    avgIters: 6,
    analysisIters: 6,
    outputProperties: {
      case: 'volumeReductionProperties',
      value: new VolumeReductionProperties({ reductionType: VolumeReductionType.VOLUME_AVERAGING }),
    },
  });
}

const pointOutputQuantity = QuantityType.TEMPERATURE;
const pointTempChoice = pointTypeChoices[0].choices.find(
  (choice) => choice.data === pointOutputQuantity,
);

export function singlePhysicsCustomFieldNodesFixture(fixture: SinglePhysicsFixtureData):
  CustomFields {
  const customFields = new CustomFields();
  customFields.customFields.push(new CustomField({
    name: 'Custom Expression 1',
    expression:
      '"Pressure"*4',
    id: 'custom-1',
  }));
  customFields.customFields.push(new CustomField({
    name: 'Custom Expression 2',
    expression:
      '"Velocity-X"/10',
    id: 'custom-2',
  }));
  customFields.customFields.push(new CustomField({
    name: 'Custom Expression 3',
    expression:
      '"Velocity-Magnitude"/1000',
    id: 'custom-3',
  }));
  return customFields;
}

export function singlePhysicsOutputNodesFixture(fixture: SinglePhysicsFixtureData): OutputNodes {
  const outputNodes = DEFAULT_OUTPUT_NODES.clone();

  const { param, physics, geometryTags, staticVolumes } = fixture;
  outputNodes.nodes.push(createResNode(param, TestExperiments, geometryTags, staticVolumes));
  // Overwrite the random id
  assert(
    outputNodes.nodes[0].nodeProps.case === 'residual',
    'TEST: first fixture output is not a residual',
  );
  outputNodes.nodes[0].id = residualOutputId;
  outputNodes.nodes[0].nodeProps.value.physicsId = getPhysicsId(physics);
  updateResidualChoices(
    outputNodes.nodes[0].nodeProps.value,
    param,
    TestExperiments,
    geometryTags,
    staticVolumes,
    true,
  );
  const forceDirection = newScalarAdVector(1, 2, 3);
  const momentCenter = newScalarAdVector(2, 3, 4);

  // Drag output should be enabled
  const dragNode = new OutputNode({
    inSurfaces: ['0/bound/wing1', '0/bound/wing2', 'propeller'],
    choice: quantityTypeToEnumNumber.get(QuantityType.DRAG),
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: dragOutputId,
    name: 'Drag',
    type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
    analysisIters: 20,
    averageIters: 15,
    trailAvgIters: 10,
    nodeProps: {
      case: 'force',
      value: new ForceNode({
        quantityType: QuantityType.DRAG,
        props: new ForceProperties({
          forceDirType: ForceDirectionType.FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR,
        }),
      }),

    },
  });
  outputNodes.nodes.push(dragNode);
  dragNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  dragNode.include[OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT] = true;
  // Lift output should be disabled because of incompatible ForceDirectionType
  const liftNode = new OutputNode({
    inSurfaces: ['0/bound/wing1', '0/bound/wing2', 'propeller'],
    choice: quantityTypeToEnumNumber.get(QuantityType.LIFT)!,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: 'lift-1',
    name: 'Lift',
    type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
    analysisIters: 1,
    averageIters: 1,
    trailAvgIters: 1,
    nodeProps: {
      case: 'force',
      value: new ForceNode({
        quantityType: QuantityType.LIFT,
        props: new ForceProperties({
          forceDirType: ForceDirectionType.FORCE_DIRECTION_CUSTOM,
          forceDirection,
        }),
      }),
    },
  });
  outputNodes.nodes.push(liftNode);
  liftNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  liftNode.include[OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT] = true;
  // Rolling Moment output should be disabled because of incompatible ForceDirectionType
  // and because it includes a non-wall bounary condition surface
  const rollNode = new OutputNode({
    inSurfaces: ['0/bound/wing1', '0/bound/wing2', '0/bound/farfield'],
    choice: quantityTypeToEnumNumber.get(QuantityType.ROLLING_MOMENT)!,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: 'roll-1',
    name: 'Rolling Moment',
    type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
    nodeProps: {
      case: 'force',
      value: new ForceNode({
        quantityType: QuantityType.ROLLING_MOMENT,
        props: new ForceProperties({
          forceDirType: ForceDirectionType.FORCE_DIRECTION_CUSTOM,
          forceDirection,
          momentCenter,
        }),
      }),
    },
  });
  outputNodes.nodes.push(rollNode);
  rollNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  rollNode.include[OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT] = true;
  // Force output should be enabled
  const forceNode = new OutputNode({
    inSurfaces: ['0/bound/wing1', '0/bound/wing2', 'propeller'],
    choice: quantityTypeToEnumNumber.get(QuantityType.TOTAL_FORCE)!,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: 'force-1',
    name: 'Force',
    type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
    nodeProps: {
      case: 'force',
      value: new ForceNode({
        quantityType: QuantityType.TOTAL_FORCE,
        props: new ForceProperties({
          forceDirType: ForceDirectionType.FORCE_DIRECTION_CUSTOM,
          forceDirection,
        }),
      }),
    },
  });
  outputNodes.nodes.push(forceNode);
  forceNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  forceNode.include[OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT] = true;
  // Density output should be disabled because it has no surfaces assigned
  const densityNode = new OutputNode({
    inSurfaces: [],
    choice: quantityTypeToEnumNumber.get(QuantityType.DENSITY)!,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: 'density-1',
    name: 'Density',
    type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
    nodeProps: {
      case: 'surfaceAverage',
      value: new SurfaceAverageNode({
        quantityType: QuantityType.DENSITY,
        props: new SurfaceAverageProperties({
          averagingType: SpaceAveragingType.SPACE_MASS_FLOW_AVERAGING,
        }),
      }),
    },
  });
  outputNodes.nodes.push(densityNode);
  densityNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  // Velocity output should be enabled
  const velocityNode = new OutputNode({
    inSurfaces: ['0/bound/wing1'],
    outSurfaces: ['0/bound/wing2'],
    choice: quantityTypeToEnumNumber.get(QuantityType.VELOCITY)!,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: velocityOutputId,
    name: 'Velocity',
    type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
    analysisIters: 2,
    averageIters: 3,
    trailAvgIters: 4,
    nodeProps: {
      case: 'surfaceAverage',
      value: new SurfaceAverageNode({
        quantityType: QuantityType.VELOCITY,
        vectorComponent: basepb.Vector3Component.VECTOR_3_COMPONENT_Y,
        props: new SurfaceAverageProperties({
          averagingType: SpaceAveragingType.SPACE_AREA_AVERAGING,
        }),
      }),
    },
  });
  outputNodes.nodes.push(velocityNode);
  velocityNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  // Point temperature output should be disabled because point_1 does not exist
  const pointTempNode = new OutputNode({
    inSurfaces: ['point_1'],
    choice: pointTempChoice!.enumNumber,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: pointTempOutputId,
    name: 'Point Temperature',
    type: OutputNode_Type.POINT_OUTPUT_TYPE,
    analysisIters: 3,
    averageIters: 4,
    trailAvgIters: 5,
    nodeProps: {
      case: 'pointProbe',
      value: new PointProbeNode({
        quantityType: pointOutputQuantity,
        props: new PointProbeType(),
      }),
    },
  });
  outputNodes.nodes.push(pointTempNode);
  pointTempNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  // Inner Iteration Count should be disabled for steady simulations
  const innerIterCountNode = new OutputNode({
    choice: INNER_ITER_ENUM_NUMBER,
    id: innerIterCountId,
    name: 'Inner Iteration Count',
    type: OutputNode_Type.GLOBAL_OUTPUT_TYPE,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    analysisIters: 1,
    averageIters: 1,
    trailAvgIters: 1,
    nodeProps: {
      case: 'basic',
      value: new BasicNode({
        quantityType: QuantityType.INNER_ITERATION_COUNT,
        props: new BasicType(),
      }),
    },
  });
  outputNodes.nodes.push(innerIterCountNode);
  innerIterCountNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;

  const derivedNode1 = new DerivedNode({
    elements: [new ExpressionElement({ elementType: { case: 'substring', value: '8*2' } })],
  });

  // Custom Output 1 should be enabled
  const derivedOutput1Node = new OutputNode({
    id: derivedOutput1Id,
    name: 'Custom Output 1',
    type: OutputNode_Type.DERIVED_OUTPUT_TYPE,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    analysisIters: 9,
    averageIters: 8,
    trailAvgIters: 7,
    nodeProps: {
      case: 'derived',
      value: derivedNode1,
    },
  });
  outputNodes.nodes.push(derivedOutput1Node);
  derivedOutput1Node.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;

  const derivedNode2 = new DerivedNode({
    elements: [
      new ExpressionElement({
        elementType: {
          case: 'dependency',
          value: new DerivedNodeDependency({
            id: derivedOutput1Id,
            include: OutputIncludes.OUTPUT_INCLUDE_BASE,
          }),
        },
      }),
      new ExpressionElement({ elementType: { case: 'substring', value: '/(8+' } }),
      new ExpressionElement({
        elementType: {
          case: 'dependency',
          value: new DerivedNodeDependency({
            id: pointTempOutputId,
            include: OutputIncludes.OUTPUT_INCLUDE_BASE,
          }),
        },
      }),
      new ExpressionElement({ elementType: { case: 'substring', value: ')' } }),
    ],
  });

  // Custom Output 2 should be disabled because it has point_1 as a dependency
  const derivedOutput2Node = new OutputNode({
    id: derivedOutput2Id,
    name: 'Custom Output 2',
    type: OutputNode_Type.DERIVED_OUTPUT_TYPE,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    analysisIters: 9,
    averageIters: 8,
    trailAvgIters: 7,
    nodeProps: {
      case: 'derived',
      value: derivedNode2,
    },
  });
  outputNodes.nodes.push(derivedOutput2Node);
  derivedOutput2Node.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;

  // This is a derived output that is identical to drag. See LC-20283.
  const derivedNode3 = new DerivedNode({
    elements: [
      new ExpressionElement({
        elementType: {
          case: 'dependency',
          value: new DerivedNodeDependency({
            id: dragOutputId,
            include: OutputIncludes.OUTPUT_INCLUDE_BASE,
          }),
        },
      }),
    ],
  });
  const derivedOutput3Node = new OutputNode({
    id: derivedOutput3Id,
    name: 'Custom Output 3',
    type: OutputNode_Type.DERIVED_OUTPUT_TYPE,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    analysisIters: 3,
    averageIters: 1,
    nodeProps: { case: 'derived', value: derivedNode3 },
  });
  outputNodes.nodes.push(derivedOutput3Node);

  derivedOutput3Node.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;

  // Disk Thrust should be disabled because it has an invalid surface, it is assigned
  // a non-disk surface, it was assigned an invalid reference frame ID, and it has not type
  outputNodes.nodes.push(
    new OutputNode({
      inSurfaces: ['0/bound/wing1', 'invalid-surface'],
      frameId: 'body_frame_id',
      choice: quantityTypeToEnumNumber.get(QuantityType.DISK_THRUST)!,
      id: 'disk-thrust-1',
      name: 'Disk Thrust',
      calcType: CalculationType.CALCULATION_AGGREGATE,
      nodeProps: {
        case: 'force',
        value: new ForceNode({
          quantityType: QuantityType.DISK_THRUST,
          props: new ForceProperties({
            forceDirType: ForceDirectionType.FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR,
            forceDirection,
          }),
        }),
      },
    }),
  );

  // Volume velocity should be TBD
  const volumeVelocityNode = new OutputNode({
    inSurfaces: ['volume-0'],
    choice: volumeVelocityChoice!.enumNumber,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: volumeVelocityId,
    name: 'Volume Velocity',
    type: OutputNode_Type.VOLUME_OUTPUT_TYPE,
    analysisIters: 6,
    averageIters: 6,
    trailAvgIters: 6,
    nodeProps: {
      case: 'volumeReduction',
      value: new VolumeReductionNode({
        quantityType: QuantityType.VELOCITY,
        props: new VolumeReductionProperties({
          reductionType: VolumeReductionType.VOLUME_AVERAGING,
        }),
        vectorComponent: basepb.Vector3Component.VECTOR_3_COMPONENT_X,
      }),
    },
  });
  outputNodes.nodes.push(volumeVelocityNode);
  volumeVelocityNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;

  // This should be an invalid input because it contains non-actuator disk surfaces.
  outputNodes.nodes.push(
    new OutputNode({
      inSurfaces: ['0/bound/wing1'],
      choice: quantityTypeToEnumNumber.get(QuantityType.DISK_ROTATION_RATE)!,
      type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
      id: 'disk-rotation-rate',
      name: 'Disk Rotation Rate',
      calcType: CalculationType.CALCULATION_AGGREGATE,
      nodeProps: {
        case: 'surfaceAverage',
        value: new SurfaceAverageNode({
          quantityType: QuantityType.DISK_ROTATION_RATE,
          props: new SurfaceAverageProperties({
            averagingType: SpaceAveragingType.SPACE_NO_AVERAGING,
          }),
        }),
      },
    }),
  );

  return outputNodes;
}

// Frontend output nodes expected to be created by default when new outputs
// are added with a specified type
export function defaultOutputNodesFixture(): OutputNodes {
  const outputNodes = new OutputNodes();
  const defaultForceDirection = newScalarAdVector(1, 0, 0);
  const defaultLiftNode = new OutputNode({
    choice: quantityTypeToEnumNumber.get(QuantityType.LIFT)!,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: 'default-lift-1',
    name: 'Lift',
    type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
    analysisIters: 1,
    averageIters: 1,
    trailAvgIters: 1,
    nodeProps: {
      case: 'force',
      value: new ForceNode({
        quantityType: QuantityType.LIFT,
        props: new ForceProperties({
          forceDirType: ForceDirectionType.FORCE_DIRECTION_BODY_ORIENTATION_AND_FLOW_DIR,
          forceDirection: defaultForceDirection,
        }),
      }),
    },
  });
  outputNodes.nodes.push(defaultLiftNode);
  defaultLiftNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  const defaultPressureForceNode = new OutputNode({
    choice: quantityTypeToEnumNumber.get(QuantityType.PRESSURE_FORCE)!,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: 'default-pressure-force-1',
    name: 'Pressure Force',
    type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
    analysisIters: 1,
    averageIters: 1,
    trailAvgIters: 1,
    nodeProps: {
      case: 'force',
      value: new ForceNode({
        quantityType: QuantityType.PRESSURE_FORCE,
        props: new ForceProperties({
          forceDirType: ForceDirectionType.FORCE_DIRECTION_CUSTOM,
          forceDirection: defaultForceDirection,
        }),
      }),
    },
  });
  outputNodes.nodes.push(defaultPressureForceNode);
  defaultPressureForceNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  const defaultAreaNode = new OutputNode({
    choice: quantityTypeToEnumNumber.get(QuantityType.AREA)!,
    calcType: CalculationType.CALCULATION_AGGREGATE,
    id: 'default-area-1',
    name: 'Area',
    type: OutputNode_Type.SURFACE_OUTPUT_TYPE,
    analysisIters: 1,
    averageIters: 1,
    trailAvgIters: 1,
    nodeProps: {
      case: 'surfaceAverage',
      value: new SurfaceAverageNode({
        quantityType: QuantityType.AREA,
        props: new SurfaceAverageProperties({
          averagingType: SpaceAveragingType.SPACE_NO_AVERAGING,
        }),
      }),
    },
  });
  outputNodes.nodes.push(defaultAreaNode);
  defaultAreaNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  defaultAreaNode.include[OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT] = false;
  defaultAreaNode.include[OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE] = false;
  const defaultResiduals = new ResidualNode({
    props: new ResidualProperties({ type: ResidualType.RESIDUAL_RELATIVE }),
  });
  const defaultResidualNode = new OutputNode({
    calcType: CalculationType.CALCULATION_AGGREGATE,
    choice: RESIDUAL_ENUM_NUMBER,
    id: 'default-residual-1',
    name: 'Solution Residuals',
    type: OutputNode_Type.GLOBAL_OUTPUT_TYPE,
    analysisIters: 1,
    averageIters: 1,
    trailAvgIters: 1,
    nodeProps: {
      case: 'residual',
      value: defaultResiduals,
    },
  });
  outputNodes.nodes.push(defaultResidualNode);
  defaultResidualNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  ALL_RESIDUALS.forEach((res) => {
    defaultResiduals.resEnabled[res] = true;
  });
  const defaultPointTempNode = new OutputNode({
    choice: pointTempChoice!.enumNumber,
    calcType: CalculationType.CALCULATION_PER_SURFACE,
    id: 'default-point-temp-1',
    name: 'Point Temperature',
    type: OutputNode_Type.POINT_OUTPUT_TYPE,
    analysisIters: 1,
    averageIters: 1,
    trailAvgIters: 1,
    nodeProps: {
      case: 'pointProbe',
      value: new PointProbeNode({
        quantityType: pointOutputQuantity,
        props: new PointProbeType(),
      }),
    },
  });
  outputNodes.nodes.push(defaultPointTempNode);
  defaultPointTempNode.include[OutputIncludes.OUTPUT_INCLUDE_BASE] = true;
  return outputNodes;
}

// A default node for the monitor plot
export function plotNodesFixture(): Plots {
  return new Plots({
    plots: [
      new PlotSettings({
        plot: {
          case: 'monitorPlot',
          value: new PlotSettings_MonitorPlot(),
        },
        name: 'Monitor Plot',
        id: newNodeId(),
      }),
    ],
  });
}

export function viewStateFixture(): ViewState {
  return {
    path: '',
    data: [],
    surfaceData: [],
    blocks: [
      '0',
      '0/cv',
      '0/bound/wing1',
      '0/bound/wing2',
      '0/bound/fuselage',
    ],
    root: {
      displayProps: {
        reprType: 'Surface',
        displayVariable: null,
      },
      visible: false,
      child: [
        {
          name: 'propeller1',
          id: 'propeller1-pv-id',
          param: {
            typ: 'ActuatorDisk',
            innerradius: 1,
            outerradius: 2,
            particleGroupId: 'propeller1',
            circumferentialresolution: 1,
            plane: {
              normal: {
                x: 1,
                y: 0,
                z: 1,
              },
              origin: {
                x: 0,
                y: 1,
                z: 1,
              },
              typ: 'Plane',
            },
            usenormal: true,
            radialresolution: 2,
          },
          paramSeq: 0,
          bounds: null,
          child: [],
          displayProps: {
            reprType: 'Surface',
            displayVariable: null,
          },
          pointData: [],
          visible: true,
        },
        {
          name: 'propeller2',
          id: 'propeller2-pv-id',
          param: {
            typ: 'ActuatorDisk',
            innerradius: 1,
            outerradius: 2,
            particleGroupId: 'propeller2',
            circumferentialresolution: 1,
            plane: {
              normal: {
                x: 2,
                y: 0,
                z: 2,
              },
              origin: {
                x: 0,
                y: 2,
                z: 2,
              },
              typ: 'Plane',
            },
            usenormal: true,
            radialresolution: 2,
          },
          paramSeq: 0,
          bounds: null,
          child: [],
          displayProps: {
            reprType: 'Surface',
            displayVariable: null,
          },
          pointData: [],
          visible: true,
        },
      ],
      id: 'Reader:90:4cbuol3bsg2uk1fih4b9vhbze8p2ljzi',
      name: 'Reader',
      param: { typ: 'Reader', url: '', fvmparams: null, customexp: null },
      bounds: [-15, 15, -15, 15, 0, 1],
      pointData: [],
      paramSeq: 0,
    },
    attrs: {
      reprType: 'Surface With Edges',
      displayVariable: null,
      blockHighlighted: { '0/bound/wing1': true, '0/bound/wing2': false },
      colorMaps: [],
      sphereSize: null,
      viewAttrsVersion: 5,
    },
    axesGridVisibility: false,
  };
}

export function meshMultiPartFixture(): MeshingMultiPart {
  const meshMultiPart = DEFAULT_MESHING_MULTIPART.clone();

  meshMultiPart.blParams[0].selection = MeshingMultiPart_BoundaryLayerParams_SelectionType.SELECTED;
  meshMultiPart.blParams[0].surfaces.push('0/bound/wing1', '0/bound/wing2');
  meshMultiPart.modelParams[0].selection = MeshingMultiPart_ModelParams_SelectionType.SELECTED;
  meshMultiPart.modelParams[0].surfaces.push('0/bound/wing1', '0/bound/wing2');
  meshMultiPart.volumeParams[0].selection = MeshingMultiPart_VolumeParams_SelectionType.SELECTED;
  meshMultiPart.volumeParams[0].volumes.push(BigInt(0));
  return meshMultiPart;
}

export function meshGenerationParamsFixture(): UserMeshingParams {
  return DEFAULT_MESH_GENERATION.clone();
}

export function extractSurfacesListFixture(): string[] {
  return ['0/bound/wing1', '0/bound/wing2'];
}

export function frontendMenuStateFixture(): FrontendMenuState {
  return DEFAULT_FRONTEND_MENU_STATE;
}

export function jobStateFixture(): JobState {
  const length = 20;
  const jobState: JobState = {
    lastIncarnation: 0,
    lastIter: length,
    lastIncarnationStat: new workflowpb.JobIncarnationStat(),
    solutions: [],
  };
  const iters = Array.from({ length }, (_, index) => BigInt(index + 1));
  iters.forEach((iter) => {
    const solution = new Solution({ iter });
    jobState.solutions.push(solution);
  });
  return jobState;
}

export function checkedUrlsStateFixture(): CheckedUrls {
  return DEFAULT_URLS.clone();
}

export function platformAdminRoleFixture() {
  return new papb.GetPlatformAdminRoleReply();
}

export function workflowStateFixture(): GetWorkflowReply {
  const { config } = singlePhysicsWorkflowConfigFixture();
  return new GetWorkflowReply({
    config,
    job: { 'job-1': new GetWorkflowReply_Job({ latestIter: 100n }) },
  });
}

export function jobConfigFixture(): workflowpb.Config {
  const config = singlePhysicsWorkflowConfigFixture().config.clone();
  const farfield = findFarfield(getSimulationParam(config));
  // Make sure the farfield velocity magnitude differs from one in the workflow config.
  farfield!.farfieldVelocityMagnitude = newAdFloat(
    getAdValue(farfield!.farfieldVelocityMagnitude!) + 10,
  );
  farfield!.farfieldFlowDirection = newScalarAdVector(1, 2, 3);
  return config;
}

/** Simple multi-dimensional table with two axis lists and Air Foil table type */
export function airFoilTableFixture() {
  return new RectilinearTable({
    axis: [
      new Axis({
        coordinate: [
          new Axis_Coordinate({ type: { case: 'adfloat', value: newAdFloat(1) } }),
          new Axis_Coordinate({ type: { case: 'adfloat', value: newAdFloat(2) } }),
        ],
      }),
      new Axis({
        coordinate: [
          new Axis_Coordinate({ type: { case: 'adfloat', value: newAdFloat(1) } }),
          new Axis_Coordinate({ type: { case: 'adfloat', value: newAdFloat(2) } }),
        ],
      }),
    ],
    record: [
      new Record({
        entry: [
          new Record_Entry({ type: { case: 'adfloat', value: newAdFloat(10) } }),
          new Record_Entry({ type: { case: 'adfloat', value: newAdFloat(20) } }),
        ],
      }),
      new Record({
        entry: [
          new Record_Entry({ type: { case: 'adfloat', value: newAdFloat(30) } }),
          new Record_Entry({ type: { case: 'adfloat', value: newAdFloat(40) } }),
        ],
      }),
    ],
    metadata: new Metadata({ tableType: TableType.AIRFOIL_PERFORMANCE }),
  });
}
