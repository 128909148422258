// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { CurrentView } from '../lib/componentTypes/context';
import { getSimulationParam } from '../lib/simulationParamUtils';
import { findStaticVolumeById, volumeNodeId } from '../lib/volumeUtils';
import { CadMetadata } from '../proto/cadmetadata/cadmetadata_pb';
import { MeshFileMetadata } from '../proto/lcn/lcmesh_pb';
import { currentViewState } from '../state/internal/global/currentView';

import { getGeoState, onGeometryTabSelector } from './geometry/geometryState';
import { geometryUsesTagsSelector } from './geometry/geometryUsesTags';
import { meshMetadataSelector, meshUrlState } from './meshState';
import { cadMetadataSetupTabState, cadMetadataState } from './useCadMetadata';
import { currentConfigSelector } from './workflowConfig';
import { workflowIdsSelector, workflowMapSelector } from './workflowState';

// A StaticVolume is always read from the mesh metadata
export interface StaticVolume {
  id: string;
  defaultName: string;
  index: number;
  domain: string;
  cadBodyId?: number;
  bounds: Set<string>; // the volume's constituent surface IDs
}

type StaticVolumesKey = {
  projectId: string;
  workflowId: string;
  jobId: string;
}

export type StaticVolumeKey = {
  projectId: string;
  workflowId: string;
  jobId: string;
  volumeId: string;
};

export const defaultVolumeState = (
  meshMetadata?: MeshFileMetadata,
  cadMetadata?: CadMetadata,
  usesGeoTags?: boolean,
): StaticVolume[] => {
  const zones = meshMetadata?.zone || [];
  const cadVolumeNames = cadMetadata?.volumeNames || [];
  const cadBodyIds = cadMetadata?.volumeIds || [];
  return zones.map((zone, i) => {
    const bounds = new Set(zone.bound.map((bound) => bound.name));
    const name = zone.volumeDisplayName ||
      (usesGeoTags ? undefined : cadVolumeNames[i]) ||
      `Volume ${i + 1}`;

    return {
      id: volumeNodeId(i),
      index: i,
      domain: zone.name,
      defaultName: name,
      cadBodyId: cadBodyIds[i],
      bounds,
    };
  });
};

// Represents the atom state for static volumes
export const staticVolumesState = atomFamily<StaticVolume[], StaticVolumesKey>({
  key: 'staticVolumes',
  default: selectorFamily<StaticVolume[], StaticVolumesKey>({
    key: 'volumes/Default',
    get: (key: StaticVolumesKey) => ({ get }) => {
      const geoUsesTags = get(geometryUsesTagsSelector(key));
      const currentView = get(currentViewState);

      // In geometry mode, the volume state comes from the geometry state.
      if (get(onGeometryTabSelector)) {
        const geoState = getGeoState(get, key.projectId);
        return geoState ?
          defaultVolumeState(geoState.metadata, geoState.cadMetadata, geoUsesTags) :
          [];
      }
      if (currentView !== CurrentView.ANALYSIS) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return get(staticVolumesSetupTabState(key));
      }
      const jobConfig = get(currentConfigSelector(key));
      const param = getSimulationParam(jobConfig);
      const [cadMetadata, metadata] = get(waitForAll([
        cadMetadataState(key),
        meshMetadataSelector({ projectId: key.projectId, meshUrl: param.input?.url || '' }),
      ]));
      return defaultVolumeState(metadata?.meshMetadata, cadMetadata, geoUsesTags);
    },
    // protobufs can modify themselves, even in get*.
    dangerouslyAllowMutability: true,
  }),
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const staticVolumesSetupTabState = atomFamily({
  key: 'staticVolumesSetupTab',
  default: selectorFamily({
    key: 'volumesSetupTab/Default',
    get: (key: StaticVolumesKey) => ({ get }) => {
      const geoUsesTags = get(geometryUsesTagsSelector(key));

      const meshUrl = get(meshUrlState(key.projectId));
      const [cadMetadata, metadata] = get(waitForAll([
        cadMetadataSetupTabState(key.projectId),
        meshMetadataSelector({ projectId: key.projectId, meshUrl: meshUrl.geometry }),
      ]));
      return defaultVolumeState(metadata?.meshMetadata, cadMetadata, geoUsesTags);
    },
  }),
});

export type SurfaceVolumeMap = Map<string, StaticVolume>;

export const surfaceToVolumeMapState = selectorFamily<SurfaceVolumeMap, StaticVolumesKey>({
  key: 'surfaceToVolumeMapState',
  get: (key: StaticVolumesKey) => async ({ get }) => {
    const staticVolumes = get(staticVolumesState(key));

    return staticVolumes.reduce((result, volume) => {
      volume.bounds.forEach((surfaceId) => {
        result.set(surfaceId, volume);
      });
      return result;
    }, new Map<string, StaticVolume>());
  },
});

export function useStaticVolumes(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(staticVolumesState({ projectId, workflowId, jobId }));
}

export function useSurfaceToVolumes(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(surfaceToVolumeMapState({ projectId, workflowId, jobId }));
}

export const staticVolumeSelector = selectorFamily<StaticVolume | undefined, StaticVolumeKey>({
  key: 'staticVolumeSelector',
  get: (key: StaticVolumeKey) => ({ get }) => {
    const { projectId, workflowId, jobId, volumeId } = key;
    const volumes = get(staticVolumesState({ projectId, workflowId, jobId }));
    return findStaticVolumeById(volumeId, volumes);
  },
});

export const useStaticVolume = (key: StaticVolumeKey) => useRecoilValue(staticVolumeSelector(key));

// Maps a workflow ID to its corresponding static volumes. It is supposed that one workflow is
// associated with a single topology.
export const staticVolumesAllWorkflowsState = selectorFamily<
  Map<string, StaticVolume[]>,
  string
>({
  key: 'staticVolumesAllWorkflows',
  get: (projectId: string) => async ({ get }) => {
    const map = new Map<string, StaticVolume[]>();
    const workflowIds = get(workflowIdsSelector(projectId));
    const workflowMap = get(workflowMapSelector({ projectId, workflowIds }));
    workflowIds.forEach((workflowId) => {
      const wf = workflowMap[workflowId];
      if (!wf) {
        return;
      }
      // We take whichever job since we suppose we are sharing the same topology.
      const jobIds = Object.keys(wf.job);
      if (jobIds.length === 0) {
        return;
      }
      const jobId = jobIds[0];
      const tags = get(staticVolumesState({ projectId, workflowId, jobId }));
      map.set(workflowId, tags);
    });
    return map;
  },
});

export const useStaticVolumesAllWorkflows = (projectId: string) => (
  useRecoilValue(staticVolumesAllWorkflowsState(projectId))
);
