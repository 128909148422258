// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/upload/upload.proto (package luminary.proto.upload, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { FileMetadata } from "../file/file_pb.js";

/**
 * Available upload methods
 *
 * @generated from enum luminary.proto.upload.Method
 */
export const Method = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.upload.Method",
  [
    {no: 0, name: "METHOD_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "METHOD_SIMPLE", localName: "SIMPLE"},
    {no: 2, name: "METHOD_GCS_RESUMABLE", localName: "GCS_RESUMABLE"},
  ],
);

/**
 * Currently supported mesh types
 *
 * @generated from enum luminary.proto.upload.MeshType
 */
export const MeshType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.upload.MeshType",
  [
    {no: 0, name: "MESH_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MESH_TYPE_ANSYS", localName: "ANSYS"},
    {no: 2, name: "MESH_TYPE_CGNS", localName: "CGNS"},
    {no: 3, name: "MESH_TYPE_OPENFOAM", localName: "OPENFOAM"},
  ],
);

/**
 * @generated from enum luminary.proto.upload.MeshConversionStatus
 */
export const MeshConversionStatus = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.upload.MeshConversionStatus",
  [
    {no: 0, name: "NOT_REQUIRED"},
    {no: 2, name: "IN_PROGRESS"},
    {no: 3, name: "COMPLETE"},
    {no: 4, name: "FAILED"},
  ],
);

/**
 * Type used for an Upload which has not yet been started.
 *
 * @generated from message luminary.proto.upload.UnspecifiedMethod
 */
export const UnspecifiedMethod = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.UnspecifiedMethod",
  [],
);

/**
 * A file upload where data is uploaded in chunks via UploadData.
 * Corresponds to METHOD_SIMPLE
 *
 * @generated from message luminary.proto.upload.SimpleMethod
 */
export const SimpleMethod = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.SimpleMethod",
  [],
);

/**
 * A GCS resumable upload.  The provided signed URL and headers should
 * be used for the initial resumable upload POST request, with subsequent
 * PUT requests to persist the data performed according to the GCS docs:
 * https://cloud.google.com/storage/docs/performing-resumable-uploads
 * Corresponds to METHOD_GCS_RESUMABLE
 *
 * @generated from message luminary.proto.upload.GCSResumableMethod
 */
export const GCSResumableMethod = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.GCSResumableMethod",
  () => [
    { no: 1, name: "signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "http_headers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * Represents an in-progress upload.
 *
 * @generated from message luminary.proto.upload.Upload
 */
export const Upload = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.Upload",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unspecified", kind: "message", T: UnspecifiedMethod, oneof: "method" },
    { no: 3, name: "simple", kind: "message", T: SimpleMethod, oneof: "method" },
    { no: 4, name: "gcs_resumable", kind: "message", T: GCSResumableMethod, oneof: "method" },
  ],
);

/**
 * Parameters to be passed to meshconverter
 *
 * @generated from message luminary.proto.upload.MeshParams
 */
export const MeshParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.MeshParams",
  () => [
    { no: 1, name: "scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "disconnect", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "mesh_type", kind: "enum", T: proto3.getEnumType(MeshType) },
    { no: 4, name: "do_not_read_zones_openfoam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "mesh_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * TODO: are there any geometry-specific parameters needed
 * at upload time?
 *
 * @generated from message luminary.proto.upload.GeometryParams
 */
export const GeometryParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.GeometryParams",
  [],
);

/**
 * Resource-specific parameters for this upload
 *
 * @generated from message luminary.proto.upload.ResourceParams
 */
export const ResourceParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.ResourceParams",
  () => [
    { no: 1, name: "mesh_params", kind: "message", T: MeshParams, oneof: "params" },
    { no: 2, name: "geometry_params", kind: "message", T: GeometryParams, oneof: "params" },
  ],
);

/**
 * @generated from message luminary.proto.upload.CreateUploadRequest
 */
export const CreateUploadRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.CreateUploadRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_meta", kind: "message", T: FileMetadata },
    { no: 3, name: "resource_params", kind: "message", T: ResourceParams },
  ],
);

/**
 * @generated from message luminary.proto.upload.CreateUploadReply
 */
export const CreateUploadReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.CreateUploadReply",
  () => [
    { no: 1, name: "upload", kind: "message", T: Upload },
  ],
);

/**
 * @generated from message luminary.proto.upload.StartUploadRequest
 */
export const StartUploadRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.StartUploadRequest",
  () => [
    { no: 1, name: "upload_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "method", kind: "enum", T: proto3.getEnumType(Method) },
  ],
);

/**
 * @generated from message luminary.proto.upload.StartUploadReply
 */
export const StartUploadReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.StartUploadReply",
  () => [
    { no: 1, name: "upload", kind: "message", T: Upload },
  ],
);

/**
 * @generated from message luminary.proto.upload.UploadDataRequest
 */
export const UploadDataRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.UploadDataRequest",
  () => [
    { no: 1, name: "upload_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message luminary.proto.upload.UploadDataReply
 */
export const UploadDataReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.UploadDataReply",
  () => [
    { no: 1, name: "limit_offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.upload.FinishUploadRequest
 */
export const FinishUploadRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.FinishUploadRequest",
  () => [
    { no: 1, name: "upload_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * The resource which is the result of the completed upload.
 * Will change this message definition, using existing definition meanwhile.
 *
 * @generated from message luminary.proto.upload.FinishUploadReply
 */
export const FinishUploadReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.upload.FinishUploadReply",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "conversion", kind: "enum", T: proto3.getEnumType(MeshConversionStatus) },
    { no: 3, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

