// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { ReactElement } from 'react';

import { Message } from '@bufbuild/protobuf';

import { QuantityType } from './proto/quantity/quantity_pb';

// This file defines the schema for descriptor files (generated by gen/generate_*.py scripts) that
// describe the auxiliary attributes of fields in simulation params, such as the default field
// values and constraints between fields.

export interface ParamGroup {
  // The proto-message type name. snake_case.
  name: string;
  // Human-readable string of the group.
  text: string;
  // The JS/TS method suffix to access this field in the toplevel Param proto.
  // Prepend "set" or "get" to get the getter or setter. In practice, the value
  // is a CamelCase spelling of the "name" field. For example, if
  // name="taylor_green_param", then pascalCaseName="TaylorGreenParam", and you
  // can call method getTaylorGreenParam (or setTaylorGreenParam) to get (or
  // set) the proto field value.
  pascalCaseName: string;
  camelCaseName: string;
  // isMap is true if there are multiple groups of this type, keyed by a
  // string. E.g., the boundary condition group has isMap=true, because there
  // is one group per bound.
  isMap: boolean;
  // isRepeated is true if there are multiple groups of this type.
  // E.g., the periodic boundaries group has isRepeated=true, because can be
  // an arbitrary number of bound pairs.
  isRepeated: boolean;
  // List of parameters in the group.
  params: Param[];
  // List of param groups in the group.
  paramGroups: string[];
  isExternal: boolean;
  isOneOf: boolean;
  // Function to create a new proto instance of the group
  createNewProto?: () => Message;
}

// Field type.
export enum ParamType {
  REAL,
  INT,
  VECTOR3,
  STRING,
  STRING_LIST,
  MULTIPLE_CHOICE,
  TABLE,
  BOOL,
}

export interface Vector3 {
  x: number;
  y: number;
  z: number;
}

// Cond defines when a field is enabled.
// For example, if field A is enabled only when multiple-choice field B's value
// is set to X, then A's cond field will have CondChoice{param:B, choice:X}.
export enum CondType {
  ALL,
  ANY,
  TRUEFALSE,
  CHOICE,
  TRUE,
  FALSE,
  EXPERIMENT,
  NOT,
  TAG,
}

// CondAny is true if any of the conditions in list[] is true.
export interface CondAny {
  type: CondType.ANY;
  list: Cond[];
}

// CondAny is true if all of the conditions in list[] are true.
export interface CondAll {
  type: CondType.ALL;
  list: Cond[]
}

// Cond is true if the param is true
export interface CondBool {
  type: CondType.TRUEFALSE;
  param: string;
}

// CondChoice becomes true if the 'param''s value is set to 'choice'.
// The 'param' must be of MULTIPLE_CHOICE type.
export interface CondChoice {
  type: CondType.CHOICE;
  param: string;
  choice: number; // enum tag
}

// CondTrue is always true. The param is always shown.
export interface CondTrue {
  type: CondType.TRUE;
}

// CondFalse is always false. The param is always hidden.
export interface CondFalse {
  type: CondType.FALSE;
}

export interface CondExperiment {
  type: CondType.EXPERIMENT;
  name: string;
}

export interface CondNot {
  type: CondType.NOT;
  cond: Cond;
}

export interface CondTag {
  type: CondType.TAG;
  tagName: string;
}

export type Cond = CondAny |
  CondAll |
  CondBool |
  CondChoice |
  CondTrue |
  CondFalse |
  CondExperiment |
  CondNot |
  CondTag;

// Choice represents one choice in multiple-choice parameter.
export interface Choice {
  // Unique name assigned to the choice. It is also the proto elem name.
  name: string;
  // String to be shown in the UI.
  text: string;
  // Number assigned to the element in the proto elem.
  enumNumber: number;
  // Tooltip help string.
  help?: string;
  // Additional data for each choice
  data?: any;
  // If a divider should be shown at the bottom of this choice in the drop down menu
  divider?: boolean;
  // Conditional used to hide the choice.
  cond?: Cond;
  // Custom react element that is used in the menu item instead of text
  customElement?: ReactElement;
  // If true, the icon indicating an experimental feature will be displayed
  earlyAccess?: boolean;
}

interface CommonParamFields {
  // Proto field name within the param group message type. snake_case.
  name: string;
  // String to be shown in the UI.
  text: string;
  // The JS/TS method suffix to access this field in the toplevel Param proto.
  // Prepend "set" or "get" to get the getter or setter. In practice, the value
  // is a CamelCase spelling of the "name" field. For example, if
  // name="taylor_green_param", then pascalCaseName="TaylorGreenParam", and you
  // can call method getTaylorGreenParam (or setTaylorGreenParam) to get (or
  // set) the proto field value.
  pascalCaseName: string;
  camelCaseName: string;
  parentGroups: string[];

  // Defines when this parameter is enabled. If cond is null,
  // the parameter is unconditionally enabled.
  cond?: Cond;
  // Tooltip help string.
  help?: string;

  quantityType?: QuantityType;
  isRepeated?: boolean;
  isMap?: boolean;
}

export interface BoolParam extends CommonParamFields {
  type: ParamType.BOOL;
  defaultValue?: boolean;
}

export interface StringParam extends CommonParamFields {
  type: ParamType.STRING;
  defaultValue?: string;
}

export interface RealParam extends CommonParamFields {
  type: ParamType.REAL;
  defaultValue?: number;
  openBound?: boolean;
  minVal?: number;
  maxVal?: number;
}

export interface IntParam extends CommonParamFields {
  type: ParamType.INT;
  defaultValue?: number;
  openBound?: boolean;
  minVal?: number;
  maxVal?: number;
}

export interface Vector3Param extends CommonParamFields {
  type: ParamType.VECTOR3;
  defaultValue?: Vector3;
}

export interface MultipleChoiceParam extends CommonParamFields {
  type: ParamType.MULTIPLE_CHOICE;
  choices: Choice[];
  defaultValue?: number;
}

type Label = { quantity: QuantityType } | { name: string };
export interface RectilinearTableParam extends CommonParamFields {
  type: ParamType.TABLE,
  defaultValue?: string;
  tableRecords: Label[];
  axis: Label[];
}

export type Param = BoolParam |
  IntParam |
  MultipleChoiceParam |
  RealParam |
  RectilinearTableParam |
  StringParam |
  Vector3Param;

// Full description of a parameter option (parameter plus its parent group)
export interface FullDescriptor {
  paramGroup: ParamGroup,
  param: Param
}

export const getFullDescriptor =
  (paramGroup: ParamGroup, param: Param): FullDescriptor => ({ paramGroup, param });

// Defines a quantity. Copy of quantity_options.Quantity. Redefined here to
// avoid circular dependencies
export type Quantity = {
  // Internal name of the quantity
  name: string;
  // User facing name of the quantity
  text: string;
  // Number of components (usually 1 for scalar and 3 for vector)
  size: number;
  // Default unit of the quantity
  unit: string;
  // Tags that can be used to add certain properties or to define what can be
  // done with the quantity (for example TAG_FIELD declares the quantity as a
  // field variable that can be integrated on a surface or in a volume).
  tags: number[];
  // Help string describing the quantity
  help: string;
  // The enum value this quantity has in the quantity.QuantityType enum
  quantityType: QuantityType;
  //
  children: QuantityType[];
  //
  parents: QuantityType[];

  cond?: Cond;
  //
  category: string;

}

// Check bounds of a parameter. Returns an error message if the values is out of bounds otherwise
// returns an empty string.
export const checkBounds = (paramDesc: Param, paramValue: number) => {
  if (paramDesc.type !== ParamType.INT && paramDesc.type !== ParamType.REAL) {
    return '';
  }
  const exceedsLowerBound = (paramDesc.minVal !== undefined &&
    (paramDesc.openBound ? paramDesc.minVal >= paramValue : paramDesc.minVal > paramValue));
  const exceedsUpperBound = (paramDesc.maxVal !== undefined &&
    (paramDesc.openBound ? paramDesc.maxVal <= paramValue : paramDesc.maxVal < paramValue));
  const lowerBoundSymbol = paramDesc.openBound ? '>' : '≥';
  const upperBoundSymbol = paramDesc.openBound ? '<' : '≤';
  const warning = exceedsLowerBound || exceedsUpperBound;
  return warning ? `Must be ${exceedsLowerBound ? lowerBoundSymbol : upperBoundSymbol}
   ${exceedsLowerBound ? paramDesc.minVal : paramDesc.maxVal}.` : '';
};
