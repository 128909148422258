import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import Draggable, { DraggableData } from 'react-draggable';

import { createStyles, makeStyles } from '@/components/Theme';
import { colors } from '@/lib/designSystem';
import { lcvHandler } from '@/lib/lcvis/handler/LcvHandler';
import { useLcvisTriadPosition } from '@/recoil/lcvis/lcvisTriadPosition';

const useStyles = makeStyles(() => createStyles({
  root: {
    '--dots-color': colors.neutral250,
    '--dots-opacity': '0',

    position: 'absolute',
    width: '32px',
    height: '32px',
    cursor: 'grab',

    '&.isHovered': {
      '--dots-opacity': '1',
    },
    '&:hover': {
      '--dots-color': colors.purple500,
    },
    '&.isGrabbing': {
      cursor: 'grabbing',
    },
  },
  dotsContainer: {
    width: '100%',
    height: '100%',
    padding: '9px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    justifyItems: 'center',
    opacity: 'var(--dots-opacity)',
  },
  dot: {
    width: '4px',
    height: '4px',
    backgroundColor: 'var(--dots-color)',
    borderRadius: '50%',
  },
}), { name: 'TriadWidgetOverlay' });

// offset from the bottom-left corner
const DRAG_ICON_OFFSET = 16;

export function TriadWidgetOverlay() {
  const classes = useStyles();
  const [triadPosition, setTriadPosition] = useLcvisTriadPosition();
  const initialTriadPosition = useRef(triadPosition);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const getShiftedPosition = (draggableData: DraggableData) => [
    initialTriadPosition.current[0] + draggableData.x,
    initialTriadPosition.current[1] - draggableData.y,
  ] satisfies [number, number];

  useEffect(() => {
    let unmount = () => {};

    lcvHandler.queueDisplayFunction('set triad hover callback', (display) => {
      display.widgets.triadWidget?.setHoverCallback(setIsHovered);

      unmount = () => {
        display.widgets.triadWidget?.setHoverCallback(null);
      };
    });

    return unmount;
  }, []);

  return (
    <Draggable
      axis="both"
      onDrag={(event, draggableData) => {
        // lcvis uses dragging as well, prevent propagation so that canvas doesn't see this event
        // and doesn't move the camera
        event.stopPropagation();
        lcvHandler.display?.setTriadPosition(getShiftedPosition(draggableData));
      }}
      onStart={() => {
        setIsGrabbing(true);
      }}
      onStop={(_, draggableData) => {
        setIsGrabbing(false);
        setTriadPosition(getShiftedPosition(draggableData));
      }}>
      <div
        className={cx(classes.root, { isGrabbing, isHovered })}
        style={{
          bottom: initialTriadPosition.current[1] + DRAG_ICON_OFFSET,
          left: initialTriadPosition.current[0] + DRAG_ICON_OFFSET,
        }}>
        <div className={classes.dotsContainer}>
          {[...Array(4).keys()].map((index) => (
            <div className={classes.dot} key={index} />
          ))}
        </div>
      </div>
    </Draggable>
  );
}
