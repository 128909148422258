// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import * as flags from '../../flags';
import { geometryLink } from '../../lib/navigation';
import { defaultConfig } from '../../lib/paramDefaults/workflowConfig';
import { useUserCanEdit } from '../../lib/projectRoles';
import { WORK_ORDER_TYPES } from '../../lib/workOrderTypes';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as meshgenerationpb from '../../proto/meshgeneration/meshgeneration_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { useCheckedUrls } from '../../recoil/checkedGeometryUrls';
import { useResetEntityGroupMap } from '../../recoil/entityGroupState';
import { useFrontendMenuState } from '../../recoil/frontendMenuState';
import { useLatestGeometryVersionid } from '../../recoil/geometry/geometryState';
import { useGeometryHealth } from '../../recoil/geometryHealth';
import { useMeshUrlState } from '../../recoil/meshState';
import { usePendingWorkOrders } from '../../recoil/pendingWorkOrders';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useCadModifier } from '../../recoil/useCadModifier';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useProjectMetadataValue } from '../../recoil/useProjectMetadata';
import { useSetProjectConfig } from '../../recoil/workflowConfig';
import { analytics } from '../../services/analytics';
import { useStatusCardPropsValue } from '../../state/internal/component/statusCard';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useIsGeometryView, useIsSetupOrAdvancedView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';

import { MeshingStatusSummary } from './MeshingStatusSummary';
import StatusCard from './StatusCard';

const { CHECK_GEOMETRY, GET_GEOMETRY, GET_MESH } = frontendpb.WorkOrderType;

// Contains a StatusCard for the pending work orders. Calls checkGeometry when the meshUrl changes
// and handles the response.
const MeshingStatus = () => {
  const { projectId, workflowId, jobId, geometryId } = useProjectContext();
  const initialStatusCardProps = useStatusCardPropsValue(projectId);
  const [meshUrlState, setMeshUrlState] = useMeshUrlState(projectId);
  const [checkedUrls] = useCheckedUrls(projectId);
  const [frontendMenuState] = useFrontendMenuState(projectId, '', '');
  const [pendingWorkOrders, setPendingWorkOrders] = usePendingWorkOrders(projectId);
  const [userGeometryMod] = useCadModifier(projectId);
  const resetEntityGroups = useResetEntityGroupMap(projectId, workflowId, jobId);
  const setProjectConfig = useSetProjectConfig(projectId);
  const setConfirmStack = useSetConfirmations();
  const [geometryHealth] = useGeometryHealth(projectId);
  const projectMetadata = useProjectMetadataValue(projectId || '');
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  const navigate = useNavigate();
  const [selectedGeometry, setSelectedGeometry] = useSelectedGeometry(projectId, '', '');
  const isGeometryView = useIsGeometryView();
  const latestGeometryVersion = useLatestGeometryVersionid(projectId, geometryId);
  const isSetupOrAdvancedView = useIsSetupOrAdvancedView();

  const experimentConfig = useEnabledExperiments();
  // TODO(LC-6917): remove when Parasolid meshing is enabled for all
  const allowParasolid = experimentConfig.includes(flags.parasolidMeshing);
  const lcsurfaceTessellation = experimentConfig.includes(flags.lcsurfaceTessellation);

  // LCC jobs are idempotent (thanks, Collin!) so instead of persisting an error message
  // in project state, we can always just call checkGeometry to get it back very quickly.
  useEffect(() => {
    const url = meshUrlState?.url || '';
    const meshUrl = meshUrlState?.mesh || '';
    if (url && !meshUrl && !geometryHealth && userCanEdit &&
      checkedUrls.status !== projectstatepb.CheckGeometryStatus.SUCCESSFUL) {
      setPendingWorkOrders((workOrders: frontendpb.PendingWorkOrders) => {
        const newWorkOrders = workOrders.clone();
        const workOrdersMap = newWorkOrders.workOrders;
        if (!workOrdersMap[CHECK_GEOMETRY]) {
          const workOrder = new frontendpb.PendingWorkOrder({
            typ: {
              case: 'checkGeometry',
              value: new frontendpb.CheckGeometryRequest({
                projectId,
                geometryId: selectedGeometry.geometryId,
                geometryVersionId: selectedGeometry.geometryVersionId,
                userGeo: new meshgenerationpb.UserGeometry({
                  url,
                  scaling: frontendMenuState.meshScaling,
                  allowParasolid,
                  lcsurfaceTessellation,
                }),
                userGeoMod: userGeometryMod || undefined,
              }),
            },
          });

          workOrdersMap[CHECK_GEOMETRY] = workOrder;
        }
        return newWorkOrders;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meshUrlState, checkedUrls, geometryHealth, selectedGeometry, userCanEdit]);

  const cancelPending = (
    pending: frontendpb.PendingWorkOrder,
    type: frontendpb.WorkOrderType,
  ) => {
    const clearSelectedGeometry = () => {
      setSelectedGeometry(new projectstatepb.SelectedGeometry());
    };
    const cancelWorkOrders = () => {
      setPendingWorkOrders(
        (workOrders) => {
          const newWorkOrders = workOrders.clone();
          newWorkOrders.workOrders = {};
          return newWorkOrders;
        },
      );
      clearSelectedGeometry();
      navigate(geometryLink(projectId));
    };
    const resetGeometry = () => {
      cancelWorkOrders();
      setMeshUrlState(new projectstatepb.MeshUrl());
      resetEntityGroups();
      setProjectConfig(defaultConfig('', null, ''));
      clearSelectedGeometry();
      navigate(geometryLink(projectId));
    };
    if (
      type === CHECK_GEOMETRY &&
      (
        pending.typ.case !== 'checkGeometry' ||
        !pending.typ.value.userGeoMod
      )
    ) {
      // Reset everything if canceling the initial CHECK_GEOMETRY. Confirm first.
      pushConfirmation(setConfirmStack, {
        continueLabel: 'Remove File',
        destructive: true,
        onContinue: resetGeometry,
        subtitle: `Canceling the geometry check will remove the current file. You'll need
          to re-upload the file to proceed.`,
        title: 'Cancel Geometry Check',
      });
    } else if (type === GET_MESH) {
      setPendingWorkOrders(
        (workOrders) => {
          const newWorkOrders = workOrders.clone();
          delete newWorkOrders.workOrders[type];
          return newWorkOrders;
        },
      );
    } else {
      cancelWorkOrders();
      if (type === GET_GEOMETRY) {
        setMeshUrlState(new projectstatepb.MeshUrl());
      }
    }

    analytics.track('Cancel Pending Work Order', {
      projectId,
      workOrderType: type,
    });
  };

  // The status card shows the initialStatusCardProps coming from the ParaviewContext if present.
  // Otherwise, it will show the status of any pending work orders in frontendMenuState.
  if (initialStatusCardProps.isVisible) {
    return <StatusCard {...initialStatusCardProps} />;
  }
  if (isSetupOrAdvancedView || isGeometryView) {
    for (let i = 0; i < WORK_ORDER_TYPES.length; i += 1) {
      const type = WORK_ORDER_TYPES[i];
      const pending = pendingWorkOrders.workOrders[type];
      if (pending) {
        // In the geometry view, make sure we are seeing the progress of the check geometry when
        // the geometry version matches the one we are looking at.
        if (isGeometryView && type === CHECK_GEOMETRY) {
          if (pending.typ.value?.geometryVersionId !== latestGeometryVersion) {
            return null;
          }
        }
        return (
          <MeshingStatusSummary
            cancel={() => cancelPending(pending, type)}
            pending={pending}
            type={type}
          />
        );
      }
    }
  }
  return null;
};

export default MeshingStatus;
