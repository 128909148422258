// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { assignDomainsToMaterial, getAssignedMaterialDomains } from '../../lib/entityRelationships';
import { ConfigurableMaterialType, appendMaterial } from '../../lib/materialUtils';
import { useWorkflowConfig } from '../../model/hooks/useWorkflowConfig';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useStaticVolumes } from '../../recoil/volumes';
import { analytics } from '../../services/analytics';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

export const useMaterialAdd = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNodeIds, setSelection } = useSelectionContext();

  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const setNewNodes = useSetNewNodes();

  const { saveParamAsync } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  // Get a list of domains (volumes) that are selected in the tree and not already assigned to a
  // material
  const getAvailableSelectedDomains = useCallback((param: simulationpb.SimulationParam) => {
    const selectedIdSet = new Set(selectedNodeIds);
    const assignedDomains = getAssignedMaterialDomains(param, geometryTags);
    return staticVolumes.reduce((result, volume) => {
      const { id, domain } = volume;
      // Add a volume to the new material if it was selected and if it's not already assigned to a
      // material
      if (selectedIdSet.has(id) && !assignedDomains.has(domain)) {
        result.add(domain);
      }
      return result;
    }, new Set<string>());
  }, [geometryTags, selectedNodeIds, staticVolumes]);

  // Add a material to the simulation param and save the updated param
  const addMaterialNode = useCallback(async (type: ConfigurableMaterialType) => {
    const materialId = await saveParamAsync(
      (newParam) => {
        const domains = getAvailableSelectedDomains(newParam);
        const newId = appendMaterial(newParam, type);
        if (domains?.size) {
          assignDomainsToMaterial(newParam, domains, newId);
        }
        setNewNodes((nodes) => [...nodes, initializeNewNode(newId)]);
        setSelection([newId]);
        return newId;
      },
    );

    // Track the material addition event
    analytics.track('Material Added', {
      workflowId,
      materialType: type,
      materialId,
    });

    return materialId;
  }, [getAvailableSelectedDomains, saveParamAsync, setNewNodes, setSelection, workflowId]);

  return {
    addMaterialNode,
  };
};
