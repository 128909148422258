// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import { cameraIndexFromNodeId, cameraNodeId } from '../../lib/cameraUtils';
import { CommonMenuItem, CommonMenuListItem } from '../../lib/componentTypes/menu';
import { IconName } from '../../lib/componentTypes/svgIcon';
import { expandGroups } from '../../lib/entityGroupUtils';
import { AnyKeyboardEvent } from '../../lib/event';
import { getAria, getHelpText, isEventTrigger } from '../../lib/keyBindings';
import { lcvResetCamera, zoomToSelection } from '../../lib/lcvis/api';
import { emitLCVKeyEvent } from '../../lib/lcvis/handler/handlerUtils';
import { METAKEY_SYMBOL, isInExploration } from '../../lib/navigation';
import { RecoilProjectKey } from '../../lib/persist';
import { useUserCanEdit } from '../../lib/projectRoles';
import { ListenerEvent, useEventListener } from '../../lib/useEventListener';
import { isSensitivityAnalysis } from '../../lib/workflowUtils';
import { CameraAccess } from '../../proto/frontend/frontend_pb';
import { PanDirectionType, RotationAxisType } from '../../pvproto/ParaviewRpc';
import {
  CameraGroupMapAccessType,
  saveCameraCallback,
  useCameraGroupMap,
  useCameraListValue,
} from '../../recoil/cameraState';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useLcvisCameraState } from '../../recoil/lcvis/lcvisCameraState';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../recoil/lcvis/lcvisReadyState';
import { useRemoveOverlays } from '../../recoil/lcvis/useRemoveOverlays';
import { CameraMode } from '../../recoil/paraviewState';
import { useMeshReadyState } from '../../recoil/useMeshReadyState';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useCurrentTab } from '../../recoil/useTabsState';
import { TreeViewType, useSetTreeViewType } from '../../recoil/useTreeViewState';
import { useActiveVisUrlValue } from '../../recoil/vis/activeVisUrl';
import { useWorkflowState } from '../../recoil/workflowState';
import { useIsAnalysisView, useIsGeometryView } from '../../state/internal/global/currentView';
import { OverlayMode, useOverlayMode } from '../../state/internal/vis/overlayMode';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { IconButton } from '../Button/IconButton';
import { DataSelect } from '../Form/DataSelect';
import { SvgIcon } from '../Icon/SvgIcon';
import { CommonMenu } from '../Menu/CommonMenu';
import { FloatingGroup } from '../Pane/FloatingGroup';
import SelectionControlBar from '../Pane/SelectionControlBar';
import { useToolbarMargin } from '../Pane/hooks/useFloatingToolbarMargin';
import { createStyles, makeStyles } from '../Theme';
import Tooltip, { TooltipProps } from '../Tooltip';
import { TipWithBindings } from '../Tooltip/TipWithBinding';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useZoomToFit } from '../hooks/useCamera';
import { useHandleManualCameraViewChange } from '../hooks/useHandleManualCameraViewChange';
import { useHandleLcVisFilterClick } from '../visFilter/useHandleLcVisFilterClick';

import ParaviewControlBar from './ParaviewControlBar';
import { useParaviewContext } from './ParaviewManager';

/**
   CameraControlPanel implements a series of buttons that control the location
   and the angle of the camera. The camera is a function that maps a world (3D)
   coordinate to a screen (2D) coordinate.
*/

const ZOOM_IN_FACTOR = Math.sqrt(2);
const ZOOM_OUT_FACTOR = 1 / Math.sqrt(2);
const ICON_SIZE = 12;
const ORTHOGRAPHIC_VIEWS = [
  CameraMode.ORTHOGRAPHIC,
  CameraMode.X_MINUS,
  CameraMode.X_PLUS,
  CameraMode.Y_MINUS,
  CameraMode.Y_PLUS,
  CameraMode.Z_MINUS,
  CameraMode.Z_PLUS,
  CameraMode.NE,
  CameraMode.NW,
  CameraMode.SE,
  CameraMode.SW,
];
const PAN_FACTOR = 0.05;
// amount to rotate camera (in radians)
const ROTATE_FACTOR = Math.PI / 16;

const useStyles = makeStyles(
  () => createStyles({
    button: {
      padding: '8px',
    },
    icon: {
      height: `${ICON_SIZE}px`,
      width: `${ICON_SIZE}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    root: {
      position: 'absolute',
      bottom: '17px',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    panel: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      overflowX: 'auto',
    },
  }),
  { name: 'CameraControlPanel' },
);

interface Props {
  disabled: boolean;
}

type ParaviewButtonProps = {
  title: TooltipProps['title'],
  className: string | undefined,
  disabled: boolean,
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  iconClassName: string | undefined,
  iconName: IconName
  dataLocator?: string
}

// button at bottom right of paraview window
const ParaviewButton = (props: ParaviewButtonProps) => (
  <Tooltip placement="top" title={props.title}>
    <span>
      <IconButton
        className={props.className}
        data-locator={props.dataLocator || 'paraviewButton'}
        disabled={props.disabled}
        kind="minimal"
        onClick={(ev) => props.onClick(ev)}>
        <div className={props.iconClassName}>
          <SvgIcon maxHeight={ICON_SIZE} maxWidth={ICON_SIZE} name={props.iconName} />
        </div>
      </IconButton>
    </span>
  </Tooltip>
);

const ParaviewCameraViewsControl = (props: Props) => {
  const classes = useStyles();

  const onManualCameraViewChange = useHandleManualCameraViewChange();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  // Keep track of the previous ids of the camera.
  const prevCameraIdsRef = useRef<bigint[]>([]);

  const setTreeViewType = useSetTreeViewType();
  const { projectId, workflowId, jobId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const { setScrollTo, setSelection } = useSelectionContext();

  const { setCameraMode } = useParaviewContext();

  const cameraKey: RecoilProjectKey = { projectId, workflowId, jobId };
  const cameraList = useCameraListValue(cameraKey);

  const localCameraGroupMap = useCameraGroupMap(cameraKey, CameraGroupMapAccessType.LOCAL);
  const globalCameraGroupMap = useCameraGroupMap(cameraKey, CameraGroupMapAccessType.GLOBAL);

  const projectMetadata = useProjectMetadata(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  const saveCamera = useRecoilCallback(saveCameraCallback);

  const localCameraAndGroupIds = Array.from(localCameraGroupMap.root().children);
  const globalCameraAndGroupIds = Array.from(globalCameraGroupMap.root().children);

  const localCamerasAndGroups = localCameraAndGroupIds.map((id) => localCameraGroupMap.get(id));
  const globalCamerasAndGroups = globalCameraAndGroupIds.map((id) => globalCameraGroupMap.get(id));

  const rootLocalCameras = localCamerasAndGroups.filter((item) => !item.children.size);
  const rootGlobalCameras = globalCamerasAndGroups.filter((item) => !item.children.size);

  const rootLocalGroups = localCamerasAndGroups.filter((item) => item.children.size);
  const rootGlobalGroups = globalCamerasAndGroups.filter((item) => item.children.size);

  const handlePredefinedView = (view: CameraMode) => {
    setCameraMode(view);
    setOpen(false);
    onManualCameraViewChange();
  };

  const highlightAndScrollToCamera = useCallback((cameraId: bigint) => {
    const newId = cameraNodeId(cameraId);
    if (newId) {
      setScrollTo({ node: newId, fast: true });
      setSelection([newId]);
    }
  }, [setSelection, setScrollTo]);

  const handleSaveCamera = async (cameraAccess: CameraAccess) => {
    setTreeViewType(TreeViewType.POST_PROCESSING);
    const newCamera = await saveCamera(cameraKey, cameraAccess);
    if (newCamera) {
      // Only highlight, but do not scroll to the node. This will be handled later when the
      // transient node is replaced with the permanent node with the real id.
      setSelection([cameraNodeId(newCamera.cameraId)]);
      setOpen(false);
    }
  };

  const handleOpenCamera = useCallback((nodeId: string) => {
    setTreeViewType(TreeViewType.POST_PROCESSING);
    // Just select the clicked camera in the control panel. The Camera.tsx will intercept the
    // highlight change and will handle the rest.
    highlightAndScrollToCamera(cameraIndexFromNodeId(nodeId));
    setOpen(false);
  }, [highlightAndScrollToCamera, setOpen, setTreeViewType]);

  // Check when a camera with id=0 is updated in the recoil state and becomes with another id.
  // If that happens, that means the camera with the id=0 was a transient recoil camera which is now
  // replaced with the actual camera data returned from the DB. If that happens, its node is
  // rerendered and we should reselect it by using its updated id.
  useEffect(() => {
    const lastIdx = cameraList.length - 1;
    if (
      prevCameraIdsRef.current.length === cameraList.length &&
      prevCameraIdsRef.current[lastIdx] === 0n &&
      cameraList[lastIdx].cameraId !== 0n
    ) {
      highlightAndScrollToCamera(cameraList[lastIdx].cameraId);
    }

    prevCameraIdsRef.current = cameraList.map((cam) => cam.cameraId);
  }, [cameraList, highlightAndScrollToCamera, setSelection]);

  const handleXPlus = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('+X');
    } else {
      handlePredefinedView(CameraMode.X_PLUS);
    }
  };

  const handleXMinus = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('-X');
    } else {
      handlePredefinedView(CameraMode.X_MINUS);
    }
  };

  const handleYPlus = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('+Y');
    } else {
      handlePredefinedView(CameraMode.Y_PLUS);
    }
  };

  const handleYMinus = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('-Y');
    } else {
      handlePredefinedView(CameraMode.Y_MINUS);
    }
  };

  const handleZPlus = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('+Z');
    } else {
      handlePredefinedView(CameraMode.Z_PLUS);
    }
  };

  const handleZMinus = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('-Z');
    } else {
      handlePredefinedView(CameraMode.Z_MINUS);
    }
  };
  const menuItems: CommonMenuItem[] = [
    {
      label: 'Save Current Camera',
      onClick: () => { },
      items: [
        {
          description: 'Save to local project',
          disabled: !userCanEdit,
          disabledReason: 'Saving cameras is disabled in view only projects',
          label: 'Local',
          onClick: () => handleSaveCamera(CameraAccess.LOCAL),
        },
        {
          description: 'Make camera reusable across projects',
          disabled: !userCanEdit,
          disabledReason: 'Saving cameras is disabled in view only projects',
          label: 'Global',
          onClick: () => handleSaveCamera(CameraAccess.GLOBAL),
        },
      ],
    },
    { separator: true },
    { label: '-X', onClick: () => handleXMinus() },
    { label: '+X', onClick: () => handleXPlus() },
    { label: '-Y', onClick: () => handleYMinus() },
    { label: '+Y', onClick: () => handleYPlus() },
    { label: '-Z', onClick: () => handleZMinus() },
    { label: '+Z', onClick: () => handleZPlus() },
  ];

  const handleNE = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('NE');
    } else {
      handlePredefinedView(CameraMode.NE);
    }
  };

  const handleNW = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('NW');
    } else {
      handlePredefinedView(CameraMode.NW);
    }
  };

  const handleSE = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('SE');
    } else {
      handlePredefinedView(CameraMode.SE);
    }
  };

  const handleSW = async (): Promise<void> => {
    if (lcvisEnabled) {
      await emitLCVKeyEvent('SW');
    } else {
      handlePredefinedView(CameraMode.SW);
    }
  };

  menuItems.push({
    label: 'Isometric',
    onClick: () => { },
    items: [
      { label: 'NE', onClick: () => handleNE() },
      { label: 'NW', onClick: () => handleNW() },
      { label: 'SE', onClick: () => handleSE() },
      { label: 'SW', onClick: () => handleSW() },
    ],
  });

  // LOCAL cameras section
  // Add the cameras that are not part of any group
  if (rootLocalCameras.length) {
    menuItems.push({ separator: true });

    rootLocalCameras.forEach((camera) => {
      menuItems.push({
        label: camera.name,
        onClick: () => handleOpenCamera(camera.id),
      });
    });
  }

  // Add the camera groups with their children cameras
  if (rootLocalGroups.length) {
    menuItems.push({ separator: true });

    rootLocalGroups.forEach((group) => {
      menuItems.push({
        label: group.name,
        startIcon: { name: 'folder' },
        onClick: () => { },
        items: Array.from(group.children).map((id) => ({
          label: localCameraGroupMap.get(id).name,
          onClick: () => handleOpenCamera(localCameraGroupMap.get(id).id),
        })),
      });
    });
  }

  // GLOBAL cameras section
  const globalCamerasMenuItems: CommonMenuListItem[] = [];
  // Add the cameras that are not part of any group
  if (rootGlobalCameras.length) {
    rootGlobalCameras.forEach((camera) => {
      globalCamerasMenuItems.push({
        label: camera.name,
        onClick: () => handleOpenCamera(camera.id),
      });
    });
  }

  // Add the camera groups with their children cameras
  rootGlobalGroups.forEach((group) => {
    globalCamerasMenuItems.push({
      label: group.name,
      startIcon: { name: 'folder' },
      onClick: () => { },
      items: Array.from(group.children).map((id) => ({
        label: globalCameraGroupMap.get(id).name,
        onClick: () => handleOpenCamera(globalCameraGroupMap.get(id).id),
      })),
    });
  });

  menuItems.push({ separator: true });
  menuItems.push({
    disabled: !globalCamerasMenuItems.length,
    label: 'Global cameras',
    startIcon: { name: 'folderArrowUpRight' },
    onClick: () => { },
    items: globalCamerasMenuItems,
  });

  return (
    <div ref={anchorRef}>
      <ParaviewButton
        className={classes.button}
        disabled={props.disabled}
        iconClassName={classes.icon}
        iconName="videoCamera"
        onClick={() => {
          setOpen(true);
        }}
        title="Camera Views"
      />
      <CommonMenu
        anchorEl={anchorRef.current}
        flushAlign
        maxHeight={600}
        menuItems={menuItems}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        position="above-left"
      />
    </div>
  );
};

const CameraControlPanel = (props: Props) => {
  // == Props
  const { disabled } = props;

  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const {
    cameraMode,
    paraviewClientState,
    paraviewRenderer,
    setCameraMode,
  } = useParaviewContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const workflowFlag = useWorkflowFlagValue();
  const isGeometryView = useIsGeometryView();
  const currentTab = useCurrentTab(projectId);
  const lcVisReady = useLcVisReadyValue();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [lcvisCameraState, setLcvisCameraState] = useLcvisCameraState({
    projectId,
    workflowId,
    jobId,
  });
  const removeLcvisOverlays = useRemoveOverlays({ projectId, workflowId, jobId });
  const projectMetadata = useProjectMetadata(projectId);
  const [overlayMode, setOverlayMode] = useOverlayMode();
  const { leafMap } = useEntityGroupData(projectId, workflowId, jobId);

  const workflow = useWorkflowState(projectId, workflowId);
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const activeVisUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const isAnalysisView = useIsAnalysisView();
  const location = useLocation();

  const isExploration = isInExploration(location.pathname);
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const showBars = (!!activeVisUrl && !isSensitivity) || isGeometryView;

  // == Hooks
  const classes = useStyles();
  const onManualCameraViewChange = useHandleManualCameraViewChange();
  const handleLcvButtonClick = useHandleLcVisFilterClick();
  const rootStyles = useToolbarMargin();
  const zoomToFit = useZoomToFit();

  // == Data
  const projectName = projectMetadata ?
    projectMetadata.summary!.name : '';
  const pvClientDisconnected = paraviewClientState.client === null;
  const showLcVisScreenshotButton = lcvisEnabled;
  const showParaviewScreenshotButton = (
    !lcvisEnabled &&
    !isGeometryView &&
    currentTab
  );

  const modeOptions = [
    {
      value: CameraMode.PERSPECTIVE,
      name: 'Perspective',
      selected: lcvisEnabled ?
        lcvisCameraState.orthographic === false :
        cameraMode === CameraMode.PERSPECTIVE,
    },
    {
      value: CameraMode.ORTHOGRAPHIC,
      name: 'Orthographic',
      selected: lcvisEnabled ?
        lcvisCameraState.orthographic === true :
        ORTHOGRAPHIC_VIEWS.includes(cameraMode),
    },
  ];

  // keeps track of when a camera control keyboard function was called.
  // Then gets compared to paraviewRenderer.mostRecentCameraUpdate.
  const timeRef = useRef<number | null>(null);

  const zoomToSelected = useCallback(() => {
    if (lcvisEnabled) {
      zoomToSelection(expandGroups(leafMap)(selectedNodeIds));
    }
  }, [leafMap, selectedNodeIds, lcvisEnabled]);

  const handleKeydown = useCallback((event: AnyKeyboardEvent) => {
    if (disabled || event.repeat) {
      return;
    }
    if (isEventTrigger('zoomIn', event)) {
      event.preventDefault();
      paraviewRenderer.zoomCamera(ZOOM_IN_FACTOR);
    } else if (isEventTrigger('zoomOut', event)) {
      event.preventDefault();
      paraviewRenderer.zoomCamera(ZOOM_OUT_FACTOR);
    } else if (isEventTrigger('resetCamera', event)) {
      event.preventDefault();
      if (lcvisEnabled) {
        lcvResetCamera();
      } else {
        paraviewRenderer.resetCamera();
      }
    } else if (isEventTrigger('zoomSelection', event)) {
      zoomToSelected();
    }
  }, [lcvisEnabled, paraviewRenderer, disabled, zoomToSelected]);
  useEventListener(
    'keydown',
    (event: ListenerEvent) => handleKeydown(event as AnyKeyboardEvent),
  );

  const handlePanAndRotate = useCallback((event: AnyKeyboardEvent) => {
    if (disabled || !paraviewRenderer.panCamera || !paraviewRenderer.rotateCamera) {
      return;
    }
    // timeRef keeps track of the last time we called handlePan or handleRotate. If
    // timeRef.current is greater than mostRecentCameraUpdate, then Paraview hasn't updated since
    // our last call. So we should do nothing to avoid overwhelming Paraview with requests.
    if (timeRef.current && timeRef.current > paraviewRenderer.mostRecentCameraUpdate) {
      return;
    }
    const timeBeforePVCalls = Date.now();
    if (isEventTrigger('panRight', event)) {
      paraviewRenderer.panCamera(PanDirectionType.VIEW_RIGHT, PAN_FACTOR * -1);
    } else if (isEventTrigger('panLeft', event)) {
      paraviewRenderer.panCamera(PanDirectionType.VIEW_RIGHT, PAN_FACTOR);
    } else if (isEventTrigger('panUp', event)) {
      paraviewRenderer.panCamera(PanDirectionType.VIEW_UP, PAN_FACTOR);
    } else if (isEventTrigger('panDown', event)) {
      paraviewRenderer.panCamera(PanDirectionType.VIEW_UP, PAN_FACTOR * -1);
    } else if (isEventTrigger('rotateRight', event)) {
      paraviewRenderer.rotateCamera(RotationAxisType.VIEW_UP, ROTATE_FACTOR);
    } else if (isEventTrigger('rotateLeft', event)) {
      paraviewRenderer.rotateCamera(RotationAxisType.VIEW_UP, ROTATE_FACTOR * -1);
    } else if (isEventTrigger('rotateUp', event)) {
      paraviewRenderer.rotateCamera(RotationAxisType.VIEW_RIGHT, ROTATE_FACTOR);
    } else if (isEventTrigger('rotateDown', event)) {
      paraviewRenderer.rotateCamera(RotationAxisType.VIEW_RIGHT, ROTATE_FACTOR * -1);
    } else if (isEventTrigger('rotateClockwise', event)) {
      paraviewRenderer.rotateCamera(RotationAxisType.VIEW_TOWARD, ROTATE_FACTOR * -1);
    } else if (isEventTrigger('rotateAntiClockwise', event)) {
      paraviewRenderer.rotateCamera(RotationAxisType.VIEW_TOWARD, ROTATE_FACTOR);
    } else {
      return;
    }
    onManualCameraViewChange();
    // only update timeRef if we did call a paraviewRenderer function.
    timeRef.current = timeBeforePVCalls;
  }, [paraviewRenderer, disabled, onManualCameraViewChange]);

  useEventListener(
    'keydown',
    (event: ListenerEvent) => handlePanAndRotate(event as AnyKeyboardEvent),
  );

  // We always show the mesh in the analysis page,
  // and always show the geometry in the geometry page.
  const controlBar = showBars && meshReadyState && (
    <ParaviewControlBar meshGeomToggle={!isAnalysisView && !isGeometryView} />
  );

  return (
    <div className={classes.root} style={rootStyles}>
      <div className={classes.panel} data-locator="cameraControlPanel">
        {!workflowFlag && (
          <>
            {showLcVisScreenshotButton && (
              <FloatingGroup>
                <ParaviewButton
                  className={classes.button}
                  dataLocator="camerabar-screenshot"
                  disabled={!lcVisReady}
                  iconClassName={classes.icon}
                  iconName="screenshot"
                  onClick={(ev) => handleLcvButtonClick(ev, 'screenshot')}
                  title={`Screenshot (press ${METAKEY_SYMBOL} + Click to use a transparent
                  background)`}
                />
              </FloatingGroup>
            )}
            {showParaviewScreenshotButton && (
              <FloatingGroup>
                <ParaviewButton
                  className={classes.button}
                  dataLocator="camerabar-screenshot"
                  disabled={pvClientDisconnected}
                  iconClassName={classes.icon}
                  iconName="desktopMonitorFramed"
                  onClick={(ev) => {
                    // To avoid showing a modal dialog to opt in for a screenshot with a transparent
                    // background, we allow users to press ctrl (or cmd) + click to enable such a
                    // mode.
                    const transparentBackground = (ev.ctrlKey || ev.metaKey);
                    paraviewRenderer.screenshot(
                      `Project ${projectName} ${currentTab!.text}`,
                      transparentBackground,
                    );
                  }}
                  title={`Screenshot (press ${METAKEY_SYMBOL} + Click to use a transparent
                  background)`}
                />
              </FloatingGroup>
            )}
          </>
        )}

        <FloatingGroup>
          <DataSelect
            asBlock
            disabled={disabled}
            kind="minimal"
            onChange={(mode) => {
              if (!lcvisEnabled) {
                setCameraMode(mode as CameraMode);
                onManualCameraViewChange();
              } else {
                setLcvisCameraState((oldState) => ({
                  ...oldState,
                  orthographic: mode === CameraMode.ORTHOGRAPHIC,
                  editSource: 'UI',
                }));
              }
            }}
            options={modeOptions}
            size="small"
          />
          <ParaviewCameraViewsControl
            disabled={disabled}
          />
          <ParaviewButton
            aria-keyshortcuts={getAria('resetCamera')}
            className={classes.button}
            disabled={disabled}
            iconClassName={classes.icon}
            iconName="arrowsOut"
            onClick={zoomToFit}
            title={<TipWithBindings binding={getHelpText('resetCamera')} tip="Zoom to Fit" />}
          />
          {lcvisEnabled && (
            <ParaviewButton
              aria-keyshortcuts={getAria('zoomSelection')}
              className={classes.button}
              disabled={disabled || !selectedNodeIds.length}
              iconClassName={classes.icon}
              iconName="geometryScale"
              onClick={zoomToSelected}
              title={(
                <TipWithBindings
                  binding={getHelpText('zoomSelection')}
                  tip="Zoom to Selection"
                />
              )}
            />
          )}
          <ParaviewButton
            className={classes.button}
            disabled={disabled}
            iconClassName={classes.icon}
            iconName="ringCircle"
            onClick={async () => {
              if (lcvisEnabled) {
                await removeLcvisOverlays({ excludeType: 'centerOfRotation' });
                setLcvisCameraState((oldState) => ({
                  ...oldState,
                  center_of_rotation_modifier: oldState.center_of_rotation_modifier ? 0 : 1,
                  editSource: 'UI',
                }));
                return;
              }
              // Toggle CENTER_OF_ROTATION on or off.
              if (overlayMode === OverlayMode.CENTER_OF_ROTATION) {
                setOverlayMode(OverlayMode.NONE);
              } else {
                setOverlayMode(OverlayMode.CENTER_OF_ROTATION);
              }
            }}
            title="Set Center of Rotation"
          />
          <ParaviewButton
            aria-keyshortcuts={getAria('zoomOut')}
            className={classes.button}
            disabled={disabled}
            iconClassName={classes.icon}
            iconName="dash"
            onClick={async () => {
              if (lcvisEnabled) {
                await emitLCVKeyEvent('zoomOut');
                return;
              }
              paraviewRenderer.zoomCamera(ZOOM_OUT_FACTOR);
              onManualCameraViewChange();
            }}
            title={<TipWithBindings binding={getHelpText('zoomOut')} tip="Zoom Out" />}
          />
          <ParaviewButton
            aria-keyshortcuts={getAria('zoomIn')}
            className={classes.button}
            disabled={disabled}
            iconClassName={classes.icon}
            iconName="plus"
            onClick={async () => {
              if (lcvisEnabled) {
                await emitLCVKeyEvent('zoomIn');
                return;
              }
              paraviewRenderer.zoomCamera(ZOOM_IN_FACTOR);
              onManualCameraViewChange();
            }}
            title={<TipWithBindings binding={getHelpText('zoomIn')} tip="Zoom In" />}
          />
          <ParaviewButton
            className={classes.button}
            disabled={disabled || (!lcvisEnabled && cameraMode === CameraMode.PERSPECTIVE)}
            iconClassName={classes.icon}
            iconName="boxSelect"
            onClick={async () => {
              if (lcvisEnabled) {
                await removeLcvisOverlays({ excludeType: 'zoomToBox' });
                setLcvisCameraState((oldState) => ({
                  ...oldState,
                  zoom_to_box_modifier: oldState.zoom_to_box_modifier ? 0 : 1,
                  editSource: 'UI',
                }));
                return;
              }
              // Toggle ZOOM_TO_BOX on or off.
              if (overlayMode === OverlayMode.ZOOM_TO_BOX) {
                setOverlayMode(OverlayMode.NONE);
              } else {
                setOverlayMode(OverlayMode.ZOOM_TO_BOX);
              }
            }}
            title={!lcvisEnabled && cameraMode === CameraMode.PERSPECTIVE ? (
              <div>
                Zoom to Box is disabled. Switch to<br />
                Orthographic mode to enable.
              </div>
            ) :
              'Zoom to Box'}
          />
        </FloatingGroup>
        <FloatingGroup>{controlBar}</FloatingGroup>
        {!workflowFlag && <FloatingGroup><SelectionControlBar /></FloatingGroup>}
      </div>
    </div>
  );
};

export default CameraControlPanel;
