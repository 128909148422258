// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useProjectContext } from '../../components/context/ProjectContext';
import { applyTransparency, setTransparentOpacity } from '../../lib/lcvis/api';
import { useMeshUrlState } from '../meshState';
import { useActiveVisUrlValue } from '../vis/activeVisUrl';

// The opacity of transparent surfaces in transparency mode
export const TRANSPARENCY_MODE_OPACITY = 0.2;
// The default opacity of transparent surfaces in LCVis
export const DEFAULT_OPACITY = 0.5;

export type TransparencySettings = {
  /** Set of surfaces that need to be either transparent or opaque (based on the `mode` flag). */
  targetSurfaceIds: Set<string>;
  /** Defines whether the selected surfaces should be transparent or opaque. */
  mode: 'transparent' | 'opaque';
  /** Some surfaces must always remain transparent (now: only farfields). */
  alwaysTransparentSurfaceIds: Set<string>;
}

export type TransparencyDialogSettings = Pick<TransparencySettings, 'targetSurfaceIds' | 'mode'> & {
  active: boolean
};

export const defaultTransparencySettings = (): TransparencySettings => ({
  targetSurfaceIds: new Set<string>(),
  mode: 'transparent',
  alwaysTransparentSurfaceIds: new Set<string>(),
});

export const defaultTransparencyDialogSettings = (): TransparencyDialogSettings => ({
  targetSurfaceIds: new Set<string>(),
  mode: 'transparent',
  active: false,
});

export const TRANSPARENCY_MODE_SUBTITLE = `
  While in Transparency Mode, the specified surfaces are see-through and can't be interacted with.
`;

/**
 * Unlike `transparencyDialogState`, this atom manages which surfaces need to be visible
 * and specifies their operational modes. It stores identifiers for surfaces that must
 * always remain transparent, regardless of the other properties.
 *
 * Currently, this functionality is used only for far fields but can be extended in the future.
 */
export const transparencySettingsState = atom<TransparencySettings>({
  key: 'transparencySettings',
  default: defaultTransparencySettings(),
  effects: [({ onSet }) => {
    onSet((newVal) => {
      applyTransparency(
        newVal.targetSurfaceIds,
        newVal.mode === 'opaque',
        newVal.alwaysTransparentSurfaceIds,
      );

      const newOpacity = newVal.targetSurfaceIds.size > 0 ?
        TRANSPARENCY_MODE_OPACITY :
        DEFAULT_OPACITY;

      setTransparentOpacity(newOpacity);
    });
  }],
});

export const useSetTransparencySettings = () => useSetRecoilState(transparencySettingsState);

/** The `transparencyDialogState` manages whether the panel is displayed, the items it contains,
 *  and whether selected items are transparent or opaque.
 *  It is used in the `TransparencyDialog` component, which automatically synchronizes its state
 *  with `transparencySettingsState`.
 * */
export const transparencyDialogState = atom<TransparencyDialogSettings>({
  key: 'transparencyDialogSettings',
  default: defaultTransparencyDialogSettings(),
});

export const useTransparencyDialogValue = () => useRecoilValue(transparencyDialogState);
export const useSetTransparencyDialog = () => useSetRecoilState(transparencyDialogState);
export const useTransparencyDialog = () => useRecoilState(transparencyDialogState);

/**
 * Handles resetting the transparency settings whenever the user navigates away from the current
 * LCVis view.
 */
export const useResetTransparency = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const setTransparencySettings = useSetTransparencySettings();
  const setTransparencyDialogSettings = useSetTransparencyDialog();
  const activeVisUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const [meshUrlState] = useMeshUrlState(projectId);

  useEffect(() => () => {
    setTransparencySettings(defaultTransparencySettings());
    setTransparencyDialogSettings(defaultTransparencyDialogSettings());
  }, [
    setTransparencySettings,
    activeVisUrl,
    meshUrlState.activeType,
    setTransparencyDialogSettings,
  ]);
};
