// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import GroupMap from '../../../../lib/GroupMap';
import { SelectOption } from '../../../../lib/componentTypes/form';
import { EntityGroupMap } from '../../../../lib/entityGroupMap';
import { isFarfield, isFarfieldChild } from '../../../../lib/farfieldUtils';
import { NodeType, SimulationTreeNode } from '../../../../lib/simulationTree/node';
import { useEntityGroupMap, useSetEntityGroupMap } from '../../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { useProjectContext } from '../../../context/ProjectContext';

// A drop-down menu to select the parent of simulation tree node
export const ParentSelector = ({ node }: { node: SimulationTreeNode }) => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  if (!node) {
    throw Error('ParentSelector should only display when a node is selected.');
  }
  const entityGroups = useEntityGroupMap(projectId, workflowId, jobId);
  const setEntityGroups = useSetEntityGroupMap(projectId, workflowId, jobId);
  const farfieldChild = isFarfieldChild(node.id, entityGroups);
  const selectedNodeIsFarfield = isFarfield(node.id) || farfieldChild;
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  // Create a list of possible parents that can be selected
  const parentOptions: SelectOption<string>[] = useMemo(() => {
    const possibleParents = entityGroups.getGroups(
      // Filter possible parents
      (group) => (
        // Do not include entities that are leafs
        !!group.children.size &&
        // Do not include the currently selected group itself
        group.id !== node.id &&
        // Do not include any groups that have the selected group as ancestor
        !entityGroups.findAncestor(group.id, (ancestor) => ancestor.id === node.id) &&
        // Do not include farfield
        (!isFarfield(group.id) || selectedNodeIsFarfield) &&
        // Do not include farfield children
        (!isFarfieldChild(group.id, entityGroups) || selectedNodeIsFarfield)
      ),
      true, // Include root node
    );
    return possibleParents.map((group) => (
      {
        name: GroupMap.isRoot(group.id) ? 'None' : group.name,
        value: group.id,
        selected: group.id === entityGroups.get(node.id).parentId!,
      }
    ));
  }, [entityGroups, node, selectedNodeIsFarfield]);

  const changeParent = (newParentId: string) => {
    setEntityGroups((oldMap) => {
      if (oldMap.get(node.id).parentId === newParentId) {
        return oldMap;
      }
      const newGroupMap = new EntityGroupMap(oldMap);
      newGroupMap.move(node.id, newParentId);
      return newGroupMap;
    });
  };

  // setting parent groups for tags and surfaces/volumes inside tags probably doesn't make sense
  const isTagFace = node.type === NodeType.TAGS_FACE;
  const isTagWrapper = node.type === NodeType.SURFACE_GROUP && geometryTags.isTagId(node.id);
  const isTagElement = isTagFace || isTagWrapper;

  return (
    <div>
      <LabeledInput
        help="The containing group of the selected group/surface."
        label="Parent Group">
        <DataSelect
          asBlock
          disabled={readOnly || farfieldChild || isTagElement}
          onChange={changeParent}
          options={parentOptions}
          size="small"
        />
      </LabeledInput>
    </div>
  );
};
