import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 24;
const baseHeight = 24;
export const CodeIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="tag"
      fill="none"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        clipRule="evenodd"
        d="M13.3252 3.05011L8.66765 20.4323L10.5995 20.9499L15.257 3.56775L13.3252 3.05011Z"
        fillRule="evenodd"
        style={{ fill: getColor(0) }}
      />
      <path
        clipRule="evenodd"
        d="M7.61222 18.3608L8.97161 16.9124L8.9711 16.8933L3.87681 12.1121L8.66724 7.00798L7.20892
        5.63928L1.0498 12.2017L7.61222 18.3608Z"
        fillRule="evenodd"
        style={{ fill: getColor(0) }}
      />
      <path
        clipRule="evenodd"
        d="M16.3883 18.3608L15.0289 16.9124L15.0294 16.8933L20.1237 12.1121L15.3333 7.00798L16.7916
        5.63928L22.9507 12.2017L16.3883 18.3608Z"
        fillRule="evenodd"
        style={{ fill: getColor(0) }}
      />
    </svg>
  );
});
