// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import { getTabName } from '../../lib/TabManager';
import WorkflowConfigValidator, { formatValidatorMessage } from '../../lib/WorkflowConfigValidator';
import { CurrentView, INTERMEDIATE_VIEWS, isIntermediateView } from '../../lib/componentTypes/context';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import { convertToProto } from '../../lib/entityGroupUtils';
import { formatValidateParamMessage } from '../../lib/errorFormatters';
import { resultsLink, workflowLink } from '../../lib/navigation';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { LeveledMessage, levelToRank, sortLeveledMessages } from '../../lib/notificationUtils';
import { Logger } from '../../lib/observability/logs';
import * as persist from '../../lib/persist';
import { updateExploration } from '../../lib/proto';
import * as rpc from '../../lib/rpc';
import { getOrCreateConvergenceCriteria, getSimulationParam } from '../../lib/simulationParamUtils';
import { NodeType } from '../../lib/simulationTree/node';
import { getSimCount } from '../../lib/simulationTree/utils';
import { getNodeMessages } from '../../lib/simulationValidation';
import { updateStoppingConds } from '../../lib/stoppingCondsUtils';
import { PARAM_VALIDATION_ERROR_NOTIFICATION_ID, addError, addRpcError, setNotification } from '../../lib/transientNotification';
import * as basepb from '../../proto/base/base_pb';
import * as explorationpb from '../../proto/exploration/exploration_pb';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import { Code } from '../../proto/lcstatus/codes_pb';
import * as levelspb from '../../proto/lcstatus/levels_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import {
  entityGroupDataSelector,
  entityGroupPrefix,
  serialize as serializeEntityGroups,
} from '../../recoil/entityGroupState';
import {
  frontendMenuState,
  frontendMenuStatePrefix,
  serialize as serializeFrontendMenu,
} from '../../recoil/frontendMenuState';
import { useGeometryHealth } from '../../recoil/geometryHealth';
import { meshMetadataSelector, meshUrlState } from '../../recoil/meshState';
import { outputNodesPrefix, outputNodesState, serialize as serializeOutputs } from '../../recoil/outputNodes';
import { useNoCredits } from '../../recoil/useAccountInfo';
import { GeometryTreePositionType, useGeometryTreePosition } from '../../recoil/useGeometryTreePosition';
import { useControlPanelMode, useIsBaselineMode, useIsExploration, useIsExplorationStarterPlan } from '../../recoil/useProjectPage';
import {
  serialize as serializeStopConds,
  stoppingConditionsPrefix,
  stoppingConditionsSelectorUpdate,
} from '../../recoil/useStoppingConditions';
import { useSkipSetupSummaryValue } from '../../recoil/user';
import { currentConfigSelector, useBatchModeChecked, useCurrentConfig } from '../../recoil/workflowConfig';
import { analytics } from '../../services/analytics';
import { useRunSimulationPending } from '../../state/external/project/simulation/runSimulationPending';
import { useSetSetupSummaryOpened } from '../../state/external/project/simulation/setupSummaryOpened';
import { useAllTabWarnings, useProjectValidator } from '../../state/external/project/validator';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { currentViewAtom_DEPRECATED, useCurrentView, useIsAnalysisView, useIsGeometryView } from '../../state/internal/global/currentView';
import { useGeometryTree } from '../../state/internal/tree/section/geometry';
import { useSimulationTree } from '../../state/internal/tree/simulation';
import { useWorkflowFlagValue } from '../../workflowFlag';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

// NOTE: this must be in sync with the backend limits package.
const MAX_JOBS_PER_WORKFLOW = 300;

/**
 * See LC-21985: we don't want to display the full stack trace in the UI.
 * Instead, it's better to display information that is valuable to the user
 * and log the full error in the browser console.
 * */
function displayErrorFeedback(message: string) {
  const humanReadableError =
    `${formatValidateParamMessage(message)}. More details are available in the browser console.`;

  addError(humanReadableError, 'One or more of your simulation parameters is invalid');

  console.log(message);
}

/**
 * Called when "Run Simulation" or "Run Exploration" button is pressed.
 */
const newWorkflow = async (
  projectId: string,
  config: workflowpb.Config,
  navigate: ReturnType<typeof useNavigate>,
  frontendState: projectstatepb.FrontendMenuState,
  stopConds: feoutputpb.StoppingConditions,
  outputs: feoutputpb.OutputNodes,
  entityGroups: EntityGroupMap,
  isExploration: boolean,
) => {
  const logger = new Logger('newWorkflow');
  const entityGroupMap = convertToProto(entityGroups);
  const param = getSimulationParam(config);

  const paramEntityMap = getOrCreateConvergenceCriteria(param).entityGroup;
  Object.entries(entityGroupMap.groups).forEach(([key, group]) => {
    paramEntityMap[key] = group;
  });
  const req = new frontendpb.NewWorkflowRequest({ projectId, config });
  try {
    const workflowReply = await rpc.client.newWorkflow(req);
    const workflowId = workflowReply.workflowId;
    const jobIds = workflowReply.jobId;
    logger.debug('created workflow', workflowId);

    const stateUpdates: Promise<any>[] = [];

    // Set all states in the kv store that are stored on per-job basis
    jobIds.forEach((jobId) => {
      const getKey = (prefix: string) => (
        persist.getProjectStateKey(prefix, { projectId, workflowId, jobId })
      );
      stateUpdates.push(
        persist.setStateBatchNow(
          projectId,
          [
            getKey(frontendMenuStatePrefix),
            getKey(stoppingConditionsPrefix),
            getKey(outputNodesPrefix),
            getKey(entityGroupPrefix),
          ],
          new Date(),
          [
            serializeFrontendMenu(frontendState),
            serializeStopConds(stopConds),
            serializeOutputs(outputs),
            serializeEntityGroups(convertToProto(entityGroups)),
          ],
        ),
      );
    });

    // Wait for all promises to resolve
    await Promise.all(stateUpdates);

    if (isExploration) {
      if (config.exploration!.policy.case === 'sensitivityAnalysis') {
        navigate(workflowLink(projectId, workflowId, true));
      } else {
        navigate(resultsLink(projectId));
      }
    } else {
      navigate(workflowLink(projectId, workflowId, false));
    }
  } catch (err: any) {
    addRpcError('Error creating a new workflow', err);
  }
};

/**
 * Returns a function that is called when the user runs a simulation or an exploration.
 */
export const useHandleWorkflowCreate = () => {
  const { projectId } = useProjectContext();
  const isExploration = useIsExploration();
  const workflowFlag = useWorkflowFlagValue();
  const navigate = useNavigate();

  const logger = new Logger('useHandleWorkflowCreate');

  return useRecoilCallback(
    ({ snapshot: { getPromise } }) => async () => {
      const currentView = await getPromise(currentViewAtom_DEPRECATED);
      if (currentView !== CurrentView.SETUP && !workflowFlag) {
        throw Error('onRunSimulation: shall not be called');
      }

      const meshUrl = await getPromise(meshUrlState(projectId));
      const metaUrl = meshUrl.mesh || meshUrl.geometry;
      const meshMetadata = await getPromise(meshMetadataSelector({ projectId, meshUrl: metaUrl }));
      const recoilKey = { projectId, workflowId: '', jobId: '' };

      const projectConfig = await getPromise(
        currentConfigSelector(recoilKey),
      );
      let runConfig = projectConfig.clone();
      // If we are not running an experiment, set experiment to baseline.
      if (!isExploration) {
        const baseline = new explorationpb.Exploration({
          policy: { case: 'baseline', value: new explorationpb.Baseline() },
        });
        runConfig = updateExploration(runConfig, baseline);
      }
      const curFrontendMenuState = await getPromise(
        frontendMenuState(recoilKey),
      );
      const outputNodes = await getPromise(outputNodesState(recoilKey));
      const stopConds = await getPromise(stoppingConditionsSelectorUpdate(recoilKey));

      // Make sure to use the computational mesh when running a simulation.
      const param = getSimulationParam(runConfig);
      param.input!.filename = metaUrl;
      if (!param.input?.url) {
        param.input!.url = metaUrl;
      }

      const entityGroupData = await getPromise(entityGroupDataSelector(recoilKey));

      // Set the stopping conditions in param
      if (runConfig.jobConfigTemplate) {
        runConfig.jobConfigTemplate.typ = {
          case: 'simulationParam',
          value: updateStoppingConds(stopConds, param, outputNodes, entityGroupData),
        };
      }

      const req = new frontendpb.ValidateParamRequest({
        projectId,
        simulationParam: getSimulationParam(runConfig),
      });
      const reply = await rpc.callRetry(
        'ValidateParam',
        rpc.client.validateParam,
        req,
      );

      // if the reply from ValidateParam contains an error message, a non-retriable error
      // occurred. Break before attempting to run the simulation.
      if (reply.errorMsg) {
        displayErrorFeedback(reply.errorMsg);
        return;
      }

      logger.info(`ValidateParamRequest answer:${JSON.stringify(reply)}`);

      // Delegate the workflow creation to the validator output. If no error is
      // reported, then the workflow can be created.
      const validator = new WorkflowConfigValidator(async (err: basepb.Status) => {
        if (err.code === Code.LC_OK) {
          await newWorkflow(
            projectId,
            runConfig,
            navigate,
            curFrontendMenuState,
            stopConds,
            outputNodes,
            entityGroupData.groupMap,
            isExploration,
          );
        } else {
          setNotification(
            PARAM_VALIDATION_ERROR_NOTIFICATION_ID,
            'error',
            formatValidatorMessage(err),
            err,
          );
          logger.warn(`Got validation result: ${err.toJsonString()}`);
        }
      });
      await validator.checkAsync(runConfig, meshMetadata!.meshMetadata);
    },
  );
};

const explorationWarningThreshold: number = 10;

/**
 * Returns a function that is used to run the simulation.
 * This is called from these places:
 *  - the Setup Summary dialog;
 *  - the Run Simulation button (when the skipSetupSummary is true).
 */
export const useHandleRunSumulation = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == State
  const [runSimulationPending, setRunSimulationPending] = useRunSimulationPending();
  const currentConfig = useCurrentConfig(projectId, workflowId, jobId);
  const setConfirmStack = useSetConfirmations();
  const isExploration = useIsExploration();
  const onRunSimulation = useHandleWorkflowCreate();
  const batchModeChecked = useBatchModeChecked(projectId, workflowId, jobId);

  const queueSimulation = useCallback((simCount: number) => {
    pushConfirmation(setConfirmStack, {
      onContinue: async () => {
        setRunSimulationPending(true);
        try {
          await onRunSimulation();
        } catch (error: any) {
          setRunSimulationPending(false);
          throw error;
        }
        setRunSimulationPending(false);
      },
      title: 'Confirm Exploration',
      children: `The requested exploration will create ${simCount.toLocaleString()} simulations.
        Are you sure you want to proceed?`,
    });
  }, [onRunSimulation, setConfirmStack, setRunSimulationPending]);

  return useCallback(async () => {
    if (runSimulationPending) {
      return;
    }
    setRunSimulationPending(true);
    try {
      if (isExploration) {
        const simCount = getSimCount(currentConfig);
        if (simCount > explorationWarningThreshold) {
          queueSimulation(simCount);
        } else {
          await onRunSimulation();
          analytics.track('Run Simulation Button Clicked', {
            type: 'exploration',
            simCount,
            batchMode: batchModeChecked,
          });
        }
      } else {
        await onRunSimulation();
        analytics.track('Run Simulation Button Clicked', {
          type: 'single',
          batchMode: batchModeChecked,
        });
      }
    } catch (error: any) {
      setRunSimulationPending(false);
      throw error;
    }
    setRunSimulationPending(false);
  }, [
    batchModeChecked,
    currentConfig,
    isExploration,
    onRunSimulation,
    queueSimulation,
    runSimulationPending,
    setRunSimulationPending,
  ]);
};

/**
 * Returns a function that is called when the Run Simulation button is clicked or when the
 * AI assistant tries to run the simulation.
 */
export const useClickRunSimulationButton = () => {
  const skipSetupSummary = useSkipSetupSummaryValue();
  const handleRunSimulation = useHandleRunSumulation();
  const currentView = useCurrentView();
  const workflowFlag = useWorkflowFlagValue();
  const isBaselineMode = useIsBaselineMode();
  const setSummaryOpened = useSetSetupSummaryOpened();

  const hasSetupSummary = (
    currentView === CurrentView.SETUP || (workflowFlag && isIntermediateView(currentView))
  ) && isBaselineMode;

  return useCallback(async () => {
    if (hasSetupSummary && !skipSetupSummary) {
      setSummaryOpened(true);
    } else {
      await handleRunSimulation();
    }
  }, [handleRunSimulation, hasSetupSummary, setSummaryOpened, skipSetupSummary]);
};

// Find the root node for the current panel.
export const usePanelRoot = () => {
  const { projectId, workflowId, jobId } = useProjectContext();

  const isGeometryView = useIsGeometryView();
  const [controlPanelMode] = useControlPanelMode();
  const simulationTree = useSimulationTree(projectId, workflowId, jobId);

  const panelRoot = useMemo(() => {
    let nodeType = NodeType.ROOT_SIMULATION;
    switch (controlPanelMode) {
      case 'exploration':
        nodeType = NodeType.ROOT_EXPLORATION;
        break;
      case 'simulation':
        nodeType = NodeType.ROOT_SIMULATION;
        break;
      case 'geometry':
        nodeType = NodeType.ROOT_GEOMETRY;
        break;
      default:
        throw Error('Invalid panel mode');
    }
    // Due to the way in which controlPanelMode is set (through useEffect), there is a race
    // between the controlPanelMode and the simulationTree (the latter is handled via recoil).
    // For now, we'll just set the nodeType by hand if we are in the geometry view.
    if (isGeometryView) {
      nodeType = NodeType.ROOT_GEOMETRY;
    }
    return simulationTree.children.find((node) => node.type === nodeType);
  }, [controlPanelMode, isGeometryView, simulationTree]);

  return panelRoot;
};

const errorLevel = levelToRank('error');

export const useRunSimulationButtonProps = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { activeNodeTable } = useSelectionContext();
  const isAnalysisView = useIsAnalysisView();
  const [geometryHealth] = useGeometryHealth(projectId);
  const geometryTree = useGeometryTree(projectId, workflowId, jobId);
  const geometryTreePosition = useGeometryTreePosition({ projectId, workflowId, jobId });
  const panelRoot = usePanelRoot();
  const validator = useProjectValidator(projectId, workflowId, jobId);
  const currentConfig = useCurrentConfig(projectId, workflowId, jobId);
  const simCount = useMemo(() => getSimCount(currentConfig), [currentConfig]);
  const hasNoCredits = useNoCredits();
  const isExplorationButtonDisabled = useIsExplorationStarterPlan();

  const messageMap = useAllTabWarnings(projectId, workflowId, jobId);
  const workflowFlag = useWorkflowFlagValue();
  const currentView = useCurrentView();
  const separatedGeometryTree = geometryTreePosition === GeometryTreePositionType.FLOATING;

  const messages = useMemo(() => {
    const leveledMessages: LeveledMessage[] = [];
    if (workflowFlag) {
      INTERMEDIATE_VIEWS.forEach((view) => {
        if (messageMap.has(view) && messageMap.get(view)?.size && view !== currentView) {
          leveledMessages.push({
            level: 'error',
            message: `Fix errors in the ${getTabName(view)} tab before proceeding.`,
          });
        }
        if (view === currentView && messageMap.has(view)) {
          messageMap.get(view)?.forEach((msgs) => {
            leveledMessages.push(...msgs);
          });
        }
      });
      return leveledMessages;
    }
    // Check for any errors in the main simulation tree
    if (panelRoot) {
      leveledMessages.push(...getNodeMessages(validator, panelRoot, errorLevel));
    }
    // Check for errors in the floating geometry tree
    if (separatedGeometryTree) {
      leveledMessages.push(...getNodeMessages(validator, geometryTree, errorLevel));
    }
    // Check if is in starter plan
    if (isExplorationButtonDisabled) {
      leveledMessages.push({
        message: 'Cannot run Design of Experiments on the Starter plan', level: 'neutral',
      });
      // Check if starter plan users has credits left
    } else if (hasNoCredits) {
      leveledMessages.push(
        { message: 'Credits are required to run simulations', level: 'neutral' },
      );
    }

    if (simCount >= MAX_JOBS_PER_WORKFLOW) {
      leveledMessages.push({
        level: 'error',
        message: `Cannot run more that ${MAX_JOBS_PER_WORKFLOW} simulations per DOE.`,
      });
    }

    // Avoid appending messages here that don't come from the getNodeMessages() function
    sortLeveledMessages(leveledMessages);
    return leveledMessages;
  }, [
    panelRoot,
    separatedGeometryTree,
    geometryTree,
    validator,
    messageMap,
    workflowFlag,
    currentView,
    hasNoCredits,
    isExplorationButtonDisabled,
    simCount,
  ]);

  const hasErrors = !!messages.length;

  // Button is disabled with an active node table or warnings or a geometry health problem. In
  // analysis view, the button is "Copy to Setup" and the warnings don't apply.
  const hasGeometryIssues = geometryHealth?.issues.some((issue) => (
    issue.level === levelspb.Level.ERROR
  ));
  const disabled = (
    (activeNodeTable.type !== NodeTableType.NONE) ||
    ((hasErrors || hasGeometryIssues) && !isAnalysisView) ||
    simCount >= MAX_JOBS_PER_WORKFLOW
  );

  return useMemo(() => ({
    disabled,
    messages,
  }), [disabled, messages]);
};

export const useIsRunSimulationDisabled = () => {
  const [runSimulationPending] = useRunSimulationPending();
  const isExplorationButtonDisabled = useIsExplorationStarterPlan();
  const hasNoCredits = useNoCredits();
  const { disabled } = useRunSimulationButtonProps();

  return runSimulationPending || disabled || hasNoCredits || isExplorationButtonDisabled;
};
