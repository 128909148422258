// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamName, paramDesc } from '../../../../SimulationParamDescriptor';
import * as flags from '../../../../flags';
import { MESH_MAX_CELLS } from '../../../../lib/constants';
import { clampMaxMeshCount } from '../../../../lib/meshingStatusUtils';
import { clamp, fromBigInt } from '../../../../lib/number';
import { getOrCreateAdaptiveMeshRefinement } from '../../../../lib/simulationParamUtils';
import { AllTet } from '../../../../proto/client/simulation_pb';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import { useIsEnabled } from '../../../../recoil/useExperimentConfig';
import { useIsMeshDetailsPanel } from '../../../../recoil/useMeshPanelState';
import { useMeshReadOnly } from '../../../../recoil/useMeshReadOnly';
import useMeshMultiPart, { useSetMeshMultiPart } from '../../../../recoil/useMeshingMultiPart';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberInput } from '../../../Form/NumberInput';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { LuminaryToggleSwitch } from '../../../controls/LuminaryToggleSwitch';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import PropertiesSection from '../../PropertiesSection';

import { LMAHeaderSection } from './components/LMAHeaderSection';
import { MeshHeaderSection } from './components/MeshHeaderSection';

type ComplexityParams = meshgenerationpb.MeshingMultiPart_MeshComplexityParams;
const ComplexityType = meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType;

// A single input containing the desired mesh size for Mesh Automation.
export const MeshAutomationSize = () => {
  const { readOnly } = useProjectContext();
  const { simParam, saveParam } = useSimulationConfig();

  const amr = getOrCreateAdaptiveMeshRefinement(simParam);

  return (
    <Form.LabeledInput
      help="The number of control volumes is doubled every two adaptive remesh operations until
      reaching the max mesh count."
      label="Max Mesh Count">
      <NumberInput
        asBlock
        disabled={readOnly}
        endAdornment={<div style={{ whiteSpace: 'nowrap' }}>million CVs</div>}
        onCommit={(newValue: number) => {
          saveParam((newParam) => {
            const intValue = clampMaxMeshCount(newValue);
            getOrCreateAdaptiveMeshRefinement(newParam).targetCvMillions = intValue;
          });
        }}
        readOnly={readOnly}
        size="small"
        value={fromBigInt(amr.targetCvMillions?.value ?? 0n)}
      />
    </Form.LabeledInput>
  );
};

// Inputs with mesh estimation scaling. Two inputs consisting of a Max / Target radio button and a
// mesh size that specifies one of three possible quantities. If set to automatic, the input will be
// the target in the params. Max is disabled. If not automatic, use max cells or target depending on
// the radio button.
export const MeshAutomationScaling = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const meshReadOnly = useMeshReadOnly(projectId);
  const complexityParams = meshMultiPart?.complexityParams;
  const isDetailsPanel = useIsMeshDetailsPanel(projectId);
  const complexityType = complexityParams?.type || ComplexityType.MAX;
  const MAX_NUM_CELLS = 2e8;
  const disabled = readOnly || meshReadOnly;

  // Apply a changeComplexity function to the complexity params and meshing mode.
  const setComplexity = (
    complexityDiff: Partial<ComplexityParams>,
    modeDiff: Partial<meshgenerationpb.MeshingMultiPart_MeshingMode> = {},
  ) => {
    setMeshMultiPart((oldMeshMultiPart) => {
      const newMeshMultiPart = oldMeshMultiPart!.clone();
      const newParams = new meshgenerationpb.MeshingMultiPart_MeshComplexityParams({
        ...newMeshMultiPart!.complexityParams,
        ...complexityDiff,
      });
      newMeshMultiPart.complexityParams = newParams;
      newMeshMultiPart.meshingMode = new meshgenerationpb.MeshingMultiPart_MeshingMode({
        ...newMeshMultiPart!.meshingMode,
        ...modeDiff,
      });
      return newMeshMultiPart;
    });
  };

  // If automatic, use the target in the params. Max is disabled.
  // If not automatic, use max cells or target depending on the current type.
  let currentSize;
  if (complexityType === ComplexityType.MAX) {
    // Divide max and target by one million. We are displaying units of one million CVs.
    currentSize = fromBigInt(complexityParams?.limitMaxCells ?? BigInt(0)) / 1e6;
  } else {
    currentSize = fromBigInt(complexityParams?.targetCells ?? BigInt(0)) / 1e6;
  }
  const handleChangeComplexity = (
    type: meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType,
  ) => {
    const complexityParam = meshMultiPart!.complexityParams;
    const complexityDiff: Partial<ComplexityParams> = { type };
    let modeDiff: Partial<meshgenerationpb.MeshingMultiPart_MeshingMode> = {};
    const numCells = Math.min(
      MAX_NUM_CELLS,
      Math.max(
        fromBigInt(complexityParam?.limitMaxCells ?? BigInt(0)),
        fromBigInt(complexityParam?.targetCells ?? BigInt(0)),
      ),
    );

    // When switching types, copy numCells into the new field and set other field to 0.
    switch (type) {
      case ComplexityType.MAX:
        complexityDiff.limitMaxCells = BigInt(numCells);
        complexityDiff.targetCells = BigInt(0);
        modeDiff = {
          mode: {
            case: 'default',
            value: new meshgenerationpb.MeshingMultiPart_MeshingMode_Default(),
          },
        };
        break;
      case ComplexityType.TARGET:
        complexityDiff.limitMaxCells = BigInt(0);
        complexityDiff.targetCells = BigInt(numCells);
        modeDiff = {
          mode: {
            case: 'default',
            value: new meshgenerationpb.MeshingMultiPart_MeshingMode_Default(),
          },
        };
        break;
      case ComplexityType.MIN:
        complexityDiff.limitMaxCells = BigInt(MESH_MAX_CELLS);
        complexityDiff.targetCells = BigInt(0);
        modeDiff = {
          mode: {
            case: 'base',
            value: new meshgenerationpb.MeshingMultiPart_MeshingMode_Base(),
          },
        };
        break;
      default:
        break;
    }
    setComplexity(complexityDiff, modeDiff);
  };

  const complexityOptions = [{
    name: 'Max Count',
    description: `If the mesh becomes larger than max cell count, the mesh will be scaled.
      The requested boundary layer profile will be maintained.`,
    selected: complexityType === ComplexityType.MAX,
    value: ComplexityType.MAX,
  }, {
    name: 'Target Count',
    description: `To reach a target number of cells, the edge length specifications will
      be proportionally scaled throughout the mesh. The requested boundary layer profile
      will be maintained.`,
    selected: complexityType === ComplexityType.TARGET,
    value: ComplexityType.TARGET,
  }, {
    name: 'Minimal Count',
    description: `A coarse mesh suitable for quickly confirming simulation settings before solving
      on a finer mesh`,
    selected: complexityType === ComplexityType.MIN,
    value: ComplexityType.MIN,
  }];

  return (
    <PropertiesSection>
      <MeshHeaderSection />
      {!isDetailsPanel && (
        <Form.LabeledInput label="Sizing Strategy">
          <DataSelect
            asBlock
            disabled={disabled}
            onChange={handleChangeComplexity}
            options={complexityOptions}
            size="small"
          />
        </Form.LabeledInput>
      )}
      {!isDetailsPanel && complexityType !== ComplexityType.MIN && (
        <Form.LabeledInput label="">
          <NumberInput
            asBlock
            disabled={disabled}
            endAdornment={<div style={{ whiteSpace: 'nowrap' }}>million CVs</div>}
            onCommit={(newValue: number) => {
              const intValue = clamp(Math.round(newValue * 1e6), [1, MAX_NUM_CELLS]);
              if (complexityType === ComplexityType.MAX) {
                setComplexity({ limitMaxCells: BigInt(intValue) });
              } else {
                setComplexity({ targetCells: BigInt(intValue) });
              }
            }}
            readOnly={disabled}
            size="small"
            value={Number(currentSize || 0)}
          />
        </Form.LabeledInput>
      )}
    </PropertiesSection>
  );
};

export const AllTetToggleSwitch = () => {
  const { readOnly } = useProjectContext();
  const { simParam, saveParam } = useSimulationConfig();
  const amr = getOrCreateAdaptiveMeshRefinement(simParam);
  if (
    amr.allTet === AllTet.ALL_TET_UNSET) {
    amr.allTet = AllTet.ALL_TET_ON;
  }
  const setAllTet = (newValue: boolean) => {
    saveParam((newParam) => {
      getOrCreateAdaptiveMeshRefinement(newParam).allTet = newValue ?
        AllTet.ALL_TET_ON : AllTet.ALL_TET_OFF;
    });
  };
  const allTetDesc = paramDesc[ParamName.AllTet];
  return (
    <Form.LabeledInput
      earlyAccess
      help={allTetDesc.help}
      label={allTetDesc.text}
      layout="favorLabel">
      <LuminaryToggleSwitch
        disabled={readOnly}
        onChange={setAllTet}
        small
        value={amr?.allTet === AllTet.ALL_TET_ON}
      />
    </Form.LabeledInput>
  );
};

export const LumiMeshAdaptationSection = () => (
  <>
    <PropertiesSection>
      <LMAHeaderSection />
      <MeshHeaderSection />
    </PropertiesSection>
    <Divider />
    <PropertiesSection>
      <div style={{ padding: '4px 0' }}>
        <MeshAutomationSize />
      </div>
      {useIsEnabled(flags.allTet) && (
        <div style={{ padding: '4px 0' }}>
          <AllTetToggleSwitch />
        </div>
      )}
    </PropertiesSection>
  </>
);
