// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo, useState } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { colors } from '../../../lib/designSystem';
import { expandGroups, isGroupVisible } from '../../../lib/entityGroupUtils';
import { isFarfield } from '../../../lib/farfieldUtils';
import { isGeomHealthId } from '../../../lib/geometryHealthUtils';
import { globalDisabledReason } from '../../../lib/geometryUtils';
import { assembleMenuSections, filteredMenuItems } from '../../../lib/menuUtil';
import { getAllAttachedSurfaceIds } from '../../../lib/motionDataUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import {
  deleteTreeNodeMenuItem,
  groupTreeNodeMenuItem,
  ungroupTreeNodeMenuItem,
  visibilityToggleTreeNodeMenuItem,
} from '../../../lib/treeUtils';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useIsGeometryServerActive } from '../../../recoil/geometry/geometryServerStatus';
import { useGeometrySelectedFeature } from '../../../recoil/geometry/geometryState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useGeometryUsesTags } from '../../../recoil/geometry/geometryUsesTags';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useSetNodeVisibility, useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import CreateTagDialog from '../../dialog/CreateTag';
import { useTagsInteractiveGeometry } from '../../hooks/useInteractiveGeometry';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeGrouping } from '../../hooks/useNodeGrouping';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'cubeOutline' };

// A row displaying information about a surface group.
export const SurfaceTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;
  const { id: nodeId } = node;

  // == Context
  const { viewState, visibilityMap } = useParaviewContext();
  const { projectId, workflowId, jobId, geometryId, readOnly } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const geometryTags = useGeometryTags(projectId);
  const geoUsesTags = useGeometryUsesTags(projectId);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteSurfaceGroupNode, postDeleteNodeIds } = useNodeDeletion();
  const {
    canGroup,
    canUngroup,
    groupEntities,
    ungroupEntities,
    groupableNodes,
  } = useNodeGrouping();
  const {
    createTag,
    isCreateTagDisabled,
    addSurfacesToExistingTag,
    removeItemFromTag,
  } = useTagsInteractiveGeometry();
  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const isGeoServerActive = useIsGeometryServerActive(geometryId);
  const setNodeVisibility = useSetNodeVisibility();

  const [isCreateTagDialogOpen, setIsCreateTagDialogOpen] = useState(false);
  const [tagCreationIds, setTagCreationIds] = useState<string[]>([]);

  const isTagChild = node.type === NodeType.TAGS_FACE;
  const surfaceId = isTagChild ? geometryTags.surfaceFromTagEntityGroupId(node.id) : nodeId;

  let isVisible = false;
  if (lcvisEnabled) {
    // NOTE: if a new mesh was loaded (e.g., a far field is added), visibilityV2 will not contain
    // the new (far field) surfaces. However, the registerFarFieldSurfaces function below will let
    // lcvis know which new surfaces are far fields.
    isVisible = isGroupVisible(visibilityV2, entityGroupData.groupMap, surfaceId);
  } else {
    isVisible = isGroupVisible(visibilityMap, entityGroupData.groupMap, surfaceId);
  }

  const surfacesWithMotion = getAllAttachedSurfaceIds(
    simParam,
    { motion: 'moving' },
    geometryTags,
    entityGroupData,
  );

  const toggleIds = useMemo(() => {
    const isSelected = selectedNodeIds.includes(surfaceId);
    // Toggle all the selected IDs, if this ID is selected.
    const ids = isSelected ? selectedNodeIds : [surfaceId];

    // Vis knows nothing about geometry health IDs, but those IDs are included as part of the
    // selection since both the health check row and the sim tree row are selected.
    return ids.reduce((result, id) => {
      if (!isGeomHealthId(id)) {
        result.add(id);
      }
      return result;
    }, new Set<string>());
  }, [selectedNodeIds, surfaceId]);

  const visControlsDisabled = lcvisEnabled ? !lcvisReady : !viewState;
  const toggleVis = useToggleVisibility(toggleIds, isVisible);
  const hideOthers = useCallback(
    () => setNodeVisibility(false, toggleIds, true),
    [setNodeVisibility, toggleIds],
  );

  const auxIcons = useMemo<IconSpec[]>(() => {
    if (surfacesWithMotion.has(surfaceId)) {
      return [{ name: 'rotatingDots', color: colors.citronGreen600 }];
    }
    return [];
  }, [surfaceId, surfacesWithMotion]);

  const deleteRow = useCallback(() => {
    if (deleteSurfaceGroupNode(surfaceId)) {
      postDeleteNodeIds([surfaceId]);
    }
  }, [deleteSurfaceGroupNode, surfaceId, postDeleteNodeIds]);

  const defaultTagDisabledReason = globalDisabledReason(
    selectedFeature,
    readOnly,
    isGeoServerActive,
  );

  const getContextMenuItems = useCallback(() => {
    const visItems = [
      visibilityToggleTreeNodeMenuItem(isVisible, toggleVis, visControlsDisabled),
      { label: 'Hide Others (Isolate)', onClick: hideOthers },
    ];

    const nodeIdsToGroup = groupableNodes(surfaceId);
    if (isGeometryView) {
      if (isCreateTagDisabled || !geoUsesTags) {
        return visItems;
      }
      // In case we are mixing groups and individual surfaces, make sure to expand the groups so
      // that we are sending the correct surface IDs to the backend.
      const surfaceIds = expandGroups(entityGroupData.leafMap)(nodeIdsToGroup);

      const addToTagDisabledReason = (
        defaultTagDisabledReason ||
        geometryTags.tags.length === 0 ? 'There are no existing Tags yet' : undefined
      );

      const groupingItems = [
        {
          label: 'Create Tag',
          onClick: async () => {
            setIsCreateTagDialogOpen(true);
            setTagCreationIds(surfaceIds);
          },
          disabled: !!defaultTagDisabledReason,
          disabledReason: defaultTagDisabledReason,
        },
        {
          label: 'Add to Tag',
          disabled: !!addToTagDisabledReason,
          disabledReason: addToTagDisabledReason,
          onClick: () => { },
          ...(addToTagDisabledReason ? {} : {
            items: geometryTags.tags.map((tag) => ({
              label: tag.name,
              onClick: async () => {
                await addSurfacesToExistingTag(tag.id, surfaceIds);
              },
            })),
          }),
        },
      ];

      if (isTagChild) {
        const tagId = node?.parent?.id || '';
        const tagChildren = [
          ...(geometryTags.surfacesFromTagEntityGroupId(tagId) || []),
          ...geometryTags.domainsFromTag(tagId),
        ];

        const removeTagChildDisabledReason = (
          defaultTagDisabledReason ||
          (tagChildren.length > 1 ? undefined : 'Tags must contain at least one item')
        );

        groupingItems.push({
          label: 'Remove from Tag',
          onClick: async () => {
            await removeItemFromTag(tagId, { surfaceId });
          },
          disabled: !!removeTagChildDisabledReason,
          disabledReason: removeTagChildDisabledReason,
        });
      }
      return assembleMenuSections(visItems, groupingItems);
    }

    const groupingItems = filteredMenuItems([
      {
        itemConfig: groupTreeNodeMenuItem(() => groupEntities(nodeIdsToGroup)),
        shouldShow: canGroup(nodeIdsToGroup, entityGroupData),
      },
      {
        itemConfig: ungroupTreeNodeMenuItem(() => ungroupEntities(surfaceId)),
        shouldShow: canUngroup(node, entityGroupData),
      },
    ]);

    const crudItems = filteredMenuItems([
      {
        itemConfig: deleteTreeNodeMenuItem(deleteRow, !canDelete(node.type, surfaceId)),
        shouldShow: isFarfield(surfaceId),
      },
    ]);

    return assembleMenuSections(visItems, groupingItems, crudItems);
  }, [
    isVisible,
    visControlsDisabled,
    toggleVis,
    hideOthers,
    canDelete,
    canGroup,
    canUngroup,
    deleteRow,
    entityGroupData,
    groupEntities,
    groupableNodes,
    isGeometryView,
    node,
    ungroupEntities,
    isCreateTagDisabled,
    geoUsesTags,
    addSurfacesToExistingTag,
    surfaceId,
    geometryTags,
    isTagChild,
    removeItemFromTag,
    defaultTagDisabledReason,
  ]);

  const visButton = (
    <VisibilityButton disabled={visControlsDisabled} isVisible={isVisible} onClick={toggleVis} />
  );

  return (
    <>
      <>
        {isCreateTagDialogOpen && (
          <CreateTagDialog
            isOpen={isCreateTagDialogOpen}
            onCancel={() => {
              setIsCreateTagDialogOpen(false);
              setTagCreationIds([]);
            }}
            onSubmit={async (name) => {
              await createTag(name, tagCreationIds);
              setIsCreateTagDialogOpen(false);
              setTagCreationIds([]);
            }}
          />
        )}
      </>
      <TreeRow
        {...props}
        auxIcons={auxIcons}
        canMultiSelect
        getContextMenuItems={getContextMenuItems}
        label={node.name}
        primaryIcon={PRIMARY_ICON}
        propertiesControl={!isGeometryView}
        renaming={!isGeometryView ? renaming : undefined}
        visibilityButton={visButton}
      />
    </>
  );
};
