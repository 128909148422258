// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { noWait, selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import * as flags from '../../../flags';
import { CurrentView } from '../../../lib/componentTypes/context';
import { newNode } from '../../../lib/paraviewUtils';
import {
  ADJOINT_SETTINGS_NODE_ID,
  CAD_SOLID_OR_FLUID_NODE_ID,
  CREATE_FARFIELD_NODE_ID,
  GENERAL_SETTINGS_NODE_ID,
  NodeType,
  POST_PROCESSING_EXTRACT_CONTAINER_ID,
  ROOT_SIMULATION_CONTAINER_ID,
  SOLVER_SETTINGS as SOLVER_SETTINGS_NODE_ID,
  STOPPING_CONDITIONS_NODE_ID,
  SimulationTreeNode,
  UPLOAD_CAD_OR_MESH_NODE_ID,
} from '../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { paraviewToSimulationNode } from '../../../lib/simulationTree/utils';
import { addChildNode } from '../../../lib/visUtils';
import { TreeNode } from '../../../pvproto/ParaviewRpc';
import { lcVisEnabledSelector } from '../../../recoil/lcvis/lcvisEnabledState';
import { editStateState } from '../../../recoil/paraviewState';
import { controlPanelModeState } from '../../../recoil/useProjectPage';
import { TreeViewType, treeViewTypeState } from '../../../recoil/useTreeViewState';
import { viewStateAtomFamily } from '../../../recoil/useViewState';
import { filterStateSelector } from '../../../recoil/vis/filterState';
import { listPostProcessingExtractsState } from '../../../recoil/vis/postProcessingExtract';
import { currentViewState, isAdjointSetupState } from '../global/currentView';

import { explorationTreeSelector } from './exploration';
import { solidOrFluidState } from './geometrySetupTree';
import { cameraSectionSelector } from './section/camera';
import { customFieldSectionSelector } from './section/customField';
import { materialsSectionSelector } from './section/material';
import { meshSectionSelector } from './section/mesh';
import { motionSectionSelector } from './section/motion';
import { outputsSectionSelector } from './section/output';
import { physicsSectionSelector } from './section/physics';
import { plotSectionSelector } from './section/plot';

import { isAdjointSimulation } from '@/lib/simulationParamUtils';
import { enabledExperimentsState } from '@/recoil/useExperimentConfig';
import { simulationParamState } from '@/state/external/project/simulation/param';

type SimulationTreeKey = SectionRecoilKey & { currentView: CurrentView };
// Selector for the childNodes of the simulation tree, based on a view.
// We can pass a particular CurrentView and get its tree, even if are not currently on it.
export const simulationTreeForViewState = selectorFamily<SimulationTreeNode, SimulationTreeKey>({
  key: 'simulationTreeForViewState',
  get: (fullKey: SimulationTreeKey) => async ({ get }) => {
    const { currentView, ...key } = fullKey;
    const [
      simParam,
      mesh,
      materials,
      physics,
      outputs,
      customFields,
      cameras,
      plot,
      motion,
      viewState,
      treeViewType,
      filterState,
      lcvisEnabled,
      solidOrFluid,
      explorationTree,
      controlPanelMode,
      isAdjointSetup,
      editState,
      enabledExperiments,
    ] = get(waitForAll([
      simulationParamState(key),
      meshSectionSelector(key),
      materialsSectionSelector(key),
      physicsSectionSelector(key),
      outputsSectionSelector(key.projectId),
      customFieldSectionSelector(key),
      cameraSectionSelector(key),
      plotSectionSelector(key.projectId),
      motionSectionSelector(key),
      viewStateAtomFamily(key.projectId),
      treeViewTypeState,
      filterStateSelector(key),
      lcVisEnabledSelector(key.projectId),
      solidOrFluidState(key.projectId),
      explorationTreeSelector(key),
      controlPanelModeState,
      isAdjointSetupState,
      editStateState,
      enabledExperimentsState,
    ]));

    const general = new SimulationTreeNode(
      NodeType.GENERAL_SETTINGS,
      GENERAL_SETTINGS_NODE_ID,
      'General',
    );

    const stoppingConditions = new SimulationTreeNode(
      NodeType.STOPPING_CONDITIONS,
      STOPPING_CONDITIONS_NODE_ID,
      'Stopping Conditions',
    );

    const solverSettings = new SimulationTreeNode(
      NodeType.SOLVER_SETTINGS,
      SOLVER_SETTINGS_NODE_ID,
      'Solver Settings',
    );

    const adjointSettings = new SimulationTreeNode(
      NodeType.ADJOINT_SETTINGS,
      ADJOINT_SETTINGS_NODE_ID,
      'Adjoint Settings',
    );

    const generateExtractsUIEnabled = enabledExperiments.includes(flags.generate_extracts_ui);

    const getVisChildren = () => {
      let rootNode: TreeNode;

      if (lcvisEnabled) {
        rootNode = filterState;
      } else if (viewState) {
        rootNode = viewState.root;
      } else {
        return [];
      }

      if (editState?.newNode) {
        const parentId = editState.nodeId;

        rootNode = addChildNode(
          rootNode,
          parentId,
          newNode(editState.param, rootNode, false, editState.displayProps, editState.newNodeId),
        );
      }

      return [paraviewToSimulationNode(rootNode, lcvisEnabled)];
    };

    const childNodes: SimulationTreeNode[] = [];
    switch (currentView) {
      case CurrentView.ADVANCED_ANALYSIS: {
        if (isAdjointSetup) {
          childNodes.push(
            adjointSettings,
            solverSettings,
            outputs,
            stoppingConditions,
          );
        }
        if (controlPanelMode === 'exploration') {
          childNodes.push(...explorationTree.children);
        }
        break;
      }

      case CurrentView.SETUP:
        childNodes.push(
          general,
          materials,
          motion,
          physics,
          mesh,
          solverSettings,
        );

        childNodes.push(
          outputs,
          stoppingConditions,
          cameras,
          plot,
          ...getVisChildren(),
        );
        break;
      case CurrentView.ANALYSIS: {
        switch (treeViewType) {
          case TreeViewType.POST_PROCESSING: {
            childNodes.push(
              outputs,
              customFields,
              plot,
              cameras,
              ...getVisChildren(),
            );
            break;
          }
          case TreeViewType.SETUP: {
            if (isAdjointSimulation(simParam)) {
              childNodes.push(adjointSettings);
            }
            childNodes.push(
              general,
              mesh,
              materials,
              motion,
              physics,
              solverSettings,
              outputs,
              stoppingConditions,
            );
            break;
          }
          default: {
            // node default, enum exhausted
          }
        }
        break;
      }
      case CurrentView.GEOMETRY:
        childNodes.push(
          new SimulationTreeNode(
            NodeType.UPLOAD_CAD_OR_MESH,
            UPLOAD_CAD_OR_MESH_NODE_ID,
            'Upload CAD or Mesh',
          ),
          new SimulationTreeNode(
            NodeType.CAD_SOLID_OR_FLUID,
            CAD_SOLID_OR_FLUID_NODE_ID,
            'Is your CAD solid or fluid?',
          ),
          // TODO: add this back once we have the geometry check trigger feature
          // new SimulationTreeNode(
          //   NodeType.GEOMETRY_CHECK,
          //   GEOMETRY_CHECK_NODE_ID,
          //   'Geometry Check',
          // ),
          // TODO: add this back once shrink wrap actually works
          // new SimulationTreeNode(
          //   NodeType.SHRINK_WRAP,
          //   SHRINK_WRAP_NODE_ID,
          //   'Shrink Wrap',
          // ),
        );
        if (solidOrFluid === 'solid') {
          childNodes.push(
            new SimulationTreeNode(
              NodeType.CREATE_FARFIELD,
              CREATE_FARFIELD_NODE_ID,
              'Create Farfield',
            ),
          );
        }
        break;
      default: {
        // none
      }
    }

    if (generateExtractsUIEnabled && currentView !== CurrentView.GEOMETRY) {
      const postProcessingExtracts = get(
        noWait(listPostProcessingExtractsState({ projectId: key.projectId })),
      );
      if (postProcessingExtracts.state === 'hasValue') {
        const { extracts } = postProcessingExtracts.contents;
        if (extracts.length > 0) {
          childNodes.push(new SimulationTreeNode(
            NodeType.POST_PROCESSING_EXTRACT_CONTAINER,
            POST_PROCESSING_EXTRACT_CONTAINER_ID,
            'Post-Processing',
            extracts.map((extract) => new SimulationTreeNode(
              NodeType.POST_PROCESSING_EXTRACT,
              extract.id,
              extract.extractSpec?.name || 'Extract',
            )),
          ));
        }
      }
    }

    return new SimulationTreeNode(
      NodeType.ROOT_SIMULATION,
      ROOT_SIMULATION_CONTAINER_ID,
      'Simulation',
      childNodes,
    );
  },
  dangerouslyAllowMutability: true,
});

// Get the simulation tree nodes depending on the actual current view we are on
export const simulationTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'simulationTreeSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => get(
    simulationTreeForViewState({ ...key, currentView: get(currentViewState) }),
  ),
  dangerouslyAllowMutability: true,
});

export function useSimulationTree(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(simulationTreeSelector({ projectId, workflowId, jobId }));
}
