import React, { Suspense, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import ProjectStateSync from '../../components/RecoilSync/ProjectState';
import suspenseWidget from '../../components/SuspenseWidget';
import Project from '../../components/context/ProjectContext';
import { MainPageLayout } from '../../components/layout/page/Main';
import { CurrentView } from '../../lib/componentTypes/context';
import { ProjectParams } from '../../lib/routeParamTypes';
import { useLoadCameraListEffect } from '../../recoil/cameraState';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useSetCurrentView } from '../../state/internal/global/currentView';
import PageBody from '../PageBody';

type StagePageProps = {
    view: CurrentView;
};

export const StagePage = (props: StagePageProps) => {
  const { view } = props;
  const params = useParams<ProjectParams>();
  const projectId = params.projectId || '';
  const projectMetadata = useProjectMetadata(projectId);
  const setCurrentView = useSetCurrentView();

  useEffect(() => {
    setCurrentView(view);
  }, [setCurrentView, view]);

  // We should get a fresh state of the cameras everytime we open the project page because otherwise
  // we might get a stale data (it happens when we open a project and populate its camera recoil,
  // then update the global cameras in another project and return to the first project).
  useLoadCameraListEffect(projectId);

  // Don't display the page until all the project data has loaded.
  const projectName = projectMetadata?.summary!.name || '';

  return (
    <MainPageLayout projectId={projectId} title={projectName}>
      <Suspense fallback={suspenseWidget}>
        {projectMetadata && (
        <ProjectStateSync projectId={projectId}>
          <Project
            projectId={projectId}
            selectedJobId=""
            workflowId="">
            <PageBody isExploration={false} />
          </Project>
        </ProjectStateSync>
        )}
      </Suspense>
    </MainPageLayout>
  );
};
