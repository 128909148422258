// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=js+dts"
// @generated from file proto/frontend/serverinfo/serverinfo.proto (package luminary.proto.frontend.serverinfo, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// ServerInfo is implemented in the same code that implements the Frontend
// service. This means it also requires a credential in the "authorization"
// header - see proto/frontend/frontend.proto for details. This file is separate
// so other code can call ServerInfo without importing all of the dependencies
// of frontend.proto.

import { Empty, MethodKind } from "@bufbuild/protobuf";
import { ServerInfoReply } from "./serverinfo_pb.js";

/**
 * @generated from service luminary.proto.frontend.serverinfo.ServerInfo
 */
export const ServerInfo = {
  typeName: "luminary.proto.frontend.serverinfo.ServerInfo",
  methods: {
    /**
     * Reads server-wide information, such as software versions and supported node
     * pools. If the calling user is staff or admin, reads current node
     * reservations.
     *
     * @generated from rpc luminary.proto.frontend.serverinfo.ServerInfo.ServerInfo
     */
    serverInfo: {
      name: "ServerInfo",
      I: Empty,
      O: ServerInfoReply,
      kind: MethodKind.Unary,
    },
  }
};

