import { LCVError, LCVStatusSeverity } from '@luminarycloudinternal/lcvis';
import * as Sentry from '@sentry/react';

import { CurrentView } from '@/lib/componentTypes/context';

/* Based on sample provided by Sentry folks:
 * https://gist.github.com/mitsuhiko/c27fee8445d2a18a8c134e0169119058
 * https://docs.sentry.io/platforms/native/guides/wasm/
 *
 * We need to intercept exceptions in Sentry and check if they have WASM
 * stack frames so that we can parse the stack frame and insert the
 * right call addresses so that our debug symbols can be picked up in Sentry
 */

export interface LcvStatusMessage {
  userId: string;
  error: string;
  errorEnum: LCVError;
  severity: string;
  severityEnum: LCVStatusSeverity;
  from: {
    objectType: string;
  };
  message: string;
  projectId: string | null,
  stackTrace: string | undefined,
  currentView: CurrentView | undefined,
}

// Module info we need to provide to Sentry for WASM stack traces
interface WasmSentryInfo {
  type: 'wasm';
  code_id: string;
  code_file: string;
  debug_id: string;
}

const WASM_IMAGES: WasmSentryInfo[] = [];

const origInstantiateStreaming = WebAssembly.instantiateStreaming;
const origCompileStreaming = WebAssembly.compileStreaming;

/* Filters for things that end up in LCVis status handler as errors
 * but which aren't actually errors. These are typically things logged to
 * stderr that we don't control the output of (e.g., shader compiler messages).
 * Any error messages from LCVis that contain one of the strings listed below will
 * be discarded and not sent to Sentry.
 */
const LCVIS_FILTERED_ERRORS: string[] = [
  'compilation of fragment shader succeeded with the following message',
  'compilation of vertex shader succeeded with the following message',
  'linking succeeded with the following message',
  'Performance: dynamic indexing of vectors and matrices is emulated and can be slow',
  'WARNING: Output of vertex shader',
  'warning X3203: signed/unsigned mismatch, unsigned assumed',
  'use of potentially uninitialized variable (dyn_index_vec3_int)',
  'still waiting on run dependencies:',
  'dependency: wasm-instantiate',
  'dependency: loading-workers',
  'dependency: library_fetch_init',
  '(end of list)',
];

/* Classes of errors from LCVis that we don't need to send to sentry
 */
const LCVIS_FILTERED_ERROR_TYPES: LCVError[] = [
  LCVError.kLCVErrorUnknown,
  LCVError.kLCVErrorInvalidField,
  LCVError.kLCVErrorMemExceeded,
  LCVError.kLCVErrorWorkspaceExecuting,
  LCVError.kLCVErrorInvalidPropertyType,
  LCVError.kLCVErrorInvalidObject,
  LCVError.kLCVErrorWebGLContextCreationFailed,
];

// We strip debug info so this is simplified vs. Sentry's example to only
// look for the build ID
function getWasmModuleBuildId(module: WebAssembly.Module) {
  const buildIDs = WebAssembly.Module.customSections(module, 'build_id');

  let buildId = null;
  if (buildIDs.length > 0) {
    const bid = new Uint8Array(buildIDs[0]);
    buildId = Array.from(bid)
      // eslint-disable-next-line no-bitwise
      .reduce((acc, x) => acc + (x & 0xff).toString(16).padStart(2, '0'), '');
  }

  return buildId;
}

function getWasmModuleIndex(url: string) {
  return WASM_IMAGES.findIndex((img) => img.code_file === url);
}

/* Record build IDs of WASM modules when they're instantiated/compiled so we
 * can send the supplemental debug info for stack traces with Wasm to map
 * addresses to debug info we have in Sentry
 */
function recordWasmModule(module: WebAssembly.Module, url: string) {
  const buildId = getWasmModuleBuildId(module);
  if (buildId) {
    // Erase old version of the image if it's in the file
    const oldIdx = getWasmModuleIndex(url);
    if (oldIdx >= 0) {
      WASM_IMAGES.splice(oldIdx, 1);
    }
    WASM_IMAGES.push({
      type: 'wasm',
      code_id: buildId,
      code_file: url,
      debug_id: `${buildId.padEnd(32, '0').substring(0, 32)}0`,
    });
  }
}

export function recordWasmInstantiateStreaming(
  promise: Response | PromiseLike<Response>,
  obj?: WebAssembly.Imports | undefined,
) {
  return Promise.resolve(promise).then((resp) => origInstantiateStreaming(resp, obj).then((rv) => {
    if (resp.url) {
      recordWasmModule(rv.module, resp.url);
    }
    return rv;
  }));
}

export function recordWasmCompileStreaming(
  promise: Response | PromiseLike<Response>,
) {
  return Promise.resolve(promise).then((resp) => origCompileStreaming(resp).then((module) => {
    if (resp.url) {
      recordWasmModule(module, resp.url);
    }
    return module;
  }));
}

/* Add an event processor to sentry that will look for Wasm frames in stack
 * traces and insert the Wasm module build info so that Sentry can match up
 * our debug symbols to show source info for those stack frames
 */
export function registerWasmSentryEventHandler() {
  Sentry.getGlobalScope().addEventProcessor((event, hint) => {
    // Nothing to do if we don't have Wasm images w/ build IDs to match up
    if (WASM_IMAGES.length === 0) {
      return event;
    }

    let haveWasmFrames = false;
    // When we send Sentry an exception within the LCVis status handler
    // we send the lcvMessage itself as the original exception so that we
    // can regenerate the stack trace. We do this because Sentry has some regex
    // that struggles with C++ template symbols that can end up in our stack traces,
    // causing it to not get the right file and function names.
    if (hint?.data &&
        (hint.data as LcvStatusMessage).stackTrace !== undefined
    ) {
      const lcvisInfo = hint.data as LcvStatusMessage;
      // Sometimes we get empty strings output by the shader compiler to stderr, just
      // drop these messages
      if (lcvisInfo.message === '') {
        return null;
      }
      // Filter out certain classes of LCVis errors that we don't need to send to Sentry
      if (LCVIS_FILTERED_ERROR_TYPES.includes(lcvisInfo.errorEnum)) {
        return null;
      }
      // Check if this is a non-error that's logged to stderr that we want to filter out
      for (let i = 0; i < LCVIS_FILTERED_ERRORS.length; i += 1) {
        if (lcvisInfo.message.includes(LCVIS_FILTERED_ERRORS[i])) {
          return null;
        }
      }

      // Remove the "Args..." part from the message to not include it in the exception name
      // to deduplicate errors better
      let lcvisMessage = lcvisInfo.message;
      const argsIdx = lcvisMessage.indexOf('Args:');
      if (argsIdx !== -1) {
        lcvisMessage = lcvisMessage.substring(0, argsIdx - 1);
      }

      const lcvisException: Sentry.Exception = {};
      lcvisException.value = lcvisMessage;
      lcvisException.type = lcvisInfo.error;

      lcvisException.module = 'LCVis';

      haveWasmFrames = true;
      lcvisException.stacktrace = {};
      // Parse the stack trace we sent ourselves from LcvHandler printStatus and
      // turn it into a Sentry stack trace we can send back
      const trace = lcvisInfo.stackTrace?.split('\n');
      if (trace) {
        lcvisException.stacktrace.frames = trace.map((frame: string) => {
          const match_fn_file = frame.match(/at (.*) \((.*)\)/);
          if (!match_fn_file) {
            return {};
          }

          const function_name = match_fn_file[1];
          let filename = match_fn_file[2];

          // Check if it's a wasm function and output the wasm addr if so
          const match_wasm_addr = filename.match(/(.*):wasm-function\[\d+\]:(0x[a-fA-F0-9]+)$/);
          if (match_wasm_addr) {
            filename = match_wasm_addr[1];
            const wasmModuleIndex = getWasmModuleIndex(filename);
            if (wasmModuleIndex >= 0) {
              return {
                filename: WASM_IMAGES[wasmModuleIndex].code_file,
                function: function_name,
                in_app: true,
                instruction_addr: match_wasm_addr[2],
                addr_mode: `rel:${wasmModuleIndex}`,
                platform: 'native',
              };
            }
          }
          // Otherwise it's a JS function in the stack trace, get source
          // line and column info
          const match_js_line = filename.match(/(.*):(\d+):(\d+)$/);
          return {
            filename: match_js_line ? match_js_line[1] : filename,
            function: function_name,
            in_app: true,
            lineno: match_js_line ? parseInt(match_js_line[2], 10) : undefined,
            colno: match_js_line ? parseInt(match_js_line[3], 10) : undefined,
          };
        }).filter((frame) => frame);
        event.exception = {
          values: [lcvisException],
        };
      } else {
        // No stack trace, discard
        return null;
      }
    } else if (event.exception?.values) {
      // Otherwise it's a regular exception in Sentry that we didn't submit,
      // just go through the normal stack frames and add info for any WASM ones
      // that we can parse out that weren't mangled by Sentry's buggy regex
      event.exception?.values?.forEach((exception) => {
        if (!exception.stacktrace?.frames) {
          return;
        }
        exception.stacktrace.frames.forEach((frame) => {
          // Wasm stack frames will have "wasm-function" + some 0x<mem addr> in them
          // We don't match the filename when we're dealing with a Sentry provided
          // stack trace because it gets tripped up by C++ symbols and messes up
          // the source file names and function names.
          const match = frame.filename?.match(
            /:wasm-function\[\d+\]:(0x[a-fA-F0-9]+)/,
          );
          if (match) {
            // TODO (will): Sentry's doing some odd matching/parsing of the stack traces
            // that breaks on C++ templates symbols in the stack trace. We know
            // we'll only have one WASM file so we can just hardcode the file and
            // only parse out the address
            haveWasmFrames = true;
            frame.instruction_addr = match[1];
            frame.addr_mode = `rel:${0}`;
            frame.filename = WASM_IMAGES[0].code_file;
            frame.platform = 'native';
          }
        });
      });
    }
    if (haveWasmFrames) {
      event.debug_meta = {
        images: WASM_IMAGES,
      };
    }
    return event;
  });
}
