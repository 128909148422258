// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from '../../../lib/browserStorage';
import { ColumnId, ColumnOps, RowId, SortDefinition } from '../../../lib/componentTypes/table';
import { localStorageEffect } from '../../../lib/persist';
// #region: Types

export type ColumnOpsRecord = Record<ColumnId, ColumnOps>;

export type TableState = {
  /** Operational state for columns */
  columns: ColumnOpsRecord;
  /** Sorting definition */
  sorting?: SortDefinition;
  pageSize?: number;
}

// #region: Helper Functions

export function defaultTableState(): TableState {
  return { columns: {} };
}

function getTableStorageKey(name: string) {
  return `data-table-state-${name}`;
}

function getPageStorageKey(name: string) {
  return `data-table-page-${name}`;
}

// #region: Atoms

/**
 * @param name a unique name that identifies the table
 */
export const dataTableState = atomFamily<TableState, string>({
  key: 'dataTableState',
  default: (name: string) => getLocalStorageData<TableState>(
    getTableStorageKey(name),
    defaultTableState(),
  ),
  effects: (name: string) => [localStorageEffect(getTableStorageKey(name))],
});

/**
 * An atom for the currently selected row IDs of a table
 *
 * @param name the unique name of the table
 */
export const dataTableSelectedRowsState = atomFamily<Set<RowId>, string>({
  key: 'dataTableSelectedRowsState',
  default: new Set(),
});

/**
 * At atom for the last opened page for a particular table
 *
 * @param name the unique name of the table
 */

export const dataTablePageState = atomFamily<number, string>({
  key: 'dataTablePageState',
  default: (name: string) => getLocalStorageData<number>(getPageStorageKey(name), 0),
  effects: (name: string) => [localStorageEffect(getPageStorageKey(name))],
});

// #region: Hooks

export function useDataTable(name: string) {
  return useRecoilState(dataTableState(name));
}

export function useDataTableValue(name: string) {
  return useRecoilValue(dataTableState(name));
}

export function useSetDataTable(name: string) {
  return useSetRecoilState(dataTableState(name));
}

export function useDataTableSelectedRows(name: string) {
  return useRecoilState(dataTableSelectedRowsState(name));
}

export function useSetDataTableSelectedRows(name: string) {
  return useSetRecoilState(dataTableSelectedRowsState(name));
}

export function useDataTablePage(name: string) {
  return useRecoilState(dataTablePageState(name));
}
