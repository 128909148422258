// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/**
 * Displays all the simulation and exploration results in a table.
 */
import React, { Suspense, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import JobTable from '../components/JobPanel/JobTable';
import TabPanel from '../components/Pane/TabPanel';
import ParaviewManager from '../components/Paraview/ParaviewManager';
import ProjectStateSync from '../components/RecoilSync/ProjectState';
import suspenseWidget from '../components/SuspenseWidget';
import { createStyles, makeStyles } from '../components/Theme';
import Project from '../components/context/ProjectContext';
import SelectionManager from '../components/context/SelectionManager';
import { useAssistantEnabled } from '../components/hooks/useAssistant';
import { MainPageLayout } from '../components/layout/page/Main';
import { SideRail } from '../components/project/assistant/SideRail';
import { CurrentView } from '../lib/componentTypes/context';
import { lcvHandler } from '../lib/lcvis/handler/LcvHandler';
import { ProjectParams } from '../lib/routeParamTypes';
import { useLcVisEnabledValue } from '../recoil/lcvis/lcvisEnabledState';
import useProjectMetadata from '../recoil/useProjectMetadata';
import { analytics } from '../services/analytics';
import { useCurrentView, useSetCurrentView } from '../state/internal/global/currentView';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      overflow: 'hidden',
      position: 'relative',
    },
    results: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '16px',
      overflow: 'hidden',
      height: '100%',
      flex: 1,
    },
    table: {
      flex: '1 1 auto',
      overflow: 'auto',
    },
  }),
  { name: 'ResultsPage' },
);
const ResultsPage = () => {
  const params = useParams<ProjectParams>();
  const projectId = params.projectId || '';
  const projectMetadata = useProjectMetadata(projectId);

  const projectName = projectMetadata ?
    projectMetadata.summary!.name :
    projectId;

  const classes = useStyles();
  const currentView = useCurrentView();
  const setCurrentView = useSetCurrentView();
  const assistantEnabled = useAssistantEnabled();
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  useEffect(() => {
    const updateCurrentView = async () => {
      if (lcvisEnabled && projectId) {
        await lcvHandler.setCurrentView(currentView);
      }
    };

    updateCurrentView().catch(console.error);

    setCurrentView(CurrentView.RESULTS);
    analytics.page('Results Page', { projectId });
  }, [setCurrentView, projectId, currentView, lcvisEnabled]);

  return (
    <MainPageLayout projectId={projectId} title={projectName}>
      <Suspense fallback={suspenseWidget}>
        <ProjectStateSync projectId={projectId}>
          <Project
            projectId={projectId}
            selectedJobId=""
            workflowId="">
            <div className={classes.root}>
              <div className={classes.results}>
                <TabPanel />
                <div className={classes.table}>
                  <JobTable />
                </div>
              </div>
              {assistantEnabled && (
                <SelectionManager>
                  <ParaviewManager>
                    <SideRail />
                  </ParaviewManager>
                </SelectionManager>
              )}
            </div>
          </Project>
        </ProjectStateSync>
      </Suspense>
    </MainPageLayout>
  );
};
export default ResultsPage;
