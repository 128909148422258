// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { globalDisabledReason } from '../../../lib/geometryUtils';
import { filteredMenuItems } from '../../../lib/menuUtil';
import {
  createDeleteAllTagsTreeNodeMenuItems,
  createTagsFromRightClickTreeNodeMenuItems,
} from '../../../lib/treeUtils';
import { useIsGeometryServerActive } from '../../../recoil/geometry/geometryServerStatus';
import { useGeometrySelectedFeature } from '../../../recoil/geometry/geometryState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useSetTagSelectionSettings } from '../../../recoil/lcvis/tagsSelection';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useProjectContext } from '../../context/ProjectContext';
import { useDeleteAllTags } from '../../hooks/useDeleteAllTags';
import { TreeRow } from '../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const TagContainerTreeRow = (props: SimulationRowProps) => {
  const { projectId, geometryId, readOnly, workflowId, jobId } = useProjectContext();
  const setTagSelection = useSetTagSelectionSettings();
  const isGeometryView = useIsGeometryView();

  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const isGeoServerActive = useIsGeometryServerActive(geometryId);
  const deleteAllTags = useDeleteAllTags();
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  const createTags = useCallback(() => {
    setTagSelection((prev) => ({
      ...prev,
      active: !prev.active,
    }));
  }, [setTagSelection]);

  const defaultDisabledReason = globalDisabledReason(
    selectedFeature,
    readOnly,
    isGeoServerActive,
  );

  const hasGeometryTags = geometryTags.tags.length > 0;

  const getContextMenuItems = useCallback(() => filteredMenuItems([
    {
      itemConfig: createTagsFromRightClickTreeNodeMenuItems(
        createTags,
        !!defaultDisabledReason,
        defaultDisabledReason,
      ),
      shouldShow: isGeometryView,
    },
    {
      itemConfig: createDeleteAllTagsTreeNodeMenuItems(
        deleteAllTags,
        !!defaultDisabledReason,
        defaultDisabledReason,
      ),
      shouldShow: isGeometryView && hasGeometryTags,
    },
  ]), [createTags, defaultDisabledReason, isGeometryView, deleteAllTags, hasGeometryTags]);

  return (
    <TreeRow
      primaryIcon={getNodeTypeIcon(props.node.type)}
      {...props}
      getContextMenuItems={getContextMenuItems}
    />
  );
};
