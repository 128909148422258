// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { CommonMenuItem } from '../../../../lib/componentTypes/menu';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';

import { PanelHeading } from './PanelHeading';

interface UndefinedBoundariesProps {
  nodeId: string;
  surfaces: string[];
  typeMenuItems: CommonMenuItem[];
}

export const UndefinedBoundaries = (props: UndefinedBoundariesProps) => {
  const { nodeId, surfaces, typeMenuItems } = props;

  // these are just placeholders required by NodeSubselect
  const nodeFilter = useCallback(() => ({ related: false, disable: true }), []);
  const onChange = useCallback(() => { }, []);

  return (
    <>
      <PanelHeading
        subtitle={
          `These surfaces are missing boundary conditions and must be defined before running a
           simulation`
        }
        title="Undefined Boundaries"
      />
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Geometry"
          nodeId={nodeId}
          panelName="surfaces">
          <NodeSubselect
            disableClearAll
            id="boundary-condition-unassigned-surfaces"
            labels={[]}
            menuButton={{
              title: 'Define All',
              items: typeMenuItems,
            }}
            nodeFilter={nodeFilter}
            nodeIds={surfaces}
            onChange={onChange}
            readOnly
            referenceNodeIds={[nodeId]}
            title="Undefined Surfaces"
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </>
  );
};
