import { atomFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from '../../../lib/browserStorage';
import { localStorageEffect } from '../../../lib/persist';

type SolidOrFluidState = 'solid' | 'fluid';
const prefix = 'solidOrFluidState';

export const solidOrFluidState = atomFamily<SolidOrFluidState, string>({
  key: 'solidOrFluidState',
  default: (projectId: string) => getLocalStorageData(`${prefix}-${projectId}`, 'solid'),
  effects: (projectId: string) => [
    localStorageEffect(`${prefix}-${projectId}`),
  ],
});

export const useSolidOrFluidState = (
  projectId: string,
) => useRecoilState(solidOrFluidState(projectId));
export const useSetSolidOrFluidState = (
  projectId: string,
) => useSetRecoilState(solidOrFluidState(projectId));
