// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { singlePhysicsOutputNodesFixture, singlePhysicsWorkflowConfigFixture } from '../lib/fixtures';
import { setDefaultName } from '../lib/outputNodeUtils';
import { DEFAULT_OUTPUT_NODES } from '../lib/paramDefaults/outputNodesState';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { getSimulationParam } from '../lib/simulationParamUtils';
import { isTestingEnv } from '../lib/testing/utils';
import * as feoutputpb from '../proto/frontend/output/output_pb';
import { ReferenceValueType, ReferenceValues } from '../proto/output/reference_values_pb';
import { Config } from '../proto/workflow/workflow_pb';

import { frontendMenuState } from './frontendMenuState';
import { projectConfigState } from './workflowConfig';
import { workflowState } from './workflowState';

export const serialize = (val: feoutputpb.OutputNodes) => val.toBinary();

export const outputNodesPrefix = 'outputNodes';

// outputNodesSelector gets the output nodes state from the kvstore.
export const outputNodesSelectorRpc = selectorFamily<
  feoutputpb.OutputNodes | undefined,
  persist.RecoilProjectKey
>({
  key: 'outputNodesSelector/rpc',
  get: (key: persist.RecoilProjectKey) => () => persist.getProjectState(
    key.projectId,
    [
      persist.getProjectStateKey(outputNodesPrefix, key),
      outputNodesPrefix,
    ],
    (val: Uint8Array) => (val.length ?
      feoutputpb.OutputNodes.fromBinary(val) :
      undefined),
  ),
  dangerouslyAllowMutability: true,
});

// Selector used for testing
const outputNodesSelectorTesting = selectorFamily<
  feoutputpb.OutputNodes,
  persist.RecoilProjectKey
>({
  key: 'outputNodesSelector/testing',
  get: () => () => {
    const fixture = singlePhysicsWorkflowConfigFixture();
    return singlePhysicsOutputNodesFixture(fixture);
  },
  dangerouslyAllowMutability: true,
});

const outputNodesSelector = isTestingEnv() ? outputNodesSelectorTesting : outputNodesSelectorRpc;

const outputNodesUpdateSelector = selectorFamily<feoutputpb.OutputNodes, persist.RecoilProjectKey>({
  key: 'outputNodesUpdateSelector',
  get: (key: persist.RecoilProjectKey) => ({ get }) => {
    const maybeOutputNodes = get(outputNodesSelector(key));
    // If output nodes aren't set in a modern format, see if legacy format has them.
    if (!maybeOutputNodes && key.workflowId) {
      // For older projects the output nodes for a specific workflow are part of the
      // frontendMenuState so we fetch them from there if they exist.
      // `frontendMenu.getOutputNodes() should be 'undefined' for new projects here but somehow
      // it is not. Its a bit strange because getting the same state with the same key in other
      // atoms correctly gives 'undefined'. Anyways, using the "initialized" boolean we can get
      // also check wether it is an old project or not.
      const frontendMenu = get(frontendMenuState(key));
      if (frontendMenu.outputNodes?.initialized) {
        return frontendMenu.outputNodes;
      }
    }
    let outputNodes: feoutputpb.OutputNodes;
    // If output nodes are unset and no legacy format exists, use the defaults.
    if (!maybeOutputNodes) {
      outputNodes = DEFAULT_OUTPUT_NODES.clone();
      // If we are requesting the per-job outputs nodes we initialize them with the per-project
      // output nodes.
      if (key.jobId && key.workflowId) {
        const projectOutputNodes = get(
          outputNodesSelector({ projectId: key.projectId, workflowId: '', jobId: '' }),
        );
        if (projectOutputNodes) {
          outputNodes = projectOutputNodes.clone();
        }
      }
    } else {
      outputNodes = maybeOutputNodes;
    }
    // Default names where previously not stored so the field can be empty if the user has
    // not changed the name of an output. This explicitly stores the name for the latter case
    // to make sure this field is always set.
    outputNodes.nodes.forEach((output) => {
      if (!output.name) {
        setDefaultName(output, outputNodes);
      }
    });

    // Upgrade the reference values (if not done already) using the reference values
    // in the simulation params.
    if (!outputNodes.useRefValues) {
      let workflowConfig: Config | undefined;
      // We either get the config from the workflow state or from the project state.
      if (key.workflowId || key.jobId) {
        const workflow = get(workflowState(
          { projectId: key.projectId, workflowId: key.workflowId },
        ));
        workflowConfig = workflow?.config;
      } else {
        workflowConfig = get(projectConfigState(key.projectId));
      }
      if (workflowConfig) {
        const simParam = getSimulationParam(workflowConfig);
        outputNodes.referenceValues = new ReferenceValues({
          referenceValueType: ReferenceValueType.REFERENCE_PRESCRIBE_VALUES,
          pRef: simParam.referenceValues?.pRef,
          tRef: simParam.referenceValues?.tRef,
          vRef: simParam.referenceValues?.vRef,
          areaRef: simParam.referenceValues?.areaRef,
          lengthRef: simParam.referenceValues?.lengthRef,
        });
        outputNodes.useRefValues = true;
      }
    }
    return outputNodes;
  },
  dangerouslyAllowMutability: true,
});

export const outputNodesState = atomFamily<feoutputpb.OutputNodes, persist.RecoilProjectKey>({
  key: 'outputNodes',
  default: outputNodesUpdateSelector,
  effects: (key: persist.RecoilProjectKey) => [
    syncProjectStateEffect(
      key.projectId,
      persist.getProjectStateKey(outputNodesPrefix, key),
      (val) => feoutputpb.OutputNodes.fromBinary(val),
      serialize,
    ),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const useOutputNodes = (projectId: string, workflowId: string, jobId: string) => (
  useRecoilState(outputNodesState({ projectId, workflowId, jobId }))
);

export const useOutputNodesValue = (projectId: string, workflowId: string, jobId: string) => (
  useRecoilValue(outputNodesState({ projectId, workflowId, jobId }))
);

export const useSetOutputNodes = (projectId: string, workflowId: string, jobId: string) => (
  useSetRecoilState(outputNodesState({ projectId, workflowId, jobId }))
);
