// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, useEffect, useRef } from 'react';

import cx from 'classnames';

import { ColorBase } from '../../../lib/designSystem';
import { isUnmodifiedEscapeKey } from '../../../lib/event';
import { SearchIcon } from '../../svg/SearchIcon';
import { XIcon } from '../../svg/XIcon';

import './SearchBox.scss';

export interface SearchBoxProps {
  onChange: (value: string) => void;
  onFocusChange?: (value: boolean) => void;
  onClear?: () => void;
  onEscape?: () => void;
  onClickIcon?: () => void;
  value: string;
  colorBase?: ColorBase;
  decorated?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
}

export const SearchBox = (props: SearchBoxProps) => {
  const {
    autofocus = false,
    colorBase = 'neutral',
    decorated,
    disabled = false,
    onChange,
    onClear,
    onClickIcon,
    onEscape,
    onFocusChange,
    value,
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const placeholderColor = (colorBase === 'neutral') ?
    `var(--color-${colorBase}-600)` :
    `var(--color-${colorBase}-850)`;

  const clearable = !!value;

  const handleFocused = (newFocused: boolean) => {
    onFocusChange?.(newFocused);
  };

  useEffect(() => {
    if (disabled) {
      inputRef.current?.blur();
    } else if (autofocus) {
      inputRef.current?.focus();
    }
  }, [autofocus, disabled]);

  return (
    <div
      className={cx('searchBox', { decorated })}
      style={{
        '--placeholder-color': placeholderColor,
        '--decorated-bg-color': `var(--color-${colorBase}-50)`,
        '--decorated-highlight-color': `var(--color-${colorBase}-300)`,
      } as CSSProperties}>
      <button
        className="leadButton"
        onClick={(event) => {
          event.stopPropagation();
          inputRef.current?.focus();
          onClickIcon?.();
        }}
        type="button">
        <SearchIcon maxHeight={12} maxWidth={12} />
      </button>
      <div className="main">
        <input
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className="searchInput"
          onBlur={() => handleFocused(false)}
          onChange={(event: React.ChangeEvent) => {
            onChange((event.target as HTMLInputElement).value);
          }}
          onClick={(event) => event.stopPropagation()}
          onFocus={() => handleFocused(true)}
          onKeyUp={(event) => {
            event.stopPropagation();
            if (isUnmodifiedEscapeKey(event)) {
              onEscape?.();
            }
          }}
          placeholder="Search…"
          ref={inputRef}
          spellCheck="false"
          value={value}
        />
      </div>
      <button
        className={cx('clearButton', { clearable })}
        onClick={(event) => {
          onChange('');
          onClear?.();
          inputRef.current?.focus();
          event.preventDefault();
          event.stopPropagation();
        }}
        type="button">
        <XIcon maxHeight={8} maxWidth={8} />
      </button>
    </div>
  );
};
