import { useCallback, useMemo } from 'react';

import { findPeriodicPairById, updatePeriodicPair } from '../../../lib/boundaryConditionUtils';
import { expandGroupsExcludingTags, rollupGroups, unwrapSurfaceIds } from '../../../lib/entityGroupUtils';
import { Logger } from '../../../lib/observability/logs';
import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useSimulationConfig } from '../useSimulationConfig';

const logger = new Logger('SelectionManager');

export const usePeriodicPairSelection = (currentSide: 'sideA' | 'sideB') => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNode } = useSelectionContext();

  // == Recoil
  const { simParam, saveParamAsync } = useSimulationConfig();
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  const sideA = currentSide === 'sideA';

  const periodicPair = useMemo(
    () => findPeriodicPairById(simParam, selectedNode?.id || ''),
    [selectedNode?.id, simParam],
  );
  const surfaces = sideA ? periodicPair?.boundA : periodicPair?.boundB;
  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);
  const nodesIdsRollUp = useMemo(() => rollup(surfaces || []), [rollup, surfaces]);

  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (geometryTags.isTagId(nodeId)) {
      const nFaces = geometryTags.surfacesFromTagEntityGroupId(nodeId)?.length || 0;
      if (nFaces === 0) {
        return { related: true, disabled: true, tooltip: 'No surfaces in this tag' };
      }
    }

    const getSurfaceErrors = (surfaceId: string) => (
      updatePeriodicPair(
        simParam,
        selectedNode?.id || '',
        [surfaceId],
        geometryTags,
        staticVolumes,
        entityGroupData,
        sideA,
        true,
      )
    );

    if (nodeType === NodeType.SURFACE) {
      const errors = getSurfaceErrors(nodeId);

      return {
        related: true,
        disabled: errors.length > 0,
        tooltip: errors.join(', '),
      };
    }

    if (nodeType === NodeType.SURFACE_GROUP) {
      const errors = unwrapSurfaceIds([nodeId], geometryTags, entityGroupData)
        .flatMap((surfaceId) => getSurfaceErrors(surfaceId))
        .join(' ');
      return { related: true, disabled: !!errors, tooltip: errors };
    }

    return defaultNodeFilter(nodeType);
  }, [
    entityGroupData,
    geometryTags,
    selectedNode?.id,
    sideA,
    simParam,
    staticVolumes,
  ]);

  const setSurfaces = useCallback(async (surfaceIds: string[]) => {
    await saveParamAsync((newParam) => {
      const periodicPairNew = findPeriodicPairById(newParam, selectedNode?.id || '');
      if (!periodicPairNew) {
        logger.error('Could not find periodic pair');
        return;
      }
      const unwrapped = expandGroupsExcludingTags(entityGroupData, surfaceIds);
      if (sideA) {
        periodicPairNew.boundA = unwrapped;
      } else {
        periodicPairNew.boundB = unwrapped;
      }
    }).catch(() => { });
  }, [
    entityGroupData,
    saveParamAsync,
    selectedNode?.id,
    sideA,
  ]);

  return { nodeFilter, setSurfaces, nodesIdsRollUp };
};
