// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/base/base.proto (package luminary.proto.base, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3 } from "@bufbuild/protobuf";
import { LCStatus } from "../lcstatus/lcstatus_pb.js";
import { QuantityType } from "../quantity/quantity_pb.js";

/**
 * Subcode is stored in StatusPayload.subcode.  It classifies the error type in
 * finer grain.
 *
 * @generated from enum luminary.proto.base.Subcode
 */
export const Subcode = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.base.Subcode",
  [
    {no: 0, name: "SUBCODE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SUBCODE_USER_NOT_FOUND", localName: "USER_NOT_FOUND"},
    {no: 2, name: "SUBCODE_JOB_CANCELED_BY_USER", localName: "JOB_CANCELED_BY_USER"},
    {no: 3, name: "SUBCODE_JOB_CANCELED_DIVERGENCE", localName: "JOB_CANCELED_DIVERGENCE"},
    {no: 4, name: "SUBCODE_JOB_CANCELED_SETUP_ERROR", localName: "JOB_CANCELED_SETUP_ERROR"},
    {no: 5, name: "SUBCODE_INCOMPATIBLE_CLIENT", localName: "INCOMPATIBLE_CLIENT"},
    {no: 6, name: "SUBCODE_INSUFFICIENT_QUOTA", localName: "INSUFFICIENT_QUOTA"},
    {no: 7, name: "SUBCODE_EMAIL_DOMAIN_NOT_IN_ALLOWED_DOMAINS", localName: "EMAIL_DOMAIN_NOT_IN_ALLOWED_DOMAINS"},
    {no: 8, name: "SUBCODE_TRIAL_ACCOUNT_USER_BLOCKED", localName: "TRIAL_ACCOUNT_USER_BLOCKED"},
  ],
);

/**
 * Mappings between a nodelet job status and a job status is defined as follows:
 *
 * - if >0 pods are in Completed state => job is in Completed state
 * - else if >0 process is in Failed state => job is in Failed state
 * - else job is in Active state
 *
 *
 * @generated from enum luminary.proto.base.JobStatusType
 */
export const JobStatusType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.base.JobStatusType",
  [
    {no: 0, name: "Invalid"},
    {no: 1, name: "Active"},
    {no: 2, name: "Completed"},
    {no: 3, name: "Failed"},
    {no: 4, name: "Suspended"},
    {no: 5, name: "PendingRetry"},
    {no: 6, name: "Suspending"},
  ],
);

/**
 * @generated from enum luminary.proto.base.Vector3Component
 */
export const Vector3Component = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.base.Vector3Component",
  [
    {no: 0, name: "VECTOR_3_COMPONENT_INVALID"},
    {no: 1, name: "VECTOR_3_COMPONENT_X"},
    {no: 2, name: "VECTOR_3_COMPONENT_Y"},
    {no: 3, name: "VECTOR_3_COMPONENT_Z"},
  ],
);

/**
 * Checksum is a cryptographic checksum of data.
 *
 * @generated from message luminary.proto.base.Checksum
 */
export const Checksum = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.Checksum",
  () => [
    { no: 1, name: "sha256", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "algorithm" },
  ],
);

/**
 * Serialization of absl::Status. Represents the result of an operation.  This
 * type is identical to
 * https://github.com/grpc/grpc/blob/master/src/proto/grpc/status/status.proto
 *
 * It is replicated here, because the grpc proto isn't available in all
 * languages (in particular Javascript/Typescript).
 *
 * @generated from message luminary.proto.base.Status
 */
export const Status = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.Status",
  () => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "details", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * StatusPayload is stored in Status.details and absl::Status details.
 *
 * @generated from message luminary.proto.base.StatusPayload
 */
export const StatusPayload = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.StatusPayload",
  () => [
    { no: 1, name: "subcode", kind: "enum", T: proto3.getEnumType(Subcode) },
    { no: 3, name: "detail", kind: "message", T: StatusPayloadDetail, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.base.StatusPayloadDetail
 */
export const StatusPayloadDetail = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.StatusPayloadDetail",
  () => [
    { no: 16, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "where", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Status of a job or a workflow. The definition must match the JobStatus type
 * in the DB schema.
 *
 *
 * @generated from message luminary.proto.base.JobStatus
 */
export const JobStatus = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.JobStatus",
  () => [
    { no: 1, name: "typ", kind: "enum", T: proto3.getEnumType(JobStatusType) },
    { no: 2, name: "status", kind: "message", T: Status, oneof: "failed_reason" },
    { no: 3, name: "lcstatus", kind: "message", T: LCStatus, oneof: "failed_reason" },
  ],
);

/**
 * 3D vector.
 *
 * @generated from message luminary.proto.base.AdVector3
 */
export const AdVector3 = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.AdVector3",
  () => [
    { no: 1, name: "x", kind: "message", T: AdFloatType },
    { no: 2, name: "y", kind: "message", T: AdFloatType },
    { no: 3, name: "z", kind: "message", T: AdFloatType },
  ],
);

/**
 * Passive 3D vector.
 *
 * @generated from message luminary.proto.base.Vector3
 */
export const Vector3 = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.Vector3",
  () => [
    { no: 1, name: "x", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "z", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Passive 3D vector.
 *
 * @generated from message luminary.proto.base.IntVector3
 */
export const IntVector3 = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.IntVector3",
  () => [
    { no: 1, name: "x", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "y", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "z", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * Passive 3x3 matrix.
 *
 * @generated from message luminary.proto.base.Matrix3
 */
export const Matrix3 = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.Matrix3",
  () => [
    { no: 1, name: "a", kind: "message", T: Vector3 },
    { no: 2, name: "b", kind: "message", T: Vector3 },
    { no: 3, name: "c", kind: "message", T: Vector3 },
  ],
);

/**
 * @generated from message luminary.proto.base.FirstOrderAdType
 */
export const FirstOrderAdType = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.FirstOrderAdType",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "tangent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
    { no: 3, name: "adjoint", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.base.SecondOrderAdType
 */
export const SecondOrderAdType = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.SecondOrderAdType",
  () => [
    { no: 1, name: "value", kind: "message", T: FirstOrderAdType },
    { no: 2, name: "tangent", kind: "message", T: FirstOrderAdType, repeated: true },
    { no: 3, name: "adjoint", kind: "message", T: FirstOrderAdType, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.base.ExpressionType
 */
export const ExpressionType = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.ExpressionType",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "expression", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.base.AdFloatType
 */
export const AdFloatType = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.AdFloatType",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "ad_types" },
    { no: 2, name: "first_order", kind: "message", T: FirstOrderAdType, oneof: "ad_types" },
    { no: 3, name: "second_order", kind: "message", T: SecondOrderAdType, oneof: "ad_types" },
    { no: 5, name: "variable", kind: "message", T: ExpressionType, oneof: "ad_types" },
    { no: 4, name: "quantity_type", kind: "enum", T: proto3.getEnumType(QuantityType), oneof: "quantity" },
  ],
);

/**
 * An object that wraps an integer. It is used in types such as fvm.Param so
 * that we can distinguish a missing field and a field that's set to the zero
 * value.
 *
 * @generated from message luminary.proto.base.Int
 */
export const Int = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.Int",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * An object that wraps an float. Used by stable schema types to represent
 * a real field. See the doc for the Int field too.
 *
 * @generated from message luminary.proto.base.Float
 */
export const Float = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.Float",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Wraps a boolean to distinguish a missing field and a field that's set to
 * false.
 *
 * @generated from message luminary.proto.base.Bool
 */
export const Bool = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.Bool",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Arbitrary length AD vector
 *
 * @generated from message luminary.proto.base.AdRow
 */
export const AdRow = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.AdRow",
  () => [
    { no: 1, name: "element", kind: "message", T: AdFloatType, repeated: true },
  ],
);

/**
 * AD matrix
 *
 * @generated from message luminary.proto.base.AdMatrix
 */
export const AdMatrix = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.base.AdMatrix",
  () => [
    { no: 1, name: "row", kind: "message", T: AdRow, repeated: true },
  ],
);

