// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVMapMode, LCVType } from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';

import { RgbColor } from '../../../designSystem';
import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { ContourState } from './filterUtils';

/**
 * A filter for computing a contour (isosurface)
 */
export class LcvContour extends LcvFilter {
  parent: LcvFilter;
  state: ContourState;
  color: RgbColor | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: ContourState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'contour',
        `contour_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'contour',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  /**
   * Pass the object params into LCVis
   */
  updateParams() {
    const { isosurface_field, iso_values, component } = this.state;
    // Copy isovalue data into LCVis
    // TODO will: make a utility for this
    const isovalue_data = this.lcv.newData1D(
      this.sessionHandle,
      LCVType.kLCVDataTypeFloat,
      iso_values.length,
      0,
    ).data;
    const mapInfo = this.lcv.mapData(
      this.sessionHandle,
      isovalue_data,
      LCVMapMode.kLCVMapModeWrite,
      0,
      0,
    );
    new Float32Array(this.lcv.memory(), mapInfo.mapping, mapInfo.size)
      .set(iso_values);
    this.lcv.unmapData(this.sessionHandle, isovalue_data);

    this.setParam('isosurface_field', LCVType.kLCVDataTypeString, isosurface_field);
    this.setParam('isovalues', LCVType.kLCVDataTypeData1D, isovalue_data);
    this.setParam('vector_component', LCVType.kLCVDataTypeUint, component);

    this.lcv.release(this.sessionHandle, isovalue_data, 0);
  }

  setState(newState: ContourState) {
    if (deepEqual(this.state, newState)) {
      return;
    }
    this.state = newState;
    this.updateParams();
  }
}
