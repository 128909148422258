// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useCallback, useMemo } from 'react';

import { ParamName, paramDesc } from '../../../SimulationParamDescriptor';
import { newAdFloat, newScalarAdVector } from '../../../lib/adUtils';
import assert from '../../../lib/assert';
import { getMaterialId, getMaterialName } from '../../../lib/materialUtils';
import { findFluidPhysicsMaterial } from '../../../lib/physicsUtils';
import { findParentPhysics, findPorousModelById } from '../../../lib/porousModelUtils';
import { MATERIAL_CONTAINER_NODE_ID, NodeType } from '../../../lib/simulationTree/node';
import { mapVisualizerEntitiesToVolumes } from '../../../lib/subselectUtils';
import { useNodePanel } from '../../../lib/useNodePanel';
import { AdFloatType, AdVector3 } from '../../../proto/base/base_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useStaticVolumes } from '../../../recoil/volumes';
import LabeledInput from '../../Form/LabeledInput';
import { AdNumberInput } from '../../Form/NumberInput';
import { AdVector3Input } from '../../Form/Vector3Input';
import { CollapsiblePanel } from '../../Panel/CollapsiblePanel';
import QuantityAdornment from '../../QuantityAdornment';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { usePorousModelVolumes } from '../../hooks/subselect/usePorousModelVolumes';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { SectionMessage } from '../../notification/SectionMessage';
import { AttributesDisplay } from '../AttributesDisplay';
import { NodeSubselect } from '../NodeSubselect';
import PropertiesSection from '../PropertiesSection';

const paramDarcyCoeff = paramDesc[ParamName.DarcyCoeff];
const paramForchCoeff = paramDesc[ParamName.ForchheimerCoeff];
const paramHeatSourcePower = paramDesc[ParamName.PorousHeatSourcePowerPerUnitVolume];

const { IDEAL_GAS, CONSTANT_DENSITY_ENERGY } = simulationpb.DensityRelationship;

// A panel displaying all the settings for the selected porous model
export const PorousModelPropPanel = () => {
  // Context
  const { projectId, readOnly, workflowId, jobId } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected porous model row');

  // Recoil
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  // Hooks
  const propClasses = useCommonTreePropsStyles();
  const { simParam, saveParamAsync } = useSimulationConfig();
  const { nodeFilter, setAssigedVolumeNodeIds, zoneIds } = usePorousModelVolumes();

  // Data
  const mapVisualizerEntities = useCallback(
    (ids: string[]) => mapVisualizerEntitiesToVolumes(ids, staticVolumes),
    [staticVolumes],
  );
  const porousModel = useMemo(() => findPorousModelById(simParam, node.id), [simParam, node.id]);
  const darcyCoeffs = useMemo(
    () => porousModel?.darcyCoeff || newScalarAdVector(),
    [porousModel],
  );
  const forchheimerCoeffs = useMemo(
    () => porousModel?.forchheimerCoeff || newScalarAdVector(),
    [porousModel],
  );
  const heatSourcePowerPerUnitVolume = useMemo(
    () => porousModel?.porousHeatSourcePowerPerUnitVolume || newAdFloat(0.0),
    [porousModel],
  );

  const defnPanel = useNodePanel(node.id, 'coordinates');

  const material = useMemo(() => {
    const physics = findParentPhysics(simParam, node.id);
    return physics ?
      findFluidPhysicsMaterial(simParam, physics, geometryTags, staticVolumes) : null;
  }, [node.id, simParam, geometryTags, staticVolumes]);

  const enableHeatSource = useMemo(() => {
    const densityRel = material?.material.case === 'materialFluid' ?
      material.material.value.densityRelationship : null;
    if (densityRel) {
      return [IDEAL_GAS, CONSTANT_DENSITY_ENERGY].includes(densityRel);
    }
    return false;
  }, [material]);

  const materialLink = useMemo(() => {
    if (material) {
      return {
        nodeIds: [getMaterialId(material)],
        label: getMaterialName(material, simParam),
      };
    }
    return {
      label: 'Materials',
      nodeIds: [MATERIAL_CONTAINER_NODE_ID],
    };
  }, [material, simParam]);

  const handleChangeDarcyCoefficients = async (value: AdVector3) => {
    await saveParamAsync((param) => {
      const model = findPorousModelById(param, node.id);
      if (model) {
        model.darcyCoeff = value;
      }
    });
  };

  const handleChangeForchheimerCoefficients = async (value: AdVector3) => {
    await saveParamAsync((param) => {
      const model = findPorousModelById(param, node.id);
      if (model) {
        model.forchheimerCoeff = value;
      }
    });
  };

  const handleChangePorousHeatSourcePowerPerUnitVolume = async (value: AdFloatType) => {
    if (!enableHeatSource) {
      return;
    }
    await saveParamAsync((param) => {
      const model = findPorousModelById(param, node.id);
      if (model) {
        model.porousHeatSourcePowerPerUnitVolume = value;
      }
    });
  };

  if (!porousModel) {
    return null;
  }

  return (
    <div className={propClasses.properties}>
      <AttributesDisplay attributes={[{ label: 'Type', value: 'Porous' }]} />
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={defnPanel.collapsed}
          heading="Definition"
          onToggle={defnPanel.toggle}>
          <LabeledInput
            help={paramDarcyCoeff.help}
            label={paramDarcyCoeff.text}>
            <AdVector3Input
              disabled={readOnly}
              onCommit={handleChangeDarcyCoefficients}
              quantityType={QuantityType.DARCY_COEFFICIENT}
              value={darcyCoeffs}
            />
          </LabeledInput>
          <LabeledInput
            help={paramForchCoeff.help}
            label={paramForchCoeff.text}>
            <AdVector3Input
              disabled={readOnly}
              onCommit={handleChangeForchheimerCoefficients}
              quantityType={QuantityType.FORCHHEIMER_COEFFICIENT}
              value={forchheimerCoeffs}
            />
          </LabeledInput>
          <LabeledInput
            label={paramHeatSourcePower.text}>
            <AdNumberInput
              asBlock
              disabled={readOnly || !enableHeatSource}
              endAdornment={(
                <QuantityAdornment
                  quantity={paramHeatSourcePower.quantityType}
                />
              )}
              onCommit={handleChangePorousHeatSourcePowerPerUnitVolume}
              size="small"
              value={heatSourcePowerPerUnitVolume}
            />
          </LabeledInput>
          {!enableHeatSource && (
            <div className={propClasses.sectionMessageContainer}>
              <div className={propClasses.sectionMessages}>
                <SectionMessage
                  level="info"
                  links={[materialLink]}>
                  Heat source power may only be applied when the density relationship is set to
                  Ideal Gas Law or Constant Density with Energy Equation.
                </SectionMessage>
              </div>
            </div>
          )}
        </CollapsiblePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <NodeSubselect
          id={node.id}
          independentSelection
          labels={['volumes']}
          mapVisualizerEntities={mapVisualizerEntities}
          nodeFilter={nodeFilter}
          nodeIds={zoneIds}
          onChange={setAssigedVolumeNodeIds}
          readOnly={readOnly}
          referenceNodeIds={[node.id]}
          title="Volumes"
          visibleTreeNodeTypes={[NodeType.VOLUME, NodeType.TAGS_BODY, NodeType.TAGS_CONTAINER]}
        />
      </PropertiesSection>
    </div>
  );
};
