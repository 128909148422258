// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useCallback, useRef, useState } from 'react';

import cx from 'classnames';
import { Link as RouterLink, To } from 'react-router-dom';

import { CommonMenuItem, ContextMenuProps } from '../../../lib/componentTypes/menu';
import { Link } from '../../../lib/componentTypes/shared';
import { Banner, CellDisplay, ColumnState, RawValue, RowConfig } from '../../../lib/componentTypes/table';
import { EMPTY_VALUE } from '../../../lib/constants';
import { getRelativeEventCoordinates } from '../../../lib/event';
import { toggleSetItem } from '../../../lib/lang';
import { useClickBasedOpenState } from '../../../lib/useClickBasedOpenState';
import { useDataTableSelectedRows } from '../../../state/internal/component/dataTable';
import Form from '../../Form';
import { JobStatus } from '../../JobPanel/JobStatus';
import { CommonMenu } from '../../Menu/CommonMenu';
import Tooltip from '../../Tooltip';
import { EditableText } from '../../controls/EditableText';
import { LuminaryToggleSwitch } from '../../controls/LuminaryToggleSwitch';
import { HorizontalCirclesTripleIcon } from '../../svg/HorizontalCirclesTripleIcon';
import { BulletedContent } from '../../visual/BulletedContent';
import { ColoredCircle } from '../../visual/ColoredCircle';
import { Flex } from '../../visual/Flex';
import { Tag } from '../../visual/Tag';

import { TableContext } from './context';
import {
  getBodyCellClasses,
  getCellContentClasses,
  getCellSizingClasses,
  getCellSortClasses,
  getFormattedValue,
  getNormalizedValue,
  getTransformedValue,
} from './util';

import { FavoriteStar } from '@/components/FavoriteStar';

interface BodyCellProps {
  column: ColumnState;
  cellDisplay?: CellDisplay | CellDisplay[];
  value: RawValue;
  // If this is the last cell, we pass the menuItems for showing the context menu there on hover
  menuItems?: CommonMenuItem[];
  link?: Link;
  route?: To;
}

const BodyCell = (props: BodyCellProps) => {
  const { cellDisplay = [], column, link, menuItems, route, value } = props;

  const cellDisplays = Array.isArray(cellDisplay) ? cellDisplay : [cellDisplay];

  // Formatting classes obliged by the column config
  const cellClasses: string[] = getBodyCellClasses(column);
  const contentClasses: string[] = getCellContentClasses(column, true);
  const highlight = column.config.displayOptions?.highlight;
  const headerStyle = column.config.displayOptions?.headerStyle;

  // These control the menu that appears over the last cell when hovering the row
  const controlRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const { open, setOpen } = useClickBasedOpenState(controlRef, menuRef);

  return (
    <TableContext.Consumer>
      {(context) => {
        const {
          disableSortHighlighting,
          currentSort,
          density,
          variant,
          showControlColumn,
        } = context;

        const sortClasses = (column && currentSort && !disableSortHighlighting) ?
          getCellSortClasses(currentSort, column) : [];
        const sizingClasses = column ? getCellSizingClasses(column) : [];

        // First, normalize value
        const normalValue = getNormalizedValue(value, column);
        // Next, apply any transformations to the value
        const intermediateValue = getTransformedValue(normalValue, column);
        // Finally, format the value
        const formattedValue = getFormattedValue(intermediateValue, column);

        const contentValue = formattedValue ?
          cellDisplays.reduce((result, display) => {
            if (display?.type === 'tag') {
              return (
                <Tag {...display} size="small">{result}</Tag>
              );
            }
            if (display?.type === 'circleIcons') {
              return (
                <Flex alignItems="center" gap={8}>
                  {display.icons.map((val) => (
                    <Tooltip key={val.name} title={val.title}>
                      <span>
                        <ColoredCircle icon={{ maxWidth: 16, maxHeight: 16, ...val }} />
                      </span>
                    </Tooltip>
                  ))}
                </Flex>
              );
            }
            if (display?.type === 'bullet') {
              return (
                <BulletedContent bulletColor={display.color}>{result}</BulletedContent>
              );
            }
            if (display?.type === 'tooltip') {
              return (
                <Tooltip title={display.content}><span>{result}</span></Tooltip>
              );
            }
            if (display?.type === 'toggle' && column.config.type === 'boolean') {
              return (
                <LuminaryToggleSwitch onChange={display.onChange} value={normalValue as boolean} />
              );
            }
            if (display?.type === 'editable' && column.config.type === 'string') {
              return (
                <div>
                  <EditableText
                    active={display.active}
                    editButton
                    onChange={display.onEdit}
                    onEditButtonClick={display.onStart}
                    value={formattedValue}
                  />
                </div>
              );
            }
            if (display?.type === 'jobStatus') {
              return (
                <JobStatus
                  inRunStatusPanel={false}
                  job={display.job}
                  projectId={display.projectId}
                  workflowId={display.workflowId}
                />
              );
            }
            if (display?.type === 'favorite') {
              return (
                <FavoriteStar active={display.active} onChange={display.onChange} />
              );
            }
            return result;
          }, formattedValue as ReactNode) :
          column.config.displayOptions?.emptyDisplay ?? EMPTY_VALUE;

        // In order to support true hyperlinks in cells without any dead space, all cells must have
        // 0 padding.  An inner element with the "cellContent" applies any common cell padding.
        const cellContent = (
          <div className={cx('cellContent', contentClasses, `${density}`)}>{contentValue}</div>
        );

        let content = cellContent;
        if (route) {
          content = (
            <RouterLink to={route}>{cellContent}</RouterLink>
          );
        } else if (link) {
          content = (
            <a
              href={link.href}
              rel="noopener noreferrer"
              target={link.external ? '_blank' : '_top'}>
              {cellContent}
            </a>
          );
        }

        return (
          <td
            className={cx(
              cellClasses,
              sizingClasses,
              sortClasses,
              `${variant}`,
              { highlight, headerStyle, menuCell: menuItems?.length },
            )}
            data-table-cell-value={formattedValue}
            data-table-column-id={column.config.id}>
            {content}

            {/* The context menu trigger is shown over the last cell when hovering the row  */}
            {!!menuItems?.length && showControlColumn && (
              <>
                <button
                  className={cx('control rowControl', { engaged: open })}
                  onClick={(event: React.MouseEvent) => {
                    setOpen((oldValue) => !oldValue);
                  }}
                  ref={controlRef}
                  type="button">
                  <HorizontalCirclesTripleIcon maxHeight={14} maxWidth={14} />
                </button>
                <div className="controlCellMenu">
                  <div ref={menuRef}>
                    <CommonMenu
                      anchorEl={controlRef.current}
                      closeOnSelect
                      menuItems={menuItems}
                      onClose={() => setOpen(false)}
                      open={open}
                      position="below-left"
                      positionTransform={{ top: 8 }}
                    />
                  </div>
                </div>
              </>
            )}
          </td>
        );
      }}
    </TableContext.Consumer>
  );
};

interface BodySelectCellProps {
  tableName: string;
  row: RowConfig;
}

const BodySelectCell = (props: BodySelectCellProps) => {
  const { row, tableName } = props;

  const [selectedRows, setSelectedRows] = useDataTableSelectedRows(tableName);

  return (
    <TableContext.Consumer>
      {(context) => {
        const { showSelectionColumn, density } = context;

        if (showSelectionColumn && selectedRows && setSelectedRows) {
          return (
            <td className="control">
              <div className={cx('cellContent control', `${density}`)}>
                <Form.CheckBox
                  checked={selectedRows?.has(row.id)}
                  disabled={row.canSelect === false}
                  onChange={() => {
                    setSelectedRows((oldRows) => {
                      const newRows = new Set(oldRows);

                      toggleSetItem(newRows, row.id);

                      return newRows;
                    });
                  }}
                />
              </div>
            </td>
          );
        }
        return null;
      }}
    </TableContext.Consumer>
  );
};

interface BodyRowProps {
  tableName: string;
  columns: ColumnState[];
  row: RowConfig;
}

function BodyRow(props: BodyRowProps) {
  const { columns, row, tableName } = props;

  const { cellDisplay = {}, id, menuItems, values } = row;

  const rowRef = useRef<HTMLTableRowElement>(null);
  const [contextMenuProps, setContextMenuProps] = useState<ContextMenuProps>({ open: false });
  const [selectedRows] = useDataTableSelectedRows(tableName);

  const getMenuTransform = useCallback((event: MouseEvent) => {
    if (rowRef.current) {
      const coords = getRelativeEventCoordinates(event, rowRef.current, { fromRight: true });
      return { left: coords.x, top: coords.y };
    }
    return undefined;
  }, []);

  const handleContextMenu = (event: React.MouseEvent) => {
    if (menuItems?.length && !contextMenuProps.open) {
      event.preventDefault();

      setContextMenuProps({
        open: true,
        transform: getMenuTransform(event.nativeEvent),
      });
    }
  };

  return (
    <tr
      className={cx({ selected: selectedRows.has(id) })}
      data-table-row-id={id}
      onClick={row.onClick}
      onContextMenu={handleContextMenu}
      onDoubleClick={row.onDoubleClick}
      ref={rowRef}>
      <BodySelectCell
        key="__systemColumnSelect"
        row={row}
        tableName={tableName}
      />
      {columns.map((column, idx) => {
        const columnId = column.config.id;
        const value = values[columnId] ?? null;

        return (
          <BodyCell
            cellDisplay={cellDisplay[columnId]}
            column={column}
            key={columnId}
            link={row.link}
            menuItems={idx === columns.length - 1 ? menuItems : undefined}
            route={row.route}
            value={value}
          />
        );
      })}
      {!!menuItems?.length && (
        <CommonMenu
          anchorEl={rowRef.current}
          closeOnSelect
          menuItems={menuItems}
          onClose={() => setContextMenuProps({ open: false })}
          open={contextMenuProps.open}
          position="right-down"
          positionTransform={contextMenuProps.transform}
        />
      )}
    </tr>
  );
}

interface BannerRowProps {
  banner: Banner;
}

function BannerRow(props: BannerRowProps) {
  const { banner } = props;

  return (
    <TableContext.Consumer>
      {(context) => {
        const { columnCount, density } = context;

        return (
          <tr data-table-row-id={banner.id}>
            <td className="bannerCell" colSpan={columnCount}>
              <div className={cx('cellContent', `${density}`)}>
                {banner.content}
              </div>
            </td>
          </tr>
        );
      }}
    </TableContext.Consumer>
  );
}

export interface TableBodyProps {
  tableName: string;
  banners?: Banner[];
  columns: ColumnState[];
  rows: RowConfig[];
}

export const TableBody = (props: TableBodyProps) => {
  const { banners = [], columns, rows, tableName } = props;

  return (
    <tbody>
      {banners.map((banner) => (
        <BannerRow
          banner={banner}
          key={banner.id}
        />
      ))}
      {rows.map((row) => (
        <BodyRow
          columns={columns}
          key={row.id}
          row={row}
          tableName={tableName}
        />
      ))}
    </tbody>
  );
};
