// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useState } from 'react';

import { colors } from '../../lib/designSystem';
import { findFrameById, findFrameParentById, removeFrame } from '../../lib/motionDataUtils';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useMotionFrameToDelete } from '../../recoil/useProjectPage';
import { useStaticVolumes } from '../../recoil/volumes';
import { RadioButton } from '../Form/RadioButton';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useNodeDeletion } from '../hooks/useNodeDeletion';
import { useSimulationConfig } from '../hooks/useSimulationConfig';

import { Dialog } from './Base';

/** The DeleteMotionFrame dialog is used to confirm deletion of a frame with children.  The user
 * must choose whether to delete all descendant frames or hoist them up to the nearest ancestor
 * frame.
 */

const useStyles = makeStyles(
  () => createStyles({
    options: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      backgroundColor: colors.surfaceDark2,
      borderRadius: '8px',
      padding: '12px',
    },
    option: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      fontWeight: 400,
      color: colors.lowEmphasisText,
    },
    optionLabel: {
      fontWeight: 400,
      fontSize: '13px',
      color: colors.lowEmphasisText,
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
    icon: {
      display: 'flex',
    },
  }),
  { name: 'DeleteMotionFrameDialog' },
);

type Action = 'delete' | 'hoist';

export const DeleteMotionFrameDialog = () => {
  // Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // Recoil
  const [frameIdToDelete, setFrameIdToDelete] = useMotionFrameToDelete();
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // Hooks
  const classes = useStyles();
  const { postDeleteNodeIds } = useNodeDeletion();
  const { simParam, saveParamAsync } = useSimulationConfig();

  // State
  const [action, setAction] = useState<Action>('hoist');

  // Data
  const frame = findFrameById(simParam, frameIdToDelete);
  const parentFrame = findFrameParentById(simParam, frameIdToDelete);

  // Handlers
  const handleSubmit = useCallback(async () => {
    const removed = await saveParamAsync(
      (newParam) => removeFrame(
        newParam,
        frameIdToDelete,
        staticVolumes,
        geometryTags,
        entityGroupData,
        { hoist: action === 'hoist' },
      ),
    );
    if (removed) {
      postDeleteNodeIds([frameIdToDelete]);
    }
    setFrameIdToDelete('');
  }, [
    saveParamAsync,
    setFrameIdToDelete,
    frameIdToDelete,
    staticVolumes,
    geometryTags,
    entityGroupData,
    action,
    postDeleteNodeIds,
  ]);

  // Effects
  useEffect(() => {
    if (!frameIdToDelete) {
      setAction('hoist');
    }
  }, [frameIdToDelete]);

  useEffect(() => {
    if (frameIdToDelete && !(frame && parentFrame)) {
      // state setter must be inside a useEffect
      // https://legacy.reactjs.org/blog/2020/02/26/react-v16.13.0.html#new-warnings
      setFrameIdToDelete('');
    }
  }, [frame, frameIdToDelete, parentFrame, setFrameIdToDelete]);

  // Render
  if (!frame || !parentFrame) {
    return <></>;
  }

  return (
    <Dialog
      cancelButton={{ label: 'Cancel' }}
      continueButton={{ label: 'Delete' }}
      destructive
      modal
      onClose={() => setFrameIdToDelete('')}
      onContinue={handleSubmit}
      open={!!frameIdToDelete}
      title="Confirm Frame Deletion">
      <div>
        <p>
          Before deleting frame <span className="fontWeight700">{frame.frameName}</span>,
          choose how to handle its child frames.
        </p>
        <div className={classes.options}>
          <label className={classes.option}>
            <RadioButton
              checked={action === 'hoist'}
              name="frameDeleteAction"
              onClick={() => setAction('hoist')}
              value={action}
            />
            <div className={classes.optionLabel}>
              Attach child frames to frame
              <span className="fontWeight700">{parentFrame.frameName}</span>
            </div>
          </label>
          <label className={classes.option}>
            <RadioButton
              checked={action === 'delete'}
              name="frameDeleteAction"
              onClick={() => setAction('delete')}
              value={action}
            />
            <div className={classes.optionLabel}>Delete child frames</div>
          </label>
        </div>
      </div>
    </Dialog>
  );
};
