// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { LCVManipulationMode } from '@luminarycloudinternal/lcvis';

import * as Vector from '../../../../lib/Vector';
import { hideDragLineWidget, hideIdentifiedPlaneWidget, releaseIdentifiedPlaneWidget, showDragLineWidget, showIdentifiedPlaneWidget, updateDragLineWidgetState, updateIdentifiedPlaneWidgetState } from '../../../../lib/lcvis/api';
import { LcvMultiSlice } from '../../../../lib/lcvis/classes/filters/LcvMultiSlice';
import { lcvHandler } from '../../../../lib/lcvis/handler/LcvHandler';
import { addRpcError, addWarning } from '../../../../lib/transientNotification';
import { EditSource, visComputeFilterWrapper } from '../../../../lib/visUtils';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../../../recoil/lcvis/lcvisEnabledState';
import { useLcvisFilterStatus } from '../../../../recoil/lcvis/lcvisFilterStatus';
import { useEditState } from '../../../../recoil/paraviewState';
import { ActionButton } from '../../../Button/ActionButton';
import Form from '../../../Form';
import CheckBox from '../../../Form/CheckBox';
import { Vector3Input } from '../../../Form/Vector3Input';
import { NumberSpinner } from '../../../NumberSpinner';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { SimpleSlider } from '../../../controls/slider/SimpleSlider';
import { PlaneInput, PlaneParam } from '../../../visFilter/PlaneInput';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import PropertiesSection from '../../PropertiesSection';
import { CommonFilterMessages } from '../shared/CommonFilterMessages';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

import { usePreventVisibilityRestore } from '@/components/hooks/usePreventFilterVisibilityChanges';

function getDefaultStart(bounds: ParaviewRpc.Bounds): ParaviewRpc.Vector3 {
  let x, y, z;
  if (bounds && bounds.length > 2) {
    x = parseFloat((bounds[0] +
      ((bounds[1] - bounds[0]) * 0.25)).toPrecision(4));
    y = parseFloat(((bounds[2] + bounds[3]) / 2).toPrecision(4));
    z = parseFloat(((bounds[4] + bounds[5]) / 2).toPrecision(4));
  } else {
    x = 0.0;
    y = 0.0;
    z = 0.0;
  }
  return { x, y, z };
}

function getDefaultEnd(bounds: ParaviewRpc.Bounds): ParaviewRpc.Vector3 {
  let x, y, z;
  if (bounds && bounds.length > 2) {
    x = parseFloat((bounds[1] -
      ((bounds[1] - bounds[0]) * 0.25)).toPrecision(4));
    y = parseFloat(((bounds[2] + bounds[3]) / 2).toPrecision(4));
    z = parseFloat(((bounds[4] + bounds[5]) / 2).toPrecision(4));
  } else {
    x = 1.0;
    y = 0.0;
    z = 0.0;
  }
  return { x, y, z };
}

/** Create the param filled with default values, to be used when creating a new
    filter off the given parent. */
export function newMultiSliceParam(bounds: ParaviewRpc.Bounds): ParaviewRpc.MultiSliceParam {
  return {
    typ: ParaviewRpc.TreeNodeType.MULTI_SLICE,
    nslices: 2,
    start: getDefaultStart(bounds),
    end: getDefaultEnd(bounds),
    currentslice: 0,
    showmultiple: false,
    url: '',
    projectvectors: false,
  };
}

// plane widgets crash when the normal length is too small
// define a distance threshold to determine when to render them
const MIN_NORMAL_LENGTH = 0.005;

// Panel for displaying and modifying a multislice filter.
export const MultiSlicePropPanel = (
  props: Omit<FilterPropertiesPanelProps, 'viewState'> & {
    viewState: ParaviewRpc.ViewState | null;
  },
) => {
  const { displayProps, filterNode, nodeId, viewState } = props;

  const {
    activeEdit,
    getDataVisibilityBounds,
    paraviewProjectId,
    paraviewActiveUrl,
    paraviewClientState,
    paraviewMeshMetadata,
    paraviewRenderer,
    onRpcSuccess,
  } = useParaviewContext();

  const [editState, setEditState] = useEditState();
  const editSource = editState ? editState.editSource : EditSource.FORM;
  const paraviewClient = paraviewClientState.client;
  const { updateEditState } = useSelectedFilterNode();
  const { projectId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [allSlicesVisible, setAllSlicesVisible] = useState(true);
  const [loopActive, setLoopActive] = useState(false);

  const getFilter = useCallback(() => (
    lcvHandler.display?.workspace?.filterIdToObj.get(nodeId) as LcvMultiSlice | undefined
  ), [nodeId]);

  const [lcvisFilterStatus] = useLcvisFilterStatus();
  const isCurrentFilterReady = useMemo(
    () => lcvisFilterStatus.get(nodeId)?.status === 'completed',
    [lcvisFilterStatus, nodeId],
  );
  const surfaceNameIndexMap = useRef(getFilter()?.getSurfaceNameIndexMap() || new Map());
  const spawnedWidgetIdentifiers = useRef<Set<string>>(new Set());

  // when filter status changes from whatever to completed: update the map
  useEffect(() => {
    if (isCurrentFilterReady) {
      surfaceNameIndexMap.current = getFilter()?.getSurfaceNameIndexMap() || new Map();
    }
  }, [getFilter, isCurrentFilterReady]);

  // passing reference here should be ok, it just modifies some fields
  usePreventVisibilityRestore(getFilter());

  const onUpdate = (source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => (
    updateEditState({
      editSource: source,
      param: newParam,
    })
  );

  const param = props.param as ParaviewRpc.MultiSliceParam;
  const calc = !editState && param.url === '' && !lcvisEnabled;
  const creationError = param.url === 'ERROR';

  const MAX_SLICES = 100;
  const MIN_SLICES = 1;

  const [sliderValue, setSliderValue] = useState(param.currentslice);
  const [projectVectors, setProjectVectors] = useState<boolean>(param.projectvectors);
  const loopTimer = useRef<NodeJS.Timer | null>(null);

  // Function to make asynchronous call to vis service to compute MultiSlice
  const executeVisFilter = (newNodeId: string) => {
    const currentParam = editState!.param as ParaviewRpc.MultiSliceParam;
    visComputeFilterWrapper(
      paraviewProjectId,
      paraviewActiveUrl,
      currentParam,
      newNodeId,
      activeEdit,
    );
  };

  // We need to keep track of whether we need to reset the axes to prior state on unmount.
  // If ON from before - set to false and do nothing.
  // If OFF prior to component mount - set to true and hide on unmount.
  // If turned ON / OFF by the user while component is mounted - set to false and do nothing.
  const resetAxesGridRef = useRef<boolean>(false);

  // restore slider/checkbox settings when prop panel is re-open
  useEffect(() => {
    const filter = getFilter();

    if (!lcvisEnabled || !filter) {
      return;
    }

    let allVisible = true;
    let firstVisibleIndex: number | null = null;

    [...Array(filter.getNumSurfaces()).keys()].forEach((index) => {
      const isSurfaceVisible = filter.getSurfaceVisibility(index);

      allVisible = allVisible && isSurfaceVisible;
      if (firstVisibleIndex === null && isSurfaceVisible) {
        firstVisibleIndex = index;
      }
    });

    setAllSlicesVisible(allVisible);
    setSliderValue(firstVisibleIndex || 0);
  }, [getFilter, lcvisEnabled]);

  useEffect(() => {
    if (!viewState) {
      return;
    }

    // Run on mount
    if (!viewState.axesGridVisibility && editState) {
      paraviewRenderer.showAxesGrid(true).then((res: ParaviewRpc.RpcResult) => {
        onRpcSuccess('showAxesGrid', res);
        resetAxesGridRef.current = true;
      }).catch((err: Error) => {
        addRpcError('Could not set grid axes', err);
      });
    }
    // Run on unmount
    return () => {
      if (resetAxesGridRef.current) {
        paraviewRenderer.showAxesGrid(false).then((res: ParaviewRpc.RpcResult) => {
          onRpcSuccess('showAxesGrid', res);
        }).catch((err: Error) => {
          addRpcError('Could not unset grid axes', err);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, viewState]);

  useEffect(() => {
    // If at any point the user switches axes grid off, (and then on/off/on again,)
    // do not hide the grid axes on line probe panel unmount. Leave it in user chosen end state.
    if (!viewState?.axesGridVisibility) {
      resetAxesGridRef.current = false;
    }
  }, [viewState?.axesGridVisibility]);

  useEffect(() => {
    if (!editState) {
      return () => { };
    }

    let mounted = true;
    let unsubscribeFn: (() => void) | null = null;

    // Called when the user moves the widget interactively and nslices > 1.
    const onLineWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION) {
        throw Error('only line widget supported now');
      }
      if (!mounted) {
        return;
      }
      if (
        !Vector.nearPv(newParam.line.point1, param.start) ||
        !Vector.nearPv(newParam.line.point2, param.end)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            start: newParam.line.point1,
            end: newParam.line.point2,
          },
        );
      }
    };

    // Called when the user moves the widget interactively and nslices = 1
    const onPlaneWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION) {
        throw Error('only plane widget supported now');
      }
      if (!mounted) {
        return;
      }

      if (
        !Vector.nearPv(newParam.plane.origin, param.start) ||
        !Vector.nearPv(newParam.plane.normal, param.end)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            start: newParam.plane.origin,
            end: newParam.plane.normal,
          },
        );
      }
    };

    const startWidget = async (): Promise<void> => {
      if (!paraviewClient || !paraviewMeshMetadata) {
        return;
      }
      if (param.nslices === 1) {
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onPlaneWidgetUpdate);
        // Activate the widget.
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
          {
            typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
            plane: {
              typ: 'Plane',
              origin: param.start,
              normal: param.end,
            },
            bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
          },
        );
      } else { // nslices > 1
        // Register the widget update handler.
        unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onLineWidgetUpdate);
        // Activate the widget.
        paraviewRenderer.activateWidget(
          filterNode.bounds!,
          {
            typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
            line: {
              typ: 'Line',
              point1: param.start,
              point2: param.end,
            },
            resolution: 1000.0,
          },
        );
      }
    };

    startWidget().then(() => { }).catch((err: Error) => {
      addRpcError('Could not activate widget', err);
    });
    return () => {
      mounted = false;
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, param.nslices, nodeId]);

  useEffect(() => {
    if (editState && editSource !== EditSource.PARAVIEW && viewState && paraviewMeshMetadata) {
      // When the user manually updates the line dialog, reflect the new line
      // params to the widget.
      if (param.nslices === 1) {
        const ws: ParaviewRpc.ImplicitPlaneWidgetState = {
          typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
          plane: {
            typ: 'Plane',
            origin: param.start,
            normal: param.end,
          },
          bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
        };
        paraviewRenderer.activateWidget(
          getDataVisibilityBounds(
            paraviewMeshMetadata.meshMetadata,
          ),
          ws,
        );
      } else {
        const ws: ParaviewRpc.LineSourceWidgetState = {
          typ: ParaviewRpc.WidgetType.LINE_SOURCE_WIDGET_REPRESENTATION,
          line: {
            typ: 'Line',
            point1: param.start,
            point2: param.end,
          },
          resolution: 1000.0,
        };
        paraviewRenderer.activateWidget(
          filterNode.bounds!,
          ws,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState, editSource, param, nodeId, param.start, param.end]);

  const editStateParam = editState?.param.typ === 'MultiSlice' ? editState.param : null;
  const slicesToSpawn = editStateParam?.nslices ?? 0;
  const isEditState = !!editState;

  // synchronizes the drag_line widget if the user has entered values in the form
  useEffect(() => {
    if (!editStateParam || editState?.editSource === EditSource.PARAVIEW) {
      return;
    }

    updateDragLineWidgetState({
      start: [editStateParam.start.x, editStateParam.start.y, editStateParam.start.z],
      end: [editStateParam.end.x, editStateParam.end.y, editStateParam.end.z],
    });
  }, [editState?.editSource, editStateParam]);

  // spawns both: drag line widget and plane widgets for each slice
  useEffect(() => {
    if (!lcvisEnabled || !isEditState) {
      return;
    }

    [...Array(slicesToSpawn).keys()].forEach((index) => {
      const identifier = `multislice-${index}`;

      spawnedWidgetIdentifiers.current.add(identifier);
      showIdentifiedPlaneWidget({
        identifier,
        onChange: () => {},
        manipulationMode: LCVManipulationMode.kLCVManipulationModeNone,
      });
    });

    showDragLineWidget({
      onChange: ({ start, end }) => {
        requestAnimationFrame(() => {
          setEditState((previousValue) => {
            if (!previousValue || previousValue.param.typ !== 'MultiSlice') {
              return previousValue;
            }

            return {
              ...previousValue,
              editSource: EditSource.PARAVIEW,
              param: {
                ...previousValue.param,
                start: { x: start[0], y: start[1], z: start[2] },
                end: { x: end[0], y: end[1], z: end[2] },
              },
            };
          });
        });
      },
    });

    return () => {
      hideDragLineWidget();

      [...Array(slicesToSpawn).keys()].forEach((index) => {
        hideIdentifiedPlaneWidget(`multislice-${index}`);
      });
    };
  }, [isEditState, lcvisEnabled, setEditState, slicesToSpawn]);

  // synchronizes plane widget states
  useEffect(() => {
    if (!editStateParam || !lcvisEnabled) {
      return;
    }

    const difference = [
      editStateParam.end.x - editStateParam.start.x,
      editStateParam.end.y - editStateParam.start.y,
      editStateParam.end.z - editStateParam.start.z,
    ];

    const points: [number, number, number][] = slicesToSpawn === 1 ? [[
      (editStateParam.end.x + editStateParam.start.x) / 2,
      (editStateParam.end.y + editStateParam.start.y) / 2,
      (editStateParam.end.z + editStateParam.start.z) / 2,
    ]] : [...Array(slicesToSpawn).keys()].map((index) => [
      editStateParam.start.x + (index * difference[0]) / (slicesToSpawn - 1),
      editStateParam.start.y + (index * difference[1]) / (slicesToSpawn - 1),
      editStateParam.start.z + (index * difference[2]) / (slicesToSpawn - 1),
    ]);

    const normal = [
      editStateParam.end.x - editStateParam.start.x,
      editStateParam.end.y - editStateParam.start.y,
      editStateParam.end.z - editStateParam.start.z,
    ] satisfies [number, number, number];

    const normalLength = Math.sqrt(normal[0] ** 2 + normal[1] ** 2 + normal[2] ** 2);

    if (normalLength < MIN_NORMAL_LENGTH) {
      return;
    }

    updateIdentifiedPlaneWidgetState(points.map((position, index) => ({
      identifier: `multislice-${index}`,
      state: { position, normal },
    })));
  }, [editStateParam, lcvisEnabled, slicesToSpawn]);

  // clear all spawned plane widgets
  useEffect(() => () => {
    spawnedWidgetIdentifiers.current.forEach((identifier) => {
      releaseIdentifiedPlaneWidget(identifier);
    });
  }, []);

  // Update the number of slices to compute
  const setNumberOfSlices = (value: number) => {
    if (Number.isNaN(value)) {
      // This is not a valid number so don't do anything.
      // The NumberSlider will keep the old value.
      return;
    }
    if (value > MAX_SLICES) {
      value = MAX_SLICES;
      addWarning(`Maximum number of slices supported is ${MAX_SLICES}.`);
    }
    if (value < MIN_SLICES) {
      value = MIN_SLICES;
      addWarning(`Minimum number of slices supported is ${MIN_SLICES}.`);
    }
    const bounds = (
      lcvisEnabled ?
        lcvHandler.display?.getCurrentDatasetBounds() :
        getDataVisibilityBounds(paraviewMeshMetadata!.meshMetadata)
    ) || [0, 0, 0, 0, 0, 0];

    if (value === 1 && param.nslices !== 1) { // User is changing the value from > 1 to 1.
      // Set defaults
      const xCenter = parseFloat(((bounds[0] + bounds[1]) / 2).toPrecision(4));
      const yCenter = parseFloat(((bounds[2] + bounds[3]) / 2).toPrecision(4));
      const zCenter = parseFloat(((bounds[4] + bounds[5]) / 2).toPrecision(4));
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          nslices: value,
          start: { x: xCenter, y: yCenter, z: zCenter },
          end: { x: 1, y: 0, z: 0 },
          url: '',
        },
      );
    } else if (value > 1 && param.nslices === 1) { // User is changing the value from 1 to > 1
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          nslices: value,
          start: getDefaultStart(bounds),
          end: getDefaultEnd(bounds),
          url: '',
        },
      );
    } else {
      onUpdate(
        EditSource.FORM,
        {
          ...param,
          nslices: value,
          url: '',
        },
      );
    }
  };

  const toggleAllSlices = (checked: boolean) => {
    if (lcvisEnabled) {
      const filter = getFilter();

      if (filter) {
        [...Array(filter.getNumSurfaces()).keys()].forEach(
          (index) => filter.setSurfaceVisible(
            index,
            checked ? true : index === sliderValue,
          ),
        );
      }

      setAllSlicesVisible(checked);
    } else {
      const newParam = { ...param, showmultiple: checked };
      activeEdit(nodeId, newParam);
    }
  };

  // Set the slice using slider and not in edit state
  const setSlice = (newValue: number) => {
    if (lcvisEnabled) {
      const filter = getFilter();

      if (filter) {
        const currentSliceName = `slice${newValue}`;

        surfaceNameIndexMap.current.forEach((primitiveIndex, surfaceName) => {
          const isVisible = surfaceName === currentSliceName;

          filter.setSurfaceVisible(primitiveIndex, isVisible);
        });
      }
    } else {
      const newParam = { ...param, currentslice: newValue };
      activeEdit(nodeId, newParam);
    }
  };

  // Depending on the number of slices requested, we require different inputs
  // from the user. If a single slice is requested, nslices = 1,
  // then an origin and normal is required to specify the slice plane.
  // When the number of slices is > 1, then we require two locations (point1, point2),
  // on the start and end plane. Then the normal is computed as point2 - point1 .
  const renderMultiSliceInput = (nslices: number): ReactElement => {
    if (nslices === 1) {
      return (
        <div>
          <PlaneInput
            onCommit={(newParam: PlaneParam) => onUpdate(
              EditSource.FORM,
              {
                ...param,
                start: Vector.toPvProto(newParam.origin),
                end: Vector.toPvProto(newParam.normal),
                url: '',
              },
            )}
            param={{
              origin: Vector.toProto(param.start),
              normal: Vector.toProto(param.end),
            }}
            readOnly={!editState}
          />
        </div>
      );
    }
    return (
      <div>
        <Form.LabeledInput help="Start point on the plane" label="Start">
          <Vector3Input
            disabled={!editState}
            onCommit={(value) => {
              onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  start: Vector.toPvProto(value),
                  url: '',
                },
              );
            }}
            value={Vector.toProto(param.start)}
          />
        </Form.LabeledInput>
        <Form.LabeledInput help="End point on the plane" label="End">
          <Vector3Input
            disabled={!editState}
            onCommit={(value) => {
              onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  end: Vector.toPvProto(value),
                  url: '',
                },
              );
            }}
            value={Vector.toProto(param.end)}
          />
        </Form.LabeledInput>
      </div>
    );
  };

  const isSliderDisabled = lcvisEnabled ? allSlicesVisible : param.showmultiple;
  const isLoopDisabled = lcvisEnabled ? !getFilter() || allSlicesVisible : param.showmultiple;

  const onSliderChange = (value: number) => {
    const roundedValue = Math.round(value);

    setSliderValue(roundedValue);
    setSlice(roundedValue);
  };

  const toggleLoop = () => {
    if (loopActive) {
      setLoopActive(false);

      if (loopTimer.current) {
        clearInterval(loopTimer.current);
      }

      return;
    }

    const numSurfaces = getFilter()?.getNumSurfaces() || 0;

    if (numSurfaces === 0) {
      return;
    }

    setLoopActive(true);

    let currentIndex = 0;

    const update = () => {
      currentIndex = (currentIndex + 1) % numSurfaces;
      onSliderChange(currentIndex);
    };

    loopTimer.current = setInterval(update, 500);
  };

  // clear loop when turning into edit state or closing prop panel
  const editStateActive = !!editState;
  useEffect(() => {
    const clearLoop = () => {
      if (loopTimer.current) {
        clearInterval(loopTimer.current);
      }
    };

    if (editStateActive) {
      clearLoop();
    }

    return clearLoop;
  }, [editStateActive]);

  return (
    <div>
      <CommonFilterMessages calculating={calc} creationError={creationError} />
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              disableEdit={calc}
              displayProps={displayProps}
              executeVisFilter={lcvisEnabled ? undefined : executeVisFilter}
              nodeId={nodeId}
              param={param}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <Form.LabeledInput label="Number of Slices">
            <NumberSpinner
              disabled={!editState}
              maximumValue={MAX_SLICES}
              minimumValue={MIN_SLICES}
              onCommit={(value) => {
                setNumberOfSlices(value);
              }}
              value={param.nslices}
            />
          </Form.LabeledInput>
          <Form.LabeledInput label="">
            <Form.ControlLabel
              help="Project vectors onto the slice planes."
              label="Project Vectors">
              <CheckBox
                checked={projectVectors}
                disabled={!editState}
                onChange={(checked: boolean) => {
                  setProjectVectors(checked);
                  onUpdate(
                    EditSource.FORM,
                    {
                      ...param,
                      projectvectors: checked,
                      url: '',
                    },
                  );
                }}
              />
            </Form.ControlLabel>
          </Form.LabeledInput>
          <Form.LabeledInput
            label={param.nslices === 1 ? 'Single Slice Input' : 'Multiple Slice Input'}
          />
          {renderMultiSliceInput(param.nslices)}

          <div style={{ padding: '8px 0', marginTop: '8px' }}>
            <Form.ControlLabel
              label="Show All Slices">
              <CheckBox
                checked={lcvisEnabled ? allSlicesVisible : param.showmultiple}
                onChange={toggleAllSlices}
              />
            </Form.ControlLabel>
          </div>

          {!editState && param.nslices > 1 && !isSliderDisabled && (
            <>
              <Form.LabeledInput label="Visible Slice">
                <SimpleSlider
                  disabled={isSliderDisabled}
                  max={param.nslices - 1}
                  min={0}
                  onChange={onSliderChange}
                  onCommit={onSliderChange}
                  readoutConfig={{
                    formatValue: (value) => Math.round(value).toFixed(0),
                  }}
                  showStops
                  stopCount={param.nslices}
                  value={sliderValue}
                />
              </Form.LabeledInput>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '8px',
                }}>

                <ActionButton
                  disabled={isLoopDisabled}
                  onClick={toggleLoop}
                  size="small"
                  startIcon={{ name: loopActive ? 'stop' : 'play' }}
                  title={
                    isLoopDisabled ? 'Loop is available only when single surface is visible' : ''
                  }>
                  {loopActive ? 'Stop Loop' : 'Start Loop'}
                </ActionButton>
              </div>
            </>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
    </div>
  );
};
