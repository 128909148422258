import React, { useCallback, useEffect, useMemo, useState } from 'react';

import * as Vector from '../../../../lib/Vector';
import { newProto, pvToList } from '../../../../lib/Vector';
import { hidePlaneWidget, showPlaneWidget, updatePlaneWidgetState } from '../../../../lib/lcvis/api';
import { lcvHandler } from '../../../../lib/lcvis/handler/LcvHandler';
import { updateTreeNodes } from '../../../../lib/paraviewUtils';
import { NodeType, SURFACE_NODE_TYPES, TAGS_NODE_TYPES } from '../../../../lib/simulationTree/node';
import { addRpcError } from '../../../../lib/transientNotification';
import { EditSource, findFilterTreeNodeByFunction } from '../../../../lib/visUtils';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../../../recoil/lcvis/lcvisEnabledState';
import { useEditState } from '../../../../recoil/paraviewState';
import { NodeFilter } from '../../../../recoil/simulationTreeSubselect';
import { useFilterState } from '../../../../recoil/vis/filterState';
import environmentState from '../../../../state/environment';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import { useProjectContext } from '../../../context/ProjectContext';
import { PlaneInput, PlaneParam } from '../../../visFilter/PlaneInput';
import { useSelectedFilterNode } from '../../../visFilter/useFilterNode';
import { FilterEditControl } from '../../FilterEditControl';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

import { unwrapSurfaceIds } from '@/lib/entityGroupUtils';
import { useEntityGroupData } from '@/recoil/entityGroupState';
import { useGeometryTags } from '@/recoil/geometry/geometryTagsState';

function newPlaneParam(bounds: ParaviewRpc.Bounds): ParaviewRpc.PlaneParam {
  return {
    typ: 'Plane',
    // The initial center is the bounds center.
    origin: {
      x: 0.5 * (bounds[1] + bounds[0]),
      y: 0.5 * (bounds[3] + bounds[2]),
      z: 0.5 * (bounds[5] + bounds[4]),
    },
    // The default normal is the YZ plane.
    normal: { x: 1, y: 0, z: 0 },
  };
}

export function newIntersectionCurveParam(
  bounds: ParaviewRpc.Bounds,
): ParaviewRpc.IntersectionCurveParam {
  return {
    typ: ParaviewRpc.TreeNodeType.INTERSECTION_CURVE,
    plane: newPlaneParam(bounds),
  };
}

// Panel for displaying and modifying an intersection curve filter.
export const IntersectionCurvePropPanel = (
  props: Omit<FilterPropertiesPanelProps, 'viewState'>,
) => {
  const { displayProps, filterNode, nodeId } = props;
  const {
    getDataVisibilityBounds,
    paraviewClientState,
    paraviewMeshMetadata,
    paraviewRenderer,
  } = useParaviewContext();

  const [editState] = useEditState();
  const paraviewClient = paraviewClientState.client;
  const editSource = editState ? editState.editSource : EditSource.FORM;
  const { updateEditState } = useSelectedFilterNode();
  const { projectId, workflowId, jobId } = useProjectContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const [filterState] = useFilterState({ projectId, workflowId, jobId });
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  const lcvisFilterParentNode = useMemo(() => {
    if (!lcvisEnabled) {
      return null;
    }

    const parentNode = findFilterTreeNodeByFunction(
      filterState,
      (node) => node.child.at(0)?.id === nodeId,
    );

    return parentNode ?? null;
  }, [filterState, lcvisEnabled, nodeId]);

  const lcvisSurfaces = useMemo(() => {
    if (lcvisFilterParentNode?.param.typ !== 'ExtractSurfaces') {
      return [];
    }

    return lcvisFilterParentNode.param.surfaces;
  }, [lcvisFilterParentNode?.param]);

  const [currentLcvisSurfaces, setCurrentLcvisSurfaces] = useState(lcvisSurfaces);
  useEffect(() => setCurrentLcvisSurfaces(lcvisSurfaces), [lcvisSurfaces]);

  const onUpdate = (source: EditSource, newParam: ParaviewRpc.TreeNodeParam) => {
    updateEditState({
      editSource: source,
      param: newParam,
    });
  };
  const param = props.param as ParaviewRpc.IntersectionCurveParam;

  // PV: activate widget
  useEffect(() => {
    if (!editState || lcvisEnabled) {
      return () => { };
    }

    let mounted = true;
    let unsubscribeFn: (() => void) | null = null;

    // Called when the user moves the widget interactively.
    const onPlaneWidgetUpdate = (newParam: ParaviewRpc.WidgetState): void => {
      if (newParam.typ !== ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION) {
        throw Error('only plane widget supported now');
      }
      if (!mounted) {
        return;
      }
      const planeParams = param.plane as ParaviewRpc.PlaneParam;
      if (
        !Vector.nearPv(newParam.plane.origin, planeParams.origin) ||
        !Vector.nearPv(newParam.plane.normal, planeParams.normal)
      ) {
        onUpdate(
          EditSource.PARAVIEW,
          {
            ...param,
            plane: {
              typ: 'Plane',
              origin: newParam.plane.origin,
              normal: newParam.plane.normal,
            },
          },
        );
      }
    };
    const startWidget = async (): Promise<void> => {
      if (!paraviewClient || !paraviewMeshMetadata) {
        return;
      }
      // Register the widget update handler.
      unsubscribeFn = await paraviewRenderer.registerOnUpdateWidgetHandler(onPlaneWidgetUpdate);
      // Activate the widget.
      paraviewRenderer.activateWidget(
        getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
        {
          typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
          plane: param.plane as ParaviewRpc.PlaneParam,
          bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
        },
      );
    };

    startWidget().then(() => { }).catch((err: Error) => {
      addRpcError('Could not activate widget', err);
    });
    return () => {
      mounted = false;
      if (unsubscribeFn) {
        unsubscribeFn();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editState, nodeId, lcvisEnabled]);

  // PV: activate widget
  useEffect(() => {
    if (!editState || editSource === EditSource.PARAVIEW || !paraviewMeshMetadata || lcvisEnabled) {
      return;
    }

    // When the user manually updates the plane dialog, reflect the new plane
    // params to the widget.
    const ws: ParaviewRpc.ImplicitPlaneWidgetState = {
      typ: ParaviewRpc.WidgetType.IMPLICIT_PLANE_WIDGET_REPRESENTATION,
      plane: param.plane as ParaviewRpc.PlaneParam,
      bounds: getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
    };
    paraviewRenderer.activateWidget(
      getDataVisibilityBounds(paraviewMeshMetadata.meshMetadata),
      ws,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState, editSource, param, param.plane, nodeId, lcvisEnabled]);

  const editStateActive = !!editState;

  // show the widget when edit mode is active
  useEffect(() => {
    if (!lcvisEnabled || !lcvisReady || !editStateActive) {
      return;
    }

    const planeBounds = lcvHandler.display?.getCurrentDatasetBounds();

    if (!planeBounds) {
      return;
    }

    showPlaneWidget((data) => {
      updateEditState({
        editSource: EditSource.PARAVIEW,
        param: {
          typ: 'IntersectionCurve',
          plane: {
            typ: 'Plane',
            origin: newProto(...data.position),
            normal: newProto(...data.normal),
          },
        },
      });
    });

    return () => {
      hidePlaneWidget();
    };
  }, [editStateActive, lcvisEnabled, lcvisReady, updateEditState]);

  // synchronize the data if user types something in the inputs
  useEffect(() => {
    if (
      !editState ||
      editState.param.typ !== 'IntersectionCurve' ||
      editState.editSource === EditSource.PARAVIEW
    ) {
      return;
    }

    updatePlaneWidgetState({
      normal: pvToList(editState.param.plane.normal),
      position: pvToList(editState.param.plane.origin),
    });
  }, [editState]);

  const nodeFilter = useCallback<NodeFilter>((nodeType) => ({
    disabled: nodeType !== NodeType.SURFACE,
    related: nodeType === NodeType.SURFACE,
  }), []);

  return (
    <div>
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              displayProps={displayProps}
              nodeId={nodeId}
              param={param}
              postprocessFiltersBeforeEdit={(root, newlyCreatedNode) => (
                updateTreeNodes(root, (node) => {
                  const filterId = newlyCreatedNode?.id ?? nodeId;
                  const isFilterParent = node.child.length === 1 && node.child[0].id === filterId;

                  if (!isFilterParent || node.param.typ !== 'ExtractSurfaces') {
                    return node;
                  }

                  const unrolledSurfaces = unwrapSurfaceIds(
                    currentLcvisSurfaces,
                    geometryTags,
                    entityGroupData,
                  );

                  return {
                    ...node,
                    param: { ...node.param, surfaces: unrolledSurfaces },
                  };
                })
              )}
            />
          )}
          heading="Visualization Input"
          nodeId={nodeId}
          panelName="input">
          <div>
            <PlaneInput
              onCommit={(newParam: PlaneParam) => onUpdate(
                EditSource.FORM,
                {
                  ...param,
                  plane: {
                    typ: 'Plane',
                    origin: Vector.toPvProto(newParam.origin),
                    normal: Vector.toPvProto(newParam.normal),
                  },
                },
              )}
              param={{
                origin: Vector.toProto((param.plane as ParaviewRpc.PlaneParam).origin),
                normal: Vector.toProto((param.plane as ParaviewRpc.PlaneParam).normal),
              }}
              readOnly={!editState}
            />
          </div>
          {lcvisEnabled && (
            <div style={{ paddingTop: '4px' }}>
              <NodeSubselect
                id="intersection-curve"
                independentSelection
                labels={['surfaces']}
                nodeFilter={nodeFilter}
                nodeIds={currentLcvisSurfaces}
                onChange={setCurrentLcvisSurfaces}
                readOnly={!editState}
                referenceNodeIds={[nodeId]}
                title="Surfaces"
                visibleTreeNodeTypes={[...SURFACE_NODE_TYPES, ...TAGS_NODE_TYPES]}
              />
            </div>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
