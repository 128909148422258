import { useCallback, useMemo } from 'react';

import { rollupGroups, unwrapSurfaceIds } from '../../../lib/entityGroupUtils';
import { assignSurfaceToBoundaryLayer, boundaryHeading, conflictingMeshBoundaryLayerSurfaces } from '../../../lib/mesh';
import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { wordsToList } from '../../../lib/text';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import useMeshMultiPart, { useSetMeshMultiPart } from '../../../recoil/useMeshingMultiPart';
import { useProjectContext } from '../../context/ProjectContext';

/** Displays the first N conflicting items in the tooltip, followed by "and more" if there are
  * additional conflicts.
  */
const MAX_ITEMS_IN_TOOLTIP = 3;

export const useMeshingBoundaryLayerSelection = ({ boundaryIndex }: { boundaryIndex: number }) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const geometryTags = useGeometryTags(projectId);

  // == Recoil
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  const uniqueSurfaces = useMemo(() => (
    new Set(
      unwrapSurfaceIds(
        meshMultiPart?.blParams[boundaryIndex].surfaces || [],
        geometryTags,
        entityGroupData,
      ),
    )
  ), [boundaryIndex, meshMultiPart?.blParams, geometryTags, entityGroupData]);

  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType !== NodeType.SURFACE && nodeType !== NodeType.SURFACE_GROUP) {
      return defaultNodeFilter(nodeType);
    }

    const unwrappedSurfaces = unwrapSurfaceIds([nodeId], geometryTags, entityGroupData);
    // This implies it's a tag without surfaces, we should not allow selection.
    if (unwrappedSurfaces.length === 0) {
      return defaultNodeFilter(nodeType);
    }
    // Already selected in this boundary layer, disallow selecting again.
    if (uniqueSurfaces.has(nodeId)) {
      return {
        related: true,
        disabled: true,
      };
    }
    // If all the children are disabled, disable the parent also.
    const allDisabled = unwrappedSurfaces.every((surfaceId) => uniqueSurfaces.has(surfaceId));
    if (allDisabled) {
      return {
        related: true,
        disabled: allDisabled,
      };
    }

    const conflictingLayerIndexes = conflictingMeshBoundaryLayerSurfaces(
      boundaryIndex,
      [nodeId],
      meshMultiPart!.blParams,
      geometryTags,
      entityGroupData,
    );
    const conflictingLayerNames = conflictingLayerIndexes.map(boundaryHeading);
    const shortenedLayerNames = conflictingLayerNames.length > MAX_ITEMS_IN_TOOLTIP ?
      [...conflictingLayerNames.slice(0, MAX_ITEMS_IN_TOOLTIP - 1), 'more'] :
      conflictingLayerNames;

    return {
      related: true,
      disabled: false,
      tooltip: conflictingLayerNames.length > 0 ?
        `This will remove some surfaces from ${wordsToList(shortenedLayerNames)}` :
        '',
    };
  }, [boundaryIndex, entityGroupData, geometryTags, meshMultiPart, uniqueSurfaces]);

  const setSurfaces = useCallback((newSelection: string[]) => {
    setMeshMultiPart((currentMultiPart) => {
      if (!currentMultiPart) {
        return null;
      }

      const updatedMultiPart = currentMultiPart.clone();
      const paramsList = updatedMultiPart.blParams;

      assignSurfaceToBoundaryLayer(
        paramsList,
        boundaryIndex,
        newSelection,
        geometryTags,
        entityGroupData,
      );

      return updatedMultiPart;
    });
  }, [boundaryIndex, entityGroupData, geometryTags, setMeshMultiPart]);

  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);
  const selectedSurfaces = useMemo(() => (
    rollup(meshMultiPart?.blParams.at(boundaryIndex)?.surfaces ?? [])
  ), [boundaryIndex, meshMultiPart?.blParams, rollup]);

  return { nodeFilter, setSurfaces, selectedSurfaces };
};
