// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { Params, matchPath } from 'react-router-dom';

import { routes } from '../../navigation';
import { Tracer } from '../tracing';

import { useOnPathOrVisibiltyChange } from './usePathOrVisibilityChange';

/**
 * All the tabs we want to report traces for, mapped to the human-readable name we will send in the
 * span.
 */
const tracedTabs = {
  [routes.login]: 'Login',
  [routes.acceptTerms]: 'Accept Terms',

  [routes.projectList]: 'Project List',
  [routes.setupPage]: 'Setup Tab',

  [routes.simulation]: 'Simulation',
  [routes.exploration]: 'Exploration',
  [routes.explorationJob]: 'Exploration Job',

  [routes.analysisPage]: 'Analysis Page',
  [routes.analysisPageAlt]: 'Analysis Page Alt',

  [routes.resultsPage]: 'Results Page',
  [routes.resultPageAlt]: 'Results Page Alt',

  [routes.geometryV1]: 'Geometry V1',
  [routes.geometryV2]: 'Geometry V2',

  [routes.account]: 'Account',
  [routes.library]: 'Library',
  [routes.landingPage]: 'Landing Page',
  [routes.getStarted]: 'Get Started',
  [routes.notFound]: 'Not Found',
};

export const pageLocationTracer = new Tracer('locationTracer');

type Match = {
  readableName: string;
  params: Params<string>;
}

/** Given a path, return the name of the route it matches, or null. */
const maybeGetPathMatch = (pathname: string): Match | null => {
  const routeKeys = Object.keys(tracedTabs);
  for (let i = 0; i < routeKeys.length; i += 1) {
    const key = routeKeys[i];
    const name = tracedTabs[key];
    const match = matchPath(key, pathname);
    if (match) {
      return {
        readableName: name,
        params: match.params,
      };
    }
  }
  return null;
};

/**
 * When the page location changes, if the new location is a tab we want to trace,
 * starts a new span.
 * If the page is hidden or the location changes, ends the span.
 * If the tab becomes visible again, starts a new span.
 * */
export const useLocationTrace = () => {
  const maybeStartSpan = useCallback((path: string) => {
    const matchedPath = maybeGetPathMatch(path);
    if (matchedPath) {
      pageLocationTracer.startSpan(
        `Active Page: ${matchedPath.readableName}`,
        {
          attributes: {
            pathname: path,
            ...matchedPath.params,
          },
        },
      );
    }
  }, []);

  const endActiveSpan = useCallback(() => {
    pageLocationTracer.endActiveSpan();
  }, []);

  // Start listening for path or visibility changes
  useOnPathOrVisibiltyChange(endActiveSpan, maybeStartSpan);
};
