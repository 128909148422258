// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef } from 'react';

import { SvgProps } from '../../lib/svg';
import { useSvg } from '../../lib/useSvg';

const baseWidth = 16;
const baseHeight = 16;
export const WireframeIcon = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { finalHeight, finalWidth, getColor } = useSvg(baseWidth, baseHeight, props);

  return (
    <svg
      data-icon-name="wireframe"
      height={finalHeight}
      ref={ref}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      version="1.1"
      viewBox={`0 0 ${baseWidth} ${baseHeight}`}
      width={finalWidth}
      xmlSpace="preserve">
      <path
        clipRule="evenodd"
        d="M2.18223 7.5503C2.17087 7.69902 2.16508 7.8493 2.16508 8.00094C2.16508 10.4628 3.68969
        12.5685 5.84623 13.4255C5.44863 12.6301 5.15318 11.605 4.97905 10.465C4.86976 9.74951
        4.81241 9.26084 4.79656 8.6892C4.34388 8.58233 3.92364 8.45355 3.5446 8.30547C3.02257
        8.10153 2.55301 7.85104 2.18223 7.5503ZM2.61558 5.74881C2.64746 5.82656 2.66504 5.9117
        2.66504 6.00094C2.66504 6.08226 2.70457 6.23131 2.9306 6.4401C3.15735 6.64955 3.52201
        6.86876 4.02857 7.06665C4.26594 7.15939 4.52713 7.24459 4.80893 7.32068C4.87109 5.72888
        5.14974 4.27684 5.58503 3.16264C5.66461 2.95893 5.75078 2.76304 5.84368 2.57735C4.38762
        3.15678 3.21994 4.30558 2.61558 5.74881ZM8.00008 0.835938C4.04296 0.835938 0.835083 4.04382
        0.835083 8.00094C0.835083 11.9581 4.04296 15.1659 8.00008 15.1659C11.9572 15.1659 15.1651
        11.9581 15.1651 8.00094C15.1651 7.28099 15.0589 6.58585 14.8613 5.93031C14.8515 5.83704
        14.8224 5.74951 14.778 5.67179C13.8113 2.85805 11.1418 0.835938 8.00008 0.835938ZM13.5176
        6.09747C12.7284 3.8095 10.5562 2.16594 8.00008 2.16594C7.89718 2.16594 7.72757 2.21666
        7.50009 2.46293C7.27193 2.70992 7.03588 3.10387 6.82385 3.64661C6.43541 4.64088 6.17107
        6.02222 6.13101 7.58597C6.74046 7.67144 7.4033 7.719 8.10004 7.719C8.81503 7.719 9.51269
        7.66943 10.2178 7.56173C11.305 7.39567 12.1913 7.1118 12.7855 6.77868C13.2913 6.49515
        13.4672 6.24951 13.5176 6.09747ZM6.13777 8.92859C6.16082 9.3269 6.21022 9.71696 6.2938
        10.2642C6.47123 11.4258 6.77491 12.3758 7.13328 13.015C7.51382 13.6938 7.84029 13.8359
        8.00008 13.8359C11.2227 13.8359 13.8351 11.2235 13.8351 8.00094C13.8351 7.89752 13.8324
        7.79474 13.8271 7.69265C13.7008 7.78209 13.5692 7.86411 13.4359 7.93881C12.6569 8.37552
        11.6024 8.69567 10.4186 8.87648C9.64281 8.99499 8.87782 9.049 8.10004 9.049C7.42 9.049
        6.76045 9.00725 6.13777 8.92859Z"
        fill={getColor(0)}
        fillRule="evenodd"
      />
    </svg>
  );
});
