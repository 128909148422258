// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVProceduralDatasetType, LCVType } from '@luminarycloudinternal/lcvis';

import { Vector3 } from '../../../../proto/base/base_pb';
import { DisplayProps } from '../../../../pvproto/ParaviewRpc';
import { ImportFilterSetupOptions, LcvModule, isTestOptions } from '../../types';

import { LcvFilter } from './LcvFilter';

export class LcvImportDatasetFilter extends LcvFilter {
  options: ImportFilterSetupOptions;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    options: ImportFilterSetupOptions,
    workspaceHandle: number,
    id: string,
    displayProps: DisplayProps,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'import_dataset',
        `import_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'import_dataset',
      id,
    );
    this.options = options;
    if (isTestOptions(options)) {
      this.setParam('metadata_url', LCVType.kLCVDataTypeString, options.metadataUrl);
      this.setParam('geometry_url', LCVType.kLCVDataTypeString, options.geometryUrl);
    } else {
      this.setParam('mesh_url', LCVType.kLCVDataTypeString, options.meshUrl);
      if (options.fvmParams) {
        this.setFvmParams(options.fvmParams);
      }
    }
    this.setDisplayProps(true, displayProps);
  }

  setFvmParams(fvmParams: string) {
    this.setParam('fvm_params', LCVType.kLCVDataTypeString, fvmParams);
  }
}

export enum FarFieldType {
  SPHERE, HALF_SPHERE, CYLINDER, BOX, INVALID
}

export class FarFieldOptions {
  type?: FarFieldType;
  transparent?: boolean;
  start?: Vector3;
  end?: Vector3;
  center?: Vector3;
  normal?: Vector3;
  radius?: number;
}

export class LcvFarFieldFilter extends LcvFilter {
  public options: FarFieldOptions;
  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    options: FarFieldOptions,
    workspaceHandle: number,
    id: string,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'procedural_dataset',
        'farfield',
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'farfield',
      id,
    );
    this.options = options;
    this.changeFarFieldOptions(options);
  }

  processVec3(vec: Vector3): number[] {
    const array = [vec.x, vec.y, vec.z];
    for (let i = 0; i < array.length; i += 1) {
      array[i] = array[i] || 0;
    }
    return array;
  }

  changeFarFieldOptions(options: FarFieldOptions) {
    let processedCenters = [];
    let processedNormals = [];
    let processedStarts = [];
    let processedEnds = [];
    if (options.type == null) {
      return;
    }
    switch (options.type) {
      case FarFieldType.SPHERE:
        if (options.center && options.radius) {
          processedCenters = this.processVec3(options.center);
          this.setParam('type', LCVType.kLCVDataTypeInt, LCVProceduralDatasetType.kSphere);
          this.setParam('radius', LCVType.kLCVDataTypeFloat, options.radius);
          this.setParam('center', LCVType.kLCVDataTypeFloat3, processedCenters);
          this.options = options;
        }
        break;
      case FarFieldType.HALF_SPHERE:
        if (options.radius && options.center && options.normal) {
          processedCenters = this.processVec3(options.center);
          processedNormals = this.processVec3(options.normal);
          this.setParam('type', LCVType.kLCVDataTypeInt, LCVProceduralDatasetType.kHalfSphere);
          this.setParam('radius', LCVType.kLCVDataTypeFloat, options.radius);
          this.setParam('normal', LCVType.kLCVDataTypeFloat3, processedNormals);
          this.setParam('center', LCVType.kLCVDataTypeFloat3, processedCenters);
          this.options = options;
        }
        break;
      case FarFieldType.CYLINDER:
        if (options.radius && options.start && options.end) {
          processedStarts = this.processVec3(options.start);
          processedEnds = this.processVec3(options.end);
          this.setParam('type', LCVType.kLCVDataTypeInt, LCVProceduralDatasetType.kCylinder);
          this.setParam('radius', LCVType.kLCVDataTypeFloat, options.radius);
          this.setParam('start', LCVType.kLCVDataTypeFloat3, processedStarts);
          this.setParam('end', LCVType.kLCVDataTypeFloat3, processedEnds);
          this.options = options;
        }
        break;
      case FarFieldType.BOX:
        if (options.start && options.end) {
          processedStarts = this.processVec3(options.start);
          processedEnds = this.processVec3(options.end);
          this.setParam('type', LCVType.kLCVDataTypeInt, LCVProceduralDatasetType.kBox);
          this.setParam('start', LCVType.kLCVDataTypeFloat3, processedStarts);
          this.setParam('end', LCVType.kLCVDataTypeFloat3, processedEnds);
          this.options = options;
        }
        break;
      default:
        break;
    }
  }
}
