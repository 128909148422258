// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import assert from './assert';

// A node table type that affects behavior.
// Note: If new type is added, it should be included to 1 of the volumes/surfaces tables at the end
export enum NodeTableType {
  NONE,
  // Table for volumes specifying a size for meshing.
  MESHING_SIZE,
  // Table for a physical behavior's attached particle groups.
  PHYSICAL_BEHAVIOR_ATTACH,
  // Table for point probes.
  POINTS,
  // Table for extracting subset of surfaces for vis.
  EXTRACT_BOUNDARY,
  // Tables for two sides of a sliding interface.
  SLIDING_INTERFACE_SURFACES_A,
  SLIDING_INTERFACE_SURFACES_B,
  // Table for volumes.
  VOLUMES,
  // Monitor plane volumes.
  MONITOR_PLANE_VOLUMES,
  // Table for adjoint sensitivity surfaces.
  SENSITIVITY_SURFACES,
}

export interface NodeTableIdentifier {
  type: NodeTableType;
  id?: string;
  index?: number;
}

// All node table types that are meant to select volumes
export const VOLUMES_TABLES = [
  NodeTableType.VOLUMES,
  NodeTableType.MESHING_SIZE,
];

// All node table types that are meant to select surfaces
export const SURFACES_TABLES = [
  NodeTableType.EXTRACT_BOUNDARY,
  NodeTableType.SLIDING_INTERFACE_SURFACES_A,
  NodeTableType.SLIDING_INTERFACE_SURFACES_B,
  NodeTableType.SENSITIVITY_SURFACES,
];

export const DISKS_TABLES = [
  NodeTableType.PHYSICAL_BEHAVIOR_ATTACH,
];

export const POINTS_TABLES = [
  NodeTableType.POINTS,
];

export const PLANE_AND_VOLUME_TABLES = [
  NodeTableType.MONITOR_PLANE_VOLUMES,
];

// All node table types (excluding the NONE type) must be part of some of the 4 entity tables above.
// This is to guarantee that the respective tree nodes will show in the Geometry tree when some
// NodeTable is active.
assert(Object.keys(NodeTableType).length / 2 === (
  VOLUMES_TABLES.length +
  SURFACES_TABLES.length +
  DISKS_TABLES.length +
  POINTS_TABLES.length +
  PLANE_AND_VOLUME_TABLES.length +
  1
), 'Node table type check failed');
