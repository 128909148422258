// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { callAfterAnimation } from '../../../lib/animationUtils';
import { colors, hexToRgb } from '../../../lib/designSystem';
import { CONNECTING_MESSAGE } from '../../../lib/paraviewUtils';
import { DEFAULT_FILTER_ROOT } from '../../../lib/visUtils';
import { useLcvisFilterStatusValue } from '../../../recoil/lcvis/lcvisFilterStatus';
import { createStyles, makeStyles } from '../../Theme';
import { ProgressBar } from '../../visual/ProgressBar';

const useStyles = makeStyles(() => createStyles({
  overlay: {
    backgroundColor: hexToRgb(colors.surfaceBackground, 0.5),
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    overflow: 'hidden',
  },
  dialog: {
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    position: 'absolute',
  },
  title: {
    fontSize: '14',
    fontColor: colors.highEmphasisText,
    fontWeight: 600,
    paddingBottom: '24px',
    textShadow: '0px 1px 1px #000000',
  },
}), { name: 'LcVisLoading' });

/** A Black overlay to show when lcvis is still loading the dataset. */
const LoadingOverlay = () => {
  const classes = useStyles();
  const filterStatus = useLcvisFilterStatusValue();

  // The progress bar is based on the status of the import dataset filter,
  // which is given the id of DEFAULT_FILTER_ROOT in LCVis.
  const rootStatus = filterStatus.get(DEFAULT_FILTER_ROOT)?.status;
  const progress = (() => {
    if (rootStatus === 'queued') {
      return 0;
    }
    if (rootStatus === 'completed' || rootStatus === 'error') {
      return 100;
    }
    return rootStatus ?? 0;
  })();

  // (LC-17037) This is a band-aid fix to hide the loading overlay when generating a far-field.
  // Currently generating a farfield filter in LCVis happens very quickly, so we get a 'flicker'
  // where the overlay is visible. We remove it by waiting for 100ms (+ 1 frame) before showing
  // the overlay.
  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    const cancel = callAfterAnimation(() => setShowOverlay(true), 100);
    return cancel;
  }, []);
  if (!showOverlay) {
    return <></>;
  }

  return (
    <>
      <div className={classes.overlay}>
        <div className={classes.dialog}>
          <div className={classes.title}>
            {CONNECTING_MESSAGE}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '50%' }}>
              <ProgressBar
                backgroundColor="rgb(255, 255, 255, 0.5)"
                height={5}
                progress={progress}
                transitionDuration={100}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingOverlay;
