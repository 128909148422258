// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, Suspense, forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { Badge, ClassNameMap } from '@mui/material';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import * as flags from '../../../flags';
import { CurrentView } from '../../../lib/componentTypes/context';
import { colors } from '../../../lib/designSystem';
import { parseString } from '../../../lib/html';
import { isInExploration } from '../../../lib/navigation';
import { LeveledMessage } from '../../../lib/notificationUtils';
import * as rpc from '../../../lib/rpc';
import { getSimulationParam } from '../../../lib/simulationParamUtils';
import useResizeObserver from '../../../lib/useResizeObserver';
import { isSensitivityAnalysis } from '../../../lib/workflowUtils';
import * as geometryservicepb from '../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as frontendpb from '../../../proto/frontend/frontend_pb';
import * as projectstatepb from '../../../proto/projectstate/projectstate_pb';
import { useGeometryState } from '../../../recoil/geometry/geometryState';
import { useMeshUrlState } from '../../../recoil/meshState';
import { useSelectedGeometry } from '../../../recoil/selectedGeometry';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import { useCurrentConfig } from '../../../recoil/workflowConfig';
import { useWorkflowState } from '../../../recoil/workflowState';
import { usePerTabWarnings } from '../../../state/external/project/validator';
import { DEFAULT_EXPANDED_RAIL_WIDTH, useSetAssistantSideRailWidth } from '../../../state/internal/assistant/assistantSideRailSize';
import { useIsRunStatusVisible } from '../../../state/internal/component/isRunStatusVisible';
import { useCurrentView, useIsAnalysisView, useIsExplorationSetup, useIsGeometryView } from '../../../state/internal/global/currentView';
import { ActionButton } from '../../Button/ActionButton';
import { IconButton } from '../../Button/IconButton';
import OutputChartPanel from '../../OutputChart/OutputChartPanel';
import PaneSwitcher from '../../Pane/PaneSwitcher';
import suspenseWidget from '../../SuspenseWidget';
import { createStyles, makeStyles } from '../../Theme';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { LuminaryToggleSwitch } from '../../controls/LuminaryToggleSwitch';
import { useAddNewAssistantMessage } from '../../hooks/assistant/useAddNewAssistantMessage';
import { useAssistantSend } from '../../hooks/assistant/useAssistantSend';
import { useAssistantEnabled } from '../../hooks/useAssistant';
import { AlertIcon } from '../../notification/AlertIcon';
import { RunStatus } from '../../project/RunStatus';
import { CaretDownIcon } from '../../svg/CaretDownIcon';
import { ChartLineIcon } from '../../svg/ChartLineIcon';
import { SparkleDoubleIcon } from '../../svg/SparkleDoubleIcon';
import { LoadingEllipsis } from '../../visual/LoadingEllipsis';
import { Resizable } from '../Resizable';

const defaultBackground = colors.surfaceDark1;

const useBadgeStyles = makeStyles(
  () => createStyles({
    badge: {
      background: colors.red500,
      fontSize: '9px',
      height: '15px',
      minWidth: '15px',
      width: '15px',
      position: 'absolute',
      top: '-5px',
      right: '-7px',
    },
  }),
  { name: 'FooterBadge' },
);

export const SPLITTER_HEIGHT = 5;

const useStyles = makeStyles(
  () => createStyles({
    alertsBar: {
      width: '100%',
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      padding: '0 12px 1px 10px',
      fontSize: '12px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: defaultBackground,
      // must be higher than the zIndex of the Resizable component
      zIndex: 200,
      boxShadow: 'inset 0 -1px 0 0 var(--color-neutral-50)',
      '--toggle-rotation': '0deg',
    },
    splitter: {
      height: `${SPLITTER_HEIGHT}px`,
      width: '100%',
    },
    resizableContent: {
      width: '100%',
      height: '100%',
      flex: '0 0 auto',
      backgroundColor: defaultBackground,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    tabContent: {
      backgroundColor: defaultBackground,
      overflow: 'auto',
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      fontSize: '13px',
      '&.noPadding': {
        padding: '0px',
      },
    },
    contentWrapper: {
      width: '100%',
      height: '100%',
    },
    contentWrappeFlex: {
      display: 'flex',
    },

    // TODO: Move the below classes to a separate component
    messageAndAction: {
      display: 'flex',
      gap: '16px',
    },
    message: {
      display: 'flex',
      gap: '4px',
      alignItems: 'baseline',
      cursor: 'pointer',
      paddingLeft: '4px',
      lineHeight: '22px',

      '&:hover': {
        textDecoration: 'underline',
        '& $autoFix': {
          opacity: 1,
        },
      },
      '&.active': {
        backgroundColor: colors.purple500,
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: colors.purple600,
        },
      },
    },
    alertIcon: {
      position: 'relative',
      top: '1px',
    },
    autoFix: {
      transition: 'opacity 250ms',
      opacity: 0,
    },
    dropdownWrapper: {
      alignContent: 'center',
      paddingBottom: '4px',
      whiteSpace: 'nowrap',

    },
    dropdownText: {
      fontSize: '13px',
      marginLeft: '8px',
    },
    toggle: {
      color: 'var(--header-caret-color)',
      flex: '0 0 auto',
      display: 'inline-flex',
      transform: 'rotate(var(--toggle-rotation))',
      transition: 'transform 250ms, color 500ms',
    },
    toggleButton: {
      marginBottom: '4px',
      padding: '2px',
      height: '24px',
      width: '24px',
    },
    collapsed: {
      '--toggle-rotation': '-90deg',
    },
    tabSplitter: {
      height: 'calc(100% + 7px)',
      width: '1px',
      position: 'relative',
      top: '-3px',
      backgroundColor: 'var(--color-neutral-50)',
    },
    codeContainer: {
      height: 'auto',
      overflowY: 'auto',
      width: '100%',
    },
    simWarnings: {
      padding: '12px 20px',
    },
  }),

  { name: 'FooterBase' },
);

type InfoFooterItem = {
  title: ReactNode;
  icon: ReactNode;
  content: ReactNode;
  // if true, the content will have its own padding, otherwise,
  // the info footer will provide padding
  customPadding?: boolean;
  key: string;
  // Adds a button to the right of the title.
  titleButton?: ReactNode;
};

type InfoFooterProps = {
  items: InfoFooterItem[];
  initialOpenTabIndex?: number;
};

/**
 * Things we need
 * - Alerts bar at the very bottom. This has a fixed height and displays small icons or text
 * - When an alert is clicked, a new panel opens up in the footer with more information
 *
 * The footer is resizable
 * - The splitter is at the top of the footer. Double-clicking the splitter will open or close it
 * - The footer has 'tabs' that can be clicked to open different panels
 * - Each tab has a title, and a content area which can be structured however we want
 * - Each tab also has a related icon which is displayed in the alerts bar.
 *    - Clicking the icon will open the tab related to it.
 */

const DEFAULT_OPEN_SIZE = '30%';
const COLLAPSE_THRESHOLD = 80;
export const DEFAULT_CLOSE_SIZE = '35px';

const CopyButton = ({ textToCopy }: { textToCopy: string; }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }).catch((err) => {
      console.error('Failed to copy', err);
    });
  };

  return (
    <button
      onClick={handleCopy}
      style={{
        alignSelf: 'center',
        top: '8px',
        right: '8px',
        background: copied ? 'rgba(46, 160, 67, 0.2)' : 'rgba(255, 255, 255, 0.1)',
        border: 'none',
        borderRadius: '4px',
        color: copied ? '#2ea043' : '#8b949e',
        padding: '4px 8px',
        fontSize: '12px',
        cursor: 'pointer',
        zIndex: 1,
      }}
      title="Copy to clipboard"
      type="button">
      {copied ? 'Copied!' : 'Copy'}
    </button>
  );
};

function generateSdkCodeFooter(
  name: string,
  loading: boolean,
  sdkCode: string,
  badgeClasses: ClassNameMap<string>,
  containerClasses: string,

): InfoFooterItem {
  return {
    titleButton: <CopyButton textToCopy={sdkCode} />,
    title: 'Geometry SDK Code',
    icon: (
      <Badge badgeContent={0} classes={badgeClasses}>
        {loading ? `${name} SDK Code Loading...` : `${name} SDK Code`}
      </Badge>
    ),
    content: (
      <ReactMarkdown
        className={containerClasses}
        components={{
          code: ({ children }) => (
            <SyntaxHighlighter language="python" style={a11yDark}>
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ),
        }}>
        {`\`\`\`python\n${sdkCode}\n\`\`\``}
      </ReactMarkdown>
    ),
    key: `${name}SdkCode`,
  };
}

export const FooterBase = forwardRef((props: InfoFooterProps, ref) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Props
  const { items, initialOpenTabIndex = 0 } = props;

  // == Hooks
  const classes = useStyles();
  const [
    isRunStatusVisible,
    setIsRunStatusVisible,
  ] = useIsRunStatusVisible({ projectId, workflowId, jobId });
  const workflow = useWorkflowState(projectId, workflowId);
  const isExplorationSetup = useIsExplorationSetup();

  const job = workflow?.job[jobId];

  // == State
  const currentView = useCurrentView();
  const resizableContentRef = useRef<HTMLDivElement>(null);
  const resizableContentSize = useResizeObserver(resizableContentRef);
  const boundingRef = ref as React.MutableRefObject<HTMLDivElement | null>;
  const [collapsed, setCollapsed] = useState(currentView !== CurrentView.ANALYSIS);
  const [windowHeight, setWindowHeight] = useState(
    collapsed ? DEFAULT_CLOSE_SIZE : DEFAULT_OPEN_SIZE,
  );
  const [activeTab, setActiveTab] = useState(initialOpenTabIndex);

  const handleAlertClick = (index: number) => {
    if (collapsed) {
      // If the footer was expanded, and then was collapsed through dragging, the windowHeight
      // would still be the height as if it was expanded. So if a button is clicked at this point
      // to expand the footer, it won't expand unless we set the height to a slightly diff value.
      if (windowHeight === DEFAULT_OPEN_SIZE) {
        setWindowHeight('31%');
      } else {
        setWindowHeight(DEFAULT_OPEN_SIZE);
      }
    } else if (activeTab === index) {
      // The same hack as below. But here the problem is if the footer was collapsed and then was
      // expanded through dragging. So if we click the active button with the intent to collapse,
      // we have to provide a slightly different value for the collapsed state (to trigger rerender)
      if (windowHeight === DEFAULT_CLOSE_SIZE) {
        setWindowHeight('37px');
      } else {
        setWindowHeight(DEFAULT_CLOSE_SIZE);
      }
    }
    setActiveTab(index);
  };

  const buttons = (
    <div className={cx(
      classes.alertsBar,
      { [classes.collapsed]: collapsed },
    )}>

      <IconButton
        className={classes.toggleButton}
        onClick={() => {
          handleAlertClick(activeTab);
        }}>
        <span className={classes.toggle}> <CaretDownIcon maxWidth={6} /></span>

      </IconButton>

      {job && !isExplorationSetup && (
        <div className={classes.dropdownWrapper}>
          <LuminaryToggleSwitch
            onChange={() => setIsRunStatusVisible((oldValue) => !oldValue)}
            small
            value={isRunStatusVisible}
          />
          <span className={classes.dropdownText}>Run Status</span>
        </div>
      )}
      <div className={classes.tabSplitter} />
      <PaneSwitcher
        buttons={items.map((item, index) => ({
          text: item.icon,
          selected: activeTab === index,
          onClick: () => {
            handleAlertClick(index);
          },
          key: item.key,
          icon: item.titleButton,
        }))}
        footerSwitcher
      />
    </div>
  );

  useEffect(() => {
    if (resizableContentSize.height > COLLAPSE_THRESHOLD) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [resizableContentSize.height, setCollapsed]);

  const item = items.at(activeTab) ?? items.at(0);

  return (
    <>
      <Resizable
        clickSettings={{
          popOpenSize: DEFAULT_OPEN_SIZE,
          openOnClick: true,
          closeOnClick: true,
        }}
        collapseThreshold={COLLAPSE_THRESHOLD}
        getDragInputs={() => ({ boundingNode: boundingRef.current ?? undefined })}
        initialSize={windowHeight}
        maxSize={700}
        minSize={parseInt(DEFAULT_CLOSE_SIZE, 10)}
        splitterContent={<div className={classes.splitter} />}
        splitterPlacement="top"
        // higher zIndex needed for tooltip to be visible above main page
        zIndex={100}
        zIndexSplitter={100}>
        <div className={classes.resizableContent} ref={resizableContentRef}>
          {buttons}
          {!collapsed && (
            <div className={cx(classes.tabContent, { noPadding: item?.customPadding })}>
              <div className={cx(
                classes.contentWrapper,
                { [classes.contentWrappeFlex]: isRunStatusVisible },
              )}>
                <RunStatus />
                {item?.content}
              </div>
            </div>
          )}
        </div>
      </Resizable>
    </>
  );
});

type SdkCodeType = 'geometry' | 'mesh' | 'simulation';

// Reusable hooks that fetch the SDK code for a given resource type.
function useSdkCodeFetcher(
  type: SdkCodeType,
): {
  sdkCode: string;
  loading: boolean;
} {
  const { projectId, workflowId, jobId, geometryId: geometryIdIn } = useProjectContext();
  const isGeometryView = useIsGeometryView();
  const [selectedGeometry] = useSelectedGeometry(projectId, workflowId, jobId);
  const geometryState = useGeometryState(projectId, geometryIdIn);
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const sdkCodeGenerationEnabled = useIsEnabled(flags.sdkCodeGeneration);
  const [loading, setLoading] = useState(true);
  const [sdkCode, setSdkCode] = useState('');
  const lastIdRef = useRef<string>('');
  const geometryId = isGeometryView ? geometryIdIn : selectedGeometry.geometryId;
  const configMeshId = getSimulationParam(config).input?.meshIdentifier?.id || '';

  const representativeId = useMemo(() => {
    switch (type) {
      case 'mesh':
        return configMeshId;
      case 'geometry': {
        if (isGeometryView) {
          const hist = geometryState?.geometryHistory;
          if (!hist || hist.length === 0) {
            return '';
          }
          return hist[hist.length - 1].historyEntry?.geometryVersionNewId || '';
        }
        return selectedGeometry.geometryVersionId;
      }
      case 'simulation': {
        return jobId;
      }
      default:
        throw new Error(`Unknown SDK code type: ${type}`);
    }
  }, [configMeshId, geometryState?.geometryHistory, isGeometryView, jobId,
    selectedGeometry.geometryVersionId, type]);

  useEffect(() => {
    if (!representativeId || !sdkCodeGenerationEnabled) {
      return;
    }

    const currentId = representativeId;
    if (currentId === lastIdRef.current) {
      return;
    }

    lastIdRef.current = currentId;
    setLoading(true);

    let fetchPromise: Promise<
      geometryservicepb.GetSdkCodeResponse |
      frontendpb.GetMeshGenerationSdkCodeReply |
      frontendpb.GetSimulationSdkCodeReply
    >;

    switch (type) {
      case 'geometry': {
        const geometryReq = new geometryservicepb.GetSdkCodeRequest({
          geometryId,
          geometryVersionId: representativeId,
        });
        fetchPromise = rpc.callRetryWithClient(
          rpc.clientGeometry!,
          'getSdkCode',
          rpc.clientGeometry!.getSdkCode,
          geometryReq,
          false,
        );
        break;
      }
      case 'mesh': {
        const meshReq = new frontendpb.GetMeshGenerationSdkCodeRequest({
          meshId: configMeshId,
        });
        fetchPromise = rpc.callRetry(
          'getMeshGenerationSdkCode',
          rpc.client.getMeshGenerationSdkCode,
          meshReq,
          false,
        );
        break;
      }
      case 'simulation': {
        const simulationReq = new frontendpb.GetSimulationSdkCodeRequest({
          projectId,
          jobId,
        });
        fetchPromise = rpc.callRetry(
          'getSimulationSdkCode',
          rpc.client.getSimulationSdkCode,
          simulationReq,
          false,
        );
        break;
      }
      default:
        fetchPromise = Promise.reject(new Error(`Unknown SDK code type: ${type}`));
    }

    fetchPromise
      .then((res) => {
        // Make sure the id hasn't changed since the request was made.
        if (currentId === lastIdRef.current) {
          setSdkCode(res.sdkCode);
        }
      })
      .catch((err) => {
        console.error(`Failed to fetch the ${type} SDK code:`, err);
        setSdkCode(`Failed to fetch the ${type} SDK code`);
      })
      .finally(() => setLoading(false));
  }, [type, sdkCodeGenerationEnabled, representativeId, geometryId, configMeshId, projectId,
    jobId]);

  return { sdkCode, loading };
}

export const InfoFooter = forwardRef((props, ref) => {
  // == Contexts
  const { projectId, workflowId, jobId, geometryId: geometryIdIn } = useProjectContext();
  const { setSelection, setScrollTo, selectedNodeIds } = useSelectionContext();

  // == Hooks
  const classes = useStyles();
  const badgeClasses = useBadgeStyles();
  const location = useLocation();

  // == State
  const assistantEnabled = useAssistantEnabled();
  const { disabled: assistantSendDisabled } = useAssistantSend();
  const addNewAssistantMessage = useAddNewAssistantMessage(projectId);
  const setAssistantSideRailWidth = useSetAssistantSideRailWidth();
  const selectedSet = useMemo(() => new Set(selectedNodeIds), [selectedNodeIds]);
  const isAnalysisView = useIsAnalysisView();
  const workflow = useWorkflowState(projectId, workflowId);
  const isExploration = isInExploration(location.pathname);
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);
  const sdkCodeGenerationEnabled = useIsEnabled(flags.sdkCodeGeneration);
  const isGeometryView = useIsGeometryView();
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const [selectedGeometry] = useSelectedGeometry(projectId, workflowId, jobId);
  const geometryId = isGeometryView ? geometryIdIn : selectedGeometry.geometryId;
  const [meshUrl] = useMeshUrlState(projectId);
  const configMeshId = getSimulationParam(config).input?.meshIdentifier?.id || '';

  const { sdkCode: geometrySdkCode, loading: geometryCodeLoading } = useSdkCodeFetcher('geometry');
  const { sdkCode: meshSdkCode, loading: meshingCodeLoading } = useSdkCodeFetcher('mesh');
  const { sdkCode: simulationSdkCode, loading: simulationCodeLoading } = useSdkCodeFetcher(
    'simulation',
  );

  // Mesh ID for which we want to fetch the SDK code for.
  const meshId = useMemo(() => {
    if (jobId) {
      return configMeshId;
    }
    return meshUrl.activeType === projectstatepb.UrlType.MESH ? meshUrl.meshId : '';
  }, [configMeshId, jobId, meshUrl.activeType, meshUrl.meshId]);

  const showOutputPanel = isAnalysisView && !isSensitivity;

  const warnings = usePerTabWarnings(projectId, workflowId, jobId);
  const simWarnings: { id: string; message: LeveledMessage; }[] = [];
  warnings.forEach((value, key) => {
    value.forEach((message) => {
      simWarnings.push({ id: key, message });
    });
  });

  const handleMessageSelect = (id: string, message: LeveledMessage) => {
    setSelection([id]);
    setScrollTo({
      node: id,
      fast: true,
    });
  };

  const simWarningsContent = (
    simWarnings.map(({ id, message }) => (
      <div
        className={cx(classes.message, { active: selectedSet.has(id) })}
        key={message.message}
        onClick={() => {
          handleMessageSelect(id, message);
        }}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter' || ev.key === ' ') {
            handleMessageSelect(id, message);
          }
        }}
        role="button"
        tabIndex={0}>
        <div className={classes.alertIcon}>
          <AlertIcon level={message.level} />
        </div>
        <div className={classes.messageAndAction}>
          {parseString(message.message)}
          {assistantEnabled && (
            <div className={classes.autoFix}>
              <ActionButton
                compact
                disabled={assistantSendDisabled}
                kind="secondary"
                onClick={(event) => {
                  // Do not propagate, because we don't want to highlight the row when the button
                  // is clicked
                  event.stopPropagation();
                  setAssistantSideRailWidth(DEFAULT_EXPANDED_RAIL_WIDTH);
                  addNewAssistantMessage(
                    `I encountered an error: "${message.message}". Can you fix it?`,
                  );
                }}
                size="small"
                title={assistantSendDisabled ? (
                  <>Assistant is busy<LoadingEllipsis /></>
                ) : ''}>
                <SparkleDoubleIcon color={colors.primaryInteractive} maxHeight={12} maxWidth={12} />
                Auto Fix
              </ActionButton>
            </div>
          )}
        </div>
      </div>
    ))
  );

  let initialOpenTabIndex = 0;

  const baseProps: InfoFooterItem[] = [];
  if (!isGeometryView) {
    baseProps.push({
      title: <>Simulation Issues ({simWarnings.length})</>,
      icon: (
        <Badge badgeContent={simWarnings.length} classes={badgeClasses}>
          Simulation Issues
        </Badge>
      ),
      content: (
        <div className={classes.simWarnings}>
          {simWarningsContent}
        </div>
      ),
      key: 'simulationIssues',
    });
  }

  // In the geometry view we display the SDK code that allows to reconstruct the geometry.
  if (geometryId && sdkCodeGenerationEnabled) {
    baseProps.push(
      generateSdkCodeFooter(
        'Geometry',
        geometryCodeLoading,
        geometrySdkCode,
        badgeClasses,
        classes.codeContainer,
      ),
    );
  }
  if (!isGeometryView && meshId && sdkCodeGenerationEnabled) {
    baseProps.push(
      generateSdkCodeFooter(
        'Mesh Generation',
        meshingCodeLoading,
        meshSdkCode,
        badgeClasses,
        classes.codeContainer,
      ),
    );
  }
  if (isAnalysisView && sdkCodeGenerationEnabled) {
    baseProps.push(
      generateSdkCodeFooter(
        'Simulation',
        simulationCodeLoading,
        simulationSdkCode,
        badgeClasses,
        classes.codeContainer,
      ),
    );
  }

  if (showOutputPanel) {
    initialOpenTabIndex = 1;
    baseProps.push({
      title: 'Output Chart',
      icon: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <ChartLineIcon color={colors.purple800} maxHeight={12} maxWidth={12} />
          Output Chart
        </div>
      ),
      content: (
        <Suspense fallback={suspenseWidget}>
          <OutputChartPanel />
        </Suspense>
      ),
      customPadding: true,
      key: 'outputChart',
    });
  }

  return <FooterBase initialOpenTabIndex={initialOpenTabIndex} items={baseProps} ref={ref} />;
});
