// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/api/v0/luminarycloud/solution/solution.proto (package luminary.proto.api.v0.luminarycloud.solution, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { File } from "../common/common_pb.js";

/**
 * Represents a solution for some iteration of a simulation.
 *
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.Solution
 */
export const Solution = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.Solution",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "simulation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "iteration", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "physical_time", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.ListSolutionsRequest
 */
export const ListSolutionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.ListSolutionsRequest",
  () => [
    { no: 1, name: "simulation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.ListSolutionsResponse
 */
export const ListSolutionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.ListSolutionsResponse",
  () => [
    { no: 1, name: "solutions", kind: "message", T: Solution, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.GetSolutionSurfaceDataRequest
 */
export const GetSolutionSurfaceDataRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.GetSolutionSurfaceDataRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.GetSolutionSurfaceDataResponse
 */
export const GetSolutionSurfaceDataResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.GetSolutionSurfaceDataResponse",
  () => [
    { no: 1, name: "file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.GetSolutionVolumeDataRequest
 */
export const GetSolutionVolumeDataRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.GetSolutionVolumeDataRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.GetSolutionVolumeDataResponse
 */
export const GetSolutionVolumeDataResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.GetSolutionVolumeDataResponse",
  () => [
    { no: 1, name: "file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.GetSurfaceDeformationTemplateRequest
 */
export const GetSurfaceDeformationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.GetSurfaceDeformationTemplateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.GetSurfaceDeformationTemplateResponse
 */
export const GetSurfaceDeformationTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.GetSurfaceDeformationTemplateResponse",
  () => [
    { no: 1, name: "file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.GetSurfaceSensitivityDataRequest
 */
export const GetSurfaceSensitivityDataRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.GetSurfaceSensitivityDataRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.solution.GetSurfaceSensitivityDataResponse
 */
export const GetSurfaceSensitivityDataResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.solution.GetSurfaceSensitivityDataResponse",
  () => [
    { no: 1, name: "file", kind: "message", T: File },
  ],
);

