// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { getNonSlipWallSurfaces, getWallSurfacesWithTags } from '../../../../lib/boundaryConditionUtils';
import { validateGtZeroInt, validateGteOne, validateGteZero } from '../../../../lib/inputValidationUtils';
import {
  BOUNDARY_ID,
  COMMON_START_ICON,
  assignSurfaceToBoundaryLayer,
  boundaryHeading,
  conflictingMeshBoundaryLayerSurfaces,
  nullableMeshing,
} from '../../../../lib/mesh';
import { DEFAULT_BL } from '../../../../lib/paramDefaults/meshingMultiPartState';
import { NodeType, TAGS_NODE_TYPES } from '../../../../lib/simulationTree/node';
import { defaultBoundaryLayerParams } from '../../../../lib/simulationUtils';
import { wordsToList } from '../../../../lib/text';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import { useEntityGroupData } from '../../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { useSimulationTreeSubselect } from '../../../../recoil/simulationTreeSubselect';
import { useMeshReadOnly } from '../../../../recoil/useMeshReadOnly';
import { useMeshSurfaces } from '../../../../recoil/useMeshSurfaces';
import { WarningLocation, useMeshValidator } from '../../../../recoil/useMeshValidator';
import useMeshMultiPart, { useSetMeshMultiPart } from '../../../../recoil/useMeshingMultiPart';
import { pushConfirmation, useSetConfirmations } from '../../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../../Button/ActionButton';
import { IconButton } from '../../../Button/IconButton';
import Form from '../../../Form';
import { DataField } from '../../../Form/DataSelect/DataField';
import { NumberField } from '../../../Form/NumberField';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useMeshingBoundaryLayerSelection } from '../../../hooks/subselect/useMeshingBoundaryLayerSelection';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { SectionMessage } from '../../../notification/SectionMessage';
import { ArrowUpRightIcon } from '../../../svg/ArrowUpRightIcon';
import { ResetIcon } from '../../../svg/ResetIcon';
import { TrashIcon } from '../../../svg/TrashIcon';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';
import { CustomCount } from '../shared/CustomCount';

type BoundaryParams = meshgenerationpb.MeshingMultiPart_BoundaryLayerParams;
const SelectionType = meshgenerationpb.MeshingMultiPart_BoundaryLayerParams_SelectionType;
const ComplexityType = meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType;

interface SelectionChange {
  selection: string[],
  type: meshgenerationpb.MeshingMultiPart_BoundaryLayerParams_SelectionType,
  overlapIndices: number[],
}

interface MeshBoundaryParamsProps {
  // The index of the boundary layer params this is displaying.
  boundaryIndex: number;
  // If the panel contains inputs rather than constant values.
  isInput: boolean;
  // Set a pending selection that must be confirmed before it is applied.
  setPendingChange?: (selectionChange: SelectionChange) => void;
}

const NODE_SUBSELECT_ID_PREFIX = 'meshing-bl-';

// A panel displaying a single BoundaryParams.
export const MeshBoundaryParams = (props: MeshBoundaryParamsProps) => {
  const { boundaryIndex, isInput } = props;
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode } = useSelectionContext();
  const { setSelection, setScrollTo } = useSelectionContext();
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const { simParam } = useSimulationConfig();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const boundary = meshMultiPart?.blParams[boundaryIndex];
  const {
    disabledLevel,
    disabledReason,
    warningLocations,
  } = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const meshSurfaces = useMeshSurfaces(projectId, workflowId, jobId);
  const meshReadOnly = useMeshReadOnly(projectId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const {
    nodeFilter,
    setSurfaces,
    selectedSurfaces,
  } = useMeshingBoundaryLayerSelection({
    boundaryIndex,
  });
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const treeSubselect = useSimulationTreeSubselect();

  const disabled = readOnly || meshReadOnly;
  const showWarning = isInput && warningLocations.includes(WarningLocation.BL_SIZE);

  if (!boundary) {
    return null;
  }
  if (isInput && !props.setPendingChange) {
    throw new Error('setPendingChange expected for an input.');
  }
  const updateBoundary = (diff: Partial<BoundaryParams>) => {
    const newMeshMultiPart = meshMultiPart.clone();
    const newBoundary = new meshgenerationpb.MeshingMultiPart_BoundaryLayerParams({
      ...boundary,
      ...diff,
    });
    newMeshMultiPart.blParams[boundaryIndex] = newBoundary;
    setMeshMultiPart(newMeshMultiPart);
  };
  const selectionOptions = [
    {
      name: 'All Surfaces',
      value: SelectionType.ALL,
      selected: boundary.selection === SelectionType.ALL,
    },
    {
      name: 'All Non-Slip Wall Surfaces',
      value: SelectionType.WALL_NO_SLIP,
      selected: boundary.selection === SelectionType.WALL_NO_SLIP,
    },
    {
      name: 'Selected Surfaces',
      value: SelectionType.SELECTED,
      selected: boundary.selection === SelectionType.SELECTED,
    },
    {
      name: 'No Surfaces',
      value: SelectionType.NONE,
      selected: boundary.selection === SelectionType.NONE,
    },
  ];

  const isDefault = (boundaryIndex === 0);
  const headerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMeshMultiPart((oldMeshMultiPart: nullableMeshing) => {
      if (!oldMeshMultiPart) {
        return null;
      }
      const newMeshMultiPart = meshMultiPart.clone();
      if (isDefault) {
        // The default has a reset button that resets everything.
        newMeshMultiPart.blParams = [defaultBoundaryLayerParams(simParam, geometryTags)];
      } else {
        // The other sections have a trash button that delete this particular boundary layer.
        newMeshMultiPart.blParams.splice(boundaryIndex, 1);
      }
      return newMeshMultiPart;
    });
  };
  const editClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelection([BOUNDARY_ID]);
    setScrollTo({ node: BOUNDARY_ID });
  };
  const headerButton = isInput ? (
    <IconButton
      disabled={disabled}
      onClick={headerClick}>
      {isDefault ? (
        <ResetIcon maxHeight={13} />
      ) : (
        <TrashIcon maxHeight={13} />
      )}
    </IconButton>
  ) : (
    <ActionButton kind="minimal" onClick={editClick} size="small">
      {disabled ? 'View' : 'Edit'}
      <ArrowUpRightIcon maxHeight={9} />
    </ActionButton>
  );

  const changeAppliesTo = (newValue: number) => {
    let newSelection: string[] = [];
    switch (newValue) {
      case SelectionType.ALL:
        newSelection = meshSurfaces;
        break;
      case SelectionType.WALL_NO_SLIP:
        newSelection = getNonSlipWallSurfaces(simParam, geometryTags);
        break;
      case SelectionType.SELECTED:
        newSelection = meshMultiPart!.blParams[0].surfaces;
        break;
      case SelectionType.NONE:
      default:
        break;
    }

    // Find all other meshing boundary layers (by index) whose surfaces include one or more member
    // of `newSelection`
    const overlapIndices = conflictingMeshBoundaryLayerSurfaces(
      boundaryIndex,
      newSelection,
      meshMultiPart.blParams,
      geometryTags,
      entityGroupData,
    );

    // Require the change to be confirmed if there is some overlap with other volumes.
    if (overlapIndices.length) {
      props.setPendingChange!({ selection: newSelection, type: newValue, overlapIndices });
    } else {
      setMeshMultiPart((currentMultiPart) => {
        if (!currentMultiPart) {
          return null;
        }

        const updatedMultiPart = currentMultiPart.clone();
        updatedMultiPart.blParams[boundaryIndex].selection = newValue;

        assignSurfaceToBoundaryLayer(
          updatedMultiPart.blParams,
          boundaryIndex,
          newSelection,
          geometryTags,
          entityGroupData,
        );

        return updatedMultiPart;
      });
    }
  };
  const showNodeTable = (
    isInput && (!isDefault || boundary.selection === SelectionType.SELECTED)
  );
  const hasWalls = !!getWallSurfacesWithTags(simParam, geometryTags).length;
  const isMinimal = meshMultiPart?.complexityParams?.type === ComplexityType.MIN;

  let appliesToHelp = hasWalls ?
    '' :
    'Setting up Wall Boundary Conditions before Mesh Generation is recommended.';
  let appliesToHelpLevel: 'warning' | 'error' = 'warning';

  if (boundary.selection === SelectionType.WALL_NO_SLIP && !isMinimal) {
    appliesToHelp = 'Wall Boundary Conditions must be set up before generating the mesh.';
    appliesToHelpLevel = 'error';
  }

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        disabled={treeSubselect.id.startsWith(NODE_SUBSELECT_ID_PREFIX)}
        headerRight={headerButton}
        heading={boundaryHeading(boundaryIndex)}
        nodeId={`${BOUNDARY_ID}-${boundaryIndex}`}
        panelName="main">
        <Form.LabeledInput label="Number of Layers">
          <NumberField
            asBlock
            disabled={disabled}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateBoundary({ nLayers: newValue });
            }}
            readOnly={disabled}
            validate={validateGtZeroInt}
            value={boundary.nLayers}
          />
        </Form.LabeledInput>
        <Form.LabeledInput label="Initial Size">
          <NumberField
            asBlock
            disabled={disabled}
            faultType={warningLocations.includes(WarningLocation.BL_SIZE) ?
              'warning' : undefined}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateBoundary({ initialSize: newValue });
            }}
            quantity={QuantityType.LENGTH}
            readOnly={disabled}
            validate={validateGteZero}
            value={boundary.initialSize}
          />
        </Form.LabeledInput>
        {showWarning && (
          <div style={{ marginTop: '8px' }}>
            <SectionMessage level={disabledLevel}>
              {disabledReason}
            </SectionMessage>
          </div>
        )}
        <Form.LabeledInput label="Growth Rate">
          <NumberField
            asBlock
            disabled={disabled}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateBoundary({ growthRate: newValue });
            }}
            readOnly={disabled}
            validate={validateGteOne}
            value={boundary.growthRate}
          />
        </Form.LabeledInput>
        {isDefault && (
          <Form.LabeledInput
            faultType={appliesToHelp ? appliesToHelpLevel : undefined}
            help={appliesToHelp}
            label="Applies to">
            <DataField
              asBlock
              disabled={disabled}
              isInput={isInput}
              onChange={changeAppliesTo}
              options={selectionOptions}
              size="small"
            />
          </Form.LabeledInput>
        )}
        {showNodeTable && (
          <div style={{ paddingTop: '8px' }}>
            <NodeSubselect
              id={`${NODE_SUBSELECT_ID_PREFIX}${boundaryIndex}`}
              independentSelection
              labels={['surfaces']}
              nodeFilter={nodeFilter}
              nodeIds={selectedSurfaces}
              onChange={setSurfaces}
              readOnly={disabled}
              referenceNodeIds={[selectedNode?.id || '']}
              title="Surfaces"
              visibleTreeNodeTypes={[NodeType.SURFACE, NodeType.SURFACE_GROUP, ...TAGS_NODE_TYPES]}
            />
          </div>
        )}
        {!isInput && <CustomCount count={meshMultiPart.blParams.length - 1} />}
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};

// A panel for displaying the mesh boundary layer parameters.
export const MeshBoundaryPropPanel = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const setConfirmStack = useSetConfirmations();
  const meshReadOnly = useMeshReadOnly(projectId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  const disabled = readOnly || meshReadOnly;

  if (!meshMultiPart) {
    return null;
  }

  const confirmChange = (pendingChange: SelectionChange) => {
    const headings = pendingChange.overlapIndices.map((idx) => boundaryHeading(idx));
    pushConfirmation(setConfirmStack, {
      onContinue: () => {
        setMeshMultiPart((oldMeshMultiPart) => {
          const newMeshMultiPart = oldMeshMultiPart!.clone();
          newMeshMultiPart.blParams[0].selection = pendingChange.type;

          assignSurfaceToBoundaryLayer(
            newMeshMultiPart.blParams,
            0,
            pendingChange.selection,
            geometryTags,
            entityGroupData,
          );

          return newMeshMultiPart;
        });
      },
      subtitle: `This will remove some surfaces from ${wordsToList(headings)}.
        Do you wish to continue?`,
      title: 'Confirm',
    });
  };

  const addCustomMeshSize = () => {
    setMeshMultiPart((oldMeshMultiPart: nullableMeshing) => {
      if (!oldMeshMultiPart) {
        return null;
      }
      const newMeshMultiPart = oldMeshMultiPart.clone();
      newMeshMultiPart.blParams.push(new meshgenerationpb.MeshingMultiPart_BoundaryLayerParams({
        ...DEFAULT_BL,
        selection: SelectionType.SELECTED,
      }));
      return newMeshMultiPart;
    });
  };

  const boundaryList = [...Array(meshMultiPart.blParams.length).keys()].map((index) => (
    <React.Fragment key={index}>
      <MeshBoundaryParams
        boundaryIndex={index}
        isInput
        key={index}
        setPendingChange={confirmChange}
      />
      <Divider />
    </React.Fragment>
  ));

  return (
    <div>
      {boundaryList}
      <PropertiesSection>
        <ActionButton
          disabled={disabled}
          kind="secondary"
          onClick={addCustomMeshSize}
          size="small"
          startIcon={COMMON_START_ICON}>
          Custom Boundary Layer
        </ActionButton>
      </PropertiesSection>
    </div>
  );
};
