// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useState } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { lcvHandler } from '../../../lib/lcvis/handler/LcvHandler';
import { assembleMenuSections } from '../../../lib/menuUtil';
import { Logger } from '../../../lib/observability/logs';
import { deleteTreeNodeMenuItem, visibilityToggleTreeNodeMenuItem } from '../../../lib/treeUtils';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useIsGeometryPending } from '../../../recoil/pendingWorkOrders';
import { useCadModifier } from '../../../recoil/useCadModifier';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { TreeRow } from '../TreeRow';

const logger = new Logger('treePanel/FarField');

const PRIMARY_ICON: IconSpec = { name: 'cubeOutline' };

export const FarFieldTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { projectId } = useProjectContext();
  const { paraviewClientState } = useParaviewContext();

  // == Recoil
  const [cadModifier] = useCadModifier(projectId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const isGeometryPending = useIsGeometryPending(projectId);

  // == State
  const [isVisible, setIsVisible] = useState<boolean>(true);

  // == Hooks
  const { canDelete, deleteFarfieldNode, postDeleteNodeIds } = useNodeDeletion();

  // == Data
  // If a get geometry work order is pending, we are showing the far field
  // imposter and we need to enable a way to control the visibility.
  const hasImposter = isGeometryPending && cadModifier;

  const toggleVisibility = useCallback(() => {
    const want = !isVisible;
    if (lcvisEnabled) {
      lcvHandler.queueDisplayFunction('set far field visibility', (display) => {
        display.workspace?.setTransientFarFieldVisibility(want).catch(
          (error) => console.warn(error),
        );
      });
    } else if (paraviewClientState.client) {
      ParaviewRpc.displayfarfieldpreview(
        paraviewClientState.client,
        want,
      ).catch((reason: any) => {
        logger.error('Failed to set farfield display: ', reason);
      });
    }
    setIsVisible(want);
  }, [lcvisEnabled, paraviewClientState.client, isVisible]);

  const deleteRow = useCallback(() => {
    if (deleteFarfieldNode()) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteFarfieldNode, node.id, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => {
    const deleteDisabled = !canDelete(node.type, node.id);
    return assembleMenuSections(
      [visibilityToggleTreeNodeMenuItem(isVisible, toggleVisibility, !hasImposter)],
      [deleteTreeNodeMenuItem(deleteRow, deleteDisabled)],
    );
  }, [hasImposter, isVisible, toggleVisibility, deleteRow, canDelete, node.id, node.type]);

  const visButton = (
    <VisibilityButton disabled={!hasImposter} isVisible={isVisible} onClick={toggleVisibility} />
  );

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      propertiesControl
      visibilityButton={visButton}
    />
  );
};
