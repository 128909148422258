import React from 'react';

import { colors } from '../lib/designSystem';

import { ActionButton } from './Button/ActionButton';
import { createStyles, makeStyles } from './Theme';

export const CARD_WIDTH = 286;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      width: `${CARD_WIDTH}px`,
      borderRadius: '8px',
      background: colors.neutral250,
      flexDirection: 'column',
      overflow: 'hidden',
      maxWidth: `${CARD_WIDTH}px`,
      minWidth: `${CARD_WIDTH}px`,
      transition: 'background 0.3s ease-in-out',

      '&:hover': {
        background: colors.neutral300,
      },

      '&:hover $image': {
        transform: 'scale(1.2)',
        transition: 'transform 0.8s ease',
      },
    },
    imageContainer: {
      width: '100%',
      minHeight: '144px',
      height: '144px',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transform: 'scale(1)',
      transition: 'transform 0.8s ease',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '32px 24px',
      width: '100%',
      height: '100%',
      gap: '16px',
    },
    headline: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      height: '48px',
    },
    description: {
      color: colors.neutral800,
      fontSize: '14px',
      lineHeight: '20px',
      height: '40px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    anchor: {
      width: 'max-content',
    },
  }),
  { name: 'GettingStartedCard' },
);

type GettingStartedCardProps = {
  image: {
    src: string;
    alt?: string;
  };
  headline: string;
  description: string;
  button: {
    disabled?: boolean;
    href?: string;
    CTA?: string;
  }
  isDescriptionHidden?: boolean;
}

export const GettingStartedCard = (props: GettingStartedCardProps) => {
  const classes = useStyles();

  const { image, headline, description, button, isDescriptionHidden } = props;

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img
          alt={image.alt || ''}
          className={classes.image}
          crossOrigin="anonymous"
          src={image.src}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.headline}>{headline}</div>
        {!isDescriptionHidden && <div className={classes.description}>{description}</div>}
        <div className={classes.anchor}>
          <ActionButton
            disabled={button.disabled}
            href={button.href}
            kind="primary"
            openInNewTab
            tabIndex={-1}>
            {button.disabled ? 'Coming soon' : button.CTA}
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default GettingStartedCard;
