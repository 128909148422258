import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import Draggable from 'react-draggable';

import { isAssistantResponding } from '../../../lib/assistant/assistantCall';
import { colors } from '../../../lib/designSystem';
import { analytics } from '../../../services/analytics';
import { useResetAssistantChat } from '../../../state/external/assistant/assistantMessages';
import { MAX_EXPANDED_RAIL_WIDTH, MIN_EXPANDED_RAIL_WIDTH, MIN_RAIL_WIDTH, useAssistantSideRailWidth } from '../../../state/internal/assistant/assistantSideRailSize';
import { pushConfirmation, useSetConfirmations } from '../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../Button/ActionButton';
import { createStyles, makeStyles } from '../../Theme';
import { FixedSizeWrapper } from '../../layout/FixedSizeWrapper';
import { ResetIcon } from '../../svg/ResetIcon';

import { AssistantChat } from './AssistantChat';
import { MacroSubscriber } from './MacroSubscriber';

const DRAGGER_WIDTH = 12;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '100%',
      border: `1px solid ${colors.neutral50}`,
      borderTop: 'none',
      flex: '0 0 auto',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      background: colors.neutral100,
      transition: 'width 300ms',
      overflow: 'hidden',
    },
    subheader: {
      height: '36px',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 10px 0 18px',
      alignItems: 'center',
      background: colors.neutral150,
      borderBottom: `1px solid ${colors.neutral200}`,
      boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.25)`,
      zIndex: 1,
    },
    subheaderItem: {
      display: 'flex',
    },
    body: {
      overflow: 'auto',
      flex: '1 1 auto',
      // This is a hack to make the chat scrollable while also preventing the entire chat
      // from expanding beyond the viewport height. The 94px is a magic number to match the
      // PageHeader and chat header.
      maxHeight: 'calc(100vh - 94px)',
    },
    dragger: {
      width: `${DRAGGER_WIDTH}px`,
      height: '100%',
      position: 'absolute',
      top: '0',
      zIndex: '3',
      cursor: 'col-resize',
      display: 'flex',
      justifyContent: 'center',
    },
    draggerLine: {
      position: 'absolute',
      height: '100%',
      width: '1px',
      backgroundColor: colors.neutral0,
      top: 0,
      left: '-1px',
      zIndex: 3,
      pointerEvents: 'none',
    },
  }),
  { name: 'SideRail' },
);

/**
 * A rail component on the right of the screen which holds the assistant chat. It's collapsible.
 */
export const SideRail = () => {
  // == Hooks
  const classes = useStyles();

  // == State
  const setConfirmStack = useSetConfirmations();
  const resetAssistantChat = useResetAssistantChat();
  const [sideRailWidth, setSideRailWidth] = useAssistantSideRailWidth();

  // Applying `cursor: col-resize` only on the dragger causes flickering during resizing,
  // activating this state adds a CSS class, ensuring global styles apply the "moving" cursor
  // to all elements.
  const [isDraggerActive, setIsDraggerActive] = useState(false);

  // dragger position is relative to the initial size, requiring a reference to it
  const initialWidthRef = useRef(sideRailWidth);

  const positionX = initialWidthRef.current - sideRailWidth;

  // == Internal state
  const [isResponding, setIsResponding] = useState(false);

  useEffect(() => {
    const subscription = isAssistantResponding.subscribe((value) => {
      setIsResponding(value);
    });
    return () => subscription.unsubscribe();
  }, []);

  const onClickReset = () => {
    pushConfirmation(setConfirmStack, {
      title: 'Are you sure you want to reset your chat?',
      subtitle: `Resetting your chat will remove your chat history and
            Lumi AI will no longer have context to your existing conversation.
            Are you sure you want to proceed?`,
      onContinue: async () => {
        try {
          await resetAssistantChat();
          console.log('Chat reset successfully');
        } catch (error) {
          console.error('Failed to reset chat:', error);
        }
      },
      destructive: true,
    });
  };

  return (
    <>
      <div
        className={cx(classes.root)}
        style={{ width: sideRailWidth <= 36 ? 0 : sideRailWidth }}>
        <div className={classes.draggerLine} />
        <div className={cx(classes.subheader)}>
          <div className={classes.subheaderItem}>
            <ActionButton
              dataLocator="assistant-reset-button"
              disabled={isResponding}
              kind="minimal"
              onClick={onClickReset}
              size="small">
              <FixedSizeWrapper height={12} width={12}>
                <ResetIcon />
              </FixedSizeWrapper>
              <div style={{ fontSize: '13px' }}>Reset</div>
            </ActionButton>
          </div>
        </div>
        <div className={cx(classes.body)}>
          <AssistantChat />
        </div>
      </div>
      <Draggable
        axis="x"
        bounds={{
          left: (initialWidthRef.current - MAX_EXPANDED_RAIL_WIDTH),
          right: (initialWidthRef.current - MIN_RAIL_WIDTH),
        }}
        onDrag={(_, { x }) => {
          let newWidth = initialWidthRef.current - x;

          // Snap the size if it does not meet the MIN_EXPANDED_RAIL_WIDTH threshold
          if (newWidth < MIN_EXPANDED_RAIL_WIDTH) {
            newWidth = MIN_RAIL_WIDTH;
          }

          setSideRailWidth(newWidth);
        }}
        onStart={() => {
          setIsDraggerActive(true);
        }}
        onStop={() => {
          setIsDraggerActive(false);
          analytics.assistant('Change Width', { value: sideRailWidth });
        }}
        position={{ x: positionX, y: 0 }}>
        <div
          className={cx(classes.dragger, isDraggerActive && 'horizontal-dragger-active')}
          // transformX(+50%) won't work here since draggable overrides that property
          style={{ right: initialWidthRef.current - DRAGGER_WIDTH / 2 }}
        />
      </Draggable>
      <MacroSubscriber />
    </>
  );
};
