// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useState } from 'react';

import assert from '../../../../lib/assert';
import { cadIdsToVolumeNodeIds, volumeNodeIdsToCadIds } from '../../../../lib/geometryUtils';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { NodeType } from '../../../../lib/simulationTree/node';
import { defaultNodeFilter, mapVisualizerEntitiesToVolumes } from '../../../../lib/subselectUtils';
import { addRpcError } from '../../../../lib/transientNotification';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import {
  DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE,
  createOrUpdateFeature,
  useGeometrySelectedFeature,
  useGeometryState,
  useSetGeometryState,
} from '../../../../recoil/geometry/geometryState';
import { useCadMetadata } from '../../../../recoil/useCadMetadata';
import { useStaticVolumes } from '../../../../recoil/volumes';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { NodeSubselect } from '../../NodeSubselect';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

// Handles deletion of bodies.
export const GeometryModificationDeleteBodyPropPanel = () => {
  // == Contexts
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();
  assert(!!node, 'No selected geometry modification delete-body row');

  // == Recoil
  const geometryState = useGeometryState(projectId, geometryId);
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const [cadMetadata] = useCadMetadata(projectId, '', '');
  const staticVolumes = useStaticVolumes(projectId, '', '');
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);

  // == Hooks
  const propClasses = useCommonTreePropsStyles();

  // == Derived data
  const mod = geometryState?.geometryFeatures.find((feature) => feature.id === node.id);
  assert(!!mod, 'No selected geometry modification delete-body');
  const del = mod.operation.value as geometrypb.Delete;
  assert(!!del, 'Missing delete-body');
  const initialBodies = cadIdsToVolumeNodeIds(del.ids, staticVolumes, cadMetadata);
  const isAcknowledgedFeature = geometryState?.ackModifications.has(node.id);

  // == State
  const [selectedBodiesNodeIds, setSelectedBodiesNodeIds] = useState<string[]>(initialBodies);

  const mapVisualizerEntities = useCallback(
    (ids: string[]) => mapVisualizerEntitiesToVolumes(ids, staticVolumes),
    [staticVolumes],
  );

  // Node subselect constructs
  const nodeFilter = useCallback((nodeType, nodeId) => {
    if (nodeType === NodeType.VOLUME) {
      return {
        related: true,
        disabled: selectedBodiesNodeIds.includes(nodeId),
      };
    }
    return defaultNodeFilter(nodeType);
  }, [selectedBodiesNodeIds]);

  const onDeleteSave = async () => {
    const feature = new geometrypb.Feature({
      operation: mod.operation,
      id: node.id,
      featureName: mod.featureName,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: createOrUpdateFeature(geometryState, node.id),
        feature,
      }),
    });
    rpc.clientGeometry.modifyGeometry(req).catch((err) => (
      addRpcError(`Server error ${err}`, err)
    ));
    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);

    // Remove the focus on the panel, the geometry being displayed may not be linked to the
    // modification.
    setSelection([]);
  };

  return (
    <div className={propClasses.properties}>
      <EditModificationMessage nodeId={node.id} />
      <NodeSubselect
        autoStart={!isAcknowledgedFeature}
        iconNotFoundNodes={{ name: 'cubeOutline' }}
        id="del-body"
        labels={['volumes']}
        mapVisualizerEntities={mapVisualizerEntities}
        nodeFilter={nodeFilter}
        nodeIds={selectedBodiesNodeIds}
        onChange={(nodeIds: string[]) => {
          setGeometryState((oldGeometryState) => {
            if (oldGeometryState === undefined) {
              return oldGeometryState;
            }
            const newGeometryState = { ...oldGeometryState };
            newGeometryState.geometryFeatures.forEach((feature) => {
              if (feature.id !== mod.id) {
                return;
              }
              const delOut = feature.operation.value as geometrypb.Delete;
              const bodies = volumeNodeIdsToCadIds(nodeIds, staticVolumes, cadMetadata);
              delOut.ids = bodies;
            });
            return newGeometryState;
          });
          setSelectedBodiesNodeIds(nodeIds);
        }}
        readOnly={readOnly}
        referenceNodeIds={[node.id]}
        showNotFoundNodes
        title="Volumes to Delete"
        visibleTreeNodeTypes={[NodeType.VOLUME]}
      />
      <GeometryModificationPanelFooter
        featureId={node.id}
        onModificationSave={onDeleteSave}
      />
    </div>
  );
};
