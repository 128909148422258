// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo } from 'react';

import * as frontendpb from '@/proto/frontend/frontend_pb';
import useAccountInfo from '@/recoil/useAccountInfo';

export const useBillingType = () => {
  const accountInfo = useAccountInfo();

  return useMemo(() => {
    switch (accountInfo?.billingType) {
      case frontendpb.BillingType.INVALID_BILLING_TYPE:
        return 'invalid';
      case frontendpb.BillingType.EVAL_BILLING_TYPE:
        return 'eval';
      case frontendpb.BillingType.ON_DEMAND_BILLING_TYPE:
        return 'on demand';
      case frontendpb.BillingType.PREPAID_BILLING_TYPE:
        return 'prepaid';
      case frontendpb.BillingType.TRIAL_BILLING_TYPE:
        return 'trial';
      case frontendpb.BillingType.UNLIMITED_BILLING_TYPE:
        return 'unlimited';
      default:
        return 'unknown';
    }
  }, [accountInfo?.billingType]);
};
