// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { BaseEditor, BaseRange, Descendant, Editor, Element, Node, Range, Text, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { unquote } from '../text';

import { CustomElement, FunctionElement, Type, VariableElement } from './slate';
import { getCurrentLineText } from './util';

/** The type of suggestion */
export enum SuggestionType {
  /** Variable suggestion */
  VARIABLE,
  /** Function suggestion */
  FUNCTION,
}

/** An autocomplete suggestion */
export interface Suggestion {
  /** The type of this suggestion */
  type: SuggestionType;
  /** The text value of this suggestion */
  value: string;
}

export const EMPTY_EDITOR: Descendant[] = [{ type: Type.Paragraph, children: [{ text: '' }] }];

export const AutocompleteEditor = {
  /**
   * Add autocomplete functionality to a given editor
   *
   * @param editor the editor to add autocomplete functionality
   * @returns editor with autocomplete functionality
   */
  withAutocomplete(editor: BaseEditor & ReactEditor) {
    const { isInline, isVoid, markableVoid } = editor;

    const inlineVoidTypes = [Type.Variable, Type.Function, Type.Preview];

    editor.isInline = (element) => {
      if (inlineVoidTypes.includes(element.type)) {
        return true;
      }
      return isInline(element);
    };
    editor.isVoid = (element) => {
      if (inlineVoidTypes.includes(element.type)) {
        return true;
      }
      return isVoid(element);
    };
    editor.markableVoid = (element) => {
      if (inlineVoidTypes.includes(element.type)) {
        return true;
      }
      return markableVoid(element);
    };

    return editor;
  },

  /**
   * Parse a given editor for variables and find if the user has written a variable in quotation
   * marks
   *
   * @param editor the editor to parse
   * @param variables the available variables to search for
   *
   * @returns the variable and range if a variable was found in the text, otherwise undefined
   */
  parseForVariables(editor: BaseEditor & ReactEditor, variables: string[]) {
    const { selection } = editor;
    const currentLine = getCurrentLineText(editor);
    if (selection && currentLine) {
      const [start] = Range.edges(selection);
      const lineBefore = Editor.before(editor, start, { unit: 'line' });
      const lineRange = lineBefore && Editor.range(editor, lineBefore, start);
      const lineText = lineRange && Editor.string(editor, lineRange);

      // match for any text between two quotation marks
      const possibleVariable = lineText?.match(/"(.*?)"/)?.[1];
      if (possibleVariable) {
        const variableBefore = Editor.before(
          editor,
          start,
          // + 2 length to account for the quotation marks
          { unit: 'character', distance: possibleVariable.length + 2 },
        );
        const variableRange = variableBefore && Editor.range(editor, variableBefore, start);
        if (variables.includes(unquote(possibleVariable)) && variableRange) {
          return { variable: possibleVariable, range: variableRange };
        }
      }
    }
    return undefined;
  },

  /**
   * Search a given editor for the start of a possible variable
   *
   * If the user writes a single quotation mark, it returns the string after it.
   *
   * @param editor the editor to search in
   *
   * @returns possible variable or undefined
   */
  searchForVariables(editor: BaseEditor & ReactEditor) {
    const { selection } = editor;
    const currentLine = getCurrentLineText(editor);
    if (selection && currentLine) {
      const { text } = currentLine;
      // match for any text between two quotation marks
      const possibleVariable = text.match(/"(.*?)$/)?.[1];
      if (possibleVariable) {
        const [start] = Range.edges(selection);
        const variableBefore = Editor.before(
          editor,
          start,
          // + 1 length for the quotation marks
          { unit: 'character', distance: possibleVariable.length + 1 },
        );
        const variableRange = variableBefore && Editor.range(editor, variableBefore, start);
        return { search: possibleVariable, target: variableRange };
      }
    }
    return undefined;
  },

  /**
   * Parse a given editor for functions and find if the user has written a function name before
   * an open parentheses.
   *
   * @param editor the editor to parse
   * @param functions the available functions to search for
   *
   * @returns the function and range if a function was found the text, otherwise undefined
   */
  parseForFunctions(editor: BaseEditor & ReactEditor, functions: string[]) {
    const { selection } = editor;
    const currentLine = getCurrentLineText(editor, 'word');
    if (selection && currentLine) {
      const { text: currentWord, range } = currentLine;
      if (currentWord.at(-1) === '(') {
        const word = currentWord.slice(0, currentWord.length - 1);
        // because input ends with an open parentheses, input could be a function
        // so now we verify that it is a function
        const possibleFunction = functions.find((func) => func === word);
        if (possibleFunction) {
          return {
            func: possibleFunction,
            range,
          };
        }
      }
    }
    return undefined;
  },

  /**
   * Search a given editor for the last word the user has written in order to give possible
   * suggestions
   *
   * @param editor the editor to parse
   *
   * @returns the search text and its range to look for a possible suggestion
   */
  searchForSuggestions(editor: BaseEditor & ReactEditor) {
    const { selection } = editor;
    const currentLine = getCurrentLineText(editor, 'word');
    if (selection && currentLine?.text) {
      return { search: currentLine.text, target: currentLine.range };
    }
    return undefined;
  },

  /**
   * Insert a suggestion into the editor. Optionally replace a range of text with the suggestion
   *
   * @param editor the editor to add the suggestion to
   * @param suggestion the suggestion to add
   * @param range the selection to replace with the suggestion
   */
  insertSuggestion(editor: BaseEditor & ReactEditor, suggestion: Suggestion, range?: BaseRange) {
    // delete any preview nodes if they exist
    this.deletePreview(editor);
    const element: CustomElement = suggestion.type === SuggestionType.VARIABLE ? {
      type: Type.Variable,
      variable: suggestion.value,
      children: [{ text: '' }],
    } : {
      type: Type.Function,
      func: suggestion.value,
      children: [{ text: '' }],
    };
    // If user supplies a range to replace, replace it, otherwise insert the node wherever the
    // cursor currently is
    range && Transforms.select(editor, range);
    Transforms.insertNodes(editor, element);
    if (suggestion.type === SuggestionType.FUNCTION) {
      Transforms.move(editor);
      Transforms.insertText(editor, '()', { at: editor.selection?.anchor });
      Transforms.move(editor, { distance: 1, reverse: true });
      return;
    }
    Transforms.move(editor);
  },

  /**
   * Select or cancel the autocomplete suggestion
   *
   * Depending on the user input, the user can:
   *  - confirm the current selection (Enter, Tab), which will insert the suggestion
   *  - change the selection (ArrowDown, ArrowUp)
   *  - cancel the suggestion (Escape)
   *
   * @param editor the editor to add the suggestion to
   * @param event the key event
   * @param target the range for the suggestion
   * @param selected the index of the currently selected suggestion
   * @param suggestions the list of suggestions
   *
   * @returns a new selected indec or an undefined index to remove the suggestion or undefined
   * for no change
   */
  selectSuggestion(
    editor: BaseEditor & ReactEditor,
    event: React.KeyboardEvent<HTMLDivElement>,
    target: Range,
    selected: number,
    suggestions: Suggestion[],
  ) {
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        const prevIndex = selected >= suggestions.length - 1 ? 0 : selected + 1;
        return { selected: prevIndex };
      }
      case 'ArrowUp': {
        event.preventDefault();
        const nextIndex = selected <= 0 ? suggestions.length - 1 : selected - 1;
        return { selected: nextIndex };
      }
      case 'Tab':
      case 'Enter': {
        event.preventDefault();
        this.insertSuggestion(editor, suggestions[selected], target);
        return { selected: undefined };
      }
      case 'Escape': {
        event.preventDefault();
        return { selected: undefined };
      }
      default:
        return undefined;
    }
  },

  /**
   * Insert a preview element to the editor to show a preview of what the autocomplete suggestion
   * would look like
   *
   * Any existing preview element is deleted first.
   *
   * @param editor the editor to add the preview to
   * @param suggestion the suggested autocomplete result
   * @param search the term used to search for the autocomplete result
   */
  insertPreview(
    editor: BaseEditor & ReactEditor,
    suggestion: string,
    search: string,
  ) {
    // delete all existing preview nodes before adding a new one
    this.deletePreview(editor);
    const preview: CustomElement = {
      type: Type.Preview,
      preview: suggestion.slice(search.length),
      children: [{ text: '' }],
    };
    Transforms.insertNodes(editor, preview);
    Transforms.move(editor, { distance: 1, reverse: true });
  },

  /**
   * Delete all Preview elements in the specified editor
   *
   * @param editor the editor to delete elements from
   */
  deletePreview(editor: BaseEditor & ReactEditor) {
    Transforms.removeNodes(editor, {
      at: [],
      match: ((node: Node) => Element.isElement(node) && node.type === Type.Preview),
    });
  },

  /**
   * Serialize the given editor into the string format needed for the custom output expression
   *
   * @param editor the editor to serialize
   * @returns the string serialized for outputs
   */
  serialize(editor: BaseEditor & ReactEditor) {
    const serializeHelper = (node: Descendant): string => {
      if (Text.isText(node)) {
        return node.text;
      }
      switch (node.type) {
        case Type.Variable: {
          return `"${node.variable}"`;
        }
        case Type.Function: {
          return node.func;
        }
        default: {
          return node.children.map(serializeHelper).join('');
        }
      }
    };
    return editor.children.map(serializeHelper).join('');
  },

  /**
   * Deserialize the given input for a custom output expression into an editor state
   *
   * @param input the input text to deserialize
   * @param variables the available variables for this editor
   * @param functions the available functions for this editor
   *
   * @returns the state of the editor based on the input string
   */
  deserialize(input: string, variables: string[], functions: string[]): Descendant[] {
    if (!input) {
      return EMPTY_EDITOR;
    }

    const variablesSet = new Set(variables);
    const functionsSet = new Set(functions);

    const result: Descendant[] = [];
    let currentNode: Descendant | undefined;

    /**
     * Append the text to the previous text node, or, if none exists, create a new text node
     * The `currentNode` is updated to the new current node
     */
    const appendOrCreateText = (text: string) => {
      const lastNode = result.at(-1);
      if (lastNode && Text.isText(lastNode)) {
        // append the current text to the previous text node
        lastNode.text += text;
        result.pop();
        currentNode = lastNode;
      } else {
        currentNode = { text };
      }
    };

    /**
     * Verify if the variable exists, if not then turn the node into text
     * The `currentNode` is updated to the new current node
     */
    const verifyVariable = (node: VariableElement) => {
      if (variablesSet.has(node.variable)) {
        result.push(node);
        currentNode = undefined;
      } else {
        // variable does not exist
        appendOrCreateText(`"${node.variable}"`);
      }
    };

    /**
     * Verify if the function exists, if not then turn the node into text
     * The `currentNode` is updated to the new current node
     */
    const verifyFunction = (node: FunctionElement) => {
      if (functionsSet.has(node.func)) {
        result.push(node);
        currentNode = { text: '(' };
      } else {
        appendOrCreateText(`${node.func}(`);
      }
    };

    // the deserializer is implemented as a FSM where the state is the current node's type and
    // the input is the parsed character
    [...input].forEach((char) => {
      if (!currentNode) {
        // current node doesn't exist, so a new node is created based on the parsed char
        if (char === '"') {
          // start of a variable
          currentNode = {
            type: Type.Variable,
            variable: '',
            children: [{ text: '' }],
          };
        } else if (/([A-Za-z])/.test(char)) {
          // could be the start of a function name
          currentNode = {
            type: Type.Function,
            func: char,
            children: [{ text: '' }],
          };
        } else {
          currentNode = { text: char };
        }
        return;
      }
      if (Text.isText(currentNode)) {
        // current node is regular text
        if (char === '"') {
          // start of a variable
          // push current node to result and create a new variable node
          result.push(currentNode);
          currentNode = {
            type: Type.Variable,
            variable: '',
            children: [{ text: '' }],
          };
        } else if (/([A-Za-z])/.test(char)) {
          // could be the start of a function name
          // push current node to result and create a new function node
          result.push(currentNode);
          currentNode = {
            type: Type.Function,
            func: char,
            children: [{ text: '' }],
          };
        } else {
          currentNode.text += char;
        }
        return;
      }
      switch (currentNode.type) {
        case Type.Variable: {
          if (char === '"') {
            // end of variable
            // verify that this variables exists
            verifyVariable(currentNode);
          } else {
            currentNode.variable += char;
          }
          break;
        }
        case Type.Function: {
          if (char === '(') {
            // start of function arguments
            // verify that this function exists
            verifyFunction(currentNode);
          } else {
            currentNode.func += char;
          }
          break;
        }
        default: {
          // should not reach this case, because paragraphs elements and preview elements should
          // not be added to the result state
          break;
        }
      }
    });

    if (currentNode) {
      // clean up the last node
      // cannot be a function because it would have parentheses
      if (Text.isText(currentNode)) {
        result.push(currentNode);
      } else {
        switch (currentNode.type) {
          case Type.Variable: {
            // cannot be a variable because it must be enclosed in quotation marks
            // however it had at aleast started with a quotation mark
            appendOrCreateText(`"${currentNode.variable}`);
            currentNode && result.push(currentNode);
            break;
          }
          case Type.Function: {
            // cannot be a function because it would have openning parentheses
            appendOrCreateText(currentNode.func);
            currentNode && result.push(currentNode);
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    return [{ type: Type.Paragraph, children: [...result, { text: '' }] }];
  },
};

/** Supported Math functions */
export const MATH_FUNCTIONS = [
  'sqrt', 'cbrt', 'pow', 'hypot', 'log',
  'exp', 'abs', 'max', 'min', 'cos',
  'sin', 'tan', 'acos', 'asin', 'atan',
  'atan2',
];
