import { useEffect, useRef } from 'react';

import EventEmitter from 'eventemitter3';

import { useAddNewAssistantMessage } from './useAddNewAssistantMessage';

import { FrodoEmitter } from '@/lib/frodoTypes';
import { useAssistantMessagesValue } from '@/state/external/assistant/assistantMessages';

// TODO(bart): pretty sure using an event emitter here isn’t the best practice, but it was
// the fastest way to get this working; we should consider a more global state pattern,
// since we need to keep Frodo and the frontend in sync.
export const useFrodoAssistantEmitter = (projectId: string) => {
  const eventEmitterRef = useRef<FrodoEmitter>(new EventEmitter());

  const rawMessages = useAssistantMessagesValue(projectId);
  const addMessage = useAddNewAssistantMessage(projectId);

  useEffect(() => {
    const eventEmitter = eventEmitterRef.current;

    eventEmitter.on('send', addMessage);

    return () => {
      eventEmitter.off('send', addMessage);
    };
  }, [addMessage]);

  useEffect(() => {
    const eventEmitter = eventEmitterRef.current;

    eventEmitter.emit('updateMessages', rawMessages);
  }, [rawMessages]);

  useEffect(() => {
    const eventEmitter = eventEmitterRef.current;

    const pullMessages = () => {
      eventEmitter.emit('updateMessages', rawMessages);
    };

    eventEmitter.on('pullMessages', pullMessages);

    return () => {
      eventEmitter.off('pullMessages', pullMessages);
    };
  }, [rawMessages]);

  return eventEmitterRef.current;
};
