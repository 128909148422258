// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useRecoilState, useSetRecoilState } from 'recoil';

/**
 * This atom holds the truth whether we should show the property panel for the geometry tree items.
 * It can be shown by either one of:
 * - clicking a button from the TreeRow;
 * - toggling the "Show prop panel on select" from the Geometry Tree and then selecting some item.
 */
export const propertiesPanelVisibleState = atom<boolean>({
  key: 'propertiesPanelVisibleState',
  default: false,
});

export function usePropertiesPanelVisible() {
  return useRecoilState(propertiesPanelVisibleState);
}
export function useSetPropertiesPanelVisible() {
  return useSetRecoilState(propertiesPanelVisibleState);
}
