// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useRef, useState } from 'react';

import assert from '../../../lib/assert';
import * as rpc from '../../../lib/rpc';
import * as visualizationservicepb from '../../../proto/visualizationservice/visualizationservice_pb';
import { createStyles, makeStyles } from '../../Theme';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    imageContainer: {
      marginTop: 16,
      maxWidth: '100%',
      '& img': {
        maxWidth: '100%',
        height: 'auto',
      },
    },
  }),
  { name: 'PostProcessingExtractPropPanel' },
);

export const PostProcessingExtractPropPanel = () => {
  const { projectId } = useProjectContext();
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const mountedRef = useRef(true);

  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected node for PostProcessingExtractPropPanel');

  const fetchData = useCallback(async () => {
    const res = await rpc.clientVisualization!.getPostProcessingExtract(
      new visualizationservicepb.GetPostProcessingExtractRequest({
        projectId,
        id: node.id,
      }),
    );
    // TODO(matt): get all the images
    if (res.outputs[0]?.data.case === 'static') {
      if (res.outputs[0].data.value.image?.data.case === 'signedUrl') {
        const url = res.outputs[0].data.value.image?.data.value.url;
        if (!mountedRef.current) {
          return;
        }
        setImageUrl(url);
      }
    }
  }, [projectId, node.id, setImageUrl]);

  useEffect(() => {
    mountedRef.current = true;
    fetchData().catch(console.error);
    return () => {
      mountedRef.current = false;
    };
  }, [fetchData]);

  return (
    <div className={classes.root}>
      {imageUrl && (
        <div className={classes.imageContainer}>
          <img alt="Post processing extract" crossOrigin="anonymous" src={imageUrl} />
        </div>
      )}
    </div>
  );
};
