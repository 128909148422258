// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { IconButton } from '../Button/IconButton';
import { createStyles, makeStyles } from '../Theme';
import { useCommonTreeRowStyles } from '../Theme/commonStyles';
import { EyeOffIcon } from '../svg/EyeOffIcon';
import { EyeOnIcon } from '../svg/EyeOnIcon';

const useBoundsListStyles = makeStyles(
  () => createStyles({
    root: {},
    iconButton: {
      color: 'currentColor',
      padding: 0,
      border: '1px solid transparent',
      backgroundColor: 'transparent',
      transition: 'background-color 250ms',
      '&:hover': {
        backgroundColor: 'rgb(255, 255, 255, 0.15)',
      },
      '&:focus': {
        borderColor: colors.primaryCta,
      },
    },
    disabled: {
      color: '#888',
    },
  }),
  { name: 'VisibilityButton' },
);

interface VisibilityButtonProps {
  /** If the button is disabled. */
  disabled: boolean;
  /** If the icon is visible. */
  isVisible: boolean;
  /** Called when the button is clicked. */
  onClick: () => void;
}

/** Shows a list of bounds and buttons to toggle their visibility. */
const VisibilityButton = (props: VisibilityButtonProps) => {
  const classes = useBoundsListStyles();
  const commonClasses = useCommonTreeRowStyles();

  return (
    <div className={cx(commonClasses.control, 'opaque', 'colorable', {
      frozen: !props.disabled && props.isVisible === false,
    })}>
      <IconButton
        className={cx(classes.iconButton, {
          [classes.disabled]: props.disabled,
        })}
        data-locator="node-row-visibility"
        disabled={props.disabled}
        onClick={(event) => {
          if (event.stopPropagation) {
            event.stopPropagation();
          }
          props.onClick();
        }}>
        {props.isVisible ? (
          <EyeOnIcon maxHeight={17} maxWidth={17} />
        ) : (
          <EyeOffIcon maxHeight={17} maxWidth={17} />
        )}
      </IconButton>
    </div>
  );
};

export default VisibilityButton;
