import { useCallback } from 'react';

import { TransparencySettings, useSetTransparencySettings } from '@/recoil/lcvis/transparencySettings';

/**
  * The hook that temporarily applies transparency, e.g. when selecting only specific tags
  * or previewing geometry health issues.
  */
export const useSetSurfacesTransparency = () => {
  const setTransparencySettings = useSetTransparencySettings();

  return useCallback((data: Partial<TransparencySettings>) => {
    let keptSettings: null | TransparencySettings;

    setTransparencySettings((currentSettings) => {
      keptSettings = currentSettings;

      return {
        ...currentSettings,
        ...data,
        alwaysTransparentSurfaceIds: currentSettings.alwaysTransparentSurfaceIds,
      };
    });

    return () => {
      if (keptSettings) {
        setTransparencySettings((currentSettings) => ({
          ...keptSettings!,
          alwaysTransparentSurfaceIds: currentSettings.alwaysTransparentSurfaceIds,
        }));
      }
    };
  }, [setTransparencySettings]);
};
