// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.

import { useCallback, useMemo } from 'react';

import assert from '@/lib/assert';
import { fromBigInt } from '@/lib/number';
import { getPhysicsId, getPhysicsInitialization, updatePhysicsInitialization } from '@/lib/physicsUtils';
import { useWorkflowConfig } from '@/model/hooks/useWorkflowConfig';
import * as simulationpb from '@/proto/client/simulation_pb';
import { InitializationState } from '@/proto/projectstate/projectstate_pb';
import { useFrontendMenuState } from '@/recoil/frontendMenuState';
import { useJobState } from '@/recoil/jobState';

// return some data about the physics, including the physics object itself and its index
export const getPhysicsWithIndex = (
  simParam: simulationpb.SimulationParam,
  physicsId: string,
) => {
  let physics: simulationpb.Physics | undefined;
  let index: number = -1;
  simParam.physics.some((item, i) => {
    if (getPhysicsId(item) === physicsId) {
      physics = item;
      index = i;
      return true;
    }
    return false;
  });
  return {
    physics,
    index,
  };
};

export const useExistingSolution = (
  projectId: string,
  workflowId: string,
  jobId: string,
) => {
  const [frontendMenuState, setFrontendMenuState] = useFrontendMenuState(
    projectId,
    workflowId,
    jobId,
  );

  const initState = frontendMenuState.initState;
  // Information about the currently selected simulation set as initState
  const selectedWorkflowId = initState?.workflowId || '';
  const selectedJobId = initState?.jobId || '';
  const jobState = useJobState(
    projectId,
    selectedWorkflowId,
    selectedJobId,
  );

  // Store solutions that have volume solution files
  const availableSolutions = useMemo(() => (
    jobState?.solutions ?? []
  ), [jobState]);

  // get the iteration from the initState
  const currentIteration = initState?.iter;
  const initSol = availableSolutions.find(
    (solution) => fromBigInt(solution.iter) === currentIteration,
  );
  const solUrl = initSol?.url || '';

  const updateInitialization = useCallback((
    initWorkflowId: string,
    initJobId: string,
    iter: number,
  ) => {
    const newState = frontendMenuState.clone();
    newState.initState = new InitializationState({
      workflowId: initWorkflowId,
      jobId: initJobId,
      iter,
    });
    setFrontendMenuState(newState);
  }, [frontendMenuState, setFrontendMenuState]);

  return useMemo(() => ({
    availableSolutions,
    initState,
    jobState,
    selectedWorkflowId,
    selectedJobId,
    solUrl,
    updateInitialization,
  }), [
    availableSolutions,
    initState,
    jobState,
    selectedWorkflowId,
    selectedJobId,
    solUrl,
    updateInitialization,
  ]);
};

// This hooks automatically selects the last iteration when switching solutions and updates the
// initialization state if the current url is different from the selected solution.
export const useFixExistingSolution = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  const { simParam, saveParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);
  const {
    availableSolutions,
    initState,
    selectedWorkflowId,
    selectedJobId,
    solUrl,
    updateInitialization,
  } = useExistingSolution(projectId, workflowId, jobId);

  return useCallback((physicsId: string) => {
    if (selectedJobId && availableSolutions.length) {
      // Automatically select the last iteration when switching solutions.
      if (initState?.iter === -1) {
        const lastSolution = availableSolutions[availableSolutions.length - 1];
        const iter = fromBigInt(lastSolution!.iter);
        assert(iter !== -1, 'Solution has invalid iteration index.');
        updateInitialization(
          selectedWorkflowId,
          selectedJobId,
          iter,
        );
      }
      // Update the initialization state if the current url is different from the selected solution.
      const { physics, index } = getPhysicsWithIndex(simParam, physicsId);
      const initialization = getPhysicsInitialization(physics);
      if (solUrl !== initialization?.existingSolutionUrl) {
        saveParam((newParam) => {
          updatePhysicsInitialization(
            newParam.physics[index],
            { url: solUrl, type: simulationpb.InitializationType.EXISTING_SOLUTION },
          );
        });
      }
    }
  }, [
    simParam,
    solUrl,
    availableSolutions,
    initState,
    saveParam,
    selectedJobId,
    selectedWorkflowId,
    updateInitialization,
  ]);
};
