import { useEffect } from 'react';

import { LcvFilter } from '@/lib/lcvis/classes/filters/LcvFilter';

/**
 * Hook that temporarily enables the `preventVisibilityRestore` flag for a given filter
 * while the component is mounted. When the component unmounts, it resets the flag to `false`.
 */
export const usePreventVisibilityRestore = (filter?: LcvFilter) => {
  // prevent the behavior with automatically restored visibility
  useEffect(() => {
    if (filter) {
      filter.preventVisibilityRestore = true;
    }

    return () => {
      if (filter) {
        filter.preventVisibilityRestore = false;
      }
    };
  }, [filter]);
};
