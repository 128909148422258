// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import * as simulationpb from '../../proto/client/simulation_pb';

import { useWorkflowConfig } from './useWorkflowConfig';

import { rollupGroups } from '@/lib/entityGroupUtils';
import { getOrCreateAdjoint } from '@/lib/simulationParamUtils';
import { useEntityGroupData } from '@/recoil/entityGroupState';

/**
 * Model hook for managing the Adjoint data structure at the SimluationParam root
 * @param projectId
 * @param workflowId
 * @param jobId
 * @param readOnly
 * @returns
 */
export const useAdjoint = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) => {
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);
  const adjoint = getOrCreateAdjoint(simParam);

  const setAdjoint = useCallback(
    async (value: simulationpb.Adjoint) => saveParamAsync((newParam) => {
      newParam.adjoint = value;
    }),
    [saveParamAsync],
  );

  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);
  const selectedSurfaces = useMemo(() => rollup(adjoint.surfaces ?? []), [rollup, adjoint]);

  const setSurfaces = useCallback(async (newSelection: string[]) => {
    await saveParamAsync((newParam) => {
      const newAdjoint = getOrCreateAdjoint(newParam).clone();
      newAdjoint.surfaces = newSelection;
      newParam.adjoint = newAdjoint;
      return newParam;
    });
  }, [saveParamAsync]);

  return {
    adjoint,
    setAdjoint,
    selectedSurfaces,
    setSurfaces,
  };
};
