import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from '../../../lib/browserStorage';
import { localStorageEffect } from '../../../lib/persist';

const AI_COLLAPSED_STORAGE_KEY = 'assistantSideRailCollapsed';

const assistantSideRailCollapsed = atom<boolean>({
  key: AI_COLLAPSED_STORAGE_KEY,
  default: getLocalStorageData(AI_COLLAPSED_STORAGE_KEY, false),
  effects: [
    localStorageEffect(AI_COLLAPSED_STORAGE_KEY),
  ],
});

export const useAssistantSideRailCollapsed = () => useRecoilState(assistantSideRailCollapsed);
export const useSetAssistantSideRailCollapsed = () => useSetRecoilState(assistantSideRailCollapsed);
