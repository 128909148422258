import * as simulationpb from '../../proto/client/simulation_pb';
import { useProjectContext } from '../context/ProjectContext';

import * as flags from '@/flags';
import { useIsEnabled } from '@/recoil/useExperimentConfig';
import { useIsLMA } from '@/recoil/useIsLMA';

export const useDisabledGradientMethods = () => {
  const { projectId, workflowId, jobId } = useProjectContext();

  const isLMA = useIsLMA({ projectId, workflowId, jobId });
  const isAllTetEnabled = useIsEnabled(flags.allTet);

  const disableGradientMethods = (isLMA && isAllTetEnabled);
  return disableGradientMethods ?
    Object.keys(simulationpb.GradientMethod).map(Number).filter((value) => {
      const numericValue = +value;

      // enums as object contain pair of both: keys and values, skip the keys:
      const isEnumKey = Number.isNaN(numericValue);
      if (isEnumKey) {
        return false;
      }

      return numericValue !== simulationpb.GradientMethod.NODAL_GRADIENT;
    }) : [];
};
