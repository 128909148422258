// PhysicalBehavior with hooks for cell data
// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MultipleChoiceParam } from '../../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../../SimulationParamDescriptor';
import { createParamScope } from '../../../lib/ParamScope';
import { newProto, normalToEuler, rotate } from '../../../lib/Vector';
import { adVec3ToVec3, getAdValue, newAdFloat } from '../../../lib/adUtils';
import assert from '../../../lib/assert';
import { prefixNameGen, uniqueSequenceName } from '../../../lib/name';
import { Logger } from '../../../lib/observability/logs';
import { findParticleGroupById, particleGroupTypeLabel } from '../../../lib/particleGroupUtils';
import {
  PHYSICAL_BEHAVIOR_LABEL,
  compatiblePhysicalBehaviorModelTypes,
  findPhysicalBehaviorById,
  getParticleGroupMapByPhysicalBehavior,
  setParticleGroupsForBehavior,
} from '../../../lib/physicalBehaviorUtils';
import { newNodeId } from '../../../lib/projectDataUtils';
import {
  getCompatibleTablesMap,
  hasKeyReference,
  updateEntryReference,
} from '../../../lib/rectilinearTable/globalMap';
import {
  AirfoilPerformanceTableDefinition,
} from '../../../lib/rectilinearTable/model';
import { checkRadialStation } from '../../../lib/rectilinearTable/util';
import { useNodePanel } from '../../../lib/useNodePanel';
import * as simulationpb from '../../../proto/client/simulation_pb';
import * as ParaviewRpc from '../../../pvproto/ParaviewRpc';
import { useEnabledExperiments } from '../../../recoil/useExperimentConfig';
import { useSimulationParamScope } from '../../../state/external/project/simulation/paramScope';
import { CollapsiblePanel } from '../../Panel/CollapsiblePanel';
import ParamRow from '../../ParamRow';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { createStyles, makeStyles } from '../../Theme';
import Divider from '../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../Theme/commonStyles';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { EditableText } from '../../controls/EditableText';
import { ListItemId, ListManager } from '../../controls/ListManager';
import { ChangeOperation } from '../../controls/TableMapInput';
import { useGetPhysicalBehaviorCellProps } from '../../hooks/physicalBehavior/useGetPhysicalBehaviorCellProps';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { AirfoilConfig } from '../../project/AirfoilConfig';
import { Attribute, AttributesDisplay } from '../AttributesDisplay';
import { NodeSubselect } from '../NodeSubselect';
import PropertiesSection from '../PropertiesSection';

import { NodeType } from '@/lib/simulationTree/node';
import { NodeFilter, useSimulationTreeSubselect } from '@/recoil/simulationTreeSubselect';

const logger = new Logger('propPanel/PhysicalBehavior');

const useStyles = makeStyles(
  () => createStyles({
    rootWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'flex-start',
      gap: '8px',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    titleText: {
      fontSize: '13px',
      fontWeight: '400',
      color: 'var(--color-low-emphasis-text)',
      flex: '0 0 auto',
    },
    textWrapper: {
      padding: '1px',
      overflow: 'hidden',
      display: 'flex',
      gap: '2px',
      alignItems: 'center',
    },
  }),
  {
    name: 'AirfoilName',
  },
);

type ValueUpdater = (
  behavior: simulationpb.PhysicalBehavior,
  newParam: simulationpb.SimulationParam,
) => void;

const {
  ACTUATOR_DISK_BLADE_ELEMENT,
} = simulationpb.ActuatorDiskModel;
const { ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR } = simulationpb.ActuatorDiskOrientationSelection;

const getPhysicalBehaviorModelDisplay = (value: simulationpb.PhysicalBehaviorModel) => {
  const { choices } = paramDesc[ParamName.PhysicalBehaviorModel] as
    MultipleChoiceParam;
  const model = choices.find((choice) => choice.enumNumber === value);

  return model ? model.text : '';
};

interface PhysicalBehaviorModelDisplayProps {
  model: simulationpb.PhysicalBehaviorModel;
}

// Displays the physical behavior type in a unique layout
export const PhysicalBehaviorModelDisplay = (props: PhysicalBehaviorModelDisplayProps) => {
  const { model } = props;

  const attributes: Attribute[] = [
    {
      label: 'Type',
      value: getPhysicalBehaviorModelDisplay(model),
    },
  ];

  return (
    <AttributesDisplay attributes={attributes} />
  );
};

// A panel displaying all the settings for the selected node.
export const PhysicalBehaviorPropPanel = () => {
  const { paraviewClientState, viewState } = useParaviewContext();
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected physical behavior row');

  const experimentConfig = useEnabledExperiments();
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const treeSubselect = useSimulationTreeSubselect();
  const { simParam, saveParam } = useSimulationConfig();
  const behavior = useMemo(() => findPhysicalBehaviorById(simParam, node.id), [node.id, simParam]);
  assert(!!behavior, 'No selected physical behavior');
  const behaviorScope = useMemo(
    () => createParamScope(behavior, experimentConfig, paramScope),
    [behavior, experimentConfig, paramScope],
  );

  const airfoilData = useMemo(() => behavior.bladeElementAirfoilData || [], [behavior]);

  const generalPanel = useNodePanel(node.id, 'general');
  const meshesPanel = useNodePanel(node.id, 'meshes');
  const bladeDefnPanel = useNodePanel(node.id, 'bladeDefinition');
  const advancedPanel = useNodePanel(node.id, 'advanced');

  const getCellProps = useGetPhysicalBehaviorCellProps(simParam, {
    general: readOnly || generalPanel.collapsed,
    blade: readOnly || bladeDefnPanel.collapsed,
    advanced: readOnly || advancedPanel.collapsed,
  });

  const updateField = useCallback((updateValue: ValueUpdater) => {
    saveParam((newParam) => {
      const newBehavior = findPhysicalBehaviorById(newParam, node.id);
      if (newBehavior) {
        updateValue(newBehavior, newParam);
      }
    });
  }, [node.id, saveParam]);

  // Issue an rpc to paraview to ensure we are no longer displaying the airfoil.
  const deleteImposters = () => {
    if (paraviewClientState.client && viewState) {
      ParaviewRpc.clearairfoilsource(paraviewClientState.client).catch((reason: any) => {
        logger.error('Failed to clearairfoilsource: ', reason);
      });
    }
  };

  const changeTableField = useCallback((
    value: ChangeOperation,
    saveName: (newBehavior: simulationpb.PhysicalBehavior, name: string) => void,
  ) => {
    updateField((newBehavior, newParam) => {
      const metadata = 'metadata' in value ? value.metadata : undefined;
      const link = updateEntryReference(value.type, value.name, newParam, metadata);
      if (link) {
        saveName(newBehavior, value.name as string);
      }
    });
  }, [updateField]);

  const changeAirfoilStation = useCallback((index: number, value: number) => {
    updateField((newBehavior) => {
      const airfoil = newBehavior.bladeElementAirfoilData[index];
      if (airfoil) {
        airfoil.airfoilRadialStation = newAdFloat(value);
      }
    });
  }, [updateField]);

  const changeAirfoilPerformance = useCallback((index: number, value: ChangeOperation) => {
    changeTableField(
      value,
      (newBehavior, tableName) => {
        const airfoil = newBehavior.bladeElementAirfoilData[index];
        if (airfoil) {
          airfoil.airfoilPerformanceData = tableName;
        }
      },
    );
  }, [changeTableField]);

  const [editingId, setEditingId] = useState<string | null>(null);

  // We need a memo here because `getParticleGroupMapByPhysicalBehavior` creates a new map
  // every time it is called (even if params and node.id have not changed). This would trigger
  // unncessary rerenders if the returned value is used as a prop for other components or as
  // dependency for hooks.
  const particleGroupIds = useMemo(() => {
    const particleGroupMap = getParticleGroupMapByPhysicalBehavior(simParam, node.id);
    return Object.keys(particleGroupMap);
  }, [simParam, node.id]);

  // Display annotations for airfoil radial stations in Paraview.
  const displayAirfoil = useCallback(() => {
    if (airfoilData.length === 0) {
      return;
    }

    let points: ParaviewRpc.AirfoilPoint[] = [];
    points = airfoilData.map((value: simulationpb.BladeElementAirfoilData, index: number) => ({
      radialstation: getAdValue(value.airfoilRadialStation),
      id: value.airfoilId,
      // name: value.airfoilName, This is blank '', so create some other name.
      name: `A${index + 1}`,
    }));

    // Get a list of actuator disks associated with this physical behavior.
    const diskGroup: simulationpb.ParticleGroup[] = [];
    particleGroupIds.forEach((value: string) => {
      const group = findParticleGroupById(simParam, value);
      if (group?.particleGroupType === simulationpb.ParticleGroupType.ACTUATOR_DISK) {
        diskGroup.push(group);
      }
    });

    // Gather up all the disk params from all associated actuator disks.
    const radii: number[] = [];
    const planes: ParaviewRpc.PlaneParam[] = [];
    const textDirs: ParaviewRpc.Vector3[] = [];
    diskGroup.forEach((value: simulationpb.ParticleGroup) => {
      radii.push(getAdValue(value.actuatorDiskOuterRadius));
      const useNormal = (
        value.actuatorDiskOrientationSelection === ACTUATOR_DISK_SPECIFY_NORMAL_VECTOR
      );
      const normal = adVec3ToVec3(value.actuatorDiskNormalVector!);
      const euler = adVec3ToVec3(value.actuatorDiskRotationAngle!);
      let rot = euler;
      if (useNormal) {
        rot = normalToEuler(normal);
      }
      // What we want here is the a direction vector that is consistent for
      // every disk. The vector represents the line on which we place the
      // radial stations.  For the Euler angles, the +Z is the base normal
      // vector(i.e., up), so we will choose x+ for the radial station line.
      const xHat = newProto(1, 0, 0);
      const zHat = newProto(0, 0, 1);
      const lineDir = rotate(rot, xHat);
      // We need the 'up' direction to place the text annotations.
      const textdir = rotate(rot, zHat);
      textDirs.push({ ...textdir });

      // The plane params here are not a plane exactly.
      // origin is the center of the disk and the normal is the direction
      // of the radial station annotations.
      planes.push({
        typ: 'Plane',
        origin: {
          // We already checked to make sure this was a disk so
          // this data should never be the || condition.
          x: getAdValue(value.actuatorDiskCenter?.x) || 0,
          y: getAdValue(value.actuatorDiskCenter?.y) || 0,
          z: getAdValue(value.actuatorDiskCenter?.z) || 0,
        },
        normal: { ...lineDir },
      });
    });

    const imposters: ParaviewRpc.AirfoilParam = {
      planes,
      radii,
      textDirs,
      points,
    };

    if (viewState && paraviewClientState.client) {
      ParaviewRpc.setairfoilsource(paraviewClientState.client, imposters).catch((reason: any) => {
        logger.error('Failed to setairfoilsource: ', reason);
      });
    }
  }, [airfoilData, paraviewClientState.client, particleGroupIds, simParam, viewState]);

  const setParticleGroups = useCallback(async (newSelection: string[]) => {
    saveParam((newParam) => {
      setParticleGroupsForBehavior(
        newSelection,
        node.id,
        newParam,
      );
    });
  }, [node.id, saveParam]);

  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId: string) => {
    if (nodeType === NodeType.PARTICLE_GROUP) {
      let disabledReason = '';
      const particleGroup = findParticleGroupById(simParam, nodeId);

      if (!particleGroup) {
        // That't mostly for pleasing TS as there shouldn't be a case where we have a Disk
        // node in the tree but not a particle group in the simParam for it
        return { related: true };
      }

      const {
        particleGroupName,
        particleGroupType,
        particleGroupBehaviorModelRef,
      } = particleGroup;

      const validModelTypes = compatiblePhysicalBehaviorModelTypes(particleGroupType);
      if (!validModelTypes.includes(behavior.physicalBehaviorModel)) {
        const typeLabel = particleGroupTypeLabel(particleGroupType).toLocaleLowerCase();
        disabledReason = `Type of ${particleGroupName} (${typeLabel}) is incompatible with
          this ${PHYSICAL_BEHAVIOR_LABEL}.`;
      }

      if (
        particleGroupBehaviorModelRef &&
        particleGroupBehaviorModelRef !== behavior.physicalBehaviorId
      ) {
        disabledReason = `${particleGroupName} is attached to another physical model.`;
      }

      return {
        related: true,
        disabled: !!disabledReason,
        tooltip: disabledReason,
      };
    }

    return {
      related: true,
      disabled: true,
    };
  }, [simParam, behavior.physicalBehaviorId, behavior.physicalBehaviorModel]);

  // Create a use effect so we can clean up the imposter when this functional
  // component is unmounted.
  useEffect(() => () => {
    if (airfoilData.length === 0) {
      return;
    }
    deleteImposters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renameAirfoil = useCallback((airfoilId: string, newName: string) => {
    updateField((newBehavior) => {
      const airfoil = newBehavior.bladeElementAirfoilData.find(
        (item) => item.airfoilId === airfoilId,
      );
      if (airfoil) {
        airfoil.airfoilName = newName;
      }
    });
  }, [updateField]);

  const classes = useStyles();

  const airfoilProtoToListConfig = useCallback(
    (airfoil: simulationpb.BladeElementAirfoilData, index: number) => {
      const airfoilId = airfoil.airfoilId;
      return {
        id: airfoilId,
        content: {
          title: `Airfoil A${index + 1}`,
          titleContent: (
            <div className={classes.rootWrapper}>
              <div className={classes.titleText}>
                {`A${index + 1}`}
              </div>
              <EditableText
                active={editingId === airfoilId}
                editButton
                onChange={(name) => {
                  renameAirfoil(airfoilId, name);
                  setEditingId(null);
                }}
                onDoubleClick={() => setEditingId(airfoilId)}
                truncate
                value={airfoil.airfoilName}
              />
            </div>
          ),
          body: (
            <AirfoilConfig
              airfoil={airfoil}
              onChangePerformance={
                (value: ChangeOperation) => changeAirfoilPerformance(index, value)
              }
              onChangeStation={(value: number) => changeAirfoilStation(index, value)}
              projectId={projectId}
              readOnly={readOnly}
              tableMapOptions={{
                dialogTitle: 'Aero File Upload',
                dialogSubtitle: (
                  <>
                    <div>
                      Upload a C81 or LC81 file describing airfoil performance. Click
                      <a
                        href="https://docs.luminarycloud.com/en/articles/9358275-disk-models"
                        rel="noopener noreferrer"
                        style={{ color: 'var(--color-primary)' }}
                        target="_blank">
                        {' here '}
                      </a>
                      for additional documentation.
                    </div>
                  </>),
                tableDefinition: AirfoilPerformanceTableDefinition,
                tableMap: getCompatibleTablesMap(simParam, AirfoilPerformanceTableDefinition),
                tableErrorFunc: (table) => checkRadialStation(table, 0),
                nameErrorFunc: (name) => {
                  if (hasKeyReference(simParam, name)) {
                    return 'Name is already in use';
                  }
                  return '';
                },
                uploadOptions: {
                  extensions: ['C81', 'LC81'],
                  inputAccept: '.c81, .lc81',
                },
                unlinkTooltip: 'Unlink aero file',
              }}
            />
          ),
        },
      };
    },
    [changeAirfoilPerformance,
      changeAirfoilStation,
      classes.rootWrapper,
      classes.titleText,
      editingId,
      projectId,
      readOnly,
      renameAirfoil,
      simParam,
    ],
  );

  const getNewAirfoilName = (behav: simulationpb.PhysicalBehavior) => {
    const currentNames = behav.bladeElementAirfoilData.map((airfoil) => airfoil.airfoilName);
    return uniqueSequenceName(currentNames, prefixNameGen('Airfoil'));
  };

  const addNewAirfoil = () => {
    updateField((newBehavior) => {
      newBehavior.bladeElementAirfoilData.push(new simulationpb.BladeElementAirfoilData({
        airfoilId: newNodeId(),
        airfoilName: getNewAirfoilName(newBehavior),
      }));
    });
  };

  const deleteAirfoil = (id: ListItemId) => {
    updateField((newBehavior) => {
      newBehavior.bladeElementAirfoilData = newBehavior.bladeElementAirfoilData.filter(
        (item) => item.airfoilId !== id,
      );
    });
  };

  const reorderAirfoil = (id: ListItemId, raiseIndex: boolean) => {
    updateField((newBehavior) => {
      const airfoils = newBehavior.bladeElementAirfoilData;

      const itemIdx = airfoils.findIndex((item) => item.airfoilId === id);
      const swapIdx = raiseIndex ? itemIdx + 1 : itemIdx - 1;

      if (swapIdx >= 0 && swapIdx < airfoils.length) {
        [airfoils[itemIdx], airfoils[swapIdx]] = [airfoils[swapIdx], airfoils[itemIdx]];
      }

      newBehavior.bladeElementAirfoilData = airfoils;
    });
  };

  const airfoilsAreSorted = airfoilData.every((item, i) => {
    if (i === 0) {
      return true;
    }

    const lastItem = airfoilData[i - 1];
    const station = getAdValue(item.airfoilRadialStation);
    const prevStation = getAdValue(lastItem.airfoilRadialStation);
    return station >= prevStation;
  });

  const getSortedAirfoilIds = () => {
    const reordered = airfoilData.map((airfoil) => ({
      id: airfoil.airfoilId,
      station: getAdValue(airfoil.airfoilRadialStation),
    }));

    reordered.sort((a, b) => (a.station - b.station));

    return reordered.map((data) => data.id);
  };

  const sortAirfoilData = () => {
    updateField((newBehavior) => {
      const airfoils = newBehavior.bladeElementAirfoilData;
      airfoils.sort((a, b) => {
        const stationA = getAdValue(a.airfoilRadialStation);
        const stationB = getAdValue(b.airfoilRadialStation);
        return stationA - stationB;
      });

      newBehavior.bladeElementAirfoilData = airfoils;
    });
  };

  useEffect(() => {
    // Note: this condition relies on the behavior to call deleteImposters() on
    // any change to the disk model (i.e., uniform thrust, blade element, ...).
    // This was done this way to minimize the rpc calls we issue.
    if (behavior.actuatorDiskModel === ACTUATOR_DISK_BLADE_ELEMENT) {
      displayAirfoil();
    }
  }, [behavior, displayAirfoil]);

  const airfoilItems = useMemo(() => {
    if (behavior.actuatorDiskModel === ACTUATOR_DISK_BLADE_ELEMENT) {
      return behavior.bladeElementAirfoilData.map(airfoilProtoToListConfig);
    }
    return [];
  }, [airfoilProtoToListConfig, behavior]);

  const cellProps = getCellProps(
    behavior,
    behaviorScope,
    updateField,
    changeTableField,
    deleteImposters,
  );

  const commonClasses = useCommonTreePropsStyles();

  return (
    <div className={commonClasses.properties}>
      {behavior && (
        <>
          <PhysicalBehaviorModelDisplay model={behavior.physicalBehaviorModel} />
          {!!cellProps.general.length && (
            <>
              <Divider />
              <PropertiesSection>
                <CollapsiblePanel
                  collapsed={generalPanel.collapsed}
                  heading="General"
                  onToggle={generalPanel.toggle}>
                  {cellProps.general.map((modelCellProp) => (
                    <ParamRow key={`${node.id}-${modelCellProp.param.name}`} {...modelCellProp} />
                  ))}
                </CollapsiblePanel>
              </PropertiesSection>
            </>
          )}
          {!!cellProps.blade.length && (
            <>
              <Divider />
              <PropertiesSection>
                <CollapsiblePanel
                  collapsed={bladeDefnPanel.collapsed}
                  heading="Blade Definition"
                  onToggle={bladeDefnPanel.toggle}>
                  {cellProps.blade.map((bladeProps) => (
                    <ParamRow key={`${node.id}-{bladeProps.param.name}`} {...bladeProps} />
                  ))}
                  <div style={{ paddingTop: '8px' }}>
                    <ListManager
                      animateNew={false}
                      disabled={readOnly}
                      itemLabel="airfoil"
                      items={airfoilItems}
                      onAdd={addNewAirfoil}
                      onDelete={deleteAirfoil}
                      onReorder={reorderAirfoil}
                      sorting={{
                        isSorted: airfoilsAreSorted,
                        getSortedIds: getSortedAirfoilIds,
                        sortItems: sortAirfoilData,
                        help: 'Click to sort by r/R',
                      }}
                    />
                  </div>
                </CollapsiblePanel>
              </PropertiesSection>
            </>
          )}
          {!!cellProps.advanced.length && (
            <>
              <Divider />
              <PropertiesSection>
                <CollapsiblePanel
                  collapsed={advancedPanel.collapsed}
                  heading="Advanced"
                  onToggle={advancedPanel.toggle}>
                  <div>
                    {cellProps.advanced.map((advancedCellProp) => (
                      <ParamRow
                        key={`${node.id}-${advancedCellProp.param.name}`}
                        {...advancedCellProp}
                      />
                    ))}
                  </div>
                </CollapsiblePanel>
              </PropertiesSection>
            </>
          )}
          <Divider />
          <PropertiesSection>
            <CollapsiblePanel
              collapsed={meshesPanel.collapsed}
              disabled={treeSubselect.id === 'physical-behavior-particle-groups'}
              heading="Geometry"
              onToggle={meshesPanel.toggle}>
              <div>
                <NodeSubselect
                  id="physical-behavior-particle-groups"
                  labels={['disks']}
                  nodeFilter={nodeFilter}
                  nodeIds={particleGroupIds}
                  onChange={setParticleGroups}
                  readOnly={meshesPanel.collapsed || readOnly}
                  referenceNodeIds={[node.id]}
                  title="Disks"
                  visibleTreeNodeTypes={[NodeType.PARTICLE_GROUP]}
                />
              </div>
            </CollapsiblePanel>
          </PropertiesSection>
        </>
      )}
    </div>
  );
};
