// Copyright 2023 Luminary Cloud, Inc. All Rights Reserved.
import { ColumnState, RawValue, RowConfig } from '../../../lib/componentTypes/table';
import { EMPTY_VALUE } from '../../../lib/constants';

function compareNumberValues(a: number, b: number) {
  if (Number.isNaN(a) || Number.isNaN(b)) {
    if (Number.isNaN(a) && Number.isNaN(b)) {
      return 0;
    }
    return Number.isNaN(a) ? -1 : 1;
  }

  return a - b;
}

function compareBooleanValues(a: boolean, b: boolean) {
  if (a === b) {
    return 0;
  }
  return a ? -1 : 1;
}

function compareStringValues(a: string, b: string) {
  // Prioritize empty values "—", placing them at the beginning
  if (a === EMPTY_VALUE && b !== EMPTY_VALUE) {
    // 'a' is an empty value, should come first
    return -1;
  }
  if (b === EMPTY_VALUE && a !== EMPTY_VALUE) {
    // 'b' is an empty value, should come first
    return 1;
  }

  // If both values are valid numbers, compare them numerically. We need this step to make sure we
  // sort negative numeric strings properly (i.e. '-10' coming before '-2').
  const numericA = Number(a);
  const numericB = Number(b);
  if (!Number.isNaN(numericA) && !Number.isNaN(numericB)) {
    return numericA - numericB;
  }

  // If either value is not a number, compare them as strings.
  // The { numeric: true } makes sure we handle alphanumeric sorting correctly.
  return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase(), undefined, { numeric: true });
}

function parseString(val: RawValue) {
  if (val === null || val === undefined) {
    return '';
  }
  return `${val}`;
}

export function sortRows(
  rowData: RowConfig[],
  columnState: ColumnState,
  descending = false,
): RowConfig[] {
  const rows = rowData.slice();

  rows.sort((a, b) => {
    const valA = a.values[columnState.config.id];
    const valB = b.values[columnState.config.id];
    switch (columnState.config.type) {
      case 'number':
        return compareNumberValues(Number(valA), Number(valB));
      case 'boolean':
        return compareBooleanValues(!!valA, !!valB);
      default:
        return compareStringValues(parseString(valA), parseString(valB));
    }
  });

  if (descending) {
    rows.reverse();
  }

  return rows;
}
