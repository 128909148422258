// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useEffect } from 'react';

import {
  CallbackInterface,
  DefaultValue,
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import GroupMap from '../lib/GroupMap';
import {
  CAMERA_LABEL_SEPARATOR,
  cameraIndexFromNodeId,
  cameraNodeId,
  generateCameraGroupId,
  generateCameraLabel,
  getGroupIdFromLabel,
  getGroupNameFromLabel,
  getParentNodeIdForCamera,
  getUpdatedCameraLabel,
} from '../lib/cameraUtils';
import { getAspectRatio, lcvisCameraToParaview } from '../lib/lcvis/lcvisUtils';
import { prefixNameGen, uniqueSequenceName } from '../lib/name';
import { NodeGroupData, NodeGroupMap } from '../lib/nodeGroupMap';
import { fromBigInt } from '../lib/number';
import { Logger } from '../lib/observability/logs';
import { RecoilProjectKey } from '../lib/persist';
import * as rpc from '../lib/rpc';
import { NodeType } from '../lib/simulationTree/node';
import { isTestingEnv } from '../lib/testing/utils';
import { addError } from '../lib/transientNotification';
import { getCurrentVisUrl } from '../lib/visUtils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { lcvisCameraState } from './lcvis/lcvisCameraState';
import { lcVisEnabledSelector } from './lcvis/lcvisEnabledState';
import { makeLcvMeshKeys } from './lcvis/lcvisPersistUtils';
import { meshMetadataSelector } from './meshState';
import { cameraPositionState, newMeshKeys } from './paraviewState';
import { activeVisUrlState } from './vis/activeVisUrl';
import { useWorkflowState } from './workflowState';

import { pickAnyJobId } from '@/lib/workflowUtils';

const logger = new Logger('recoil/cameraState');

const MAX_GROUPING_LEVEL = 2;

const { CameraAccess } = frontendpb;

function rpcListCameras(projectId: string): Promise<frontendpb.ListCamerasReply> {
  const req = new frontendpb.ListCamerasRequest({ projectId });
  return rpc.callRetry('ListCameras', rpc.client.listCameras, req);
}

function rpcAddCamera(camera: frontendpb.CameraInfo, position: ParaviewRpc.CameraState | null) {
  const { name, projectId, cameraAccess } = camera;
  const req = new frontendpb.AddCameraRequest({
    projectId,
    name,
    cameraAccess,
    label: '',
    cameraJson: JSON.stringify(position),
  });
  return rpc.callRetry('AddCamera', rpc.client.addCamera, req);
}

function rpcUpdateCameraInfo(camera: frontendpb.CameraInfo) {
  const { cameraId, name, label, cameraAccess } = camera;
  const req = new frontendpb.UpdateCameraInfoRequest({ cameraId, name, label, cameraAccess });
  return rpc.callRetry('UpdateCameraInfo', rpc.client.updateCameraInfo, req);
}

function rpcDeleteCamera(camera: frontendpb.CameraInfo) {
  const { cameraId } = camera;
  const req = new frontendpb.DeleteCameraRequest({ cameraId });
  return rpc.callRetry('DeleteCamera', rpc.client.deleteCamera, req);
}

function rpcGetCamera(cameraId: number) {
  const req = new frontendpb.GetCameraRequest({ cameraId: BigInt(cameraId) });
  return rpc.callRetry('GetCamera', rpc.client.getCamera, req).then(
    (response) => {
      const camera = response.cameraJson;
      // We need to make sure that the types match up.
      return JSON.parse(camera) as ParaviewRpc.CameraState;
    },
  );
}

// The default state here will cause the listCameras rpc to be called twice when opening a project
// for the first time, but we need the call in the ProjectPage.tsx to refresh the state and avoid
// stale data when opening the project and we also need the default state here to guarantee
// that we have a state in case the effect in the ProjectPage fails for some reason.
export const cameraListState = atomFamily<frontendpb.CameraInfo[], RecoilProjectKey>({
  key: 'cameraListState',
  default: async (key: RecoilProjectKey) => {
    if (isTestingEnv()) {
      return [];
    }
    const projectId = key.projectId;
    const reply = await rpcListCameras(projectId);
    if (!reply.cameras) {
      throw Error(`No cameras received for project ${projectId}`);
    }
    return reply.cameras;
  },
  effects: (key: RecoilProjectKey) => [
    ({ onSet, setSelf, getPromise }) => {
      // Listen for changes to the recoil state and if some camera has been added, updated or
      // removed, fire a corresponding RPC request to save the change.
      onSet((newValue, oldValue) => {
        const { projectId } = key;
        // This should not run on the initial load, but only after following recoil updates.
        if (!(oldValue instanceof DefaultValue)) {
          // Loop through the new cameras and find any new additions or updates
          newValue?.forEach(async (camera, index) => {
            // If there's a transient camera in the state, fire an AddCameraRequest to save it to
            // the DB and when the response is received, update the recoil state with the real data.
            if (camera.cameraId === 0n) {
              // If the AddCameraRequest is successful, use the returned data to update the
              // cameraId and createTime in the transient camera.
              const lcvisEnabled = await getPromise(lcVisEnabledSelector(projectId));
              let position: ParaviewRpc.CameraState | null = null;
              if (lcvisEnabled) {
                const activeVisUrl = await getPromise(activeVisUrlState(key));
                const aspectRatio = await getAspectRatio(projectId);
                if (aspectRatio === 0) {
                  logger.error('Could not get aspect ratio');
                } else {
                  const lcvisCam = await getPromise(
                    lcvisCameraState({
                      projectId,
                      lcvMeshKeys: makeLcvMeshKeys('lcvisCamera', activeVisUrl),
                    }),
                  );
                  position = lcvisCameraToParaview(lcvisCam, aspectRatio);
                }
              } else {
                const currentVisUrl = getCurrentVisUrl();
                const meshMetadata = await getPromise(meshMetadataSelector({
                  projectId,
                  meshUrl: currentVisUrl,
                }));
                position = await getPromise(
                  cameraPositionState({
                    projectId,
                    meshKeys: newMeshKeys('cameraPosition', currentVisUrl, meshMetadata),
                  }),
                );
              }

              if (position) {
                try {
                  const addReply = await rpcAddCamera(camera, position);
                  const cameraResponse = addReply.camera;
                  if (cameraResponse) {
                    camera.cameraId = cameraResponse.cameraId;
                    camera.createTime = cameraResponse.createTime;
                    camera.label = '';
                    setSelf([...newValue]);
                  }
                } catch (error) {
                  logger.error('Failed to save camera.', error);
                  setSelf([...oldValue]);
                }
              } else {
                addError('Failed to save camera.');
                logger.debug('Camera position was empty');
                setSelf([...oldValue]);
              }
            }
            const oldCamera = (oldValue as frontendpb.CameraInfo[]).find(
              (item) => item.cameraId === camera.cameraId,
            );

            // If the camera's name or label was changed in the recoil state, send an update RPC.
            if (oldCamera &&
              (camera.name !== oldCamera.name || camera.label !== oldCamera.label)
            ) {
              try {
                await rpcUpdateCameraInfo(camera);
              } catch (error) {
                logger.error(`Failed to update camera ${camera.cameraId}.`, error);
              }
            }

            // If the camera was marked for removal (with INVALID_ACCESS), send a remove RPC.
            if (oldCamera && camera.cameraAccess === CameraAccess.INVALID_ACCESS) {
              try {
                await rpcDeleteCamera(camera);
                // When the remove rpc finishes, remove the camera from the recoil list
                setSelf(newValue.filter((cam) => cam.cameraId !== oldCamera.cameraId));
              } catch (error) {
                logger.error(`Failed to delete camera ${camera.cameraId}.`, error);
                setSelf([...newValue, camera]);
              }
            }
          });
        }
      });
    },
  ],
  dangerouslyAllowMutability: true,
});

export const useCameraList = (key: RecoilProjectKey) => useRecoilState(
  cameraListState(key),
);
export const useSetCameraList = (key: RecoilProjectKey) => useSetRecoilState(
  cameraListState(key),
);
export const useCameraListValue = (key: RecoilProjectKey) => useRecoilValue(
  cameraListState(key),
);
export const useLoadCameraList = (key: RecoilProjectKey) => {
  const setCameraList = useSetCameraList(key);

  return useCallback(async () => {
    const projectId = key.projectId;
    const reply = await rpcListCameras(projectId);
    if (!reply.cameras) {
      throw Error(`No cameras received for project ${projectId}`);
    }
    setCameraList(reply.cameras);
  }, [key, setCameraList]);
};

export const useLoadCameraListEffect = (key: RecoilProjectKey) => {
  const workflow = useWorkflowState(key.projectId, key.workflowId);

  const jobId = (key.jobId || (workflow ? pickAnyJobId(workflow) : '')) ?? '';
  const loadCameraList = useLoadCameraList({ ...key, jobId });

  useEffect(() => {
    if (key.projectId) {
      loadCameraList().catch(() => {});
    }
  }, [key.projectId, loadCameraList]);
};

// This callback creates and adds a new transient camera to the recoil state. It will have an id
// of 0. When the recoil cameraListState detects that new camera (via the onSet callback) it will
// send an RPC request to save it to the DB. And when its response is received, the transient
// item in the state will be replaced with the response from the DB (which includes the real id).
export const saveCameraCallback = ({ snapshot: { getPromise }, set }: CallbackInterface) => async (
  key: RecoilProjectKey,
  cameraAccess: frontendpb.CameraAccess,
): Promise<frontendpb.CameraInfo | null> => {
  const cameraList = await getPromise(cameraListState(key));
  const getNewCameraName = () => {
    const currentNames = cameraList?.map((cam) => cam.name) ?? [];
    return uniqueSequenceName(currentNames, prefixNameGen('Camera'));
  };
  if (cameraList) {
    const newCamera = new frontendpb.CameraInfo({
      name: getNewCameraName(),
      projectId: key.projectId,
      cameraAccess,
    });
    set(cameraListState(key), [...cameraList, newCamera]);
    return newCamera;
  }
  return null;
};

export const cameraJsonState = atomFamily<ParaviewRpc.CameraState, number>({
  key: 'cameraJson',
  default: selectorFamily<ParaviewRpc.CameraState, number>({
    key: 'cameraJsonState/Default',
    get: (cameraId: number) => () => rpcGetCamera(cameraId),
    dangerouslyAllowMutability: true,
  }),
  dangerouslyAllowMutability: true,
});

// The frontendpb.CameraAccess has only LOCAL, GLOBAL and INVALID_ACCESS types as each camera can
// have only one of these values assigned. But when we construct the camera group map, we need
// either LOCAL, GLOBAL or all cameras. That's why we create a new enum that will be used
// exclusively for the group map and it partially extends the frontendpb.CameraAccess.
export enum CameraGroupMapAccessType {
  ALL = -10,
  LOCAL = CameraAccess.LOCAL,
  GLOBAL = CameraAccess.GLOBAL,
}

const filterCamerasByAccessType = (
  cameras: frontendpb.CameraInfo[],
  cameraAccess: CameraGroupMapAccessType,
) => {
  switch (cameraAccess) {
    case CameraGroupMapAccessType.ALL:
      return cameras;
    case CameraGroupMapAccessType.LOCAL:
      return cameras.filter((camera) => camera.cameraAccess === CameraAccess.LOCAL);
    case CameraGroupMapAccessType.GLOBAL:
      return cameras.filter((camera) => camera.cameraAccess === CameraAccess.GLOBAL);
    default:
      throw Error(`Invalid CameraGroupMapAccessType: ${cameraAccess}`);
  }
};

export type CameraGroupMapProps = {
  key: RecoilProjectKey,
  cameraAccess: CameraGroupMapAccessType,
}

// Camera group related states
export const cameraGroupState = atomFamily<NodeGroupMap, CameraGroupMapProps>({
  key: 'cameraGroupSelector',
  default: selectorFamily<NodeGroupMap, CameraGroupMapProps>({
    key: `cameraGroupSelector/default`,
    get: ({ key, cameraAccess }: CameraGroupMapProps) => ({ get }) => {
      // Get the cameras and sort them by id (this will effect sort them by order of creation).
      // Ignore the transient camera with id=0 while sorting or otwherise it will be shown
      // temporarily at the top, until the real id comes from the db and it moves to the bottom.
      const camerasList = get(cameraListState(key)).sort(
        (a, b) => (a.cameraId === 0n ? 0 : fromBigInt(a.cameraId - b.cameraId)),
      );

      // The cameraList always contains alls cameras for the user in that project.
      // But the group map is usually filtered by the access type because we use the group map to
      // render the cameras in the tree or in the camera dropdown.
      const filteredCameras = filterCamerasByAccessType(camerasList, cameraAccess);

      // The camera groups are not saved anywhere. When a group is "created", we generate an id and
      // a name and update the label field for the grouped cameras to be "<groupid>⋮⋮⋮<groupname>".
      // Then we derive a cameraGroupMap from these camera labels.
      // The groupid is timestamp based so that we can sort the groups by their order of creation
      // and each label will look like "group-1691149023223⋮⋮⋮Group name".
      const cameraGroupMap = new NodeGroupMap();

      const cameraLabels = [
        ...new Set(filteredCameras?.map((cam) => cam.label).filter((label) => label !== '')),
      ];

      const derivedGroupsData = cameraLabels.map((label) => ({
        id: getGroupIdFromLabel(label),
        name: getGroupNameFromLabel(label),
      }));
      // Sort the groups by their id (which is in effect their order of creation).
      derivedGroupsData.sort((a, b) => (a.id < b.id ? -1 : 1));

      // Get the cameras for each group and put them into the cameraGroup NodeGroupMap
      derivedGroupsData.forEach((group) => {
        const groupChildren = filteredCameras?.filter(
          (camera) => getGroupIdFromLabel(camera.label) === group.id,
        ).map((camera) => cameraNodeId(camera.cameraId)) || [];

        // Add each derived group to the cameraGroupMap
        cameraGroupMap.add({
          id: group.id,
          name: group.name,
          parentId: GroupMap.rootId,
          children: new Set(groupChildren),
          nodeType: NodeType.CAMERA_GROUP,
          maxLevel: MAX_GROUPING_LEVEL,
        });
      });

      // Add each camera to the cameraGroupMap. This should happen after we've added the
      // derived groups because we need to lookup for the id of the newly generated groups.
      filteredCameras?.forEach((camera) => {
        cameraGroupMap.add({
          id: cameraNodeId(camera.cameraId),
          name: camera.name,
          parentId: getParentNodeIdForCamera(camera, cameraGroupMap),
          children: new Set([]),
          item: camera,
          nodeType: NodeType.CAMERA,
          maxLevel: MAX_GROUPING_LEVEL,
        });
      });

      return cameraGroupMap;
    },
  }),
});

export const useCameraGroupMap = (
  key: RecoilProjectKey,
  cameraAccess: CameraGroupMapAccessType,
) => useRecoilValue(cameraGroupState({ key, cameraAccess }));

export const cameraGroupDataState = selectorFamily<NodeGroupData, CameraGroupMapProps>({
  key: 'cameraGroupDataSelector',
  get: (key) => ({ get }) => {
    const groupMap = get(cameraGroupState(key));
    return {
      groupMap,
      leafMap: groupMap.createLeafMap(),
    };
  },
});

export const useCameraGroupData = (
  key: RecoilProjectKey,
  cameraAccess: CameraGroupMapAccessType,
) => useRecoilValue(cameraGroupDataState({ key, cameraAccess }));

// Returns a callback to group cameras. onNewGroup is called when the new group is created.
// By default the new group gets a random id assigned.
export const useGroupCameras = (
  key: RecoilProjectKey,
  onGroup?: (newGroupId: string) => void,
) => useRecoilCallback(({
  set,
  snapshot: { getPromise },
}) => async (selectedNodeIds: string[]) => {
  const cameraGroupMap = await getPromise(cameraGroupState({
    key,
    cameraAccess: CameraGroupMapAccessType.ALL,
  }));

  // The new group should be timestamp based so that we can order the groups by order of creation.
  const newGroupId = generateCameraGroupId();
  const newGroupName = uniqueSequenceName(
    cameraGroupMap.getGroups(((group) => group.children.size > 0)).map((group) => group.name),
    (count: number) => `Group ${count}`,
  );

  // Update the cameras state
  set(cameraListState(key), ((oldList) => oldList?.map((camera) => {
    if (selectedNodeIds.includes(cameraNodeId(camera.cameraId))) {
      const newCamera = camera.clone();
      newCamera.label = generateCameraLabel({ id: newGroupId, name: newGroupName });
      return newCamera;
    }
    return camera;
  })));
  onGroup?.(newGroupId);
});

// Returns a callback to ungroup cameras. onUngroup is called when the new group is created and
// is used to return the ids of the ungrouped cameras so we can highlight them.
export const useUngroupCameras = (
  key: RecoilProjectKey,
  onUngroup?: (ungroupdIds: string[]) => void,
) => useRecoilCallback(({ set, snapshot: { getPromise } }) => async (groupId: string) => {
  const cameraGroupMap = await getPromise(cameraGroupState({
    key,
    cameraAccess: CameraGroupMapAccessType.ALL,
  }));

  if (cameraGroupMap.has(groupId)) {
    const childrenIds = cameraGroupMap.findDescendants(groupId);
    const nodeIdsToUngroup = childrenIds.length ? childrenIds : [groupId];

    // Update the cameras state
    set(cameraListState(key), ((oldList) => oldList?.map((cam) => {
      if (nodeIdsToUngroup.includes(cameraNodeId(cam.cameraId))) {
        const newCamera = cam.clone();
        newCamera.label = '';
        return newCamera;
      }
      return cam;
    })));
    onUngroup?.(nodeIdsToUngroup);
  }
});

export const useSetCameraName = (
  key: RecoilProjectKey,
) => useRecoilCallback(({ snapshot, set }) => async (nodeId: string, name: string) => {
  const cameraIndex = cameraIndexFromNodeId(nodeId);
  const cameraList = await snapshot.getPromise(cameraListState(key));
  const updatedList = cameraList.map((cam) => {
    if (cam.cameraId === cameraIndex) {
      const newCam = cam.clone();
      newCam.name = name;
      return newCam;
    }
    return cam;
  });
  set(cameraListState(key), updatedList);
});

export const useSetCameraGroupName = (
  key: RecoilProjectKey,
) => useRecoilCallback(({ snapshot, set }) => async (nodeId: string, name: string) => {
  name = name.replace(CAMERA_LABEL_SEPARATOR, '');
  const cameraList = await snapshot.getPromise(cameraListState(key));

  const updatedList = cameraList.map((cam) => {
    if (getGroupIdFromLabel(cam.label) === nodeId) {
      const newCam = cam.clone();
      newCam.label = getUpdatedCameraLabel(cam.label, name);
      return newCam;
    }
    return cam;
  });
  set(cameraListState(key), updatedList);
});
