// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

//
// A panel for setting exploration parameters, e.g., gridsearch vs latinhypercube.
//

import React, { useMemo, useState } from 'react';

import { useRecoilCallback } from 'recoil';

import * as flags from '../../../../flags';
import assert from '../../../../lib/assert';
import { SelectOption } from '../../../../lib/componentTypes/form';
import { TABLE_PREVIEW_SUBTITLE, resetColumnVariables } from '../../../../lib/explorationUtils';
import { updateExploration } from '../../../../lib/proto';
import { getCompatibleTablesMapExploration, hasKeyReferenceExploration } from '../../../../lib/rectilinearTable/globalMap';
import { CustomSampleDOETableDefintion } from '../../../../lib/rectilinearTable/model';
import * as explorationpb from '../../../../proto/exploration/exploration_pb';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { tableSelector } from '../../../../recoil/tableState';
import { useEnabledExperiments } from '../../../../recoil/useExperimentConfig';
import { useStaticVolumes } from '../../../../recoil/volumes';
import { useCurrentConfig, useSetProjectConfig } from '../../../../recoil/workflowConfig';
import { useSimulationParam } from '../../../../state/external/project/simulation/param';
import { useSimulationParamScope } from '../../../../state/external/project/simulation/paramScope';
import { ActionButton } from '../../../Button/ActionButton';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { NumberInput } from '../../../Form/NumberInput';
import { createStyles, makeStyles } from '../../../Theme';
import { useProjectContext } from '../../../context/ProjectContext';
import { ChangeOperation, TableMapInput } from '../../../controls/TableMapInput';
import { TablePreviewDialog } from '../../../dialog/TablePreviewDialog';
import PropertiesSection from '../../PropertiesSection';

type PolicyCase = explorationpb.Exploration['policy']['case'];

const useStyles = makeStyles(() => (createStyles({
  previewRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '6px',
  },
})), { name: 'ExplorationPolicyPanel' });

// A panel for setting latinhypercube config parameters.
const LatinHypercubeSettings = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const setProjectConfig = useSetProjectConfig(projectId);

  const config = useCurrentConfig(projectId, workflowId, jobId);
  const exploration = config.exploration!;
  assert(exploration.policy.case === 'latinHypercube', 'Policy type is not set to Latin hypercube');
  const lh = exploration.policy.value;
  const setNumSamples = (rawValue: number) => {
    const MIN_SAMPLES = 2;
    const MAX_SAMPLES = 1000;
    const value = Math.min(
      Math.max(Math.floor(rawValue), MIN_SAMPLES),
      MAX_SAMPLES,
    );
    const newExp = exploration.clone();
    assert(
      newExp.policy.case === 'latinHypercube',
      'New policy type is not set to Latin hypercube',
    );
    newExp.policy.value.nSamples = value;
    setProjectConfig(updateExploration(config, newExp));
  };
  return (
    <>
      <Form.LabeledInput
        help={'Total number of solver runs. It also sets the number of ' +
          'values generated for each variable'}
        label="Samples">
        <NumberInput
          asBlock
          disabled={readOnly}
          onCommit={setNumSamples}
          size="small"
          value={lh.nSamples}
        />
      </Form.LabeledInput>
    </>
  );
};

const ManualSettings = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const setProjectConfig = useSetProjectConfig(projectId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // == Hooks
  const classes = useStyles();

  // == State
  const [previewOpen, setPreviewOpen] = useState(false);

  // == Data
  const exploration = config.exploration!;
  assert(exploration.policy.case === 'custom', 'Policy type is not set to custom');
  const selectedTableName = exploration.policy.value.table;
  const selectedTableUrl = selectedTableName &&
    exploration.tableReferences[selectedTableName]?.url;

  const changePolicyTable = useRecoilCallback(({ snapshot }) => async (value: ChangeOperation) => {
    const newExp = exploration.clone();
    const newMetadata = 'metadata' in value ? value.metadata : undefined;
    const tableMap = newExp.tableReferences;
    if (newMetadata) {
      // if there is metadata, then this is a new table so it must be added to the table map
      tableMap[value.name] = newMetadata;
    }
    assert(newExp.policy.case === 'custom', 'New policy type is not set to custom');
    newExp.policy.value.table = value.name;
    // to get the URL, either use the new metadata or find the existing metadata
    const metadata = newMetadata ?? tableMap[value.name];
    const table = await snapshot.getPromise(tableSelector({
      projectId,
      tableUrl: metadata?.url ?? null,
    }));
    newExp.policy.value.nSamples = table?.record.length ?? 0;
    if (value.type === 'create-assign' || value.type === 'assign') {
      newExp.var.forEach((variable) => {
        // when a new table is assigned to the exploration, we must clear existing column
        // selections and set the new table name to the variables
        variable.valueTyp = {
          case: 'column',
          value: new explorationpb.TableColumn({ table: value.name }),
        };
      });
    }
    setProjectConfig(updateExploration(config, newExp));
  }, [exploration, projectId, setProjectConfig, config]);

  return (
    <>
      <Form.LabeledInput label="Samples">
        <TableMapInput
          dialogSubtitle={'Upload your specific samples that are essential for capturing the ' +
            'unique characteristics of your experiment and ensuring accurate results.'}
          dialogTitle="Sample Points"
          disabled={readOnly}
          disableHelp
          gridHeaderColumn
          gridPreview
          hidePreview
          nameErrorFunc={(name) => {
            if (hasKeyReferenceExploration(exploration, name)) {
              return 'Name is already in use';
            }
            return '';
          }}
          onChange={changePolicyTable}
          projectId={projectId}
          tableDefinition={CustomSampleDOETableDefintion}
          tableErrorFunc={(data) => {
            const hasEmptyRecord = data.record.some(
              ({ entry }) => entry.some(({ type }) => type.case === 'empty'),
            );

            return hasEmptyRecord ? 'CSV file cannot contain empty values.' : '';
          }}
          tableMap={getCompatibleTablesMapExploration(exploration, CustomSampleDOETableDefintion)}
          uploadOptions={{ inputAccept: '.csv' }}
          value={selectedTableName || ''}
        />
      </Form.LabeledInput>
      {selectedTableName && selectedTableUrl && (
        <div className={classes.previewRow}>
          <ActionButton
            compact
            disabled={readOnly}
            kind="minimal"
            onClick={() => setPreviewOpen(true)}>
            Preview
          </ActionButton>
          <TablePreviewDialog
            explorationPreview={{ exploration, paramScope }}
            headerColumn
            onClose={() => setPreviewOpen(false)}
            open={previewOpen}
            subtitle={TABLE_PREVIEW_SUBTITLE}
            tableName={selectedTableName}
            tableUrl={selectedTableUrl}
          />
        </div>
      )}
    </>
  );
};

export const ExplorationPolicyPanel = () => {
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const setProjectConfig = useSetProjectConfig(projectId);

  // Recoil
  const enabledExperiments = useEnabledExperiments();
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);

  // Data
  const isCustomDOEEnabled = enabledExperiments.includes(flags.customSampleDOE);
  const exploration = config.exploration!;
  const policyCase = exploration.policy.case;
  const onSetPolicy = (newPolicyCase: PolicyCase) => {
    if (policyCase === newPolicyCase) {
      return;
    }
    const newExp = exploration.clone();
    switch (newPolicyCase) {
      case 'gridSearch':
        newExp.policy = { case: 'gridSearch', value: new explorationpb.GridSearch() };
        break;
      case 'latinHypercube': {
        newExp.policy = {
          case: 'latinHypercube',
          value: new explorationpb.LatinHypercube({ nSamples: 4 }),
        };
        break;
      }
      case 'custom': {
        newExp.policy = { case: 'custom', value: new explorationpb.CustomSamples() };
        break;
      }
      default:
        throw Error(`invalid policy ${newPolicyCase}`);
    }
    if (policyCase === 'custom' || newPolicyCase === 'custom') {
      // when switching between custom and other policies, we must reset the variables as only
      // custom policy allows for column selection
      // if the new policy is not custom, the default variable type is range
      resetColumnVariables(simParam, paramScope, newExp, geometryTags, staticVolumes);
    }
    setProjectConfig(updateExploration(config, newExp));
  };

  const policyOptions = useMemo(() => {
    const selectedCase = policyCase === 'baseline' ? 'gridSearch' : policyCase;
    const options: SelectOption<PolicyCase>[] = [
      {
        value: 'gridSearch',
        name: 'Full sweep',
        tooltip: 'Explore all possible parameter value combinations',
        selected: selectedCase === 'gridSearch',
      },
      {
        value: 'latinHypercube',
        name: 'Latin hypercube',
        tooltip: 'Generate N values for each parameter, then randomly pair these values to ' +
          'produce N exploration runs',
        selected: selectedCase === 'latinHypercube',
      },
    ];
    if (isCustomDOEEnabled) {
      options.push({
        value: 'custom',
        tooltip: 'Unlike methods that generate sample matrices, this allows direct input of your ' +
          'specific data.',
        name: 'Manual',
        selected: selectedCase === 'custom',
      });
    }
    return options;
  }, [isCustomDOEEnabled, policyCase]);

  return (
    <PropertiesSection>
      <LabeledInput help="Policy for generating values to explore" label="Policy">
        <DataSelect
          asBlock
          disabled={readOnly}
          locator="Policy"
          onChange={onSetPolicy}
          options={policyOptions}
          size="small"
        />
      </LabeledInput>
      {policyCase === 'latinHypercube' && <LatinHypercubeSettings />}
      {policyCase === 'custom' && <ManualSettings />}
    </PropertiesSection>
  );
};
