// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export type FilterInfo = {
  status: 'queued' | 'completed' | 'error' | number;
  error: string;
};

export type FilterStatus = Map<string, FilterInfo>;

/**
 * Whether there are filters pending execution in LCVis. If there are, we want to display some
 * indicator that the filters are still being calculated, and disable editing them.
 */
export const lcvisFilterStatus = atom<FilterStatus>({
  key: 'lcvisFilterPendingAtom',
  default: new Map(),
});

export const useLcvisFilterStatus = () => useRecoilState(lcvisFilterStatus);
export const useLcvisFilterStatusValue = () => useRecoilValue(lcvisFilterStatus);
export const useSetLcvisFilterStatus = () => useSetRecoilState(lcvisFilterStatus);

export const isStatusPending = (status?: FilterInfo['status']) => !([
  'completed',
  'error',
] as FilterInfo['status'][]).includes(status!);

const anyFiltersPendingState = selector<boolean>({
  key: 'anyFiltersPendingSelector',
  get: ({ get }) => {
    const filters = get(lcvisFilterStatus);

    return [...filters.values()].some(({ status }) => isStatusPending(status));
  },
});

export const useAnyFiltersPending = () => useRecoilValue(anyFiltersPendingState);
