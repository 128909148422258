// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilValue, useSetRecoilState, waitForAll } from 'recoil';

import { meshMultiPartFixture } from '../lib/fixtures';
import {
  meshParamsId,
  meshParamsUrl,
  nullableMeshing,
  nullableOptions,
  updateMeshSelectionsFromParam,
} from '../lib/mesh';
import { Logger } from '../lib/observability/logs';
import { DEFAULT_MESH_GENERATION } from '../lib/paramDefaults/meshGenerationState';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import * as rpc from '../lib/rpc';
import { getSimulationParam } from '../lib/simulationParamUtils';
import { defaultMeshComplexityParams, defaultMeshingMode } from '../lib/simulationUtils';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import { isMeshFile } from '../lib/upload/uploadUtils';
import * as cadmetadatapb from '../proto/cadmetadata/cadmetadata_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';

import { GeometryTags } from './geometry/geometryTagsObject';
import { meshUrlState } from './meshState';
import { cadMetadataSetupTabState } from './useCadMetadata';
import { meshGenParamsSelector } from './useMeshGeneration';
import { meshSurfacesState } from './useMeshSurfaces';
import { currentConfigSelector } from './workflowConfig';

const logger = new Logger('recoil/useMeshingMultiPart');

const MeshingMultiPart = meshgenerationpb.MeshingMultiPart;
const meshingMultiPartKey = 'meshingMultiPart';

function serialize(val: nullableMeshing): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}
function deserialize(val: Uint8Array): nullableMeshing {
  return (val.length ? MeshingMultiPart.fromBinary(val) : null);
}

// Compute the default volume params from the CAD metadata.
export function defaultVolumeParams(
  metadata: cadmetadatapb.CadMetadata,
  meshingOptions?: nullableOptions,
) {
  const maxSize = meshingOptions?.globalMaxSizeM || metadata.globalMaxSizeM ||
    DEFAULT_MESH_GENERATION.globalMaxSizeM;
  const minSize = meshingOptions?.globalMinSizeM || metadata.globalMinSizeM ||
    DEFAULT_MESH_GENERATION.globalMinSizeM;
  const volParams = new meshgenerationpb.MeshingMultiPart_VolumeParams({
    maxSize,
    minSize,
    selection: meshgenerationpb.MeshingMultiPart_VolumeParams_SelectionType.ALL,
  });
  // In some old projects (before Nov 2022), the metadata is missing NBodies and min and max
  // values.
  const numVols = Math.max(metadata.nBodies, 1);
  for (let i = 0; i < numVols; i += 1) {
    volParams.volumes.push(BigInt(i));
  }
  return volParams;
}

// Converts the old mesh generation proto with only a single part to the new multi-part meshing
// proto for backwards compatibility.
function convertSingleToMulti(
  single: nullableOptions,
  cadMetadata: cadmetadatapb.CadMetadata,
): nullableMeshing {
  if (!single) {
    return null;
  }
  return new MeshingMultiPart({
    blParams: [{
      nLayers: single.blNLayers,
      initialSize: single.blInitialSize,
      growthRate: single.blGrowRate,
      selection: meshgenerationpb.MeshingMultiPart_BoundaryLayerParams_SelectionType.SELECTED,
      surfaces: single.blSurfaces,
    }],
    modelParams: [{
      curvature: single.modelFaceCurvatureDeg,
      maxSize: single.modelMaxSize,
      selection: meshgenerationpb.MeshingMultiPart_ModelParams_SelectionType.SELECTED,
      surfaces: single.modelSurfaces,
    }],
    volumeParams: [defaultVolumeParams(cadMetadata, single)],
    complexityParams: defaultMeshComplexityParams(),
    meshingMode: defaultMeshingMode(),
  });
}

// Selects the multi-part meshing from the session state kv store.
export const meshingMultiPartSelectorRpc = selectorFamily<nullableMeshing, string>({
  key: meshingMultiPartKey,
  get: (projectId: string) => (
    () => persist.getProjectState(projectId, [meshingMultiPartKey], deserialize)
  ),
  dangerouslyAllowMutability: true,
});

export const meshingMultiPartStateRpc = atomFamily<nullableMeshing, string>({
  key: `${meshingMultiPartKey}/rpc`,

  default: selectorFamily<nullableMeshing, string>({
    key: `${meshingMultiPartKey}/Default`,
    get: (projectId: string) => ({ get }) => {
      // Return null for mesh files. They come with a mesh and do not have mesh generation params.
      if (isMeshFile(get(meshUrlState(projectId)).url)) {
        return null;
      }
      // Uses the new multi-part meshing if it exists.
      const meshingMultiPart = get(meshingMultiPartSelectorRpc(projectId));
      if (meshingMultiPart) {
        // Add default complexity params for old projects that don't have them.
        const complexityParams = meshingMultiPart.complexityParams;
        if (!complexityParams?.limitMaxCells &&
          !complexityParams?.targetCells && !complexityParams?.type) {
          meshingMultiPart.complexityParams = defaultMeshComplexityParams();
        }
        return meshingMultiPart;
      }
      // Old projects do not have the new proto, so we convert the old proto to the new proto.
      // If neither exists, use null.
      const [meshGeneration, cadMetadata] = get(waitForAll([
        meshGenParamsSelector({ projectId, meshUrl: '' }),
        cadMetadataSetupTabState(projectId),
      ]));
      return convertSingleToMulti(meshGeneration, cadMetadata) || null;
    },
  }),
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, meshingMultiPartKey, deserialize, serialize),
  ],
});

const meshingMultiPartTesting = atomFamily<nullableMeshing, string>({
  key: `${meshingMultiPartKey}/testing`,
  default: meshMultiPartFixture,
});

export const meshingMultiPartState = isTestingEnv() ?
  meshingMultiPartTesting : meshingMultiPartStateRpc;

export const meshingMultiPartSelector = selectorFamily<nullableMeshing, persist.RecoilProjectKey>({
  key: 'getMeshGenerationParams',
  get: (key: persist.RecoilProjectKey) => async ({ get }) => {
    const { projectId } = key;
    const [cadMetadata, projectMeshUrl, config, meshSurfaces] = get(waitForAll([
      cadMetadataSetupTabState(projectId),
      meshUrlState(projectId),
      currentConfigSelector(key),
      meshSurfacesState(key),
    ]));
    const readOnly = key.workflowId !== '';
    const simParam = getSimulationParam(config);
    const meshUrl = meshParamsUrl(simParam, readOnly);
    const meshId = meshParamsId(simParam, readOnly);
    const geometryTags = new GeometryTags(undefined);

    // Return null for uploaded mesh files.  Since we did not generate the mesh
    // file, there are no corresponding mesh generation parameters.
    if (isMeshFile(projectMeshUrl.url)) {
      return null;
    }

    // If meshUrl is empty, use the mesh params associated with the projectId.
    if (!meshUrl) {
      return get(meshingMultiPartState(key.projectId));
    }

    // If meshUrl is defined send a request to get the mesh generation params.
    // Include the meshId since we have two versions and have to merge.
    const req = new frontendpb.GetMeshGenerationParamsRequest({
      projectId: key.projectId,
      meshUrl,
      meshId,
    });

    try {
      const reply = await rpc.callRetry(
        'GetMeshGenerationParams',
        rpc.client.getMeshGenerationParams,
        req,
      );
      const meshMultiPart = reply.meshingMultiplePart;
      if (!meshMultiPart) {
        logger.error(`No mesh params received, meshUrl=${meshUrl} meshId=${meshId}`);
        return null;
      }
      // The type of selection is not being saved on the backend. It returns a value of 0 in all
      // cases. This sets the selection based on the params. This is discussed more in LC-11439.
      if (simParam && meshMultiPart) {
        updateMeshSelectionsFromParam(
          meshMultiPart,
          simParam,
          cadMetadata,
          meshSurfaces,
          geometryTags,
        );
      }

      return meshMultiPart;
    } catch (error) {
      logger.error(`Error in GetMeshGenerationParams RPC, error=${error}`);
      return null;
    }
  },
});

export const meshingMultiPartSelectorNew = (
  selectorFamily<nullableMeshing, persist.RecoilMeshKey>
)({
  key: 'getMeshGenerationParams',
  get: (key: persist.RecoilMeshKey) => async ({ get }) => {
    const { projectId, meshId, meshUrl } = key;

    // If meshUrl is defined send a request to get the mesh generation params.
    const req = new frontendpb.GetMeshGenerationParamsRequest({
      projectId,
      meshUrl,
      meshId,
    });

    try {
      const reply = await rpc.callRetry(
        'GetMeshGenerationParams',
        rpc.client.getMeshGenerationParams,
        req,
      );
      const meshMultiPart = reply.meshingMultiplePart;
      if (!meshMultiPart) {
        logger.error(`No mesh params received, meshUrl=${meshUrl} meshId=${meshId}`);
        return null;
      }
      return meshMultiPart;
    } catch (error) {
      logger.error(`Error in GetMeshGenerationParams RPC, error=${error}`);
      return null;
    }
  },
});

/**
 * meshUrl can be empty or defined. If it is defined we return the mesh params used to create
 * that URL. If it is empty, we return the mesh generation associated with the project. Both are
 * needed. The project version is used during setup. The meshUrl is used in the results.
 */
export default function useMeshMultiPart(
  projectId: string,
  workflowId: string,
  jobId: string,
) {
  return useRecoilValue(meshingMultiPartSelector({
    projectId,
    workflowId,
    jobId,
  }));
}

/**
 * meshUrl is not included here since only the mesh params for the project should be modified.
 * Use this with extreme caution.  This uses the MeshMulitPart state that is assiociated
 * with the project in the KV store.  If you're not in the setup tab, this is not what you want.
 */
export const useSetMeshMultiPart = (projectId: string) => (
  useSetRecoilState(meshingMultiPartState(projectId))
);
