import { LCVColorBarTickMode, LCVType } from '@luminarycloudinternal/lcvis';

import { DisplayPvVariable } from '../../../../pvproto/ParaviewRpc';
import { RIGHT_OVERLAY_CARDS_WIDTH } from '../../../constants';
import { Point } from '../../../geometry';
import { VIEWER_PADDING } from '../../../visUtils';
import { LcvModule } from '../../types';
import { LcvObject } from '../base/LcvObject';
import { LcvColorMap } from '../filters/LcvColorMap';
import { LcvWorkspace } from '../filters/LcvWorkspace';

/** Adjusts a size value based on the device's pixel ratio. */
const adjustSize = (value: number) => value * window.devicePixelRatio;

export interface ColorMapItem {
  presetName: string;
  range: [number, number];
  bins: number;
  displayVariable: DisplayPvVariable;
  discretize: boolean;
  position: Point;
  isVertical: boolean;
}

const FIRST_TOP_RIGHT_POSITION = [
  RIGHT_OVERLAY_CARDS_WIDTH + 3 * VIEWER_PADDING,
  3.25 * VIEWER_PADDING,
];
const BAR_SIZE = [8, 210];
const DEFAULT_GAP_BETWEEN_CHARTS = 60;

export class LcvColorBarAnnotation extends LcvObject {
  private data: ColorMapItem;

  static arrangeData(
    data: (Omit<ColorMapItem, 'position'> & { position?: Point })[],
  ): ColorMapItem[] {
    let stackedHeight = FIRST_TOP_RIGHT_POSITION[1];

    // the position is oriented with the origin at the bottom-right
    return data.map((item) => {
      const fullSize = item.isVertical ?
        [BAR_SIZE[0] + 40, BAR_SIZE[1] + 4] :
        [BAR_SIZE[1] - 80, BAR_SIZE[0] + 120];

      const result = {
        ...item,
        position: {
          x: FIRST_TOP_RIGHT_POSITION[0] + fullSize[0] - (item.position?.x ?? 0),
          y: stackedHeight + fullSize[1] + (item.position?.y ?? 0),
        },
      };

      stackedHeight += fullSize[1] + DEFAULT_GAP_BETWEEN_CHARTS;
      stackedHeight -= item.isVertical ? 0 : 84;

      return result;
    });
  }

  constructor(lcv: LcvModule, sessionHandle: number, data: ColorMapItem) {
    super(
      lcv,
      lcv.newAnnotation(sessionHandle, 'color_bar', 0).annotation,
      sessionHandle,
    );

    this.data = data;
  }

  show(
    canvasSize: { width: number; height: number; },
    workspace: LcvWorkspace,
  ) {
    const bottomLeftPosition = [
      canvasSize.width - this.data.position.x,
      canvasSize.height - this.data.position.y,
    ].map(adjustSize);

    this.setParam('text_size', LCVType.kLCVDataTypeFloat, adjustSize(36));
    this.setParam('size', LCVType.kLCVDataTypeFloat2, BAR_SIZE.map(adjustSize));
    this.setParam('position', LCVType.kLCVDataTypeFloat2, bottomLeftPosition);
    this.setParam('widget_orientation', LCVType.kLCVDataTypeUint, this.data.isVertical ? 0 : 1);
    this.setParam(
      'tick_display_mode',
      LCVType.kLCVDataTypeUint,
      this.data.discretize ?
        LCVColorBarTickMode.kLCVColorBarTickModeMinMax :
        LCVColorBarTickMode.kLCVColorBarTickModeAll,
    );

    try {
      const colorMap = new LcvColorMap(
        this.lcv,
        this.sessionHandle,
        workspace.handle,
        this.data.displayVariable,
      );

      colorMap.setParam('texture_name', LCVType.kLCVDataTypeString, this.data.presetName);
      colorMap.setParam('bins', LCVType.kLCVDataTypeUint, this.data.bins);
      colorMap.setParam('discretize', LCVType.kLCVDataTypeUint, this.data.discretize ? 1 : 0);

      const [minVal, maxVal] = this.data.range;
      colorMap.setParam('min_value', LCVType.kLCVDataTypeFloat, minVal);
      colorMap.setParam('max_value', LCVType.kLCVDataTypeFloat, maxVal);

      this.setParam('color_map', LCVType.kLCVDataTypeColorMap, colorMap.handle);
    } catch (error) {
      // color_map is null by default; if it fails, nothing will be rendered, which isn't too bad
    }
  }

  hide() {
    this.setParam('color_map', LCVType.kLCVDataTypeColorMap, null);
  }
}
