import React, { useState } from 'react';

import cx from 'classnames';

import { listToPv, toArray, toProto } from '../../../../lib/Vector';
import { analytics } from '../../../../services/analytics';
import { ActionButton } from '../../../Button/ActionButton';
import { DataSelect } from '../../../Form/DataSelect';
import { NumberInput } from '../../../Form/NumberInput';
import { RadioButtonGroup } from '../../../Form/RadioButtonGroup';
import { TextInput } from '../../../Form/TextInput';
import { ValidVector3Input } from '../../../Form/ValidatedInputs/ValidVector3Input';
import { createStyles, makeStyles } from '../../../Theme';
import { PROP_PANEL_GAP } from '../../../Theme/commonStyles';
import Tooltip from '../../../Tooltip';
import { useProjectContext } from '../../../context/ProjectContext';
import { LuminaryToggleSwitch } from '../../../controls/LuminaryToggleSwitch';
import { useAddNewAssistantMessage } from '../../../hooks/assistant/useAddNewAssistantMessage';
import { useAssistantSend } from '../../../hooks/assistant/useAssistantSend';

const useSuggestedListStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginBottom: '12px',
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      gap: `${PROP_PANEL_GAP}px`,
      alignItems: 'center',
    },
    label: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      minWidth: '50%',
      maxWidth: '140px',
    },
    formItem: {
      maxWidth: '140px',
    },
    action: {
      display: 'flex',
      justifyContent: 'end',
    },
  }),
  { name: 'NestedForm' },
);

type ParamBase = {
  name: string;
  label: string;
  help?: string;
};

type ParamString = ParamBase & {
  type: 'string';
  default?: string | number;
}

type ParamNumber = ParamBase & {
  type: 'number';
  default?: number;
}

type ParamBoolean = ParamBase & {
  type: 'boolean';
  default?: boolean;
}

type ParamChoice = ParamBase & {
  type: 'choice';
  choices: string[];
  default?: string;
}

type ParamVector = ParamBase & {
  type: 'vector';
  default?: number[];
}

type Param = ParamString | ParamNumber | ParamBoolean | ParamChoice | ParamVector;
type ParamValue = Param['default'];

export type NestedFormData = { entries: Param[]};

type NestedFormProps = {
  data: NestedFormData;
}

export const NestedForm = (props: NestedFormProps) => {
  // == Props
  const { data: { entries } } = props;

  // == Contexts
  const { projectId } = useProjectContext();

  // == Hooks
  const classes = useSuggestedListStyles();

  // == State
  const [submitted, setSubmitted] = useState(false);
  const { disabled } = useAssistantSend();
  const addNewAssistantMessage = useAddNewAssistantMessage(projectId);

  const [values, setValues] = useState<Record<string, any>>(
    entries.reduce((acc, param) => {
      acc[param.name] = param.default;
      return acc;
    }, {} as Record<string, any>),
  );

  const updateValue = (name: string, value: ParamValue) => {
    setValues((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (disabled) {
      return;
    }

    setSubmitted(true);

    // We want to submit all values that are defined (by the user or as `default` by the AI).
    const valuesToSubmit = entries.reduce((acc, entry) => {
      let value = values[entry.name];
      if (value !== undefined && value !== null) {
        if (entry.type === 'vector') {
          value = JSON.stringify(value);
        }
        acc.push(`${entry.label}=${value}`);
      }
      return acc;
    }, [] as string[]);

    let message: string;

    if (entries.length === 1 && entries[0].default !== values[entries[0].name]) {
      message = values[entries[0].name];
    } else if (valuesToSubmit.length) {
      message = valuesToSubmit.join('; ');
    } else {
      message = 'Go ahead';
    }

    addNewAssistantMessage(message);
    analytics.assistant('Submitted Nested Form', { value: message });
  };

  if (!entries.length) {
    return null;
  }

  const renderFormItem = (item: Param, totalItemsLength: number) => {
    const value = values[item.name];

    switch (item.type) {
      case 'string':
        return (
          <TextInput
            disabled={submitted}
            onChange={(newValue: string) => updateValue(item.name, newValue)}
            size="small"
            value={value || ''}
          />
        );
      case 'number':
        return (
          <NumberInput
            disabled={submitted}
            onCommit={(newValue:number) => updateValue(item.name, newValue)}
            size="small"
            value={value || 0}
          />
        );
      case 'boolean': {
        // If we have a default value, we can show the param as a toggle to simplify things
        if (item.default === true || item.default === false) {
          return (
            <LuminaryToggleSwitch
              disabled={submitted}
              onChange={(newValue: boolean) => updateValue(item.name, newValue)}
              small
              value={value === true}
            />
          );
        }
        // If we don't have a default value, we must show it as radio button with it we can
        // leave all options non checked
        return (
          <RadioButtonGroup
            disabled={submitted}
            kind="secondary"
            name={item.name}
            onChange={(newValue: string) => updateValue(item.name, newValue)}
            options={[
              {
                value: 'true',
                label: 'True',
              },
              {
                value: 'false',
                label: 'False',
              },
            ]}
            value={value}
            vertical
          />
        );
      }
      case 'choice':
        return (
          <DataSelect
            asBlock
            disabled={submitted}
            onChange={(newValue: string | number) => updateValue(item.name, newValue)}
            options={item.choices.map((choice) => ({
              value: choice,
              name: choice,
              selected: value === choice,
            }))}
            size="small"
          />
        );
      case 'vector': {
        return (
          <ValidVector3Input
            disabled={disabled}
            onChange={() => {}}
            onCommit={(newValue) => {
              updateValue(item.name, toArray(newValue));
            }}
            value={toProto(listToPv(value || [0, 0, 0]))}
          />
        );
      }
      default: {
        throw Error('Unknown type for the nested chat form');
      }
    }
  };

  return (
    <div className={classes.root}>
      {entries.map((item) => (
        <div className={cx(classes.item)} key={item.name}>
          <Tooltip title={item.help}>
            <div className={classes.label}>
              {item.label}
            </div>
          </Tooltip>
          <div className={classes.formItem}>
            {renderFormItem(item, entries.length)}
          </div>
        </div>
      ))}
      {!submitted && (
        <div className={classes.action}>
          <ActionButton
            disabled={disabled || submitted}
            kind="primary"
            onClick={handleSubmit}
            size="small"
            type="button">
            Submit
          </ActionButton>
        </div>
      )}
    </div>
  );
};
