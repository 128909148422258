// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/frontend/serverinfo/serverinfo.proto (package luminary.proto.frontend.serverinfo, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// ServerInfo is implemented in the same code that implements the Frontend
// service. This means it also requires a credential in the "authorization"
// header - see proto/frontend/frontend.proto for details. This file is separate
// so other code can call ServerInfo without importing all of the dependencies
// of frontend.proto.

import { proto3 } from "@bufbuild/protobuf";
import { ClusterConfig, UserReservation } from "../../clusterconfig/clusterconfig_pb.js";

/**
 * Contains software versions, node pools, and optionally reservations.
 *
 * @generated from message luminary.proto.frontend.serverinfo.ServerInfoReply
 */
export const ServerInfoReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.serverinfo.ServerInfoReply",
  () => [
    { no: 1, name: "jobmaster_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fvm_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "paraview_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "meshconverter_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "page_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "jobmaster_git_commit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "cluster_config", kind: "message", T: ClusterConfig },
    { no: 9, name: "reservation", kind: "message", T: UserReservation, repeated: true },
  ],
);

