// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// Manages websocket connections to the paraview server.

import { useCallback, useEffect } from 'react';

import { ObservableResult, ValueType, trace } from '@opentelemetry/api';
import { atomFamily, selectorFamily, useRecoilState, useRecoilValue, waitForAll } from 'recoil';
import SmartConnect, { WebsocketCloseEvent } from 'wslink/src/SmartConnect';
import { WebsocketConnection } from 'wslink/src/WebsocketConnection';

import * as basepb from '../proto/base/base_pb';
import * as lcmeshpb from '../proto/lcn/lcmesh_pb';
import { SolnFileMetadata } from '../proto/lcn/lcsoln_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../recoil/lcvis/lcvisEnabledState';
import { meshMetadataSelector } from '../recoil/meshState';
import { activeVisUrlState } from '../recoil/vis/activeVisUrl';
import { currentViewAtom_DEPRECATED, useCurrentView } from '../state/internal/global/currentView';

import * as RuntimeParams from './RuntimeParams';
import { CurrentView } from './componentTypes/context';
import { isStaff } from './jwt';
import { meshAggregateStats } from './mesh';
import { Logger } from './observability/logs';
import { meter } from './observability/metrics';
import { CONNECTING_MESSAGE } from './paraviewUtils';
import { RecoilProjectKey } from './persist';
import { base64ToProto } from './proto';
import * as status from './status';

const logger = new Logger('ParaviewClient');
const connectionLatencyHistogram = meter.createHistogram(
  'ParaviewClient_connection_latency',
  {
    description: 'The time it took for a requested rendering session to be started or restarted',
    unit: 'ms',
    valueType: ValueType.INT,
  },
);
// Consider a connection request delayed after 1 minute.
const delayedConnectionMillis = 60000;
// Consider a connection request failed after 2 minutes.
const failedConnectionMillis = 120000;
const tracer = trace.getTracer('ParaviewClient');

// Range of exponential backoff timeout interval values after an unexpected
// connection error. Exponent is always 1.2.
const MIN_BACKOFF_MS = 200;
const MAX_BACKOFF_MS = 1000;

// The #ms to keep the connection live even if the user is idle.
const KEEPALIVE_TIMEOUT_MS = 12 * 60 * 1000;
// Same as KEEPALIVE_TIMEOUT_MS, but trying out a shorter timeout for STAFF
// users, will be removed soon. See LC-14071.
const KEEPALIVE_TIMEOUT_MS_STAFF = 5 * 60 * 1000;

// Interval between successive ping RPC requests to keep the connection
// live.
const PING_INTERVAL_MS = 5 * 1000;
// Ping RPC timeout. The connection will be closed and reopened on timeout.
const PING_TIMEOUT_MS = 10 * 1000;
// Websocket handshake timeout.
const CONNECT_TIMEOUT_MS = 5 * 1000;

// Delay showing a status message for 2s.  This is to avoid a screen flicker
// when a message shows then disappears quickly.
const PROGRESS_REPORT_DELAY_MS = 2 * 1000;

export const PAUSED_MESSAGE = 'Visualization paused to conserve resources';

/** The error message to show when a non-retriable error is encountered */
const FAILED_MESSAGE = `A visualization error occurred.  Click "Restart" to reconnect. If this
  error persists, please contact Luminary support.`;

// Metadata about the current state of the Paraview connection.
// CONNECTED means that  the client is connected to the paraview.
// There are two disconnected states.
// CONNECTING means the client is currently is not connected, but it wants to.
// VOLUNTARY_DISCONNECT means the client disconnected due to the idle timeout,
// and connection can be resumed using keepalive().

export enum ConnState {
  CONNECTING = 0,
  VOLUNTARY_DISCONNECT = 1,
  CONNECTED = 2,
}

// Defines rendering views. A view is a canvas on which a mesh is drawn.
// Creating multiple view is useful if you want to show multiple canvases
// simultaneously, or if you want to let the user switch between two canvases
// quickly. Each view costs the backend memory and GPU cycles, so its number
// should be minimized.
export enum ViewName {
  SETUP = 'setup', // Used to show a mesh in the setup screen
  SOLUTION = 'soln', // Used to show a mesh or a solution in the analysis screen.
}

const emptyClientStateCache: { [connKey: string]: ClientState } = {};

function emptyClientState(connKey: string): ClientState {
  // Report a singleton instance per connKey to reduce downstream churn.
  let state = emptyClientStateCache[connKey];
  if (!state) {
    state = {
      connKey,
      client: null,
      connState: ConnState.CONNECTING,
      message: CONNECTING_MESSAGE,
    };
    emptyClientStateCache[connKey] = state;
  }
  return state;
}

// Checks if the two clientstates have the same property values.
function clientStateEquals(s0: ClientState, s1: ClientState): boolean {
  return (
    s0.connKey === s1.connKey &&
    s0.client === s1.client &&
    s0.connState === s1.connState &&
    s0.message === s1.message);
}

// Generate a URL query string that maps one to one to a paraview connection.
function newConnKey(
  projectId: string,
  jobId: string,
  url: string,
  viewName: ViewName,
  meshMetadata: lcmeshpb.MeshFileMetadata | null,
  solnMetadata: SolnFileMetadata | null,
): string {
  if (!meshMetadata) {
    return '';
  }

  const renderer = 'image';
  const meshStats = meshAggregateStats(meshMetadata);
  let meshUrl = url;
  // NOTE: we may be in the solution view but we have no solution data available, in that case
  // we are accessing the visualizer state corresponding to ParaviewFileType === mesh, i.e. there's
  // no need to re-assess the meshUrl.
  if (solnMetadata) {
    let lcmeshFileName = meshMetadata.parentMeshUrl;
    if (lcmeshFileName.length === 0) {
      lcmeshFileName = solnMetadata.meshUrl;
    }
    if (lcmeshFileName.length === 0) {
      logger.error('no mesh set - ' +
        `viewName: ${viewName} ` +
        `projectID: ${projectId} ` +
        `url: ${encodeURIComponent(url)}`);
    } else {
      meshUrl = new URL(`../${lcmeshFileName}`, url).toString();
    }
  }

  let key = `viewName=${viewName}&` +
    `projectID=${projectId}&` +
    `url=${encodeURIComponent(url)}&` +
    `nCvs=${meshStats.counters.controlVolume}&` +
    `nFaces=${meshStats.counters.face}&` +
    `nPoints=${meshStats.counters.point}&` +
    `renderer=${renderer}&` +
    `meshURL=${encodeURIComponent(meshUrl)}`;
  if (jobId !== '') {
    key += `&jobID=${jobId}`;
  }
  return key;
}

// A high-level construct for talking to Paraview.
export class Client {
  constructor(
    // The view name selected by the caller. Nonempty.
    public readonly viewName: ViewName,
    // The current websocket connection.
    public readonly connection: WebsocketConnection,
    // A nonce that identifies a server process. It changes every time the
    // server process boots. Nonempty.
    public readonly serverUid: string,
    // The Paraview's internal ID that identifies the rendering view.  View ID
    // must be sent in all RPCs that manipulate views. Maps 1<->1 with the
    // viewname on the server. Never zero.
    public readonly viewId: ParaviewRpc.ViewId,
  ) {
    if (!this.connection) {
      throw Error(`view ${this.viewName}: not connected`);
    }
  }

  async runRpc<Result>(methodName: string, args: any[]): Promise<Result> {
    logger.debug(`calling rpc ${methodName}: args = ${args} `);
    return this.connection.getSession().call(methodName, args);
  }

  // Start listening to one-way messages from the server. The callback will be
  // run on every message. Returns a unsubscribe function whose invocation will
  // cancel the subscription.
  async subscribe<Arg>(topic: string, callback: (arg: Arg) => void): Promise<() => Promise<void>> {
    const result = this.connection.getSession().subscribe(topic, (args: any[]) => {
      if (args.length !== 1) {
        throw Error(`subscribe ${topic}: expect one arg, got ${args}`);
      }
      callback(args[0]);
      return Promise.resolve(null);
    });
    await result.promise;
    logger.debug(`subscribe ${topic}: done`);
    return result.unsubscribe;
  }

  // Start listening to one-way messages from the server, but immediately unsubscribe
  // from the subscription after one message has been receieved. When chaining promises,
  // you cannot access the subscribe function within the callback. This function solves
  // that problem.
  async subscribeOne<Arg>(topic: string, callback: (arg: Arg) => void): Promise<void> {
    const unsubscribeFn = await this.subscribe(topic, async (args: any[]) => {
      callback(args[0]);
      await unsubscribeFn();
    });
  }

  toString(): string {
    return `{connection=${this.connection}, serverUid=${this.serverUid}, ` +
      `viewName=${this.viewName}, viewId=${this.viewId}}`;
  }
}

enum WantState {
  DISCONNECT = 0,
  CONNECT = 2
}

// Each connection maintains a state machine that keeps track of the progress
// message display. It is used to reduce screen flickers when a progress message
// is shown and then removed quickly.
//
// Initially, the state machine is INACTIVE.
//
// If a new progress message arrives, the state becomes DELAYED for the next
// PROGRESS_REPORT_DELAY_MS.  Messages are suppressed during the DELAYED period.
// As an exception, if the machine has been in INACTIVE state for less than
// PROGRESS_REPORT_DELAY_MS, it transitions to IMMEDIATE instantly.  If two
// different progress reports arrive with a short null progress in between, we
// don't want to block the 2nd report.
//
// The DELAYED state switches to IMMEDIATE state after PROGRESS_REPORT_DELAY_MS.
// At the start of IMMEDIATE, the last progress message received during the
// DELAYED period is shown, and any future progress reports will be shown
// immediately.
//
// In any state, reception of an empty progress will turn the state to INACTIVE.
enum ProgressReportState {
  INACTIVE,
  DELAYED,
  IMMEDIATE,
}

// A possible ClientState.progress value. Indicates that an operation is
// progressing, but the progress percentage is not known.
export const INDEFINITE_PROGRESS = -1.0;

// A possible ClientState.progress value. Indicates that no operation
// is happening.
export const NO_PROGRESS = -2.0;

// The Paraview client plus a bit of extra state to help distinguish
// different kinds of disconnections.
export type ClientState = {
  connState: ConnState;
  client: Client | null; // Set iff connState=CONNECTED.
  // Message from this library or paraview.
  // Set iff. connState ∈ {CONNECTING, VOLUNTARY_DISCONNECT}.
  //
  // INVARIANT: message is defined iff. progress is defined.
  message: string | null;
  // Either NO_PROGRESS, INDEFINITE_PROGRESS, or in range [0,1].
  progress?: number;
  // Internal ID of this client. Key to connectorCache.
  connKey: string;
};

type Timer = ReturnType<typeof setTimeout>;

// Represents one websocket/jsonrpc connection to Paraview.
// One Client is created per view (i.e., rendering window).
class Connector {
  // The current connection state.
  private state: ClientState;

  // List of callbacks added in addCallback. Deduped.
  private onUpdateCallbacks: ((clientState: ClientState | null) => void)[] = [];

  // wantState is the desired connection state.  If the value is CONNECT, the
  // user desires to keep the connection live.  If the value is DISCONNECT, the
  // user is idle and the browser wishes to disconnect from the server.
  private wantState: WantState = WantState.CONNECT;

  // {connecting, connection} represent the actual state of the connection.
  // connecting=true iff. the websocket connection
  // request has been made, but it hasn't completed nor failed.
  private connecting = false;

  // Time to wait before the next reconnection attempt.
  private backoffMs = MIN_BACKOFF_MS;
  private reconnectTimer: Timer | null = null; // to reconnect after a failure
  private connectTimer: Timer | null = null; // to detect stuck connection handshake
  private keepaliveTimer: Timer | null = null; // to detect user inactivity
  private pingRequestTimer: Timer | null = null; // to send periodic ping RPCs.
  private pingTimeoutTimer: Timer | null = null; // to detect a stuck ping RPC.
  private delayedConnectionTimer: Timer | null = null; // to detect delayed connections.
  private failedConnectionTimer: Timer | null = null; // to detect failed connections.

  // Current state of the progress state machine.
  private progressState = ProgressReportState.INACTIVE;
  // The last time onProgress was called.
  private lastProgressChangeTime = 0;
  // Timer to drive DELAYED -> IMMEDIATE transition.
  private progressTimer: Timer | null = null;

  private readonly connector: SmartConnect;

  // The last status received in a "luminarycloud.disconnect" message. It
  // typically contains the crash message. It is sent just before paraviewmux
  // closes the connection.
  private disconnectReason: basepb.Status | null = null;

  private startConnectingTime = new Date().getTime();
  private stopConnectingTime = 0;
  private delayedConnectionFlag = meter.createObservableUpDownCounter(
    'ParaviewClient_delayed_connections',
    {
      description:
        'A binary flag indicating if this connector is taking a long time to connect to a ' +
        'rendering session',
      valueType: ValueType.INT,
    },
  );

  private failedConnectionFlag = meter.createObservableUpDownCounter(
    'ParaviewClient_failed_connections',
    {
      description:
        'A binary flag indicating if this connector is taking such a long time to connect to a ' +
        'rendering session that we consider it failed',
      valueType: ValueType.INT,
    },
  );

  constructor(
    public readonly viewName: ViewName,
    connKey: string,
    public readonly projectId: string,
  ) {
    this.delayedConnectionFlag.addCallback((result) => {
      this.isLongConnectionCallback(result, delayedConnectionMillis);
    });
    this.failedConnectionFlag.addCallback((result) => {
      this.isLongConnectionCallback(result, failedConnectionMillis);
    });
    const firstConnectionSpan = tracer.startSpan('Connector first connection');
    logger.debug(`ParaviewRpc.Connector: view=${this.viewName} connKey=${connKey}`);
    this.state = emptyClientState(connKey);
    this.connector = SmartConnect.newInstance({
      config: {
        sessionURL: `${RuntimeParams.paraviewServer}?${connKey}`,
      },
    });

    this.connector.onConnectionClose((conn: WebsocketConnection, event: WebsocketCloseEvent) => {
      this.connecting = false;
      logger.debug(`pv connection "${this.viewName}" closed: disconnect=${this.disconnectReason}`);
      logger.debug(`pv connection "${this.viewName}" closed: event=${event.code},${event.reason}`);
      if (this.disconnectReason) {
        if (!status.shouldRetry(status.fromProto(this.disconnectReason)!)) {
          this.wantState = WantState.DISCONNECT;
          logger.error(
            'paraviewClient disconnected: ',
            status.stringifyError(this.disconnectReason),
          );
        }
        this.maybeDisconnect(FAILED_MESSAGE);
      } else {
        this.maybeDisconnect(CONNECTING_MESSAGE);
      }
      this.scheduleReconnect();
    });

    this.connector.onConnectionError((conn: WebsocketConnection, err: Event) => {
      // TODO(supriya): Check how to handle this callback? Logging for now.
      logger.info(`pv connection error "${this.viewName}"; connKey:
      ${this.connKey()}; error: ${JSON.stringify(err)}`);
    });

    this.connector.onConnectionReady((connection: WebsocketConnection) => {
      logger.info(`pv connection ${this.viewName} ready; connKey: ${this.connKey()} `);
      this.keepalive(); // call keepalive for the first time since the connection is active now
      this.backoffMs = MIN_BACKOFF_MS;
      if (this.state.client) {
        throw Error('double client');
      }
      this.clearConnectTimer();
      switch (this.wantState) {
        case WantState.DISCONNECT:
          this.connecting = false;
          logger.info(`pv connKey: ${this.connKey()} WantState=Disconnect,
          calling connection.destroy()`);
          connection.destroy();
          break;
        case WantState.CONNECT: {
          this.resetPingTimeout();
          logger.info(`pv connKey: ${this.connKey()} WantState=Connect`);
          // Run a handshake RPC with the server. It registers the view with the given
          // name on the server side.  This call must be the very first RPC after
          // connection establishment.
          type RpcHandshakeResult = {
            serverUid: string; // uid assigned when the server boots
            viewId: ParaviewRpc.ViewId; // 1-1 mapped to of viewName.
          };

          const result0 = connection.getSession().subscribe(
            'luminarycloud.progress',
            (args: any[]) => {
              if (args.length !== 1) {
                throw Error(`progress: expect one arg, got ${args}`);
              }
              this.onProgress(args[0] as ParaviewRpc.Progress);
              return Promise.resolve(null);
            },
          );
          result0.promise.then(() => {
            logger.debug('Done registering progress subscriber');
            const result1 = connection.getSession().subscribe(
              'luminarycloud.disconnect',
              (args: any[]) => {
                if (args.length !== 1) {
                  throw Error(`disconnect: expect one arg, got ${args} `);
                }
                this.onDisconnect(args[0] as string);
                return Promise.resolve(null);
              },
            );
            return result1.promise;
          }).then(() => {
            logger.debug(`start handshake view = ${viewName}`);
            return connection.getSession().call(
              'luminarycloud.handshake',
              // The third argument is the useGeometryRenderer state. As this has been deprecated,
              // the value will always be false (LC-21010). The argument can probably be removed
              // from the handshake RPC (paraview_server/server.py) in the future, though soon we
              // will no longer be using paraview.
              [this.viewName, this.projectId, false],
            );
          }).then(
            (rawResult: any) => {
              this.connecting = false;
              const result = rawResult as RpcHandshakeResult;
              // this shouldn't happen
              if (!result.serverUid || !result.viewId) {
                throw Error(`handshake: invalid result: ${JSON.stringify(result)} `);
              }
              logger.debug(
                `handshake ${this.viewName}: serverUid = ${result.serverUid} ` +
                `viewid = ${result.viewId} `,
              );
              const client = new Client(
                this.viewName,
                connection,
                result.serverUid,
                result.viewId,
              );
              this.updateState({
                ...this.state,
                connState: ConnState.CONNECTED,
                client,
                message: null,
              });
              this.resetPingRequestTimer();
              this.clearLongConnectionTimers();
              this.stopConnectingTime = new Date().getTime();
              connectionLatencyHistogram.record(
                this.stopConnectingTime - this.startConnectingTime,
                { 'lc.projectID': this.projectId },
              );
              firstConnectionSpan.end();
            },
          ).catch(
            (err: any) => {
              logger.warn(`handshake ${this.viewName} error: ${status.stringifyError(err)} `);
              this.connecting = false;
              this.maybeDisconnect(CONNECTING_MESSAGE);
              this.scheduleReconnect();
            },
          );
        }
          break;
        default:
          this.connecting = false;
          throw Error(`illegal state: ${this.wantState}`);
      }
    });

    // start the pv connection for the first time;
    this.wantState = WantState.CONNECT;
    this.maybeStartConnect();
  }

  public connKey(): string {
    return this.state.connKey;
  }

  // Welcome to Yaz's state machine.  The StatusOverlay renders (and blocks
  // user interaction) while there is a message in the buffer. Progress updates
  // from paraview come in with message=null  or with text.  A series of
  // messages should always end with a null, indicating that the pipeline has
  // completed.  From what I can tell, the purpose is two fold.
  // 1) Prevent a series of progress messages in the form: null, 'slice', null,
  // 'clip', null from toggling rendering of the status overlay, which causes
  // flickering.
  // 2) Limit the number of notifications to observers via calls to updateState.
  private onProgress(progress: ParaviewRpc.Progress): void {
    logger.debug(`received progress: ${JSON.stringify(progress)} state: ${this.toString()} `);
    const now = Date.now();
    // Update this.state and lastProgressChangeTime.
    const lastChange = this.lastProgressChangeTime;
    // Create a copy of the current state so updateState is called with
    // different object, otherwise it would never know anything changed.
    let newState: ClientState;
    if (progress.text) {
      if (this.state.message === progress.text && this.state.progress === progress.progress) {
        this.clearProgressTimer();
        return;
      }
      newState = {
        ...this.state,
        progress: progress.progress,
        message: progress.text,
      };
    } else {
      if (!this.state.message) {
        this.clearProgressTimer();
        return;
      }
      newState = {
        ...this.state,
        message: null,
        progress: NO_PROGRESS,
      };
    }

    if (!this.state.client) {
      logger.debug(`onProgress: received ${JSON.stringify(progress)} on disconnected paraview`);
      return;
    }
    logger.debug(`Got progress: ${this.toString()} ${JSON.stringify(progress)} `);
    this.lastProgressChangeTime = now;
    // LC-17379: Prevent the connection from idling out while we are receiving progress updates
    // from the server.  For large cases, loading the case may take a long time, and we don't want
    // to cancel the connection due to inactivity during the loading.
    this.keepalive();

    switch (this.progressState) {
      case ProgressReportState.INACTIVE:
        if (this.progressTimer) {
          throw Error('last progress timer');
        }
        if (!newState.message) {
          return;
        }

        // A new progress report has arrived. If the last transition to INACTIVE
        // was less than PROGRESS_REPORT_DELAY_MS ago, switch to IMMEDIATE. Else
        // wait for PROGRESS_REPORT_DELAY_MS then switch to IMMEDIATE.  If two
        // different progress reports arrive with a short null
        // progress in between, we don't want to block the 2nd report.
        if (now - lastChange < PROGRESS_REPORT_DELAY_MS) {
          this.progressState = ProgressReportState.IMMEDIATE;
          this.updateState(newState);
          return;
        }
        this.progressState = ProgressReportState.DELAYED;
        // Its very important that timers are cleared from different states.
        this.progressTimer = setTimeout(() => {
          if (this.progressState !== ProgressReportState.DELAYED) {
            throw Error(`invalid progress state: ${this.progressState} ${this.progressTimer} `);
          }
          this.progressTimer = null;
          this.progressState = ProgressReportState.IMMEDIATE;
          this.updateState(newState);
        }, PROGRESS_REPORT_DELAY_MS);
        break;
      case ProgressReportState.DELAYED:
        // Report a null progress immediately, since the UI usually blocks while
        // a progress message is shown and we need to clear it.  A non-null
        // progress message is buffered
        // until the timer expires.
        if (!this.progressTimer) {
          throw Error('delay progress timer');
        }
        if (!newState.message) {
          this.clearProgressTimer();
          this.updateState(newState);
        }
        break;
      case ProgressReportState.IMMEDIATE:
        if (this.progressTimer) {
          throw Error('immediate progress timer');
        }
        if (!newState.message) {
          this.clearProgressTimer();
        }
        this.updateState(newState);
        break;
      default:
        throw Error(`invalid progress state: ${this.progressState} ${this.progressTimer} `);
    }
  }

  private clearProgressTimer(): void {
    if (this.progressTimer) {
      clearTimeout(this.progressTimer);
      this.progressTimer = null;
      this.progressState = ProgressReportState.INACTIVE;
    }
  }

  // Called on receiving luminary.disconnect message.  Payload is a
  // base64-encoded basepb.Status.
  private onDisconnect(base64status: string): void {
    if (!this.state.client) {
      logger.info(`onDisconnect: received ${base64status} on disconnected
      paraview; connKey: ${this.state.connKey} `);
      return;
    }
    this.disconnectReason = base64ToProto(base64status, basepb.Status.fromBinary);
    logger.info(`onDisconnect: ${this.disconnectReason}; connKey: ${this.state.connKey} `);
  }

  private clearPingTimeout(): void {
    if (this.pingTimeoutTimer) {
      clearTimeout(this.pingTimeoutTimer);
      this.pingTimeoutTimer = null;
    }
  }

  private resetPingTimeout(): void {
    this.clearPingTimeout();
    this.pingTimeoutTimer = setTimeout(() => {
      this.clearPingTimeout();
      this.clearPingRequestTimer();
      this.maybeDisconnect('ping timeout');
      this.scheduleReconnect();
    }, PING_TIMEOUT_MS);
  }

  private clearPingRequestTimer(): void {
    if (this.pingRequestTimer) {
      clearTimeout(this.pingRequestTimer);
      this.pingRequestTimer = null;
    }
  }

  private resetPingRequestTimer(): void {
    this.clearPingRequestTimer();
    if (!this.state.client) {
      throw Error(`pingrequest: null client ${this.state} `);
    }
    this.pingRequestTimer = setTimeout(() => {
      this.pingRequestTimer = null;
      if (!this.state.client) {
        throw Error(`pingrequest callback: null client ${this.state} `);
      }
      const startTime = Date.now();
      this.state.client.runRpc<string>(
        'luminarycloud.ping',
        ['xxx', 'hello'],
      ).then(() => {
        logger.debug(`ping: ${Date.now() - startTime} ms`);
        this.resetPingTimeout();
      }).catch((err: Error) => {
        logger.warn(`ping error: ${err} `);
      }).finally(() => {
        if (this.state.client) {
          this.resetPingRequestTimer();
        }
      });
    }, PING_INTERVAL_MS);
  }

  private clearConnectTimer(): void {
    if (this.connectTimer) {
      clearTimeout(this.connectTimer);
      this.connectTimer = null;
    }
  }

  private resetConnectTimer(): void {
    this.clearConnectTimer();
    this.connectTimer = setTimeout(() => {
      // reset the connecting state otherwise maybeStartConnect doesn't work and
      // the connection is never restarted.
      this.connecting = false;
      this.maybeDisconnect('connect timeout');
      this.scheduleReconnect();
    }, CONNECT_TIMEOUT_MS);
    if (this.delayedConnectionTimer == null) {
      this.delayedConnectionTimer = setTimeout(() => {
        logger.error(
          'rendering session not connected after 1 minute; connKey: %s',
          this.clientState().connKey,
        );
      }, delayedConnectionMillis);
    }
    if (this.failedConnectionTimer == null) {
      this.failedConnectionTimer = setTimeout(() => {
        logger.error(
          'rendering session not connected after 2 minutes; connKey: %s',
          this.clientState().connKey,
        );
      }, failedConnectionMillis);
    }
  }

  private clearLongConnectionTimers(): void {
    if (this.delayedConnectionTimer) {
      clearTimeout(this.delayedConnectionTimer);
      this.delayedConnectionTimer = null;
    }
    if (this.failedConnectionTimer) {
      clearTimeout(this.failedConnectionTimer);
      this.failedConnectionTimer = null;
    }
  }

  // Adds a callback to run when the connection is established and closed.
  // The callback should be produced using React.useCallback. Adding
  // the same callback twice is a no-op.
  //
  // The callback may be called multiple times, as the connection is
  // closed and reestablished after network errors.
  addCallback(
    onUpdate: (connState: ClientState | null) => void,
  ): void {
    if (!this.onUpdateCallbacks.includes(onUpdate)) {
      this.onUpdateCallbacks.push(onUpdate);
      onUpdate(this.clientState());
    }
  }

  // Closes the connection and releases all the resources. It must be the last
  // method to be called on this object.
  close(): void {
    if (this.keepaliveTimer != null) {
      clearTimeout(this.keepaliveTimer);
      this.keepaliveTimer = null;
    }
    this.wantState = WantState.DISCONNECT;
    this.maybeDisconnect(PAUSED_MESSAGE);
    // Wipe out any remaining client state when we close.
    this.onUpdateCallbacks.forEach((callback) => callback(null));
  }

  // Schedule an async reconnect after an exponential backoff.
  private scheduleReconnect(): void {
    logger.debug(`scheduleReconnect ${this.toString()}: backoff = ${this.backoffMs} `);
    if (this.state.client) {
      throw Error('paraview still connected');
    }
    if (this.reconnectTimer == null) {
      if (this.backoffMs < MAX_BACKOFF_MS) {
        this.backoffMs *= 1.2;
      }
      this.reconnectTimer = setTimeout(() => {
        this.reconnectTimer = null;
        if (this.wantState === WantState.CONNECT) {
          this.maybeStartConnect();
        }
      }, this.backoffMs);
    }
  }

  private maybeStartConnect(): void {
    if (this.state.client == null && !this.connecting) {
      logger.info(`maybeStartConnect ${this.viewName} start connKey ${this.connKey()} `);
      this.connecting = true;
      this.disconnectReason = null;
      this.connector.connect();
      this.resetConnectTimer();
      this.onProgress({
        text: CONNECTING_MESSAGE,
        progress: INDEFINITE_PROGRESS,
      });
    }
  }

  // Disconnects the connection if it's been established. An optional message
  // shows the reason of disconnect. If it's empty, the default generic message
  // will shown on the screen.
  //
  // maybeDisconnect can be called in multiple scenarios like
  // 1. Connection closed with an error.
  // 2. timeout while creating the connection.
  // 3. ping response not received in time from the server.
  // In all scenarios, we should make sure the state is reset correctly and
  // we can reconnect if needed.
  private maybeDisconnect(message: string): void {
    if (!message) {
      throw Error('no disconnect message set');
    }
    // Destroy the client connection if established.
    // The client object is only set if the connection is successful.
    if (this.state.client != null) {
      logger.info(`maybeDisconnect ${this.toString()} msg = ${message} start`);
      this.state.client.connection.destroy();
      if (this.connecting) {
        throw Error(`connecting ${this.toString()} `);
      }
    } else {
      logger.info(`maybeDisconnect ${this.toString()} msg = ${message} noop`);
    }
    this.clearPingRequestTimer();
    this.clearPingTimeout();
    this.clearConnectTimer();
    this.clearProgressTimer();
    if (this.wantState === WantState.DISCONNECT) {
      this.updateState({
        ...this.state,
        client: null,
        connState: ConnState.VOLUNTARY_DISCONNECT,
        message,
        progress: NO_PROGRESS,
      });
      this.clearLongConnectionTimers();
    } else {
      this.updateState({
        ...this.state,
        client: null,
        connState: ConnState.CONNECTING,
        message: CONNECTING_MESSAGE,
        progress: INDEFINITE_PROGRESS,
      });
      this.maybeResetStartConnectingTime();
      this.onProgress({
        text: CONNECTING_MESSAGE,
        progress: INDEFINITE_PROGRESS,
      });
    }
  }

  // Update the connection state and trigger callbacks.
  private updateState(state: ClientState) {
    if (!state) {
      throw Error(`null state: ${state} `);
    }
    if (clientStateEquals(state, this.state)) {
      return;
    }
    logger.debug(`UpdateState: old = ${JSON.stringify(this.state)} new= ${JSON.stringify(state)} `);
    this.state = state;
    this.onUpdateCallbacks.forEach((callback) => callback(state));
  }

  // Keepalive should be called to tell that the connection should be kept
  // alive for the next KEEPALIVE_TIMEOUT_MS. If called when the
  // wantState=DISCONNECT mode, this function sets wantState=CONNECT and start
  // a new connection.
  public keepalive(): void {
    this.wantState = WantState.CONNECT;
    this.maybeStartConnect();

    if (this.keepaliveTimer != null) {
      clearTimeout(this.keepaliveTimer);
      this.keepaliveTimer = null;
    }
    this.keepaliveTimer = setTimeout(() => {
      if (this.state.client) {
        logger.info(
          `Disconnect ${this.viewName} sess = (${this.state.client.serverUid}) ` +
          `connKey:  ${this.connKey()} `,
        );
      }
      this.wantState = WantState.DISCONNECT;
      this.maybeDisconnect(PAUSED_MESSAGE);
    }, isStaff() ? KEEPALIVE_TIMEOUT_MS_STAFF : KEEPALIVE_TIMEOUT_MS);
  }

  public clientState(): ClientState {
    return this.state;
  }

  private toString(): string {
    return `viewname = ${this.viewName} projectId = ${this.projectId} ` +
      `connState = ${this.state.connState} client = ${this.state.client ? 1 : 0} ` +
      `msg = '${this.state.message}' want = ${this.wantState} connecting = ${this.connecting} ` +
      `progress = ${this.progressState} `;
  }

  private maybeResetStartConnectingTime() {
    if (this.stopConnectingTime > this.startConnectingTime) {
      this.startConnectingTime = new Date().getTime();
    }
  }

  private isLongConnectionCallback(result: ObservableResult, thresholdMillis: number) {
    // If the connection has been made, it's not a long connection.
    if (this.stopConnectingTime > this.startConnectingTime) {
      result.observe(0, {
        'lc.projectID': this.projectId,
      });
      return;
    }
    if (new Date().getTime() - this.startConnectingTime > thresholdMillis) {
      result.observe(1, {
        'lc.projectID': this.projectId,
      });
      return;
    }
    result.observe(0, {
      'lc.projectID': this.projectId,
    });
  }
}

const connectorCache: { [connKey: string]: Connector } = {};

// Maps the connection ID to the connection state.
export const paraviewClientState = atomFamily<ClientState | null, string>({
  key: 'paraviewClientState',
  default: null,
});

export const connectionKey = selectorFamily<string, RecoilProjectKey>({
  key: 'connectionKey',
  get: (key: RecoilProjectKey) => ({ get }) => {
    const [currView, activeUrl] = get(waitForAll([
      currentViewAtom_DEPRECATED,
      activeVisUrlState(key),
    ]));
    const viewName = currView === CurrentView.SETUP ? ViewName.SETUP : ViewName.SOLUTION;
    const meshMetadata = get(meshMetadataSelector(
      { projectId: key.projectId, meshUrl: activeUrl },
    ));
    return newConnKey(
      key.projectId,
      key.jobId,
      activeUrl,
      viewName,
      meshMetadata?.meshMetadata || null,
      meshMetadata?.solnMetadata || null,
    );
  },
});

// A context manager for paraview connection. viewName identifies the rendering
// buffer on the paraview server. It returns a non-null Client once the
// connection to paraview is established. Note that the context value may
// repeatedly transition between nonnull and null when network problems happen.
export function useParaviewClientState(
  projectId: string,
  workflowId: string,
  jobId: string,
): ClientState {
  const currView = useCurrentView();
  const connKey = useRecoilValue(connectionKey({ projectId, workflowId, jobId }));
  const viewName = currView === CurrentView.SETUP ? ViewName.SETUP : ViewName.SOLUTION;
  const [clientState, setClientState] = useRecoilState(paraviewClientState(connKey));
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  const onUpdate = useCallback((newState: ClientState | null) => {
    const newStateStr = newState ? `${newState.client} ${newState.connState} ` : 'null';
    logger.debug(`onUpdate: pvstate key = ${connKey} ${newStateStr} `);
    setClientState(newState);
  }, [connKey, setClientState]);

  useEffect(() => {
    if (!connKey || lcvisEnabled) {
      return;
    }
    let conn: Connector | null = connectorCache[connKey];
    if (!conn) {
      // TODO(supriya): Check if we should do this? This can create delays in
      // some scenarios.
      // No connection exists yet. First, close existing connections for the same
      // view (typically, there is at most one such connection). This is not
      // strictly necessary, but it helps conserve resources on
      // the server side.
      Object.values(connectorCache).forEach((cachedConn: Connector) => {
        if (cachedConn.viewName === viewName) {
          logger.info(`Closing connection connKey: ${cachedConn.connKey()} `);
          cachedConn.close();
          if (cachedConn !== connectorCache[cachedConn.connKey()]) {
            throw Error('key mismatch: {cachedConn.connKey()}');
          }
          delete connectorCache[cachedConn.connKey()];
        }
      });
      logger.info(`Creating view ${viewName}: connKey = ${connKey} `);
      conn = new Connector(viewName, connKey, projectId);
      connectorCache[connKey] = conn;
    }
    conn.addCallback(onUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connKey, lcvisEnabled]);

  if (!clientState) {
    return emptyClientState(connKey);
  }
  return clientState;
}

// Renew the connection lease for the given connection key so that the connection remains
// live for the next KEEPALIVE_TIMEOUT_MS.  If called when the connection is not
// established, it starts new connection.
export function keepalive(state: ClientState): void {
  const client = connectorCache[state.connKey];
  if (client) {
    client.keepalive();
  }
}
