// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useState } from 'react';

import { onHide, onShow } from '@intercom/messenger-js-sdk';

declare global {
  interface Window {
    intercomListenersAdded?: boolean;
  }
}

export const useIntercomOpened = () => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    // Make sure we don't add the listeres more than once
    if (!window.intercomListenersAdded) {
      onShow(() => {
        setOpened(true);
      });
      onHide(() => {
        setOpened(false);
      });
      window.intercomListenersAdded = true;
    }
  }, []);

  return opened;
};
