// Copyright 2025 Luminary Cloud, Inc. All Rights Reserved.
import { useGeometryServerStatus } from '../../recoil/geometry/geometryServerStatus';
import { useGeometryUsesTags } from '../../recoil/geometry/geometryUsesTags';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';

export const useCanModifyTags = () => {
  const { projectId, geometryId } = useProjectContext();
  const isGeoServerActive = useGeometryServerStatus(geometryId);
  const isGeometryView = useIsGeometryView();
  const geoUsesTags = useGeometryUsesTags(projectId, '', '');

  return isGeoServerActive && isGeometryView && geoUsesTags;
};
