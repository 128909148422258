// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useMemo } from 'react';

import cx from 'classnames';

import backgroundImage from '../assets/images/luminary-get-started.jpg';
import RectangleButton from '../components/Button/RectangleButton';
import { createStyles, makeStyles } from '../components/Theme';
import VandV from '../components/VandV';
import ProjectDialog from '../components/dialog/Project';
import { MainPageLayout } from '../components/layout/page/Main';
import { useProjectDialogState } from '../components/project/controller/dialog/useProjectDialogState';
import { useSaveProject } from '../components/project/controller/useSaveProject';
import {
  LEARNING_URL,
  SUPPORT_URL,
} from '../lib/constants';
import { colors } from '../lib/designSystem';
import { INTERCOM_LAUNCHER_SELECTOR, isIntercomReady } from '../lib/intercom';

import GettingStartedCarousel from '@/components/GettingStartedCarousel';
import HowTo from '@/components/HowTo';
import { HEADER_HEIGHT } from '@/components/layout/PageHeader';
import { SampleProjectCategory } from '@/lib/sampleProjects';
import { useSampleProjectsValue } from '@/state/external/project/sampleProjects';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      minWidth: '600px',
      marginLeft: '0',
      marginRight: '0',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      paddingBottom: '80px',
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '0 20px',
      maxWidth: '940px',
      gap: '48px',
    },
    image: {
      height: '248px',
      background: `url(${backgroundImage}) center center no-repeat`,
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      marginTop: `${-HEADER_HEIGHT}px`,
    },
    title: {
      position: 'absolute',
      bottom: '36px',
      fontSize: '32px',
      lineHeight: 1.25,
      fontWeight: 600,
    },
    intro: {
      display: 'flex',
      padding: '24px 48px',
      flexDirection: 'column',
      gap: '24px',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRadius: '8px',
      backgroundColor: colors.surfaceMedium1,
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '36px 48px',
    },
    action: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    actionTitle: {
      fontSize: '16px',
      fontWeight: 600,
    },
    actionDescription: {
      lineHeight: '20px',
      color: colors.lowEmphasisText,
    },
    topButtons: {
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
    },
    sectionHeader: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600,
      paddingBottom: '8px',
    },
    sectionDescriptionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    sectionDescription: {
      fontSize: '14px',
      lineHeight: '20px',
      paddingBottom: '16px',
      color: colors.neutral800,
    },
    viewAll: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 700,
      color: colors.neutral800,
      border: 'none',
      background: 'transparent',
      cursor: 'pointer',
      padding: 0,
      height: 'max-content',
    },
    cards: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    VnVs: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
  }),
  { name: 'GetStartedPage' },
);

const GetStartedPage = () => {
  // Hooks
  const dialogState = useProjectDialogState();
  const saveProject = useSaveProject(() => dialogState.close());
  const classes = useStyles();

  const sampleProjects = useSampleProjectsValue();

  const sampleProjectsToRender = useMemo(() => [
    ...sampleProjects?.sampleProjects.map((sampleProject) => ({
      projectId: sampleProject.projectId,
      projectDescription: sampleProject.projectDescription,
      projectName: sampleProject.projectName,
      ...(
        'iconName' in sampleProject ?
          { iconName: sampleProject.iconName } :
          { imageUrl: sampleProject.imageUrl }
      ),
      documentationUrl: sampleProject.documentationUrl,
      category: sampleProject.category,
      teaser: sampleProject.teaser,
      position: sampleProject.position,
      comingSoon: sampleProject.comingSoon,
    })) || [],
  ], [sampleProjects?.sampleProjects]);

  const tutorials = useMemo(() => sampleProjectsToRender.filter(
    (sampleProject) => sampleProject.category === SampleProjectCategory.TUTORIAL,
  ), [sampleProjectsToRender]);

  const VnVs = useMemo(() => sampleProjectsToRender.filter(
    (sampleProject) => sampleProject.category === SampleProjectCategory.V_AND_V,
  ), [sampleProjectsToRender]);

  const withSkippedPropagation = (
    event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>,
    onClick: () => void,
  ) => {
    event.stopPropagation();
    onClick?.();
  };

  const handleViewAllTutorialsClick = () => {
    dialogState.openTutorials();
  };

  return (
    <MainPageLayout
      noHeading
      noPadding
      title="Get Started">
      <div className={classes.root}>
        <div className={classes.image}>
          <div className={classes.container}>
            <div className={classes.content}>
              <div className={classes.title}>Welcome to Luminary!</div>
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={classes.topButtons}>
              <RectangleButton href={LEARNING_URL} icon={{ name: 'compassIcon' }}>
                Documentation
              </RectangleButton>
              <div className={cx(
                classes.container,
                isIntercomReady() ? INTERCOM_LAUNCHER_SELECTOR.replace('.', '') : '',
              )}>
                <RectangleButton
                  allowPropagation={isIntercomReady()}
                  href={!isIntercomReady() ? SUPPORT_URL : undefined}
                  icon={{ name: 'comments', maxWidth: 16, maxHeight: 16 }}>
                  Chat Support
                </RectangleButton>
              </div>
              <RectangleButton
                icon={{ name: 'ringPlus' }}
                onClick={() => dialogState.newProject()}>
                Create a Blank Project
              </RectangleButton>
            </div>
            {tutorials.length > 0 && (
              <GettingStartedCarousel
                items={tutorials}
                viewAllClick={handleViewAllTutorialsClick}
              />
            )}
            {VnVs.length > 0 && (
              <div>
                <div className={classes.sectionHeader}>
                  Read our V&Vs
                </div>
                <div className={classes.sectionDescription}>
                  Verified and Validated: Real world examples of Luminary&apos;s performance
                </div>
                <div className={classes.VnVs}>
                  {VnVs.map((VnV) => (
                    <VandV
                      description={VnV.projectDescription}
                      documentationUrl={VnV.documentationUrl}
                      image={{
                        src: 'imageUrl' in VnV ? VnV.imageUrl : '',
                        alt: VnV.projectName,
                      }}
                      key={VnV.projectName}
                      onClick={
                        (event) => {
                          withSkippedPropagation(event, () => dialogState.openVnV());
                        }
                      }
                      title={VnV.projectName}
                    />
                  ))}
                </div>
              </div>
            )}
            <div>
              <div className={classes.sectionHeader}>
                How To&apos;s
              </div>
              <div className={classes.sectionDescription}>
                Videos to help you learn your way around Luminary
              </div>
              <HowTo />
            </div>
          </div>
        </div>
      </div>
      <ProjectDialog
        {...dialogState.props}
        onSubmit={saveProject}
      />
    </MainPageLayout>
  );
};

export default GetStartedPage;
