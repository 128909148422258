// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { isInitialized } from '../lib/farfieldUtils';
import { findBodyFrame } from '../lib/motionDataUtils';
import { Level } from '../lib/notificationUtils';
import { getHLimitBounds, getRefinementRegionWarnings } from '../lib/refinementRegionUtils';
import { getSimulationParam } from '../lib/simulationParamUtils';
import { getDuplicateSurfaceMeshErrors } from '../lib/simulationValidation';
import { allVolumesAssigned, getVolumeIdsFromSurfaces, mapIndicestoIds } from '../lib/volumeUtils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

import { checkedUrlsState } from './checkedGeometryUrls';
import { entityGroupDataSelector } from './entityGroupState';
import { geometryTagsState } from './geometry/geometryTagsState';
import { pendingWorkOrdersState } from './pendingWorkOrders';
import { cadMetadataState } from './useCadMetadata';
import { cadModifierState } from './useCadModifier';
import { meshGenParamsSelector } from './useMeshGeneration';
import { MeshPanelType, meshPanelStateAtom } from './useMeshPanelState';
import { meshingMultiPartSelector } from './useMeshingMultiPart';
import { projectActiveMeshSelector } from './useProjectActiveMesh';
import { staticVolumesState } from './volumes';
import { currentConfigSelector } from './workflowConfig';

export interface MeshSettingsWarning {
  disabledReason: string;
  disabledLevel: Level;
  warningLocations: Array<WarningLocation>;
}

export enum WarningLocation {
  MESH_SIZE_MIN = 'MESH_SIZE_MIN',
  MESH_SIZE_MAX = 'MESH_SIZE_MAX',
  MODEL_SIZE = 'MODEL_SIZE',
  BL_SIZE = 'BL_SIZE',
}

export type MeshValidorRecoilKey = {
  projectId: string;
  workflowId: string;
  jobId: string;
  readOnly: boolean;
}

const ComplexityType = meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType;
export const MESH_GEN_WARNING_MESSAGE = 'A new mesh is being generated.';

// This large selector was extracted from Mesh.tsx. Its purpose is to evaluate the mesh inputs and
// mesh state. If there is an issue, warning, or reason why a mesh cannot be generated, the warning
// is returned so that it can be displayed on the Mesh Tab or subtab (Mesh Size, Model, etc.)
export const meshValidator = selectorFamily<MeshSettingsWarning, MeshValidorRecoilKey>({
  key: 'meshValidator',
  get: (key: MeshValidorRecoilKey) => async ({ get }) => {
    const { projectId, workflowId, jobId, readOnly } = key;

    // Get all mesh data needed to determine its status and any errors
    const [
      meshMultiPart,
      meshPanelState,
      activeMesh,
      checkedUrls,
      cadModifier,
      pendingWorkOrders,
      cadMetadata,
      meshGeneration,
      staticVolumes,
      currentConfig,
      entityGroupData,
      geometryTags,
    ] = get(waitForAll([
      meshingMultiPartSelector({ projectId, workflowId, jobId }),
      meshPanelStateAtom(projectId),
      projectActiveMeshSelector({ projectId }),
      checkedUrlsState(projectId),
      cadModifierState(projectId),
      pendingWorkOrdersState(projectId),
      cadMetadataState(projectId),
      meshGenParamsSelector({ projectId, meshUrl: '' }),
      staticVolumesState(projectId),
      currentConfigSelector({ projectId, workflowId, jobId }),
      entityGroupDataSelector({ projectId, workflowId, jobId }),
      geometryTagsState({ projectId }),
    ]));

    const simParam = getSimulationParam(currentConfig);
    const urlChecked = checkedUrls.status === projectstatepb.CheckGeometryStatus.SUCCESSFUL;
    const isMinimal = meshMultiPart?.complexityParams?.type === ComplexityType.MIN;
    const bodyFrame = findBodyFrame(simParam);
    const CHECK_GEOMETRY = frontendpb.WorkOrderType.CHECK_GEOMETRY;
    const GET_MESH = frontendpb.WorkOrderType.GET_MESH;

    const { maxSize, minSize } = meshMultiPart ?
      getHLimitBounds(meshMultiPart) : { maxSize: 0, minSize: 0 };

    // #region
    // Find all volumes that contain at least one of the given surfaces.
    const surfacesToVolumes = (surfaces: string[]) => {
      const volumeIds = getVolumeIdsFromSurfaces(surfaces, staticVolumes);
      return meshMultiPart?.volumeParams.filter((volParams) => {
        const paramsVolumeIds = mapIndicestoIds(staticVolumes, volParams.volumes);
        return paramsVolumeIds.some((volId) => volumeIds.includes(volId));
      }) || [];
    };
    // Run some test function over all models and the volumes associated with them.
    const testAllModels = (
      testFunc: (
        model: meshgenerationpb.MeshingMultiPart_ModelParams,
        volume: meshgenerationpb.MeshingMultiPart_VolumeParams
      ) => boolean,
    ) => meshMultiPart?.modelParams.every(
      (model) => surfacesToVolumes(model.surfaces).every(
        (volume) => testFunc(model, volume),
      ),
    );
    // Run some test function over all boundary layers and the volumes associated with them.
    const testAllBLs = (
      testFunc: (
        blParam: meshgenerationpb.MeshingMultiPart_BoundaryLayerParams,
        volume: meshgenerationpb.MeshingMultiPart_VolumeParams
      ) => boolean,
    ) => meshMultiPart?.blParams.every(
      (blParam) => surfacesToVolumes(blParam.surfaces).every(
        (volume) => testFunc(blParam, volume),
      ),
    );
    // #endregion

    // #region
    // Warnings are used for most messages where something needs to be fix before the user can
    // generate a mesh. Info messages are used when the user is waiting for a process to finish.
    let disabledLevel: Level = 'warning';
    let disabledReason = '';
    const warningLocations: Array<WarningLocation> = [];

    if (readOnly) {
      disabledReason = 'Mesh is read only.';
      disabledLevel = 'info';
      return { disabledLevel, disabledReason, warningLocations };
    }

    // If we have a valid mesh, the validity of the meshgen params is irrelevant. Early return.
    if (activeMesh && meshPanelState === MeshPanelType.DETAILS) {
      return { disabledLevel, disabledReason, warningLocations };
    }

    if (!urlChecked) {
      if (pendingWorkOrders.workOrders[CHECK_GEOMETRY]) {
        disabledReason = `We're ensuring that your geometry can be properly meshed. You can generate
        the mesh once this process has completed.`;
        disabledLevel = 'info';
      } else {
        disabledReason = 'Mesh cannot be generated until geometry is successfully checked.';
      }
    } else if (pendingWorkOrders.workOrders[GET_MESH]) {
      disabledLevel = 'info';
    } else if (!isMinimal && meshMultiPart?.volumeParams.some(
      (volParams) => volParams.minSize >= volParams.maxSize,
    )) {
      disabledReason = 'Min Size must be less than Max Size.';
      warningLocations.push(...[WarningLocation.MESH_SIZE_MIN, WarningLocation.MESH_SIZE_MAX]);
    } else if (
      !isMinimal && !testAllModels((model, volume) => model.maxSize > volume.minSize)
    ) {
      disabledReason = 'Model Max Size must be greater than Volume Min Size.';
      warningLocations.push(...[WarningLocation.MESH_SIZE_MAX, WarningLocation.MODEL_SIZE]);
    } else if (
      !isMinimal && !testAllModels((model, volume) => model.maxSize <= volume.maxSize)
    ) {
      disabledReason = 'Model Max Size must be less than or equal to Volume Max.';
      warningLocations.push(...[WarningLocation.MESH_SIZE_MAX, WarningLocation.MODEL_SIZE]);
    } else if (
      !isMinimal &&
      !testAllBLs((blParam, volume) => blParam.initialSize <= volume.maxSize)
    ) {
      disabledReason = 'Boundary Layer Initial Size must be less than or equal to Volume Max.';
      warningLocations.push(...[WarningLocation.MESH_SIZE_MAX, WarningLocation.BL_SIZE]);
    } else if (cadModifier && !isInitialized(cadModifier)) {
      disabledReason = 'Far-Field settings are not saved.';
    } else if (!meshMultiPart) {
      disabledReason = 'Multi-part meshing proto is not defined.';
    } else if (!isMinimal && !allVolumesAssigned(cadMetadata.nBodies, meshMultiPart)) {
      disabledReason = 'All Volumes must have a Mesh Size.';
    } else if (meshGeneration?.addRefinement && !bodyFrame) {
      disabledReason = 'Mesh refinement requires Body Frame to orient refinement ' +
        'box. Deselect Refinement checkbox or add a body frame.';
    } else if (meshMultiPart.refinementParams.some((
      region,
    ) => !!getRefinementRegionWarnings(region, maxSize, minSize).length)) {
      disabledReason = 'Refinement regions have configuration warnings.';
    } else {
      const duplicateSurfaceWarnings = getDuplicateSurfaceMeshErrors(
        meshMultiPart,
        getSimulationParam(currentConfig),
        entityGroupData,
        geometryTags,
      );
      if (duplicateSurfaceWarnings.model.length) {
        disabledReason = 'Duplicate surfaces found in model configuration.';
      } else if (duplicateSurfaceWarnings.boundary.length) {
        disabledReason = 'Duplicate surfaces found in boundary layer configuration.';
      }
    }
    // #endregion

    return { disabledLevel, disabledReason, warningLocations };
  },
  dangerouslyAllowMutability: true,
});

export function useMeshValidator(
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
) {
  const key = { projectId, workflowId, jobId, readOnly };
  return useRecoilValue(meshValidator(key));
}
