import { EntityGroupData } from '../recoil/entityGroupState';
import { TRANSPARENCY_MODE_SUBTITLE, defaultTransparencyDialogSettings, useTransparencyDialog } from '../recoil/lcvis/transparencySettings';

import { expandGroups } from './entityGroupUtils';
import { filteredWithLeadIcon } from './menuUtil';

export function useSurfaceTransparencyMenuItems() {
  const [transparencyDialogSettings, setTransparencyDialogSettings] = useTransparencyDialog();

  return (
    nodeIdsToGroup: string[],
    entityGroupData: EntityGroupData,
  ) => {
    const canMakeSelectionTransparent = Boolean(
      nodeIdsToGroup.length &&
      entityGroupData.groupMap.has(nodeIdsToGroup[0]),
    );

    return filteredWithLeadIcon('transparency', [
      {
        itemConfig: {
          label: 'Make Transparent',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => {
            setTransparencyDialogSettings({
              active: true,
              mode: 'transparent',
              targetSurfaceIds: new Set(expandGroups(entityGroupData.leafMap)(nodeIdsToGroup)),
            });
          },
        },
        shouldShow: !transparencyDialogSettings.active && canMakeSelectionTransparent,
      },
      {
        itemConfig: {
          label: 'Make Others Transparent (Reveal)',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => {
            setTransparencyDialogSettings({
              active: true,
              mode: 'opaque',
              targetSurfaceIds: new Set(expandGroups(entityGroupData.leafMap)(nodeIdsToGroup)),
            });
          },
        },
        shouldShow: !transparencyDialogSettings.active && canMakeSelectionTransparent,
      },
      {
        itemConfig: {
          label: 'Exit Transparency Mode',
          help: TRANSPARENCY_MODE_SUBTITLE,
          onClick: () => setTransparencyDialogSettings(defaultTransparencyDialogSettings()),
        },
        shouldShow: transparencyDialogSettings.active,
      },
    ]);
  };
}
