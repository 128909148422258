// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { MouseEvent, createRef, forwardRef, useCallback, useMemo, useRef, useState } from 'react';

import { rollupGroups } from '../../../lib/entityGroupUtils';
import { CREATE_TAG_NODE_TYPES, GEOMETRY_TREE_NODE_TYPES, NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { OVERLAY_CARD_WIDTH } from '../../../lib/visUtils';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useFloatingPanelTopRight } from '../../../recoil/lcvis/infoCardDims';
import { defaultTagSelectionSettings, useTagSelectionSettings } from '../../../recoil/lcvis/tagsSelection';
import { ActionButton } from '../../Button/ActionButton';
import { useDraggable } from '../../Paraview/draggable';
import { useProjectContext } from '../../context/ProjectContext';
import { Dialog } from '../../dialog/Base';
import CreateTagDialog from '../../dialog/CreateTag';
import { useTagsInteractiveGeometry } from '../../hooks/useInteractiveGeometry';
import { NodeSubselect } from '../../treePanel/NodeSubselect';

const TAG_SELECTION_DRAG_ID = 'tag-selection-panel';

const TagSelectionDialog = forwardRef((
  props,
  ref,
) => {
  const overlayRef = createRef<HTMLDivElement>();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { initDrag, draggedPositions, dragPositionToStyle, currentDragId } = useDraggable();
  const dialogNode = useRef<HTMLDivElement>(null);
  const isDragging = currentDragId === TAG_SELECTION_DRAG_ID;
  const [tagSelectionSetting, setTagSelectionSettings] = useTagSelectionSettings();
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const [isCreateTagDialogOpen, setIsCreateTagDialogOpen] = useState(false);
  const [tagCreationIds, setTagCreationIds] = useState<string[]>([]);

  const { createTag } = useTagsInteractiveGeometry();

  const { active, surfaces } = tagSelectionSetting;

  const topRight = useFloatingPanelTopRight(dialogNode);

  const groupedSurfaces = useMemo(
    () => rollupGroups(entityGroupData)([...surfaces]),
    [entityGroupData, surfaces],
  );

  const onMouseDown = (event: MouseEvent) => {
    if (overlayRef.current && dialogNode.current) {
      initDrag(
        TAG_SELECTION_DRAG_ID,
        event,
        dialogNode.current,
        overlayRef.current,
      );
    }
  };

  const nodeFilter = useCallback((node: NodeType) => {
    if (NodeType[node].includes('ROOT') || NodeType[node].includes('CONTAINER')) {
      // check if the nodetype name contains ROOT or CONTAINER
      if (GEOMETRY_TREE_NODE_TYPES.has(node)) {
        return { related: true };
      }
      return { related: false };
    }
    if (
      [NodeType.PHYSICAL_BEHAVIOR, NodeType.PARTICLE_GROUP, NodeType.FILTER].includes(node)
    ) {
      return {
        related: true,
        disabled: true,
      };
    }
    if (GEOMETRY_TREE_NODE_TYPES.has(node)) {
      return { related: true, disabled: false };
    }
    return defaultNodeFilter(node);
  }, []);

  const child = (
    <>
      <NodeSubselect
        autoStart={!tagSelectionSetting.surfaces.size}
        id="tagCreationSelect"
        labels={['Surfaces', 'Volumes']}
        nodeFilter={nodeFilter}
        nodeIds={groupedSurfaces}
        onChange={(selected) => {
          setTagSelectionSettings({
            active,
            surfaces: new Set<string>(selected),
          });
        }}
        referenceNodeIds={[NodeType[NodeType.TAGS_CONTAINER]]}
        title="Select Surfaces or Volumes"
        visibleTreeNodeTypes={CREATE_TAG_NODE_TYPES}
      />
      <br />
      <span style={{ fontSize: '12px' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <ActionButton
            disabled={tagSelectionSetting.surfaces.size <= 0}
            onClick={async () => {
              setIsCreateTagDialogOpen(true);
              setTagCreationIds(Array.from(tagSelectionSetting.surfaces));
            }}>
            Create tag
          </ActionButton>
        </div>
      </span>
    </>
  );

  return (
    <div
      ref={dialogNode}
      style={{
        position: 'absolute',
        zIndex: 1000,
        transition: 'top 0.2s',
        ...topRight,
        ...dragPositionToStyle(draggedPositions[TAG_SELECTION_DRAG_ID]),
      }}>
      <>
        {isCreateTagDialogOpen && (
          <CreateTagDialog
            isOpen={isCreateTagDialogOpen}
            onCancel={() => {
              setIsCreateTagDialogOpen(false);
              setTagCreationIds([]);
            }}
            onSubmit={async (name) => {
              await createTag(name, tagCreationIds);
              setIsCreateTagDialogOpen(false);
              setTagCreationIds([]);
              setTagSelectionSettings(defaultTagSelectionSettings());
            }}
          />
        )}
      </>
      <Dialog
        compact
        draggable={!!onMouseDown}
        dragging={isDragging}
        onClose={() => {
          setTagSelectionSettings(defaultTagSelectionSettings());
        }}
        onFrameMousedown={onMouseDown}
        open={active}
        title="Tag creation"
        width={OVERLAY_CARD_WIDTH}>
        {child}
      </Dialog>
    </div>
  );
});

export default TagSelectionDialog;
