// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState } from 'recoil';

import { CurrentView } from '../lib/componentTypes/context';
import { cadMetadataFixture } from '../lib/fixtures';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as cadmetadatapb from '../proto/cadmetadata/cadmetadata_pb';
import { currentViewState } from '../state/internal/global/currentView';

import { getGeoState, onGeometryTabSelector } from './geometry/geometryState';
import { getGeometryTessellationRpc } from './meshState';
import { selectedGeometryState } from './selectedGeometry';

const cadMetadataKey = 'cadMetadata';

const DEFAULT_MODIFIER = new cadmetadatapb.CadMetadata();

function serialize(val: cadmetadatapb.CadMetadata): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): cadmetadatapb.CadMetadata {
  return (val.length ?
    cadmetadatapb.CadMetadata.fromBinary(val) :
    DEFAULT_MODIFIER);
}

// Selects the CAD metadata from the session state kv store.
const cadMetadataSelectorRpc = selectorFamily<
  cadmetadatapb.CadMetadata, persist.RecoilProjectKey
>({
  key: `${cadMetadataKey}/rpc`,
  get: (key: persist.RecoilProjectKey) => ({ get }) => {
    if (get(onGeometryTabSelector)) {
      const geoState = getGeoState(get, key.projectId);
      return geoState ? geoState.cadMetadata : DEFAULT_MODIFIER.clone();
    }
    const currentView = get(currentViewState);
    if (currentView !== CurrentView.ANALYSIS) {
      return persist.getProjectState(key.projectId, [cadMetadataKey], deserialize);
    }
    const selectedGeometry = get(selectedGeometryState(key));
    if (!selectedGeometry.geometryId) {
      return persist.getProjectState(key.projectId, [cadMetadataKey], deserialize);
    }
    const geometryTessellation = get(getGeometryTessellationRpc({
      projectId: key.projectId,
      geometryId: selectedGeometry.geometryId,
      geometryVersionId: selectedGeometry.geometryVersionId,
    }));
    return geometryTessellation.reply.cadMetadata ||
      persist.getProjectState(key.projectId, [cadMetadataKey], deserialize);
  },
  dangerouslyAllowMutability: true,
});

export const cadMetadataSelectorSetupTabRpc = selectorFamily<cadmetadatapb.CadMetadata, string>({
  key: `${cadMetadataKey}SetupTab/rpc`,
  get: (projectId: string) => () => persist.getProjectState(
    projectId,
    [cadMetadataKey],
    deserialize,
  ),
  dangerouslyAllowMutability: true,
});

const cadMetadataSelectorTesting = selectorFamily<
  cadmetadatapb.CadMetadata, persist.RecoilProjectKey
>({
  key: `${cadMetadataKey}/testing`,
  get: () => cadMetadataFixture,
  dangerouslyAllowMutability: true,
});

const cadMetadataSetupTabSelectorTesting = selectorFamily<
  cadmetadatapb.CadMetadata, string
>({
  key: `${cadMetadataKey}/setuptab/testing`,
  get: () => cadMetadataFixture,
  dangerouslyAllowMutability: true,
});

export const cadMetadataSelector = isTestingEnv() ?
  cadMetadataSelectorTesting : cadMetadataSelectorRpc;

export const cadMetadataState = atomFamily<cadmetadatapb.CadMetadata, persist.RecoilProjectKey>({
  key: cadMetadataKey,
  default: cadMetadataSelector,
  effects: (key: persist.RecoilProjectKey) => {
    // Disallow mutation and updates through the streaming RPC while in the analysis view. In that
    // case, the geometry metadata is immutable.
    if (key.jobId) {
      return [];
    }
    return [syncProjectStateEffect(key.projectId, cadMetadataKey, deserialize, serialize)];
  },
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

const cadMetadataSetupTabSelector = isTestingEnv() ?
  cadMetadataSetupTabSelectorTesting : cadMetadataSelectorSetupTabRpc;

export const cadMetadataSetupTabState = atomFamily<cadmetadatapb.CadMetadata, string>({
  key: `${cadMetadataKey}SetupTab`,
  default: cadMetadataSetupTabSelector,
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, cadMetadataKey, deserialize, serialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const useCadMetadata = (projectId: string, workflowId: string, jobId: string) => (
  useRecoilState(cadMetadataState({ projectId, workflowId, jobId }))
);
