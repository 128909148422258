// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { DefaultValue, atom, selectorFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { getSessionStorageData } from '../lib/browserStorage';
import { sessionStorageEffect } from '../lib/persist';

// Track the expanded/collapsed state of panels, keyed by nodeId/panelName, and persist in
// sessionStorage

// #region nodeExpandedPanels

export type NodeId = string;
export type PanelName = string;

// The PanelsState type represents a { panel-name <=> expanded }  mapping
export type PanelsState = Record<PanelName, boolean>;
// The NodePanelsState type represents panel states for individual node IDs
export type NodePanelsState = Record<NodeId, PanelsState>;

export const STORAGE_KEY = 'nodePanelsState';
export const DEFAULT_STORE = {};

export const nodeExpandedPanelsStore = atom<NodePanelsState>({
  key: 'nodeExpandedPanelsStore',
  default: getSessionStorageData<NodePanelsState>(STORAGE_KEY, DEFAULT_STORE),
  effects: [sessionStorageEffect(STORAGE_KEY)],
});

// Selector for all expanded panels, keyed by node ID
export const nodeExpandedPanelsState = selectorFamily<PanelsState, NodeId>({
  key: 'nodeExpandedPanelsState',
  get: (key: NodeId) => ({ get }) => {
    const store = get(nodeExpandedPanelsStore);
    return store[key] || {};
  },
  set: (key: NodeId) => ({ get, set }, panelsState: PanelsState | DefaultValue) => {
    const store = get(nodeExpandedPanelsStore);
    const newStore: NodePanelsState = { ...store };
    if (panelsState instanceof DefaultValue) {
      delete newStore[key];
    } else {
      newStore[key] = panelsState;
    }

    set(nodeExpandedPanelsStore, newStore);
  },
});

// #region panel

export type PanelKey = {
  nodeId: NodeId;
  panelName: PanelName;
  defaultExpanded?: boolean;
}

// Selector for getting or setting a boolean (representing expanded) for an individual panel,
// keyed by { nodeID, panelName }
export const panelState = selectorFamily<boolean, PanelKey>({
  key: 'panelState',
  get: (key: PanelKey) => ({ get }) => {
    const { nodeId, panelName, defaultExpanded = false } = key;
    const panels = get(nodeExpandedPanelsState(nodeId));
    if (panelName in panels) {
      return panels[panelName];
    }
    return defaultExpanded;
  },
  set: (key: PanelKey) => ({ get, set }, expanded: (boolean | DefaultValue)) => {
    const { nodeId, panelName, defaultExpanded = false } = key;
    const panels = get(nodeExpandedPanelsState(nodeId));
    const newPanels = { ...panels };

    newPanels[panelName] = (expanded instanceof DefaultValue) ? defaultExpanded : expanded;

    set(nodeExpandedPanelsState(nodeId), { ...newPanels });
  },
});

export function usePanel(key: PanelKey) {
  return useRecoilState(panelState(key));
}

export function useSetPanel(key: PanelKey) {
  return useSetRecoilState(panelState(key));
}
