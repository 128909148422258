// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { Message } from '@bufbuild/protobuf';

import { Param, ParamGroup, ParamType } from '../ProtoDescriptor';
import { paramGroupDesc } from '../SimulationParamDescriptor';

import { extractProtoField } from './proto';
import { upperFirst } from './text';

export interface CallbackArgs<T> {
  groupDesc: ParamGroup;
  param: Param;
  value: any;
  set: (value: any) => void;
  groupCallbackReturn: T;
}

export interface GroupCallbackArgs<T> {
  groupDesc: ParamGroup;
  proto: Message;
  parentGroupCallbackReturn: T;
}

function getAccessor(param: Param | ParamGroup) {
  // Most params in gen/lib/param_registry.py have lower case names, such as name="sigma_w_2", used
  // in the Turbulence param group.  This translates to camel-case and pascal-case names "sigmaW2"
  // and "SigmaW2", respectively, both of which are expressed in SimulationParamDescriptor.ts.  In
  // the generated Typescript bindings, the corresponding attribute in the Turbulence message is the
  // camel-case "sigmaW1".  So naturally we want to use the camel-case value as an accessor for the
  // "sigma_w_2" param on a Turbulence message.
  //
  // However, a handful of params in param_registry.py start with upper case names, such as
  // name="C_sa_des", also used in the Turbulence param group.  In the Typescript bindings, the
  // generated attribute is "CSaDes", so we need to use the pascal-case value as an accessor.
  //
  // If the raw param name starts with an upper-case letter, then return the pascalCaseName;
  // otherwise, return the camelCaseName
  if (param.name === upperFirst(param.name)) {
    return param.pascalCaseName;
  }
  return param.camelCaseName;
}

function getDescValue(proto: Message, desc: Param | ParamGroup): any {
  const accessor = getAccessor(desc);
  return (proto as any)[accessor];
}

function normalizeValueToSet(desc: Param, value: any) {
  const isInt = (
    desc.type === ParamType.INT &&
    !Number.isNaN(Number(value))
  );

  return isInt ? BigInt(value as number) : value;
}

export function setParamValue(proto: Message, desc: Param, value: any): void {
  const accessor = getAccessor(desc);
  (proto as any)[accessor] = normalizeValueToSet(desc, value);
}

// Calls functions for every parameter and every group in the proto message/descriptor pair.
// Generic argument T represents an object that is returned by the groupCallback function and
// passed as argument to all parameter callbacks that are part of the respective group.
export function paramCallback<T>(
  // Proto message containing values of the group described by paramGroup.
  proto: Message,
  // Descriptor for the proto message.
  groupDesc: ParamGroup,
  // Function called for every parameter.
  callback: (args: CallbackArgs<T>) => void,
  // Function called for every group.
  groupCallback: (args: GroupCallbackArgs<T>) => T,
  // Object created by the parent of the currently processed group
  parentGroupCallbackReturn: T,
) {
  const groupCallbackReturn = groupCallback({ groupDesc, parentGroupCallbackReturn, proto });
  groupDesc.params.forEach((param) => {
    try {
      if (!param.isMap && !param.isRepeated) {
        callback({
          groupDesc,
          param,
          value: extractProtoField(proto, param),
          set: (value: any) => setParamValue(proto, param, value),
          groupCallbackReturn,
        });
      }
    } catch (error: any) {
      throw Error(`Error when processing ${param.name} in group ${groupDesc.name}: ${error}`);
    }
  });
  groupDesc.paramGroups.forEach((groupName) => {
    try {
      const childGroupDesc = paramGroupDesc[groupName];
      // We do not process maps or external groups
      if (childGroupDesc.isExternal || childGroupDesc.isMap) {
        return;
      }
      if (childGroupDesc.isRepeated) {
        // Repeated groups
        getDescValue(proto, childGroupDesc).forEach((subProto: any) => {
          paramCallback(
            subProto,
            childGroupDesc,
            callback,
            groupCallback,
            groupCallbackReturn,
          );
        });
      } else if (childGroupDesc.isOneOf) {
        // OneOf groups
        const protoValue = (proto as any)[childGroupDesc.camelCaseName];
        childGroupDesc.paramGroups.some((oneOfGroup) => {
          const desc = paramGroupDesc[oneOfGroup];
          if (protoValue.case === desc.camelCaseName) {
            paramCallback(
              protoValue.value,
              desc,
              callback,
              groupCallback,
              groupCallbackReturn,
            );
            return true;
          }
          return false;
        });
      } else {
        // Everything else
        let childProto = extractProtoField(proto, childGroupDesc) as Message;
        if (!childProto && childGroupDesc.createNewProto) {
          childProto = childGroupDesc.createNewProto();
          (proto as any)[childGroupDesc.camelCaseName] = childProto;
        }
        paramCallback(
          childProto,
          childGroupDesc,
          callback,
          groupCallback,
          groupCallbackReturn,
        );
      }
    } catch (error: any) {
      throw Error(`Error when processing group ${groupDesc.name}/${groupName}: ${error}`);
    }
  });
}
