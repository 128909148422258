// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=js+dts"
// @generated from file proto/visualizationservice/visualizationservice.proto (package luminary.proto.visualizationservice, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateExtractFromViewStateRequest, CreatePostProcessingExtractRequest, CreatePostProcessingExtractResponse, DeletePostProcessingExtractRequest, DeletePostProcessingExtractResponse, GetPostProcessingExtractRequest, GetPostProcessingExtractResponse, GetRenderDataUrlsRequest, GetRenderDataUrlsResponse, ListPostProcessingExtractsRequest, ListPostProcessingExtractsResponse, ListSolutionFieldsReply, ListSolutionFieldsRequest } from "./visualizationservice_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service luminary.proto.visualizationservice.VisualizationService
 */
export const VisualizationService = {
  typeName: "luminary.proto.visualizationservice.VisualizationService",
  methods: {
    /**
     * @generated from rpc luminary.proto.visualizationservice.VisualizationService.CreatePostProcessingExtract
     */
    createPostProcessingExtract: {
      name: "CreatePostProcessingExtract",
      I: CreatePostProcessingExtractRequest,
      O: CreatePostProcessingExtractResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc luminary.proto.visualizationservice.VisualizationService.GetPostProcessingExtract
     */
    getPostProcessingExtract: {
      name: "GetPostProcessingExtract",
      I: GetPostProcessingExtractRequest,
      O: GetPostProcessingExtractResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc luminary.proto.visualizationservice.VisualizationService.ListPostProcessingExtracts
     */
    listPostProcessingExtracts: {
      name: "ListPostProcessingExtracts",
      I: ListPostProcessingExtractsRequest,
      O: ListPostProcessingExtractsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc luminary.proto.visualizationservice.VisualizationService.DeletePostProcessingExtract
     */
    deletePostProcessingExtract: {
      name: "DeletePostProcessingExtract",
      I: DeletePostProcessingExtractRequest,
      O: DeletePostProcessingExtractResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Creates a post processing extract from a given project entity to which the
     * current view state is applied. This RPC reuses
     * CreatePostProcessingExtractResponse as response type since this RPC
     * calls CreatePostProcessingExtract internally.
     *
     * @generated from rpc luminary.proto.visualizationservice.VisualizationService.CreateExtractFromViewState
     */
    createExtractFromViewState: {
      name: "CreateExtractFromViewState",
      I: CreateExtractFromViewStateRequest,
      O: CreatePostProcessingExtractResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc luminary.proto.visualizationservice.VisualizationService.GetRenderDataUrls
     */
    getRenderDataUrls: {
      name: "GetRenderDataUrls",
      I: GetRenderDataUrlsRequest,
      O: GetRenderDataUrlsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc luminary.proto.visualizationservice.VisualizationService.ListSolutionFields
     */
    listSolutionFields: {
      name: "ListSolutionFields",
      I: ListSolutionFieldsRequest,
      O: ListSolutionFieldsReply,
      kind: MethodKind.Unary,
    },
  }
};

