// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import { getAdValue, newAdFloat } from '../../../../lib/adUtils';
import { validateGtZeroInt, validateGteOne, validateGteZero } from '../../../../lib/inputValidationUtils';
import newInt from '../../../../lib/intUtils';
import { BOUNDARY_ID, COMMON_START_ICON, adaptationBoundaryHeading } from '../../../../lib/mesh';
import { fromBigInt } from '../../../../lib/number';
import { DEFAULT_ADAPTATION_BL } from '../../../../lib/paramDefaults/boundaryLayerProfileState';
import { getOrCreateAdaptiveMeshRefinement } from '../../../../lib/simulationParamUtils';
import { NodeType, TAGS_NODE_TYPES } from '../../../../lib/simulationTree/node';
import { defaultAdaptationBoundaryLayerParams } from '../../../../lib/simulationUtils';
import { AllTet, BoundaryLayerProfile } from '../../../../proto/client/simulation_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { useSimulationTreeSubselect } from '../../../../recoil/simulationTreeSubselect';
import { WarningLocation, useMeshValidator } from '../../../../recoil/useMeshValidator';
import { ActionButton } from '../../../Button/ActionButton';
import { IconButton } from '../../../Button/IconButton';
import Form from '../../../Form';
import { NumberField } from '../../../Form/NumberField';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useAdaptationBoundarySelection } from '../../../hooks/subselect/useAdaptationBoundarySelection';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { SectionMessage } from '../../../notification/SectionMessage';
import { ArrowUpRightIcon } from '../../../svg/ArrowUpRightIcon';
import { ResetIcon } from '../../../svg/ResetIcon';
import { TrashIcon } from '../../../svg/TrashIcon';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';
import { CustomCount } from '../shared/CustomCount';

interface MeshBoundaryParamsProps {
  // The index of the boundary layer params this is displaying.
  boundaryIndex: number;
  // If the panel contains inputs rather than constant values.
  isInput: boolean;
}

const NODE_SUBSELECT_ID_PREFIX = 'adaptation-bl-';

// A panel displaying a single BoundaryParams.
export const MeshAdaptationBoundaryParams = (props: MeshBoundaryParamsProps) => {
  const { boundaryIndex, isInput } = props;
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo, selectedNode } = useSelectionContext();
  const { simParam, saveParam } = useSimulationConfig();
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const boundary = simParam.adaptiveMeshRefinement?.boundaryLayerProfile[
    boundaryIndex
  ];
  const { nodeFilter, setSurfaces, nodeIds } = useAdaptationBoundarySelection({ boundaryIndex });
  const treeSubselect = useSimulationTreeSubselect();

  const {
    disabledLevel,
    disabledReason,
    warningLocations,
  } = useMeshValidator(projectId, workflowId, jobId, readOnly);

  const showWarning = isInput && warningLocations.includes(WarningLocation.BL_SIZE);

  if (!boundary) {
    return null;
  }

  const updateBoundary = (updateFunc: (params: BoundaryLayerProfile) => void) => {
    saveParam((newParam) => {
      const amr = getOrCreateAdaptiveMeshRefinement(newParam).clone();
      updateFunc(amr.boundaryLayerProfile[boundaryIndex]);
      newParam.adaptiveMeshRefinement = amr;
      return newParam;
    });
  };

  const isDefault = (boundaryIndex === 0);
  const headerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isDefault) {
      // The default has a reset button that resets everything.
      saveParam((newParam) => {
        const amr = getOrCreateAdaptiveMeshRefinement(newParam).clone();
        amr.boundaryLayerProfile = [defaultAdaptationBoundaryLayerParams(simParam, geometryTags)];
        newParam.adaptiveMeshRefinement = amr;
        return newParam;
      });
    } else {
      // The other sections have a trash button that delete this particular boundary layer.
      saveParam((newParam) => {
        const amr = getOrCreateAdaptiveMeshRefinement(newParam).clone();
        const newBoundaryList = amr.boundaryLayerProfile?.splice(boundaryIndex, 1) || [];
        amr.boundaryLayerProfile = newBoundaryList;
        newParam.adaptiveMeshRefinement = amr;
        return newParam;
      });
    }
  };
  const editClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelection([BOUNDARY_ID]);
    setScrollTo({ node: BOUNDARY_ID });
  };
  const headerButton = isInput ? (
    <IconButton
      disabled={readOnly}
      onClick={headerClick}>
      {isDefault ? (
        <ResetIcon maxHeight={13} />
      ) : (
        <TrashIcon maxHeight={13} />
      )}
    </IconButton>
  ) : (
    <ActionButton kind="minimal" onClick={editClick} size="small">
      {readOnly ? 'View' : 'Edit'}
      <ArrowUpRightIcon maxHeight={9} />
    </ActionButton>
  );

  const showNodeTable = isInput;

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        disabled={treeSubselect.id.startsWith(NODE_SUBSELECT_ID_PREFIX)}
        headerRight={headerButton}
        heading={adaptationBoundaryHeading(boundaryIndex)}
        nodeId={`${BOUNDARY_ID}-${boundaryIndex}`}
        panelName="main">
        <Form.LabeledInput label="Number of Layers">
          <NumberField
            asBlock
            disabled={readOnly}
            isInput={isInput}
            onCommit={(newValue: number) => updateBoundary((params: BoundaryLayerProfile) => {
              params.nLayers = newInt(newValue);
            })}
            readOnly={readOnly}
            validate={validateGtZeroInt}
            value={boundary.nLayers ? fromBigInt(boundary.nLayers.value) : null}
          />
        </Form.LabeledInput>
        <Form.LabeledInput label="Initial Size">
          <NumberField
            asBlock
            disabled={readOnly}
            faultType={warningLocations.includes(WarningLocation.BL_SIZE) ?
              'warning' : undefined}
            isInput={isInput}
            onCommit={(newValue: number) => updateBoundary((params: BoundaryLayerProfile) => {
              params.initialSize = newAdFloat(newValue);
            })}
            quantity={QuantityType.LENGTH}
            readOnly={readOnly}
            validate={validateGteZero}
            value={getAdValue(boundary.initialSize) ?? null}
          />
        </Form.LabeledInput>
        {showWarning && (
          <div style={{ marginTop: '8px' }}>
            <SectionMessage level={disabledLevel}>
              {disabledReason}
            </SectionMessage>
          </div>
        )}
        <Form.LabeledInput label="Growth Rate">
          <NumberField
            asBlock
            disabled={readOnly}
            isInput={isInput}
            onCommit={(newValue: number) => updateBoundary((params: BoundaryLayerProfile) => {
              params.growthRate = newAdFloat(newValue);
            })}
            readOnly={readOnly}
            validate={validateGteOne}
            value={getAdValue(boundary.growthRate) ?? null}
          />
        </Form.LabeledInput>
        {showNodeTable && (
          <div style={{ paddingTop: '8px' }}>
            <NodeSubselect
              id={`${NODE_SUBSELECT_ID_PREFIX}${boundaryIndex}`}
              independentSelection
              labels={['surfaces']}
              nodeFilter={nodeFilter}
              nodeIds={nodeIds}
              onChange={setSurfaces}
              readOnly={readOnly}
              referenceNodeIds={[selectedNode?.id || '']}
              title="Surfaces"
              visibleTreeNodeTypes={[NodeType.SURFACE, NodeType.SURFACE_GROUP, ...TAGS_NODE_TYPES]}
            />
          </div>
        )}
        {!isInput && (
          <CustomCount
            count={simParam.adaptiveMeshRefinement!.boundaryLayerProfile.length - 1}
          />
        )}
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};

// A panel for displaying the mesh boundary layer parameters.
export const AdaptationBoundaryPropPanel = () => {
  const { readOnly } = useProjectContext();
  const { simParam, saveParam } = useSimulationConfig();
  const amr = getOrCreateAdaptiveMeshRefinement(simParam);
  if (amr.allTet === AllTet.ALL_TET_ON) {
    return (
      <div>
        <PropertiesSection>
          <SectionMessage
            level="info"
            message="Manual adaptation boundary layer settings are disabled
            because automatic boundary layer adaptation is enabled."
          />
        </PropertiesSection>
      </div>
    );
  }
  const boundaryLayersList = amr.boundaryLayerProfile;

  const addCustomMeshSize = () => {
    saveParam((param) => {
      const newBL = DEFAULT_ADAPTATION_BL.clone();
      getOrCreateAdaptiveMeshRefinement(param).boundaryLayerProfile.push(newBL);
      return param;
    });
  };

  const boundaryList: ReactElement[] = [];
  for (let i = 0; i < boundaryLayersList.length; i += 1) {
    boundaryList.push(
      <MeshAdaptationBoundaryParams
        boundaryIndex={i}
        isInput
        key={`vol-${i}`}
      />,
    );
    boundaryList.push(<Divider key={`div-${i}`} />);
  }
  return (
    <div>
      {boundaryList}
      <PropertiesSection>
        <ActionButton
          disabled={readOnly}
          kind="secondary"
          onClick={addCustomMeshSize}
          size="small"
          startIcon={COMMON_START_ICON}>
          Custom Boundary Layer
        </ActionButton>
      </PropertiesSection>
    </div>
  );
};
