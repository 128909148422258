// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { renameBoundaryCondition, renamePeriodicBound } from '../../lib/boundaryConditionUtils';
import { validateExpressions } from '../../lib/derivedOutput';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import { findHeatSourceById } from '../../lib/heatSourceUtils';
import { renameMaterial } from '../../lib/materialUtils';
import { findFrameById } from '../../lib/motionDataUtils';
import { uniqueSequenceName } from '../../lib/name';
import { findOutputNodeById } from '../../lib/outputNodeUtils';
import { findParticleGroupById, getProbePointsTable } from '../../lib/particleGroupUtils';
import { RecoilProjectKey } from '../../lib/persist';
import { renamePhysicalBehavior } from '../../lib/physicalBehaviorUtils';
import { renamePhysics } from '../../lib/physicsUtils';
import { renamePlot } from '../../lib/plot';
import { renamePorousModel } from '../../lib/porousModelUtils';
import { ProbePointsTableModel } from '../../lib/rectilinearTable/model';
import { disabledOutputCategories } from '../../lib/sensitivityUtils';
import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { renameSlidingInterface } from '../../lib/slidingInterfaceUtils';
import { renameTreeNode } from '../../lib/visUtils';
import { renameVolume } from '../../lib/volumeUtils';
import { useHeatBoundaryCondition } from '../../model/hooks/useHeatBoundaryCondition';
import { useMaterialEntity } from '../../model/hooks/useMaterialEntity';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useSetCameraGroupName, useSetCameraName } from '../../recoil/cameraState';
import { useSetEntityGroupMap } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcvisFilterStatusValue } from '../../recoil/lcvis/lcvisFilterStatus';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useSetPlotNodes } from '../../recoil/plotNodes';
import { useSetMeshMultiPart } from '../../recoil/useMeshingMultiPart';
import { useControlPanelMode } from '../../recoil/useProjectPage';
import { useSetFilterState } from '../../recoil/vis/filterState';
import { useCurrentConfig } from '../../recoil/workflowConfig';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';
import { useFilterNode } from '../visFilter/useFilterNode';

import { useRenameGeometryFeature, useTagsInteractiveGeometry } from './useInteractiveGeometry';
import { useSimulationConfig } from './useSimulationConfig';

export const useNodeRenaming = (node: SimulationTreeNode | null) => {
  // == Context
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setNodeName } = useParaviewContext();

  const geometryTags = useGeometryTags(projectId);

  // == Hooks
  const { saveParam, saveParamAsync } = useSimulationConfig();
  const { renameGeometryFeature, isFeatureRenameDisabled } = useRenameGeometryFeature();
  const { renameTag, isTagRenameDisabled } = useTagsInteractiveGeometry();

  // == Models
  const { param: filterParam } = useFilterNode(node);
  const {
    isDependent,
  } = useHeatBoundaryCondition(projectId, workflowId, jobId, readOnly, node?.id ?? '');
  const {
    hasFixedPreset,
  } = useMaterialEntity(projectId, workflowId, jobId, readOnly, node?.id ?? '');

  // == Recoil
  const cameraKey: RecoilProjectKey = { projectId, workflowId, jobId };
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, '', '');
  const setEntityGroupMap = useSetEntityGroupMap(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const setPlotState = useSetPlotNodes(projectId);
  const setFilterState = useSetFilterState({ projectId, workflowId, jobId });
  const setCameraName = useSetCameraName(cameraKey);
  const setCameraGroupName = useSetCameraGroupName(cameraKey);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const isGeometryView = useIsGeometryView();
  const [controlPanelMode] = useControlPanelMode();
  const lcvisFilterStatus = useLcvisFilterStatusValue();

  // == Data
  const isReaderNode = useMemo(
    () => (filterParam?.typ === ParaviewRpc.TreeNodeType.READER),
    [filterParam],
  );

  const disabled = useMemo(() => {
    switch (node?.type) {
      // Some nodes can always be renamed
      case NodeType.CAMERA:
      case NodeType.CAMERA_GROUP:
      case NodeType.PLOT: {
        return false;
      }
      case NodeType.MATERIAL_FLUID:
      case NodeType.MATERIAL_SOLID:
      case NodeType.MONITOR_PLANE:
      case NodeType.MOTION_FRAME:
      case NodeType.PARTICLE_GROUP:
      case NodeType.PHYSICAL_BEHAVIOR:
      case NodeType.PHYSICS_FLUID:
      case NodeType.PHYSICS_HEAT:
      case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION:
      case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION:
      case NodeType.PHYSICS_HEAT_HEAT_SOURCE:
      case NodeType.PHYSICS_SLIDING_INTERFACE:
      case NodeType.PHYSICS_PERIODIC_PAIR:
      case NodeType.POROUS_MODEL:
      case NodeType.PROBE_POINT:
      case NodeType.REFINEMENT_REGION: {
        return readOnly;
      }
      case NodeType.GEOMETRY_MODIFICATION: {
        return isFeatureRenameDisabled;
      }
      case NodeType.SURFACE:
        return readOnly || isGeometryView;
      case NodeType.SURFACE_GROUP:
        if (geometryTags.isTagId(node.id)) {
          return isTagRenameDisabled;
        }
        return readOnly || isGeometryView;
      case NodeType.VOLUME: {
        return readOnly || isGeometryView;
      }
      case NodeType.FILTER: {
        return !filterParam || (
          lcvisEnabled && lcvisFilterStatus.get(node.id)?.status !== 'completed'
        );
      }
      case NodeType.OUTPUT: {
        const outputNode = findOutputNodeById(outputNodes, node.id);
        const isSensitivity = (
          config.exploration?.policy.case === 'sensitivityAnalysis' &&
          controlPanelMode === 'exploration'
        );

        return (
          !outputNode ||
          (disabledOutputCategories.includes(outputNode.nodeProps.case) && isSensitivity)
        );
      }
      default: {
        return true;
      }
    }
  }, [
    config, controlPanelMode, filterParam, isFeatureRenameDisabled, isGeometryView, node,
    outputNodes, readOnly, lcvisFilterStatus, geometryTags, isTagRenameDisabled, lcvisEnabled,
  ]);

  const onCommit = useCallback(async (newName: string) => {
    switch (node?.type) {
      case NodeType.CAMERA: {
        return setCameraName(node.id, newName);
      }
      case NodeType.CAMERA_GROUP: {
        return setCameraGroupName(node.id, newName);
      }
      case NodeType.FILTER: {
        if (lcvisEnabled) {
          setFilterState((prev) => renameTreeNode(prev, node.id, newName));
        } else {
          // issue paraview rpc to change the name.
          setNodeName(node.id, newName);
        }
        break;
      }
      case NodeType.MATERIAL_FLUID:
      case NodeType.MATERIAL_SOLID: {
        return saveParam((newParam) => renameMaterial(newParam, node.id, newName));
      }
      case NodeType.MONITOR_PLANE: {
        return saveParam((newParam) => {
          newParam.monitorPlane.forEach((plane) => {
            if (node.id === plane.monitorPlaneId) {
              plane.monitorPlaneName = newName;
            }
          });
        });
      }
      case NodeType.MOTION_FRAME: {
        return saveParamAsync((newParam) => {
          const newFrame = findFrameById(newParam, node.id);
          if (newFrame) {
            newFrame.frameName = newName;
          }
        });
      }
      case NodeType.OUTPUT: {
        let cleanName = newName.replace(/"/g, '');
        // Only set the name if the name changed. This prevents us from accidentally
        // setting a custom name that overrides any changes to the quantity type.
        if (!cleanName || (node?.name === cleanName)) {
          return;
        }

        const existingNames = outputNodes.nodes.map((outputNode) => outputNode.name);
        cleanName = uniqueSequenceName(
          existingNames,
          // only append count when there are duplicates
          (count) => (count === 1 ? cleanName : `${cleanName} ${count - 1}`),
          { recycleNumbers: true },
        );

        const newOutputNodes = outputNodes.clone();
        const newOutput = findOutputNodeById(newOutputNodes, node.id);
        if (!newOutput) {
          throw Error('Current output not in output list.');
        }
        newOutput.name = cleanName;
        setOutputNodes(newOutputNodes);

        const expressions = new Map<string, string>();
        newOutputNodes.nodes.forEach((outputNode) => {
          if (outputNode.nodeProps.case === 'derived') {
            const elements = outputNode.nodeProps.value.elements;
            // Invalid Custom Outputs will have only one substring ExpressionElement with the
            // entire invalid expression in it
            if (
              elements.length === 1 &&
              elements[0].elementType.case === 'substring' &&
              (
                elements[0].elementType.value.includes(cleanName) ||
                elements[0].elementType.value.includes(node.name)
              )
            ) {
              expressions.set(outputNode.id, elements[0].elementType.value);
            }
          }
        });
        if (expressions.size > 0) {
          validateExpressions(newOutputNodes, setOutputNodes, projectId, expressions);
        }
        break;
      }
      case NodeType.PARTICLE_GROUP: {
        return saveParam(
          (newParam) => {
            const particleGroup = findParticleGroupById(newParam, node.id);
            if (particleGroup) {
              particleGroup.particleGroupName = newName;
            }
          },
        );
      }
      case NodeType.PHYSICAL_BEHAVIOR: {
        return saveParam((newParam) => renamePhysicalBehavior(newParam, node.id, newName));
      }
      case NodeType.PHYSICS_FLUID:
      case NodeType.PHYSICS_HEAT: {
        return saveParam((newParam) => renamePhysics(newParam, node.id, newName));
      }
      case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION:
      case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION: {
        return saveParam((newParam) => renameBoundaryCondition(newParam, node.id, newName));
      }
      case NodeType.PHYSICS_HEAT_HEAT_SOURCE: {
        return saveParam((newParam) => {
          const newSource = findHeatSourceById(newParam, node.id);
          if (newSource) {
            newSource.heatSourceName = newName;
          }
        });
      }
      case NodeType.PHYSICS_SLIDING_INTERFACE: {
        return saveParam((newParam) => renameSlidingInterface(newParam, node.id, newName));
      }
      case NodeType.PHYSICS_PERIODIC_PAIR: {
        return saveParam((newParam) => renamePeriodicBound(newParam, node.id, newName));
      }
      case NodeType.PLOT: {
        return setPlotState((prevValue) => {
          const newPlotState = prevValue.clone();
          renamePlot(newPlotState, node.id, newName);
          return newPlotState;
        });
      }
      case NodeType.POROUS_MODEL: {
        return saveParam((newParam) => renamePorousModel(newParam, node.id, newName));
      }
      case NodeType.PROBE_POINT: {
        return saveParam((newParam) => {
          const table = getProbePointsTable(newParam);
          if (table) {
            const tableModel = new ProbePointsTableModel(table);
            const record = tableModel.getRecord('id', node.id);
            if (record) {
              record.setNamedEntry('name', newName);
            }
          }
        });
      }
      case NodeType.REFINEMENT_REGION: {
        return setMeshMultiPart((oldMultiPart) => {
          const newMultiPart = oldMultiPart?.clone();
          const rr = newMultiPart?.refinementParams.find((rrParam) => rrParam.id === node.id);
          if (rr) {
            rr.name = newName;
          }
          return newMultiPart || oldMultiPart;
        });
      }
      case NodeType.SURFACE:
      case NodeType.SURFACE_GROUP: {
        if (geometryTags.isTagId(node.id)) {
          await renameTag(node.id, newName);
          return;
        }
        return setEntityGroupMap((oldMap) => {
          const newMap = new EntityGroupMap(oldMap);
          if (newName.length) {
            newMap.get(node.id).name = newName;
          }
          return newMap;
        });
      }
      case NodeType.VOLUME: {
        return setEntityGroupMap((oldMap) => {
          const newMap = new EntityGroupMap(oldMap);
          renameVolume(newMap, node.id, newName);
          if (newName.length) {
            newMap.get(node.id).name = newName;
          }
          return newMap;
        });
      }
      case NodeType.GEOMETRY_MODIFICATION: {
        return renameGeometryFeature(node.id, newName);
      }

      default: {
        return null;
      }
    }
  }, [
    geometryTags,
    lcvisEnabled,
    node,
    outputNodes,
    projectId,
    renameGeometryFeature,
    renameTag,
    saveParam,
    setCameraGroupName,
    setCameraName,
    setEntityGroupMap,
    setFilterState,
    setMeshMultiPart,
    setNodeName,
    setPlotState,
    setOutputNodes,
    saveParamAsync,
  ]);

  return useMemo(() => {
    // dependent boundary condition
    if (isDependent) {
      return undefined;
    }

    // READER is the root Visualizations node and is not editable
    if (isReaderNode) {
      return undefined;
    }

    // When a material has a fixed preset (like Standard Air), the preset's name is used instead of
    // the user-configured name, and it can't be renamed.
    if (hasFixedPreset) {
      return undefined;
    }
    return {
      disabled,
      onCommit,
    };
  }, [disabled, onCommit, isDependent, hasFixedPreset, isReaderNode]);
};
