// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo, useState } from 'react';

import assert from '../../../../lib/assert';
import { MultiCheckBoxItemProps } from '../../../../lib/componentTypes/form';
import { cadIdsToVolumeNodeIds, volumeNodeIdsToCadIds } from '../../../../lib/geometryUtils';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { NodeType } from '../../../../lib/simulationTree/node';
import { defaultNodeFilter, mapVisualizerEntitiesToVolumes } from '../../../../lib/subselectUtils';
import { addRpcError } from '../../../../lib/transientNotification';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import {
  DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE,
  createOrUpdateFeature,
  useGeometrySelectedFeature,
  useGeometryState,
  useSetGeometryState,
} from '../../../../recoil/geometry/geometryState';
import { useCadMetadata } from '../../../../recoil/useCadMetadata';
import { useStaticVolumes } from '../../../../recoil/volumes';
import { IconButton } from '../../../Button/IconButton';
import Form from '../../../Form';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { DoubleArrowUpDownIcon } from '../../../svg/DoubleArrowUpDownIcon';
import { Flex } from '../../../visual/Flex';
import { NodeSubselect } from '../../NodeSubselect';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

const SwapBodiesAndToolsButton = (props: {
  onSwap: () => void;
}) => (
  <Flex gap={8}>
    <IconButton onClick={props.onSwap}>
      <DoubleArrowUpDownIcon maxHeight={14} maxWidth={14} />
    </IconButton>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      fontSize: '13px',
      fontWeight: '400',
    }}>
      Swap volumes
    </div>
  </Flex>
);

// Handles boolean geometry modifications.
// NOTE: since the booleans use integer ids and the frontend node trees use `volume-id` as string
// id, we need to perform some ugly transformations between ints and strings.
export const GeometryModificationBooleanPropPanel = () => {
  // == Contexts
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();
  assert(!!node, 'No selected geometry modification boolean row');

  // == Recoil
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const [cadMetadata] = useCadMetadata(projectId, '', '');
  const staticVolumes = useStaticVolumes(projectId, '', '');
  const geometryState = useGeometryState(projectId, geometryId);

  // == Hooks
  const propClasses = useCommonTreePropsStyles();

  // == Derived data
  const currentModification = geometryState?.geometryFeatures.find(
    (feature) => feature.id === node.id,
  );
  assert(!!currentModification, 'No selected geometry modification boolean');
  const modificationBoolean = currentModification.operation.value as geometrypb.Boolean;
  assert(!!modificationBoolean, 'Missing boolean operation');
  const booleanType = modificationBoolean.op.value;
  assert(!!booleanType, 'Missing boolean type');
  const isAcknowledgedFeature = geometryState?.ackModifications.has(node.id);

  const initialBodies = cadIdsToVolumeNodeIds(
    booleanType?.bodies ?? [],
    staticVolumes,
    cadMetadata,
  );

  // Tools only exist for Subtract and Chop
  const hasTools = 'tools' in booleanType;
  const initialTools = (hasTools) ?
    cadIdsToVolumeNodeIds(booleanType.tools, staticVolumes, cadMetadata) :
    [];

  // == State
  const [selectedBodiesNodeIds, setSelectedBodiesNodeIds] = useState<string[]>(initialBodies);
  const [keepSourceBodies, setKeepSourceBodies] = useState<boolean>(booleanType.keepSourceBodies);
  const [
    keepTools,
    setKeepTools,
  ] = useState<boolean>(hasTools ? booleanType.keepToolBodies : false);
  const [
    propagateToolTags,
    setPropagateToolTags,
  ] = useState<boolean>(hasTools ? booleanType.propagateToolTags : false);
  const [selectedToolsNodeIds, setSelectedToolsNodeIds] = useState<string[]>(initialTools);
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);

  const restBodyIds = useMemo(() => {
    const bool = currentModification?.operation.value as geometrypb.Boolean;
    if (!bool) {
      return [];
    }
    const booleanOperation = bool.op.value!;
    const setBodyIds = new Set(
      volumeNodeIdsToCadIds(selectedBodiesNodeIds, staticVolumes, cadMetadata),
    );
    const toolIds = ('tools' in booleanOperation) ? selectedToolsNodeIds : [];
    const setToolIds = new Set(volumeNodeIdsToCadIds(toolIds, staticVolumes, cadMetadata));
    return cadMetadata.volumeIds.filter(
      (volumeId) => !setBodyIds.has(BigInt(volumeId)) && !setToolIds.has(BigInt(volumeId)),
    );
  }, [cadMetadata, currentModification?.operation.value, selectedBodiesNodeIds,
    selectedToolsNodeIds, staticVolumes]);

  // Node subselect constructs
  const nodeFilter = useCallback((nodeType, nodeId) => {
    if (nodeType === NodeType.VOLUME) {
      return {
        related: true,
        disabled: selectedBodiesNodeIds.includes(nodeId) || selectedToolsNodeIds.includes(nodeId),
      };
    }
    return defaultNodeFilter(nodeType);
  }, [selectedBodiesNodeIds, selectedToolsNodeIds]);

  // Generates a checkbox to select whether tool/source bodies are to be kept.
  const generateOptions = (isTool: boolean): MultiCheckBoxItemProps => {
    const type = isTool ? 'tool' : 'target';
    return {
      key: 'keep-volumes',
      checked: isTool ? keepTools : keepSourceBodies,
      disabled: false,
      help: `Keep the ${type} volumes after performing the boolean operation.`,
      optionText: isTool ? 'Keep Original Tool Volumes' : 'Keep Original Target Volumes',
      onChange: (checked) => {
        if (isTool) {
          setKeepTools(checked);
        } else {
          setKeepSourceBodies(checked);
        }

        setGeometryState((oldGeometryState) => {
          if (oldGeometryState === undefined) {
            return oldGeometryState;
          }
          const newGeometryState = { ...oldGeometryState };
          newGeometryState.geometryFeatures.forEach((feature) => {
            if (feature.id !== currentModification.id) {
              return;
            }
            const bool = feature.operation.value as geometrypb.Boolean;
            const boolOperation = bool.op.value!;
            if (isTool && 'keepToolBodies' in boolOperation) {
              boolOperation.keepToolBodies = checked;
            } else {
              boolOperation.keepSourceBodies = checked;
            }
          });
          return newGeometryState;
        });
      },
    };
  };

  const handleNodeSubselectChange = (nodeIds: string[], isTool: boolean) => {
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures.forEach((feature) => {
        if (feature.id !== currentModification.id) {
          return;
        }
        const bool = feature.operation.value as geometrypb.Boolean;
        const booleanOperation = bool.op.value!;
        const bodies = volumeNodeIdsToCadIds(nodeIds, staticVolumes, cadMetadata);
        if (isTool && 'tools' in booleanOperation) {
          booleanOperation.tools = (bodies as bigint[]);
        } else {
          booleanOperation.bodies = (bodies as bigint[]);
        }
      });
      return newGeometryState;
    });
    if (isTool) {
      setSelectedToolsNodeIds(nodeIds);
    } else {
      setSelectedBodiesNodeIds(nodeIds);
    }
  };

  const handleSelectAll = (isTool: boolean) => {
    const restBigInt = restBodyIds.map((volumeId) => BigInt(volumeId));
    const restNodeIds = cadIdsToVolumeNodeIds(restBigInt, staticVolumes, cadMetadata);
    handleNodeSubselectChange(restNodeIds, isTool);
  };

  // Swaps the tools and bodies in the boolean operation.
  const swap = () => {
    if (!hasTools) {
      return;
    }
    setSelectedBodiesNodeIds(selectedToolsNodeIds);
    setSelectedToolsNodeIds(selectedBodiesNodeIds);
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined || !hasTools) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures.forEach((feature) => {
        if (feature.id !== currentModification.id) {
          return;
        }
        const bool = feature.operation.value as geometrypb.Boolean;
        const booleanOperation = bool.op.value!;
        if (!('tools' in booleanOperation)) {
          return;
        }
        const bodies = booleanOperation.bodies;
        const tools = booleanOperation.tools;
        booleanOperation.bodies = (tools as bigint[]);
        booleanOperation.tools = (bodies as bigint[]);
      });
      return newGeometryState;
    });
  };

  const mapVisualizerEntities = useCallback(
    (ids: string[]) => mapVisualizerEntitiesToVolumes(ids, staticVolumes),
    [staticVolumes],
  );

  // Builds the tool/body boolean selection.
  const volumeSelectionPanel = (isTool: boolean) => (
    <NodeSubselect
      autoStart={!isTool && !isAcknowledgedFeature}
      iconNotFoundNodes={{ name: 'cubeOutline' }}
      id={isTool ? 'boolean-tool' : 'boolean-body'}
      labels={['volumes']}
      mapVisualizerEntities={mapVisualizerEntities}
      nodeFilter={nodeFilter}
      nodeIds={isTool ? selectedToolsNodeIds : selectedBodiesNodeIds}
      onChange={(nodeIds: string[]) => handleNodeSubselectChange(nodeIds, isTool)}
      readOnly={readOnly}
      referenceNodeIds={[node.id]}
      selectAll={{
        disabled: restBodyIds.length === 0,
        label: 'Select All Available',
        onClick: () => handleSelectAll(isTool),
        title: '',
      }}
      showNotFoundNodes
      title={isTool ? 'Tool Volumes' : 'Target Volumes'}
      visibleTreeNodeTypes={[NodeType.VOLUME]}
    />
  );

  const propagateToolTagsCheckbox: MultiCheckBoxItemProps = {
    checked: propagateToolTags,
    disabled: false,
    help: 'Propagate the tool volume tags to their faces before performing the boolean operation.',
    optionText: 'Propagate Tool Tags',
    key: 'propagate-tool-tags',
    onChange: (checked) => {
      setPropagateToolTags(checked);

      setGeometryState((oldGeometryState) => {
        if (oldGeometryState === undefined) {
          return oldGeometryState;
        }
        const newGeometryState = { ...oldGeometryState };
        newGeometryState.geometryFeatures.forEach((feature) => {
          if (feature.id !== currentModification.id) {
            return;
          }
          const bool = feature.operation.value as geometrypb.Boolean;
          const boolOperation = bool.op.value!;
          if ('propagateToolTags' in boolOperation) {
            boolOperation.propagateToolTags = checked;
          }
        });
        return newGeometryState;
      });
    },
  };

  const onBooleanSave = async () => {
    const feature = new geometrypb.Feature({
      operation: currentModification.operation,
      id: node.id,
      featureName: currentModification.featureName,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: createOrUpdateFeature(geometryState, node.id),
        feature,
      }),
    });
    rpc.clientGeometry.modifyGeometry(req).catch((err) => (
      addRpcError(`Server error ${err}`, err)
    ));

    // Let the selection manager know that we intentionally requested a modification so that it
    // does not fire a selection change event.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);

    // Remove the focus on the panel, the geometry being displayed may not be linked to the
    // modification.
    setSelection([]);
  };

  return (
    <div className={propClasses.properties}>
      <EditModificationMessage nodeId={node.id} />
      {volumeSelectionPanel(false)}
      <div style={{ margin: '14px 0 18px' }}>
        <Form.MultiCheckBox checkBoxProps={[generateOptions(false)]} />
      </div>
      {hasTools && (
        <div style={{ marginBottom: '8px' }}>
          <SwapBodiesAndToolsButton onSwap={swap} />
        </div>
      )}
      {hasTools && volumeSelectionPanel(true)}
      {hasTools && (
        <div style={{ margin: '14px 0 18px' }}>
          <Form.MultiCheckBox checkBoxProps={[generateOptions(true), propagateToolTagsCheckbox]} />
        </div>
      )}
      <GeometryModificationPanelFooter
        featureId={node.id}
        onModificationSave={onBooleanSave}
      />
    </div>
  );
};
