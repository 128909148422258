// Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved.
import { useRecoilTransactionObserver_UNSTABLE } from 'recoil';

import { Logger } from '../lib/observability/logs';

const logger = new Logger('recoil/debug');

// useDebugObserver should be invoked once for the entire app, as it
// registers a listener on all recoil state updates.  These state
// updates will be logged at a debug level to help debug issues.
export function useDebugObserver() {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    // TODO(bamo): It may be possible to get the listeners for the states that changed, but
    // I could not figure out how to get it working (see getInfo_UNSTABLE and the like).
    const nodes = snapshot.getNodes_UNSTABLE({ isModified: true });
    const changedKeys = Array.from(nodes).map(({ key }) => key);
    logger.debug(`recoil new snapshot id=${snapshot.getID()} changedKeys=`, changedKeys);
  });
}
