// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo, useState } from 'react';

import * as flags from '../../../flags';
import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { colors } from '../../../lib/designSystem';
import { isGroupVisible } from '../../../lib/entityGroupUtils';
import { isFarfield } from '../../../lib/farfieldUtils';
import { isGeomHealthId } from '../../../lib/geometryHealthUtils';
import { getAllAttachedSurfaceIds } from '../../../lib/motionDataUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import {
  deleteTreeNodeMenuItem,
  groupTreeNodeMenuItem,
  ungroupTreeNodeMenuItem,
} from '../../../lib/treeUtils';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import CreateTagDialog from '../../dialog/CreateTag';
import { useTagsInteractiveGeometry } from '../../hooks/useInteractiveGeometry';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeGrouping } from '../../hooks/useNodeGrouping';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { ContextMenuSection, TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'cubeOutline' };

// A row displaying information about a surface group.
export const SurfaceTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;
  const { id: nodeId } = node;

  // == Context
  const { viewState, visibilityMap } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const geometryTags = useGeometryTags(projectId);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteSurfaceGroupNode, postDeleteNodeIds } = useNodeDeletion();
  const {
    canGroup,
    canUngroup,
    groupEntities,
    ungroupEntities,
    groupableNodes,
  } = useNodeGrouping();
  const { createTag, isCreateTagDisabled } = useTagsInteractiveGeometry();
  const geoTagsEnabled = useIsEnabled(flags.geoTags);

  const [isCreateTagDialogOpen, setIsCreateTagDialogOpen] = useState(false);
  const [tagCreationIds, setTagCreationIds] = useState<string[]>([]);

  const isTagChild = node.type === NodeType.TAGS_FACE;
  const surfaceId = isTagChild ? geometryTags.surfaceFromTagEntityGroupId(node.id) : nodeId;

  let isVisible = false;
  if (lcvisEnabled) {
    // NOTE: if a new mesh was loaded (e.g., a far field is added), visibilityV2 will not contain
    // the new (far field) surfaces. However, the registerFarFieldSurfaces function below will let
    // lcvis know which new surfaces are far fields.
    isVisible = isGroupVisible(visibilityV2, entityGroupData.groupMap, surfaceId);
  } else {
    isVisible = isGroupVisible(visibilityMap, entityGroupData.groupMap, surfaceId);
  }

  const surfacesWithMotion = getAllAttachedSurfaceIds(
    simParam,
    { motion: 'moving' },
    geometryTags,
    entityGroupData,
  );

  const toggleIds = useMemo(() => {
    const isSelected = selectedNodeIds.includes(surfaceId);
    // Toggle all the selected IDs, if this ID is selected.
    const ids = isSelected ? selectedNodeIds : [surfaceId];

    // Vis knows nothing about geometry health IDs, but those IDs are included as part of the
    // selection since both the health check row and the sim tree row are selected.
    return ids.reduce((result, id) => {
      if (!isGeomHealthId(id)) {
        result.add(id);
      }
      return result;
    }, new Set<string>());
  }, [selectedNodeIds, surfaceId]);
  const toggleVis = useToggleVisibility(toggleIds, isVisible);

  const visibilityControl = {
    show: isVisible,
    disabled: lcvisEnabled ? !lcvisReady : !viewState,
    isHovered: (rowHovered: boolean) => rowHovered,
    toggle: toggleVis,
  };

  const auxIcons = useMemo<IconSpec[]>(() => {
    if (surfacesWithMotion.has(surfaceId)) {
      return [{ name: 'rotatingDots', color: colors.citronGreen600 }];
    }
    return [];
  }, [surfaceId, surfacesWithMotion]);

  const deleteRow = useCallback(() => {
    if (deleteSurfaceGroupNode(surfaceId)) {
      postDeleteNodeIds([surfaceId]);
    }
  }, [deleteSurfaceGroupNode, surfaceId, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];
    const nodeIdsToGroup = groupableNodes(surfaceId);
    if (isGeometryView) {
      if (!isCreateTagDisabled && geoTagsEnabled) {
        sections.push({
          section: 'grouping',
          menuItems: [
            {
              label: 'Create Tag',
              onClick: async () => {
                setIsCreateTagDialogOpen(true);
                setTagCreationIds(nodeIdsToGroup);
              },
            },
          ],
        });
      }
      return sections;
    }

    if (isFarfield(surfaceId)) {
      const disabled = !canDelete(node.type, surfaceId);
      const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
      sections.push({ section: 'crud', menuItems: [deleteItem] });
    }

    if (canGroup(nodeIdsToGroup, entityGroupData)) {
      const groupItem = groupTreeNodeMenuItem(() => groupEntities(nodeIdsToGroup));
      sections.push({ section: 'grouping', menuItems: [groupItem] });
    }

    if (canUngroup(node, entityGroupData)) {
      const ungroupItem = ungroupTreeNodeMenuItem(() => ungroupEntities(surfaceId));
      sections.push({ section: 'grouping', menuItems: [ungroupItem] });
    }

    return sections;
  }, [
    canDelete,
    canGroup,
    canUngroup,
    deleteRow,
    entityGroupData,
    groupEntities,
    groupableNodes,
    isGeometryView,
    node,
    ungroupEntities,
    isCreateTagDisabled,
    geoTagsEnabled,
    surfaceId,
  ]);

  return (
    <>
      <>
        {isCreateTagDialogOpen && (
          <CreateTagDialog
            isOpen={isCreateTagDialogOpen}
            onCancel={() => {
              setIsCreateTagDialogOpen(false);
              setTagCreationIds([]);
            }}
            onSubmit={async (name) => {
              await createTag(name, tagCreationIds);
              setIsCreateTagDialogOpen(false);
              setTagCreationIds([]);
            }}
          />
        )}
      </>
      <TreeRow
        {...props}
        auxIcons={auxIcons}
        canMultiSelect
        getExtraContextMenuItems={getExtraContextMenuItems}
        label={node.name}
        primaryIcon={PRIMARY_ICON}
        propertiesControl={!isGeometryView}
        renaming={!isGeometryView ? renaming : undefined}
        visibility={visibilityControl}
      />
    </>
  );
};
