// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import * as flags from '../../../../flags';
import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../../lib/componentTypes/svgIcon';
import { isGroupVisible } from '../../../../lib/entityGroupUtils';
import { deleteTreeNodeMenuItem } from '../../../../lib/treeUtils';
import { useEntityGroupMap } from '../../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../../recoil/lcvis/lcvisReadyState';
import { useLcvisVisibilityMapValue } from '../../../../recoil/lcvis/lcvisVisibilityMap';
import { useSelectedGeometry } from '../../../../recoil/selectedGeometry';
import { useIsEnabled } from '../../../../recoil/useExperimentConfig';
import useMeshMultiPart from '../../../../recoil/useMeshingMultiPart';
import { useSetRefinementRegionVisibility } from '../../../../recoil/useRefinementRegions';
import { useToggleVisibility } from '../../../../recoil/vis/useToggleVisibility';
import { useIsGeometryView } from '../../../../state/internal/global/currentView';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import { useProjectContext } from '../../../context/ProjectContext';
import { useNodeDeletion } from '../../../hooks/useNodeDeletion';
import { ContextMenuSection, TreeRow, VisibilityControl } from '../../TreeRow';

export interface GeometryOrMeshRowProps extends SimulationRowProps {
  isGeometry: boolean;
}

// A top level row for all the geometry or mesh surfaces.
export const GeometryOrMeshRow = (props: GeometryOrMeshRowProps) => {
  // == Props
  const { isGeometry, node } = props;

  // == Contexts
  const { visibilityMap, viewState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const setRefinementRegionVis = useSetRefinementRegionVisibility(projectId);
  const multiPartMeshing = useMeshMultiPart(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const [selectedGeometry] = useSelectedGeometry(projectId);
  const geoModEnabled = useIsEnabled(flags.geoModifications);

  // == Hooks
  const { canDelete, deleteGeometryNode, deleteMeshNode, postDeleteNodeIds } = useNodeDeletion();

  // == State
  const primaryIcon = useMemo<IconSpec>(
    () => ({ name: isGeometry ? 'cubeSolid' : 'cubeOutline' }),
    [isGeometry],
  );

  // == Data
  const surfaceGroups = entityGroupMap.getGroups();

  const refinementRegionIds = useMemo(
    () => (
      multiPartMeshing?.refinementParams ?? []
    ).map((region) => region.id),
    [multiPartMeshing],
  );

  // Check if any of the groups are visible.
  const isVisible = surfaceGroups.some(
    (group) => isGroupVisible(
      lcvisEnabled ? visibilityV2 : visibilityMap,
      entityGroupMap,
      group.id,
    ),
  );

  const toggleIds = useMemo(
    () => surfaceGroups.reduce((result, group) => result.add(group.id), new Set<string>()),
    [surfaceGroups],
  );
  const toggleVis = useToggleVisibility(toggleIds, isVisible);

  const visibilityControl: VisibilityControl = {
    disabled: lcvisEnabled ? !lcvisReady : !viewState,
    show: isVisible,
    toggle: () => {
      toggleVis();
      setRefinementRegionVis((oldVis) => {
        const newVis = { ...oldVis };
        refinementRegionIds.forEach((id) => {
          newVis[id] = !isVisible;
        });
        return newVis;
      });
    },
  };

  const deleteRow = useCallback(() => {
    const deleted = isGeometry ? deleteGeometryNode() : deleteMeshNode();
    if (deleted) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteGeometryNode, deleteMeshNode, isGeometry, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    // If Interactive Geometry is enabled and the source of the file is Interactive Geometry we
    // disable deleting the root geometry node.
    if (geoModEnabled && selectedGeometry.geometryId) {
      return sections;
    }

    const disabled = !canDelete(node.type, node.id);
    if (!disabled) {
      const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
      sections.push({ section: 'crud', menuItems: [deleteItem] });
    }

    return sections;
  }, [canDelete, deleteRow, node.id, node.type, selectedGeometry.geometryId, geoModEnabled]);

  return (
    <TreeRow
      {...props}
      earlyAccess={!isGeometry}
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={primaryIcon}
      propertiesControl={isGeometry && !isGeometryView}
      visibility={visibilityControl}
    />
  );
};
