// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useState } from 'react';

import * as simulationpb from '../proto/client/simulation_pb';
import { ComputeOutputReply } from '../proto/frontend/frontend_pb';
import * as outputpb from '../proto/output/output_pb';
import { QuantityType } from '../proto/quantity/quantity_pb';
import { useGeometryTags } from '../recoil/geometry/geometryTagsState';
import { JobState } from '../recoil/jobState';
import { useOutputNodes } from '../recoil/outputNodes';
import { useEnabledExperiments } from '../recoil/useExperimentConfig';
import { useStaticVolumes } from '../recoil/volumes';

import { getAdValue } from './adUtils';
import { frameExists } from './motionDataUtils';
import { computeOutputsReply } from './output/computeOutputs';
import { newBasicOutput } from './outputUtils';
import { getReferenceValues } from './referenceValueUtils';
import { isSimulationTransient } from './simulationUtils';

const IterationIndexOutput = newBasicOutput(QuantityType.ITERATION_INDEX, true, false);
const PhysicalTimeOutput = newBasicOutput(QuantityType.PHYSICAL_TIME, true, false);

// Returns the time series data and iterations based on the input outputList.
const useTimeSeriesOutput = (
  projectId: string,
  workflowId: string,
  jobId: string,
  outputList: outputpb.Output[],
  param: simulationpb.SimulationParam,
  jobState: JobState | null,
  // If disabled is true, the output cannot be computed so we skip the analyzer request
  // and return empty data
  disabled: boolean,
  // Iteration range for inner iteration plots
  innerItersWindow?: outputpb.IterationRange,
): { data: number[][], iters: number[], time: number[] } => {
  const experiments = useEnabledExperiments();
  // Get the per-job output nodes for the reference values
  const [perJobOutputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);

  const [outputReply, setOutputReply] = useState<ComputeOutputReply>();

  const firstOutput = outputList?.[0];
  // Inner loop residual plot only has one output in the outputList
  const inner = firstOutput?.innerIters;
  // Reference frame is the same for all outputs in the outputList
  const frameId = firstOutput?.frameId;
  const frameFound = (
    !frameId ||
    firstOutput.outputProperties.case !== 'forceProperties' ||
    frameExists(param, frameId)
  );

  const minIter = 1;
  const maxIter = jobState?.lastIter ?? 0;

  // Set the output reply to undefined when the outputs change in order to indicate that data
  // is being fetched.
  useEffect(() => setOutputReply(undefined), [outputList]);

  useEffect(() => {
    if (!frameFound || maxIter < minIter ||
      outputList.length === 0 || disabled) {
      return;
    }
    const outputListCopy = [...outputList];

    // Add iteration index to output request
    outputListCopy.push(new outputpb.Output({ ...IterationIndexOutput, innerIters: inner }));

    // If transient add the physical time to the output request
    if (isSimulationTransient(param)) {
      outputListCopy.push(new outputpb.Output({ ...PhysicalTimeOutput, innerIters: inner }));
    }

    outputListCopy.forEach((out: outputpb.Output) => {
      if (inner) {
        if (!innerItersWindow) {
          throw Error('Inner iters window not defined');
        }
        const begin = Math.min(Math.max(minIter, innerItersWindow.begin), maxIter);
        const newRange = new outputpb.IterationRange({
          begin,
          end: Math.max(Math.min(maxIter, innerItersWindow.end), begin),
        });
        out.range = newRange;
      } else {
        out.range = new outputpb.IterationRange({ begin: minIter, end: maxIter });
      }
    });

    const refValues =
      getReferenceValues(perJobOutputNodes, param, experiments, geometryTags, staticVolumes);
    computeOutputsReply(
      projectId,
      jobId,
      outputListCopy,
      refValues,
    ).then((reply) => {
      if ('status' in reply) {
        return;
      }
      setOutputReply(reply);
    }).catch((error) => {
      throw Error(`Error while fetch outputs: ${error}`);
    });
  }, [
    experiments,
    inner,
    setOutputReply,
    maxIter,
    disabled,
    frameFound,
    projectId,
    workflowId,
    jobId,
    outputList,
    innerItersWindow,
    param,
    perJobOutputNodes,
    geometryTags,
    staticVolumes,
  ]);

  if (
    maxIter < minIter ||
    outputReply?.output.length === 1
  ) {
    return { data: [], iters: [], time: [] };
  }

  if (outputReply) {
    const data: number[][] = [];
    let time: number[] = [];
    outputReply.output.forEach((output, index) => {
      // Multiply fractional outputs by 100 to present to user as percentage
      const percentVal = [
        outputpb.TimeAnalysisType.PERCENT_MAX_DEV,
        outputpb.TimeAnalysisType.PERCENT_MAX_DEV_AVG,
        outputpb.TimeAnalysisType.PERCENT_MAX_DEV_SERIES,
        outputpb.TimeAnalysisType.PERCENT_MAX_DEV_AVG_SERIES,
      ].includes(output.timeAnalysis);
      const scale = percentVal ? 100 : 1;
      data.push(outputReply.result[index].values.map(
        (value) => {
          const newVal = scale * getAdValue(value);
          return percentVal && newVal === 0 ? NaN : newVal;
        },
      ));
    });

    // Put the the physical time into its own variable
    if (param && isSimulationTransient(param)) {
      time = data.pop()!;
    }

    const iters = data.pop()!;
    return { data, iters, time };
  }

  return { data: [], iters: [], time: [] };
};

export default useTimeSeriesOutput;
