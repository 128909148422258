// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { noWait, selectorFamily, useRecoilValue } from 'recoil';

import * as rpc from '../../lib/rpc';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';

// List of geometries in a given project.
const geometryListStateRpc = selectorFamily<
  geometryservicepb.ListGeometriesResponse | null,
  string
>({
  key: 'geometryList',
  get: (projectId: string) => async () => {
    if (!projectId) {
      return null;
    }
    try {
      const response = await rpc.callRetryWithClient(
        rpc.clientGeometry,
        'listGeometries',
        rpc.clientGeometry.listGeometries,
        new geometryservicepb.ListGeometriesRequest({ projectId }),
      );
      return response;
    } catch (error) {
      console.error('Failed to list geometries', error);
      return null;
    }
  },
  // Protobuf objects mutates themselves even in get*.
  dangerouslyAllowMutability: true,
});

export const geometryListState = selectorFamily<
  geometryservicepb.ListGeometriesResponse | null,
  string
>({
  key: 'geometryListState',
  get: (projectId: string) => ({ get }) => {
    const response = get(noWait(geometryListStateRpc(projectId)));
    return response.state === 'hasValue' ? response.contents : null;
  },
});

export function useGeometryList(projectId: string) {
  return useRecoilValue(geometryListState(projectId));
}
