import { useMemo } from 'react';

import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useProjectContext } from '../context/ProjectContext';

export const useTagSurfaceIds = (nodeId: string) => {
  // == Context
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);

  const tagSurfaceIds = useMemo(() => {
    if (!geometryTags.isTagId(nodeId)) {
      return [];
    }

    const {
      volumes: tagVolumes,
      surfaces: tagSurfaces,
    } = geometryTags.getAssignedNodeIds(new Set([nodeId]), true);

    tagVolumes.forEach((volumeId) => {
      const volume = staticVolumes.find((item) => item.id === volumeId);

      volume?.bounds?.forEach((bound) => {
        tagSurfaces.add(bound);
      });
    });

    // returns surfaces directly from tags and surfaces from volumes associated with each tag
    return [...tagSurfaces];
  }, [geometryTags, nodeId, staticVolumes]);

  return tagSurfaceIds;
};
