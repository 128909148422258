// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo } from 'react';

import { MultipleChoiceParam } from '../../../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../../../SimulationParamDescriptor';
import assert from '../../../../lib/assert';
import { SelectOption } from '../../../../lib/componentTypes/form';
import { colors } from '../../../../lib/designSystem';
import {
  BODY_FRAME_DESCRIPTION,
  BODY_FRAME_PARENT_MUST_BE_GLOBAL,
  MOTION_FRAME_VOLUME_SUBSELECT_ID,
  applyTransforms,
  assignFrameMotionFormulation,
  assignFrameMotionType,
  assignFrameOrientation,
  assignFrameOrigin,
  assignFrameRotationAngles,
  assignFrameRotationalVelocity,
  assignFrameTranslation,
  assignFrameTranslationalVelocity,
  extractCoordinatesFromTransforms,
  findFrameById,
  frameHasMotion,
  getFrameDefaultRotationalAngles,
  getFrameDefaultRotationalVelocity,
  getFrameDefaultTranslation,
  getFrameDefaultTranslationVelocity,
  getFrameParentCandidates,
  getFramePathFromGlobal,
  initializeFrameCoordinates,
  isFrameGlobal,
  reassignParentFrame,
  summarizeFrameCoordinateSystem,
  summarizeMotion,
} from '../../../../lib/motionDataUtils';
import { assignedFrameIds, assignedVolumes } from '../../../../lib/porousModelUtils';
import { NodeType, TAGS_NODE_TYPES } from '../../../../lib/simulationTree/node';
import { isSimulationTransient } from '../../../../lib/simulationUtils';
import { useCoordinateVisualizer } from '../../../../lib/useCoordinateVisualizer';
import { useMeshGeometry } from '../../../../lib/useMeshGeometry';
import { useNodePanel } from '../../../../lib/useNodePanel';
import { mapDomainsToIds } from '../../../../lib/volumeUtils';
import * as basepb from '../../../../proto/base/base_pb';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import * as quantitypb from '../../../../proto/quantity/quantity_pb';
import { useGeometryTags } from '../../../../recoil/geometry/geometryTagsState';
import { useLcVisEnabledValue } from '../../../../recoil/lcvis/lcvisEnabledState';
import { StaticVolume, useStaticVolumes } from '../../../../recoil/volumes';
import { useSimulationParam } from '../../../../state/external/project/simulation/param';
import { IconButton } from '../../../Button/IconButton';
import Form from '../../../Form';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { Vector3Input } from '../../../Form/Vector3Input';
import { SvgIcon } from '../../../Icon/SvgIcon';
import { CollapsiblePanel } from '../../../Panel/CollapsiblePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import Divider from '../../../Theme/Divider';
import {
  useCommonMotionFramePropStyles,
  useCommonMultiInputLines,
  useCommonTreePropsStyles,
} from '../../../Theme/commonStyles';
import Tooltip from '../../../Tooltip';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useMotionFrameSelection } from '../../../hooks/subselect/useMotionFrameSelection';
import { useLcvCoordinateVisualizer } from '../../../hooks/useLcvCoordinateVisualizer';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { RingCircleIcon } from '../../../svg/RingCircleIcon';
import Collapsible from '../../../transition/Collapsible';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';
import { FrameCoordinates } from '../shared/FrameCoordinates';

/**
 * Build the dropdown options for the Motion Type of the Frame.
 */
export const getMotionTypes = (motionType: simulationpb.MotionType) => [
  {
    name: 'None',
    value: simulationpb.MotionType.NO_MOTION,
    selected: motionType === simulationpb.MotionType.NO_MOTION,
  },
  {
    name: 'Rotation',
    value: simulationpb.MotionType.CONSTANT_ANGULAR_MOTION,
    selected: motionType === simulationpb.MotionType.CONSTANT_ANGULAR_MOTION,
  },
  {
    name: 'Translation',
    value: simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION,
    selected: motionType === simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION,
  },
];

// A panel displaying all the settings for the selected probe point node.
export const MotionFramePropPanel = React.memo(() => {
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  // Hooks & contexts
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected frame row');
  const frameClasses = useCommonMotionFramePropStyles();
  const propClasses = useCommonTreePropsStyles();
  const multiInputClasses = useCommonMultiInputLines();
  const { paraviewClientState } = useParaviewContext();
  const { calculateCentroid } = useMeshGeometry(projectId);
  const {
    volumeNodeFilter,
    setVolumes,
    surfaceNodeFilter,
    setSurfaces,
  } = useMotionFrameSelection(node.id);

  // Recoil state
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  // Other data
  const { saveParamAsync } = useSimulationConfig();
  const isTransient = isSimulationTransient(simParam);
  const frame = findFrameById(simParam, node.id);
  assert(!!frame, 'No selected frame');
  const { frameId, motionType, motionFormulation } = frame;
  const isRotation = motionType === simulationpb.MotionType.CONSTANT_ANGULAR_MOTION;
  const isTranslation = motionType === simulationpb.MotionType.CONSTANT_TRANSLATION_MOTION;
  const isNoMotion = motionType === simulationpb.MotionType.NO_MOTION;
  const isAutomaticFormulation = (
    !isTransient || motionFormulation !== simulationpb.MotionFormulation.MRF_MOTION_FORMULATION
  );

  const coordsPanel = useNodePanel(node.id, 'coordinates');
  const motionPanel = useNodePanel(
    node.id,
    'motion',
    { tagsToExpand: ['motion'], defaultExpanded: !isNoMotion },
  );
  const geometryPanel = useNodePanel(node.id, 'geometry');

  const parentFrame = findFrameById(simParam, frame.frameParent);

  const coordinateVisualizer = useCoordinateVisualizer(simParam, paraviewClientState);

  const lcvEnabled = useLcVisEnabledValue(projectId);
  const lcvCoordVisualizer = useLcvCoordinateVisualizer();

  const activeCoordinateVisualizer = lcvEnabled ? lcvCoordVisualizer : coordinateVisualizer;

  const coordinatesSummary = summarizeFrameCoordinateSystem(frame, parentFrame);
  const motionSummary = summarizeMotion(frame);

  const transforms = frame.frameTransforms;
  const { origin, orientation } = extractCoordinatesFromTransforms(transforms);
  const motionRotationAngles = getFrameDefaultRotationalAngles(frame);
  const rotationalVelocity = getFrameDefaultRotationalVelocity(frame);
  const motionTranslation = getFrameDefaultTranslation(frame);
  const translationalVelocity = getFrameDefaultTranslationVelocity(frame);

  const motionTypes: SelectOption<simulationpb.MotionType>[] = getMotionTypes(motionType);

  const paramMotionFormulation = paramDesc[ParamName.MotionFormulation] as MultipleChoiceParam;

  const motionFormulations: SelectOption<simulationpb.MotionFormulation>[] = [
    {
      name: 'Moving Mesh',
      value: simulationpb.MotionFormulation.AUTOMATIC_MOTION_FORMULATION,
      selected: isAutomaticFormulation,
      tooltip: paramMotionFormulation.choices[0].help,
    },
    {
      name: 'Moving Frame',
      value: simulationpb.MotionFormulation.MRF_MOTION_FORMULATION,
      selected: !isAutomaticFormulation,
      tooltip: paramMotionFormulation.choices[1].help,
    },
  ];

  // Memoized state

  // The surface IDs attached to the frame
  const attachedSurfaceIds = useMemo(() => frame.attachedBoundaries, [frame]);

  // The volume IDs attached to the frame
  const attachedVolumeIds = useMemo(
    () => {
      const volumeIds = mapDomainsToIds(staticVolumes, frame.attachedDomains);
      const tagIds = frame.attachedDomains.filter((domain) => geometryTags.isTagId(domain));

      return [...volumeIds, ...tagIds];
    },
    [frame.attachedDomains, geometryTags, staticVolumes],
  );
  // The centroid of the attached geometry
  const centroid = useMemo(
    () => calculateCentroid(attachedVolumeIds, attachedSurfaceIds),
    [attachedVolumeIds, attachedSurfaceIds, calculateCentroid],
  );
  // The centroid is expressed in global coordinates, while centeredOrigin expresses it in the local
  // coordinate frame by inversely applying the list of transformations in every frame from global
  // to this frame's parent).
  const centeredOrigin = useMemo(() => {
    const ancestorPath = getFramePathFromGlobal(simParam, frameId, false);
    const ancestorTransforms = ancestorPath.reduce((result, ancestor) => {
      result.push(...ancestor.frameTransforms);
      return result;
    }, [] as simulationpb.FrameTransforms[]);

    return applyTransforms(centroid, ancestorTransforms, true);
  }, [centroid, frameId, simParam]);
  // Is the origin coincident with the centroid of the attached geometry
  const isCentered = useMemo(() => (
    (centeredOrigin.x === origin.x) &&
    (centeredOrigin.y === origin.y) &&
    (centeredOrigin.z === origin.z)
  ), [centeredOrigin, origin]);

  const volumesWithPorousModels = useMemo(() => {
    // All volumes (identified by domain) that are attached to a porous model
    const porousDomains = assignedVolumes(simParam, staticVolumes, geometryTags)
      .map((volume) => volume.domain);

    return staticVolumes.reduce((result, volume) => {
      if (porousDomains.includes(volume.domain)) {
        result.push(volume);
      }
      return result;
    }, [] as StaticVolume[]);
  }, [geometryTags, simParam, staticVolumes]);

  const isAttachedToPorousModel = useMemo(
    () => assignedFrameIds(simParam).has(frameId),
    [frameId, simParam],
  );

  const disabledParentOption = useCallback((pframe: simulationpb.MotionData) => {
    if (frameHasMotion(pframe)) {
      // Disable parent assignment if prospective parent frame has motion and this frame is
      // referenced by a porous model
      if (isAttachedToPorousModel) {
        return `${frame.frameName} is assigned to a porous model and cannot inherit motion
          from ${pframe.frameName}`;
      }

      // Disable parent assignment if prospective parent frame has motion and is assigned to a
      // volume referenced by a porous model
      const domains = frame.attachedDomains;
      const volumeMatch = volumesWithPorousModels.some(
        (staticVolume) => domains.includes(staticVolume.domain),
      );
      if (volumeMatch) {
        return `${frame.frameName} is assigned to a volume associated with a porous model and
          cannot inherit motion from ${pframe.frameName}`;
      }
    }
    return '';
  }, [frame, isAttachedToPorousModel, volumesWithPorousModels]);

  const parentOptions: SelectOption<string>[] = useMemo(() => {
    const potentialParentFrames = getFrameParentCandidates(simParam, frameId);
    return potentialParentFrames.map((pframe) => {
      const disabledReason = disabledParentOption(pframe);
      return {
        name: pframe.frameName,
        value: pframe.frameId,
        disabled: !!disabledReason,
        disabledReason,
        selected: pframe.frameId === frame.frameParent,
      };
    });
  }, [disabledParentOption, simParam, frame, frameId]);

  // Event handlers
  // Change the frame parent
  const handleChangeParent = async (parentId: string) => {
    await saveParamAsync((newParam) => reassignParentFrame(newParam, frameId, parentId));
  };

  // Reset origin and orientation to (0, 0, 0)
  const handleResetCoordinates = async (event: React.MouseEvent) => {
    event.stopPropagation();
    await saveParamAsync((newParam) => {
      const newFrame = findFrameById(newParam, frameId);
      if (newFrame) {
        initializeFrameCoordinates(newFrame);
      }
    });
  };

  // Assign the origin to the centeredOrigin point as determined by the centroid of the attached
  // geometry and parent frame transformations
  const handleRecenter = async (event: React.MouseEvent) => {
    event.stopPropagation();
    await saveParamAsync((newParam) => assignFrameOrigin(newParam, frameId, centeredOrigin));
  };

  const handleChangeMotion = async (newMotionType: simulationpb.MotionType) => {
    await saveParamAsync((newParam) => assignFrameMotionType(newParam, frameId, newMotionType));
  };

  const handleChangeFormulation = async (newMotionFormulation: simulationpb.MotionFormulation) => {
    await saveParamAsync(
      (newParam) => assignFrameMotionFormulation(newParam, frameId, newMotionFormulation),
    );
  };

  // Change the origin
  const setOrigin = async (value: basepb.Vector3) => {
    await saveParamAsync((newParam) => assignFrameOrigin(newParam, frameId, value));
  };

  // Change the orientation
  const setOrientation = async (value: basepb.Vector3) => {
    await saveParamAsync((newParam) => assignFrameOrientation(newParam, frameId, value));
  };

  // Select this frame as the Body Frame
  const setAsBodyFrame = async (checked: boolean) => {
    await saveParamAsync((newParam) => {
      newParam.bodyFrame = new simulationpb.BodyFrame({
        bodyFrameId: checked ? frameId : '',
      });
    });
  };

  const setRotationalVelocity = async (value: basepb.Vector3) => {
    await saveParamAsync((newParam) => assignFrameRotationalVelocity(newParam, frameId, value));
  };

  const setMotionRotationAngles = async (value: basepb.Vector3) => {
    await saveParamAsync((newParam) => assignFrameRotationAngles(newParam, frameId, value));
  };

  const setTranslationalVelocity = async (value: basepb.Vector3) => {
    await saveParamAsync((newParam) => assignFrameTranslationalVelocity(newParam, frameId, value));
  };

  const setTranslation = async (value: basepb.Vector3) => {
    await saveParamAsync((newParam) => assignFrameTranslation(newParam, frameId, value));
  };

  // Effects
  useEffect(() => {
    if (coordsPanel.expanded) {
      activeCoordinateVisualizer.show(frame.frameId);
    } else {
      activeCoordinateVisualizer.clear();
    }
    // Adding activeCoordinateVisualizer to the dependency array causes issues like infinite render
    // loop and rendering of the frames when de-selecting a motion node.
    // NOTE: adding a dependency on simParam makes sure that we refresh the motion data when we
    // change the motion frames.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordsPanel.expanded, frame.frameId, simParam]);

  const bodyFrameId = simParam.bodyFrame?.bodyFrameId;
  const isBodyFrame = bodyFrameId === frameId;
  const parentIsGlobal = parentFrame ? isFrameGlobal(simParam, parentFrame?.frameId) : false;

  const handleLabelClick = async () => {
    // This check is for the "onClick" action which does not have a "disabled" property.
    if (!readOnly && parentIsGlobal) {
      await setAsBodyFrame(!isBodyFrame);
    }
  };

  return (
    <div className={propClasses.properties}>
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={coordsPanel.collapsed}
          headerRight={(
            <div className={propClasses.panelHeaderButtons}>
              <Tooltip title="Assign origin to centroid of assigned geometry">
                <span>
                  <IconButton
                    disabled={readOnly || isCentered}
                    onClick={handleRecenter}>
                    <RingCircleIcon maxHeight={13} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Reset coordinates">
                <span>
                  <IconButton
                    disabled={readOnly}
                    onClick={handleResetCoordinates}>
                    <SvgIcon
                      maxHeight={13}
                      name="reset"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          )}
          heading="Coordinates"
          onToggle={coordsPanel.toggle}>
          <LabeledInput
            label="Parent">
            <DataSelect
              asBlock
              disabled={readOnly || isBodyFrame}
              onChange={handleChangeParent}
              options={parentOptions}
              size="small"
            />
          </LabeledInput>
          <FrameCoordinates
            orientation={orientation}
            origin={origin}
            readOnly={readOnly}
            setOrientation={setOrientation}
            setOrigin={setOrigin}
          />
          <div
            className={multiInputClasses.root}
            style={{ marginTop: '16px' }}>
            <Form.LabeledCheckbox
              checkbox={{
                checked: isBodyFrame,
                disabled: readOnly || !parentIsGlobal,
                onChange: handleLabelClick,
              }}
              labelTooltip={{
                title: (parentIsGlobal ? BODY_FRAME_DESCRIPTION : BODY_FRAME_PARENT_MUST_BE_GLOBAL),
                body: 'Use as Body Frame',
              }}
              onClick={handleLabelClick}
              svgTooltip={{
                title: (
                  <div>
                    Body Frame Orientation Convention:
                    <ul>
                      <li>The positive x-direction points forward.</li>
                      <li>The positive y-direction points to the right.</li>
                    </ul>
                  </div>
                ),
                icon: (
                  <SvgIcon
                    color={colors.lowEmphasisText}
                    maxHeight={12}
                    maxWidth={12}
                    name="diskInfo"
                  />
                ),
              }}
            />
          </div>
        </CollapsiblePanel>
        <Collapsible collapsed={coordsPanel.expanded} transitionPeriod={250}>
          <div className={frameClasses.framePanelSummary}>
            <div className={frameClasses.frameConfigSummary}>{coordinatesSummary}</div>
          </div>
        </Collapsible>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={motionPanel.collapsed}
          heading="Motion and Position"
          onToggle={motionPanel.toggle}>
          <LabeledInput label="Type">
            <DataSelect
              asBlock
              disabled={readOnly}
              onChange={handleChangeMotion}
              options={motionTypes}
              size="small"
            />
          </LabeledInput>
          {isTransient && !isNoMotion && (
            <LabeledInput
              help={paramMotionFormulation.help}
              label="Formulation">
              <DataSelect
                asBlock
                disabled={readOnly}
                onChange={handleChangeFormulation}
                options={motionFormulations}
                size="small"
              />
            </LabeledInput>
          )}
          {isRotation && (
            <LabeledInput
              help="Initial rotation vector (the magnitude defines the rotation angle)"
              label="Initial Rotation">
              <Vector3Input
                disabled={readOnly}
                onCommit={setMotionRotationAngles}
                quantityType={quantitypb.QuantityType.DEGREE}
                value={motionRotationAngles}
              />
            </LabeledInput>
          )}
          {isRotation && (
            <LabeledInput
              help="Rotational velocity and direction (the magnitude defines the rotation rate)"
              label="Rotation Vector">
              <Vector3Input
                disabled={readOnly}
                onCommit={setRotationalVelocity}
                quantityType={quantitypb.QuantityType.ANGULAR_VELOCITY}
                value={rotationalVelocity}
              />
            </LabeledInput>
          )}
          {isTranslation && (
            <LabeledInput
              help="Initial translation vector"
              label="Initial Translation">
              <Vector3Input
                disabled={readOnly}
                onCommit={setTranslation}
                quantityType={quantitypb.QuantityType.LENGTH}
                value={motionTranslation}
              />
            </LabeledInput>
          )}
          {isTranslation && (
            <LabeledInput
              help="Translational velocity and direction"
              label="Translation Vector">
              <Vector3Input
                disabled={readOnly}
                onCommit={setTranslationalVelocity}
                quantityType={quantitypb.QuantityType.VELOCITY}
                value={translationalVelocity}
              />
            </LabeledInput>
          )}
        </CollapsiblePanel>
        <Collapsible collapsed={motionPanel.expanded} transitionPeriod={250}>
          <div className={frameClasses.framePanelSummary}>
            <div className={frameClasses.frameConfigSummary}>{motionSummary}</div>
          </div>
        </Collapsible>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={geometryPanel.collapsed}
          heading="Geometry"
          onToggle={geometryPanel.toggle}>
          <div className={propClasses.selectorContainer}>
            <NodeSubselect
              id={MOTION_FRAME_VOLUME_SUBSELECT_ID}
              independentSelection
              labels={['volumes']}
              nodeFilter={volumeNodeFilter}
              nodeIds={attachedVolumeIds}
              onChange={setVolumes}
              readOnly={readOnly}
              referenceNodeIds={[node?.id]}
              title="Volumes"
              visibleTreeNodeTypes={[
                NodeType.SURFACE_GROUP,
                NodeType.VOLUME,
                NodeType.VOLUME_CONTAINER,
                ...TAGS_NODE_TYPES,
              ]}
            />
            <NodeSubselect
              id="frame-surfaces"
              independentSelection
              labels={['surfaces']}
              nodeFilter={surfaceNodeFilter}
              nodeIds={attachedSurfaceIds}
              onChange={setSurfaces}
              readOnly={readOnly}
              referenceNodeIds={[node?.id]}
              title="Surfaces"
              visibleTreeNodeTypes={[
                NodeType.SURFACE_GROUP,
                NodeType.SURFACE,
                ...TAGS_NODE_TYPES,
              ]}
            />
          </div>
        </CollapsiblePanel>
      </PropertiesSection>
    </div>
  );
});
