// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily } from 'recoil';

import { RecoilProjectKey } from '../../../../lib/persist';
import { CAMERA_CONTAINER_ID, CAMERA_GLOBAL_CONTAINER_ID, NodeType, SimulationTreeNode } from '../../../../lib/simulationTree/node';
import { createSimTreeNodes } from '../../../../lib/simulationTree/utils';
import { CameraGroupMapAccessType, cameraGroupState } from '../../../../recoil/cameraState';

export const cameraSectionSelector = selectorFamily<SimulationTreeNode, RecoilProjectKey>({
  key: 'cameraSectionSelector',
  get: (key: RecoilProjectKey) => ({ get }) => {
    // Get the local cameras
    const localCameraGroupMap = get(cameraGroupState({
      key,
      cameraAccess: CameraGroupMapAccessType.LOCAL,
    }));
    const localTreeNodes = createSimTreeNodes(localCameraGroupMap, []);

    // Get the global cameras
    const globalCameraGroupMap = get(cameraGroupState({
      key,
      cameraAccess: CameraGroupMapAccessType.GLOBAL,
    }));
    const globalTreeNodes = createSimTreeNodes(globalCameraGroupMap, []);

    // Construct the main Camera node under the root
    const allCameraNodes = [
      // Add the local cameras and local camera groups to the Camera node
      ...localTreeNodes,
    ];

    // If there are global cameras and global camera groups, add them after the local cameras, under
    // a new Global Cameras node
    if (globalTreeNodes.length) {
      allCameraNodes.push(new SimulationTreeNode(
        NodeType.CAMERA_GLOBAL_CONTAINER,
        CAMERA_GLOBAL_CONTAINER_ID,
        'Global Cameras',
        globalTreeNodes,
      ));
    }

    return new SimulationTreeNode(
      NodeType.CAMERA_CONTAINER,
      CAMERA_CONTAINER_ID,
      'Cameras',
      allCameraNodes,
    );
  },
  dangerouslyAllowMutability: true,
});
