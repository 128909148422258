// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/** A dialog that manages the upload of mesh files, directories, or URLs */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import commondir from 'commondir';
import { useNavigate } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';

import * as flags from '../../flags';
import { UploadProgress } from '../../lib/UploadProgress';
import assert from '../../lib/assert';
import { SelectOption, SelectOptionGroup } from '../../lib/componentTypes/form';
import { colors } from '../../lib/designSystem';
import { FileX } from '../../lib/filesystem';
import { flattenSelectionOptionGroups } from '../../lib/form';
import { INTERCOM_LAUNCHER_SELECTOR } from '../../lib/intercom';
import { projectsLink } from '../../lib/navigation';
import { basename, extname } from '../../lib/path';
import { progressFraction } from '../../lib/progressInfoUtils';
import * as rpc from '../../lib/rpc';
import { addRpcError } from '../../lib/transientNotification';
import { allLengthUnits } from '../../lib/units';
import { FileType, NUMERIC_EXTENSIONS, fileTypeToMeshTypeProto } from '../../lib/upload/fileTypes';
import { FileUploader } from '../../lib/upload/fileUploader';
import {
  UploadResult,
  isDiscreteGeometryFile,
  isGeometryFile,
  isMeshFile,
  isOpenFOAM,
} from '../../lib/upload/uploadUtils';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import * as uploadpb from '../../proto/upload/upload_pb';
import { useSetImportedModel } from '../../recoil/importedModelState';
import { usePendingWorkOrders } from '../../recoil/pendingWorkOrders';
import { useNoCredits } from '../../recoil/useAccountInfo';
import { useIsDraggingFiles, useSetDraggingFiles } from '../../recoil/useDragOver';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { analytics } from '../../services/analytics';
import { useUploadError } from '../../state/external/project/uploadError';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { ActionButton } from '../Button/ActionButton';
import Form from '../Form';
import { DataSelect } from '../Form/DataSelect';
import { InputDescription } from '../Form/InputDescription';
import { NumberInput } from '../Form/NumberInput';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { ButtonTabs } from '../controls/ButtonTabs';
import { ControlState, Dialog } from '../dialog/Base';
import { AlertIcon } from '../notification/AlertIcon';
import { FileBoxIcon } from '../svg/FileBoxIcon';
import { LinkIcon } from '../svg/LinkIcon';
import Collapsible from '../transition/Collapsible';
import { Flex } from '../visual/Flex';

import { UploadHelp } from './UploadHelp';

export interface MeshUploadData {
  files: File[];
  paths: string[];
}

type PresetFile = File;
type PresetDirectory = MeshUploadData;

export type Preset = PresetFile | PresetDirectory;
export type MeshUpload = (
  files: File[],
  paths: string[],
  scaling: number,
  onFinish: (success: boolean) => void,
) => void;
export type MeshUploadUrl = (
  url: string,
  scaling: number,
  onFinish: (success: boolean) => void,
) => void;

export interface MeshImportDialogProps {
  // The project ID
  projectId: string;
  // Toggles the dialog open/closed
  open: boolean;
  // Called when the dialog is closed
  onClose: () => void;
  // Given a Url already uploaded into our system, handle mesh conversion. files contains the list
  // of File uploaded by the user.
  processInputFile: (
    url: string,
    scaling: number,
    conversion: frontendpb.MeshConversionStatus,
    setUploadProgress: (value: React.SetStateAction<UploadProgress>) => void,
    fileNames: string[],
    fileTypeIn: uploadpb.MeshType | undefined,
    forceDiscrete: boolean,
  ) => Promise<projectstatepb.MeshUrl | null>;
  // Called just after the user submits the request to upload a file. This can be used to set some
  // state related to filenames.
  onStartUpload?: (name: string) => void;
  // Called after the file has been uploaded a processed
  onNewMeshUrl?: (newMeshUrl: projectstatepb.MeshUrl | null) => void;
  // Called before starting the upload process. This can be used to set some state based on the
  // scaling factor.
  handleScaling?: (scaling: number) => void;
  // The list of file types that can be uploaded as a file
  fileTypeOptions: (SelectOption<FileType> | SelectOptionGroup<FileType>)[];
  // The list of file types that can be uploaded as a directory
  directoryTypeOptions: string[];
  // Accept numeric file extensions to be selectable by the input
  acceptNumericExtensions?: boolean;
  // Prepopulated file or directory values
  preset?: Preset;
  // Used for determining analytics message
  type?: 'CAD' | 'MESH';
  // Disallows showing the force discrete checkbox. This is used to avoid confusion to the users
  // since in some cases, we need to force the checkbox to be ON no matter the input of the
  // user.
  disallowForceDiscrete?: boolean;
}

enum ImportType {
  FILE = 'FILE',
  DIRECTORY = 'DIRECTORY',
  URL = 'URL',
}

const importLabels: Record<ImportType, string> = {
  [ImportType.FILE]: 'File',
  [ImportType.DIRECTORY]: 'Directory',
  [ImportType.URL]: 'URL',
};

const useStyles = makeStyles(
  () => createStyles({
    formContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    importTypes: {
      display: 'flex',
      justifyContent: 'center',
    },
    horizontalLabelCluster: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
    input: {
      width: '150px',
    },
    rightInput: {
      marginLeft: 'auto',
      marginTop: '8px',
    },
    inputSource: {
      marginTop: '5px',
      display: 'flex',
      backgroundColor: colors.surfaceDark2,
      boxShadow: `inset 0 -1px 0 ${colors.surfaceLight2},
                  inset 0 1px 4px rgb(24, 25, 30, 0.1)`,
      '& > input': {
        border: 0,
        outline: 0,
        boxShadow: 0,
        padding: 0,
        backgroundColor: 'transparent',
        color: colors.highEmphasisText,
        fontSize: '14px',
        flex: '1 1 auto',
        alignSelf: 'stretch',
        transition: 'color 500ms',
        '&::placeholder': {
          color: colors.inputPlaceholderText,
        },
        '&:disabled': {
          color: colors.secondaryButtonBackground,
        },
      },
    },
    inputSourceLeft: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    errorBorder: {
      border: `1px ${colors.red500} solid`,
      borderRadius: '4px 0 0 4px',
    },
    inputSourceIcon: {
      padding: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '0 0',
      opacity: 1,
      transition: 'opacity 500ms',
      '&.disabled': {
        opacity: 0.5,
      },
    },
    fileLabel: {
      flex: '1 1 auto',
      color: colors.highEmphasisText,
      textAlign: 'left',
      transition: 'color 500ms',
      '&.disabled': {
        color: colors.secondaryButtonBackground,
      },
      '&.placeholder': {
        color: colors.inputPlaceholderText,
      },
    },
    collapsible: {
      marginTop: '16px',
    },
    bottomMargin: {
      marginBottom: '16px',
    },
    noCreditsTitleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    noCreditsIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '15px',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
    noCreditsTitle: {
      margin: 0,
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    noCreditsSubtitle: {
      margin: '8px 0 20px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      color: 'var(--color-low-emphasis-text)',
    },
  }),
  { name: 'MeshImportDialog' },
);

const initialImportType = ImportType.FILE;

const urlRecoilState = atom<ImportType>({
  key: 'meshImportDialogUrlType',
  default: initialImportType,
});

const units = allLengthUnits();

const emptyUnit = 'NONE';

function isPathLCMesh(file: string): boolean {
  return /\.lcmesh$/.test(file);
}

// Given a mesh upload data structure, return the root path
function getRootPath(data: MeshUploadData): string {
  // The process here is to get all non-empty paths and verify that they have a
  // common ancestor directory.  This should always be true when browsing for
  // mesh files, but since any application can set the 'preset' prop with
  // arbitrary files/paths, we can't assume that to be true.

  const { files, paths } = data;

  // First flatten all path values from the files' webkitRelativePath property
  // and from the paths array itself.
  const allPaths = files.map((file: File) => (file as FileX).webkitRelativePath);
  allPaths.push(...paths);

  // Filter out empty values.
  const realPaths = allPaths.filter((path: string) => !!path);
  if (realPaths.length === 0) {
    return '';
  }

  // Get closest common parent directory
  const commonPath = commondir('', realPaths);

  if (commonPath) {
    // Strip possible leading '/' and anything after first '/'
    return commonPath.replace(/^\/?(.+?\/).*$/, '$1');
  }

  return '';
}

function getProgress(
  uploadProgress: UploadProgress,
  progressInfo?: frontendpb.ProgressInfo,
): UploadProgress {
  if (progressInfo) {
    return {
      done: true,
      message: progressInfo.details,
      progress: progressFraction(progressInfo) ?? null,
    };
  }
  return uploadProgress;
}

const browseButtonText = 'Browse';
const scalingTooltip = `All x,y,z coordinates in the model will be multiplied
  by this scaling factor.  "1.00" means the model will not be scaled.`;

const MeshImportDialog = (props: MeshImportDialogProps) => {
  const {
    onClose, open, preset,
    fileTypeOptions, directoryTypeOptions, acceptNumericExtensions,
    type = 'MESH',
  } = props;
  const { projectId } = useProjectContext();
  const isGeometryView = useIsGeometryView();
  const projectName = useProjectMetadata(projectId)?.summary?.name;
  const [isDraggingFiles] = useIsDraggingFiles();
  const setDraggingFiles = useSetDraggingFiles();

  // Label for user-selected file or directory
  const [fileLabel, setFileLabel] = useState<string>('');
  // Track form validity
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  // Track selected import type
  const [importType, setImportType] = useRecoilState(urlRecoilState);
  // Should we show the units. Units are not needed for native mesh and CAD files.
  const [showUnits, setShowUnits] = useState<boolean>(false);
  // An uploader that is responsible for handling the file we are currently uploading.
  // It is null if no file is currently being uploaded.
  const [fileUploader, setFileUploader] = useState<FileUploader | null>(null);
  // Track selected file when import type is FILE
  const [meshFiles, setMeshFiles] = useState<File[] | undefined>();
  // Track selected file when import type is DIRECTORY
  const [meshDir, setMeshDir] = useState<MeshUploadData | undefined>();
  // Track user-input URL when import type is URL
  const [meshUrl, setMeshUrl] = useState<string>('');
  // User-selected unit for mesh file (implies a scaling factor)
  const [meshUnit, setMeshUnit] = useState<string>(emptyUnit);
  // Additional scaling (for resizing model)
  const [extraScaling, setExtraScaling] = useState<number>(1.0);
  // Track the selected file type
  const [fileType, setFileType] = useState<FileType | undefined>();
  // Track whether "load cell zones" is checked
  const [loadCellZones, setLoadCellZones] = useState<boolean>(true);
  // Track upload error
  const [uploadError, setUploadError] = useUploadError(projectId);
  // Track upload progress
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({
    done: true,
    progress: 0,
  });
  // Should we show the "force discrete" checkbox
  const [showForceDiscrete, setShowForceDiscrete] = useState<boolean>(false);
  // Track whether "force discrete" is checked
  const [forceDiscrete, setForceDiscrete] = useState<boolean>(false);
  const fileDesc = 'mesh or CAD file';
  const [pendingWorkOrders] = usePendingWorkOrders(props.projectId);
  const getGeomOrder = pendingWorkOrders.workOrders[frontendpb.WorkOrderType.GET_GEOMETRY];
  // URL upload does not use a fileUploader, so explicitly track when a URL upload is happening
  const [urlUploadInProgress, setUrlUploadInProgress] = useState<boolean>(false);
  // Importing consists of first uploading the file and then sending a GetGeometry request.
  // NOTE: GetGeometry work orders only affect the setup tab, the geometry tab should not be
  // influenced by the things that happen on the setup tab.
  const isImporting = !!fileUploader || (!isGeometryView && !!getGeomOrder) || urlUploadInProgress;
  const progressInfo = (!isGeometryView && getGeomOrder?.progressInfo) || undefined;
  const progress = getProgress(uploadProgress, progressInfo);
  const isStaff = useIsStaff();
  const setImportedModel = useSetImportedModel();
  const hasNoCredits = useNoCredits();

  const experimentConfig = useEnabledExperiments();
  // We use the --disconnect meshconverter cli arg when moving parts feature flags are on. This
  // is needed in order to generate multi-zone meshes from non-native meshes.
  const disconnect = true;

  // File input reference
  const fileInput = useRef<HTMLInputElement>(null);
  // URL input reference
  const urlInput = useRef<HTMLInputElement>(null);
  // SubmitButton reference
  const refSubmit = useRef<HTMLButtonElement>(null);

  const classes = useStyles();

  const navigate = useNavigate();

  const handleSelectImportType = (value: ImportType) => {
    if (value) {
      setImportType(value);
      setFileType(undefined);
      analytics.track(`${type} Import Type Selected`, { importType: value });
    }
  };

  const supportedImportTypes: ImportType[] = [ImportType.FILE, ImportType.DIRECTORY];
  if (isStaff) {
    supportedImportTypes.push(ImportType.URL);
  }

  // eslint-disable-next-line
  const importTabs = supportedImportTypes.map((t) => ({
    value: t,
    label: importLabels[t],
  }));

  const handleUrlChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setMeshUrl(target.value || '');
    setFileType(undefined);
  };

  const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const files = target.files ? [...target.files] : [];

    setFileType(undefined);
    switch (importType) {
      case ImportType.DIRECTORY: {
        const paths = files.map((file: File) => file.webkitRelativePath);
        setMeshDir({ files, paths });
        analytics.track(`${type} Directory Selected`, { fileCount: files.length });
        break;
      }
      case ImportType.FILE: {
        setMeshFiles(files.length ? files : undefined);
        if (files.length) {
          analytics.track(`${type} File Selected`, {
            fileName: files[0].name, fileSize: files[0].size,
          });
        }
        break;
      }
      default: {
        // do nothing
      }
    }
  };

  const meshUnitScale: () => number | null = useCallback(
    () => {
      const unit = units.find((item) => item.name === meshUnit);
      return unit ? unit.valueInMeters : null;
    },
    [meshUnit],
  );

  // TODO(LC-6917): remove when Parasolid meshing is enabled for all
  const allowParasolid = experimentConfig.includes(flags.parasolidMeshing);
  const lcsurfaceTessellation = experimentConfig.includes(flags.lcsurfaceTessellation);
  const remeshingEnabled = experimentConfig.includes(flags.remeshing);

  const processInputFile = async (
    url: string,
    scaling: number,
    conversion: frontendpb.MeshConversionStatus,
    fileTyp: uploadpb.MeshType | undefined,
  ) => {
    try {
      const names = meshFiles?.map((file) => file.name) || [url];
      const result =
        await props.processInputFile(
          url,
          scaling,
          conversion,
          setUploadProgress,
          names,
          fileTyp,
          showForceDiscrete && forceDiscrete,
        );
      setFileUploader(null);
      return result;
    } catch (err) {
      throw Error(err);
    }
  };

  // Upload one or more local files, performing mesh conversion if necessary.
  const uploadFiles = async (files: File[], paths: string[], scaling: number) => {
    setUploadProgress({ done: false, progress: 0 });
    props.onStartUpload?.(files[0].name);

    const newFileUploader = new FileUploader();
    setFileUploader(newFileUploader);
    let uploadResult: UploadResult;
    const fType = fileType ? fileTypeToMeshTypeProto(fileType) : undefined;
    if (files.length === 1) {
      const fileName = files[0].name;
      if (!isMeshFile(fileName) && !isGeometryFile(fileName)) {
        throw Error(`Unknown file extension: ${fileName}`);
      }
      uploadResult = await newFileUploader.uploadFile(
        props.projectId,
        files[0],
        scaling,
        disconnect,
        setUploadProgress,
        fType,
        loadCellZones,
      );
    } else {
      uploadResult = await newFileUploader.uploadFiles(
        props.projectId,
        files,
        paths,
        scaling,
        disconnect,
        setUploadProgress,
        fType,
        loadCellZones,
      );
    }
    setUploadProgress({ done: true, message: 'Allocating Resources...', progress: null });
    return processInputFile(uploadResult.url, scaling, uploadResult.conversion, fType);
  };

  // Upload a file via URL, performing mesh conversion if necessary.
  const uploadUrl = async (userUrl: string, scaling: number) => {
    const url = userUrl.trim();
    props.onStartUpload?.(basename(url));
    const req = new frontendpb.UploadUrlRequest({
      projectId: props.projectId,
      url,
      scaling,
      disconnect,
      doNotReadZonesOpenfoam: !loadCellZones,
    });
    if (fileType) {
      req.meshType = fileTypeToMeshTypeProto(fileType);
    }
    const reply = await rpc.callRetry('UploadUrl', rpc.client.uploadUrl, req);
    const fType = fileType ? fileTypeToMeshTypeProto(fileType) : undefined;
    return processInputFile(reply.url, scaling, reply.conversion, fType);
  };

  const handleSubmit = async () => {
    if (isImporting || !formIsValid) {
      return;
    }

    setUploadError('');

    let scaling = extraScaling;
    if (showUnits) {
      const unitScale = meshUnitScale();
      // validate() prevents this, but it's typed such that it could be null
      assert(unitScale !== null, 'meshUnitScale() is null');
      scaling *= unitScale;
    }

    props.handleScaling?.(scaling);
    analytics.track(`${type} Upload Started`, { importType, scaling, fileType: fileType?.name });
    if (importType === ImportType.URL) {
      setUrlUploadInProgress(true);
      await uploadUrl(meshUrl, scaling)
        .then(props.onNewMeshUrl)
        .catch(
          (err: Error) => {
            addRpcError(
              `Could not upload url for project ${projectId}`,
              err,
              { projectId, projectName },
            );
            // Use the last part of the error message as the error message to the user.
            const errorMessage = err.message.substring(err.message.lastIndexOf(':') + 1).trim();
            setFileUploader(null);
            setUploadError(
              `The uploaded file could not be processed.
              Please check that your URL is valid. Upload failed with message: ${errorMessage}`,
            );
            analytics.track(`${type} Upload Failed`, { importType, error: errorMessage });
          },
        ).finally(() => {
          setUrlUploadInProgress(false);
        });
    } else {
      const files: File[] = [];
      const paths: string[] = [];
      if (importType === ImportType.FILE) {
        // validate() makes sure that meshFile is not undefined, but TS doesn't know that.
        assert(!!meshFiles, 'meshFile is not defined');
        meshFiles.forEach((file) => paths.push(file.webkitRelativePath));
        files.push(...meshFiles);
      } else {
        // validate() makes sure that meshDir is not undefined, but TS doesn't know that.
        assert(!!meshDir, 'meshDir is not defined');
        files.push(...meshDir.files);
        paths.push(...meshDir.paths);
      }
      await uploadFiles(files, paths, scaling).then(props.onNewMeshUrl)
        .catch(
          (err: Error) => {
            setFileUploader(null);
            // Do not show error message when canceled.
            if (err.message === 'canceled') {
              analytics.track(`${type} Upload Canceled`, { importType });
              return;
            }
            addRpcError(
              `Could not upload ${fileDesc} for project ${projectId}`,
              err,
              { projectId, projectName },
            );
            // Use the last part of the error message as the error message to the user.
            const errorMessage = err.message.substring(err.message.lastIndexOf(':') + 1).trim();
            setUploadError(
              `The uploaded ${importLabels[importType].toLocaleLowerCase()} could not be processed.
              Please check that your file is a valid ${fileDesc}.
              Upload failed with message: ${errorMessage}`,
            );
            analytics.track(`${type} Upload Failed`, { importType, error: errorMessage });
          },
        );
    }

    setImportedModel(true);
  };

  const handleChangeExtraScaling = (value: number) => {
    setExtraScaling(value);
    analytics.track(`${type} Scaling Changed`, { scaling: value });
  };

  // Validate form
  const validate = () => {
    let valid = true;

    switch (importType) {
      case ImportType.DIRECTORY: {
        valid = !!meshDir?.files.length && isOpenFOAM(meshDir!.paths);
        break;
      }
      case ImportType.FILE: {
        if (!meshFiles?.length) {
          valid = false;
        } else {
          const allGeometryFile = meshFiles.every((file) => isGeometryFile(file.name));
          const allMeshFile = meshFiles.every((file) => isMeshFile(file.name));
          const fileName = meshFiles[0].name;
          const allSameExtension =
            meshFiles.every((file) => extname(file.name) === extname(fileName));
          if (!allSameExtension) {
            valid = false;
          } else if (meshFiles.length > 1 && allMeshFile) {
            valid = false;
          } else if (!allGeometryFile && !allMeshFile) {
            valid = false;
          } else if (!allSameExtension) {
            valid = false;
          }
        }
        break;
      }
      case ImportType.URL: {
        if (!meshUrl) {
          valid = false;
        }
        break;
      }
      default: {
        // just for eslint, which complains even though enum is exhausted
      }
    }

    if (!fileType) {
      valid = false;
    }

    if (showUnits) {
      if (!meshUnitScale()) {
        valid = false;
      }
    }

    if (!extraScaling) {
      valid = false;
    }

    setFormIsValid(valid);
  };

  // If dialog is open and presets are defined, infer and set the import type; otherwise, do
  // nothing. If we do this when open==false, the tabs' state may change during the brief transition
  // time when the dialog is fading out, which is unsightly.
  useEffect(() => {
    if (open) {
      setFileUploader(null);
      setUploadError('');
      setMeshUnit(emptyUnit);

      if (preset instanceof File) {
        setMeshFiles([preset]);
        setImportType(ImportType.FILE);
      } else if (preset) {
        setMeshDir(preset);
        setImportType(ImportType.DIRECTORY);
      }
      analytics.track(`${type} Import Dialog Opened`);
    }
  }, [preset, open, setImportType, setUploadError, type]);

  // Whenever any of the form's values change, run validation
  useEffect(validate, [
    extraScaling, importType, meshFiles, meshDir, meshUnit, meshUnitScale,
    meshUrl, showUnits, allowParasolid, lcsurfaceTessellation, fileType,
  ]);

  useEffect(() => {
    if (meshFiles && meshFiles.length > 0) {
      const fileName = meshFiles[0].name;
      const allSameExtension =
        meshFiles.every((file) => extname(file.name) === extname(fileName));
      if (!isMeshFile(fileName) && !isGeometryFile(fileName)) {
        setUploadError('Please check that your file is a valid mesh or CAD file.');
        return;
      }
      // Since we have to specify the file type, we only allow one extension at a time. We can
      // change this later.
      if (!allSameExtension) {
        setUploadError('Please check that all files have the same extension.');
        return;
      }
      // Only one mesh file can be uploaded at a time. We cannot merge them.
      if (meshFiles.length > 1 && meshFiles.every((file) => isMeshFile(file.name))) {
        setUploadError('Please upload only one mesh file at a time.');
        return;
      }

      if (meshFiles.length > 5) {
        setUploadError('Please upload less than 5 files at a time.');
        return;
      }

      // Disallow uploading big files from the frontend clients if there are multiple files. This is
      // to try to prevent attacks with huge files that do not take much space when compressed (at
      // least from the frontend clients).
      if (meshFiles.length > 1) {
        const fileSizes = meshFiles.map((file) => file.size);
        const totalSize = fileSizes.reduce((acc, size) => acc + size, 0);
        if (totalSize > 300 * 1024 * 1024) {
          setUploadError('Please upload files with a total size less than 300MiB.');
          return;
        }
      }
    } else if (meshDir) {
      if (!isOpenFOAM(meshDir.paths)) {
        setUploadError('Please check that your directory is a valid openFOAM directory.');
        return;
      }
    }
    setUploadError('');
  }, [meshFiles, meshDir, setUploadError]);

  useEffect(() => {
    // Focus the submit button so that users can hit ENTER to submit after they have chosen a file.
    // Do not do this for URL as it prevents people from typing the URL. We must also check that the
    // user is not editing the scale input, as it will prevent typing there as well.
    if (document.activeElement?.textContent === browseButtonText) {
      if (formIsValid && refSubmit.current && importType !== ImportType.URL) {
        refSubmit.current.focus();
      }
    }
  }, [meshFiles, formIsValid, importType]);

  // Update the 'fileLabel' display value depending on whether a file or a
  // directory is being uploaded
  useEffect(() => {
    let label = '';
    switch (importType) {
      case ImportType.FILE: {
        meshFiles?.forEach((file, index) => {
          label += file.name;
          if (index < meshFiles.length - 1) {
            label += ', ';
          }
        });
        break;
      }
      case ImportType.DIRECTORY: {
        if (meshDir) {
          label = getRootPath(meshDir);
        }
        break;
      }
      default: {
        // do nothing
      }
    }
    setFileLabel(label);
  }, [importType, meshFiles, meshDir]);

  // When a single file is uploaded (FILE/URL), check the extension.  If it's
  // .lcmesh or a CAD file, then the units are included in the file and should
  // not be shown.
  useEffect(() => {
    let newShowUnits = false;

    switch (importType) {
      case ImportType.FILE: {
        if (meshFiles) {
          newShowUnits = !isPathLCMesh(meshFiles[0].name) &&
            !isGeometryFile(meshFiles[0].name) && !remeshingEnabled;
        }
        break;
      }
      case ImportType.URL: {
        const trimmedUrl = meshUrl.trim();
        if (trimmedUrl) {
          newShowUnits = !isPathLCMesh(trimmedUrl) &&
            !isGeometryFile(meshUrl) && !remeshingEnabled;
        }
        break;
      }
      default: {
        // no op
      }
    }

    setShowUnits(newShowUnits);
  }, [importType, meshDir, meshFiles, meshUrl, remeshingEnabled]);

  // Show "force discrete" checkbox
  useEffect(() => {
    if (props.disallowForceDiscrete) {
      setShowForceDiscrete(false);
      return;
    }
    if (fileType) {
      const ext = fileType.ext[0];
      setShowForceDiscrete(isGeometryFile(ext) && !isDiscreteGeometryFile(ext));
    }
  }, [fileType, props.disallowForceDiscrete]);

  // Update form state based on the import type.  If it's FILE or DIRECTORY, toggle the directory
  // and webkitdirectory attributes on the file input.  If it's URL, focus the URL input for
  // convenience.
  const syncInputs = useCallback((it: ImportType) => {
    switch (it) {
      case ImportType.FILE: {
        if (fileInput.current) {
          fileInput.current.removeAttribute('directory');
          fileInput.current.removeAttribute('webkitdirectory');
        }
        break;
      }
      case ImportType.DIRECTORY: {
        if (fileInput.current) {
          fileInput.current.setAttribute('directory', '');
          fileInput.current.setAttribute('webkitdirectory', '');
        }
        break;
      }
      case ImportType.URL: {
        if (urlInput.current) {
          urlInput.current.focus();
        }
        break;
      }
      default: {
        // just for eslint, since enum is exhausted
      }
    }
  }, []);

  useEffect(() => {
    if (open) {
      syncInputs(importType);
    }
  }, [open, importType, syncInputs]);

  // If upload succeeds, the view changes and the dialog automatically goes
  // away.  If, however, there's an error, nothing happens—we need to
  // 'release' the dialog's inputs from their disabled state so that the
  // user can try again.
  useEffect(() => {
    if (progress.done && progress.progress === 1) {
      setFileUploader(null);
      analytics.track(`${type} Upload Completed`, { importType });
    }
  }, [progress, importType, type]);

  // Parses the file name for its extension
  // If there's a numeric extension, it is removed and the file name is parsed again
  const parseExtension = (file: string) => {
    let extension = extname(file).toLowerCase();
    if (NUMERIC_EXTENSIONS.includes(extension)) {
      extension = extname(
        file.slice(0, file.length - extension.length).toLowerCase(),
      );
    }
    return extension;
  };

  // the file extension of the currently uploaded file, undefined if no file is uploaded
  // if the file has a numeric extension, it is removed
  const uploadedFileExt = useMemo(() => {
    const file = (importType === ImportType.FILE && meshFiles && meshFiles[0].name) ||
      (importType === ImportType.URL && meshUrl);
    if (file) {
      return parseExtension(file);
    }
    return undefined;
  }, [importType, meshFiles, meshUrl]);

  const flattenedFileTypeOptions = useMemo(
    () => flattenSelectionOptionGroups(fileTypeOptions),
    [fileTypeOptions],
  );

  const acceptedInputs = useMemo(() => [
    ...(acceptNumericExtensions ? NUMERIC_EXTENSIONS : []),
    ...flattenedFileTypeOptions.flatMap((option) => option.value.ext),
  ].join(', '), [acceptNumericExtensions, flattenedFileTypeOptions]);

  const possibleFileTypes = useMemo(() => new Set(
    uploadedFileExt ? flattenedFileTypeOptions.reduce((acc, option) => {
      if (option.value.ext.includes(uploadedFileExt)) {
        acc.push(option.value.name);
      }
      return acc;
    }, [] as string[]) : [],
  ), [flattenedFileTypeOptions, uploadedFileExt]);

  const fileTypeSelectOptions = useMemo(() => {
    if (importType === ImportType.DIRECTORY) {
      return directoryTypeOptions.map((option) => {
        const selected = fileType?.name === option;
        return ({
          value: { name: option, ext: [] },
          name: option,
          selected,
        });
      });
    }
    const setDetails = (option: SelectOption<FileType>) => {
      if (
        (importType === ImportType.FILE && meshFiles) || (importType === ImportType.URL && meshUrl)
      ) {
        // a file has been uploaded so we try to guess what the file type is
        // if the file type is unambiguous, we select it
        const selected = fileType?.name === option.value.name || (
          possibleFileTypes.size === 1 && possibleFileTypes.has(option.value.name)
        );
        if (selected) {
          setFileType(option.value);
        }
        return {
          ...option,
          selected,
          disabled: !!possibleFileTypes.size &&
            !selected &&
            !possibleFileTypes.has(option.value.name),
        };
      }
      const selected = option.value.name === fileType?.name;
      return { ...option, selected };
    };
    return fileTypeOptions.map((option) => {
      if ('options' in option) {
        return {
          ...option,
          options: option.options.map((groupedOption) => setDetails(groupedOption)),
        };
      }
      return setDetails(option);
    });
  }, [
    directoryTypeOptions, fileType?.name, fileTypeOptions, importType,
    meshFiles, meshUrl, possibleFileTypes,
  ]);

  const fileTypeCollapsed = !((importType === ImportType.FILE && !!meshFiles) ||
    (importType === ImportType.DIRECTORY && !!meshDir) ||
    (importType === ImportType.URL && !!meshUrl));

  // If importing, do not allow closing of dialog. Interupting import breaks future imports.
  const onCancel = () => {
    if (!isImporting) {
      onClose();
      setDraggingFiles(false);
    }
  };

  let controlState: ControlState = 'normal';
  if (isImporting) {
    // Cancel has not been implemented for URL. It is less of a priority since it is only enabled
    // for staff.
    controlState = importType === ImportType.URL ? 'disabled' : 'working cancelable';
  }

  const progressMessage = (
    progress.message ||
    `Uploading (${Math.round(100 * Math.min(progress.progress || 0, 1))}%)`
  );

  const backToProjects = () => {
    navigate(projectsLink());
  };

  if (hasNoCredits) {
    return (
      <Dialog
        continueButton={{
          label: (
            <span className={INTERCOM_LAUNCHER_SELECTOR.replace('.', '')}>
              Add Credits via Chat
            </span>
          ),
          name: 'pmidSubmitButton',
        }}
        hasCloseButton={false}
        modal
        onClose={onCancel}
        onContinue={() => { }}
        open={open}
        secondaryButtons={[{
          key: 'Back to projects',
          label: 'Back to projects',
          onClick: backToProjects,
        }]}>
        <div>
          <div className={classes.noCreditsTitleWrapper}>
            <div className={classes.noCreditsIcon}>
              <AlertIcon level="error" />
            </div>
            <h4 className={classes.noCreditsTitle}>Credits are required to upload geometry</h4>
          </div>
          <h5 className={classes.noCreditsSubtitle}>
            You&apos;re out of credits and will need to add more to continue with this project.
          </h5>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      cancelButton={{
        disabled: isImporting,
        label: 'Cancel',
        name: 'pmidCancelButton',
      }}
      continueButton={{
        disabled: !formIsValid || isImporting,
        label: 'Upload',
        name: 'pmidSubmitButton',
      }}
      controlState={controlState}
      modal
      onClose={onCancel}
      onContinue={handleSubmit}
      open={open}
      progress={{
        message: progressMessage,
        progress: importType === ImportType.URL ? null : progress.progress,
        visible: isImporting,
      }}
      refSubmit={refSubmit}
      title="File Upload">
      <div className={classes.formContent}>
        <div className={classes.importTypes}>
          <ButtonTabs
            disabled={isImporting}
            equalWidths
            onChange={handleSelectImportType}
            size="small"
            tabs={importTabs}
            value={importType}
          />
        </div>
        <div>
          <Form.Group>
            <Form.Label>{importLabels[importType]}</Form.Label>
            <Form.HelperText>
              <UploadHelp />
            </Form.HelperText>
          </Form.Group>
          {
            importType === ImportType.URL ? (
              <div className={
                cx(classes.inputSource, 'url', { [classes.errorBorder]: uploadError })
              }>
                <div className={cx(classes.inputSourceIcon, { disabled: isImporting })}>
                  <LinkIcon color={colors.neutral800} maxHeight={16} />
                </div>
                <input
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  data-name="meshUrlInput"
                  disabled={isImporting}
                  onBlur={() => setMeshUrl((value) => value.trim())}
                  onInput={handleUrlChange}
                  placeholder="Enter a file URL"
                  ref={urlInput}
                  spellCheck="false"
                  type="text"
                  value={meshUrl}
                />
              </div>
            ) : (
              <div className={classes.inputSource}>
                <div className={
                  cx(classes.inputSourceLeft, { [classes.errorBorder]: uploadError })
                }>
                  <div className={cx(classes.inputSourceIcon, { disabled: isImporting })}>
                    <FileBoxIcon color={colors.neutral800} maxHeight={16} />
                  </div>
                  <input
                    accept={acceptedInputs}
                    data-name="meshLocalInput"
                    disabled={isImporting}
                    multiple
                    name="fileInput"
                    onChange={handleFileChange}
                    ref={fileInput}
                    style={{ display: 'none' }}
                    type="file"
                  />
                  <div
                    className={cx(classes.fileLabel, {
                      disabled: isImporting,
                      placeholder: !fileLabel,
                    })}>
                    {fileLabel ||
                      (isDraggingFiles ?
                        'Drop your files here' :
                        `Choose a ${importLabels[importType]}...`)}
                  </div>
                </div>
                <ActionButton
                  disabled={isImporting}
                  inset="right"
                  kind="secondary"
                  onClick={() => {
                    fileInput.current?.click();
                  }}
                  startIcon={{ name: 'inboxSearch' }}>
                  {browseButtonText}
                </ActionButton>
              </div>
            )
          }
          <Collapsible collapsed={!uploadError}>
            <div className={classes.collapsible}>
              <InputDescription faultType="error" value={uploadError} />
            </div>
          </Collapsible>
        </div>
        <hr />
        <div>
          <Collapsible collapsed={fileTypeCollapsed}>
            <div className={classes.bottomMargin}>
              <Form.Group horizontal>
                <div className={classes.horizontalLabelCluster}>
                  <Form.Label>File Type</Form.Label>
                  <Form.HelperText>Select the file type for this model.</Form.HelperText>
                </div>
                <div className={classes.input}>
                  <DataSelect
                    asBlock
                    disabled={isImporting}
                    onChange={(selectedFileType) => setFileType(selectedFileType)}
                    options={fileTypeSelectOptions}
                  />
                </div>
              </Form.Group>
              <Collapsible collapsed={fileType?.name !== 'OpenFOAM'}>
                <div className={cx(classes.input, classes.rightInput)}>
                  <Form.MultiCheckBox
                    checkBoxProps={[
                      {
                        checked: loadCellZones,
                        optionText: 'Load cell zones',
                        key: 'load-cell-zones',
                        onChange: (checked) => setLoadCellZones(checked),
                      },
                    ]}
                  />
                </div>
              </Collapsible>
            </div>
          </Collapsible>
          <Collapsible collapsed={!showUnits}>
            <div className={classes.bottomMargin}>
              <Form.Group horizontal>
                <div className={classes.horizontalLabelCluster}>
                  <Form.Label>Units</Form.Label>
                  <Form.HelperText>Select the units for this model.</Form.HelperText>
                </div>
                <div className={classes.input}>
                  <DataSelect
                    asBlock
                    disabled={isImporting || !showUnits}
                    onChange={setMeshUnit}
                    options={units.map((unit) => ({
                      value: unit.name,
                      name: unit.name,
                      selected: unit.name === meshUnit,
                    }))}
                  />
                </div>
              </Form.Group>
            </div>
          </Collapsible>
          <Form.Group className={classes.bottomMargin} horizontal>
            <div className={classes.horizontalLabelCluster}>
              <Form.Label tooltipText={scalingTooltip}>Model Scaling</Form.Label>
              <Form.HelperText>
                A value other than 1 will resize the model.
              </Form.HelperText>
            </div>
            <div className={classes.input}>
              <NumberInput
                disabled={isImporting}
                onChange={handleChangeExtraScaling}
                value={extraScaling}
              />
            </div>
          </Form.Group>
          <Collapsible collapsed={!showForceDiscrete}>
            <Form.Group className={classes.bottomMargin} horizontal>
              <div className={classes.horizontalLabelCluster}>
                <Form.MultiCheckBox
                  checkBoxProps={[
                    {
                      checked: forceDiscrete,
                      key: 'force-discrete',
                      optionText: 'Convert to discrete representation',
                      onChange: (checked) => setForceDiscrete(checked),
                    },
                  ]}
                />
                <Form.HelperText>
                  This allows the use of shrinkwrap.
                </Form.HelperText>
              </div>
            </Form.Group>
          </Collapsible>
          <Collapsible collapsed={!isImporting}>
            <Flex justifyContent="center">
              <Form.Label className={classes.collapsible}>
                Please do not close this window or refresh the page while file is uploading.
              </Form.Label>
            </Flex>
          </Collapsible>
        </div>
      </div>
    </Dialog>
  );
};

export default MeshImportDialog;
