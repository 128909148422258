// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState, waitForAll } from 'recoil';

import { getSessionStorageData } from '../lib/browserStorage';
import { ControlPanelMode } from '../lib/componentTypes/controlPanel';
import { EntityGroup } from '../lib/entityGroupMap';
import { NodeGroup } from '../lib/nodeGroupMap';
import { RecoilProjectKey, sessionStorageEffect } from '../lib/persist';
import {
  GEOMETRY_NODE_ID,
  MESH_NODE_ID,
  OUTPUT_CONTAINER_ID,
  SURFACE_CONTAINER_ID,
  VARIABLE_CONTAINER_ID,
  VOLUME_CONTAINER_ID,
} from '../lib/simulationTree/node';

import { CameraGroupMapAccessType, CameraGroupMapProps, cameraGroupState } from './cameraState';
import { entityGroupState } from './entityGroupState';

export type ProjectModeKey = {
  projectId: string;
  mode: ControlPanelMode;
}

export type ProjectModeCameraKey = CameraGroupMapProps & {
  mode: ControlPanelMode;
}

export type JobModeKey = RecoilProjectKey & {
  mode: ControlPanelMode;
}

export type OpenedRecord = Record<string, boolean>;

// The SimulationTreeNode contains references to its parent, which get stale after re-rendering,
// crashing the app.  So we shouldn't store the node itself; instead, we store the relevant IDs.
export type ClickedNode = {
  id: string;
  parentId?: string;
  index: number;
}

// Generate a unique key for a (mode: ControlPanelMode, property) tuple
function getStorageKey(key: ProjectModeKey, property: string) {
  return `simulation-tree-${key.projectId}-${key.mode}-${property}`;
}

// Nodes that are open by default in the tree. If a node is open, its children are displayed. In
// simulation mode, the geometry, mesh and boundary conditions are initially opened. In exploration
// mode, the inputs and outputs are initially opened.
const defaultOpened: Record<ControlPanelMode, OpenedRecord> = {
  simulation: {
    [GEOMETRY_NODE_ID]: true,
    [SURFACE_CONTAINER_ID]: true,
    [VOLUME_CONTAINER_ID]: true,
    [MESH_NODE_ID]: true,
  },
  exploration: {
    [VARIABLE_CONTAINER_ID]: true,
    [OUTPUT_CONTAINER_ID]: true,
  },
  geometry: {
    [GEOMETRY_NODE_ID]: true,
    [SURFACE_CONTAINER_ID]: true,
    [VOLUME_CONTAINER_ID]: true,
  },
};

export const simulationTreeRowsOpenedState = atomFamily<OpenedRecord, ProjectModeKey>({
  key: 'simulationTreeRowsOpenedState',
  default: (key: ProjectModeKey) => {
    const storageKey = getStorageKey(key, 'opened');
    return getSessionStorageData<OpenedRecord>(storageKey, defaultOpened[key.mode]);
  },
  effects: (key: ProjectModeKey) => [
    sessionStorageEffect(getStorageKey(key, 'opened')),
  ],
});

export function useRowsOpened(projectId: string, mode: ControlPanelMode) {
  return useRecoilState(simulationTreeRowsOpenedState({ projectId, mode }));
}

export function useRowsOpenedValue(projectId: string, mode: ControlPanelMode) {
  return useRecoilValue(simulationTreeRowsOpenedState({ projectId, mode }));
}

export function useSetRowsOpened(projectId: string, mode: ControlPanelMode) {
  return useSetRecoilState(simulationTreeRowsOpenedState({ projectId, mode }));
}

export const lastClickedNodeState = atomFamily<ClickedNode | null, ProjectModeKey>({
  key: 'lastClickedNodeState',
  default: null,
});

export function useLastClickedNode(projectId: string, mode: ControlPanelMode) {
  return useRecoilState(lastClickedNodeState({ projectId, mode }));
}

export function useSetLastClickedNode(projectId: string, mode: ControlPanelMode) {
  return useSetRecoilState(lastClickedNodeState({ projectId, mode }));
}

export const lastClickedCameraState = selectorFamily<NodeGroup | null, ProjectModeCameraKey>({
  key: 'lastClickedCameraState',
  get: (key: ProjectModeCameraKey) => async ({ get }) => {
    const projectId = key.key.projectId;
    const { cameraAccess, mode } = key;
    const [cameraGroupMap, lastClickedNode] = get(waitForAll([
      cameraGroupState({ key: key.key, cameraAccess }),
      lastClickedNodeState({ projectId, mode }),
    ]));
    if (lastClickedNode && cameraGroupMap.has(lastClickedNode.id)) {
      return cameraGroupMap.get(lastClickedNode.id);
    }
    return null;
  },
});

export const lastClickedEntityState = selectorFamily<EntityGroup | null, JobModeKey>({
  key: 'lastClickedEntityState',
  get: (key: JobModeKey) => async ({ get }) => {
    const { projectId, workflowId, jobId, mode } = key;
    const [entityGroupMap, lastClickedNode] = get(waitForAll([
      entityGroupState({ projectId, workflowId, jobId }),
      lastClickedNodeState({ projectId, mode }),
    ]));
    if (lastClickedNode && entityGroupMap.has(lastClickedNode.id)) {
      return entityGroupMap.get(lastClickedNode.id);
    }
    return null;
  },
  dangerouslyAllowMutability: true,
});

export function useLastClickedCamera(
  key: RecoilProjectKey,
  mode: ControlPanelMode,
  cameraAccess: CameraGroupMapAccessType,
) {
  return useRecoilValue(lastClickedCameraState({ key, mode, cameraAccess }));
}

export function useLastClickedEntity(
  projectId: string,
  workflowId: string,
  jobId: string,
  mode: ControlPanelMode,
) {
  return useRecoilValue(lastClickedEntityState({ projectId, workflowId, jobId, mode }));
}
