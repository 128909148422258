// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Level, getIconName } from '../../lib/notificationUtils';
import { getIconDims } from '../../lib/svgIcon/utils';
import { SvgIcon } from '../Icon/SvgIcon';

export interface AlertIconProps {
  level: Level;
  maxHeight?: number;
  maxWidth?: number;
}

function getIconColor(level: Level): string {
  switch (level) {
    case 'error':
    case 'dev-error':
      return 'var(--color-red-500)';
    case 'warning':
      return 'var(--color-yellow-500)';
    case 'success':
      return 'var(--color-green-500)';
    case 'info':
      return 'var(--color-purple-800)';
    default:
      return 'var(--color-neutral-500)';
  }
}

export const AlertIcon = (props: AlertIconProps) => {
  const { level } = props;

  const { maxHeight, maxWidth } = getIconDims(props.maxHeight, props.maxWidth, 12, 12);
  const iconName = getIconName(level);
  const color = getIconColor(level);

  if (iconName) {
    return (
      <SvgIcon color={color} maxHeight={maxHeight} maxWidth={maxWidth} name={iconName} />
    );
  }

  return null;
};
