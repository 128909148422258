import { useCallback, useMemo } from 'react';

import { rollupGroups, unwrapSurfaceIds } from '../../../lib/entityGroupUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import { setSlidingInterfaceSurfaces } from '../../../lib/simulationUtils';
import { findSlidingInterfaceById } from '../../../lib/slidingInterfaceUtils';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { useWorkflowConfig } from '../../../model/hooks/useWorkflowConfig';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';

export const useSlidingInterfaceSelection = ({ sideA }: { sideA: boolean }) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode } = useSelectionContext();

  // == Recoil
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // == Custom hooks
  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const slidingInterface = useMemo(
    () => findSlidingInterfaceById(simParam, selectedNode?.id || ''),
    [selectedNode?.id, simParam],
  );
  const surfaces = sideA ? slidingInterface?.slidingA : slidingInterface?.slidingB;
  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);
  const nodesIdsRollUp = useMemo(() => rollup(surfaces || []), [rollup, surfaces]);

  const setSurfaces = useCallback(async (surfaceIds: string[]) => {
    await saveParamAsync((newParam) => {
      setSlidingInterfaceSurfaces(
        surfaceIds,
        selectedNode?.id || '',
        sideA,
        newParam,
        geometryTags,
        staticVolumes,
        entityGroupData,
        false,
      );
    });
  }, [entityGroupData, geometryTags, saveParamAsync, selectedNode?.id, sideA, staticVolumes]);

  const errorBySurface = useMemo(() => {
    const surfaceIds = [...new Set(staticVolumes.flatMap((volume) => [...volume.bounds]))];

    return Object.fromEntries(surfaceIds.map((surfaceId) => {
      const surfaceError = setSlidingInterfaceSurfaces(
        [surfaceId],
        selectedNode?.id || '',
        sideA,
        simParam,
        geometryTags,
        staticVolumes,
        entityGroupData,
        true,
      );

      return [surfaceId, surfaceError] as const;
    }));
  }, [entityGroupData, geometryTags, selectedNode?.id, sideA, simParam, staticVolumes]);

  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (geometryTags.isTagId(nodeId)) {
      const nFaces = geometryTags.surfacesFromTagEntityGroupId(nodeId)?.length || 0;
      if (nFaces === 0) {
        return { related: true, disabled: true, tooltip: 'No surfaces in this tag' };
      }
    }
    if (nodeType === NodeType.SURFACE) {
      const surfaceError = errorBySurface[nodeId] || '';

      return {
        related: true,
        disabled: !!surfaceError,
        tooltip: surfaceError,
      };
    }

    if (nodeType === NodeType.SURFACE_GROUP) {
      const errors = unwrapSurfaceIds([nodeId], geometryTags, entityGroupData)
        .map((surfaceId) => errorBySurface[surfaceId])
        .filter(Boolean)
        .join(' ');

      return { related: true, disabled: !!errors, tooltip: errors };
    }

    return defaultNodeFilter(nodeType);
  }, [entityGroupData, errorBySurface, geometryTags]);

  return { nodeFilter, setSurfaces, nodesIdsRollUp };
};
