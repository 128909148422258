// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react';

import { IconName, SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { SvgProps } from '../../lib/svg';
import { AirplaneIcon } from '../svg/AirplaneIcon';
import { AirplaneOutlineIcon } from '../svg/AirplaneOutlineIcon';
import { ArrowDownIcon } from '../svg/ArrowDownIcon';
import { ArrowDownLeftIcon } from '../svg/ArrowDownLeftIcon';
import { ArrowDownRightIcon } from '../svg/ArrowDownRightIcon';
import { ArrowLeftIcon } from '../svg/ArrowLeftIcon';
import { ArrowRightIcon } from '../svg/ArrowRightIcon';
import { ArrowUpDownLeftRightIcon } from '../svg/ArrowUpDownLeftRightIcon';
import { ArrowUpIcon } from '../svg/ArrowUpIcon';
import { ArrowUpLeftIcon } from '../svg/ArrowUpLeftIcon';
import { ArrowUpRightIcon } from '../svg/ArrowUpRightIcon';
import { ArrowsOutIcon } from '../svg/ArrowsOutIcon';
import { AtomIcon } from '../svg/AtomIcon';
import { BallAsteriskIcon } from '../svg/BallAsteriskIcon';
import { BarChartIcon } from '../svg/BarChartIcon';
import { BeakerIcon } from '../svg/BeakerIcon';
import { BellOutlinedIcon } from '../svg/BellOutlinedIcon';
import { BellSolidIcon } from '../svg/BellSolidIcon';
import { BillIcon } from '../svg/BillIcon';
import { BisectIcon } from '../svg/BisectIcon';
import { BookmarkIcon } from '../svg/BookmarkIcon';
import { BooksIcon } from '../svg/BooksIcon';
import { BoxSelectCursorIcon } from '../svg/BoxSelectCursorIcon';
import { BoxSelectIcon } from '../svg/BoxSelectIcon';
import { BrowserShieldIcon } from '../svg/BrowserShieldIcon';
import { CameraIcon } from '../svg/CameraIcon';
import { CarIcon } from '../svg/CarIcon';
import { CaretDownIcon } from '../svg/CaretDownIcon';
import { CaretLeftIcon } from '../svg/CaretLeftIcon';
import { CaretRightIcon } from '../svg/CaretRightIcon';
import { ChartArrowRightIcon } from '../svg/ChartArrowRightIcon';
import { ChartLineIcon } from '../svg/ChartLineIcon';
import { CheckBulletIcon } from '../svg/CheckBullet';
import { CheckmarkIcon } from '../svg/CheckmarkIcon';
import { ChevronDownIcon } from '../svg/ChevronDownIcon';
import { ChevronLeftIcon } from '../svg/ChevronLeftIcon';
import { ChevronRightIcon } from '../svg/ChevronRightIcon';
import { ChevronUpIcon } from '../svg/ChevronUpIcon';
import { CircleIcon } from '../svg/CircleIcon';
import { ClientSideIcon } from '../svg/ClientSideIcon';
import { ClipboardCheckIcon } from '../svg/ClipboardCheckIcon';
import { ClockIcon } from '../svg/ClockIcon';
import { ClockResetIcon } from '../svg/ClockResetIcon';
import { ClockWedgeIcon } from '../svg/ClockWedgeIcon';
import { CloudDownloadIcon } from '../svg/CloudDownloadIcon';
import { CloudUploadIcon } from '../svg/CloudUploadIcon';
import { CodeIcon } from '../svg/CodeIcon';
import { CommentsIcon } from '../svg/CommentsIcon';
import { CompassIcon } from '../svg/CompassIcon';
import { ConeIcon } from '../svg/ConeIcon';
import { ContourIcon } from '../svg/ContourIcon';
import { CopyIcon } from '../svg/CopyIcon';
import { CrownIcon } from '../svg/CrownIcon';
import { CubeDashIcon } from '../svg/CubeDashIcon';
import { CubeFacesOutlineIcon } from '../svg/CubeFacesOutlineIcon';
import { CubeOutlineIcon } from '../svg/CubeOutlineIcon';
import { CubeOutlineInvertedCircleIcon } from '../svg/CubeOutlineInvertedCircleIcon';
import { CubeOutlineStarIcon } from '../svg/CubeOutlineStarIcon';
import { CubePlusIcon } from '../svg/CubePlusIcon';
import { CubeSolidIcon } from '../svg/CubeSolidIcon';
import { CubeSparkleIcon } from '../svg/CubeSparkleIcon';
import { CylinderIcon } from '../svg/CylinderIcon';
import { DashIcon } from '../svg/DashIcon';
import { DashedBoxIcon } from '../svg/DashedBoxIcon';
import { DesktopMonitorFrameIcon } from '../svg/DesktopMonitorFrameIcon';
import { DesktopMonitorIcon } from '../svg/DesktopMonitorIcon';
import { DiskArrowUpIcon } from '../svg/DiskArrowUpIcon';
import { DiskArrowUpRightIcon } from '../svg/DiskArrowUpRightIcon';
import { DiskCheckIcon } from '../svg/DiskCheckIcon';
import { DiskExclamationIcon } from '../svg/DiskExclamationIcon';
import { DiskInfoIcon } from '../svg/DiskInfoIcon';
import { DiskLightningIcon } from '../svg/DiskLightningIcon';
import { DiskQuestionIcon } from '../svg/DiskQuestionIcon';
import { DocumentClippedIcon } from '../svg/DocumentClippedIcon';
import { DocumentIcon } from '../svg/DocumentIcon';
import { DottedCircleIcon } from '../svg/DottedCircleIcon';
import { DoubleArrowLeftRightIcon } from '../svg/DoubleArrowLeftRightIcon';
import { DoubleArrowUpDownIcon } from '../svg/DoubleArrowUpDownIcon';
import { DoubleUniqueFramesIcon } from '../svg/DoubleUniqueFramesIcon';
import { DownloadIcon } from '../svg/DownloadIcon';
import { DuplicateIcon } from '../svg/DuplicateIcon';
import { EnvelopeIcon } from '../svg/EnvelopeIcon';
import { EyeCheckIcon } from '../svg/EyeCheckIcon';
import { EyeOffIcon } from '../svg/EyeOffIcon';
import { EyeOnIcon } from '../svg/EyeOnIcon';
import { FarfieldIcon } from '../svg/FarfieldIcon';
import { FileArrowUpRightIcon } from '../svg/FileArrowUpRightIcon';
import { FileBoxIcon } from '../svg/FileBoxIcon';
import { FileDrawerIcon } from '../svg/FileDrawerIcon';
import { FilterIcon } from '../svg/FilterIcon';
import { FlagIcon } from '../svg/FlagIcon';
import { FolderIcon } from '../svg/FolderIcon';
import { FunctionIcon } from '../svg/FunctionIcon';
import { GearIcon } from '../svg/GearIcon';
import { GeometryBooleanIcon } from '../svg/GeometryBooleanIcon';
import { GeometryIntersectIcon } from '../svg/GeometryIntersectIcon';
import { GeometryScaleIcon } from '../svg/GeometryScaleIcon';
import { GeometrySubtractIcon } from '../svg/GeometrySubtractIcon';
import { GeometryUnionIcon } from '../svg/GeometryUnionIcon';
import { GeometryXorIcon } from '../svg/GeometryXorIcon';
import { GhostIcon } from '../svg/GhostIcon';
import { GlobeIcon } from '../svg/GlobeIcon';
import { GridBlocksIcon } from '../svg/GridBlocksIcon';
import { GridFourDots } from '../svg/GridFourDots';
import { GridIcon } from '../svg/GridIcon';
import { GridOffIcon } from '../svg/GridOffIcon';
import { GridOnIcon } from '../svg/GridOnIcon';
import { GridQuadOutlinedIcon } from '../svg/GridQuadOutlinedIcon';
import { GridQuadSolidIcon } from '../svg/GridQuadSolidIcon';
import { GridUnevenIcon } from '../svg/GridUnevenIcon';
import { GroupActionIcon } from '../svg/GroupActionIcon';
import { GroupIcon } from '../svg/GroupIcon';
import { HalfSphereIcon } from '../svg/HalfSphereIcon';
import { HashIcon } from '../svg/HashIcon';
import { HeadphonesIcon } from '../svg/HeadphonesIcon';
import { HorizontalCirclesTripleIcon } from '../svg/HorizontalCirclesTripleIcon';
import { HorizontalLinesQuadrupleIcon } from '../svg/HorizontalLinesQuadrupleIcon';
import { HorizontalWavesTripleIcon } from '../svg/HorizontalWavesTripleIcon';
import { InboxSearchIcon } from '../svg/InboxSearchIcon';
import { IntersectingDisksIcon } from '../svg/IntersectingDisksIcon';
import { IntersectingPlanesIcon } from '../svg/IntersectingPlanesIcon';
import { IntersectingRingsIcon } from '../svg/IntersectingRingsIcon';
import { LayersIcon } from '../svg/LayersIcon';
import { LcLogoGraphOnlyIcon } from '../svg/LcLogoGraphOnlyIcon';
import { LcLogoIcon } from '../svg/LcLogoIcon';
import { LineSegmentIcon } from '../svg/LineSegmentIcon';
import { LinkIcon } from '../svg/LinkIcon';
import { ListBulletedIcon } from '../svg/ListBulletedIcon';
import { ListNumberedIcon } from '../svg/ListNumberedIcon';
import { LogoutIcon } from '../svg/LogoutIcon';
import { MirroredTriangles } from '../svg/MirroredTriangles';
import { NullIcon } from '../svg/NullIcon';
import { OpenIcon } from '../svg/OpenIcon';
import { PaperAirplaneIcon } from '../svg/PaperAirplaneIcon';
import { PaperIcon } from '../svg/PaperIcon';
import { ParallelogramOutlinedIcon } from '../svg/ParallelogramOutlinedIcon';
import { PatternCircularIcon } from '../svg/PatternCircularIcon';
import { PatternLinearIcon } from '../svg/PatternLinearIcon';
import { PauseIcon } from '../svg/PauseIcon';
import { PdfIcon } from '../svg/PdfIcon';
import { PencilIcon } from '../svg/PencilIcon';
import { PencilLinesIcon } from '../svg/PencilLinesIcon';
import { PeopleIcon } from '../svg/PeopleIcon';
import { PersonIcon } from '../svg/PersonIcon';
import { PlayIcon } from '../svg/PlayIcon';
import { PlayIconOutline } from '../svg/PlayIconOutline';
import { PlusIcon } from '../svg/PlusIcon';
import { PointerBoxIcon } from '../svg/PointerBoxIcon';
import { PointerCubeOutlinedIcon } from '../svg/PointerCubeOutlinedIcon';
import { PointerOutlinedBoxDashedIcon } from '../svg/PointerOutlinedBoxDashedIcon';
import { PointerOutlinedTargetIcon } from '../svg/PointerOutlinedTargetIcon';
import { PointerSparkleIcon } from '../svg/PointerSparkleIcon';
import { ProbeIcon } from '../svg/ProbeIcon';
import { RectanglePoppedInIcon } from '../svg/RectanglePoppedIn';
import { RectanglePoppedOutIcon } from '../svg/RectanglePoppedOut';
import { RedoIcon } from '../svg/RedoIcon';
import { RefreshIcon } from '../svg/RefreshIcon';
import { ResetIcon } from '../svg/ResetIcon';
import { RingCheckIcon } from '../svg/RingCheckIcon';
import { RingCircleIcon } from '../svg/RingCircleIcon';
import { RingDashIcon } from '../svg/RingDashIcon';
import { RingExclamationIcon } from '../svg/RingExclamationIcon';
import { RingOfCirclesIcon } from '../svg/RingOfCirclesIcon';
import { RingPlusIcon } from '../svg/RingPlusIcon';
import { RingXIcon } from '../svg/RingXIcon';
import { RotateIcon } from '../svg/RotateIcon';
import { RotatingDotsIcon } from '../svg/RotatingDotsIcon';
import { RulerIcon } from '../svg/RulerIcon';
import { ScalpelIcon } from '../svg/ScalpelIcon';
import { ScissorsIcon } from '../svg/ScissorsIcon';
import { ScreenshotIcon } from '../svg/ScreenshotIcon';
import { SearchIcon } from '../svg/SearchIcon';
import { ServerSideIcon } from '../svg/ServerSideIcon';
import { ShareIcon } from '../svg/ShareIcon';
import { ShieldIcon } from '../svg/ShieldIcon';
import { SortAscIcon } from '../svg/SortAscIcon';
import { SortAzIcon } from '../svg/SortAzIcon';
import { SortDescIcon } from '../svg/SortDescIcon';
import { SortZaIcon } from '../svg/SortZaIcon';
import { SparkleDoubleIcon } from '../svg/SparkleDoubleIcon';
import { SparkleIcon } from '../svg/SparkleIcon';
import { SphereIcon } from '../svg/SphereIcon';
import { SphereWithLines } from '../svg/SphereWithLines';
import { StarFilledIcon } from '../svg/StarFilledIcon';
import { StarOutlinedIcon } from '../svg/StarOutlinedIcon';
import { StepBackwardIcon } from '../svg/StepBackwardIcon';
import { StepForwardIcon } from '../svg/StepForwardIcon';
import { StopIcon } from '../svg/StopIcon';
import { StopIconOutline } from '../svg/StopIconOutline';
import { StreamArrowsIcon } from '../svg/StreamArrowsIcon';
import { StreamVorticesIcon } from '../svg/StreamVorticesIcon';
import { SuccessWithWarningIcon } from '../svg/SuccessWithWarning';
import { SurfaceLICIcon } from '../svg/SurfaceLICIcon';
import { SwapAxesIcon } from '../svg/SwapAxesIcon';
import { TableOutlinedIcon } from '../svg/TableOutlinedIcon';
import { TagIcon } from '../svg/TagIcon';
import { TargetIcon } from '../svg/TargetIcon';
import { ThermometerIcon } from '../svg/ThermometerIcon';
import { ThumbsDownIcon } from '../svg/ThumbsDownIcon';
import { ThumbsUpIcon } from '../svg/ThumbsUpIcon';
import { TransparencyIcon } from '../svg/TransparencyIcon';
import { TrashIcon } from '../svg/TrashIcon';
import { TreeIcon } from '../svg/TreeIcon';
import { TriAxesIcon } from '../svg/TriAxesIcon';
import { DotsTwoColumns } from '../svg/TwoColumnDots';
import { UndoIcon } from '../svg/UndoIcon';
import { UngroupActionIcon } from '../svg/UngroupActionIcon';
import { VerticalControlsIcon } from '../svg/VerticalControlsIcon';
import { VerticalPlanesFilledDoubleIcon } from '../svg/VerticalPlanesFilledDouble';
import { VerticalPlanesFilledOutlinedIcon } from '../svg/VerticalPlanesFilledOutlined';
import { VerticalPlanesFilledTripleIcon } from '../svg/VerticalPlanesFilledTriple';
import { VerticalWavesIcon } from '../svg/VerticalWavesIcon';
import { VideoCameraIcon } from '../svg/VideoCameraIcon';
import { WandIcon } from '../svg/WandIcon';
import { WarningIcon } from '../svg/WarningIcon';
import { WarningTriangleIcon } from '../svg/WarningTriangleIcon';
import { WavesQuadrupleIcon } from '../svg/WavesQuadrupleIcon';
import { WavesTripleIcon } from '../svg/WavesTripleIcon';
import { WireframeIcon } from '../svg/WireframeIcon';
import { WireframeInvertedIcon } from '../svg/WireframeInvertedIcon';
import { XIcon } from '../svg/XIcon';
import { ShrinkwrapIcon } from '../svg/shrinkwrapIcon';

type IconMap = Record<IconName, ForwardRefExoticComponent<SvgProps & RefAttributes<SVGSVGElement>>>;
export const iconMap: IconMap = {
  airplane: AirplaneIcon,
  airplaneOutline: AirplaneOutlineIcon,
  arrowDown: ArrowDownIcon,
  arrowDownLeft: ArrowDownLeftIcon,
  arrowDownRight: ArrowDownRightIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowsOut: ArrowsOutIcon,
  arrowUp: ArrowUpIcon,
  arrowUpDownLeftRight: ArrowUpDownLeftRightIcon,
  arrowUpLeft: ArrowUpLeftIcon,
  compassIcon: CompassIcon,
  arrowUpRight: ArrowUpRightIcon,
  atom: AtomIcon,
  ballAsterisk: BallAsteriskIcon,
  barChart: BarChartIcon,
  beaker: BeakerIcon,
  bellOutlined: BellOutlinedIcon,
  bellSolid: BellSolidIcon,
  bill: BillIcon,
  bisect: BisectIcon,
  bookmark: BookmarkIcon,
  books: BooksIcon,
  boxSelect: BoxSelectIcon,
  boxSelectCursorIcon: BoxSelectCursorIcon,
  browserShield: BrowserShieldIcon,
  camera: CameraIcon,
  car: CarIcon,
  caretDown: CaretDownIcon,
  caretLeft: CaretLeftIcon,
  caretRight: CaretRightIcon,
  chart: ChartLineIcon,
  chartArrowRight: ChartArrowRightIcon,
  chartLine: ChartLineIcon,
  checkBullet: CheckBulletIcon,
  checkmark: CheckmarkIcon,
  chevronDown: ChevronDownIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  circle: CircleIcon,
  clientSide: ClientSideIcon,
  clipboardCheck: ClipboardCheckIcon,
  clock: ClockIcon,
  clockWedge: ClockWedgeIcon,
  clockReset: ClockResetIcon,
  cloudDownload: CloudDownloadIcon,
  cloudUpload: CloudUploadIcon,
  code: CodeIcon,
  comments: CommentsIcon,
  cone: ConeIcon,
  contour: ContourIcon,
  copy: CopyIcon,
  crown: CrownIcon,
  cubeDash: CubeDashIcon,
  cubeFacesOutline: CubeFacesOutlineIcon,
  cubePlus: CubePlusIcon,
  cubeOutline: CubeOutlineIcon,
  cubeOutlineInvertedCircle: CubeOutlineInvertedCircleIcon,
  cubeOutlineStar: CubeOutlineStarIcon,
  cubeSolid: CubeSolidIcon,
  cubeSparkle: CubeSparkleIcon,
  cylinder: CylinderIcon,
  dash: DashIcon,
  dashedBox: DashedBoxIcon,
  desktopMonitor: DesktopMonitorIcon,
  desktopMonitorFramed: DesktopMonitorFrameIcon,
  diskArrowUp: DiskArrowUpIcon,
  diskArrowUpRight: DiskArrowUpRightIcon,
  diskCheck: DiskCheckIcon,
  diskExclamation: DiskExclamationIcon,
  diskInfo: DiskInfoIcon,
  diskLightning: DiskLightningIcon,
  diskQuestion: DiskQuestionIcon,
  document: DocumentIcon,
  documentPdf: PdfIcon,
  documentClipped: DocumentClippedIcon,
  dottedCircle: DottedCircleIcon,
  download: DownloadIcon,
  doubleUniqueFrames: DoubleUniqueFramesIcon,
  doubleArrowLeftRight: DoubleArrowLeftRightIcon,
  doubleArrowUpDown: DoubleArrowUpDownIcon,
  dotsTwoColumns: DotsTwoColumns,
  duplicate: DuplicateIcon,
  envelope: EnvelopeIcon,
  eyeCheck: EyeCheckIcon,
  eyeOff: EyeOffIcon,
  eyeOn: EyeOnIcon,
  farfield: FarfieldIcon,
  fileBox: FileBoxIcon,
  fileDrawer: FileDrawerIcon,
  filter: FilterIcon,
  flag: FlagIcon,
  folder: FolderIcon,
  folderArrowUpRight: FileArrowUpRightIcon,
  function: FunctionIcon,
  gear: GearIcon,
  geometryBoolean: GeometryBooleanIcon,
  geometryIntersect: GeometryIntersectIcon,
  geometryScale: GeometryScaleIcon,
  geometrySubtract: GeometrySubtractIcon,
  geometryUnion: GeometryUnionIcon,
  geometryXor: GeometryXorIcon,
  ghost: GhostIcon,
  globe: GlobeIcon,
  grid: GridIcon,
  gridFourDots: GridFourDots,
  gridQuadOutlined: GridQuadOutlinedIcon,
  gridUneven: GridUnevenIcon,
  gridBlocks: GridBlocksIcon,
  gridOff: GridOffIcon,
  gridOn: GridOnIcon,
  gridQuadSolid: GridQuadSolidIcon,
  group: GroupIcon,
  groupAction: GroupActionIcon,
  halfSphere: HalfSphereIcon,
  hash: HashIcon,
  headphones: HeadphonesIcon,
  horizontalCirclesTriple: HorizontalCirclesTripleIcon,
  horizontalLinesQuadruple: HorizontalLinesQuadrupleIcon,
  horizontalWavesTriple: HorizontalWavesTripleIcon,
  inboxSearch: InboxSearchIcon,
  intersectingDisks: IntersectingDisksIcon,
  intersectingPlanes: IntersectingPlanesIcon,
  intersectingRings: IntersectingRingsIcon,
  layers: LayersIcon,
  lcLogo: LcLogoIcon,
  lcLogoGraphOnly: LcLogoGraphOnlyIcon,
  lineSegment: LineSegmentIcon,
  link: LinkIcon,
  listBulleted: ListBulletedIcon,
  listNumbered: ListNumberedIcon,
  logout: LogoutIcon,
  mirroredTriangles: MirroredTriangles,
  null: NullIcon,
  open: OpenIcon,
  paper: PaperIcon,
  paperAirplane: PaperAirplaneIcon,
  parallelogramOutline: ParallelogramOutlinedIcon,
  patternCircular: PatternCircularIcon,
  patternLinear: PatternLinearIcon,
  pause: PauseIcon,
  pdf: PdfIcon,
  pencil: PencilIcon,
  pencilLines: PencilLinesIcon,
  people: PeopleIcon,
  person: PersonIcon,
  play: PlayIcon,
  playOutline: PlayIconOutline,
  plus: PlusIcon,
  pointerBox: PointerBoxIcon,
  pointerCubeOutlined: PointerCubeOutlinedIcon,
  pointerOutlinedBoxDashed: PointerOutlinedBoxDashedIcon,
  pointerSparkle: PointerSparkleIcon,
  pointerOutlinedTarget: PointerOutlinedTargetIcon,
  probe: ProbeIcon,
  rectanglePoppedIn: RectanglePoppedInIcon,
  rectanglePoppedOut: RectanglePoppedOutIcon,
  redo: RedoIcon,
  refresh: RefreshIcon,
  reset: ResetIcon,
  ringCheck: RingCheckIcon,
  ringCircle: RingCircleIcon,
  ringDash: RingDashIcon,
  ringExclamation: RingExclamationIcon,
  ringOfCircles: RingOfCirclesIcon,
  ringPlus: RingPlusIcon,
  ringX: RingXIcon,
  rotate: RotateIcon,
  rotatingDots: RotatingDotsIcon,
  ruler: RulerIcon,
  scalpel: ScalpelIcon,
  scissors: ScissorsIcon,
  screenshot: ScreenshotIcon,
  search: SearchIcon,
  serverSide: ServerSideIcon,
  share: ShareIcon,
  shield: ShieldIcon,
  shrinkwrap: ShrinkwrapIcon,
  sortAsc: SortAscIcon,
  sortAz: SortAzIcon,
  sortDesc: SortDescIcon,
  sortZa: SortZaIcon,
  sparkle: SparkleIcon,
  sparkleDouble: SparkleDoubleIcon,
  sphere: SphereIcon,
  sphereWithLines: SphereWithLines,
  starOutlined: StarOutlinedIcon,
  starFilled: StarFilledIcon,
  stepBackward: StepBackwardIcon,
  stepForward: StepForwardIcon,
  stop: StopIcon,
  stopOutline: StopIconOutline,
  streamArrows: StreamArrowsIcon,
  streamVortices: StreamVorticesIcon,
  successWithWarning: SuccessWithWarningIcon,
  surfaceLIC: SurfaceLICIcon,
  swapAxes: SwapAxesIcon,
  tableOutlined: TableOutlinedIcon,
  tag: TagIcon,
  target: TargetIcon,
  thermometer: ThermometerIcon,
  thumbsDown: ThumbsDownIcon,
  thumbsUp: ThumbsUpIcon,
  transparency: TransparencyIcon,
  trash: TrashIcon,
  tree: TreeIcon,
  triAxes: TriAxesIcon,
  undo: UndoIcon,
  ungroupAction: UngroupActionIcon,
  verticalControls: VerticalControlsIcon,
  verticalPlanesFilledDouble: VerticalPlanesFilledDoubleIcon,
  verticalPlanesFilledOutlined: VerticalPlanesFilledOutlinedIcon,
  verticalPlanesFilledTriple: VerticalPlanesFilledTripleIcon,
  verticalWaves: VerticalWavesIcon,
  videoCamera: VideoCameraIcon,
  wand: WandIcon,
  warning: WarningIcon,
  warningTriangle: WarningTriangleIcon,
  wavesQuadruple: WavesQuadrupleIcon,
  wavesTriple: WavesTripleIcon,
  wireframe: WireframeIcon,
  wireframeInverted: WireframeInvertedIcon,
  x: XIcon,
};

export interface SvgIconProps extends SvgIconSpec {
}

/**
 * Use SvgIcon to render pre-defined icons, specifying scaling and color via
 * props.
 */
export const SvgIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  const { name, ...svgProps } = props;

  const SvgComponentName = iconMap[name];
  return (
    <SvgComponentName {...svgProps} ref={ref} />
  );
});

export interface LuminaryLogoProps {
  maxHeight?: number;
  maxWidth?: number;
  color?: string;
  graphOnly?: boolean;
}
