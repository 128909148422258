const FAILING_FILTERS_INTRO = 'Failing filters:';

export function getFailedFilters(errorMessage: string) {
  if (!errorMessage.includes(FAILING_FILTERS_INTRO)) {
    return [];
  }

  const failingFiltersLine = errorMessage.split('\n').find(
    (line) => line.includes(FAILING_FILTERS_INTRO),
  )!;

  const failingFiltersStartColumn = failingFiltersLine.indexOf(FAILING_FILTERS_INTRO);
  const failingFiltersString = failingFiltersLine.substring(
    failingFiltersStartColumn + FAILING_FILTERS_INTRO.length,
  );

  const failingFilters = failingFiltersString.split(',').map((item) => item.trim()).filter(Boolean);

  // Failing filters are listed in the format "type_ID".
  // We need to exclude the "type" prefix before returning the result.
  return failingFilters.map((failingFilter) => failingFilter.split('_').slice(1).join('_'));
}
