// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import * as flags from '../../../flags';
import { CurrentView, getNextPath } from '../../../lib/componentTypes/context';
import { useGeometryList } from '../../../recoil/geometry/geometryListState';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import { useCurrentView, useIsGeometryView } from '../../../state/internal/global/currentView';
import { ActionButton } from '../../Button/ActionButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useIsRunSimulationDisabled } from '../../hooks/useRunSimulation';
import { useLoadToSetupDisabled } from '../../project/ModificationTreePanel';
import { ArrowRightIcon } from '../../svg/ArrowRightIcon';
import { Flex } from '../../visual/Flex';

const useIsIgeoProject = () => {
  const { projectId } = useProjectContext();
  const geoModEnabled = useIsEnabled(flags.geoModifications);
  const geometryList = useGeometryList(projectId);
  return geoModEnabled && !!geometryList?.geometries.length;
};

export const GoToNextStepButton = () => {
  // == Context
  const { projectId } = useProjectContext();

  // == Hooks
  const navigate = useNavigate();

  // == State
  const currentView = useCurrentView();
  const isGeometryView = useIsGeometryView();
  const isIgeoProject = useIsIgeoProject();
  const { disabled: isLoadToSetupDisabled } = useLoadToSetupDisabled();
  const isRunSimulationDisabled = useIsRunSimulationDisabled();

  const goToNextStage = useCallback(() => {
    const nextPath = getNextPath(projectId, currentView, isIgeoProject);
    if (nextPath) {
      navigate(nextPath);
    }
  }, [currentView, isIgeoProject, navigate, projectId]);

  const buttonKind = useMemo(() => {
    if ((isGeometryView && isLoadToSetupDisabled) || (!isGeometryView && isRunSimulationDisabled)) {
      return 'primary';
    }
    return 'cancel';
  }, [isGeometryView, isLoadToSetupDisabled, isRunSimulationDisabled]);

  const buttonTitle = useMemo(() => {
    switch (currentView) {
      case CurrentView.GEOMETRY:
        return 'Physics';
      case CurrentView.PHYSICS:
        return isIgeoProject ? 'Mesh' : 'Outputs';
      case CurrentView.MESH:
        return 'Outputs';
      case CurrentView.OUTPUTS:
        return 'Solver';
      default:
        return '';
    }
  }, [currentView, isIgeoProject]);

  return (
    <ActionButton
      asBlock
      kind={buttonKind}
      onClick={goToNextStage}
      size="medium"
      title="Proceed to the next stage in setup. You can always come back to this view later.">
      <Flex alignItems="center" gap={8} justifyContent="center" minWidth={75}>
        {buttonTitle}
        <ArrowRightIcon maxHeight={9} />
      </Flex>
    </ActionButton>
  );
};
