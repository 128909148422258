// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo, useState } from 'react';

import assert from '../../../lib/assert';
import {
  FORCE_DISTRIBUTION_DEFAULT_NBINS,
  FORCE_DISTRIBUTION_MAXIMUM_NBINS,
} from '../../../lib/constants';
import { expandGroupsExcludingTags, rollupGroups } from '../../../lib/entityGroupUtils';
import { useNodePanel } from '../../../lib/useNodePanel';
import * as plotpb from '../../../proto/plots/plots_pb';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useCurrentlySelectedPlot, usePlotNodes } from '../../../recoil/plotNodes';
import { DataSelect } from '../../Form/DataSelect';
import LabeledInput from '../../Form/LabeledInput';
import { NumberInput } from '../../Form/NumberInput';
import { PositiveAbsoluteIntegerInput } from '../../Form/PositiveAbsoluteIntegerInput';
import { CollapsiblePanel } from '../../Panel/CollapsiblePanel';
import Divider from '../../Theme/Divider';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useVisualizationSurfacesSubselect } from '../../hooks/subselect/useVisualizationSurfaces';
import { NodeSubselect } from '../NodeSubselect';
import PropertiesSection from '../PropertiesSection';

export const ForceDistributionPropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected plot row');

  // == Recoil
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const [thisPlotNode, setThisPlotNode] = useCurrentlySelectedPlot();
  const distributionPanel = useNodePanel(node.id, 'distributionPanel');
  const forcePanel = useNodePanel(node.id, 'forcePanel');
  const geometryPanel = useNodePanel(node.id, 'geometryPanel');
  const [plotState, setPlotState] = usePlotNodes(projectId);
  const { nodeFilter } =
    useVisualizationSurfacesSubselect('force-distribution-surfaces', geometryTags);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // == Data
  const originalXYPlot = thisPlotNode.plot.case === 'forceDistribution' ?
    thisPlotNode.plot.value : undefined;
  const thisXYPlot = originalXYPlot?.clone();
  const [lastSelectedPlotId, setLastSelectedPlotId] = useState('');

  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);
  const rollupNodeIds = useMemo(() => rollup(thisXYPlot?.bounds || []), [rollup, thisXYPlot]);

  useEffect(() => {
    const selectedPlotNode = plotState.plots.find((plot) => plot.id === node?.id);
    if (selectedPlotNode && selectedPlotNode !== thisPlotNode) {
      setThisPlotNode(selectedPlotNode);
    }
  }, [plotState, node, thisPlotNode, setThisPlotNode]);

  const updatePlot = () => {
    const newPlotNode = thisPlotNode.clone();
    if (thisXYPlot) {
      newPlotNode.plot = { case: 'forceDistribution', value: thisXYPlot };
    }
    setThisPlotNode(newPlotNode);

    // We want to insert the updated plot in the list at the same index it was at before
    const newPlotState = plotState.clone();
    newPlotState.plots = newPlotState.plots.map((plot) => {
      if (plot.id === newPlotNode.id) {
        return newPlotNode;
      }
      return plot;
    });
    setPlotState(newPlotState);
  };

  // If thisPlotNode has a different id than lastSelectedPlotId, we've selected a different plot,
  // as opposed to having updated the currently selected plot. In that case, we reset the quantity
  // and range defaults.
  if (thisPlotNode.id !== lastSelectedPlotId) {
    setLastSelectedPlotId(thisPlotNode.id);
    updatePlot();
  }

  const DirectionType = [
    {
      name: 'X Axis',
      value: plotpb.XAxisOptions.X_COORD,
      selected: thisXYPlot?.axis === plotpb.XAxisOptions.X_COORD,
    },
    {
      name: 'Y Axis',
      value: plotpb.XAxisOptions.Y_COORD,
      selected: thisXYPlot?.axis === plotpb.XAxisOptions.Y_COORD,
    },
    {
      name: 'Z Axis',
      value: plotpb.XAxisOptions.Z_COORD,
      selected: thisXYPlot?.axis === plotpb.XAxisOptions.Z_COORD,
    },
  ];

  const DistributionType = [
    {
      name: 'Local',
      tooltip: 'Force per unit length at each location.',
      value: plotpb.ForceDistributionOptions.LOCAL,
      selected: thisXYPlot?.distributionType === plotpb.ForceDistributionOptions.LOCAL,
    },
    {
      name: 'Cumulative',
      tooltip: 'Total force from 0 up to each location.',
      value: plotpb.ForceDistributionOptions.CUMULATIVE,
      selected: thisXYPlot?.distributionType === plotpb.ForceDistributionOptions.CUMULATIVE,
    },
  ];

  const ForceDirectionType = [
    {
      name: 'Custom',
      tooltip: 'Force is computed in the specified direction.',
      value: plotpb.ForceDirectionOptions.VECTOR,
      selected: thisXYPlot?.forceDirType === plotpb.ForceDirectionOptions.VECTOR,
    },
  ];

  // The properties panel for XY plots
  const xyPlot = (
    <div>
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={distributionPanel.collapsed}
          heading="Distribution"
          onToggle={distributionPanel.toggle}>
          <LabeledInput
            label="Direction">
            <DataSelect
              asBlock
              onChange={(value) => {
                if (thisXYPlot) {
                  thisXYPlot.axis = value;
                }
                updatePlot();
              }}
              options={DirectionType}
              size="small"
            />
          </LabeledInput>
          <LabeledInput
            label="Type">
            <DataSelect
              asBlock
              onChange={(value) => {
                if (thisXYPlot) {
                  thisXYPlot.distributionType = value;
                }
                updatePlot();
              }}
              options={DistributionType}
              size="small"
            />
          </LabeledInput>
          <LabeledInput
            help="Number of equally-spaced slices used to compute the distribution."
            label="Resolution">
            <PositiveAbsoluteIntegerInput
              asBlock
              onCommit={(value) => {
                if (thisXYPlot) {
                  thisXYPlot.nBins = value > FORCE_DISTRIBUTION_MAXIMUM_NBINS ?
                    FORCE_DISTRIBUTION_MAXIMUM_NBINS :
                    value;
                }
                updatePlot();
              }}
              size="small"
              // Default the number of bins for existing plots.
              value={thisXYPlot?.nBins ? thisXYPlot.nBins : FORCE_DISTRIBUTION_DEFAULT_NBINS}
            />
          </LabeledInput>
        </CollapsiblePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={forcePanel.collapsed}
          heading="Force"
          onToggle={forcePanel.toggle}>
          <LabeledInput
            label="Direction">
            <DataSelect
              asBlock
              onChange={(value) => {
                if (thisXYPlot) {
                  thisXYPlot.forceDirType = value;
                }
                updatePlot();
              }}
              options={ForceDirectionType}
              size="small"
            />
          </LabeledInput>
          <LabeledInput
            label="X">
            <NumberInput
              asBlock
              onCommit={(value: number) => {
                if (thisXYPlot) {
                  thisXYPlot.forceDirX = value;
                }
                updatePlot();
              }}
              size="small"
              value={thisXYPlot ? thisXYPlot.forceDirX : 0}
            />
          </LabeledInput>
          <LabeledInput
            label="Y">
            <NumberInput
              asBlock
              onCommit={(value: number) => {
                if (thisXYPlot) {
                  thisXYPlot.forceDirY = value;
                }
                updatePlot();
              }}
              size="small"
              value={thisXYPlot ? thisXYPlot.forceDirY : 0}
            />
          </LabeledInput>
          <LabeledInput
            label="Z">
            <NumberInput
              asBlock
              onCommit={(value: number) => {
                if (thisXYPlot) {
                  thisXYPlot.forceDirZ = value;
                }
                updatePlot();
              }}
              size="small"
              value={thisXYPlot ? thisXYPlot.forceDirZ : 0}
            />
          </LabeledInput>
        </CollapsiblePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={geometryPanel.collapsed}
          heading="Geometry"
          onToggle={geometryPanel.toggle}>
          <NodeSubselect
            id="force-distribution-surfaces"
            independentSelection
            labels={['surfaces']}
            nodeFilter={nodeFilter}
            nodeIds={rollupNodeIds}
            onChange={(surfaces) => {
              if (thisXYPlot) {
                thisXYPlot.bounds = expandGroupsExcludingTags(entityGroupData, surfaces);
              }
              updatePlot();
            }}
            readOnly={thisXYPlot === undefined}
            referenceNodeIds={[node?.id || '']}
            title="Surfaces"
          />
        </CollapsiblePanel>
      </PropertiesSection>
    </div>
  );
  return xyPlot;
};
