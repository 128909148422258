import { LCVSpriteSetDrawMode, LCVSpriteSetTexture, LCVType } from '@luminarycloudinternal/lcvis';

import { Vector3 } from '../../../../ProtoDescriptor';
import { Level } from '../../../../proto/lcstatus/levels_pb';
import { CoordinatesIssue, VisualizerError } from '../../../../recoil/useSelectedVisualizerError';
import { Logger } from '../../../observability/logs';
import { LcvIdPair, LcvModule } from '../../types';
import { LcvObjectList } from '../base/LcvObjectList';

import { LCStatus } from '@/proto/lcstatus/lcstatus_pb';

const WARNING_SPRITE = LCVSpriteSetTexture.kLCVSpriteSetTextureWarningThinLine;
const ERROR_SPRITE = LCVSpriteSetTexture.kLCVSpriteSetTextureDangerThinLine;
const SPRITE_DRAW_MODE = LCVSpriteSetDrawMode.kLCVSpriteSetDrawModeTextured;
const ICON_SIZE = 64;

const logger = new Logger('LcvErrorList');

type ClickCallback = ((data: VisualizerError | null) => void);

export class LcvErrorList extends LcvObjectList {
  private lastRenderedData: [Vector3, CoordinatesIssue[]][] = [];
  private clickCallback: ClickCallback | null = null;

  constructor(lcv: LcvModule, sessionHandle: number, size: number) {
    super(
      lcv,
      lcv.newAnnotation(sessionHandle, 'sprite_set', 0).annotation,
      sessionHandle,
      size,
    );

    this.setParam('enable_depth_testing', LCVType.kLCVDataTypeUint, 0);
    this.setParam('draw_mode', LCVType.kLCVDataTypeUint, SPRITE_DRAW_MODE);
    this.setParam('size', LCVType.kLCVDataTypeFloat, ICON_SIZE);
  }

  private getHighestIssueLevel(index: number) {
    const DEFAULT_LEVEL = 'warning';
    const renderedItem = this.lastRenderedData.at(index);

    if (!renderedItem) {
      return DEFAULT_LEVEL;
    }

    return renderedItem[1].reduce<'error' | 'warning'>(
      (result, item) => (item.issue.level === Level.ERROR ? 'error' : result),
      DEFAULT_LEVEL,
    );
  }

  public isHovering([objectId]: LcvIdPair) {
    return objectId === this.objectId;
  }

  /** The `updateSelection` callback is triggered when user clicks some object in the visualizer.
    * It enables us to invoke a callback and retrieve details about the clicked point in the UI.
    * */
  public updateSelection(selectionTarget: LcvIdPair | null) {
    if (selectionTarget === null) {
      this.clickCallback?.(null);
      return;
    }

    const [objectId, primitiveIndex] = selectionTarget;
    if (objectId !== this.objectId) {
      this.clickCallback?.(null);
      return;
    }

    const foundPoint = this.lastRenderedData.at(primitiveIndex);

    if (foundPoint) {
      const [coordinates, issues] = foundPoint;

      this.clickCallback?.({ coordinates, issues });
    } else {
      this.clickCallback?.(null);
      logger.error(`Point with ${primitiveIndex} index was clicked but not recognised`);
    }
  }

  /** The `updateProblematicCoordinates` method updates the points to reflect the latest data. */
  public updateProblematicCoordinates(problematicCoordinates: Map<Vector3, CoordinatesIssue[]>) {
    this.lastRenderedData = [...problematicCoordinates.entries()];

    // when updating items we need to close the currently open panel
    this.clickCallback?.(null);

    this.setSize(this.lastRenderedData.length);

    // if we face some performance issues we can do some optimizations here
    this.lastRenderedData.forEach(([point], index) => {
      this.setParamAtIndex(
        index,
        'points',
        LCVType.kLCVDataTypeFloat3,
        [point.x, point.y, point.z],
      );

      // Make sure each point is visible
      this.setParamAtIndex(
        index,
        'visible',
        LCVType.kLCVDataTypeByte,
        1,
      );

      const highestIssueLevel = this.getHighestIssueLevel(index);
      this.setParamAtIndex(
        index,
        'texture_ids',
        LCVType.kLCVDataTypeByte,
        highestIssueLevel === 'warning' ? WARNING_SPRITE : ERROR_SPRITE,
      );
    });
  }

  public setClickCallback(clickCallback: ClickCallback | null) {
    this.clickCallback = clickCallback;
  }

  public getCoordinateIssuesByStatusIssue(statusIssue: LCStatus) {
    return this.lastRenderedData
      .find(([, issues]) => issues.some((item) => item.issue === statusIssue));
  }
}
