// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useLocation } from 'react-router-dom';

import { isInExploration } from '../../../lib/navigation';
import { WORK_ORDER_TYPES } from '../../../lib/workOrderTypes';
import { isSensitivityAnalysis } from '../../../lib/workflowUtils';
import { usePendingWorkOrders } from '../../../recoil/pendingWorkOrders';
import { useWorkflowState } from '../../../recoil/workflowState';
import { useIsRunStatusVisibleValue } from '../../../state/internal/component/isRunStatusVisible';
import { useLeftOverlayCardsWidthValue } from '../../../state/internal/component/leftOverlayCards';
import { useStatusCardPropsValue } from '../../../state/internal/component/statusCard';
import { useIsGeometryView, useIsSetupView } from '../../../state/internal/global/currentView';
import { useShowGeometryTree } from '../../../state/internal/tree/showGeometryTree';
import { useProjectContext } from '../../context/ProjectContext';

import { RIGHT_OVERLAY_CARDS_WIDTH } from '@/lib/constants';

const EDGE_PADDING = 20;

/**
 * Returns the left and right attributes to use for a floating element to be centered in the
 * viewport, depending on the visible cards.
 */
export const useToolbarMargin = (): { left: number, right: number } => {
  const location = useLocation();
  const { projectId, workflowId, jobId } = useProjectContext();
  const isGeometryView = useIsGeometryView();
  const isSetupView = useIsSetupView();
  const workflow = useWorkflowState(projectId, workflowId);
  const isRunStatusVisible = useIsRunStatusVisibleValue({ projectId, workflowId, jobId });
  const [pendingWorkOrders] = usePendingWorkOrders(projectId);
  const initialStatusCardProps = useStatusCardPropsValue(projectId);
  const showGeometryTree = useShowGeometryTree({ projectId, workflowId, jobId });
  const leftOverlayCardsWidth = useLeftOverlayCardsWidthValue();

  const isExploration = isInExploration(location.pathname);
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const hasPendingWorkOrder = WORK_ORDER_TYPES.some((type) => pendingWorkOrders.workOrders[type]);

  // Leave some space from the left edge or for the leftPane/summary windows.
  let marginLeft = EDGE_PADDING;
  if (
    (!isSensitivity && !!workflow && isRunStatusVisible) || // Run Status panel
    (!isSensitivity && isExploration) || // Exploration panel in DoE simulation
    initialStatusCardProps.isVisible || // Meshing panel
    showGeometryTree || // Geometry tree in 3D viewer
    (
      hasPendingWorkOrder &&
      (isSetupView || isGeometryView)
    ) // Geometry processing in progress
  ) {
    marginLeft = EDGE_PADDING + leftOverlayCardsWidth;
  }

  return { left: marginLeft, right: EDGE_PADDING + RIGHT_OVERLAY_CARDS_WIDTH };
};
