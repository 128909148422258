// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useState } from 'react';

import classNames from 'classnames';

import { EMPTY_VALUE } from '../lib/constants';
import { colors } from '../lib/designSystem';
import { formatNumber } from '../lib/number';
import { useInTimeUnits } from '../recoil/useInTimeUnits';

import { IconButton } from './Button/IconButton';
import { DataSelect } from './Form/DataSelect';
import { createStyles, makeStyles } from './Theme';
import { TimeBarSettings, pickNearestIndex } from './TimeBar';
import Tooltip from './Tooltip';
import { useProjectContext } from './context/ProjectContext';
import { BarChartIcon } from './svg/BarChartIcon';
import { ClockIcon } from './svg/ClockIcon';
import { ClockResetIcon } from './svg/ClockResetIcon';
import { StepBackwardIcon } from './svg/StepBackwardIcon';
import { StepForwardIcon } from './svg/StepForwardIcon';
import { SwapAxesIcon } from './svg/SwapAxesIcon';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    inputIcon: {
      color: colors.neutral650,
      width: '12px',
      height: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    noninteractive: {
      padding: '6px',
    },
    disabledIcon: {
      opacity: '0.5',
    },
    endTime: {
      color: colors.neutral650,
      fontSize: '13px',
      whiteSpace: 'nowrap',
    },
    timeInput: {
      background: colors.surfaceDark3,
      border: 'none',
      color: 'white',
      fontSize: '13px',
      outline: 'none',
      textAlign: 'right',
      width: '100px',
    },
    timeInputContainer: {
      background: colors.surfaceDark3,
      borderRadius: '4px',
      padding: '4px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '28px',
    },
    divider: {
      height: '26px',
      background: colors.surfaceLight2,
      width: '1px',
      margin: '0 8px',
    },
    averageInput: {
      background: colors.surfaceDark3,
      border: 'none',
      color: 'white',
      fontSize: '14px',
      outline: 'none',
      textAlign: 'right',
      width: '40px',
    },
    readOnlyInput: {
      color: colors.disabledType,
      background: colors.surfaceDark2,
    },
    rangeText: {
      fontSize: '14px',
      lineHeight: 1.0,
      padding: '0 0.5em',
      color: colors.lowEmphasisText,
      '&.readonly': {
        color: colors.disabledType,
      },
    },
  }),
  { name: 'TimeBarControls' },
);

interface TimeBarInputProps {
  // The name of the class to style the input.
  className: string;
  // The value that is displayed.
  value: string;
  // Updates the value. Propagate this change to the rest of the time bar.
  updateValue?: (newValue: string) => void;
  // Read only
  readOnly?: boolean;

  inputType?: 'text' | 'dropdown';
  dropdownSteps?: number[];
}

/** Maximum number of options for the dropdown. If exceeded - text input is shown. */
const MAX_OPTIONS_FOR_DROPDOWN = 10;

// An input for the timebar. Update the value when blurred or enter is pressed.
// Set the display value when anything is typed.
const TimeBarInput = (props: TimeBarInputProps) => {
  // This is the value the user has typed in. It is displayed in the input, but it is not applied
  // until updateValue is called.
  const [editValue, setEditValue] = useState('');
  // True if the user has edited the value. editValue is displayed if isEdited is true.
  const [isEdited, setIsEdited] = useState(false);

  const dropdownSteps = props.dropdownSteps?.map((step) => formatNumber(step)) || [];
  const showDropdown = props.inputType === 'dropdown' &&
    dropdownSteps.length > 0 &&
    dropdownSteps.length <= MAX_OPTIONS_FOR_DROPDOWN;

  if (showDropdown) {
    return (
      <DataSelect
        kind="minimal"
        onChange={props.updateValue}
        options={(props.dropdownSteps || []).map((item) => ({
          name: `${item}`, value: `${item}`, selected: props.value === formatNumber(item),
        }))}
        size="small"
      />
    );
  }

  return (
    <input
      className={props.className}
      onBlur={() => {
        if (props.updateValue) {
          props.updateValue(editValue);
        }
        setIsEdited(false);
      }}
      onChange={(event) => {
        setEditValue(event.target.value);
        setIsEdited(true);
      }}
      onFocus={() => {
        setIsEdited(false);
      }}
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          if (props.updateValue) {
            props.updateValue(editValue);
          }
          setIsEdited(false);
        }
      }}
      readOnly={props.readOnly ?? false}
      type="text"
      value={isEdited ? formatNumber(Number(editValue)) : props.value}
    />
  );
};

// The TimeBarControls allows users to step through the time bar or type in a
// particular time step.
const TimeBarControls = (props: { settings: TimeBarSettings }) => {
  const classes = useStyles();
  const {
    innerItersWindow,
    currentIndex,
    setIndex,
    steps,
    isTransient,
    fixedPrecision,
    swapAxes,
    xyChart,
  } = props.settings;

  let timeValue = '';
  if (currentIndex >= 0) {
    timeValue = formatNumber(steps[currentIndex]);
  }
  const { projectId, workflowId, jobId } = useProjectContext();
  const [inTimeUnits, setInTimeUnits] = useInTimeUnits({ projectId, workflowId, jobId });

  // Update the input by snapping it to the nearest step.
  const updateTimeValue = (newTimeValue: string) => {
    const value = parseFloat(newTimeValue);
    setIndex(pickNearestIndex(steps, value));
  };
  let endTime = EMPTY_VALUE;
  if (steps.length > 0) {
    const step = steps[steps.length - 1];
    endTime = `${step.toFixed(fixedPrecision)}${inTimeUnits ? 's' : ''}`;
  }

  return (
    <div className={classes.root} data-locator="time-bar-controls">
      {xyChart && (
        <IconButton
          onClick={swapAxes}
          title="Swap Axes">
          <SwapAxesIcon maxHeight={12} maxWidth={12} />
        </IconButton>
      )}
      <IconButton
        disabled={currentIndex <= 0}
        name="stepBackward"
        onClick={() => setIndex(currentIndex - 1)}>
        <StepBackwardIcon maxHeight={12} maxWidth={12} />
      </IconButton>
      <IconButton
        disabled={currentIndex === steps.length - 1}
        name="stepForward"
        onClick={() => setIndex(currentIndex + 1)}>
        <StepForwardIcon maxHeight={12} maxWidth={12} />
      </IconButton>
      <div
        className={
          classNames(classes.timeInputContainer)
        }>
        <Tooltip
          title={isTransient ? 'Change between iterations and time steps for the x-axis' : ''}>
          <IconButton
            className={classNames(classes.iconButton, {
              [classes.disabled]: !isTransient,
            })}
            disabled={!isTransient}
            onClick={() => setInTimeUnits(!inTimeUnits)}>
            <div className={classNames(classes.inputIcon, {
              [classes.disabledIcon]: !isTransient,
            })}>
              {inTimeUnits ? (
                <ClockIcon maxHeight={11} />
              ) : (
                <BarChartIcon maxHeight={11} />
              )}
            </div>
          </IconButton>
        </Tooltip>
        <TimeBarInput
          className={
            classNames(classes.timeInput)
          }
          dropdownSteps={steps}
          inputType="dropdown"
          updateValue={updateTimeValue}
          value={timeValue}
        />
      </div>
      <div className={classes.endTime}>
        {`/ ${endTime}`}
      </div>
      {!!innerItersWindow && (
        <>
          <div className={classes.divider} />
          <div className={
            classNames(classes.timeInputContainer, classes.readOnlyInput)
          }>
            <Tooltip title="Plot time step range">
              <div className={classNames(
                classes.inputIcon,
                classes.noninteractive,
                classes.disabledIcon,
              )}>
                <ClockResetIcon maxHeight={11} />
              </div>
            </Tooltip>
            <TimeBarInput
              className={classNames(classes.averageInput, classes.readOnlyInput)}
              readOnly
              value={`${innerItersWindow.begin}`}
            />
            <div className={classNames(classes.rangeText, { readonly: true })}>
              to
            </div>
            <TimeBarInput
              className={classNames(classes.averageInput, classes.readOnlyInput)}
              readOnly
              value={`${innerItersWindow.end}`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TimeBarControls;
