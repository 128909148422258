import React, { useState } from 'react';

import cx from 'classnames';

import { colors } from '../lib/designSystem';

import { createStyles, makeStyles } from './Theme';

interface CollapsibleTextProps {
  text: string;
  collapseLimit: number;
  className?: string;
}

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    toggler: {
      border: '0',
      background: 'transparent',
      color: colors.neutral750,
      fontSize: '13px',
      cursor: 'pointer',
      padding: '0',
      marginLeft: '6px',
      whiteSpace: 'nowrap',

      '&:hover': {
        color: colors.neutral850,
      },
    },
  }),
  { name: 'CollapsibleText' },
);

export function CollapsibleText({ text, collapseLimit, className }: CollapsibleTextProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const classes = useStyles();

  const isOverflowing = text.length > collapseLimit;

  return (
    <div className={cx(classes.root, className)}>
      <span>
        {isCollapsed ? `${text.slice(0, collapseLimit)}...` : text}

        {isOverflowing && (
          <button
            className={classes.toggler}
            onClick={(event) => {
              event.stopPropagation();

              setIsCollapsed((value) => !value);
            }}
            type="button">show {isCollapsed ? 'more' : 'less'}
          </button>
        )}
      </span>

    </div>
  );
}
