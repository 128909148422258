import { LCVSurfaceLICGeometryMode, LCVType } from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';

import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { SurfaceLICState } from './filterUtils';

import { paraviewToLcvisContrast } from '@/lib/sliderValues';

export class LcvSurfaceLIC extends LcvFilter {
  parent: LcvFilter;
  state: SurfaceLICState;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: SurfaceLICState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'surface_lic',
        `surface_lic_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'surface_lic',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  updateContrast(valuePercent?: number) {
    if (valuePercent !== undefined) {
      const contrast = paraviewToLcvisContrast(valuePercent);

      this.setAppearanceParam('contrast', LCVType.kLCVDataTypeFloat, contrast);
    }
  }

  /** Pass the object params into LCVis */
  updateParams() {
    const {
      vector_field,
      lic_geometry_mode,
      surface_names,
      origin,
      normal,
      plane_clip_box,
      contrast_in_percent,
    } = this.state;

    this.updateContrast(contrast_in_percent);
    this.setParam('vector_field', LCVType.kLCVDataTypeString, vector_field);
    this.setParam('lic_geometry_mode', LCVType.kLCVDataTypeUint, lic_geometry_mode);

    if (lic_geometry_mode === LCVSurfaceLICGeometryMode.kLCVSurfaceLICPlane) {
      this.setParam('surface_names', LCVType.kLCVDataTypeData1D, 0);
    } else if (surface_names) {
      this.setParam(
        'surface_names',
        LCVType.kLCVDataTypeData1D,
        this.lcv.writeStringArray(this.sessionHandle, surface_names),
      );
    }

    if (lic_geometry_mode === LCVSurfaceLICGeometryMode.kLCVSurfaceLICPlane) {
      if (origin) {
        this.setParam('origin', LCVType.kLCVDataTypeFloat3, origin);
      }
      if (normal) {
        this.setParam('normal', LCVType.kLCVDataTypeFloat3, normal);
      }
      if (plane_clip_box) {
        this.setParam('plane_clip_box', LCVType.kLCVDataTypeFloat3x2, plane_clip_box);
      }
    }
  }

  setState(newState: SurfaceLICState) {
    if (deepEqual(this.state, newState)) {
      return;
    }

    this.state = newState;
    this.updateParams();
  }
}
