import React, { useState } from 'react';

import { isUnmodifiedEnterKey } from '../lib/event';

import { IconButton } from './Button/IconButton';
import GettingStartedCard, { CARD_WIDTH } from './GettingStartedCard';
import { createStyles, makeStyles } from './Theme';
import { ChevronLeftIcon } from './svg/ChevronLeftIcon';
import { ChevronRightIcon } from './svg/ChevronRightIcon';

import { colors } from '@/lib/designSystem';
import { SampleProjectType } from '@/state/external/project/sampleProjects';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      overflow: 'hidden',
    },
    sectionHeader: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    sectionDescriptionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    sectionDescription: {
      fontSize: '14px',
      lineHeight: '20px',
      color: colors.neutral800,
    },
    buttons: {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
    },
    carouselButtons: {
      display: 'flex',
      justifyContent: 'center',
      gap: '4px',
    },
    viewAll: {
      position: 'relative',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 700,
      color: colors.neutral800,
      border: 'none',
      background: 'transparent',
      cursor: 'pointer',
      padding: 0,
      height: 'max-content',
      zIndex: 100,
    },
    carouselItem: {
      transition: 'transform 0.5s ease',
    },
    cards: {
      display: 'flex',
      gap: '16px',
      justifyContent: 'flex-start',
      marginTop: '12px',
    },
  }),
  { name: 'GettingStartedCarousel' },
);

type GettingStartedCarouselProps = {
  items: SampleProjectType[];
  viewAllClick: () => void;
}

const ITEMS_PER_SLIDE = 3;
const OFFSET_PER_VIEW = CARD_WIDTH + 16; // 16 is a gap inside cards styles

export const GettingStartedCarousel = ({ items, viewAllClick }: GettingStartedCarouselProps) => {
  const classes = useStyles();

  const maxIndexToRender = items.length - 1;

  const [currentIndex, setCurrentIndex] = useState(0);

  const offset = (currentIndex / ITEMS_PER_SLIDE) * OFFSET_PER_VIEW;

  const handleNextClick = () => {
    setCurrentIndex((prev) => prev + 1 * ITEMS_PER_SLIDE);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prev) => prev - 1 * ITEMS_PER_SLIDE);
  };

  return (
    <div className={classes.root}>
      <div className={classes.sectionHeader}>
        Tutorials
      </div>
      <div className={classes.sectionDescriptionContainer}>
        <div className={classes.sectionDescription}>
          Example projects with step-by-step written directions
        </div>
        <div className={classes.buttons}>
          <div
            className={classes.viewAll}
            onClick={() => {
              viewAllClick();
            }}
            onKeyUp={(event) => {
              if (isUnmodifiedEnterKey(event)) {
                viewAllClick();
              }
            }}
            role="button"
            tabIndex={0}>
            View all
          </div>
          <div className={classes.carouselButtons}>
            <IconButton
              disabled={currentIndex === 0}
              onClick={handlePrevClick}
              title="Show previous slides">
              <ChevronLeftIcon maxHeight={10} maxWidth={10} />
            </IconButton>
            <IconButton
              disabled={currentIndex + ITEMS_PER_SLIDE > maxIndexToRender}
              onClick={handleNextClick}
              title="Show next slides">
              <ChevronRightIcon maxHeight={10} maxWidth={10} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={classes.cards}>
        {items.map((item) => {
          const imageSrc = item.imageUrl || '';

          return (
            <div
              className={classes.carouselItem}
              key={item.projectName}
              style={{ transform: `translateX(-${offset * ITEMS_PER_SLIDE}px)` }}>
              <GettingStartedCard
                button={{ href: item.documentationUrl, CTA: 'View tutorial' }}
                description={item.projectDescription}
                headline={item.projectName}
                image={{
                  src: imageSrc,
                  alt: item.projectName,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GettingStartedCarousel;
