import { useEffect, useMemo } from 'react';

import { Vector3 } from '../../ProtoDescriptor';
import { newProto } from '../../lib/Vector';
import assert from '../../lib/assert';
import { BoundingBox, centroid } from '../../lib/geometry';
import { getNodeIds, verticesToSurfacesIds } from '../../lib/geometryHealthUtils';
import { LcvDisplay } from '../../lib/lcvis/classes/LcvDisplay';
import { reorder } from '../../lib/lcvis/classes/LcvFrame';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { Bounds } from '../../lib/lcvis/types';
import { shouldNeverFail } from '../../lib/observability/utils';
import { unpackProto } from '../../lib/protoUtils';
import { SelectionAction } from '../../lib/selectionUtils';
import { volumeNodeId } from '../../lib/volumeUtils';
import * as codespb from '../../proto/lcstatus/codes_pb';
import * as geometrypb from '../../proto/lcstatus/details/geometry/geometry_pb';
import { LCStatus } from '../../proto/lcstatus/lcstatus_pb';
import { useGeometryHealthValue } from '../../recoil/geometryHealth';
import { CoordinatesIssue, useSetSelectedVisualizerError } from '../../recoil/useSelectedVisualizerError';
import { StaticVolume, useStaticVolumes } from '../../recoil/volumes';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { useSetSurfacesTransparency } from './useSetSurfacesTransparency';

import environmentState from '@/state/environment';

const serializeCoordinates = (vector: Vector3) => `${vector.x},${vector.y},${vector.z}`;
const deserializeCoordinates = (value: string) => {
  const [x, y, z] = value.split(',').map(Number);
  return { x, y, z };
};

const ERROR_CODES_FOR_PREVIEW = [
  // TODO: take volume centoids
  codespb.Code.GEO_VOLUME_NON_MANIFOLD,
  codespb.Code.GEO_VOLUME_OPEN,
  codespb.Code.GEO_VOLUME_UNMESHABLE,

  // vertices
  codespb.Code.GEO_VERTEX_DUPLICATE,

  // edges
  codespb.Code.GEO_EDGE_LARGE_TOLERANCE,
  codespb.Code.GEO_EDGE_NOT_SMOOTH,
  codespb.Code.GEO_EDGE_UNMESHABLE,

  // surfaces
  codespb.Code.GEO_FACE_EDGES_TOO_CLOSE,
  codespb.Code.GEO_FACE_EDGE_TOO_SMALL,
  codespb.Code.GEO_FACE_FACE_INTERSECTION,
  codespb.Code.GEO_FACE_LARGE_TOLERANCE,
  codespb.Code.GEO_FACE_NEEDS_IMPRINT,
  codespb.Code.GEO_FACE_NOT_SMOOTH,
  codespb.Code.GEO_FACE_SELF_INTERSECTION,
  codespb.Code.GEO_FACE_UNMESHABLE,
  codespb.Code.GEO_FACE_POOR_APPROX,
  codespb.Code.GEO_FACE_EDGE_CROSS,
] as const;

type ErrorResolver = (
  issue: LCStatus,
  context: { display: LcvDisplay; volumesById: Map<string, StaticVolume> }
) => Promise<BoundingBox[]>;

const lcvisBoundsToBoundingBox = (bounds: Bounds): BoundingBox => {
  const [minX, maxX, minY, maxY, minZ, maxZ] = reorder(bounds);
  return {
    min: newProto(minX, minY, minZ),
    max: newProto(maxX, maxY, maxZ),
  };
};

const getPrimitiveBoundingBoxes = async (primitiveIds: string[], display: LcvDisplay) => {
  const boundingBoxes: BoundingBox[] = [];

  await Promise.all(primitiveIds.filter(Boolean).map(async (primitiveId) => {
    await display.workspace?.workspaceQueue.enqueue(async () => {
      const primitiveBounds = display.workspace?.getUnionBounds([primitiveId]);

      if (primitiveBounds) {
        boundingBoxes.push(lcvisBoundsToBoundingBox(primitiveBounds));
      }
    }).catch(shouldNeverFail);
  }));

  return boundingBoxes;
};

const getVolumeBoundingBoxes = async (
  volumeDomains: (number | undefined)[],
  display: LcvDisplay,
  volumesById: Map<string, StaticVolume>,
) => {
  const boundingBoxes: BoundingBox[] = [];

  await Promise.all((volumeDomains).map(async (volumeDomain) => {
    if (volumeDomain === undefined) {
      return;
    }

    const volumeId = volumeNodeId(volumeDomain);
    const volume = volumesById.get(volumeId);

    if (!volume) {
      return;
    }

    await display.workspace?.workspaceQueue.enqueue(async () => {
      const volumeBounds = display.workspace?.getUnionBounds([...volume.bounds]);

      if (volumeBounds) {
        boundingBoxes.push(lcvisBoundsToBoundingBox(volumeBounds));
      }
    }).catch(shouldNeverFail);
  }));

  return boundingBoxes;
};

// `useHighlightProblematicEdges` uses similar resolvers, but this hook focuses
// on extracting coordinates rather than edges. In the future, we may refactor to consolidate
// the logic and avoid code duplication. However, for now, maintaining similar code in both
// places provides quicker results, as the direction of this PoC is still uncertain.
const BOUNDING_BOX_RESOLVERS = new Map<
  typeof ERROR_CODES_FOR_PREVIEW[number],
  ErrorResolver
>([
  // volumes
  [
    codespb.Code.GEO_VOLUME_NON_MANIFOLD, (issue, { display, volumesById }) => {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeNonManifoldDetails);
      const problematicVolumeId = details?.volumeId;

      return getVolumeBoundingBoxes([problematicVolumeId], display, volumesById);
    },
  ],

  [
    codespb.Code.GEO_VOLUME_OPEN, (issue, { display, volumesById }) => {
      const details = unpackProto(issue.details, geometrypb.GeoVolumeOpenDetails);
      const problematicVolumeId = details?.volumesId;

      return getVolumeBoundingBoxes([problematicVolumeId], display, volumesById);
    },
  ],

  [codespb.Code.GEO_VOLUME_UNMESHABLE, (issue, { display, volumesById }) => {
    const details = unpackProto(issue.details, geometrypb.GeoVolumeUnmeshableDetails);
    const problematicVolumeId = details?.volumeId;

    return getVolumeBoundingBoxes([problematicVolumeId], display, volumesById);
  }],

  // vertices
  [
    codespb.Code.GEO_VERTEX_DUPLICATE, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoVertexDuplicateDetails);
      const surfaceIds = verticesToSurfacesIds([details?.vertex1, details?.vertex2]);

      return getPrimitiveBoundingBoxes(surfaceIds, display);
    },
  ],

  // edges
  [
    codespb.Code.GEO_EDGE_LARGE_TOLERANCE, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeLargeToleranceDetails);
      const problematicEdgeId = details?.edge?.id ?? '';

      return getPrimitiveBoundingBoxes([problematicEdgeId], display);
    },
  ],
  [
    codespb.Code.GEO_EDGE_NOT_SMOOTH, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeNotSmoothDetails);
      const problematicEdgeId = details?.edge?.id ?? '';

      return getPrimitiveBoundingBoxes([problematicEdgeId], display);
    },
  ],
  [
    codespb.Code.GEO_EDGE_UNMESHABLE, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoEdgeUnmeshableDetails);
      const problematicEdgeId = details?.edge?.id ?? '';

      return getPrimitiveBoundingBoxes([problematicEdgeId], display);
    },
  ],

  // faces
  [
    codespb.Code.GEO_FACE_EDGES_TOO_CLOSE, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgesTooCloseDetails);
      const problematicSurfaceId = details?.surfaceId ?? '';

      return getPrimitiveBoundingBoxes([problematicSurfaceId], display);
    },
  ],
  [
    codespb.Code.GEO_FACE_EDGE_TOO_SMALL, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeTooSmallDetails);
      const problematicSurfaceId = details?.surfaceId ?? '';

      return getPrimitiveBoundingBoxes([problematicSurfaceId], display);
    },
  ],
  [
    codespb.Code.GEO_FACE_FACE_INTERSECTION, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoFaceFaceIntersectionDetails);
      const problematicSurfaceIds = [details?.surface1Id ?? '', details?.surface2Id ?? ''];

      return getPrimitiveBoundingBoxes(problematicSurfaceIds, display);
    },
  ],
  [
    codespb.Code.GEO_FACE_LARGE_TOLERANCE, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoFaceLargeToleranceDetails);
      const problematicSurfaceId = details?.surfaceId ?? '';

      return getPrimitiveBoundingBoxes([problematicSurfaceId], display);
    },
  ],
  [
    codespb.Code.GEO_FACE_NEEDS_IMPRINT, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoFaceNeedsImprintDetails);
      const problematicSurfaceId = details?.surfaceId ?? '';

      return getPrimitiveBoundingBoxes([problematicSurfaceId], display);
    },
  ],
  [
    codespb.Code.GEO_FACE_NOT_SMOOTH, (issue, { display }) => {
      const details = unpackProto(issue.details, geometrypb.GeoFaceNotSmoothDetails);
      const problematicSurfaceId = details?.surfaceId ?? '';

      return getPrimitiveBoundingBoxes([problematicSurfaceId], display);
    },
  ],
  [codespb.Code.GEO_FACE_SELF_INTERSECTION, (issue, { display }) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceSelfIntersectionDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return getPrimitiveBoundingBoxes([problematicSurfaceId], display);
  }],
  [codespb.Code.GEO_FACE_POOR_APPROX, (issue, { display }) => {
    const details = unpackProto(issue.details, geometrypb.GeoFacePoorApproxDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return getPrimitiveBoundingBoxes([problematicSurfaceId], display);
  }],
  [codespb.Code.GEO_FACE_UNMESHABLE, (issue, { display }) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceUnmeshableDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return getPrimitiveBoundingBoxes([problematicSurfaceId], display);
  }],
  [codespb.Code.GEO_FACE_EDGE_CROSS, (issue, { display }) => {
    const details = unpackProto(issue.details, geometrypb.GeoFaceEdgeCrossDetails);
    const problematicSurfaceId = details?.surfaceId ?? '';

    return getPrimitiveBoundingBoxes([problematicSurfaceId], display);
  }],
]);

assert(
  BOUNDING_BOX_RESOLVERS.size === ERROR_CODES_FOR_PREVIEW.length,
  'Some error types do not have corresponding resolvers',
);

/**
 * This hook processes all GEO_FACE_* errors, extracts their associated surfaces,
 * and renders visual markers in the UI at the locations where the errors were detected.
 *
 * Currently, markers are positioned at the centroids of the bounding boxes
 * of the problematic surfaces.
 */
export const useVisualizerErrorOverlay = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { modifySelection } = useSelectionContext();

  // == Recoil
  const geometryHealth = useGeometryHealthValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const setSelectedVisualizerError = useSetSelectedVisualizerError(projectId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const setSurfacesTransparency = useSetSurfacesTransparency();

  const issuesForPreview = useMemo(() => (geometryHealth?.issues || []).filter(
    ({ code }) => (ERROR_CODES_FOR_PREVIEW as readonly codespb.Code[]).includes(code),
  ), [geometryHealth?.issues]);

  const volumesById = useMemo(() => staticVolumes.reduce((result, item) => {
    result.set(item.id, item);

    return result;
  }, new Map<string, StaticVolume>()), [staticVolumes]);

  useEffect(() => {
    if (!lcvisReady) {
      return;
    }

    const display = lcvHandler.display;
    display?.errorList?.setClickCallback(async (data) => {
      // ideally we should take the biggest box but i think it's just fine for now

      if (data) {
        const selectedNodeIds = data.issues.flatMap(({ issue }) => getNodeIds(issue));
        modifySelection({ action: SelectionAction.OVERWRITE, modificationIds: selectedNodeIds });
      }

      setSelectedVisualizerError(data);
    });

    return () => {
      display?.errorList?.setClickCallback(null);
    };
  }, [
    lcvisReady,
    modifySelection,
    setSelectedVisualizerError,
    setSurfacesTransparency,
    volumesById,
  ]);

  useEffect(() => {
    if (!lcvisReady) {
      return;
    }

    const issuesBySerializedCoordinates = new Map<string, CoordinatesIssue[]>();

    lcvHandler.queueDisplayFunction('setPreviewableIssues', async (display) => {
      await Promise.all(issuesForPreview.map(async (issue) => {
        const boundingBoxesResolver = BOUNDING_BOX_RESOLVERS.get(
          issue.code as typeof ERROR_CODES_FOR_PREVIEW[number],
        ) ?? (() => Promise.resolve([]));

        const problematicBoundingBoxes = await boundingBoxesResolver(
          issue,
          { display, volumesById },
        );
        const problematicCentroids = problematicBoundingBoxes.map(centroid);

        problematicCentroids.forEach((coordinates, index) => {
          const boundingBox = problematicBoundingBoxes[index];
          const key = serializeCoordinates(coordinates);
          const existingIssues = issuesBySerializedCoordinates.get(key) || [];

          issuesBySerializedCoordinates.set(key, [...existingIssues, { issue, boundingBox }]);
        });
      }));

      const issuesByCoordinates = new Map(
        [...issuesBySerializedCoordinates.entries()].map(([serializedCoordinates, issues]) => {
          const coordinates = deserializeCoordinates(serializedCoordinates);

          return [coordinates, issues] as const;
        }),
      );

      display.errorList?.updateProblematicCoordinates?.(issuesByCoordinates);
    });
  }, [lcvisReady, issuesForPreview, volumesById]);
};
