// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter, mapVisualizerEntitiesToVolumes } from '../../../lib/subselectUtils';
import { mapDomainsToIds, mapIdsToDomains } from '../../../lib/volumeUtils';
import { usePhysics } from '../../../model/hooks/usePhysics';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useProjectContext } from '../../context/ProjectContext';

export const usePhysicsVolumes = (physicsId: string) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);

  // == Model
  const {
    entityIds,
    setDomains,
    volumeAssignmentDisabledReason,
  } = usePhysics(projectId, workflowId, jobId, readOnly, physicsId);

  const nodeIds = Array.from(entityIds);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);

  /**
   * Ordinarily, both filterVolumes and getTooltip below would have to lookup a volume by ID and
   * call volumeAssignmentDisabledReason to get some meaningful result.  We can optimize this by
   * generating a lookup object of each volume's disabled reason.
   */
  const volumesDisabledReasons = useMemo(
    () => staticVolumes.reduce((result, volume) => {
      result[volume.id] = volumeAssignmentDisabledReason(volume.domain);
      return result;
    }, {} as Record<string, string>),
    [staticVolumes, volumeAssignmentDisabledReason],
  );

  /** Memoize the nodeFilter object passed to NodeSubselect to avoid infinite looping */
  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType === NodeType.VOLUME) {
      const disabledReason = volumesDisabledReasons[nodeId];
      return {
        related: true,
        tooltip: disabledReason,
        disabled: !!disabledReason,
      };
    }
    if (nodeType === NodeType.SURFACE_GROUP) {
      const domains = geometryTags.domainsFromTagEntityGroupId(nodeId);
      if (domains?.length) {
        const disabledReason = mapDomainsToIds(staticVolumes, domains)
          .map((volumeId) => volumesDisabledReasons[volumeId]);

        // If there's at least one volume with issues in this tag, disable the group. Otherwise we
        // have to implement complex logic regarding the relationships between tags, volumes and
        // materials, i.e. filter out bodies in the tag that are not assigned to a correct material.
        if (disabledReason.length > 0) {
          return {
            related: true,
            tooltip: disabledReason.join(', '),
            disabled: disabledReason.some((reason) => !!reason),
          };
        }
        return {
          related: true,
          disabled: false,
        };
      }
    }
    return defaultNodeFilter(nodeType);
  }, [geometryTags, staticVolumes, volumesDisabledReasons]);

  const setVolumeIds = useCallback(async (volumeIds: string[]) => {
    const domains = volumeIds.map((volumeId) => {
      const tag = geometryTags.domainsFromTag(volumeId);
      if (tag.length) {
        return volumeId;
      }
      return mapIdsToDomains(staticVolumes, [volumeId])[0];
    });
    await setDomains(new Set(domains.filter((domain) => !!domain)));
  }, [setDomains, staticVolumes, geometryTags]);

  /** A callback for mapping entities clicked in the visualizer to volumes that can be toggled */
  const mapVisualizerEntities = useCallback(
    (ids: string[]) => mapVisualizerEntitiesToVolumes(ids, staticVolumes),
    [staticVolumes],
  );

  return {
    nodeFilter,
    nodeIds,
    setVolumeIds,
    mapVisualizerEntities,
  };
};
