// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily } from 'recoil';

import { connectionKey, paraviewClientState } from '../lib/ParaviewClient';
import { FORCE_DISTRIBUTION_DEFAULT_NBINS } from '../lib/constants';
import { fromBigInt } from '../lib/number';
import * as rpc from '../lib/rpc';
import { addRpcError } from '../lib/transientNotification';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as plotpb from '../proto/plots/plots_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

import { plotNodesState } from './plotNodes';

import { lcvHandler } from '@/lib/lcvis/handler/LcvHandler';

type plotSelectorKey = {
  projectId: string,
  workflowId: string,
  jobId: string,
  plotId: string,
  plotInfoString: string,
  lcvisEnabled: boolean,
}

function createPlotParams(xyPlot: plotpb.PlotSettings_XYPlot | undefined): ParaviewRpc.PlotParam {
  return {
    typ: ParaviewRpc.TreeNodeType.PLOT,
    nodeIds: xyPlot?.dataIds || [],
    param: {
      typ: 'ScatterPlot',
      // The extents of the scalar quantity.
      range: [
        xyPlot?.yAxisRange?.rangeStart!,
        xyPlot?.yAxisRange?.rangeEnd!,
      ],
      // Variable to plot.
      quantity: {
        // 'Density', etc.
        displayDataName: xyPlot?.yAxis?.displayDataName || '',
        // Used to select vector components.
        displayDataNameComponent: fromBigInt(xyPlot?.yAxis?.displayDataNameComponent || 0),
      },
    },
  };
}

export const plotDataSelector = selectorFamily<
  ParaviewRpc.PlotData | null,
  plotSelectorKey
>({
  key: 'plotDataState',
  get: (key: plotSelectorKey) => async ({ get }) => {
    const connKey = get(connectionKey({ ...key }));
    const clientState = get(paraviewClientState(connKey));
    const client = clientState?.client;

    // Find the plot with the passed id and retrieve RPC params from that plot's proto
    const plotNodes = get(plotNodesState(key.projectId));
    const node = plotNodes.plots.find((plotNode) => plotNode.id === key.plotId);
    const xyPlot = node?.plot.case === 'xyPlot' ? node.plot.value : undefined;
    const plotParam = createPlotParams(xyPlot);

    if (key.lcvisEnabled) {
      return new Promise<ParaviewRpc.PlotData | null>((resolve) => {
        lcvHandler.queueDisplayFunction('getPlotData', (display) => {
          display.workspace?.getPlotData(plotParam).then((result) => {
            resolve(result);
          }).catch((err) => {
            throw new Error(err);
          });
        });
      });
    }

    // Check param values to make sure we don't call an rpc with invalid parameters
    // Also make sure client exists
    if (client) {
      const paraviewData = await ParaviewRpc.getplotdata(client, plotParam);
      return paraviewData;
    }

    return null;
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export function makeVisSpanwiseDistributionRequest(
  forcePlot: plotpb.PlotSettings_ForceDistribution,
  projectId: string,
  activeUrl: string,
  pRef: number,
): frontendpb.VisSpanwiseDistributionRequest {
  const axis = () => {
    switch (forcePlot.axis) {
      case plotpb.XAxisOptions.X_COORD:
        return 0;
      case plotpb.XAxisOptions.Y_COORD:
        return 1;
      case plotpb.XAxisOptions.Z_COORD:
        return 2;
      default:
        return 0;
    }
  };
  return new frontendpb.VisSpanwiseDistributionRequest({
    projectId,
    url: activeUrl,
    bounds: forcePlot.bounds,
    // Upgrade nBins if necessary (for distribution plots created before exposing this parameter).
    nBins: forcePlot.nBins ? forcePlot.nBins : FORCE_DISTRIBUTION_DEFAULT_NBINS,
    cumulative: forcePlot.distributionType === plotpb.ForceDistributionOptions.CUMULATIVE,
    axis: axis(),
    pRef,
    forceDirX: forcePlot.forceDirX,
    forceDirY: forcePlot.forceDirY,
    forceDirZ: forcePlot.forceDirZ,
  });
}

type forceDistributionSelectorKey = {
  projectId: string,
  workflowId: string,
  jobId: string,
  plotId: string,
  activeUrl: string,
  plotInfoString: string,
  pRef: number,
}

export const forceDistributionSelector = selectorFamily<
  {x: number[], y: number[]} | null,
  forceDistributionSelectorKey
>({
  key: 'plotDataState',
  get: (key: forceDistributionSelectorKey) => async ({ get }) => {
    // Find the plot with the passed id and retrieve RPC params from that plot's proto
    const plotNodes = get(plotNodesState(key.projectId));
    const node = plotNodes.plots.find((plotNode) => plotNode.id === key.plotId);
    const forcePlot = node?.plot.case === 'forceDistribution' ? node.plot.value : undefined;

    if (!forcePlot || forcePlot.bounds.length === 0 || !key.activeUrl) {
      return null;
    }
    const req =
        makeVisSpanwiseDistributionRequest(forcePlot, key.projectId, key.activeUrl, key.pRef);
    try {
      return rpc.callRetry(
        'ComputeForceDistribution',
        rpc.client.computeVisSpanwiseDistribution,
        req,
      );
    } catch (err) {
      console.error('ComputeForceDistribution failed: ', err);
      addRpcError('Error computing the force distribution', err);
      return null;
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});
