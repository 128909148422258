// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import { isUnmodifiedEnterKey, isUnmodifiedEscapeKey } from '../../lib/event';
import { createStyles, makeStyles } from '../Theme';

const ALL_WHITESPACE = /^\s*$/;

export const EDITABLE_TEXT_BORDER = 1;
// TODO: once we remove TreeRow's dependency on this locator, we can stop exporting it
export const CONTENT_EDITABLE_DATA_LOCATOR = 'editableTextDisplay';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      '--cursor': 'pointer',
      '--border-color': 'transparent',
      '--display': 'inline-flex',
      '--button-opacity': 0,
      '&.active': {
        '--cursor': 'text',
      },
      '&.asBlock': {
        '--display': 'flex',
      },
      '&.hoverOutline:hover': {
        '--border-color': colors.neutral450,
      },
      '&:hover': {
        '--button-opacity': 1,
      },
      '&.disabled': {
        '--cursor': 'unset',
      },
      display: 'var(--display)',
      overflow: 'hidden',
      maxWidth: '100%',
      position: 'relative',
      alignItems: 'center',
      gap: '3px',
      flexGrow: 1,
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      overflow: 'hidden',
      flexGrow: 1,
    },
    display: {
      '--bg-color': 'transparent',
      '&:focus': {
        '--bg-color': colors.surfaceBackground,
        '--border-color': colors.primaryCta,
      },
      color: colors.highEmphasisText,
      flex: '1 1 auto',
      lineHeight: 'normal',
      borderRadius: 4,
      userSelect: 'none',
      padding: '3px 2px',
      margin: 0,
      outline: 0,
      backgroundColor: 'var(--bg-color)',
      border: `${EDITABLE_TEXT_BORDER}px solid var(--border-color)`,
      transition: 'background-color 250ms, border-color 250ms',
      cursor: 'var(--cursor)',
      '&.truncate': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:not(:focus)': {
          boxSizing: 'border-box',
          '&.ellipsis': {
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    editButton: {
      opacity: 'var(--button-opacity)',
      '&.editing': {
        visibility: 'hidden',
      },
    },
  }),
  { name: 'EditableTextSimplified' },
);

interface EditableTextProps {
  // Set to true to enable editing
  isEditing: boolean;
  // The current value of the text (when not editing)
  value: string;
  // The text to display when the value is blank, i.e. has no non-whitespace characters
  displayValueWhenBlank?: string;
  // Optional class to apply when the value is blank
  classWhenBlank?: string;
  // Callback to call when the input text is submitted
  onSubmit: (newValue: string) => void;
  // Callback to call when the text is double-clicked (in non-editing mode)
  onDoubleClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  // Set to true to truncate the text with ellipsis when it overflows
  truncate?: boolean;
}

/*
  * This is a semi-controlled component. The parent component must set `value`
  * and `isEditing` appropriately, and is generally expected to set `isEditing`
  * to true when `onDoubleClick` is invoked. When in editing mode, it behaves
  * like an uncontrolled input, and when that input is blurred (or enter is
  * pressed), the `onSubmit` callback is called with the new value. The parent
  * component would typically set `value` to that new value and set `isEditing`
  * to `false` in its `onSubmit` handler.
  *
  * This is more robust than the `EditableText` component in that it does not
  * read or write any DOM elements' `textContent` properties. It also has fewer
  * features than `EditableText`, but it could probably gain those features if
  * we want it to replace more usages of `EditableText`.
  */
export const EditableTextSimplified = ({
  isEditing,
  value,
  onSubmit,
  onDoubleClick,
  truncate,
  displayValueWhenBlank,
  classWhenBlank,
}: EditableTextProps) => {
  displayValueWhenBlank = displayValueWhenBlank || '';
  classWhenBlank = classWhenBlank || '';
  const valueBlank = ALL_WHITESPACE.test(value);
  const displayValue = valueBlank ? displayValueWhenBlank : value;

  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [tempValue, setTempValue] = useState(value);

  // see below for why we need this ref
  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  // this effect must only run when `isEditing` changes, that's why we pull
  // the value prop from a ref, because if we used the prop directly, we'd
  // have to put it in our dependency array, and then this effect would run
  // every time the value changes, too.
  useEffect(() => {
    if (isEditing) { // we just transitioned to editing mode
      setTempValue(valueRef.current);
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(0, valueRef.current.length);
    }
  }, [isEditing]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(event.target.value);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onSubmit(tempValue);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Parent components may have their own keyboard handlers, but we don't want to trigger them
    // when we're editing text.
    event.stopPropagation();

    if (isUnmodifiedEnterKey(event)) {
      onSubmit(tempValue);
    } else if (isUnmodifiedEscapeKey(event)) {
      // TODO: the idea is if we're editing and escape is pressed, call
      // `onSubmit` with the value we had before we started editing. but this
      // doesn't work because the escape key causes a blur event rather than
      // a keydown event, so we just get `handleInputBlur` called instead.
      onSubmit(value);
    }
  };

  const handleInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Parent components may have their own keyboard handlers, but we don't want to trigger them
    // when we're editing text. E.g. TreeRow has an `onKeyUp` that treats the space bar as a click.
    event.stopPropagation();
  };

  const handleInputDoubleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    // Another event that we don't want to propogate to parents when we're editing text.
    event.stopPropagation();
  };

  return (
    <div className={cx(classes.root, { active: isEditing })}>
      <div className={cx(classes.wrapper)}>
        {isEditing ? (
          <input
            className={cx(classes.display)}
            data-locator={CONTENT_EDITABLE_DATA_LOCATOR}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            onDoubleClick={handleInputDoubleClick}
            onKeyDown={handleInputKeyDown}
            onKeyUp={handleInputKeyUp}
            ref={inputRef}
            type="text"
            value={tempValue}
          />
        ) : (
          <div
            className={cx(classes.display, {
              truncate,
              ellipsis: true,
              [classWhenBlank]: valueBlank,
            })}
            data-locator={CONTENT_EDITABLE_DATA_LOCATOR}
            onDoubleClick={onDoubleClick}>
            {displayValue}
          </div>
        )}
      </div>
    </div>
  );
};
