// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { deepEqual } from 'fast-equals';

import { RgbColor } from '../../../designSystem';
import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { ThresholdState } from './filterUtils';

/**
 * A filter for computing a threshold
 */
export class LcvThreshold extends LcvFilter {
  parent: LcvFilter;
  state: ThresholdState;
  color: RgbColor | null = null;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: ThresholdState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'threshold',
        `threshold_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'threshold',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  /**
   * Pass the object params into LCVis
   */
  updateParams() {
    // TODO(LC-22403): set appropriate params for the threshold filter
  }

  setState(newState: ThresholdState) {
    if (deepEqual(this.state, newState)) {
      return;
    }

    this.state = newState;
    this.updateParams();
  }
}
