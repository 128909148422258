// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { expandGroupsExcludingTags, rollupGroups } from '../../../../lib/entityGroupUtils';
import { hideSurfaces } from '../../../../lib/paraviewUtils';
import { SURFACE_NODE_TYPES, TAGS_NODE_TYPES } from '../../../../lib/simulationTree/node';
import { EditSource, EditState, filterWarnings } from '../../../../lib/visUtils';
import { EntityType } from '../../../../proto/entitygroup/entitygroup_pb';
import * as ParaviewRpc from '../../../../pvproto/ParaviewRpc';
import { useEntityGroupData, useEntityGroupMap } from '../../../../recoil/entityGroupState';
import { useEditState } from '../../../../recoil/paraviewState';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import { useProjectContext } from '../../../context/ProjectContext';
import { useExtractSurfacesSubselect } from '../../../hooks/subselect/useExtractSurfaces';
import { FilterEditControl } from '../../FilterEditControl';
import { NodeSubselect } from '../../NodeSubselect';
import PropertiesSection from '../../PropertiesSection';
import { CommonFilterMessages } from '../shared/CommonFilterMessages';
import { FilterDisplayPanel } from '../shared/FilterDisplayPanel';

import { FilterPropertiesPanelProps } from './props';

export function newExtractSurfacesParam(): ParaviewRpc.ExtractSurfacesParam {
  return {
    typ: ParaviewRpc.TreeNodeType.EXTRACT_SURFACES,
    surfaces: [],
    disks: [],
  };
}

const SUBSELECT_ID = 'extract-surfaces';

// Panel for displaying and modifying a extract surfaces filter.
export const ExtractSurfacesPropPanel = (props: FilterPropertiesPanelProps) => {
  const { displayProps, filterNode, nodeId } = props;

  const { visibilityMap, setVisibility } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  const [editState, setEditState] = useEditState();
  const { isActive, nodeFilter } = useExtractSurfacesSubselect(SUBSELECT_ID);

  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const param = props.param as ParaviewRpc.ExtractSurfacesParam;
  const warnings = filterWarnings(param);
  const readOnly = !editState;

  const surfaceList = useMemo(() => {
    const items = [...param.surfaces];
    if (param.disks) {
      items.push(...param.disks);
    }
    return items;
  }, [param]);

  const rollup = useMemo(() => rollupGroups(entityGroupData), [entityGroupData]);
  const rollupNodeIds = useMemo(() => rollup(surfaceList), [rollup, surfaceList]);

  const updateSurfaces = useCallback((nodeIds: string[]) => {
    const surfaceIds: string[] = [];
    const diskIds: string[] = [];

    // Keep tags as they are, but expand groups. This allows us to keep settings associated to tags.
    const unrolledSurfaceList = expandGroupsExcludingTags(entityGroupData, nodeIds);
    nodeIds.forEach((id) => {
      if (entityGroupMap.has(id)) {
        const group = entityGroupMap.get(id);
        if (group.entityType === EntityType.SURFACE && !group.children.size) {
          surfaceIds.push(id);
        }
        if (group.entityType === EntityType.PARTICLE_GROUP) {
          diskIds.push(id);
        }
      }
    });
    setEditState(
      (oldEditState) => (
        oldEditState ? {
          ...oldEditState,
          editSource: EditSource.FORM,
          param: { ...oldEditState.param, surfaces: unrolledSurfaceList, disks: diskIds },
        } as EditState : null
      ),
    );
  }, [entityGroupMap, setEditState, entityGroupData]);

  return (
    <div>
      <CommonFilterMessages disabled={isActive} warnings={warnings} />
      <FilterDisplayPanel filterNode={filterNode} />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={!!editState}
          expandWhenDisabled
          headerRight={(
            <FilterEditControl
              displayProps={displayProps}
              nodeId={nodeId}
              // hide extracted surfaces from Geometry section
              onSave={() => {
                if (surfaceList.length > 0) {
                  setVisibility(hideSurfaces(surfaceList, visibilityMap));
                }
              }}
              param={param}
            />
          )}
          heading="Geometry"
          nodeId={nodeId}
          panelName="meshSurfaces">
          <NodeSubselect
            autoStart={!displayProps.displayVariable}
            id={SUBSELECT_ID}
            independentSelection
            labels={['mesh surfaces']}
            nodeFilter={nodeFilter}
            nodeIds={rollupNodeIds}
            onChange={updateSurfaces}
            readOnly={readOnly}
            referenceNodeIds={[nodeId]}
            visibleTreeNodeTypes={[...SURFACE_NODE_TYPES, ...TAGS_NODE_TYPES]}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
