// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/api/v0/luminarycloud/output_definition/output_definition.proto (package luminary.proto.api.v0.luminarycloud.output_definition, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { OutputNode } from "../../../../frontend/output/output_pb.js";

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.CreateOutputDefinitionRequest
 */
export const CreateOutputDefinitionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.CreateOutputDefinitionRequest",
  () => [
    { no: 1, name: "output_definition", kind: "message", T: OutputNode },
    { no: 2, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.CreateOutputDefinitionResponse
 */
export const CreateOutputDefinitionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.CreateOutputDefinitionResponse",
  () => [
    { no: 1, name: "output_definition", kind: "message", T: OutputNode },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.GetOutputDefinitionRequest
 */
export const GetOutputDefinitionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.GetOutputDefinitionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.GetOutputDefinitionResponse
 */
export const GetOutputDefinitionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.GetOutputDefinitionResponse",
  () => [
    { no: 1, name: "output_definition", kind: "message", T: OutputNode },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.ListOutputDefinitionsRequest
 */
export const ListOutputDefinitionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.ListOutputDefinitionsRequest",
  () => [
    { no: 1, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.ListOutputDefinitionsResponse
 */
export const ListOutputDefinitionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.ListOutputDefinitionsResponse",
  () => [
    { no: 1, name: "output_definitions", kind: "message", T: OutputNode, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.UpdateOutputDefinitionRequest
 */
export const UpdateOutputDefinitionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.UpdateOutputDefinitionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "output_definition", kind: "message", T: OutputNode },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.UpdateOutputDefinitionResponse
 */
export const UpdateOutputDefinitionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.UpdateOutputDefinitionResponse",
  () => [
    { no: 1, name: "output_definition", kind: "message", T: OutputNode },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.DeleteOutputDefinitionRequest
 */
export const DeleteOutputDefinitionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.DeleteOutputDefinitionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.api.v0.luminarycloud.output_definition.DeleteOutputDefinitionResponse
 */
export const DeleteOutputDefinitionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.api.v0.luminarycloud.output_definition.DeleteOutputDefinitionResponse",
  [],
);

