// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { ConnectError } from '@connectrpc/connect';
import { BehaviorSubject } from 'rxjs';

import * as assistpb from '../../proto/assistant/assistant_pb';
import { analyzerServer } from '../RuntimeParams';
import { sessionKey } from '../jwt';
import { Logger } from '../observability/logs';
import * as rpc from '../rpc';
import { addRpcError } from '../transientNotification';

import { MacroMap } from './assistantMacros';

const logger = new Logger('assistant');
logger.logToBrowser = false;

const rpcPool = new rpc.StreamingRpcPool<
  assistpb.Request,
  assistpb.Reply
>('Assist', rpc.client.assist);

export const isAssistantResponding = new BehaviorSubject<boolean>(false);

function outcomeCb(outcome: assistpb.Outcome) {
  const sess = outcome.session;
  const id = outcome.request;
  logger.info(sess, id, `assistant: outcome=${outcome}`);
  rpc.callRetry(
    'AssistCallback',
    rpc.client.assistCallback,
    outcome,
  ).then(() => {
    logger.info(sess, id, 'assistant: issued callback with outcome:', outcome);
  }).catch((err: Error) => {
    logger.error(sess, id, `assistant: error issuing callback, err: ${err} outcome: ${outcome}`);
    addRpcError('assistant callback failed', err);
  });
}

function onAction(sess: string, id: string, action: assistpb.Action) {
  const params: any[] = [];
  if (action.params) {
    action.params.forEach((val: string) => {
      params.push(JSON.parse(val));
    });
  }
  const outcome = new assistpb.Outcome();
  outcome.session = sess;
  outcome.request = id;

  logger.info(sess, id, `assistant: run action=${action.name} params=${params}`);
  const macro = MacroMap.get(action.name);
  if (!macro) {
    outcome.result.case = 'err';
    outcome.result.value = `error: action ${action.name} is not defined`;
    outcomeCb(outcome);
    return;
  }
  macro(...params)
    .then((res: any) => {
      if (typeof res !== 'undefined') {
        outcome.result.case = 'val';
        outcome.result.value = JSON.stringify(res);
      }
      outcomeCb(outcome);
    })
    .catch((err: Error) => {
      let stack = err.stack;
      if (stack && stack.length > 200) {
        stack = `${stack.substring(0, 200)}...`;
      }
      outcome.result.case = 'err';
      outcome.result.value = `error: action:${action.name} params:${params} err:${err} at:${stack}`;
      outcomeCb(outcome);
    });
}

function getLcSession2(): string {
  const ls = localStorage.getItem(sessionKey);
  if (ls) {
    return ls;
  }
  logger.warn(`unexpected missing ${sessionKey} in localStorage`);
  const parts = document.cookie.split('; ');
  const kvlist = parts.map((ele) => ele.split('='));
  const cookies = Object.fromEntries(kvlist);
  return cookies[sessionKey];
}

// this needs to be replaced with a proper api target derived from terraform
function getSdkAccess(): string {
  const url = new URL(analyzerServer);
  let target;
  let verify;
  switch (url.hostname) {
    case 'app.luminarycloud.com':
      target = `apis.luminarycloud.com`;
      verify = true;
      break;
    case 'test0.int.luminarycloud.com':
      target = `apis.test0.int.luminarycloud.com`;
      verify = true;
      break;
    case 'main.int.luminarycloud.com':
      target = `apis.main.int.luminarycloud.com`;
      verify = true;
      break;
    case 'localhost':
      target = `localhost`;
      verify = false;
      break;
    default:
      target = `apis-${url.hostname}`;
      verify = false;
      if (!target.endsWith('.int.luminarycloud.com')) {
        throw new Error(`unable to find apiserver for unknown analyzer ${analyzerServer}`);
      }
  }
  const token = getLcSession2();
  return JSON.stringify({ token, target, verify });
}

export async function GetChatSessions(scope: string): Promise<string[]> {
  const req = new assistpb.GetChatSessionsRequest({ scope });
  const resp = await rpc.callRetry(
    'GetChatSessions',
    rpc.client.getChatSessions,
    req,
  );
  return resp.sessionIds;
}

export async function NewChatSession(scope: string): Promise<string> {
  const req = new assistpb.NewChatSessionRequest({ scope });
  const resp = await rpc.callRetry(
    'NewChatSession',
    rpc.client.newChatSession,
    req,
  );
  return resp.sessionId;
}

export async function GetChatHistory(
  session: string,
  scope: string,
): Promise<assistpb.ChatHistoryEntry[]> {
  const req = new assistpb.GetChatHistoryRequest({ session, scope });
  const resp = await rpc.callRetry(
    'GetChatHistory',
    rpc.client.getChatHistory,
    req,
  );
  return resp.entries;
}

export async function Assist(
  scope: string,
  sessionId: string,
  id: string,
  cmd: string,
  responseCb: (conv: string) => void,
): Promise<boolean> {
  const sess = sessionId;
  console.debug(`Assist: sessionId= ${sess}, id=${id}, cmd=${cmd}`);
  logger.info(sess, id, 'assistant: starting session');
  isAssistantResponding.next(true);
  // RPC errors are automatically retried by the rpcPool, so this function never invokes reject.
  return new Promise<boolean>((resolve: (ok: boolean) => void, reject: (err: Error) => void) => {
    logger.info(sess, id, 'assistant: starting rpc');
    let done = false;
    const cancelRpc = rpcPool.start(
      sess /* rpc key */,
      () /* onrequest */ => {
        const req = new assistpb.Request();
        req.scope = scope;
        req.session = sess;
        req.request = id;
        req.cmd = cmd;
        req.access = getSdkAccess();
        logger.info(sess, id, 'assistant: sent command:', req);
        return req;
      },
      (reply: assistpb.Reply) /* onreply */ => {
        if (done) {
          return;
        }
        logger.info(sess, id, 'assistant: got reply:', JSON.stringify(reply));
        switch (reply.step.case) {
          case 'action':
            logger.info(sess, id, 'assistant: running action:', reply.step.value);
            onAction(sess, id, reply.step.value as assistpb.Action);
            break;
          case 'response':
            logger.info(sess, id, 'assistant: showing response:', reply.step.value);
            responseCb(reply.step.value as string);
            break;
          default:
            logger.debug(sess, id, 'assistant: ignored esponse case:', reply.step.case);
            break;
        }
        if (reply.last) {
          logger.info(sess, id, 'assistant: session completed');
          done = true;
          cancelRpc();
          resolve(true);
        }
      },
      (err: ConnectError) /* onerror */ => {
        if (done) {
          return;
        }
        logger.info(sess, id, 'assistant: session ended by rpc error:', err);
        addRpcError('assistant request rpc failed', err);
        done = true;
        reject(err);
      },
      undefined,
      () => {
        console.debug('Assist onStop called');
        isAssistantResponding.next(false);
      },
    );
  });
}

export async function ping() {
  const id = `id:${Date.now()}:${Math.random()} `;
  logger.info(`assistant: pinging(id: ${id})`);
  await Assist('', '', id, '/ping', (val: string) => {
    logger.info(`assistant: ping response: ${val} `);
  });
}

export async function pong(id: string): Promise<string> {
  logger.info(`assistant: action complete: pong(id: ${id})`);
  return '/pong';
}

// TODO(siri): Below is a temporary stub to help debug backend until we have UI in place
export function debugAssistant() {
  MacroMap.set('/ping', ping);
  MacroMap.set('/pong', pong);
  (<any>window).Assistant = { MacroMap, Assist };
}
