// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useState } from 'react';

import { ActionLink } from '../../components/Button/ActionLink';
import AuthForm from '../../components/Form/AuthForm';
import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { AuthState } from '../../lib/AuthInfoCallback';
import { setAuthInfoV2 } from '../../lib/AuthInfoCallbackV2';
import { EVAL_URL, PRIVACY_URL, TOS_URL } from '../../lib/constants';
import { getUserErrorMessage } from '../../lib/errors';
import { loadSessionJwt } from '../../lib/jwt';
import * as rpc from '../../lib/rpc';
import * as frontendpb from '../../proto/frontend/frontend_pb';

export const NewUserTos = () => (
  <span>
    I agree to the Luminary Cloud <ActionLink href={TOS_URL}>Terms of Service</ActionLink>,
    <ActionLink href={PRIVACY_URL}>Privacy Policy</ActionLink>,
    and <ActionLink href={EVAL_URL}>Evaluation Order Form</ActionLink>.
  </span>
);

// This page is for accepting the terms only for new users trying to authenticate with Google.
// Users activating with email/password will accept terms in the Activate page where they have
// a checkbox as part of the main form.
// Re-accepting the terms also happens in another page.
const AcceptTermsGoogleActivation = () => {
  // State
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      const req = new frontendpb.AcceptTermsRequest({ isNewUser: true });
      await rpc.callRetry('AcceptTerms', rpc.client.acceptTerms, req);
      const jwt = loadSessionJwt();
      if (jwt !== null) {
        jwt['https://luminarycloud.com/new_user'] = false;
      }
      setAuthInfoV2({
        authState: AuthState.AUTHENTICATED,
        jwt,
      });
    } catch (err) {
      const tosErrorMessage = 'Accepting the terms of service failed. Please try again.';
      setError(getUserErrorMessage(err, tosErrorMessage));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AuthPageLayout
      title="Complete Account Activation">
      <AuthForm
        contained
        fields={[
          {
            label: <NewUserTos />,
            dataLocator: 'accept-terms-of-service-checkbox',
            disabled: submitting,
            name: 'consent',
            required: true,
            type: 'checkbox',
          },
        ]}
        globalError={error}
        onSubmit={handleSubmit}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Agree and Activate Account',
        }}
      />
    </AuthPageLayout>
  );
};

export default AcceptTermsGoogleActivation;
