// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { Suspense, useEffect, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import ProjectStateSync from '../components/RecoilSync/ProjectState';
import suspenseWidget from '../components/SuspenseWidget';
import Project from '../components/context/ProjectContext';
import { MainPageLayout } from '../components/layout/page/Main';
import { CurrentView } from '../lib/componentTypes/context';
import { geometryLink, physicsLink } from '../lib/navigation';
import { ProjectParams } from '../lib/routeParamTypes';
import { GetProjectReply } from '../proto/frontend/frontend_pb';
import { useLoadCameraList } from '../recoil/cameraState';
import { meshUrlState } from '../recoil/meshState';
import { geometryPendingState } from '../recoil/pendingWorkOrders';
import { selectedGeometryState } from '../recoil/selectedGeometry';
import useProjectMetadata from '../recoil/useProjectMetadata';
import { useSetCurrentView } from '../state/internal/global/currentView';
import { useWorkflowFlagValue } from '../workflowFlag';

import PageBody from './PageBody';

/**
 * Displays status of one project.
 */
const ProjectPage = () => {
  const params = useParams<ProjectParams>();
  const projectId = params.projectId || '';
  const cameraKey = { projectId, workflowId: '', jobId: '' };
  const projectMetadata = useProjectMetadata(projectId);
  const navigate = useNavigate();

  const loadCameraList = useLoadCameraList(cameraKey);
  const setCurrentView = useSetCurrentView();
  const workflowFlag = useWorkflowFlagValue();

  // At this point, projectMetadata may be null since its result comes from a streaming RPC. To
  // circumvent this, we will only assess the need to redirect to the different tabs once
  // projectMetadata !== null.
  const projectMetadataRef = useRef<GetProjectReply | null>(null);
  const handleNavigation = useRecoilCallback(
    ({ snapshot: { getPromise } }) => async () => {
      const mesh = await getPromise(meshUrlState(projectId));
      const selectedGeometry = await getPromise(selectedGeometryState(projectId));
      const hasImport = await getPromise(geometryPendingState(projectId));
      const hasInitiatedLoadToSetup = !!selectedGeometry.geometryId;
      const hasMeshGeo = !!mesh.mesh || !!mesh.geometry;
      const shouldRedirectToGeometry = !hasInitiatedLoadToSetup && !hasMeshGeo;
      if (shouldRedirectToGeometry && !hasImport) {
        navigate(geometryLink(projectId));
      } else if (workflowFlag) {
        // This makes the project page into a dummy page to just redirect either
        // to the geometry page or to the physics page:
        navigate(physicsLink(projectId));
      } else {
        setCurrentView(CurrentView.SETUP);
      }
    },
  );

  // We should get a fresh state of the cameras everytime we open the project page because otherwise
  // we might get a stale data (it happens when we open a project and populate its camera recoil,
  // then update the global cameras in another project and return to the first project).
  useEffect(() => {
    if (projectId) {
      loadCameraList().catch((error) => { });
    }
  }, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // If user has not pushed geometry to the Setup tab, redirect to the Geometry tab. Note that
    // projectMetadata may be null and we don't want to rerun all of this redirects after changes
    // to the streamed projectMetadata values.
    if (projectMetadataRef.current === null && projectMetadata) {
      handleNavigation().catch((err) => console.error(err));
      projectMetadataRef.current = projectMetadata;
    }
  }, [setCurrentView, projectMetadata, handleNavigation]);

  // Don't display the page until all the project data has loaded.
  const projectName = projectMetadata?.summary!.name || '';

  return (
    <MainPageLayout projectId={projectId} title={projectName}>
      <Suspense fallback={suspenseWidget}>
        {projectMetadata && (
          <ProjectStateSync projectId={projectId}>
            <Project
              projectId={projectId}
              selectedJobId=""
              workflowId="">
              <PageBody
                isExploration={false}
              />
            </Project>
          </ProjectStateSync>
        )}
      </Suspense>
    </MainPageLayout>
  );
};

export default ProjectPage;
