import { useCallback, useEffect, useMemo, useRef } from 'react';

import { getDefaultAdjoint } from '../../lib/adjointUtils';
import { CurrentView } from '../../lib/componentTypes/context';
import { isPrimalSimulation } from '../../lib/simulationParamUtils';
import { workflowHasAdjointJobConfig } from '../../lib/workflowUtils';
import { useFixExistingSolution } from '../../model/hooks/useExistingSolution';
import { useWorkflowConfig } from '../../model/hooks/useWorkflowConfig';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useSetEntitySelection } from '../../recoil/selectionOptions';
import useProjectMetadata, { } from '../../recoil/useProjectMetadata';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useCurrentView, useIsAdjointSetup } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';

// Returns a hook that performs some operations when changing the current view/tab.
export const useSwitchPage = () => {
  const { projectId } = useProjectContext();
  const setSelectionType = useSetEntitySelection(projectId);
  const currentView = useCurrentView();
  const refCurrentView = useRef(currentView);
  const isAdjointSetup = useIsAdjointSetup();
  const projectMetadata = useProjectMetadata(projectId);

  const lastAdjointWorkflow = useMemo(() => {
    const adjointWorkflows = (projectMetadata?.workflow ?? []).filter(workflowHasAdjointJobConfig);

    return adjointWorkflows.length ?
      adjointWorkflows.sort((a, b) => b.creationTime - a.creationTime)[0] :
      undefined;
  }, [projectMetadata?.workflow]);

  // We dont't want to use the workflowId and jobId in these hooks because we want to check and
  // update the setup configs (not the simulation configs)
  const setupParam = useSimulationParam(projectId, '', '');
  const { saveParam } = useWorkflowConfig(projectId, '', '', false);
  const fixExistingSolution = useFixExistingSolution(projectId, '', '', false);

  // As per LC-22869, the desired behavior is to set the selection type to 'none' when switching to
  // the analysis page. If the views are the same, the selection type should not be changed. If
  // going from Analysis into something else, switch back to surfaces unless the selection type
  // is already set to something else that's not none. This is because in the setup phases, it is
  // useful to have selections ON by default.
  const onSwitchPage = useCallback((oldView: CurrentView, newView: CurrentView) => {
    const bothViewsTheSame = oldView === newView;
    if (oldView !== CurrentView.ANALYSIS && newView === CurrentView.ANALYSIS) {
      setSelectionType('surface_no_highlight');
    } else if (!bothViewsTheSame) {
      setSelectionType((oldSelectionType) => {
        if (oldSelectionType === 'surface_no_highlight') {
          return 'surface';
        }
        return oldSelectionType;
      });
    }
    return newView;
  }, [setSelectionType]);

  // Run this only when mounting, this makes sure that we set the correct default. We could also
  // move this into recoil maybe.
  useEffect(() => {
    if (currentView === CurrentView.ANALYSIS) {
      setSelectionType('surface_no_highlight');
      return;
    }
    setSelectionType('surface');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // An effect to manage some config when we open/close the Setup Adjoint page
  useEffect(() => {
    // Opening the Setup Adjoint page will:
    // - set the Simulation type to Adjoint;
    // - set the adjoint configs from the last created adjoint worklfow;
    // - update the solution/init to make sure we don't have errors when attempting to run the sim.
    if (isAdjointSetup && isPrimalSimulation(setupParam)) {
      saveParam((newParam) => {
        if (newParam.general) {
          newParam.general.floatType = simulationpb.FloatType.ADA1D;
        }

        const lastAdjointJobConfig = lastAdjointWorkflow?.config?.jobConfigTemplate;
        if (lastAdjointJobConfig?.typ.case === 'simulationParam') {
          const lastAdjoint = lastAdjointJobConfig.typ.value?.adjoint;
          if (lastAdjoint && !setupParam.adjoint?.adjointOutput?.id) {
            newParam.adjoint = lastAdjoint.clone();
          }

          lastAdjointJobConfig.typ.value.physics.forEach((lastAdjointPhysics) => {
            newParam.physics.forEach((physics) => {
              if (physics.physicsIdentifier?.id === lastAdjointPhysics.physicsIdentifier?.id) {
                if (physics.params.case === 'fluid' &&
                  lastAdjointPhysics.params.case === 'fluid') {
                  physics.params.value.adjointControlsFluid =
                    lastAdjointPhysics.params.value.adjointControlsFluid;
                }
                if (physics.params.case === 'heat' &&
                  lastAdjointPhysics.params.case === 'heat') {
                  physics.params.value.adjointControlsHeat =
                    lastAdjointPhysics.params.value.adjointControlsHeat;
                }
              }
            });
          });
        }
      });

      setupParam.physics.forEach((physics) => {
        if (physics.physicsIdentifier?.id) {
          fixExistingSolution(physics.physicsIdentifier.id);
        }
      });
    }

    // Closing the Setup Adjoint page will revert the simulation type (floatType) back to Primal and
    // reset the adjoint configs (they are probably ignored anyway but there's no need to keep them)
    if (!isAdjointSetup && !isPrimalSimulation(setupParam)) {
      saveParam((newParam) => {
        if (newParam.general) {
          newParam.general.floatType = simulationpb.FloatType.DOUBLE;
        }
        newParam.adjoint = getDefaultAdjoint();
      });
    }
  }, [
    isAdjointSetup,
    setupParam,
    fixExistingSolution,
    saveParam,
    lastAdjointWorkflow?.config?.jobConfigTemplate,
  ]);

  useEffect(() => {
    refCurrentView.current = onSwitchPage(refCurrentView.current, currentView);
  }, [currentView, onSwitchPage]);
};
