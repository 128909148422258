// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useRef, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import cx from 'classnames';

import { bladeLocalAngleOfAttack, bladeSectionalDragCoefficient, bladeSectionalLiftCoefficient, thrustPerUnitArea, torquePerUnitArea } from '../../QuantityDescriptor';
import { colors } from '../../lib/designSystem';
import Form from '../Form';
import { DataSelectKind } from '../Form/DataSelect';
import { CommonMenu } from '../Menu/CommonMenu';
import { createStyles, makeStyles } from '../Theme';
import { ChevronDownIcon } from '../svg/ChevronDownIcon';

import { groupVariables } from './groupVariables';

const diskDataNames = [
  thrustPerUnitArea.text,
  torquePerUnitArea.text,
  bladeLocalAngleOfAttack.text,
  bladeSectionalDragCoefficient.text,
  bladeSectionalLiftCoefficient.text,
];

const NONE_OPTION = 'None';

const useStyles = makeStyles(
  () => createStyles({
    dropdown: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '8px',
      backgroundColor: colors.neutral450,
      boxShadow: `0px 1px 0px 0px rgba(24, 25, 30, 0.16),
        0px 1px 0px 0px rgba(244, 244, 247, 0.16) inset`,
      border: 0,
      borderRadius: '4px',
      color: colors.highEmphasisText,
      cursor: 'pointer',
      transition: 'background-color 250ms, box-shadow 1000ms, outline 1000ms',

      '&.block': {
        width: '100%',
      },
      '&.small': {
        padding: '6px 8px',
        fontSize: '13px',
      },
      '&.medium': {
        padding: '8px 12px',
        fontSize: '14px',
        lineHeight: '20px',
      },
      '&.minimal': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&:hover': {
        backgroundColor: colors.neutral550,
      },
    },
    label: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    block: {
      display: 'block',
    },
    iconWrapper: {
      display: 'flex',
      minWidth: '8px',
    },
  }),
  { name: 'DataNameSelect' },
);

export interface DataNameSelectProps extends Pick<Parameters<
  typeof CommonMenu>[0],
  'position' | 'positionTransform' | 'innerMenuPosition' | 'innerMenuPositionTransform'
> {
  options: string[];
  // The current display variable. If not provided, the current name defaults to None
  value: string;
  // Called with the display variable name to display. dataName is always nonempty.
  onChange: (dataName: string) => void;
  // Whether to disable/gray out the data name selector.
  disabled?: boolean;
  // Whether to display the select as a full row with a label.
  fullRow?: boolean;
  kind?: DataSelectKind;
  size?: 'small' | 'medium';
  asBlock?: boolean;
  locator?: string;
  /** Determines whether the "None" option is displayed, defaults to `true` */
  displayNoneOption?: boolean;
}

const isDiskName = (name: string) => diskDataNames.some((diskName) => name.includes(diskName));

// Draw a dropdown menu that selects data to be displayed,
// e.g., density, pressure, velocity.
const DataNameSelect = (props: DataNameSelectProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  if (!props.options?.length) {
    return null;
  }

  // Figure out if the current selected data name is available in the solution file.
  // If that's not the case, then select the first available variable name.
  // This happens because the variables available in the solution files depend on the
  // simulation configuration and because we share the viewwAttrs between simulations in
  // a same project.
  // TODO(vis): consider removing this if we assign an independent viewAttrs to each simulation
  // tab.
  let currentValue = props.value || NONE_OPTION;

  const hasCurrentValue = props.options.includes(currentValue) || currentValue === NONE_OPTION;
  if (!hasCurrentValue) {
    currentValue = props.options[0];
    props.onChange(currentValue);
  }

  const options = [
    ...props.options,
    ...(props.displayNoneOption === false ? [] : [NONE_OPTION]),
  ].map((name) => ({
    name: isDiskName(name) ? `${name} (Disk)` : `${name}`,
    value: name,
    selected: name === currentValue,
  }));

  const groupedOptions = groupVariables(options);
  const currentlySelectedOption = options.find(({ selected }) => selected);
  const size = props.size || 'small';
  const isMinimal = props.kind === 'minimal';

  const getOptionPicker = (name: string) => () => {
    props.onChange(name);
    setOpen(false);
  };

  const select = (
    <>
      <CommonMenu
        anchorEl={buttonRef.current}
        closeDelay={60}
        flushAlign
        innerMenuPosition={props.innerMenuPosition}
        innerMenuPositionTransform={props.innerMenuPositionTransform}
        maxHeight={600}
        menuItems={Object.entries(groupedOptions)
          .sort(([aCategory], [bCategory]) => {
            // ensure NONE_OPTION is pushed to the right side
            if (aCategory === NONE_OPTION) {
              return 1;
            }
            if (bCategory === NONE_OPTION) {
              return -1;
            }

            return aCategory.localeCompare(bCategory);
          })
          .map(([category, categoryItems]) => {
            if (categoryItems.length === 1 && categoryItems[0].name === category) {
              return {
                label: category,
                onClick: getOptionPicker(category),
                selected: category === currentValue,
              };
            }

            return {
              label: category,
              onClick: () => {},
              selected: false,
              items: categoryItems
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => ({
                  label: item.name,
                  onClick: getOptionPicker(item.value),
                  selected: item.name === currentValue,
                })),
            };
          })}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        position={props.position}
        positionTransform={props.positionTransform}
      />
      <div
        aria-disabled={props.disabled}
        className={cx(
          'dataSelect', // locator for the selenium tests
          classes.dropdown,
          size,
          isMinimal && 'minimal',
          props.asBlock && 'block',
        )}
        data-locator={props.locator}
        onClick={() => {
          if (!props.disabled) {
            setOpen(true);
          }
        }}
        onKeyDown={() => {
          //  empty handler so that a11y eslint rules are not listed
        }}
        ref={buttonRef}
        role="button"
        tabIndex={0}>
        <span className={classes.label}>
          {currentlySelectedOption?.name || NONE_OPTION}
        </span>
        <div className={classes.iconWrapper}>
          <ChevronDownIcon maxWidth={8} />
        </div>
      </div>
    </>
  );

  if (props.fullRow) {
    return (
      <Form.LabeledInput label="Color By">
        {select}
      </Form.LabeledInput>
    );
  }
  return <FormControl className={cx(props.asBlock && classes.block)}>{select}</FormControl>;
};

export default DataNameSelect;
