// Copyright 2020-2021 Luminary Cloud, Inc. All Rights Reserved.

// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/userstate/userstate.proto (package luminary.proto.userstate, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * SystemRole determines the actions a user can make on the system as a whole.
 * Corresponds to "luminarycloud.com/system_role" field in auth0.
 * For more details, see go/core/jwt/jwt.go
 *
 * @generated from enum luminary.proto.userstate.SystemRole
 */
export const SystemRole = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.userstate.SystemRole",
  [
    {no: 0, name: "USER"},
    {no: 1, name: "STAFF"},
    {no: 2, name: "ADMIN"},
  ],
);

/**
 * Metadata for an experiment.  The same as frontendpb.Experiment,
 * except includes the option to override.
 *
 * @generated from message luminary.proto.userstate.ExperimentMeta
 */
export const ExperimentMeta = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.userstate.ExperimentMeta",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "override", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * ExperimentPreferences stores the user's experiment preferences.
 *
 * @generated from message luminary.proto.userstate.ExperimentPreferences
 */
export const ExperimentPreferences = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.userstate.ExperimentPreferences",
  () => [
    { no: 1, name: "experiments", kind: "map", K: 4 /* ScalarType.UINT64 */, V: {kind: "message", T: ExperimentMeta} },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Role for the user to view the frontend. This role is never sent to or
 * used by the backend, for security purposes. It only determines what frontend
 * features are or are not shown.
 *
 * @generated from message luminary.proto.userstate.FrontendRole
 */
export const FrontendRole = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.userstate.FrontendRole",
  () => [
    { no: 1, name: "role", kind: "enum", T: proto3.getEnumType(SystemRole) },
  ],
);

/**
 * @generated from message luminary.proto.userstate.FavoriteEntities
 */
export const FavoriteEntities = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.userstate.FavoriteEntities",
  () => [
    { no: 1, name: "simulations", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

