import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import * as persist from '@/lib/persist';
import { syncUserStateEffect } from '@/lib/recoilSync';
import * as userstatepb from '@/proto/userstate/userstate_pb';

const favoriteEntitiesKey = 'favoriteEntities';

function serialize(val: userstatepb.FavoriteEntities) {
  return new userstatepb.FavoriteEntities({ simulations: val.simulations }).toBinary();
}

function deserialize(val: Uint8Array) {
  if (val.length) {
    const favoriteEntities = userstatepb.FavoriteEntities.fromBinary(val);

    return favoriteEntities;
  }

  return new userstatepb.FavoriteEntities({ simulations: [] });
}

export const favoriteEntitiesState = atom<userstatepb.FavoriteEntities>({
  key: 'favoriteEntities',
  effects: [
    syncUserStateEffect(favoriteEntitiesKey, deserialize, serialize),
    persist.getUserStateEffect(favoriteEntitiesKey, deserialize),
  ],
  // protobufs can modify themselves, even in get*.
  dangerouslyAllowMutability: true,
});

export const useFavoriteEntitiesValue = () => useRecoilValue(favoriteEntitiesState);
export const useSetFavoriteEntities = () => useSetRecoilState(favoriteEntitiesState);
export const useFavoriteEntities = () => useRecoilState(favoriteEntitiesState);
