// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { NodeType } from '../lib/simulationTree/node';

// Configure which nodes may be sub-selected and optionally add tooltips to either allowed or
// disallowed nodes
export interface NodeFilterResult {
  // Is the node related to the subselection?  When `related` is true, the optional `disabled` and
  // `tooltip` values may be applied to the row.  Otherwise, the row will remain in its normal
  // state.
  related: boolean;
  // Should selection of the node be disabled
  disabled?: boolean;
  // Optional tooltip to show for node
  tooltip?: string;
  // If true, the node will not be be allowed to be unassigned from the subselection. The reason
  // to be disabled should be set in tooltip.
  disableUnassigment?: boolean;
}

export type NodeFilter = (type: NodeType, id: string) => NodeFilterResult;

export interface SimulationSubselect {
  // Is subselect active or not
  active: boolean;
  // A unique identifier for the subselect state,
  id: string;
  // Filter tree nodes for selection eligibility
  nodeFilter: NodeFilter;
  onChange: (nodeIds: string[]) => void;
  // A list of node IDs from which subselect originated
  referenceNodeIds: string[];
  // By default, the selection of tree nodes with children is derived from the children's selection
  // state.  Set 'independentSelection' to true to decouple parent/child selection.  For instance,
  // if surfaces and surface groups may be selected separately and independently.
  independentSelection: boolean;
  // If set, when the subselect is active, only the set NodeTypes will be visible in the Geometry
  // tree and all other nodes will be hidden
  visibleTreeNodeTypes: NodeType[];
}

export const simulationTreeSubselectState = atom<SimulationSubselect>({
  key: 'simulationTreeSubselect',
  default: {
    active: false,
    id: '',
    nodeFilter: () => ({ related: false }),
    referenceNodeIds: [],
    independentSelection: true,
    visibleTreeNodeTypes: [],
    onChange: () => { },
  },
  dangerouslyAllowMutability: true,
});

interface SelectedVisualizerEntities {
  ids: string[];
}

export const selectedVisualizerEntities = atom<SelectedVisualizerEntities | null>({
  key: 'simulationTreeSubselectSelectedVisualizerEntities',
  default: null,
});

export function useSimulationTreeSubselect() {
  return useRecoilValue(simulationTreeSubselectState);
}

export function useSetSimulationTreeSubselect() {
  return useSetRecoilState(simulationTreeSubselectState);
}

export function useResetSimulationTreeSubselect() {
  return useResetRecoilState(simulationTreeSubselectState);
}

export function useSelectedVisualizerEntities() {
  return useRecoilState(selectedVisualizerEntities);
}

export function useSetSelectedVisualizerEntities() {
  return useSetRecoilState(selectedVisualizerEntities);
}
