// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { isGroupVisible, toggleVisibility } from '../../../lib/entityGroupUtils';
import { assembleMenuSections } from '../../../lib/menuUtil';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem, visibilityToggleTreeNodeMenuItem } from '../../../lib/treeUtils';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useCopySimAnnotation } from '../../hooks/nodeDuplication/useCopySimAnnotation';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'parallelogramOutline' };

// A row in the simulation tree representing a monitor plane
export const MonitorPlaneTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { viewState, visibilityMap, setVisibility } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteMonitorPlaneNode, postDeleteNodeIds } = useNodeDeletion();
  const duplicateRow = useCopySimAnnotation();

  // == Data
  const isVisible = isGroupVisible(visibilityMap, entityGroupMap, node.id);
  const isSelected = selectedNodeIds.includes(props.node.id);

  const toggleVis = useCallback(
    () => {
      const toggleIds = new Set(isSelected ? selectedNodeIds : [node.id]);
      setVisibility(toggleVisibility(visibilityMap, entityGroupMap, toggleIds, !isVisible));
    },
    [isSelected, selectedNodeIds, node.id, visibilityMap, entityGroupMap, isVisible, setVisibility],
  );

  const deleteRow = useCallback(async () => {
    if (await deleteMonitorPlaneNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteMonitorPlaneNode, node.id, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => {
    const visItems = [visibilityToggleTreeNodeMenuItem(isVisible, toggleVis, !viewState)];

    const crudDisabled = !canDelete(node.type, node.id);
    const crudItems = [
      duplicateTreeNodeMenuItem(() => duplicateRow(node.id, 'plane'), crudDisabled),
      deleteTreeNodeMenuItem(deleteRow, crudDisabled),
    ];

    return assembleMenuSections(visItems, crudItems);
  }, [isVisible, toggleVis, viewState, canDelete, deleteRow, node.id, node.type, duplicateRow]);

  const visButton = (
    <VisibilityButton disabled={!viewState} isVisible={isVisible} onClick={toggleVis} />
  );

  return (
    <TreeRow
      {...props}
      canMultiSelect
      getContextMenuItems={getContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      propertiesControl
      renaming={renaming}
      visibilityButton={visButton}
    />
  );
};
