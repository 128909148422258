import { LCVMapMode, LCVType } from '@luminarycloudinternal/lcvis';
import { deepEqual } from 'fast-equals';

import { LcvModule } from '../../types';

import { LcvFilter } from './LcvFilter';
import { IntersectionCurveState } from './filterUtils';

export class LcvIntersectionCurve extends LcvFilter {
  parent: LcvFilter;
  state: IntersectionCurveState;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    workspaceHandle: number,
    id: string,
    parent: LcvFilter,
    initialState: IntersectionCurveState,
  ) {
    super(
      lcv,
      lcv.newFilter(
        sessionHandle,
        workspaceHandle,
        'intersection_curve',
        `intersection_curve_${id}`,
        0,
      ).filter,
      sessionHandle,
      workspaceHandle,
      'intersection_curve',
      id,
    );

    this.parent = parent;
    this.state = initialState;

    this.lcv.connectFilters(sessionHandle, workspaceHandle, parent.handle, this.handle);

    this.updateParams();
  }

  updateParams() {
    const { normal, origin, surfaces } = this.state;

    const surfaceNames = this.lcv.writeStringArray(
      this.sessionHandle,
      surfaces,
    );

    this.setParam('origin', LCVType.kLCVDataTypeFloat3, origin);
    this.setParam('normal', LCVType.kLCVDataTypeFloat3, normal);
    this.setParam('surface_names', LCVType.kLCVDataTypeData1D, surfaceNames);
  }

  setState(newState: IntersectionCurveState) {
    if (deepEqual(this.state, newState)) {
      return;
    }

    this.state = newState;
    this.updateParams();
  }

  getLinePrimitiveIndexes() {
    const totalLines = this.getNumLines();

    if (totalLines === 0) {
      return [];
    }

    const firstLineIndex: number = this.getFirstLineIndex();

    return [...Array(totalLines).keys()].map((index) => index + firstLineIndex);
  }

  getPrimitivePositions(primitiveIndex: number) {
    const positions = this.lcv.getPrimitivePositions(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      primitiveIndex,
      0,
    ).positions;

    const { mapping, size } = this.lcv.mapData(
      this.sessionHandle,
      positions,
      LCVMapMode.kLCVMapModeRead,
      0,
      0,
    );

    const positionsArray = new Float32Array(this.lcv.memory(), mapping, size * 3);

    const resultArray: { x: number; y: number; z: number }[] = [];
    for (let index = 0; index < positionsArray.length; index += 3) {
      const x = positionsArray.at(index);
      const y = positionsArray.at(index + 1);
      const z = positionsArray.at(index + 2);

      if (x !== undefined && y !== undefined && z !== undefined) {
        resultArray.push({ x, y, z });
      }
    }

    this.lcv.unmapData(this.sessionHandle, positions);
    this.lcv.release(this.sessionHandle, positions, 0);

    return resultArray;
  }

  getFieldValues(fieldName: string, primitiveIndex: number) {
    const data = this.lcv.getPrimitiveField(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      primitiveIndex,
      fieldName,
      0,
    ).field;

    const { mapping, size } = this.lcv.mapData(
      this.sessionHandle,
      data,
      LCVMapMode.kLCVMapModeRead,
      0,
      0,
    );
    const fieldDataArray = new Float32Array(this.lcv.memory(), mapping, size);
    const fieldData = [...fieldDataArray];

    this.lcv.unmapData(this.sessionHandle, data);
    this.lcv.release(this.sessionHandle, data, 0);

    return fieldData;
  }
}
