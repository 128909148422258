// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect } from 'react';

import * as flags from '../../../flags';
import { CurrentView } from '../../../lib/componentTypes/context';
import { isGeometryFile } from '../../../lib/upload/uploadUtils';
import { useMeshUrlState } from '../../../recoil/meshState';
import { useSelectedGeometry } from '../../../recoil/selectedGeometry';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import { MeshPanelType, useMeshPanelState, useSetMeshPanelState } from '../../../recoil/useMeshPanelState';
import { useHasActiveMesh } from '../../../recoil/useProjectActiveMesh';
import { useCurrentView } from '../../../state/internal/global/currentView';
import { useProjectContext } from '../../context/ProjectContext';

import { MeshDetailsPanel } from './mesh/panels/MeshDetailsPanel';
import { MeshEditPanel } from './mesh/panels/MeshEditPanel';
import { MeshSelectPanel } from './mesh/panels/MeshSelectPanel';

// A panel for displaying the mesh statistics and the mesh generation.
export const MultiMeshParentPanel = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const currentView = useCurrentView();
  const remeshingEnabled = useIsEnabled(flags.remeshing);
  const meshPanelState = useMeshPanelState(projectId);
  const setMeshPanelState = useSetMeshPanelState(projectId);
  const hasActiveMesh = useHasActiveMesh(projectId);
  const [meshUrlState] = useMeshUrlState(projectId);
  const [selectedGeometry] = useSelectedGeometry(projectId, workflowId, jobId);
  const isMeshable = isGeometryFile(meshUrlState.url) || !!selectedGeometry.geometryId;

  useEffect(() => {
    if (currentView === CurrentView.ANALYSIS) {
      setMeshPanelState(MeshPanelType.DETAILS);
    } else if (meshPanelState === MeshPanelType.DETAILS && !hasActiveMesh) {
      setMeshPanelState(MeshPanelType.SELECT);
    } else if (meshPanelState === MeshPanelType.EDIT && hasActiveMesh) {
      setMeshPanelState(MeshPanelType.DETAILS);
    }
  }, [meshPanelState, hasActiveMesh, setMeshPanelState, currentView]);

  if (!(isMeshable || remeshingEnabled)) {
    return <MeshDetailsPanel />;
  }

  let meshPanel = <></>;

  // MultiMesh Panel will have 3 unique states/panels:
  //    MeshSelect:   creating a new mesh or selecting an existing mesh
  //    MeshEdit:     editing the current mesh
  //    MeshDetails:  viewing details of a generated mesh
  switch (meshPanelState) {
    case (MeshPanelType.SELECT):
      meshPanel = <MeshSelectPanel />;
      break;
    case (MeshPanelType.EDIT):
      meshPanel = <MeshEditPanel />;
      break;
    case (MeshPanelType.DETAILS):
      meshPanel = <MeshDetailsPanel />;
      break;
    default:
      break;
  }

  return (
    <>
      {meshPanel}
    </>
  );
};
