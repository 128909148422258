// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { IconSpec } from '../../../lib/componentTypes/svgIcon';
import { colors } from '../../../lib/designSystem';
import { isGroupVisible } from '../../../lib/entityGroupUtils';
import { getAllAttachedDomains } from '../../../lib/motionDataUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import { getNodeTypeIcon } from '../../../lib/simulationTree/nodeIcon';
import { addRpcError } from '../../../lib/transientNotification';
import { mapDomainsToIds } from '../../../lib/volumeUtils';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useStaticVolume, useStaticVolumes } from '../../../recoil/volumes';
import environmentState from '../../../state/environment';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import VisibilityButton from '../../Paraview/VisibilityButton';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import CreateTagDialog from '../../dialog/CreateTag';
import { useCanModifyTags } from '../../hooks/useCanModifyTags';
import { useTagsInteractiveGeometry } from '../../hooks/useInteractiveGeometry';
import { useNodeDrop } from '../../hooks/useNodeDrop';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { useVolumeNodeRowMenu } from '../../hooks/useVolumeNodeRowMenu';
import { DraggableTreeRow } from '../DraggableTreeRow';

export const VolumeTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { visibilityMap, viewState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();
  const isGeometryView = useIsGeometryView();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const { onNodeDrop, destinationTag } = useNodeDrop(props.node);

  const selectedCoreNodeIds = selectedNodeIds.map((id) => geometryTags.getCoreNodeIdentifier(id));

  const isTagChild = node.type === NodeType.TAGS_BODY;
  // In some state transitions, the volume id may not be available. Avoid crashing in that case.
  const volumeId = ((isTagChild && geometryTags.tagIds().size) ?
    mapDomainsToIds(
      staticVolumes,
      [geometryTags.domainFromTagEntityGroupId(node.id) || ''],
    ).at(0) :
    node.id) || '';

  // == Hooks
  const renaming = useNodeRenaming(node);
  const staticVolume = useStaticVolume({ projectId, workflowId, jobId, volumeId });
  const { createTag, removeItemsFromTag } = useTagsInteractiveGeometry();

  const visControlsDisabled = lcvisEnabled ? !lcvisReady : !viewState;
  // Check if any of the volume's surfaces are visible.
  const isVisible = useMemo(
    () => [...(staticVolume?.bounds ?? [])].some((bound) => isGroupVisible(
      lcvisEnabled ? visibilityV2 : visibilityMap,
      entityGroupMap,
      bound,
    )),
    [entityGroupMap, lcvisEnabled, staticVolume, visibilityMap, visibilityV2],
  );

  // Get the surfaces for a given array of volume IDs.
  const getBounds = useCallback((nodeIds: string[]) => staticVolumes.reduce((result, volume) => {
    if (nodeIds.includes(volume.id)) {
      return new Set([...result, ...volume.bounds]);
    }
    return result;
  }, new Set<string>()), [staticVolumes]);

  // Toggle all the selected IDs, if this ID is selected.
  const isSelected = useMemo(
    () => selectedCoreNodeIds.includes(volumeId),
    [selectedCoreNodeIds, volumeId],
  );

  const bounds = useMemo(
    () => (isSelected ? getBounds(selectedCoreNodeIds) : getBounds([volumeId])),
    [isSelected, getBounds, volumeId, selectedCoreNodeIds],
  );
  const toggleVis = useToggleVisibility(bounds, isVisible);

  const {
    getContextMenuItems,
    isCreateTagDialogOpen,
    isCreateTagFacesDialogOpen,
    setIsCreateTagDialogOpen,
    setIsCreateTagFacesDialogOpen,
  } = useVolumeNodeRowMenu(volumeId, node, isVisible, visControlsDisabled, toggleVis);
  const canModifyTags = useCanModifyTags();

  const auxIcons: IconSpec[] = [];

  if (
    staticVolume &&
    getAllAttachedDomains(simParam, { motion: 'moving' }, geometryTags).has(staticVolume.domain)
  ) {
    auxIcons.push({
      name: 'rotatingDots',
      color: colors.citronGreen600,
    });
  }

  const visButton = !staticVolume ? undefined : (
    <VisibilityButton disabled={visControlsDisabled} isVisible={isVisible} onClick={toggleVis} />
  );

  const removeVolumeFromTag = useCallback(async () => {
    try {
      const domainId = geometryTags.domainFromTagEntityGroupId(node.id);
      await removeItemsFromTag(node.parent!.id, [], [`${domainId}`]);
    } catch (error) {
      addRpcError('Failed to change tag assignment', error);
    }
  }, [geometryTags, node.id, node.parent, removeItemsFromTag]);

  return (
    <>
      {/* Create tag from the volume */}
      {isCreateTagDialogOpen && (
        <CreateTagDialog
          isOpen={isCreateTagDialogOpen}
          onCancel={() => {
            setIsCreateTagDialogOpen(false);
          }}
          onSubmit={async (name) => {
            const nodeIds = isSelected ? selectedCoreNodeIds : [volumeId];
            await createTag(name, nodeIds);
            setIsCreateTagDialogOpen(false);
          }}
        />
      )}

      {/* Create tag from the volume faces */}
      {isCreateTagFacesDialogOpen && (
        <CreateTagDialog
          isOpen={isCreateTagFacesDialogOpen}
          onCancel={() => {
            setIsCreateTagFacesDialogOpen(false);
          }}
          onSubmit={async (name) => {
            if (staticVolume) {
              await createTag(name, Array.from(staticVolume.bounds));
            }
            setIsCreateTagFacesDialogOpen(false);
          }}
        />
      )}

      <DraggableTreeRow
        {...props}
        auxIcons={auxIcons}
        canMultiSelect
        dropDestinationTagNode={destinationTag}
        getContextMenuItems={getContextMenuItems}
        isDraggable={canModifyTags}
        manipulationType={isTagChild ? 'dragAndDrop' : 'dragOnly'}
        onNodeDrop={onNodeDrop}
        onRemoveZoneDrop={removeVolumeFromTag}
        primaryIcon={getNodeTypeIcon(props.node.type)}
        propertiesControl={!isGeometryView}
        renaming={!isGeometryView ? renaming : undefined}
        visibilityButton={visButton}
      />
    </>
  );
};
