// src/lib/assistantUtils.ts
import { IconName } from '@/lib/componentTypes/svgIcon';

// Types and constants
export type SuggestedCardItem = [string, IconName | undefined, number?];
export const DEFAULT_WEIGHT = 1;
export const MAX_SUGGESTED_ITEMS = 3;

/**
 * Select items randomly from an array using weighted probability.
 * Each item can have an optional weight as its third element.
 */
export const getRandomItems = (arr: SuggestedCardItem[], exclude: SuggestedCardItem[] = []) => {
  // Filter out already selected items
  const availableItems = arr.filter((item) => !exclude.some(([text]) => text === item[0]));

  // If no items available, return empty array
  if (availableItems.length === 0) {
    return [];
  }

  // Function to get the weight of an item
  const getWeight = (item: SuggestedCardItem): number => item[2] ?? DEFAULT_WEIGHT;

  const result: SuggestedCardItem[] = [];
  const tempItems = [...availableItems];

  // Select MAX_SUGGESTED_ITEMS weighted random items (or fewer if not enough available)
  while (result.length < MAX_SUGGESTED_ITEMS && tempItems.length > 0) {
    // Calculate total weight of remaining items
    const totalWeight = tempItems.reduce((sum, item) => sum + getWeight(item), 0);

    // Generate random value between 0 and total weight
    let randomValue = Math.random() * totalWeight;
    let selectedIndex = -1;

    // Find the item based on accumulated weights
    for (let i = 0; i < tempItems.length; i += 1) {
      randomValue -= getWeight(tempItems[i]);
      if (randomValue <= 0) {
        selectedIndex = i;
        break;
      }
    }

    // If no item selected (shouldn't happen, but just in case)
    if (selectedIndex === -1) {
      selectedIndex = 0;
    }

    // Add the selected item to results
    result.push(tempItems[selectedIndex]);

    // Remove the selected item from the pool
    tempItems.splice(selectedIndex, 1);
  }

  return result;
};
