import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import Draggable from 'react-draggable';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

import { ChatMessage } from '../../../lib/assistant/assistant';
import { colors } from '../../../lib/designSystem';
import { parseString } from '../../../lib/html';
import { useAssistantThinkingValue } from '../../../state/external/assistant/assistantThinking';
import { useCurrentView, useIsAdvancedAnalysisView, useIsAnalysisView } from '../../../state/internal/global/currentView';
import { ActionButton } from '../../Button/ActionButton';
import { TextInput } from '../../Form/TextInput';
import { createStyles, makeStyles } from '../../Theme';
import Tooltip from '../../Tooltip';
import { useAssistantSend } from '../../hooks/assistant/useAssistantSend';
import { PaperAirplaneIcon } from '../../svg/PaperAirplaneIcon';
import { SparkleDoubleIcon } from '../../svg/SparkleDoubleIcon';

import { NestedForm, NestedFormData } from './Chat/NestedForm';
import { NestedNodeLinks, NestedNodeLinksData } from './Chat/NestedNodeLinks';
import { SuggestedCard } from './Chat/SuggestedCard';
import { FEEDBACKS, ThumbsFeedback } from './Chat/ThumbsFeedback';

import { useProjectContext } from '@/components/context/ProjectContext';
import { useAddNewAssistantMessage } from '@/components/hooks/assistant/useAddNewAssistantMessage';
import { DEFAULT_WEIGHT, SuggestedCardItem, getRandomItems } from '@/components/project/assistant/utils';
import { AnimatedEllipsis } from '@/components/visual/AnimatedEllipsis';
import { Flex } from '@/components/visual/Flex';
import { CurrentView } from '@/lib/componentTypes/context';
import { NodeType } from '@/lib/simulationTree/node';
import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';
import { analytics } from '@/services/analytics';
import { useAssistantRespondingValue } from '@/state/external/assistant/assistantResponding';
import { MAX_MESSAGE_BOX_HEIGHT, MIN_MESSAGE_BOX_HEIGHT, useChatPanelHeight } from '@/state/internal/assistant/assistantSideRailSize';

type ChatProps = {
  messages: ChatMessage[];
  onSendMessage: (message: string) => void;
}

// These are custom nodes that can be passed in the response from the assistant. If they exist,
// we should parse a specially crafted component for each of them with the help of ReactMarkdown.
type CustomMarkdownComponents = ReactMarkdownOptions['components'] & {
  nodelinks?: React.ComponentType<{ children: string }>;
  parameterform?: React.ComponentType<{ children: string }>;
  code?: React.ComponentType<{
    node?: any;
    inline?: boolean;
    className?: string;
    children: React.ReactNode;
  }>;
};

const DRAGGER_HEIGHT = 12;
const CHAT_GAP = 16;
const MAX_CHAT_WIDTH = 500;
const MIN_CHAT_WIDTH = 200;

const SUGGESTED_GEOMETRY_ITEMS: SuggestedCardItem[] = [
  ['Create a farfield box', getNodeTypeIcon(NodeType.FAR_FIELD)?.name],
  ['Create a farfield sphere', getNodeTypeIcon(NodeType.FAR_FIELD)?.name],
  ['Create a farfield cylinder', getNodeTypeIcon(NodeType.FAR_FIELD)?.name],
  ['Copy the farfield from another project', getNodeTypeIcon(NodeType.FAR_FIELD)?.name],
  ['Change the dimension of the farfield', getNodeTypeIcon(NodeType.FAR_FIELD)?.name],
  ['What does ‘load to setup’ mean?', 'diskQuestion'],
  ['What can I do if my geometry has issues?', 'diskQuestion'],
  ['What are sliver surfaces, and should I be concerned?', 'diskQuestion'],
  ['What are the dimensions of my geometry?', 'diskQuestion'],
  ['How can I contact customer support?', 'diskQuestion'],
  ['List my projects', 'documentClipped'],
  ['Convert 100 feet to meters', 'doubleArrowLeftRight'],

  // New features have a higher chance of being suggested
  // TODO (LC-23906): Restore the weights to 1 after a while
  ['How to install Luminary Cloud SDK?', 'diskQuestion', 3 * DEFAULT_WEIGHT],
  ['How to import a geometry using the SDK?', 'code', 3 * DEFAULT_WEIGHT],
  ['Write a Python script for geometry preparation using SDK', 'code', 3 * DEFAULT_WEIGHT],
];

const SUGGESTED_SETUP_ITEMS: SuggestedCardItem[] = [
  ['Create a material', getNodeTypeIcon(NodeType.MATERIAL_CONTAINER)?.name],
  [
    'Change my material to an incompressible fluid',
    getNodeTypeIcon(NodeType.MATERIAL_CONTAINER)?.name,
  ],
  ['Add fluid physics', getNodeTypeIcon(NodeType.PHYSICS_FLUID)?.name],
  ['Change the turbulence model', getNodeTypeIcon(NodeType.PHYSICS_FLUID)?.name],
  ['Initialize using farfield values', getNodeTypeIcon(NodeType.FAR_FIELD)?.name],
  ['Create a farfield boundary condition', getNodeTypeIcon(NodeType.FAR_FIELD)?.name],
  ['Update the velocity in the farfield', getNodeTypeIcon(NodeType.FAR_FIELD)?.name],
  ['Create a reference frame', getNodeTypeIcon(NodeType.MOTION_FRAME)?.name],
  ['Create a body frame', getNodeTypeIcon(NodeType.MOTION_FRAME)?.name],
  ['Create minimal mesh for LMA', getNodeTypeIcon(NodeType.MESH)?.name],
  ['Start the simulation', 'playOutline'],

  ['What setup actions can you do?', 'diskQuestion'],
  ['What is the difference between a reference frame and a body frame?', 'diskQuestion'],
  ['When should I use constant density vs. ideal gas?', 'diskQuestion'],
  ['What RANS turbulence models do Luminary Cloud support?', 'diskQuestion'],
  ['List my projects', 'documentClipped'],
  ['How many boundary conditions are there in my setup?', 'diskQuestion'],
  ['Summarize my simulation setup', 'cubeOutlineStar'],
  ['What is the temperature and pressure at 10,000 ft height?', 'diskQuestion'],
  ['Convert 100 knots to m/s', 'doubleArrowLeftRight'],
  ['Convert 10 psi to Pa', 'doubleArrowLeftRight'],

  // New features have a higher chance of being suggested
  // TODO (LC-23906): Restore the weights to 1 after a while
  ['I need help with the Luminary Cloud SDK', 'diskQuestion', 3 * DEFAULT_WEIGHT],
  ['Write a Python script to create a project using SDK', 'code', 3 * DEFAULT_WEIGHT],
  ['Write a Python script to download the simulation solution data', 'code', 3 * DEFAULT_WEIGHT],
  [
    'Calculate the wall distance for y+ = 1',
    getNodeTypeIcon(NodeType.MESH)?.name,
    3 * DEFAULT_WEIGHT,
  ],
];

const SUGGESTED_ADVANCED_ANALYSIS_ITEMS: SuggestedCardItem[] = [
  ['What is a design of experiments?', 'diskQuestion'],
  ['How do you set up a design of experiments?', 'diskQuestion'],
  ['What exploration policies are available?', 'diskQuestion'],
  ['How can you configure a design of experiments using a csv file?', 'diskQuestion'],
  ['What types of geometry can be included in a design of experiments?', 'diskQuestion'],
  ['What are the benefits of using latin hypercube sampling?', 'diskQuestion'],
  ['How does the full sweep policy determine the number of simulations to run?', 'diskQuestion'],
  ['How do I add more outputs to my design of experiments?', 'diskQuestion'],
  ['How do you monitor the progress of a design of experiments?', 'diskQuestion'],
];

const SUGGESTED_ANALYSIS_ITEMS: SuggestedCardItem[] = [
  ['Can I change reference values after I run my simulation?', 'diskQuestion'],
  ['What options are available for exporting my results?', 'diskQuestion'],
  ['What visualization tools are available?', 'diskQuestion'],
  ['How do I create 2D plots?', 'diskQuestion'],
  ['How do I create custom fields?', 'diskQuestion'],
  ['How do I create custom outputs?', 'diskQuestion'],
  ['How do I change the colormap?', 'diskQuestion'],
  ['What expressions are supported for custom fields?', 'diskQuestion'],
  ['What is a force distribution plot?', 'diskQuestion'],
  ['How do I download my surface solution results from the SDK?', 'diskQuestion'],
  ['How do I get forces and moments through the SDK?', 'diskQuestion'],
  ['How do I download my volume solution through the SDK?', 'diskQuestion'],
];

const SUGGESTED_RESULTS_ITEMS: SuggestedCardItem[] = [
  ['Can I change reference values after I run my simulation?', 'diskQuestion'],
  ['How do I monitor the status of multiple, concurrently running simulations?', 'diskQuestion'],
  ['How do you access the results viewer page for a specific simulation?', 'diskQuestion'],
  ['Can I copy settings from a previously run simulation?', 'diskQuestion'],
  ['How do I save my settings to the library?', 'diskQuestion'],
  ['How do I rename a simulation?', 'diskQuestion'],
  ['What can I check if I am having trouble getting good convergence?', 'diskQuestion'],
];

const useStyles = makeStyles(
  () => createStyles({
    root: {
      width: '100%',
      maxWidth: `${MAX_CHAT_WIDTH}px`,
      minWidth: `${MIN_CHAT_WIDTH}px`,
      height: '100%',
      padding: `${CHAT_GAP}px`,
      display: 'flex',
      gap: `${CHAT_GAP}px`,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      overflow: 'hidden',
      position: 'relative',
    },
    chatHistory: {
      overflowY: 'auto',
      padding: '8px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: `${CHAT_GAP}px`,
      flex: '1',
      fontSize: '13px',
    },
    inputBox: {
      display: 'flex',
      gap: '8px',
      position: 'relative',
      alignSelf: 'center',
    },
    spacer: {
      // Pushes the messages to the bottom
      flex: '1',
    },
    dragger: {
      left: '16px',
      height: `${DRAGGER_HEIGHT}px`,
      width: 'calc(100% - 32px)',
      position: 'absolute',
      zIndex: 1,
      cursor: 'row-resize',
      display: 'flex',
      alignItems: 'center',
    },
    draggerLine: {
      position: 'relative',
      width: '100%',
      height: '1px',
      backgroundColor: colors.neutral0,
    },
  }),
  { name: 'Chat' },
);

const useAssistantChatStyles = makeStyles(
  () => createStyles({
    assistantMessage: {
      backgroundColor: 'inherit',
      color: colors.lowEmphasisText,
      display: 'flex',
      flexDirection: 'row',
      gap: '6px',
      alignSelf: 'flex-start',
      alignItems: 'baseline',
      width: '100%',

      // When the message start to appear, if it is a math formula, we might end up in a case
      // where only a part of the formula is displayed. In that case, the message will have
      // "$$" only at the beginning of the message but not at the end, and remark/rehype will
      // treat the text as an error (not as a latex formula) and will display it in red color.
      // To prevent this transitional error state, we'll just hide the unfinished formula.
      '& .katex-error': {
        display: 'none',
      },

      '& table': {
        '& thead': {
          backgroundColor: colors.surfaceMedium1,
        },
        '& th, & td': {
          padding: '4px',
          borderBottom: `1px solid ${colors.neutral300}`,
        },
      },
    },
    assistantIcon: {
      height: '12px',
      width: '12px',
      flex: '0 0 auto',
    },
    response: {
      flex: 1,
      width: 'calc(100% - 18px)',
      maxWidth: 'calc(100% - 18px)',
    },
    responseControlBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    preparingResponse: {
      fontStyle: 'italic',
    },
    markdown: {
      '& p': {
        marginTop: '0.9em',
        marginBottom: '0.75em',
      },
      '& ul, & ol': {
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: '20px',
      },
      '& li': {
        marginBottom: '0.25em',
      },
      '& a': {
        textDecoration: 'none',
        transition: 'color 250ms, box-shadow 350ms',
      },
      '& a, & a:visited': {
        color: colors.primaryInteractive,

        '&:hover': {
          color: colors.primaryCta,
          boxShadow: `inset 0 -1px 0 0 ${colors.primaryCta}`,
        },
      },
    },
    code: {
      position: 'relative',
      width: '100%',
      maxWidth: '100%',
      margin: '0.75em 0',
      overflow: 'hidden',
      borderRadius: '6px',
      border: '1px solid #30363d',
    },
    moreButton: {
      background: 'none',
      border: 'none',
      fontSize: '13px',
      padding: '0',
      textAlign: 'left',
      cursor: 'pointer',
      transition: 'color 250ms',
      color: colors.primaryInteractive,

      '&:hover': {
        color: colors.primaryCta,
      },
    },
  }),
  { name: 'AssistantChat' },
);

const useUserChatStyles = makeStyles(
  () => createStyles({
    userMessage: {
      backgroundColor: colors.neutral300,
      color: colors.highEmphasisText,
      borderRadius: '8px',
      padding: '4px 8px',
      maxWidth: '80%',
      alignSelf: 'flex-end',
      textAlign: 'left',
      width: 'max-content',
    },
  }),
  { name: 'UserChat' },
);

type AssistantMessageProp = {
  text: string;
  isPreparing?: boolean,
  index?: number,
  isLast?: boolean,
  onMoreOptionsClick?: () => void,
}

// Extract CopyButton as a standalone component
const CopyButton = ({ textToCopy }: { textToCopy: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }).catch((err) => {
      console.error('Failed to copy', err);
    });
  };

  return (
    <button
      data-locator="assistant-copy-code-block"
      onClick={handleCopy}
      style={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        background: copied ? 'rgba(46, 160, 67, 0.2)' : 'rgba(255, 255, 255, 0.1)',
        border: 'none',
        borderRadius: '4px',
        color: copied ? '#2ea043' : '#8b949e',
        padding: '4px 8px',
        fontSize: '12px',
        cursor: 'pointer',
        zIndex: 1,
        maxWidth: '80px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      title="Copy to clipboard"
      type="button">
      {copied ? 'Copied!' : 'Copy'}
    </button>
  );
};

const AssistantMessage = forwardRef<HTMLDivElement, AssistantMessageProp>((props, ref) => {
  // == Contexts
  const { projectId } = useProjectContext();

  // == Props
  const { index, text, isPreparing = false, isLast = false, onMoreOptionsClick } = props;

  // == Hooks
  const classes = useAssistantChatStyles();

  // == State
  const currentView = useCurrentView();
  const assistantResponding = useAssistantRespondingValue();
  const addNewAssistantMessage = useAddNewAssistantMessage(projectId);
  const { disabled } = useAssistantSend();
  const [suggestedItems, setSuggestedItems] = useState<SuggestedCardItem[]>([]);

  const allCardItems = useMemo(() => {
    switch (currentView) {
      case CurrentView.ADVANCED_ANALYSIS:
        return SUGGESTED_ADVANCED_ANALYSIS_ITEMS;
      case CurrentView.GEOMETRY:
        return SUGGESTED_GEOMETRY_ITEMS;
      case CurrentView.SETUP:
        return SUGGESTED_SETUP_ITEMS;
      case CurrentView.ANALYSIS:
        return SUGGESTED_ANALYSIS_ITEMS;
      case CurrentView.RESULTS:
        return SUGGESTED_RESULTS_ITEMS;
      default:
        return SUGGESTED_SETUP_ITEMS;
    }
  }, [currentView]);

  const handleMoreOptions = () => {
    // Add 3 more random items to the suggested items list.
    const newItems = getRandomItems(allCardItems, suggestedItems);
    setSuggestedItems((prev) => [...prev, ...newItems]);
    onMoreOptionsClick?.();
  };

  useEffect(() => {
    setSuggestedItems(getRandomItems(allCardItems));
  }, [currentView, allCardItems]);

  return (
    <div className={classes.assistantMessage} ref={ref}>
      <div className={classes.assistantIcon}>
        <SparkleDoubleIcon color={colors.purple800} maxHeight={12} maxWidth={12} />
      </div>
      {isPreparing ? (
        <span className={classes.preparingResponse}>
          {text}<AnimatedEllipsis />
        </span>
      ) : (
        <div className={classes.response}>
          <ReactMarkdown
            className={classes.markdown}
            components={{
              a: ({ children, href }) => (
                <a href={href} rel="noopener noreferrer" target="_blank">
                  {children}
                </a>
              ),
              code: ({ node, inline, className, children, ...rest }: {
                node?: any;
                inline?: boolean;
                className?: string;
                children: React.ReactNode;
                [key: string]: any;
              }) => {
                const match = /language-(\w+)/.exec(className || '');
                const language = match ? match[1] : '';
                const codeString = String(children).replace(/\n$/, '');

                if (inline) {
                  return (
                    <code className={className} {...rest}>
                      {children}
                    </code>
                  );
                }

                return (
                  <div className={classes.code}>
                    <CopyButton textToCopy={codeString} />
                    <SyntaxHighlighter
                      customStyle={{
                        border: 'none',
                        borderRadius: '6px',
                        fontFamily: 'monospace',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        margin: '0',
                        maxWidth: '100%',
                        overflow: 'auto',
                        padding: '16px',
                        paddingTop: '24px',
                        whiteSpace: 'pre',
                        width: '100%',
                      }}
                      language={language}
                      style={vscDarkPlus}
                      wrapLongLines={false}>
                      {codeString}
                    </SyntaxHighlighter>
                  </div>
                );
              },
              nodelinks: ({ children }) => {
                try {
                  const content: NestedNodeLinksData = JSON.parse(children);
                  return content.links.length ? <NestedNodeLinks links={content.links} /> : null;
                } catch (err) {
                  return (
                    <p>An error occured while generating the links. Please contact support.</p>
                  );
                }
              },
              parameterform: ({ children }) => {
                try {
                  const formContent: NestedFormData = JSON.parse(children);
                  return formContent.entries.length ? <NestedForm data={formContent} /> : null;
                } catch (err) {
                  return (
                    <p>An error occured while generating the form. Please contact support.</p>
                  );
                }
              },
            } as CustomMarkdownComponents}
            rehypePlugins={[rehypeRaw, rehypeKatex]}
            remarkPlugins={[remarkMath, remarkGfm]}>
            {text}
          </ReactMarkdown>

          <Flex flexDirection="column" gap={16}>
            {index === 0 && (
              <Flex flexDirection="column" gap={12}>
                {suggestedItems.map((item) => (
                  <SuggestedCard
                    disabled={disabled}
                    icon={{ name: item[1] ?? 'sparkleDouble' }}
                    key={item[0]}
                    onClick={() => {
                      if (!disabled) {
                        analytics.assistant('Click Suggested Card', { value: text });
                        addNewAssistantMessage(item[0]);
                      }
                    }}
                    tag={item[2] ? 'NEW' : undefined}
                    text={item[0]}
                  />
                ))}
                {suggestedItems.length < allCardItems.length && (
                  <button
                    className={cx(classes.moreButton)}
                    onClick={() => {
                      analytics.assistant('Click More Suggested Items');
                      handleMoreOptions();
                    }}
                    type="button">
                    More suggestions
                  </button>
                )}
              </Flex>
            )}
          </Flex>

          {index && index > 0 ? (
            <div className={classes.responseControlBar}>
              <div>
                {assistantResponding && isLast && !['!', '?', '.'].includes(text.slice(-1)) && (
                  <AnimatedEllipsis />
                )}
              </div>
              <ThumbsFeedback disabled={!isLast} />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
});

const UserMessage = forwardRef<HTMLDivElement, { text: string; isLastUserMessage?: boolean }>(
  ({ text, isLastUserMessage }, ref) => {
    const classes = useUserChatStyles();
    return (
      <div
        className={classes.userMessage}
        data-last-user-message={isLastUserMessage ? true : undefined}
        ref={ref}>
        {parseString(text)}
      </div>
    );
  },
);

const IGNORE_REGEX = /<hidden>([\s\S]*?)<\/hidden>/;

export const Chat = (props: ChatProps) => {
  // == Props
  const { onSendMessage } = props;

  // == Hooks
  const classes = useStyles();
  const chatHistoryRef = useRef<HTMLDivElement | null>(null);
  const recentUserMessageRef = useRef<HTMLDivElement | null>(null);
  const [currentMessage, setCurrentMessage] = useState('');
  const assistantThinking = useAssistantThinkingValue();
  const { disabled, disabledReason } = useAssistantSend();
  const [messageBoxHeight, setMessageBoxHeight] = useChatPanelHeight();
  const isAnalysisView = useIsAnalysisView();
  const isAdvancedAnalysisView = useIsAdvancedAnalysisView();

  // Applying `cursor: row-resize` only on the dragger causes flickering during resizing,
  // activating this state adds a CSS class, ensuring global styles apply the "moving" cursor
  // to all elements.
  const [isDraggerActive, setIsDraggerActive] = useState(false);

  // dragger position is relative to the initial size, requiring a reference to it
  const initialHeightRef = useRef(messageBoxHeight);
  const positionY = initialHeightRef.current - messageBoxHeight;

  const lastMessageIsUserFeedback = useMemo(() => (FEEDBACKS as unknown as string[]).includes(
    props.messages[props.messages.length - 1]?.text,
  ), [props.messages]);

  // We want to construct an array of clean messages - only the ones that we want to display
  const messages = useMemo(
    () => props.messages.filter((message) => {
      // When the assistant starts to generate a response, its first message is an empty text.
      // Then, after a few moments the empty message gets replaced with the actual response.
      // We don't want to show this empty message and receiving it ends the "is preparing" state so
      // it's better to just filter out these empty messages.
      if (message.text === '') {
        return false;
      }
      // The thumbs/up down buttons just send normal text to the assistant as a regular message.
      // But since we don't want to expose this to the user, we just ignore these messages.
      if ((FEEDBACKS as unknown as string[]).includes(message.text)) {
        return false;
      }
      // When we send a feedback (thumbs up/down or deselect feedback), the assistant will
      // return a <hidden>thank you...</hidden> message. We don't want to show this response
      // so we should ignore it.
      if (message.text.match(IGNORE_REGEX)) {
        return false;
      }
      return true;
    }),
    [props.messages],
  );

  const scrollToBottom = useCallback(() => {
    const el = chatHistoryRef.current;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }, []);

  // An effect to scroll the chat to the bottom when the user sends a message.
  // The auto scroll will also happen when we are already scrolled to near the bottom and the
  // assistant is responding. But do not auto scroll if we are far from the bottom because the user
  // may intentially want to check old responses while the current response is generated.
  useLayoutEffect(() => {
    const el = chatHistoryRef.current;
    if (el) {
      const distanceFromBottom = el.scrollHeight - el.scrollTop - el.clientHeight;
      if (messages.at(-1)?.isUser || distanceFromBottom < 150) {
        // Do not use the scrollIntoView as it messes up the layout when the messages contain math
        // formulas and ReactMarkdown uses the rehype/remark plugins to format them.
        scrollToBottom();
      }
    }
  }, [messages, scrollToBottom]);

  const handleSubmit = () => {
    if (currentMessage.length > 0 && !disabled) {
      onSendMessage(currentMessage);
      setCurrentMessage('');
      analytics.assistant('Send Message', { value: currentMessage });
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.chatHistory}
        data-locator="assistant-chat-history"
        ref={chatHistoryRef}>
        <div className={classes.spacer} /> {/* Spacer to push messages to the bottom */}
        {messages.map((message, idx) => {
          if (message.isUser) {
            // Update recentUserMessageRef
            return (
              <UserMessage
                key={message.id}
                ref={recentUserMessageRef} // Now points to the last user message
                text={message.text}
              />
            );
          }

          // If not a user message, just render the assistant's message.
          return (
            <AssistantMessage
              // We want the feedback buttons to be active only for the last response because we
              // may confuse the assistant if we send feedback for some of the previous responses.
              index={idx}
              isLast={idx === messages.length - 1}
              key={message.id}
              onMoreOptionsClick={() => {
                requestAnimationFrame(scrollToBottom);
              }}
              text={message.text}
            />
          );
        })}

        {/* If the last message was a user feedback (thumbs up/down), we don't want to show anything
        in the chat (including the "processing...") because we want this feedback to be silent. */}
        {assistantThinking && !lastMessageIsUserFeedback && (
          <AssistantMessage
            isPreparing
            text="Preparing response"
          />
        )}
      </div>

      <Draggable
        axis="y"
        bounds={{
          top: (initialHeightRef.current - MAX_MESSAGE_BOX_HEIGHT),
          bottom: (initialHeightRef.current - MIN_MESSAGE_BOX_HEIGHT),
        }}
        onDrag={(_, { y }) => {
          setMessageBoxHeight(initialHeightRef.current - y);
        }}
        onStart={() => {
          setIsDraggerActive(true);
        }}
        onStop={() => {
          setIsDraggerActive(false);
        }}
        position={{ x: 0, y: positionY }}>
        <div
          className={cx(classes.dragger, isDraggerActive && 'vertical-dragger-active')}
          style={{
            top: `calc(100% - ${initialHeightRef.current}px - 28px - ${DRAGGER_HEIGHT / 2}px)`,
          }}>
          <div className={classes.draggerLine} />
        </div>
      </Draggable>

      <div
        className={classes.inputBox}
        style={{
          height: messageBoxHeight,
          // minHeight wins with flex: 1 property applied on a spacer
          minHeight: messageBoxHeight,
        }}>
        <Tooltip title={disabledReason}>
          <span>
            <TextInput
              adornmentButton={(
                <ActionButton
                  dataLocator="assistant-send-message"
                  disabled={disabled || !currentMessage.length}
                  onClick={handleSubmit}
                  size="small">
                  <PaperAirplaneIcon maxHeight={12} maxWidth={12} /> Send
                </ActionButton>
              )}
              cols={MAX_CHAT_WIDTH / 10}
              dataLocator="assistant-message-input"
              disabled={!!disabledReason}
              fullHeight
              multiline
              onChange={(newValue: string) => {
                setCurrentMessage(newValue);
              }}
              onEnter={handleSubmit}
              placeholder={
                (isAnalysisView || isAdvancedAnalysisView) ?
                  'Ask a question' :
                  'Ask a question or type a command'
              }
              resize="none"
              rows={null}
              submitOnEnter
              value={currentMessage}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
