// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import { EntityGroupMap } from '../../../../lib/entityGroupMap';
import { FARFIELD_NAME, FARFIELD_NODE_ID, isFarfield } from '../../../../lib/farfieldUtils';
import { intersects } from '../../../../lib/lang';
import { DISKS_TABLES, NodeTableType, PLANE_AND_VOLUME_TABLES, POINTS_TABLES, SURFACES_TABLES, VOLUMES_TABLES } from '../../../../lib/nodeTableUtil';
import {
  GEOMETRY_NODE_ID,
  NodeType,
  POINT_CONTAINER_ID,
  ROOT_FLOATING_GEOMETRY_CONTAINER_ID,
  SURFACE_CONTAINER_ID,
  SURFACE_NODE_TYPES,
  SimulationTreeNode,
  TAGS_CONTAINER_ID,
  VOLUME_CONTAINER_ID,
} from '../../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../../lib/simulationTree/sectionRecoilKey';
import { groupToSimTreeNode } from '../../../../lib/simulationTree/utils';
import { EntityType } from '../../../../proto/entitygroup/entitygroup_pb';
import { entityGroupState } from '../../../../recoil/entityGroupState';
import { geometryListState_DEPRECATED } from '../../../../recoil/geometry/geometryListState';
import { canComputeGeometryContactsAtom } from '../../../../recoil/geometryContactsState';
import { selectedGeometryState } from '../../../../recoil/selectedGeometry';
import { simulationTreeSubselectState } from '../../../../recoil/simulationTreeSubselect';
import { activeNodeTableAtom } from '../../../../recoil/useActiveNodeTable';
import { cadModifierState } from '../../../../recoil/useCadModifier';
import { inputFilenameState } from '../../../../recoil/useInputFilename';
import { routeParamsAtom_DEPRECATED } from '../../../../recoil/useRouteParams';

import { geometryContactsSectionSelector } from './geometryContact';

const createProbePointsNode = (children: SimulationTreeNode[]) => (
  new SimulationTreeNode(NodeType.POINT_CONTAINER, POINT_CONTAINER_ID, 'Points', children)
);

const createSimTreeNodes = (
  types: EntityType[],
  entityGroupMap: EntityGroupMap,
  children: string[],
) => children.reduce((nodes, childId) => {
  if (types.includes(entityGroupMap.get(childId).entityType)) {
    nodes.push(groupToSimTreeNode(entityGroupMap.get(childId), entityGroupMap));
  }
  return nodes;
}, [] as SimulationTreeNode[]);

/**
 * This selector returns all the geometry nodes, contained in a parent NodeType.GEOMETRY. It is used
 * for displaying the geometry nodes both in the control panel's simulation tree and in the floating
 * geometry tree.
 */
export const geometrySectionSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'geometrySection',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const [
      inputFilename,
      cadModifier,
      entityGroupMap,
      activeNodeTable,
      simulationTreeSubselect,
      geometryList,
      routeParams,
      selectedGeometry,
    ] = get(waitForAll([
      inputFilenameState(key.projectId),
      cadModifierState(key.projectId),
      entityGroupState(key),
      activeNodeTableAtom,
      simulationTreeSubselectState,
      geometryListState_DEPRECATED(key.projectId),
      routeParamsAtom_DEPRECATED,
      selectedGeometryState(key.projectId),
    ]));

    const filterTreeNodeByNodeTable = activeNodeTable.type !== NodeTableType.NONE;
    const filterTreeNodeByNodeSubselect = (
      simulationTreeSubselect.active && simulationTreeSubselect.visibleTreeNodeTypes.length
    );

    const rootChildren = entityGroupMap.getChildren(EntityGroupMap.rootId);

    const geomSurfaceEntityTypes = [EntityType.SURFACE];
    geomSurfaceEntityTypes.push(EntityType.PARTICLE_GROUP, EntityType.MIXED);

    const geomSurfaces = createSimTreeNodes(
      geomSurfaceEntityTypes,
      entityGroupMap,
      rootChildren,
    );
    const monitorPlanes = createSimTreeNodes(
      [EntityType.MONITOR_PLANE],
      entityGroupMap,
      rootChildren,
    );
    const volumeNodes = createSimTreeNodes([EntityType.VOLUME], entityGroupMap, rootChildren);

    const farfieldNodes: SimulationTreeNode[] = [];
    // Only add the farfield node if the cad modifier state is set and we don't have a farfield
    // already generated
    if (cadModifier && !entityGroupMap.getGroups().some((group) => isFarfield(group.id))) {
      farfieldNodes.push(
        new SimulationTreeNode(NodeType.FAR_FIELD, FARFIELD_NODE_ID, FARFIELD_NAME),
      );
    }

    const children: SimulationTreeNode[] = [];

    const tags = createSimTreeNodes(
      [EntityType.TAG_CONTAINER, EntityType.BODY_TAG, EntityType.FACE_TAG],
      entityGroupMap,
      rootChildren,
    );
    if (tags.length) {
      children.push(new SimulationTreeNode(
        NodeType.TAGS_CONTAINER,
        TAGS_CONTAINER_ID,
        'Tags',
        [...tags],
      ));
    }

    if (filterTreeNodeByNodeTable) {
      if (SURFACES_TABLES.includes(activeNodeTable.type)) {
        children.push(...geomSurfaces, ...farfieldNodes, ...monitorPlanes);
      }
      if (VOLUMES_TABLES.includes(activeNodeTable.type)) {
        children.push(...volumeNodes);
      }
      if (DISKS_TABLES.includes(activeNodeTable.type)) {
        children.push(...geomSurfaces);
      }
      if (PLANE_AND_VOLUME_TABLES.includes(activeNodeTable.type)) {
        children.push(...monitorPlanes, ...volumeNodes);
      }
    } else if (filterTreeNodeByNodeSubselect) {
      if (intersects(simulationTreeSubselect.visibleTreeNodeTypes, SURFACE_NODE_TYPES)) {
        children.push(...geomSurfaces, ...farfieldNodes);
      }
      if (simulationTreeSubselect.visibleTreeNodeTypes.includes(NodeType.MONITOR_PLANE)) {
        children.push(...monitorPlanes);
      }
      if (simulationTreeSubselect.visibleTreeNodeTypes.includes(NodeType.VOLUME)) {
        children.push(...volumeNodes);
      }
    } else {
      children.push(
        new SimulationTreeNode(
          NodeType.SURFACE_CONTAINER,
          SURFACE_CONTAINER_ID,
          'Surfaces',
          [...geomSurfaces, ...farfieldNodes, ...monitorPlanes],
        ),
        new SimulationTreeNode(
          NodeType.VOLUME_CONTAINER,
          VOLUME_CONTAINER_ID,
          'Volumes',
          volumeNodes,
        ),
      );
    }

    // In the geometry tab, the geometry name comes from the route params which indicate the
    // geometry being modified. In the setup tab, the name is determined by the selected geometry
    // or the input filename.
    const geometryId = routeParams.geometryId || selectedGeometry?.geometryId;
    const geo = (geometryId &&
      geometryList?.geometries.find((geom) => geom.id === geometryId)) || undefined;

    return new SimulationTreeNode(
      NodeType.GEOMETRY,
      GEOMETRY_NODE_ID,
      inputFilename.name || geo?.name || 'Geometry',
      children,
    );
  },
  dangerouslyAllowMutability: true,
});

/**
 * This returns the geometry items in a parent NodeType.ROOT_FLOATING_GEOMETRY container. It is used
 * only for the floating geometry tree.
 */
export const geometryTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'geometryTree',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const [
      geometries,
      entityGroupMap,
      contacts,
      canComputeGeometryContacts,
      activeNodeTable,
      simulationTreeSubselect,
    ] = get(waitForAll([
      geometrySectionSelector(key),
      entityGroupState(key),
      geometryContactsSectionSelector(key),
      canComputeGeometryContactsAtom(key.projectId),
      activeNodeTableAtom,
      simulationTreeSubselectState,
    ]));

    const filterTreeNodes = (
      activeNodeTable.type !== NodeTableType.NONE ||
      (simulationTreeSubselect.active && simulationTreeSubselect.visibleTreeNodeTypes.length)
    );

    // All subtrees that start at the root of the floating geometry panel
    const parentNodes = [];

    // We need the parent Geometry container row only if it's not empty
    if (geometries.children.length) {
      parentNodes.push(geometries);
    }

    const rootChildren = entityGroupMap.getChildren(EntityGroupMap.rootId);
    const probePoints = createSimTreeNodes(
      [EntityType.PROBE_POINTS],
      entityGroupMap,
      rootChildren,
    );

    if (
      probePoints.length &&
      (
        !filterTreeNodes ||
        POINTS_TABLES.includes(activeNodeTable.type) ||
        simulationTreeSubselect.visibleTreeNodeTypes.includes(NodeType.PROBE_POINT)
      )
    ) {
      parentNodes.push(createProbePointsNode(probePoints));
    }

    if (canComputeGeometryContacts && !filterTreeNodes) {
      parentNodes.push(contacts);
    }

    return new SimulationTreeNode(
      NodeType.ROOT_FLOATING_GEOMETRY,
      ROOT_FLOATING_GEOMETRY_CONTAINER_ID,
      '',
      parentNodes,
    );
  },
  dangerouslyAllowMutability: true,
});

/**
 * This is used to display the Geometry tree separately from the existing Simulation tree.
 *
 * @param projectId string
 * @param workflowId string
 * @param jobId string
 * @returns SimulationTreeNode with all Geometry subnodes
 */
export function useGeometryTree(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(geometryTreeSelector({ projectId, workflowId, jobId }));
}
