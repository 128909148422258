import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { getLocalStorageData } from './lib/browserStorage';
import { localStorageEffect } from './lib/persist';

/**
 * Whether to show the UI for the new workflow-based project view.
 * It's persisted in localstorage.
 */
const WORKFLOW_FLAG_KEY = 'workflowFlag2';
export const workflowFlagState = atom({
  key: 'workflowFlag',
  default: getLocalStorageData(WORKFLOW_FLAG_KEY, true),
  effects: [
    localStorageEffect(WORKFLOW_FLAG_KEY),
  ],
});

export const useWorkflowFlagValue = () => useRecoilValue(workflowFlagState);

export const useSetWorkflowFlag = () => useSetRecoilState(workflowFlagState);
