// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
// File to define types/classes relevant to grouping.
import * as entitygrouppb from '../proto/entitygroup/entitygroup_pb';

import GroupMap, { Groupable } from './GroupMap';

export interface EntityGroup extends Groupable {
  entityType: entitygrouppb.EntityType;
}

export class EntityGroupMap extends GroupMap<EntityGroup> {
  private disableCallback: boolean;
  constructor(existingMap?: EntityGroupMap, disableCallback?: boolean) {
    super(
      (group, groupMap) => {
        if (disableCallback || existingMap?.disableCallback) {
          // The callback can be very expensive, so we allow it to be disabled in case the object
          // creator knows that it's not needed.
          return;
        }
        // Set the correct type of the group based on its children.  If all of the children are of
        // the same type, the group has this type as well.  If they are of different types the group
        // will get EntityType.MIXED as type.
        if (group.entityType === entitygrouppb.EntityType.TAG_CONTAINER) {
          return;
        }
        const childIds = Array.from(group.children);
        if (childIds.length) {
          const type = groupMap.get(childIds[0]).entityType;
          const newType = childIds.some((childId) => groupMap.get(childId).entityType !== type) ?
            entitygrouppb.EntityType.MIXED : type;
          if (newType !== undefined) {
            group.entityType = newType;
          }
        }
      },
      existingMap,
    );
    this.disableCallback = disableCallback || false;
  }
}
