// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { DefaultValue, atomFamily, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { useProjectContext } from '../../../components/context/ProjectContext';
import { ChatThread } from '../../../lib/assistant/assistant';
import { Assist, GetChatHistory, GetChatSessions, NewChatSession } from '../../../lib/assistant/assistantCall';
import { Logger } from '../../../lib/observability/logs';
import { analytics } from '../../../services/analytics';

const logger = new Logger('AssistantChat');

/**
 * The state for the AI assistant chat messages. This state is keyed on the project id.
*/
async function sendToAssistant(
  scope: string,
  sessionId: string,
  userMessage: string,
  setMessages: (callback: (currMessages: ChatThread | DefaultValue) => ChatThread) => void,
) {
  const id = `${Date.now()}`;
  const finalId = `${id}-response`;

  const updateMessages = (response: string) => {
    setMessages((prevThread) => {
      const curThread: ChatThread = prevThread instanceof DefaultValue ?
        new ChatThread() :
        prevThread;
      return curThread.appendAssistantResponse(finalId, response);
    });
  };

  try {
    await Assist(scope, sessionId, id, userMessage, updateMessages);
  } catch (error) {
    logger.error('Error communicating with assistant:', error);
    updateMessages("Sorry, I couldn't process your request. Please try again.");
  }
}

const assistantMessageState = atomFamily<ChatThread, string>({
  key: 'assistantMessageState',
  default: new ChatThread(),
  effects: (scope: string) => [
    ({ setSelf, trigger }) => {
    // Async fetch session ids
      const initSession = async (): Promise<ChatThread> => {
        // when we are first initialized figure out if there is already an existing session
        // associated with this project. If so we continue it. Otherwise we wait until
        // the first user request and establish a new session then.
        const sessionIds = await GetChatSessions(scope);
        let chatThread = new ChatThread();

        if (sessionIds.length > 0) {
          // use one of the existing session ids arbitrarily.
          // eventually we will need a policy (e.g., maybe reuse the latest one)
          const sessionId = sessionIds[sessionIds.length - 1];
          chatThread = chatThread.assignSessionId(sessionIds[0]);
          // request chat history for that session
          const chatHistoryEntries = await GetChatHistory(sessionId, scope);

          // hack to keep using milliseconds as the message ids. We go back 100msecs
          // so we can increment it safely
          let msgId = Date.now() - 100;
          chatHistoryEntries.forEach((entry) => {
            msgId += 1;
            if (entry.type === 'assistant') {
              const id = `${msgId}-response`;
              chatThread = chatThread.appendAssistantResponse(id, entry.content);
            } else {
              // must be user
              chatThread = chatThread.addUserMessage(entry.content, `${msgId}`);
            }
          });
          // we will reset the chat thread from the history, but do not let this
          // cause spurious queries to the backend again
          chatThread = chatThread.withSkipEffect();
        }
        return chatThread;
      };
      if (trigger === 'get') {
        // only initialize session if this is the first call into this effect.
        setSelf(initSession());
      }
    },
    ({ setSelf, onSet }) => {
      onSet(async (newThread, _, isReset) => {
        // there are three use cases:
        // 1. The chat thread is being reset. In this case we do nothing.
        // 2. The chat thread is being initialized from the history. In this case we
        //    do not want to send the last user message to the assistant again. meaning
        //    we do nothing.
        // 3. The chat thread is being updated by the user. In this case we want to
        //    send the last user message to the assistant.

        // case 1
        if (isReset) {
          return;
        }
        // case 2
        if (newThread.skipEffect) {
          return;
        }
        // case 3
        const lastUserMessage = newThread.getLastUserMessage();
        if (lastUserMessage?.text) {
          let sessionId = newThread.sessionId;

          if (sessionId === '') {
            // if there was no prior session established, we establish it
            // lazily before we first attempt to use assistant.
            sessionId = await NewChatSession(scope);
            setSelf((prevThread) => {
              const curThread: ChatThread = prevThread instanceof DefaultValue ?
                new ChatThread() :
                prevThread;
              return curThread.assignSessionId(sessionId);
            });
          }
          await sendToAssistant(scope, sessionId, lastUserMessage.text, setSelf);
        }
      });
    },
  ],
});

export const useResetAssistantChat = () => {
  const { projectId } = useProjectContext();
  const resetMessages = useResetRecoilState(assistantMessageState(projectId));
  const chatThread = useRecoilValue(assistantMessageState(projectId));

  return useCallback(async () => {
    try {
      if (chatThread.sessionId !== '') {
        await Assist(projectId, chatThread.sessionId, projectId, '/reset', (val: string) => {
          console.debug(`Assistant reset response: ${val}`);
        });
      }
      resetMessages();
      analytics.assistant('Reset', {
        eventType: 'session_reset',
      }, '', true);
    } catch (error) {
      logger.error('Error resetting assistant:', error);
      analytics.assistant('Reset', {
        eventType: 'session_reset',
      }, '', false, JSON.stringify(error));
    }
  }, [projectId, chatThread, resetMessages]);
};

export const useAssistantMessages = (
  projectId: string,
) => useRecoilState(assistantMessageState(projectId));

export const useSetAssistantMessages = (
  projectId: string,
) => useSetRecoilState(assistantMessageState(projectId));

export const useAssistantMessagesValue = (
  projectId: string,
) => useRecoilValue(assistantMessageState(projectId));
