// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import {
  findFluidBoundaryCondition,
  findHeatBoundaryCondition,
  isDependentBoundaryCondition,
  removeBoundaryCondition,
  removePeriodicBound,
} from '../../lib/boundaryConditionUtils';
import { cameraIndexFromNodeId } from '../../lib/cameraUtils';
import { getMaterialDomains } from '../../lib/entityRelationships';
import { explorationVariableNodeId } from '../../lib/explorationUtils';
import { FARFIELD_CHANGE_BASE_DIALOG, isFarfield, willChangeBeDestructive } from '../../lib/farfieldUtils';
import { removeHeatSource } from '../../lib/heatSourceUtils';
import { deleteImposter } from '../../lib/imposterFilteringUtils';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { findFrameById, frameHasChildren, removeFrame } from '../../lib/motionDataUtils';
import { updateSurfaces } from '../../lib/outputNodeUtils';
import {
  getProbePointsTable,
  pruneProbePointsParticleGroup,
  removeParticleGroup,
} from '../../lib/particleGroupUtils';
import { removePhysicalBehavior } from '../../lib/physicalBehaviorUtils';
import { findPhysicsByDomain } from '../../lib/physicsUtils';
import { removePorousModel } from '../../lib/porousModelUtils';
import { updateExploration } from '../../lib/proto';
import { ProbePointsTableModel } from '../../lib/rectilinearTable/model';
import { deleteRefinementRegion } from '../../lib/refinementRegionUtils';
import { deleteCameraWarning } from '../../lib/simulationTree/deletionWarningUtils';
import { NodeType } from '../../lib/simulationTree/node';
import { removeSlidingInterface } from '../../lib/slidingInterfaceUtils';
import { isMeshFile } from '../../lib/upload/uploadUtils';
import { deleteTreeNode, findFilterTreeNode, findFilterTreeNodeByFunction, getAllNodeIds } from '../../lib/visUtils';
import { useMaterials } from '../../model/hooks/useMaterials';
import { useMultiphysicsInterfaces } from '../../model/hooks/useMultiphysicsInterfaces';
import { usePhysicsSet } from '../../model/hooks/usePhysicsSet';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useCameraList } from '../../recoil/cameraState';
import { useSetCustomFieldNodes } from '../../recoil/customFieldNodes';
import { useEntityGroupData, useEntityGroupMap } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useLcvisCancelEdit } from '../../recoil/lcvis/hooks/useLcvisCancelEdit';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useMeshUrlState } from '../../recoil/meshState';
import { useOutputNodes } from '../../recoil/outputNodes';
import { usePlotNodes } from '../../recoil/plotNodes';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useSetMeshMultiPart } from '../../recoil/useMeshingMultiPart';
import { useHasActiveMesh } from '../../recoil/useProjectActiveMesh';
import { useSetMotionFrameToDelete } from '../../recoil/useProjectPage';
import { useViewState } from '../../recoil/useViewState';
import { useFilterState } from '../../recoil/vis/filterState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useCurrentConfig } from '../../recoil/workflowConfig';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { useFarfield } from './useFarfield';
import { useGeometryMesh } from './useGeometryMesh';
import { useTagsInteractiveGeometry } from './useInteractiveGeometry';
import { useSimulationConfig } from './useSimulationConfig';

import environmentState from '@/state/environment';

/** A custom hook that handles deletion-related functions for simulation tree nodes */
export const useNodeDeletion = () => {
  // == Contexts
  const { deleteNode: deleteParaviewNode } = useParaviewContext();
  const { projectId, workflowId, jobId, readOnly, onNewWorkflowConfig } = useProjectContext();
  const { deselectNodeIds } = useSelectionContext();

  // == Recoil
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const [cameraList, setCameraList] = useCameraList({ projectId, workflowId, jobId });
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const [filterState, setFilterState] = useFilterState({ projectId, workflowId, jobId });
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const lcvisCancelEdit = useLcvisCancelEdit();
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, '', '');
  const setCustomFieldNodes = useSetCustomFieldNodes(projectId);
  const [plots, setPlots] = usePlotNodes(projectId);
  const setConfirmStack = useSetConfirmations();
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const setFrameIdToDelete = useSetMotionFrameToDelete();
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);
  const [viewState] = useViewState(projectId);
  const [selectedGeometry] = useSelectedGeometry(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const hasActiveMesh = useHasActiveMesh(projectId);
  const isGeometryView = useIsGeometryView();
  const [meshUrlState] = useMeshUrlState(projectId);
  const isMesh = isMeshFile(meshUrlState.url);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);

  // == Hooks
  const { simParam, saveParamAsync } = useSimulationConfig();
  const { deleteFarfieldGeometry, deleteFarfieldNode } = useFarfield();
  const { deleteMeshNode, deleteGeometryNode } = useGeometryMesh();
  const { deleteMaterial } = useMaterials(projectId, workflowId, jobId, readOnly);
  const {
    removePhysics,
    saveBoundaryConditionAsync,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);
  const {
    deleteMultiphysicsInterface,
  } = useMultiphysicsInterfaces(projectId, workflowId, jobId, readOnly);
  const { isRemoveTagDisabled } = useTagsInteractiveGeometry();

  const getFilterNode = useCallback((id: string) => {
    if (lcvisEnabled && id) {
      return findFilterTreeNode(filterState, id);
    }
    if (viewState && id) {
      return findFilterTreeNode(viewState.root, id);
    }
    return null;
  }, [filterState, lcvisEnabled, viewState]);

  // canDelete function cannot be async – it is used synchronously in many places across the app
  const canDelete = useCallback((type: NodeType, id: string) => {
    switch (type) {
      case NodeType.EXPLORATION_VARIABLE:
      case NodeType.FAR_FIELD:
      case NodeType.MONITOR_PLANE:
      case NodeType.MOTION_FRAME:
      case NodeType.PARTICLE_GROUP:
      case NodeType.PHYSICAL_BEHAVIOR:
      case NodeType.PHYSICS_FLUID:
      case NodeType.PHYSICS_HEAT:
      case NodeType.PHYSICS_HEAT_HEAT_SOURCE:
      case NodeType.PHYSICS_MULTI_INTERFACE:
      case NodeType.PHYSICS_PERIODIC_PAIR:
      case NodeType.PHYSICS_SLIDING_INTERFACE:
      case NodeType.POROUS_MODEL:
      case NodeType.PROBE_POINT:
      case NodeType.REFINEMENT_REGION: {
        return !readOnly;
      }
      case NodeType.MESH:
        return !readOnly && (hasActiveMesh || isMesh);
      case NodeType.GEOMETRY: {
        const fromInteractiveGeo = selectedGeometry.geometryId;
        // We cannot delete the geometry top node in the geometry tab because we do not support such
        // functionality in the backend and we don't want users to end up with an empty geometry,
        // yet.
        return !readOnly && !fromInteractiveGeo && !isGeometryView;
      }
      case NodeType.MATERIAL_FLUID:
      case NodeType.MATERIAL_SOLID: {
        if (readOnly) {
          return false;
        }

        // If material is not yet assigned to any domains (volumes), then it's OK to delete
        const domains = getMaterialDomains(simParam, id, geometryTags);
        if (!domains.size) {
          return true;
        }

        // If material is assigned to domains (volumes), but none of them are assigned to physics
        // yet, then it's also OK to delete the material
        return ![...domains].some((domain) => findPhysicsByDomain(simParam, domain, geometryTags));
      }
      case NodeType.SURFACE_GROUP: {
        if (geometryTags.isTagId(id)) {
          return !isRemoveTagDisabled;
        }
        return !readOnly && isFarfield(id);
      }
      case NodeType.FILTER: {
        const filterNode = getFilterNode(id);
        // TODO: LC-15826: make filters deletable thru lcvis
        if (filterNode) {
          return filterNode.param.typ !== ParaviewRpc.TreeNodeType.READER;
        }
        return false;
      }
      case NodeType.PLOT: {
        const plot = plots.plots.find((plotNode) => plotNode.id === id);
        return (!!plot && plot?.plot.case !== 'monitorPlot');
      }
      case NodeType.CAMERA:
      case NodeType.OUTPUT: {
        return true;
      }
      case NodeType.CUSTOM_FIELD: {
        return true;
      }
      case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION: {
        const bc = findFluidBoundaryCondition(simParam, id);
        return !!bc && !isDependentBoundaryCondition(bc) && !readOnly;
      }
      case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION: {
        const bc = findHeatBoundaryCondition(simParam, id);
        return !!bc && !isDependentBoundaryCondition(bc) && !readOnly;
      }
      default: {
        return false;
      }
    }
  }, [getFilterNode, plots, readOnly, simParam, selectedGeometry.geometryId,
    geometryTags, isRemoveTagDisabled, hasActiveMesh, isGeometryView, isMesh,
  ]);

  const postDeleteNodeIds = (ids: string[]) => deselectNodeIds(ids);

  const deleteBoundaryNode = async (id: string): Promise<boolean> => saveBoundaryConditionAsync(
    (newParam) => removeBoundaryCondition(newParam, id),
  );

  const deletePhysicsNode = async (id: string): Promise<boolean> => saveBoundaryConditionAsync(
    (newParam) => removePhysics(newParam, id),
  );

  // Mark the camera for removal by setting its access to INVALID_ACCESS. The recoil state will
  // intercept the update and will send the appropriate remove RPCs and recoil changes.
  const removeCamera = (cameraId: bigint) => {
    const invalidAccess = frontendpb.CameraAccess.INVALID_ACCESS;
    const updatedList = cameraList.map((cam) => {
      if (cam.cameraId === cameraId) {
        const newCam = cam.clone();
        newCam.cameraAccess = invalidAccess;
        return newCam;
      }
      return cam;
    });
    setCameraList(updatedList);
  };

  const deleteCameraNode = (id: string): boolean => {
    const cameraId = cameraIndexFromNodeId(id);

    // If the camera to be deleted is global camera, ask for confirmation.
    const camera = cameraList.find((item) => item.cameraId === cameraId);
    if (camera?.cameraAccess === frontendpb.CameraAccess.GLOBAL) {
      pushConfirmation(setConfirmStack, {
        continueLabel: 'Delete',
        destructive: true,
        onContinue: () => {
          removeCamera(cameraId);
          postDeleteNodeIds([id]);
        },
        title: 'Delete a Global Camera',
        children: deleteCameraWarning(camera.name),
      });
      return false;
    }

    removeCamera(cameraId);
    return true;
  };

  // Remove the exploration variable from the exploration
  const deleteExplorationVariableNode = async (id: string): Promise<boolean> => {
    const exploration = config.exploration;
    if (exploration) {
      const newExploration = exploration.clone();
      const newVarList = newExploration.var.filter(
        (_, i) => explorationVariableNodeId(i) !== id,
      );
      newExploration.var = newVarList;
      onNewWorkflowConfig(updateExploration(config, newExploration));
      return true;
    }
    return false;
  };

  // Remove a heat source node from the simulation
  const deleteHeatSourceNode = async (id: string): Promise<boolean> => saveParamAsync(
    (newParam) => removeHeatSource(newParam, id),
  );

  const deleteFilterNode = (id: string): boolean => {
    if (lcvisEnabled) {
      if (!lcvisReady) {
        return false;
      }

      let idToDelete = id;
      // If there is an in-progress edit state, cancel it.
      lcvisCancelEdit();
      const initialIds = getAllNodeIds(filterState);

      // when removing the IntersectionCurve node in LCVis, we also want to delete its parent
      // see: https://github.com/luminarycloud/core/pull/20186#issuecomment-2669126710
      const intersectionCurveParent = findFilterTreeNodeByFunction(
        filterState,
        (node) => {
          if (node.param.typ !== 'ExtractSurfaces') {
            return false;
          }

          const isParent = node.child.length === 1 && node.child[0].id === idToDelete;
          return isParent;
        },
      );

      if (intersectionCurveParent) {
        idToDelete = intersectionCurveParent.id;
      }

      let newRoot = deleteTreeNode(filterState, idToDelete);

      const newIds = getAllNodeIds(newRoot);
      // find all the nodes that were deleted, and delete them from LCVis. Then delete them
      // from the filterState in recoil as well.
      const deletedNodes = [...initialIds].filter((nodeId) => !newIds.has(nodeId));
      deletedNodes.forEach((nodeId) => lcvHandler.display?.filterHandler?.deleteNode(nodeId));
      newRoot = lcvHandler.display?.filterHandler?.maybeUpdateVisibility(newRoot) ?? newRoot;
      setFilterState(newRoot);
      return true;
    }
    deleteParaviewNode(id);
    return true;
  };

  const deleteMonitorPlaneNode = async (id: string): Promise<boolean> => {
    const deleted = await saveParamAsync((newParam) => {
      const planeToDelete = newParam.monitorPlane.find((item) => (item.monitorPlaneId === id));
      const newPlanes = newParam.monitorPlane.filter((item) => (item.monitorPlaneId !== id));

      newParam.monitorPlane = newPlanes;

      return !!planeToDelete;
    });

    setOutputNodes((outputs) => updateSurfaces(outputs, entityGroupMap));
    if (deleted && viewState && viewState.root) {
      deleteImposter(id, viewState.root, deleteParaviewNode);
    }

    return deleted;
  };

  const deleteMotionFrameNode = async (id: string): Promise<boolean> => {
    const frame = findFrameById(simParam, id);

    if (!frame) {
      return false;
    }

    // If the frame has children, launch a dialog to get user feedback on how to handle them
    if (frameHasChildren(simParam, frame)) {
      setFrameIdToDelete(frame.frameId);
      return false;
    }

    // Otherwise, if the frame has no children, delete it without user input
    return saveParamAsync(
      (newParam) => removeFrame(
        newParam,
        frame.frameId,
        staticVolumes,
        geometryTags,
        entityGroupData,
      ),
    );
  };

  const deleteMultiphysicsInterfaceNode = async (id: string): Promise<boolean> => (
    deleteMultiphysicsInterface(id)
  );

  const deleteParticleGroupNode = async (id: string): Promise<boolean> => {
    const deleted = await saveParamAsync((newParam) => removeParticleGroup(newParam, id));
    if (deleted && viewState && viewState.root) {
      deleteImposter(id, viewState.root, deleteParaviewNode);
    }
    return deleted;
  };

  const deleteSlidingInterfaceNode = async (id: string): Promise<boolean> => saveParamAsync(
    (newParam) => removeSlidingInterface(newParam, id),
  );

  const deletePeriodicBoundNode = async (id: string): Promise<boolean> => saveParamAsync(
    (newParam) => removePeriodicBound(newParam, id),
  );

  const deletePhysicalBehaviorNode = async (id: string): Promise<boolean> => saveParamAsync(
    (newParam) => removePhysicalBehavior(newParam, id),
  );

  const deletePorousModelNode = async (id: string): Promise<boolean> => saveParamAsync(
    (newParam) => removePorousModel(newParam, id),
  );

  const deletePlotNode = (id: string): boolean => {
    // Delete the plot node by removing it from the list of plots in plotState
    const newPlotsState = plots.clone();
    const newPlotsList = newPlotsState.plots.filter((plot) => (plot.id !== id));
    const deleted = newPlotsList.length < newPlotsState.plots.length;
    newPlotsState.plots = newPlotsList;
    setPlots(newPlotsState);
    return deleted;
  };

  const deleteProbePointNode = async (id: string): Promise<boolean> => {
    const deleted = await saveParamAsync((newParam) => {
      const table = getProbePointsTable(newParam);
      if (table) {
        const tableModel = new ProbePointsTableModel(table);
        tableModel.deleteRecords('id', id);
        // If the number of points is zero then delete the group from the list.
        if (tableModel.records.length === 0) {
          pruneProbePointsParticleGroup(newParam);
        }
        return true;
      }
      return false;
    });
    setOutputNodes((outputs) => updateSurfaces(outputs, entityGroupMap));
    if (deleted && viewState?.root) {
      deleteImposter(id, viewState.root, deleteParaviewNode);
    }
    return deleted;
  };

  const deleteOutputNode = (id: string): boolean => {
    const newOutputNodes = outputNodes.clone();
    const outputList = outputNodes.nodes;
    const newList = outputList.filter(
      (outputNode: feoutputpb.OutputNode) => outputNode.id !== id,
    );
    newOutputNodes.nodes = newList;
    setOutputNodes(newOutputNodes);
    return true;
  };

  const deleteCustomFieldNode = (id: string): boolean => {
    setCustomFieldNodes((currentValue) => {
      const newCustomFieldNodes = currentValue.clone();
      const customFieldList = currentValue.customFields;

      newCustomFieldNodes.customFields = customFieldList.filter(
        (customField) => {
          const isDeletedField = customField.id === id;

          if (isDeletedField) {
            lcvHandler.display?.workspace?.removeField(customField.name);
          }

          return !isDeletedField;
        },
      );

      return newCustomFieldNodes;
    });

    return true;
  };

  const deleteSurfaceGroupNode = (id: string): boolean => {
    if (isFarfield(id)) {
      const deleteFarfield = () => {
        deleteFarfieldGeometry();
        postDeleteNodeIds([id]);
      };
      if (willChangeBeDestructive(simParam, entityGroupMap)) {
        // The farfield geometry has been added to the model. Deleting it at this point causes
        // many of the settings to be reset. We open a dialog to confirm.
        pushConfirmation(setConfirmStack, {
          ...FARFIELD_CHANGE_BASE_DIALOG,
          onContinue: deleteFarfield,
        });
      } else {
        deleteFarfield();
      }
    }
    return false;
  };

  const deleteRefinementRegionNode = (id: string): boolean => deleteRefinementRegion(
    setMeshMultiPart,
    id,
  );

  return {
    canDelete,
    deleteBoundaryNode,
    deleteCameraNode,
    deleteExplorationVariableNode,
    deleteFarfieldNode,
    deleteFilterNode,
    deleteGeometryNode,
    deleteHeatSourceNode,
    deleteMeshNode,
    deleteMaterial,
    deleteMonitorPlaneNode,
    deleteMotionFrameNode,
    deleteMultiphysicsInterfaceNode,
    deleteOutputNode,
    deleteCustomFieldNode,
    deleteParticleGroupNode,
    deletePeriodicBoundNode,
    deletePhysicalBehaviorNode,
    deletePhysicsNode,
    deletePlotNode,
    deletePorousModelNode,
    deleteProbePointNode,
    deleteRefinementRegionNode,
    deleteSlidingInterfaceNode,
    deleteSurfaceGroupNode,
    postDeleteNodeIds,
  };
};
