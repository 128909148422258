// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo, useState } from 'react';

import { isJobInStoppedState } from '../../lib/jobUtils';
import { getSimulationParam } from '../../lib/simulationParamUtils';
import { useWorkflowState } from '../../recoil/workflowState';
import { useIsStaff } from '../../state/external/user/frontendRole';
import { useIsRunStatusVisible } from '../../state/internal/component/isRunStatusVisible';
import { IconButton } from '../Button/IconButton';
import { getJobStatusData, getStopAndHoverText } from '../JobPanel/JobStatus';
import JobVerticalDataTable from '../JobPanel/JobVerticalDataTable';
import { PauseResumeToggleAnalysisView } from '../JobPanel/PauseResumeToggle';
import { CollapsiblePanel } from '../Panel/CollapsiblePanel';
import { useFloatingCollapsiblePanelStyles } from '../Theme/commonStyles';
import { useProjectContext } from '../context/ProjectContext';
import { XIcon } from '../svg/XIcon';

// This panel displays the run status of a simulation.
export const RunStatus = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Hooks
  const classes = useFloatingCollapsiblePanelStyles();
  const isStaff = useIsStaff();

  // == Recoil
  const workflow = useWorkflowState(projectId, workflowId);
  const [
    isRunStatusVisible,
    setIsRunStatusVisible,
  ] = useIsRunStatusVisible({ projectId, workflowId, jobId });

  // == State
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const showPanel = useMemo(() => workflow && isRunStatusVisible, [workflow, isRunStatusVisible]);

  if (!showPanel) {
    return null;
  }

  // Data
  const param = getSimulationParam(workflow?.config!);
  const job = workflow?.job[jobId]!;
  const jobStatusData = getJobStatusData(
    projectId,
    [workflowId],
    [job],
    [param],
    false,
    isStaff,
    true,
  );
  const [stopText, hoverText] = getStopAndHoverText(job);

  // Render
  return (
    <div className={classes.root}>
      <CollapsiblePanel
        collapsed={collapsed}
        headerRight={isJobInStoppedState(job) ? (
          <div className={classes.closePanelButton}>
            <IconButton
              onClick={() => setIsRunStatusVisible(false)}>
              <XIcon maxHeight={8} maxWidth={8} />
            </IconButton>
          </div>
        ) : undefined}
        heading="Run Status"
        onToggle={() => setCollapsed(!collapsed)}
        primaryHeading>
        <div className={classes.content}>
          <JobVerticalDataTable
            categories={jobStatusData}
            inRunStatusPanel
            name="simulationControl"
          />
          <div className={classes.cancelButton}>
            <PauseResumeToggleAnalysisView
              cancelOnly
              hoverText={hoverText}
              kind="cancel"
              overrideCancel={stopText}
              projectId={projectId}
              workflowId={workflowId}
            />
          </div>
        </div>
      </CollapsiblePanel>
    </div>
  );
};
