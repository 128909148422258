// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import * as d3 from 'd3';

import assert from './assert';
import { EMPTY_VALUE } from './constants';
import { escapeRegExp } from './text';

export enum DigitSeparator {
  NARROW_SPACE = '\u202f',
  COMMA = ',',
  PERIOD = '.',
}

// This sets the language to American for everyone. To switch to the user's local language, use
// navigator.language. Currently, there are some formatting issues on focus and we are initially
// only targeting the American market.
const LOCALE = 'en-US';

// This is not quite perfect, but it is a temporary solution until we come up
// with a better and more robust concept for dealing with numbers in the chart.
export function limitNumericStringLength(x: string, maxLength: number): string {
  const dpPos = x.indexOf('.');
  const expPos = x.indexOf('e');

  // Increase the max length for negative numbers so that we can show the same precision
  // for 132.123 and -123.123
  if (x.charAt(0) === '-') {
    maxLength += 1;
  }

  // Remove numbers only if there's a decimal point and the lenght is larger than the limit
  if (x.length > maxLength && dpPos > -1) {
    // Remove extra characters between the "." and the "e" in a scientific number, e.g. 1.123456e+5
    if (expPos > -1) {
      return x.slice(0, Math.max(dpPos + 2, Math.min(expPos, maxLength - 2))) + x.slice(expPos);
    }
    // If the maxLength stops at the decimal point, add one more possible character
    if (dpPos === maxLength - 1) {
      return x.slice(0, maxLength + 1);
    }
    // Remove the decimal point for numbers whose whole number part exceeds the limit (e.g 100000.1)
    if (dpPos >= maxLength) {
      return x.slice(0, dpPos);
    }
    return x.slice(0, maxLength);
  }
  return x;
}

export interface FormatOptions {
  // The maximum number of digits to show after the decimal. The number of digits can be lowered if
  // trimTrailingZeros is enabled.
  numDecimals?: number;
  locale?: string;
  // Use scientific notation for numbers that are below this value
  scientificLow?: number;
  // Use scientific notation for numbers that are above this value.
  scientificHigh?: number;
  // Whether or not to trim trailing zeros.
  trimTrailingZeros?: boolean;
  // Attempt to limit the number to a particular length. This should only be used in a few special
  // cases where we have limited space.
  maxLength?: number,
  // Whether or not to add "," or "." locale separators for large numbers (e.g 1,000,000).
  useGrouping?: boolean,
}

/**
 * Convert a number to a locale-aware string based on the formatting options. Locale is always
 * inferred from the browser.
 */
export function formatNumber(
  value: number,
  options: FormatOptions = {},
): string {
  const {
    numDecimals = 4,
    locale = LOCALE,
    scientificLow = 1e-3,
    scientificHigh = 1e6,
    trimTrailingZeros = true,
    maxLength,
    useGrouping = true,
  } = options;

  if (Number.isNaN(value) || value === undefined) {
    return EMPTY_VALUE;
  }
  let formatted = '';

  // Check if we should use scientific notation if the number is very large or if the number is very
  // small but not 0.
  const useScientific = (
    (Math.abs(value) > scientificHigh) ||
    (Math.abs(value) < scientificLow && value !== 0)
  );
  if (useScientific) {
    // To allow locale grouping (like 1,000,000), we add ","
    const groupingMod = useGrouping ? ',' : '';
    // To trim trailing zeros (like 1.000e-3), we append "~" before the d3 formatter type "e".
    const trimTrailingZerosMod = trimTrailingZeros ? '~' : '';
    formatted = d3.format(`${groupingMod}.${numDecimals}${trimTrailingZerosMod}e`)(value);
  } else {
    const intlOptions: Intl.NumberFormatOptions = {
      style: 'decimal',
      useGrouping,
    };
    intlOptions.minimumSignificantDigits = trimTrailingZeros ? 1 : numDecimals + 1;
    // The number of digits in front of the decimal point. We should at least keep all the digits in
    // front of the decimal point.
    const valueDigits = Math.ceil(Math.log10(Math.abs(value)));
    intlOptions.maximumSignificantDigits = Math.max(numDecimals + 1, valueDigits);

    formatted = value.toLocaleString(locale, intlOptions);
  }
  return maxLength ? limitNumericStringLength(formatted, maxLength) : formatted;
}

export function thousandSeparator(locale = LOCALE) {
  // 1000 doesn't work, because in some languages, including Spanish, thousands separators may not
  // be used unless the value is 100_000 or higher.
  const sample = 1_000_000;
  const localized = sample.toLocaleString(locale);
  return localized.replace(/^\d+/, '').substring(0, 1);
}

export function decimalSeparator(locale = LOCALE) {
  const sample = 1.1;
  const localized = sample.toLocaleString(locale);
  return localized.replace(/^\d+/, '').substring(0, 1);
}

// A utility for replacing a separator with an optional replacement character.  For white space
// separators, literal space is added as an alternation to the regular expression.
export function replaceSeparator(
  value: string,
  separator: string,
  global: boolean,
  replacement = '',
) {
  let regExpContent = escapeRegExp(separator);
  if (separator === DigitSeparator.NARROW_SPACE) {
    // Writers in languages that officially use the narrow non-breaking space are more likely
    // to write a literal space, so check for either.
    regExpContent += '| ';
  }

  const sepRe = new RegExp(regExpContent, global ? 'g' : '');
  return value.replace(sepRe, replacement);
}

// Parse a number formatted with Intl.NumberFormat
export function parseLocale(value: string, locale?: string) {
  let numberValue = value;

  const decSep = decimalSeparator(locale);
  const thouSep = thousandSeparator(locale);
  if (thouSep) {
    numberValue = replaceSeparator(numberValue, thouSep, true);
  }
  if (decSep) {
    numberValue = replaceSeparator(numberValue, decSep, false, '.');
  }

  return parseFloat(numberValue);
}

interface Multiplier {
  prefix: string;
  name: string;
  power: number;
}

const AllMultipliers: Multiplier[] = [
  { prefix: 'y', name: 'yocto', power: -24 },
  { prefix: 'z', name: 'zepto', power: -21 },
  { prefix: 'a', name: 'atto', power: -18 },
  { prefix: 'f', name: 'femto', power: -15 },
  { prefix: 'p', name: 'pico', power: -12 },
  { prefix: 'n', name: 'nano', power: -9 },
  { prefix: 'μ', name: 'micro', power: -6 },
  { prefix: 'm', name: 'milli', power: -3 },
  { prefix: 'c', name: 'centi', power: -2 },
  { prefix: 'd', name: 'deci', power: -1 },
  { prefix: '', name: '', power: 0 },
  { prefix: 'da', name: 'deka', power: 1 },
  { prefix: 'h', name: 'hecto', power: 2 },
  { prefix: 'k', name: 'kilo', power: 3 },
  { prefix: 'M', name: 'mega', power: 6 },
  { prefix: 'G', name: 'giga', power: 9 },
  { prefix: 'T', name: 'tera', power: 12 },
  { prefix: 'P', name: 'peta', power: 15 },
  { prefix: 'E', name: 'exa', power: 18 },
  { prefix: 'Z', name: 'zetta', power: 21 },
  { prefix: 'Y', name: 'yotta', power: 24 },
];

// Excludes 'uncommon' multipliers, i.e. any multiplier whose power isn't a
// multiple of 3
const CommonMultipliers: Multiplier[] = AllMultipliers.filter(
  (multiplier) => !(multiplier.power % 3),
);

// Given a value, find the largest multiplier that can be divided into the value
// while yielding a quotient >= 1.
function getMultiplierByValue(
  value: number,
  allowUncommon?: boolean, // Include uncommon prefixes
): Multiplier {
  const useMultipliers = allowUncommon ? AllMultipliers : CommonMultipliers;

  const lowest = useMultipliers[0];
  const highest = useMultipliers[useMultipliers.length - 1];

  const power = value === 0 ? 0 : Math.log10(value);

  if (power <= lowest.power) {
    return lowest;
  }

  for (let i = 1; i < useMultipliers.length; i += 1) {
    const multiplier = useMultipliers[i];
    const previous = useMultipliers[i - 1];

    if (power === multiplier.power) {
      return multiplier;
    }

    if (power < multiplier.power && power > previous.power) {
      return previous;
    }
  }

  return highest;
}

interface FormatSIOptions {
  // Allow uncommon prefixes (where power is not a multiple of 3)
  allowUncommon?: boolean;
  // The maximum number of fraction digits to use
  maximumFractionDigits?: number;
}

// Given a value and a unit, return a string expressing the value with an
// appropriate SI multiplier: e.g. 10,000,000 foo => 10 Mfoo
export function formatPrefixed(
  value: number,
  unit: string,
  options: FormatSIOptions = {},
): string {
  const { allowUncommon = false, maximumFractionDigits = 2 } = options;

  const multiplier = getMultiplierByValue(value, allowUncommon);

  const amount = value / (10 ** multiplier.power);
  const amtStr = amount.toLocaleString(undefined, {
    maximumFractionDigits,
  });

  return `${amtStr} ${multiplier.prefix}${unit}`;
}

export function generateRange(size: number): number[] {
  return (new Array(size)).fill(0).map((_, i) => i);
}

export function getDecimalPlaces(value: number): number {
  if (value === Math.floor(value)) {
    return 0;
  }
  return `${value}`.split('.').pop()?.length || 0;
}

export function clamp(value: number, bounds: [number, number]): number {
  const min = Math.min(...bounds);
  const max = Math.max(...bounds);

  return Math.max(Math.min(value, max), min);
}

export function toPositiveAbsoluteInteger(value: number) {
  return Math.max(Math.abs(Math.round(value)), 1);
}

export function isNumericInt(str: string) {
  return /^[0-9]+$/.test(str);
}

export function sumByBoolean(values: boolean[]): number {
  return values.reduce((result, value) => (value ? result + 1 : result), 0);
}

interface ByteUnit {
  decimal: string;
  binary: string;
}

// Units used to count bytes.  Bytes may be counted either in powers of
// 1000 (decimal) or of 1024 (binary).
// In practice, we should never need to handle more than exabytes.  We also
// don't worry about fractions of a byte, so all of these are increasing.
const byteUnits: ByteUnit[] = [
  { decimal: 'B', binary: 'B' },
  { decimal: 'kB', binary: 'KiB' },
  { decimal: 'MB', binary: 'MiB' },
  { decimal: 'GB', binary: 'GiB' },
  { decimal: 'TB', binary: 'TiB' },
  { decimal: 'PB', binary: 'PiB' },
  { decimal: 'EB', binary: 'EiB' },
];

interface FormatBytesOptions {
  // If true, use binary units (kibibytes, mebibytes, etc.) instead of decimal (default false)
  binary?: boolean;
  // The maximum number of significant digits to include (default 3)
  maxSignificantDigits?: number;
}

// Format a number of bytes in a "human-readable" format -- i.e. reduce to
// the largest unit for which bytes can be expressed as a non-fractional multiple
// of that unit.  Some logic is done to keep the returned values reasonably short,
// by rounding or including/not including decimal places.
// This roughly mimics the -h behavior of coreutils like ls, with a few tweaks.
// Examples:
//   1234 -> "1.23 kB" OR "1.21 KiB"
//   27777000 -> "27.8 MB" OR "26.5 MiB"
//   529234567890 -> "529 GB" OR "493 GiB"
export function formatBytesHuman(bytes: number, opts: FormatBytesOptions = {}): string {
  const binary = !!opts.binary;
  const maximumSignificantDigits = opts.maxSignificantDigits ?? 3;
  let unitIdx = 0;
  let qty = bytes;
  const multiplier = binary ? 1024 : 1000;
  while (qty >= multiplier && unitIdx < byteUnits.length - 1) {
    qty /= multiplier;
    unitIdx += 1;
  }
  const qtyStr = qty.toLocaleString(undefined, {
    maximumSignificantDigits,
  });
  const unit = binary ? byteUnits[unitIdx].binary : byteUnits[unitIdx].decimal;
  return `${qtyStr} ${unit}`;
}

/**
 * Rounds a number to the nearest thousand, million, or billion and returns
 * {num}K, {num}M, or {num}B as a string
 */
export function formatNumberCondensed(value: number | undefined): string {
  if (Number.isNaN(value) || value === undefined) {
    return EMPTY_VALUE;
  }

  let formatted = `${Math.round(value)}`;

  // The rounding is a little arbitrary here but essentially it prevents 999.9 being represented
  // as 1000K and so on with M and B
  if (value >= 950_000_000) {
    formatted = `${Math.round(value / 1_000_000_000)}B`;
  } else if (value >= 950_000) {
    formatted = `${Math.round(value / 1_000_000)}M`;
  } else if (value >= 950) {
    formatted = `${Math.round(value / 1_000)}K`;
  }

  return formatted;
}

/**
 * Converts a bigint to a number.
 * @param bigInt the bigint to convert
 * @returns the converted number
 * @throws an error if the bigint is out of number range
 */
export function fromBigInt(bigInt: bigint | number): number {
  assert(
    bigInt >= Number.MIN_SAFE_INTEGER && bigInt <= Number.MAX_SAFE_INTEGER,
    `Value ${bigInt} is too small or large and cannot be safely converted to number`,
  );
  return Number(bigInt);
}

// Format list of numbers as a comma-separated string of formatted values, wrapped in parentheses
export function formatNumberList(
  values: number[],
  options?: FormatOptions,
  suffix = '',
): string {
  const parts = values.map((value) => formatNumber(value, options));
  const result = `(${parts.join(', ')})`;

  return `${result}${suffix}`;
}
