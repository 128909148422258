// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import {
  LCVDisplayHandleType,
  LCVDisplaySignals,
  LCVKeyEvent,
  LCVMouseEvent,
  LCVMouseWheelEvent,
  LCVType,
} from '@luminarycloudinternal/lcvis';
import FileSaver from 'file-saver';

import { RgbaColor, colors, hexToRgbList } from '../../designSystem';
import { Logger } from '../../observability/logs';
import { isTestingEnv } from '../../testing/utils';
import { LcvFilterHandler } from '../handler/LcvFilterHandler';
import { LcvRrAnnotationHandler } from '../handler/LcvRrAnnotationHandler';
import { LcvSimAnnotationHandler } from '../handler/LcvSimAnnotationHandler';
import { LcVisEventType } from '../handler/handlerUtils';
import { DEFAULT_POINT_RADIUS, FilterProgressCallback, ImportFilterSetupOptions, LcvModule } from '../types';

import { LcvCamera } from './LcvCamera';
import { LcvFrame } from './LcvFrame';
import { LcvRenderer } from './LcvRenderer';
import { LcvArrowAnnotation } from './annotations/LcvArrowAnnotation';
import { LcvTransformAnnotation } from './annotations/LcvTransformAnnotation';
import { LcvMonitorPointList } from './annotations/simAnnotations/LcvMonitorPointList';
import { LcvObject, clearAllLcvObjects, getEveryLcvObject } from './base/LcvObject';
import { LcvColorMap } from './filters/LcvColorMap';
import { LcvFilter } from './filters/LcvFilter';
import { LcvWorkspace } from './filters/LcvWorkspace';
import { LcvArcballCameraWidget } from './widgets/LcvArcballCameraWidget';
import { LcvBoxWidget } from './widgets/LcvBoxWidget';
import { LcvClipBoxWidget } from './widgets/LcvClipBoxWidget';
import { LcvClipPlaneWidget } from './widgets/LcvClipPlaneWidget';
import { LcvCylinderWidget } from './widgets/LcvCylinderWidget';
import { LcvHalfSphereWidget } from './widgets/LcvHalfSphereWidget';
import { LcvMeasureWidget } from './widgets/LcvMeasureWidget';
import { LcvProbeWidget } from './widgets/LcvProbeWidget';
import { LcvSelectionWidget } from './widgets/LcvSelectionWidget';
import { LcvSphereWidget } from './widgets/LcvSphereWidget';
import { LcvTriadWidget } from './widgets/LcvTriadWidget';

type LcvEvent = LCVMouseEvent | LCVMouseWheelEvent | LCVKeyEvent
type LcvLeakedObjectInfo = { handle: number, id: number, type: string, refCount: number };

const PROBE_POINT_COLOR = hexToRgbList(colors.probePointColor);

// Set fixed size for the triad widget to make the cube ~90px on the cube in an isometric view
// We scale by DPI to keep the size consistent across low and high DPI displays
const TRIAD_SIZE = 187 * window.devicePixelRatio;

export const FRAMEBUFFER_SIZE_ERROR = 'framebuffer width or height is 0';
export const CANVAS_SIZE_ERROR = 'canvas dimensions are 0 or undefined';

const logger = new Logger('LcvDisplay');

/**
 * The LcvDisplay object is tied to the current lcvis canvas. It owns all LcvObjects in the
 * scene, so when the canvas is removed, it must be released along with all objects that
 * it holds references to.
 */
export class LcvDisplay extends LcvObject {
  canvasId: string;
  frames: LcvFrame[] = [];
  cameras: LcvCamera[] = [];
  // Widgets which are owned and managed by the display directly
  widgets: {
    // The camera controller widget
    arcballWidget?: LcvArcballCameraWidget,
    // The selection widget. This handles clicking on surfaces and box select.
    selectionWidget?: LcvSelectionWidget,
    // The probe widget. Toggles by attaching or detaching from the active frame.
    probeWidget?: LcvProbeWidget,
    // The triad widget. Displays the global coordinate axes in the bottom-left corner
    triadWidget?: LcvTriadWidget,
    // The measure widget. Calculates the distance between two user placed points.
    measureWidget?: LcvMeasureWidget,
    // Clip plane widget. This is a transient clip widget which is used for interactive geometry.
    planeWidget?: LcvClipPlaneWidget,
    // Clip box widget. This is a transient box clip widget which is used for interactive geometry.
    boxClipWidget?: LcvClipBoxWidget,
    // Box widget. This is a static box widget which can be used for geometry modification,
    // refinement regions, or other interactive boxes.
    boxWidget?: LcvBoxWidget,
    // Sphere widget. This is a static sphere widget that can be used for defining a
    // sphere interactively in the 3D UI for geometry modification, refinement regions,
    // or other interactive sphere placement.
    sphereWidget?: LcvSphereWidget,
    // Cylinder widget. This is a static cylinder widget that can be used for defining
    // a cylinder interactively in the 3D UI for geometry modification, refinement regions,
    // or other interactive cylinder placement.
    cylinderWidget?: LcvCylinderWidget,
    // Half-sphere widget. This is a static half-sphere widget that can be used for defining a
    // half-sphere interactively in the 3D UI for geometry modification, refinement regions,
    // or other interactive sphere placement.
    halfSphereWidget?: LcvHalfSphereWidget,
  } = {};

  // An annotation for previewing geometry transformations.
  // TODO (jared, will): this will become a widget so I'm adding it directly to the display
  // so that it's easier to move later.
  transformAnnotation: LcvTransformAnnotation | null = null;
  probePoint: LcvMonitorPointList | null = null;

  // Arrow used to define vectors in the scene.
  arrowList: LcvArrowAnnotation | null = null;

  annotationHandler: LcvRrAnnotationHandler | null = null;

  simAnnotationHandler: LcvSimAnnotationHandler | null = null;

  // The handler for all filters in the scene.
  filterHandler: LcvFilterHandler | null = null;

  workspace: LcvWorkspace | null = null;
  renderers: LcvRenderer[] = [];
  nextFrame: number = 0;

  screenshotInfo = {
    captureNextFrame: false,
    imageText: '',
    transparentBackground: false,
  }

  // Whether the display has successfully executed the workspace at least once.
  complete = false;

  release() {
    // stop the render loop, then release all the display's children.
    this.nextFrame && cancelAnimationFrame(this.nextFrame);
    this.filterHandler?.release();
    this.annotationHandler?.releaseAnnotations();
    this.simAnnotationHandler?.releaseAll();
    this.transformAnnotation?.show(null);
    this.transformAnnotation?.release();
    const allToRelease: LcvObject[] = (
      this.frames as LcvObject[]
    )
      .concat(this.cameras, this.renderers)
      .concat(Object.values(this.widgets));

    this.workspace?.release();
    allToRelease.forEach((object: LcvObject) => object.release());
    this.probePoint?.release();
    this.arrowList?.release();
    this.widgets = {};

    // Check that all objects were correctly released. If any objects weren't released,
    // release them so the app doesn't break, but log an error because it needs to be addressed.
    const everyLcvObject = getEveryLcvObject();
    const leakedObjects: LcvLeakedObjectInfo[] = [];
    everyLcvObject.forEach((obj) => {
      if (
        obj.refCount !== 0 &&
        // Filters are the exception, they're managed by the workspace
        !(obj instanceof LcvFilter) &&
        // Color maps are an exception, they don't need to be released
        !(obj instanceof LcvColorMap) &&
        (obj !== this)
      ) {
        leakedObjects.push({
          handle: obj.handle,
          id: obj.objectId,
          type: LCVType[obj.objectType],
          refCount: obj.refCount,
        });
        obj.release();
      }
    });
    // clear the object registry so that the list resets every time we release the display.
    clearAllLcvObjects();
    if (leakedObjects.length) {
      logger.error(
        'LCVis memory leak! Some LcvObjects were not released during teardown: ',
        leakedObjects,
      );
    }

    // release the Display.
    super.release();
  }

  /**
   * Throw an error if either height or width are 0 or undefined.
   * This function should be called before initializing a new display or frame.
   */
  checkSize(canvasId: string) {
    if (isTestingEnv()) {
      return;
    }
    const dims = (
      document.getElementById(canvasId) as HTMLCanvasElement | null
    )?.getBoundingClientRect();
    if ((!dims?.height || !dims?.width)) {
      this.release();
      throw new Error(CANVAS_SIZE_ERROR);
    }
    const fbSize = this.getProperty('framebuffer_size', LCVType.kLCVDataTypeInt2);
    if (fbSize.includes(0)) {
      throw new Error(FRAMEBUFFER_SIZE_ERROR);
    }
  }

  constructor(lcv: LcvModule, canvasId: string, sessionHandle: number) {
    super(
      lcv,
      lcv.newDisplay(
        sessionHandle,
        LCVDisplayHandleType.kLCVDisplayHandleHTMLCanvas,
        `#${canvasId}`,
        0,
      ).display,
      sessionHandle,
    );
    this.checkSize(canvasId);
    this.canvasId = canvasId;
    this.widgets.probeWidget = new LcvProbeWidget(this.lcv, this.sessionHandle);
    this.widgets.measureWidget = new LcvMeasureWidget(this.lcv, this.sessionHandle);
    this.doRenderLoop();
  }

  /** Initialize a frame in the display. */
  async initFrame(
    options: ImportFilterSetupOptions,
    progressCallback: FilterProgressCallback,
    onExecuteWorkspace: () => void,
  ) {
    const displaySize = (
      isTestingEnv() ?
        [0, 0] :
        this.getProperty('framebuffer_size', LCVType.kLCVDataTypeInt2)
    );
    const newCamera = new LcvCamera(this.lcv, this.sessionHandle);
    this.cameras.push(newCamera);
    this.workspace = new LcvWorkspace(this.lcv, this.sessionHandle, options, () => {
      onExecuteWorkspace();
      this.complete = true;
    });
    const newRenderer = new LcvRenderer(this.lcv, this.sessionHandle);
    this.renderers.push(newRenderer);
    this.checkSize(this.canvasId);
    const newFrame = new LcvFrame(
      this.lcv,
      this.sessionHandle,
      displaySize,
      newCamera,
      this,
      this.workspace,
      newRenderer,
    );
    this.frames.push(newFrame);
    const newArcball = new LcvArcballCameraWidget(
      this.lcv,
      this.sessionHandle,
      newFrame,
      newCamera,
    );
    this.widgets.arcballWidget = newArcball;

    const newTriad = new LcvTriadWidget(this.lcv, this.sessionHandle, newFrame);
    this.widgets.triadWidget = newTriad;
    // Connect the triad widget to drive the camera when dragging on the widget
    this.widgets.triadWidget.setParam(
      'arcball_camera_widget',
      LCVType.kLCVDataTypeWidget,
      this.widgets.arcballWidget.handle,
    );
    this.widgets.triadWidget.setParam('size', LCVType.kLCVDataTypeUint, TRIAD_SIZE);

    // Set initial position
    this.updateTriadPosition();

    const newSelection = new LcvSelectionWidget(this.lcv, this.sessionHandle, newFrame);
    this.widgets.selectionWidget = newSelection;

    this.widgets.planeWidget = new LcvClipPlaneWidget(this.lcv, this.sessionHandle);
    this.widgets.boxClipWidget = new LcvClipBoxWidget(this.lcv, this.sessionHandle);
    this.widgets.boxWidget = new LcvBoxWidget(this.lcv, this.sessionHandle);
    this.widgets.sphereWidget = new LcvSphereWidget(this.lcv, this.sessionHandle);
    this.widgets.cylinderWidget = new LcvCylinderWidget(this.lcv, this.sessionHandle);
    this.widgets.halfSphereWidget = new LcvHalfSphereWidget(this.lcv, this.sessionHandle);

    this.filterHandler = new LcvFilterHandler(
      this.lcv,
      this.sessionHandle,
      newRenderer,
      newFrame,
    );

    this.annotationHandler = new LcvRrAnnotationHandler(this.lcv, this, this.sessionHandle);
    this.simAnnotationHandler = new LcvSimAnnotationHandler(this.lcv, this.sessionHandle);

    this.initProbePoint(newFrame);
    this.initArrowList(newFrame);
    if (!this.transformAnnotation) {
      this.transformAnnotation = new LcvTransformAnnotation(this.lcv, this.sessionHandle);
    }

    // In geometry mode, we may end up here with an empty URL when streaming tessellations. Do not
    // run the workspace in that case.
    if (!isTestingEnv() && ('meshUrl' in options && options.meshUrl !== '')) {
      await this.workspace!.executeWorkspaceExternal(true, true, progressCallback);
    }
  }

  /**
   * Activates a widget in the display.
   * @param widget the widget to activate.
   */
  activateWidget(widget: LcvClipBoxWidget |
                 LcvClipPlaneWidget |
                 LcvBoxWidget |
                 LcvSphereWidget |
                 LcvCylinderWidget |
                 LcvHalfSphereWidget) {
    if (!this.frames.length) {
      return;
    }
    widget.attachFrame(this.frames[0]);
  }

  /**
   * Deactivates a widget in the display. This doesn't delete the widget, just stops showing it.
   * @param widget the widget to deactivate
   */
  deactivateWidget(widget: LcvClipBoxWidget |
                   LcvClipPlaneWidget |
                   LcvBoxWidget |
                   LcvSphereWidget |
                   LcvCylinderWidget |
                   LcvHalfSphereWidget) {
    widget.detachFrame();
  }

  /** Return the string id of the currently hovered item. */
  getHoveredId(): string {
    let id = '';
    if (this.workspace?.lastHoveredIndices.length) {
      id = this.workspace?.getIdFromIndex(...this.workspace.lastHoveredIndices[0]) ?? '';
    }
    if (!id && this.simAnnotationHandler?.currentlyHovered.length) {
      const { currentlyHovered } = this.simAnnotationHandler;
      id = currentlyHovered[0];
    }
    return id;
  }

  /**
   * Initialize the probe point to be used for the probe mode, and attach it to the frame.
   */
  initProbePoint(frame: LcvFrame) {
    if (this.probePoint) {
      return;
    }
    this.probePoint = new LcvMonitorPointList(this.lcv, this.sessionHandle, 1);
    this.probePoint.setParamAtIndex(0, 'visible', LCVType.kLCVDataTypeUint, 0);
    this.probePoint.setParamAtIndex(0, 'colors', LCVType.kLCVDataTypeFloat3, PROBE_POINT_COLOR);
    this.probePoint.setParam('radius', LCVType.kLCVDataTypeFloat, DEFAULT_POINT_RADIUS);
    frame.attachAnnotation('probePoint', this.probePoint);
  }

  initArrowList(frame: LcvFrame) {
    if (this.arrowList) {
      return;
    }
    this.arrowList = new LcvArrowAnnotation(this.lcv, this.sessionHandle);
    this.arrowList.setParamAtIndex(0, 'visible', LCVType.kLCVDataTypeUint, 0);
    this.arrowList.setParamAtIndex(0, 'colors', LCVType.kLCVDataTypeFloat3, PROBE_POINT_COLOR);
    this.arrowList.setParamAtIndex(0, 'points', LCVType.kLCVDataTypeFloat3, [0, 0, 0]);
    this.arrowList.setParamAtIndex(0, 'directions', LCVType.kLCVDataTypeFloat3, [1, 0, 0]);
    frame.attachAnnotation('arrows', this.arrowList);
  }

  /** Take a screenshot in the next animation frame. */
  screenshot(text: string, transparentBackground: boolean) {
    this.screenshotInfo = {
      captureNextFrame: true,
      imageText: text,
      transparentBackground,
    };
  }

  /**
   * Start or continue the rendering loop. LCVis is smart about renders, so this is very
   * inexpensive to keep calling if nothing changes in the scene.
   */
  doRenderLoop = () => {
    this.nextFrame = requestAnimationFrame(async () => {
      const prevBackgrounds: RgbaColor[] = [];
      if (this.screenshotInfo.captureNextFrame && this.screenshotInfo.transparentBackground) {
        this.renderers.forEach(
          (renderer) => {
            prevBackgrounds.push(renderer.getBackgroundColor());
            renderer.setBackgroundColor([0, 0, 0, 0]);
          },
        );
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const frame of this.frames) { // need a for ... of loop to await each frame render
        await frame.render();
      }

      // To take a screenshot, we must capture the blob immediately after rendering, since if we
      // grab it at the wrong time we might get an empty image instead.
      // see https://stackoverflow.com/a/32641456
      if (this.screenshotInfo.captureNextFrame) {
        this.screenshotInfo.captureNextFrame = false;
        const canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;
        if (canvas) {
          const blob: Blob | null = await new Promise((resolve) => canvas.toBlob(resolve));
          if (blob) {
            FileSaver.saveAs(
              blob,
              `screenshot ${this.screenshotInfo.imageText}.png`,
            );
          }
        }
        if (prevBackgrounds.length > 0) {
          this.renderers.forEach(
            (renderer, i) => renderer.setBackgroundColor(prevBackgrounds[i]),
          );
        }
      }

      this.doRenderLoop();
    });
  }

  invokeEvent(event: LcvEvent, type: LcVisEventType) {
    const eventMap: { [key in LcVisEventType]: (
      sessionHandle: number,
      displayHandle: number,
      e: LcvEvent
    ) => void } = {
      mouseup: this.lcv.displayMouseReleaseEvent,
      touchend: this.lcv.displayMouseReleaseEvent,
      mousedown: this.lcv.displayMousePressEvent,
      touchstart: this.lcv.displayMousePressEvent,
      mousemove: this.lcv.displayMouseMoveEvent,
      touchmove: this.lcv.displayMouseMoveEvent,
      wheel: this.lcv.displayMouseWheelEvent,
      keydown: this.lcv.displayKeyPressEvent,
      keyup: this.lcv.displayKeyReleaseEvent,
    };
    eventMap[type]?.(this.sessionHandle, this.handle, event);
  }

  resize() {
    const canvas = document.getElementById(this.canvasId);
    if (!canvas || !this.workspace) {
      return;
    }
    this.lcv.displayResizeEvent(this.sessionHandle, this.handle);
    // Keep the frame size matching the display framebuffer size
    const displaySize = this.getProperty('framebuffer_size', LCVType.kLCVDataTypeInt2);
    if (displaySize.includes(0)) {
      // If the display size is 0, we can't resize the frame. So do nothing instead of throwing an
      // error. A new resize event _should_ be triggered again once the display size is no longer 0.
      return;
    }

    // during a resize event, detach all widgets that are attached to the frame, then reattach
    // them after the event. If a widget is already detached, don't reattach it.
    const widgetsWithAttachedFrames = Object.values(this.widgets)
      .filter((widget) => widget.frame !== null);
    widgetsWithAttachedFrames.forEach((widget) => widget.detachFrame());

    this.filterHandler?.detachWidgets();
    this.frames.forEach((frame) => frame.release());

    // TODO (post-milestone 1): make this work for multiple frames.
    // currently we know that the display only has 1 camera, 1 filter, and 1 renderer, so we
    // can just use the first one. But later on we should use a map or something and give each
    // object an id tied to its frame.
    this.frames = [];
    const newFrame = new LcvFrame(
      this.lcv,
      this.sessionHandle,
      displaySize,
      this.cameras[0],
      this,
      this.workspace,
      this.renderers[0],
    );
    this.frames.push(newFrame);
    widgetsWithAttachedFrames.forEach((widget) => widget.attachFrame(newFrame));
    if (this.filterHandler) {
      this.filterHandler.activeFrame = newFrame;
      this.filterHandler.attachWidgets();
    }

    // Re-attach annotations to the frames on resize once a new Frame instance is created.
    // Currently since there is only a single frame and the annotations exist on all frames
    // this works. We will want to introduce IDs and then have a ID -> annotationMap logic once
    // there are more frames.
    this.frames.forEach((frame) => {
      this.annotationHandler!.annotationsMap.forEach((annotation, key) => {
        frame.attachAnnotation(key, annotation);
      });
      this.simAnnotationHandler?.attachAnnotations(frame);
      if (this.annotationHandler!.gridAttached) {
        this.annotationHandler?.showGridAxes(true);
      }
      if (this.probePoint) {
        frame.attachAnnotation('probePoint', this.probePoint);
      }
      if (this.arrowList) {
        frame.attachAnnotation('arrows', this.arrowList);
      }
      if (this.transformAnnotation?.frame) {
        this.transformAnnotation.show(frame);
      }
    });

    // Update triad position after resize
    this.updateTriadPosition();
  }

  /** Get the background color for the frame (only 1 frame for now) */
  getBackgroundColor(): RgbaColor | null {
    if (!this.frames.length) {
      return null;
    }
    return this.frames[0].renderer.getBackgroundColor();
  }

  /** Set the background color for the frame (only 1 frame for now) */
  setBackgroundColor(color: RgbaColor) {
    if (!this.frames.length) {
      return;
    }
    this.frames[0].renderer.setBackgroundColor(color);
  }

  /* Gets the best bounds for the current dataset. We return the visible bounds
  * if valid, and otherwise return the total bounds. Return value is in the form
  * [minX,maxX,minY,maxY,minZ,maxZ].
  */
  getCurrentBounds(): [number, number, number, number, number, number] | null {
    if (this.frames.length) {
      return this.frames[0].getCurrentBounds();
    }
    return null;
  }

  /* Gets the best bounds for the current dataset only not including annotations
   * or other things we render. This is useful for things like widget placement,
   * which only need the bounds of the data not including monitor points,
   * refinement regions, etc. We return the visible bounds if valid, and
   * otherwise return the total bounds. Return value is in the form
   * [minX,maxX,minY,maxY,minZ,maxZ].
   */
  getCurrentDatasetBounds(): [number, number, number, number, number, number] | null {
    if (this.frames.length) {
      return this.frames[0].getCurrentDatasetBounds();
    }
    return null;
  }

  getWorkspace(): LcvWorkspace | null {
    return this.workspace;
  }

  displayHidden() {
    this.sendSignal(LCVDisplaySignals.kDisplayHidden, LCVType.kLCVDataTypeVoidPtr, 0);
  }

  public updateTriadPosition() {
    if (this.widgets.triadWidget) {
      this.widgets.triadWidget.setParam(
        'screen_position',
        LCVType.kLCVDataTypeUint2,
        [0, 25],
      );
    }
  }
}
