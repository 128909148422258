// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { useRecoilValueLoadable } from 'recoil';

import { ConnState, useParaviewClientState } from '../../lib/ParaviewClient';
import { getAdValue } from '../../lib/adUtils';
import { getTertiaryColor } from '../../lib/color';
import { SelectOption, SelectOptionGroup } from '../../lib/componentTypes/form';
import { AxisLabels } from '../../lib/componentTypes/plot';
import { EMPTY_2D_ARRAY } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { parseString } from '../../lib/html';
import { frameExists } from '../../lib/motionDataUtils';
import { formatNumber, fromBigInt } from '../../lib/number';
import {
  FRAME_NOT_FOUND_MESSAGE,
  createOutputs,
  defaultName,
  getForceNotAvailableWarnings,
  getOutputNodeWarnings,
  isIncluded,
  setIncluded,
} from '../../lib/outputNodeUtils';
import { traverseTreeNodes } from '../../lib/paraviewUtils';
import { MONITOR_PLOT_NODE_ID, getPlotCoordinatesComponent } from '../../lib/plot';
import { forceDistribution, selectivelyUpdateRpc } from '../../lib/plotData';
import { getReferencePressure } from '../../lib/referenceValueUtils';
import { NodeType } from '../../lib/simulationTree/node';
import { isSimulationImplicitTime, isSimulationTransient } from '../../lib/simulationUtils';
import useResizeObserver from '../../lib/useResizeObserver';
import useTimeSeriesOutput from '../../lib/useTimeSeriesOutput';
import { JobStatusType } from '../../proto/base/base_pb';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as feoutputpb from '../../proto/frontend/output/output_pb';
import * as outputpb from '../../proto/output/output_pb';
import * as plotpb from '../../proto/plots/plots_pb';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useJobState } from '../../recoil/jobState';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useCurrentlySelectedPlot, usePlotNodes } from '../../recoil/plotNodes';
import useSelectedIterationState from '../../recoil/selectedIter';
import { useInTimeUnitsValue } from '../../recoil/useInTimeUnits';
import { useInnerItersWindow } from '../../recoil/useInnerItersWindow';
import { useViewState } from '../../recoil/useViewState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useWorkflowState } from '../../recoil/workflowState';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useSimulationParamScope } from '../../state/external/project/simulation/paramScope';
import DownloadMenu from '../DownloadMenu';
import { DataSelect } from '../Form/DataSelect';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { createStyles, makeStyles } from '../Theme';
import TimeBarControls from '../TimeBarControls';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import NodeLink from '../treePanel/NodeLink';

import OutputChart, { DataConfig } from './OutputChart';
import { Scale } from './constants';

import { lcvHandler } from '@/lib/lcvis/handler/LcvHandler';
import { useLcVisEnabledValue } from '@/recoil/lcvis/lcvisEnabledState';
import { useFilterState } from '@/recoil/vis/filterState';

const getLocationKey = (datasetIndex: number, itemPosition: number) => (
  `${datasetIndex}::${itemPosition}`
);

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      backgroundColor: colors.surfaceMedium2,
    },
    topBar: {
      background: colors.surfaceMedium2,
      borderBottom: `1px solid ${colors.surfaceBackground}`,
      height: '44px',
      padding: '2px 12px',
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    chartContainer: {
      flex: '1 1 auto',
    },
    leftMenuContainer: {
      display: 'flex',
    },
    rightMenuContainer: {
      display: 'flex',
    },
    timeControls: {
      alignSelf: 'center',
    },
    emptyStateMsg: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      color: colors.neutral800,
      fontSize: '14px',
      gap: '7px',
    },
    plotName: {
      fontSize: '13px',
      fontWeight: 600,
    },
  }),
  { name: 'OutputChartPanel' },
);

// Split any output that is defined by the same output node but should not be plotted in
// the same graph into separate nodes
const splitOutputCoefficients = (
  outputs: feoutputpb.OutputNodes,
  isTransient: boolean,
  timeImplicit: boolean,
) => {
  const splitOutputs = new feoutputpb.OutputNodes();
  outputs.nodes.forEach((node: feoutputpb.OutputNode) => {
    // Creates a new output node that only includes the outputs specified in the includedOutputs
    // array appends the string "postfix" to the name and adds it to the splitOutputs list
    const newSplitOutput = (includedOutputs: feoutputpb.OutputIncludes[], postfix: string) => {
      let hasOutputs = false;
      includedOutputs.forEach((inc: feoutputpb.OutputIncludes) => {
        hasOutputs ||= isIncluded(node, inc);
      });
      if (hasOutputs) {
        const newNode = node.clone();
        newNode.include = {};
        includedOutputs.forEach((inc: feoutputpb.OutputIncludes) => {
          setIncluded(newNode, inc, isIncluded(node, inc));
        });
        newNode.name = `${node.name || defaultName(node)} ${postfix && `- ${postfix}`}`;
        splitOutputs.nodes.push(newNode);
      }
    };
    if (isIncluded(node, feoutputpb.OutputIncludes.OUTPUT_INCLUDE_BASE)) {
      newSplitOutput([
        feoutputpb.OutputIncludes.OUTPUT_INCLUDE_BASE,
        feoutputpb.OutputIncludes.OUTPUT_INCLUDE_TIME_AVERAGE,
      ], '');
      // Currently we are only storing inner iterations for residuals and volume reductions.
      if (
        isTransient &&
        timeImplicit &&
        node.nodeProps.case === 'volumeReduction' &&
        isIncluded(node, feoutputpb.OutputIncludes.OUTPUT_INCLUDE_INNER)
      ) {
        newSplitOutput([
          feoutputpb.OutputIncludes.OUTPUT_INCLUDE_BASE,
          feoutputpb.OutputIncludes.OUTPUT_INCLUDE_INNER,
        ], 'Inner');
      }
    }
    if (isIncluded(node, feoutputpb.OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT)) {
      newSplitOutput([
        feoutputpb.OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT,
        feoutputpb.OutputIncludes.OUTPUT_INCLUDE_COEFFICIENT_TIME_AVERAGE,
      ], 'Coefficient');
    }
    if (isTransient) {
      if (timeImplicit && node.nodeProps.case === 'residual') {
        newSplitOutput([feoutputpb.OutputIncludes.OUTPUT_INCLUDE_INNER], 'Inner');
      }
    } else {
      // This is for Cauchy convergence of outputs, which is not used in transient simulations,
      // not to be confused with the solver residuals.
      newSplitOutput([feoutputpb.OutputIncludes.OUTPUT_INCLUDE_RESIDUAL], 'Residual');
      newSplitOutput([feoutputpb.OutputIncludes.OUTPUT_INCLUDE_MAX_DEV], '% Deviation');
    }
  });
  return splitOutputs;
};

/**
 * For a set of iters with repeated values, space out the values so we can plot them at different
 * positions. For example, if iters = [1, 1, 1, 1, 1, 2], it will return [1, 1.2, 1.4, 1.6, 1.8, 2].
 */
const spaceInnerIterations = (iters: number[]) => {
  const spacedIters: number[] = [];
  // The number of inner iterations for each outer iterations.
  const numInnerIters = new Map<number, number>();
  iters.forEach((i) => {
    if (!numInnerIters.has(i)) {
      numInnerIters.set(i, 0);
    }
    numInnerIters.set(i, numInnerIters.get(i)! + 1);
  });
  // Counter record the number of times we have encounter each value.
  const counter = new Map<number, number>();
  iters.forEach((i) => {
    if (!counter.has(i)) {
      counter.set(i, 0);
    }
    // Dividing the counter, but the total number will evenly space out the inner iterations.
    spacedIters.push(i + counter.get(i)! / numInnerIters.get(i)!);
    counter.set(i, counter.get(i)! + 1);
  });
  return spacedIters;
};

enum ChartOptionType { XY, MONITOR }

type ChartOption = {
  type: ChartOptionType;
  index: number;
}

const EMPTY_SOLUTIONS: frontendpb.Solution[] = [];
const EMPTY_OUTPUT_LIST: outputpb.Output[] = [];

// A panel containing graphs in a output chart and the time bar controls.
const OutputChartPanel = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const {
    outputGraphId,
    setOutputGraphId,
    selectedNode,
    setSelection,
  } = useSelectionContext();
  const { paraviewActiveUrl } = useParaviewContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const jobState = useJobState(projectId, workflowId, jobId);
  const [iter, setIter] = useSelectedIterationState(projectId, workflowId, jobId);
  const [viewState] = useViewState(projectId);
  const [plotState] = usePlotNodes(projectId);
  const inTimeUnits = useInTimeUnitsValue({ projectId, workflowId, jobId });
  const paraviewClient = useParaviewClientState(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [thisPlotNode, setThisPlotNode] = useCurrentlySelectedPlot();
  // The outputs before they are split into separate graphs for dimensional and coefficients.
  // workflowId and jobId are empty because otherwise the nodes do not have the right coefficient
  // and averaging selections.
  const [combinedOutputs] = useOutputNodes(projectId, '', '');
  // The outputs for the current simulation so that we can access the reference pressure for force
  // distribution plots.
  const [outputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const workflowState = useWorkflowState(projectId, workflowId);
  const [filterState] = useFilterState({ projectId, workflowId, jobId });
  /* Plot data select will be null if we cannot construct a valid param for this plot (i.e. if it's
   missing range data or a list of line/curve nodes -- this will usually be before the user has
   made all the necessary selections in the properties panel) or if the client retrieved using
   paraviewConnKey is null or undefined. */
  const plotData = selectivelyUpdateRpc(
    projectId,
    workflowId,
    jobId,
    thisPlotNode?.id || '',
    lcvisEnabled ? filterState : (viewState?.root ?? null),
    lcvisEnabled ? '' : (viewState?.path ?? ''),
    plotState,
    lcvisEnabled,
  );
  const plotDataSelect = useRecoilValueLoadable(plotData);

  const geometryTags = useGeometryTags(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);

  // Similar for force distribution plots.
  const forceData = forceDistribution(
    projectId,
    workflowId,
    jobId,
    thisPlotNode?.id || '',
    paraviewActiveUrl,
    plotState,
    getAdValue(getReferencePressure(outputNodes, simParam, geometryTags, staticVolumes).value),
  );
  const forceDataSelect = useRecoilValueLoadable(forceData);

  // == State
  const [dataOnY, setDataOnY] = useState(true);
  const domRef = useRef<HTMLDivElement>(null);

  // == Hooks
  const classes = useStyles();
  const domSize = useResizeObserver(domRef, { name: 'outputChartPanel' });
  const isTransient = isSimulationTransient(simParam);
  const timeImplicit = isTransient && isSimulationImplicitTime(simParam);
  const xyPlot = thisPlotNode.plot.case === 'xyPlot' ? thisPlotNode.plot.value : undefined;
  const forcePlot =
    thisPlotNode.plot.case === 'forceDistribution' ? thisPlotNode.plot.value : undefined;

  // == Derived data
  const solutions = jobState?.solutions || EMPTY_SOLUTIONS;
  // The outputs after they are split into separate graphs.
  const graphOutputs = useMemo(
    () => splitOutputCoefficients(combinedOutputs, isTransient, timeImplicit),
    [combinedOutputs, isTransient, timeImplicit],
  );
  // The selected node is identified by the outputGraphId.
  const selectedOutputNode = useMemo(() => {
    const nodeGraphOutputs = graphOutputs.nodes.filter((output) => (
      output.id === outputGraphId.node
    ));
    return nodeGraphOutputs[outputGraphId.graphIndex] || null;
  }, [graphOutputs, outputGraphId]);
  const graphNodes = graphOutputs.nodes;
  const jobActive = workflowState?.status?.typ === JobStatusType.Active;
  const useInnerWindow = (
    selectedOutputNode?.nodeProps.case === 'residual' ||
    selectedOutputNode?.nodeProps.case === 'volumeReduction'
  );
  const outputId = useInnerWindow ? selectedOutputNode.id : '';
  const [innerItersWindow] = useInnerItersWindow(projectId, jobId, outputId);
  const outputIndex = selectedOutputNode ? graphNodes.indexOf(selectedOutputNode) : -1;

  // If no output chart node exists, set it to the first output if one exists.
  useEffect(() => {
    if (!selectedOutputNode && graphNodes.length > 0) {
      setOutputGraphId({ node: graphNodes[0].id, graphIndex: 0 });
    }
  }, [graphNodes, selectedOutputNode, setOutputGraphId]);

  let fixedPrecision = 0;
  if (simParam && inTimeUnits) {
    // TODO: TimeStepVal may not be set. It might be safer to use another value
    // or to determine the precision for each step individually.
    fixedPrecision = Math.max(-Math.log10(getAdValue(simParam.time!.timeStepVal)), 0);
  }

  // List of timesteps/iterations to show in TimeBar.
  let solutionSteps: number[] = [];
  let solutionIters: number[] = [];
  const availableIterations = new outputpb.IterationRange();

  const minIter = 1;
  const maxIter = jobState?.lastIter ?? 0;

  let currentIndex = -1;
  if (solutions) {
    if (maxIter > minIter) {
      availableIterations.begin = minIter;
      availableIterations.end = maxIter;
    }
    solutionIters = solutions.map((soln, index) => {
      const solutionIter = fromBigInt(soln.iter);
      if (solutionIter === iter) {
        currentIndex = index;
      }
      return solutionIter;
    });
    if (inTimeUnits) {
      solutionSteps = solutions.map((soln) => soln.time);
    } else {
      solutionSteps = solutionIters;
    }
  }
  const setIndex = (index: number) => {
    if (index >= 0) {
      setIter(solutionIters[index]);
    }
  };

  const includeMap = selectedOutputNode?.include;
  const innerIters = isTransient && includeMap?.[feoutputpb.OutputIncludes.OUTPUT_INCLUDE_INNER];

  // Whether we have selected an XY plot node. If so, display the XY plot. If not,
  // display the monitor plot.
  const showXyPlot = !!xyPlot || !!forcePlot;
  // Monitor and force plots always have dataOnY. XY plots can swap axes.
  const plotDataOnY = !showXyPlot || !!forcePlot || dataOnY;

  // Compute Monitor Plot options and XY plot options
  const monitorOptions = graphOutputs.nodes.map((output, i) => ({
    name: output.name,
    value: { type: ChartOptionType.MONITOR, index: i },
    selected: !showXyPlot && (i === outputIndex),
  }));
  const xyPlotOptions: SelectOption<ChartOption>[] = [];
  plotState.plots.forEach((plot, i) => {
    if (i > 0) {
      xyPlotOptions.push({
        name: plot.name,
        value: { type: ChartOptionType.XY, index: i },
        selected: showXyPlot && plot === thisPlotNode,
      });
    }
  });
  const optionGroups: SelectOptionGroup<ChartOption>[] = [];
  if (monitorOptions.length > 0) {
    optionGroups.push({ label: 'Monitor Plot', options: monitorOptions });
  }
  if (xyPlotOptions.length > 0) {
    optionGroups.push({ label: xyPlot ? 'XY Plot' : 'Force Distribution', options: xyPlotOptions });
  }

  const leftMenu = (
    <DataSelect
      disabled={optionGroups.length === 0}
      onChange={(value) => {
        if (value.type === ChartOptionType.MONITOR) {
          const node = graphNodes[value.index].id;
          // Find the new graphIndex by counting the number of earlier nodes
          // that have the same ID.
          let graphIndex = 0;
          for (let i = 0; i < value.index; i += 1) {
            if (graphNodes[i].id === node) {
              graphIndex += 1;
            }
          }
          // Update graph ID and thisPlotNode.
          setOutputGraphId({ node, graphIndex });
          setThisPlotNode(plotState.plots[0]);
          // Update the selection if a plot is selected. The graph ID and thisPlotNode usually
          // operate independently of the selection. But if a plot is selected it will override
          // the other values. The selection must be updated to the new plot in this case.
          if (selectedNode?.type === NodeType.PLOT) {
            setSelection([MONITOR_PLOT_NODE_ID]);
          }
        } else {
          setThisPlotNode(plotState.plots[value.index]);
          if (selectedNode?.type === NodeType.PLOT) {
            setSelection([plotState.plots[value.index].id]);
          }
        }
      }}
      options={optionGroups}
      size="small"
    />
  );

  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const frameId = selectedOutputNode?.frameId;
  const frameFound = simParam &&
    (!frameId || selectedOutputNode?.nodeProps.case !== 'force' || frameExists(simParam, frameId));
  const labelMap = new Map<plotpb.XAxisOptions | undefined, string>([
    [plotpb.XAxisOptions.ARC_LENGTH, 'Arc Length'],
    [plotpb.XAxisOptions.X_COORD, 'X'],
    [plotpb.XAxisOptions.Y_COORD, 'Y'],
    [plotpb.XAxisOptions.Z_COORD, 'Z'],
    [undefined, ''],
  ]);
  const labelWithUnits = (xOptions?: plotpb.XAxisOptions) => (
    xOptions ? `${labelMap.get(xOptions)} (m)` : ''
  );

  const outputNodeWarnings = useMemo(() => (
    selectedOutputNode && paramScope && !showXyPlot ?
      getOutputNodeWarnings(
        selectedOutputNode,
        graphOutputs,
        simParam,
        entityGroupData,
        paramScope,
        staticVolumes,
        geometryTags,
        combinedOutputs.referenceValues?.referenceValueType,
      ) : []
  ), [
    selectedOutputNode,
    paramScope,
    showXyPlot,
    graphOutputs,
    simParam,
    entityGroupData,
    staticVolumes,
    combinedOutputs,
    geometryTags,
  ]);

  const forceNotAvailableWarnings = useMemo(() => (
    selectedOutputNode && !showXyPlot ?
      getForceNotAvailableWarnings(
        selectedOutputNode,
        graphOutputs,
        simParam,
        entityGroupData,
        staticVolumes,
        geometryTags,
      ) : []
  ), [
    selectedOutputNode,
    showXyPlot,
    graphOutputs,
    simParam,
    entityGroupData,
    staticVolumes,
    geometryTags,
  ]);

  const { outputList, staticOutputIndex } = useMemo(() => {
    if (!outputNodeWarnings.length && selectedOutputNode) {
      return createOutputs(
        selectedOutputNode,
        graphOutputs,
        simParam,
        entityGroupData,
        !isTransient,
        true,
        [],
      );
    }
    return { outputList: EMPTY_OUTPUT_LIST, staticOutputIndex: [] };
  }, [
    selectedOutputNode,
    entityGroupData,
    simParam,
    graphOutputs,
    outputNodeWarnings.length,
    isTransient,
  ]);

  const dataConfig: DataConfig[] = [];

  const saveChartFileName = (showXyPlot || outputList === EMPTY_OUTPUT_LIST) ? 'export.csv' :
    `${selectedOutputNode.name.trim()}.csv`;
  outputList.forEach((output, index) => {
    if (innerIters) {
      output.range = innerItersWindow;
    }
    const shortName = output.shortName;
    dataConfig.push({
      name: shortName.length > 0 ? `${output.name} - ${shortName}` : output.name,
      color: getTertiaryColor(staticOutputIndex[index]),
    });
  });

  const { data, iters, time } = useTimeSeriesOutput(
    projectId,
    workflowId,
    jobId,
    outputList,
    simParam,
    jobState,
    !!outputNodeWarnings.length,
    innerItersWindow,
  );

  const hasInnerIters = selectedOutputNode ?
    isIncluded(selectedOutputNode, feoutputpb.OutputIncludes.OUTPUT_INCLUDE_INNER) : false;

  // If no inner loop iterations exist in the summary files, don't display
  // the sawtooth plot. Inform the user that the sim was run before inner loop
  // residuals were being reported.
  const excludeSawtooth = (
    hasInnerIters &&
    iters.length === Math.abs(innerItersWindow.end - innerItersWindow.begin + 1)
  );

  let emptyStateText = excludeSawtooth ? 'Chart Unavailable' : 'No Data Available';
  let emptyStateSubtext: ReactNode = 'We are processing your request.';
  if (excludeSawtooth) {
    emptyStateSubtext = 'Inner iterations not reported in older simulations. ' +
      'Please rerun to view inner loop convergence.';
  } else if (paraviewClient.connState !== ConnState.CONNECTED && !!xyPlot) {
    emptyStateSubtext = 'Visualizer disconnected.';
  } else if (forcePlot) {
    emptyStateSubtext = 'Add Surfaces.';
  }
  let addSurfaces = false;

  // If we need to add surfaces to an output, display a different message
  // in the panel than if we are waiting for the chart to populate
  if (
    !showXyPlot &&
    (
      selectedOutputNode?.nodeProps.case === 'force' ||
      selectedOutputNode?.nodeProps.case === 'surfaceAverage'
    ) &&
    (selectedOutputNode?.inSurfaces.length === 0)) {
    emptyStateSubtext = 'Add Surfaces';
    addSurfaces = true;
  }
  if (
    solutions &&
    !frameFound &&
    selectedOutputNode?.nodeProps.case === 'force'
  ) {
    emptyStateText = 'Reference Frame Not Found';
    emptyStateSubtext = FRAME_NOT_FOUND_MESSAGE;
  }
  if (!!forcePlot && forcePlot.bounds.length === 0) {
    addSurfaces = true;
  }

  // Inform the user if no residuals are selected for this node.
  if (
    selectedOutputNode?.nodeProps.case === 'residual' &&
    Object.values(selectedOutputNode.nodeProps.value?.resEnabled).every((checked) => !checked)
  ) {
    emptyStateSubtext = (
      <div>
        No residuals are selected in&nbsp;
        <NodeLink nodeIds={[selectedOutputNode.id]} text={selectedOutputNode.name} />
      </div>
    );
  }

  const swapAxes = () => {
    setDataOnY(!dataOnY);
  };

  const yLabel = () => {
    if (xyPlot) {
      return xyPlot.yAxis?.displayDataName;
    }
    if (forcePlot) {
      return forcePlot.distributionType === plotpb.ForceDistributionOptions.LOCAL ?
        'Force per unit length (N/m)' : 'Force (N)';
    }
    return '';
  };

  const timeBarSettings = {
    jobActive: jobActive || hasInnerIters,
    innerItersWindow: innerIters ? innerItersWindow : null,
    availableIterations,
    currentIndex,
    fixedPrecision,
    isTransient,
    setIndex,
    steps: solutionSteps,
    swapAxes,
    xyChart: showXyPlot,
    iters: solutionIters,
    hasInnerIters,
    xyChartHeader: {
      x: labelWithUnits(xyPlot?.xAxis || forcePlot?.axis),
      y: yLabel(),
    },
  };

  const timeBarControls = (
    <TimeBarControls
      settings={timeBarSettings}
    />
  );

  const displayWarnings = [...outputNodeWarnings];
  if (selectedOutputNode) {
    data.forEach((datum) => {
      if (!datum.length) {
        displayWarnings.push(
          (selectedOutputNode.nodeProps.case === 'derived') ?
            'Expression is not well defined (e.g. division by zero) or dependency unavailable.' :
            (forceNotAvailableWarnings.at(0) || 'Requested quantity unavailable.'),
        );
      }
    });
  }

  let plotDataQuantities: number[][] = EMPTY_2D_ARRAY;
  let plotCoordinates: number[][] = EMPTY_2D_ARRAY;
  if (plotDataSelect.state === 'hasValue') {
    plotCoordinates = getPlotCoordinatesComponent(plotDataSelect.contents, xyPlot?.xAxis);
    if (plotDataSelect.contents?.data && 'quantity' in plotDataSelect.contents?.data) {
      plotDataQuantities = plotDataSelect.contents.data.quantity;
    }
  }

  const hasPlotData = plotCoordinates !== EMPTY_2D_ARRAY;

  // These conditions have to be true to display a chart instead of the "No Data Available" message
  let showChart = (
    !addSurfaces &&
      solutions &&
      (showXyPlot ? true : outputList && selectedOutputNode !== null && data.length > 0) &&
      !excludeSawtooth &&
      (
        (lcvisEnabled ? hasPlotData : paraviewClient.connState === ConnState.CONNECTED) || !xyPlot
      ) &&
      !displayWarnings.length
  );

  const shouldOverrideWithForceData = !hasPlotData || !lcvisEnabled;

  if (shouldOverrideWithForceData) {
    if (forceDataSelect.state === 'hasValue' && forceDataSelect.contents) {
      plotCoordinates = [forceDataSelect.contents.x];
      plotDataQuantities = [forceDataSelect.contents.y];
    }
  }

  let axisLabels: AxisLabels | undefined;
  if (showXyPlot) {
    // Create Axis labels
    const posLabel = timeBarSettings.xyChartHeader.x;
    const dataLabel = timeBarSettings.xyChartHeader.y || '';
    axisLabels = {
      xAxisLabel: plotDataOnY ? posLabel : dataLabel,
      yAxisLabel: plotDataOnY ? dataLabel : posLabel,
    };
  }

  let plotDataConfig: DataConfig[] = [];

  const getLineNameFromId = (id: string | undefined) => {
    let lineName = 'Line';
    const treeRoot = lcvisEnabled ? filterState : viewState?.root;

    if (id && treeRoot) {
      traverseTreeNodes(treeRoot, (node) => {
        if (node.id === id) {
          lineName = node.name;
        }
      });
    }

    return lineName;
  };

  if (showXyPlot) {
    const newDataConfig: DataConfig[] = [];
    plotDataQuantities?.forEach((_plotNode, index) => {
      newDataConfig.push({
        name: xyPlot ? getLineNameFromId(xyPlot.dataIds[index]) : thisPlotNode.name,
        color: getTertiaryColor(index),
      });
    });
    plotDataConfig = newDataConfig;
  }

  const chooseYScale = () => {
    if (showXyPlot) {
      if (xyPlot?.yAxisScale === plotpb.AxisScale.LOG) {
        // Log scale is only defined for values greater than 0. If we have zero/negative
        // values, show an error in the chart panel and use linear scale to avoid
        // an error
        if (plotDataQuantities.every((row) => row.every(
          (value) => (value > 0 || Number.isNaN(value)),
        ))) {
          return Scale.LOG;
        }
        showChart = false;
        emptyStateText = 'Log scale undefined';
        emptyStateSubtext = 'All values must be greater than zero';
      }
      // Force distribution uses linear scales.
      return Scale.LINEAR;
    }
    return (
      selectedOutputNode!.nodeProps.case === 'residual' ||
      isIncluded(selectedOutputNode, feoutputpb.OutputIncludes.OUTPUT_INCLUDE_RESIDUAL) ||
      isIncluded(selectedOutputNode, feoutputpb.OutputIncludes.OUTPUT_INCLUDE_MAX_DEV)
    ) ? Scale.LOG : Scale.LINEAR;
  };

  let linePositions = iters;
  let positions: number[][] = [];
  if (showXyPlot) {
    positions = plotCoordinates;
  } else {
    if (hasInnerIters) {
      linePositions = spaceInnerIterations(iters);
    }
    for (let i = 0; i < dataConfig.length; i += 1) {
      positions.push(linePositions.slice());
    }
  }

  // For a given position, returns a label describing it for the tooltip.
  const getPositionLabel = (position: number) => {
    if (showXyPlot) {
      const xLabel = labelMap.get(xyPlot?.xAxis || forcePlot?.axis);
      return `${dataOnY ? axisLabels?.yAxisLabel : axisLabels?.xAxisLabel}
      at ${xLabel} = ${formatNumber(position)}m`;
    }
    if (timeBarSettings.isTransient) {
      const index = linePositions.indexOf(position);
      if (inTimeUnits) {
        if ((index >= 0) && (index < time.length)) {
          return `Time ${formatNumber(time[index])}s`;
        }
      } else if ((index >= 0) && (index < iters.length)) {
        return `Step ${iters[index]}`;
      }
    }
    return `Iteration ${position.toFixed(timeBarSettings.fixedPrecision)}`;
  };

  // If we're displaying an XY chart, determine Y scale first so that we can display
  // a warning for an invalid log scale
  let yScale = Scale.LINEAR;
  if (showXyPlot) {
    yScale = chooseYScale();
  }

  // Flexbox item to be displayed if no chart is available
  const emptyStateMsg = (
    <div className={classes.emptyStateMsg}>
      <div>
        <b>{emptyStateText}</b>
      </div>
      <div style={{ fontSize: '13px' }}>
        {displayWarnings.length ? parseString(displayWarnings[0]) : emptyStateSubtext}
      </div>
    </div>
  );

  /**
   * Stores the points associated with a specific location key.
   * The location key is a string in the format: getLocationKey(datasetIndex, itemPosition).
   */
  const pointsByLocationKey = useMemo(() => {
    const result = (
      new Map<string, { x: number; y: number; z: number; }[]>()
    );

    if (
      plotDataSelect.state === 'hasValue' &&
      plotDataSelect.contents?.data?.typ === 'ScatterPlot' &&
      showXyPlot &&
      lcvisEnabled
    ) {
      let positionField: 'arc' | 'xcoords' | 'ycoords' | 'zcoords';

      switch (xyPlot?.xAxis) {
        case plotpb.XAxisOptions.X_COORD:
          positionField = 'xcoords';
          break;
        case plotpb.XAxisOptions.Y_COORD:
          positionField = 'ycoords';
          break;
        case plotpb.XAxisOptions.Z_COORD:
          positionField = 'zcoords';
          break;
        default:
          positionField = 'arc';
      }

      const datasetsCount = plotDataSelect.contents.data.xcoords.length;

      for (let datasetIndex = 0; datasetIndex < datasetsCount; datasetIndex += 1) {
        const datasetItemsCount = (
          plotDataSelect.contents.data.xcoords[datasetIndex].length
        );

        // set of strings (x,y,z) to avoid duplicates
        const existingPointKeys = new Set<string>();

        for (let itemIndex = 0; itemIndex < datasetItemsCount; itemIndex += 1) {
          const x = plotDataSelect.contents.data.xcoords[datasetIndex][itemIndex];
          const y = plotDataSelect.contents.data.ycoords[datasetIndex][itemIndex];
          const z = plotDataSelect.contents.data.zcoords[datasetIndex][itemIndex];

          const point = { x, y, z };
          const pointKey = `(${x},${y},${z})`;

          if (existingPointKeys.has(pointKey)) {
            continue;
          }

          existingPointKeys.add(pointKey);

          const itemPosition = (
            plotDataSelect.contents.data[positionField][datasetIndex][itemIndex]
          );
          const locationKey = getLocationKey(datasetIndex, itemPosition);

          const existingPoints = result.get(locationKey) ?? [];
          result.set(locationKey, [...existingPoints, point]);
        }
      }
    }

    return result;
  }, [
    lcvisEnabled,
    plotDataSelect?.contents?.data,
    plotDataSelect?.state,
    showXyPlot,
    xyPlot?.xAxis,
  ]);

  return (
    <div className={classes.root} ref={domRef}>
      <div className={classes.topBar}>
        <div className={classes.leftMenuContainer} data-locator="output-chart-panel-outputs-menu">
          {leftMenu}
        </div>
        <div className={classes.timeControls}>
          {timeBarControls}
        </div>
        <div className={classes.rightMenuContainer}>
          <DownloadMenu
            data={showXyPlot ? plotDataQuantities || EMPTY_2D_ARRAY : data}
            dataConfig={showXyPlot ? plotDataConfig : dataConfig}
            fileName={saveChartFileName}
            param={simParam}
            positions={showXyPlot ? plotCoordinates : [iters]}
            settings={timeBarSettings}
            time={time}
          />
        </div>
      </div>
      <div className={classes.chartContainer}>
        {showChart ? (
          <OutputChart
            axisLabels={axisLabels}
            data={showXyPlot ? plotDataQuantities || EMPTY_2D_ARRAY : data}
            dataConfig={showXyPlot ? plotDataConfig : dataConfig}
            dataOnY={plotDataOnY}
            getPositionLabel={getPositionLabel}
            // I don't know what this '50' is for. It was originally 44, but when I moved the panel
            // to the InfoFooter, there was an infinite resize loop. Increasing the number fixed the
            // issue. -jw
            height={Math.max(domSize.height - 50, 0)}
            onScatterHover={(value) => {
              const points = value.flatMap(({ datasetIndex, xPosition, color }) => {
                const locationKey = getLocationKey(datasetIndex, xPosition);

                const locationPoints = (pointsByLocationKey.get(locationKey) ?? []);

                return locationPoints.map((point) => ({ ...point, color }));
              });

              lcvHandler.display?.hoveredPlotPoints?.setPoints(points);
            }}
            positions={positions}
            sawtooth={hasInnerIters}
            timeBarSettings={timeBarSettings}
            type={showXyPlot && lcvisEnabled ? 'scatter' : 'line'}
            xyChart={showXyPlot}
            xyPlot={xyPlot}
            yScale={showXyPlot ? yScale : chooseYScale()}
          />
        ) :
          <>{emptyStateMsg}</>}
      </div>
    </div>
  );
};

export default OutputChartPanel;
