// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
// Run-time parameters injected through the toplevel index.html.

import { APP_URL_DEV, APP_URL_DEV_ITAR, APP_URL_PROD, APP_URL_PROD_ITAR, APP_URL_STAGE, APP_URL_STAGE_ITAR } from './constants';
import { isStorybookEnv } from './testing/utils';

function mustGetAttribute(name: string): string {
  const value = document.currentScript!.getAttribute(name);
  if (!value && !isStorybookEnv()) {
    throw Error(`attribute ${name} not set in the document (value=${value})`);
  }
  return value ?? '';
}

/**
   The URL of the analyzer server.
*/
export const analyzerServer = mustGetAttribute('analyzerserver');

/**
   The URL of the geometry services.
*/
export const geometryServer = mustGetAttribute('geometryServer');

/**
   The URL of the visualization services. These services are WIP and may not be available.
*/
export const visualizationServer =
   document.currentScript!.getAttribute('visualizationServer') || '';

/**
   The URL of the paraview server.
*/
export const paraviewServer = mustGetAttribute('paraviewserver');

/**
   The Authentication Tenant for this environment.
*/
export const authDomain = mustGetAttribute('authdomain');

/**
   The Authentication Tenant for this environment.
*/
export const authTenant = mustGetAttribute('authtenant');

/**
   The Authentication Client Id (aka. Audience) for this environment.
*/
export const authClientId = mustGetAttribute('authclientid');

/**
   The webpage version published by the jobmaster.
*/
export const pageVersion = mustGetAttribute('pageversion');

// Checks if we are on the production environment
export const isProd = (
  analyzerServer.startsWith(APP_URL_PROD) ||
   analyzerServer.startsWith(APP_URL_PROD_ITAR)
);

// Checks if we are on the staging environment
export const isStaging = (
  analyzerServer.startsWith(APP_URL_STAGE) ||
   analyzerServer.startsWith(APP_URL_STAGE_ITAR)
);

// Checks if we are on the main dev environment
export const isDev = (
  analyzerServer.startsWith(APP_URL_DEV) ||
   analyzerServer.startsWith(APP_URL_DEV_ITAR)
);

export const isHostedEnvironment = isProd || isStaging || isDev;

// True iff the web app is connected to an ITAR environemnt.
export const isItarEnv = mustGetAttribute('isitarenv') === 'true';
