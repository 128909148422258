// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import * as frontendpb from '../../proto/frontend/frontend_pb';
import * as rpc from '../rpc';

import { RpcQueue } from './RpcQueue';

class StoppingConditionRequestRpcQueue extends RpcQueue<
  frontendpb.StoppingConditionRequest,
  frontendpb.StoppingConditionBatchRequest,
  frontendpb.StoppingConditionReply,
  frontendpb.StoppingConditionBatchReply
> {
  packData(
    stopRequest: frontendpb.StoppingConditionRequest[],
    req: frontendpb.StoppingConditionBatchRequest,
  ): void {
    stopRequest.forEach((request) => {
      req.stoppingConditions.push(request);
    });
  }

  unpackData(index: number, reply: frontendpb.StoppingConditionBatchReply):
  frontendpb.StoppingConditionReply {
    return reply.stoppingConditions[index];
  }

  getDataSize(req: frontendpb.StoppingConditionBatchRequest): number {
    return req.stoppingConditions.length;
  }

  sendRequest(
    req: frontendpb.StoppingConditionBatchRequest,
  ): Promise<frontendpb.StoppingConditionBatchReply> {
    return rpc.callRetry(
      'stoppingConditionStatusBatch',
      rpc.client.stoppingConditionStatusBatch,
      req,
    );
  }

  clearData(req: frontendpb.StoppingConditionBatchRequest): void {
    req.stoppingConditions = [];
  }
}

export default StoppingConditionRequestRpcQueue;
