// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { Logger } from '../../lib/observability/logs';
import { useUserCanEdit } from '../../lib/projectRoles';
import * as rpc from '../../lib/rpc';
import { stringifyError } from '../../lib/status';
import * as basepb from '../../proto/base/base_pb';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useWorkflowState } from '../../recoil/workflowState';
import { ActionButton, ActionKind } from '../Button/ActionButton';

const logger = new Logger('JobPanel/PauseResumeToggle');

const suspendWorkflow = (projectId: string, workflowId: string) => {
  const req = new frontendpb.SuspendWorkflowRequest({ projectId, workflowId });
  rpc.client.suspendWorkflow(req)
    .then((reply) => logger.debug(`suspended workflow ${workflowId}`))
    .catch((err) => logger.error(`suspendWorkflow: ${stringifyError(err)}`));
};

const resumeWorkflow = (projectId: string, workflowId: string) => {
  const req = new frontendpb.ResumeWorkflowRequest({ projectId, workflowId });
  rpc.client.resumeWorkflow(req)
    .then((reply) => logger.debug(`suspended workflow ${workflowId}`))
    .catch((err) => logger.error(`suspendWorkflow: ${stringifyError(err)}`));
};

interface PauseResumeToggleProps {
  projectId: string;
  workflowId: string;
  cancelOnly?: boolean;
  kind?: ActionKind;
  overrideCancel?: string;
  hoverText?: string;
}

// This panel displays the job list and several accompanying buttons and a
// graph.
const PauseResumeToggle = (props: PauseResumeToggleProps) => {
  const {
    projectId,
    workflowId,
    cancelOnly,
    kind = 'primary',
    overrideCancel,
    hoverText,
  } = props;

  const projectMetadata = useProjectMetadata(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  const workflowState = useWorkflowState(projectId, workflowId);
  // This code assumes we have read workflow state already
  if (!workflowState) {
    throw Error('no workflow');
  }

  // If we are waiting for the workflow to be suspended.
  const [waitingToSuspend, setWaitingToSuspend] = useState(false);
  // If we are waiting for the workflow to be resumed.
  const [waitingToResume, setWaitingToResume] = useState(false);

  const workflowActive = workflowState.status!.typ === basepb.JobStatusType.Active;
  const workflowSuspended = workflowState.status!.typ === basepb.JobStatusType.Suspended;

  if (workflowActive && waitingToResume) {
    setWaitingToResume(false);
  }
  if (workflowSuspended && waitingToSuspend) {
    setWaitingToSuspend(false);
  }
  if (workflowActive) {
    const cancelText = overrideCancel != null && overrideCancel.length > 0 ?
      overrideCancel : 'Cancel';
    return (
      <ActionButton
        compact
        disabled={waitingToSuspend || !userCanEdit}
        hoverText={hoverText}
        kind={kind}
        onClick={(event) => {
          event.stopPropagation();
          setWaitingToSuspend(true);
          suspendWorkflow(projectId, workflowId);
        }}
        showSpinner={waitingToSuspend}>
        {cancelOnly ? cancelText : 'Pause'}
      </ActionButton>
    );
  }
  if (workflowSuspended) {
    return (
      <ActionButton
        compact
        disabled={waitingToResume || cancelOnly || !userCanEdit}
        kind={kind}
        onClick={(event) => {
          event.stopPropagation();
          setWaitingToResume(true);
          resumeWorkflow(projectId, workflowId);
        }}
        showSpinner={waitingToResume}>
        {cancelOnly ? 'Cancel' : 'Resume'}
      </ActionButton>
    );
  }
  return null;
};

export default PauseResumeToggle;
