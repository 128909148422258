// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { useParaviewContext } from '../../components/Paraview/ParaviewManager';
import { useProjectContext } from '../../components/context/ProjectContext';
import { toggleVisibility } from '../../lib/entityGroupUtils';
import { LcVisVisibilityMap } from '../../lib/lcvis/types';
import { updateTreeNodes } from '../../lib/paraviewUtils';
import { getVisibilityInfo } from '../../lib/visUtils';
import { UrlType } from '../../proto/projectstate/projectstate_pb';
import { useEntityGroupMap } from '../entityGroupState';
import { useLcVisEnabledValue } from '../lcvis/lcvisEnabledState';
import { useLcvisVisibilityMap, useSetLcvisVisibilityMap } from '../lcvis/lcvisVisibilityMap';
import { useMeshUrlState } from '../meshState';
import { useStaticVolumes } from '../volumes';

import { useFilterState } from './filterState';

/**
 * Returns a function that toggles the visibilities of the nodes specified in either PV or LCVis.
 *
 * @param nodeIds the ids whose visibilities will change
 * @param isVisible whether the ids are currently visible. The visibility of all nodes in nodeIds
 * will be set to !isVisible.
 */
export const useToggleVisibility = (nodeIds: Set<string>, isVisible: boolean) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { visibilityMap, setVisibility } = useParaviewContext();

  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const setVisibilityV2 = useSetLcvisVisibilityMap({ projectId, workflowId, jobId });

  const toggleIds = useCallback(() => {
    if (lcvisEnabled) {
      setVisibilityV2(
        (currVisibility) => toggleVisibility(
          currVisibility,
          entityGroupMap,
          nodeIds,
          !isVisible,
        ),
      );
    } else {
      setVisibility(toggleVisibility(
        visibilityMap,
        entityGroupMap,
        nodeIds,
        !isVisible,
      ));
    }
  }, [
    entityGroupMap,
    setVisibilityV2,
    visibilityMap,
    setVisibility,
    isVisible,
    nodeIds,
    lcvisEnabled,
  ]);

  return toggleIds;
};

/**
 * Returns a function that sets the visibility of the nodes specified in either PV or LCVis. That
 * function has the following parameters:
 *
 * @param show whether to show or hide the nodes
 * @param nodeIds the nodes to show/hide
 * @param inverse if true, the inverse set of the given nodeIds will be shown/hidden
 */
export const useSetNodeVisibility = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { visibilityMap: pvVisibilityMap, setVisibility: setPvVisibility } = useParaviewContext();

  const projectKey = { projectId, workflowId, jobId };

  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const [lcvisVisibilityMap, setLcvisVisibilityMap] = useLcvisVisibilityMap(projectKey);
  const [filterState, setFilterState] = useFilterState(projectKey);
  const staticVolumes = useStaticVolumes(projectId);
  const [meshUrlState] = useMeshUrlState(projectId);
  const isMesh = meshUrlState.activeType === UrlType.MESH;

  const visMap = lcvisEnabled ? lcvisVisibilityMap : pvVisibilityMap;

  const setNodeVisibility = (show: boolean, nodeIds: Set<string>, inverse = false) => {
    const visibilities = getVisibilityInfo(
      isMesh,
      filterState,
      nodeIds,
      visMap,
      entityGroupMap,
      staticVolumes,
    );

    let toToggle: Set<string>;
    if (show) {
      toToggle = inverse ? visibilities.deselectedHidden : visibilities.selectedHidden;
    } else {
      toToggle = inverse ? visibilities.deselectedVisible : visibilities.selectedVisible;
    }
    const visTransformer = (oldMap: LcVisVisibilityMap) => {
      const newMap = new Map(oldMap);
      toToggle.forEach((id) => newMap.set(id, show));
      return newMap;
    };

    if (lcvisEnabled) {
      setLcvisVisibilityMap(visTransformer);
    } else {
      setPvVisibility(visTransformer(pvVisibilityMap));
    }

    if (isMesh) {
      setFilterState((prev) => updateTreeNodes(prev, (node) => {
        if (nodeIds.has(node.id)) {
          return { ...node, visible: show };
        }
        return node;
      }));
    }
  };

  return setNodeVisibility;
};
