// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../proto/client/simulation_pb';

import { applyFluidMaterialPreset } from './fluidMaterialPresets';
import { applySolidMaterialPreset } from './solidMaterialPresets';

export function applyAllMaterialPresets(
  simParam: simulationpb.SimulationParam,
) {
  simParam.materialEntity.forEach((materialEntity) => {
    if (materialEntity.material.case === 'materialFluid') {
      applyFluidMaterialPreset(materialEntity.material.value);
    }
    if (materialEntity.material.case === 'materialSolid') {
      applySolidMaterialPreset(materialEntity.material.value);
    }
  });
}
