// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { colors } from '../../lib/designSystem';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { SelectionAction } from '../../lib/selectionUtils';
import { SimulationTreeNode } from '../../lib/simulationTree/node';
import { useSetPropertiesPanelVisible } from '../../recoil/propertiesPanel';
import { useSetLastClickedNode } from '../../recoil/useSimulationTreeState';
import { IconButton } from '../Button/IconButton';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { GridFourDots } from '../svg/GridFourDots';

import { useControlPanelModeValue } from '@/recoil/useProjectPage';

const useStyles = makeStyles(
  () => createStyles({
    iconButton: {
      color: 'currentColor',
      padding: 0,
      border: '1px solid transparent',
      backgroundColor: 'transparent',
      transition: 'background-color 250ms',
      '&:hover': {
        backgroundColor: 'rgb(255, 255, 255, 0.15)',
      },
      '&:focus': {
        borderColor: colors.primaryCta,
      },
    },
  }),
  { name: 'ShowPropPanelButton' },
);

interface ShowPropPanelButtonProps {
  node: SimulationTreeNode;
  onClick: () => void;
}

// Button for manually opening the prop panel for some geometry item
export const ShowPropPanelButton = (props: ShowPropPanelButtonProps) => {
  // == Props
  const { node, onClick } = props;

  // == Selections
  const { projectId } = useProjectContext();
  const { modifySelection, selectedNode } = useSelectionContext();

  // == Recoil
  const controlPanelMode = useControlPanelModeValue();
  const setLastClickedNode = useSetLastClickedNode(projectId, controlPanelMode);

  // == Hooks
  const classes = useStyles();

  // == Recoil
  const setPropertiesPanelVisible = useSetPropertiesPanelVisible();

  // == Handlers
  const handleClick = () => {
    if (node.id === selectedNode?.id) {
      // Clicking the button for the node that is currently selected only toggles the prop panel.
      setPropertiesPanelVisible((visible) => !visible);
    } else {
      setPropertiesPanelVisible(true);

      // But if we click the button for a node that is not the currently selected node,
      // that doesn't select it automatically so we need to manually select it.
      modifySelection({
        action: SelectionAction.OVERWRITE,
        modificationIds: [node.id],
        nodeTableOverride: { type: NodeTableType.NONE },
      });

      setLastClickedNode(() => {
        const siblingIds = node.parent?.children.map((child) => child.id) ?? [];
        return {
          id: node.id,
          parentId: node.parent?.id,
          index: siblingIds.indexOf(node.id),
        };
      });
    }

    onClick();
  };

  return (
    <IconButton
      className={classes.iconButton}
      data-locator="showPropPanel"
      onClick={handleClick}>
      <GridFourDots maxHeight={10} />
    </IconButton>
  );
};
