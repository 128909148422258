// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { findStaticVolumeById } from '../../lib/volumeUtils';
import { useEntityGroupMap } from '../../recoil/entityGroupState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useProjectContext } from '../context/ProjectContext';

/**
 * Returns a function that can be called when we want to highlight some surfaces in the 3D viewer
 * when we hover a surface/volume in the geometry tree or in the selection tables.
 * At the moment this only works for lcvis and not for paraview.
 */
export const useToggleHighlightForNode = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId, workflowId, jobId);

  return (nodeId: string, isVolume: boolean, hovered: boolean) => {
    if (hovered) {
      const surfacesToSelect: string[] = [];
      if (isVolume) {
        const bounds = findStaticVolumeById(nodeId, staticVolumes)?.bounds;
        if (bounds?.size) {
          surfacesToSelect.push(...bounds);
        }
      } else {
        const group = entityGroupMap.has(nodeId) ? entityGroupMap.get(nodeId) : null;
        const surfaces = (
          group?.children.size ? entityGroupMap.findDescendants(group.id) : [nodeId]
        );
        surfacesToSelect.push(...surfaces);
      }
      lcvHandler.queueDisplayFunction(
        'hover from tree',
        (display) => {
          display.workspace?.hoverSurfacesById(
            surfacesToSelect,
            isVolume ? 'volume' : 'surface',
          );
          if (!isVolume) {
            display.simAnnotationHandler?.hoverAnnotationsById(surfacesToSelect);
          }
        },
      );
    } else {
      lcvHandler.queueDisplayFunction(
        'hover from tree',
        (display) => {
          display.workspace?.exitHover();
          display.simAnnotationHandler?.exitHover();
        },
      );
    }
  };
};
