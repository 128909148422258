// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import FormControl from '@mui/material/FormControl';

import { findNode } from '../../lib/paraviewUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useViewStateOverflow } from '../../recoil/lcvis/viewStateOverflow';
import { useFilterState } from '../../recoil/vis/filterState';
import Form from '../Form';
import { DataSelect, DataSelectKind } from '../Form/DataSelect';
import { useProjectContext } from '../context/ProjectContext';

import { useParaviewContext } from './ParaviewManager';

interface DataComponentBaseProps {
  // Called with the chosen vector component.
  onChange: (component: number) => void;
  // The current display variable.
  displayVariable: ParaviewRpc.DisplayPvVariable;
  // Whether to disable/gray out the data name selector.
  disabled?: boolean;
  // The number of components to display.
  nComponents: number;
  kind?: DataSelectKind;
  size?: 'small' | 'medium';
}

// Contains just the base selection input without a label or anything around it.
const DataComponentBase = (props: DataComponentBaseProps) => {
  const { displayVariable, nComponents, size } = props;

  // Name of the components. The first component is always the magnitude.
  let componentsNames: string[] = ['Magnitude'];
  if (nComponents === 1) {
    // The display variable is a scalar. Do not create this component in that case.
    return null;
  } if (nComponents === 3) {
    // The display variable is a vector.
    componentsNames = componentsNames.concat(['X', 'Y', 'Z']);
  } else if (!nComponents) {
    // The display variable was not found, this is a safeguard.
    return null;
  } else {
    throw Error('Number of components not supported');
  }

  // Current selected component.
  const currentValue = componentsNames[displayVariable.displayDataNameComponent];

  return (
    <DataSelect
      asBlock
      disabled={props.disabled}
      kind={props.kind}
      onChange={(value) => {
        // Map the component string to an index.
        if (value) {
          const index = componentsNames.indexOf(value);
          props.onChange(index);
        }
      }}
      options={componentsNames.map((name) => ({
        name,
        value: name,
        selected: name === currentValue,
      }))}
      size={size}
    />
  );
};

interface DataComponentSelectProps {
  viewState: ParaviewRpc.ViewState | null;
  // Called with the chosen vector component.
  onChange: (component: number) => void;
  // The current display variable.
  displayVariable?: ParaviewRpc.DisplayPvVariable;
  // Whether to disable/gray out the data name selector.
  disabled?: boolean;
  // Whether to display the select as a full row with a label.
  fullRow?: boolean;
  kind?: DataSelectKind;
  size?: 'small' | 'medium';
}

type LcvisDataComponentProps = Omit<DataComponentSelectProps, 'viewState'> & {
  filterNodeId : string | null;
};

export const LcvisDataComponent = (props: LcvisDataComponentProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const [lcvisData] = useViewStateOverflow({ projectId, workflowId, jobId });
  const [filterState] = useFilterState({ projectId, workflowId, jobId });

  // Is this needed anymore? I think replace with displayVar
  if (!lcvisData.attrs.displayVariable && !props.displayVariable) {
    return null;
  }

  let displayVariable: ParaviewRpc.DisplayPvVariable | null =
    props.displayVariable ?? (lcvisData.attrs.displayVariable || null);
  if (props.filterNodeId) {
    const node = findNode(filterState, props.filterNodeId);
    displayVariable = node?.displayProps?.displayVariable || null;
  }

  const nComponents = lcvisData.data.find(
    (datum) => datum.name === displayVariable?.displayDataName,
  )?.dim;

  if (!displayVariable || nComponents === 1 || nComponents === undefined) {
    return null;
  }

  const baseSelect = (
    <DataComponentBase
      disabled={props.disabled}
      displayVariable={displayVariable}
      kind={props.kind}
      nComponents={nComponents ?? 0}
      onChange={props.onChange}
      size={props.size || 'small'}
    />
  );
  if (props.fullRow) {
    return (
      <Form.LabeledInput label="Component">
        {baseSelect}
      </Form.LabeledInput>
    );
  }
  return <FormControl>{baseSelect}</FormControl>;
};

// Draw a dropdown menu that selects the vector component.
export const DataComponentSelect = (props: DataComponentSelectProps) => {
  const {
    displayVariableComponents,
  } = useParaviewContext();

  if (!props.viewState?.data?.length || !props.viewState.attrs) {
    return null;
  }

  const displayVariableAttrs = props.viewState.attrs.displayVariable;
  const displayVariable = props.displayVariable || displayVariableAttrs;
  // No active displayVariable, no need to add this component.
  if (!displayVariable) {
    return null;
  }

  // Compute the number of components of the display variable.
  const nComponents = displayVariableComponents(displayVariable);
  if (nComponents === 1 || nComponents === null) {
    return null;
  }

  const baseSelect = (
    <DataComponentBase
      disabled={props.disabled}
      displayVariable={displayVariable}
      kind={props.kind}
      nComponents={nComponents}
      onChange={props.onChange}
      size={props.size || 'small'}
    />
  );
  if (props.fullRow) {
    return (
      <Form.LabeledInput label="Component">
        {baseSelect}
      </Form.LabeledInput>
    );
  }
  return <FormControl>{baseSelect}</FormControl>;
};

export default DataComponentSelect;
