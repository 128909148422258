// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/clusterconfig/clusterconfig.proto (package luminary.proto.clusterconfig, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, proto3 } from "@bufbuild/protobuf";

/**
 * When you update this enum, also update gpuTypeNames in lib/proto.ts.
 *
 * @generated from enum luminary.proto.clusterconfig.GPUType
 */
export const GPUType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.clusterconfig.GPUType",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "CPU"},
    {no: 2, name: "T4"},
    {no: 3, name: "V100"},
    {no: 4, name: "A100"},
    {no: 5, name: "A100_80"},
    {no: 6, name: "H100"},
    {no: 7, name: "N_GPU_TYPE"},
  ],
);

/**
 * @generated from enum luminary.proto.clusterconfig.DayOfWeek
 */
export const DayOfWeek = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.clusterconfig.DayOfWeek",
  [
    {no: 0, name: "UNKNOWN"},
    {no: 1, name: "MON"},
    {no: 2, name: "TUE"},
    {no: 3, name: "WED"},
    {no: 4, name: "THU"},
    {no: 5, name: "FRI"},
    {no: 6, name: "SAT"},
    {no: 7, name: "SUN"},
  ],
);

/**
 * @generated from enum luminary.proto.clusterconfig.PreemptibleOverride
 */
export const PreemptibleOverride = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.clusterconfig.PreemptibleOverride",
  [
    {no: 0, name: "UNSPECIFIED_PREEMPTIBLE_OVERRIDE"},
    {no: 1, name: "NEVER_PREEMPTIBLE"},
    {no: 2, name: "ALWAYS_PREEMPTIBLE"},
  ],
);

/**
 * ResourceUnit defines a billable resource. It must exactly match DB
 * ResourceUnit type definition.  See db/000001.*.sql for their meanings.
 *
 * @generated from enum luminary.proto.clusterconfig.ResourceUnit
 */
export const ResourceUnit = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.clusterconfig.ResourceUnit",
  [
    {no: 0, name: "RESOURCE_INVALID"},
    {no: 1, name: "RESOURCE_CPU"},
    {no: 2, name: "RESOURCE_T4"},
    {no: 3, name: "RESOURCE_V100"},
    {no: 4, name: "RESOURCE_A100"},
    {no: 5, name: "RESOURCE_GCS"},
    {no: 6, name: "RESOURCE_GCN"},
    {no: 7, name: "RESOURCE_A100_80"},
    {no: 8, name: "RESOURCE_E2"},
    {no: 9, name: "RESOURCE_H100"},
    {no: 10, name: "N_RESOURCE_UNIT"},
  ],
);

/**
 * @generated from message luminary.proto.clusterconfig.TimeOfDay
 */
export const TimeOfDay = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.TimeOfDay",
  () => [
    { no: 1, name: "hour", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "minute", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * Simple way to denote the time window when nodes should be kept warm:
 * (1) which days of the week
 * (2) what time-window during those days
 * In particular, 9-5 on week days would be specified as
 * peak_usage_days='MON,TUE,WED,THU,FRI'
 * peak_usage_start_time='09:00'
 * peak_usage_duration='8h'
 * Note that
 * (1) this start time is interpreted as "Pacific Time", and
 * (2) we make the simplifying assumption that it doesn't span multiple days.
 *
 * @generated from message luminary.proto.clusterconfig.PeakUsageWindow
 */
export const PeakUsageWindow = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.PeakUsageWindow",
  () => [
    { no: 18, name: "peak_usage_days", kind: "enum", T: proto3.getEnumType(DayOfWeek), repeated: true },
    { no: 19, name: "peak_usage_start_time_pst", kind: "message", T: TimeOfDay },
    { no: 20, name: "peak_usage_duration", kind: "message", T: Duration },
  ],
);

/**
 * Summary of a GKE nodepool.
 *
 * @generated from message luminary.proto.clusterconfig.NodePool
 */
export const NodePool = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.NodePool",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "machine_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "zone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "gpu_type", kind: "enum", T: proto3.getEnumType(GPUType) },
    { no: 5, name: "n_gpu_per_node", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "n_cpu_per_node", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "memory_gb_per_node", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "max_nodes_per_job", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "job_scheduler", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "reservation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "n_keep_alive", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 14, name: "allocation_priority", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 15, name: "max_alive_nodes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "no_bulk_insert", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "no_small_jobs", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "peak_usage_window", kind: "message", T: PeakUsageWindow },
    { no: 19, name: "allow_peak_usage", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "unused", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "preemptible_override", kind: "enum", T: proto3.getEnumType(PreemptibleOverride) },
    { no: 22, name: "kueue_local_queue", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "kueue_local_queue_preemption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.clusterconfig.ClusterConfig
 */
export const ClusterConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.ClusterConfig",
  () => [
    { no: 6, name: "node_pools", kind: "message", T: NodePool, repeated: true },
    { no: 7, name: "reservations", kind: "message", T: NodeReservation, repeated: true },
    { no: 10, name: "nodemanager_k8s_config", kind: "message", T: NodemanagerK8sConfig },
  ],
);

/**
 * Request to allocate one node.
 *
 * @generated from message luminary.proto.clusterconfig.NodeReservation
 */
export const NodeReservation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.NodeReservation",
  () => [
    { no: 1, name: "n_pu", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "gpu_type", kind: "enum", T: proto3.getEnumType(GPUType) },
    { no: 3, name: "expiry", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message luminary.proto.clusterconfig.NodeReservationState
 */
export const NodeReservationState = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.NodeReservationState",
  () => [
    { no: 1, name: "request", kind: "message", T: NodeReservation },
    { no: 2, name: "node_pool", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * A container used for seeing existing node reservations
 *
 * @generated from message luminary.proto.clusterconfig.UserReservation
 */
export const UserReservation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.UserReservation",
  () => [
    { no: 1, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reservation", kind: "message", T: NodeReservationState, repeated: true },
  ],
);

/**
 * Used to configure the warmed-up jobs and their sizing for the geometry
 * service.
 *
 * @generated from message luminary.proto.clusterconfig.GeometryServiceClusterConfig
 */
export const GeometryServiceClusterConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.GeometryServiceClusterConfig",
  () => [
    { no: 1, name: "peak_warmed_up_jobs", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "non_peak_warmed_up_jobs", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "cpu_request", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "mem_gib_request", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "peak_usage_window", kind: "message", T: PeakUsageWindow },
    { no: 6, name: "hesa_config", kind: "message", T: GeometryServiceClusterConfig_HesaConfig },
  ],
);

/**
 * @generated from message luminary.proto.clusterconfig.GeometryServiceClusterConfig.HesaConfig
 */
export const GeometryServiceClusterConfig_HesaConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.GeometryServiceClusterConfig.HesaConfig",
  () => [
    { no: 1, name: "namespace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cluster_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "service_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "node_pool", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GeometryServiceClusterConfig_HesaConfig"},
);

/**
 * @generated from message luminary.proto.clusterconfig.NodemanagerK8sConfig
 */
export const NodemanagerK8sConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.NodemanagerK8sConfig",
  () => [
    { no: 1, name: "hesa_config", kind: "message", T: NodemanagerK8sConfig_HesaConfig },
    { no: 2, name: "hesa_node_pools", kind: "message", T: NodemanagerK8sConfig_HesaNodePool, repeated: true },
    { no: 3, name: "argo_config", kind: "message", T: NodemanagerK8sConfig_ArgoConfig },
  ],
);

/**
 * @generated from message luminary.proto.clusterconfig.NodemanagerK8sConfig.HesaConfig
 */
export const NodemanagerK8sConfig_HesaConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.NodemanagerK8sConfig.HesaConfig",
  () => [
    { no: 1, name: "namespace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cluster_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "service_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "NodemanagerK8sConfig_HesaConfig"},
);

/**
 * @generated from message luminary.proto.clusterconfig.NodemanagerK8sConfig.HesaNodePool
 */
export const NodemanagerK8sConfig_HesaNodePool = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.NodemanagerK8sConfig.HesaNodePool",
  () => [
    { no: 1, name: "node_pool", kind: "message", T: NodePool },
    { no: 2, name: "workload_priority_class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "workload_priority_class_batch", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "workload_priority_class_trial_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "workload_priority_class_trial_account_batch", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "workload_priority_class_preemption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "supports_tas", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "NodemanagerK8sConfig_HesaNodePool"},
);

/**
 * @generated from message luminary.proto.clusterconfig.NodemanagerK8sConfig.ArgoConfig
 */
export const NodemanagerK8sConfig_ArgoConfig = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.clusterconfig.NodemanagerK8sConfig.ArgoConfig",
  () => [
    { no: 1, name: "service_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "toleration_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "NodemanagerK8sConfig_ArgoConfig"},
);

