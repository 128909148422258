import { Struct } from '@bufbuild/protobuf';
import mixpanel from 'mixpanel-browser';

import { isHostedEnvironment, isProd } from '../lib/RuntimeParams';
import { MIXPANEL_TOKEN_DEV, MIXPANEL_TOKEN_PROD } from '../lib/constants';
import { loadSessionJwt } from '../lib/jwt';
import { Logger } from '../lib/observability/logs';
import * as rpc from '../lib/rpc';
import * as frontendpb from '../proto/frontend/frontend_pb';

const logger = new Logger('analytics');

interface AssistantInteractionMetadata {
  // optional
  eventType?: 'panel_invoked' | 'panel_closed' | 'session_reset' | 'tool_usage';
  projectId?: string; // If relevant in the context
  workflowId?: string; // If relevant
  jobId?: string; // where applicable
  geometryId?: string; // where applicable
  meshId?: string; // The Mesh used by this simulation
  sessionId?: string;

  // arbitrary prop value
  value?: number | string;

  // These are added automatically in the analytics.assistant method so no need to passed them
  timestamp?: string;
  userId?: string;
  email?: string;
  accountId?: string;
}

interface AssistantInteraction {
  metadata: Omit<AssistantInteractionMetadata, 'workflowId' | 'jobId'> & {
    simulationId?: string;
    solutionId?: string;
  };

  // For "tool_usage" events:
  toolCategory?: string; // e.g., "PROJECT_MANAGEMENT", "PHYSICS", etc.

  // Indicate success/failure of the tool usage
  success?: boolean; // present only if event_type = "tool_usage"
  errorDetails?: string; // optional; only if success = false
}

export interface IAnalytics {
  init(): void;
  identify(userId: string, traits?: Record<string, any>): void;
  updateUserProperties(properties: Record<string, any>): void;
  track(eventName: string, properties?: Record<string, any>): void;
  page(pageName: string, properties?: Record<string, any>): void;
  assistant(
    eventName: string,
    metadata?: AssistantInteractionMetadata,
    toolCategory?: string,
    success?: boolean,
    errorDetails?: string,
  ): void;
}

class MixpanelAnalytics implements IAnalytics {
  private shouldSendEvents: boolean = true;

  init(): void {
    if (!isHostedEnvironment) {
      return console.warn('Not Hosted Environment, Mixpanel is not initialized');
    }

    const token = isProd ? MIXPANEL_TOKEN_PROD : MIXPANEL_TOKEN_DEV;
    mixpanel.init(token, { debug: !isHostedEnvironment });
  }

  private checkShouldSendEvents(userId: string, traits?: Record<string, any>): boolean {
    const email = traits?.$email;
    const isInternal = email?.endsWith('@luminarycloud.com');
    const isAutomation =
      (email?.startsWith('automation') || email?.startsWith('dev')) && isInternal;

    return !isAutomation;
  }

  identify(userId: string, traits?: Record<string, any>): void {
    if (!isHostedEnvironment || !this.checkShouldSendEvents(userId, traits)) {
      return console.warn(`Mixpanel identify: ${userId}`, traits);
    }

    mixpanel.identify(userId);
    if (traits) {
      const props = new Struct().fromJsonString(JSON.stringify(traits));
      try {
        // JSON.stringify may throw if a value is undefined.
        rpc.callRetry(
          'Analytics',
          rpc.client.analytics,
          new frontendpb.AnalyticsRequest({
            event: frontendpb.AnalyticsRequest_Event.UPDATE_USER_PROPERTIES,
            properties: props,
          }),
        ).catch(logger.error);
      } catch (err) {
        logger.error(err);
      }
    }
  }

  updateUserProperties(properties: Record<string, any>): void {
    if (!isHostedEnvironment || !this.shouldSendEvents) {
      return console.warn(`Mixpanel update user properties:`, properties);
    }

    try {
      // JSON.stringify may throw if a value is undefined.
      const props = properties && new Struct().fromJsonString(JSON.stringify(properties));
      rpc.callRetry(
        'Analytics',
        rpc.client.analytics,
        new frontendpb.AnalyticsRequest({
          event: frontendpb.AnalyticsRequest_Event.UPDATE_USER_PROPERTIES,
          properties: props,
        }),
      ).catch(logger.error);
    } catch (err) {
      logger.error(err);
    }
  }

  track(eventName: string, properties?: Record<string, any>): void {
    if (!isHostedEnvironment || !this.shouldSendEvents) {
      return console.warn(`Mixpanel event: ${eventName}`, properties);
    }
    try {
      const props = properties && new Struct().fromJsonString(JSON.stringify(properties));
      rpc.callRetry(
        'Analytics',
        rpc.client.analytics,
        new frontendpb.AnalyticsRequest({
          event: frontendpb.AnalyticsRequest_Event.TRACK,
          name: eventName,
          properties: props,
        }),
      ).catch(logger.error);
    } catch (err) {
      logger.error(err);
    }
  }

  page(pageName: string, properties?: Record<string, any>): void {
    if (!isHostedEnvironment || !this.shouldSendEvents) {
      return console.warn(`Mixpanel page view: ${pageName}`, properties);
    }
    try {
      const props = properties && new Struct().fromJsonString(JSON.stringify(properties));
      rpc.callRetry(
        'Analytics',
        rpc.client.analytics,
        new frontendpb.AnalyticsRequest({
          event: frontendpb.AnalyticsRequest_Event.TRACK,
          name: 'Page View',
          properties: props,
        }),
      ).catch(logger.error);
    } catch (err) {
      logger.error(err);
    }
  }

  assistant(
    eventName: string,
    metadata?: AssistantInteractionMetadata,
    toolCategory?: string,
    success?: boolean,
    errorDetails?: string,
  ): void {
    const jwt = loadSessionJwt();
    const { eventType = 'tool_usage', ...meta } = metadata || {};
    const properties: AssistantInteraction = {
      metadata: {
        eventType,
        timestamp: new Date().toISOString(),
        userId: jwt?.['https://luminarycloud.com/lc_user_id'],
        email: jwt?.email ?? '',
        accountId: jwt?.['https://luminarycloud.com/account_id'],
        ...meta,
      },
      ...(toolCategory && { toolCategory }),
      ...(success !== undefined && { success }),
      ...(errorDetails && { errorDetails }),
    };
    if (!isHostedEnvironment || !this.shouldSendEvents) {
      return console.warn(`Mixpanel assistant: ${eventName}`, properties);
    }
    try {
      const props = properties && new Struct().fromJsonString(JSON.stringify(properties));
      rpc.callRetry(
        'Analytics',
        rpc.client.analytics,
        new frontendpb.AnalyticsRequest({
          event: frontendpb.AnalyticsRequest_Event.TRACK,
          name: `Assistant Interaction: ${eventName}`,
          properties: props,
        }),
      ).catch(logger.error);
    } catch (err) {
      logger.error(err);
    }
  }
}

export const analytics: IAnalytics = new MixpanelAnalytics();
