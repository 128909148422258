// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState } from 'recoil';

import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import * as rpc from '../lib/rpc';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

import { selectedGeometryKey } from './selectedGeometryKey';

function serialize(val: projectstatepb.SelectedGeometry): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): projectstatepb.SelectedGeometry {
  return (val.length ?
    projectstatepb.SelectedGeometry.fromBinary(val) :
    new projectstatepb.SelectedGeometry());
}

const selectedGeometryStateRpc = selectorFamily<projectstatepb.SelectedGeometry, string>({
  key: `${selectedGeometryKey}/rpc`,
  get: (projectId: string) => () => (
    persist.getProjectState(projectId, [selectedGeometryKey], deserialize)
  ),
});

// Caches the return value of the getGeometryFromJob RPC call which is used to retrieve the
// geometry IDs related to a job ID.
const getGeometryFromJobRpcCache = selectorFamily<
  projectstatepb.SelectedGeometry, persist.RecoilProjectKey
>({
  key: `${selectedGeometryKey}/jobRpcCache`,
  get: (key: persist.RecoilProjectKey) => async () => {
    try {
      const result = await rpc.callRetry(
        'getGeometryFromJob',
        rpc.client.getGeometryFromJob,
        new frontendpb.GetGeometryFromJobRequest({
          projectId: key.projectId,
          jobId: key.jobId,
        }),
      );
      return new projectstatepb.SelectedGeometry({
        geometryId: result.geometryId,
        geometryVersionId: result.geometryVersionId,
      });
    } catch (err) {
      console.error(err);
      return new projectstatepb.SelectedGeometry();
    }
  },
});

const selectedGeometryStateTesting = selectorFamily<
  projectstatepb.SelectedGeometry, persist.RecoilProjectKey
>({
  key: selectedGeometryKey,
  get: () => () => new projectstatepb.SelectedGeometry(),
});

const selectedGeometryStateNotTesting = selectorFamily<
  projectstatepb.SelectedGeometry, persist.RecoilProjectKey
>({
  key: selectedGeometryKey,
  get: (key: persist.RecoilProjectKey) => ({ get }) => {
    if (key.jobId) {
      return get(getGeometryFromJobRpcCache(key));
    }
    return get(selectedGeometryStateRpc(key.projectId));
  },
});

const selectedGeometryStateSelector = isTestingEnv() ?
  selectedGeometryStateTesting : selectedGeometryStateNotTesting;

// Currently selected geometry in the setup tab.
export const selectedGeometryState = atomFamily<
  projectstatepb.SelectedGeometry, persist.RecoilProjectKey
>({
  key: `${selectedGeometryKey}/final`,
  default: selectedGeometryStateSelector,
  effects: (key: persist.RecoilProjectKey) => {
    // Disallow mutation and updates through the streaming RPC while in the analysis view. In that
    // case, the selected geometry is immutable.
    if (key.jobId) {
      return [];
    }
    return [syncProjectStateEffect(key.projectId, selectedGeometryKey, deserialize, serialize)];
  },
});

export const useSelectedGeometry = (projectId: string, workflowId: string, jobId: string) => (
  useRecoilState(selectedGeometryState({ projectId, workflowId, jobId }))
);
