import { RgbColor } from './designSystem';

export const hsv2rgb = (hsv: Array<number>): RgbColor => {
  const sector = Math.floor(hsv[0] / 60.0);
  const frac = (hsv[0] / 60.0) - sector;
  const co = hsv[2] * (1.0 - hsv[1]);
  const cp = hsv[2] * (1.0 - hsv[1] * frac);
  const cq = hsv[2] * (1.0 - hsv[1] * (1.0 - frac));

  switch (Math.floor(sector)) {
    default:
    case 0:
      return [hsv[2], cq, co];
    case 1:
      return [cp, hsv[2], co];
    case 2:
      return [co, hsv[2], cq];
    case 3:
      return [co, cp, hsv[2]];
    case 4:
      return [cq, co, hsv[2]];
    case 5:
      return [hsv[2], co, cp];
  }
};
