// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { NodeType } from '../../lib/simulationTree/node';
import { deleteTreeNodeMenuItem } from '../../lib/treeUtils';

import { useNodeDeletion } from './useNodeDeletion';

/**
 * A custom hook for managing tree nodes that represent physics
 */
export const usePhysicsNode = (
  nodeId: string,
  nodeType: NodeType.PHYSICS_FLUID | NodeType.PHYSICS_HEAT,
) => {
  const { canDelete, deletePhysicsNode, postDeleteNodeIds } = useNodeDeletion();

  const deleteRow = useCallback(async () => {
    if (await deletePhysicsNode(nodeId)) {
      postDeleteNodeIds([nodeId]);
    }
  }, [deletePhysicsNode, nodeId, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => [
    deleteTreeNodeMenuItem(deleteRow, !canDelete(nodeType, nodeId)),
  ], [canDelete, deleteRow, nodeId, nodeType]);

  return {
    getContextMenuItems,
  };
};
