// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { SimulationRowProps } from '../../../../../lib/componentTypes/simulationTree';
import { parsePhysicsIdFromSubId } from '../../../../../lib/physicsUtils';
import { usePhysics } from '../../../../../model/hooks/usePhysics';
import { useProjectContext } from '../../../../context/ProjectContext';
import { AddFluidBoundaryConditionButton } from '../../../AddFluidBoundaryConditionButton';
import { TreeRow } from '../../../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';

export const PhysicsFluidBoundaryConditionContainerTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Inputs
  const physicsId = useMemo(() => parsePhysicsIdFromSubId(node.id), [node.id]);

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Hooks
  const { hasVolumes } = usePhysics(projectId, workflowId, jobId, readOnly, physicsId);

  return (
    <TreeRow
      {...props}
      addControl={<AddFluidBoundaryConditionButton physicsId={physicsId} />}
      dimmed={!hasVolumes}
      forceUserCanEdit
      primaryIcon={getNodeTypeIcon(props.node.type)}
    />
  );
};
