// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, selector, useRecoilValue, useSetRecoilState, waitForAll } from 'recoil';

import { CurrentView } from '@/lib/componentTypes/context';
import { controlPanelModeState } from '@/recoil/useProjectPage';
import { routeParamsState } from '@/recoil/useRouteParams';

/**
 * The current view. It is being set separately from each one of the top project pages.
 */
export const currentViewState = atom<CurrentView>({
  key: 'currentView',
  default: CurrentView.SETUP,
});

export const useSetCurrentView = () => useSetRecoilState(currentViewState);

export const useCurrentView = () => useRecoilValue(currentViewState);

export const useIsAnalysisView = () => useRecoilValue(currentViewState) === CurrentView.ANALYSIS;

export const useIsGeometryView = () => useRecoilValue(currentViewState) === CurrentView.GEOMETRY;

export const useIsResultsView = () => useRecoilValue(currentViewState) === CurrentView.RESULTS;

export const useIsSetupView = () => useRecoilValue(currentViewState) === CurrentView.SETUP;

export const useIsAdvancedAnalysisView = () => (
  useRecoilValue(currentViewState) === CurrentView.ADVANCED_ANALYSIS
);

export const useIsSetupOrAdvancedView = () => {
  const currentView = useCurrentView();
  return [
    CurrentView.SETUP,
    CurrentView.ADVANCED_ANALYSIS,
  ].includes(currentView);
};

export const isExplorationSetupState = selector({
  key: 'isExplorationSetupState',
  get: ({ get }) => {
    const [
      currentView,
      controlPanelMode,
    ] = get(waitForAll([
      currentViewState,
      controlPanelModeState,
    ]));

    return (
      currentView === CurrentView.ADVANCED_ANALYSIS &&
      controlPanelMode === 'exploration'
    );
  },
});

export const useIsExplorationSetup = () => useRecoilValue(isExplorationSetupState);

export const isAdjointSetupState = selector({
  key: 'isAdjointSetupState',
  get: ({ get }) => {
    const [
      currentView,
      params,
    ] = get(waitForAll([
      currentViewState,
      routeParamsState,
    ]));

    return (
      currentView === CurrentView.ADVANCED_ANALYSIS &&
      params.advancedType === 'adjoint'
    );
  },
});

export const useIsAdjointSetup = () => useRecoilValue(isAdjointSetupState);
