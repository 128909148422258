// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import {
  atomFamily,
  selectorFamily,
  useRecoilValue,
  waitForAll,
} from 'recoil';

import * as flags from '../flags';
import { CurrentView, INTERMEDIATE_VIEWS } from '../lib/componentTypes/context';
import { Logger } from '../lib/observability/logs';
import * as rpc from '../lib/rpc';
import { isTestingEnv } from '../lib/testing/utils';
import { isDiscreteGeometryFile, isGeometryFile } from '../lib/upload/uploadUtils';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { LCStatus } from '../proto/lcstatus/lcstatus_pb';
import * as meshgenerationpb from '../proto/meshgeneration/meshgeneration_pb';
import { currentViewAtom_DEPRECATED } from '../state/internal/global/currentView';

import { frontendMenuState } from './frontendMenuState';
import { getBaseFileUrl, meshUrlState } from './meshState';
import { selectedGeometryState } from './selectedGeometry';
import { cadModifierState } from './useCadModifier';
import { enabledExperimentsSelector_DEPRECATED } from './useExperimentConfig';

const logger = new Logger('geometryContacts');
type RecoilKey = {
  projectId: string;
};

export type FrontendGeometryContacts = {
  operationError: boolean;
  found: boolean;
  contacts: frontendpb.GetGeometryContactsReply_GeoContact[];
  issues: LCStatus[];
};

// Whether a given meshUrlState can have contacts. We can only compute contacts of brep geometry
// files (i.e. we cannot compute contacts of STLs). Moreover, we disallow the contacts functionality
// if the feature flag is off. At last, due to how we use ContactsInfo to retrieve the contacts
// information, we lose the ability to get the contacts in the setup tab, since in that case the
// meshUrlState, cadModifier, and other state may have changed. We'd need to store the contacts
// result if we were to show the contacts in the simulation tab.
export const canComputeGeometryContactsAtom = atomFamily<boolean, string>({
  key: 'canComputeGeometryContactsAtom',
  default: selectorFamily<boolean, string>({
    key: 'canComputeGeometryContactsAtom/default',
    get: (projectId: string) => ({ get }) => {
      const [meshUrl, experiments, currentView] = get(waitForAll([
        meshUrlState(projectId),
        enabledExperimentsSelector_DEPRECATED,
        currentViewAtom_DEPRECATED,
      ]));

      return (
        isGeometryFile(meshUrl.url) &&
        !isDiscreteGeometryFile(meshUrl.url) &&
        experiments.includes(flags.contacts) &&
        [
          CurrentView.SETUP,
          CurrentView.ADVANCED_ANALYSIS,
          ...INTERMEDIATE_VIEWS,
        ].includes(currentView)
      );
    },
  }),
});

export function useCanComputeGeometryContacts(projectId: string) {
  return useRecoilValue(canComputeGeometryContactsAtom(projectId));
}

// Helper to ensure consistency on the userGeo message that's needed to start the contacts
// computation and to retrieve the results.
export function buildUserGeo(
  meshUrl: string,
  scaling: number,
  allowParasolid: boolean,
  lcsurfaceTessellation: boolean,
) {
  return new meshgenerationpb.UserGeometry({
    url: meshUrl,
    scaling,
    allowParasolid,
    lcsurfaceTessellation,
  });
}

type GeometryContactsKey = RecoilKey & { url: string, meshScaling: number };

const geometryContacts = selectorFamily<FrontendGeometryContacts, GeometryContactsKey>({
  key: 'geometryContacts',
  get: (key: GeometryContactsKey) => async ({ get }) => {
    const { projectId, url, meshScaling } = key;
    const [currModifier, experiments, selectedGeometry] = get(waitForAll([
      cadModifierState(projectId),
      enabledExperimentsSelector_DEPRECATED,
      selectedGeometryState(projectId),
    ]));
    const allowParasolid = experiments.includes(flags.parasolidMeshing);
    const lcSurface = experiments.includes(flags.lcsurfaceTessellation);
    const { geometryId, geometryVersionId } = selectedGeometry;
    const req = new frontendpb.GetGeometryContactsRequest({
      geometryId,
      geometryVersionId,
      projectId,
      userGeo: buildUserGeo(url, meshScaling, allowParasolid, lcSurface),
      userGeoMod: currModifier || undefined,
    });

    try {
      const reply = await rpc.callRetry('GetGeometryContacts', rpc.client.getGeometryContacts, req);
      const { found, geoContacts, issues, operationError } = reply;
      return { found, contacts: geoContacts, issues, operationError };
    } catch (err: any) {
      logger.error(`Error getting contacts information err=${err}`);
      return {
        operationError: false,
        found: false,
        contacts: [],
        issues: [],
      };
    }
  },
});

// Contacts per geometry mod. This selector depends on the reloadContacts atom and hence can be
// reloaded by changing the value of reloadContacts.
const geometryContactsStateSelectorRpc = selectorFamily<FrontendGeometryContacts, RecoilKey>({
  key: 'contactsStateSelectorRpc',
  get: (key: RecoilKey) => async ({ get }) => {
    const [
      meshUrl,
      canComputeGeometryContacts,
      feMenuState,
    ] = get(waitForAll([
      meshUrlState(key.projectId),
      canComputeGeometryContactsAtom(key.projectId),
      frontendMenuState({
        projectId: key.projectId,
        jobId: '',
        workflowId: '',
      }),
    ]));

    // When we apply the libraries, the frontend menu state is empty for a brief amount of time.
    // In that case, we don't want to get the contacts state because we will be requesting with
    // invalid information.
    if (!canComputeGeometryContacts || !feMenuState.toString()) {
      return {
        operationError: false,
        found: false,
        contacts: [],
        issues: [],
      };
    }
    return get(geometryContacts({
      projectId: key.projectId,
      url: getBaseFileUrl(meshUrl),
      meshScaling: feMenuState.meshScaling,
    }));
  },
  dangerouslyAllowMutability: true,
});

const geometryContactsStateSelectorTesting = selectorFamily<FrontendGeometryContacts, RecoilKey>({
  key: 'contactsStateSelector',
  get: (_: RecoilKey) => () => ({
    operationError: false,
    found: false,
    contacts: [],
    issues: [],
  }),
  dangerouslyAllowMutability: true,
});

export const geometryContactsStateSelector = isTestingEnv() ?
  geometryContactsStateSelectorTesting : geometryContactsStateSelectorRpc;

export function useGeometryContacts(projectId: string): FrontendGeometryContacts {
  return useRecoilValue(geometryContactsStateSelector({ projectId }));
}
